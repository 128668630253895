import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '../rootState';
import { IAchievmentDefault } from './types';
import { TranslationGetters } from '../translation/getters';
import { updateAchievement, createAchievement, deleteAchievement, publishUnpublishAchievement } from '@/api/achievement';
import { AchievementMiscGetters } from '../achievementMisc/getters';
import { AchievementMiscMutations } from '../achievementMisc/mutations';
import { AchievementStatusEnum, IconStatus } from './enums';
import { IResponseObject } from '@/api/misc';
import { convertFileToBase64, convertUrlToBase64 } from '@/utils/fileConvertion';
import { AchievementMutations } from './mutations';
import { sanitizeText } from '@/utils/textSanitizer';

export enum AchievementActions {
    createAchievement = 'achievement/createAchievement',
    updateAchievement = 'achievement/updateAchievement',
    deleteAchievement = 'achievement/deleteAchievement',
    publishAchievement = 'achievement/publishAchievement',
    unpublishAchievement = 'achievement/unpublishAchievement'
}

/**
 * Add this as the last argument in a commit to be able to use the mutation enums for each store
 */
const fromStoreRoot = { root: true };

const dispatchAndCommitAchievement = async (
    { commit, rootGetters }: ActionContext<IAchievmentDefault, RootState>,
    payload: IAchievmentDefault,
    actionStatus: AchievementStatusEnum
): Promise<IResponseObject> => {
    const { data, error } = await publishUnpublishAchievement(payload.id, actionStatus);

    if (error === null) {
        const array = [...rootGetters[AchievementMiscGetters.getAchievements]];
        const index = array.findIndex((achivement: IAchievmentDefault) => achivement.id === payload.id);
        array[index] = data;

        commit(AchievementMiscMutations.setAchievements, array, fromStoreRoot);
    }
    return { data, error };
};

const formatIcon = async (icon: string | Blob | unknown, iconStatus: IconStatus) => {
    if (iconStatus === IconStatus.NOT_CHANGED) {
        return undefined;
    }

    if (typeof icon === 'string') {
        const cleanUrl = icon.split('?')[0];
        const { data } = await convertUrlToBase64(cleanUrl);
        return data;
    }
    return await convertFileToBase64(icon);
};

const formatAchievementObject = async ({ state, rootGetters }: ActionContext<IAchievmentDefault, RootState>): Promise<any> => {
    const originalState = { ...state };
    originalState.translations = rootGetters[TranslationGetters.getTranslations];

    originalState.lockedIcon = await formatIcon(originalState.lockedIcon as string | Blob, originalState.lockedIconStatus);
    originalState.unlockedIcon = await formatIcon(originalState.unlockedIcon as string | Blob, originalState.unlockedIconStatus);
    return originalState;
};

export const actions: ActionTree<IAchievmentDefault, RootState> = {
    async updateAchievement(context): Promise<IResponseObject> {
        const originalState = await formatAchievementObject(context);
        // This is used when fetching achivements to get the lates images for the updated object.
        context.commit(AchievementMiscMutations.addIdOfUpdatedAchievement, originalState.id, fromStoreRoot);
        context.commit(AchievementMiscMutations.setTimeStampOfLatestUpdate, Date.now(), fromStoreRoot);

        originalState.status = AchievementStatusEnum.UNPUBLISH;

        return await updateAchievement(originalState.id, originalState);
    },
    async createAchievement(context): Promise<IResponseObject> {
        context.commit(AchievementMutations.setLockedIconStatus, IconStatus.NEW, fromStoreRoot);
        context.commit(AchievementMutations.setUnlockedIconStatus, IconStatus.NEW, fromStoreRoot);

        const originalState = await formatAchievementObject(context);

        originalState.status = AchievementStatusEnum.UNPUBLISH;

        return await createAchievement(originalState);
    },
    async publishAchievement(context, payload: IAchievmentDefault) {
        return await dispatchAndCommitAchievement(context, payload, AchievementStatusEnum.PUBLISH);
    },
    async unpublishAchievement(context, payload: IAchievmentDefault) {
        return await dispatchAndCommitAchievement(context, payload, AchievementStatusEnum.UNPUBLISH);
    },

    async deleteAchievement({ rootGetters, commit }, payload: IAchievmentDefault): Promise<IResponseObject> {
        const { data, error } = await deleteAchievement(payload.id);

        if (error === null) {
            const preArray = rootGetters[AchievementMiscGetters.getAchievements];

            const t = preArray.filter((achivement: IAchievmentDefault) => !(achivement.id === payload.id));

            commit(AchievementMiscMutations.setAchievements, t, fromStoreRoot);
        }
        return { data, error };
    }
};

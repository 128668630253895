import { Module } from 'vuex';
import { WhiteLabelState } from './types';
import { RootState } from '../rootState';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const state: WhiteLabelState = {
  themes: {
    sidebarColor: '#3d586a',
    topBarColor: '#3d586a',
    logo: require('../../assets/enerfy_logo.svg'),
    isDefault: true
  }
};

export const whitelabel: Module<WhiteLabelState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};

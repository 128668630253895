import { SelectedGroup, SelectedUser } from '@/types/toolbar';
import { MutationTree } from 'vuex';
import { ToolbarState } from './types';

export enum ToolbarMutations {
  setSelectedUsers = 'toolbar/setSelectedUsers',
  setSelectedGroups = 'toolbar/setSelectedGroups',
  resetState = 'toolbar/resetState'
}

export const mutations: MutationTree<ToolbarState> = {
  setSelectedUsers(state, payload: SelectedUser[]): void {
    state.SelectedUsers = payload;
  },
  setSelectedGroups(state, payload: SelectedGroup[]): void {
    state.SelectedGroups = payload;
  },
  resetState(state): void {
    state.SelectedUsers = undefined;
    state.SelectedGroups = undefined;
  }
};

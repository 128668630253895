import { Module } from 'vuex';
import { ToolbarState } from './types';
import { RootState } from '../rootState';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const state: ToolbarState = {
    SelectedUsers: undefined,
    SelectedGroups: undefined
};

export const toolbar: Module<ToolbarState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};

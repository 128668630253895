import { GetterTree } from 'vuex';
import { AchievementStatusEnum } from '../achievement/enums';
import { IAchievmentDefault } from '../achievement/types';
import { RootState } from '../rootState';
import { AgentScoreType, IAchievementMisc, IFilter } from './types';

export enum AchievementMiscGetters {
    getTemplates = 'achievementMisc/getTemplates',
    getAchievements = 'achievementMisc/getAchievements',
    getCurrentLicenseKey = 'achievementMisc/getCurrentLicenseKey',
    getNumberOfActiveAchivements = 'achievementMisc/getNumberOfActiveAchivements',
    getIdsOfUpdatedAchievements = 'achievementMisc/getIdsOfUpdatedAchievements',
    getTimeStampOfLatestUpdate = 'achievementMisc/getTimeStampOfLatestUpdate',
    getDisplayableAchievements = 'achievementMisc/getDisplayableAchievements',
    getFilters = 'achievementMisc/getFilters',
    getAgentScoreType = 'achievementMisc/getAgentScoreType'
}

/**
 * Returns a filtered list that only mates the filter objects.
 *
 * Ex. if filters is defines as [{ key: 'status', value: AchievementStatusEnum.UNPUBLISH }] the returning array will only contain
 * achievements that have the status property set to AchievementStatusEnum.UNPUBLISH
 *
 * @param data array of {@link IAchievmentDefault}
 * @param filters array of {@link IFilter}
 * @returns array of {@link IAchievmentDefault}
 */
const filterItems = (data: IAchievmentDefault[], filters: IFilter[]) =>
    data.filter(
        (item: IAchievmentDefault) =>
            !filters.find(
                (filterObject: IFilter) => filterObject.key.split('.').reduce((keys: any, key: any) => keys[key], item) !== filterObject.value
            )
    );

export const getters: GetterTree<IAchievementMisc, RootState> = {
    getTemplates(state): IAchievmentDefault[] {
        return state.template_achivements;
    },
    getAchievements(state): IAchievmentDefault[] {
        return state.licenseKey_achievements;
    },
    getCurrentLicenseKey(state): string {
        return state.current_licenseKey;
    },
    getNumberOfActiveAchivements(state): number {
        return state.licenseKey_achievements.filter((achievement: IAchievmentDefault) => achievement.status === AchievementStatusEnum.PUBLISH).length;
    },
    getIdsOfUpdatedAchievements(state): Set<string> {
        return state.idsOfUpdatedAchievements;
    },
    getTimeStampOfLatestUpdate(state): number | null {
        return state.timeStampOfLatestUpdate;
    },
    getDisplayableAchievements(state): IAchievmentDefault[] {
        return filterItems(state.displayableAchievements, state.filters);
    },
    getFilters(state): IFilter[] {
        return state.filters;
    },
    getAgentScoreType(state): AgentScoreType | null {
        return state.agentScoreType;
    }
};

import { GlobalActionTrigger, ToolbarActionTrigger } from '@/types/toolbar';
import { commApi } from '../utils/http';

const addAction = (payload: GlobalActionTrigger) => {
  return commApi.post(`action`, payload);
};

const addActionGroup = (payload: ToolbarActionTrigger) => {
  return commApi.post(`action/toolbar/group`, payload);
};

export default {
  addAction,
  addActionGroup
};

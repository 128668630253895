import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);
export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 482,
      md: 992,
      lg: 1474,
    }
  },
  theme: {
    dark: false
  },
  icons: {
    iconfont: 'fa'
  }
});

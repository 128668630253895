// NOTE: This file should be replace with a backend feed
// Its now used to display image alternatives for the user when selecting images in creating/editiding achivements

// The $mediaUrl is defined in main.ts and uses the env variable 'VUE_APP_MEDIAURL'
import Vue from 'vue';

const fileType = '.png';

// The are both translationKeys and the name of the file.
const imageFileNames: string[] = [
    '100km',
    '200km',
    '25km',
    '50km',
    'referafriend',
    'safedriver',
    'ecofriend',
    'superstar',
    'worldclass',
    'co2saver',
    'ridewithme',
    'defaulticonimage'
];
// These two urls are set in the backend if the user havnt selected/provided any images at all
export const fallbackLockedUrl = `${Vue.prototype.$mediaUrl}/fma/100/6bd1c93e-4113-4bc5-94d2-8958139b16d8`;
export const fallbackUnlockedUrl = `${Vue.prototype.$mediaUrl}/fma/100/afcd6ded-7f77-4cdb-b359-db764038fb86`;

export interface IImageEntity {
    fileName: string;
    url: string;
}

const StatusData = {
    locked: {
        fallbackUrl: fallbackLockedUrl,
        rideWithMeUrl: '/fma/100/5a902918-3506-4c22-bede-81e075742285',
        baseUrl: `${Vue.prototype.$mediaUrl}/fma/100/badges/incentive/locked/`,
        translationKey: 'locked'
    },
    unlocked: {
        fallbackUrl: fallbackUnlockedUrl,
        rideWithMeUrl: '/fma/100/6cd9a013-ed8a-44f5-b8f6-ecc20c352c6b',
        baseUrl: `${Vue.prototype.$mediaUrl}/fma/100/badges/incentive/unlocked/`,
        translationKey: 'unlocked'
    }
} as const;

type StatusData = typeof StatusData[keyof typeof StatusData];

const getListOfImageObjects = (data: StatusData): IImageEntity[] => {
    const { fallbackUrl, rideWithMeUrl, translationKey, baseUrl } = data;

    return imageFileNames.map((imageName: string) => {
        let url = '';
        if (imageName === 'defaulticonimage') {
            url = fallbackUrl;
        } else {
            url = imageName !== 'ridewithme' ? `${baseUrl}${imageName}${fileType}` : `${Vue.prototype.$mediaUrl}${rideWithMeUrl}`;
        }

        return {
            fileName: `${Vue.prototype.$localize(imageName)} ${Vue.prototype.$localize(translationKey)}`,
            url
        };
    });
};

export const getImages = (isUnlocked: boolean): IImageEntity[] => {
    return getListOfImageObjects(isUnlocked ? StatusData.unlocked : StatusData.locked);
};

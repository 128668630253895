export interface MenuItem {
  title: string;
  icon: string;
  route?: string;
  stagingRoute?: string;
  children?: MenuItem[];
  newTab?: boolean;
  login?: boolean;
  backgroundColor?: string;
  index: number;
  productId?: string;
}
interface DefaultMenuItems extends Array<MenuItem> {}

export interface IMenuItems {
  home: MenuItem;
  user: MenuItem;
}

const MenuItems: IMenuItems = {
  home: { title: 'home' , icon: 'fas fa-house', route: '/', index: 0},
  user: { title: 'user' , icon: 'fas  fa-user-cog', route: '', index: 1, children: [
    { title: 'settings' , icon: 'fas fa-cog', route: '/Settings', index: 1.1 },
    { title: 'p2ppayments', icon: 'fa-solid fa-credit-card', route: '', index: 1.2 },
    { title: 'logout' , icon: 'fas fa-lock', route: '', index: 1.3 }
  ]}
};

const ComponentStaticPages = [
  MenuItems.home,
  MenuItems.user
];

export {
  ComponentStaticPages,
  MenuItems
};

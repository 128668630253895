import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { DefaultResponse } from '@/types/general';
import { LoginResponse } from '@/types/user';

let refreshing = false;

// Create two clients, one for each server
const interceptors = {
  request: async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    // Try to attach access token on requests if possible
    const accessToken = sessionStorage.getItem('access_token');
    if (accessToken) {
      config.headers.token = accessToken;
      config.headers['X-Authtoken'] = accessToken;
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

        // Interceptor request
    if (!config.params) {
            config.params = {};
        }
    return config;
    },
    response: (response: AxiosResponse<DefaultResponse>): AxiosResponse<DefaultResponse> => {
        return response;
    },
    error: async (error: any): Promise<any> => {
        // Check to see if we need to refresh token
        if (error.response?.status === 401) {
            if (!refreshing) {
                await refresh();
                // Retry
                error.config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('access_token');
                return axios.request(error.config);
            }
        } else {
            return Promise.reject(error.response.data);
        }
    },
  // To override default serialization by axios
  paramsSerializer: (params: any) =>
  Object.entries(params).map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}=${JSON.stringify(value)}`;
      }
      return `${key}=${value}`;
    }).join('&')
};

async function refresh(): Promise<void> {
    const refreshToken: string | null = sessionStorage.getItem('refresh_token');
    return new Promise((resolve, reject) => {
        if (!refreshToken) {
            return reject();
        }
        refreshing = true;
        const params = new URLSearchParams();
        params.append('refresh_token', refreshToken);
        params.append('grant_type', 'refresh_token');
        app04
            .post('/v2/Token', params)
            .then((res: AxiosResponse<LoginResponse>) => {
                // We need only to update tokens as user is already stored
                sessionStorage.setItem('access_token', res.data.access_token);
                sessionStorage.setItem('refresh_token', res.data.refresh_token);
                refreshing = false;
                resolve();
            })
            .catch(() => reject())
            .finally(() => (refreshing = false));
    });
}

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 1 * 60 * 1000
});

api.interceptors.request.use(interceptors.request, interceptors.error);
api.interceptors.response.use(interceptors.response, interceptors.error);

const app04 = axios.create({
  baseURL: process.env.VUE_APP_APP04_URL,
  timeout: 1 * 60 * 1000
});

app04.interceptors.request.use(interceptors.request, interceptors.error);
app04.interceptors.response.use(interceptors.response, interceptors.error);

const paymentApi = axios.create({
  baseURL: 'https://gtglobalpayments.azurewebsites.net/api/',
  timeout: 1 * 60 * 1000,
  headers: {
    'X-Auth': '8bb0d947-0884-4a82-8467-a69383c3dc1c'
  },
  paramsSerializer: interceptors.paramsSerializer
});

paymentApi.interceptors.request.use(interceptors.request, interceptors.error);
paymentApi.interceptors.response.use(interceptors.response, interceptors.error);

const localHost = axios.create({
  baseURL: 'http://localhost:7071/api/',
  timeout: 1 * 60 * 1000,
  headers: {
    'X-Auth': '8bb0d947-0884-4a82-8467-a69383c3dc1c'
  }

});

localHost.interceptors.request.use(interceptors.request, interceptors.error);
localHost.interceptors.response.use(interceptors.response, interceptors.error);

const grouphandler = axios.create({
  baseURL: 'https://gt-grouphandler.azurewebsites.net/api',
  timeout: 1 * 60 * 1000
});

grouphandler.interceptors.request.use(interceptors.request, interceptors.error);
grouphandler.interceptors.response.use(interceptors.response, interceptors.error);

const commApi = axios.create({
  baseURL: process.env.VUE_APP_COMM_API_URL,
  timeout: 1 * 60 * 1000
});

commApi.interceptors.request.use(interceptors.request, interceptors.error);
commApi.interceptors.response.use(interceptors.response, interceptors.error);

const account = axios.create({
  baseURL: 'https://gt-account-api.azurewebsites.net/api',
  timeout: 1 * 60 * 1000
});

account.interceptors.request.use(interceptors.request, interceptors.error);
account.interceptors.response.use(interceptors.response, interceptors.error);

// For achievement
const achievementhandler = axios.create({
  baseURL: process.env.VUE_APP_ACHIEVEMENT_PRODUCTION_URL,
  timeout: 1 * 60 * 1000
});
achievementhandler.interceptors.request.use(interceptors.request, interceptors.error);
achievementhandler.interceptors.response.use(interceptors.response, interceptors.error);

// Translation
const translationhandler = axios.create({
  baseURL: process.env.VUE_APP_GT_TRANSLATIONS_URL,
  timeout: 1 * 60 * 1000
});
translationhandler.interceptors.request.use(interceptors.request, interceptors.error);
translationhandler.interceptors.response.use(interceptors.response, interceptors.error);

// Images
// Note: It might be overkill to create this handler but it dosnt hurt and is sort of the standard
const imageBlobStorageHandler = axios.create({
  timeout: 1 * 60 * 1000,
  responseType: 'blob'
});
imageBlobStorageHandler.interceptors.request.use(interceptors.request, interceptors.error);
imageBlobStorageHandler.interceptors.response.use(interceptors.response, interceptors.error);

const agreementHandler = axios.create({
  baseURL: process.env.VUE_APP_AGREEMENT_URL,
  timeout: 1 * 60 * 1000
});
agreementHandler.interceptors.request.use(interceptors.request, interceptors.error);
agreementHandler.interceptors.response.use(interceptors.response, interceptors.error);

export { app04, api, paymentApi, grouphandler, localHost, account,  commApi, achievementhandler, translationhandler, imageBlobStorageHandler, agreementHandler };

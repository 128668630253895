import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '../rootState';
import { LanguageAbbreviation } from './enums';
import { TranslationMutations } from './mutations';
import { ITranslations, ITranslationDefault, ITranslationEntry } from './types';
import { getTranslations} from '../../api/translations';


export enum TranslationActions {
    remove = 'translation/removeFromPreviousSelectedLanguages',
    translate = 'translation/translate',
    addLangSupport = 'translation/addLangSupport',
    setPredefinedTranslations = 'translation/setPredefinedTranslations'
}
/**
 * Add this as the last argument in a commit to be able to use the mutation enums for each store
 */
const fromStoreRoot = {root: true};

const getLangsInItem = (item: ITranslations): string[] =>  {
    return Array.from(
        new Set([
            ...Object.keys(item.nameKey),
            ...Object.keys(item.lockedKey),
            ...Object.keys(item.unlockedKey)
        ])
    );
};

const fetchAndCommitTranslation = async ({commit, state}: ActionContext<ITranslationDefault, RootState>, property: ITranslationEntry, mutation: TranslationMutations) => {
    // TODO: this should proabably return the error message so it can be displayed in a toast
    const {data, error} = await getTranslations([state.selectedLanguage.lang], property[LanguageAbbreviation.EN]);

    if (error !== null) {
        // tslint:disable-next-line:no-console
        console.error(error);
        return;
    }

    const { translatedText } = data;
    commit(mutation, translatedText, fromStoreRoot);
};


export const actions: ActionTree<ITranslationDefault, RootState> = {
    setPredefinedTranslations({commit}, payload) {
        commit(TranslationMutations.setTranslations, payload, fromStoreRoot);
        commit(TranslationMutations.setPreviousSelectedLanguages, getLangsInItem(payload), fromStoreRoot
        );
    },

    // Adds the current value of selectedLanguage to a list of languages and also creates new properties on nameKey, lockedKey and unlockedKey
    addLangSupport({commit, state}) {
        commit(TranslationMutations.setPreviousSelectedLanguages, [...state.previousSelectedLanguages, state.selectedLanguage.lang], fromStoreRoot);
        commit(TranslationMutations.addLangProperty, state.selectedLanguage.lang, fromStoreRoot);
    },

    // TODO: this should proabably return the error message so it can be displayed in a toast
    async translate(context) {
        const {commit, state} = context;

        commit(TranslationMutations.setFetchingTranslations, true, fromStoreRoot);
        const { nameKey, unlockedKey, lockedKey } = state.translations;

        // TODO: Get potential error message from fetchAndCommitTranslation so it can be displayed in a toaster
        await fetchAndCommitTranslation(context, nameKey, TranslationMutations.setNameKey);
        await fetchAndCommitTranslation(context, unlockedKey, TranslationMutations.setUnlockedKey);
        await fetchAndCommitTranslation(context, lockedKey, TranslationMutations.setLockedKey);

        commit(TranslationMutations.setFetchingTranslations, false, fromStoreRoot);
    }
};

import { LanguageAbbreviation } from './enums';
import { ITranslationDefault } from './types';

const state: ITranslationDefault = {
    selectedLanguage: {
        lang: LanguageAbbreviation.EN,
        text: 'English'
    },
    previousSelectedLanguages: [LanguageAbbreviation.EN],
    fetchingTranslations: false,
    translations: {
        nameKey: {
            en: ''
        },
        lockedKey: {
            en: ''
        },
        unlockedKey: {
            en: ''
        }
    }
};

export function getDefaultState() {
    return JSON.parse(JSON.stringify(state));
}

import _Vue from 'vue';

export enum EventBusEvents {
  yes = 'confirm_yes',
  no = 'confirm_no',
  scrollTop = 'scrollTop',
  logout = 'logout',
  goHome = 'goHome',
  loading = 'loading',
  loaded = 'loaded'
}

export interface EventBus extends _Vue {
  events: EventBusEvents;
}

export default {
  install(Vue: typeof _Vue) {
    Vue.prototype.$bus = new _Vue() as EventBus;
    Vue.prototype.$bus.events = EventBusEvents;
  }
};

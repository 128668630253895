export interface SelectedUser {
  userId: number;
}

export interface SelectedGroup {
  licenseKey: string;
}

export interface GlobalActionTrigger {
  licenseKey: string;
  userId: number;
  userName: string | null;
  email: string | null;
  triggerType: number;
  titleKeys: any;
  bodyKeys: any;
  sendTime: string | null;
}

export interface ToolbarActionTrigger {
    appLicenseKey: string;
    licenseKey: string;
    titleKeys: any;
    bodyKeys: any;
    sendTime: string;
    groups: string[];
    triggerType: number;
}

export const ToolbarSendType = {
  sendNow: 0,
  sendLater: 1
} as const;

export const ToolbarActionType = {
  email: 0,
  push: 1,
  sms: 2
} as const;

export interface ToolbarMenuItem {
  title: string;
  icon: string; 
  action: number;
}

export const RecommendedMaxCharacterCountBody = {
  email: 500,
  push: 150,
  sms: 320
} as const;

import { ActionTree } from 'vuex';
import { RootState } from '../rootState';
import { WhiteLabelState } from './types';

export enum WhiteLabelActions {
  fetchThemes = 'whitelabel/fetchThemes'
}

export const actions: ActionTree<WhiteLabelState, RootState> = {
  async fetchThemes({ commit }, payload: any): Promise<any> {
    try {
      if (payload.licenseKey === '2efbe857-f61b-4987-be37-d0fbcfadf5dc') {
        commit('setThemes', { topBarColor: '#25477B', sidebarColor: '#25477B', logo: require('../../assets/Geotab.png'), isDefault: false });
      } else {
        commit('setThemes', { topBarColor: '#3d586a', sidebarColor: '#3d586a', logo: require('../../assets/enerfy_logo.svg'), isDefault: true });
      }
    } catch (e) {
      await Promise.reject(e);
    }
  }
};

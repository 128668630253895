import _Vue, { CreateElement, VueConstructor } from 'vue';
import { VAlert } from 'vuetify/lib';
import './toast.scss';

export interface ToastOptions {
  duration?: number;
  width?: string;
  position?: ToastPosition;
}

export interface ToastAlert extends ToastOptions {
  type: string;
  message: string;
  id: number;
}

export enum ToastPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM'
}

export interface ToastService {
  active: () => boolean;
  error: (message: string, options?: ToastOptions) => void;
  success: (message: string, options?: ToastOptions) => void;
  warning: (message: string, options?: ToastOptions) => void;
  info: (message: string, options?: ToastOptions) => void;
}

const defaultOptions: ToastOptions = {
  duration: 2500,
  position: ToastPosition.TOP,
  width: '100%',
  
};

export const Alert = (_Vue as VueConstructor<
  _Vue & {
    props: {
      target: HTMLElement
    }
  }
>).extend({
  props: {
    target: {
      default: () => document.body
    }
  },
  components: {
    VAlert
  },
  // Render function to set up wrapper for alerts and render alerts
  render(createElement: CreateElement): any {
    // renders all alerts in array alerts[]
    return createElement('transition-group', {
      attrs: {
        name: 'toast-list', tag: 'div'
      },
      style: {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
        top: '0',
        padding: '25px'
      }
    }, this.alerts.map((alert: ToastAlert, i: number) => createElement('v-alert', {
        props: {
          type: alert.type,
          width: alert.width
        },
        key: alert.id,
        slot: 'default'
      }, alert.message)
    ));
  },
  data: () => ({
    alerts: [] as ToastAlert[],
    unexpected: 'unexpectederror',
    cnt: 0 as number // use this for keying all alerts for smooth transitions without stutter
  }),
  methods: {
    add(type: string, message: string, options: ToastOptions) {
      this.cnt ++;
      options = { ...defaultOptions, ...options };
      this.alerts.unshift({ id: this.cnt, type, message, ...options });
      setTimeout(() => {
        this.alerts.pop();
      }, options.duration);
    }
  }
});

const plugin:
  {
    wrapper: any,
    alert: any,
    install: (Vue: typeof _Vue, options: any) => void
  } = {
  wrapper: undefined,
  // Alert component to hold vuetify alerts and stuff, logic
  alert: new Alert(),
  install(Vue: typeof _Vue, pluginOptions: { target: HTMLElement | string, vuetify: any }) {
    // Create container to mount our alerts if target is not specified
    const vm = this;
    (Vue as any).mixin({
      mounted() {
        // Either root or some other dude
        if (!this.$parent && !vm.wrapper) {
          let wrapper;
          if (pluginOptions?.target) {
            if (typeof pluginOptions.target === 'string') {
              wrapper = document.querySelector(pluginOptions.target);
            } else {
              wrapper = pluginOptions.target;
            }
          } else {
            wrapper = document.createElement('div');
            wrapper.setAttribute('id', 'wrapper__container');
            wrapper.setAttribute(
              'style', `position: fixed; left: 0; right: 0; top: 0; bottom: 0; pointer-events: none;`
            );

            // Sneaking that element in there
            this.$root.$el.appendChild(wrapper);
          }

          vm.wrapper = document.createElement('div');
          vm.wrapper.setAttribute('id', 'toast__container');
          wrapper!.appendChild(vm.wrapper);

          // Create instance of alert when app is ready
          vm.alert = new Alert({
            vuetify: pluginOptions.vuetify,
            propsData: {
              target: vm.wrapper
            }
          });

          vm.alert.$mount('#toast__container');
        }
      }
    });

    // Setup main global method
    Vue.prototype.$toast = {
      active: () => this.alert?.$data.alerts,
      error: (message: string, options: ToastOptions): void => {
        this.alert?.add('error',
        message.length ?
          message :
          _Vue.prototype.$localize(this.alert?.unexpected), options);
      },
      success: (message: string, options: ToastOptions): void => {
        this.alert?.add('success', message, options);
      },
      warning: (message: string, options: ToastOptions): void => {
        this.alert?.add('warning', message, options);
      },
      info: (message: string, options: ToastOptions): void => {
        this.alert?.add('info', message, options);
      }
    };
  }
};

export default plugin;

import sanitizeHtml from 'sanitize-html';
// DOMParser will by default not execute scripts,
const decoderDocument = new DOMParser().parseFromString('', 'text/html');
const decoder = decoderDocument.createElement('textarea');

export const sanitizeText = (inputValue: string, allowedTags: string[] | undefined = []) => {
    // Convert enetity references to characters
    decoder.innerHTML = sanitizeHtml(inputValue, {
        allowedTags: [...allowedTags, ...['br', 'b']]
    });
    return decoder.value;
};

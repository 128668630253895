<template>
  <v-dialog persistent v-model="show" width="400" content-class="test" style="z-index:20001;">
    <div class="modal-wrapper">

      <!-- Header Slot -->
      <div class="modal-header">
        <v-btn icon @click="$emit('close')">
          <v-icon>fa-solid fa-arrow-left-long</v-icon>
        </v-btn>
        <slot name="header"></slot>
      </div>

      <!-- Body Slot -->
      <div class="modal-body">
        <slot name="body"></slot>
      </div>

      <!-- Footer Slot -->
      <div class="modal-footer">
        <slot name="footer"></slot>
      </div>

    </div>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss">
.modal-wrapper {
  background-color: white;
}

.modal-header {
  h3 {
    margin-top: 0;
  }
  margin: 0 5% 0% 5%;
  padding: 2% 0 2% 0;
}
.modal-body {
  margin: 0 5% 0% 5%;
  padding-bottom: 5%;
}
/* .modal-mask {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3 ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
  display: table;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.5s ease;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}


.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"color":_vm.toolbarColor,"dense":"","floating":"","bottom":"","rounded":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.toggleCommunicationPopup(0)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconColor ? _vm.iconColor: 'white'}},[_vm._v("fa fa-envelope")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('email')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.toggleCommunicationPopup(1)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconColor ? _vm.iconColor: 'white'}},[_vm._v("fa fa-bell")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('pushnotification')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.toggleCommunicationPopup(2)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconColor ? _vm.iconColor: 'white'}},[_vm._v("fa fa-message-sms")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('sms')))])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconColor ? _vm.iconColor: 'white'}},[_vm._v("fa fa-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('other')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconColor ? _vm.iconColor: 'white'}},[_vm._v("fa fa-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('other')))])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconColor ? _vm.iconColor: 'white'}},[_vm._v("fa fa-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('other')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.iconColor ? _vm.iconColor: 'white'}},[_vm._v("fa fa-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('other')))])])],1),_c('v-dialog',{attrs:{"retain-focus":false,"transition":"dialog-top-transition","max-width":"1200"},model:{value:(_vm.showCommunicationPopup),callback:function ($$v) {_vm.showCommunicationPopup=$$v},expression:"showCommunicationPopup"}},[_c('ScheduleCommunication',{attrs:{"commType":this.typeOfCommunication},on:{"close":_vm.closeCommunicationPopup}})],1),_c('v-snackbar',{attrs:{"centered":"","multi-line":"","vertical":"","min-height":"200","min-width":"350","timeout":"-1"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.commErrorSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$localize('close'))+" ")])]}}]),model:{value:(_vm.commErrorSnackbar),callback:function ($$v) {_vm.commErrorSnackbar=$$v},expression:"commErrorSnackbar"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$localize('failedtosendtothefollowingusers'))+" ")]),_vm._l((_vm.commErrorRecipients),function(recipient,idx){return _c('ul',{key:idx},[_c('li',[_vm._v(" "+_vm._s(recipient)+" ")])])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { getAchievementsByAlk } from '@/api/achievement';
import { getAgentScoreType } from '@/api/agreements';
import { IResponseObject } from '@/api/misc';
import user from '@/api/user';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import { RootState } from '../rootState';
import { UserGetters } from '../user/getters';
import { AchievementMiscGetters } from './getters';
import { AchievementMiscMutations } from './mutations';
import { IAchievementMisc } from './types';

const templateLicenseKey = '';

export enum AchievementMiscActions {
    fetchTemplates = 'achievementMisc/fetchTemplates',
    fetchAchievements = 'achievementMisc/fetchAchievements',
    validatePassword = 'achievementMisc/validatePassword',
    fetchAndSetAgentScoreType = 'achievementMisc/fetchAndSetAgentScoreType'
}
/**
 * Add this as the last argument in a commit to be able to use the mutation enums for each store
 */
const fromStoreRoot = { root: true };

export const actions: ActionTree<IAchievementMisc, RootState> = {
    // TODO: remake this. this wont work
    async fetchTemplates({ commit }) {
        commit(AchievementMiscMutations.setTemplates, await getAchievementsByAlk(templateLicenseKey), fromStoreRoot);
    },
    async fetchAchievements({ commit, rootGetters }) {
        const licenceKey = rootGetters[AchievementMiscGetters.getCurrentLicenseKey];

        if (!licenceKey) {
            commit(AchievementMiscMutations.setAchievements, [], fromStoreRoot);
            return { error: null };
        }
        Vue.prototype.$loading = true;
        const { data, error } = await getAchievementsByAlk(licenceKey);
        Vue.prototype.$loading = false;

        if (error === null) {
            const ids = rootGetters[AchievementMiscGetters.getIdsOfUpdatedAchievements];
            const latestTimeStamp = rootGetters[AchievementMiscGetters.getTimeStampOfLatestUpdate];

            data.forEach((element: any) => {
                element.disableItemActions = !!!element.translations;

                if (ids.has(element.id) && latestTimeStamp) {
                    // Adding timestamp will create a more unique url wich will make it so the images needs to be re fetched.
                    // Before it was, we think, a chaching issue wich made it so the image content didnt change.
                    element.unlockedIcon = `${element.unlockedIcon}?${latestTimeStamp}`;
                    element.lockedIcon = `${element.lockedIcon}?${latestTimeStamp}`;
                }
            });
            commit(AchievementMiscMutations.setAchievements, data, fromStoreRoot);
        } else {
            Vue.prototype.$toast.error(error);
        }
        return { data, error };
    },
    async validatePassword({ rootGetters }, password: string): Promise<IResponseObject> {
        try {
            const { data: { errors } } = await user.validatePassword(rootGetters[UserGetters.getUserEmail], password);

            if (errors.length > 0) {
                throw new Error();
            }
            return { data: true, error: null };
        } catch (error) {
            return { data: null, error: 'wrongpasswordpopuptext' };
        }
    },
    async fetchAndSetAgentScoreType({commit, rootGetters}) {
        const licenseKey = rootGetters[AchievementMiscGetters.getCurrentLicenseKey];
        const { data, error } = await getAgentScoreType(licenseKey);

        if (error !== null) {
            commit(AchievementMiscMutations.setAgentScoreType, null, fromStoreRoot);
            Vue.prototype.$toast.error(error);
            return;
        }
        commit(AchievementMiscMutations.setAgentScoreType, data.scoreType, fromStoreRoot);
    }
};

import Vue from 'vue';

import { DirectiveBinding } from 'vue/types/options';

interface OverlayBinding extends DirectiveBinding {
  value: {
    active: boolean;
    content: string;
  };
}

function makeOverlay() {
  const overlay = document.createElement('div');
  overlay.setAttribute(
    'style',
  `
    position: absolute; top: 0; left: 0; right: 0; bottom: 0;
    display: flex; justify-content: center; align-items: center;
    flex-direction: column;
    margin: 12px;
    backdrop-filter: blur(10px) !important;
    -webkit-backdrop-filter: blur(10px) !important;
  `
  );
  overlay.classList.add('bluenight');
  overlay.id = 'v-overlay-el';
  return overlay;
}

Vue.directive('overlay', {
  inserted(el: HTMLElement, binding: OverlayBinding | DirectiveBinding): void {
    // When inserted, attach overlay with text
    const overlay = makeOverlay();
    overlay.innerHTML = binding.value.content;
    el.style.position = 'relative';
    el.appendChild(overlay);
  },
  update(el: HTMLElement, binding: OverlayBinding | DirectiveBinding): void {
    const overlay = el.querySelector('#v-overlay-el');
    if (overlay) {
      overlay.innerHTML = binding.value.content;
    }
  },
  unbind(el: HTMLElement) {
    const overlay = el.querySelector('#v-overlay-el');
    if (overlay) {
      el.removeChild(overlay);
    }
  }
});

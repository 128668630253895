/*
Each rule should return a stringified object containing the translation key and any potential placeholders.
The message attribute in v-slot:message only returns boolean and string therefor we need to stringify and then parse

By stringified, parsing and using v-localize we reduce the number of $localize function calls

Example:
RULE:
    maxChars: (value: any) => value.length <= 25 || JSON.stringify({ key: 'maxxcharactersrule', x: '25' }),

TEMPLATE:
    <v-text-field :rules="[rules.maxChars]">
        <template v-slot:message="{ message }">
            <span v-localize="JSON.parse(message)" />
        </template>
    </v-text-field>

    OUTCOME: 'Max 25 characters'
*/

export const rules = {
    required: (value: any) => !!value || JSON.stringify({ key: 'required' }),
    maxChars: (value: any) => value.length <= 30 || JSON.stringify({ key: 'maxxcharactersrule', x: '30' }),
    maxCharsLong: (value: any) => value.length <= 220 || JSON.stringify({ key: 'maxxcharactersrule', x: '220' }),
    pointsMinMax: (value: number) => {
        return (!isNaN(value) && value >= 0 && value <= 1000000) || JSON.stringify({ key: 'numberbetweenxandyrule', x: '0', y: '1000000' });
    },
    pointsRequired: (value: number) => value === 0 || !!value || JSON.stringify({ key: 'required' }),
    noScriptTag: (value: any) => {
        // matches any version of <....script....>
        return !value.match(/<[\s\S]*?script[\s\S]*?>/gi) || JSON.stringify({ key: 'illegalxrule', word: '<&#8203;script> or <&#8203;/script>' });
    },
    noIFrameTag: (value: any) => {
        // matches any version of <....iframe....>
        return !value.match(/<[\s\S]*?iframe[\s\S]*?>/gi) || JSON.stringify({ key: 'illegalxrule', word: '<&#8203;iframe> or <&#8203;/iframe>' });
    }
};

import { User } from '@/types/user';
import { UserState } from './types';
import { GetterTree } from 'vuex';
import { RootState } from '../rootState';

export enum UserGetters {
  getUserEmail = 'user/getUserEmail'
}

export const getters: GetterTree<UserState, RootState> = {
  getUser(state): User | undefined {
    return state.user;
  },
  getUserImg(state): string | undefined {
    return state.user
      ? `${process.env.VUE_APP_MEDIAURL}/profilephotos/${state.user.profilePicture}`
      : '';
  },
  loggedIn(state): boolean {
    // It is not enough to have user and licenseKey, we need to finish login completely
    return (
      !state.isLoggingIn &&
      state.user !== undefined &&
      state.agent?.hasOwnProperty('licenseKey')
    );
  },
  isUserSignedIn(state): boolean {
    return state.user !== undefined;
  },
  getUserRoles(state): string[] | undefined {
    return state.roles;
  },
  getAgent(state): object | undefined {
    return state.agent;
  },
  getUserEmail(state): string | undefined {
    return state.user?.email;
  },
  isEnerfyUser(state): boolean {
    let userEmail;
    // If user is in the state
    if (state.user && state.user.email) {
      userEmail = state.user.email;
    }
    if (!userEmail) {
      // else try session-storage
      const user = sessionStorage.getItem('user');
      if (user) {
        const parsedUser = JSON.parse(user);
        userEmail = parsedUser.email;
      }
    }
    if (userEmail) {
      return (
        userEmail.includes('@enerfy.eu') ||
        userEmail.includes('@greaterthan.eu')
      );
    }
    return false;
  },
  getIsLoggingIn(state): boolean | undefined {
    return state.isLoggingIn;
  }
};

export const ComparisonValueTypeEnum = {
    // group 3
    LEVEL: 300,
    SCORE: 301,
    STAR_GRADE: 302,
    CO2_SAVINGS: 303,

    // group 1
    TOTAL_DISTANCE: 100,
    WEEKLY_AVERAGE_SCORE: 101,
    WEEKLY_AVERAGE_CO2: 102,

    // group 0
    FRIENDS_REFERRAL: 0,
    CONNECT_TO_CAR: 1,
    RIDE_WITH_ME: 3
} as const;
export type ComparisonValueTypeEnum = typeof ComparisonValueTypeEnum[keyof typeof ComparisonValueTypeEnum];

export const ActionTypeEnum = {
    TRIP: 0,
    FRIEND_REFER: 1,
    DAILY_SUMMARY: 2,
    WEEKLY_SUMMARY: 3,
    MONTHLY_SUMMARY: 4
} as const;
export type ActionTypeEnum = typeof ActionTypeEnum[keyof typeof ActionTypeEnum];

export const TimeTypeEnum = {
    EACH: 0,
    DAILY: 1,
    WEEKLY: 2,
    MONTHLY: 3
} as const;
export type TimeTypeEnum = typeof TimeTypeEnum[keyof typeof TimeTypeEnum];

export const ComparisonOpTypeEnum = {
    EQUAL: 0,
    GREATER: 1,
    GREATER_EQUAL: 2,
    LESS: 3,
    LESS_EQUAL: 4
} as const;
export type ComparisonOpTypeEnum = typeof ComparisonOpTypeEnum[keyof typeof ComparisonOpTypeEnum];

export const AchievementQueryOption = {
    CREATE: 'create',
    TEMPLATE: 'template',
    EDIT: 'edit'
} as const;
export type AchievementQueryOption = typeof AchievementQueryOption[keyof typeof AchievementQueryOption];


export const AchievementStatusEnum = {
    NONE: 0,
    DRAFT: 1,
    IN_REVIEW: 2,
    ACCEPTED: 3,
    REJECTED: 4,
    PUBLISH: 5,
    UNPUBLISH: 6,
    Deleted: 7
} as const;
export type AchievementStatusEnum = typeof AchievementStatusEnum[keyof typeof AchievementStatusEnum];

export const IconStatus = {
    CHANGED: 0,
    NOT_CHANGED: 1,
    NEW: 2
} as const;
export type IconStatus = typeof IconStatus[keyof typeof IconStatus];

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"schedule-communication"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"create-communication col"},[_c('h2',{staticClass:"schedule-communication-title"},[_vm._v(" "+_vm._s(_vm.$localize('schedulemessage'))+" ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.disableHeading)?_c('validation-provider',{attrs:{"name":"messageheader","rules":'required|max:' + _vm.headingCounter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":_vm.headingCounter,"outlined":"","error-messages":errors,"label":_vm.$localize('subject'),"required":""},model:{value:(_vm.heading),callback:function ($$v) {_vm.heading=$$v},expression:"heading"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"messagebody","rules":'required|max:' + _vm.maxCharacterCountBody},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":_vm.maxCharacterCountBody,"outlined":"","error-messages":errors,"label":_vm.$localize('messagebody'),"required":""},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"selectdate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"554"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"select-date",attrs:{"label":_vm._f("localize")('date'),"readonly":"","error-messages":errors,"solo":"","append-icon":"fa-light fa-chevron-down fa-xs"},on:{"click:append":on.click},model:{value:(_vm.scheduledDate),callback:function ($$v) {_vm.scheduledDate=$$v},expression:"scheduledDate"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{staticClass:"select-date date-picker",attrs:{"first-day-of-week":1,"no-title":"","min":new Date().toISOString().substr(0, 10),"prev-icon":"fa-light fa-xs fa-chevron-left","next-icon":"fa-light fa-xs fa-chevron-right"},model:{value:(_vm.scheduledDate),callback:function ($$v) {_vm.scheduledDate=$$v},expression:"scheduledDate"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"selecttime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$localize('time'),"error-messages":errors,"type":"time"},model:{value:(_vm.scheduledTime),callback:function ($$v) {_vm.scheduledTime=$$v},expression:"scheduledTime"}})]}}],null,true)}),_c('div',{staticClass:"cancel-submit-btns"},[_c('v-btn',{staticClass:"cancel-send-btn",attrs:{"color":"blue lighten-4","rounded":"","large":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$localize('cancel'))+" ")]),_c('v-btn-toggle',{staticClass:"schedule-send-btns",attrs:{"rounded":"","large":""}},[_c('v-btn',{staticClass:"schedule-send-btn",attrs:{"type":"submit","disabled":invalid,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$localize('schedulesend'))+" ")]),_c('v-menu',{attrs:{"top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.validFormSendNow,"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" fa-caret-down ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.scheduleSendMenuItems),function(menuItem,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleScheduleAction(menuItem.action)}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(menuItem.icon)}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$localize(menuItem.title))+" ")])],1)}),1)],1)],1)],1)],1)]}}])})],1),_c('div',{staticClass:"preview-communication col"},[_c('h2',{staticClass:"preview-communication-title"},[_vm._v(" "+_vm._s(_vm.$localize('preview1'))+" ")]),(_vm.commType === _vm.emailActionType)?_c('PreviewEmail',{attrs:{"heading":this.heading,"body":this.body,"date":this.scheduledDate}}):_vm._e(),(_vm.commType === _vm.smsActionType)?_c('PreviewTextMessage',{attrs:{"body":this.body}}):_vm._e(),(_vm.commType === _vm.pushActionType)?_c('PreviewPushNotification',{attrs:{"heading":this.heading,"body":this.body}}):_vm._e(),(_vm.commType === _vm.pushActionType)?_c('PreviewPushNotification',{attrs:{"heading":this.heading,"body":this.body,"inverted":true}}):_vm._e()],1)]),_c('v-snackbar',{attrs:{"top":"","color":"green darken-1","timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$localize('close'))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.$localize('yourmessagehasbeencreated'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
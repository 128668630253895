import { IAchievementMisc } from './types';

const state: IAchievementMisc = {
    current_licenseKey: '',
    licenseKey_achievements: [],
    template_achivements: [],
    idsOfUpdatedAchievements: new Set(),
    timeStampOfLatestUpdate: null,
    displayableAchievements: [],
    filters: [],
    agentScoreType: null
};
export function getDefaultState() {
    return { ...state };
}

import {
    ActionTypeEnum,
    ComparisonValueTypeEnum,
    TimeTypeEnum,
    ComparisonOpTypeEnum
} from '@/store/achievement/enums';

import Vue from 'vue';

const unlockedUrl = `${Vue.prototype.$mediaUrl}/fma/100/badges/incentive/unlocked/`;
const lockedUrl = `${Vue.prototype.$mediaUrl}/fma/100/badges/incentive/locked/`;

// TODO: put all objects in the database under a specific licenseKey that holds all templates
// So this file should be removed after the process
const influencer = {
    actionType: ActionTypeEnum.FRIEND_REFER,
    comparisonOpType: ComparisonOpTypeEnum.LESS_EQUAL,
    comparisonValueType: ComparisonValueTypeEnum.FRIENDS_REFERRAL,
    comparisonValue: 10,
    lockedIcon: `${lockedUrl}referafriend.png`,
    unlockedIcon: `${unlockedUrl}referafriend.png`,
    name: 'Influencer',
    onceOnly: false,
    points: 500,
    score: 0,
    timeType: TimeTypeEnum.EACH,
    unlockLimit: 10,
    translations: {
        nameKey: {
            en: `Influencer`,
            de: `Influencer`,
            da: `Influencer`,
            fr: `Influenceur`,
            nb: `Influencer`,
            sv: `Influencer`,
            pl: `Influencer`,
            ja: `フレンド`,
            vi: `Người có sức ảnh hưởng`,
            id: `Pemberi pengaruh`
        },
        lockedKey: {
            en: 'Invite a friend to the app to earn this badge. You get this badge when your friend creates an account in the app.',
            nb: 'Inviter en venn til appen for å få dette merket. Du får dette merket når vennen din oppretter en konto i appen.',
            fr: 'Invitez un ami à l\'application pour gagner ce badge. Vous obtenez ce badge lorsque votre ami crée un compte dans l\'application.',
            da: 'Inviter en ven til appen for at få dette badge. Du får dette badge, når din ven opretter en konto i appen.',
            sv: 'Bjud in en vän till appen för att få det här märket. Du får detta märke när din vän skapar ett konto i appen.',
            pl: 'Zaproś znajomego do aplikacji, aby zdobyć tę odznakę. Otrzymasz tę odznakę, gdy znajomy utworzy konto w aplikacji.',
            vi: 'Mời một người bạn vào ứng dụng để kiếm được huy hiệu này. Bạn nhận được huy hiệu này khi bạn bè của bạn tạo tài khoản trong ứng dụng.',
            id: 'Undang teman ke aplikasi untuk mendapatkan lencana ini. Anda mendapatkan lencana ini ketika teman Anda membuat akun di aplikasi.',
            ja: '新たなメンバーを招待した後、​\nアカウントの登録まで完了したら​\n獲得できるバッジ',
            de: 'Lade einen Freund in die App ein, um dieses Abzeichen zu verdienen. Sie erhalten dieses Abzeichen, wenn Ihr Freund ein Konto in der App erstellt.'
        },
        unlockedKey: {
            en: 'Great, {user}! You invited a friend to the app!',
            de: 'Großartig, {user}! Du hast einen Freund in die App eingeladen!',
            sv: 'Bra, {user}! Du bjöd in en vän till appen!',
            fr: 'Super, {utilisateur} ! Vous avez invité un ami sur l\'application !',
            nb: 'Flott, {bruker}! Du inviterte en venn til appen!',
            da: 'Fantastisk, {bruger}! Du inviterede en ven til appen!',
            pl: 'Świetnie, {user}! Zaprosiłeś znajomego do aplikacji!',
            ja: '{user}さん、\nお友だちを招待いただき、\nありがとうございます！\nぜひチャレンジや直接対決の\n機能も利用してみてください！',
            vi: 'Tuyệt vời, {user}! Bạn đã mời một người bạn vào ứng dụng!',
            id: 'Hebat, {pengguna}! Anda mengundang teman ke aplikasi!'
        }
    }
};
const safeDriverWeekly = {
    actionType: ActionTypeEnum.WEEKLY_SUMMARY,
    comparisonOpType: ComparisonOpTypeEnum.GREATER,
    comparisonValueType: ComparisonValueTypeEnum.STAR_GRADE,
    timeTypee: TimeTypeEnum.WEEKLY,
    comparisonValue: 4.4,
    name: 'Safe Driver Weekly',
    onceOnly: false,
    points: 200,
    score: 0,
    timeType: TimeTypeEnum.WEEKLY,
    unlockLimit: '',
    lockedIcon: `${lockedUrl}safedriver.png`,
    unlockedIcon: `${unlockedUrl}safedriver.png`,
    translations: {
        nameKey: {
            sv: 'Säker förare varje vecka',
            fr: 'Conducteur sûr chaque semaine',
            en: 'Safe Driver Weekly',
            nb: 'Sikker sjåfør ukentlig',
            de: 'Wöchentlich sicherer Fahrer',
            ja: '安全運転​\nボーナス',
            id: 'Pengemudi yang aman setiap minggu',
            vi: 'Lái xe an toàn hàng tuần',
            pl: 'Bezpieczny kierowca co tydzień',
            da: 'Sikker chauffør ugentligt'
        },
        lockedKey: {
            sv: 'För att få det här märket, håll ett resultat över {score} i en vecka.',
            fr: 'Pour obtenir ce badge, conservez un score supérieur à {score} pendant une semaine.',
            en: 'To earn this badge, keep a score above {score} for a week.',
            ja: '1週間の平均運転スコアが​\n{score}以上を記録した場合に​\n獲得できるバッジ​\n（獲得上限：1週間に1回まで）​',
            de: 'Um dieses Abzeichen zu erhalten, müssen Sie eine Woche lang eine Punktzahl über {score} halten.',
            nb: 'For å få dette merket, hold en poengsum over {score} i en uke.',
            pl: 'Aby zdobyć tę odznakę, utrzymuj wynik powyżej {score} przez tydzień.',
            vi: 'Để kiếm được huy hiệu này, hãy giữ điểm trên {score} trong một tuần.',
            id: 'Untuk mendapatkan lencana ini, pertahankan skor di atas {score} selama seminggu.',
            da: 'For at opnå dette badge skal du holde en score over {score} i en uge.'
        },
        unlockedKey: {
            sv: 'Bra jobbat {user}! Du höll ett resultat över {score} i en vecka.',
            fr: 'Excellent travail {utilisateur} ! Vous avez conservé un score supérieur à {score} pendant une semaine.',
            en: 'Great job {user}! You kept a score above {score} for a week.',
            vi: 'Làm tốt lắm {user}! Bạn đã giữ điểm trên {score} trong một tuần.',
            ja: '{user}さん、\nおめでとうございます！\n素晴らしい運転技術により\n直近1週間の平均スコアが\n{score}以上を記録しました！',
            de: 'Tolle Arbeit {user}! Sie haben eine Woche lang eine Punktzahl über {score} gehalten.',
            nb: 'Godt jobbet {bruker}! Du holdt en poengsum over {score} i en uke.',
            pl: 'Świetna robota {user}! Utrzymałeś wynik powyżej {score} przez tydzień.',
            id: 'Kerja bagus {pengguna}! Anda menyimpan skor di atas {score} selama seminggu.',
            da: 'Godt arbejde {bruger}! Du holdt en score over {score} i en uge.'
        }
    }
};
const twentyFiveKm = {
    comparisonSummaryValue: ComparisonValueTypeEnum.TOTAL_DISTANCE,
    actionType: ActionTypeEnum.TRIP,
    comparisonOpType: ComparisonOpTypeEnum.GREATER,
    comparisonValueType: ComparisonValueTypeEnum.TOTAL_DISTANCE,
    comparisonValue: 25,
    name: '25 km',
    onceOnly: true,
    points: 300,
    score: 0,
    unlockLimit: '',
    lockedIcon: `${lockedUrl}25km.png`,
    unlockedIcon: `${unlockedUrl}25km.png`,
    translations: {
        nameKey: {
            en: '25 km',
            de: '25 km',
            da: '25 km',
            fr: '25 km',
            nb: '25 km',
            sv: '25 km',
            pl: '25 km',
            ja: '25 km',
            vi: '25 km',
            id: '25 km'
        },
        unlockedKey: {
            en: 'You drove a distance of 25 km with the app!',
            de: 'Du bist mit der App 25 km gefahren!',
            nb: 'Du kjørte en distanse på 25 km med appen!',
            fr: 'Vous avez parcouru une distance de 25 km avec l\'application !',
            sv: 'Du körde en sträcka på 25 km med appen!',
            pl: 'Z aplikacją przejechałeś dystans 25 km!',
            ja: 'あなたはアプリで50kmの距離を運転しました！',
            da: 'Du kørte en strækning på 25 km med appen!',
            vi: 'Bạn đã lái xe quãng đường 25 km với ứng dụng!',
            id: 'Anda berkendara sejauh 25 km dengan aplikasi!'
        },
        lockedKey: {
            de: 'Fahren Sie eine Distanz von 25 km, um dieses Abzeichen zu erhalten.',
            en: 'Drive a distance of 25 km to earn this badge.',
            ja: 'アプリ接続中の走行距離が​\n50kmに到達した場合に​\n獲得できるバッジ​\n（獲得上限：1回まで）',
            fr: 'Parcourez une distance de 25 km pour obtenir ce badge.',
            pl: 'Przejedź 25 km, aby zdobyć tę odznakę.',
            sv: 'Kör en sträcka på 25 km för att få detta märke.',
            da: 'Kør en strækning på 25 km for at opnå dette badge.',
            nb: 'Kjør en distanse på 25 km for å få dette merket.',
            id: 'Berkendara sejauh 25 km untuk mendapatkan lencana ini.',
            vi: 'Lái xe quãng đường 25 km để giành được huy hiệu này.'
        }
    }
};
const fiftyKm = {
    actionType: ActionTypeEnum.TRIP,
    comparisonOpType: ComparisonOpTypeEnum.GREATER,
    comparisonValueType: ComparisonValueTypeEnum.TOTAL_DISTANCE,
    comparisonValue: 50,
    name: '50 km',
    onceOnly: true,
    points: 300,
    score: 0,
    unlockLimit: '',
    lockedIcon: `${lockedUrl}50km.png`,
    unlockedIcon: `${unlockedUrl}50km.png`,
    translations: {
        nameKey: {
            en: '50 km',
            de: '50 km',
            da: '50 km',
            fr: '50 km',
            nb: '50 km',
            sv: '50 km',
            pl: '50 km',
            ja: '50 km',
            vi: '50 km',
            id: '50 km'
        },
        unlockedKey: {
            en: 'You drove a distance of 50 km with the app!',
            de: 'Du bist mit der App 50 km gefahren!',
            nb: 'Du kjørte en distanse på 50 km med appen!',
            fr: 'Vous avez parcouru une distance de 50 km avec l\'application !',
            sv: 'Du körde en sträcka på 50 km med appen!',
            pl: 'Z aplikacją przejechałeś dystans 50 km!',
            ja: 'あなたはアプリで50kmの距離を運転しました！',
            da: 'Du kørte en strækning på 50 km med appen!',
            vi: 'Bạn đã lái xe quãng đường 50 km với ứng dụng!',
            id: 'Anda berkendara sejauh 50 km dengan aplikasi!'
        },
        lockedKey: {
            de: 'Fahren Sie eine Distanz von 50 km, um dieses Abzeichen zu erhalten.',
            en: 'Drive a distance of 50 km to earn this badge.',
            ja: 'アプリ接続中の走行距離が​\n50kmに到達した場合に​\n獲得できるバッジ​\n（獲得上限：1回まで）',
            fr: 'Parcourez une distance de 50 km pour obtenir ce badge.',
            pl: 'Przejedź 50 km, aby zdobyć tę odznakę.',
            sv: 'Kör en sträcka på 50 km för att få detta märke.',
            da: 'Kør en strækning på 50 km for at opnå dette badge.',
            nb: 'Kjør en distanse på 50 km for å få dette merket.',
            id: 'Berkendara sejauh 50 km untuk mendapatkan lencana ini.',
            vi: 'Lái xe quãng đường 50 km để giành được huy hiệu này.'
        }
    }
};
const oneHundredKm = {
    actionType: ActionTypeEnum.TRIP,
    comparisonOpType: ComparisonOpTypeEnum.GREATER,
    comparisonValueType: ComparisonValueTypeEnum.TOTAL_DISTANCE,
    comparisonValue: 100,
    name: '100 km',
    onceOnly: true,
    points: 300,
    score: 0,
    unlockLimit: '',
    lockedIcon: `${lockedUrl}100km.png`,
    unlockedIcon: `${unlockedUrl}100km.png`,
    translations: {
        nameKey: {
            en: '100 km',
            de: '100 km',
            da: '100 km',
            fr: '100 km',
            nb: '100 km',
            sv: '100 km',
            pl: '100 km',
            ja: '100 km',
            vi: '100 km',
            id: '100 km'
        },
        lockedKey: {
            de: 'Fahren Sie 100 km zurück, um dieses Abzeichen zu erhalten.',
            fr: 'Conduisez une distance de 100 km pour gagner ce badge.',
            sv: 'Kör en sträcka på 100 km för att få detta märke.',
            en: 'Drive a distance of 100 km to earn this badge.',
            nb: 'Kjør en distanse på 100 km for å få dette merket.',
            pl: 'Przejedź 100 km, aby zdobyć tę odznakę.',
            ja: 'アプリ接続中の走行距離が​\n100kmに到達した場合に​\n獲得できるバッジ​\n（獲得上限：1回まで）',
            id: 'Berkendara sejauh 100 km untuk mendapatkan lencana ini.',
            da: 'Kør en strækning på 100 km for at opnå dette badge.',
            vi: 'Lái xe quãng đường 100 km để giành được huy hiệu này.'
        },
        unlockedKey: {
            de: 'Du bist mit der App 100 km gefahren!',
            fr: 'Vous avez parcouru une distance de 100 km avec l\'application !',
            ja: 'あなたはアプリで100kmの距離を\n運転しました！',
            nb: 'Du kjørte en strekning på 100 km med appen!',
            pl: 'Z aplikacją przejechałeś dystans 100 km!',
            en: 'You drove a distance of 100 km with the app!',
            sv: 'Du körde en sträcka på 100 km med appen!',
            id: 'Anda berkendara sejauh 100 km dengan aplikasi!',
            da: 'Du kørte en strækning på 100 km med appen!',
            vi: 'Bạn đã lái xe quãng đường 100 km với ứng dụng!'
        }
    }
};
const twoHundredKm = {
    actionType: ActionTypeEnum.TRIP,
    comparisonOpType: ComparisonOpTypeEnum.GREATER,
    comparisonValueType: ComparisonValueTypeEnum.TOTAL_DISTANCE,
    comparisonValue: 200,
    name: '200 km',
    onceOnly: true,
    points: 300,
    score: 0,
    unlockLimit: '',
    lockedIcon: `${lockedUrl}200km.png`,
    unlockedIcon: `${unlockedUrl}200km.png`,
    translations: {
        nameKey: {
            en: '200 km',
            de: '200 km',
            da: '200 km',
            fr: '200 km',
            nb: '200 km',
            sv: '200 km',
            pl: '200 km',
            ja: '200 km',
            vi: '200 km',
            id: '200 km'
        },
        lockedKey: {
            de: 'Fahren Sie 200 km zurück, um dieses Abzeichen zu erhalten.',
            fr: 'Conduisez une distance de 200 km pour gagner ce badge.',
            sv: 'Kör en sträcka på 200 km för att få detta märke.',
            en: 'Drive a distance of 200 km to earn this badge.',
            nb: 'Kjør en distanse på 200 km for å få dette merket.',
            pl: 'Przejedź 200 km, aby zdobyć tę odznakę.',
            ja: 'アプリ接続中の走行距離が​\n200kmに到達した場合に​\n獲得できるバッジ​\n（獲得上限：1回まで）',
            id: 'Berkendara sejauh 200 km untuk mendapatkan lencana ini.',
            da: 'Kør en strækning på 200 km for at opnå dette badge.',
            vi: 'Lái xe quãng đường 200 km để giành được huy hiệu này.'
        },
        unlockedKey: {
            de: 'Du bist mit der App 200 km gefahren!',
            fr: 'Vous avez parcouru une distance de 200 km avec l\'application !',
            ja: 'あなたはアプリで200kmの距離を\n運転しました！',
            nb: 'Du kjørte en strekning på 200 km med appen!',
            pl: 'Z aplikacją przejechałeś dystans 200 km!',
            en: 'You drove a distance of 200 km with the app!',
            sv: 'Du körde en sträcka på 200 km med appen!',
            id: 'Anda berkendara sejauh 200 km dengan aplikasi!',
            da: 'Du kørte en strækning på 200 km med appen!',
            vi: 'Bạn đã lái xe quãng đường 200 km với ứng dụng!'
        }
    }
};
const ecoFriend = {
    actionType: ActionTypeEnum.DAILY_SUMMARY,
    comparisonOpType: ComparisonOpTypeEnum.GREATER,
    comparisonValueType: ComparisonValueTypeEnum.CO2_SAVINGS,
    comparisonValue: 10,
    name: 'Eco Friend',
    onceOnly: false,
    points: 200,
    score: 0,
    timeType: TimeTypeEnum.WEEKLY,
    unlockLimit: '',
    lockedIcon: `${lockedUrl}ecofriend.png`,
    unlockedIcon: `${unlockedUrl}ecofriend.png`,
    translations: {
        nameKey: {
            en: 'Eco friend',
            da: 'Øko ven',
            nb: 'Øko venn',
            de: 'Umweltfreund',
            fr: 'Ami écolo',
            sv: 'Ekovän',
            pl: 'Eko przyjaciel',
            ja: 'CO2削減\nボーナス',
            id: 'teman lingkungan',
            vi: 'Bạn sinh thái'
        },
        lockedKey: {
            en: 'Drive eco friendly and save more than {co2value}% CO₂ in a week to earn this badge.',
            de: 'Fahren Sie umweltfreundlich und sparen Sie mehr als {co2value} CO₂ in einer Woche, um dieses Abzeichen zu erhalten.',
            nb: 'Kjør miljøvennlig og spar mer enn {co2value} CO₂ på en uke for å få dette merket.',
            da: 'Kør miljøvenligt, og spar mere end {co2value} CO₂ på en uge for at opnå dette badge.',
            fr: 'Conduisez de manière écologique et économisez plus de {co2value} CO₂ en une semaine pour gagner ce badge.',
            sv: 'Kör miljövänligt och spara mer än {co2value} CO₂ på en vecka för att få det här märket.',
            pl: 'Jedź ekologicznie i zaoszczędź ponad {co2value} CO₂ w ciągu tygodnia, aby zdobyć tę odznakę.',
            ja: '環境にやさしい運転をして\nCO₂排出量を{co2value}%削減した​\n運転を1週間続けることが​\nできた際に獲得できるバッジ',
            id: 'Dorong ramah lingkungan dan hemat lebih dari {co2value} CO₂ dalam seminggu untuk mendapatkan lencana ini.',
            vi: 'Lái xe thân thiện với môi trường và tiết kiệm hơn {co2value} CO₂ trong một tuần để kiếm được huy hiệu này.'
        },
        unlockedKey: {
            en: 'You have saved over {co2value}% CO₂ this week by driving eco friendly. Thank you for caring about the environment!',
            da: 'Du har sparet over {co2value}% CO₂ i denne uge ved at køre miljøvenligt. Tak fordi du passer på miljøet!',
            fr: 'Vous avez économisé plus de {co2value} % de CO₂ cette semaine en conduisant de manière écologique. Merci de vous soucier de l\'environnement!',
            nb: 'Du har spart over {co2value} % CO₂ denne uken ved å kjøre miljøvennlig. Takk for at du bryr deg om miljøet!',
            de: 'Sie haben diese Woche über {co2value} % CO₂ eingespart, indem Sie umweltfreundlich gefahren sind. Danke, dass Sie sich um die Umwelt kümmern!',
            sv: 'Du har sparat över {co2value}% CO₂ den här veckan genom att köra miljövänligt. Tack för att du bryr dig om miljön!',
            pl: 'Zaoszczędziłeś w tym tygodniu ponad {co2value}% CO₂, jeżdżąc w sposób przyjazny dla środowiska. Dziękujemy za troskę o środowisko!',
            id: 'Anda telah menghemat lebih dari {co2value}% CO₂ minggu ini dengan berkendara ramah lingkungan. Terima kasih telah peduli dengan lingkungan!',
            ja: 'この1週間で\n{co2value}%のCO₂排出を\n削減することができました。\n環境にやさしい\n運転をありがとうございます！',
            vi: 'Bạn đã tiết kiệm hơn {co2value}% CO₂ trong tuần này bằng cách lái xe thân thiện với môi trường. Cảm ơn bạn đã quan tâm đến môi trường!'
        }
    }
};
const superStar = {
    actionType: ActionTypeEnum.TRIP,
    comparisonOpType: ComparisonOpTypeEnum.GREATER,
    comparisonValueType: ComparisonValueTypeEnum.STAR_GRADE,
    comparisonValue: 3.5,
    name: 'Superstar',
    onceOnly: false,
    points: 10,
    score: 0,
    timeType: TimeTypeEnum.DAILY,
    unlockLimit: '',
    lockedIcon: `${lockedUrl}superstar.png`,
    unlockedIcon: `${unlockedUrl}superstar.png`,
    translations: {
        nameKey: {
            en: 'Superstar',
            fr: 'Superstar',
            nb: 'Superstjerne',
            da: 'Superstjerne',
            de: 'Superstar',
            ja: '安全運転',
            sv: 'Superstar',
            pl: 'Super gwiazda',
            vi: 'Siêu sao',
            id: 'superstar'
        },
        lockedKey: {
            en: 'Finish a drive at score {score} or better to unlock this badge. You can earn this badge once per day.',
            nb: 'Fullfør en kjøretur med score {score} eller bedre for å låse opp dette merket. Du kan få dette merket én gang per dag.',
            fr: 'Terminez un trajet avec un score de {score} ou mieux pour déverrouiller ce badge. Vous pouvez gagner ce badge une fois par jour.',
            da: 'Afslut en køretur med score {score} eller bedre for at låse denne badge op. Du kan optjene dette badge én gang om dagen.',
            de: 'Beende eine Fahrt bei Punktzahl {score} oder besser, um dieses Abzeichen freizuschalten. Sie können sich dieses Abzeichen einmal pro Tag verdienen.',
            ja: '1回の運転でスコアが{score}以上を​\n記録した場合に獲得できるバッジ​\n（獲得上限：1日1回まで）​',
            id: 'Selesaikan perjalanan dengan skor {score} atau lebih baik untuk membuka lencana ini. Anda bisa mendapatkan lencana ini sekali per hari.',
            pl: 'Zakończ jazdę z wynikiem {score} lub lepszym, aby odblokować tę odznakę. Możesz zdobyć tę odznakę raz dziennie.',
            vi: 'Hoàn thành lái xe ở điểm {score} hoặc cao hơn để mở khóa huy hiệu này. Bạn có thể kiếm được huy hiệu này một lần mỗi ngày.',
            sv: 'Avsluta en körning med poängen {score} eller bättre för att låsa upp det här märket. Du kan tjäna det här märket en gång per dag.'
        },
        unlockedKey: {
            nb: 'Du er en stjerne, {bruker}! Du fikk en poengsum over {score} i dag!',
            en: 'You are a star, {user}! You got a score above {score} today!',
            fr: 'Vous êtes une star, {utilisateur} ! Vous avez obtenu un score supérieur à {score} aujourd\'hui !',
            ja: '{user}さん、​\n今日も運転お疲れ様でした！​\n安全な運転により​\n3.5以上のスコアを​\n記録しました！',
            de: 'Du bist ein Star, {user}! Du hast heute eine Punktzahl über {score}!',
            da: 'Du er en stjerne, {bruger}! Du fik en score over {score} i dag!',
            pl: 'Jesteś gwiazdą, {user}! Masz dzisiaj wynik powyżej {driveScore}!',
            id: 'Anda adalah seorang bintang, {pengguna}! Anda mendapat skor di atas {score} hari ini!',
            sv: 'Du är en stjärna, {user}! Du fick ett resultat över {score} idag!',
            vi: 'Bạn là một ngôi sao, {user}! Bạn đã đạt điểm trên {score} hôm nay!'
        }
    }
};
const worldClass = {
    actionType: ActionTypeEnum.TRIP,
    comparisonOpType: ComparisonOpTypeEnum.GREATER,
    comparisonValueType: ComparisonValueTypeEnum.STAR_GRADE,
    comparisonValue: 4.4,
    name: 'World Class',
    onceOnly: false,
    points: 40,
    score: 0,
    timeType: TimeTypeEnum.DAILY,
    unlockLimit: '',
    lockedIcon: `${lockedUrl}worldclass.png`,
    unlockedIcon: `${unlockedUrl}worldclass.png`,
    translations: {
        nameKey: {
            en: 'World Class',
            fr: 'Classe mondiale',
            de: 'Weltklasse',
            da: 'Verdens klasse',
            sv: 'Världsklass',
            pl: 'Klasa światowa',
            id: 'Kelas dunia',
            vi: 'Đẳng cấp thế giới',
            ja: '安全運転\nプロ',
            nb: 'Verdensklasse'
        },
        lockedKey: {
            en: 'Finish a drive at score {score} or better to unlock this badge. You can earn this badge once per day.',
            fr: 'Terminez un trajet avec un score de {score} ou mieux pour déverrouiller ce badge. Vous pouvez gagner ce badge une fois par jour.',
            de: 'Beende eine Fahrt bei Punktzahl {score} oder besser, um dieses Abzeichen freizuschalten. Sie können sich dieses Abzeichen einmal pro Tag verdienen.',
            id: 'Selesaikan perjalanan dengan skor {score} atau lebih baik untuk membuka lencana ini. Anda bisa mendapatkan lencana ini sekali per hari.',
            sv: 'Avsluta en körning med poängen {score} eller bättre för att låsa upp det här märket. Du kan tjäna det här märket en gång per dag.',
            pl: 'Zakończ jazdę z wynikiem {score} lub lepszym, aby odblokować tę odznakę. Możesz zdobyć tę odznakę raz dziennie.',
            da: 'Afslut en køretur med score {score} eller bedre for at låse denne badge op. Du kan optjene dette badge én gang om dagen.',
            vi: 'Hoàn thành lái xe ở điểm {score} hoặc cao hơn để mở khóa huy hiệu này. Bạn có thể kiếm được huy hiệu này một lần mỗi ngày.',
            ja: '1回の運転でスコアが{score}以上を​\n記録した場合に獲得できるバッジ​\n（獲得上限：1日1回まで）',
            nb: 'Fullfør en kjøretur med score {score} eller bedre for å låse opp dette merket. Du kan få dette merket én gang per dag.'
        },
        unlockedKey: {
            de: 'Du bist großartig {user}! Du hast heute eine Punktzahl über {score}!',
            fr: 'Vous êtes incroyable {user} ! Vous avez obtenu un score supérieur à {score} aujourd\'hui !',
            en: 'You are amazing {user}! You got a score above {score} today!',
            id: 'Anda luar biasa {user}! Anda mendapat skor di atas {score} hari ini!',
            sv: 'Du är fantastisk {user}! Du fick ett resultat över {score} idag!',
            pl: 'Jesteś niesamowity {user}! Masz dzisiaj wynik powyżej {score}!',
            da: 'Du er fantastisk {user}! Du fik en score over {score} i dag!',
            vi: 'Bạn thật tuyệt vời {user}! Bạn đã đạt điểm trên {score} hôm nay!',
            ja: 'あなたは素晴らしい{user}です！今日は{score}を超えるスコアを獲得しました。',
            nb: 'Du er fantastisk {user}! Du fikk en poengsum over {score} i dag!'
        }
    }
};
const co2saver = {
    actionType: ActionTypeEnum.TRIP,
    comparisonOpType: ComparisonOpTypeEnum.GREATER,
    comparisonValueType: ComparisonValueTypeEnum.CO2_SAVINGS,
    comparisonValue: 10,
    name: 'CO₂ Saver',
    onceOnly: false,
    points: 10,
    score: 0,
    timeType: TimeTypeEnum.DAILY,
    unlockLimit: '',
    lockedIcon: `${lockedUrl}co2saver.png`,
    unlockedIcon: `${unlockedUrl}co2saver.png`,
    translations: {
        nameKey: {
            fr: 'Économiseur de CO₂',
            nb: 'CO₂-sparer',
            en: 'CO₂ Saver',
            sv: 'CO₂-sparare',
            da: 'CO₂-besparelse',
            pl: 'CO₂ Oszczędność',
            vi: 'CO₂ Saver',
            de: 'CO₂-Sparer',
            ja: 'CO₂削減',
            id: 'Penghemat CO'
        },
        lockedKey: {
            fr: 'Conduisez de manière écologique et économisez plus de {co2value} CO₂ par trajet pour gagner ce badge.',
            nb: 'Kjør miljøvennlig og spar mer enn {co2value} CO₂ per reise for å få dette merket.',
            en: 'Drive eco friendly and save more than {co2value}% CO₂ per trip to earn this badge.',
            sv: 'Kör miljövänligt och spara mer än {co2value} CO₂ per resa för att få det här märket.',
            vi: 'Lái xe thân thiện với môi trường và tiết kiệm hơn {co2value} CO₂ mỗi chuyến đi để kiếm được huy hiệu này.',
            pl: 'Jedź ekologicznie i zaoszczędź ponad {co2value} CO₂ na podróż, aby zdobyć tę odznakę.',
            da: 'Kør miljøvenligt og spar mere end {co2value} CO₂ pr. rejse for at opnå dette badge.',
            de: 'Fahren Sie umweltfreundlich und sparen Sie mehr als {co2value} CO₂ pro Fahrt, um dieses Abzeichen zu erhalten.',
            id: 'Berkendara ramah lingkungan dan hemat lebih dari {co2value} CO₂ per perjalanan untuk mendapatkan lencana ini.',
            ja: '環境にやさしい運転をして​\nCO₂排出量を{co2value}％削減できた際​\nに獲得できるバッジ'
        },
        unlockedKey: {
            nb: 'Flott jobb! Du har spart over {co2value} % CO₂ denne turen ved å kjøre miljøvennlig.',
            fr: 'Bon travail! Vous avez économisé plus de {co2value} % de CO₂ pendant ce voyage en conduisant de manière écologique.',
            en: 'Great job! You saved over {co2value}% CO₂ this trip by driving eco friendly.',
            sv: 'Bra jobbat! Du sparade över {co2value}% CO₂ den här resan genom att köra miljövänligt.',
            vi: 'Bạn đã làm rất tốt! Bạn đã tiết kiệm hơn {co2value}% CO₂ cho chuyến đi này bằng cách lái xe thân thiện với môi trường.',
            id: 'Kerja bagus! Anda menghemat lebih dari {co2value}% CO₂ perjalanan ini dengan berkendara ramah lingkungan.',
            ja: 'CO₂排出を{co2value}%\n削減することが\nできました。',
            pl: 'Dobra robota! Zaoszczędziłeś ponad {co2value}% CO₂ podczas tej podróży, jeżdżąc w sposób przyjazny dla środowiska.',
            de: 'Gut gemacht! Sie haben bei dieser Fahrt über {co2value} % CO₂ eingespart, indem Sie umweltfreundlich gefahren sind.',
            da: 'Godt arbejde! Du sparede over {co2value} % CO₂ denne tur ved at køre miljøvenligt.'
        }
    }
};
export default [
    influencer,
    safeDriverWeekly,
    twentyFiveKm,
    fiftyKm,
    oneHundredKm,
    twoHundredKm,
    ecoFriend,
    superStar,
    worldClass,
    co2saver
];

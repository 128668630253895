import { txt } from '@/translations/translations';
import { DirectiveBinding } from 'vue/types/options';
import _Vue from 'vue';

// @ts-ignore
window.activeTranslations = {};

interface LocalizeValue {
  key: string;
  [key: string]: string;
}

interface LocaleBinding extends DirectiveBinding {
  value: string | LocalizeValue;
}
// Observable to keep track of locale in app
let userLocale = _Vue.observable('en');
let returnTextIfNoMatch = false;

function keyToText(key: string): string {
  return localize(key, {key});
}

// Localize directive parameter object
const locDirective = {
  updateText(el: HTMLElement, binding: LocaleBinding | DirectiveBinding) {
    if (!binding.expression) {
      return;
    }
    const listOfKeys = Object.keys(binding.value);
    const bindingValue = binding.value;
    if (typeof bindingValue === 'object') {
      let newStr: string = localize(binding.value.key, {key: binding.value.key});
      listOfKeys.map((element) => {
        if (element !== 'key') {
          newStr = newStr.replace(`{${element}}`, bindingValue[element]);
          // const replace: string = `{{[${element}]*}}`;
          // const regex: RegExp = new RegExp(replace, 'g');
          // newStr = newStr.replace(regex, bindingValue[element]);
        }
      });
      el.innerHTML = newStr;
    } else if (typeof binding.value === 'string') {
      const res = keyToText(binding.value);
      if (res) {
        el.innerHTML = res;
      }
    }
  },
  // When the bound element is inserted into the DOM...
  inserted(el: HTMLElement, binding: LocaleBinding | DirectiveBinding): void {
    returnTextIfNoMatch = binding.arg ? true : false;
    locDirective.updateText(el, binding);
  }
};

// translation filter function
function filterTranslateKey(value: string) {
  return localize(value, {key: value});
}

// translation global function
function localize(
  val: any,
  params: { [key: string]: string } | undefined = undefined
) {
  const translationkey = 'txt_global_std_' + val;
  let locale = userLocale;

  if (!txt.hasOwnProperty(locale)) {
    locale = 'en'; // fallback
  }

  if (!(txt as any)[locale].hasOwnProperty(translationkey)) {
    return returnTextIfNoMatch ? val : '';
  }

  let text = txt[locale][translationkey];
  if (params) {
    for (const param of Object.keys(params)) {
      text = text.replace(`{${param}}`, params[param]);
    }
  }

  // save active translations to window object
  if (window.self !== window.top) {
    // @ts-ignore
    window.activeTranslations[translationkey] = text;
  }
  return text;
}
// Clear Non-active translations from window object
function cleanActiveTranslations() {
  const txt = document.body.textContent;
  // @ts-ignore
  for (const [key, value] of Object.entries(window.activeTranslations)) {
    // @ts-ignore
    if (txt.indexOf(value) < 0) {
      // @ts-ignore
      delete window.activeTranslations[key];
    }
  }
}
setInterval(cleanActiveTranslations, 5000);
export { locDirective, filterTranslateKey, localize };
export default {
  install(Vue: typeof _Vue): void {
    Vue.directive('localize', locDirective);
    Vue.filter('localize', filterTranslateKey);

    Vue.prototype.$localize =  (value: string, params: any) => {
      returnTextIfNoMatch = false;
      return localize(value, params);
    };
    Vue.prototype.$localizeNoMatch = (value: string, params: any ) => {
      returnTextIfNoMatch = true;
      return localize(value, params);
    };
    Object.defineProperty(Vue.prototype, '$userLocale', {
      get() {
        // Switch language manually
        if (!localStorage.getItem('userLocale')) {
          const browserLang = navigator.language.split('-')[0];
          switch (browserLang) {
            case 'en':
              userLocale = 'en';
              break;
            case 'ja':
              userLocale = 'ja';
              break;
            case 'fr':
              userLocale = 'fr';
              break;
            case 'de':
              userLocale = 'de';
              break;
            case 'es':
              userLocale = 'es';
              break;
            default:
              userLocale = 'en';
              break;
          }
          // browserLang === 'ja' ? (userLocale = 'ja') : (userLocale = 'en');
          localStorage.setItem('userLocale', userLocale);
        }
        return userLocale;
      },
      set(locale: string) {
        userLocale = locale;
        localStorage.setItem('userLocale', userLocale);
      }
    });
  }
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr',{staticClass:"hr"}),_c('div',{staticClass:"form"},[_c('h2',{directives:[{name:"localize",rawName:"v-localize",value:({ key: 'changepassword' }),expression:"{ key: 'changepassword' }"}]}),_c('p',{directives:[{name:"localize",rawName:"v-localize",value:({ key: 'changeyourpassword' }),expression:"{ key: 'changeyourpassword' }"}]}),_c('validation-observer',{ref:"observer",staticClass:"passwordValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":_vm.$localize('password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"password",attrs:{"outlined":"","error-messages":errors,"label":_vm.$localize('password'),"type":_vm.showCurrent ? 'text' : 'password',"append-icon":_vm.showCurrent ? 'far fa-eye' : 'far fa-eye-slash login-eye-icon',"required":""},on:{"click:append":function($event){_vm.showCurrent = !_vm.showCurrent}},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$localize('password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"outlined":"","error-messages":errors,"label":_vm.$localize('newpassword'),"type":_vm.showNew ? 'text' : 'password',"append-icon":_vm.showNew ? 'far fa-eye' : 'far fa-eye-slash login-eye-icon',"required":""},on:{"click:append":function($event){_vm.showNew = !_vm.showNew}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$localize('password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"outlined":"","error-messages":errors,"label":_vm.$localize('repeatyourpassword'),"type":_vm.showConfirm ? 'text' : 'password',"append-icon":_vm.showConfirm ? 'far fa-eye' : 'far fa-eye-slash login-eye-icon',"required":""},on:{"click:append":function($event){_vm.showConfirm = !_vm.showConfirm}},model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}})]}}],null,true)}),_c('div',{staticClass:"footer"},[_c('v-btn',{attrs:{"type":"submit","disabled":invalid,"color":"primary","depressed":""}},[_vm._v(" "+_vm._s(_vm._f("localize")('changepassword'))+" ")]),_c('br')],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
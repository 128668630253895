import { app04, grouphandler, account } from '../utils/http';
import { ResetPassword, ChangePassword } from '@/types/user';

const login = (username: string, password: string) => {
  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  params.append('grant_type', 'password');
  app04.defaults.headers['X-Auth'] = ''; // Empty, we do not want to specify specific permissions
  return app04.post('/v2/Token', params);
};
const logout = () => {
  return app04.post('/v2/Account/Logout');
};
const getTeams = () => {
  return grouphandler.get(`/Team`);
};
const getRoles = () => {
  return app04.get('v2/Roles');
};
const resetPassword = (rp: ResetPassword) => {
  return app04.put('v2/Account/Password', rp);
};
const changePassword = (chP: ChangePassword) => {
  return app04.post('/v2/Account/ChangePassword', chP);
};
const getSideBarComponents = () => {
  return grouphandler.get('/Sidebar');
};
const getUserGlobalSideBar = () => {
  return account.get('/UserGlobalSideBar');
};
function getUserInfo() {
  return app04.get('/GetMyProfile');
}
const getSession = () => {
  const params = new URLSearchParams();
  params.append('grant_type', 'generate_session');

  return app04.post('/v2/Token', params);
};

const refresh = (refreshToken: string, alk: string = '') => {
  const params = new URLSearchParams();
  params.append('refresh_token', refreshToken);
  params.append('grant_type', 'refresh_token');
  if (alk) { app04.defaults.headers['X-Auth'] = alk; }
  return app04.post('/v2/Token', params);
};
const validatePassword = (username: string, password: string) => {
  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  return app04.post('/login', params);
};

const getAgents = () => {
  return grouphandler.get(`/Team`);
};

export default {
  login,
  logout,
  getRoles,
  resetPassword,
  changePassword,
  getSideBarComponents,
  getUserGlobalSideBar,
  getTeams,
  getUserInfo,
  getSession,
  refresh,
  validatePassword,
  getAgents
};

import { translationhandler } from '@/utils/http';
import { IResponseObject } from './misc';

enum TranslationApiUrls {
    translate = '/translate'
}

export const getTranslations = async (languages: string[], value: string | undefined = ''): Promise<IResponseObject> => {
    if (value === '') {
        return { data: '', error: null };
    }
    try {
        const { status, data } = await translationhandler.post(
            TranslationApiUrls.translate, { languages, value }
        );

        if (status !== 200) {
            throw new Error();
        }
        return { data: data[0], error: null };
    } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error);
        return { data: null, error: 'Somthing went wrong when translating!' };
    }
};

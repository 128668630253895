import { GetterTree } from 'vuex';
import { RootState } from '../rootState';
import { IconStatus } from './enums';
import { IAchievmentDefault } from './types';

export enum AchievementGetters {
    getLicenseKey = 'achievement/getLicenseKey',
    getState = 'achievement/getState',
    getPoints = 'achievement/getPoints',
    isOnceOnly = 'achievement/isOnceOnly',
    getLockedIcon = 'achievement/getLockedIcon',
    getUnlockedIcon = 'achievement/getUnlockedIcon',
    getImageUrlBadgePreview = 'achievement/getImageUrlBadgePreview',
    getName = 'achievement/getName',
    getGroup = 'achievement/getGroup',
    getKey = 'achievement/getKey',
    getLockedIconStatus = 'achievement/getLockedIconStatus',
    getUnlockedIconStatus = 'achievement/getUnlockedIconStatus'
}
export const getters: GetterTree<IAchievmentDefault, RootState> = {
    getState(state) {
        return { ...state };
    },
    getLicenseKey(state): string | undefined {
        return state.licenseKey;
    },
    getName(state): string | undefined {
        return state.name;
    },
    getGroup(state): number | undefined {
        return state.group;
    },
    getKey(state): number | undefined {
        return state.key;
    },
    getPoints(state): number | undefined {
        return state.points;
    },
    getLockedIcon(state): Blob | string | undefined {
        return state.lockedIcon;
    },
    getUnlockedIcon(state): Blob | string | undefined {
        return state.unlockedIcon;
    },
    isOnceOnly(state): boolean | undefined {
        return state.onceOnly;
    },
    getUnlockLimit(state): number | undefined {
        return state.unlockLimit;
    },
    getBackgroundColor(state): string | undefined {
        return state.backgroundColor;
    },
    getIsTemplate(state): boolean | undefined {
        return state.isTemplate;
    },
    getLockedIconStatus(state): IconStatus {
        return state.lockedIconStatus;
    },
    getUnlockedIconStatus(state): IconStatus {
        return state.unlockedIconStatus;
    }
};

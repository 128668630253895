import { Module } from 'vuex';
import { RootState } from '../rootState';
import { IAchievmentDefault } from './types';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { getDefaultState } from './initialState';

const state: IAchievmentDefault = getDefaultState();

export const achievement: Module<IAchievmentDefault, RootState> = {
    state,
    actions,
    mutations,
    getters,
    namespaced: true
};

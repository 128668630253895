import {
  AddSubscriptionItemRequest,
  CheckoutSession, NewSubscriptionRequest,
  PortalSession,
  PortalSessionRequest,
  Product,
  StripeCustomer,
  StripeSession,
  SubscriptionItem
} from '@/types/stripeCustomer';
import { localHost, paymentApi } from '../utils/http';

const http = paymentApi;

// Shady ugly temporary solution to find out the environment - should be added to pipes, env. files etc
const host = window.location.host;
const STAGING_OR_DEV = host.includes('localhost') || host.includes('staging');

/*
  * As it says on the tin, this is where we add a user as a customer on the Stripe side. This is primarily done after a user has finished an onboarding process.
  * @param { interface } StripeCustomer an interface that has no required variables. It contains the basic information that stripe needs to add a customer to database.
  * @returns { axios res } a server response confirming whether the user was successfuly added as a customer
*/
const addStripeCustomer = (payload: StripeCustomer) => {
  let agent: any = sessionStorage.getItem('agent');
  let alk = null;
  if (agent) {
    agent = JSON.parse(agent);
    alk = agent && agent.licenseKey;
    http.defaults.headers.malk = alk;
  }
  return http.post(`customer/${STAGING_OR_DEV}`, payload);
};

/*
  * This is where we add a subscription to a customer on the Stripe side. Also done in the onboarding process. To access modules, a customer needs a subscription.
  * @param { interface } NewSubscriptionRequest an interface that has these required variables:
  *   @param { string } paymentToken The token used for tracking the transaction
  *   @param { string } customerId The customer's unique ID number
  *   @param { string[] } priceIds  With stripe you add specific prices to a customer's subscription. Stripe will then handle these as the "things" that a customer owns.
  *                                 These prices are attached to products, so products can have multiple prices.
  *                                 This is an array of those prices that we are putting in to the subscription.
  *   @params { strings } successUrl/cancelUrl The interface will at some point require a response in the form of these URLs.
  *                                             "success" if the request went well, "cancel" otherwise.
  *   This interface is built to contain and handle everything that comes up during a subscription request.
  * @returns { axios res } A server response confirming whether the subscription was successfully added.
*/
const addStripeSubscription = (payload: NewSubscriptionRequest) => {
  let agent: any = sessionStorage.getItem('agent');
  let alk = null;
  if (agent) {
    agent = JSON.parse(agent);
    alk = agent && agent.licenseKey;
    http.defaults.headers.malk = alk;
  }
  return http.post(`subscription/${STAGING_OR_DEV}`, payload);
};

/*
  * This is for adding prices to an already existing subscription for an already existing customer.
  * @param { interface } AddSubscriptionItemRequest an interface that has these required variables:
  *   @param { string } subscriptionId The subscription that we are adding products to.
  *   @param { string[] } priceIds Same as before, we add prices to subscriptions and that's how we keep track of what's in them.
  * It contains the basic information that stripe needs to add prices to a subscription.
  * @returns { axios res } a server response confirming whether the user was successfuly added as a customer
*/
const addProductsToSubscription = (payload: AddSubscriptionItemRequest) => {
  let agent: any = sessionStorage.getItem('agent');
  let alk = null;
  if (agent) {
    agent = JSON.parse(agent);
    alk = agent && agent.licenseKey;
    http.defaults.headers.malk = alk;
  }
  return http.post<SubscriptionItem[]>(`addsubscriptionitem/${STAGING_OR_DEV}`, payload);
};

/*
  * @param { string[] } productIDs Unique identifiers for the products we are interested in
  * @returns { axios res } Containing more extensive information
*/
const fetchStripeProducts = (productIds: string[]) => {
  return http.post<Product[]>(`products/${STAGING_OR_DEV}`, productIds);
};

/*
  * @param { string[] } productIDs Unique identifiers for the products we are interested in
  * @returns { axios res } Containing more extensive information for said products
*/
const fetchStripeCustomer = (alk: string) => {
    return http.get<StripeCustomer>(`customer/${alk}/${STAGING_OR_DEV}`);
};

/*
  * @param { NewSubscriptionRequest } payload A part of adding subscriptions is creating this type of session
  * @returns { axios res } Containing success status and data with a session ID
*/
const createPaymentSession = (payload: NewSubscriptionRequest) => {
    return http.post<CheckoutSession>(`checkoutsession/${STAGING_OR_DEV}`, payload);
};

/*
  * @param { string } sessionId Unique identifiers for the session that we are interested in
  * @returns { axios res } Containing more extensive information
*/
const fetchPaymentSession = (sessionId: string) => {
  return http.get<StripeSession>(`checkoutsession/${sessionId}/${STAGING_OR_DEV}`);
};

/*
  * This is used to open a "portal" into Stripe itself, letting customers browse various products
  * @param { PortalSessionRequest } payload An interface that has these required variables:
  *   @param { string } customerId The customer who wants to see products available to them
  *   @param { string } returnUrl The URL that will take the user back to where they came from
  * @returns { axios res } Containing more extensive information that will help push the user through the portal
*/
const fetchPortalSession = (payload: PortalSessionRequest) => {
    return http.post<PortalSession>(`customersession/${STAGING_OR_DEV}`, payload);
  };

export default {
    addStripeCustomer,
    fetchStripeCustomer,
    createPaymentSession,
    fetchPaymentSession,
    fetchPortalSession,
    fetchStripeProducts,
    addStripeSubscription,
    addProductsToSubscription
  };

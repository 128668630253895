import { MutationTree } from 'vuex';
import {
    ActionTypeEnum,
    ComparisonOpTypeEnum,
    ComparisonValueTypeEnum,
    IconStatus,
    TimeTypeEnum
} from './enums';
import { getDefaultState } from './initialState';
import { IAchievmentDefault } from './types';

export enum AchievementMutations {
    setLicenseKey = 'achievement/setLicenseKey',
    setName = 'achievement/setName',
    setGroup = 'achievement/setGroup',
    setKey = 'achievement/setKey',
    setPoints = 'achievement/setPoints',
    setLockedIcon = 'achievement/setLockedIcon',
    setUnlockedIcon = 'achievement/setUnlockedIcon',

    setOnceOnly = 'achievement/setOnceOnly',
    setUnlockLimit = 'achievement/setUnlockLimit',

    setScore = 'achievement/setScore',
    setComparisonValue = 'achievement/setComparisonValue',
    setComparisonOpType = 'achievement/setComparisonOpType',
    setComparisonValueType = 'achievement/setComparisonValueType',
    setTimeType = 'achievement/setTimeType',

    setJsonUrl = 'achievement/setJsonUrl',
    setAtlasUrl = 'achievement/setAtlasUrl',
    setImageUrl = 'achievement/setImageUrl',
    setBackgroundColor = 'achievement/setBackgroundColor',

    setActionType = 'achievement/setActionType',
    resetState = 'achievement/resetState',

    setPrefefinedValues = 'achievement/setPrefefinedValues',
    setIsTemplate = 'achievement/setIsTemplate',

    setLockedIconStatus = 'achievement/setLockedIconStatus',
    setUnlockedIconStatus = 'achievement/setUnlockedIconStatus'
}

export const mutations: MutationTree<IAchievmentDefault> = {
    setLicenseKey(state, payload: string): void {
        state.licenseKey = payload;
    },
    setName(state, payload: string): void {
        state.name = payload;
    },
    setGroup(state, payload: number): void {
        state.group = payload;
    },
    setKey(state, payload: number): void {
        state.key = payload;
    },
    setPoints(state, payload: number): void {
        state.points = payload;
    },
    setLockedIcon(state, payload: Blob | string): void {
        state.lockedIcon = payload;
    },
    setUnlockedIcon(state, payload: Blob | string): void {
        state.unlockedIcon = payload;
    },
    setOnceOnly(state, payload: boolean): void {
        state.onceOnly = payload;
        if (payload) {
            state.unlockLimit = 1;
        }
    },
    setUnlockLimit(state, payload: number): void {
        state.unlockLimit = payload;
    },
    setScore(state, payload: number): void {
        state.score = payload;
    },
    setComparisonValue(state, payload: number): void {
        state.comparisonValue = payload;
    },
    setComparisonValueType(state, payload: ComparisonValueTypeEnum): void {
        state.comparisonValueType = payload;
    },

    setComparisonOpType(state, payload: ComparisonOpTypeEnum): void {
        state.comparisonOpType = payload;
    },
    setTimeType(state, payload: TimeTypeEnum): void {
        state.timeType = payload;
    },
    setJsonUrl(state, payload: string): void {
        state.jsonUrl = payload;
    },
    setAtlasUrl(state, payload: string): void {
        state.atlasUrl = payload;
    },
    setImageUrl(state, payload: string): void {
        state.imageUrl = payload;
    },
    setBackgroundColor(state, payload: string): void {
        state.backgroundColor = payload;
    },
    setActionType(state, payload: ActionTypeEnum): void {
        state.actionType = payload;
    },
    setIsTemplate(state, payload: boolean): void {
        state.isTemplate = payload;
    },
    resetState(state): void {
        Object.assign(state, getDefaultState());
    },
    setPrefefinedValues(state, payload): void {
        Object.assign(state, getDefaultState());
        Object.assign(state, payload);
    },
    setLockedIconStatus(state, payload: IconStatus): void {
        state.lockedIconStatus = payload;
    },
    setUnlockedIconStatus(state, payload: IconStatus): void {
        state.unlockedIconStatus = payload;
    }
};

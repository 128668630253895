import { MutationTree } from 'vuex';
import { WhiteLabelState, WhiteLabelTheme } from './types';

export enum WhiteLabelMutations {
  setThemes = 'whitelabel/setThemes'
}

export const mutations: MutationTree<WhiteLabelState> = {
  setThemes(state, payload: WhiteLabelTheme): void {
    state.themes = payload;
  }

};

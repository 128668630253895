import { Locale } from '@/store/user/types';
import { locales } from '@/utils/locales';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true,
      roles: [
        'User',
        'Admin',
        'Developer',
        'SuperAdmin',
        'EnterpriseUser',
        'EnterpriseAdmin'
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings.vue')
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPassword.vue')
  },
  {
    path: '/HandleAchievement',
    name: 'HandleAchievement',
    component: () => import('@/views/achievements/HandleAchievement.vue')
  }
];
// This will create a string containing only the  values from locales. Each value will be separeted with '|' exept for the last one.
// The result would look something like this: en|ja|fr
// The perks of using this is that if locales.ts changes we dont have to manuelly update the router prepend step with the new languages
const langs = locales.reduce((result: string, item: Locale, index: number): string =>  {
  return index === locales.length - 1 ? result + item.value : result + item.value + '|';
}, '');

// Prepend all routes with the user locale
const router = new VueRouter({
  routes: routes.map((r) => {
    r.path = `/:lang(${langs})?${r.path}`;
    return r;
  })
});

router.beforeEach(function(to, from, next) {
  const userRoles: string[] = JSON.parse(sessionStorage.getItem('roles')!);
  const routerRoles = to.meta?.roles as string[];

  let { lang } = to.params;
  let prepend = false;
  if (!lang) {
    lang = router.app.$userLocale || 'en';
    prepend = true;
  }

  if (router.app.$userLocale !== lang && lang) {
    router.app.$userLocale = lang;
  }

  if (
    to.name === 'Login' &&
    sessionStorage.getItem('access_token') &&
    !to.query.access_token
  ) {
    next(false);
  } else if (to.name === 'ResetPassword') {
    next();
  } else if (
    to.name !== 'Login' &&
    !sessionStorage.getItem('access_token') &&
    !sessionStorage.getItem('refresh_token')
  ) {
    next(`${lang}/login`);
  } else {
    if (prepend) {
      next({
        ...to,
        path: `/${lang}${to.path}`,
        params: { ...to.params, lang }
      } as any);
    } else {
      next();
    }
  }
});

export default router;

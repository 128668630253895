import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueGtag from 'vue-gtag';
import '@fortawesome/fontawesome-pro/css/all.css';

import translationsEngine from '@/plugins/translationsEngine';

Vue.use(translationsEngine);

import '@/utils/filters';

import toast from '@/plugins/toast';
Vue.use(toast, { vuetify, target: '.v-main__wrap' }); // Must pass vuetify

import eventbus from '@/plugins/eventbus';
Vue.use(eventbus);

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config:
    {
      id: 'G-G70D3GKP7B'
    }
});

// Global observable loading
const loading = Vue.observable({ state: false });

Object.defineProperty(Vue.prototype, '$loading', {
  get() {
    return loading.state;
  },
  set(value: boolean) {
    loading.state = value;
  }
});

import { GlobalPopup } from './types/popups';
// Global observable popup
const popup: GlobalPopup =
  Vue.observable({ component: null, props: {}, active: false });

Object.defineProperty(Vue.prototype, '$popup', {
  get() {
    return popup;
  },
  set(value: GlobalPopup) {
    popup.component = value.component;
    popup.props = value.props;
    popup.attrs = value.attrs;
    popup.active = value.active || value.component !== null;
  }
});

Vue.prototype.$mediaUrl = process.env.VUE_APP_MEDIAURL;
// To show extra fields etc for developers, put ! before the condition => !(process.env....)
Vue.prototype.$inStagingOrProdMode = (process.env.VUE_APP_TARGETED_SESSION === 'staging' || process.env.VUE_APP_TARGETED_SESSION === 'production') as boolean;
import '@/directives/overlay';

document.title = 'Achievements - Enerfy Global';

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');

import { MenuItem } from '@/types/menu';
import { setToken, removeToken } from '@/utils/headers';
import { MutationTree } from 'vuex';
import { MiscState } from './types';

export enum MiscMutation {
  setMenuItems = 'misc/setMenuItems',
  setShowLoading = 'misc/setShowLoading'
}
export const mutations: MutationTree<MiscState> = {
  setMenuItems(state, payload: MenuItem[]): void {
    state.MenuItems = payload;
  },
  setShowLoading(state, payload: boolean): void {
    state.showLoading = payload;
  }
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"your-achievement-table-wrapper default-card"},[_c('div',{staticClass:"tabel-tabs-wrapper"},[_c('div',{directives:[{name:"localize",rawName:"v-localize",value:({ key: 'alltabtext' }),expression:"{ key: 'alltabtext' }"}],staticClass:"tabel-tab tabel-tab-active pa-5",on:{"click":function($event){return _vm.selectTab(_vm.tabs.enums.ALL, $event)}}}),_c('div',{directives:[{name:"localize",rawName:"v-localize",value:({ key: 'templatestabtext' }),expression:"{ key: 'templatestabtext' }"}],staticClass:"tabel-tab pa-5",on:{"click":function($event){return _vm.selectTab(_vm.tabs.enums.TEMPLATE, $event)}}}),_c('div',{directives:[{name:"localize",rawName:"v-localize",value:({ key: 'customtabtext' }),expression:"{ key: 'customtabtext' }"}],staticClass:"tabel-tab pa-5",on:{"click":function($event){return _vm.selectTab(_vm.tabs.enums.CUSTOM, $event)}}})]),_c('v-divider',{staticClass:"divider"}),_c('v-container',{staticClass:"px-5 pt-0",staticStyle:{"min-width":"100%"}},[_c('div',{staticClass:"d-flex pa-0",staticStyle:{"max-width":"50%"}},[_c('FilterAchievements'),_c('v-text-field',{attrs:{"outlined":"","dense":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fa fa-search")])]},proxy:true},{key:"label",fn:function(){return [_c('span',{directives:[{name:"localize",rawName:"v-localize",value:({ key: 'searchbartitleandpointstext' }),expression:"{ key: 'searchbartitleandpointstext' }"}]})]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"items":_vm.filteredItems,"headers":_vm.tableHeader,"search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[item.disableItemActions && 'disableActions']},[_c('HandleAchievementPopup',{attrs:{"action":"publishUnpublish","item":item}})],1)]}},{key:"item.unlockedIcon",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"img-nameKey-container"},[_c('v-img',{staticClass:"circle",attrs:{"max-height":"40","max-width":"40","src":item.unlockedIcon}})],1)]}},{key:_vm.getNameKeySlot,fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getLocalizedText(item))+" ")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"localize",rawName:"v-localize",value:({ key: item.onceOnly ? 'uniquecategory' : 'collectablecategory' }),expression:"{ key: item.onceOnly ? 'uniquecategory' : 'collectablecategory' }"}]})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{class:['d-flex', item.disableItemActions && 'disableActions']},[_c('v-btn',{attrs:{"text":"","icon":"","disabled":_vm.disableEdit(item)},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-pen")])],1),_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('HandleAchievementPopup',{attrs:{"action":"delete","item":item}})]},proxy:true}],null,true)},[[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa fa-ellipsis ")])],1)]],2)],1),(item.disableItemActions)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"#F57A70","dark":""}},'v-icon',attrs,false),on),[_vm._v("fa fa-circle-exclamation ")])]}}],null,true)},[_c('span',{directives:[{name:"localize",rawName:"v-localize",value:({ key: 'disableachievementtooltiptext' }),expression:"{ key: 'disableachievementtooltiptext' }"}]})]):_vm._e()],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
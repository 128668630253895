import { Module } from 'vuex';
import { StripeState } from './types';
import { RootState } from '../rootState';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const state: StripeState = {
    Customer: {},
    SdcProductId: 'prod_LUhvexYqUV6CA3',
    SdcTestProductId: 'prod_LUhvexYqUV6CA3',
    BusinessProductId: 'prod_LUhwHGqQv4hUYk',
    BusinessTestProductId: 'prod_LfW42pD0HriWhy',
    Products: [],
    Subscription: null
};

export const stripe: Module<StripeState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};

<template>
  
    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
  
</template>
<script>
export default {
  props: ['overlay'],
  data: () => ({
    absolute: true
  })
};
</script>

import api from '@/api/user';
import { ActionTree } from 'vuex';
import { RootState } from '../rootState';
import { AxiosResponse } from 'axios';
import { LoginResponse, RolesResponse, ResetPassword, ChangePassword} from '@/types/user';
import { UserState } from './types';

export enum UserActions {
  login = 'user/login',
  logout = 'user/logout',
  getAgents = 'user/getAgents',
  checkPassword = 'user/checkPassword',
  getRoles = 'user/getRoles',
  resetPassword = 'user/resetPassword',
  setDistanceUnit = 'user/setDistancDistanceUniteUnit',
  changePassword = 'user/changePassword',
  getTeams = 'user/getTeams',
  getUserInfo = 'user/getUserInfo',
  getSession = 'user/getSession',
  refresh = 'user/refresh',
  updateDivision = 'user/updateDivision'
}

export const actions: ActionTree<UserState, RootState> = {
  async refresh({commit}, refreshToken: string): Promise<any> {
    try {
      refreshToken = sessionStorage.getItem(
        'refresh_token'
      ) as string;
      let res: AxiosResponse<LoginResponse>;
      res = await api.refresh(refreshToken);
      const tokens = { access_token: res.data.access_token, refresh_token: res.data.refresh_token };
      commit('setTokens', tokens);
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getAgents({ commit }) {
    const res: AxiosResponse<any> = await api.getAgents();
    if (!res.data) {
      commit('setAgents', null);
      return null;
    } else {
      commit('setAgents', res.data[0]);
    }
    return res.data[0];
  },
  async updateDivision({commit}, payload): Promise<any> {
    try {
      const refreshToken: string = sessionStorage.getItem('refresh_token') as string;
      const res: AxiosResponse<LoginResponse> = await api.refresh(refreshToken, payload.licenseKey);
      const tokens = { access_token: res.data.access_token, refresh_token: res.data.refresh_token };
      commit('setTokens', tokens);
      commit('setAgents', payload);
      return res;
    } catch (e) {
      return Promise.reject(e);
    }

  },
  async login({commit}, payload): Promise<any> {
    const res = api.login(payload.username, payload.password);
    const promise = Promise.resolve(await res);

    promise.then(json => {
      commit('setUser', json.data.user);
      const tokens = { access_token: json.data.access_token, refresh_token: json.data.refresh_token };
      commit('setTokens', tokens);
    });
    return await res;
  },
  async getSession({ }): Promise<any> {
    try {
      const res = await api.getSession();
      const tokens = { access_token: res.data.access_token, refresh_token: res.data.refresh_token };
      return tokens;
    } catch (e) {
      await Promise.reject(e);
    }
  },
  async logout({ commit }): Promise<any> {
    try {
      await api.logout();
      commit('logout');
    } catch (e) {
      commit('logout');
      await Promise.reject(e);
    }
  },
  async getRoles({ commit }): Promise<any> {
    try {
      let res: AxiosResponse<RolesResponse>;
      res = await api.getRoles();
      commit('setUserRoles', res.data.roles);
      return res.data;
    } catch (e) {
      commit('logout');
      await Promise.reject(e);
    }
  },
  async getUserInfo({ commit }): Promise<any> {
    try {
      const res = await api.getUserInfo();
      if (res.data?.errors?.length && res.data?.errors?.length > 0) {
        return false;
      }
      commit('setUser', res.data);
      return true;
    } catch (e) {
      await Promise.reject(e);
    }
  },
  async resetPassword({}, resetPassword: ResetPassword): Promise<any> {
    try {
      let res: AxiosResponse<any>;
      res = await api.resetPassword(resetPassword);
      return res;
    } catch (e) {
      await Promise.reject(e);
    }
  },

  async changePassword({}, changePassword: ChangePassword): Promise<any> {
    return await api.changePassword(changePassword);
  },

  async checkPassword({ }, password: string): Promise<any> {
    let passwordValidated = false;
    // We set NewPassword and ConfirmPassword as any password that's 6 of the same kind of symbol. In this case we use six lowercase letters: "enerfy".
    const checkPassword: ChangePassword = {
      NewPassword: 'enerfy',
      OldPassword: password,
      ConfirmPassword: 'enerfy'
    };
    // With this we have "stacked the deck" so that the first error we will generate on an incorrect password is "incorrect password"
    await api.changePassword(checkPassword)
    .catch((err) => {
      // Hence the error "incorrect password" will only happen if an incorrect pasword is used
      if (err.ModelState[''][0] === 'Incorrect password.') {
        passwordValidated = false;
      } else {
        // This will only happen if we generate an error related to password formatting, meaning that the password was correct
        passwordValidated = true;
      }
    });
    return passwordValidated;
  },

  async getTeams({commit}): Promise<any> {
    try {
      const res = await api.getTeams();
      commit('setAgents', res.data[0]);
      return res.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
};

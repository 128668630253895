import { User } from '@/types/user';
import { setToken, removeToken } from '@/utils/headers';
import { MutationTree } from 'vuex';
import { UserState } from './types';

export enum UserMutations {
  setUser = 'user/setUser',
  setTokens = 'user/setTokens',
  logout = 'user/logout',
  setUserRoles = 'user/setUserRoles',
  setIsLoggingIn = 'user/setIsLoggingIn',
  setSignInStatus = 'user/setSignInStatus'
}

const saveSessionTokens = (accessToken: string, refreshToken: string): void => {
  sessionStorage.setItem('access_token', accessToken);
  sessionStorage.setItem('refresh_token', refreshToken);
};

export const mutations: MutationTree<UserState> = {
  setUser(state, payload: User): void {
    state.user = payload;
    sessionStorage.setItem('user', JSON.stringify(payload));
  },
  setUserRoles(state, payload: string[]) {
    state.roles = payload;
    sessionStorage.setItem('roles', JSON.stringify(payload));
  },
  setAgents(state, payload: any): void {
    state.agent = payload;
    sessionStorage.setItem('agent', JSON.stringify(payload));
  },
  setTokens(state, payload): void {
    state.access_token = payload.access_token;
    state.refresh_token = payload.refresh_token;
    saveSessionTokens(payload.access_token, payload.refresh_token);
  },
  setIsLoggingIn(state, payload: boolean): void {
    state.isLoggingIn = payload;
  },
  logout(state) {
    sessionStorage.clear();
    localStorage.clear();
    removeToken();
    state.user = undefined;
  }
};

import Vue from 'vue';
import { RootState } from './rootState';
import { user } from './user';
import { misc } from './misc';
import { toolbar } from './toolbar';
import { whitelabel } from './whitelabel';
import { stripe } from './stripe';
import { achievement } from './achievement';
import { achievementMisc } from './achievementMisc';
import { translation } from './translation';
import Vuex, { StoreOptions } from 'vuex';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    modules: {
        user,
        misc,
        toolbar,
        whitelabel,
        stripe,
        achievement,
        achievementMisc,
        translation
    }
};
export default new Vuex.Store<RootState>(store);

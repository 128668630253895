import { Module } from 'vuex';
import { MiscState } from './types';
import { RootState } from '../rootState';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

// Vue wont be able to attach watchers to properties in state if default values are not set
const state: MiscState = {
  MenuItems: undefined,
  showLoading: false
};

export const misc: Module<MiscState, RootState> = {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};

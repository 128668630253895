import api from '@/api/user';
import { ActionTree } from 'vuex';
import { RootState } from '../rootState';
import { AxiosResponse } from 'axios';
import { LoginResponse, RolesResponse, ResetPassword, ChangePassword } from '@/types/user';
import { MiscState } from './types';
import { GetMenuItemsResponse } from '@/types/miscResponses';
import { MenuItem } from '@/types/menu';

export enum MiscActions {
    getMenuItems = 'misc/getMenuItems'
}

export const actions: ActionTree<MiscState, RootState> = {
    /*
    * This is an axios function that will fetch, sort, commit and return the product modules that are on Stripe
    * @returns { MenuItem[] } sortedRes This is the modules that GT will offer users, sorted according their index from Stripe
    */
    async getMenuItems({ commit }): Promise<any> {
        try {
            let res: AxiosResponse;
            res = await api.getUserGlobalSideBar();
            for (const item of res.data.active) {
                item.index = Number(item.index);
            }

            let resCombined: MenuItem[];
            resCombined = res.data.active
              .sort((a: MenuItem, b: MenuItem) => (a.index < b.index) ? -1 : 1);

            const sortedRes = resCombined
              .concat(res.data.inActive
                .sort((a: MenuItem, b: MenuItem) => (a.index < b.index) ? -1 : 1));

            commit('setMenuItems', sortedRes);
            return sortedRes;
        } catch (e) {
            return Promise.reject(e);
        }
    }
};

export const txt: { [key: string]: { [key: string]: string; } } = {
  en: {
    txt_global_std_areyoudrivinganelectricorobd: 'Are you driving an electric vehicle or have in-vehicle devices installed? ',
    txt_global_std_nodrivingdata: 'No driving data',
    txt_global_std_HusseinAbdullaFacts: 'Adel has won the FIA CCR T2 World Championship with Sebastian Delaunay as a Co-driver. In his first full season of cross-country competition, Adel Hussein claimed wins in Portugal, Abu Dhabi and Spain during a successful campaign in the world’s toughest off-road championship.',
    txt_global_std_gotoleaderboard: 'Go to leaderboard',
    txt_global_std_inordertoconnecttoyourcartheincludedobd: 'In order to connect to your car, the included OBD device, the Enerfy plug-in, must be installed in your vehicle - or - if your vehicle has the option to connect your phone via Bluetooth directly, the Enerfy app has to be running in your phone while driving.',
    txt_global_std_p2pfaq20a: '1. Select "Drive" from the "Menu" to check the latest driving data and evaluation.\n' +
      '2. Select specific driving data to see the details of the feedback.',
    txt_global_std_p2pcancelled: 'Cancelled',
    txt_global_std_togetthecorrectstatsofyourdrivingyouneedtogototheappsettingsinyourphoneand: 'To get the correct stats of your driving, you need to go to the app settings in your phone and choose Allow Always in location services.',
    txt_global_std_minimum5characters: 'Minimum 5 characters',
    txt_global_std_inactivateapp: 'Inactivate App',
    txt_global_std_noinsurancesfound: 'No insurances found',
    txt_global_std_letstrythatagain: "Let's try that again!",
    txt_global_std_apr: 'Apr',
    txt_global_std_HSolbergMusic: 'POP and rock music that gives enthusiastic spirit in my blood.',
    txt_global_std_youhaveatotalcostof: 'You have a total cost of',
    txt_global_std_challengedetails: 'Challenge details:',
    txt_global_std_driversname: "Driver's Name",
    txt_global_std_tomasengemileage: 'I always love all roads which leads to my home, to my family',
    txt_global_std_yourinsurancehasbeenrenewed: 'Your insurance has been renewed.',
    txt_global_std_now: 'Now',
    txt_global_std_p2pviewandpay: 'View and pay',
    txt_global_std_p2pnamecancelledname2sbooking: '{name} cancelled {name2}´s booking',
    txt_global_std_telephoneclaims: 'Telephone Claims',
    txt_global_std_emailalreadyusedpleasetrywithanotheremail: 'Email already used. Please try with another email.',
    'txt_global_std_Al-KuwariBesttip': 'Concentration and try to enjoy the drive.',
    txt_global_std_newtext: 'New text',
    txt_global_std_p2pemailhello: 'Hello',
    txt_global_std_carmodelform: 'Car model: ',
    txt_global_std_sendreferrallink: 'Send referral link',
    txt_global_std_failedtoaddusers: 'Failed to add users',
    txt_global_std_feedback: 'Feedback',
    txt_global_std_hoponinletsdrivesmart: 'Hop on in lets drive smart',
    txt_global_std_JyrkiainenChamp: 'Best rookie driver in Finnish Championship 2016',
    txt_global_std_hellogreattoseeyou: 'Hello, great to see you!',
    txt_global_std_p2pmembersinthisgroup: 'Members in this group',
    txt_global_std_couldnotjoinevent: 'Could not join event.',
    txt_global_std_cantfindyourcaryet: "Can't find your car yet!",
    txt_global_std_allusershavesuccessfullycompletedthisstep: 'All users have successfully completed this step.',
    txt_global_std_forgotyourpassword1: 'Forgot your password?',
    txt_global_std_paddonDescription: 'Hayden has started driving go-karts at the age of 6. Lived and breathed motorsport all his life.',
    txt_global_std_security: 'Security',
    txt_global_std_welcometoapp: 'Welcome to {app}!',
    txt_global_std_adjustyouryearlymileage: 'Adjust your yearly mileage',
    txt_global_std_easilykeeptrackofyourcostandco2emissionsintheapp: 'Easily keep track of your cost and CO2 emissions in the app.',
    txt_global_std_p2ppleasechooseexpensetype: 'Please choose expense type',
    txt_global_std_electriccar: 'Electric car?',
    txt_global_std_someoneelseispairedtoyourcar: '• Someone else is paired to your car.',
    txt_global_std_moreattributes: 'More attributes',
    txt_global_std_disableachievementtooltiptext1: "This achievement was created without the Achievement Tool. <br> Therefore, it can't currently be altered in this tool.",
    txt_global_std_atheappwilltrackyourscoresandprovidearatebasedonyourdrivinghabitsyouwillgetthefirstmonthlyratethatisbasedonyourdrivingafteryourfirstsixmonthperiodyourratewillthenchangeonamonthlybasisbasedonyourscoreanddrivinghabitsyouwillreceiveupdatesaftereachtripadvisingyouofyourpotentialsavingsorifyouneedtoimproveyourdrivingscoretoearnadiscount: 'A: The app will track your scores and provide a rate based on your driving habits. You will get the first monthly rate that is based on your driving after your first six-month period. Your rate will then change on a monthly basis based on your score and driving habits. You will receive updates after each trip advising you of your potential savings or if you need to improve your driving score to earn a discount.',
    txt_global_std_notsupported: 'Not supported',
    txt_global_std_inviteafriendorfamilytoborrow: 'Invite a friend or family member to borrow your {appname} car',
    txt_global_std_400badrequest: '400 - Bad request',
    txt_global_std_TaxInvoice4: 'Currency/Exch Rate',
    txt_global_std_someoneelseispairedtoyourcaryourbluetoothbeacon: '• Someone else is paired to your car/your bluetooth beacon\n',
    txt_global_std_f_lanzinterests: 'Adventure Climbing, River Rafting, Ziplining, Trail Running, Dancing , Wild Nature Explorer',
    txt_global_std_password2: 'Password',
    txt_global_std_v2sdcscore: 'Score',
    txt_global_std_trytheappfunctionisnotavailableindemomode: '"Try the app" function is not available in demo mode',
    txt_global_std_leaderboard_tab_daily: 'Daily',
    txt_global_std_days: 'Days',
    txt_global_std_youcanuploadmaximum9photos: 'You can upload maximum 9 photos',
    txt_global_std_connectwithobdreader: 'Connect with OBD reader',
    txt_global_std_ongoinghead2headchallenges: 'Ongoing Head 2 Head challenges',
    txt_global_std_easytogetstartedrequestyourzipriskindexviaourapi: 'Easy to get started, request your Zip risk index via our API.',
    txt_global_std_avglevel: 'Avg. level',
    txt_global_std_idnumber: 'Id number',
    txt_global_CoreTemplate_enter: 'Enter',
    txt_global_std_startsin: 'Starts in',
    txt_global_std_retrive: 'Retrieve',
    txt_global_std_ok: 'Ok',
    txt_global_std_questionsaboutinsurance: 'Questions about the insurance',
    txt_global_std_PaddonChamp: 'PWRC Champion in 2011, 4 Wins New Zealand Rally Championship (2008, 2009, 2013, 2018). Won Rally of Argentina in 2016.',
    txt_global_std_saveyourcar: 'Save your car',
    txt_global_std_selectteam: 'Select team',
    txt_global_std_ayouwillbenotifiedintheappwhenitstimetorenewyourinsuranceifyoudonthaveautomaticrenewalsetupfollowthestepsintheapptoensureyouhavecoverageforthefollowingmonth: 'A: You will be notified in the app when it’s time to renew your insurance. If you don’t have automatic renewal set up, follow the steps in the app to ensure you have coverage for the following month.',
    txt_global_std_globalteams: 'Global Teams',
    txt_global_std_mystatsthismonth: 'My stats this month',
    txt_global_std_downloadinglatestversionofapp: 'We are now downloading the latest versions of all applications. Please bare with us, it might take up to a minute.',
    txt_global_std_renewedfor: 'Renewed for',
    txt_global_std_countryroadintersectionDESC: 'The blue car driver is planned and focused and drives smoothly through the crossing.\n' +
      'The red car driver does not adjust their speed to the driving situation and often needs to use harsh brakes.',
    txt_global_std_tomasengebesttip: 'To focus on driving as much as possible is a key to successfully reach your destination',
    txt_global_std_thisensuresyourcarisstilltrackedbyloyaltysystem: 'This ensures your car is still tracked by loyalty system.',
    txt_global_std_nestgroup: 'Nest group',
    txt_global_std_replay: 'Replay',
    txt_global_std_thisisaskilleddriver: 'This is a skilled driver, however, sometimes during a trip and only for a short period of time, this driver tend to lose focus on Cool Driving. Some distractions or stress may cause this driver to ignore Safety awareness. This is often fixed by talking with the driver and ask for his consideration. ',
    txt_global_std_p2ptypeamessage: 'Type a message',
    txt_global_std_PolicySchedule20: 'NCD',
    txt_global_std_establishingobddatalink: 'Establishing OBD data link',
    txt_global_std_clickrefreshbuttoninapptotryagain: 'Click refresh button in app to try again.',
    txt_global_std_bad2: 'Bad',
    txt_global_std_addlogo: 'Add logo',
    txt_global_std_whendrivingonhigherspeedroadsplanningismoreimportantthaneverenteringahighwaytooslowlycancausedangertofellowmotoristsandleadtoqueuesbehindyouobservingthetrafficflowandadaptingyourspeedtotheconditionsisimportanttoavoidunnecessaryqueuesandpanicbrakingnew: 'When driving on higher speed roads, planning is more important than ever. Entering a highway too slowly can cause danger to fellow motorists and lead to queues behind you. Observing the traffic flow and adapting your speed to the conditions is important to avoid unnecessary queues and panic braking.',
    txt_global_std_howdoiterminatemyinsurance: 'How do I terminate my insurance?',
    txt_global_std_manageparticipants: 'Manage participants',
    txt_global_std_MSIG_InsuranceLetter19: 'Persons or classes of persons entitled to drive*',
    txt_global_std_tryouttrygdrive: 'Try the app',
    txt_global_std_onceyouhavecompletedachallengeitwillappearhere: 'Once you have completed a challenge, it will appear here.',
    txt_global_std_daysdayshourshoursminutesminutes: '{days} Days {hours} Hours {minutes} Minutes',
    txt_global_std_p2psendinvite: 'Send invite',
    txt_global_std_pickstarttime: 'Pick start time',
    txt_global_std_setupyourprofiletocontinue: 'Set-up your profile to continue',
    txt_global_std_riskindex_upsell: 'Risk Index – access the bigger picture to understand global trends in driving risk.  ',
    txt_global_std_teammembers: 'Team members:',
    txt_global_std_KleinschmidtBesttip: "I find it important to foresee the drive ahead, so you don't need to break, accelerate, break again all the time.",
    txt_global_std_newmobilitycompany: 'New Mobility company',
    txt_global_std_statsforthistrip: 'Stats for this trip',
    txt_global_std_cimage24d: 'Find RPM that gives lowest fuel consumption at speed.',
    txt_global_std_hoursshort: 'h',
    txt_global_std_warikan_onboarding19: ' If the button is not displayed, please download KINTO directly from the App Store or Google Play.',
    txt_global_std_anewaccountwillbecreated: 'A new account will be created',
    txt_global_std_joinname: 'Join {appName}',
    txt_global_std_restrictedtopeoplewithinmyorganization: '(restricted to people within my organization)',
    txt_global_std_youraveragelevelthismonthisthebasisforyourdiscountyoureceivethediscountwhenyourenewyourinsurancefortheupcomingmonths: 'Your average level this month is the basis for your discount. You receive the discount when you renew your insurance for the upcoming months',
    txt_global_std_units: 'Units',
    txt_global_std_listoffunctions: 'List of functions',
    txt_global_std_p2pviewyourbookingsandblockyourcarwhenyouwanttomakeitunavailable: 'View your bookings and block your car when you want to make it unavailable.',
    txt_global_std_viewactivationcode: 'View Activation Code',
    txt_global_std_p2psendyourcode: 'Send your friend code',
    txt_global_std_whenyouactivateyourinsurance: 'When you activate your insurance, you will get information about it here',
    txt_global_std_teamleaderscore: 'Teamleader score',
    txt_global_std_results: 'Results',
    txt_global_std_choosefromlibrary: 'Choose from library',
    txt_global_std_distancefromparking: 'Distance from parking and start of trip',
    txt_global_std_youhavebeenchallengedtoahead2head: 'You have been challenged to a head to head. Open the app and accept it now!',
    txt_global_std_wanttochallengeanewfriend: 'Want to challenge a new friend?',
    txt_global_std_currentodometervalueinkm: 'Enter your current odometer value (km)',
    txt_global_std_HansenMileage: 'I really fancy a good long trip if I can share the ride with my family or friends. ',
    txt_global_std_countryroad3DESC: 'The blue car driver maintains a slow pace to drive focused and smoothly.\n' +
      '\n' +
      'The red car driver does not plan the driving and accelerates/brakes unnecessarily often.',
    txt_global_std_collectpointsbyearningbadgeseverybadgeisworthdifferentamountofpointstapabadgetolearnmoreaboutit: 'Collect points by earning badges. Every badge is worth different amount of points. Tap a badge to learn more about it.',
    txt_global_std_getstarted: 'Get started',
    txt_global_std_challenges: 'Challenges',
    txt_global_std_may: 'May',
    txt_global_std_documentation2: 'Documentation',
    txt_global_std_alreadyhaveanenerfypluginclickhere: 'Already have an Enerfy plug-in? Click here!',
    txt_global_std_drivercpproverview: 'Driver crash pattern profiling report - overview',
    txt_global_std_likeourcarsharingpage: 'Like our Facebook page',
    txt_global_std_youearnedapercentagediscount: 'You earned a {percentage}% discount',
    txt_global_std_uploadyourcompanylogo: 'Upload your company logo',
    txt_global_std_pushneverdrivenbody2: 'Get started with {appname}, connect to your car next time you are going for a drive!',
    txt_global_std_kintofaq10q: 'How do you measure my driving?',
    txt_global_std_p2ptotaltosplit: 'Total to split:',
    txt_global_std_worldranking: 'World ranking',
    txt_global_std_yourinsuranceisrenewedrenewaldate: 'Your insurance is renewed {renewaldate}.',
    txt_global_std_setupyourcompanychallenge: 'Set up your company Workspace',
    txt_global_std_companychallenge: 'Company challenge',
    txt_global_std_ConfirmFia_Text11: 'It’s less than 24 hours left to your scheduled time slot at the FIA Smart Driver Challenge test drive in Paris!',
    txt_global_std_clickonthesettingsbelowtochangepermission: 'Click on the settings below to change permission',
    txt_global_std_starttheengineandopentheapp: 'Start the engine and open the app.',
    txt_global_std_supportedfileformats: 'Supported file formats',
    txt_global_std_demotext3: 'See how much you have driven in total.',
    txt_global_std_savedimagesmarkedwitha: 'Saved images marked with a',
    txt_global_std_makesureengineisrunning: 'Make sure engine is running.',
    txt_global_std_havealreadybeeninapp: 'Already an user',
    txt_global_std_unpublishachievementpopuptext: 'When you unpublish an achievement, it will be removed for all users who have not yet unlocked it. If a user has already received it they will still have it among their other achievements. If you have not yet received it, it will be removed when unpublishing.',
    txt_global_std_KleinschmidtWhyjoin: 'Because we are the coolest team and we have lots of fun.',
    txt_global_std_risklevel: 'Risk level',
    txt_global_std_driveabitmoretogetscore: 'Drive a bit more to get a score',
    txt_global_std_updatedtranslation: 'Updated Translation',
    txt_global_std_countyears: '{count} years',
    txt_global_std_insurance: 'Insurance',
    txt_global_std_shownewactivities: 'Show news activities',
    txt_global_std_startachallenge: 'Start a challenge',
    txt_global_std_discipline: 'Discipline',
    txt_global_std_competition: 'Competition',
    txt_global_std_notsignedupforsompoclimateheroinsuranceyet: 'Not signed up for Sompo Climate Hero insurance yet?',
    txt_global_std_pleaserefreshthepage: 'Please refresh the page',
    txt_global_std_hereyoustartadrivemanuallywhenyouhavefinished: 'Here you start a drive manually. When you have finished your drive you click stop drive to get your score. We use GPS to give you a score. If you drive in to a tunnel or a parking garage, your drive can be affected.',
    txt_global_std_p2pborrowedtime: 'Borrowed time',
    txt_global_std_collision123: 'Collision',
    txt_global_std_atleast5characters: 'At least 5 characters',
    txt_global_std_p2plendyou: 'lend you',
    txt_global_std_signup: 'Sign up',
    txt_global_std_calculating: 'Calculating',
    txt_global_std_30daysavg: '30 days avg.',
    txt_global_std_pleaseverifyyourenerfyglobalemailadress: 'Please verify your Enerfy Global email adress',
    txt_global_std_p2pmorefunctions: 'More functions',
    txt_global_std_scantheqrcodetoaccessthechallenge: 'Scan the QR code to access the challenge.',
    txt_global_std_man: 'Man',
    txt_global_std_sharelink1: 'Share link',
    txt_global_std_refresh: 'Refresh',
    txt_global_std_easilymanagecommunication: 'Easily manage communication in all channels. Some of the messages are automated for the best customer journey. Build and customize your own or choose between existing emails, text messages, push notifications and in-app notifications.',
    txt_global_std_sep: 'Sep',
    txt_global_std_p2perrorcodebookingduplicaterequest: 'You have already sent a request for this time.',
    txt_global_std_p2pborrowtext: '{name} wants to borrow your {vehicle}',
    txt_global_std_youwillearnachievements: 'you will earn achievements',
    txt_global_std_mysavings: 'My savings',
    txt_global_std_besuretoaddfourmainpicturesofyourcarfrontleftrightandbacksidesasshowninthediagramsbelow: 'Be sure to add four main pictures of your car (front, left, right, and back sides) as shown in the diagrams below.',
    txt_global_std_creatingyourprofile: 'Creating your profile',
    txt_global_std_sorrytheimagerecognitionfailedpleaseentertheodometervaluemanually: 'Sorry, the image recognition failed. Please enter the odometer value manually.',
    txt_global_std_alreadyhaveanaccountlogin: 'Already have an account? Log in',
    txt_global_std_PolicySchedule33: 'Address',
    txt_global_std_TomczykInterests: 'Classic cars, all kind of sports',
    txt_global_std_p2psafedriverbonusnotincluded: 'Safedriver bonus not included',
    txt_global_std_takesomephotosofyourcar: 'Take some photos of your car',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriendsthedriverwiththebestscorewinstheround: 'Compete in your everyday driving against your friends. The driver with the best score wins the round!',
    txt_global_std_p2pemailcancelledbookings: 'Cancelled bookings',
    txt_global_std_whatisapayasyoudriveinsurance: 'What is a pay as you drive insurance?',
    txt_global_std__log_in: 'Log in',
    txt_global_std_gobackandfix: 'Go back and fix',
    txt_global_std_allyear: 'All year',
    txt_global_std_world: 'World',
    txt_global_std_leftuntilrenewal: 'Left until renewal',
    txt_global_std_policynumberalreadyexists: 'Policy number already exists',
    txt_global_std_buyandinsuranceandstartsavingmoneynow: 'Buy an insurance and start saving money now',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint2: 'Uncover new insights to enable targeted action',
    txt_global_std_oct: 'Oct',
    txt_global_std_coupons: 'Coupons',
    txt_global_std_okaywewillremindyoulater: 'Okay, we will remind you later',
    txt_global_std_successfullypublishedreviewedtranslations: 'Successfully Published Reviewed translations',
    txt_global_std_p2pyouhavenobooking: 'You have no booking requests',
    txt_global_std_warikan_onboarding5: '1. The owner KINTO ONE contractor (customer) downloads the app',
    txt_global_std_koscore: 'Eco',
    txt_global_std_editprofilepicture: 'Edit profile picture',
    txt_global_std_deactivated: 'Deactivated',
    txt_global_std_timesincelogin: 'Time since login, parked etc',
    txt_global_std_selectyourobddevicefromthelistobdmini2: 'Select your OBD device from the list: OBD Mini 2',
    txt_global_std_enterthenrofdigitsdigitcodeorclickonthelinksenttophonenr: 'Enter the {nrOfDigits} - digit code or click on the link sent to: {phoneNr}',
    txt_global_std_howtoplay: 'How to play',
    txt_global_std_kintofaq7a: 'No problem! Request a BT-OBD dongle and it will do the magic! Simply connect the dongle to the cable and plug it into your 12v charger in the car.  Then follow the “Connect to car” steps as normal and chose the name “Enerfy OBD” during the bluetooth connection.',
    txt_global_std_senddetails: 'Send details',
    txt_global_std_youwillreceiveanemailwithyourpersonaldata: 'You will receive an email with your personal data',
    txt_global_std_ihaveanenerfypluginhowdoiconnectitinmycar: 'I have an Enerfy Plug-in, how do i plug it in my car?',
    txt_global_std_thepasswordhastobe8charactersorlongerandmustinclude1numeralandspecialcharacter: 'The password has to be 8 characters or longer, and must include 1 numeral and special character',
    txt_global_std_enddate: 'End date',
    txt_global_std_p2ppushbodylendingcomingup: 'Please note that "{name}" will lend your car. Starts "{start}"',
    txt_global_std_inviteyournetworktostartdrivingsmarter: 'Invite your network to start driving smarter!',
    txt_global_std_calender: 'Calender',
    txt_global_std_p2pnocancelledbookingrequests: 'No cancelled booking requests',
    txt_global_std_itwillexpireshortlysomakesuretoverifysoonremembertocheckyourspamfolder: 'It will expire shortly, so make sure to verify soon. Remember to check your spam folder.',
    txt_global_std_me: 'Me',
    txt_global_std_connectingtoyourcar: 'Connecting to your car',
    txt_global_std_ttest94: 'this is a test',
    txt_global_std_alerthighdivisiontext: 'Warning! Your driving style generates high cost per kilometer.',
    'txt_global_std_Al-KhelaifiQuote': 'Quote coming soon...',
    txt_global_std_p2ppushbodyexpensesdenied: "{name} didn't approve the expense",
    txt_global_std_canitrytheappbeforesigningup: 'Can I try the app before signing up?',
    txt_global_std_PolicySchedule24: 'GST',
    txt_global_std_kintoloyaltysystem: 'The loyalty system',
    txt_global_std_download: 'Download',
    txt_global_std_youareinthetop5keepgoing: 'You are in the top 5, Keep going!',
    txt_global_std_buttogetthecorrectstatsofyourdrivingyouneedtogotoappsettingsinyourphoneandchoosebalwaysb: '...but to get the correct stats of your driving, you need to go to app settings in your phone and choose <b>Always.</b>',
    txt_global_std_p2ppushbodyrevokeadmin: 'Your admin rights for "{vehicle}" has been revoked by {name}.',
    txt_global_std_filteredon: 'Filtered on',
    txt_global_std_smallscratches: 'Small scratches?',
    txt_global_std_yourinsurancewillberenewedonmonthdayyear: 'Your insurance will be renewed on {month} {day}, {year}',
    txt_global_std_placeholdertext: 'Placeholder text',
    txt_global_std_thisisaskilledinconsistentdriver: 'This is a skilled driver, however, sometimes during a trip and only for a short period of time, this driver tend to lose focus on Cool Driving. Some distractions or stress may cause this driver to ignore Safety awareness. This is often fixed by talking with the driver and ask for his consideration. ',
    txt_global_std_lockedtitle: 'Locked',
    txt_global_std_chooseallowonce: 'Choose Allow Once',
    txt_global_std_connectionwasabortedbeforethedrivewasvalid: 'Connection was aborted before the drive was valid, drive will be discarded',
    txt_global_std_preparing: 'Preparing...',
    txt_global_std_usermustacceptaninvitationtotheteamfirst: 'User must accept an invitation to the team first.',
    txt_global_std_p2pfaq7q: 'How can I cancel a booking?',
    txt_global_std_drivingdatatroubleshooting: 'Driving data trouble shooting',
    txt_global_std_time: 'Time',
    txt_global_std_continuetosetup: 'Continue to setup',
    txt_global_std_fleetcompany: 'Fleet company',
    txt_global_std_p2pleavegroupchat: 'Leave group chat',
    txt_global_std_acceptuserterms: 'Accept user terms',
    txt_global_std_selecttype2: 'Select type',
    txt_global_std_mustbeavalidsizeandcanonlyincludenumbersand: 'Must be a valid size and can only include numbers and +',
    txt_global_std_aiexplainer: 'AI-Explainer',
    txt_global_std_start: 'Start',
    txt_global_std_MSIG_Onboarding12: 'MS First Capital Insurance Ltd',
    txt_global_std_HSolbergChamp: 'Won 5 times the Norwegian Championship (1999-2003)',
    txt_global_std_createanaccountoruseexistingone: 'Create an account or use existing one',
    txt_global_std_youwonyourh2hchallenge: 'You won your Head2Head challenge vs. {user}!',
    txt_global_std_Inviteafriedbothwillgetawarded: 'Invite a friend and both of you will get awarded!',
    txt_global_std_burglary123: 'Burglary',
    txt_global_std_apioverview: 'API overview',
    txt_global_std_whenthechallengeisaccepteditwillshowuponthestartpage: 'When the challenge is accepted it will show up on the start page.',
    txt_global_std_kintofaq10a: "The Kinto app uses artificial intelligence to measure your driving. The app connects to the cloud and will analyse every second of your driving- turned into “a profile”. The AI is comparing your profiles to over 650 million other unique driving profiles. The AI has over 16 years of experience to look for a driving pattern's probability to be involved in an accident. Luckily, after almost every trip, driver and passengers return home safe to their loved ones.",
    txt_global_std_theft: 'Theft?',
    txt_global_std_PolicySchedule26: 'NIL EXCESS FOR INSURED AND AUTHORISED DRIVERS',
    txt_global_std_PolicySchedule9: 'Tonnage/CC',
    txt_global_std_letmeshowyouhoweverythingworks: 'Let me show you how everything works here!',
    txt_global_std_disableachievementtooltiptext: "This achievement was created without the achievement tool. <br> Therefore, it can't currently be altered in this tool.",
    txt_global_std_apikeymanager: 'API KEY MANAGER',
    txt_global_std_howlongwillittaketoprocessmyclaim: 'How long will it take to process my claim?',
    txt_global_std_warikan_onboarding3: 'Please download the Warikan KINTO app from the URL below.\n',
    txt_global_std_100km: '100 Km',
    txt_global_std_save: 'Save',
    txt_global_std_searchbynameoraccounttype: 'Search by name or account type',
    txt_global_std_whendrivingonhigherspeedroadsplanningismoreimportantthaneverenteringahighwaytooslowlycancausedangertofellowmotoristsandleadtoqueuesbehindyouobservingthetrafficflowandadaptingyourspeedtotheconditionsisimportanttoavoidunnecessaryqueuesandpanicbraking: 'When driving on higher speed roads, planning is more important than ever. Entering a highway too slowly can cause danger to fellow motorists and lead to queues behind you. Observing the traffic flow and adapting your speed to the conditions is important to avoid unnecessary queues and panic braking.',
    txt_global_std_pushdriveendmessagetitle: 'Drive has ended - {0}',
    'txt_global_std_your@email.com': 'your@email.com',
    txt_global_std_enerfyanalytics_upsell_bulletpoint2: 'Uncover new insights to enable targeted action',
    txt_global_std_unknownracedriverDescription: 'To be announced',
    txt_global_std_KristofferssonMileage: 'I enjoy driving up north in Sweden a sunny winter day ready for a ski trip.',
    txt_global_std_findteamordriver: 'Find team or driver',
    txt_global_std_invitefriendsviaemail: 'Invite friends via email',
    txt_global_std_ifyoudonothavebluetoothinthecaryoucanuseachipthatisplacedinthecarsobd2portachipcanbeusedinmostcarsfrom2001orlater: "If you do not have Bluetooth in the car, you can use a chip that is placed in the car's OBD2 port. A chip can be used in most cars from 2001 or later.",
    txt_global_std_creategroup: 'Create Group',
    txt_global_std_yougetthisafterdriving25tripswithtrygdrive: 'You get this after driving 25 trips with Tryg Drive',
    txt_global_std_getiobd2: 'Get iOBD2',
    txt_global_std_KleinschmidtDescription: 'She is the first and so far the only woman who has ever taken outright victory in the longest and toughest rally in the world, the Dakar Rally. Her racing career started with motorcycle. After 4 rallies on motorbike she changed to 4 wheels in 1995, and became involved in the development of the most successful rally car, the Mitsubishi Pajero Evo, in which she won the Dakar in 2001. Besides he job as an instructor, she has published her book “Meine Siege bei der Dakar” – My win in Dakar.',
    txt_global_std_distance: 'Distance',
    txt_global_std_observe: 'Observe',
    txt_global_std_deletetranslation: 'Delete translation',
    txt_global_std_damageabroad: 'Damage abroad',
    txt_global_std_okay: 'Okay',
    txt_global_std_demotext4: 'When you score – you save! See your total bonus here.',
    txt_global_std_joinyourteaminthefiasdcandstayactivesmartandsustainablewhenyoutravelbycar: 'Join your team in the Fia SDC and stay active, smart and sustainable when you travel by car.',
    txt_global_std_p2ppushtitlelendcar: '{name} - lend my car?',
    txt_global_std_p2pemailorpasswordwrong: 'Email address / password - both or one of them is wrong',
    txt_global_std_areyousureyouwanttoadjustyourmileagethepricewillbeadjustedaccordingtothenewmileage: 'Are you sure you want to adjust your mileage? The price will be adjusted according to the new mileage.',
    txt_global_std_ajustingmilageplan: 'Ajusting milageplan',
    txt_global_std_vsteam: 'VS. Team',
    txt_global_std_yourscoreisbasedonthedrivingpatternsyoucreatewitheachtripconcentrateonyourdrivingskillstoimproveyourscoreswewillprovideahistoryofallyourtripssoyoucanseehowyouredoing: "Your score is based on the driving patterns you create with each trip. Concentrate on your driving skills to improve your scores. We will provide a history of all your trips so you can see how you're doing.",
    txt_global_std_belowisthelinktoyourenerfyglobalapp: 'Below is the link to your Enerfy Global app.',
    txt_global_std_youcancontactoneofourlicensedinsuranceagenciesbyclickingonlinkorcallingagentatphoneno: 'You can contact one of our licensed insurance agencies by clicking on {link} or calling {agent} at {phoneNo}',
    txt_global_std_cimage12h: 'GAIN SPEED BEFORE UPHILL',
    txt_global_std_noreviewstobepublishedpleaseedittranslationsifyouwishtopublish: 'No Reviews to be published please edit translations if you wish to publish.',
    txt_global_std_changecar: 'Change car',
    txt_global_std_emailtoolong: 'Email can be a maximum of 127 characters.',
    txt_global_std_howdoicompeteinenerfyloyaltychallenge: 'How do I compete in Enerfy Loyalty Challenge?',
    txt_global_std_cimage20h: 'HIGH MAX SPEED DO NOT GIVE TIME GAINS',
    txt_global_std_kretursanalyse: 'Trip analytics',
    txt_global_std_enteryourpolicynumbertounlockallthebenefitsofthisapp: 'Enter your policy number to unlock all the benefits of this app. ',
    txt_global_std_createyourownleaderboardinthefiasmartdrivingchallengeandchallengeeachothertobecomesmarterandsaferontheroad: 'Create your own leaderboard in the FIA Smart Driving Challenge and challenge each other to become smarter and safer on the road.',
    txt_global_std_cimage21d: 'It is technically easier to drive economically at lower speeds.',
    txt_global_std_avaragescore: 'Average score',
    txt_global_std_emergencynumber: 'SOS',
    txt_global_std_tiphighway1DESC: 'The blue car driver is focused and enters the highway safely.\n' +
      'The red car driver does not adjust their speed to the driving situation and needs to brake often.',
    txt_global_std_makesureyouarewithintenmeters: 'Make sure you are within 10 m. from the OBD-reader.',
    txt_global_std_drivingtips: 'Driving tips',
    txt_global_std_introduction: 'Introduction',
    txt_global_std_starting: 'Starting',
    txt_global_std_noactiveparticipantsthisweek: 'No active participants this week',
    txt_global_std_fieldcannotbeempty: 'Field cannot be empty',
    txt_global_std_yourchallengeranking: 'Your challenge ranking',
    txt_global_std_createnewchallenge: 'Create new challenge',
    txt_global_std_enteravaliddate: 'Enter a valid date.',
    txt_global_std_p2pemailadressforcommunication: 'Email adress for communication',
    txt_global_std_searchcardriver: 'Search car/driver',
    txt_global_std_recruitedby: 'Recruited by',
    txt_global_std_containsforbiddencharactersorspace: " contains forbidden characters: ' ? & / &#60 &#62; or space",
    txt_global_std_p2psignupandcontinue: 'Sign up and continue',
    txt_global_std_idonthavebluetoothinmycarcanistillusetheapp: 'I don’t have Bluetooth in my car, can I still use the app?',
    txt_global_std_allowbluetooth: 'Allow Bluetooth',
    txt_global_std_youhavenowearnedascoreof4point4outof510timeswithtrygdrive: 'You have now earned a score of 4.4 out of 5 10 times with Tryg Drive',
    txt_global_std_p2pselectedcar: 'Selected car',
    txt_global_std_pleaseallowkintoaccesstoyourlocationtogetstartedwiththeapp: 'Please allow Kinto access to your location to get started with the app.',
    txt_global_std_baselevel: 'Base Level',
    txt_global_std_ifyoucontinuetodriveonlevel: 'If you continue to drive on level {level} you could save {discount} on your insurance.  ',
    txt_global_std_generateinvitationcode: 'Generate invitation code',
    txt_global_std_MSIG_InsuranceLetter6: 'Motor Vehicles (Third-Party Risks) Rules, 1959 (Malaysia)',
    txt_global_std_p2pconnectyourphonetoyourcarandyouwillunlockallthemagic: 'Connect your phone to your car - and you will unlock all the magic.',
    txt_global_std_addusersbyuploadingacsvfilewiththeirdetailsthefieldsfornameemailandpermissionsaremandatory: 'Add users by uploading a CSV file with their details. First name, last name, email address, phone number and group are required.',
    txt_global_std_fixitnowreminder: 'Fix it now',
    txt_global_std_managecarsagreementshere: 'Manage cars & agreements here',
    txt_global_std_comparemyactivities: 'Compare my trips',
    txt_global_std_readytodrive: 'Ready to drive',
    txt_global_std_p2pmycalendar: 'My calendar',
    txt_global_std_earnpointsandsavemoney: 'Earn points and save money on your deductible',
    txt_global_std_drivescoresave: 'Drive. Score. Save.',
    txt_global_std_highest: 'Highest',
    txt_global_std_testdeploynb: 'Hej',
    txt_global_std_backtochallengedashboard: 'Back to Challenge Dashboard',
    txt_global_std_warikan_onboarding11: 'KINTO Customer Center',
    txt_global_std_takepicturesofyourcar: 'Take pictures of your car',
    txt_global_std_acommontypeofunsafedrivingbehaviorisputtinghugeeffortintochasingthenexttrafficlightitslikeachallengetogettothenextcrossingasquicklyaspossibleonlytobestuckwaitingforthelighttoturngreendrivingsmoothlyandlookingwellaheadtoanticipatewhatscomingupsavesbothtimeandfuelnew: 'A common type of unsafe driving behavior is putting huge effort into chasing the next traffic light! It’s like a challenge to get to the next crossing as quickly as possible only to be stuck waiting for the light to turn green😊 Driving smoothly and looking well ahead to anticipate what’s coming up saves both time and fuel.',
    txt_global_std_addyourlogotypeandchoosecolortheme: 'Add your logotype and choose color theme',
    txt_global_std_accounttype: 'Account type',
    txt_global_std_noofdrives: 'No. of drives',
    txt_global_std_wasanyoneinjured: 'Was anyone injured?',
    txt_global_std_november: 'November',
    txt_global_std_variablepremium: 'Variable monthly premium',
    txt_global_std_youneedtoinputapassword: 'You need to input a Password',
    txt_global_std_findchallenges: 'Find challenges',
    txt_global_std_easytomonitoryouresgtargets: 'Easy to monitor your ESG targets',
    txt_global_std_xhours: '{hours} hours',
    txt_global_std_iwanttonotifyyouaboutyourdrivingresults: 'I want to notify you about your driving results. Ok?',
    txt_global_std_p2ppercentageused: 'Percentage used',
    txt_global_std_youraveragerisklevelispoints: 'Your average risk level is {points}',
    txt_global_std_loyalty_upsell: 'Loyalty Pages – support business growth through stronger relationships.',
    txt_global_std_p2pwarikanloginorlinkissues: 'Warikan - Login or link issues',
    txt_global_std_enterpassword: 'Enter password',
    txt_global_std_lastdatereceived: 'Last date received',
    txt_global_std_neverbeeneasier: 'Never been easier',
    txt_global_std_activatemyinsurance: 'Activate my insurance',
    txt_global_std_p2pother: 'Other',
    txt_global_std_riskindex_upsell_pitch_bulletpoint3: 'Risk levels globally, per country or per zip-code/ postcode ',
    txt_global_std_appnamecollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: '{appname} collects location data to enable driving score even when the app is closed or not in use.',
    txt_global_std_whendrivingincityconditionsavoidchasingthenextcrossingortrafficlightbygainingspeedquicklythenslowingquicklythishurtstraveltimeandfueleconomyandincreasescrashrisklookingwellaheadanddrivingsmoothlywillhelpyouanticipateandhandleanyevent: 'When driving in city conditions, avoid chasing the next crossing or traffic light by gaining speed quickly then slowing quickly. This hurts travel time and fuel economy, and increases crash risk. Looking well ahead and driving smoothly will help you anticipate and handle any event.',
    txt_global_std_p2pwhatdoyouwanttodo: 'What do you want to do?',
    txt_global_std_ourairiskinsighttoolgivesyouthepowertoidentifyfollowupandreportoncsrtargetstrackriskandco2perteammemberandrewardthosewhoexcel: 'Our AI risk insight tool gives you the power to identify, follow up and report on CSR targets, track risk and CO2 per team member and reward those who excel.',
    txt_global_std_cimage21h: 'LOWER SPEED MAKES IT EASIER TECHNICALLY',
    txt_global_std_average: 'Average',
    txt_global_std_yourdocumentwassent: 'Your document was sent!',
    txt_global_std_followupdate: 'Follow-up date',
    txt_global_std_won: 'Won',
    txt_global_std_hellolinus: 'hello Linus !',
    txt_global_std_speedchangedescription: 'This graph shows you the total amount of time you’ve spent in acceleration, constant speed and decreasing speed. General tips: Aim for more time in decreasing speed than acceleration.',
    txt_global_std_p2pblockedthecar: '{name} blocked the car',
    txt_global_std_above1000unlocked: 'You drove above 1000! Awesome!',
    txt_global_std_letsdrive: "Let's drive!",
    txt_global_std_easytogeteverydriverinyournetworkinvolved: 'Easy to get every driver in your network involved',
    txt_global_std_fiasdcisaninitiativetoreducepollutionandincreasesafetyontheraodswhiledrivingmotoristsgettheirdrivingassessedinrealtimebyouraitechnologythatunderstandseachtripslevelofriskandcarbonfootprintjustbymakingmotoristsawareoftheirimpactwecancreateapositivechange: "Fia SDC is an initiative to reduce pollution and increase safety on the raods. While driving, motorists get their driving assessed in real-time by our AI technology, that understands each trip's level of risk and carbon footprint. Just by making motorists aware of their impact we can create a positive change.",
    txt_global_std_VilliersQuote: 'Quote coming soon...',
    txt_global_std_membersinthisgroup: 'Members in this group',
    txt_global_std_renewaldate: 'Renewal date',
    txt_global_std_1000pointaverageachievementlocked: 'Drive with an average {scoretype} of {score} or better for a week to unlock this achievement and earn 1000 points.',
    txt_global_std_carriedrisk: 'Carried risk',
    txt_global_std_idrivetoomuch: 'I drive too much',
    txt_global_std_headtohead: 'Head to head',
    txt_global_std_thisisonlyneededthefirsttimeyouconnecttoyourcar: 'This is only needed the first time you connect to your car. ',
    txt_global_std_reducing: 'reducing',
    txt_global_std_p2pgotocostsplit: 'Go to cost split',
    txt_global_std_p2psendalinksothatthepersonborrowingyourcarcandownload: 'Send a link so that the person borrowing your car can download the {appname} app.',
    txt_global_std_HansenQuote: 'Driving smart is fun! It saves me money and reduces my impact on our precious environment.',
    txt_global_std_PettersolbergShortDescription: 'World RX Champion 2014 & 2015, WRC Champion 13 times, Rally GB Champion 2002 & 2003',
    txt_global_std_choosebetweenenteringchassisnumberenginenumberplatenumberorpolicynumber: 'Choose between entering chassis number/engine number, plate number or policy number.',
    txt_global_std_p2pusesameemailasregisteredonkintoaccount: '* Use same email as registered on Kinto account',
    txt_global_std_chooseaccidenttype: 'Choose accident type',
    txt_global_std_choosefile: 'Choose file...',
    txt_global_std_alreadyexistspossibleduplicationinternalservererror: 'Already exists, possible duplication. Internal server error',
    txt_global_std_cimage22d: 'Only use cruise control on flat roads. Roads may be classified as flat should you not need to use parking brake.',
    txt_global_std_p2pdoyouwanttobookthedaysinbetweenormakeitseparatebookings: 'Do you want to book the days in between or make it separate bookings?',
    txt_global_std_youraveragelevelis: 'Your average level is',
    txt_global_std_bottomfinaliststext: 'The World Challenge consists of 3 challenge heats, 3 qualifying periods in between the heats and 1 grand final. \n' +
      '\n' +
      'The top 3 drivers of each challenge heat will be directly qualified to the big final in December.',
    txt_global_std_anerroroccurredwhilesavingyourchanges: 'An error occurred while saving your changes',
    txt_global_std_activatelocationservice: 'Activate Location Services',
    txt_global_std_p2ppaynow: 'Pay now',
    txt_global_std_wheresmyoutlet: "Where's my outlet?",
    txt_global_std_friendstothekintoapp: 'friends to the Kinto-app',
    txt_global_std_createyouraccounttosaveandtrackthecostofyourtripsandgetaccesstoyourpersonaldrivingcoach: 'Create your account to save and track the cost of your trips and get access to your personal driving coach ',
    txt_global_std_plugitinyourcaarsobdoutlet: "Plug it in your car's OBD-outlet. Normally located near the steering wheel, (Your dongle will be with you within 1-3 business days)",
    txt_global_std_WelcomeLetter_Text4: 'Welcome!',
    txt_global_std_HSolbergInterests: 'Cars, rally & racing. Promotion days with my sponsors',
    txt_global_std_areyousureyouwanttoredeemxpointscoupon: 'Are you sure you want to redeem {x} points?',
    txt_global_std_p2pclickdatestoseebooking: 'Click dates in the calendar to see your bookings.',
    txt_global_std_currentodometervalue: 'Current odometervalue',
    txt_global_std_alreadyhavefiasdc: 'Already have FIA SDC?',
    txt_global_std_description: 'Description',
    txt_global_std_uploadyourdatatoseehowsufficientitis: 'Upload your data to see how sufficient it is.',
    txt_global_std_platenumber: 'Plate number ',
    txt_global_std_invalidcode: 'Invalid code',
    txt_global_std_p2pyesremovefriend: 'Yes, remove friend',
    txt_global_std_paylessnextmonth: 'pay less next month',
    txt_global_std_KristofferssonQuote: 'Road safety is very close to my heart. The FIA Smart Driving Challenge will be such fun and smart as it sounds and it will let anyone improve their eco-friendly and safe driving. As I’ve been racing on every road condition you can imagine, I am now really looking forward to transmitting that knowledge to encourage smarter driving amongst all of us.',
    txt_global_std_p2pyour: 'Your',
    txt_global_std_youcancontactoneofourlicensedinsuranceagenciesbyclickingonfindanagentonourwebsiteorcallingagentatphonenumber: 'You can contact one of our licensed insurance agencies by clicking on Find an Agent on our website or calling {agent} at {phoneNumber}.',
    txt_global_std_renewyourinsurance: 'Renew your insurance',
    txt_global_std_p2pcost: 'Cost',
    txt_global_std_yesexchangepoints: 'Yes exchange points',
    txt_global_std_oldpasswordhelp: 'We need your current password to ensure that you are the owner of this account.',
    txt_global_std_offlinetrip: 'Offline',
    txt_global_std_uploadlogotype: 'Upload logotype',
    txt_global_std_donthaveanaccountsignuplink: "Don't have an account? {signUpLink}",
    txt_global_std_haveaproblemwiththeappvisitourfaqorcontactus: 'Have a problem with the app? Visit our FAQ or contact us...',
    txt_global_std_starburstunlockedkey: 'Your drove at level 4.4 or better! Awesome! 300 points have been added to your Kinto Wallet.',
    txt_global_std_aonceaclaimsettlementisdeterminedyoucanreceiveacheckoranelectronicpaymentintoyourbankaccount: 'A: Once a claim settlement is determined, you can receive a check or an electronic payment into your bank account.',
    txt_global_std_completewizard: 'Complete wizard',
    txt_global_std_enteremailtodostuff: 'Enter your email to receive a mail, where you can reset and set a new password of your choice.',
    txt_global_std_datasentsuccess: 'You data has been sent, please check your inbox!',
    txt_global_std_on: 'On',
    txt_global_std_whatifichangecar: 'What if I change car?',
    txt_global_std_connecttotheprovidedobddeviceifyourcar: "Connect to the provided OBD-device (Enerfy Mini OBD) if your car doesn't have bluetooth",
    txt_global_std_selectyourcarmodeltoseethemostaccuratedataofsavedco2: 'Select your car model to see the most accurate data of saved CO2.',
    txt_global_std_forgotyourpassword: 'Forgot your password?',
    txt_global_std_getacoscoreof15orlowerstartdrivingtogetyourscore: 'Get a CO₂ score of 15% or lower. Start driving to get your score! ',
    txt_global_std_TaxInvoice3: 'Doc No',
    txt_global_std_enerfyplugin: 'Enerfy Plug-in',
    txt_global_std_addnoteshere: 'Add notes here',
    txt_global_std_best: 'Best',
    txt_global_std_p2pnogoback2: 'No, go back',
    txt_global_std_fitnessmotion: 'Fitness/motion',
    txt_global_std_p2pyourkintonumbercanbefoundwhenyoulogintoyourkintoaccount: 'Your Kinto number can be found when you log in to your Kinto account.',
    txt_global_std_superstar: 'Superstar',
    txt_global_std_doihavetoactivatetheappeverytimeidriveanswer: 'No- the app works in background mode! Make sure you always keep Bluetooth and location services activated on your phone.',
    txt_global_std_sorrywecouldnotfindthatemailaddresspleasecontactagentsupport: 'Sorry, we could not find that email address. Please contact {agentSupport}.',
    txt_global_std_ayoucancontactoneofourlicensedinsuranceagenciesbyclickingonlinkorcallingagentatphoneno: 'A: You can contact one of our licensed insurance agencies by clicking {link} or calling {agent} at {phoneNo}.',
    txt_global_std_thisisonlyneededthefirsttimeyouconnecttoyourcar1: 'This is only needed the first time you connect to your car. ',
    txt_global_std_somethingwentwrongandwecouldnotdisplayyourresulthere: 'Something went wrong and we could not display your result here.',
    txt_global_std_someoneelseispairedtoyourcarnodot: 'Someone else is paired to your car',
    txt_global_std_selectagreementnumber: 'Select Agreement Number',
    txt_global_std_thisfunctionisunstable: 'This functionality is unstable and should be used with caution.',
    txt_global_std_p2pfriendcalendar: "{name}'s calendar",
    txt_global_std_hourh: 'h',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweek: 'You drove really well! Your average {scoretype} was better than {score} for a week!',
    txt_global_std_vehicledata: 'Vehicle data',
    txt_global_std_amazingdriver: 'Amazing Driver',
    txt_global_std_bothyouandyourfriendwillget10kmfreedistancetodrivewithenerfy: 'Both, you and your friend will get 10km free distance to drive with Enerfy!',
    txt_global_std_confirmyouremailaddress: 'Confirm your email address',
    txt_global_std_rwmgoback: 'Go back',
    txt_global_std_appcreator_upsell_pitch_bulletpoint1: 'Optimize your digital customer journey, incorporating brand colors and logotype',
    txt_global_std_yourco2overview: 'Your CO2 overview',
    txt_global_std_excessdiscount: 'Excess discount',
    txt_global_std_addressline2: 'Address line 2',
    txt_global_std_inbonusforyourinsurance: 'in bonus for your insurance.',
    txt_global_std_didyoureceivethepayment: 'Did you receive the payment?',
    txt_global_std_ensammenligningmeddennekreturoggennemsnittetafalledineturedeseneste30dage: 'Your driving stats for this trip compared to your 30 days average.',
    txt_global_std_createyourapp: 'Create your app',
    txt_global_std_dashboard_users: 'Users',
    txt_global_std_ifbluetoothisalreadyactivatedyoucanstillgetthiserror: 'Follow these steps to make sure you have bluetooth turned on.',
    txt_global_std_notificationsettings: 'Notification Settings',
    txt_global_std_loadingevents: 'Loading events...',
    txt_global_std_firstname: 'First name',
    txt_global_std_hassentyouachallenge: 'has sent you a challenge!',
    txt_global_std_wrongpasswordformatminimum8charactersatleastoneuppercaseletteronelowercaseletterandonenumber: 'Wrong password format. Minimum 8 characters, at least one uppercase letter, one lowercase letter and one number. ',
    txt_global_std_pigwon: 'Winner of head2head',
    txt_global_std_personalizeyourchallenge: 'Personalize your challenge',
    txt_global_std_thereisnopermissions: 'There is no permissions',
    txt_global_std_claimsdata: 'Claims data',
    txt_global_std_badinternetconnection: 'Bad internet connection. This can cause delays in app and affect your driving data.',
    txt_global_std_test_key22: 'this is a test 2',
    txt_global_std_6months: '6 months',
    txt_global_std_groupvsgroup: 'Group vs. group',
    txt_global_std_reportaccidentonline: 'Report accident online',
    txt_global_std_date: 'Date',
    txt_global_std_communicate_upsell_pitch_bulletpoint2: 'Connect with users on their preferred channels',
    txt_global_std_v2risklevel: 'Risk level',
    txt_global_std_hereyoucanloweryoursubscriptioncostbyupto20permonthbydrivingsafe2: 'Here you can lower your subscription cost by up to 20% per month by driving safe.',
    txt_global_std_ifyouareaninsurancecustomeryoucanfindyourpolicynumberwhenyoulogintoyourappnameaccount: 'If you are an insurance customer you can find your policy number when you log in to your {appname} account.',
    txt_global_std_above1000streaklocked: 'Unlock this achievement by driving with a score above 1000 5 times.',
    txt_global_std_loadingusers: 'Loading users...',
    txt_global_std_createapikey: 'Create API Key',
    txt_global_std_unlockeddescriptionlabel: 'Unlocked description, shown in app',
    txt_global_std_totalpointscollected: 'Total points collected',
    txt_global_std_p2ptogetaccesstoallfunctionsinthisappyouneedtobeinvitedbyakintocarowner: 'To get access to all functions in this app you need to be invited by a Kinto car owner.',
    txt_global_std_hour: 'Hour',
    txt_global_std_yourteam: 'Your team',
    txt_global_std_p2plendcar: 'Lend car',
    txt_global_std_demotext1: 'Drive smart and reduce CO2. See your CO2 savings here.',
    txt_global_std_p2pconfirmselection: 'Confirm Selection',
    txt_global_std_skeylevel: 'Level',
    txt_global_std_terminateinsurance2: 'Terminate Insurance',
    txt_global_std_achievements: 'Achievements',
    txt_global_std_applyfilters: 'Apply filters',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsabouttheagentappandcompeteagainstthemforlowerscores: "A: Once you pair the app to your vehicle's Bluetooth and allow the app to use location services, we match your driving habits to our AI’s library of driver patterns and provide a score. Your insurance premium is based on who drives your vehicle and how well they drive. Whoever is driving your vehicle is encouraged to download the app and track their scores. Tell your friends about the {agent} app and compete against them for lower scores.",
    txt_global_std_carsharingcompany: 'Car sharing company',
    txt_global_std_communicate_upsell_pitch_bulletpoint3: 'Communicate contextual messages at the right time',
    txt_global_std_p2pwantstoborrowyour: ' wants to borrow your ',
    txt_global_std_editgroup: 'Edit group',
    txt_global_std_coreresources: 'Core resources',
    txt_global_std_highrisk: 'High risk',
    txt_global_std_cosavings: 'CO² savings',
    txt_global_std_fitnessreasontext: 'Fitness/Motion are required to determine wether you are driving or walking.',
    txt_global_std_p2perrorcostcannotbegreaterthantotalcosttosplit: 'Error - cost cannot be greater than total cost to split',
    txt_global_std_register: 'Register',
    txt_global_std_Unsubscribe: 'Click here to unsubscribe from this list.',
    txt_global_std_team: 'Team',
    txt_global_std_greatdriving: 'Great driving!',
    txt_global_std_aroundtheclock: 'Around the clock',
    txt_global_std_enteravalidvehicleidentification: 'Enter a valid vehicle identification.',
    txt_global_std_createnewbtntext: 'Create new',
    txt_global_std_everydayhero: 'Everyday Hero',
    txt_global_std_resultofanalyse: 'Result of analyse',
    txt_global_std_MSIG_Onboarding3: 'which is valid from',
    txt_global_std_p2paccept: 'Accept',
    txt_global_std_cimage3h: 'ANTICIPATE SPEED REDUCTIONS',
    txt_global_std_goldlevel: 'Gold Level',
    txt_global_std_p2puserborrowedcartimesph: '{user} borrowed {car} {times} times',
    txt_global_std_smartdriverweeklylockedkey: 'To earn this badge, keep a score above 3.5 for a week.',
    txt_global_std_p2ppushbodynewpaymentquestion: 'Did you receive a payment from {name}? Make sure to mark it as paid.',
    txt_global_std_howdoicompeteinappchallenge: 'How do I compete in {appname} Challenge?',
    txt_global_std_forthisapptoworkproperlylocationservicemustbeallowed: 'For this app to work properly, Location Service must be allowed on your device. Please select "Always" in location settings.',
    txt_global_std_mapsarenotavailableforthisperiod: 'Maps are not available for this period',
    txt_global_std_faq: 'FAQ',
    txt_global_std_searchdivision: 'Search division',
    txt_global_std_of: 'of',
    txt_global_std_PolicySchedule12: 'Cover Type',
    txt_global_std_chooseyouroption: 'Choose your option:',
    txt_global_std_rwmsheettext: 'Send a link to your friend so that they can download the Pioneer app. Once they have created an account they will show up in your list of friends. You will then be able to register a trip where you shared a ride.',
    txt_global_std_PaddonQuote: "Don't just dream it, achieve it! Do it for the planet!",
    txt_global_std_locked_account: 'This user has been locked for too many failed login attempts. Try again in 1 hour.',
    txt_global_std_30days: '30 days',
    txt_global_std_account: 'account',
    txt_global_std_challengedashboard: 'Challenge dashboard',
    txt_global_std_youcanonlyregisteraridesharingtriponceperweek: 'You can only register a ride sharing trip once per week',
    txt_global_std_thephonenumbercannotincludespecialcharacterslikes: `The phone number cannot include special characters like "'?&/&#60; &#62;\\s#!%$%^*-`,
    txt_global_std_writemessage: 'Write small message',
    txt_global_std_costoverview: 'Cost Overview',
    txt_global_std_p2pyouandmembersborrowingyourcarneedtoconnecttheapptoyourcarsbluetooth: 'You and members borrowing your car need to connect the app to your car’s Bluetooth system before driving.',
    txt_global_std_yourreportisready: 'Your report is ready!',
    txt_global_std_needstobeavalidphonenumbermustbeavalidsizeandcanonlyincludenumbersand: 'Needs to be a valid phone number. Must be a valid size and can only include numbers and +',
    txt_global_std_week: 'Week',
    txt_global_std_reviewbeforepublishing: 'Review Before Publishing',
    txt_global_std_addnewuser: 'Add new user',
    txt_global_std_p2pfaq3q: 'What if I cannot find where the car is parked?',
    txt_global_std_typeofscore: 'Type of score',
    txt_global_std_p2pthefollowingbonuswillbegiven: 'The following bonus will be given.',
    txt_global_std_JyrkiainenFacts: 'Been growing up surrounded by motorsport in a racing family, daughter of Minna Sillankorva, 1991 Ladies Rally World Champion and nine-time Finnish Champion',
    txt_global_std_yourtripsinsight: 'Your trips insight',
    txt_global_std_account1: 'Account:',
    txt_global_std_toptrips: 'Top trips',
    txt_global_std_fax: 'Fax',
    txt_global_std_highusageparamamount: 'High usage {amount}.',
    txt_global_std_startyourcarandmakesureyourbluetoothison: 'Start your car and make sure your bluetooth is on.',
    txt_global_std_selectaminimumoftwogroupsthatshouldcompeteagainsteachotherinthechallenge: 'Select a minimum of two groups that should compete against each other in the challenge.',
    txt_global_std_examplevalue: 'Example value',
    txt_global_std_PolicySchedule1: 'SCHEDULE',
    txt_global_std_p2ppaywith: 'Pay with',
    txt_global_std_german: 'German',
    txt_global_std_presstoconnect: 'Press to connect',
    txt_global_std_connectiontoyourcar: 'Connection to your car',
    txt_global_std_buyfiasdc: 'Buy FIA SDC',
    txt_global_std_copiedtoclipboard: 'Copied to clipboard!',
    txt_global_std_clickheretoseewhy: 'Click here to see why',
    txt_global_std_therequestwasunacceptableoftenduetomissingarequiredparameter: 'The request was unacceptable, often due to missing a required parameter.',
    txt_global_std_thedescriptioncantbelongerthan180characters: "The description can't be longer than 180 characters",
    txt_global_std_previewapp: 'Preview app',
    txt_global_std_usersuccessfullyadded2: 'User successfully added',
    txt_global_std_waitingtodownloadyourapp: 'Waiting to download your app',
    txt_global_std_fiaquarterfinalparticipaterule2020: 'In order to participate in the Qualification Final you need to become one of the top 20 drivers or one of the top 3 drivers of the top 3 teams.',
    txt_global_std_aphonenumbermayonlyincludenumerics: 'A phone number may only include numerics,',
    txt_global_std_challengesuccessfullyremoved: 'Challenge successfully removed',
    txt_global_std_topfeatures: 'Top features',
    txt_global_std_p2pkintomonthlyfee: 'Kinto monthly fee',
    txt_global_std_pickyourcolortheme: 'Pick your color theme',
    txt_global_std_calledsalvage: 'Called Salvage',
    txt_global_std_toreportitnow: 'to report it now.',
    txt_global_std_cimage9d: 'Only use cruise control when flat, othervise keep gas steady.',
    txt_global_std_downloadingdata: 'Downloading data',
    txt_global_std_confirm2: 'Confirm',
    txt_global_std_cimage19d: 'Keep steady gas - NOT - steady speed!',
    txt_global_std_sendchallengeinvitation: 'Send challenge invitation',
    txt_global_std_validforxxminutes: '(Valid for 6 minutes)',
    txt_global_std_TaxInvoice6: 'IMPORTANT NOTICE:',
    txt_global_std_male: 'Male',
    txt_global_std_getanoverviewofyourcustomerportfolio: 'Get an overview of your customer portfolio',
    txt_global_std_exceededinsureddistance: 'You have now exceeded your annual mileage and will not receive full compensation for damage. Please contact us so we can adjust the mileage and make sure you are properly insured.',
    txt_global_std_howwilligetpaidforaclaim: 'How will I get paid for a claim?',
    txt_global_std_pleasecontactsupportlinkalllowercaseifyouhavenotreceivedthereaderafter7businessdays: 'Please {contactSupportLinkAllLowerCase} if you have not received the reader after 7 business days.',
    txt_global_std_howwewantyoudata: 'How we want you data',
    txt_global_std_getinsured: 'Get insured',
    txt_global_std_viewwhoisparticipatinginthechallenge: 'View who is participating in the challenge',
    txt_global_std_resendtemporarypassword: 'Resend temporary password',
    txt_global_std_day: 'Day',
    txt_global_std_sendalinksothatthepersondrivingyourcarcandownloadtheapp: 'Send a link so that the person driving your car can download the app.',
    txt_global_std_documentcar: 'Document car',
    txt_global_std_competitionrulesapplied: 'Competition rules applied',
    txt_global_std_renewinsurance: 'Renew insurance',
    txt_global_std_incabtraining: 'In-car training',
    txt_global_std_testingnewfronttranslation3: 'testingnewfronttranslation3 edit',
    txt_global_std_reporthistory: 'Report history',
    txt_global_std_yournicknameshouldcontainatleastthree2: 'Your nickname should contain at least three (3) characters and no special characters.',
    txt_global_std_seeperiod: 'Details',
    txt_global_std_areyousureyouwanttodeletethisitem: 'Are you sure you want to delete this item?',
    txt_global_std_validto: 'Valid to',
    txt_global_std_downloadpersonaldata: 'Download personal data',
    txt_global_std_seedemo: 'See demo',
    txt_global_std_MSIG_Onboarding6: 'What you have to do now is:',
    txt_global_std_worldchallenge2022: 'World Challenge 2022',
    txt_global_std_sidekickprivacypolicy: 'privacy policy',
    txt_global_std_fiaheatrule2020: 'You need to drive more than 10 km during a heat (week) in order to get a score in the heat leaderboard.',
    txt_global_all_weekend: 'Weekend',
    txt_global_std_Fia_Receipt_Text12: 'If you have any questions, please send us an email:',
    txt_global_std_p2pselectedowner: 'Selected owner',
    txt_global_std_MSIG_InsuranceLetter30: 'MS First Capital Insurance Ltd',
    txt_global_std_automotivecompanydescription: 'New ownership models and driver engagement.  ',
    txt_global_std_yourinsuranceexpires: 'Your insurance expires: {insuranceenddate} Remember to add your current mileage (value in km) before your insurance expires.',
    txt_global_std_location2: 'Location',
    txt_global_std_youneedtofillinallnecessaryfields: 'You need to fill in all necessary fields',
    txt_global_std_retry123: 'Retry',
    txt_global_std_apicreator: 'API Creator',
    txt_global_std_resetyourpassword: 'Reset your password',
    txt_global_std_hello: 'Hello',
    txt_global_std_nogoback: 'No, go back!',
    txt_global_std_KristofferssonShortDescription: 'World RX Champion 2017 & 2018',
    txt_global_std_p2pdeny: 'Deny',
    txt_global_std_youhavereducedtheaveragecoemissionsofyourcarby: 'You have reduced the average CO₂ emissions of your car by {percent}',
    txt_global_std_p2pleftthegroup: 'left the group',
    txt_global_std_notavailableindemo: 'Not available in demo',
    txt_global_std_publishunpublish: 'Publish / Unpublish',
    txt_global_std_wanttojoinanevent: 'Want to join an event?',
    txt_global_std_PSolbergWhyjoin: 'Coming soon...',
    txt_global_std_moveyourselfandotherstosecure: 'Move yourself and others to a secure location. In case of an injury, call: ',
    txt_global_std_p2pnotavalidprice: 'Not a valid price',
    txt_global_std_cimage37h: 'LEARN HOW FAR YOUR VEHICLE ROLL AT DIFFERENT SPEEDS',
    txt_global_std_hereswhereyouhavethelargestimprovementpossibility: "Here's where you have the largest improvement possibility",
    txt_global_std_areyousureyouwanttodeletethechallengeoncedeletedyouwillnolongerbeabletoseethechallengeresultsparticipantswillstillbeabletoseetheirtripsinthefiasdcapp: 'Are you sure you want to delete the challenge? Once deleted you will no longer be able to see the challenge results. Participants will still be able to see their trips in the FIA SDC app.',
    txt_global_std_year: 'Year',
    txt_global_std_phonenumbercannotincludecharacterslike6062s: `Phone number Cannot include characters like "'?&/&#60; &#62;\\s#!%$%^*-`,
    txt_global_std_whenyouhavecompletedyourfirstdrivewithenerfy: 'When you have completed your first drive with Enerfy, you will be able to view your cost and invoices.',
    txt_global_std_onboardingtype: 'Onboarding type :',
    txt_global_std_enteravalidemailaddress: 'Enter a valid email address.',
    txt_global_std_showstimesincethevehiclehasbeenparked: 'Shows time since the vehicle has been parked.',
    txt_global_std_200kmlockedkey: 'Drive a distance of 200 km to earn this badge.',
    txt_global_std_twofactorenabled1: 'Two factor enabled',
    txt_global_std_examplemessagesms: 'Hello! This is a text message example.',
    txt_global_std_p2pspecifiedprice: 'Specified price',
    txt_global_std_oilandoilfilter: 'Oil and Oil filter ',
    txt_global_std_maximizeheader: 'Maximize',
    txt_global_std_daysdriven: 'Days driven',
    txt_global_std_p2pwithfamilyandfriends: 'with family and friends',
    txt_global_std_p2pfaq9q: 'What if my friend returns the car early?',
    txt_global_std_joinclubsevent: 'Join Club’s event',
    txt_global_std_thesamegeographicalroutewillbescoredamaximumof10timesinoneday: '• The same geographical route will be scored a maximum of 10 times in one day.',
    txt_global_std_livetopdrivers: 'Live top drivers',
    txt_global_std_p2pcostsplitinvoice: 'Cost split invoice',
    txt_global_std_news: 'Activity',
    txt_global_std_authenticatedrequest: 'Authenticated request',
    txt_global_std_enterpasswordtoremove: 'Enter password to remove',
    txt_global_std_loadingapps: 'Loading apps...',
    txt_global_std_SendMyPosition_Hello: 'Hello',
    txt_global_std_basecolor: 'Base Color',
    txt_global_std_enddateisrequired: 'End date is required',
    txt_global_std_sendalinksothatthepersondrivingyourcarcandownloadtheappnameapp: 'Send a link so that the person driving your car can download the {appname} app.',
    txt_global_std_reportaccidentbottomtext: 'Please upload pictures of your car within 24 hours of your report. You can click on your old report above to add pictures to it.',
    txt_global_std_therearenoongoinggamesrightnow: 'There are no ongoing games right now.',
    txt_global_std_loginsignup: 'Login / Signup',
    txt_global_std_alertweredrivingyoudrivingbody: 'Remember to start & stop your drives in the app to get your driving score.',
    txt_global_std_connectyourgarminwatch: 'Connect your Garmin watch',
    txt_global_std_pickendtime: 'Pick end time',
    txt_global_std_NewPW_Text3: 'is:',
    txt_global_std_BeforeFirstBill_Text2: 'What am I going to pay?',
    txt_global_std_wildfirelockedkey: 'Drive with an average level of 4.4 or better for a week to unlock this achievement and earn 5000 points to your Kinto Wallet.',
    txt_global_std_p2ptoconfirmprice: 'to confirm the specified price',
    txt_global_std_selectgroupsintheformandclickonthegeneratebuttonaccessthecode: 'Select groups in the form and click on the generate button access the code',
    txt_global_std_or: 'or',
    txt_global_std_4thdate: '4th',
    txt_global_std_setupmanualstartstop: 'Set up: Manual start & stop',
    txt_global_std_yourdrivingstatsforthistripcomparedtoyour30daysaverage: 'Your driving stats for this trip compared to your 30 days average.',
    txt_global_std_wrongformat: 'Wrong format!',
    txt_global_std_driventimeperperiod: 'Driven time per period',
    txt_global_std_speed: 'Speed',
    txt_global_std_events_overview_total: 'Total',
    txt_global_std_MSIG_InsuranceLetter41: 'Road Transport Act, 1987 (Malaysia)',
    txt_global_std_appcreator: 'App Creator',
    txt_global_std_earnpercentpremiumreductionperperiod: 'Earn {percent} Premium Reduction per period',
    txt_global_std_activationcodevalidated: 'Activation code validated',
    txt_global_std_countday: '{count} day',
    txt_global_std_selectfriend: 'Select friend',
    txt_global_std_july: 'July',
    txt_global_std_ConfirmFia_Text7: 'Remember to arrive at the venue 10 minutes before your selected time slot.',
    txt_global_std_KristofferssonChamp: 'World RX Champion 2017 & 2018',
    txt_global_std_wewillnowbuildyourdemoapp: 'We will now build your demo-app.',
    txt_global_std_youdroveextremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedthisbadge: 'You drove extremely well! Your average {scoretype} was better than {score} for a week! You have earned this badge!',
    txt_global_std_congrats: 'Congratulations!',
    txt_global_std_p2ppushtitlehasnowfinalizedthebooking: 'Booking finialized',
    txt_global_std_noinvoicesyet: 'No invoices yet',
    txt_global_std_consentInfoNo: 'You have not activated your "consent" under settings. When "consent" is not enabled, we will not be able to share all of the exciting competitions and tips provided in the Tryg Drive app on an ongoing basis.',
    txt_global_std_friends: 'Friends',
    txt_global_std_searchbynamegrouporscore: 'Search by name, group or score',
    txt_global_std_balancing: 'Balancing',
    txt_global_std_ChicheritInterests: 'Coming soon...',
    txt_global_std_ayourpolicybeginsonthedateandtimewhenyouelectronicallysigntheapplicationandmakethedownpaymentonyourpolicy: 'A: Your policy begins on the date and time when you electronically sign the application and make the down payment on your policy.',
    txt_global_std_manageagreementshere: 'Manage agreements here',
    txt_global_std_inmostcarstheobdsocket: 'In most cars, the OBD socket is to the left under the steering wheel. If you still cannot find the OBD socket, click here.',
    txt_global_std_p2pgiveadiscountiffriend: 'Give a discount if your friend drives your car in a safe manner.',
    txt_global_std_improvementspeedarea: 'Improvement speed area',
    txt_global_std_otherreasonsthatyourcarisnotshowingupinthelist: 'Other reasons that your car is not showing up in the list:',
    txt_global_std_success: 'Success!',
    txt_global_std_phonenumberforinsurance: 'Enter the phone number used when you signed up for your insurance.',
    'txt_global_std_Al-KhelaifiWhyjoin': 'Because I enjoy different types of challenges and I am a competitive driver.',
    txt_global_std_ifyoureferafriendyoubothgetnok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsuranceamountinmarkedfontshowsvalidamountsaddedtothepiggybankpaymentinordertoreceiveadrivingdividend75ofthedistancetraveledmustbecompletedwiththeappandchiptenfoldremembertohaveboththeappandbluetoothonwhenyoudriveandthetripwillberegistered: 'If you refer a friend, you both get NOK 250 in the piggy bank. This requires that you are both customers of Tryg for at least one month after the start date of the insurance. Amount in marked font, shows valid amounts added to the piggy bank. Payment\n' +
      'In order to receive a driving dividend, 75% of the distance traveled must be completed with the app and chip tenfold. Remember to have both the app and Bluetooth on when you drive, and the trip will be registered.',
    txt_global_std_startdrivingsaferwithmefollowthelinkbelowandget10kmdistancetodrivewithenerfy: 'Start driving safer with me! Follow the link below and get 10Km distance to drive with Enerfy.',
    txt_global_std_parkingaccident2: 'Parking Accident',
    txt_global_std_achievementstitle: 'Achievements',
    txt_global_std_maintainingintendedspeedisutmostimportantforfueleconomymindwanderingandlackoffocusoftencausesspeedvariationupanddowncausingunnecessaryaccelerationshenceincreasesfuelconsumptionnew: 'Maintaining intended speed is utmost important for fuel economy. Mind wandering and lack of focus often causes speed variation up and down, causing unnecessary accelerations, hence increases fuel consumption.',
    txt_global_std_inthenextpopupbr1makesurebprecisebissettobonbbr2chooseballowonceb: 'In the next popup: <br> 1. Make sure <b>Precise</b> is set to: <b>On.</b> <br> 2. Choose <b>Allow Once.</b>',
    txt_global_std_p2pgoheretoinvitemembersandstartlendingyourcar: 'Go here to invite members and start lending your car.',
    txt_global_std_p2piamhavingproblemswiththelinkloggingin: 'I am having problems with the link / logging in.',
    txt_global_std_allowpushnotifications: 'Allow push notifications',
    txt_global_std_currency: 'kr',
    txt_global_std_subscribe: 'Subscribe',
    txt_global_std_MikkelsenFacts: "Andreas has a broad background in sports. He has competed in alpine skiing and was a member of the national juniors' alpine skiing team. In 2003 and 2004, he competed in motocross, also representing the national juniors' team. He finished third in the world rally championship in 2014, 2015 and 2016, collecting three wins and 20 podiums.",
    txt_global_std_expireson: 'Expires on',
    txt_global_std_sentlogfiles: 'Sent log files',
    txt_global_std_athelowerthescorethemoresavingsyoucanearnyouwillseeyourpotentialsavingswitheachtrip: 'A: The lower the score the more savings you can earn. You will see your potential savings with each trip.',
    txt_global_std_encouragetoavoiddistractions: 'Encourage driver to avoid distractions',
    txt_global_std_nationalitycolon: 'Nationality:',
    txt_global_std_pleaseselectvehiclesregistrationnumberyouaredriving: "Please select the vehicle's registration number you are driving.",
    txt_global_std_latesttrips: 'Latest trips',
    txt_global_std_avgscore123: 'Avg. score',
    txt_global_std_other: 'Other',
    txt_global_std_twofactorauthenticationcode: 'Two-Factor Authentication code',
    txt_global_std_writeamessagetoafriendoragroup: 'Write a message to a friend or a group',
    txt_global_std_resendactivationcode: 'Resend activation code',
    txt_global_std_personalizeyouraccount: 'Personalize your account',
    txt_global_std_achievementtool_upsell_pitch: 'Create your own inspiring badges and achievements to;',
    txt_global_std_addemail2: 'add e-mail',
    txt_global_std_addexistinguser: 'Add existing user',
    txt_global_std_miles: 'Miles',
    txt_global_std_drivewithanaveragescoretypeofscoreorbetterforaweektounlockthisbadge: 'Drive with an average {scoretype} of {score} or better for a week to unlock this badge.',
    txt_global_std_p2pfaq6a: '1.Open the group chat that you want to leave.\n' +
      '2.Press the settings icon on your top right in the group chat header.\n' +
      '3.Choose leave this group chat.',
    txt_global_std_writeyourregistrationnumber: 'Write your registration number',
    txt_global_std_p2pcarrentoverview: 'Car rent overview',
    txt_global_std_cimage35h: 'FIND OPTIMAL GEAR FOR START',
    txt_global_std_Fia_Receipt_Text4: 'You can enter this manually when creating an account.',
    txt_global_std_discovermore: 'Discover more',
    txt_global_std_BeforeFirstBill_Text5: 'You can always keep track of your costs in the {appName} app, there you see the cost for every single drive and the overall amount for the month. Go to "Costs" in the app to see your costs.',
    txt_global_std_coolcatsunisshining: 'cool cat sun is shining',
    txt_global_std_riskydecisionwithintrips: 'Risky decision within trips',
    txt_global_std_apireference: 'API reference',
    txt_global_std_defaulthere: 'here',
    txt_global_std_above1000locked: 'Drive with a score above 1000 to unlock this achievement.',
    txt_global_std_allownotifications: 'Allow Notifications',
    txt_global_std_previousbonusperiod: 'Previous bonus period',
    txt_global_std_challengeaverage: 'Challenge average',
    txt_global_std_uploadatleastfourphotosofyourcarfrontbackleftandrightsides: 'Upload at least 4 photos of your car. Front, back, left and right sides.',
    txt_global_std_teamleaderslatestscore: 'Teamleaders latest score:',
    txt_global_std_p2ppleaseselectavehicle: 'Please select a vehicle',
    txt_global_std_wallet: 'Wallet',
    txt_global_std_earnedpoints: 'Earned Points',
    txt_global_std_cohabiting: 'Cohabiting',
    txt_global_std_radiatorflush: 'Radiator flush',
    txt_global_std_single: 'Single',
    txt_global_std_p2pincompletebooking: 'Incomplete booking',
    txt_global_std_electric: 'Electric',
    txt_global_std_notallowedtoorderanobdreader: 'Not allowed to order an OBD reader',
    txt_global_std_finishadriveatscoretypescoreorbettertounlockthisachievementandearnpointspoints: 'Finish a drive at {score} or better to unlock this achievement and earn {points} points. ',
    txt_global_std_tinaQuote: 'Power from within & the Power of choice',
    txt_global_std_threecharactersbetweenazand09: 'Requires three characters between A-Z and 0-9',
    txt_global_std_saveenergyandco2: 'Save energy & CO₂',
    txt_global_std_trainingdate: 'Training date',
    txt_global_std_successsenddetails: 'Successfully sent details',
    txt_global_std_sendinvitetofriend: 'Send invitation to a friend',
    txt_global_std_pigsinvitedlocked: 'You get this when you have recruited a friend to Sidekick',
    txt_global_std_nodatafoundstartdrivingtoseethisstatistic: 'No data found. Start driving to see this statistic!',
    txt_global_std_okdriving: 'Ok driving!',
    txt_global_std_Fia_Receipt_Text5: '',
    txt_global_std_poweredby: 'powered by',
    txt_global_std_demotext6: 'I will help you drive safe! I will guide you on your journey towards five stars.',
    txt_global_std_addall: 'Add all',
    txt_global_std_insuranceoverview: 'Insurance overview',
    txt_global_std_HansenInterests: 'Training, motorsport, and barista',
    txt_global_std_useralreadyexists: 'Could not create the account, e-mail already exists!',
    txt_global_std_rwmnodrivestitle: 'You don’t have any trips yet.',
    txt_global_std_editchallenge: 'Edit challenge',
    txt_global_std_monthlycost: 'Monthly cost',
    txt_global_std_welcometoyournewapp: 'Welcome to your new app.',
    txt_global_std_weknowthisiscrazy: 'We know this is crazy...',
    txt_global_std_ayoucanfileaclaimbycontactingphonenoorclickingonfileaclaiminyouragentapp: 'A: You can file a claim by contacting {phoneNo} or clicking on File a Claim in your {agent} app.',
    txt_global_std_damageguide: 'Damage guide',
    txt_global_std_cimage36h: 'PLAN TO AVOID TOTAL STOP AT OBSTACLES',
    txt_global_std_speedgappoints: 'Speed gap points',
    txt_global_std_howdoisetupautomaticrenewal: 'How do I set up automatic renewal?',
    txt_global_std_availablepoints: 'Available points',
    txt_global_std_locationservicealways: 'Location Service (Always)',
    txt_global_std_signupwith: 'Sign up with {name}',
    txt_global_std_enteravalidplatenumber: 'Enter a valid plate number.',
    txt_global_std_p2pusershareofcost: '{name} share of cost',
    txt_global_std_finalresult: 'Final result',
    txt_global_std_focus: 'Focus',
    txt_global_std_yourbluetoothisntturnedon: "Your Bluetooth isn't turned on",
    txt_global_std_nosplitsavailableforthisrun: 'No splits available for this run',
    txt_global_std_centralzone: 'Central Zone',
    txt_global_std_technicalservice: 'Technical service',
    txt_global_std_cimage9h: 'KEEP STEADY GAS RATHER THAN SPEED',
    txt_global_std_okdriving2: 'Ok driving',
    txt_global_std_coverage: 'Coverage',
    txt_global_std_selectaccount: 'Select account',
    txt_global_std_ifyouhavebluetoothyoucanturnonautomatic: 'If you have Bluetooth in your car, you can turn on automatic start and stop.',
    txt_global_std_p2pgroupnamechanged: 'Group name changed',
    txt_global_std_address: 'Address',
    txt_global_std_discoverourheats: 'Discover our events',
    txt_global_std_sendmessage: 'SEND MESSAGE',
    txt_global_std_p2paddedby: 'Added by',
    txt_global_std_turnonhazardlights: 'Turn on hazard lights',
    txt_global_std_soon: 'soon.',
    txt_global_std_alluserscompeteindividually: 'All, users compete individually',
    txt_global_std_ecofriendweeklyunlockedkey: 'You have saved over {co2value}% CO₂ this week by driving eco friendly. Thank you for caring about the environment!',
    txt_global_std_thepasswordminimumlengthis8charactersandmustinclude1numeralandspecialcharacter: 'The password minimum length is 8 characters, and must include 1 numeral and special character',
    txt_global_std_lastname: 'Last name',
    txt_global_std_translationtool_upsell_pitch: 'Effortlessly manage your own translations and language updates to;',
    txt_global_std_wewillsendtheobdreadertotheaddressyouhaveregisteredwithusneedtochangeanythingcontactsupport: 'We will send the OBD reader to the address you have registered with us. Need to change anything? {contactSupportLink}.',
    txt_global_std_loyaltypages: 'Loyalty Pages',
    txt_global_std_managepermissions: 'Manage Permissions',
    txt_global_std_addressline1: 'Address line 1',
    txt_global_std_youcanfindtheoutlethere: 'You can find the outlet here',
    txt_global_std_whatwillhappeniftheobddeviceisactive: 'What will happen if the OBD device is active but I forget to start the app?',
    'txt_global_std_Al-KhelaifiChamp': 'Two times Champion for Qatar Touring Car Championship. Two times Champion for Qatar National Sprint. 2017 Spa 12H endurance race CUP1 class winner',
    txt_global_std_sharemycar: 'Share my car',
    txt_global_std_hereyoucanuploadanyextrainformationregardingyourclaim: 'Here you can upload any extra information regarding your claim. All fields are optional.',
    txt_global_std_testglobal: 'test global',
    txt_global_std_chassisenginenumber: 'Chassis/Engine number',
    txt_global_std_youarenowloggedin: 'You are now logged in!',
    txt_global_std_engagingteammembersinasharedmissionisgoodforproductivity: 'Engaging team members in a shared mission is good for productivity',
    txt_global_std_letmein: 'Let me in!',
    txt_global_std_badformattingwithkey: 'Bad formatting with key',
    txt_global_std_drives2: 'Drives',
    txt_global_std_good: 'Good',
    txt_global_std_questionsandanswers: 'Questions & answers',
    txt_global_std_uploadfromlist: 'Upload From List',
    txt_global_std_somethingtherenewfake: 'Something there new fake',
    txt_global_std_drivemorethan1kilometertogetmoredetailedanalyticsaboutyourtrip: 'Drive more than 1 kilometer to get more detailed analytics about your trip.',
    txt_global_std_enteryouractivationcodeyoureceived: 'Enter your activation code you received in your activation letter or email when you signed up.',
    txt_global_std_cimage16d: 'Plan every speed increase to maintain planned max speed or to roll as many meters as possible.',
    txt_global_std_yourtripwasnotregisteredifyouhadyourpluginconnected: 'Your trip was not registered. If you had your plug-in connected when you drove the car but did not have the app, Bluetooth or GPS / location services active so that it records driving data, completed trips are charged afterwards at premium levels 10 to 15.',
    txt_global_std_byjoiningthechallengeyouacceptthe: 'By joining the challenge you accept the',
    txt_global_std_yourprofilephotowassaved: 'Your profile photo was saved',
    txt_global_std_imagepickerunlocked: 'Choose unlocked badge icon',
    txt_global_std_ecofriend: 'Eco friend',
    txt_global_std_p2punavaliblebetween: 'Unavailable between',
    txt_global_std_youaregoodtogostartdriving: 'You are good to go, start driving!',
    txt_global_std_thiswindowwillcloseinclosesecondsseconds: 'This window will close in {closeSeconds} seconds',
    txt_global_std_failedtopublishreviews: 'Failed to publish Reviews!',
    txt_global_std_FiaPassword_Text3: 'is',
    txt_global_std_selectyourcountry: 'Select your country',
    txt_global_std_writecurrentpassword: 'Write current password',
    txt_global_std_compare: 'Compare',
    txt_global_std_zoomtofit: 'Zoom to Fit',
    txt_global_std_head2head2: 'Head 2 Head',
    txt_global_std_p2pyouwillnotbeabletosendorreceivebookingsfromthisfriendanymore: 'You will not be able to send or receive bookings from this friend anymore.',
    txt_global_std_addbackground: 'Add background',
    txt_global_std_KristofferssonInterests: 'My family and fitness',
    txt_global_std_passwordforname: 'New password for {name}',
    txt_global_std_collectedpoints: 'COLLECTED POINTS',
    txt_global_std_showdataby: 'Show data by',
    txt_global_std_elite: 'Elite',
    txt_global_std_yourinsurancehasexpired: 'Your insurance has expired.',
    txt_global_std_MarklundMusic: 'I like to listen to interesting documentaries on the radio when driving, however, when I need to concentrate, I prefer to have it totally silent.',
    txt_global_std_congratulationsyouhavesuccessfullyconnectedtoyourcar: 'Congratulations! You have successfully connected to your car.',
    txt_global_std_p2pcomingsoon: 'Coming soon',
    txt_global_std_ofcourseyoumayaddphotoslater: 'Of course you may add photos later',
    txt_global_std_pleaseselectaprojectfrommyprojects: ' Please select a project from My projects.',
    txt_global_std_pleasecheckyouremailtoverify: 'Please check your email to verify',
    txt_global_std_connectyourself: 'Connect yourself!',
    txt_global_std_startdate: 'Start date',
    txt_global_std_youcannoteditthistranslationfromvisualeditorduetotechnicalreasonspleasecontactusourdeveloperswillupdateitforyou: 'You can not edit this Translation from visual editor due to technical reasons. Please contact us, our developers will update it for you.',
    txt_global_std_redeem: 'Redeem',
    txt_global_std_warikan_onboarding8: '4. Invite members to share the car to create a share group',
    txt_global_std_loyaltyemailheadertext: 'and get rewarded!',
    txt_global_std_p2pemailchangedbookings: 'Changed bookings',
    txt_global_std_enterpasswordtopublishchanges: 'Enter password to publish changes',
    txt_global_std_badgeisworthxpoints: 'Badge worth: {points}P',
    txt_global_std_welcometoname: 'Welcome to {appName}',
    txt_global_std_supportedfiles: 'Supported file format: png, svg. Max. file size: X mb.',
    txt_global_std_p2pwearehappythatyouarehere: 'We are happy that you are here!',
    txt_global_std_categorytypeheader: 'Type',
    txt_global_std_earn3500pointsifyoubook15timesamonth: 'Earn 3,500 points when you make 15 reservations in a month.',
    txt_global_std_paynow: 'Pay now',
    txt_global_std_showthisreceiptatcheckout: 'Show this receipt at checkout.',
    txt_global_std_jointco2savings: 'Joint CO₂ savings\n',
    txt_global_std_p2pinvoices: 'Invoices',
    txt_global_std_sparklockedkey: 'Drive with an average level of 4 or better for a week to unlock this achievement and earn 1000 points to your Kinto Wallet.',
    txt_global_std_thefiasmartdrivingchallengeisaworldchallenge: 'The FIA Smart Driving Challenge is a world challenge where smartness beats speed. Get inspired by your team leader and compete with your daily driving.',
    txt_global_std_renewyourinsuranceformonthofmonthatalowercost: 'Renew your insurance for month of {month} at a lower cost',
    txt_global_std_clubs: 'Clubs',
    txt_global_std_p2penterthetemporarypasswordsenttoyouremail: 'Enter the temporary password sent to your email',
    txt_global_std_counthours: '{count} hours',
    txt_global_std_zipcodeglobal: 'Zip-code',
    txt_global_std_p2pconfirmed: 'Confirmed',
    txt_global_std_premiuminsurancecode: 'Premium Insurance Code',
    txt_global_std_adduserstogroup: 'Add users to group',
    txt_global_std_p2pequallydivided: 'Equally divided',
    txt_global_std_afteryoustartdrivingyourinvoiceswillshowuphere: 'After you start driving, your invoices will show up here.',
    txt_global_std_saloon: 'Saloon',
    txt_global_std_awesomestreak: 'Awesome streak!',
    txt_global_std_currentstatus: 'Current status:',
    txt_global_std_emailisalreadyaddedtobeinvited: 'Email is already added to be invited',
    txt_global_std_hereyoucandocumentyourcar: 'Here you can document your car.',
    txt_global_std_include: 'Include',
    txt_global_std_web: 'Web',
    txt_global_std_p2pgoback: 'Go back',
    txt_global_std_carrescue: 'Car rescue',
    txt_global_std_p2pyoudonthaveanyfriendsyet: "You don't have any friends yet.",
    txt_global_std_eventname: 'Event name',
    txt_global_std_this15discountcodecanberedeemedongarmincomonselectedgarminautomotivedevices: 'This 15% discount code can be redeemed on garmin.com on selected Garmin automotive devices.',
    txt_global_std_yourdeviceisonitsway: 'Your device is on its way. When it arrives, all you have to do is to plug it in! In the meantime you can join a team and sign up for heats!',
    txt_global_std_twoormoregroupscompeteagainsteachother: 'Two or more groups compete against each other',
    txt_global_std_agreementnumber2: 'Agreement number:',
    txt_global_std_removingtheuserfromthegroupdoesnotdeletetheusersaccount: 'Removing the user from the group does not delete the users account.',
    txt_global_std_pushdriveendlevel1: 'Wow, you drive is world class! Press here to see details of your drive.',
    txt_global_std_administrateandinviteusers: 'Administrate and invite users.',
    txt_global_std_ecofriendunlockedkey: 'You have saved over {co2value}% CO₂ this week by driving eco friendly. Thank you for caring about the environment!',
    txt_global_std_savemoneyonyourinsurance: '- Save money on your insurance.',
    txt_global_std_afollowthesestepstoconnecttheapptoyourcar1pressthedrivebuttonintheapp2startyourcarsengine3yourcarshouldshowupintheapp4selectyourcar: 'A: Follow these steps to connect the app to your car:\n' +
      '    1. Press the drive button in the app\n' +
      '    2. Start your car’s engine\n' +
      '    3. Your car should show up in the app\n' +
      '    4. Select your car\n',
    txt_global_std_firstdrive4: 'First drive',
    txt_global_std_p2pnothinghaschanged: 'Nothing has changed',
    txt_global_std_recruitmentamount: 'Recruitment amount',
    txt_global_std_repeatyourpassword: 'Repeat your password',
    txt_global_std_selectcategory: 'Select category',
    txt_global_std_howdoidrivewellanswer: 'A safe driver is an aware driver. Safe driving is about staying 100% focused on your driving and on your surrounding environment. Stay alert and take traffic rhythm and other circumstances, such as rain, etc into account. ALWAYS ADJUST YOUR SPEED TO THE PREVAILING CONDITIONS.',
    txt_global_std_choose: 'Choose:',
    txt_global_std_ifyoucannotfindyourfriendorarenotregisteredasauseryourfriendencouragesyoutodownloadtheappnameappandgetaprofile: 'If you can not find your friend or are not registered as a user, your friend encourages you to download the {appname} app and get a profile',
    txt_global_std_addcarinvolved: 'Add car involved',
    txt_global_std_tinaDescription: "2008 I was historically in the Middle East by competing with Nasser Saleh Al-Attiyha (Qatar), right now the largest sports profile there - we won two World Cups in 2008, one in the series and one in the Baja series. In this constellation, there has only been one such successful sports team in the world! That we succeeded in our successes, I would like to thank Nasser's mother, who raised her son not to have preconceived opinions regarding his card reader's skin color, sex or religion. We also led Dakar rally in South America in 2009 when we had to break the sixth stage.\n" +
      '\n' +
      'My career in motor sports started in 1990. Since then, I have won three women´s World Championship titles, two second places in Gr. N. rally world championship and two world cup wins in rally raid 2008 & 2009. To my main merits, I expect a second and third place in the famous Dakar rally.\n' +
      '\n' +
      'Perhaps my most famous drivers have been Colin McRae, Jutta Kleinschmidt, Kenneth Eriksson and Ari Vatanen; In addition, I have navigated to about twenty international drivers.\n',
    txt_global_std_youhavenoactivechallengesrightnow: 'You have no active challenges right now',
    txt_global_std_type2: 'Type',
    txt_global_std_addfriend: 'Add friend',
    txt_global_std_confirmpurchase: 'Confirm purchase',
    txt_global_std_pullthebartoseehowyourleveleffectsyourdiscount: 'Move the marker to see how your level affects your discount.',
    txt_global_std_idontwanttoconnectnow: "I don't want to connect now",
    txt_global_std_p2ppushtitleyouhaveabookingtofinalize: 'You have a booking to finalize',
    txt_global_std_stateinsuranceinformation: '{state} Insurance Information',
    txt_global_std_youhaveexceededthespeedlimitof: 'You have exceeded the speed limit of {maxSpeed} km/h.',
    txt_global_std_copyinvitationlink: 'Copy Invitation Link',
    txt_global_std_carusage: 'Car Usage',
    txt_global_std_selectyourcarmodeltoseethemostaccuratedata: 'Select your car model to see the most accurate data',
    txt_global_std_createyourveryowncompanychallengeinthefiasdcandunderstandandmanagetheimpactyourteamhasonroadsafetyandco2emissions: 'Create your very own company challenge in the FIA SDC and understand and manage the impact your team has on road safety and CO2-emissions.',
    txt_global_std_iobd2: 'iOBD2',
    txt_global_std_safedriverweeklylockedkey: 'To earn this badge, keep a score above {score} for a week.',
    txt_global_std_connecttocarlockedkey: 'Connect your app to the car via Bluetooth to earn this badge.',
    txt_global_std_p2pearnpointswhenyoudrivesafe: 'Earn points when you drive safe',
    txt_global_std_connectwithbluetooth: 'Connect with Bluetooth',
    txt_global_std_detailedstatisticsaboutyourdrivingwilldisplayherewhenyouhavedoneyourfirsttrip: 'Detailed statistics about your driving will display here when you have done your first trip.',
    txt_global_std_entertheinvitationcodeyouhavereceivedfromyourworkspaceadministrator: 'Enter the invitation code you have received from your workspace administrator',
    txt_global_std_division: 'DIVISION',
    txt_global_std_selectfriendsoragroup: 'Select friends or a group',
    txt_global_std_p2ppushtitleexpensesapproved: 'Expenses approved',
    txt_global_std_ifyourfriendregisterswiththereferralcodebothyouandyourfriendwillreceivenok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsurancetheamountisenteredinthepiggybankwhentherecruitmentcodehasbeenregisteredyouhaveafulltimeoverviewofyourrecruitsonthesavingsside: 'If your friend registers with the referral code, both you and your friend will receive NOK 250 in the piggy bank (this requires that you are both customers of Tryg for at least one month after the start date of the insurance).\n' +
      '\n' +
      'The amount is entered in the piggy bank when the recruitment code has been registered. You have a full-time overview of your recruits on the savings side.',
    txt_global_std_stardriverlockedkey: 'Finish a drive at score {score} or better to unlock this badge. You can earn this badge once per day.',
    txt_global_std_createyourowncompanychallengeinjustthreesteps: 'Create your own Company Challenge in just three steps!',
    txt_global_std_p2puserguides: 'User guides',
    txt_global_std_describetheaccident: 'Describe the accident',
    txt_global_std_kintoemailheadertext: 'Join Kinto and get rewarded!',
    txt_global_std_status: 'Status',
    txt_global_std_comingsoon: 'Coming soon!',
    txt_global_std_ChicheritWhyjoin: 'Coming soon...',
    txt_global_std_needhelptogetstarted: 'Need help to get started?',
    txt_global_std_remainingpointstonextlevel: '{points} points remaining to {level}',
    txt_global_std_damagesalvagecall: 'For damages',
    txt_global_std_lookslikethisisempty: 'Looks like this is empty!',
    txt_global_std_monthmon: 'mon',
    txt_global_std_selectdates: 'Select Dates',
    txt_global_std_createyourownappinafewclicks: 'Create your own app in a few clicks',
    txt_global_std_usernotfound: 'User not found.',
    txt_global_std_recruitfivefriendsandyouallcompete: 'Recruit 5 friends and you all compete in the first heat for free',
    txt_global_std_shootingstarnamekey: 'Shooting Star!',
    txt_global_std_conteactinformation: 'Contact information',
    txt_global_std_bestdriversrightnow: 'Best drivers right now!',
    txt_global_std_nameofapp: 'Name of app',
    txt_global_std_quantitytoshow: 'Quantity to show',
    txt_global_std_TaxInvoice8: 'Cover terminates on the 61st day if premium is not paid within 60 days of inception date of the policy.',
    txt_global_std_checkifcorrectobdreader: 'Check if OBD-reader is correctly connected.',
    txt_global_std_youhaveonlydistancekmleft: 'You have only {distance}km left.',
    txt_global_std_p2pfaq8q: 'How do I make my car unavailable on days that I’m using it myself?',
    txt_global_std_thisisadrivingappforustobeabletomeasureyourdrivingweneedtoaccesstobbluetoothlocationservicesbandbmotionfitnessb: 'This is a driving app. For us to be able to measure your driving we need access to <b>Bluetooth, location services</b> and <b>motion & fitness.</b>',
    txt_global_std_HansenWhyjoin: 'I will work closely with my teammates and try to combine the two factors of smart driving. Together we will aim to enjoy our driving, push ourselves to do better and drive super smart and safe! By doing so, I believe we can win every race, even the greatest one of them all: the race for saving our planet!',
    txt_global_std_JyrkiainenMileage: 'I have had driving license for only almost a year but I love the route to Finnish Lapland, the scenery becomes more and more snowy all the time!',
    txt_global_std_earn1500pointsifyoubook5timesamonth: 'Earn 1,500 points when you make 5 reservations in a month.',
    txt_global_std__ongoing: '(Ongoing)',
    txt_global_std_p2pdeletingofmessages: 'Deleting {nocurrent} of {todelete} messages',
    txt_global_std_additionalfiles: 'Additional files',
    txt_global_std_p2pmystatscomingsoon: 'My stats – Coming soon',
    txt_global_std_afriend: 'A friend',
    txt_global_std_PolicySchedule29: 'TIERED EXCESS OF UP TO $2,000 WILL APPLY IN THE EVENT OF AN ACCIDENT SUBJECTED TO THE AVERAGE SCORE.',
    txt_global_std_areyouabsolutelysuperdupersurethatyouwanttoexchangeyourpreciouspointsnowandnotsavethemforlater: 'Are you absolutely super duper sure that you want to exchange your precious points now and not save them for later?',
    txt_global_std_age: 'Age',
    txt_global_std_supportingcluestowhy: 'Supporting clues to "Why" ',
    txt_global_std_maximizetext: 'Trips with bonus',
    txt_global_std_MikkelsenWhyjoin: 'I will use my experience from rally & racing to help people become better drivers. Being a good driver is not only about being fast but being just as efficient and clever.',
    txt_global_std_bronze: 'Bronze',
    txt_global_std_super: 'Super',
    txt_global_std_areyousureyouwanttosignout: 'Are you sure you want to sign out?',
    txt_global_std_sidekicknotifications: 'For the best experience of the app, please allow Sidekick to send you notifications. ',
    txt_global_std_notavailable: 'Not available',
    txt_global_std_hangin: 'Hang in',
    txt_global_std_wildfireunlockedkey: 'You drove extremely well! Your average level was better than 4.4 for a week! 5000 points have been added to your Kinto Wallet.',
    txt_global_std_removeuserfrom: 'Remove user from',
    txt_global_std_borrowmycar: 'Borrow my car',
    txt_global_std_HEAT: 'HEAT',
    txt_global_std_PolicySchedule10: 'Seaters',
    txt_global_std_contactus: 'Contact us',
    txt_global_std_p2pfriendinvitedyouto: 'Your friend invited you to Kinto Car Sharing',
    txt_global_std_jan: 'Jan',
    txt_global_std_sentbyenerfyglobalaproductbygreaterthanab: 'Sent by Enerfy Global, a product by Greater Than AB.',
    txt_global_std_groupchat: 'Group Chat',
    txt_global_std_newpasswordagain: 'New password again',
    txt_global_std_allownotificationstogetdrivingtipsafter: 'Allow Notifications to get driving tips after each drive and take your driving skills to the next level.',
    txt_global_std_cimage36d: ' ',
    txt_global_std_enterthetemporarypasswordsenttoyoubyemail: 'Enter the temporary password sent to you by email.',
    txt_global_std_loyaltydrivers: 'Drivers',
    txt_global_std_fiasdc2019w1price2: '3 Season package',
    txt_global_std_trips: 'Trips',
    txt_global_std_totalscore1: 'Total score',
    txt_global_std_attention: 'Attention',
    txt_global_std_p2preservation: 'Reservation',
    txt_global_std_nothingtoshow: 'Nothing to show',
    txt_global_std_p2ppendingph: '{count} Pending',
    txt_global_std_vehicleinfo: 'Vehicle info',
    txt_global_std_wehavereceivedyourreport: 'We have received your report and your claim will be handled shortly',
    txt_global_std_warikan_onboarding6_2: '14F, Nagoya Mitsui Building North Tower, 4-8-18 Meieki, Nakamura-ku, Nagoya, Aichi 450-0002, Japan',
    txt_global_std_50kmlockedkey: 'Drive a distance of 50 km to earn this badge.',
    txt_global_std_ayouwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithappnamewithalapseincoverage: 'A: You will have {days} days from the expiration date to make a payment and continue your policy with {appName} with a lapse in coverage.',
    txt_global_std_contact: 'Contact',
    txt_global_std_this15discountcodecanberedeemedagainstanygarminpndpurchase: 'This 15% discount code can be redeemed against any Garmin PND purchase.',
    txt_global_std_p2pscore: 'Score:',
    txt_global_std_nodataavailable1: 'no data available',
    txt_global_std_uploadextrareportinfo: 'Upload extra report info',
    txt_global_std_signout: 'Sign out',
    txt_global_std_herearetheresultsofthechallengename: 'Here are the results of the ',
    txt_global_std_maxxcharactersrule: 'Max {x} characters',
    txt_global_std_enteryourinformation: 'Enter your information',
    txt_global_std_p2pdrivendistance: 'Driven distance',
    txt_global_std_earn10000pointsifyouupgradetovalueplan: 'Earn 10,000 points when you upgrade to Value Plan.',
    txt_global_std_nationality: 'Nationality',
    txt_global_std_tiphighway4DESC: 'The red car driver uses brake and gas more often',
    txt_global_std_delete: 'Delete',
    txt_global_std_startguidetip4: 'Let the app load until you see the score circle.',
    txt_global_std_howmuch: 'How much',
    txt_global_std_arequesthasbeenreceivedtochangethepasswordforyour: 'A request has been received to change the password for your ',
    txt_global_std_thislinkexpiresin: 'This link expires in',
    txt_global_std_p2preceipt: 'Receipt',
    txt_global_std_nochallengestojoinyet: 'No challenges to join yet',
    txt_global_std_b1gotosettingsforappnameinyourphonebbrb2allowaccesstobluetoothb: '<b>1. Go to settings for {appname} in your phone.</b> <br> <b>2. Allow access to Bluetooth</b>',
    txt_global_std_view: 'View',
    txt_global_std_daterange: 'Date range',
    txt_global_std_youdrovesupremelywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'You drove supremely well! Your average was better than {score} for a week! You have earned {points} points!',
    txt_global_std_p2psuccessfullyeditedadminrights: 'Successfully edited admin rights',
    txt_global_std_soclosetoearningachievement: "You were so close to earning an achievement and gaining loyalty points. Let's focus!",
    txt_global_std_MSIG_Onboarding4: 'onwards. Find your certificate of insurance and other information attached in this email.',
    txt_global_std_yourinvitewassenttoemail: 'Your invite was sent to {email}',
    txt_global_std_p2pincreasetextsize: 'Increase text size',
    txt_global_std_p2pfindmycarcomingsoon: 'Find my car – Coming soon',
    txt_global_std_monthlysavings: 'Monthly savings',
    txt_global_std_stats: 'Stats',
    txt_global_std_50offdeductible: '50% off deductible',
    txt_global_std_ongoing__: '(Ongoing)',
    txt_global_std_startday: 'Start day',
    txt_global_std_addcomparison: 'Add comparison',
    txt_global_std_awesomedriveroftheday: 'Awesome driver of the day',
    txt_global_std_selectformat2: 'Select format',
    txt_global_std_aug: 'Aug',
    txt_global_std_choosebetweenenteringchassisnumberplatenumberorpolicynumber: 'Choose between entering chassis number, plate number or policy number.',
    txt_global_std_PolicySchedule5: 'Insured',
    txt_global_std_cimage8h: 'ACCELERATE GENTLY',
    txt_global_std_bevisibleorhiddenintheleaderboard: 'Be visible/hidden in the Leaderboard',
    txt_global_std_findyourfriends: 'Find your friends',
    txt_global_std_SulayemQuote: 'Quote coming soon...',
    txt_global_std_p2psplittingthecostbydistancetravelledrequiresbluetoothconnection: 'Splitting the cost by distance travelled requires Bluetooth connection.',
    txt_global_std_errorcodes: 'Error codes',
    txt_global_std_MSIG_InsuranceLetter40: 'SGD3,500.00 SECTION I & II SEPARATELY IS IMPOSED ON THOSE DRIVERS WHO ARE BELOW 22 YEARS OLD AND/OR WHO HAVE LESS THAN 2 YEARS OF DRIVING EXPERIENCE SGD1,000.00 ALL CLAIMS IS IMPOSED ON DRIVERS WHO HAVE DEACTIVED THE DONGLE FOR AT LEAST 14 DAYS IN A CALENDAR YEAR. TIERED EXCESS OF UP TO $2,000.00 WILL APPLY IN THE EVENT OF AN ACCIDENT SUBJECTED TO THE AVERAGE SCORE AN EXCESS OF $100.00 IS APPLICABLE FOR EACH AND EVERY WINDSCREEN CLAIM\t\n',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithcovercubewithalapseincoverage: 'You will have {days} days from the expiration date to make a payment and continue your policy with Covercube with a lapse in coverage.',
    txt_global_std_inserttheobdreaderandstartyourcarwaitforthereadertoshowabluelight: 'Insert the OBD reader and start your car. Wait for the reader to show a blue light.',
    txt_global_std_yourcarisntturnedonnodot: "Your car isn't turned on.",
    txt_global_std_qdoescovercubeacceptdriversthatarenotlicensedintheunitedstates: 'Q: Does Covercube accept drivers that are not licensed in the United States?',
    txt_global_std_1year: '1 year',
    txt_global_std_warikan_onboarding10: '* This email address is for sending only. Please note that we cannot respond to this email directly.\n',
    txt_global_std_welcometoteam: 'Welcome to {team}',
    txt_global_std_p2pfaq30a: 'Every month, you receive an invoice from the owner, and the message "You received the details of the invoice" is displayed in "What to do" on the home screen. Alternatively, you can check it from "Warikan" in "Menu".\n' +
      '1. After selecting "Warikan", check the details from the Invoice tab.\n' +
      '2. After confirming the billing details, select "Continue" to confirm the payment amount.\n' +
      '3. Select a payment method and select "Pay Now".',
    txt_global_std_PaddonFacts: 'Hayden has started driving go-karts at the age of 6. Lived and breathed motorsport all his life.',
    txt_global_std_yourobdreaderisonitsway: 'Your OBD reader is on its way and will arrive in 3-5 business days. Follow the next step how to easily install it.',
    txt_global_std_timezone: 'Time zone',
    txt_global_std_youravgscoreis123: 'Your average score is',
    txt_global_std_welcometocovercube_: 'Welcome to Covercube!',
    txt_global_std_thisisatextmessage: 'This is a text message.',
    txt_global_std_challengefriend2: 'Challenge friend!',
    txt_global_std_Fia_Receipt_Text9: 'Quantity',
    txt_global_std_theworldchampionshipwheresmartnessbeatsspeed: 'the World challenge where smartness beats speed. Compete in your everyday driving and get coached by the pros to become the smartest driver in the world.',
    txt_global_std_events_overview_monthly: 'Monthly',
    txt_global_std_wildfirenamekey: 'Wildfire!',
    txt_global_std_p2pchangedthenameofthegroup: 'changed the name of the group',
    txt_global_std_socialbutterflynamekey: 'Social Butterfly!',
    txt_global_std_invalidvouchercode: 'Invalid voucher code',
    txt_global_std_2nd: '2:nd',
    txt_global_std_p2perrorcodecarmustbebonded: 'Car must be bonded.',
    txt_global_std_expensessummary: 'Expenses summary',
    txt_global_std_everyday: 'Every day',
    txt_global_std_whatwillitcosttodrivewithoutbeing: 'What will it cost to drive without being connected to my car?',
    txt_global_std_p2peasyoverviewinyourcalendar: 'Easy overview in your calendar',
    txt_global_std_featured: 'Featured',
    txt_global_std_youdrovesupremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'You drove supremely well! Your average {scoretype} was better than {score} for a week! You have earned {points} points!',
    txt_global_std_contactdetailss: 'Contact details',
    txt_global_std_congratulationsonthistypeoftripyouareanecochampionthanksforbeinganecofriendlydriver: 'Congratulations! On this type of trip, you are an eco champion! Thanks for being an eco-friendly driver!',
    txt_global_std_networkerrorpleasetryagainlater: 'Network error, please try again later',
    txt_global_std_orientering: 'Anticipate',
    txt_global_std_refer10friendstounlockthisbadge: 'Refer 10 friends to unlock this badge.',
    txt_global_std_downloadapispecification: 'Download API Specification',
    txt_global_std_showchanges: 'Show changes?',
    txt_global_std_contributesustainabilitythroughwarikan: 'Contribute to sustainability through Warikan KINTO',
    txt_global_std_makeitempty: 'Make it empty!',
    txt_global_std_changeemail: 'Change e-mail',
    txt_global_std_letsfindyourcar: "Let's find your car",
    txt_global_std_MSIG_Onboarding13: 'MS First Capital Insurance Ltd | Corporate Office: 6, Raffles Quay, #21-00 | Singapore 048580 | Tel: (65) 6222 2311',
    txt_global_std_profilesetup: 'Profile setup',
    txt_global_std_co2saverunlockedkey: 'Great job! You saved over {co2value}% CO₂ this trip by driving eco friendly.',
    txt_global_std_MSIG_InsuranceLetter25: "Use only for social, domestic and pleasure purposes and for the Insured's business.",
    txt_global_std_goldusers: 'Gold users',
    txt_global_std_iwillfixitlater: 'I will fix it later',
    txt_global_std_joinwithinvitationcode: 'Join with invitation code',
    txt_global_std_statisticsshowingyourpersonalprogress: 'Statistics showing your personal progress.',
    txt_global_std_mandatory: 'Mandatory',
    txt_global_std_p2pfaq20q: 'Is it possible to check the driving data?',
    txt_global_std_allowlocationservicetogetaccuratescore: 'Allow Location Service to get personalized tips and accurate score.',
    txt_global_std_templates: 'Templates',
    txt_global_std_enterpolicynumberhere: 'Enter policy number here',
    txt_global_std_shootingstarunlockedkey: 'You drove at level 3.3 or better! Great! 100 points have been added to your Kinto Wallet.',
    txt_global_std_addadditionalfiles: 'Add additional files',
    txt_global_std_hasbeeninvitedasaroletoyourteam: 'They have been invited with the permissions of {role}',
    txt_global_std_mpltipiip: 'This is skilled driver but indicate driving inconsistency. In this case, \n' +
      'the driver is ignoring Cool Driving for a random day or trip. This is a \n' +
      'combination of ignoring Cool Driving during a day or just for one trip \n' +
      'during a day. To begin with, we recommend that trainer together with \n' +
      'driver, try to figure out what the problem may be.',
    txt_global_std_p2phasreceivedyourbooking: 'has received your booking request.',
    txt_global_std_forthisapptoworkproperlyweneedtoaccessnobr: 'To be able to register a drive you need to\n' +
      'allow bluetooth, location services and\n' +
      'motion & fitness.',
    txt_global_std__resendtemporarypassword: 'Resend temporary password.',
    txt_global_std_translationtool_upsell_pitch_bulletpoint2: 'Match communications to your brand’s tone of voice ',
    txt_global_std_yourdatahasbeensent: 'Your data has been sent, please check your inbox!',
    txt_global_std_prev: 'Prev',
    txt_global_std_today: 'Today',
    txt_global_std_MSIG_InsuranceLetter21: 'The Insured may also drive a Motor Car not belonging to or hired (under a hire purchase agreement or otherwise) to him or his employer or his partner.',
    txt_global_std_OgierDescription: "He is the current holder of the World Rally Drivers' Championship, having won the title six times, in 2013, 2014, 2015, 2016, 2017 and 2018. With 44 victories in the World Rally Championship and six consecutive WRC titles, he is the second most successful WRC driver, after former Citroën WRC teammate Sébastien Loeb (nine titles).",
    txt_global_std_terrainhandling: 'Terrain handling',
    txt_global_std_readytogoappsolutionsthatwehavehandpickedforyou: 'Ready-to-go app solutions that we have handpicked for you.',
    txt_global_std_callwithdriver: 'On site training',
    txt_global_std_whatwillhappenififorgettoconnect: 'What will happen if I forget to connect to my car?',
    txt_global_std_filteroptionnotinuse: 'Not in use',
    txt_global_std_youhavenowmaintainedafullweeksdrivingwithanaveragescoreabove4: "You have now maintained a full week's driving with an average score above 4.0",
    txt_global_std_icannotconnectanswertext1: 'Make sure you complete all the steps in the “Connect to Car”. Please email ',
    txt_global_std_anewpasswordhasbeengeneratedforuser: 'A new password has been generated for {user}',
    txt_global_std_ConfirmFia_Text10: 'Are you ready for your SDC test drive?',
    txt_global_std_fiasdc2019w1price1: '5 Season package',
    txt_global_std_p2pselectthedatesandtime: 'Select the dates and time you want to make your car unavailable',
    txt_global_std_p2pfaq14q: 'What if I return the car too late?',
    txt_global_std_p2pfaq30q: 'Please tell me how to pay the cost.',
    txt_global_std_groupnames: 'Group names',
    txt_global_std_youcanrepublishyourtranslationslaterifyouwant: 'You can republish your translations later if you want.',
    txt_global_std_p2pfaq22q: 'I want to record additional costs.',
    txt_global_std_speedchangeanalysis2: 'Speed change analysis',
    txt_global_std_p2pfaqcontactinfo1: '・ Reception hours 9:00 to 18:00 (excluding the year-end and New Year holidays)',
    txt_global_std_areyousureyouwanttodeletememberfromworkspacethisactioncannotbeundone: 'Are you sure you want to delete user? This action cannot be undone. \n' +
      '\n' +
      'Please note that the users’ own account in the app won’t be deleted.',
    txt_global_std_tripdetailshavebeensenttoemail1: 'Trip details have been sent to email.',
    txt_global_std_resendactivationcodeto: 'Resend activation code to {name}',
    txt_global_std_mars: 'Mars',
    txt_global_std_activationcodeoptional: 'Activation code (optional)',
    txt_global_std_lockeddescriptionlabel: 'Locked description, shown in app',
    txt_global_std_minute: 'Minute',
    txt_global_std_enerfycoachtip1: '1. Stay 100 % focused on your driving.',
    txt_global_std_p2pcostsplitsettings: 'Cost split settings',
    txt_global_std_p2pnamesentaneditrequesttoname2: '{name} sent an edit request to {name2}',
    txt_global_std_changebackground: 'Change background image',
    txt_global_std_ridewithmehowitworks: 'How it works',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithappnamewithalapseincoverage: 'You will have {days} days from the expiration date to make a payment and continue your policy with {appName} with a lapse in coverage.',
    txt_global_std_youhavereachthemaximumlevel: 'You have reach the maximum Level!',
    txt_global_std_p2pfriendadminrights: 'Friend admin rights',
    txt_global_std_tipcity1DESC: 'The blue car driver keeps fair distance to surrounding cars. \n' +
      'The red car driver accelerates and brakes unnecessarily often',
    txt_global_std_ConfirmFia_Text2: 'You have a reserved spot at the launch of FIA Smart Driving Challenge in Paris.',
    txt_global_std_wearebuildingyourworkspace: 'We are building your workspace',
    txt_global_std_events: 'Events',
    txt_global_std_teamage: 'Age:',
    txt_global_std_NewPW_Text2: 'Your new password to the user',
    txt_global_std_safetyalarms: 'Safety alarms',
    txt_global_std_superadmin: 'super admin',
    txt_global_std_p2pfaq21a: 'You can check all registered members by selecting "Members" from the "Menu".',
    txt_global_std_workshops: 'Workshops',
    txt_global_std_failedtogettranslationoverview: 'Failed to get TranslationOverview',
    txt_global_std_obdreadersnearby: 'OBD-readers nearby',
    txt_global_std_p2pfaq1a: 'Those are really cool functions that will be available in the app soon. So, keep your eye out for updates!',
    txt_global_std_numberofexecutions: 'Number of executions',
    txt_global_std_thisisyourusername: 'This is your username',
    txt_global_std_daysleft: 'Days left',
    txt_global_std_p2paddedexpenses: 'Expenses',
    txt_global_std_drivingdata: 'Driving Data',
    'txt_global_std_Al-KuwariWhyjoin': 'Because I create a family within the team.',
    txt_global_std_p2pitsalittleemptyinhere: "It's a little empty in here",
    txt_global_std_oldpassword: 'Old password',
    txt_global_std_permission: 'Permission',
    txt_global_std_iconback: '{icon} Back',
    txt_global_std_KleinschmidtMileage: 'Too me, my experience of mileage is depending on the current traffic situation and on my personal situation',
    txt_global_std_followupontheonboardingstatusofyourusers: 'Follow-up on the onboarding status of your users',
    txt_global_std_jointhechallenge: 'Join the challenge',
    txt_global_std_bronzelevel: 'Bronze Level',
    txt_global_std_p2pview: 'View',
    txt_global_std_KristofferssonWhyjoin: 'If you see a competition as a challenge and your goal is to give 100 % with the conditions you have - then you should join my team.\n',
    txt_global_std_youcollectedpointsduringthepreviosperiod: 'You collected {points} points during the previous period.',
    txt_global_std_noogpsdatafound: 'No GPS data',
    txt_global_std_safetydrivingskillupwithwarikankintobody: 'Warikan KINTO helps you to skill-up your safety driving. Scoring function and badge acquisitions are available even if you are not KINTO ONE user.',
    txt_global_std_ifyouwanttolendyourcartoafriendyoushouldinviteyourfriendtotheappnameappbytappingthebuttonbelow: 'If you want to lend your car to a friend you should invite your friend to the {appname} app by tapping the button below.',
    txt_global_std_activity: 'Activity',
    txt_global_std_end: 'End',
    txt_global_std_p2pcancelthisbooking: 'Cancel this booking?',
    txt_global_std_heat123: 'HEAT',
    txt_global_std_adgangtillokalitet: 'Allow Location Services',
    txt_global_std_secureyourlocation: 'Secure your location',
    txt_global_std_rwmnofriendstext: 'Invite a friend to be able to register a ridesharing trip.',
    txt_global_std_yourpolicyisreneweddate: 'Your policy is renewed {date}',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimeinconstantspeedpayingattentiontoyourenvironmenthelpsyoutosmoothlyandsteadilyvaryyourspeedaccordinglythroughoutyourtrip: 'On this type of trip, the average eco champion spent less time in constant speed. Paying attention to your environment helps you to smoothly and steadily vary your speed accordingly throughout your trip.',
    txt_global_std_compilinginformationdrive: 'Compiling information from your drive...',
    txt_global_std_region: 'Region',
    txt_global_std_videotutorials: 'Video tutorials',
    txt_global_std_PaddonBesttip: 'Be patient it’s my strongest advice. It’s very easy to become tense or impatient with other road users when on the road, but remember, a small speed difference will only make a few minutes difference at the end. So, relax, turn up the music and enjoy your ride.',
    txt_global_std_Fia_Receipt_Text16: '',
    txt_global_std_findanagent: 'Find an agent',
    txt_global_std_insurancenumbernumber: 'Insurance number: {number}',
    txt_global_std_p2pwriteamessagetoafriendorgroupregardingabookingoranythingselsethatsonyourmind: "Write a message to a friend or group regarding a booking or anything else that's on your mind.",
    txt_global_std_features: 'Features',
    txt_global_std_co2savings: 'CO₂ savings',
    txt_global_std_p2pselectmember: 'Select member',
    txt_global_std_getsarouteforagiventrip: 'Gets a route for a given trip',
    txt_global_std_notheappworksinbackgroundmodemakesureyoualwayskeepbluetoothandlocationservicesactivatedonyourphone: 'No, the app works in background mode. Make sure you always keep Bluetooth and location services activated on your phone.',
    txt_global_std_p2ppaid: 'Paid',
    txt_global_std_p2pemailsent: 'Email sent!',
    txt_global_std_acceleration: 'Acceleration',
    txt_global_std_nocloudconnection: 'No cloud connection',
    txt_global_std_pointsoverview: 'Points overview',
    txt_global_std_emailnotfound: 'Email not found',
    txt_global_std_textwelcomewiget: 'This is gonna be so fun! Let’s start driving smart. I’ll be around to guide you through scoring high!',
    txt_global_std_PSolbergBesttip: 'Coming soon...',
    txt_global_std_Insurance_WelcomeLetter: 'Welcome Letter',
    txt_global_std_countdays: '{count} days',
    txt_global_std_privateorbusiness: 'Private or Business?',
    txt_global_std_addphoto: 'Add photo',
    txt_global_std_private2: 'Private',
    txt_global_std_loadingyourdrives: 'Loading your drives...',
    txt_global_std_whathappensififorgettorenewbeforemyinsurancehasexpired: 'What happens if I forget to renew before my insurance has expired?',
    txt_global_std_invitesent: 'Invite sent',
    txt_global_std_recommended: 'Recommended',
    txt_global_std_letsdrivewithwarikankinto: 'Let’s drive with Warikan KINTO!',
    txt_global_std_mobilenumber: 'Mobile number',
    txt_global_std_p2pgoheretocreateabookingofyourfriendscar: 'Go here to create a booking of your friend’s car.',
    txt_global_std_wantmoreindepthanalysis: 'Want more in depth analysis and detailed insights with instant identification of high- and low-risk drivers?',
    txt_global_std_monthm: 'm',
    txt_global_std_alinktodownloadyourdemoappwillbesenttoemailwhenready: 'A link to download your demo-app will be sent to {email} when ready.',
    txt_global_std_countminutes: '{count} minutes',
    txt_global_std_OSolbergMusic: 'Coming soon...',
    txt_global_std_remainingpoints: 'Remaining points',
    txt_global_std_cimage13h: 'LET SPEED DROP',
    txt_global_std_norweigan: 'Norweigan',
    txt_global_std_ifyouwantinfoonnotificationscallphonenumberorgotoweburlorgotothefaqpagelink: 'If you want info on notifications call {phoneNumber} or go to {webUrl}. Or go to the {faqPageLink}. ',
    txt_global_std_startyourcarsengine: 'Start your car’s engine',
    txt_global_std_p2pviewless: 'View less',
    txt_global_std_cimage18h: 'PLANNED AVERAGE SPEED',
    txt_global_std_startdrivingtoappearontheleaderboard: 'Start driving to get a score on the leaderboard!',
    txt_global_std_p2pselectvehicle: 'Select vehicle',
    txt_global_std_aonceyoupairtheapptoyourcehiclesbluethoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhatscoreyougetwhendriving: "Once you pair the app to your vehicle's Bluetooth and allow the app to use location services, we match your driving habits to our AI’s library of driver patterns and provide a score. Your insurance premium is based on what score you get when driving.",
    txt_global_std_connecttocarunlockedkey: 'Nicely done! You connected to the car and are ready to drive. Be safe!',
    txt_global_std_listview: 'List view',
    txt_global_std_ifyourcarhasbluetoothbutdoesnotshowupintheappyoumightneedtocheckthebluetoothsettingsforbothyourphoneandyourcartryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagain: 'If your car has Bluetooth, but does not show up in the app, you might need to check the Bluetooth settings for both your phone and your car. Try resetting your car’s connection to your phone by removing it and then adding it again.',
    txt_global_std_PolicySchedule2: 'Agency.',
    txt_global_std_invitenewmember: 'Invite new user',
    txt_global_std_topimprover: 'Top improver',
    txt_global_std_advancedsettings: 'Advanced settings',
    txt_global_std_inviteafriend: 'Invite a friend',
    txt_global_std_excellentdriving: 'Excellent driving!',
    txt_global_std_conversionkmmi: 'Conversion km/mi',
    txt_global_std_p2pborrowcar: 'Borrow car',
    txt_global_std_competitionrules: 'Competition rules',
    txt_global_std_smspackagefiasdcactivation: 'Welcome to the FIA SDC!\n' +
      'Please click the link down below to automatically download app and join FIA SDC. \n' +
      '\n' +
      '[LINK]\n' +
      '\n' +
      'Activation code: {activationcode}',
    txt_global_std_challangeranking: 'Your challenge ranking',
    txt_global_std_gdprterms: 'GDPR Terms',
    txt_global_CoreTemplate_more: 'More',
    txt_global_std_riskmatcha: 'Risk Match {type}',
    txt_global_std_newpwdcheckinbox: 'Please check your inbox!',
    txt_global_std_towed: 'Towed',
    txt_global_std_averagecosavings: 'Average CO₂ savings',
    txt_global_std_startguide: 'Start guide',
    txt_global_std_bysigningupyouaccepttheusertermsprivacypolicy: 'By signing up you accept the {userTerms} & {privacyPolicy}',
    txt_global_std_cimage34d: ' ',
    txt_global_std_p2pdonthaveatemporarypasswordregisterat: 'Don’t have a temporary password? \nRegister at [Kinto Website]',
    txt_global_std_riskydecisionbetweendays: 'Risky decision between days',
    txt_global_std_exceededlimit: 'Exceeded limit',
    txt_global_std_accountstartdate: 'Account start date',
    txt_global_std_exchangedpoints: 'Exchanged points',
    txt_global_std_challengetermsconditions: 'Challenge Terms & Conditions',
    txt_global_std_animalcollision123: 'Animal Collision',
    txt_global_std_litre: 'Litre',
    txt_global_std_p2pyoureceivedaninvoicetosplitcost: 'You received an invoice to split cost.',
    txt_global_std_PolicySchedule13: 'MARKET VALUE AT THE TIME OF LOSS',
    txt_global_std_appcreator_upsell_pitch_bulletpoint2: 'Pilot and grow your digital offering without costly development time ',
    txt_global_std_timeline2: 'Timeline',
    txt_global_std_buylater: 'Buy later',
    txt_global_std_p2pmakeseparatebookings: 'Make separate bookings',
    txt_global_std_skillscomparison: 'Skills comparison',
    txt_global_std_intraining: 'In training',
    txt_global_std_p2pfaq5q: 'How do I enlarge an image in messages?',
    txt_global_std_alertdrivingtext: 'You are now technically driving.',
    txt_global_std_sonicethatyoudecidedtojoinus: 'So nice that you decided to join us! ',
    txt_global_std_buyheatsnowtocompete: 'Buy heats now to compete!',
    txt_global_std_wecanretrievedataformultiplecompletedtripsinasinglerequesthoweverwerecommendnottosendrequestswithdataexceeding50mb: 'We can retrieve data for multiple completed trips in a single request. However we recommend not to send requests with data exceeding 50MB.',
    txt_global_std_doyouwanttojointeam: 'Do you want to join Team',
    txt_global_std_inviteafriendtostartachallenge: 'Invite a friend to start a challenge!',
    txt_global_std_driving: 'Driving',
    txt_global_std_allusers: 'All users',
    txt_global_std_searchbyfilter: 'Search by filter',
    txt_global_std_youonlyhavedistancelefttotravelleft: 'You have only have {distanceLeftToTravel} left.',
    txt_global_std_selectrecipients: 'Select recipients',
    txt_global_std_startadrivemanually: 'Start a drive manually',
    txt_global_std_topdrivers: 'Top drivers',
    txt_global_std_whenyourfriendusesyourreferralcodetoactivateanewsidekickcarinsurancebothofyouget250krinthepiggybank: 'When your friend uses your referral code to activate a new Sidekick car insurance, both of you get 250 kr in the piggy bank. ',
    txt_global_std_warikan_onboarding2: 'Thank you for applying to use the Warikan KINTO app.',
    txt_global_std_achievementtool: 'Achievement tool',
    txt_global_std_addexpenses: 'Add expenses',
    txt_global_std_communication: 'Communication',
    txt_global_std_idonthavebluetoothinmycar: "I don't have Bluetooth in my car",
    txt_global_std_changeswillnotbesaved: 'Changes will not be saved',
    txt_global_std_1findyourobdportinyourcar2inserttheobdreaderandstartyourcarwaitforthereadertoshowabluelight3turnonyourphonesbluetooth4oncetheapprecognisestheobdreaderyoullmoveautomaticallytothenextstep5selectyourobddevicefromthelistobdmini2: "1: Find your OBD port in your car. 2: Insert the OBD reader and start your car. Wait for the reader to show a blue light. 3: Turn on your phone’s Bluetooth. 4: Once the app recognises the OBD reader, you'll move automatically to the next step. 5: Select your OBD device from the list: OBD Mini 2",
    txt_global_std_p2pwearegladthatyouwanttousewarikanasawaytoshareyourcar: 'We are glad that you want to use Warikan as a way to share your car.',
    txt_global_std_welcomeback: 'Welcome back!',
    txt_global_std_alerthighusagetext: 'Warning! High usage [amount] kr.',
    txt_global_std_wrongusernameorpassword: 'Wrong username or password',
    txt_global_std_p2pborrowed: 'borrowed',
    txt_global_std_telephonecontact: 'Telephone',
    txt_global_std_p2pemailnotpaidbookings: 'Not paid bookings',
    txt_global_std_enterodometervalue: 'Enter odometer value',
    txt_global_std_stopdrivingq: 'Stop driving?',
    txt_global_std_pushnotdrivenforesometimetitle2: 'We miss you our friend! ',
    txt_global_std_byinvitingfriendsyouwillearnloyaltypoints: 'By inviting friends you will earn loyalty points',
    txt_global_std_100offdeductible: '100% off deductible',
    txt_global_std_areyousure: 'Are you sure?',
    txt_global_std_p2pfaq9a: 'Ask your friend to go to the booking summary and press finish booking. The booking will then be finished, and your car will be available to lend again.',
    txt_global_std_undertheusersectionextendittoupdateyouraccountdetailsorlogoutfromtheadminportal: 'Extend the user section to update your account details, view payments or log out from the workspace.',
    txt_global_std_cimage17h: 'ACTIVE DRIVING ON HIGHWAY',
    txt_global_std_MSIG_InsuranceLetter33: 'Company Reg. No. 195000106C',
    txt_global_std_p2pyouwillnotbeabletoseeorwriteanymessagesinthisgroupchat: 'You will not be able to see or write any messages in this group chat.',
    txt_global_std_calculatethedates: 'Calculate the dates',
    txt_global_std_whywouldyou: 'Why would you?',
    txt_global_std_workspace: 'Workspace',
    txt_global_std_yesterday: 'Yesterday',
    txt_global_std_smartdriverweekly: 'Smart driver weekly',
    txt_global_std_private: 'Private',
    txt_global_std_effectivedates: 'Effective dates',
    txt_global_std_whatdoineedtodoinordertoconnectmycar: 'What do I need to do in order to connect my car?',
    txt_global_std_yourfriendsnickname: 'Your friends username',
    txt_global_std_p2pcarborrow: 'Car',
    txt_global_std_tuneup: 'Tune up',
    txt_global_std_findmycar2: 'Find my car',
    txt_global_std_gamificationinspirestosmarterdrivingforlowerco2imprintandfewerroadaccidents: 'Gamification inspires to smarter driving for lower CO2 imprint and fewer road accidents',
    txt_global_std_turnonlocationservicesinyourphone: 'Turn on Location services (GPS) in your phone.',
    txt_global_std_youhavebeenchallengedtoahead2headacceptitnow: 'You have been challenged to a head to head. Accept it now.',
    txt_global_std_managetheimpactyourteamhasonroadsafetyandco2emissions: 'Manage the impact your team has on road safety and CO2 emissions',
    txt_global_std_startguidetip1: 'Make sure the OBD-reader is connected into the cars OBD-connection.',
    txt_global_std_p2pconfirmedph: '{count} Confirmed',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscores: "A: Once you pair the app to your vehicle's Bluetooth and allow the app to use location services, we match your driving habits to our AI’s library of driver patterns and provide a score. Your insurance premium is based on who drives your vehicle and how well they drive. Whoever is driving your vehicle is encouraged to download the app and track their scores. ",
    txt_global_std_statusheader: 'Status',
    txt_global_std_25offdeductible: '25% off deductible',
    txt_global_std_validatedata: 'Validate data',
    txt_global_std_newtoagent: 'New to {agent}?',
    txt_global_std_whydoyouwanttoterminateyourinsurance: 'Why do you want to terminate your insurance?',
    txt_global_std_user: 'User',
    txt_global_std_ourinsurancesolutionisbaseduponafixedandvariablecost: 'Our insurance solution is based upon a fixed and a variable cost. The fixed cost is based on the insured vehicle and the vehicle owners registered address, and is billed monthly in advance, regardless of how often and in which way the vehicle is used. The variable cost is based on the insurance coverage, mileage and your driving behavior. In order to calculate and price the cost of your driving, the included OBD device (Enerfy plug-in) must be installed in your vehicle - or - if your vehicle has the option to connect your phone via Bluetooth directly - the Enerfy app has to be running in your smartphone while driving. Your variable cost is then calculated based on premium levels, i.e. driving levels from one (1) to (15) fifteen, based on your individual driving behavior, where premium level 1 is the level with the lowest cost per kilometer and premium level 15 is the highest. The variable cost is accumulated for each drive and is billed the coming month. If you are not connected and your drives are not registered, you will be billed at premium level 10.',
    txt_global_std_p2pyouneedtocreateabookingbeforeaddingaexpense: 'You need to create a booking before adding a expense.',
    txt_global_std_trygonboarding1: "We are really glad you decided to join us! Let's get on with getting your profile ready.",
    txt_global_std_p2pcostsplit: 'Cost split',
    txt_global_std_KristofferssonBesttip: 'Keep your eyes far ahead to anticipate traffic. This will give you more time to plan your driving.',
    txt_global_std_acceptconnectiontogetascore: 'Accept connection to get a score',
    txt_global_std_p2ppasswordnotmatchedpleasetryagain: 'Password not matched. Please try again.',
    txt_global_std_woman: 'Woman',
    txt_global_std_onesofthebestsdrivers: 'Ones of the bests drivers!',
    txt_global_std_youhavedrivenatotalof: 'You have driven a total of ',
    txt_global_std_anyonewiththelinkcangetinvited: '(anyone with the link can get invited)',
    txt_global_std_signinwith2: 'Sign in with',
    txt_global_std_pigsinvitedunlocked: 'Nice! You now have 250 crowns more in your piggy bank because you have recruited a friend',
    txt_global_std_contactinformation: 'Contact information',
    txt_global_std_mpltiplvl7: 'We would recommend driver training with extra attention on driver focus and planning. Which means, never lose focus on the traffic and conditions around you. We are Yamato and we always focus and plan the driving with special attention to Kindness, Prioritising, Emotional self-control and Trust',
    txt_global_std_failedtoedittranslation: 'Failed to edit translation!',
    txt_global_std_pigwonlocked: 'Compete and win against a friend in head2head to receive this award',
    txt_global_EnerfyActivityDetailTemplate_comparetomy: 'Compare to:',
    txt_global_std_howitworks: 'How it works',
    txt_global_std_letsdothis: 'Lets do this!',
    txt_global_std_yearlymilageused: 'Yearly milage used',
    txt_global_std_browsetemplates: 'Browse templates',
    txt_global_std_verygood: 'Very good',
    txt_global_std_kintofaq3q: 'How do I earn a loyalty status?',
    txt_global_std_contactinfo: 'Contact info',
    txt_global_std_pleasenotethatwewilldetuct: 'Please note that we will deduct 1 SEK from your card when updating your card details. The amount will be paid back in full. In case you have any unpaid invoices, the invoices will be paid shortly after the update. Also, please make sure that your card is open to Internet purchases.',
    txt_global_std_customizeyourapp: 'Customize your app',
    txt_global_std_toreportyourclaim: 'To report your claim, just follow the few next steps and your claim will be handled shortly.',
    txt_global_std_p2ppressthedrivebuttontoconnecttoyourcarandstartatripyouwillgetfeedbackandinsights: 'Press the drive button to connect to your car and start a trip. You will get feedback and insights on your driving.',
    txt_global_std_planetsaver: 'Planet saver',
    txt_global_std_speeddecrease: 'Speed decrease',
    txt_global_std_CertificateofInsurance: 'Certificate of Insurance',
    txt_global_std_byinvitingfriendsyouwillearnachievements: 'By inviting friends you will earn achievements',
    txt_global_std_motorclaimshotline: 'Motor Claims Hotline',
    txt_global_std_friendstotheapp: 'friends to the Enerfy Loyalty app.',
    txt_global_std_dontworryaslongastheenerfyappisinstalledandyouareloggedin: "Don't worry, as long as the Enerfy app is installed and you are logged in, the app will connect automatically to your device.",
    txt_global_std_connectnow: 'Connect now',
    txt_global_std_emailwithremovallink: 'Success! An email has been sent with a removal link, please check your inbox!',
    txt_global_std_totaltranslatedwords: 'Total translated Words',
    txt_global_std_cimage24h: 'FIND OPTIMAL RPM',
    txt_global_std_baseusers: 'Base users',
    txt_global_std_keyname: 'Key name',
    txt_global_std_collectpointsbyearningbadgeseverybadgeisworthadifferentamountofpointstapabadgetolearnmoreaboutit: 'Collect points by earning badges. Every badge is worth a different amount of points. Tap a badge to learn more about it.',
    txt_global_std_selectcar: 'Select car',
    txt_global_std_pleasetryusinganotherusername: 'Please try using another username.',
    txt_global_std_whyterminateinsurance: 'Why terminate {appName} Insurance?',
    txt_global_std_getdiscountcode: 'Get discount code',
    txt_global_std_warikan_onboarding4_2: 'Contact:\nKINTO Customer Center',
    txt_global_std_confirmconnectionbetweenyourphoneandobdreader: 'Confirm connection between your phone and the OBD-reader.',
    txt_global_std_copytoclipboard: 'Copy to clipboard',
    txt_global_std_p2pregisterkinto: 'Register Kinto',
    txt_global_std_accountdetails: 'Account Details',
    txt_global_std_whyshouldanyonejoinyourteam: 'Why should anyone join your team?',
    txt_global_std_pleaseenteravalidemailaddress: 'Please enter a valid email address',
    txt_global_std_planningfeedbacktips: '<b>Looking further ahead</b> (and not just right in front of you) usually results in smoother driving and a higher chance of discovering obstacles in time.<br><br> \n' +
      '<b>Ensuring an enjoyable ride</b> for you and your passengers.',
    txt_global_std_p2pifyouareakintoownerallyouneedtodoisregisteryourcarhereandyoullbereadytogo: "If you are a Kinto owner all you need to do is register your car here and you'll be ready to go!",
    txt_global_std_p2punread: 'unread',
    txt_global_std_competitions: 'Competitions',
    txt_global_std_accountidentification: 'Account identification',
    txt_global_std_starttrip: 'Start trip',
    txt_global_std_youcanexpectthereadertoarrivewithin35businessdays: 'You can expect the reader to arrive within 3-5 business days.',
    txt_global_std_individualdrivers: 'Individual drivers',
    txt_global_std_p2pyoudonthaveanytripsyet: "You don't have any trips yet.",
    txt_global_std_tomczykDescription: 'After a karting career, Tom raced in Formula BMW and Formula 3. 2001 he was the youngest DTM driver ever at that time. He becomes DTM champion in 2011, third in 2007, fourth in 2006 and fifth in 2004, having won eight races. In total Tom was 16 years within the DTM championship. 2017 he raced in the IMSA in order to prepare himself for the endurance racing. 2018/19 he is part of the BMW M8 GTE WEC (World Endurance Championship) program.',
    txt_global_std_kintoImprovement3: "Don't forget to check out your feedback to start earning some savings!",
    txt_global_std_failedtoremove: 'Failed to remove',
    txt_global_std_youractivationcodes: 'Your activation codes',
    txt_global_std_warikan_onboarding18: 'Temporary password:',
    txt_global_std_doyouwanttocontinue: 'Do you want to continue',
    txt_global_std_tomasengeinterests: 'Racing in general, kids',
    txt_global_std_TaxInvoice12: 'Payment by a policyholder to an agent or a broker is deemed payment to the insurer',
    txt_global_std_MSIG_InsuranceLetter8: 'Type of Cover.',
    txt_global_std_p2ppleaseselectacar: 'Please select a car',
    txt_global_std_history: 'History',
    txt_global_std_drivtimeover80: 'Drive time over {speed}',
    txt_global_std_whyyoushouldusefiasdcforyourbusiness: 'Why you should use FIA SDC for your business',
    txt_global_std_trackingofyourco2emissions: 'Tracking of your CO2 emissions.',
    txt_global_std_sender: 'Sender',
    txt_global_std_replaydrive: 'Replay trip',
    txt_global_std_homeaddress: 'Home address',
    txt_global_std_teammemberbig: 'TEAM MEMBER\n',
    txt_global_std_p2pbookingpending: 'Booking pending',
    txt_global_std_p2pselectedfriend: 'Selected friend',
    txt_global_std_appcreator_upsell_pitch_bulletpoint3: 'Get ahead in the race for customer centric, digital-first solutions  ',
    txt_global_std_opensettingsinthephoneturnon: 'Open “Settings” in the phone, turn ON Bluetooth and open the app.',
    txt_global_std_pleaseentercompanyname: 'Please enter company name',
    txt_global_std_therearenoaccountscreatedundertheagreement: 'There are no accounts created under the agreement.',
    txt_global_std_p2pfaq14a: 'We recommend that you inform the car owner in the chat messages if you know that you are going to be late.',
    txt_global_std_allow: 'Allow',
    txt_global_std_vehicleapi: 'Vehicle API',
    txt_global_std_yourmaxspeed: 'Your max speed',
    txt_global_std_p2pemailforcommunication: 'Email for communication',
    txt_global_std_loyaltystatus: 'Loyalty status',
    txt_global_std_gps: 'GPS',
    txt_global_std_mpltipdev7pp: 'This is skilled driver but indicate driving inconsistency. For many days or sometimes for weeks, Cool Driving is practised. But then only for one day, Cool Driving is ignored. There is something that causes the driver to change attitude to driving that is hard for us to determine why. So we recommend a brief discussion with the driver and ask for his view on why this happens. ',
    txt_global_std_youmustpickadate: 'You must pick a date',
    txt_global_std_riskindex: 'Risk Index',
    txt_global_std_settingssmall: 'settings',
    txt_global_std_ended: 'Ended',
    txt_global_std_nickname: 'Nickname',
    txt_global_std_getoverviewtrips: 'Get an overview of all your trips',
    txt_global_std_cimage23h: 'ROLL SOME EXTRA METERS',
    txt_global_std_itsalittleemptyinhere: "It's a little empty in here",
    txt_global_std_ifbuttondontworktrylink: 'If the button above doesn’t work, paste this link into your web browser:',
    txt_global_std_teamname1: 'Team name',
    txt_global_std_dontstress: "Don't stress",
    txt_global_std_areyousureyouwanttoredeempointstotradepoints: 'Are you sure you want to redeem points?',
    txt_global_std_ConfirmFia_Text6: 'Place de la Concorde, 75008 Paris, France',
    txt_global_std_addyourvehicleidentificationtobeabltoenjoyallthebenefitsandclaimyourrewards: 'Add your vehicle identification to be able to enjoy  all the benefits and claim your rewards!',
    txt_global_std_yourfriendslistlooksabitemptyusethebuttonbelowtostartinvitingyourfriends: 'Your friends list looks a bit empty. Use the button below to start inviting your friends. ',
    txt_global_std_MSIG_InsuranceLetter27: '* Limitations rendered inoperative by Section 8 of the Motor Vehicles (Third-Party Risks and Compensation) Act (Chapter 189) and Section 95 of the Road Transport Act, 1987 (Malaysia), are not to be included under these headings.',
    txt_global_std_addfriends: 'Add friends',
    txt_global_std_addapurpose: 'Add a purpose',
    txt_global_std_checkifcorrectobdreaderisconnected: 'Check if correct OBD-reader is connected.',
    txt_global_std_placethewarningtriangleatleast100metersfromthecar: 'Place the warning triangle at least 100 meters from the car',
    txt_global_std_cimage28h: 'IT IS NOT APPROPRIATE TO USE CRUISE CONTROL',
    txt_global_std_wall: 'Wall',
    txt_global_std_somethingiswrong: 'Something is wrong',
    txt_global_std_p2pfaq32q: '\nPlease tell me how to split the cost.',
    txt_global_std_accessdeniedyoudonothavepermissiontoaccesstheworkspacepleasecontactyouradministratorifincorrect: 'Access denied. You do not have permission to access the workspace. Please contact your administrator if incorrect.',
    txt_global_std_ChicheritQuote: 'Coming soon...',
    txt_global_std_insuranceactivesince: 'Insurance active since',
    txt_global_std_MSIG_InsuranceLetter4: 'Motor Vehicles (Third-Party Risks and Compensation) Rules, 1960',
    txt_global_std_enginescanner: 'Engine scanner',
    txt_global_std_bestscore: 'Best score',
    txt_global_std_howitworks1: 'How it works',
    txt_global_std_p2pcleaning: 'Cleaning',
    txt_global_std_MSIG_InsuranceLetter37: '36 Robinson Road #16-01 City House Singapore 068877 Tel: (65) 6507 3848 Fax: (65) 6507 3849',
    txt_global_std_heavydamage: 'Heavy damage?',
    txt_global_std_KristofferssonFacts: 'Johan started his career with International Super Series with Sweden team where he impressed and moved on to Touring Car Championship (STCC) where he won in a single season in 2002. He moved then to the European RX in 2013-2014 season and in 2015 to the FIA WRX where he won 5 rallies and came out 3-rd in overall ranking. Ended runner up in 2016 behind his countryman Mattias Ekström and won it in 2017.',
    txt_global_std_youwilllosedrivedata: 'You will lose all data, including drives, if you login with another user.',
    txt_global_std_typeoftraining: 'Type of training',
    txt_global_std_MSIG_InsuranceLetter18: 'Authorised Driver*',
    txt_global_std_setupcompanychallenge_fia_text: 'Join your team in the FIA SDC and stay active, smart and sustainable when you travel by car.',
    txt_global_std_selectyourcar: 'Select your car',
    txt_global_std_youarearockstardriver: 'Your driving was excellent on this trip! We have no further tips to give you at this moment. You’re a rockstar!',
    txt_global_std_trygroadsideassistance: 'Tryg roadside assistance',
    txt_global_std_MSIG_Password1: 'Hello!',
    txt_global_std_unknown: 'Unknown',
    txt_global_std_youhaveupdatedyourdetails: 'You have updated your details',
    txt_global_std_cimage31d: ' ',
    txt_global_std_promocode: 'Promo Code',
    txt_global_std_youvefinishedyourdrive: "You've finished your drive!",
    txt_global_std_learnmore: 'Learn more',
    txt_global_std_amazingdriverlockedkey: 'Finish a drive at score {score} or better to unlock this badge. You can earn this badge once per day.',
    txt_global_std_reportaccidentheadtext: 'If you are in an accident please report it as soon as possible below.',
    txt_global_std_expired: 'Expired',
    txt_global_std_locationreasontext: "Location services are required to determine your driving score. If location services are not allowed, the app won't work in background mode and it won't start your trip automatically.",
    txt_global_std_searchbygroupname: 'Search by group, name',
    txt_global_std_MarklundChamp: '2x FIA European Rallycross Champion',
    txt_global_std_insnum: 'Ins. number',
    txt_global_std_itseasytomonitoryouresgtargets: 'It’s easy to monitor your ESG targets',
    txt_global_std_kintorecruit5userslocked: 'Unlock this achievement and earn ¥10.000 by recruiting 5 Kinto users.',
    txt_global_std_unplugtheobdreaderandplugitbackagain: 'Unplug the OBD reader and plug it back again. Wait until the reader is flashing blue.',
    txt_global_std_activeparticipantsfor: 'Active participants for',
    txt_global_std_SignupFia_Text5: 'Download the SDC app to get a sneek peak of what’s coming!',
    txt_global_std_restrictions: 'Restrictions',
    txt_global_std_stayfocused: 'Stay focused',
    txt_global_std_TomczykFacts: 'After a karting career, Tom raced in Formula BMW and Formula 3. 2001 he was the youngest DTM driver ever at that time. He becomes DTM champion in 2011, third in 2007, fourth in 2006 and fifth in 2004, having won eight races. In total Tom was 16 years within the DTM championship. 2017 he raced in the IMSA in order to prepare himself for the endurance racing. 2018/19 he is part of the BMW M8 GTE WEC (World Endurance Championship) program.',
    txt_global_std_fileinput: 'File input',
    txt_global_std_theapikeydoesnthavepermissionstoperformtherequest: "The API key doesn't have permissions to perform the request.",
    txt_global_std_latesttrip: 'Latest trip',
    txt_global_std_areyousureyouwanttodeletethegroupdeletingthegroupdoesnotdeletetheusersaccounts: 'Are you sure you want to delete the group?\n' +
      '\n' +
      'Deleting the group does not delete the users accounts.',
    txt_global_std_theuserwillbeaddedtoyouragreementaccordingtoyourpriceplan: 'The user will be added to your agreement according to your price plan.',
    txt_global_std_rwmselectfriendtext: 'Select the friend/friends that rode with you',
    txt_global_std_welcomeresendemail: 'Welcome to your new account! We have sent you an email to verify your new account. Remember to check your spam folder. Didn’t get an email?',
    txt_global_std_automaticstartandstoprequiresaccesstobluetoothlocationservicesgpsandmotionfitness: 'Automatic start and stop requires access to bluetooth, location services (GPS) and motion & fitness.',
    txt_global_std_riskportfoliotracker_upsell_pitch: 'Easily connect your entire portfolio for one comprehensive view of driver risk. Gain;',
    txt_global_std_inviteall: 'Invite all',
    txt_global_std_enterdriverriskweight: 'Enter driver risk weight',
    txt_global_std_warikan_onboardingemailsubject: '[Warikan KINTO] Information on how to use the app\n',
    txt_global_std_couldnotsavechanges: 'Could not save your changes',
    txt_global_std_youraveragelevelispoints: 'Your average level is {points}',
    txt_global_std_connectivity: 'Connectivity',
    txt_global_std_avgrisklevel: 'Avg. Risk Level',
    txt_global_std_youneedtodrivemorethankmkmduringaheatweekinordertogetascoreintheheatleaderboard: 'You need to drive more than {km} km during a heat (week) in order to get a score in the heat leaderboard.',
    txt_global_std_driveraverage: 'Driver average',
    txt_global_std_challengeleaderboard_upsell_pitch: 'Organize a challenge for teams, divisions, customers, partners, and other guests to; ',
    txt_global_std_noofusers: 'No. of users',
    txt_global_std_yourunsavedchangeswillbelostsavechangesbeforeclosing: 'Your unsaved changes will be lost. Save changes before closing?',
    txt_global_std_emailuniqueinvitations: 'Email unique invitations',
    txt_global_std_TaxInvoice1: 'TAX INVOICE',
    txt_global_std_starburstnamekey: 'Starburst!',
    txt_global_std_p2paskakintoownertoinviteyouandyouwillgetaccesstoallthecoolfunctionsinthisapp: 'Ask a Kinto owner to invite you, and you will get access to all the cool functions in this app.',
    txt_global_std_continue123: 'Continue',
    txt_global_std_p2pcoolandfunstatsaboutyourfriendsbookingofyourcar: "Cool and fun stats about your friend's booking of your car.",
    txt_global_std_ridewithmekey: 'Ride with me',
    txt_global_std_joinrandom: 'join random',
    txt_global_std_couldnotcalculatespeedimprovementpossibility: 'Could not calculate speed improvement possibility!',
    txt_global_std_trygdemo: 'Skip and get started with Tryg Drive',
    txt_global_std_from2: 'from',
    txt_global_std_driveminimum1kmduringaheatinordertoqualifyforthechallenge: '• Drive minimum 1 km during a heat in order to qualify for the challenge.',
    txt_global_std_invitedmembers: 'Invited members',
    txt_global_std_p2ppushtitlelendingcomingup: 'Lending coming up',
    txt_global_std_communication_upsell_bulletpoint2: 'Connect with users on their preferred channels',
    txt_global_std_startlendingyourcar: 'Start lending your car!',
    txt_global_std_seehowmuchco2savingsyougetwhenyoudrivewell: 'See how much co2-savings you get when you drive well',
    txt_global_std_trip: 'Trip',
    txt_global_std_opensettingsinyourphonegotoprivacy: 'Open “Settings” in your phone. Go to Privacy, open Location Service and turn ON for FIA Smart.',
    txt_global_std_warikan_onboarding17: 'Mail address:',
    txt_global_std_sendmyposition: 'Send my position',
    txt_global_std_buymorekilometers: 'Buy more kilometers',
    txt_global_std_letsstartwiththebasics: "Let's start with the basics",
    txt_global_std_nofiltersyet: 'No filters yet',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint2: 'Congratulate drivers for achieving their milestones/ targets',
    txt_global_std_warikan_onboarding15: 'Inquiry form:\n',
    txt_global_std_trysidekick: 'Try Sidekick',
    txt_global_std_carsagreements: 'Cars & agreements',
    txt_global_std_thepasswordminimumlengthis8charactersandmustinclude1lowercaseuppercasenumeralandspecialcharacter: 'The password minimum length is 8 characters, and must include 1 lower-case, upper-case, numeral and special character',
    txt_global_std_SignupFia_Text4: 'Are you up for the challenge?',
    txt_global_std_confirmationmail: 'You are now booked!',
    txt_global_std_invoiceoverview: 'Cost',
    txt_global_std_welcometoappname: 'Welcome to {appName}!',
    txt_global_std_whenwillmyratechange: 'When will my rate change?',
    txt_global_std_gpserror: 'GPS error',
    txt_global_std_qhowdoifileaclaim: 'Q: How do I file a claim?',
    txt_global_std_analyzingtheresults: 'Analyzing the results of your drive',
    txt_global_std_exportexcel: 'Export XLS',
    txt_global_std_toturnonbluetoothinyourphone: 'To turn on Bluetooth in your phone, you could go to your settings and turn it on from there. You could also swipe down to turn on Bluetooth from your control center.',
    txt_global_std_manageadvancedusersettingsandsupporthereusethequickaccessbuttonsbelowtoeasilynavigatebetweenuserscarsagreementsandfollowupandhelpoutwiththeironboarding: 'Manage advanced user settings and support here. Use the quick access buttons below to easily navigate between users & agreements and follow up and help out with onboarding.',
    txt_global_std_target: 'Target',
    txt_global_std_inordertoreceiveadrivingdividend75ofthedistancetraveledmustbecompletedwiththeappandchiptenfoldremembertohaveboththeappandbluetoothonwhenyoudriveandthetripwillberegistered: 'In order to receive a driving dividend, 75% of the distance traveled must be completed with the app and chip tenfold. Remember to have both the app and Bluetooth on when you drive, and the trip will be registered.',
    txt_global_std_youcanalwaysgobacktothisvideoguidebyclickingtheinthetopnavigationbar: 'You can always go back to this video guide by clicking the ? in the top navigation bar.',
    txt_global_std_wowgoodprogress: 'Wow, good progress!',
    txt_global_std_myinsurance2: 'My insurance',
    txt_global_std_adjustyourannualmileage: 'Adjust your annual mileage',
    txt_global_std_changelater: 'Change later',
    txt_global_std_p2pcalendar: 'Calendar',
    txt_global_std_publicanyonewiththeappcanparticipate: 'Public (anyone with the app can participate)',
    txt_global_std_key: 'Key',
    txt_global_std_countryofresidence: 'Country of residence',
    txt_global_std_overviewoftheonboarding: 'Overview of the onboarding',
    txt_global_std_analyzingyourdrive1: 'Analyzing your drive',
    txt_global_std_wrongcurrentpassword: 'Wrong current password, please try again.',
    txt_global_std_p2penterthedigitcodeorclickonthelinksentto: 'Enter the 6-digit code or click on the link sent to: {number}',
    txt_global_std_messageheader: 'Message header',
    txt_global_std_wewilldeducttheinvoicepaymentfromthe: 'We will deduct the invoice payment from you the 1st day each month for the fixed premium in advance and the variable premium for the past month. The deduction will be made on the debit or credit card you used when purchasing the insurance.',
    txt_global_std_unlockbenefitsandrewards: 'Unlock benefits & rewards',
    txt_global_std_new: 'New',
    txt_global_std_activationcodeisnotvalid: 'Activation code is not valid.',
    txt_global_std_fueltype: 'Fuel type',
    txt_global_std_saveandclose: 'Save and close',
    txt_global_std_ifyoudonthaveanygroupsyetyoucancreatetheminthegroupsmodule: "If you don't have any groups yet you can create them in the Groups module.",
    txt_global_std_p2ppushtitlenewmessage: 'New message from {name}',
    txt_global_std_keepasafedistance: 'Keep a safe distance',
    txt_global_std_email2: 'E-mail',
    txt_global_std_update: 'Update',
    txt_global_std_p2phasconfirmedbooking: ' has confirmed booking',
    txt_global_std_ifyoudidntmakerequestignore: 'If you didn’t make this request you can safely ignore this email.',
    txt_global_std_levellevel: 'Level {level}',
    txt_global_std_howcaniimprovemyscore: 'How can I improve my score?',
    txt_global_std_pressondevicebelowtoaddit: 'Press on device below to add it.',
    txt_global_std_yousignedupforournewsletter: 'You signed up for our newsletter!',
    txt_global_std_wereanyothercarsinvolved: 'Were any other cars involved?',
    txt_global_std_p2pfaq26q: 'Please tell me how to share a car with friends.',
    txt_global_std_invalidoldpasswordornewpassword: 'Invalid old password or new password',
    txt_global_std_ecodriving: 'Eco Driving',
    txt_global_std_general: 'General',
    txt_global_std_PaddonInterests: 'Racing, Golf, Mountain Biking',
    txt_global_std_thisisneededtogiveyouestimatedsavings: 'This is needed to give you estimated savings.',
    txt_global_std_p2pshowyourreferralcode: 'Show your friend referral code',
    txt_global_std_platenumberornameofcar: 'Plate number or name of car',
    txt_global_std_activationcodeisrequired: 'Activation code is required',
    txt_global_std_motionfitnesswillhelpyoustartandstopdrivesautomaticallysothatyoucanjustfocusonyourdriving: 'Motion & Fitness will help you start and stop drives automatically so that you can just focus on your driving.',
    txt_global_std_timet: 'T',
    txt_global_std_savechanges2: 'Save changes?',
    txt_global_std_p2pfinishandpayquestion: 'Finish?',
    txt_global_std_reactivateinsurance: 'Reactivate insurance',
    txt_global_std_p2pgoheretofindcostsplitfunctioncheckyourdrivingstatsvisityourfriendsprofileandmore: 'Go here to find cost split function, check your driving stats, visit your friends profile and more.',
    txt_global_std_firedamage123: 'Fire Damage',
    txt_global_std_firstwithscore: "Let's be the first one with score in the heat",
    txt_global_std_p2pconfirmedthebooking: 'confirmed the booking.',
    txt_global_std_numberofparticipants: 'Number of participants',
    txt_global_std_HansenShortDescription: 'EURO RX Champion 2016, RX Lite Cup 2015, World Champion in ROK Junior 2010',
    txt_global_std_nextstatus: 'Next status',
    txt_global_std_somethingwentwrong: 'Something went wrong',
    txt_global_std_thatsokwhenyouarereadytodriveyoucanconnecthere: 'That’s OK! When you are ready to drive, you can connect to your car here.',
    txt_global_std_viewalltrips: 'View all trips',
    txt_global_std_fiasdcecoscore: 'FIA SDC Eco score',
    txt_global_std_nothanks2: 'No thanks',
    txt_global_std_onlylettersnumbersandperiodsareallowed: 'Only letters (a-z), numbers (0-9) and periods (.) Are allowed.',
    txt_global_std_15discountonrenewal: '15% discount on renewal',
    txt_global_std_yourcarwillstillbeinsuredbutyourpremium: 'Your car will still be insured, but your premium cost might increase due to us billing you at premium level 10 for non connected drives.',
    txt_global_std_howdoisignupfortheinsurance: 'How do I sign up for the insurance?',
    txt_global_std_youralreadyuseddevices: 'Your already used devices',
    txt_global_std_borrowacar: 'Borrow a car',
    txt_global_std_create: 'Create',
    txt_global_std_totalsavings: 'Total savings',
    txt_global_std_anticipation: 'Anticipation',
    txt_global_std_allday: 'All day',
    txt_global_std_noeventtoday: 'No event today',
    txt_global_std_p2paddexpense: 'Add expense',
    txt_global_std_signingupforinsurance: 'Signing up for insurance',
    txt_global_std_MSIG_InsuranceLetter9: 'Certificate No.',
    txt_global_std_ridewithmelockedkey: 'Did a friend ride with you? Register a ridesharing trip to earn this badge. You can find this function in the menu with the three dots. You can earn this badge once per week.',
    txt_global_std_accruedlevel: 'Accrued level =',
    txt_global_std_joinenerfy: 'Join {appName}',
    txt_global_std_herunderkandusefleredetaljeromdennetur: 'Below you can see more details about this trip.',
    txt_global_std_402requestfailed: '402 - Request Failed',
    txt_global_std_invitefriend: 'Invite a friend',
    txt_global_std_almosttherewewillnowbuildyourdemoapp: 'Almost there. We will now build your demo-app.',
    txt_global_std_connectedbluetoothid: 'Connected Bluetooth ID',
    txt_global_std_nosampledata: 'No sample data',
    txt_global_std_nicknamesmayonlyincludeletters: 'Nicknames may only include letters,',
    txt_global_std_appcreatoroverview: 'App Creator overview',
    txt_global_std_75offdeductible: '75% off deductible',
    txt_global_std_whendrivingincityconditionsavoidchasingthenextcrossingortrafficlightbygainingspeedquicklythenslowingquicklythishurtstraveltimeandfueleconomyandincreasescrashrisklookingwellaheadanddrivingsmoothlywillhelpyouanticipateandhandleanyeventnew: 'When driving in city conditions, avoid chasing the next crossing or traffic light by gaining speed quickly then slowing quickly. This hurts travel time and fuel economy, and increases crash risk. Looking well ahead and driving smoothly will help you anticipate and handle any event.',
    txt_global_std_HSolbergQuote: `Being a professional driver for so many years now, I have been part of a lot of driving challenges in my career. The first thing that comes in my mind when I am behind the wheel is the “safe driving”. Let's take this challenge together in the FIA Smart Driving Challenge for "safer roads".`,
    txt_global_std_PettersolbergDescription: 'The first person to be crowned an FIA World Champion in two different motorsport disciplines, Petter Solberg followed up his 2003 World Rally Championship success by winning the FIA World Rallycross Championship for two consecutive seasons in 2014 and 2015. Having begun his motorsport career with rallycross outings in his native Norway, Solberg – known as ‘Hollywood’ to his many fans for his crowd-pleasing antics – made his World Rally Championship debut in 1998 before a switch to Subaru for 2000 preceded his first win, on the 2002 Rally GB.',
    txt_global_std_BeforeFirstBill_Text3: 'You will pay two premiums each month; a variable and a fixed premium. The fixed premium is the same and does not change, it is based on the type of car you have. The variable premium is based entirely on how you drive. If you drive smart and safely, your premium becomes cheaper.',
    txt_global_std_filternofilter: 'No filter',
    txt_global_std_fiasdccompanychallenge: 'FIA SDC Company Challenge',
    txt_global_std_vehicleidentificationwassaved: 'Vehicle identification was saved',
    txt_global_std_areyousureyouwanttodeletethegroup: 'Are you sure you want to delete the group?',
    txt_global_std_seeyourprogressandgetinsightsandfeedbackonyourdriving: 'See your progress and get insights and feedback on your driving.',
    txt_global_std_asyoumayhaveareportconsistingofmanymembersyouareabletosubmitarequesttodownloadthewholesetifyouwish: 'As you may have a report consisting of many members, you are able to submit a request to download the whole set if you wish.',
    txt_global_std_welcometocompany: 'Welcome to [Company]!',
    txt_global_std_secondsec: 'sec',
    txt_global_std_useapi: 'Use API',
    txt_global_std_howdoicompeteinappchallengeanswer: 'You automatically participate when you sign up. Don’t worry, the leaderboard will only show your nickname- so you will always stay anonymous. There is a monthly and yearly competition.',
    txt_global_std_youwillsoonbeready: 'You will soon be ready!',
    txt_global_std_conversionto: 'Conversion to',
    txt_global_std_TidemandShortDescription: 'WRC 2 Champion 2017, Touring Car Champion 2010.',
    txt_global_std_manageyourteammembersandtheiraccountpermissions: 'Manage your team members and their account permissions',
    txt_global_std_learnmoreaboutus: 'Learn more about us',
    txt_global_std_sendcodetome: 'Send me the code',
    txt_global_std_ready2go: 'Ready2Go',
    txt_global_std_yourplateshouldcontainacombinationofnumbersandletters: 'Your plate should contain a combination of numbers and letters.',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimeinconstantspeedunnecessaryspeedvariationcansometimesindicatedistractionorlackoffocus: 'On this type of trip, the average eco champion spent more time in constant speed. Unnecessary speed variation can sometimes indicate distraction or lack of focus.',
    txt_global_std_whymustiallowalwaysallowlocationsservices: 'Why must I allow “always allow locations services"?',
    txt_global_std_collect: 'Collect',
    txt_global_std_acceptusertermsconditionsandprivacypolicy: 'Accept user terms, conditions & privacy policy.',
    txt_global_std_atleast10characters: 'At least 10 characters',
    txt_global_std_added: 'Added',
    txt_global_std_MSIG_InsuranceLetter38: "Any person who is driving on the Insured's order or with their permission.",
    txt_global_std_OgierQuote: 'Coming soon...',
    txt_global_std_tryingtosignyouintoenerfy: 'Trying to sign you in to {appName}…',
    txt_global_std_noreaderyetskipthisstep: 'No reader yet? Skip this step',
    txt_global_std_almostdone: 'Almost done',
    txt_global_std_anactivationcodecanbeusedtogetaccesstospecificeventsandchallenges: 'An activation code can be used to get access to specific events and challenges.',
    txt_global_std_points123: 'points',
    txt_global_std_forquestionsandfeedbackregardingtheappcontacttheemailabove: 'For questions and feedback regarding the app, contact the email above.',
    txt_global_std_theactivationcodehasbeensent: 'The activation code has been sent.',
    txt_global_std_liveonmap: 'Live on map',
    txt_global_std_customerservice: 'Customer service',
    txt_global_std_somethingwentwrongpleasetryagainlater: 'Something went wrong, please try again later.',
    txt_global_std_PolicySchedule17: 'NCDP,',
    txt_global_std_savechanges: 'Save Changes',
    txt_global_std_acceptthedongle: 'Please allow to connect the dongle too your phone',
    txt_global_std_averagespeed: 'Average speed',
    txt_global_std_annualprice: 'Annual price',
    txt_global_std_youneedtodrivemorethan10kmduringaheatweekinordertogetascoreintheheatleaderboard: 'You need to drive more than 10 km during a heat (week) in order to get a score in the heat leaderboard.',
    txt_global_std_cimage31h: 'TRY TO AVOID EVEN THE SMALL ACCELERATIONS',
    txt_global_std_enteravalidcode: 'Enter a valid code.',
    txt_global_std_firstdriveunlocked: 'You drove and received a score! Good job!',
    txt_global_std_resendpassword: 'Resend password',
    txt_global_std_chassinumberalreadyexists: 'Chassinumber already exists',
    txt_global_std_nextqualificationfortheendfinalstarts: 'Next qualification for the End Final starts',
    'txt_global_std_Al-KuwariChamp': '2nd Place 2016 FIA World Cup for Cross-Country Rallies - T2 - Co-Drivers, 1st 2017 FIA MERC 2 - Co-Driver,1st 2018 FIA MERC 2 - Co-Driver',
    txt_global_std_p2ppushbodyhasnowfinalizedthebooking: '{name} has now finalized the booking.',
    txt_global_std_enerfyriskportfoliotracker: 'Enerfy Risk Portfolio Tracker',
    txt_global_std_socialbutterflylockedkey: 'Refer 10 friends to unlock this achievement and earn 300 points to your Kinto Wallet.',
    txt_global_std_letsgetstarted: "Let's get started!",
    txt_global_std_onboardingstatus: 'Onboarding status',
    txt_global_std_fiasdc2019w1price3: '1 Season package',
    'txt_global_std_ben-sulayemDescription': 'He has competed in African, European, Middle East and World Championship Rallies. He won one of his titles while recovering from a broken neck.',
    txt_global_std_drivingtogetherismorefunaddyourcoworkersorsendthemalink: 'Driving together is more fun, add your co-workers or send them a link.',
    txt_global_std_subscribetoupdates: 'Subscribe to updates',
    txt_global_std_p2pfaq16q: 'What if I damage the car?',
    txt_global_std_p2pcalendaroverview: 'Calendar overview',
    txt_global_std_april: 'April',
    txt_global_std_sendsms: 'Send SMS',
    txt_global_std_thisisrequired: 'This is required',
    txt_global_std_WelcomeLetter_Text3: 'If you have any questions about your car insurance please contact us or visit our website www.enerfy.se to get more information on how {appName} works. ',
    txt_global_std_performancechart: 'Performance chart',
    txt_global_std_p2paddmember: 'Add member',
    txt_global_std_p2pselectcalendar: 'Select Calendar',
    txt_global_std_youhave: 'You have',
    txt_global_std_premiumdiscount: 'Premium Discount',
    txt_global_std_comebacklatertherearenochallengeshereatthemoment: 'Come back later, there are no challenges here at the moment.',
    txt_global_std_viewriskandco2levelsregardlessofvehicletypeandlocation: 'View risk and CO2 levels regardless of vehicle type and location',
    txt_global_std_shareyourredeemcode: 'Share your redeem code',
    txt_global_std_p2ppleaseenteravalidpriceabovezero: 'Please enter a valid price above 0',
    txt_global_std_seedetails: 'See Details',
    txt_global_std_p2pnewmessage: 'New Message',
    txt_global_std_thisisgonnabesofunletsgetstartedwithyourreallysmartdriving: "This is gonna be so fun! Let's get started with your smart driving. I'll be around to score you really well.",
    txt_global_std_competitionformat: 'Competition format',
    txt_global_std_createanaccounttocollectandstoreyourresultsitsfree: "Create an account to collect and store your results. It's free!",
    txt_global_std_wrongpasswordpopuptext: 'Wrong password',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimedecreasingspeedeffectiveobservationandleavingasafefollowingdistancecanhelpreduceunnecessaryspeedvariations: 'On this type of trip, the average eco champion spent less time decreasing speed. Effective observation and leaving a safe following distance can help reduce unnecessary speed variations.',
    txt_global_std_thereisnoinsurancetoterminate: 'There is no insurance to terminate',
    txt_global_std_zipdriverriskmorerelevantthancreditscore: 'Zip-Driver risk more relevant than credit score',
    txt_global_std_drivesafethismonth: 'Drive safe this month',
    txt_global_std_p2pnoconfirmedbookingrequests: 'No confirmed booking requests',
    txt_global_std_vehicletype2: 'Vehicle type',
    txt_global_std_safetydrivingskillupwithwarikankinto: 'Safety driving skill-up with Warikan KINTO',
    txt_global_std_notuploadeddrivingtime: 'Not uploaded driving time: ',
    txt_global_std_p2pnopendingbookingrequests: 'No pending booking requests.',
    txt_global_std_youhavenofinishedchallengesyet: 'You have no finished challenges yet',
    txt_global_std_unabletosavesettings: 'Unable to save settings',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatuscheckouttheachievementsbelowyoucanearnbybeingaappnamesuperdriver: 'Achievements help you collect points to earn your loyalty status. Check out the achievements below you can earn by being a {appname} super driver!',
    txt_global_std_p2pviewyourbookings: 'View your bookings.',
    txt_global_std_defineaneventthatinitiatesapushnotification: 'Define an event that initiates a push notification',
    txt_global_std_howdoiearnsavingsanswer: 'Savings are generated when you drive safe. For every trip you drive safe, you will generate a saving per kilometer. The safer you drive, the higher the saving! The saving is visible directly after your trip is finished and will be visible in your {appname} wallet.',
    txt_global_std_adwancedadmin: 'Adwanced admin',
    txt_global_std_p2pmessages: 'Messages',
    txt_global_std_templatestabtext: 'Templates',
    txt_global_std_takeapicture: 'Take a picture',
    txt_global_std_insuredvehicle: 'Insured vehicle',
    txt_global_std_p2pitlookslikethisaccountisalreadyexist: 'It looks like this account already exist',
    txt_global_std_p2pfaq23q: 'Is it possible change the way to split the cost',
    txt_global_std_insurancecard: 'Insurance card',
    txt_global_std_whatisanactivationcode: 'What is an activation code? ',
    txt_global_std_showreasons: 'Show reasons',
    txt_global_std_TomczykChamp: '16 years DTM - 2011 DTM Champion',
    txt_global_std_adduserstodifferentgroups: 'Add users to different groups.',
    txt_global_std_needstobeavalidphonenumbercanonlyincludenumbersand: 'Needs to be a valid phone number. Can only include numbers and +',
    txt_global_std_typeofnotification: 'type of notification',
    txt_global_std_datafailedtosent: 'Something went wrong when trying to send your data, please ensure your e-mail address is correct and try again!',
    txt_global_std_daydreamingormindwandering: 'Day dreaming or mind wandering',
    txt_global_std_p2phasacceptedtherequest: 'has accepted the request.',
    txt_global_std_dashboard_groups: 'Groups',
    txt_global_std_carsharing: 'Car sharing',
    txt_global_std_estimatedpricebasedonyourperformance: 'Estimated price this month based on your performance is',
    txt_global_std_french: 'French',
    txt_global_std_speedincrease: 'Speed increase',
    txt_global_std_howtogetstartedwithzipdriverriskindex: 'How to get started with Zip-Driver risk index',
    txt_global_std_dates: 'Dates',
    txt_global_std_p2pkeepthecommunicationgoingandchatwithyourfriendaboutyourbookings: 'Keep the communication going and chat with your friend about your bookings.',
    txt_global_std_alreadyhaveanaccount: 'Already have an account?',
    txt_global_std_PolicySchedule7: 'Year of Registration',
    txt_global_std_loyalty_upsell_pitch_bulletpoint1: 'Incentivize safe, eco-friendly driving ',
    txt_global_std_yourveryownfiasdccompanychallenge: 'Your very own FIA SDC Company Workspace',
    txt_global_std_vs: 'vs',
    txt_global_std_overview2: 'Overview',
    txt_global_std_p2pselectedmember: 'Selected member',
    txt_global_std_p2premembertomarkthemaspaidonceyoureceivethepayment: 'Remember to mark them as paid once you receive the payment.',
    txt_global_std_thisistheworldsfirstchallengerewardingsmartskillsatthewheelgetyourcompanyinvolvedinsmartdriving: "This is the world's first challenge rewarding smart skills at the wheel. Get your company involved in smart driving.",
    txt_global_std_privateforthoseinmygroupwiththesameemaildomainname: 'Private (for those in my group with the same email domain name)',
    txt_global_std_noinsuranceregistered: 'No insurance registered',
    txt_global_std_yourdroveatscoretypescoreorbetterawesomeyouhaveearnedpointspoints: 'Your drove at {score} or better! Awesome! You have earned {points} points!',
    txt_global_std_subscribetoupdates_2: 'Subscribe to updates',
    txt_global_std_areyousureyouwanttodisablethecommunication: 'Are you sure you want to disable the communication?',
    txt_global_std_odometer: 'Odometer',
    txt_global_std_acceptbluetooth: 'Accept Bluetooth',
    txt_global_std_yourinsurancehasbeenterminated: 'Your insurance has been terminated!',
    txt_global_std_tosignwiththisaccountwejustneedsomecomplementaryinfoaboutyoubeforeyoucanstart: 'To sign with this account, we just need some complementary info about you before you can start!',
    txt_global_std_MSIG_InsuranceLetter17: 'Excess:',
    txt_global_std_kintofaq4q: 'How do I compete in Kinto Challenge?',
    txt_global_std_MikkelsenMusic: 'I listen to all kind of music when I drive but I would say I mostly listen to pop and electronic dance music.',
    txt_global_std_createnewpushnotification: 'Create new push notification',
    txt_global_std_notethiscodeexpiresin6minutesbutyoucangenerateanotherbylogginginagain: 'Note: This code expires in 6 minutes, but you can generate another by logging in again.',
    txt_global_std_filteroptionactive: 'Active',
    txt_global_std_theconnectiontypeissaved: 'The connection type is saved.',
    txt_global_std_reasontoterminatetheinsurance: 'Reason to terminate the insurance',
    txt_global_std_50000pts: '> 50 000 pts',
    txt_global_std_youraveragescoreis2: 'Your average score is {points}',
    txt_global_std_yourcarmodelistoooldanddoesnthavebluetoothnodot: 'Your car model is too old and doesn’t have Bluetooth.',
    txt_global_std_bonuspaymentday: 'Bonus payment day',
    txt_global_std_toregisteryourdrivingcorrectlyyoumustselectalways: 'To register your driving correctly, you must select «Always»',
    txt_global_std_getthecarinsuranceyoudeservethesaferyoudrivethemoreyousave: 'Get the car insurance you deserve. The safer you drive, the more you save.',
    txt_global_std_onboardingstatususeranalytics: 'Onboarding status / user analytics',
    txt_global_std_userguides: 'User guides',
    txt_global_std_logout: 'Logout',
    txt_global_std_fromfromvalkmuptotovalkm: 'From {fromVal} km up to {toVal} km.',
    txt_global_std_Fia_Receipt_Text10: 'Amount',
    txt_global_std_p2pexpenses: 'Expenses',
    txt_global_std_apitool: 'Api tool',
    txt_global_std_zipriskindexinsuranceratingcomponent: 'Zip risk index - Insurance rating component',
    txt_global_std_daydreamingisacommontypeofdriverdistractionyoumaythinkitsharmlessbutanythingthattakesyourmindoffthedrivingtaskincreasescrashriskchangingyourgazefrequentlycanhelpyoumaintainfocusontheenvironmentaroundyou: 'Day dreaming is a common type of driver distraction. You may think it’s harmless, but anything that takes your mind off the driving task increases crash risk. Changing your gaze frequently can help you maintain focus on the environment around you.',
    txt_global_std_qualified: 'Qualified',
    txt_global_std_VilliersMileage: 'I South Africa we have some beautiful passes and the Franschoek pass, close to my home is one of my favourites.',
    txt_global_std_topimprovercomingsoon: 'Top improver coming soon',
    txt_global_std_savetranslation: 'Save Translation',
    txt_global_std_heresyour15discountcode: 'Here’s your 15% discount code',
    txt_global_std_p2ppushtitlenewpaymentquestion: 'New payment?',
    txt_global_std_edithistory: 'Edit history',
    txt_global_std_numberbetweenxandyrule: 'Number has to be between {x} and {y}',
    txt_global_std_areyouthenextworldchampion: 'Are you the next world champion?',
    txt_global_std_qhowdoesthescoringsystemwork: 'Q: How does the scoring system work?',
    txt_global_std_Fia_Receipt_Text11: 'Total',
    txt_global_std_inorderfortheapptoworkproperlyweneedaccesstoyourphoneslocationaswellasexerciseandfitnessdata: "In order for the app to work properly, we need access to your phone's location as well as exercise and fitness data.",
    txt_global_std_drive: 'Drive',
    txt_global_std_technicaldrivingperformance: 'Technical driving performance',
    txt_global_std_p2pnext: 'Next',
    txt_global_std_syncwithgarminconnect: 'Sync with Garmin Connect™',
    txt_global_std_HSolbergBesttip: 'Have flow in the driving, braking less = less accelerations that use energy',
    txt_global_std_thiswindowwillclosein30sec: 'This window will close in {closeSeconds} seconds',
    txt_global_std_policynumbercouldnotbeverified: 'Policy number could not be verified.',
    txt_global_std_p2pfuel: 'Fuel',
    txt_global_std_p2pand: 'and',
    txt_global_std_apikey1: 'API key 1',
    txt_global_std_teamc02savings: 'Team c02 savings',
    txt_global_std_policynumber: 'Policy number',
    txt_global_std_SulayemChamp: '14 times FIA Middle East Rally Champion',
    txt_global_std_PSolbergFacts: 'The first person to be crowned an FIA World Champion in two different motorsport disciplines, Petter Solberg followed up his 2003 World Rally Championship success by winning the FIA World Rallycross Championship for two consecutive seasons in 2014 and 2015. Having begun his motorsport career with rallycross outings in his native Norway, Solberg – known as ‘Hollywood’ to his many fans for his crowd-pleasing antics – made his World Rally Championship debut in 1998 before a switch to Subaru for 2000 preceded his first win, on the 2002 Rally GB.',
    txt_global_std_available: 'Available',
    txt_global_std_swipetoopenthecontrolcenterandmakesureyouhavebluetoothturnedon: 'Swipe to open the control center and make sure you have Bluetooth turned on.',
    txt_global_std_nothankyou: 'No thank you',
    txt_global_std_theinsurancedeleted: 'The Insurance deleted!',
    txt_global_std_thankyouforallowingustoaccessyourbluetooth: 'Thank you for allowing us to access your Bluetooth settings. We value your privacy and will not share this information with a third party entity.',
    txt_global_std_challange: 'Challange',
    txt_global_std_personaldetails2: 'Personal details',
    txt_global_std_areyousurethatyouwanttocompletelyremoveyouraccount: 'Are you sure that you want to completely remove your account, driving records, insurance information',
    txt_global_std_getstarsaftereachtripforhowgoodsafeandecofriendlyyourdrivewas: 'After every drive you get a score between 0-100 depending on how safe you drove. Here you can find your total score.',
    txt_global_std_p2pgroupname: 'Group name',
    txt_global_std_youonlyhave: 'You only have',
    txt_global_std_uploadintervalsecondssec: '{uploadIntervalSeconds} sec',
    txt_global_std_namesmayonlyincludeletters: 'Names may only include letters,',
    txt_global_std_HSolbergFacts: 'Solberg is the elder brother of the 2003 FIA World Rally Champion Petter Solberg and through his Swedish spouse Maud the stepfather of Swedish rally driver Pontus Tidemand. One of the sport’s most experienced drivers, when he’s in the groove he has the power to embarrass the youngsters. ',
    txt_global_std_qdoesagentacceptdriversthatarenotlicensedincountry: 'Q: Does {agent} accept drivers that are not licensed in {country}?',
    txt_global_std_p2ppushbodybookingcomingup: 'Please note that booking starts "{start}".',
    txt_global_std_nameemailhasinvitedyoutojointheirgrouptostartdrivingsmarter: '{name} ({email}) has invited you to join their {group} to start driving smarter.',
    txt_global_std_opensettingsinthephonelocationturnon: 'Open “Settings” on your phone. Go to Privacy, open Location Service and turn ON for Enerfy.',
    txt_global_std_timeinretardation: 'Time in retardation',
    txt_global_std_loadingitems: 'Loading items',
    txt_global_std_interests: 'Interests',
    txt_global_std_viamail: 'Via mail',
    txt_global_std_TaxInvoice10: 'ALL INDIVIDUAL POLICYHOLDERS ARE SUBJECT TO A PAYMENT BEFORE COVER WARRANTY',
    txt_global_std_MSIG_Onboarding2: 'Thank you for choosing MS First Capital Insurance Ltd. You have signed the insurance for the car',
    txt_global_std_teamaveragescorebig: 'TEAM AVERAGE SCORE\n',
    txt_global_std_notspecified: 'Not specified',
    txt_global_std_apitool_upsell_pitch_bulletpoint3: 'Easy, low effort integration of data to enhance business efficiency',
    txt_global_std_p2pfinishquestion: 'Finish?',
    txt_global_std_p2pwrongemailorpassword: 'Wrong email or password!',
    txt_global_std_ayesagentacceptsdriverswhoarelicensedinothercountries: 'A: Yes, {agent} accepts drivers who are licensed in other countries.',
    txt_global_std_enterinvitationcode: 'Enter invitation code',
    txt_global_std_thiswonttakemorethanaminuteyoullbeintheworkspaceonceitsready: 'This won’t take more than a minute, you’ll be in the workspace once it’s ready.',
    txt_global_std_f_lanzbesttip: 'Always look ahead, scan the horizon for potential hazards. At the same time enjoy the views. ',
    txt_global_std_exportdata: 'Export data',
    txt_global_std_savingsisgeneratedifriskislowerthanclaims: 'Saving is generated if risk is lower than calculated claims',
    txt_global_std_qualifiedto2021finals: 'Qualified to 2021 finals',
    txt_global_std_documentthedamage: 'Document the damage',
    txt_global_std_riskportfoliotracker: 'Risk Portfolio Tracker',
    'txt_global_std_Al-KhelaifiFacts': 'Abdulla participates in most motorsport events in Qatar and has several championships in different types of racing like National Drag, Qatar Mile, National Sprint, and QTCC.',
    txt_global_std_from: 'From',
    txt_global_std_callroadsideassistance: 'Call Roadside assistance',
    txt_global_std_p2ppushtitlehasacceptedthebooking: 'Booking accepted',
    txt_global_std_loyalty: 'Loyalty',
    txt_global_std_selectcountry: 'Select country',
    txt_global_std_HansenBesttip: 'To me, there are two crucial key factors for smart driving. The first: look and check what’s happening around you. The second: learn and get to know your car, and how it’s reacting to your inputs of the throttle, break and engine breaking. Combining these two crucial factors will take you really far in this competition.',
    txt_global_std_therulesforthecompanychallengeareasfollowingbrtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsbrdriveminimum1kmduringaheatinordertoqualifyforthechallengebrthesamegeographicalroutewillbescoredamaximumof10timesinoneday1: 'The rules for the company challenge are as following:\n' +
      '<br />• The individual driver score is calculated on an average based on the top 10% trips.\n' +
      '<br/>• Drive minimum 1 km during a heat in order to qualify for the challenge.\n' +
      '<hr>• The same geographical route will be scored a maximum of 10 times in one day.',
    txt_global_std_addinjuredperson: 'Add injured person',
    txt_global_std_okaywewillendthetour: 'Okay, we will end the tour.',
    txt_global_std_p2pexpensessummary: 'Expenses summary',
    txt_global_std_PolicySchedule28: 'SGD1,000 ALL CLAIMS IS IMPOSED ON DRIVERS WHO HAVE DEACTIVED THE DONGLE FOR AT LEAST 14 DAYS IN A CALENDAR YEAR.',
    txt_global_std_insurancecost: 'Insurance cost',
    txt_global_std_couldnotretrievedrivedata: 'Could not retrieve trip data',
    txt_global_std_loyaltypointsgainedfor: 'Loyalty points gained for reservation',
    txt_global_std_youversususername: 'You vs. {user}',
    txt_global_std_splittrips: 'Split trip',
    txt_global_std_topecodrivers: 'Top eco-drivers',
    txt_global_std_speedchangeanalysis: 'SPEED CHANGE ANALYSIS',
    txt_global_std_ongoinggames: 'Ongoing events',
    txt_global_std_totalpointsthisweek: 'Total points this week:',
    txt_global_std_statusnondriving: 'Non-driving',
    txt_global_std_unabletosavenote: 'Unable to save note',
    txt_global_std_schedule: 'Schedule',
    txt_global_std_deleteapikey: 'Delete API key',
    txt_global_std_leaderboard: 'Leaderboard',
    txt_global_std_cherry: 'Cherry!',
    txt_global_std_p2pendbeforestart: 'Please make sure end time is not earlier than start time!',
    txt_global_std_verifyyourphonenumber: 'Verify your phone number',
    txt_global_std_monthshorthand: 'mon',
    txt_global_std_youarenowconnectedandready: 'You are now connected and ready to start your first drive!',
    txt_global_std_youearnedthisbadgextimes: '(You earned this badge {x} times)',
    txt_global_std_p2pconfirmprice: 'Confirm price',
    txt_global_std_Fia_Receipt_Text14: 'Shipping and VAT (6%) is included in the price.',
    txt_global_std_p2pincludesafedriverbonus: 'Include safedriver bonus',
    txt_global_std_isthisanelectriccar: 'Is this an electric car?',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint2: 'Proactive understanding of portfolio strengths and weaknesses',
    txt_global_std_MSIG_Onboarding5: 'Our customers mean everything to us and we are glad to have you on board!',
    txt_global_std_signinwithfacebook: 'Sign in with Facebook',
    txt_global_std_zipdriver_upsell: 'Zip-Driver – determine precise risk level based on driving location.',
    txt_global_std_month: 'Month',
    txt_global_std_uploadcustombadgeicon: 'Upload custom badge icon',
    txt_global_std_PolicySchedule30: 'The windscreen cover under Endorsement No. 89C is unlimited.',
    txt_global_std_removeaccount: 'Remove Account',
    txt_global_std_verifyphonenumber: 'Your security code to verify phone number is',
    txt_global_std_deletegroup2: 'Delete group',
    txt_global_std_p2prepair: 'Repair',
    txt_global_std_repetition: 'Repetition',
    txt_global_std_requestinsuranceactivationcodebysms: 'Hi! \n' +
      'You have requested a new activation code for your {appname} account: {activationcode}\n' +
      'Greetings {appname} gang in {companyshort}.',
    txt_global_std_SignupFia_Text6: 'The signup will open soon.',
    txt_global_std_planningtips: 'Looking further ahead (and not just right in front of you) usually results in smoother driving and a higher chance of discovering obstacles in time. \n' +
      '\n' +
      'Ensuring an enjoyable ride for you and your passengers.',
    txt_global_std_riskindex_upsell_pitch_bulletpoint1: 'Unique insights into driver risk trends around the world ',
    txt_global_std_endaddress: 'End address',
    txt_global_std_daysremaininginthisperiod: '{days} days remaining in this period',
    txt_global_std_p2pcoderesent: 'Code resent',
    txt_global_std_didafriendridewithyou: 'Did a friend ride with you?',
    txt_global_std_TomczykMileage: 'The route to the family vacation is above all one of my favorites mileage to drive. ',
    txt_global_std_activationcode: 'Activationcode',
    txt_global_std_recruitfriendsandgetfreeheats: 'Recruit friends and get free heats!',
    txt_global_std_areyoureadytodrive: 'Ready to drive?',
    txt_global_std_ringsosphoneno: 'Ring SOS: {phoneNo}',
    txt_global_std_insuranceactivationkeyalreadycreated: 'Insurance activation key already created',
    txt_global_std_p2pconnecttoyourfriendscarwhenyourbookingstartsyouwillgetfeedbackandinsightsonyourdriving: 'Connect to your friend’s car when your booking starts. You will get feedback and insights on your driving.',
    txt_global_std_trafficdelaysdeadlinesandrunninglatetheycanallcausestresswhichisnegativeforyourhealthandsafetyallowingextratimeplanningyourrouteandcheckingtravelupdatescanhelptoreducestressandimproveyourfocusnew: 'Traffic delays, deadlines, and running late; they can all cause stress, which is negative for your health AND safety. Allowing extra time, planning your route, and checking travel updates can help to reduce stress and improve your focus.',
    txt_global_std_typicallyyouseeadigitalscreendisplayingtworowsofnumbersoneforthecarstotaldistancetravelledandoneforthedailydistances: "Typically you see a digital screen displaying two rows of numbers, one for the car's total distance travelled and one for the daily distances.",
    txt_global_std_TaxInvoice7: 'ALL CORPORATE POLICYHOLDERS ARE SUBJECT TO A PREMIUM PAYMENT WARRANTY For Periods of Insurance of more than 60 Days',
    txt_global_std_geyouwillreceiveanemailwithyourpersonaldata: 'You will receive an email with your personal data',
    txt_global_std_codehasbeensentto: 'Code has been sent to',
    txt_global_std_theenerfyglobalteam: 'The EnerfyGlobal team',
    txt_global_std_p2pstartadrivetoaccessinsightsandstatisticsaboutyourdriving: 'Start a drive to access insights and statistics about your driving.',
    txt_global_std_cimage17d: 'Plan and be active when on highway. Become aware how you can improve driving longer distances.',
    txt_global_std_removemissingtrip: 'Remove trip',
    txt_global_std_howdoyoumeasuremydrivinglexusanswer: 'The Lexus app uses artificial intelligence to measure your driving. The app connects to the cloud and will analyze every second of your driving- turned into “a profile”. The AI is comparing your profiles to over 750 million other unique driving profiles. The AI has over 16 years of experience to look for a driving pattern´s probability to be involved in an accident. Luckily, after almost every trip, drivers and passengers return home safe to their loved ones.',
    txt_global_std_notsignedupforacovercubeinsuranceyet: 'Not signed up for a Covercube insurance yet?',
    txt_global_std_iwilldoitlater: 'I will do it later',
    txt_global_std_sothedevicecanconnectyourphonewithyourcar: 'so the device can connect your phone with your car.',
    txt_global_std_WelcomeLetter_Text2: 'In this email, you will find your insurance documents including your insurance letter and pre-sale information. The complete insurance terms can be found in the {appName} app and at www.enerfy.se. If something in the attached documents is incorrect, please contact us so that we can correct it. ',
    txt_global_std_markermovediscount: 'Move the marker to see how your level affects your discount',
    txt_global_std_achachievements: 'Achievements',
    txt_global_std_skip: 'Skip',
    txt_global_std_wetakeeverystepandmeasuretominimiseourco2footprintenergyconsumptionandroadaccidentsbyinspiringourdriverstosmarterdrivingandtrackingourperformance: 'We take every step and measure to minimise our CO2 footprint, energy consumption and road accidents by inspiring our drivers to smarter driving and tracking our performance',
    txt_global_std_p2pfaq11a: '1.Press the menu with the three dots.\n' +
      '2.Go to your friends page.\n' +
      '3.Press the Invite friends button.\n' +
      '4.Send an invitation link to your friend. When your friend downloads the app via the link, they will show up in your list of friends.',
    txt_global_std_passwordsdonotmatch: 'Passwords do not match',
    txt_global_std_sendthemalink: 'Send them a link so that they can use the app.',
    txt_global_std_howdoirenewmyinsurance: 'How do I renew my insurance?',
    txt_global_std_35000pts2: '&#60;35 000 pts',
    txt_global_std_p2pselectcar: 'Select car',
    txt_global_std_notconnectedtoenerfycloud: 'Not connected to the {appName} cloud.',
    txt_global_std_dashboard_challenges: 'Challenges',
    txt_global_std_exportcsv: 'Export CSV',
    txt_global_std_downloadingdrivers: 'Downloading drivers',
    txt_global_std_invalidemail: 'Invalid email',
    txt_global_std_succesfullyactivatedinsurance: 'Successfully activated insurance',
    txt_global_std_hello123: 'Hello',
    txt_global_std_usernotfoundinteam: 'User not found in team.',
    txt_global_std_notifications: 'Notifications',
    txt_global_std_summaryofdistnacedivenperslectedperiod: 'Summary of distnace diven per slected period.',
    txt_global_std_PolicySchedule15: 'The policy is subject to endorsements/clauses',
    txt_global_std_contactsupport: 'Contact support',
    txt_global_std_iconheader: 'Unlocked icon',
    txt_global_std_userlockedout: 'The user is currently locked out for too many failed login attempts',
    txt_global_std_kintosmsandemailtext: 'Start driving safer with me! Follow the link below and get 10 km distance to drive with Kinto.',
    txt_global_std_since: 'Since',
    txt_global_std_unrestrictedinvitelink: 'Unrestricted invite link',
    txt_global_std_alertbreaktimetext: 'Rest alarm triggered.',
    txt_global_std_areyoudrivinganelectriccar: 'Are you driving an electric car?',
    txt_global_std_mycardoesnthavebluetoothanswer: 'No problem! Request a BT-OBD dongle and it will do the magic! Simply connect the dongle to the cable and plug it into your 12v charger in the car.  Then follow the “Connect to car” steps as normal and chose the name “Enerfy OBD” during the Bluetooth connection.',
    txt_global_std_firsttimeinapp: 'First time in app',
    txt_global_std_mutualfriends: 'Mutual friends',
    txt_global_std_p2pdeletemessage: 'Delete message',
    txt_global_std_improvetext: 'Trips with bonus in a row',
    txt_global_std_marklundDescription: 'Started racing internationally 2011 and have since then been competing in series as Porsche Carrera Cup Scandinavia, FIA World/European Rallycross Championship and X-Games.',
    txt_global_std_changeyournumber: 'Change your number',
    txt_global_std_error: 'Error',
    txt_global_std_drivingtechnique: 'Driving technique',
    txt_global_std_deleteachievementpopuptext: 'Your achievement will be deleted permanently. Your users progress will be lost. Thoes who have already recieved the achievement will still be able to se it.',
    txt_global_std_challengeinvitesent: 'Challenge invite sent',
    txt_global_std_bycheckingthischeckboxandlogginginyoureacceptingour: 'By checking this checkbox and logging in you’re accepting our',
    txt_global_std_startdriving: 'Start driving',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithagentwithalapseincoverage: 'You will have {days} days from the expiration date to make a payment and continue your policy with {agent} with a lapse in coverage.',
    txt_global_std_thechaser: 'The chaser',
    txt_global_std_pickyourcoverimage: 'Pick your cover image',
    txt_global_std_tobeabletosendmessagesweneedtoaddyourfriendsletsinvitethem: "To be able to send messages we need to add your friends. Let's invite them!",
    txt_global_std_pleasechoosewhichtypeofpictures: 'Please choose which type of pictures',
    txt_global_std_demotext5: 'When you score – you save! See your total bonus here.',
    txt_global_std_p2pverifyphonenumber: 'Verify phone number',
    txt_global_std_yourlinkshouldbeavailableinyourinboxin5minutespleaseremembertocheckyourjunk: 'Your link should be available in your inbox in 5 minutes. Please remember to check your junk.',
    txt_global_std_startyourcar: 'Start your car.',
    txt_global_std_manydriversrelytooheavilyoncruisecontrolitiscomfortableyesbutlethalforfueleconomyinmanycasesusingcruisecontrolontheslightestuphillordownhillwillcausetheenginetofighthardtosatisfyyourwishofmaintainingtheexactspeedyousetinsteadmanagingyourspeedwithyourfootandaimingforasmoothridewillhelpyousaveahugeamountonfuel: 'Many drivers rely too heavily on cruise control. It is comfortable, yes, but lethal for fuel economy in many cases. Using cruise control on the slightest uphill or downhill will cause the engine to fight hard to satisfy your wish of maintaining the exact speed you set. Instead, managing your speed with your foot and aiming for a smooth ride will help you save a huge amount on fuel!',
    txt_global_std_bethefirsttoreceivenews: 'Be the first to receive news',
    txt_global_std_p2pitstimetosplityourcostformonthofph: 'Its time to split your cost for month of {month}',
    txt_global_std_enterzipcode: 'Enter zip code',
    txt_global_std_top3bestdrives: 'Top 3 best drives',
    txt_global_std_KristofferssonMusic: 'I like to listen to Godsmack.',
    txt_global_std_contactinfogreaterthaneuformoreinformationonhowtoactivatethismodule: 'Contact info@greaterthan.eu for more information on how to activate this module',
    txt_global_std_startguidetip3: 'Click the Play-button in the bottom middle of the screen.',
    txt_global_std_carsagreementsinsurance: 'Cars & agreements / insurance',
    txt_global_std_enteravalidchassisnumber: 'Enter a valid chassis number.',
    txt_global_std_p2pyoudonthaveanycostsplitsorinvoicesyet: "You don't have any cost splits or invoices yet.",
    txt_global_std_p2pacceptmarketingandprivacy: 'Accept privacy policy and register for newsletter.',
    txt_global_std_p2pselectfriendsoragroup: 'Select friends or a group',
    txt_global_std_cimage4h: 'PLAN FOR FREQUENT STOPS',
    txt_global_std_notconnectednoaddress: 'Not connected - no address',
    txt_global_std_savingsisbasedonrisklevelanddistance: 'Saving is based on risk level and distance',
    txt_global_std_searchfriends: 'Search friends',
    txt_global_std_p2pnofriendsfoundpleaseinvitefriend: 'No friends found, please invite friend',
    txt_global_std_insureddistance_used_100__1: 'Du har nu använt din körsträcka. För att få full kompensation är det viktigt att du inte överskrider antalet kms som valts. Vänligen kontakta eller justera körsträckan här.',
    txt_global_std_p2psendrequest: 'Send request',
    txt_global_std_usersupportcenter: 'User Support Center',
    txt_global_std_drivingstartandstopsettings: 'Driving start & stop settings',
    txt_global_std_aaninsuranceadjusterwillcontactyouwithin24hoursofyourclaimbeingsubmittedtheentireclaimsprocesswilldependonthecomplexityoftheclaim: 'A: An insurance adjuster will contact you within 24 hours of your claim being submitted. The entire claims process will depend on the complexity of the claim.',
    txt_global_std_signedinas: 'Signed in as',
    txt_global_std_pleaseenablebluetoothlocationservicesandmotionfitness: 'Please enable Bluetooth, Location Services and Motion & Fitness.',
    txt_global_std_createanaccounttostartyour30dayfreetrial: 'Create an account to start your 30 day free trial.',
    txt_global_std_members3: 'members',
    txt_global_std_yourchassisshouldcontainacombinationofnumbersandletters: 'Your chassis should contain a combination of numbers and letters.',
    txt_global_std_directtoapp: 'Direct to app',
    txt_global_std_cimage10d: 'Drive on as high gear possible and keep RPM low.',
    txt_global_std_TidemandInterests: 'Water sports, seeing friends and Motorsports',
    txt_global_std_firsttimeusingtheserviceregisterfromsignuplink: 'First time using the service? Register from {signUpLink}',
    txt_global_std_youhavedeniedthelocationpermission: 'You have denied the location permission',
    txt_global_std_somethingwhentwrongtryagain: 'Something went wrong, try again.',
    txt_global_std_yourreporthasbeensentsuccessfully: 'Your report has been sent successfully!',
    txt_global_std_fromtheleftsidetoolbarhoverorclickontheiconstonavigatethroughtheportaltoitsdifferentcomponentsandpages: 'From the left side toolbar, hover or click on the icons to navigate through the portal to its different components and pages.',
    txt_global_std_october: 'October',
    'txt_global_std_drivedetailshavebeensentto{email}': 'Trip details have been sent to {email}',
    txt_global_std_payouts: 'Payouts',
    txt_global_std_choosesetup: 'Choose setup',
    txt_global_std_picturereceived: 'Picture received',
    txt_global_std_oliversolbergDescription: 'Son of Petter Solberg. He drives his father’s car for the Nordic team.',
    'txt_global_std_H.SolbergDescription': 'Solberg är den äldre bror till 2003 FIA World Rally Champion Petter Solberg och genom sin svenska maka Maud, styvfar till den svenska rallydrivaren Pontus Tidemand. En av sportens mest erfarna förare, när han är i spåret, har han makt att skämma bort ungdomarna.',
    txt_global_std_cimage32h: 'EVERY ACCELERATION COSTS MONEY',
    txt_global_std_tryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagain: "• Try resetting your car's connection to your phone by removing it and then adding it again.",
    txt_global_std_whendrivingonmotorwaystoomanydriverspumptheacceleratorpedalupanddowninaneverendingpattern100kmhto110kmhthen110kmhto100kmhandsoonthistypeofdrivingusesunnecessaryfuelkeepingasafesuitablefollowingdistancecanhelpavoidthetemptationtovaryspeedunnecessarily: 'When driving on motorways too many drivers pump the accelerator pedal up and down in a never-ending pattern: 100 km/h to 110 km/h, then 110 km/h to 100 km/h and so on! This type of driving uses unnecessary fuel. Keeping a safe, suitable following distance can help avoid the temptation to vary speed unnecessarily.',
    txt_global_std_reward: 'Reward',
    txt_global_std_changeyourcontactdetails: 'Change your contact information',
    txt_global_std_cimage3d: 'Try to forsee happenings',
    txt_global_std_thepumper: 'The pumper',
    txt_global_std_hereisthetestkey: 'Here is the test key',
    txt_global_std_headers: 'Headers',
    txt_global_std_3selectyourcarinthelist: '3. Select your car in the list:\n',
    txt_global_std_textaboutwhentranslationswillbevisibleinappetc: 'Text about when translations will be visible in app etc.',
    txt_global_std_useruploadtemplate: 'User Upload Template',
    txt_global_std_joinwithactivationcode: 'Join with activation code',
    txt_global_std_communityteams: 'Community Teams',
    txt_global_std_resendemail: 'Resend email',
    txt_global_std_headerpassword: 'Password',
    txt_global_std_f_lanzwhyjoin: "While learning about the challenges we face daily on the roads, let's enjoy it at the same time. \n" +
      'Learning whilst having fun always equals better results',
    txt_global_std_OgierFacts: "He is the current holder of the World Rally Drivers' Championship, having won the title six times, in 2013, 2014, 2015, 2016, 2017 and 2018. With 44 victories in the World Rally Championship and six consecutive WRC titles, he is the second most successful WRC driver, after former Citroën WRC teammate Sébastien Loeb (nine titles).",
    txt_global_Fia_Receipt_Text1: 'Welcome!',
    txt_global_std_teamranking: 'Team ranking',
    txt_global_std_p2pawaitingapproval: 'Awaiting approval',
    txt_global_std_MSIG_InsuranceLetter35: 'MS First Capital Insurance Ltd 6 Raffles Quay #21-00 Singapore 048580 Tel: (65) 6222 2311 Fax: (65) 6222 3547 www.msfirstcapital.com.sg',
    txt_global_std_exclude: 'Exclude',
    txt_global_std_sendinvite: 'Send invite',
    txt_global_std_milesmi: 'Miles (mi)',
    txt_global_std_finalistsheat1: 'Finalists - Heat 1',
    txt_global_std_whenwouldyouliketoterminateyourinsurance2: 'When would you like to terminate your insurance?',
    txt_global_std_fixitnow: 'Fix it now',
    txt_global_std_p2ppushbodyaddedexpences: 'Check expences on booking. \nFrom: {bookingperiod}',
    txt_global_std_theloyaltysystem: 'The loyalty system',
    txt_global_std_entertheemailthatyouusedwhenyouregisteredyourinsurance: 'Enter the email that you used when you registered your insurance',
    txt_global_std_choosealwaysorallowwhileusing: 'Choose "Always" or "Allow while using".',
    txt_global_std_gpsactive: 'GPS active',
    txt_global_std_discardchanges: 'Discard changes',
    txt_global_std_theuseriscurrentlylockedoutfortoomanyfailedattempts: 'The user is currently locked out for too many failed attempts',
    txt_global_std_levelprogress: 'Level Progress',
    txt_global_std_MSIG_InsuranceLetter23: '* Provided that the person driving is permitted in accordance with the licensing or other laws or regulations to drive the Motor Vehicle or has been so permitted and is not disqualified by order of a Court of Law or by reason of any enactment or regulation in that behalf from driving the Motor Vehicle.',
    txt_global_std_accidentguide: 'Accident guide',
    txt_global_std_p2plendyourkintotofamilyandfriends: 'Lend your Kinto to family and friends',
    txt_global_std_BeforeFirstBill_Text1Enerfy: 'We will soon draw money from your card for your car insurance. We have compiled the most common questions that our customers have when receiving their first invoice. ',
    txt_global_std_yourdrivewillendin: 'Your drive will automatically end in:',
    txt_global_std_howdoyoumeasuremydrivinganswer: '{appname} uses artificial intelligence to measure your driving. The app connects to the cloud and will analyze every second of your driving- turned into “a profile”. The AI is comparing your profiles to over 750 million other unique driving profiles. The AI has over 16 years of experience to look for a driving pattern´s probability to be involved in an accident. Luckily, after almost every trip, drivers and passengers return home safe to their loved ones.',
    txt_global_std_p2ptemppassword: 'Temporary password',
    txt_global_std_roadsideassistance: 'Roadside Assistance',
    txt_global_std_signupforinsurance: 'Sign up for insurance',
    txt_global_std_100pointtripachievementlocked: 'Finish a drive at {scoretype} {score} or better to unlock this achievement and earn 100 points. ',
    txt_global_std_keepingyourmindandeyesonwhatshappeningaroundyouisvitalforyoursafetyandthesafetyofothersphoneactivityandotherdistractionsaffectyourfocusandincreasecrashrisknew: 'Maintaining intended speed is utmost important for fuel economy. Mind wandering and lack of focus often causes speed variation up and down, causing unnecessary accelerations, hence increases fuel consumption.',
    txt_global_std_baseurl: 'Base URL',
    txt_global_std_SignupFia_Text1: 'You are in!',
    txt_global_std_leavechallenge: 'Leave challenge',
    txt_global_std_accountcreatedlogin: 'Your account has been created, you can now login!',
    txt_global_std_ihavesoldmycar2: 'I have sold my car',
    txt_global_std_hi: 'Hi!',
    txt_global_std_photo: 'Photo',
    txt_global_std_TaxInvoice9: 'Insurer is entitled to a pro-rata premium payment for the 60-day cover (subject to minimum S$25).',
    txt_global_std_obdconnected: 'OBD connected',
    txt_global_std_nodatatoshow_loyalty: 'No data to show',
    txt_global_std_ifyoudidntmakethisrequestyoucansafelyignorethisemail: 'If you didn’t make this request you can safely ignore this email.',
    txt_global_std_activationcodeemail1: 'Your activation code',
    txt_global_std_p2ppushtitlechangeperiodfromto: 'Changed period',
    txt_global_std_minutesshort: 'm',
    txt_global_std_youhavenowreceivedamountyenatcompany: 'You have now received {amount} yen at {company}.',
    txt_global_std_chooseafile: 'Choose a file',
    txt_global_std_alertlocationnotalways: 'Set Location Service permission to Always to register drives without data loss.',
    txt_global_std_nodriversonleaderboard: 'Currently no drivers on the leaderboard',
    txt_global_std_nextstep: 'Next',
    txt_global_std_registrationnumberalreadyexists: 'Registration number already exists',
    txt_global_std_typeofmessage: 'Type of message',
    txt_global_std_birthdate: 'Birthdate',
    txt_global_std_badgeisworthxpointsunlocked: 'Badge worth: {points}P',
    txt_global_std_realtimefeedbackonthego: 'Real time feedback on the go.',
    txt_global_std_youarereadytogodriveandearnyourfirstbonus: 'You are ready to go! Drive and earn your first bonus :)',
    txt_global_std_Fia_Receipt_Text13: 'info@fiasmartdrivingchallenge.com',
    txt_global_std_inactive: 'Inactive',
    txt_global_std_retardation: 'Retardation',
    txt_global_std_totalminutes: 'Total time',
    txt_global_std_yesihaveredeemedmypoints: 'Yes, I have redeemed my points!',
    txt_global_std_jointeam: 'Join team',
    txt_global_std_p2pblockeddate: 'Blocked date',
    txt_global_std_getstartedmanual: 'Get Started Manual',
    txt_global_std_bringoutyourcompetitivesideandreceivethehighestscoreoftheevent: 'Bring out your competitive side and receive the highest score of the event.',
    txt_global_std_timeinconstantspeed: 'Time in constant speed',
    txt_global_std_claimsdepartment: 'Claims department',
    txt_global_std_p2pwrongcodetryagain: 'Wrong code. Try again.',
    txt_global_all_wednesday: 'Wednesday',
    txt_global_std_MSIG_InsuranceLetter14: 'Financial Institution',
    txt_global_std_doyouacceptthechallenge: 'Do you accept the challenge?',
    txt_global_std_off: 'Off',
    txt_global_std_oversigtovernivauetpdintur: 'Level overview',
    txt_global_std_allowallthetime: 'Allow all the time',
    txt_global_std_youcandobetter: 'You can do better!',
    txt_global_std_yourdrivewasnotregisteredifyouhadyourenerfypluginconnectedwhiledrivingthecarbutdidnothavetheappbluetoothorgpslocationservicesactivesothatitregistersdrivingdatacompletedrunswillbechargedafterwardsinpremiumlevel10to15: 'Your drive was not registered. If you had your Enerfy plug-in connected while driving the car but did not have the app, Bluetooth or GPS / location services active so that it registers driving data, completed runs will be charged afterwards in premium level 10 to 15.',
    txt_global_std_atrustedscoreusedbyfia: 'A trusted score used by FIA',
    txt_global_std_thedriverwiththebestscorewinstheround: 'The driver with the best score wins the round!',
    txt_global_std_usertermsandconditions: 'User terms & conditions',
    txt_global_std_verifyemail: 'Verify email',
    txt_global_std_authentication: 'Authentication',
    txt_global_std_tomasengechamp: 'First Czech driver in F1 and Indycar, Czechcircuit champion, German FFord Champion',
    txt_global_std_carrorewardworkshops: 'Carro reward workshops',
    txt_global_std_welcometo2: 'Welcome to',
    txt_global_std_cardnummer: 'Card no.',
    txt_global_std_henningsolbergDescription: 'Solberg is the older brother of the 2003 FIA World Rally Champion Petter Solberg and through his Swedish spouse Maud the stepfather of Swedish rally driver Pontus Tidemand. One of the sport’s most experienced drivers, when he’s in the groove he has the power to embarrass the youngsters.',
    txt_global_std_cimage10h: 'DRIVE ON LOW RPM AND HIGH GEARS',
    txt_global_std_signedinasv2: 'Signed in as',
    txt_global_std_insureddistance_used_80__1: 'Du har nu använt 80% av din årliga valda körsträcka. Vill du justera körsträckan?',
    txt_global_std_p2pbookingmodifiedsuccessfully: 'Booking modified successfully',
    txt_global_std_ConfirmFia_Text4: 'Time',
    txt_global_std_p2pfaqandsupport: 'FAQ and support',
    txt_global_std_congratulationsyoursetupiscompleteandyouarereadytogo: 'Congratulations! Your setup is complete and you are ready to go.',
    txt_global_std_nottowed: 'Not towed',
    txt_global_std_zipdriver: 'Zip Driver',
    txt_global_std_userstate: 'User state',
    txt_global_std_p2pyoucaninvitexfriendspercar: 'You can invite {number} friends per car',
    txt_global_std_online: 'Online',
    txt_global_std_obdreaderonitsway: 'OBD reader on its way!',
    txt_global_std_cimage19h: 'KEEP YOUR GAS STEADY',
    txt_global_std_p2pchooseafriendorinviteanewfriend: 'Choose a friend or invite a new friend',
    txt_global_std_notificationsisagreatwaytostayontopallowthemtoletusguideyoutowardsbecomeanevensmarterdriverandtakeyourdrivingtothenextlevel: 'Notifications is a great way to stay on top. Allow them to let us guide you towards become an even smarter driver and take your driving to the next level.',
    txt_global_std_mpltipafpp: 'This is a skilled driver, however, sometimes during a trip and only for a short period of time, this driver tend to lose focus on Cool Driving. Some distractions or stress may cause this driver to ignore Safety awareness. This is often fixed by talking with the driver and ask for his consideration. ',
    txt_global_std_translationtool_upsell_pitch_bulletpoint1: 'Quickly make your app multilingual ',
    txt_global_std_itisa12digitcodethatyoureceivefromtheeventorchallengeorganizer: 'It is a 12-digit code that you receive from the event or challenge organizer.',
    txt_global_std_searchandmanageagreements: 'Search and manage agreements ',
    txt_global_std_afollowthesestepstoconnecttheapptoyourcar1pressthedrivebuttonintheapp2attachtheobddevicetothecableandplugitintothe12vpowercigarettelightersocketinyourcar3startyourcarsengine4selecttheobdreaderinthelistintheappandcontinue: 'A: Follow these steps to connect the app to your car:\n' +
      '1. Press the drive button in the app\n' +
      '2. Attach the OBD-device to the cable and plug it into the 12v power (cigarette lighter) socket in your car.\n' +
      '3. Start your car’s engine\n' +
      '4. Select the OBD reader in the list in the app and continue.',
    txt_global_std_calendar: 'Calendar',
    txt_global_std_p2pnonsequentialdatesselected: 'Nonsequential dates selected',
    txt_global_std_sortby: 'Sort by',
    txt_global_std_youcannoteditthisfromvisualeditor: 'You can not edit this from Visual editor.',
    txt_global_std_teamcolon: 'Team:',
    txt_global_std_exportxml: 'Export XML',
    txt_global_std_youraveragelevelis123: 'Your average level is ',
    txt_global_std_triptypes: 'Trip types',
    txt_global_std_belowisalistofalluserswhosnotcompletedthisstep: 'Below is a list of all users who’s not completed this step',
    txt_global_std_ChicheritFacts: 'Monster Athlete Guerlain Chicherit steps up to the FIA World Rallycross Championship in 2018 with his own GC Kompetition team driving the new Prodrive-developed Renault Megane RS. A professional skier and four-time world champion in freeriding, Chicherit holds the record for becoming the first man in history to complete an unassisted backflip in a car. Chicherit made his World RX debut in the JRM Mini RX Countryman in 2015.',
    txt_global_std_p2ptotal: 'Total:',
    txt_global_std_sendasemail: 'Send as email',
    txt_global_std_advancedusersettingsfor: 'Advanced user settings for {name}',
    txt_global_std_bluetoothreasontext: 'A Bluetooth connection is required to make sure you are seated in your car.',
    txt_global_std_wrongpassword: 'Wrong password',
    txt_global_std_youaredrivingregno: 'You are driving {regno}',
    txt_global_std_p2pblockmycar: 'Block my car',
    txt_global_std_loadingteamleaderboard: 'Loading teamleaderboard...',
    txt_global_std_successfulpasswordchange: 'You have successfully changed your password.',
    txt_global_std_dayd: 'd',
    txt_global_std_inviteandinspireeverydriverinyournetworktoparticipateandgetnewdataonyouresgperformance: 'Invite and inspire every driver in your network to participate and get new data on your ESG performance ',
    txt_global_std_startadrive: 'Start a trip',
    txt_global_std_statusdriving: 'Driving',
    txt_global_std_tilladnotifikationer: 'Allow push notifications',
    txt_global_std_safedriverweeklyunlockedkey: 'Great job {user}! You kept a score above {score} for a week.',
    txt_global_std_wecannotfindanydata: 'We cannot find any data!',
    txt_global_std_qhowwilligetpaidforaclaim: 'Q: How will I get paid for a claim?',
    txt_global_std_notripsperday: 'Number of trips per day',
    txt_global_std_kintofaqlink1: 'Get Started Manual (Japanese)',
    txt_global_std_ranking: 'Ranking',
    txt_global_std_noconnectiontocar: 'No connection to car',
    txt_global_std_yournicknameshouldcontainatleastthree: 'Your nickname should contain at least three (3) characters and no spaces.',
    txt_global_std_decline: 'Decline',
    txt_global_std_p2pconfirm: 'Confirm',
    txt_global_std_tobeabletostartadrive: 'To be able to start a drive we need access to bluetooth, location services and motion fitness.',
    txt_global_std_SendMyPosition_Text1: 'has sent their position to you from the {appName}-app.',
    txt_global_std_enterbasepremium: 'Enter base premium',
    txt_global_std_colortheme: 'Color theme:',
    txt_global_std_1gotosettingsforappnameinyourphonebrb2allowaccesstomotionfitnessb: '1. Go to settings for {appname} in your phone.<br><b>2. Allow access to Motion & Fitness.</b>',
    txt_global_std_mostpopular: 'Most popular',
    txt_global_std_challengeaverage1: 'Challenge average',
    txt_global_std_statistikfordennekretur: 'Stats for this trip',
    txt_global_std_OSolbergQuote: 'Quote coming soon...',
    txt_global_std_invitationsent: 'Invitation sent.',
    txt_global_std_tilladatappensenderdignotifikationernrdererregistreretnyekreture: 'Allow Tryg Drive to send you notifications when you have actions to do in the app.',
    txt_global_std_p2pcarcare: 'Car care',
    txt_global_std_p2pemailopenapp: 'OPEN {appname}',
    'txt_global_std_Al-KuwariMusic': 'Classic music.',
    txt_global_std_pleasefirstfillinyourfirstnameandlastname: 'Please first fill in your first name and last name.',
    txt_global_std_nicknamenicknamealreadyexists: 'Nickname {nickname} already exists',
    txt_global_std_2makesureyourcarsbluetoothison: "2. Make sure your car's Bluetooth is on.",
    txt_global_std_enginemustberunning: 'Engine must be running',
    txt_global_std_qualificationfinalstarts30thmarch: 'Qualification final starts 30th march.',
    txt_global_std_shareinvite: 'Share invite',
    txt_global_std_p2pselecteddateandtime: 'Selected date and time',
    txt_global_std_forthisapptoworkproperlyweneedtoaccessyourlocationbluetoothandphysicalfitnesspermissionthelocationserviceisusedinbackgroundforthedrivingfeaturetoanalyzeyourdrivingwithoptimalresultstheappalsodoesthisinthebackgroundwevalueyourprivacyandwillnotsharethisinformationwithathirdpartyentity: 'For this app to work properly, we need to access your location, Bluetooth and physical fitness permission. The location service is used in background for the driving feature. To analyze your driving with optimal results the app also does this in the background. We value your privacy and will not share this information with a third party entity.',
    txt_global_std_kintofaq9a: 'Because the AI needs to know the road to be able to calculate the score and to help you in case of emergency. The Kinto app will never share location or any other data with a third party.',
    txt_global_std_qhowdoiconnecttomycarsbluetooth: 'Q: How do I connect to my car’s Bluetooth?',
    txt_global_std_passwordchanged: 'Password changed',
    txt_global_std_deleteaccount: 'Delete Account',
    txt_global_std_repetitionsnumber: 'Repetitions number',
    txt_global_std_collectablebadges: 'Collectable badges',
    txt_global_std_youbooked5timesinonemonthyouhaveearned1500points: 'You have earned 1,500 points for making 5 reservations in a month.',
    txt_global_std_goodthatyouarekeepingyouraccountsecure: 'Good that you are keeping your account secure.',
    txt_global_std_business: 'Business',
    txt_global_std_BeforeFirstBill_Hello: 'Hello',
    txt_global_std_drivemorethan1miletogetmoredetailedanalyticsaboutyourtrip: 'Drive more than 1 mile to get more detailed analytics about your trip.',
    txt_global_std_summaryofanalyse: 'Summary of analyse',
    txt_global_std_youearnedapercentdiscountbydrivingsafe: 'You earned a {percent}% discount by driving safe',
    txt_global_std_thepiggybank: 'The piggy bank',
    txt_global_std_youareamongthebestappnamedrivers: 'Wow! You are among the best {appname} drivers!',
    txt_global_std_step3: 'Step 3',
    txt_global_std_upcoming: 'Upcoming',
    txt_global_std_pigsinvited: 'Refer friends',
    txt_global_std_weregladtohaveyouonboardwatchourvideoguideforsomequicktipstogetyouupandrunning: 'We’re glad to have you onboard. Watch our video guide for some quick tips to get you up and running.',
    txt_global_std_youmayhavetogotoyourbluetoothsettingsforbothyourphoneandcar: 'You may have to go to your Bluetooth settings for both your phone and car.',
    txt_global_std_buy2: 'Buy',
    txt_global_std_p2pborrowyour: 'borrow your',
    txt_global_std_f_lanzmusic: 'Classic , Hip Hop and Dance',
    txt_global_std_HSolbergWhyjoin: 'For the first time, my fans and I can compete together to win an FIA Challenge. ',
    txt_global_std_friendsreferral: 'Friends referral',
    txt_global_std_qcanisignupforinsurancetobegintoday: 'Q: Can I sign up for insurance to begin today?',
    txt_global_std_MSIG_Onboarding9: '3. Drive!',
    txt_global_std_gooddrivingtechnique: 'Good driving technique',
    txt_global_std_howdoifileaclaim: 'How do I file a claim?',
    txt_global_std_SignupFia_Text3: 'FIA SDC is the initiative where top racing stars and drivers from across the globe meet in one epic challenge. With the use of a smartphone app, you’ll be able to join a team, challenge the best drivers in the world and compete for the title as the FIA Smartest Driver.',
    txt_global_std_yourappnamehastobeatleasttwocharacterslong: 'Your app name has to be at least two characters long',
    txt_global_std_appcreator_upsell: 'App Creator – create your own branded driving app in minutes.  ',
    txt_global_std_advancedusersettings: 'Advanced user settings',
    txt_global_std_weneedpermissiontoaccessyourbluetoothforthisapptowork: 'We need permission to access your Bluetooth for this app to work. To continue using this app you need to go to your device ',
    txt_global_std_p2pfaq10q: 'What if my friend damage my car?',
    txt_global_std_rulesforservice: 'Rules for service',
    txt_global_std_itemsperpage: 'Items per page',
    txt_global_std_idling: 'Idling',
    txt_global_std_searchtranslation: 'Search Translation',
    txt_global_std_fiasmartdrivingchallenge: 'FIA Smart Driving Challenge',
    txt_global_std_p2phasreceivedlendingrequest: ' has received your lending request.',
    txt_global_std_publishchanges: 'Publish Changes',
    txt_global_std_endinsurance: 'End Insurance',
    txt_global_std_qhowdoiconnecttomycar: 'Q: How do I connect to my car?',
    txt_global_std_congratulationyoupositiononleaderboardwasincreased: 'Congratulation! You position on leaderboard was increased!',
    txt_global_std_TidemandDescription: 'Pontus’ first taste of competitive came in crosskarts, where he became both Nordic and Swedish champion. 2011, the youngest driver to win the Swedish Rally Championship',
    txt_global_std_p2pfaq8a: 'You can easily block your car on days that you know you will be driving it yourself or for other reasons, does not want to share your car.\n' +
      '1.Go to Home\n' +
      '2.Press the Calendar button on your top left\n' +
      '3.Then choose the date and time you would like to block your car.',
    txt_global_std_youdrovereallywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'You drove really well! Your average was better than {score} for a week! You have earned {points} points!',
    txt_global_std_SulayemMileage: 'Liwa Oasis – Al Ain road through the Empty Quarter Desert, experiencing the spectacular peaceful beauty of the desert, driving on this road will make you calm and collected.',
    txt_global_std_failedtosendtothefollowingusers: 'A message was added and sent to all users except for the following:',
    txt_global_std_repeatyourpassword2: 'Repeat your password',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint3: 'Reinforce good behaviors through gamification ',
    txt_global_std_pleasereloadortryagainlater: 'Please reload or try again later.',
    txt_global_std_inviteuser: 'Invite user',
    txt_global_std_nofriendsfound: 'No friends found.',
    txt_global_std_co2lockedkey: 'Drive eco friendly and save more than {co2value}% CO₂ per trip to earn this badge.',
    txt_global_std_addfriendtolist: 'Add friend to list',
    txt_global_std_daysdays: '{days} Days',
    txt_global_std_bestdriverstoday: 'Best drivers today',
    txt_global_std_backtoleaderboard: 'Back to leaderboard',
    txt_global_std_50kmunlockedkey: 'You drove a distance of 50 km with the app!',
    txt_global_std_warikan_onboarding_title_2: '【Warikan KINTO】App Temporary Password',
    txt_global_std_allowmotionfitness: 'Allow Motion & Fitness',
    txt_global_std_locationselectalways: 'Location - select "always"',
    txt_global_std_sms: 'SMS',
    txt_global_std_idonthaveanaccount: "I don't have an account?",
    txt_global_std_allowlocationservices: 'Allow Location Services',
    txt_global_std_activatecode: 'Activate Code',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint3: 'Incentivize users using your own KPIs including CO2 reductions, risk reductions, car-free days, and more',
    txt_global_std_TaxInvoice11: 'Full premium payment must be made before policy inception at the time of documentation.',
    txt_global_std_hereyoucanseehowyouhavedriven: 'Here you can see how you have driven.',
    txt_global_std_appnamewallet: '{appname} Wallet',
    txt_global_std_canbeearnedwhenyoufocustoimproveinthisspeed: 'can be earned when you focus to improve in this speed',
    txt_global_std_wildfire: 'Wildfire!',
    txt_global_std_letsgo: 'Lets go',
    txt_global_std_theresetlinkwillexpirein20minafterthatyoullneedtosubmitanewrequestinordertochangeyourpassword: 'The reset link will expire in 20 min, after that you’ll need to submit a new request in order to change your password.',
    txt_global_std_warikan_onboarding13: 'Reception hours 9:00 to 18:00 Open all year round (excluding the year-end and New Year holidays)\n',
    txt_global_std_challengesomeone: 'Challenge someone',
    txt_global_std_settingssaved: 'Settings saved',
    txt_global_std_p2pviewreceipt: 'View receipt',
    txt_global_std_greatstreak: 'Great streak!',
    txt_global_std_vouchercodes: 'Voucher codes',
    txt_global_std_getstarted2: 'Get started',
    txt_global_std_p2pdistancetravelled: 'Distance travelled',
    txt_global_std_discount: 'Discount',
    txt_global_std_buymoremilage: 'Buy more milage',
    txt_global_std_closed: 'Closed',
    txt_global_std_addcustombadgeicon: 'Add custom badge icon',
    txt_global_std_feedbackoptions: 'Feedback options',
    txt_global_std_navigation: 'Navigation',
    txt_global_std_willbeaddedtoyoursubscription: 'will be added to your subscription',
    txt_global_std_fortst: 'Continue',
    txt_global_std_youmustnotuseappwhiledriving: 'You must not use the app while driving.',
    txt_global_std_allowbluetoothtocollectdrivingscore: 'Allow Bluetooth to collect your driving score.',
    txt_global_std_viewpassword: 'View password',
    txt_global_std_leaderboard_tab_monthly: 'Monthly',
    txt_global_std_invitationsentto: 'Invitation sent to',
    txt_global_std_thesebadgesyoucanonlyearnonetime: 'These badges you can only earn one time.',
    txt_global_std_communication_upsell: 'Communication Tool – boost engagement with personalized notifications.',
    txt_global_std_ifyoucomeacrosswordsandphrasesyoucanteditbyclickinginthevisualeditoryoucansearchandfindtheminthelistbelow: ' * If you come across words and phrases you can’t edit by clicking in the visual editor, you can search and find them in the list below.',
    txt_global_std_typehere: 'Type here',
    txt_global_std_endd: 'End',
    txt_global_std_accountpreferences: 'Account preferences',
    txt_global_std_rules: 'rules',
    txt_global_std_showonleaderboard: 'Show on leaderboard',
    txt_global_std_cimage5h: 'HILL TECHNIQUE',
    txt_global_std_MSIG_InsuranceLetter7: 'Type of Policy.',
    txt_global_std_connectingyourgarminwatchiseasyclicktheconnectbuttonbelowtohelpusuncovernewinsightsintocrashriskbyanalyzingbothdrivingandhealthdata: 'Connecting your Garmin watch is easy! Click the Connect button below to help us uncover new insights into crash risk by analyzing both driving and health data.',
    txt_global_std_showall: 'Show all',
    txt_global_std_p2pcostsplitisbasedonhoursthecarisused: 'Cost split is based on hours the car is used.',
    txt_global_std_anonymous: 'anonymous',
    txt_global_std_viewloyaltypoints: 'View loyalty points',
    txt_global_std_totaldistance2: 'Total Distance',
    txt_global_std_: '',
    txt_global_std_namecancelledthebooking: '{name} cancelled booking',
    txt_global_std_cimage40d: 'By rolling, you keep the progress, in addition to saving fuel and the car from wearing down.',
    txt_global_std_uservsuser: 'User vs. user',
    txt_global_std_powerhuman: 'Power Human',
    txt_global_std_p2pdividedequallyamongfriendsthatusedcar: 'Divided equally among friends that used car.',
    txt_global_std_achievementcreatedmessage: 'Achievement have been created!',
    txt_global_std_p2pitstimetosplityourcostformonthof: 'It’s time to split your cost for month of',
    txt_global_std_dontrestrictkey: 'Don’t restrict key',
    txt_global_std_usethistemplate: 'Use this template',
    txt_global_std_sendagain: 'Send again',
    txt_global_std_mysubscriptioncost: 'My subscription cost',
    txt_global_std_kilometers_driven: 'Kilometers driven',
    txt_global_std_typeyourverificationcode: 'Type your verification code',
    txt_global_std_howdoesthescoringsystemwork: 'How does the scoring system work?',
    txt_global_std_orrecruitafriend: 'Or recruit a friend',
    txt_global_std_stop: 'Stop',
    txt_global_std_youcanuploadmax10photosofyourcar: 'You can upload max 9 photos of your car',
    txt_global_std_hereyoufindyourhighestimprovmentpotential: 'Here you find your highest improvment potential',
    txt_global_std_easywaytogetinvolved: 'Easy way to get involved',
    txt_global_std_OSolbergFacts: 'Son of Petter Solberg. He drives his father’s car for the Nordic team.',
    txt_global_std_usersuccessfullyadded: 'User successfully added',
    txt_global_std_avgrpm: 'Avg. RPM',
    txt_global_std_youearnedapercentagephdiscount: 'You earned a {percentage}% discount',
    txt_global_CoreTemplate_insuranceoverview: 'Insurance overview',
    txt_global_std_addvehicleidentification: 'Add vehicle identification',
    txt_global_std_includedkm: 'Included kilometers',
    txt_global_std_password: 'Password',
    txt_global_std_apikey: 'API key',
    txt_global_std_points2: 'Points ',
    txt_global_std_saveinsurancekey: 'Save insurance key',
    txt_global_std_namekeytitle: 'Title, shown in app',
    txt_global_std_cannotfindanybluetoothdevice: 'Can not find any Bluetooth device.',
    txt_global_std_p2pborrowacartwo: 'Borrow a car',
    txt_global_std_p2ppushbodynewexpensetoapprove: '{name} added expenses for the month of {monthname}.',
    txt_global_std_userspecific: 'User specific',
    txt_global_std_deletedtranslationfromreview: 'deleted translation from Review',
    txt_global_std_MSIG_InsuranceLetter31: '(Approved Insurers)',
    txt_global_std_bysigningupyouaccept: 'By signing up you accept ',
    txt_global_all_monday: 'Monday',
    txt_global_std_worldclass: 'World Class',
    txt_global_std_typeofchallenge: 'Type of challenge',
    txt_global_std_problempersistcontactsupport: 'If the problem persists, please contact support.',
    txt_global_std_kintoremainingpointstonextlevel: '{points} points remaining to {level}',
    txt_global_std_do: 'Do',
    txt_global_std_feb: 'Feb',
    txt_global_std_apirestrictions: 'API restrictions',
    txt_global_std_standard: 'Standard',
    txt_global_std_MSIG_InsuranceLetter34: 'GST Reg. No. M2-0001676-9',
    txt_global_std_driver: 'Driver',
    txt_global_std_loyalty_upsell_pitch: 'Quickly customize your own on-brand loyalty program to;',
    txt_global_std_warikan_onboardingmail_temppwd: 'Temporary password:',
    txt_global_std_SignupFia_Text11: 'for more information.',
    txt_global_std_savesettings: 'Save settings',
    txt_global_std_compareactivities: 'Compare activities',
    txt_global_std_errorfetchingtrends: 'Error fetching trends',
    txt_global_std_p2pfaq2q: 'How can I edit the date and time for a booking?',
    txt_global_std_1000pointaverageachievementunlocked: 'You drove really well! Your average {scoretype} was better than {score} for a week! You have earned 1000 points!',
    txt_global_std_emailregisteredintheapp: 'Email registered in the app',
    txt_global_std_p2ppushbodyaddadmin: 'Admin rights for {vehicle} has been added by {name}.',
    txt_global_std_keepingyourmindandeyesonwhatshappeningaroundyouisvitalforyoursafetyandthesafetyofothersphoneactivityandotherdistractionsaffectyourfocusandincreasecrashrisk: 'Keeping your mind and eyes on what’s happening around you is vital for your safety, and the safety of others. Phone activity and other distractions affect your focus and increase crash risk.',
    txt_global_std_fuelprice: 'Fuel price',
    txt_global_std_recoveryhelp: 'Recovery Help',
    txt_global_std_areyousureyouwanttoterminateInsurance: 'Are you sure you want to terminate your insurance?',
    txt_global_std_carrorewards: 'Carro rewards',
    txt_global_std_recruitedfriends: 'Recruited friends',
    txt_global_std_changesinreview: 'Changes in review',
    txt_global_std_youhaveinvitedamountfriends: 'You have invited {amount} friends',
    txt_global_std_searchforgroups: 'Search for groups',
    txt_global_std_sidekickusertermsandconditions: 'user terms and conditions',
    txt_global_std_iacceptthe: 'I accept the ',
    txt_global_std_clientlibraries: 'Client libraries',
    txt_global_std_kintofaqlink2: 'Explore The App Manual (Japanese)',
    txt_global_std_apirestrictionsspecifytheenabledapisthatthiskeycancall: 'API restrictions specify the enabled APIs that this key can call',
    txt_global_std_nextstep2: 'Next step',
    txt_global_std_MSIG_InsuranceLetter16: 'Market Value At Time Of Loss',
    txt_global_std_signinwithappname: 'Sign in with {appName}',
    txt_global_std_uniquebadges: 'Unique badges',
    txt_global_std_hellothereletsgetstarted: "Hello there! Let's get started.",
    txt_global_std_loyaltydistribution: 'Loyalty distribution',
    txt_global_std_sendalinktoyourfriendsothattheycandownloadtheapp: 'Send a link to your friend so that they can download the app.',
    txt_global_std_p2pemailsupport: '・For technical app support weekdays during 09:00-18:00',
    txt_global_std_offerendsin: 'Offer ends in',
    txt_global_std_invited2: 'Invited',
    txt_global_std_viewoffer: 'View offer',
    txt_global_std_loadingdata: 'Loading data…',
    txt_global_std_saveandexit: 'Save and exit',
    txt_global_std_SulayemBesttip: 'Think safety first, always be aware of your surroundings and keep your speed within limits. ',
    txt_global_std_p2pborrow: 'Borrow',
    txt_global_std_pleaseverifyifyourmileageiscorrectbeforesavingit: 'Please verify if your mileage is correct before saving it ',
    txt_global_std_clickthelinkbelowtoconfirmyouremailandfinishcreatingyourenerfyglobalaccount: 'Click the link below to confirm your email and finish creating your Enerfy Global account. ',
    txt_global_std_p2pwantstolendyou: ' wants to lend you',
    txt_global_std_choosehowmanydrivesyouwanttochallengeyourfriendon: 'Choose how many drives you want to challenge your friend on.',
    txt_global_std_callsosphonenumber: 'Call SOS, phone number:',
    txt_global_std_regnumber: 'Reg. number',
    txt_global_std_p2pfaq5a: 'Press the image and then zoom using your fingers.',
    txt_global_std_yourannualmilagewasunabletoreadfromtheserverpleasetryagainlater: 'Your annual milage was unable to read from the server, please try again later.',
    txt_global_std_contactforactivation: 'Contact for activation',
    txt_global_std_p2pconfirmwith: 'Confirm with',
    txt_global_std_lastlogfilesent: 'Last logfile sent',
    txt_global_std_removeparticipant: 'Remove participant',
    txt_global_std_fiatodayrule2020: 'You need to drive at least 1 km in order to get a score in the leaderboard.',
    txt_global_std_v2ilevel: 'Level',
    txt_global_std_lostconnectionbig: 'The trip will end',
    txt_global_std_p2pfaq22a: '1. Select "Warikan" from "Menu".\n' +
      '2. Select "Additional Costs".\n' +
      '3. Select Add Cost.\n' +
      '4. Select the car, select the type of expense, enter the fee, upload the image of the receipt and select save.\n' +
      '5. The added costs will be reflected on the "Additional Costs" page.',
    txt_global_std_accidentdetails: 'Accident details',
    txt_global_std_rwmregister: 'Register',
    txt_global_std_insuranceandpayments: 'Insurance and payments',
    txt_global_std_notjoinable: 'Not joinable',
    txt_global_std_yourinsuranceperiodisover: 'Your insurance period is over: ',
    txt_global_std_join: 'Join',
    txt_global_std_chooseallowalways: 'Choose Allow Always',
    txt_global_std_refer10friendstounlockthisachievementandearn300pointstoyour: 'Refer 10 friends to unlock this achievement and earn 300 points to your',
    txt_global_std_areyousurethatyouwanttoremovethisdrivenotreversable: 'Are you sure that you want to remove this trip. This action is not reversable.',
    txt_global_std_searchbynameoremailaddress: 'Search by name or email address',
    txt_global_std_challengearandomdriver: 'Challenge a random driver',
    txt_global_std_howdidyoufeel: 'How did you feel?',
    txt_global_std_theanalysistookalongertime: "This analysis took a longer time than expected, but don't worry we will send you a push notification when it's done",
    txt_global_std_p2psafedriverbonusincluded: 'Safedriver bonus included',
    txt_global_std_assigntogroups: 'Assign to groups',
    txt_global_std_kilometerpremiumtohigh: 'Kilometer premium to high',
    txt_global_std_groupmanagement: 'Group management',
    txt_global_std_website: 'Website',
    txt_global_std_speedvariation: 'Speed variation',
    txt_global_std_profilesettings: 'Profile/Settings',
    txt_global_std_yourlocation1: 'Your location',
    txt_global_std_connecttoyourcarbeforelendingittoafriend: 'Connect to your car before lending it to a friend',
    txt_global_std_areyousureyouwanttounpublishyourtranslationsifyouunpublishtranslationstheappwillnotshowthislanguageanymore: ' Are you sure you want to unpublish your translations? If you unpublish translations the app will not show this language any more.',
    txt_global_std_adgangtilbluetooth: 'Allow Bluetooth',
    txt_global_std_somethingsmissing: "Something's missing...",
    txt_global_std_teamscore: 'TEAM SCORE',
    txt_global_std_ConfirmFia_Text3: 'Date',
    txt_global_std_p2pcostsplitisbasedonthepercentageofuse: 'Cost split is based on the percentage of use.',
    txt_global_std_p2preceiptsummary: 'Receipt summary',
    txt_global_std_25kmlockedkey: 'Drive a distance of 25 km to earn this badge.',
    txt_global_std_PolicySchedule22: 'NCD Protector (If any)',
    txt_global_std_403forbidden: '403 - Forbidden',
    txt_global_std_worldchallenge: 'World challenge',
    txt_global_std_yourinsuranceisnowactivated: 'Your insurance is now activated',
    txt_global_std_p2pfaq12q: 'Can I remove a friend?',
    txt_global_std_reset: 'Reset',
    txt_global_std_remove: 'Remove',
    txt_global_std_deleteuseraccount: 'Delete user account',
    txt_global_std_addaddress: 'Add address',
    txt_global_std_recurring: 'Recurring',
    txt_global_std_sendupdate: 'Send update',
    txt_global_std_novalidapikeyprovided: 'No valid API key provided.',
    txt_global_std_bestdriversintotal: 'Best drivers in total',
    txt_global_std_secondss: 's',
    txt_global_std_selecttheregnumberofyourcar: 'Select the regnumber of your car',
    txt_global_std_NewPW_Text1: 'You have requested a new password for your {appName}-account.',
    txt_global_std_thislinkexpiresinxtimeandcanonlybusedonce: 'This link expires in 6 minutes and can only be used once.',
    txt_global_std_sosinternationalaroundtheclock: 'SOS international, around the clock',
    txt_global_std_drivesafeandecofriendly: 'Drive safe and eco friendly',
    txt_global_std_carstatsthisday: 'Car stats for latest day',
    txt_global_std_youhaventpairedwithacaryet: "You haven't paired with a car yet.",
    txt_global_std_p2powneruserguide: 'Owner user guide',
    txt_global_std_p2ptodo: 'To do',
    txt_global_std_amount_default: 'Amount',
    txt_global_std_thisisatestblabla: 'This is a test blabla',
    txt_global_std_challengeranking: 'Your challenge ranking ',
    txt_global_std_insuranceoverview2: 'Insurance Overview',
    txt_global_std_resetpassword: 'Reset password',
    txt_global_std_achievementtool_upsell: 'Achievements Tool – make it easy to form a habit of smart driving.  ',
    txt_global_std_verybad: 'Very bad',
    txt_global_std_thebetteryoudrivethemoreyousave: 'The better you drive, the more you save.',
    txt_global_std_whymustiallowalwaysallowlocationsservicesanswer: 'Because the AI needs to know the road to be able to calculate the score and to help you in case of emergency.',
    txt_global_std_members2: 'Members',
    txt_global_std_getstartedtips: 'Get started tips',
    txt_global_std_ifyourfriendregisterswiththereferralcodebothyouandyourfriendwillreceivenok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsurance: 'If your friend registers with the referral code, both you and your friend will receive NOK 250 in the piggy bank (this requires that you are both customers of Tryg for at least one month after the start date of the insurance).',
    txt_global_std_mytrips: 'My trips',
    txt_global_std_tripsapi: 'Trips API',
    txt_global_std_youwillbebilledatpremiumlevel10: 'You will be billed at premium level 10, please refer to your insurance letter for price details.',
    txt_global_std_p2pwantstolendborrow: 'wants to {lendborrow}',
    txt_global_std_kintofaq9q: 'Why must I allow “always allow" locations services?',
    txt_global_std_doyouwanttodeletetheapikey: 'Do you want to delete the API key?',
    txt_global_std_yourealmostthere: "You're almost there!",
    txt_global_std_trygdiscountpopuptext: 'Here you can see how much you would save, if you had a Sidekick car insurance. Potential savings are calculated by your annual price, annual mileage and car model. If you have not entered this information, the savings are calculated based on an annual price of NOK 5,000 and an annual mileage of 20,000 km.',
    txt_global_std_thesebadgesyoucanearnseveraltimes: 'These badges you can earn several times.',
    txt_global_std_doyouwanttodriveforteamnamesteam: "Do you want to drive for {teamName}'s team?",
    txt_global_std_p2ptypeamessagedot: 'Type a message...',
    txt_global_std_WelcomeLetter_Text6: 'CEO',
    txt_global_std_welcometoyournewaccountwehavesentyouanemailtoverifyyournewaccountremembertocheckyourspamfolderdidntgetanemail: 'Welcome to your new account! We have sent you an email to verify your new account. Remember to check your spam folder. Didn’t get an email?',
    txt_global_std_searchchallenges: 'Search challenges',
    txt_global_std_yourcarisntturnedon: "• Your car isn't turned on.",
    txt_global_std_100pointtripachievementunlocked: 'You drove at {scoretype} {score} or better! Great! You have earned 100 points!',
    txt_global_std_upcomingtraining: 'Upcoming training',
    txt_global_std_estimatedprice: 'Estimated Price',
    txt_global_std_yourmileagewillbesavedintheobddevice: "Your mileage will be saved in the OBD device's built in memory storage and will be uploaded at premium level 10 when you complete your next connected drive.",
    txt_global_std_exportdrives: 'Export drives',
    txt_global_std_usersoverview: 'Users Overview',
    txt_global_std_PolicySchedule3: 'Cover Note/Ref. No.',
    txt_global_std_youhaveexceededthedrivingmaxtimeof: 'You have exceeded the driving max time of {breakTime}.',
    txt_global_std_KleinschmidtChamp: 'Dakar Rally Champion 2001',
    txt_global_std_mustbeavalidsizenumbersand: 'Must be a valid size, numbers and +',
    txt_global_std_reasonterminatedinsurance: 'Reason terminated insurance',
    txt_global_std_automatic: 'Automatic',
    txt_global_std_tryoutappname: 'Try out {appName}',
    txt_global_std_allowingaccesstobluetoothisnecessaryforthisapptoconnecttoyourcarandmeasureyourdriving: 'Allowing access to bluetooth is necessary for this app to connect to your car and measure your driving.',
    txt_global_std_accidentandclaims: 'Accidents & claims',
    txt_global_std_buyinsurance: 'Buy insurance',
    txt_global_std_ifyourcardoesnotshowupinthelistyoumightneedtogotobluetoothsettingsforbothyourphoneandyourcar: 'If your car does not show up in the list, you might need to go to Bluetooth settings for both your phone and your car.',
    txt_global_std_verifycode: 'Verify code',
    txt_global_std_p2pcancelledph: '{count} Cancelled',
    txt_global_std_destination: 'Destination',
    txt_global_std_thesepermissionsarenecessaryfortheapptobeabletoregisteryourdrivescorrectlyletsgo: "These permissions are necessary for the app to be able to register your drives correctly. We value your privacy and will not share this information with a third party entity. Let's go!",
    txt_global_std_willbesenttotheemailregisteredintheonboarding: ' Will be sent to the email registered in the onboarding',
    txt_global_std_findyourobdportinyourcar: 'Find your OBD port in your car',
    txt_global_std_yournewappishere: 'Your new app is here!',
    txt_global_std_workspacepermissions: 'Workspace permissions',
    txt_global_std_rpmspeednotfluctating: 'RPM/speed not fluctating',
    'txt_global_std_Al-KhelaifiInterests': 'Cars, Photography, Motorsports',
    txt_global_std_welcometofiatciaodrive1: 'Welcome to FIAT CIAO DRIVE',
    txt_global_std_addtogroups: 'Add to groups',
    txt_global_std_enteryourpolicynumber: 'Enter your policy number',
    txt_global_std_youneedtofinishsettingupyourprofilebeforejoining: 'You need to finish setting up your profile before joining an event.',
    txt_global_std_allteamleaders: 'all team leaders',
    txt_global_std_createanaccountforname: 'Create an account for {appName}',
    txt_global_std_apitool_upsell_pitch_bulletpoint1: 'Comprehensive digital onboarding for seamless integration ',
    txt_global_std_jpfaqtitle: 'Please tell me how to connect the app to the car and record driving data.',
    txt_global_std_trygtakemetoapp: 'Skip and get started with Tryg Drive',
    txt_global_std_cimage2h: 'KEEP DISTANCE',
    txt_global_std_canisignupforinsurancetobegintoday: 'Can I sign up for insurance to begin today?',
    txt_global_std_p2psubmit: 'Submit',
    txt_global_std_timesinceparked: 'Time since parked',
    txt_global_std_testarenandra: 'Kiểm tra một giây',
    txt_global_std_restrictkey: 'Restrict key',
    txt_global_std_yourinsurancehasbeenrenewedformonth: 'Your insurance has been renewed for {month}.',
    txt_global_std_p2pyouhavechosen: 'You have chosen:',
    txt_global_std_5streak: '5 streak!',
    txt_global_std_p2pfaq13q: 'What if I return the car early?',
    txt_global_std_p2pconfirmedbookings: 'Confirmed bookings',
    txt_global_std_thepasswordminimumislength8charactersandmustinclude1lowercaseuppercasenumeralandspecialcharacter: 'The password minimum is length 8 characters, and must include 1 lower-case, upper-case, numeral and special character',
    txt_global_std_resetyourenerfyglobalpassword: 'Reset your Enerfy Global password',
    txt_global_std_welcomebackenerfyfirstname: 'Welcome back to {appName}, {firstName}!',
    txt_global_std_westzone: 'West Zone',
    txt_global_std_monitoringyourdrivinghasneverbeeneasier: 'Monitoring your driving has never been easier.',
    txt_global_std_apis: 'APIs',
    txt_global_std_f_lanzchamp: '2020 KZ2 SA Champion, 2019 KZ2 SA Vice Champion, 2018 Rok Final Shifter Ladies Champion and P16 Overall\n',
    txt_global_std_companyaverage: 'Company average',
    txt_global_std_p2pcostsplits: 'Cost splits',
    txt_global_std_placethewarningtriangle: 'Place the warning triangle in a visible location at least 100 meters in front of the car',
    txt_global_std_FiaPassword_Text2: 'You have requested a new password for your FIA Smart Driving Challenge-account. Your new password to the user',
    txt_global_std_drivesettingsnotfinished: 'Drive settings not finished',
    txt_global_std_allowaccesstomotionfitness: 'Allow access to Motion ＆ Fitness',
    txt_global_std_myprojects: 'My projects',
    txt_global_std_60days: '60 days',
    txt_global_std_howdoesitwork: 'How does it work?',
    txt_global_std_selectatemplate: 'Select a template',
    txt_global_std_birthday: 'Birthday',
    txt_global_std_sdcrapply2019price3: '300 kr gasoline checks',
    txt_global_std_thisactivationcodeisinvalid: 'This activation code is invalid',
    txt_global_std_insurancehasbeenactivatedforregno: 'The insurance has been activated for vehicle {regNo}.',
    txt_global_std_nextlevel: 'next level',
    txt_global_std_claimshandling: 'Claims handling',
    txt_global_std_insuranceperiod: 'Insurance period',
    txt_global_std_activationcodeemail3: 'You can activate it in the app!',
    txt_global_std_cantfinduserinhead2head: "Can't find your user? You can invite a friend in ReferFriend.",
    txt_global_std_actualtimegain: 'Actual time gain',
    txt_global_std_anodometerorodographisaninstrumentusedformeasuringthedistancetravelledbyavehicleitcanbefoundinthecarsdashboard: "An odometer or odograph is an instrument used for measuring the distance travelled by a vehicle. It can be found in the car's dashboard.",
    txt_global_std_p2pfriendscalendars: 'Friends with cars calendars',
    txt_global_std_p2pfaq16a: 'You can user the Kinto Insurance. However, please notify the person in charge and discuss between each other how to resolve.',
    txt_global_std_stress: 'Stress',
    txt_global_std_weareunabletorecordyourtrip: 'We are unable to record your trip!',
    txt_global_std_validbetween: 'Valid between',
    txt_global_std_city: 'City',
    txt_global_std_daydreamingisacommontypeofdriverdistractionyoumaythinkitsharmlessbutanythingthattakesyourmindoffthedrivingtaskincreasescrashriskchangingyourgazefrequentlycanhelpyoumaintainfocusontheenvironmentaroundyounew: 'Day dreaming is a common type of driver distraction. You may think it’s harmless, but anything that takes your mind off the driving task increases crash risk. Changing your gaze frequently can help you maintain focus on the environment around you.',
    txt_global_std_whatdoyouwanttodo: 'What do you want to do?',
    txt_global_std_p2pwhenitstimetodrive: 'When it’s time to drive',
    txt_global_std_yourdrivingstatsforthistripcomparedtotheaveragedriver: 'Your driving stats for this trip compared to the average driver.',
    txt_global_std_myrewards: 'My Rewards',
    txt_global_std_whatisanodometer: 'What is an Odometer?',
    txt_global_std_Fia_Receipt_Text15: 'Your activation code:',
    txt_global_std_locationservice: 'Location service',
    txt_global_std_resetpasswordforname: 'Reset password for {name}?',
    txt_global_std_sdcrapply2019price1: '1000 kr gasoline checks',
    txt_global_std_driversnow: 'drivers now',
    txt_global_std_pointsheader: 'Points',
    txt_global_std_p2pyouhavexfriendsintheapp: 'You have {number} friends in the app',
    txt_global_std_step4: 'Step 4',
    txt_global_std_warikan_onboarding4: 'Instructions for use:',
    txt_global_std_p2plogintomykintoaccount: 'Log in to my Kinto account',
    txt_global_std_nobluetoothbrnoworries: 'No Bluetooth? <br/>No worries!',
    txt_global_std_insuranceactivationkeydoesnotexist: 'Insurance activation key does not exist',
    txt_global_std_wearebuildingyourdemoapp: 'We are building your demo-app',
    txt_global_std_reportuser: 'Report user',
    txt_global_std_lendings: 'Lendings',
    txt_global_std_entervalidphone: 'Enter phone number with at least 6 digits',
    txt_global_std_idriveforaridesharingcompanywillyouinsuremyvehicle: 'I drive for a Ridesharing Company, will you insure my vehicle?',
    txt_global_std_youpurchasedcdw5timestoyourbookingyouhaveearned100points: 'You have earned 100 points for making 5 reservations and opted-in for CDW. This achievement can be earned more than once.',
    txt_global_std_WelcomeLetter_Hello: 'Hello',
    txt_global_std_enteroptionalactivationcode: 'Enter optional activation code',
    txt_global_std_yourapikey: 'Your API key',
    txt_global_std_settingshasbeensaved: 'Settings has been saved',
    txt_global_std_loginwithoutaccount: 'Log in without account',
    txt_global_std_participantsfor: 'Participants for',
    txt_global_std_yourfinalscorewillbecalculatednexttime: 'Your final score will be calculated the next time you are connected',
    txt_global_std_whenyouhavecompletedyourfirstdriveyouwillseeithere: 'When you have completed your first drive you will see it here.',
    txt_global_std_turnonyourphonesbluetooth: "Turn on your phone's Bluetooth.",
    txt_global_std_removeuser: 'Remove User',
    txt_global_std_youneedtoactivateprivacysettings: 'You need to activate privacy settings',
    txt_global_std_covercubecollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: 'Covercube collects location data to enable driving score even when the app is closed or not in use.',
    txt_global_std_MikkelsenChamp: 'IRC champion 2011 & 2012. 3rd place in World Rally Championship (2014, 2015 & 2016). 3 wins in World Rally Championship. Was a part of the FIA young driver excellence academy\n',
    txt_global_std_drivewascancelledbeforeitwasvalidandwillbediscarded: 'Drive was cancelled before it was valid and will be discarded',
    txt_global_std_rwmconnecttocar: 'Connect to car',
    txt_global_std_insnumber: 'Insurance number',
    txt_global_std_p2ptotalexpenses: 'Total expenses',
    txt_global_std_theusermustacceptaninvitationtothegroupfirst: 'The user must accept an invitation to the group first.',
    txt_global_std_p2pfaq24a: 'There are four methods, and you can choose freely.\n' +
      '(Total cost: KINTO monthly fee plus additional cost for each member)\n' +
      '\n' +
      `1. "Usage ratio" Calculate the ratio of each member's usage time to the total monthly time (July example: 24 hours x 31 days = 744 hours) and multiply by the total cost.\n` +
      '2. "Usage time" Divide the total cost by the total usage time to calculate the unit price of the usage time. Multiply each usage time.\n' +
      '3. "Equal division" The total cost is evenly divided by the number of people in the group.\n' +
      '4. "Mileage" Calculate the unit price of mileage by dividing the total cost by the total mileage of the month. Multiply your mileage.',
    txt_global_std_active: 'Active',
    txt_global_std_cimage43h: 'AVOID START AND IMMIDIEATE STOP WHEN IN QUEUES ',
    txt_global_std_policynumberwassaved: 'Policy number was saved',
    txt_global_std_p2pminutes: 'minutes',
    txt_global_std_createddate: 'Created date',
    txt_global_std_activecompetitions: 'Active competitions',
    txt_global_std_p2pfaq17q: 'How do I add friends?',
    txt_global_std_chooseoneoption: 'Choose one option',
    txt_global_std_howdoiconnecttomycarsbluetooth: 'How do I connect to my car’s Bluetooth?',
    txt_global_std_viewandredeem: 'View and redeem',
    txt_global_std_insureddistance_used_90__2: 'Snart tomt! Öka årlig körsträcka här.',
    txt_global_std_insuranceterms2: 'Insurance terms',
    txt_global_std_translationtool_upsell_pitch_bulletpoint3: 'Position your products in front of a larger audience ',
    txt_global_std_ChicheritMusic: 'Coming soon...',
    txt_global_std_whenyouarereadytodrive: 'When you are ready to drive',
    txt_global_std_keepingyourmindandeyesontheroadandtrafficaroundyouisvitalforyoursafetyandthesafetyofotherslookingwellaheadandscanningtheenvironmentwillhelpyoutoanticipatewhatotherroadusersmightdonew: 'Keeping your mind and eyes on the road and traffic around you is vital for your safety, and the safety of others. Looking well ahead and scanning the environment will help you to anticipate what other road users might do.',
    txt_global_std_couldhavesaved: 'Could have saved',
    txt_global_std_level123: 'Level',
    txt_global_std_theapikey: 'The API key',
    txt_global_std_yougetthiswhenyouhaveachieved5tripswithascorebetterthan3point3: 'You get this when you have achieved 5 trips with a score better than 3.3',
    txt_global_std_andspaces: 'and spaces.',
    txt_global_std_pressconfirmtoadjustyourmilagebonusplan: 'Press "confirm" to adjust your milage bonus plan.',
    txt_global_std_OSolbergMileage: 'Coming soon...',
    txt_global_std_settled: 'Settled',
    txt_global_std_group: 'Group',
    txt_global_std_avgspeed7d: 'Avg speed 7D',
    txt_global_EnerfyLeaderboardTemplate_currleaguetext: 'You are in league',
    txt_global_std_anotherverificationemailhasbeensentpleasecheckyourinbox: 'Another verification email has been sent, please check your inbox',
    txt_global_std_PolicySchedule4: 'Policy No',
    txt_global_std_keepingyourmindandeyesontheroadandtrafficaroundyouisvitalforyoursafetyandthesafetyofotherslookingwellaheadandscanningtheenvironmentwillhelpyoutoanticipatewhatotherroadusersmightdo: 'Keeping your mind and eyes on the road and traffic around you is vital for your safety, and the safety of others. Looking well ahead and scanning the environment will help you to anticipate what other road users might do.',
    txt_global_std_clickhere2: 'Click here',
    txt_global_std_feedbackinterval: 'Feedback interval',
    txt_global_std_MSIG_InsuranceLetter44: 'TIERED EXCESS OF UP TO $2,000.00 WILL APPLY IN THE EVENT OF AN ACCIDENT SUBJECTED TO THE AVERAGE SCORE',
    txt_global_std_p2pthetemporarypasswordisonlyvalidfor72hourspleaserequestanewpassword: 'The temporary password is only\n' +
      'valid for 72 hours. Please request a new password.',
    txt_global_std_joiningheatspleasewait: 'Joining heats, please wait...',
    txt_global_std_yourmessagewillbesentat: 'Your message will be sent at:',
    txt_global_std_oncepairedyoullautomaticallymovetothenextstep: "Once paired, you'll automatically move to the next step.",
    txt_global_std_youdroveextremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'You drove extremely well! Your average {scoretype} was better than {score} for a week! You have earned {points} points!',
    txt_global_std_pioneeronepageuserguide: 'User guide',
    txt_global_std_checkifyouareconnectedcorrectobdreader: 'Check if you are connected to the correct OBD-reader.',
    txt_global_std_joinaheat: 'Join a heat',
    txt_global_std_selectnewmileageselector: 'Select new mileage',
    txt_global_std_setupcompanychallenge: 'Set up company Workspace',
    txt_global_std_p2pclose: 'Close',
    txt_global_std_locked: '(Locked)',
    txt_global_std_getyourdrivingscore: 'Get your driving score!',
    txt_global_std_competeasindividualsandinteams: 'Compete as individuals and in teams',
    txt_global_std_AbdullaDescription: 'Adel has won the FIA CCR T2 World Championship with Hakam Rabie as a Co-driver after a protest which led to a FIA Court Appeal. In his first full season of cross-country competition, Adel Hussein claimed wins in Portugal, Abu Dhabi and Spain during a successful campaign in the world’s toughest off-road championship.',
    txt_global_std_p2pcontactsupport: 'Contact support',
    txt_global_std_eventcreator: 'Event Creator',
    txt_global_std_leaderboards: 'Leaderboards',
    txt_global_std_consentInfo: "Yes = Join us from the start, join our community and become part of Tryg Drive. Once you have given your consent, you can enter our competitions and gain full access to the app's features.\n" +
      'Tryg may contact me by e-mail, in the Tryg Drive app, and by SMS in order to receive news and special offers from Tryg A / S about insurance, non-life insurance products, competitions and other Trygs services. You can always ask for marketing inquiries easily and free of charge.\n' +
      'No = You have not activated your "consent" under settings. When "consent" is not enabled, we will not be able to share all of the exciting competitions and tips provided in the Tryg Drive app on an ongoing basis.',
    txt_global_std_howmanycarswereinvolved: 'How many cars were involved?',
    txt_global_std_communicationtool: 'Communication Tool',
    txt_global_std_nothingtoshowhere: 'Nothing to show here',
    txt_global_std_MSIG_InsuranceLetter5: 'Road Transport (Amendment) Act 2019 (Malaysia)',
    txt_global_std_kintorefer50friendsunlocked: 'Thank you! You have referred 50 Kinto users. ¥5000 has been sent to your Kinto wallet.',
    txt_global_std_whenusingthiscodeorlinkuserwillbeonboardedtoyourenerfyglobalteam: 'When using this code or link user will be onboarded to your Enerfy Global team.',
    txt_global_std_avgtrips7d: 'Avg trips 7D',
    txt_global_std_whereismycar: 'Where is my car?',
    txt_global_std_donthaveanaccount: "Don't have an account?",
    txt_global_std_invitecolleaguesorevenfriendsandfamilytoyourchallengetomakeyourcompanychallengemoreinteresting: 'Invite and inspire every driver in your network to participate and get new data on your ESG performance.',
    txt_global_std_customfilters: 'CUSTOM FILTERS',
    txt_global_std_tipcity3DESC: 'The blue car driver naturally slows down and drives through the crossing smoothly. \n' +
      'The red car driver accelerates and brakes to hard before the crossing.',
    txt_global_std_pleasewaitwhileweauthenticateyouremail: 'Please wait while we authenticate your email',
    txt_global_std_doyouwanttojoinname: 'Do you want to join {name}?',
    txt_global_std_whendrivingonmotorwaystoomanydriverspumptheacceleratorpedalupanddowninaneverendingpattern100kmhto110kmhthen110kmhto100kmhandsoonthistypeofdrivingusesunnecessaryfuelkeepingasafesuitablefollowingdistancecanhelpavoidthetemptationtovaryspeedunnecessarilynew: 'When driving on motorways too many drivers pump the accelerator pedal up and down in a never-ending pattern: 100 km/h to 110 km/h, then 110 km/h to 100 km/h and so on! This type of driving uses unnecessary fuel. Keeping a safe, suitable following distance can help avoid the temptation to vary speed unnecessarily.',
    txt_global_std_reportaccident: 'Report accident',
    txt_global_std_closingthispopupyouconfirmyouhaveredeemedyourpoints: 'Closing this popup you confirm you have redeemed your points.',
    txt_global_std_aryoudrivinganelectricorobd: '電気自動車を運転していますか、または、車載デバイスを取り付けていますか？',
    txt_global_std_previewheader: 'Preview',
    txt_global_std_activationletter_text15sidekick: 'With the Sidekick app, you can learn more about your own driving style and how you can become a safer driver. In addition, you can save money on insurance by getting a good driving score!',
    txt_global_std_opened: 'Opened',
    txt_global_std_subheats: 'Subheats',
    txt_global_std_thesevehiclesarealreadyactivated: 'These vehicles are already activated:',
    txt_global_std_avgmaxspeed7d: 'Avg max speed 7D',
    txt_global_std_teamco2: 'Team CO2 savings',
    txt_global_std_skillcomparison: 'Skill comparison',
    txt_global_std_allowlocationaccess: 'Allow Location Access',
    txt_global_std_officialtimingpartner: 'OFFICIAL TIMING PARTNER',
    txt_global_std_createnew: 'Create new',
    txt_global_std_saved: 'Saved',
    txt_global_std_doitlater: "I'll do it later",
    txt_global_std_yourinsurance: 'Your insurance',
    txt_global_std_yougotthecodefrommail: 'You got the code from mail when you signed the incurance.',
    txt_global_std_gallonus: 'Gallon US',
    txt_global_std_vehicle2: 'Vehicle',
    txt_global_std_pleaseenteryouremailaddress: 'Please enter your email address.',
    txt_global_std_SignupFia_Text2: 'You’re on the list to receive first hand news and updates on the FIA Smart Driving Challenge, the first challenge rewarding smart skills at the wheel.',
    txt_global_std_webclaims: 'Web Claims',
    txt_global_std_xxperofourcustomers: '{perc}% of our customers use automatic because of the ease of use.',
    txt_global_std_groups: 'Groups',
    txt_global_std_hereisapreviewofhowyourappwilllookifyouarehappywiththeresultpleasecontinuetopublishyourdemoapp: 'Here is a preview of how your app will look. If you are happy with the result, please continue to publish your demo-app.',
    txt_global_std_amounttopay: 'Amount to pay',
    txt_global_std_cimage37d: 'Investigate rolling distance in different speeds.',
    txt_global_std_forthebestexperience: 'For the best experience',
    txt_global_std_BeforeFirstBill_Text1: 'We will soon draw money from your card for your car insurance. We have compiled the most common questions that our customers have when receiving their first invoice. ',
    txt_global_std_clickontheareaswhereyourvehiclehasbeendamages: 'Click on the area(s) where your vehicle has been damaged',
    txt_global_std_riskindex_upsell_pitch_bulletpoint2: 'The impact of political, health and economical changes on driving risk',
    txt_global_std_bodyrepair1panel: 'Body repair 1 panel',
    txt_global_std_tripdata: 'Trip data',
    txt_global_std_premiumwithzipdrivingriskindex: 'Premium with Zip-Driving risk index',
    txt_global_std_3selectyourcarinthelistbelow: '3. Select your car in the list below.',
    txt_global_std_aresetlinkhasbeensenttouser: 'A reset link has been sent to ',
    txt_global_std_idrivetomuch2: 'I drive to much',
    txt_global_std_doyouwanttochallengename: 'Do you want to challenge {name}?',
    txt_global_std_p2pbookingsummary: 'Booking summary',
    txt_global_std_weekw: 'w',
    txt_global_std_ifyouhaveaninsuranceyoucanfindyourpolicynumberinyourinsurancedocuments: 'If you have an insurance you can find your policy number in your insurance documents.\n' +
      'Don’t have it at hand right now? No worries! You can add it later in settings.',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'You drove really well! Your average {scoretype} was better than {score} for a week! You have earned {points} points!',
    txt_global_std_clickdownbelowtoseehowitwent: 'Click down below to see how it went',
    txt_global_std_set_up_automatic_start_stop: 'Set up: Automatic start & stop',
    txt_global_std_youhavebeeninvitedtojoinagroup: 'You have been invited to join a group',
    txt_global_std_HusseinAbdullaMileage: 'Nothing specific',
    txt_global_std_speedcostanalysis: 'SPEED COST ANALYSIS',
    txt_global_std_co2reduced: 'CO₂ reduced',
    txt_global_std_cimage39h: 'LET OFF YOUR GAS EARLIER - DARE TO ROLL MORE',
    txt_global_std_youdroveextremelywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'You drove extremely well! Your average was better than {score} for a week! You have earned {points} points!',
    txt_global_std_p2pstart: 'Start: ',
    txt_global_std_searchforusers: 'Search for users',
    txt_global_std_distancedriven: 'Distance driven',
    txt_global_std_drivertrainingrecommended: 'Driver training recommended',
    txt_global_std_iunderstandthatthiswillcompletewipeallofmyaccount: 'I understand that this will completely wipe all of my account data and driving records.',
    txt_global_std_ownerdidnotgetthatnotification: 'Owner did not get that notification',
    txt_global_std_p2pwaitingtoconfirmthespecifiedprice: 'Waiting to confirm the specified price.',
    txt_global_std_cannotbeempty: 'Can not be empty',
    txt_global_std_acceptconnectioninapp: 'Accept connection in app',
    txt_global_std_thisuserwillbeaddedtoyourplanatanadditionalcostofuserfeeperuser: 'The user will be added to your agreement according to your price plan.',
    txt_global_std_theusermustacceptaninvitationtotheteam: 'The user must accept an invitation to the team.',
    txt_global_std_acheivementcannotbedeleted: ' Please note that this acheivement cannot be deleted.',
    txt_global_std_refer10friendsachievementunlocked: 'You have referred 10 friends! Nice! You have earned 300 points!',
    txt_global_std_female: 'Female',
    txt_global_std_member1: 'member',
    txt_global_std_viewloyaltypoints1: 'View loyalty points',
    txt_global_std_manageusersettingsandsupporthereusethequickaccessbuttonsbelowandnavigatebetweenusersbysearchingforthemintheusersview: 'Manage user settings and support here. Use the quick access buttons below and  navigate between users by searching for them in the users view.',
    txt_global_std_afteryourfirstnumberofmonthsmonthperiodourappwilltrackyourscoresandprovidearatebasedonyourdrivinghabitsifyouhaveafewbadscoresyouhaveanentirenumberofmonths2monthstoimproveyouwillreceiveupdatesalongthewaywitheachtripadvisingyouofyourpotentialsavingsorifyourneedtoprovidemorefocuswithyourdrivinghabits: 'After your first {numberOfMonths}-month period our app will track your scores and provide a rate based on your driving habits. If you have a few bad scores you have an entire {numberOfMonths2} months to improve. You will receive updates along the way with each trip advising you of your potential savings or if your need to provide more focus with your driving habits.',
    txt_global_std_sunday: 'Sunday',
    txt_global_std_youwillbereadysoon: 'You will be ready soon!',
    txt_global_std_stepcurrentstepoftotalsteps: 'Step {currentStep} of {totalSteps}',
    txt_global_std_advancedadmin: 'Advanced Admin',
    txt_global_std_teamnames: 'Team names',
    txt_global_std_recoverpassword: 'Recover password',
    txt_global_std_dontyouhaveanaccountwithenerfy: "Don't you have an account with {appName}?",
    txt_global_std_cimage1d: 'Lower speed',
    txt_global_std_seehowmuchyoucouldsave: 'See how much you could save',
    txt_global_std_showmehow: 'Show me how',
    txt_global_std_yourownbrandedworkspaceopenforalldriverthatwishtoparticipate: 'Your own branded workspace open for all driver that wish to participate',
    txt_global_std_friendstothe: 'friends to the',
    txt_global_std_yougetthiswhenyoufirstachieveascoreabove4point4: 'You get this when you first achieve a score above 4.4',
    txt_global_EnerfySettingsTemplate_other: 'Other',
    txt_global_std_ConfirmFia_Text1: 'Congratulations',
    txt_global_std_leaderboardpositionincrease: 'Your leaderboard position has increased!',
    txt_global_std_KristofferssonDescription: 'Johan started his career with International Super Series with Sweden team where he impressed and moved on to Touring Car Championship (STCC) where he won in a single season in 2002. He moved then to the European RX in 2013-2014 season and in 2015 to the FIA WRX where he won 5 rallies and came out 3-rd in overall ranking. Ended runner up in 2016 behind his countryman Mattias Ekström and won it in 2017.',
    txt_global_std_settings: 'Settings',
    txt_global_std_alertdriveservicetitle: 'Drive Service',
    txt_global_std_warikan_onboarding5_2: 'KINTO Corporation',
    txt_global_std_p2pbookingmessages: 'Booking messages',
    txt_global_std_carsharingemptyviewtitle: 'No Activities',
    txt_global_std_moreinformation: 'More information',
    txt_global_std_hellothere: 'Hello there!',
    txt_global_std_ifyourdriverhasproblemswithreceivingtheirpasswordyoucanviewitherenotethatthecurrentpasswordresetswhenyouclickthebutton: 'If your driver has problems with receiving their password, you can view it here. Note that the current password resets when you click the button.',
    txt_global_std_profilepreferences: 'Profile preferences',
    txt_global_std_continue: 'Continue',
    txt_global_std_tiphighway2DESC: 'The blue car driver is driving well planned and keeps a good pace and distance to surrounding cars.\n' +
      'The red car driver is overtaking without reason and is tailgating the car in front.',
    txt_global_std_p2pcarsharingismorefunwhenyouinviteyourfriendsandfamily: 'Car sharing is more fun when you invite your friends and family to join!',
    txt_global_std_failedtogetreviewlist: 'Failed to get Review List',
    txt_global_std_noofparticipants: 'No of participants',
    txt_global_std_downloadapp: 'Download App',
    txt_global_all_weekday: 'Week day',
    txt_global_std_choosethecarontheappscreenandwaituntilyougetnotificatedthatthedevicesarepaired: 'Choose the car on the app screen and wait until you get notificated that the devices are paired',
    txt_global_std_statusinitdriving: 'Connected',
    txt_global_std_p2puploadpicturesofyourcartodocumentanyscratchesordents: 'Upload pictures of your car to document any scratches or dents.',
    txt_global_std_p2pfinishbooking: 'Finish booking',
    txt_global_std_acceptedpermissions: 'Accepted permissions',
    txt_global_std_publishachievementpopuptext: 'When publishing an achievement, its locked state will become visible to users in the app. Once a user been awarded a badge, it will show in its unlocked state.',
    txt_global_std_highspeedconditions: 'High speed conditions',
    txt_global_std_eastzone: 'East Zone',
    txt_global_std_denneerndvendigforatappenkanvidehvornrduholderstilleeribevgelseaccelerererellerbremser: 'Enable motion & fitness to get support for starting and ending your trips automatically.',
    txt_global_std_warikan_onboarding12: 'Tel：0120-075-912',
    txt_global_std_HansenChamp: 'EURO RX Champion 2016, RX Lite Cup 2015, World Champion in ROK Junior 2010',
    txt_global_std_communication_upsell_pitch_bulletpoint3: 'Communicate contextual messages at the right time',
    txt_global_std_VilliersWhyjoin: 'Because we all need to do what we can to make our roads safer, and also, this is gonna be really fun.',
    txt_global_std_p2psaveandclose: 'Save and close',
    txt_global_std_selectanotherdevice: 'Select another device',
    txt_global_std_adduser: 'Add user',
    txt_global_std_pioneersmartdrivingchallenge: 'Pioneer Driving Challenge',
    txt_global_std_exportpdf: 'Export PDF',
    txt_global_std_creatingaccountwiththirdpartyname: 'Creating account with {thirdPartyName}...',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint1: 'Understand the factors that determine risk level',
    txt_global_std_locationwithbest: 'Location with best improvement potential',
    txt_global_std_carbonclean: 'Carbon clean',
    txt_global_std_march: 'March',
    txt_global_std_3rddate: '3rd',
    txt_global_std_p2ppushbodynewfriend: 'You are now friend with {name}.',
    txt_global_std_people: 'People',
    txt_global_std_MSIG_InsuranceLetter20: '1) The Insured',
    txt_global_std_cimage15d: 'Be aware that the small things makes the difference.',
    txt_global_std_youractivatedkeys: 'Your activated codes',
    txt_global_std_riskydecisionbetweentrips: 'Risky decision between trips',
    txt_global_std_youhavenowachievedyourbestscoresofar: 'You have now achieved your best score so far (over 4.4)',
    txt_global_std_p2pshareakintocar: 'Share a KINTO car',
    'txt_global_std_Al-KuwariInterests': 'Car racing, Car endurance, Cross Country Rallies, Baja Rallies',
    txt_global_std_foratappenskalfungerekorrektharvibrugforadgangtildintelefonslokationsamtmotionogfitnessdata: 'To record your driving history, turn on Bluetooth, location services, motion and fitness. This personal information will not be shared with third parties.',
    txt_global_std_p2paddexpenses: 'Add expenses',
    txt_global_std_nomapcoordinatesavailableforthisrun: 'No map coordinates available for this run',
    txt_global_std_cimage22h: 'AVOID CRUISE CONTROL',
    txt_global_std_emergencycontact: 'Emergency contact',
    txt_global_std_repetition1: 'Repetition',
    txt_global_std_p2pbookingrequests: 'Booking requests',
    txt_global_std_addtosubscription: 'Add to subscription',
    txt_global_std_kintofaq6a1: 'Make sure you complete all the steps in the “Connect to Car”. Please email ',
    txt_global_std_enteravalidphonenumber: 'Enter a valid phone number.',
    txt_global_std_wednesday: 'Wednesday',
    txt_global_std_describeaccident: 'Describe accident',
    txt_global_std_pointslabel: 'Points',
    txt_global_std_drivingskills: 'Driving skills',
    txt_global_std_takecontrolofyouraccountandprofile: 'Take control of your account and profile.',
    txt_global_std_MSIG_Onboarding1: 'Welcome!',
    txt_global_std_loyaltyoverview: 'Loyalty overview',
    txt_global_std_warikanchallengerulesnotes: '【Notes】\n' +
      '・By joining, your nickname and icon will be displayed to other users. By clicking "Join competition", you agree to be bound by the terms and conditions of this agreement. You can also hide your nickname on your profile settings screen.\n' +
      '・The distance must be longer than 1km per trip.\n' +
      '・The same trip will be scored up to twice a day.\n' +
      '・The total distance must be more than 1km.\n' +
      '・Heat is for the score average, and Total is for the average of the top 10% of your drives.',
    txt_global_std_VilliersBesttip: 'Always be alert and anticipate what other drivers will do. They might turn right in front of you, so you need be alert to be able to stop in time or avoid them.',
    txt_global_std_nopleasebeadvisedthattheenerfyapp: 'No. Please be advised that the Enerfy app needs to be installed on your phone and that you need to be logged in for your drives to get registered. You also need to have Bluetooth activated on your phone in order to connect.',
    txt_global_std_premium: 'Premium',
    txt_global_std_p2punpaid: 'Unpaid',
    txt_global_std_youhavebeendrivingwithtrygdriveforayear: 'You have been driving with TrygDrive for a year!',
    txt_global_std_latest: 'Latest',
    txt_global_std_trygfromabroad: 'Safe from abroad\n',
    txt_global_std_co2unlockedkey: 'Great job! You saved over {co2value}% CO₂ this trip by driving eco friendly.',
    txt_global_std_PolicySchedule19: 'Gross Premium',
    txt_global_std_afewsimplereasonswhyfiasdcmakesadifferenceforyourbusiness: 'A few simple reasons why FIA SDC makes a difference for your business',
    txt_global_std_bestregards: 'Best regards',
    txt_global_std_distanceunit: 'Distance unit',
    txt_global_std_pressthedrivebuttonintheapp: 'Press the drive button in the app',
    txt_global_std_at: 'at',
    txt_global_std_events_overview_season: 'Season',
    txt_global_std_p2p2totalcosttosplit: 'Total cost to split',
    txt_global_std_youpositiononleaderboardwasincreased: 'You position on leaderboard was increased!',
    txt_global_std_responses: 'Responses',
    txt_global_std_avglevel123: 'Avg. level',
    txt_global_std_PolicySchedule34: 'Registration No',
    txt_global_std_companyname: 'Company name',
    txt_global_std_yourealmostdonenowwejustneedtoverifyyouremailaverificationlinkhasbeensentto: 'You’re almost done! Now we just need to verify your email. A verification link has been sent to:',
    txt_global_std_startatrip: 'Start a trip',
    txt_global_std_ayouwillhave60daysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithcovercubewithalapseincoverage: 'A: You will have 60 days from the expiration date to make a payment and continue your policy with Covercube with a lapse in coverage.',
    txt_global_std_done: 'Done',
    txt_global_std_MSIG_InsuranceLetter2: 'ORIGINAL',
    txt_global_std_rwmnotnow: 'Not now',
    txt_global_std_foratopretteforbindelsemellemdinbilogappnskaldergivesadgangtilbluetooth: 'To establish a connection to your car Tryg Drive needs access to bluetooth.',
    txt_global_std_youvsecochampions: 'You vs eco champions',
    txt_global_std_carsharingcompanydescription: 'Short sentence about this. ',
    txt_global_std_circle: 'Circle!',
    txt_global_std_p2pfaq1q: 'Why are some buttons in the app greyed out?',
    txt_global_std_selectlanglabel: 'Select language',
    txt_global_std_gennemsnitsbilist: 'Avg. Driver',
    txt_global_std_loyaltysystemdesc: 'Please note that your perks are based on the final results of the previous loyalty period. Points are earned by getting achievements and are reset at the beginning of a new period.',
    txt_global_std_connectiontype: 'Connection type',
    txt_global_std_manual_start_and_stop_requires_access_to_location_services_and_motion_fitness: 'Manual start and stop requires access to location services (GPS) and motion & fitness.',
    txt_global_std_pluginthedeviceinyourcar: 'Plug in the device in your car.',
    txt_global_std_yourdrivingskills: 'Your driving skills',
    txt_global_std_keepcollectingloyaltypointseventhoughsomeoneelseisdrivingyourcar: 'Keep collecting loyalty points even though someone else is driving your car!',
    txt_global_std_anyspeedgainisanaccelerationnomatterhowsmallsoplanandmakegooduseofeveryspeedincrease: 'Any speed gain is an acceleration, no matter how small. So, plan and make good use of every speed increase.',
    txt_global_std_validatedactivationcode: 'Validated activation code',
    txt_global_std_p2pchoosehowtosplitcost: 'Choose how to split cost',
    txt_global_std_yourlinkshouldbeavailableinyourinboxinminutespleaseremembertocheckyourjunk: 'Your link should be available in your inbox in {number} minutes. Please remember to check your junk.',
    txt_global_std_userwithoutinsurance: '{appName}-user without insurance',
    txt_global_std_successfullyunpublishedlanguage: 'Successfully Unpublished Language',
    txt_global_std_p2pfaq24q: 'In what way can you split the cost',
    txt_global_std_nointernetconnection2: 'No internet connection!',
    txt_global_std_bluetoothlink: 'Bluetooth link',
    txt_global_std_OgierMusic: 'Coming soon...',
    txt_global_std_congratulations2: 'Congratulations',
    txt_global_std_events_overview_today: 'Heat',
    txt_global_std_userhasbeenscheduledforremoval: 'User {{email}} has been scheduled for removal at {{timestamp}}.',
    txt_global_std_fileaclaimdirectlyintheappbyclickingfileclaimlinkorbycallingphonenumber: 'File a claim directly in the app by clicking {fileClaimLink} or by calling {phoneNumber}.',
    txt_global_std_p2ppushtitleexpensesdenied: 'Expenses denied',
    txt_global_std_HSolbergMileage: 'Love to drive on snow and ice, that is the ultimate driving fun.',
    txt_global_std_info: 'Info',
    txt_global_std_newpasswordsaved: 'New password saved',
    txt_global_std_texttofriend: 'Text to friend',
    txt_global_std_connected2: 'Connected',
    txt_global_std_p2piwanttoborrowakinto: 'I want to borrow a Kinto',
    txt_global_std_welcometoenerfyfirstname: 'Welcome to {appName}!',
    txt_global_std_charactercountexceeded: 'Character count exceeded',
    txt_global_std_roadconditionswithbest: 'Road conditions with best improvement potential',
    txt_global_std_opensettingsonyourphoneandmakesuretohavebluetoothactivated: 'Open settings on your phone and make sure to have Bluetooth activated.',
    txt_global_std_compareriskandco2levelsacrossyourorganization: 'Compare risk and CO2 levels across your organization',
    txt_global_std_yourmessagehasbeencreated: 'Your message has been created!',
    txt_global_std_redeemed: 'Redeemed',
    txt_global_std_specialimagefilenameid: 'Custom Image',
    txt_global_std_improveheader: 'Best streak',
    txt_global_std_yesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforagentinsurance: 'Yes, you can download the app and choose the option “try the app”. You will be able to drive with the app and see how much you could save if you signed up for {agent} insurance.',
    txt_global_std_select: 'Select',
    txt_global_std_p2pimakintoowner: "I'm a Kinto owner",
    txt_global_std_searchbynamegrouporaccounttype: 'Search by name, group or account type',
    txt_global_std_tripsinsight: 'Trips insight',
    txt_global_std_removeaccountpluspersonaldata: 'Remove account + personal data',
    txt_global_std_drivertobeannounced: 'Driver to be announced',
    txt_global_std_dennekretur: 'This trip',
    txt_global_std_p2puploadreceipt: 'Upload image of your receipt',
    txt_global_std_dailytrips: 'Daily Trips',
    txt_global_std_thisisanexampletitle: 'This is an example title ',
    txt_global_std_invitationcode: 'Invitation code',
    txt_global_std_p2pnamecancelledbooking: '{name} cancelled booking',
    txt_global_std_connecttocar: 'Connect to car',
    txt_global_std_experiencethebenefits: 'Experience the benefits with {appName}',
    txt_global_std_p2pfaq13a: '1.Go to the booking summary of your booking.\n' +
      '2.Press the button Finish booking.',
    txt_global_std_discountcodetermsconditions: 'Discount Code Terms & Conditions',
    txt_global_std_Fia_Receipt_Text6: 'YOUR RECEIPT:',
    txt_global_std_p2pfaq21q: 'I want to check the members of the group.',
    txt_global_std_addemail: 'Add email',
    txt_global_std_Fia_Receipt_Text8: 'Your order',
    txt_global_std_p2pfaq25a: '1. Select "Driving Data" from the "Menu".\n' +
      '2. You can check the data of your driving history.\n' +
      '3. If you select the "Share data" tab, you can check the driving data of the group together with the member history.',
    txt_global_std_driverslog: "Driver's log",
    txt_global_std_achievementcannotbedeleted: 'Please note that this achievement cannot be deleted.',
    txt_global_std_format2: 'Format',
    txt_global_std_addexpenseserror: 'Please add your expenses type, cost and image.',
    txt_global_std_invitedrivers: 'Invite drivers',
    txt_global_std_endtime: 'End time',
    txt_global_std_2attachtheobddevicetothecableandplugitintothe12vpowercigarettelightersocketinyourcar: '2. Attach the OBD-device to the cable and plug it into the 12v power (cigarette lighter) socket in your car.',
    txt_global_std_alreadyhaveaninsurance: 'Already have an insurance?',
    txt_global_std_weareproudyouwanttobe: "We're proud you want to be a part of {appName}!",
    txt_global_std_icannotconnectanswertext2: 'for help.',
    txt_global_std_invitefriends: 'Invite friends',
    txt_global_std_successfullyaddedtogroups: 'Successfully added to groups',
    txt_global_std_yes: 'Yes',
    txt_global_std_joinenerfyandgetawarded: 'Join Enerfy and get awarded!',
    txt_global_std_entert: 'Enter',
    txt_global_std_acceptfitnessmotion: 'Accept Fitness/Motion',
    txt_global_std_deepdiveintodrivers: "Deep dive into drivers and policy holders: performance, crash probability, eco driving, emissions and more <br /> Once you've activated a report you can download it as many times as you like.",
    txt_global_std_congratulationsyoujustclimbedtheleaderboardviewyourranking: 'Congratulations - you just climbed the leaderboard! View your ranking.',
    txt_global_std_ecoscorescore: 'Eco score {score}',
    txt_global_std_welcome: 'Welcome!',
    txt_global_std_onlylettersaznumbers09andperiodsareallowed: 'Only letters (az), numbers (0-9) and periods (.) Are allowed.',
    txt_global_std_finalistsheat3: 'Finalists - Heat 3',
    txt_global_std_username: 'Username',
    txt_global_std_customnotification: 'Custom notification',
    txt_global_std_friend: 'Friend',
    'txt_global_std_Al-KuwariDescription': 'As a co-driver, Nasser has won 2nd Place in 2016 FIA Cross Country co-driver, 1st in 2017 and 2018 MERC 2 – co-driver, 9 times Qatar Championships as a co-driver and 4 time champion in Kuwait Championships.',
    txt_global_std_checkyouremailformoredetails: 'Check your email for more details.',
    txt_global_std_f_lanzquote: 'Where the Passion lies, the effort flows passionately',
    txt_global_std_loginorcreateaccounthere: 'Log in or create account here',
    txt_global_std_PSolbergMusic: 'Coming soon...',
    txt_global_std_bronzeusers: 'Bronze users',
    txt_global_std_internalservererrorjsonnotvalid: 'Internal server error (JSON not valid)',
    txt_global_std_cimage2d: 'Keep at least 3 seconds to next vehicle.',
    txt_global_std_loyaltynumber: 'Loyalty number',
    txt_global_std_newmobilitycompanydescription: 'Smart on-demand mobility.',
    txt_global_std_anerroroccurredwhiletryingtousetheactivationkey: 'An error occurred while trying to use the activation key',
    txt_global_std_p2pselectafriendtoviewallbookingsoftheircar: 'Select a friend to view all bookings of their car.',
    txt_global_std_mystatsthisperiod: 'My stats this period',
    txt_global_std_second: 'Second',
    txt_global_std_calculationexample: 'Calculation example',
    txt_global_std_invitationlink: 'Invitation link',
    txt_global_std_oilchange: 'Oil change',
    txt_global_std_waxing: 'Waxing',
    txt_global_std_teamco2savings: 'TEAM CO2 SAVINGS',
    txt_global_std_driversselected: 'drivers selected',
    txt_global_std_ayescovercubeacceptsdriverswhoarelicensedinothercountries: 'A: Yes, Covercube accepts drivers who are licensed in other countries.',
    txt_global_std_minutemin: 'min',
    txt_global_std_sparknamekey: 'Spark!',
    txt_global_std_endsin: 'Ends in',
    txt_global_std_p2phasspecifiedprice: ' has specified a price.',
    txt_global_std_consentInfoYes: "Join us from the start, join our community and become part of Tryg Drive. Once you have given your consent, you can enter our competitions and gain full access to the app's features. Tryg may contact me by e-mail, in the Tryg Drive app, and by SMS in order to receive news and special offers from Tryg A / S about insurance, non-life insurance products, competitions and other Trygs services. You can always ask for marketing inquiries easily and free of charge.",
    txt_global_std_achieved: 'Achieved',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatus: 'Achievements help you collect points to earn your loyalty status. Check out below the achievements you can earn by being a {appname} super driver!',
    txt_global_std_drivername: 'Driver name',
    txt_global_std_constantspeeddescription: "Try to lower the top speed. A high top speed doesn't always mean a high average speed.",
    txt_global_std_southzone: 'South Zone',
    txt_global_std_thisisagorillatest: 'This is a gorilla test',
    txt_global_std_withtheenerfyriskportfoliotrackeryouget: 'With the Enerfy Risk Portfolio Tracker you get one view on actual risk and CO2 impact.',
    txt_global_std_p2pnonsequentialdatesselected2: 'Nonsequential dates selected',
    txt_global_std_unlockatbronzelevel: 'Unlock at bronze level',
    txt_global_std_insureddistance_used_90__1: 'Du har nu använt 90% av din årliga valda körsträcka. Kom ihåg att justera körsträckan så att du undviker förkortning vid skador. Du kan justera körsträckan här.',
    txt_global_std_duedate2: 'Due date',
    txt_global_std_p2ppushbodytimetosplitcost: 'Split your cost for {monthname}.',
    txt_global_std_wanttoshareyourprogress: 'Want to share your progress?',
    txt_global_std_p2plendtext: '{name} wants to lend you {vehicle}',
    txt_global_std_OgierInterests: 'Coming soon...',
    txt_global_std_kintoImprovement4: "So close! You could have earned {savings}¥ on this trip. Let's focus!",
    txt_global_std_apitool_upsell_pitch: 'Get up and running in no time with this comprehensive developers’ entry point, for; ',
    txt_global_std_welcometoenerfyglobalworkspace: 'Welcome to Enerfy Global Workspace!',
    txt_global_std_p2pcreateanameforthisgroup: 'Create a name for this group',
    txt_global_std_youdonothaveanyupcomingchallengesatthemoment: 'You do not have any upcoming challenges at the moment.',
    txt_global_std_exploretheappmanual: 'Explore The App Manual',
    txt_global_std_status1: 'Status',
    txt_global_std_ayesyoucanswitchyourinsuranceanytimemostinsurancecompaniesdonothaveanearlyterminationpenaltybutyouwillneedtoinquirewithyourcurrentinsurancecarrier: 'A: Yes, you can switch your insurance any time. Most insurance companies do not have an early termination penalty, but you will need to inquire with your current insurance carrier.',
    txt_global_std_edityourcar: 'Edit your car',
    txt_global_std_signinwith: 'Sign in with {name}',
    txt_global_std_addmultipleusers: 'Add multiple users',
    txt_global_std_onlyshowdriversthatneedaction: 'Only show drivers that need action',
    txt_global_std_p2pborrowedfrom: 'Borrowed from',
    txt_global_std_p2pyoucaninvitexfriends: 'You can invite {number} friends',
    txt_global_std_competeonlyasindividualsorbothindividuallyandinteamsteamscouldbedifferentdivisionsatyourcompany: 'Compete only as individuals or both individually and in teams. Teams could be different divisions at your company.',
    txt_global_std_doyouwanttocancelconnectionandchangeobdreader: 'Do you want to cancel connection and change OBD reader?',
    txt_global_std_yesyoumustadviseyouragentthatyourvehicleisusedforaridesharingcompanyandtheywillapplytheridesharingendorsementtoyourpolicy: 'Yes, you must advise your agent that your vehicle is used for a Ridesharing Company and they will apply the Ridesharing Endorsement to your policy.',
    txt_global_std_pointexchange: 'Point exchange',
    txt_global_std_createatrip: 'Create a trip',
    txt_global_std_removeaccount_subject: 'Remove Account',
    txt_global_std_p2pchoosepaymentmethod: 'Choose payment method',
    txt_global_std_pleasewaitaminuteortwobeforeyourefreshthisviewtogetnewsuggestions: 'Please wait a minute or two before you refresh this view to get new suggestions.',
    txt_global_std_p2psentaneditrequest: 'sent an edit request',
    txt_global_std_accelerationdescription: 'A gentle acceleration is often more safe and you additionally save some fuel.',
    txt_global_std_drivesafeandecofriendlysavemoneyonyourinsurance: 'Drive safe and eco friendly - Save money on your insurance.',
    txt_global_std_TaxInvoice13: 'The warranty is complied with once payment is made to the Insurer or agent or broker before policy inception',
    txt_global_std_readdocumentation: 'Read documentation',
    txt_global_std_nodriversinthislistyet: 'No drivers in this list yet',
    txt_global_std_temporarypassword_: 'Temporary password',
    txt_global_std_thischallenge: 'This challenge',
    txt_global_std_inyourcar: 'in your car.',
    txt_global_std_p2ptobepaid: 'To be paid',
    txt_global_std_division2: 'Division',
    txt_global_std_cropyourprofileimage: 'Crop your profile image',
    txt_global_std_MikkelsenInterests: 'Motocross, Alpine-skiing, fishing and all sports in general.',
    txt_global_std_congratulations: 'Congratulations!',
    txt_global_std_acceptpermissions: 'Accept permissions',
    txt_global_std_p2pshareyourcarwithfamilyandfriends: 'Share your car with family and friends',
    txt_global_std_previousstep: 'Previous step',
    txt_global_std_cimage29h: 'RUN UP TO CRUSING SPEED - PLANNED',
    txt_global_std_PSolbergChamp: 'World RX Champion 2014 & 2015, WRC Champion 13 times, Rally GB Champion 2002 & 2003',
    txt_global_std_p2pselectfriend: 'Select friend',
    txt_global_std_p2pthischatmightnotbeprivatealladminsandownersofthecarcanseethischat: 'This chat might not be private. All admins and owners of the car can see this chat.',
    txt_global_std_p2pfaq2a: '1.Go to booking summary for that booking and press the button edit booking.\n' +
      '2.Set the new date and time and press send update.\n' +
      '3.Wait for your friend to confirm your new proposed time.',
    txt_global_std_p2ppushtitlelendcarnomessage: 'New lend request received',
    txt_global_std_earnings: 'Earnings',
    txt_global_std_greatdrivertoday: 'Great driver today',
    txt_global_std_JyrkiainenInterests: 'Motorsport obviously, jogging, golf, skiing, driving snowmobile in Finnish Lapland',
    txt_global_std_mileageinkm: 'Mileage in km',
    txt_global_std_hours: 'Hours',
    txt_global_std_parkedvsstartoftrip: 'Parked vs start of trip',
    txt_global_std_milesdriven: 'Miles driven',
    txt_global_std_getitfilled: 'Get it filled!',
    txt_global_std_collectpointstounlock: 'Collect points to unlock.',
    txt_global_std_youmustfillinthemileage: 'You must fill in the mileage',
    txt_global_std_achievementsreceived: 'achievements received',
    txt_global_std_registerakinto: 'Register a Kinto',
    txt_global_std_rwmnotripalreadyregisterforthisweektext: 'We are sorry, you can only register a ridesharing trip and earn points once a week. ',
    txt_global_std_NewPW_Text4: 'Sincerely,\r\n{appName}',
    txt_global_std_ontheinjury: 'on the injury',
    txt_global_std_p2pdeletemessages: 'Delete messages',
    txt_global_std_p2prewardyourfriend: 'Reward your friend',
    txt_global_std_actions: 'Actions',
    txt_global_std_loyaltycertificate: 'Loyalty certificate',
    txt_global_std_mystatsthisday: 'My stats for latest day',
    txt_global_std_kintorecruit5usersunlocked: 'Thank you! You have recruited 5 Kinto users. ¥10.000 has been sent to your Kinto wallet.',
    txt_global_std_whenwillyoudeducttheinvoicepayment: 'When will you deduct the invoice payment?',
    txt_global_std_phone: 'Phone',
    txt_global_std_zipdriver_upsell_pitch: 'Geographical analysis using zip code/ postcode risk database to;',
    txt_global_std_p2pbooksequentialdates: 'Book sequential dates',
    txt_global_std_turnonthewarninglight: 'Turn on the warning light',
    txt_global_std_insurancepolicydocuments: 'Insurance policy documents',
    txt_global_std_youdroveatscoretypescoreorbettergreatyouhaveearnedpointspoints: 'You drove at {score} or better! Great! You have earned {points} points!',
    txt_global_std_OSolbergInterests: 'Coming soon...',
    txt_global_std_qidonthavebluetoothinmycarcanistillusetheapp: 'Q: I don’t have Bluetooth in my car, can I still use the app?',
    txt_global_std_qhowdoirenewmyinsurance: 'Q: How do I renew my insurance? ',
    txt_global_std_MSIG_InsuranceLetter12: 'Period Of Insurance',
    txt_global_std_acommontypeofunsafedrivingbehaviorisputtinghugeeffortintochasingthenexttrafficlightitslikeachallengetogettothenextcrossingasquicklyaspossibleonlytobestuckwaitingforthelighttoturngreendrivingsmoothlyandlookingwellaheadtoanticipatewhatscomingupsavesbothtimeandfuel: 'A common type of unsafe driving behavior is putting huge effort into chasing the next traffic light! It’s like a challenge to get to the next crossing as quickly as possible only to be stuck waiting for the light to turn green😊 Driving smoothly and looking well ahead to anticipate what’s coming up saves both time and fuel.',
    txt_global_std_youmayhavetogoto: 'You may have to go to ',
    txt_global_std_what: 'What',
    txt_global_std_wanttobuyinsurance: 'Want to buy insurance?',
    txt_global_std_unlockbenefitsenterpolicynumber: 'Unlock benefits - Enter policy number',
    txt_global_std_timing: 'Timing',
    txt_global_std_bydrivingsafeandsmartrememberto: 'By driving safe and smart. Remember to:',
    txt_global_std_p2pcompleted: 'Completed',
    txt_global_std_whenusingthiscodeorlinkuserwillbeonboardedtoyourenerfyglobalteamandthefollowingsubgroupgroups: 'When using this code or link user will be onboarded to your Enerfy Global team and the following sub group/groups',
    txt_global_std_p2precommended: 'Recommended',
    txt_global_std_avgspeed: 'Avg. Speed',
    txt_global_std_photos: 'Photos',
    txt_global_std_pickyourteam: 'Pick your team',
    txt_global_std_p2phoursused: 'Hours used',
    txt_global_std_automatichelpsyourecord: 'Automatic connection helps you record your trips in case you forget to open the app.',
    txt_global_std_invitationqrcode: 'Invitation QR code',
    txt_global_std_connectengine: 'Start the engine to connect',
    txt_global_std_driverforscore: 'Drive {appname} for a score',
    txt_global_std_cimage26d: ' ',
    txt_global_std_ConfirmFia_Text8: 'See you there!',
    txt_global_std_ringphoneno: 'Ring {phoneNo}',
    txt_global_std_northzone: 'North Zone',
    txt_global_std_p2pdistancetravelledrequiresbluetooth: 'Distance travelled requires bluetooth',
    txt_global_std_name: 'Name',
    txt_global_std_customize: 'Customize',
    txt_global_std_p2pwelcometokintowarikan: 'Welcome to Kinto Warikan!',
    txt_global_std_cartype: 'Car type',
    txt_global_std_unknownstreet: 'Unknown street',
    txt_global_std_p2ppushbodyyouhaveabookingtofinalize: 'Please note that a booking is not settled',
    txt_global_std_imagepickerlocked: 'Choose locked badge icon',
    txt_global_std_vouchercodesaved: 'Voucher code saved',
    txt_global_std_enerfyanalytics_upsell_pitch: 'Get a complete understanding of your data with personalized or predefined reports to;',
    txt_global_std_warikan_onboarding1_2: 'Dear. Warikan KINTO customer',
    txt_global_std_selectdate: 'Select date',
    txt_global_std_invite10friendstojointhechallengeforabetterworld: 'Invite 10 friends to join the challenge for a better world.',
    txt_global_std_activate: 'Activate',
    txt_global_std_makesureyouandothersareinasafeplaceifanyoneisinjuredcallphoneno: 'Make sure you and others are in a safe place. If anyone is injured, call: {phoneNo}',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatuscheckoutbelowtheachievementsyoucanearnbybeinga: 'Achievements help you collect points to earn your loyalty status. Check out below the achievements you can earn by being a',
    txt_global_std_oncetheapprecognisestheobdreaderyoullmoveautomaticallytothenextstep: "Once the app recognises the OBD reader, you'll move automatically to the next step.",
    txt_global_std_greatdrive: 'Great drive!',
    txt_global_std_thankyouforyourorderenerfy: 'Thank you for your order. Your Enerfy plug-in will be sent to you the next working day. Estimated delivery is 1-3 working days.',
    txt_global_std_internalservererrorpleasecontactinfogreaterthaneulicensekeynotvalid: 'Internal server error, please contact info@greaterthan.eu (Licensekey not valid)',
    txt_global_std_countrycode: 'Country code',
    txt_global_std_astronautssuperlonggroupnamegroup23: 'Astronauts, Super long group name, Group 23',
    txt_global_std_welcomeletsstart: "Welcome! Let's start!",
    txt_global_std_car2: 'Car',
    txt_global_std_tripsperperiod: 'Trips per period',
    txt_global_std_p2pdateandtime: 'Date & time',
    txt_global_std_p2pwehavesentanewtemporarypasswordtoyouremailaddress: 'We have sent a new temporary password to your email address.',
    txt_global_std_map2: 'Map',
    txt_global_std_enteravalidpolicynumber: 'Enter a valid policy number.',
    txt_global_std_sendnow: 'Send now',
    txt_global_std_qrcode: 'QR code',
    txt_global_std_wrongemailorpassword: 'Wrong email or password',
    txt_global_std_randomperson: 'Random person',
    txt_global_std_3gotobluetoothsettinginyourmobilemakesureyourmobilesbluetoothisturnedon: "3. Go to Bluetooth setting in your mobile, make sure your mobile's Bluetooth is turned on.",
    txt_global_std_step5: 'Step {number}',
    txt_global_std_currentinsurancecompany: 'Current insurance company',
    txt_global_std_mysettings: 'My settings',
    txt_global_std_areyousureyouwanttoremoveuserfromthegroup: 'Are you sure you want to remove user from the group?',
    txt_global_std_youhaventdriventhismonth: "You haven't driven this month",
    txt_global_std_checkyourinbox: 'Check your inbox',
    txt_global_std_communication_upsell_bulletpoint3: 'Communicate contextual messages at the right time',
    txt_global_std_reportfilled: 'Report filled',
    txt_global_std_Alert_WereYouDrivingBody: 'Remember to start & stop your drives in the app to get your driving score.',
    txt_global_std_invitemoreusers: 'Invite More Users',
    txt_global_std_searchmenu: 'Search menu',
    txt_global_std_header: 'Header',
    txt_global_std_p2pconnecttocarcomingsoon: 'Connect to car – Coming soon',
    txt_global_std_actual: 'Actual',
    txt_global_std_or2: 'or',
    txt_global_std_communication_upsell_pitch_bulletpoint2: 'Connect with users on their preferred channels',
    txt_global_std_cimage20d: 'You save time by planning your driving. High max speed gives small or no time gains.',
    txt_global_std_internetconnectionlost: 'Connection lost. This can cause delays in app and affect your driving data.',
    txt_global_std_turnonyourcar: 'Turn on your car',
    txt_global_std_p2pemailnewbookings: 'New bookings',
    txt_global_std_alertdrivenotstarted: 'Warning! You seems to be traveling but no drive is started.',
    txt_global_std_manageyourteammembersandtheiraccountpermissionshere: 'Manage users and their account permissions here',
    'txt_global_std_Al-KuwariQuote': 'Quote coming soon...',
    txt_global_std_startbonusno: 'Start bonus',
    txt_global_std_saving: 'Saving',
    txt_global_std_nationalinsuranceno: 'National Insurance. No',
    txt_global_std_ChicheritDescription: 'Monster Athlete Guerlain Chicherit steps up to the FIA World Rallycross Championship in 2018 with his own GC Kompetition team driving the new Prodrive-developed Renault Megane RS. A professional skier and four-time world champion in freeriding, Chicherit holds the record for becoming the first man in history to complete an unassisted backflip in a car. Chicherit made his World RX debut in the JRM Mini RX Countryman in 2015.',
    txt_global_CoreTemplate_Offline: 'Offline trip',
    txt_global_std_youhaveunsavedsettingsquitanyway: 'You have unsaved settings quit any way?',
    txt_global_std_p2ppushtitlehasdeniedthebooking: 'Booking denied',
    txt_global_std_MikkelsenMileage: "I always enjoy driving in my hometown Oslo when back in Norway. I'm traveling quite a lot, so too me it's always nice to come home. However, I also enjoy driving in the alps since the views there are amazing.",
    txt_global_std_inviteyourfriendsandfamilytojointhechallengeforabetterworld: 'Invite your friends and family to join the challenge for a better world',
    txt_global_std_yourcarnowhasanactivatedinsurance: 'Your car now has an activated insurance',
    txt_global_std_weadaptthestartscreen: 'We adapt the start screen according to your selections.',
    txt_global_std_highlightedtheareainwhichyouhavethemostimprovementpotentialinthemapandchart: "We've highlighted the area in which you have the most improvement potential in the map and chart above, go see for yourself!",
    txt_global_std_theusermustacceptaninvitationtotheteamfirst: 'The user must accept an invitation to the team first.',
    txt_global_std_caniswitchtoagentifialreadyhaveanactiveinsurancepolicy: 'Can I switch to {agent} if I already have an active insurance policy?',
    txt_global_std_createnewemail: 'Create new email',
    txt_global_std_p2pfaq15q: 'How do I add expenses related to the car?',
    txt_global_std_yourpasswordwaschanged: 'Your password was changed!',
    txt_global_std_sparkunlockedkey: 'You drove really well! Your average level was better than 4 for a week! 1000 points have been added to your Kinto Wallet.',
    txt_global_std_joinaheattoenterthefiasdc: 'Join a heat to enter the Smart Driving Challenge!',
    txt_global_std_turnonbluetooth: 'Turn on Bluetooth',
    txt_global_std_yeary: 'y',
    txt_global_std_HusseinAbdullaWhyjoin: 'Spirit of challenge',
    txt_global_std_rwmnotripalreadyregisterforthisweektitle: 'Ridesharing trip was already registered for this week',
    txt_global_std_JyrkiainenWhyjoin: 'Together we can have fun and improve our efficient driving skills with FIA Smart Driving Challenge!',
    txt_global_std_cimage34h: 'DO NOT STRESS WHEN IN QUEUES',
    txt_global_std_manageadvancedusersettingsandsupporthereusethequickaccessbuttonsbelowtoeasilynavigatebetweenusersagreementsandfollowupandhelpoutwithonboarding: 'Manage advanced user settings and support here. Use the quick access buttons below to easily navigate between users, agreements and follow up and help out with onboarding.',
    txt_global_std_yesagentacceptsdriverswhohavebeenlicensedinothercountries: 'Yes, {agent} accepts drivers who have been licensed in other countries.',
    txt_global_std_MSIG_InsuranceLetter45: 'AN EXCESS OF $100.00 IS APPLICABLE FOR EACH AND EVERY WINDSCREEN CLAIM',
    txt_global_std_datespan: 'Date span',
    txt_global_std_top3besttrips: 'Top 3 best trips',
    txt_global_std_welldone: 'Well done',
    txt_global_std_practicaltimesaving: 'Practical time saving',
    txt_global_std_thelowerthescorethemoresavingsyoucanearnyouwillseeyourpotentialsavingswitheachtrip: 'The lower the score the more savings you can earn. You will see your potential savings with each trip.',
    txt_global_std_payment: 'Payment',
    txt_global_std_remembertostartandstop: 'Remember to start and stop your drives in the app to collect points and rewards.',
    txt_global_std_searchbartitleandpointstext: 'Search by title or points',
    txt_global_std_currentstandings: 'Current standings',
    txt_global_std_kintofaq4a: 'You automatically participate when you sign up. Don’t worry, the leaderboard will only show your nickname- so you will always stay anonymous. The competition lasts from 1st November 2020 to 31st January 2021 and the winner wins a trip to go ice fishing in Northern Sweden! ',
    txt_global_std_p2ppushbodynewinvoicetopay: 'You received an invoice for cost split of {vehicletype}',
    txt_global_std_workshop: 'Workshop',
    txt_global_CoreTemplate_help: 'Help',
    txt_global_std_drivingmenu: 'driving',
    txt_global_std_weareimpressed: 'Wow {user}, we are impressed!',
    txt_global_std_youhavenowdriven5tripsbetterthan3point3: 'You have now driven 5 trips better than 3.3',
    txt_global_std_qcaniswitchtoagentifialreadyhaveanactiveinsurancepolicy: 'Q: Can I switch to {agent} if I already have an active insurance policy?',
    txt_global_std_savingsistobeusedforsubscriptiondeduction: 'Saving is to be used for subscription deduction',
    txt_global_std_p2pfaq31a: '1. Select "Driving Data" from the "Menu".\n' +
      '2. You can check the data of your driving history. You can see the details of the feedback by selecting specific driving data.',
    txt_global_std_campaignname: 'Campaign name',
    txt_global_std_tomasengedescriptionshort: 'A former professional racing driver and the first Czech driver in F1. He loves silence while driving.',
    txt_global_std_invalidtime: 'Invalid time.',
    txt_global_std_kilometerpremiumtoohigh: 'Kilometer premium too high',
    txt_global_std_redeemablepoints: 'redeemable points',
    txt_global_std_nofriendsselected: 'No friends selected',
    txt_global_std_propernumber: 'Must be a proper phone number',
    txt_global_std_ConfirmFia_Hello: 'You are now booked!',
    txt_global_std_readthecarsengineerrorsusingtheiobd2app: "Read the car's engine errors using the iOBD2 app",
    txt_global_std_createchallenge: 'Create challenge',
    txt_global_std_defaulticonimage: 'Default',
    txt_global_std_insuranceterms: 'Insurance terms',
    txt_global_std_p2pfaq4q: 'How do I delete a message?',
    txt_global_std_confirmandupdateaccount: 'Confirm and update account',
    txt_global_std_notesaved: 'Note saved',
    txt_global_std_youhaveinvited: 'You have invited',
    txt_global_std_p2pmaintenance: 'Maintenance',
    txt_global_std_viewalldrivingdata: 'View all driving data',
    txt_global_std_audiofeedback: 'Audio feedback',
    txt_global_std_p2pfaq3a: 'Soon we will add a feature that will help you find your car. For now, we recommend asking your friend in the chat messages.',
    txt_global_std_encouragetostayfocused: 'Encourage driver to stay focused',
    txt_global_std_nicetohaveyouonboard: 'Nice to have you on board!',
    txt_global_std_warikan_onboarding14: 'Please note that it may be difficult to connect when it is crowded.',
    txt_global_std_p2pseeyourbookingsorcheckavailabilityofyourfriendscar: 'See your bookings or check availability of your friend’s car.',
    txt_global_std_dateandtime: 'Date and time',
    txt_global_std_totalistheleaderboardforthechallenge: 'Total is the leaderboard for the challenge',
    txt_global_std_updatetext: 'Update Text',
    txt_global_std_total: 'Total',
    txt_global_std_SulayemFacts: 'He has competed in African, European, Middle East and World Championship Rallies. He won one of his titles while recovering from a broken neck',
    txt_global_std_congratulationsyouhaveverifiedyouremailyouwillberedirectedshortly: 'Congratulations, you have verified your email. You will be redirected shortly',
    txt_global_std_PSolbergQuote: 'Quote coming soon...',
    txt_global_std_tryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagainnodot: 'Try resetting your car’s connection to your phone by removing it and then adding it again',
    txt_global_std_cimage25h: 'OPTIMAL RPM',
    txt_global_std_p2pbookingcancelled: 'Booking cancelled',
    txt_global_std_drivingyieldtotalamount: 'Driving yield (total amount)',
    'txt_global_std_drivingtimeabove{speed}': 'driving time above {speed}',
    txt_global_std_saveanduploadreport: 'Save and upload report',
    txt_global_std_pleasechooseaninsurance: 'Please choose an insurance',
    txt_global_std_techsupport: 'Tech support',
    txt_global_std_p2piacceptthe: 'I accept the',
    txt_global_std_safedriverweekly: 'Safe Driver Weekly',
    txt_global_std_pleasemakesurethatyouareconnectedtoyourcar: 'Please make sure that you are connected to your car and try again.',
    'txt_global_std_{count}points': '{count} points',
    txt_global_std_bestoptionifyoudonthavebt: "This is the best option if you don't have a Bluetooth connection in your car.",
    txt_global_std_planaheadbeprepared: 'Plan ahead, be prepared',
    txt_global_std_top: 'Top',
    txt_global_std_howdoidrivewell: 'How do I drive well?',
    txt_global_std_fellowdriver: 'fellow driver',
    txt_global_std_apopupintheappwillaskyoutoconnect: 'A popup in the app will ask you to connect (May take upto 2 minutes)',
    txt_global_std_qhowdoiterminatemyinsurance: 'Q: How do I terminate my insurance?',
    txt_global_std_incorrectlyformattedcsvfilemakesuretofollowtheexactformatofthetemplate: 'Incorrectly formatted .csv file. Make sure to follow the exact format of the template!',
    txt_global_std_startaddress: 'Start address',
    txt_global_std_collectablecategory: 'Collectable',
    txt_global_std_andallowitwevalueyourprivacy: 'and allow it. We value your privacy and will not share this information with a third party entity.',
    txt_global_std_9digitactivationcode: 'Enter the 9-digit activation code sent to your phone and email.',
    txt_global_std_linktopolicies: 'Link to policies',
    txt_global_std_couldnotchangeyourpassword: 'Could not change your password!',
    txt_global_std_december: 'December',
    txt_global_std_SignupFia_Text9: '#FIASmartDrivingChallenge',
    txt_global_std_5000pointaverageachievementlocked2: 'Drive with an average {scoretype} of {score} or better for a week to unlock this achievement and earn 5000 points.',
    txt_global_std_nicedrive: 'Nice drive!',
    txt_global_std_uniquecategory: 'Unique',
    txt_global_std_appName: '{appName}',
    txt_global_std_howdoiearnaloyaltystatus: 'How do I earn a loyalty status?',
    txt_global_std_p2pcontinueandpay: 'Continue and Pay',
    txt_global_std_planahead: 'Plan ahead',
    txt_global_std_p2ppushtitleaddedexpences: '{name} added expences',
    txt_global_std_competeagainsteachotherwintogether: 'Compete against each other - win together',
    txt_global_std_forbothyourphoneandcar: 'for both your phone and car.',
    txt_global_std_nodatatoshow: 'Get started to see your progress',
    txt_global_std_HansenDescription: 'Kevin Hansen, the youngest ever FIA European Supercar champion and 2016 FIA Rookie of the year started his career at only five years of age in karting. Progressing through the classes of karting, Kevin switched to RX in 2012 and quickly showed he was there to succeed. Winning several championships during 2013-2015, Kevin reached his dream in 2016 by winning EuroRX. Since 2017, Kevin has been a World Rallycross regular and has finished 8th twice in the World Championship, and in 2018 he became a factory driver of Peugeot at the age of 19!',
    txt_global_std_p2pyouborrowed: 'You borrowed',
    txt_global_std_manual: 'Manual',
    txt_global_std_resetpasswordwrongpasswordformat: 'Wrong password format. Minimum 8 characters, at least one uppercase letter, one lowercase letter and one number.',
    txt_global_std_fileaclaim: 'File a claim',
    txt_global_std_drivercppr: 'Driver crash pattern profiling report',
    txt_global_std_membernumber: 'Member number',
    txt_global_std_amazingdriverunlockedkey: 'You are amazing {user}! You got a score above {score} today!',
    txt_global_std_openinghoursinsurance: '8-20 weekdays, 10-18 weekend',
    txt_global_std_howdoicompeteinlexuschallenge: 'How do I compete in Lexus Challenge?',
    txt_global_std_p2perrorcodebookingmaynotbeearlierthannow: 'Booking may not be earlier than now.',
    txt_global_std_p2paddedcaraslendable: 'Added car as lendable',
    txt_global_std_activateitnow: 'Activate it now',
    txt_global_std_choosetypeofapp: 'Choose type of app',
    txt_global_std_challengeafriend3: 'Challenge a friend!',
    txt_global_std_f_lanzdescriptionshort: 'The best lady racing driver in South Africa. She loves dancing, wild Nature Explorer',
    txt_global_std_carbondioxide: "Carbon dioxide is a colorless gas with a density about 60% higher than that of dry air. Carbon dioxide consists of a carbon atom covalently double bonded to two oxygen atoms. It occurs naturally in Earth's atmosphere as a trace gas.",
    txt_global_std_p2pfaq19a: 'If you are using an Android phone, driving data may not be recorded if the "Optimize battery" function is turned on.\n' +
      '1. Select "Apps and notifications" from "Settings" on your smartphone.\n' +
      '2. Select advanced app and notification settings, then select Special app access.\n' +
      '3. Select "Optimization of electricity" from the special app access.\n' +
      '4. Select "All apps" from the pull-down menu, and then select "Warikan KINTO".\n' +
      '5. Select "Do not optimize" from the dialog.',
    txt_global_std_add: 'Add',
    txt_global_std_documentyourcar: 'Document your car',
    txt_global_std_schema: 'Schema',
    txt_global_std_visualeditor: 'Visual editor',
    txt_global_std_estimatedsurcharge: 'Estimated surcharge',
    txt_global_std_plugindevice: 'Plug in device',
    txt_global_std_therecouldbeotherreasonswhyyourcarsbluetoothdoesntshowupinthelist: "There could be other reasons why your car's Bluetooth doesn't show up in the list:",
    txt_global_std_silverusers: 'Silver users',
    txt_global_std_clickhere: 'Click here!',
    txt_global_std_p2pemailifyouremailclientdoesnotacceptbutton: 'If your email client does not accept button, click on this link instead.',
    txt_global_std_toconnectyourcar: 'To connect your car:',
    txt_global_std_nousersfoundtryreloadingthepage: 'No users found. Try reloading the page.',
    txt_global_std_co2: 'CO2',
    txt_global_std_improvementpossibility: 'Improvement possibility',
    txt_global_std_unpublish: 'Unpublish',
    txt_global_std_textmessage: 'Text message',
    txt_global_std_cimage43d: ' ',
    txt_global_std_set_up_manual_start_stop: 'Set up: Manual start & stop',
    txt_global_std_pwdtenandtwice: 'Your password must be at least ten (10) characters, and must be written twice!',
    txt_global_std_cimage30h: 'PLAN EVERY ACCELERATION TO GET USE OF IT',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint3: 'Obtain accurate insights into real-time geographical risk exposure',
    txt_global_std_zipdrivingriskindex: 'Zip-Driving risk index',
    txt_global_std_p2pfaq18q: 'Please tell me how to connect the app to the car and record driving data.',
    txt_global_std_maxfilesize: 'Max file size',
    txt_global_std_togetmorefriendssuggestionsyoucanloadyourfriendsfromfacebook: 'To get more friend suggestions you can load your friends from Facebook.',
    txt_global_std_youcanemailthiscertificatetogetdiscountswithdifferentvendors: 'You can email this certificate to get discounts with different vendors.',
    txt_global_std_toprovideyouwithaccuratestatsanddrivingscoreappnameneedstoruninbackgroundmode: 'To provide you with accurate stats and driving score {appname} needs to run in background mode.',
    txt_global_std_carwash: 'Car wash',
    txt_global_std_ABC: 'ABC',
    txt_global_std_whoisthesmartestdriver: 'Who is the smartest driver?',
    txt_global_std_enerfyanalytics_upsell_bulletpoint1: 'Understand the factors that determine risk level',
    txt_global_std_api: 'API',
    txt_global_std_adgangtilmotionogfitnessdata: 'Allow Motion & Fitness',
    txt_global_std_p2ptotaltopaytoph: 'Total to pay to {user}',
    txt_global_std_ConfirmFia_Text71: "If you by any chance won't make it to the event please cancel your timeslot by clicking on the following link: ",
    txt_global_std_electriccarorobd: 'Electric car or OBD?',
    'txt_global_std_Al-KuwariMileage': 'I like to drive long distances I find myself in long distances.',
    txt_global_std_oliversolbergShortDescription: 'None',
    txt_global_std_p2pwaitingfor: 'Waiting for',
    txt_global_std_iwanttoreceiveinformationandupdatesviaemail: 'I want to receive information and updates via email.',
    txt_global_std_fullname: 'Full name',
    txt_global_std_3rd: '3:rd',
    txt_global_std_p2ppushbodyhasdeniedthebooking: '{name} has denied the booking.',
    txt_global_std_OSolbergChamp: 'Coming soon...',
    txt_global_std_referfriendsgetrewards: 'Refer friends. Get rewards.',
    txt_global_std_p2ppushtitlesetpriceforbookingto: 'Price of booking is set',
    txt_global_std_pleasewait: 'Please wait',
    txt_global_std_joinevent: 'Join event',
    txt_global_std_points1: 'points',
    txt_global_std_pair: 'Pair',
    txt_global_std_demotext2: 'The better you drive, the lower level you get. See your level here.',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriend: 'Compete in your everyday driving against your friend.',
    txt_global_all_thursday: 'Thursday',
    txt_global_std_youneedtoselectatype2: 'You need to select a type',
    txt_global_std_gallonimp: 'Gallon Imp',
    txt_global_std_socialbutterflyunlockedkey: 'You have referred 10 friends! Nice! 300 points have been added to your Kinto Wallet.',
    txt_global_std_findaservicecenter: 'Find a service center',
    txt_global_std_pickyourteamleader2: 'Pick your teamleader',
    txt_global_std_401unauthorized: '401 - Unauthorized',
    txt_global_std_smssent: 'SMS Sent!',
    txt_global_std_p2psplittingthecostforyourkintosubscribtionwithyourfriendsiseasy: 'Splitting the cost for your Kinto subscribtion with your friends is easy!',
    txt_global_std_iveforgottenmypassword: "I've forgotten my password",
    txt_global_std_OSolbergBesttip: 'Coming soon...',
    txt_global_std_connectedtoobd: 'Connected to OBD',
    txt_global_std_emailorusername: 'Email or username',
    txt_global_std_documentsandpolicies: 'Documents and policies',
    txt_global_std_sendreport2: 'Send report',
    txt_global_std_skillslevel7d: 'Skills level 7D',
    txt_global_std_login2: 'Login',
    txt_global_std_MSIG_Onboarding8: '2. Follow the steps in the app.',
    txt_global_std_privacysettingssaved: 'Privacy settings saved',
    'txt_global_std_Al-KhelaifiDescription': 'Abdulla participates in most motorsport events in Qatar and has several championships in different types of racing like National Drag, Qatar Mile, National Sprint, and QTCC. ',
    txt_global_std_temporarypassword: 'Temporary password',
    txt_global_std_clickthelinkbelowtoconfirmemail: 'Click the link below to confirm your email and finish creating your Enerfy Global account.',
    txt_global_std_lendmycar: 'Lend my car',
    txt_global_std_ifyourcardoesnthavebluetooth: "If your car doesn't have bluetooth",
    txt_global_std_tripanalytics: 'Trip analytics',
    txt_global_std_getstartedfolks: 'Get started folks!',
    txt_global_std_takecareofinjury: 'Take care of injury',
    txt_global_std_drivenkmperyear: 'Driven km per year',
    txt_global_std_sos: 'SOS',
    txt_global_std_showmodal: 'Show Modal',
    txt_global_std_hiheresmylocation: "Hi, here's my location:",
    txt_global_std_pushnotdrivenforesometimebody2: 'Make sure to get connected the next time you drive.',
    txt_global_std_p2pgotocarsharing: 'Go to car sharing',
    txt_global_std_keeponpracticingandyoucangetabetterprice: 'Keep on practicing and you can get a better price on your insurance.',
    txt_global_std_distancekm2: 'Distance/km',
    txt_global_std_dontallow: "Don't allow",
    txt_global_std_insurancecontactdetails: 'Insurance contact details',
    txt_global_std_agreementnumber: 'Agreement number',
    txt_global_std_warikan_onboarding7: '3. Enter the following information to create an account\n',
    txt_global_std_dennetur: 'This trip',
    txt_global_std_successfullypublishedlanguage: 'Successfully Published Language',
    txt_global_std_registeryouraccount: 'Register your account',
    txt_global_std_countryroadroundaboutDESC: 'The blue car driver adjusts their speed before entering the roundabout \n' +
      'The red car driver does not adjust their speed enough to enter the roundabout smoothly',
    txt_global_std_p2ppushtitletimetosplitcost: 'It’s time to split cost',
    txt_global_std_period: 'Period',
    txt_global_std_MSIG_Onboarding11: 'Drive safetly,',
    txt_global_std_totaldistance: 'Distance',
    txt_global_std_usersuccessfullyremoved: 'User Successfully Removed',
    txt_global_std_eventualinsurancebonus: 'Eventual insurance bonus\n',
    txt_global_std_activatebluetoothandconnect: '3. Activate bluetooth and connect the OBD reader',
    txt_global_std_locationservicetroubleshooting: 'Location service trouble shooting',
    txt_global_std_vehicleidentification: 'Vehicle identification',
    txt_global_std_dsadsadsadsa: 'dadsasd',
    txt_global_std_phonenumber: 'Phone number',
    txt_global_std_ayessompoacceptsdriverswhoarelicensedinothercountries: 'A: Yes, Sompo accepts drivers who are licensed in other countries.',
    txt_global_std_somethingwentwrongwhenattemptingtoredeempointspleasetryagainlater: 'Something went wrong when attempting to redeem points, please try again later',
    txt_global_std_1startyourcarsengine: '1. Start your car’s engine.',
    txt_global_std_moreoptions: 'More options',
    txt_global_std_minutem: 'm',
    txt_global_std_learnmorehere: 'Learn more here',
    txt_global_std_iaccepttheusertermslinkandconditionsprivacypolicylink: 'I accept the {userTermsLink} and {privacyPolicyLink}',
    txt_global_std_messagebody: 'Message body',
    txt_global_std_safedriver: 'Safe Driver',
    txt_global_std_optionnotavailableyet: 'This option is not available yet!',
    txt_global_std_insuredvehicles: 'Insured vehicles',
    txt_global_std_VilliersInterests: 'Motor racing, all-terrain racing, circuit racing',
    txt_global_std_reviewenerfy: 'Review',
    txt_global_std_downloadqrcode: 'Download QR Code',
    txt_global_std_nocountryselected: 'No country selected',
    txt_global_std_MarklundMileage: 'I love driving the route to home. When you travel a lot, you really appreciate to just be home and the feeling of getting closer and closer is really satisfying. I live in the North of Sweden where the roads are sometimes all empty and where the andscape of lot of woods and wilderness makes me calm and happy. \n',
    txt_global_std_clickheretobuyfiasdc: 'Click here to buy FIA SDC',
    txt_global_std_yesyoucanswitchyourinsuranceanytimemostinsurancecompaniesdonothaveanearlyterminationpenaltyyouwillneedtoinquirewithyourcurrentinsurancecarrier: 'Yes, you can switch your insurance any time. Most insurance companies do not have an early termination penalty. You will need to inquire with your current insurance carrier.',
    txt_global_std_p2pskip: 'Skip',
    txt_global_std_primarybluetoothid: 'Primary bluetooth ID',
    txt_global_std_nofinishedchallenges: 'No finished challenges',
    txt_global_std_selectcountrycode: 'Select country code',
    txt_global_std_youbooked10timesinonemonthyouhaveearned2500points: 'You have earned 2,500 points for making 10 reservations in a month.',
    txt_global_std_typeyourcodefrominsuranceletter: 'Type your code from the insurance welcome letter',
    txt_global_std_wecantfindanydrives: "We can't find any drives!",
    txt_global_std_watchexplainervideo: 'Watch explainer video',
    txt_global_std_thiskeydoesnothaveatranslationinthislanguage: 'this key does not have a translation in this language',
    txt_global_std_documentandpolicyes: 'Documents and policyes',
    txt_global_std_loyaltypoints: 'Loyalty points',
    txt_global_std_couldnotconnect: 'Could not connect',
    txt_global_std_countmonths: '{count} months',
    txt_global_std_closestyou: 'closest you',
    txt_global_std_viewcost: 'View cost',
    txt_global_std_theinsurancehasbeenactivated: 'The insurance has been activated.',
    txt_global_std_createnewtextmessage: 'Create new text message',
    txt_global_std_tomasengemusic: "I don't listen to anything in the car as I love silence while driving - like in race car",
    txt_global_std_cimage5d: 'Use good hill techinque;keep gas steady,  lose speed uphill, roll out downhill.',
    txt_global_std_smsclientonboardingpincode: 'Here comes your {pinlength} digits PIN code: {pincode}. PIN code is valid for {validminutes} minutes. \n' +
      '\n' +
      'Best regards, \n' +
      '{companyname}',
    txt_global_std_remembertodrivesmart: 'Remember to drive smart.',
    txt_global_std_countryroad4DESC: 'The blue car driver slows down and approaches the curve smoothly. \n' +
      'The red car driver does not adjust their speed to the curvature and is forced to brake.',
    txt_global_std_p2ppleasecontactsupport: 'please contact support.',
    txt_global_std_MarklundFacts: 'Started racing internationally 2011 and have since then been competing in series as Porsche Carrera Cup Scandinavia, FIA World/European Rallycross Championship and X-Games.',
    txt_global_std_to_make_a_change_in_your_insurance_policy: 'To make a change in your insurance policy, please log in at {url} or contact support at {email} or {phone}.',
    txt_global_std_spooring: 'Spooring',
    txt_global_std_inviteapersonyouwantotshareinsurancewith: 'Invite a person you want to share your insurance with.',
    txt_global_std_yourusername: 'Your username',
    txt_global_std_superdriver: 'super driver!',
    txt_global_std__enterthetemporarypasswordsendtoyoubyemail: 'Enter the temporary password sent to you by email.',
    txt_global_std_kintofaq3a: 'You earn your loyalty status by collecting points! You get points from the different achievements available. Achievements are given to our loyal and safe Kinto drivers! Learn more what it takes under the “Achievements section”.',
    txt_global_std_usethistemplateasabadgeinyourappcustomizeitinthenextstep: 'Use this template as a badge in your app. Customize it in the next step.',
    txt_global_std_TomczykBesttip: 'Stay focused on your driving style and drive “defensively”. ',
    txt_global_std_writeanewpassword: 'Write a new password',
    txt_global_std_userhasnogroups: 'User has no groups',
    txt_global_std_nextqualificationforthegrandfinalindecemberstarts: 'Next qualification for the Grand Final in December starts',
    txt_global_std_paddonQualification: 'Test Qualification text for Paddon',
    txt_global_std_4th: '4:th',
    txt_global_std_p2pchatwithyourfriends: 'Chat with your friends',
    txt_global_std_clickthebuttonbelowtoresetyourenerfyglobalpassword: 'Click the button below to reset your Enerfy Global password.',
    txt_global_std_lostconnectionsmall: 'start the engine to continue',
    txt_global_std_enterdetails: 'Enter details',
    txt_global_std_entergender: 'Enter gender',
    txt_global_std_makesuretheobdreaderisreadytobepaired: 'Make sure the OBD-reader is ready to be paired. It will flash blue when ready.',
    txt_global_std_yourpolicyshouldcontaineight8numbers: 'Your policy should contain eight (8) numbers.',
    txt_global_std_step2: 'Step 2',
    txt_global_std_FiaPassword_Text1: 'Hello!',
    txt_global_std_v2score: 'Score',
    txt_global_std_youraveragescoreis123: 'Your average score is',
    txt_global_std_saveinformation: 'Save information',
    txt_global_std_conclusion: 'Conclusion',
    txt_global_std_p2pspecifyprice: 'Specify price',
    txt_global_std_p2pyouhaveanexpensetoapprove: 'You have an expense to approve',
    txt_global_std_redirectingyou: 'Redirecting you...',
    txt_global_std_developerpage: 'Developer page',
    txt_global_std_p2pspecifyegfuel: 'Specify (e.g. fuel)',
    txt_global_std_p2ptotaltopayto: 'Total to pay to',
    txt_global_std_ayesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforcovercubeinsurance: 'A: Yes, you can download the app and choose the option “try the app”. You will be able to drive with the app and see how much you could save if you signed up for Covercube insurance. ',
    txt_global_std_sendinvitation: 'Send invitation',
    txt_global_std_2makesureyourcarsbluetoothisonorthatyourbluetoothbeaconisconnected: "2. Make sure your car's Bluetooth is on or that your bluetooth beacon is connected.",
    txt_global_std_p2premovefriend: 'Remove friend',
    txt_global_std_noaccountsignuphere: 'No account? Sign up here',
    txt_global_std_p2ppayments: 'Payments',
    txt_global_std_internalservererror: 'Internal server error',
    txt_global_std_thisyear: 'This year',
    txt_global_std_costforspeedover80: 'Cost for speed above {speed}',
    txt_global_std_timeframes: 'Time Frames',
    txt_global_std_p2ppleaseenteratemporarypassword2: 'Please enter a temporary password.',
    txt_global_std_TidemandQuote: "I always enjoy a good challenge, especially a challenge that has a bigger purpose. To drive smart and responsibly is something that concerns us all and by improving our eco-friendly driving in our everyday life, we're investing in our future. I'm all in!",
    txt_global_std_locateyourobdsocket: '1. Locate your OBD socket',
    txt_global_std_noconnectiontomapserver: 'No connection to map server',
    txt_global_std_hiwelcometoappname: 'Hi! Welcome to {appName}!',
    txt_global_std_unitskmmi: 'Units km/mi',
    txt_global_std_warikan_onboarding3_2: 'Please note that the temporary password is valid for 72 hours.\n' +
      'After the expiration, please reissue another temporary password by clicking "Resend Temporary Password" button in the app.\n' +
      '\n' +
      '*This email address is only for sending purpose. We will not be able to respond to any replies, so please direct any inquiries to the following address.',
    txt_global_std_pushnotification: 'Push Notification',
    txt_global_std_willigetnotifiedififorgottostart: 'Will I get notified if I forget to start the Enerfy app?',
    txt_global_std_backtolist: 'Back to list',
    txt_global_std_startdrivingtogetascore: 'Start driving to get a score!',
    txt_global_std_enerfyanalytics2: 'Enerfy Analytics',
    txt_global_std_p2ppushbodyexpensesthismonthquestion: 'Make sure to add your expenses for {monthname}.',
    txt_global_std_totalthismonth: 'Total this month',
    txt_global_std_youcannotsetafuturedate: 'You cannot set a future date',
    txt_global_std_myprofile: 'My profile',
    txt_global_std_achievementsavedmessage: 'Achievement have been saved!',
    txt_global_std_export2: 'Export',
    txt_global_std_timelocationaretheonlymandatoryfields: 'Time & location are the only mandatory fields.',
    txt_global_std_daydreamingormindwanderingnew: 'Day dreaming or mind wandering',
    txt_global_std_stardriver: 'Star Driver',
    txt_global_std_p2phide: 'Hide',
    txt_global_std_riskydecisionwithintripsbetweendays: 'Risky decision within trips & between days',
    txt_global_std_connecttoyourcar: 'Connect to your car',
    'txt_global_std_Al-KuwariFacts': 'As a co-driver, Nasser has won 2nd Place in 2016 FIA Cross Country Co-driver, 1st in 2017  and 2018 MERC 2 –co-driver, 9 times Qatar Championships as a co-driver and 4 time champion in Kuwait Championships.',
    txt_global_std_averagescoreofparticipants: 'Average score of participants',
    txt_global_std_createaccountlater: 'Create account later',
    txt_global_std_p2pamount: 'Amount',
    txt_global_std_KleinschmidtInterests: 'Sports, computer technology, flying',
    txt_global_std_drivendistanceperperiod: 'Driven distance per period',
    txt_global_all_tuesday: 'Tuesday',
    txt_global_std_p2pleavethisgroupchatquestion: 'Leave this group chat?',
    txt_global_std_90days: '90 days',
    txt_global_std_co2saver: 'CO₂ Saver',
    txt_global_std_25km: '25 Km',
    txt_global_std_tipcityparking2DESC: 'The blue car driver alerts surrounding cars by using turn signals.\n' +
      'The red car driver does not use turn signals before parallel parking.',
    txt_global_std_inviteafriendyouallwillgetareward: 'invite a friend you all will get a  reward',
    txt_global_std_shortcuts: 'Shortcuts',
    txt_global_std_toprovideyouwithadrivingscoreandstatsaboutyourdrivingappnameneedsaccesstolocationservices: 'To provide you with a driving score and stats about your driving {appname} needs access to location services.',
    txt_global_std_poor: 'Poor',
    txt_global_std_after6monthsyouwillgetarenewalofferbasedonyourdrivinguntilthenyoucankeepimprovingyourdrivingfollowyourprogressandseeyourestimatedfuturesavingshere: 'After 6 months you will get a renewal offer based on your driving. Until then you can keep improving your driving. Follow your progress and see your estimated future savings here.',
    txt_global_std_thecruisebeliever: 'The cruise believer',
    txt_global_std_cimage6d: 'Avoid overtaking other vehilcles.',
    txt_global_std_KleinschmidtShortDescription: 'Dakar Rally Champion 2001',
    txt_global_std_senddocumentto2: 'Send document to:',
    txt_global_std_saveunits: 'Save units',
    txt_global_std_perusermonth: 'per user/month',
    txt_global_std_nameheader: 'Achievement title',
    txt_global_std_changeyouraddress: 'Change your address',
    txt_global_std_translationstoapprove: 'Translations to approve',
    txt_global_std_head2head: 'You have been challenged in Head to Head!',
    txt_global_std_MSIG_Password3: 'is',
    txt_global_std_privateonlyformyorg: 'Private (only for my org)',
    txt_global_std_settimeperiodforhowlongyourdatawillbestored: 'Set time period for how long your data will be stored',
    txt_global_std_404notfound: 'Resource not found!',
    txt_global_std_ayesyoumustadviseyouragentthatyourvehicleisusedforaridesharingcompanyandtheywillapplytheridesharingendorsementtoyourpolicy: 'A: Yes, you must advise your agent that your vehicle is used for a ridesharing company and they will apply the ridesharing endorsement to your policy.',
    txt_global_std_thelinkwillbeavailableforxdays: 'The link will be available for {x} days.',
    txt_global_std_200ok: '200 - OK',
    txt_global_std_trygdrivehelpsyouwithmotivationandtipstodrivebettersaferandmoreecofriendly: 'Sidekick helps you with the motivation and tips to drive better, safer and more eco-friendly.',
    txt_global_std_earnpointsbydrivingsafe: 'Earn points by driving safe.',
    txt_global_std_privacy: 'Privacy',
    txt_global_std_riskscore: 'Risk score',
    txt_global_std_addaphoto: 'Add a photo',
    txt_global_std_p2ppushbodysetpriceforbookingto: '{name} set price for booking to: {currprice}',
    txt_global_std_easyas123: 'Easy as 1-2-3',
    txt_global_std_actualtranslation: 'Actual Translation',
    txt_global_std_androidallowallthetime: '…but to get the correct stats of your driving, you need to go to app settings - permissions and choose <b>Allow all the time</b>',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint3: 'Actionable risk insights for smarter business decisions',
    txt_global_std_heattab: 'Heat',
    txt_global_std_createnewgroup: 'Create new group',
    txt_global_std_cimage14d: 'Use down hill to gain higher speed.',
    txt_global_std_xkm: '{km} Km',
    txt_global_std_pigsavedlocked: 'For every 50 crowns you get this achievement',
    txt_global_std_created: 'created',
    txt_global_std_TaxInvoice14: 'THIS IS A COMPUTER GENERATED DOCUMENT, NO SIGNATURE IS REQUIRED',
    txt_global_std_requiredformatcolumnastext: 'Required, format column as text',
    txt_global_std_therulesforthecompanychallengeareasfollowingbrtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsbrdriveminimum1kmduringaheatinordertoqualifyforthechallengebrthesamegeographicalroutewillbescoredamaximumof10timesinoneday: 'The rules for the company challenge are as following:\n' +
      '<br>• The individual driver score is calculated on an average based on the top 10% trips.\n' +
      '<br>• Drive minimum 1 km during a heat in order to qualify for the challenge.\n' +
      '<br>• The same geographical route will be scored a maximum of 10 times in one day.',
    txt_global_std_drivingtogetherismorefun: 'Driving together is more fun',
    txt_global_std_currentpassword: 'Current password',
    txt_global_std_unavailable: 'Unavailable',
    'txt_global_std_emailalreadyusedpleasetrywithanotheremail.': 'Email already used. Please try with another email.',
    txt_global_std_loadingpleasewait: 'Loading... Please wait',
    txt_global_std_availablefromlevel: 'Available from level',
    txt_global_std_p2ptobesettled: 'To be settled:',
    txt_global_std_makeyourselection: 'Please make your selection below:',
    txt_global_std_focusfeedbacktips: '<b>Keep your eyes on the road</b> and focus completely on driving. Sometimes it is easy to be distracted by surroundings, phones, people in the car, etc.<br><br>\n' +
      '<b>Driving tired or stressed</b> can effect the focus and expose you to higher risk.<br><br>\n' +
      '<b>We care about you.</b>',
    txt_global_std_SulayemWhyjoin: 'As a 14 times FIA motorsport champion, I am passionate about driving and motorsports. intrigued by the advances in technology in the motoring industry that we are currently experiencing. We need to embrace these technologies and be a part of the solution to positively impact our environment.',
    txt_global_std_great: 'Great',
    txt_global_std_TidemandBesttip: "To make smart driving as easy as possible - start by never stressing behind the wheel and always keep an eye on what's going on around you and adapt the speed in good time. ",
    txt_global_std_enterpasswordtodeleteuseraccount: 'Enter password to delete user account',
    txt_global_std_p2pfromthismonth: 'From this month',
    txt_global_std_dolinkonthedamage: 'Do {link} on the damage',
    txt_global_std_rwmnoeligibletripstext: 'Register your ridesharing trip within 1 day after the trip has ended.',
    txt_global_std_yourchangesweresaved: 'Your changes were saved',
    txt_global_std_pickyourteamleader: 'Pick your team leader',
    txt_global_std_mar: 'Mar',
    txt_global_std_averagemonthsmonths: 'Average {months} months',
    txt_global_std_doesagentacceptdriversthatarenotlicensedincountry: 'Does {agent} accept drivers that are not licensed in {country}?',
    txt_global_std_majorDescription: 'Anders enas gurkans hämnd 19',
    txt_global_std_p2pnamesentalendrequesttoname2: '{name} sent a lend request to {name2}',
    txt_global_std_subject: 'Subject',
    txt_global_std_rank: 'Rank',
    txt_global_std_OgierWhyjoin: 'Coming soon...',
    txt_global_std_teamleaderboard: 'Team leaderboard',
    txt_global_std_hereyoucanseehowmuchyouwouldhavegottenbackifyouhadhadatrygdrivecarinsurancethebonusiscalculatedbasedontheannualinsurancepriceyourkilometersdrivenperyearandthecartypebasedonthenumberplateyouhaveprovidedifyouhavenotstatedacurrentinsurancepricenumberofkilometersperyearandanumberplatethebonusiscalculatedonthebasisofanannualinsurancepriceofdkk5000andanannualmileageof20000km: 'Here you can see how much you would have gotten back if you had had a Tryg Drive car insurance. The bonus is calculated based on the annual insurance price, your kilometers driven per. year and the car type based on the number plate you have provided. If you have not stated a current insurance price, number of kilometers per. year and a number plate, the bonus is calculated on the basis of an annual insurance price of DKK 5,000. and an annual mileage of 20,000 km.\n',
    txt_global_std_sendchallenge: 'Send challenge',
    txt_global_std_stats2: 'Stats',
    txt_global_std_p2pnomessages: 'No messages',
    txt_global_std_securetheaccidentsite: 'Secure the accident site',
    txt_global_std_cashback: 'Cashback',
    txt_global_std_selectwhatyouwanttouse: 'Select what you want to use.',
    txt_global_std_p2pwaitingforacceptanceandprice: 'Waiting for acceptance and price.',
    txt_global_std_MikkelsenBesttip: 'I always make sure to have plenty of space to the cars in front of me. That always saves you from many situations and allows you to drive more comfortably, smartly and efficiently.',
    txt_global_std_badgetitleplaceholder: 'Badge title',
    txt_global_std_getsexpenseandextradataofgiventrips: 'Gets expense and extra data  of given trips',
    txt_global_std_garminconnected: 'Garmin connected',
    txt_global_std_fiasdcisbettertogether: 'FIA SDC is better together',
    txt_global_std_chooseateamleaderthatyouwanttocompeteforwerecommendoneoftheseteamleadersbasedonyourlocationandgoal: 'Choose a team leader that you want to compete for. We recommend one of these teamleaders based on your location and goal.',
    txt_global_std_termsconditions: 'Terms & conditions',
    txt_global_std_tipcity2DESC: 'The blue car driver engine brakes and drives through the crossing smoothly.\n' +
      'The red car driver accelerates and brakes too hard before the crossing.',
    txt_global_std_lighttheme: 'Light theme',
    txt_global_std_activationemail: 'Activation email',
    txt_global_std_highcostperkilometer: 'High cost per kilometer.',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimeincreasingspeedunnecessaryharshaccelerationisbadfortheenvironmentandforroadsafety: 'On this type of trip, the average eco champion spent more time increasing speed. Unnecessary harsh acceleration is bad for the environment, and for road safety.',
    txt_global_std_savings: 'Savings',
    txt_global_std_opensettingsandmakesureyourbluetoothison: 'Open settings and make sure your Bluetooth is on.',
    txt_global_std_navigatebetweenmodules: 'Navigate between modules',
    txt_global_std_fiasdcnewsletter: 'FIA SDC NEWSLETTER',
    txt_global_std_emailclaims: 'E-mail Claims',
    txt_global_std_connectingto: 'Connecting to',
    txt_global_std_mypoints: 'My Points',
    txt_global_std_pressthedrivebuttontogetstarted: 'Press the drive button to get started.',
    txt_global_std_makeaclaim: 'Make a claim',
    txt_global_std_carsharestats: 'Car share stats',
    txt_global_std_failsenddetails: 'Failed to send details',
    txt_global_std_getshareablelink: 'Get shareable link ',
    txt_global_std_imagerecognitioncompleted: 'Image recognition completed',
    txt_global_std_startdrive2: 'Start drive',
    txt_global_std_cimage7d: 'Accelerate fast to cruising speed when traffic allows.',
    txt_global_std_unknownracedriverShortDescription: 'None',
    'txt_global_std_Al-KhelaifiMusic': 'Nothing specific. Calm music on the radio. Nothing distracting.',
    txt_global_std_p2ppushbodyhasacceptedthebooking: '{name} has accepted the booking.',
    txt_global_std_teamposition: 'Team position:',
    txt_global_std_sendallpersonaldatatoyouremail: 'Send all personal data to your email?',
    txt_global_std_belowyoucanseemoredetailsaboutthistrip: 'Below you can see more details about this trip.',
    txt_global_std_applications: 'Applications',
    txt_global_std_examplemessagepush: 'You have received an example request from John Doe. Open the app to respond now!',
    txt_global_std_topdriversnow: 'Top drivers now',
    txt_global_std_nousersfound: 'No users found, try reloading the page',
    txt_global_std_yourhavebeeninvitedby: 'Your have been invited by:',
    txt_global_std_p2pgroupchatsettings: 'Group chat settings',
    txt_global_std_p2pviewall: 'View all',
    txt_global_std_MarklundQuote: 'Quote coming soon...',
    txt_global_std_yourposition: 'Your position:',
    txt_global_std_notethisinformationwillbevisibletoanyonewhodownloadstheapp: 'Note: This information will be visible to anyone who downloads the app.',
    txt_global_std_numoftrips: '{numoftrips} trips',
    txt_global_std_selectyourcarsbluetooth: 'Select your cars bluetooth',
    txt_global_std_otherinformation: 'Other information',
    txt_global_std_wanttojointhechallenge: 'Want to join the challenge?',
    txt_global_std_whenyouarereadytodriveyoucanconnect: 'When you are ready to drive, you can connect to your car and start your first trip here.',
    txt_global_std_both2: 'Both',
    txt_global_std_MSIG_Onboarding14: 'Your certificate of insurance is attached and password protected with your NRIC/FIN/UEN in lower case.',
    txt_global_std_ilendthecar: 'I lend the car',
    txt_global_std_applanguage: 'App language',
    txt_global_std_searchonname: 'Search on name',
    txt_global_std_set: 'Set',
    txt_global_std_content: 'Content',
    txt_global_std_goback: 'Go back',
    txt_global_std_to: 'To',
    txt_global_std_illegalxrule: 'Illegal word: {word}',
    txt_global_std_agentofferselectronicfundstransferfromyourcheckingaccountorrecurringcreditcardpaymentfromyourcreditdebitcardyoucansignupforthesepaymentplanswhenyoustartyourpolicyoranytimeduringyourpolicytermbyloggingintoweburl: '{agent} offers electronic funds transfer from your checking account or recurring credit card payment from your credit/debit card. You can sign up for these payment plans when you start your policy or any time during your policy term by logging into {webUrl}.',
    txt_global_std_resetviewpassword: 'Reset & View Password',
    txt_global_std_aagentofferselectronicfundstransferfromyourcheckingaccountorrecurringcreditcardpaymentfromyourcreditdebitcardyoucansignupforthesepaymentplanswhenyoustartyourpolicyoranytimeduringyourpolicytermbyloggingintolink: 'A: {agent} offers electronic funds transfer from your checking account or recurring credit card payment from your credit/debit card. You can sign up for these payment plans when you start your policy or any time during your policy term by logging into {link}.',
    txt_global_std_whathappensnow: 'What happens now?',
    txt_global_std_iflocationserviceisoffyourperformancegetworse: 'If Location Service is OFF your performance get worse.',
    txt_global_std_unlocked: '(Unlocked)',
    txt_global_std_seconds: 'Seconds',
    txt_global_std_anemailhasbeensentwithyournewpassword: 'An email has been sent with your new password.',
    txt_global_std_accidentfaq: 'Accident FAQ',
    txt_global_std_timeandlocationistheonlymandatoryfield: 'Time & location is the only mandatory field. Please upload pictures of your car within 24 hours.',
    txt_global_std_riskportfoliotracker_upsell: 'Enerfy Risk Portfolio Tracker – your key to operational excellence.',
    txt_global_std_vouchercode: 'Voucher code',
    txt_global_std_getstartedguide: 'Start guide',
    txt_global_std_OgierChamp: 'Won 6 times the WRC and 1 time the J-WRC',
    txt_global_std_heatsmall: 'Heat',
    txt_global_std_yourmonthwithtrygdrive: 'Your month with TrygDrive!',
    txt_global_std_getspagedlistoftrips: 'Gets paged list of trips',
    txt_global_std_p2phasreceivedyoureditrequest: 'has received your edit request',
    txt_global_std_startingdrive: 'Starting trip...',
    txt_global_std_addyourbrandcolors: 'Add your brand colors ',
    txt_global_std_introductionwelcome: 'Welcome',
    txt_global_std_yourquoteformonthofmonth: 'Your quote for month of {month}',
    txt_global_std_parameters: 'Parameters',
    txt_global_std_openlog: 'Open log',
    txt_global_std_kintofaq6q: 'I cannot connect?',
    txt_global_std_activeachievements: 'Active achievements',
    txt_global_std_dontyouhaveacode: "Don't you have any code?",
    txt_global_std_namemustacceptaninvitationtotheteamfirst: '{name} must accept an invitation to the team first.',
    txt_global_std_allowaccesstobluetooth: 'Allow access to Bluetooth',
    txt_global_std_eco1: 'Eco',
    txt_global_std_p2phasspecifiedaprice: 'has specified a price',
    txt_global_std_share: 'Share',
    txt_global_std_p2pfaq23a: '1. Select "Warikan" from "Menu".\n' +
      '2. Select "Settings" and select the method of change. You can select from "Usage ratio", "Usage time", "Equal division", and "Mileage".',
    txt_global_std_p2pfaq15a: 'This app does not have a function for settling expenses. Therefore, please agree and settle the expenses with the person directly and in accordance with the law.',
    txt_global_std_youmayhavetogotobluetoothsettingforbothyourphoneandcar: 'You may have to go to Bluetooth settings for both your phone and car.',
    txt_global_std_ecofriendweeklylockedkey: 'Drive eco friendly and save more than {co2value}% CO₂ in a week to earn this badge.',
    txt_global_std_thisaccountdoesnothaveapaymentplanaddpaymentplantoaccesspaymentspage: 'This account does not have a payment plan. Add payment plan to access payments page.',
    txt_global_std_getupandrunninginnotimewithourwhitelabelappsolution: 'Get up and running in no time with our white label app solution.',
    txt_global_std_PolicySchedule27: 'SGD3,500.00 SECTION I & II SEPARATELY IS IMPOSED ON THOSE DRIVERS WHO ARE BELOW 22 YEARS OLD AND/OR WHO HAVE LESS THAN 2 YEARS OF DRIVING EXPERIENCE.',
    txt_global_std_yesiamsure: 'Yes I am sure!',
    txt_global_std_youhavebeeninvitedby: 'You have been invited by:',
    txt_global_std_p2pinvoiced: 'Invoiced',
    txt_global_std_redeemfriendscode: 'Redeem friends code',
    txt_global_std_advancedadmin_upsell_pitch_bulletpoint1: 'Information and support to inform your decisions',
    txt_global_std_drivetime: 'Drive time',
    txt_global_std_showalldrives: 'Show all drives',
    txt_global_std_drivesafeandpaylessforyourfiat500subscription: 'Drive safe and pay less for your Fiat 500 subscription.',
    txt_global_std_kintosmsmailtext: 'Start driving safer with me! Follow the link below and start drive with Kinto.',
    txt_global_std_photosofyourcarscurrentcondition: "Photos of your car's current condition can be good to have at hands if something should happen to your car.",
    txt_global_std_logsaved: 'Log saved',
    txt_global_std_createanaccounttostartyour30dayfreetrialnocreditcardrequired: 'Create an account to start your 30 day free trial. No credit card required.',
    txt_global_std_p2pfaq6q: 'How do I leave a group chat?',
    txt_global_std_p2pfaq17a: 'You need to be invited by a Kinto owner to add them as a friend. Please ask your friend to send you an invitation link.',
    txt_global_std_ifyouareinneedofroadsideassistanceplease: 'If you are in need of Roadside Assistance, please ',
    txt_global_std_wouldntitbenicetobeabletosaythatasacompanyyoumakenoticabledifferenceinloweringtheimpactvehiclesanddrivinghaveontheworldstartyourownchallengeandmakethatimpacttoday: "Wouldn't it be nice to be able to say that, as a company, you make noticable difference in lowering the impact vehicles and driving have on the world? Start your own challenge and make that impact - today! ",
    txt_global_std_warikan_onboarding16: 'KINTO Co., Ltd. | 4-8-18 Meieki, Nakamura-ku, Nagoya City Nagoya Mitsui Building North Building 14F',
    txt_global_std_MarklundBesttip: 'First of all, plan your driving and make sure to have enough time, stress is never a smart way of driving. Keep your eyes up front and be alert on what’s going on ahead of you, maybe someone brakes? Then maybe you have time to lift the throttle instead of instant and hard braking? Be patient and adopt your driving style and speed to circumstances such as traffic and weather conditions. I know it’s hard but it makes you a smarter driver.\n',
    txt_global_std_p2pnocarsavailable: 'No cars available',
    txt_global_std_selectyourcarinthelistsompo: '3. Select your car in the list:',
    txt_global_std_passwordchangefailed: 'Password change failed',
    txt_global_std_ihaveanaccount: 'I have an account',
    txt_global_std_notnowlater: 'Not now',
    txt_global_std_yourpasswordshouldcontainatleast5charactersandmustbeenteredcorrectlytwice: 'Your password should contain at least 5 characters and must be entered correctly twice.',
    txt_global_std_rwmnodrivestext: 'You need to connect to your car and drive before you can use this function. Come back to register your trip after you have shared a ride with friends.',
    txt_global_std_gooddriving2: 'Good driving!',
    txt_global_std_p2ptoconfirmbooking: ' to confirm booking ',
    txt_global_std_createcommunicationthatreachesout: 'Create communication that reaches out',
    txt_global_std_signedinasusername: 'Signed in as\n{userName}',
    txt_global_std_connectyourobdreader: 'Connect your OBD-reader to your car.',
    txt_global_std_documentanyscratchesordingsonyourcar: 'Document any scratches or dings on your car.',
    txt_global_std_OgierBesttip: 'Coming soon...',
    txt_global_std_calmdriversoftengetbetterscore: 'Calm drivers often get better score',
    txt_global_std_thepasswordcontainsforbiddencharacters6062orspace: "The password contains forbidden characters: ' & / &#60; &#62; , . = or space",
    txt_global_std_owner2: 'Owner',
    txt_global_std_theapikeynamecreatedcreationdatewillbedeletedimmediatelyandpermanentlyoncedeleteditcannolongerbeusertomakeapirequests: 'The API key {name}, created {creationdate} will be deleted immediately and permanently. Once deleted it can no longer be user to make API requests.',
    txt_global_std_byconnectingtoyourcarwithyoursmartphone: 'By connecting to your car with your smartphone and the Enerfy app, your drive will be analyzed by our AI database, where your drive will be compared with more than 500 million driving profiles which will validate how safe and environmentally friendly you drive.',
    txt_global_std_publishapp: 'Publish app',
    txt_global_std_emergencyroadsideassistance: 'Emergency Roadside Assistance',
    txt_global_std_avaragescoreofparticipants: 'Average score of participants',
    txt_global_std_invite: 'Invite',
    txt_global_std_PolicySchedule18: 'PDP And SLEC',
    txt_global_std_ensammenligningmeddennekreturogetgennemsnitafalleandrebilisterskrselsadfrd: 'Your driving stats for this trip compared to the average driver.',
    txt_global_std_mine: 'Mine',
    txt_global_std_wehavesentanewverificationcodetophoneno: 'We have sent a new verification code to {phoneNo}.',
    txt_global_std_youneedtoacceptallofthepermissionsinoderfortheapp: 'You need to accept all of the permissions in order for the app to work properly',
    txt_global_std_earngreatrewards: '- Earn great rewards!',
    txt_global_std_currentstatsper: 'Current stats per',
    txt_global_std_signupletter: 'You’re on the list!',
    txt_global_std_joinedparticipants: 'Joined participants',
    txt_global_std_all: 'All',
    txt_global_std_ttest96: 'this is a test',
    txt_global_std_earn100pointswhenyoupurchasecdw5timestoyourbooking: 'Earn 100 points when you make 5 reservations and opt-in for CDW. This achievement can be earned more than once.',
    txt_global_std_anticipate: 'Anticipate',
    txt_global_std_p2pyouhavebookingswithoutregistereddistance: 'You have bookings without registered distance.',
    txt_global_std_ifyourecruitafriendbothyouandyourfriendget250kronerinthepiggybank: 'If you recruit a friend, both you and your friend get 250 kroner in the piggy bank.',
    txt_global_std_PolicySchedule16: '2, 25, 57, 72, 89C, E28, E29, E33, E45, E48J, E6,',
    txt_global_std_p2pcancelbooking: 'Cancel booking',
    txt_global_std_upload123: 'Upload',
    txt_global_std_qhowdoisignupforagentinsurance: 'Q: How do I sign up for {agent} insurance? ',
    txt_global_std_country: 'Country',
    txt_global_std_p2pdiscount: 'discount',
    txt_global_std_yourpassword: 'Your password',
    txt_global_std_timeandplace2: 'Time and place',
    txt_global_std_starburtslockedkey: 'Finish a drive at level 4.4 or better to unlock this achievement and earn 300 points to your Kinto Wallet.',
    txt_global_std_MSIG_InsuranceLetter39: 'NIL FOR INSURED AND AUTHORISED DRIVERS',
    txt_global_std_machinedamage123: 'Machine Damage',
    txt_global_std_heresyourauthenticationcode: 'Here’s your authentication code:',
    txt_global_std_editpermissionsfor: 'Edit permissions for',
    txt_global_std_fromthetoprightappsiconyoucannavigatebetweendifferentmoduleswithintheworkspace: 'From the top right apps icon, you can navigate between different modules within the workspace',
    txt_global_std_therulesforthecompanychallengeareasfollowingtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsdriveminimum1kmduringaheatinordertoqualifyforthechallengethesamegeographicalroutewillbescoredamaximumof10timesinoneday: 'The rules for the company challenge are as following:\n' +
      '• The individual driver score is calculated on an average based on the top 10% trips.\n' +
      '• Drive minimum 1 km during a heat in order to qualify for the challenge.\n' +
      '• The same geographical route will be scored a maximum of 10 times in one day.',
    txt_global_std_somethingwhenwrongwithyourpasswordpleasetryanotherpassword: 'Something when wrong with your password. Please try another password.',
    txt_global_std_maintainingintendedspeedisutmostimportantforfueleconomymindwanderingandlackoffocusoftencausesspeedvariationupanddowncausingunnecessaryaccelerationshenceincreasesfuelconsumption: 'Maintaining intended speed is utmost important for fuel economy. Mind wandering and lack of focus often causes speed variation up and down, causing unnecessary accelerations, hence increases fuel consumption.',
    txt_global_std_singleaccident2: 'Single Accident',
    txt_global_std_reportandtrackyourresults: 'Report and track your results ',
    txt_global_std_MarklundInterests: 'Snowmobiling, Nature/wildlife, Family and friends',
    txt_global_std_theactivationcodehastobe36characters: 'The activation code has to be 36 characters',
    'txt_global_std_Al-KhelaifiMileage': 'Umm Bab to Dukhan Highway (Route 39). Because of the elevation changes and the scenery.',
    txt_global_std_p2peditbooking: 'Edit booking',
    txt_global_std_failedtogettranslations: 'Failed to get translations',
    txt_global_std_cost2: 'Cost',
    txt_global_std_youwillbemailedyourpersonaldata: 'You will be mailed your personal data',
    txt_global_std_hideadvancedsettings: 'Hide advanced settings',
    txt_global_std_publishtranslations: 'Publish translations',
    txt_global_std_kilometerkm: 'Kilometer (km)',
    txt_global_std_smartdriverweeklyunlockedkey: 'To earn this badge, keep a score above 3.5 for a week',
    txt_global_std_ttest95: 'this is a test test test',
    txt_global_std_HusseinAbdullaInterests: 'Cars, Rallies, Racing',
    txt_global_std_chataboutabookingorstartagroupchatwithyourinvitedfriends: 'Chat about a booking or start a group chat with your invited friends.',
    txt_global_std_unregistereddriving: 'Unregistered driving',
    txt_global_std_ConfirmFia_Text5: 'Place',
    txt_global_std_february: 'February',
    txt_global_std_OrderConfirmation: 'Order confirmation FIA Smart Driving Challenge',
    txt_global_std_thereisnotworkshopstoshow: 'There is not workshops to show.',
    txt_global_std_PolicySchedule31: 'An excess of $100.00 is applicable for every windscreen claim, after which, the windscreen cover will be automatically reinstated.',
    txt_global_std_noofdrivers: 'No of drivers',
    txt_global_std_jointheglobalcommunityreducingco2emission: 'Join the global community reducing CO2 emission.',
    txt_global_std_accentcolor: 'Accent Color',
    txt_global_std_enteredodometervalue: 'Entered odometer value',
    txt_global_std_minutes: 'Minutes',
    txt_global_std_youralreadyboundeddevices: 'Your already used devices.',
    txt_global_std_permissions: 'Permissions',
    txt_global_CoreTemplate_cost: 'Cost',
    txt_global_std_300pointTripAchievementlocked: 'Finish a drive at {scoretype} {score} or better to unlock this achievement and earn 300 points.',
    txt_global_std_everythingworkedasexpected: 'Everything worked as expected.',
    txt_global_std_about: 'About',
    txt_global_std_yourfriends: 'Your friends',
    txt_global_std_ontheleaderboardpageyoucamseechallengesoverview: 'On the Leaderboard page you can see challenges overview.',
    txt_global_std_thetotalscoremeasuringbothyourrisklevelandecodriving: 'The total score measuring both your risk level and eco driving.',
    txt_global_std_schedulesend: 'Schedule send',
    txt_global_std_typeofaccident: 'Type of accident',
    txt_global_std_upto6000kmmilage: 'Up to 6000 km.',
    txt_global_std_p2ptogetaninvitationlinkfromakintoownerifyouarehavingtroublewiththelinkpleasecontactsupportandwewillhelpyou: 'To borrow a Kinto car you need to get an invitation link from a Kinto owner. If you are having trouble with the link, please contact support and we will help you',
    txt_global_std_decideyourownview: 'Decide your own view',
    txt_global_std_youmustwriteareason: 'You must write a reason',
    txt_global_std_passwordisnotvalid: 'Password is not valid.',
    txt_global_std_imageuploadfailed: 'Could not upload image, please try again',
    txt_global_std_achievementsdesc: 'Engage your users with achievements in your app. Achievements are in-app badges and can be unique or collectibles. ',
    txt_global_std_notification: 'Notification',
    txt_global_std_p2pyouhaveselectedmultipledays: 'You have selected multiple days',
    txt_global_std_silver: 'Silver',
    txt_global_std_speedareawithbest: 'Speed area with best improvement potential',
    txt_global_std_p2pinvitenewfriend: 'Invite new friend',
    txt_global_std_declined: 'Declined',
    txt_global_std_redeemedpoints: 'Redeemed points',
    txt_global_std_usingtheapp: 'Using the app',
    txt_global_std_findworkshop: 'Find workshop',
    txt_global_std_Fia_Receipt_Text7: 'Order ID',
    txt_global_std_kintofaq2a: 'A safe driver is an aware driver. Safe driving is about staying 100% focused on your driving and on your surrounding environment. Stay alert and take traffic rhythm and other circumstance, such as rain etc into account. ALWAYS ADJUST YOUR SPEED TO THE PREVAILING CONDITIONS.',
    txt_global_std_errorloggingintoyouraccountpleasetryagain: 'Error logging in to your account, please try again!',
    txt_global_std_qidriveforaridesharingcompanywillyouinsuremyvehicle: 'Q: I drive for a ridesharing company, will you insure my vehicle?',
    txt_global_std_yourscore: 'Your score',
    txt_global_std_leavewithoutsaving: 'Leave Without Saving',
    txt_global_std_PolicySchedule32: 'Signed for and on behalf of the Company',
    txt_global_std_pushdriveendlevel4: 'Now you are driving risky. Tap here to see what you can improve.',
    txt_global_std_p2pallcars: 'All cars',
    txt_global_std_gold: 'Gold',
    'txt_global_std_Compare with my': 'Compare with my',
    txt_global_std_notdrivingthiscar: 'Not driving this car?',
    txt_global_std_checkhowitwent: 'Check how it went',
    txt_global_std_5discountonrenewal: '5% discount on renewal',
    txt_global_std_fix: 'Fix',
    txt_global_std_cantfindyourcargoto: "Can't find your car? Go to",
    txt_global_std_adduserstogroups: 'Add users to groups',
    txt_global_std_wereyoudriving: 'Were you driving?',
    txt_global_std_viewinvoice: 'View invoice',
    txt_global_std_paidaftertheendoftheinsuranceyear: 'Paid after the end of the insurance year.',
    txt_global_std_forfurtherinstructionspleasechooseyourconnectionmethod: 'For further instructions, please choose your connection method',
    txt_global_std_details: 'Details',
    txt_global_std_notsigneduptryg: 'Not signed up for a Sidekick insurance yet?',
    txt_global_std_p2pyescancelbooking: 'Yes, cancel booking',
    txt_global_std_mmyy: 'MM/YY',
    txt_global_std_orderednewpassword: 'Ordered new password',
    txt_global_std_kintofaq1a: 'No- the app works in background mode! Make sure you always keep bluetooth and location services activated on your phone.',
    txt_global_std_translations: 'Translations',
    txt_global_std_v2stargrade: 'Level',
    txt_global_std_poins: 'Points',
    txt_global_std_usethiskeyinyourapplicationby: 'Use this key in your application by..........',
    txt_global_std_gooddrivingperformance: 'Good driving performance',
    txt_global_std_driveforthefuture: 'Drive for the future.',
    txt_global_std_weareinareyou: 'We are in - are you?',
    txt_global_std_fleetcompanydescription: 'Driver risk management and CO2 impact.',
    txt_global_std_wasthecartowed2: 'Was the car towed?',
    txt_global_std_insuranceusage: 'Insurance usage',
    txt_global_std_yourpolicybeginsonthedateandtimewhenyouelectronicallysigntheapplicationandmakethedownpaymentonyourpolicy: 'Your policy begins on the date and time when you electronically sign the application and make the downpayment on your policy.',
    txt_global_std_areyousureyouwanttoendyourcurrentrun: 'Are you sure you want to end your current trip',
    txt_global_std_ohno: 'Oh no',
    txt_global_std_keeptrackhowmanykilometersyouhavedriven: 'Keep track of how many kilometers you have driven',
    txt_global_std_nextloayaltylevelprogressbar: 'Next loyalty level progress bar',
    txt_global_std_VilliersMusic: 'When I drive, I listen to any good music, I am not too fussy about music, to be honest.',
    txt_global_std_garminwatchconnected: 'Garmin watch connected',
    txt_global_std_drivesafeandecofriendlyearngreatrewards: 'Drive safe and eco friendly\n– Earn great rewards!',
    txt_global_std_forquestionscallagentatphonenumberphoneno: 'For questions call {agent} at phone number {phoneNo}',
    txt_global_std_p2ppushtitlenewexpensetoapprove: 'New expenses to approve',
    txt_global_std_unlockeddescription: 'Unlocked When badge has been received',
    txt_global_std_totalscore2: 'Score',
    txt_global_std_insuranceinformation: 'Insurance information',
    txt_global_std_doihavetoactivatetheappeverytimeidrive: 'Do I have to activate the app every time I drive?',
    txt_global_std_NewPW_Hello: 'Hello',
    txt_global_std_namecannotbetoolong: 'Name can not be too long',
    txt_global_std_recruitafriend: 'Recruit friend',
    txt_global_std_filters: 'filters',
    txt_global_std_yourinvitationwassuccessfullysent: 'Your invitation was successfully sent!',
    txt_global_std_currentlynotopdrivers: 'Currently no top drivers',
    txt_global_std_MSIG_InsuranceLetter3: 'Motor Vehicles (Third-Party Risks and Compensation) Act (Chapter 189)',
    txt_global_std_finishedhead2head: 'Finished head 2 head challenges',
    txt_global_std_warikan_onboarding1: 'KINTO ONE contractor',
    txt_global_std_p2pwecouldnotverifyyourkintonumberpleaselogintoyourkintoaccountandclickonthelinktodownloadthewarikanapp: 'We could not verify your kinto number. Please log in to your Kinto account and click on the link to download the Warikan app',
    txt_global_std_topdriversthishour: 'Top drivers this hour',
    txt_global_std_pushinsurancerenewalodoremtitle: '{appname} renewal',
    txt_global_std_score: 'Score',
    txt_global_std_yougetthiswhenyouhavescoredover4point410times: 'You get this when you have scored over 4.4 10 times',
    txt_global_std_myaccount: 'My account',
    txt_global_std_anemailaddressisrequired: 'An email address is required',
    txt_global_std_howdoigetagoodscore: 'How do I get a good score?',
    txt_global_std_p2pmarkaspaid: 'Mark as paid',
    txt_global_std_SulayemInterests: 'Racing, Motorsports, Classic cars',
    txt_global_std_reportclaim: 'Report claim',
    txt_global_std_thismonth: 'This month',
    txt_global_std_totalamountofaccountscreated: 'Total amount of accounts created',
    txt_global_std_myleaguetext: 'You drive in {league} league. Continue to drive smart and climb in ranking.',
    txt_global_std_atthechallengedashboardyoucanviewyourchallengesandcreatenewonestocompeteindrivingsafeandecofriendly: 'At the Challenge dashboard, you can view your challenges and create new ones to compete in driving safe and eco-friendly.',
    txt_global_std_donthaveanaccountsignupsignuplink: "Don't have an account? \nSign up {signuplink}",
    txt_global_std__login: 'Log in',
    txt_global_std_thisactionwillremoveyouraccount: 'This action will remove your account, personal data. This action cannot be undone!',
    txt_global_std_howdoiearnaloyaltystatusanswer: 'You earn your loyalty status by collecting points! You get points from the different achievements available. Achievements are given to our loyal and safe {appname} drivers! Learn more about it under the “Achievements section”.',
    txt_global_std_MSIG_Password4: 'Sincerely, MS First Capital',
    txt_global_std_translationtoolvisualeditor: 'Translation Tool - visual editor',
    txt_global_std_redeemyourrewards: 'Redeem your rewards',
    txt_global_std_nocode: 'No code? Click here',
    txt_global_std_f_lanzmileage: '200km Nature Trip. Out here in South Africa, we can quickly escape the city and enjoy a scenic Nture Drive. Very open Space and the mind can focus and process , and relax at the same time. ',
    txt_global_std_pushdriveendlevel2: 'Very good drive. If you want to see more info, please tap here.',
    txt_global_std_youredone: "You're done!",
    txt_global_std_numberno: 'No.',
    txt_global_std_VillersDescription: 'Giniel De Villiers has never retired. Even better, only once has he not finished in the Top 10 (in 2007). He has 14 stage victories and in 2009 he won the first Dakar contest in South America.',
    txt_global_std_youneedtoinputavalidemailformat: 'You need to input a valid Email format',
    txt_global_std_JyrkiainenDescription: 'Been growing up surrounded by motorsport in a racing family, daughter of Minna Sillankorva, 1991 Ladies Rally World Champion and nine-time Finnish Champion',
    txt_global_std_youcannotopenthispopupagain: 'You can not open this popup again.',
    txt_global_std_addlicense: 'ADD LICENSE',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint1: 'Rate driving risk based on actual driving locations, not home or workplace',
    txt_global_std_numbercantinclude6062s: `Number can't include "'?&/&#60; &#62;\\s#!%$%^*-`,
    txt_global_std_gointoapp: 'Go into app',
    txt_global_std_movethemarkertoseehowyourlevelaffectsyourdiscount: 'Move the marker to see how your level affects your discount',
    txt_global_std_profile: 'Profile',
    txt_global_std_TidemandMusic: "A well-composed mix of all genres from different times. When I drive it's all about finding the right vibe for the mood, I'm in. ",
    txt_global_std_SignupFia_Text10: 'FIA Smart Driving Challenge is owned and arranged by Fédération Internationale de l’Automobile and Greater Than. Visit',
    txt_global_std_cimage39d: 'Your vehicle rolls longer distances than you believe.',
    txt_global_std_youhavebeeninvited: 'You have been invited!',
    txt_global_std_disconnectallotherdevicesconnectedtotheobd: 'Disconnect all other devices connected to the OBD-reader, it can only be connected to one device at time.',
    txt_global_std_yourdrivewillautomaticallyending: 'Your drive will automatically end',
    txt_global_std_monday: 'Monday',
    txt_global_std_activateyourinsurance: 'Activate your insurance',
    txt_global_std_addyourcurrentmileagevalueinkmtakingapictureofyourodometeroursystemcouldaddthisvalueusingimagerecognition: 'Add your current mileage (value in km) taking a picture of your odometer. Our system could add this value using image recognition!',
    txt_global_std_totalco2savings: 'Total CO2 savings',
    txt_global_std_finalistsnationalchallenges: 'finalists - NATIONAL CHALLENGES',
    txt_global_std_youhavereferred10friendsnice: 'You have referred 10 friends! Nice!',
    txt_global_std_heats: 'Events',
    txt_global_std_addedodometervaluemileage: 'Added odometer value (mileage)',
    txt_global_std_usercannotberemoved: 'User {{email}} cannot be removed.',
    txt_global_std_p2pcarlend: 'Car',
    txt_global_std_rowsperpage: 'Rows per page',
    txt_global_std_100kmunlockedkey: 'You drove a distance of 100 km with the app!',
    txt_global_std_pleaseenteryourpasswordtodeletethischallenge: 'Please enter your password to delete this challenge',
    txt_global_std_distractions: 'Distractions',
    txt_global_std_motorclaimshotline1: 'Motor Claims Hotline',
    txt_global_std_actionsheader: 'Actions',
    txt_global_std_manualstartstopofdrive: 'Manual start/stop of drive',
    txt_global_std_PolicySchedule8: 'Make/Body Type',
    txt_global_std_pioneergetstartedguide: 'Get started guide',
    txt_global_std_therearenocostsyet: 'You have no costs yet.',
    txt_global_std_p2pmessage: 'Message',
    txt_global_std_befocused: 'Be focused',
    txt_global_std_MSIG_InsuranceLetter42: 'SGD3,500.00 SECTION I & II SEPARATELY IS IMPOSED ON THOSE DRIVERS WHO ARE BELOW 22 YEARS OLD AND/OR WHO HAVE LESS THAN 2 YEARS OF DRIVING EXPERIENCE.',
    txt_global_std_policynumber1: 'Policy number',
    txt_global_std_p2pend: 'End:',
    txt_global_std_silverlevel: 'Silver Level',
    txt_global_std_yourrecenttrips: 'Your recent trips',
    txt_global_std_support: 'Support',
    txt_global_std_addyourcurrentmileagevalueinkm: 'Add your current mileage (value in km)',
    txt_global_std_joined: 'Joined',
    txt_global_std_turnoncarengine: 'Turn on car engine',
    txt_global_std_youcanstillusetheapptogetinsightsandstatisticsonyourdriving: 'You can still use the app to get insights and statistics on your driving.',
    txt_global_std_connectyourdevice: '2. Connect your device',
    txt_global_std_youarealreadydrivingwhenyouhavefinished: 'You are already driving. When you have finished your drive you will find your car here.',
    txt_global_std_qdoessompoacceptdriversthatarenotlicensedintheunitedstates: 'Q: Does Sompo accept drivers that are not licensed in the United States?',
    txt_global_std_p2pmemberuserguide: 'Member user guide',
    txt_global_std_diesel: 'Diesel',
    txt_global_std_lokaliteterndvendigforatvurderedinkrselsadfrdogberegnedinkrescore: 'Tryg Drive collects location data to enable driving score even when the app is closed or not in use.',
    txt_global_std_turnonbluetoothinyourphone: 'Turn on Bluetooth in your phone.',
    txt_global_std_MSIG_InsuranceLetter29: 'Issued at Singapore on',
    txt_global_std_january: 'January',
    txt_global_std_warning: 'Warning',
    txt_global_std_youdroveatscoretypescoreorbettergreatyouhaveunlockedthisbadge: 'You drove at {scoretype} {score} or better! Great! You have unlocked this badge. ',
    txt_global_std_drivingsmartmeansusinglessenergyandlessenergymeanslowerco2emissions: 'Driving smart means using less energy, and less energy means lower CO2 emissions.',
    txt_global_std_pigsavedunlocked: 'For every 50 crowns you get this award',
    txt_global_std_qwhenwillmyratechange: 'Q: When will my rate change?',
    txt_global_std_alertmaxspeedtext: 'Max speed alarm triggered.',
    txt_global_std_entertheemailaddressthatyouhaveregisteredonyouragentaccount: 'Enter the email address that you have registered on your {agent} account',
    txt_global_std_oneuniformmeasurementregardlessofvehicletypeandgeography: 'One uniform measurement regardless of vehicle type and geography',
    txt_global_std_august: 'August',
    txt_global_std_cimage25d: 'Investigate what RPM you have at highest gear at 80 km/h. Then try to gear below this RPM at all gears.',
    txt_global_std_ridewithmeunlockedkey: 'Great job! Riding in a car together instead of taking separate cars saves a lot of CO2! You can earn this badge once per week.',
    txt_global_std_p2pbyname: 'By name',
    txt_global_std_obdreader: 'OBD-reader',
    txt_global_std_howdoicompeteinenerfyloyaltychallengeanswer: 'You automatically participate when you sign up. Don’t worry, the leaderboard will only show your nickname- so you will always stay anonymous. There is a monthly and yearly competition.',
    txt_global_all_saturday: 'Saturday',
    txt_global_std_p2pnamewantstoborrowvehicle: '{name} wants to borrow {vehicle}',
    txt_global_std_activatebluetoothsothedevicecanconnectyourphonewithyourcar: 'Activate Bluetooth so the device can connect your phone with your car',
    txt_global_std_BeforeFirstBill_Text4: 'Can I keep track of my costs?',
    txt_global_std_PSolbergMileage: 'Coming soon...',
    txt_global_std_editapikey: 'Edit API Key',
    txt_global_std_p2pfaq4a: 'Press and hold the message you want to delete, and a dialog will appear where you can delete the message.',
    txt_global_std_progressstatus: 'Progress Status',
    txt_global_std_p2ppushtitlerevokeadmin: 'Admin rights revoked',
    txt_global_std_sdcrapply2019price2: '500 kr gasoline checks',
    txt_global_std_preparingyournewenerfyhome: 'Preparing your new {appName} home...',
    txt_global_std_networkoffline: 'Network offline',
    txt_global_std_Alert_WereYouDrivingTitle: 'Were you driving?',
    txt_global_std_loyaltysmsmailtext: 'Start driving safer with me! Follow the link below and start drive with ',
    txt_global_std_invitefriendsgetawards: 'Invite friends. Get awards.',
    txt_global_std_failedtopublishedlanguage: 'Failed to Published Language!',
    txt_global_std_unlocklimitheader: 'Unlock limit',
    txt_global_std_SignupFia_Text8: 'Stay tuned - Follow us on social media',
    txt_global_std_percentagedissconnectedtripsmax25: '{percentageDissconnectedTrips}% disconnected trips (of max 25%)',
    txt_global_std_users: 'Users',
    txt_global_std_orderobd: 'Order OBD',
    txt_global_std_challengesent2: 'Challenge Sent!',
    txt_global_std_verifyyourchanges: 'Verify your changes',
    txt_global_std_belowisyourlocationsoyoucaneasilydescribewhereyouaretotheroadassistantmanager: 'Below is your location, so you can easily describe where you are to the Road Assistant manager.',
    txt_global_std_trafficdelaysdeadlinesandrunninglatetheycanallcausestresswhichisnegativeforyourhealthandsafetyallowingextratimeplanningyourrouteandcheckingtravelupdatescanhelptoreducestressandimproveyourfocus: 'Traffic delays, deadlines, and running late; they can all cause stress, which is negative for your health AND safety. Allowing extra time, planning your route, and checking travel updates can help to reduce stress and improve your focus.',
    txt_global_std_TaxInvoice2: 'Date',
    txt_global_std_current: 'Current:',
    txt_global_std_refer10friendsachievementlocked: 'Refer 10 friends to unlock this achievement and earn 300 points.',
    txt_global_std_removephoto: 'Remove photo',
    txt_global_std_pleaseenteravalidemail: 'Please enter a valid email.',
    txt_global_std_emailhasbeensent: 'Email has been sent!',
    txt_global_std_p2pblockcar: 'Block Car',
    txt_global_std_countryroadfilechangeDESC: 'The blue car driver picks one lane and drive focused. \n' +
      'The red car driver is tailgating the car in front and changes lane stressfully.  ',
    txt_global_std_discountinformation: 'Your average level this month is the basis for your discount. You receive the discount when you renew your insurance for the upcoming months.',
    txt_global_std_business2: 'Business',
    txt_global_std_cimage30d: 'An acceleration followed by a retardation is waste of money.',
    txt_global_std_30daysfreetrialnocreditcardrequired: '30 days free trial. No credit card required.',
    txt_global_std_onceyoupairtheapptoyourvehiclesbluetoothandchangethesettingstoallowlocationserviceswematchyourdrivinghabitstoexistingdriversandprovideascoreinsuranceisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsabouttheagentappandcompeteagainstthemforlowerscores: "Once you pair the app to your vehicle's Bluetooth and change the settings to allow location services, we match your driving habits to existing drivers and provide a score. Insurance is based on who drives your vehicle and how well they drive. Whoever is driving your vehicle is encouraged to download the app and track their scores. Tell your friends about the {agent} app and compete against them for lower scores.",
    txt_global_std_locationservicesnotsettoalwaysallow: 'Location services not set to Always Allow',
    txt_global_std_yourfriendsname: "You friends' name",
    txt_global_std_accepted: 'Accepted',
    txt_global_std_thesaferyoudrivethehigherthesaving: 'The safer your drive - the higher the saving!',
    txt_global_std_clear: 'Clear',
    txt_global_std_achievementcannotbedeletedpopup: 'This achievement cannot be deleted.',
    txt_global_std_alluploadedphotoswillbemarkedwithacheckmark: 'All uploaded photos will be marked with a check mark.',
    txt_global_std_p2paddedbyyou: 'Added by you',
    txt_global_std_partlyavailable: 'Partly available',
    txt_global_std_p2pbookingconfirmed: 'Booking confirmed',
    txt_global_std_avgtimeonroad7d: 'Avg time on road 7D',
    txt_global_std_createyouraccounttosaveyourtripsandearnbonusetc: 'Create your account to save your trips, earn bonus and get access to your personal driving coach. You may create your account at a later time.',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint1: 'Top-down driver risk insights filterable by geography, date, time, vehicle, and more',
    txt_global_std_warikan_onboarding9: '5. Select the date and time of use of the car from the calendar and make a reservation',
    txt_global_std_exampleemail: 'name@domain.com',
    txt_global_std_startguidetip2: 'Make sure Bluetooth and location service are on.',
    txt_global_std_getspecialoffers: 'Get special offers',
    txt_global_std_acceptfreemiumterms: 'Accept terms and policies',
    txt_global_std_MikkelsenQuote: 'It’s an honour to be selected as team-leader in this exciting project! I am competing at the elite-level of motorsport, and if I, as a driver, can inspire others to become smart, safe and environmental-friendly drivers, I’m happy to do that. Join my team today, and let’s drive smarter! ',
    txt_global_std_searchbartext: 'Search by xxx, xxx or xxx ',
    txt_global_std_qhowlongwillittaketoprocessmyclaim: 'Q: How long will it take to process my claim?',
    txt_global_std_PolicySchedule23: 'Premium',
    txt_global_std_insurancecompany: 'Insurance Company',
    txt_global_std_TaxInvoice15: 'Entered by STELLAL',
    txt_global_std_thepasswordhasbeenreset: 'The password has been reset.',
    txt_global_std_youreinvited: "You're invited!",
    txt_global_std_resetpasswordsuccess: 'Success! Check your email for a reset password link.',
    txt_global_std_enerfycoachtip3: '3. Do not rush when driving, even if you are in a hurry. ',
    txt_global_std_whatwillhappenifmyphonelosessignalorifmyphone: 'What will happen if my phone loses signal or if my phone runs out of battery?',
    txt_global_std_bestregardscomma: 'Best regards,',
    txt_global_std_competeindividuallyonly: 'Compete individually only',
    txt_global_std_paid: 'Paid',
    txt_global_std_friendwasremoved: 'Friend was removed',
    txt_global_std_makesureyourengineison: 'Make sure your engine is on',
    txt_global_std_confirmandcreateaccount: 'Confirm and create account',
    txt_global_std_pigsaved: '50 crowns saved',
    txt_global_std_qcanitrytheappbeforesigningup: 'Q: Can I try the app before signing up?',
    txt_global_std_HusseinAbdullaQuote: 'Quote coming soon...',
    txt_global_std_p2ppushtitleborrowcar: '{name} - borrow car?',
    txt_global_std_home: 'Home',
    txt_global_std_youareabouttopublishtranslationspleasemakesureyouhavereviewedalltranslationsbeforeproceeding: 'You are about to publish translations, please make sure you have reviewed all translations before proceeding.',
    txt_global_std_patternprofiling: 'Pattern Profiling',
    txt_global_std_qualifications: 'Qualifications:',
    txt_global_std_p2pfaq32a: '1. At the beginning of the month, a "Warikan" reminder will be automatically displayed on the home screen. Or select "Warikan" from "Menu".\n' +
      '2. Select "Warikan" for the month.\n' +
      '3. From the pull-down, select four split methods and consider how to split the cost. You can choose from "Usage ratio", "Usage time", "Equal sharing", and "Mileage".\n' +
      '4. Next, check the cost to be charged for each member. You can adjust the amount by selecting the pencil mark.\n' +
      '5. Select Charge Expenses to charge members.\n' +
      '6. When you receive the cost from the member, select "Complete payment".',
    txt_global_std_SulayemMusic: 'Smooth Jazz',
    txt_global_std_competeforthesebadges: 'Compete for these badges',
    txt_global_std_youaretheowner: 'We need your current password to ensure that you are the owner of this account.',
    txt_global_std_staytunedonupcomingchallenges: 'Stay tuned on upcoming challenges',
    txt_global_std_statstotal: 'Total',
    txt_global_std_signin: 'Sign in',
    txt_global_std_p2ptypeofexpenses: 'Type of expenses',
    txt_global_std_estimatedcost: 'Estimated cost',
    txt_global_std_automatic_start_and_stop_requires_access_to_bluetooth_location_services_and_motion_fitness: 'Automatic start and stop requires access to bluetooth, location services (GPS) and motion & fitness.',
    txt_global_std_defaultfirsttimeusingtheserviceregisterfromsignuplink: 'First time using the service? Register from {signUpLink}',
    txt_global_std_p2pcalculationswillnotbecorrect: 'Calculations will not be correct.',
    txt_global_std_youcanfileaclaimbyclickingonfileaclaiminyourenerfyapp: 'You can file a claim by clicking on File a Claim in your Enerfy app.',
    txt_global_std_illdothislater: 'I’ll do this later',
    txt_global_std_dataintervalupload: '20 sec',
    txt_global_std_youneedtofillindriversname: 'You need to fill in Drivers name',
    txt_global_std_challengeafriend: 'Challenge a friend!',
    txt_global_std_sorrytheimagerecognitionfailed: 'Sorry, the image recognition failed',
    txt_global_std_emailaddress: 'Email address',
    txt_global_std_p2pmycars: 'My cars',
    txt_global_std_howdoiearnsavings: 'How do I earn savings?',
    txt_global_std_getupdatesregardingnews: 'Get updates regarding news and challenges!',
    txt_global_std_closestworkshop: 'Closest workshop',
    txt_global_std_sidekickprofilepreferences: 'Profile preferences',
    txt_global_std_stardriverunlockedkey: 'You are a star, {user}! You got a score above {score} today!',
    txt_global_std_atthecompanychallengepageyoucancreatenewprivatechallengeswhereyoucancompeteindrivingsafeandecofriendly: 'At the Company Challenge page, you can create new private challenges where you can compete in driving safe and eco-friendly.',
    txt_global_std_therecouldbeotherreasonswhyyourcarsbluetoothyourbluetoothbeacondoesntshowupinthelist: 'There could be other reasons why your\n' +
      "car's Bluetooth/your bluetooth beacon doesn't show up in the\n" +
      'list:',
    txt_global_std_rwmnofriendstitle: 'You don’t have any friends yet',
    txt_global_std_p2ppushtitlecancelledperiodfromto: 'Booking with period {from} has been cancelled.',
    'txt_global_std_sorryiobd2errorcodereadingnotsupportedbywindowsphone.': 'Sorry, iOBD2 error code reading not supported in Windows Phone.',
    txt_global_std_warikan_onboarding3_1: 'なお、仮パスワードの有効期限は72時間となっております。\n' +
      '超過後は、「わりかんKINTO」アプリの「仮パスワードを再送信」より再度メールを受け取っていただき、手続きをお願いいたします。\n' +
      '※本メールは送信専用です。ご返信いただいてもお応えいたしかねますので、お問い合わせは下記へお願いいたします。',
    txt_global_std_english: 'English',
    txt_global_std_pleaseconfirmyourlicenseplate: 'Please confirm your license plate:',
    txt_global_std_aifyourcardoesnthavebluetoothpleasecontactsupportatphonenotoorderanobdreaderthatyouwillneedtoplugintoyourcar: 'A: If your car doesn’t have Bluetooth, please contact support at {phoneNo} to order an OBD reader that you will need to plug in to your car.',
    txt_global_std_ignore: 'Ignore',
    txt_global_std_someviewslookdifferentdependingonifthereisdrivingdataornotyoucanlogintoyouraccountintheapporusethedifferentuserstatestoviewandtranslatetheappwithorwithoutdrivingdata: '* Some views look different depending on if there is driving data or not. You can log in to your account in the app, or use the different user states to view and translate the app with or without driving data.',
    txt_global_std_phonenumberdoesnotexist: 'Phone number does not exist!',
    txt_global_std_themescreendesign: 'Theme / Screen design',
    txt_global_std_my_saving: 'MY SAVING',
    txt_global_std_finalistsheat2: 'Finalists - Heat 2',
    txt_global_std_addusersto: 'Add users to ',
    txt_global_std_yourchallenges: 'Your challenges',
    txt_global_std_gender: 'Gender',
    txt_global_std_youraddress: 'Your address',
    txt_global_std_company: 'Company',
    txt_global_std_MSIG_InsuranceLetter13: 'Insured Estimated Value',
    txt_global_std_reminderinsurance: 'Reminder',
    txt_global_std_bluetoothtroubleshooting: 'Bluetooth trouble shooting',
    txt_global_std_cimage28d: 'Only use cruise control when flat, otherwise keep steady gas.',
    txt_global_std_joinussignupforourcommunityandbecomeapartofus: 'Join us, sign up for our community and become a part of us. Once you have consented you can participate in our competitions or challenge a friend.',
    txt_global_std_p2pwarningexpenses: 'Make sure to add your expenses first. You will not be able to do that later.',
    txt_global_std_youupgradedtovalueplanyouhaveearned10000points: 'You have earned 10,000 points for upgrading to Value Plan.',
    txt_global_std_igetit: 'I get it!',
    txt_global_std_registeryourridesharingtripwithin1dayafterthetriphasended: 'Register your ridesharing trip within 1 day after the trip has ended',
    txt_global_std_darktheme: 'Dark theme',
    txt_global_std_designandsendpushnotificationstoyourteam: 'Design and send messages to your team in no time, or schedule them for the best customer journey.',
    txt_global_std_tipcityparking1DESC: 'The blue car driver uses their turn signals and waits until there is a larger gap between the cars.\n' +
      'The red car leaves the parking slot without using turn signals or waiting for a natural gap in the traffic.',
    txt_global_all_friday: 'Friday',
    txt_global_std_p2prequestanewpassword: 'Request a new password',
    txt_global_std_newpassword: 'New password',
    txt_global_std_seewherepoliciesarewrittenvswheretheyaredriving: 'See where policies are written vs where they are driving',
    txt_global_std_shareyourinvitecode: 'Share your invite code',
    txt_global_std_ridewithme: 'Ride with me',
    txt_global_std_yourfriendemail: 'your-friend@email.com',
    txt_global_std_p2pchataboutabookingorstartagroupchatwithyourinvitedfriends: 'Chat about a booking or start a group chat with your invited friends.',
    txt_global_std_yougetthisachievementafterdrivingafullweekandmaintaininganaveragescoreofover4: 'You get this achievement after driving a full week and maintaining an average score of over 4.0',
    txt_global_std_setupyourcompanychallengeandinviteyourcoworkerstothefiasmartdrivingchallengecompeteagainsteachotherinafunwaytobecomebetterdriverssavemoreco2andimproveyourscore: 'Set up your Company Challenge and invite your co-workers to the FIA Smart Driving Challenge. Compete against each other in a fun way to become better drivers, save more CO2 and improve your score.',
    txt_global_std_howdoestheappwork: 'How does the app work?',
    txt_global_std_activationcodeforname: 'Activation code for {name}',
    txt_global_std_1stdate: '1st',
    txt_global_std_choosebetweenenteringchassinumberplatenumberorpolicynumber: 'Choose between entering chassis number, plate number or policy number.',
    txt_global_std_gotochallenge: 'Go to challenge',
    txt_global_std_p2pfaq12a: 'Yes, if you remove a friend you will not be able to create new bookings with that friend.\n' +
      '1.Go to your friends page from the more menu (three dots)\n' +
      '2.Select your friend to see their profile.\n' +
      '3.Press the settings button.\n' +
      '4.Choose remove friend.',
    txt_global_std_redeemedon: 'Redeemed on',
    txt_global_std_saturday: 'Saturday',
    txt_global_std_bluetooth: 'Bluetooth',
    txt_global_std_yourlatesttripwasbetterthanappnameaverage: 'Great job! Your latest trip was better than the {appname} average! ',
    txt_global_std_p2pyouhavereachedthemaximumamountoffriendspercar: 'You have reached the maximum amount of friends per car',
    txt_global_std_getstartedandgainaccessto: 'Get started and gain access to',
    txt_global_std_createcustom: 'Create custom',
    txt_global_std_loyaltylevel: 'Loyalty level',
    txt_global_std_you: 'You',
    txt_global_std_teamaveragescore: 'Average score of participants',
    txt_global_std_cropimage: 'Crop image',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriends: 'Compete in your everyday driving against your friends. The driver with the best score wins the round!',
    txt_global_std_driverspolicy: 'Drivers policy',
    txt_global_std_pleasemakesurethatyouareconnectedtoyourcarandtryagain: 'Please make sure that you are connected to your car and try again.',
    txt_global_std_deletechallenge: 'Delete challenge',
    txt_global_std_everydayheroeswearegatheringtomakeanimpactwedrivetoreduceourcoemissionsbybeingmoremindfulawareandactiveinthewaywetransportourselvesfromoneadventuretothenextbeittheweekendgetawayortogetthekidsfromsoccerpracticewemaketheworldsaferandbetterhowmuchletsfindout: "Everyday heroes - we are gathering to make an impact. We drive to reduce our CO² emissions by being more mindful, aware and active in the way we transport ourselves from one adventure to the next(be it the weekend get-away or to get the kids from soccer practice). We make the world safer and better. How much? Let's find out!",
    txt_global_std_mysaving: 'MY SAVING',
    txt_global_std_theparameterswerevalidbuttherequestfailed: 'The parameters were valid but the request failed.',
    txt_global_std_automotivecompany: 'Automotive company',
    txt_global_std_p2pfinish: 'Finish',
    txt_global_std_findmycar: 'Find my car',
    txt_global_std_individual: 'Individual',
    txt_global_std_kmleftyourinsuranceisrenewed: 'km left.\nYour insurance is renewed',
    txt_global_std_p2pbookedby: 'Booked by',
    txt_global_std_p2pfaq18a: '1. Select "Connect / Settings" from the menu bar at the bottom of the screen.\n' +
      '2. Allow Bluetooth, location services, motion and fitness settings. For location services, select "Always" for accurate data acquisition.\n' +
      '3. After starting the engine, connect the car and the app via Bluetooth. When the name of the car is displayed on the app, select "Select a car".\n' +
      '4. When you start driving, you will receive real-time feedback on your driving.\n' +
      '5. When the driving is finished, the driving data is automatically saved in the app.',
    txt_global_std_optional2: '(Optional)',
    txt_global_std_atleast8characters: 'At least 8 characters.',
    txt_global_std_uploadimage: 'Upload image',
    txt_global_std_p2ppushtitlenewfriend: 'New friend',
    txt_global_std_p2paddmembers: 'Add members',
    txt_global_std_allowlocationtoparticipateinfiasdc: 'Allow location to participate in FIA SDC',
    txt_global_std_bookingchat: 'Booking Chat',
    txt_global_std_summaryoftimedrivenperselectedperiod: 'Summary of time driven  per selected period.',
    txt_global_std_timeandcanonlybeusedonce: 'time and can only be used once.',
    txt_global_std_PolicySchedule11: 'Chassis No',
    txt_global_std_bad: 'Bad',
    txt_global_std_averagescore: 'Average score',
    txt_global_std_MSIG_InsuranceLetter1: 'CERTIFICATE OF INSURANCE',
    txt_global_std_yourinsurancewasautomaticallyrenewalformonth: 'Your insurance was automatically renewal for {month}.',
    txt_global_std_unitsaved: 'Unit saved',
    txt_global_std_pleasenotethattojoinyoumusthavethefiasdcappinstalledonyourmobiledeviceinordertobeabletojointhegroup: 'Please note that to join you must have the FIA SDC app installed on your mobile device in order to be able to join the team.',
    txt_global_std_p2plendmycar: 'Lend my {carname}',
    txt_global_std_typeofbusiness: 'Type of business',
    txt_global_std_close: 'Close',
    txt_global_std_datetimetogt: 'Date time to GT',
    txt_global_std_risklevelandscoring: 'Risk level and scoring',
    txt_global_std_teammemberbig1: 'TEAM MEMBER',
    txt_global_std_thereaderwillnotbevisibleinyourphonesbluetoothsettings: "The reader will not be visible in your phone's bluetooth settings",
    txt_global_std_kintoloyaltylevels: 'Kinto loyalty levels:',
    txt_global_std_p2pmember: 'Member',
    txt_global_std_presspaynowwhenyouarereadytocheckoutintheservicecenter: 'Press "Pay now" when you are ready to check out in the service center. ',
    txt_global_std_tttest99: 'this is a test',
    txt_global_std_p2phours: 'hours',
    txt_global_std_yourbonus: 'Your bonus',
    txt_global_std_carstatsthismonth: 'Car stats this month',
    txt_global_std_signinwithemail: 'Sign in with Email',
    txt_global_std_p2powner: 'Owner',
    txt_global_std_carroreward: 'Carro reward',
    txt_global_std_pleaseenteravalidphonenumberincludingareacode: 'Please enter a valid phone number including area code',
    txt_global_std_somethinghasgonewrong: 'Something has gone wrong!',
    txt_global_std_Fia_Receipt_Text3: 'Welcome to FIA Smart Driving Challenge, 2021! A SMS is sent to you with your unique activation code, follow the instructions to get started.',
    txt_global_std_completedaconnecteddrive: 'Completed a connected drive',
    txt_global_std_1st: '1:st',
    txt_global_std_requiredformatcolumnastext2: 'Required (format column as text)',
    txt_global_std_OnboardingEmail: 'Welcome to MS First Capital Insurance Ltd!',
    txt_global_std_ourpremierworkshops: 'Our premier workshops',
    txt_global_std_connect: 'Connect',
    txt_global_std_p2pfaq11q: 'How can I invite my friends to Warikan Kinto?',
    txt_global_std_sparet: 'Saved',
    txt_global_std_connecttoobdreader: 'Connect to OBD-reader',
    txt_global_std_drives: 'drives',
    'txt_global_std_timein{state}': 'Time in {state}',
    txt_global_std_bekindtotheenvironment: 'Be kind to the environment.',
    txt_global_std_2minutesagoshorthand: '2m ago',
    txt_global_std_selectthetypeofinjurythatyouwishtoregisterbelowandfollowtheinstructions: 'Select the type of injury that you wish to register below and follow the instructions',
    txt_global_std_earnpointsandachievethenextlevel: 'Earn points and achieve the next level!',
    txt_global_std_avoidhardacceleration: 'Avoid hard acceleration',
    txt_global_std_deleteachivementpopuptext: 'Your achievement will be deleted permanently. Your users progress will be lost. Thoes who have already recieved the achievement will still be able to se it.',
    txt_global_std_notes: 'Notes',
    txt_global_std_head2headchallenge: 'Head 2 Head Challenge',
    txt_global_std_yourrequestedauthenticationcode: 'Your requested authentication code:',
    txt_global_std_leveloverview: 'Level overview',
    'txt_global_std_t brikken': 'Contact information',
    txt_global_std_cropyourbackground: 'Crop your background',
    txt_global_std_yourein: "You're in!",
    'txt_global_std_countryroadcatch-up2DESC': 'The red car driver approaches at high speed and tailgates the car in front. The blue car driver maintains a slow pace to drive focused and smoothly.',
    txt_global_std_howdoyoumeasuremydriving: 'How do you measure my driving?',
    txt_global_std_managegroups: 'Manage groups',
    txt_global_std_unlockedtitle: 'Unlocked',
    txt_global_std_encouragelowriskdrivingviaapotentialsavingspertrip: 'Encourage low risk driving via a potential savings per trip',
    txt_global_std_actiontimestamp: 'Action time',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsaboutthecovercubeappandcompeteagainstthemforlowerscores: "A: Once you pair the app to your vehicle's Bluetooth and allow the app to use location services, we match your driving habits to our AI’s library of driver patterns and provide a score. Your insurance premium is based on who drives your vehicle and how well they drive. Whoever is driving your vehicle is encouraged to download the app and track their scores. Tell your friends about the Covercube app and compete against them for lower scores.",
    txt_global_std_accountremoval: 'Account removal',
    txt_global_std_carsharingemptyviewlent: 'Du har inte lånat någon bil än. Dina körningar kommer sammanställas direkt i appen.',
    txt_global_std_p2pifyouwanttoupdatetheemailusedasusername: 'If you want to update the email used as username,',
    txt_global_std_thismaytakeafewseconds: 'This may take a few seconds.',
    txt_global_std_youhavebeenchallengedby: 'You have been challenged by',
    txt_global_std_smartdriving: 'Smart driving',
    txt_global_std_preparingsomethings: 'Preparing some things...',
    txt_global_std_wecannotfindthecaridentityselectregno: "Select the vehicle's registration number you are driving.",
    txt_global_std_weareherforeyoucontact: 'We are here for you. Contact us!',
    txt_global_std_focustips: 'Keep your eyes on the road and focus completely on driving. Sometimes it is easy to be distracted by surroundings, phones, people in the car, etc. \n' +
      '\n' +
      'Driving tired or stressed can effect the focus and expose you to higher risk. \n' +
      '\n' +
      'We care about you.',
    txt_global_std_gas: 'Gas',
    txt_global_std_qhowcaniimprovemyscore: 'Q: How can I improve my score?',
    txt_global_std_somefacts: 'Some facts',
    txt_global_std_MSIG_InsuranceLetter24: 'Limitations as to use',
    txt_global_std_paymentpalndoesnotexistonthisaccountgotoeventcreatorandcreateaddpaymentplan: 'Payment paln does not exist on this Account. Go to event creator and create add payment plan.',
    txt_global_std_ReminderMail: 'Are you ready for your SDC test drive?',
    txt_global_std_kintoImprovement2: 'Hey - we care about you! Take it easy next time!',
    txt_global_std_yourinsurancehasbeencanceled: 'Your insurance has been canceled',
    txt_global_std_base: 'Base',
    txt_global_std_petrol: 'Petrol',
    txt_global_std_endtour: 'End tour',
    txt_global_std_carregistrationnumber: 'Car registration number',
    txt_global_std_starttime: 'Start time',
    txt_global_std_who: 'Who',
    txt_global_std_rwmcontinue: 'Continue',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedthisbadge: 'You drove really well! Your average {scoretype} was better than {score} for a week! You have earned this badge!',
    txt_global_std_2faenabled: 'Two-factor enabled',
    txt_global_std_p2pborrowacar: 'Borrow a {carname}',
    txt_global_std_youalwaysstarttheinsuranceyearwithnok1000inthepiggybankyouwillbepaidtheseifyouhavebeenasafedriverthroughouttheinsuranceyearifyouchoosetoterminatetheinsurancebeforetheendoftheyearacalculationwillbemadeonthebasisoftheperiodyouhadtheinsurance: 'You always start the insurance year with NOK 1000 in the piggy bank. You will be paid these if you have been a safe driver throughout the insurance year. If you choose to terminate the insurance before the end of the year, a calculation will be made on the basis of the period you had the insurance.',
    txt_global_std_jpfaqanswer: '1. Select “Connect” from the menu bar at the bottom of the screen.\n' +
      '2. Allow Bluetooth, location service, and motion fitness setting. For location services, select “Always” for accurate data acquisition.\n' +
      '3. After starting the engine, connect the car and the app via Bluetooth. When the name of the car is displayed on the app, select “Select a car”.\n' +
      '4. When you start driving, you will receive real-time feedback on your driving.\n' +
      '5. When you finish driving, the driving data is automatically saved in the app.',
    txt_global_std_manydriversrelytooheavilyoncruisecontrolitiscomfortableyesbutlethalforfueleconomyinmanycasesusingcruisecontrolontheslightestuphillordownhillwillcausetheenginetofighthardtosatisfyyourwishofmaintainingtheexactspeedyousetinsteadmanagingyourspeedwithyourfootandaimingforasmoothridewillhelpyousaveahugeamountonfuelnew: 'Many drivers rely too heavily on cruise control. It is comfortable, yes, but lethal for fuel economy in many cases. Using cruise control on the slightest uphill or downhill will cause the engine to fight hard to satisfy your wish of maintaining the exact speed you set. Instead, managing your speed with your foot and aiming for a smooth ride will help you save a huge amount on fuel!',
    txt_global_std_leaderboardisempty: 'Leaderboard is empty.',
    txt_global_std_tttest78: 'test\nasd',
    txt_global_std_pleasecompletethesesteps: 'Please complete these steps',
    txt_global_std_rwmsheettitle: 'Invite a friend',
    txt_global_std_dontforgettocheckfeedback: "Don't forget to check out your feedback to start improving your driving.",
    txt_global_std_youwillbenotifiedintheappwhenitstimetorenewyourinsuranceifyoudonthaveautomaticrenewalsetupfollowthestepsintheapptoensureyouhavecoveragefornextmonth: 'You will be notified in the app when it’s time to renew your insurance. If you don’t have automatic renewal set up, follow the steps in the app to ensure you have coverage for next month.',
    txt_global_std_keepgooddistancebetweencars: 'Keep good distance between cars',
    txt_global_std_unit: 'Unit',
    txt_global_std_JyrkiainenBesttip: 'Be patient and proactive. Follow surroundings and traffic. It’s also good to check the fuel consumption. ',
    txt_global_std_personaldatapreferences: 'Personal data preferences',
    txt_global_std_p2pwecouldnotfindanybookingchatswhenyoucreateabookingyouwillbeabletochataboutthatbookinghere: 'We could not find any booking chats. When you create a booking you will be able to chat about that booking here.',
    txt_global_std_adressline1: 'Adress line 1',
    txt_global_std_PaddonWhyjoin: "In our team you will win! I'm a very competitive person and will do all I can to work with all our team mates to ensure we put our best foot forward. While this will be a great competition it’s important not to forget the important messages, this challenge carries on. We're in to strive for being safer and more complete drivers, something we all can share and inspire many more people to be.",
    txt_global_std_downloadtheappnameapptodrivemycar: 'Download the {appname} app to drive my car',
    txt_global_std_p2pwecouldnotfindyou: 'We could not find you',
    txt_global_std_enablemotionfitnesstogetsupportforstartingandendingyourtripsautomatically: 'Enable motion & fitness to get support for starting and ending your trips automatically.',
    txt_global_std_pushinsurancerenewalodorembody: 'Your insurance period expires soon\n' +
      'Remember to take a picture of your odometer before the insurance period is over.',
    txt_global_std_p2ppushtitleaddadmin: 'Admin rights added',
    txt_global_std_p2ppushbodyexpensesapproved: '{name} approved your added expenses.',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint2: 'Incorporate location risk into insurance pricing/ safety program ',
    txt_global_std_iamapassenger: 'I am a passenger',
    txt_global_std_ongoing: 'Ongoing',
    txt_global_std_kintoEncouragement3: 'Great job! Your latest trip was better than the Kinto average! ',
    txt_global_std_areyousureyouwanttoredeempoints: 'Are you sure you want to redeem points?',
    txt_global_std_pwdfiveandtwice: 'Your password must be at least five (5) characters, and must be written twice!',
    txt_global_std_selectdamagereport: 'Select Damage Report',
    txt_global_std_autojoinchallenges: 'Auto join challenges',
    txt_global_std_noreviewsselectedtobepublished: 'No reviews selected to be published!',
    txt_global_std_namesmayonlyincludelettersandspaces: 'Names may only include letters, -and spaces.',
    txt_global_std_kintofaq2q: 'How do I drive well?',
    txt_global_std_p2pyesdelete: 'Yes, delete',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimedecreasingspeedleavingasafefollowingdistanceenablesyoutodecreaseyourspeedsteadilyandsmoothly: 'On this type of trip, the average eco champion spent more time decreasing speed. Leaving a safe following distance enables you to decrease your speed steadily and smoothly.',
    txt_global_std_ecotips: 'Your eco score measures how eco friendly you drive. In a lot of ways it can be connected to your planning.\n' +
      '\n' +
      'Adjust your accelerations to avoid unnecessary brakes and stops. Every acceleration and brake consume energy.\n' +
      '\n' +
      'Together we can make a difference for the future.',
    txt_global_std_nicestreak: 'Nice streak!',
    txt_global_std_finalizepersonalinformation: 'Finalize personal information',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint2: 'Reinforce good behaviors through gamification ',
    txt_global_std_downloadthefiasdcappbyclickingthelinkonyourmobilephone: 'Download the FIA SDC app by clicking the link on your mobile phone',
    txt_global_std_presstoloadmore: 'Press to load more',
    txt_global_std_alertweredrivingyoudrivingtitle: 'Were you driving?',
    txt_global_std_awardedbadges: 'Awarded badges',
    txt_global_std_hereyouloweryoursubscriptioncostbydrivingsafe: 'Here you lower your subscription cost by driving safe.',
    txt_global_std_activateinsurance: 'Activate insurance',
    txt_global_std_settingupprofile: 'Setting up profile',
    txt_global_std_completeinfoandcreateaccount: 'Complete info and create account',
    txt_global_std_kintofaq1q: 'Do I have to activate the app every time I drive?',
    txt_global_std_yourmessagehasbeensent: 'Your message has been sent!',
    txt_global_std_choosingbalwaysallowbisnecessarytogetcorrectstatsaboutyourdriving: 'Choosing <b>Always Allow</b> is necessary to get correct stats about your driving.',
    txt_global_std_ayesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforagentinsurance: 'A: Yes, you can download the app and choose the option "try the app". You will be able to drive with the app and see how much you could save if you signed up for {agent} insurance.',
    txt_global_std_servicedandsoldby: 'Serviced and sold by',
    txt_global_std_p2pwhenyougiveyourfriendadminrightstheycanviewandhandlebookingsofyourcar: 'When you give your friend admin rights they can view and handle bookings of your car.',
    txt_global_std_mydrivingstats: 'My car stats',
    'txt_global_std_Click the plus sign to create a new challenge.': 'Click the plus sign to create a new challenge.',
    txt_global_std_alreadyhaveacovercubepolicy: 'Already have a Covercube policy?',
    txt_global_std_TidemandWhyjoin: 'Because together we are unbeatable and always aim for the top! ',
    txt_global_std_PaddonMileage: 'I love driving on the back-country roads of New Zealand and exploring new places. The west coast of the South Island is a very special place, as you feel so isolated at the same time drive on amazing twisty scenic roads.',
    txt_global_std_WelcomeLetter_Text5: 'Sincerely,',
    txt_global_std_kintofaq8a: 'Savings are generated when you drive safe. For every trip you drive safe, you will generate a saving per kilometre. The safer you drive, the higher the saving! The saving is visible directly after your trip is finished and will be visible in your Kinto wallet.',
    txt_global_std_accident: 'Accident',
    txt_global_std_enerfycoachtip2: '2. Hold great distance to the car in front, even at low speed.',
    txt_global_std_learnmoreaboutpermissions: 'Learn more about permissions',
    txt_global_std_rwmyoucanonlyregistertripsmadewithinthelastday: 'You can only register trips made within the last day',
    txt_global_std_nooftrips: 'No of trips',
    txt_global_std_p2pongoing: 'Ongoing',
    txt_global_std_p2pbooking: 'Booking',
    txt_global_std_setuplocationservices: 'Set up location services',
    txt_global_std_cancel: 'Cancel',
    txt_global_std_VilliersChamp: '4 times SA Touring car champion, 7 Dakar podiums, 1 Dakar win, 3 times Marocco rally winner, 2008 won the Portugal Rally, 2008 winner Dos Sertoes rally Brazil.',
    txt_global_std_p2psafedriverdiscount: 'Safedriver discount',
    txt_global_std_setupcomplete: 'Setup complete!',
    txt_global_std_enablemotionfitness: 'Enable Motion & Fitness in your phone.',
    txt_global_std_gettingtheappready: 'Getting the app ready',
    txt_global_std_TomczykMusic: 'What I listen to when driving depends on my mood.  I like hardrock a lot, like Dio and Manowar, but I also listen to current music charts.',
    txt_global_std_youhavealreadyorderedanobdreader: 'You have already ordered an OBD reader',
    txt_global_std_loyaltylevelrewards: 'This loyalty level entiteles you to a {reward}',
    txt_global_std_theenerfyteam: 'The Enerfy Team',
    txt_global_std_invitetherestofyourteam: 'Invite the rest of your team',
    txt_global_std_teams: 'Teams',
    txt_global_std_joinchallenge: 'Join challenge',
    txt_global_std_coach: 'Coach',
    txt_global_std_appcreator_upsell_pitch: 'Build and customize your own driving app, with no coding needed, to to;',
    txt_global_std_c02overview: 'C02 overview',
    txt_global_std_youhavenowdriven25tripswithtrygdrive: 'You have now driven 25 trips with Tryg Drive',
    txt_global_std_thankyou: 'Thank you!',
    txt_global_std_gotoyourpreferredservicecenterandshowthemthispopup: 'Go to your preferred service center and show them this popup.',
    txt_global_std_mystats: 'My stats this year',
    txt_global_std_p2pamountshouldbeshownonthereceipt: '*Amount should be shown on the receipt.',
    txt_global_std_p2pviewexpenses: 'View expenses',
    txt_global_std_cimage11d: 'Always turn off engine at  standstill',
    txt_global_std_p2pemailbestregards: 'Best regards, <br> the {appname} team',
    txt_global_std_p2ppleaseuploadareceiptpicture: 'Please upload a receipt picture',
    txt_global_std_wecannotfindthecaridentityenteractivationcode: 'We cannot find the car identity, enter the activation code you have in the welcome mail.',
    txt_global_std_onboardingnotificationsheader: 'Notifications',
    txt_global_std_takethefootofthepedalandletthecarcoastasmuchasitmakesensesavesfuel: 'Take the foot of the pedal and let the car coast as much as it make sense, saves fuel.',
    txt_global_std_sonicethatyoudecidedtojoinusletssetupyourprofile: 'How nice that you decided to join us! Let us set up your profile.',
    txt_global_std_mystatstext: 'My stats',
    txt_global_std_successfullyupdatedtranslation: 'Successfully Updated translation',
    txt_global_std_nice: 'Nice!',
    txt_global_std_origin: 'Origin',
    txt_global_std_p2pemailnewsavailableinappcarSharing: 'News available in {appname} - Car Sharing',
    txt_global_std_inviteusers: 'Invite users',
    txt_global_std_choosethetypeofbusinessthatdescribesyourcompanythebest: 'Choose the type of business that describes your company the best.',
    txt_global_std_polish: 'Polish',
    txt_global_std_startdrive: 'Start trip',
    txt_global_std_icannotconnect: 'I cannot connect?',
    txt_global_std_gameonyourchallengehasbeenaccepted: 'Game on! Your challenge has been accepted! ',
    txt_global_std_earnsavingsintoyourkintowalletforsafedriving: 'Earn savings into your Kinto wallet - for safe driving:',
    txt_global_std_p2pmaximum9999999allowedforanexpense: 'Maximum {currency} 9,999,999 allowed for an expense',
    txt_global_std_100kmlockedkey: 'Drive a distance of 100 km to earn this badge.',
    txt_global_std_challenge: 'Challenge',
    txt_global_std_dragme: 'Drag me!',
    txt_global_std_namesmayonlyincludeletters1: 'Names may only include letters.',
    txt_global_std_Fia_Receipt_Text17: 'Greater Than, Karlavägen 60, 114 49 Stockholm Sweden. Organisation number: 556608-3258',
    txt_global_std_PolicySchedule25: 'Total Due',
    txt_global_std_p2padditionalexpense: 'Additional expense',
    txt_global_std_preferredrequirementaccuracy: 'Preferred requirement accuracy',
    txt_global_std_signupandsavemoneyonyourinsurance: 'Sign up and save money on your insurance',
    txt_global_std_search: 'Search',
    txt_global_std_p2pfaqcontactinfo2: '・ Please note that it may be difficult to connect when it is crowded.',
    txt_global_std_emailhasbeensent2: 'Email has been sent!',
    txt_global_std_communication_upsell_pitch: 'Easily design your own messaging and communication schedule to; ',
    txt_global_std_endday: 'End day',
    txt_global_std_pointstounlock: 'points to unlock.',
    txt_global_std_ifyouhaveaninsuranceyoucanfindyourpolicynumberinyourappnameaccountdonthaveitathandrightnownoworriesyoucanadditlaterinsettings: 'If you have an insurance you can find your policy number in your {appname} account. \n' +
      'Don’t have it at hand right now? No worries! You can add it later in settings.',
    txt_global_std_allmonth: 'All month',
    txt_global_std_ChicheritChamp: 'Coming soon...',
    txt_global_std_gettheapp: 'Get the app',
    txt_global_std_everymonth: 'Every month',
    txt_global_std_youaredriving: 'You are driving',
    txt_global_std_secondarycolor: 'Secondary Color',
    txt_global_std_cimage11h: 'TURN OF ENGINE',
    txt_global_std_latest10: 'Latest 10 ',
    txt_global_std_p2papprove: 'Approve',
    txt_global_std_yourdatais: 'Your data is =',
    txt_global_std_numberofrepetitions: 'Number of repetitions',
    txt_global_std_warikan_onboarding2_2: 'Thank you very much for your application for "Warikan KINTO" app.\n' +
      'Please use the following temporary password to create an account in the app.',
    txt_global_all_sunday: 'Sunday',
    txt_global_std_authenticating: 'Authenticating',
    txt_global_std_HusseinAbdullaChamp: '2016 won FIA T2 World Champion',
    txt_global_std_nodataavailable: 'No data available',
    txt_global_std_signinginwiththirdpartyname: 'Signing in with {thirdPartyName}...',
    txt_global_std_fiaquarterfinalqualifyrule2020: 'You need to participate in at least 4 heats to be qualified for the Qualification Final.',
    txt_global_std_RemoveAccount_text1: 'Hello\n' +
      'We have received your desire to delete your information in your account. You should be aware that deleted data can not be recovered. Your account and all data about you will be deleted, including driving records, teams, race dates and competitions.\n' +
      '\n' +
      'If you are an insurance customer, your information will not be deleted until 1 year after you have completed your insurance and all cases are closed. Deleting your account does not end your insurance.\n' +
      ' \n' +
      'Remove account by clicking on below link:\n' +
      '{{removelink}}\n' +
      ' \n' +
      'Do you have any questions please contact us at info@enerfy.se or 08-555 932 00',
    txt_global_std_cardwithmoreinformationaboutgdpr: 'Card with more information about GDPR',
    txt_global_std_bluetoothsystem: 'Bluetooth system',
    txt_global_std_nophotosadded: 'No photos added',
    txt_global_std_SignupFia_Text7: 'Download the app!',
    txt_global_std_fiasdcsmartscore: 'FIA SDC smart score',
    txt_global_std_Insurance_Chat: 'Chat',
    txt_global_std_youcanaddtranslationbyclickinghere: 'You can add translation by clicking here.',
    'txt_global_std_de-villiersDescription': 'Giniel De Villiers has never retired. Even better, only once has he not finished in the Top 10 (in 2007). He has 14 stage victories and in 2009 he won the first Dakar contest in South America.',
    txt_global_std_digitaltraining: 'Digital training',
    txt_global_std_valuedisconnctedtripsofmaxmaxvalue: '{value}% disconncted trips (of max {maxvalue}%)',
    txt_global_std_energysaved: 'Energy saved',
    txt_global_std_reactivateyourinsurancetokeepyourcoverage: 'Reactivate your insurance to keep your coverage.',
    txt_global_std_theoreticaltimesaving: 'Theoretical time saving',
    txt_global_std_splits: 'Splits',
    txt_global_std_MSIG_Onboarding10: 'Do you have any questions? Contact our support team at 0123456789, or email us at info@msfc.com.sg.',
    txt_global_std_incorrectpassword: 'Incorrect password',
    txt_global_std_youhavebeeninvitedtojoin: 'You have been invited to join:',
    txt_global_std_finishingyourscore: 'Finishing your score..',
    txt_global_std_requestssent: 'Request sent',
    txt_global_std_companychallengename2: 'Company challenge name',
    txt_global_std_pleaseseethefinishedreport: 'Please see the finished report at',
    txt_global_std_creationdate: 'Creation date',
    txt_global_std_p2pfaq10a: 'Please visit https://kinto-jp.com/insurance/ for more information.',
    txt_global_std_policyholder: 'Policyholder',
    txt_global_std_rwmnoeligibletripstitle: 'We could not find any trips within the last day.',
    txt_global_std_myscore: 'My score',
    txt_global_std_riskindex_upsell_pitch: 'Turn knowledge into action with this sophisticated tool combining news, data and risk analysis  to identify;',
    txt_global_std_shootingstarlockedkey: 'Finish a drive at level 3.3 or better to unlock this achievement and earn 100 points to your Kinto Wallet. ',
    txt_global_std_p2papply: 'Apply',
    txt_global_std_termsofservice: 'Terms of Service & Privacy Policy',
    txt_global_std_youneedtoaddatleast8characters: 'You need to add at least 8 characters. Including 1 lowercase(a-z), 1 uppercase(A-Z) and 1 number(0-9).',
    txt_global_std_reallynicerun: 'Really nice run',
    txt_global_std_changingyouremaildoesnotchangeyourusername: 'Changing your email does not change your username',
    txt_global_std_influencer: 'Influencer',
    txt_global_std_agreementcreated: 'Agreement created',
    txt_global_std_orderobddeviceto: 'Order OBD device to:',
    txt_global_std_p2pfaq19q: 'There are sections where driving data is not recorded.',
    txt_global_std_letsdrivewithwarikankintopushbody: "Let's drive with Warikan KINTO and collect badges and points! Points will be able to exchange with gifts! Scoring function and badge acquisitions are available even if you are not KINTO ONE user.",
    txt_global_std_analytics: 'Analytics',
    txt_global_std_p2pforgotwhereyouparkedyourcarnoworrieswegotyoucoveredjustpressthisbuttonandwewillfinditforyou: "Forgot where you parked your car? No worries, we've got you covered. Just press this button and we will find it for you!",
    txt_global_std_kintofaq5a: 'Inform the Kinto team via email. Then re-connect to your new car by following the “Connect to Car” steps again.',
    txt_global_std_annualcarinsuranceprice: 'Annual car insurance price',
    txt_global_std_p2paddedmemberstothegroup: 'added members to the group',
    txt_global_std_acceptlocationservices: 'Accept Location Services',
    txt_global_std_youcancontactyourinsuranceagency: 'You can contact your insurance agency.',
    txt_global_std_savechangespopuptext: 'Your unsaved changes will be lost. Save changes before closing?',
    txt_global_std_change: 'Change',
    txt_global_std_whatwillhappenifidonotconnectmycar: 'What will happen if I do not connect my car?',
    txt_global_std_SendMyPosition_Text2: 'Sincerely,\r\n{appName}',
    txt_global_std_tomasengequote: 'If you want to reach your goals you must do everything for it',
    txt_global_std_ayourscoreisbasedonthedrivingpatternsyoucreatewitheachtripconcentrateonyourdrivingskillstoimproveyourscoreswewillprovideahistoryofallyourtripssoyoucanseehowyouredoing: "A: Your score is based on the driving patterns you create with each trip. Concentrate on your driving skills to improve your scores. We will provide a history of all your trips so you can see how you're doing.",
    txt_global_std_ifyouwanttolendyourcartoafriendyoushouldinviteyourfriendtotheappbytappingthebuttonbelowthisensuresyourcarisstilltrackedbyloyaltysystem: 'If you want to lend your car to a friend you should invite your friend to the app by tapping the button below.\n' +
      'This ensures your car is still tracked by loyalty system.',
    txt_global_std_acceptconsent: 'Accept Consent',
    txt_global_std_bycontinuingyouaccept: 'By continuing you accept our',
    txt_global_std_5000pointaverageachievementlocked: 'Drive with an average {scoretype) of {score} or better for a week to unlock this achievement and earn 5000 points.',
    txt_global_std_kintoquestions: 'KINTO Questions:',
    txt_global_std_co2besparelse: 'Co2 reduced',
    txt_global_std_wewillnotbeabletoanalyzeyourdriveand: 'We will not be able to analyze your drive and you will be billed at premium level 10.',
    txt_global_std_apitool_upsell_pitch_bulletpoint2: 'Fast and secure transfer of driver data ',
    txt_global_std_yourdatahasbeensent2: 'Your data has been sent, please check your inbox!',
    txt_global_std_HansenMusic: 'Listening to music while driving can take away a lot of focus. But getting out on highways and cruising, I truly enjoy listen to music that makes me engage and sing along.',
    txt_global_std_insureddistance_used_100__2: 'Ut! Öka årlig körsträcka här.',
    txt_global_std_uploadpicturestooldaccidentreport: 'Please upload pictures of your car within 24 hours of your report. You can click on your old report below to add pictures to it.',
    txt_global_std_edituser: 'Edit user',
    txt_global_std_opendocument: 'Open document',
    txt_global_std_qdoihavetoactivatetheappeverytimeidrive: 'Q: Do I have to activate the app every time I drive?',
    txt_global_std_orderobdreader: 'Order OBD reader',
    txt_global_std_friday: 'Friday',
    txt_global_std_otherdocuments: 'Other documents',
    txt_global_std_insuranceisrenewedplaceholder: 'You only have 63 km left.\nYour insurance is renewed 2021-10-29',
    txt_global_std_manageapikeys: 'Manage API keys',
    txt_global_std_zipcode2: 'Zip code',
    txt_global_std_minutesandcanonlybeusedonce: 'minutes and can only be used once.',
    txt_global_std_couldnotconnect1: 'Could not connect!',
    txt_global_std_brakevca: 'Brake V/C/A',
    txt_global_std_insurancestatus: 'Insurance status',
    txt_global_std_takecareoftheinjured: 'Take care of the injured',
    txt_global_std_deactivateuserfrom: 'Deactivate user from:',
    txt_global_std_jointaveragescore: 'Joint average score',
    txt_global_std_duration: 'Duration',
    txt_global_std_tryglocationservice: 'Allow access to location - Select always',
    txt_global_std_p2ppleaseselectpaymentmethod: 'Please select payment method',
    txt_global_std_hellothisisanonboardingtext: 'Hello this is an onboarding text.',
    txt_global_std_manualstartstoprequiresaccess: 'Manual start and stop requires access to location services (GPS) and motion & fitness.',
    txt_global_std_yournumber: 'Your number',
    txt_global_std_startcarengine: "1. Start your car's engine.",
    txt_global_std_yourfriendsemail: "Your friends' email",
    txt_global_std_theamountisenteredinthepiggybankwhentherecruitmentcodehasbeenregisteredyouhaveafulltimeoverviewofyourrecruitsonthesavingsside: 'The amount is entered in the piggy bank when the recruitment code has been registered. You have a full-time overview of your recruits on the savings side.',
    txt_global_std_p2pyouhavenocar: 'You have no car',
    txt_global_std_gohome: 'Go home',
    txt_global_std_connectingtocar: 'Connecting to car',
    txt_global_std_beonestepahead: 'Be one step ahead',
    txt_global_std_skillup: 'Skill-up',
    txt_global_std_TidemandChamp: 'WRC 2 Champion 2017, Touring Car Champion 2010',
    txt_global_std_cimage1h: 'REDUCE YOUR MAX SPEED',
    txt_global_std_MSIG_Password2: 'You have requested a new password for your MS First Capital-account. Your new password to the user',
    txt_global_std_pleasenotethatifyouhavealargerproportionofdisconnectedtripsyoumaynotbepaidadrivingdividendremembertodrivewiththeappconnected: 'Please note that if you have a larger proportion of disconnected trips, you may not be paid a driving dividend. Remember to drive with the app connected.',
    txt_global_std_2pairyourphonewithyourcarviabluetooth: '2. Pair your phone with your car via Bluetooth.',
    txt_global_std_cimage14h: 'USE DOWN HILL',
    txt_global_std_TidemandMileage: "I prefer smaller roads with twists and turns surrounded by beautiful scenery compared to highways. It's more fun to drive and you never know what you might find after the next corner. Sweden or anywhere else in the world doesn't matter, discovering new roads is always fun.",
    txt_global_std_p2psplityourcostfor: 'Split your cost for {month}',
    txt_global_std_category: 'Category',
    txt_global_std_cantfindyourchallenge: 'Can’t find your challenge?',
    txt_global_std_OSolbergWhyjoin: 'Coming soon...',
    txt_global_std_inspiringsmarterdrivinghelpsreducefueluseco2footprintandcrashes: 'Inspiring smarter driving helps reduce fuel use, CO2 footprint and crashes',
    txt_global_std_HusseinAbdullaMusic: 'Calm music',
    'txt_global_std_Al-KhelaifiBesttip': 'Commuting home after work.',
    txt_global_std_loading: 'Loading...',
    txt_global_std_p2pnogoback: 'No, go back',
    txt_global_std_nov: 'Nov',
    txt_global_std_drivenotregistered: 'Drive not registered',
    txt_global_std_exploretheapp: 'Explore the app',
    txt_global_std_ridewithmesubtitle: 'Earn 300 points for ride sharing!',
    txt_global_std_login1: 'Login',
    txt_global_std_successfullycreatedgroup: 'Successfully created group',
    txt_global_std_carinformation: 'Car Information',
    txt_global_std_removealldatafromthisaccount: 'Remove all data from this account?',
    txt_global_std_september: 'September',
    txt_global_std_TomczykWhyjoin: "My goal is not just the obviously aim to win. It's also the fun part of joining in and fighting during the heats to become qualified as the best and smartest team.",
    txt_global_std_ratedrivingriskbasedonactualdrivinglocationsnothomeorworkplace: 'Rate driving risk based on actual driving locations, not home or workplace.',
    txt_global_std_june: 'June',
    txt_global_std_weliketorewardyousoeverytripyoudrivesafeyouwillearn: 'We like to reward you – so every trip you drive safe you will earn a saving. The safer you drive, the more you save! The savings are immediately collected here in your wallet.',
    txt_global_std_invitecoworkersandstartcompetingagainsteachothertobecomebetterdriversandsavemoreco2: 'Invite co-workers and start competing against each other to become better drivers and save more CO2.',
    txt_global_std_PSolbergInterests: 'Coming soon...',
    txt_global_std_tirerotationunload: 'Tire rotation, unload …',
    txt_global_std_p2ptounlockyouraccesstotheappandthebenefitsregisteratthewebsite: 'To unlock your access to the app and the benefits, register at the [Website]',
    txt_global_std_whatmusicdoyouliketolistentowhenyoudrive: 'What music to you like to listen to when you drive?',
    txt_global_std_yourinsurancewasautomaticallyrenewedformonthwithadiscountonyourpremium: 'Your insurance was automatically renewed for {month} with a discount on your premium.',
    txt_global_std_thisisatestphraseforonboarding: 'This is a test phrase for onboarding.',
    txt_global_std_cimage29d: 'When road and traffic allows. Gear in two steps.',
    txt_global_std_issomeoneelsegoingtodriveyourcar2: 'Is someone else going to drive your car? Send them a link so that they can use the app. ',
    txt_global_std_reportaccidentheresmile: 'Report accidents here! :-)',
    txt_global_std_locationaccess: 'Location Access',
    txt_global_std_p2ppushtitlebookingcomingup: 'Booking coming up',
    txt_global_std_whatifichangecarlexusanswer: 'Inform the Lexus team via email. Then re-connect to your new car by following the “Connect to Car” steps again.',
    txt_global_std_p2pyoucanfindcarsrharingin: 'You can find Car Sharing in the menu (three dots) below.',
    txt_global_std_registerridesharingtrip: 'Register ride-sharing trip',
    txt_global_std_optional: 'Optional',
    txt_global_std_yourinsurancewasautomaticallyrenewedformonth: 'Your insurance was automatically renewed for {month}.',
    txt_global_std_qhowdoisetupautomaticrenewal: 'Q: How do I set up automatic renewal?',
    txt_global_std_exampleofvalidemailaddresses: 'Example of valid email addresses:',
    txt_global_std_willbedeletedimmediatelyandpermanentlyoncedeleteditcannolongerbeusertomakeapirequests: 'will be deleted immediately and permanently. Once deleted it can no longer be user to make API requests.',
    txt_global_std_drivewellchallenge: 'Drive Well Challenge',
    txt_global_std_gotoaservicecentertoredeemyourrewards: 'Go to a service center to redeem your rewards. ',
    txt_global_std_removeuserfromgroup: 'Remove user from group',
    txt_global_std_p2pto: 'to',
    txt_global_std_customemail: 'Custom Email',
    txt_global_std_p2ppending: 'Pending',
    txt_global_std_youraveragescoreis: 'Your average level is ',
    txt_global_std_wehavetoaccesslocationservicesinthebackgroundwhile: 'We have to access Location services in the background while you focus on driving. When this popup shows up, please select "Always allow".',
    txt_global_std_MSIG_InsuranceLetter28: 'I/We HEREBY CERTIFY that the Policy to which this Certificate relates is issued in accordance with the provisions of the Motor Vehicles (Third-Party Risks and Compensation) Act (Chapter 189) and Part IV of the Road Transport Act, 1987 (Malaysia)',
    txt_global_std_avgrisklevel123: 'Avg. risk level',
    txt_global_std_avgscore: 'Avg. score',
    txt_global_std_p2pfaq29q: 'Please tell me how to borrow a car',
    txt_global_std_deepdiveintodrivercrash: 'Deep dive into driver crash probability, carried risk and more stats',
    txt_global_std_contactdetailsfromyouraccountpleaseeditdetailsifneeded: 'Contact details from your account, please edit details if needed.',
    txt_global_std_thischallengeactiveandupcomingchallenges: 'This challenge, active and upcoming challenges',
    txt_global_std_p2pfaq29a: '1. Select "Rent a car" from the home screen.\n' +
      '2. Select the owner of the car you want to rent and select the car.\n' +
      '3. Select the date and time.\n' +
      '4. Enter your message (optional) and finally select "Send Request".\n' +
      '5. The booking request will be held until the member approves or rejects it.',
    txt_global_std_last10drives: 'Last 10 trips',
    txt_global_std_nousersfoundinviteuserstogetstarted: 'No users found. Invite users to get started.',
    txt_global_std_edit: 'Edit',
    txt_global_std_statusnotconnected: 'Not connected',
    txt_global_std_reasonforpoints: 'Reason for points',
    txt_global_std_appquestions: 'APP Questions:',
    txt_global_std_connecting: 'Connecting',
    txt_global_std_back1: 'Back',
    txt_global_std_max30characters: 'Max 30 characters',
    txt_global_std_fileisattached: 'File is attached',
    txt_global_std_yourpwwasnotchanged: 'Your password was not changed. Try another new password or write a valid current password!',
    txt_global_std_badges: 'Badges',
    txt_global_std_p2pnotincluded: 'Not included',
    txt_global_std_p2pdistancetravellednotrecommended: 'Distance travelled not recommended.',
    txt_global_std_addpolicynumber: 'Add policy number',
    txt_global_std_entervalidmessage: 'Enter a text to your friend',
    txt_global_std_visibleinmaps: 'Visible in maps',
    txt_global_std_challengename: 'Challenge name',
    txt_global_std_MSIG_InsuranceLetter22: "2) Any other person who is driving on the Insured's order or with his permission.",
    txt_global_std_areyousureyouwanttodeletethismessage: 'Are you sure you want to delete this message?',
    txt_global_std_takemetotheappinstead: 'Take me to the app instead',
    txt_global_std_cantfindanybluetoothdevice: "Can't find any Bluetooth device.",
    txt_global_std_youareoffline: 'You are offline',
    txt_global_std_co2savingsscore: 'CO₂ Savings',
    txt_global_std_p2pfaq31q: 'Where can I check the driving data?',
    txt_global_std_improvementareaforspeed: 'Improvement area for speed',
    txt_global_std_createyouraccount: 'Create your account',
    txt_global_std_anonymoususer: 'Anonymous user',
    txt_global_std_pointslefttoreach: 'points left to reach',
    txt_global_std_thistrip: 'This trip',
    txt_global_std_weeklyachievements: 'Weekly achievements',
    txt_global_std_duedate: 'Due date',
    txt_global_std_options: 'Options',
    txt_global_std_consentleaderboard: 'Consent to our competitions & contact',
    txt_global_std_MSIG_InsuranceLetter32: 'Authorised Signature',
    txt_global_std_namehasinvitedyoutojointheirgroup: '{name} has invited you to join their {group}',
    txt_global_std_35000pts: '&#62;35 000 pts',
    txt_global_std_totaldistancekm: 'Total distance (km)',
    txt_global_std_mydrives: 'My drives',
    txt_global_std_comment: 'Comment',
    txt_global_std_pluginthedevice: 'Plug in the device',
    txt_global_std_thecontactinformationyousubmithereiswhatwewillusetocontactyouregardingtheappandsendyoutheappsdownloadlink: 'The contact information you submit here is what we will use to contact you regarding the app and send you the app’s download link.',
    txt_global_std_enteryearlyprice: 'Enter yearly price',
    txt_global_std_welcometo: 'Welcome to',
    txt_global_std_repeatthechallengedailyweeklymonthlyorannually: 'Repeat the challenge daily, weekly, monthly or annually.',
    txt_global_std_totaltime: 'Total time',
    txt_global_std_downloadreport: 'Download report',
    txt_global_std_referafriend: 'Refer a friend',
    txt_global_std_p2pnoconfirmedbookings: 'No confirmed bookings to show.',
    txt_global_std_p2pyourshareofcost: 'Your share of cost',
    txt_global_std_p2ppushbodycancelledperiodfromto: 'Please be informed that booking {from} has been cancelled. Another booking has been generated and accepted that overlaps with this unaccepted booking.',
    txt_global_std_welcometoenerfy: 'Welcome to a new driving experience.',
    txt_global_std_enerfyanalyticsriskportfoliotrackerapp: 'Enerfy Analytics Risk Portfolio Tracker, App',
    txt_global_std_locationservicemustbeontodrivewithdimmedscreen: 'Location Service must be ON to drive with dimmed screen otherwise you will lose data.',
    txt_global_std_weekly: 'Weekly',
    txt_global_std_exporttranslations: 'Export Translations',
    txt_global_std_pleasereachouttothesupportifyouseethesameerrorwhenyoutrynexttimelater: 'Please reach out to the support if you see the same error when you try next time later.',
    txt_global_std_tojointeam: 'To join team:',
    txt_global_std_cimage15h: 'FOCUS ON DETAILS',
    txt_global_std_numbersonly: 'Numbers only',
    txt_global_std_youmustchooseareason: 'You must choose a reason',
    txt_global_std_theoreticaltimegain: 'Theoretical time gain',
    txt_global_std_TaxInvoice5: 'KINDLY MAKE CHEQUE PAYABLE TO MS FIRST CAPITAL INSURANCE LTD',
    txt_global_std_why: 'Why',
    txt_global_std_p2pyourexpenses: 'Your expenses',
    txt_global_std_japanese: 'Japanese',
    txt_global_std_recruiterunlockedkey: 'Great, {user}! You invited a friend to the app!',
    txt_global_std_downloadingevents: 'Downloading events',
    txt_global_std_drivenotstarted: 'Drive not started',
    txt_global_std_sendon: 'Send on:',
    txt_global_std_updatecarddetails: 'Update card details',
    txt_global_std_policyholderinformation: 'Policy holder information',
    txt_global_std_settingupclientcredentials: 'Setting up client credentials',
    txt_global_std_howmanypeoplewereinjured: 'How many people were injured?',
    txt_global_std_writefriendspromocode: 'Write friends Promo Code',
    txt_global_std_uploadfiles: 'Upload files',
    txt_global_std_p2prequestsenttoname: 'Request sent to {name}',
    txt_global_std_constantspeed: 'Constant speed',
    txt_global_std_noteam: 'No team',
    txt_global_std_wehavesentatemporarypasswordtoyouremailaddress: 'We have sent a temporary password to your email address.',
    txt_global_std_p2pallopenexpenses: 'All open expenses',
    txt_global_std_analyzeyourdrivinghabitstoimprove: 'Analyze your driving habits to improve your driving skills.',
    txt_global_std_cimage32d: ' ',
    txt_global_std_howcanwehelpyou: 'How can we help you?',
    txt_global_std_chassinumber: 'Chassi number',
    txt_global_std_insurancedocuments: 'Insurance documents',
    txt_global_std_p2pacceptedbooking: 'Accepted booking',
    txt_global_std_yourchallangeranking: 'Your challenge ranking',
    txt_global_std_dec: 'Dec',
    txt_global_std_enerfyanalytics: 'Enerfy Analytics',
    txt_global_std_addusers: 'Add users',
    txt_global_std_p2pparking: 'Parking',
    txt_global_std_pointsexpires: 'Points expires',
    txt_global_std_yourbonuswillbepaidoutinafewdays: 'Your bonus will be paid out in a few days',
    txt_global_std_avg: 'Avg.',
    txt_global_std_underwrittenby: 'Underwritten by',
    txt_global_std_p2ppushtitleexpensesthismonthquestion: 'Expenses this month?',
    txt_global_std_goheretocreateabookingofyourfriendscar: 'Go here to create a booking of your friend’s car.',
    txt_global_std_cimage35d: 'For example, start with a higher gear if starting downhill.',
    txt_global_std_tomasengewhyjoin: "I love to be the best behind the steering wheel and I'm sure everyone who join me has the same goal.",
    txt_global_std_youhavenotfinishedanychallengesyet: 'You have not finished any challenges yet.',
    txt_global_std_jul: 'jul',
    txt_global_std_p2pfilter: 'Filter',
    txt_global_std_youremail: 'Your e-mail',
    txt_global_std_hi123: 'Hi',
    txt_global_std_kintofaq8q: 'How do I earn savings?',
    txt_global_std_civilistatusmarriedcohabitingsingle: 'Civil status (married, cohabiting, single)',
    txt_global_std_whenyoudrivesafelyandwithgoodflowweputmoneyinyourpiggybankyouwillbepaidthesewhentheinsuranceyearisovertogetherwithmoneyforkilometersyoumaynothaveusedthetotalamountincludesstartbonusmoneysavedforsafedrivingandmoneyforfriendrecruitment: 'When you drive safely and with good flow, we put money in your piggy bank. You will be paid these when the insurance year is over, together with money for kilometers you may not have used. The total amount includes start bonus, money saved for safe driving and money for friend recruitment.',
    txt_global_std_p2p1booking: '(1 booking)',
    txt_global_std_p2ppushbodylendcar: '{name} has sent you a lend requeset',
    txt_global_std_damagereport: 'Damage report',
    txt_global_std_areyousureyouwanttoenablethecommunication: 'Are you sure you want to enable the communication?',
    txt_global_std_nowitstimetoconnecttoyourcarsbluetooth: "Now it's time to connect to your car's bluetooth.",
    txt_global_std_thisisneededbeforeyoustartyourfirstdrive: 'This is needed before you start your first drive.',
    txt_global_std_getstartedinnotimewithourtutorials: 'Get started in no time with our tutorials!',
    txt_global_std_p2punblockcar: 'Unblock car',
    txt_global_std_zipdriverriskindexinsuranceratingcomponent: 'Zip-Driver risk index - Insurance rating component',
    txt_global_std_topimprovers: 'Top improvers',
    txt_global_std_establishingbluetoothlink: 'Establishing bluetooth link',
    txt_global_std_drivewithanaveragescoretypeofscoreorbetterforaweektounlockthisachievementandearnpointspoints: 'Drive with an average {scoretype} of {score} or better for a week to unlock this achievement and earn {points} points.',
    txt_global_std_sharelink: 'Share link',
    txt_global_std_warikan_onboarding6: '2. Launch the Warikan KINTO app and select "Sign up"\n',
    txt_global_std_insurancerenew: 'You only have {km} km left. Your insurance is renewed {date}.',
    txt_global_std_p2ppartlyavailable: 'Partly available',
    txt_global_std_loadmore: 'Press to load more',
    txt_global_std_MikkelsenDescription: "Andreas has a broad background in sports. He has competed in alpine skiing and was a member of the national juniors' alpine skiing team. In 2003 and 2004, he competed in motocross, also representing the national juniors' team. He finished third in the world rally championship in 2014, 2015 and 2016, collecting three wins and 20 podiums.",
    txt_global_std_safedriverdiscountpercent: 'Safedriver discount {percent}%',
    txt_global_std_iunderstand: 'I understand',
    txt_global_std_MSIG_InsuranceLetter36: 'Claims & Motor Underwriting Dept:',
    txt_global_std_referfriend: 'Refer a friend.',
    txt_global_std_p2presendcode: 'Resend code',
    txt_global_std_p2pbecomefriendstoviewthisprofile: 'Become friends to view this profile',
    txt_global_std_p2pthisemailadressisusedforustosendcommunication: 'This email adress is used for us to send communication, for example if you forget your password.',
    txt_global_std_no: 'No',
    txt_global_std_p2pmytripscomingsoon: 'My trips – Coming soon',
    txt_global_std_pigwonunlocked: 'Congratulations! You have won a competition in head2head',
    txt_global_std_registrationnumber2: 'Registration number',
    txt_global_std_notyetdecided: 'Not yet decided',
    txt_global_std_driversidentificationnumber: "Driver's Identification Number",
    txt_global_std_insuranceidcard: 'Insurance ID card',
    txt_global_std_repeatchallenge: 'Repeat challenge?',
    txt_global_std_rewardandendorsesmartbehavior: 'Reward and endorse smart behavior',
    txt_global_std_KleinschmidtFacts: 'She is the first and so far the only woman who has ever taken outright victory in the longest and toughest rally in the world, the Dakar Rally. Her racing career started with motorcycle. After 4 rallies on motorbike she changed to 4 wheels in 1995, and became involved in the development of the most successful rally car, the Mitsubishi Pajero Evo, in which she won the Dakar in 2001. Beside her job as a speaker, she has published her book “Mein Sieg bei der Dakar” – My win in Dakar.',
    txt_global_std_MSIG_InsuranceLetter10: 'Vehicle No / Chassis No',
    txt_global_std_qwhathappensififorgettorenewbeforemyinsurancehasexpired: 'Q: What happens if I forget to renew before my insurance has expired?',
    txt_global_std_community: 'Community',
    txt_global_std_estimateddiscount: 'Estimated discount',
    txt_global_std_score2: 'Score',
    txt_global_std_addprice: 'Add price',
    txt_global_std_p2phowmuchdoyouwanttocharge: 'How much do you want to charge?',
    txt_global_std_p2pkintonumber: 'Kinto number',
    txt_global_std_p2pfaq7a: '1.Go to the booking summary for that booking.\n' +
      '2.Press the cancel booking button on your top left.',
    txt_global_std_kintofaq5q: 'What if I change car?',
    txt_global_std_Fia_Receipt_Text1: 'Welcome!',
    txt_global_std_postalcode: 'Postal code',
    txt_global_std_weareinthischallengetomaketheworldsaferandbettertogetherjoinustohelpmakeanimpact: 'We are in this challenge to make the world safer and better - together. Join us to help make an impact!',
    txt_global_std_anotheappworksinbackgroundmodemakesureyoualwayskeepbluetoothandlocationservicesactivatedonyourphone: 'A: No, the app works in background mode. Make sure you always keep Bluetooth and location services activated on your phone.',
    txt_global_std_p2pstatus: 'Status',
    txt_global_std_p2pshareofmonthlyfee: 'Share of monthly fee',
    txt_global_std_rwmselectthetripsyouwereridesharing: 'Select the trips you were ride sharing',
    txt_global_std_ecoscore123: 'Eco score',
    txt_global_std_p2pgreatyourcostsplitwassenttoyourfriends: 'Great! Your cost split was sent to your friends.',
    txt_global_std_activationletter_text14: 'Follow the instructions in the app.',
    txt_global_std_advancedadmin_upsell_pitch_bulletpoint3: 'Documents, policies, and other intelligent management tools  all in one place',
    txt_global_std_youarebeingredirectedtodashboard: 'You are being redirected to dashboard.',
    txt_global_std_sportscorescore: 'Sport score {score}',
    txt_global_std_yourlatestscore: 'Your latest score:',
    txt_global_std_MSIG_InsuranceLetter11: 'Name of Insured',
    txt_global_std_codewasnotfound: 'Code was not found',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimeincreasingspeedreadingtheroadaheadcanhelpyoudeterminewhetheritissuitabletobuildspeed: 'On this type of trip, the average eco champion spent less time increasing speed. Reading the road ahead can help you determine whether it is suitable to build speed.',
    txt_global_std_p2pbluetoothconnectionrequired: 'Bluetooth connection required',
    txt_global_std_topteamdrivers: 'Top team drivers',
    txt_global_std_mycar: 'My car',
    txt_global_std_weeks: 'Weeks',
    txt_global_std_cimage13d: 'Let your speed decrease go down slightly going up hill.',
    txt_global_std_km: 'km',
    txt_global_std_p2ptoyotawalletcomingsoon: 'Toyota Wallet (coming soon)',
    txt_global_std_weresettingupyourchallenge: "We're setting up your challenge",
    txt_global_std_greatrun: 'Great run',
    txt_global_std_welcometocovercube: 'Welcome to Covercube!',
    txt_global_std_sidekickhelpsyouwiththemotivationandtipstodrivebettersaferandmoreecofriendly: 'Sidekick helps you with the motivation and tips to drive better, safer and more eco-friendly.',
    txt_global_std_bysigningupiacceptthetermsandconditionsandprivacypolicy: 'By signing up, I accept the Terms and Conditions \nand Privacy Policy.',
    txt_global_std_p2ppushbodynewmessage: 'first line of new message',
    txt_global_std_JyrkiainenMusic: 'Pop and rap music.',
    txt_global_std_p2ptimes: 'times',
    txt_global_std_totalvalid: 'Total valid',
    txt_global_std_2pairyourphonewithyourcarsbluetoothsystem: '2. Pair your phone with your car’s Bluetooth system.',
    txt_global_std_schedulemessage: 'Schedule message',
    txt_global_std_activatebluetooth: 'Activate Bluetooth',
    txt_global_std_p2paddyourexpensesbeforethelastdayofthemonthtogetthemincludedinthenextcostsplit: 'Add your expenses before the last day of the month to get them included in the next cost split.',
    txt_global_std_p2pcurioustoknowhowyoudrivekeeptrackofyourprogressandsestatsaboutyourscoreco2savingsdistanceandmore: 'Curious to know how you drive? Keep track of your progress and see stats about your score, CO2 savings, distance and more.',
    txt_global_std_rwmsendinvitelink: 'Send invite link',
    txt_global_std_p2pdocumentmycarcomingsoon: 'Document my car – Coming soon',
    txt_global_std_p2pmessagedeleted: 'Message deleted!',
    txt_global_std_clickthroughtheappintheframeandfindthetranslationyouwanttoeditorfindtheminthelistontherightside: 'Click through the app in the frame and find the translation you want to edit, or find them in the list on the right side.',
    txt_global_std_allset: 'All set!',
    txt_global_std_p2pwaitingforacceptance: 'Waiting for acceptance.',
    txt_global_std_createaccounttext: 'Create account',
    txt_global_std_costsplit: 'Cost split',
    txt_global_std_p2pcancelledthebooking: 'cancelled the booking.',
    txt_global_std_help: 'Help',
    txt_global_std_youcannowclosethiswindoworreturntothedashboard: 'You can now close this window or return to the dashboard.',
    txt_global_std_getshareabledomainlink: 'Get shareable domain link',
    txt_global_std_lowrisk: 'Low risk',
    txt_global_std_tttest98: 'this is a test 98',
    txt_global_std_p2pcostsplitisbasedonkilometersdriven: 'Cost split is based on kilometers driven.',
    txt_global_std_pleasechooseareason: 'Please choose a reason',
    txt_global_std_selectedreader: 'Selected reader',
    txt_global_std_domainrestrictedinvitelink: 'Domain restricted invite link',
    txt_global_std_challenge2: 'CHALLENGE',
    txt_global_std_startcarengineandpleasemakesurebluetoothoncarsideisenabledifthisisyourfirsttimeconnectingyourphonetocarpleaseconnectbluetoothfromcarside: 'Start car engine, and please make sure Bluetooth on car side is enabled.\n' +
      'If this is your first time connecting your phone to car, please connect Bluetooth from car side.',
    txt_global_std_10discountonrenewal: '10% discount on renewal',
    txt_global_std_treessaved: 'Trees saved',
    txt_global_std_JyrkiainenQuote: 'FIA Smart Driving Challenge is a great way to show that environmental questions also matter in motorsport. Compete against your friends and drive smart!',
    txt_global_std_couldnotvalidateyourcode: 'Could not validate your code! Please make sure that you entered the code correctly, and that the code is valid, and try again!',
    txt_global_std_preview1: 'Preview',
    txt_global_std_tojoingroup: 'To join team:',
    'txt_global_std_costofspeedabove{speed}': 'cost of speed above {speed}',
    txt_global_std__entertheemailthatyouusedwhenyouregisteredyourinsurance: 'Enter the email that you used when you registered your insurance.',
    txt_global_std_above1000streakunlocked: 'You drove above a score of 1000, 5 times. Fantastic work!',
    txt_global_std_apitool_upsell: 'API Tool  – the gateway for developers into data integration.',
    txt_global_std_choosenumberbetween1and9: 'Choose a number between 1 and 9',
    txt_global_std_importtranslations: 'Import Translations',
    txt_global_std_renewyourinsuranceformonthandgetadiscountonyourpremium: 'Renew your insurance for {month} and get a discount on your premium',
    txt_global_std_compareyourtripresultstothebestecodriverswecallthemecochampions: 'Compare your trip results to the best eco-drivers. We call them eco champions.',
    txt_global_std_signup1: 'Sign up',
    txt_global_std_notvalidchassinumber: 'Not valid chassinumber',
    txt_global_std_describepurpose: 'Describe purpose',
    txt_global_std_latesttripdate: 'Latest trip: {date}',
    txt_global_std_insurancecustomer: 'Insurance customer',
    txt_global_std_connecttoanothercar: 'Connect to another car',
    txt_global_std_KleinschmidtMusic: 'When driving I prefer to listen to the radio and to information.',
    txt_global_std_reviewchanges: 'Review changes',
    txt_global_std_p2puserexpenses: '{name} expenses',
    txt_global_std_partnercode: 'Partner code',
    txt_global_std_bluetoothsettings: 'Bluetooth settings',
    txt_global_std_p2pincluded: 'Included',
    txt_global_std_p2pstarttime: 'Start time:',
    txt_global_std_rwminviteanewfriend: 'Invite a new friend',
    txt_global_std_calculateyourco2savings: 'Calculate your CO2 savings',
    txt_global_std_currentbonusperiod: 'Current bonus period:',
    txt_global_std_200km: '200 Km',
    txt_global_std_youreceivedarenewalofferformonth: 'You received a renewal offer for {month}.',
    txt_global_std_activationcodestartdate: 'Activation code start date',
    txt_global_std_previous: 'Previous',
    txt_global_std_uploadphotosofthedamagesorscratchesonyourcar: 'Upload photos of the damages or scratches on your car',
    txt_global_std_yourcarmodelistoooldanddoesnthavebluetooth: "• Your car model is too old and doesn't have Bluetooth.",
    txt_global_std_contributesustainabilitythroughwarikanbody: "Why don't you enjoy driving while being aware of eco-friendly driving? Warikan KINTO is here to help! Scoring function and badge acquisitions are available even if you are not KINTO ONE user",
    txt_global_std_cityconditions: 'City conditions',
    txt_global_std_cimage18d: 'Planned average speed saves more time than high max speed',
    txt_global_std_1startyourcar: '1. Start your car.',
    txt_global_std_accessdeniedyoudonothavepermissiontoaccesstheworkspacepleasecontactyouradministratorifincorrect1: 'Access denied. You do not have permission to access the workspace. Please contact your administrator if incorrect.',
    txt_global_std_yourchangesaresaved: 'Your changes are saved!',
    txt_global_std_challengeleaderboard_upsell: 'Challenge Dashboard – incentivize safe driving with custom challenges.',
    txt_global_std_distancekm1: 'Distance (km)',
    txt_global_std_p2pthispasswordhasexpired: 'This password has expired',
    txt_global_std_p2psendinvoices: 'Send invoices',
    txt_global_std_HansenFacts: 'Kevin Hansen, the youngest ever FIA European Supercar champion and 2016 FIA Rookie of the year',
    txt_global_std_drivers2: 'Drivers',
    txt_global_std_p2pfaq26a: '1. Select "Reserve" from the home screen.\n' +
      '2. Select "Lend a car".\n' +
      '3. Select the member you want to rent a car for.\n' +
      '4. Select the date and time.\n' +
      '5. Enter your message (optional) and finally select "Send Request".\n' +
      '6. The loan request will be put on hold until the member approves or rejects it.',
    txt_global_std_doyouwanttoresetthepasswordforuser: 'Do you want to reset the password for ',
    txt_global_std_2makesureyourphoneispairedtoyourcarviabluetooth: '2. Make sure your phone is paired to your car via Bluetooth.',
    txt_global_std_carsharingemptyviewborrowed: 'Lend out the car and it will be a bit more fun!',
    txt_global_std_summaryoftripsperslectecdperiod: 'Summary of trips per slectecd period.',
    txt_global_std_newreport: 'New report',
    txt_global_std_cardwithmoreinformationaboutpermissions: 'Card with more information about permissions',
    txt_global_std_p2pbookingof: ' booking of  ',
    txt_global_std_MSIG_Onboarding7: '1. Click on the link you received by text.',
    txt_global_std_qhowdoestheappwork: 'Q: How does the app work?',
    txt_global_std_kintoloyaltysystemdesc: 'Please note that your perks are based on the final results of the previous loyalty period. Points are earned by getting achievements and are reset at the beginning of a new period.',
    txt_global_std_p2pyourfriendinvitedyoutoborrowtheircar: 'Your friend invited you to borrow their car.',
    txt_global_std_place: 'Place',
    txt_global_std_inorderforustoshowyouvariousstatisticsitisimportantthatthephoneissettouselocationalways: 'In order for us to show you various statistics, it is important that the phone is set to use location "always"',
    txt_global_std_gotosettingsonyourcarscreenandpairthebluetoothwithyourphone: 'Go to settings on your car screen and pair the bluetooth with your phone',
    txt_global_std_cimage6h: 'AVOID UNECCESARY OVERTAKING',
    txt_global_std_youhavenotenabledyourprivacyundersettings: 'You have not enabled your "privacy" under Settings. When "privacy" is not enabled, we can not share all the exciting competitions and tips that are provided in the app with you. Then activate "privacy" and join our community already today.',
    txt_global_std_downloadcsvtemplate: 'Download CSV template',
    txt_global_std_countryroadroundabout2DESC: 'The blue car driver adjusts their speed to go through the roundabout smoothly.\n' +
      "The red car driver doesn't adjust their speed to enter the roundabout smoothly.",
    txt_global_std_cimage12d: ' ',
    txt_global_std_pleaseenteravalidcellphonenumber: 'Please enter a valid cell phone number',
    txt_global_std_sendorder: 'Send order',
    txt_global_std_brokenitems: 'Broken Items?',
    txt_global_std_selectatemplatedesc: 'Use this template as a badge in your app. Customize it in the next step.',
    txt_global_std_sendlink: 'Send link',
    txt_global_std_leavewithoutchanges: 'Leave without changes',
    txt_global_std_toestablishaconnectiontoyourcarappnameneedstoaccesstobluetooth: 'To establish a connection to your car {appname} needs to access to bluetooth.',
    txt_global_std_p2pleavethisgroup: 'Leave this group',
    txt_global_std_PolicySchedule14: 'Named Drivers',
    txt_global_std_p2pbookingnotifications: 'Booking notifications',
    txt_global_std_bestdriversoftheday: 'Best drivers of the day',
    txt_global_std_customtabtext: 'Custom achievements',
    txt_global_std_MarklundWhyjoin: 'We’ll make a difference towards the better only by changing our driving style. In my team we’ll focus on making difference, with the aim to win and on the way there, we’ll have fun. I like competitions and I like winning, but I also like to see my future kids grow up in a safe and healthy place with me there. That’s what’s most important. \n',
    txt_global_std_thepersonyouinvitegetaccessyoalldrivesandcost: 'The person you invite, get access to all your trips and cost.',
    txt_global_std_drivewithanaverageofscoreorbetterforaweektounlockthisachievementandearnpointspoints: 'Drive with an average of {score} or better for a week to unlock this achievement and earn {points} points.',
    txt_global_std_advancedadmin_upsell_pitch: 'Easily navigate your own digital customer success journey for; ',
    txt_global_std_answer: 'Answer',
    txt_global_std_thursday: 'Thursday',
    txt_global_std_cimage40h: 'EVERY ADDITIONAL METER ROLLED IMPROVES RESULT',
    txt_global_std_simplyprovideyourpersonaldetailssowecansetupyouradminaccountnextyoullbeabletostartaddingteammemberstojoinyourfiasdccompanychallenge: "Simply provide your personal details so we can set up your admin account. Next, you'll be able to start adding team members to join your FIA SDC Company Challenge!",
    txt_global_std_datetimedistance: 'Date, time, distance',
    txt_global_std_identifyfollowupandreportonsafetyandsustainabilitytargets: 'Identify, follow up and report on safety and sustainability targets',
    txt_global_std_sharecertificate: 'Share certificate',
    txt_global_std_coporateoffice: 'Coporate Office',
    txt_global_std_p2pemailstartingsoon: 'Starting soon',
    txt_global_std_p2pfaq27q: 'What is administrator privileges?',
    txt_global_std_firstdrive: 'First drive',
    txt_global_std_acaddyourbrandcolors: 'Add your brand colors',
    txt_global_std_improvementpotential: 'Inprovement potential',
    txt_global_std_thepasswordcontainsforbiddencharactersorspace: "The password contains forbidden characters: ' & / &#60; &#62; or space",
    txt_global_std_typeofachievement: 'Type of achievement ',
    txt_global_std_finishdrive: 'Finish trip',
    txt_global_std_youshouldaddapictureofyourodometer: 'You should add a picture of your Odometer',
    txt_global_std_connectingtogps: 'Connecting to GPS',
    txt_global_std_app: 'app',
    txt_global_std_Fia_Receipt_Text2: 'Click the link to start competing in FIA SDC:',
    txt_global_std_2nddate: '2nd',
    txt_global_std_startd: 'Start',
    txt_global_std_youcanalsouploadpicturesthatmightberelevant: 'You can also upload pictures that might be relevant in case of an accident.',
    txt_global_std_missingbonuspayout: 'Missing Bonus Payout',
    txt_global_std_publish: 'Publish',
    txt_global_std_daysshort: 'd',
    txt_global_std_areyoudriving: 'Are you driving',
    txt_global_std_timelefttoreactivateinsurance: 'Time left to reactivate insurance',
    txt_global_std_fivereasonswhyjoiningfiasdcisasmartbusinessdecision: 'Five reasons why joining FIA SDC is a smart business decision:',
    txt_global_std_orenteractivationcode: 'Or enter activation code:',
    txt_global_std_youdonothaveanyongoingchallengesatthemoment: 'You do not have any ongoing challenges at the moment.',
    txt_global_std_locationserviceneedstobeontoworkcorrectly: 'Location Service needs to be ON to work correctly with the map functionality.',
    txt_global_std_forthisapptoworkproperlyweneedtoaccess: 'For this app to work properly, we need to access your location, Bluetooth and fitness/motion. The location service is used in background for the driving feature. To analyze your driving with optimal results the app also does this in the background. We value your privacy and will not share this information with a third party entity.',
    txt_global_std_translationtool_upsell: 'Translation Tool – reflect your brand values by communicating your own way.  ',
    txt_global_std_cimage23d: 'Every additional meter rolled have an impact on speed decrease or stop.',
    txt_global_std_analyzingdotdot: 'Analyzing...',
    txt_global_std_insureddistance_used_100__3: 'Oi! Se till att du inte riskerar förkortning vid skador. Justera körsträckan så att den är korrekt.',
    txt_global_std_youmayaddaphotolater: 'You may add a photo later',
    txt_global_std_sendassms: 'Send as SMS',
    txt_global_std_selectinsurancetoterminate: 'Select insurance to terminate',
    txt_global_std_nowitstimetosetupyourcompanychallenge: "Now it's time to set up your Company Challenge!",
    txt_global_std_youvehavegatheredatotalof: 'You’ve have gathered a total of ',
    txt_global_std_alsochallengeyourselfandengageyourfriendsinaworldcompetition: 'Also, challenge yourself and engage your friends in a world competition.',
    txt_global_std_youmusthaveinternetconnection: 'You must have an internet connection to receive live scores.',
    txt_global_std_p2pnomessagesyet: 'No messages yet',
    txt_global_std_invalidinput: 'Invalid input',
    txt_global_std_cimage7h: 'FAST TO CRUISING SPEED',
    txt_global_std_p2pdenyy: 'Deny',
    txt_global_std_surcharge: 'Surcharge',
    txt_global_std_p2pgotosupport: 'Go to support',
    txt_global_std_MSIG_InsuranceLetter43: 'SGD1,000.00 ALL CLAIMS IS IMPOSED ON DRIVERS WHO HAVE DEACTIVED THE DONGLE FOR AT LEAST 14 DAYS IN A CALENDAR YEAR.',
    txt_global_std_cimage16h: 'PLAN YOUR SPEED INCREASE',
    txt_global_std_timeinacceleration: 'Time in acceleration',
    txt_global_std_planning: 'Planning',
    txt_global_std_p2ptimeused: 'Time used',
    txt_global_std_whenyouvestartedadriveyoucanseeyourcarslocation: "When you've started a drive you can see your car's location.",
    txt_global_std_myfriends: 'My friends',
    txt_global_std_1000drive: '1000 drive!',
    txt_global_std_cimage26h: 'GEAR ON LOW RPM',
    txt_global_std_p2pyoublocked: 'You blocked',
    txt_global_std_reportaccident2: 'Report Accident',
    txt_global_std_bycheckingthisboxandlogginginyoureacceptingour: 'By checking this box and logging in you’re accepting our',
    txt_global_std_wesharethislater: 'We share this later...',
    txt_global_std_syncingwithyourgarminconnectaccountiseasyclicktheconnectbuttonbelowtohelpusuncovernewinsightsintocrashriskbyanalyzingbothdrivingandhealthdata: 'Syncing with your Garmin Connect™ account is easy! Click the Connect button below to help us uncover new insights into crash risk by analyzing both driving and health data.',
    txt_global_std_showadvancedsettings: 'Show advanced settings',
    txt_global_std_rewardthosewhoexcel: 'Reward those who excel!',
    txt_global_std_kintofaq6a2: 'for help.',
    txt_global_std_uploadintervalminutesmin: '{uploadIntervalMinutes} min',
    txt_global_std_p2penterkintonumber: 'Enter Kinto number',
    txt_global_std_startadrivetogetascore: 'Start a drive to get a score!',
    txt_global_std_orgenerateinvitationcodeandlinkformultipleuse: 'Or generate invitation code and link for multiple use',
    txt_global_std_atrustedscorevettedbythefia: 'A trusted score vetted by the FIA',
    txt_global_std_tryagain: 'Try again',
    txt_global_std_timetoconnecttoyourcar: 'Time to connect to your car',
    txt_global_std_MSIG_InsuranceLetter26: 'The Policy does not cover use for hire or reward, racing, pacemaking, reliability trial, speed-testing, the carriage of goods other than samples in connection with any trade or business or use for any purpose in connection with the Motor Trade.',
    txt_global_std_acontactyourinsuranceagencytoterminateyourinsurance: 'A: Contact your insurance agency to terminate your insurance.',
    txt_global_std_selectinsurance: 'Select Insurance',
    txt_global_std_nameunavailable: 'Name unavailable',
    txt_global_std_p2pwarningdeletebooking: 'This will permanentely delete the booking.',
    txt_global_std_numberoftimes: 'Number of times',
    txt_global_std_menu: 'Menu',
    txt_global_std_enteravalidlicenseplate: 'Enter a valid license plate!',
    txt_global_std_tuesday: 'Tuesday',
    txt_global_std_endinsuranceforname: 'End insurance for {name}?',
    txt_global_std_enerfyanalytics_upsell_bulletpoint3: 'Get more value from data to improve ROI',
    txt_global_std_greatprogress: 'Great progress!',
    txt_global_std_manageyourgroups: 'Manage your groups',
    txt_global_std_colleaguespartnersandinvitedguestscanjoinyourbrandedworkspace: 'Colleagues, partners and invited guests can join your branded workspace',
    txt_global_std_enablepushnotifications: 'Enable Push Notifications',
    txt_global_std_messages: 'Messages',
    txt_global_std_p2puploadaprofileimage: 'Upload a profile image',
    txt_global_std_createyourownworkspaceinthefiasmartdrivingchallengeyoucancreatemanyworkspacessuitableforcustomersandotherpartnersinviteandgettoknowmoreaboutdrivingandyourco2imprint: 'Create your own workspace in the FIA Smart Driving Challenge. You can create many workspaces, suitable for customers and other partners. Invite and get to know more about driving and your CO2 imprint.',
    txt_global_std_makeapayment: 'Make a payment',
    txt_global_std_dashboard: 'Dashboard',
    txt_global_std_addyourlogotypeandchoosecolorthemeexpandtoseeapreviewofthechallenge: 'Add your logotype and choose color theme. Expand to see a preview of the challenge.',
    txt_global_std_selectaparent: 'Select a parent',
    txt_global_std_accept: 'Accept',
    txt_global_std_p2pfaq27a: "You can give members some of the owner's privileges.\n" +
      'What you can do: Manage reservations (approve / reject reservations from other members, change reservation time, etc.)',
    txt_global_std_bluetoothid: 'Bluetooth ID',
    txt_global_std_timelocationaretheonlymandatoryfieldspleaseuploadpicturesofyourcarwithin24hours: 'Time & location are the only mandatory fields. Please upload pictures of your car within 24 hours.',
    txt_global_std_cantfindyourevent: 'Can’t find your event?',
    txt_global_std_step: 'Step',
    txt_global_std_privacypolicy: 'Privacy policy',
    txt_global_std_improveyourdrivingandtheplanet: 'Improve your driving and the planet',
    txt_global_std_pleasetryagain: 'Please try again',
    txt_global_std_youcanfindotheruserguidesifyougotothefaqinthemoremenu: 'You can find other user guides if you go to the FAQ in the more menu. ',
    txt_global_std_remembertotakepicturesofthedamageandsavethedatetimeandplace: 'Remember to take pictures of the damage, and save the date, time and place.',
    txt_global_std_topecodriver: 'Top eco-driver',
    txt_global_std_p2pclosethispopup: 'Close this popup',
    txt_global_std_highestscore: 'Highest score',
    txt_global_std_totaldistance3: 'Total distance',
    txt_global_std_pushdriveendlevel3: 'Good drive. Tap me to see your feedback.',
    txt_global_std_viewmarketingmessages: 'View news in Activity',
    txt_global_std_p2pfaq28q: 'I want to add admin rights to a friend',
    txt_global_std_logininformation: 'Login information',
    txt_global_std_bonus: 'Bonus',
    txt_global_std_uploadyourlogoandaddyourcolorstomaketheappyourown: 'Upload your logo and add your colors to make the app your own.',
    txt_global_std_activepoints: 'Active points',
    txt_global_std_addyourpolicynumbertobeabletoenjoyallthebenefitsandclaimyourrewards: 'Add your policy number to be able to enjoy  all the benefits and claim your rewards!',
    txt_global_std_rewards: 'Rewards',
    txt_global_std_yourthreemostimportantdrivingtips: 'Your three most important driving tips',
    txt_global_std_KleinschmidtQuote: 'Together we can make the world a better place! Join this challenge and be part of a great contribution to environmentally friendly and safer driving. Improve your smart driving skills, have a lot of fun and an exciting competition!',
    txt_global_std_avgdriver: 'Avg. Driver',
    txt_global_std_top3bestdriverscomingsoon: 'Top 3 best drivers coming soon',
    txt_global_std_avgcosavings: 'Avg. CO₂ savings',
    txt_global_std_p2pminimumbookingtime: 'Minimum booking time is 30 minutes',
    txt_global_std_p2pchoosecar: 'Choose Car',
    txt_global_std_syncedwithgarminconnect: 'Synced with Garmin Connect™',
    txt_global_std_entertheactivationcodeyouhavereceivedfromyourworkspaceadministrator: 'Enter the activation code you have received from your workspace administrator',
    txt_global_std_send2: 'Send',
    txt_global_EnerfyLeaderboardTemplate_keepituptext: 'Keep on driving well and smart and you will soon gain rank!',
    txt_global_std_removingaparticipantfromachallengedoesnotdeletetheuserortheirdrivingdataintheappiftheyhavealreadydriven: 'Removing a participant from a challenge does not delete the user or their driving data in the app, if they have already driven.',
    txt_global_std_readmore1: 'Read more',
    txt_global_std_carstats: 'Car stats',
    txt_global_std_comingup: 'Coming up',
    txt_global_std_viewall: 'View all',
    txt_global_std_showonmap: 'Show on map',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint3: 'Get more value from data to improve ROI',
    txt_global_std_activationcodeemail2: 'Here is your activation code',
    txt_global_std_syncyourgarminconnectaccount: 'Sync your Garmin Connect™ account',
    txt_global_std_changepassword: 'Change password',
    txt_global_std_garmin15discountcodeforparticipantsandprizesformonthlywinners: 'Garmin 15% discount code for participants and prizes for monthly winners.',
    txt_global_std_sooncompleted: 'Soon completed!',
    txt_global_std_maycontactmeonphoneemailandsms: 'Yes, {appName}, and Greater Than may contact me on phone, email, {appName} drive app, and SMS with regard to receive news and special offers from Greater Than about insurance, injury preventive products, competitives, and trial other services. You can always please read and preferred your references for marketing.',
    txt_global_std_skilluplockedkey: 'To earn this badge, keep a score above {score} for a week.',
    txt_global_std_mypage: 'My page',
    txt_global_std_MSIG_InsuranceLetter15: 'To',
    txt_global_std_ChicheritMileage: 'Coming soon...',
    txt_global_std_insurancenumber2: 'Ins. number',
    txt_global_std_activitydetails: 'Activity details',
    txt_global_std_lockeddescription: 'Locked When badge has not <br />yet been received',
    txt_global_std_TomczykQuote: 'The FIA smart driving challenge encouraged me to drive more economical, safer and with more attention. After your drive, you can see in realtime how good you drove and compare it to other drivers all around the world. \n' +
      'Join my team and let us achieve the maximum points together. Challenge yourself and try to be the best smart driver in the world.',
    txt_global_std_level2: 'Level',
    txt_global_std_ChicheritBesttip: 'Coming soon...',
    txt_global_std_p2plendborrow: 'Lend/Borrow',
    txt_global_std_p2pchoosecarcarstolend: 'Choose car/cars to lend',
    txt_global_std_newchallenge: 'New challenge',
    txt_global_std_p2pfaq28a: 'You can select members and give them administrator privileges for the car.\n' +
      '1. Select "Members" from the "Menu".\n' +
      '2. Select a member to view your profile.\n' +
      '3. Select Settings.\n' +
      '4. Operate the button of the car you want to give permission to give permission.',
    txt_global_std_yourobdreaderhasalreadybeenorderedandyoucanexpectthereadertoarrivewithin35businessdays: 'Your OBD reader has already been ordered, and you can expect the reader to arrive within 3-5 business days.',
    txt_global_std_p2ppushtitleborrowcarnomessage: 'New borrow request received',
    txt_global_std_regno2: 'Reg. No',
    txt_global_std_PaddonMusic: 'When driving, I’d like to listen to relaxing/mellow music like Coldplay, Mumford and Sons etc.',
    txt_global_std_sdclevel: 'SDC Level:',
    txt_global_std_ifyoudonthavebluetoothinyourcaryoucanpluginaobdreaderanobdreadercanbepluggedintocarsmanufacturedyear2001andlater: "If you don't have Bluetooth in your car, you can plug in a OBD reader.\n" +
      '\n' +
      'An OBD reader can be plugged in to cars manufactured year 2001 and later.',
    txt_global_std_validateyourdata: 'Validate your data',
    txt_global_std_kintoEncouragement2: 'Your driving is saving the planet! You save {percent}% vs average.',
    txt_global_std_entercodehere: 'Enter code here',
    txt_global_std_youbooked15timesinonemonthyouhaveearned3500points: 'You have earned 3,500 points for making 15 reservations in a month.',
    txt_global_std_p2pgotomykinto: 'Go to my Kinto',
    txt_global_std_enteremailaddressyoucanentermorethanoneifyouseparatethembyacomma: 'Enter email address, you can enter more than one if you separate them by a comma.',
    txt_global_std_trytheapp: 'Try the app',
    txt_global_std_firstdrivelocked: 'You unlock this achievement when you drive your first time.',
    txt_global_std_onceaclaimsettlementisdeterminedyoucanreceiveacheckoranelectronicpaymentintoyourbankaccount: 'Once a claim settlement is determined, you can receive a check or an electronic payment into your bank account.',
    txt_global_std_disabled: 'Disabled',
    txt_global_std_youmustpickaninsurance: 'You must pick an insurance',
    txt_global_std_changenow: 'Change now',
    txt_global_std_areyousureyouwanttoremoveuserfromthegroupremovingtheuserfromthegroupdoesnotdeletetheusersaccount: 'Are you sure you want to remove user from the group?\n' +
      '\n' +
      'Removing the user from the group does not delete the users account.',
    txt_global_std_earn2500pointsifyoubook10timesamonth: 'Earn 2,500 points when you make 10 reservations in a month.',
    txt_global_std_p2perrorcodestartofbookingisoverlapping: 'Start of booking is overlapping.',
    txt_global_std_createaccount: 'Sign up',
    txt_global_std_activationcode2: 'Activation code',
    txt_global_std_thisisatranslation: 'This is a translation.',
    txt_global_std_p2pseefriendcode: 'Show or redeem friend codes',
    txt_global_std_activatebluetoothinsettings: 'Activate bluetooth in your phone',
    txt_global_std_challengeaverage2: 'Challenge average:',
    txt_global_std_usermanagement: 'User management',
    txt_global_std_publishyourdemoapp: 'Publish your demo app',
    txt_global_std_dateoftraining: 'Date of training',
    txt_global_std_saveandcontinue: 'Save and continue',
    txt_global_std_maxcharacters: 'Max. 15 characters',
    txt_global_std_locationofaccident: 'Location of accident',
    txt_global_std_leaderboardoverview: 'Leaderboard overview',
    txt_global_std_thechallengenamecantbelongerthan30characters: "The challenge name can't be longer than 30 characters",
    txt_global_std_createnewachievement: 'Create new achievement',
    txt_global_std_matched: 'Matched',
    txt_global_std_pleasereportyouraccidentusingtheclaimsformassoonaspossible: 'Please report your accident using the claims form as soon as possible.',
    txt_global_std_retardationdescription: 'Plan the driving. Prolong the energy of the vehicle by releasing the throttle pedal early.',
    txt_global_std_cropyourteamimage: 'Crop your team image',
    txt_global_std_p2ppleaseselectadate: 'Please select a date',
    txt_global_std_examplephone: '12 34 56 78',
    txt_global_std_onboardingnotificationstext: 'Allow {appName} to send you notifications when you have actions to do in the app.',
    txt_global_std_verygooddriving: 'Very good driving!',
    txt_global_std_p2ppushbodychangeperiodfromto: '{name} changed period. \nFrom: {from} \nTo: {to}',
    txt_global_std_motorclaimshotline2: 'Motor Claims Hotline',
    txt_global_std_uploadphoto: 'Upload photo',
    txt_global_std_challengeleaderboard: 'Challenge dashboard',
    txt_global_std_doyouhaveanymileagethatyoufancy: 'Do you have any mileage that you fancy the most to drive, why this one?',
    txt_global_std_byinvitingfriends: 'By inviting friends',
    txt_global_std_kintoImprovement1: 'Please drive carefully! We care about you!',
    txt_global_std_p2pyoublockedyourcar: 'You blocked your car',
    txt_global_std_skillupunlockedkey: 'Great job {user}! You kept a score above {score} for a week.',
    txt_global_std_100000pts: '> 100 000 pts',
    txt_global_std_p2pforfamilyfriends: 'For family & friends',
    txt_global_std_foryourowndocumentation: 'For your own documentation :) ',
    txt_global_std_OgierMileage: 'Coming soon...',
    txt_global_std_jun: 'Jun',
    txt_global_std_TidemandFacts: 'Pontus’ first taste of competitive came in cross-karts, where he became both Nordic and Swedish champion. 2011, the youngest driver to win the Swedish Rally Championship',
    txt_global_std_thisfeatureisnotavailableyet: 'This feature is not available yet',
    txt_global_std_reason1: 'Reason',
    txt_global_std_createyourownchallengetoinspiresmartdrivingexpandtoseeapreviewofthechallenge: 'Create your own challenge to inspire smart driving! Expand to see a preview of the challenge.',
    txt_global_std_friendfinder: 'Friend Finder',
    txt_global_std_p2pfaq25q: 'Where can I check the driving data?',
    txt_global_std_agreements: 'Agreements',
    txt_global_std_lexusweekend: 'Lexus Weekend',
    txt_global_std_5000pointaverageachievementunlocked: 'You drove extremely well! Your average {scoretype} was better than {score} for a week! You have earned 5000 points!',
    txt_global_std_carstatstext: 'Car stats',
    txt_global_std_claims: 'Claims',
    txt_global_std_sendinvitationby: 'Send invitation by',
    txt_global_std_wewouldrecommenddrivertraining: 'We would recommend driver training with extra attention on driver focus and planning. Which means, never lose focus on the traffic and conditions around you. We are Yamato and we always focus and plan the driving with special attention to Kindness, Prioritising, Emotional self-control and Trust.',
    txt_global_std_tttest97: 'this is a test 97 2',
    txt_global_std_howtogetstarted: 'How to get started',
    txt_global_std_searchandmanageusers: 'Search and manage users',
    txt_global_std_fixedpremium: 'Fixed monthly premium',
    txt_global_std_createanaccount: 'Create an account',
    txt_global_std_open: 'Open',
    txt_global_std_connectyourcarandstartadrive: 'Connect your car and start a drive to see your estimated savings. Press the drive button to get started.',
    txt_global_std_whatifichangecaranswer: 'Inform the {appname} team via email. Then re-connect to your new car by following the “Connect to Car” steps again.',
    txt_global_std_greaterthansprivacypolicy: "Greater Than's privacy policy.",
    txt_global_std_followupontheonboardingstatusofyourusersclickonastatusbarbelowtoseeandhelpyourusersthatmightbestuck: 'Follow-up on the onboarding status of your users, click on a status bar below to see and help your users that might be stuck.',
    txt_global_std_p2pwelcomekintoowner: 'Welcome, Kinto owner!',
    txt_global_std_p2ppleasetypeprice: 'Please type price',
    txt_global_std_unpublishtranslations: 'Unpublish translations',
    txt_global_std_p2pendtime: 'End time:',
    txt_global_std_clickheretocallsupport: 'Click {here} to call support',
    txt_global_std_50km: '50 Km',
    txt_global_std_loyalty_upsell_pitch_bulletpoint3: 'Increase your program’s visibility to deliver the best possible ROI',
    txt_global_std_kintoEncouragement1: 'Wow! You are among the best Kinto drivers!',
    txt_global_std_publishapptest: 'Publish app test',
    txt_global_std_startadrive_: 'Start a drive',
    txt_global_std_300pointTripAchievementunlocked: 'Your drove at {scoretype} {score} or better! Awesome! You have earned 300 points!',
    txt_global_std_p2pemailunreadmessages: 'Unread messages',
    txt_global_std_ecofriendlockedkey: 'Drive eco friendly and save more than {co2value}% CO₂ in a week to earn this badge.',
    txt_global_std_p2ppushtitlenewinvoicetopay: 'New invoice to pay',
    txt_global_std_rateapp: 'Rate app',
    txt_global_std_reportcentersworkshops: 'Report centers & workshops',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint1: 'Encourage drivers to build new skills through gamification ',
    txt_global_std_invitelink: 'Invite link',
    txt_global_std_cimage8d: 'Accelerate gently when traffic or road makes your speed vary a lot.',
    txt_global_std_loyalty_upsell_pitch_bulletpoint2: 'Reward smart drivers to encourage engagement and retention',
    txt_global_std_p2plendmycartwo: 'Lend my car',
    txt_global_std_HusseinAbdullaBesttip: 'Patience',
    txt_global_std_youwillcomebacktothesesettingsthenexttimeyoupressthedrivebutton: 'You will come back to these settings the next time you press the drive button.',
    txt_global_std_advancedadmin_upsell: 'Advanced Admin – simple self-management portal for a powerful user experience.',
    txt_global_std_selecttime: 'Select time',
    txt_global_std_deletingthegroupdoesnotdeletetheusersaccounts: 'Deleting the group does not delete the users accounts.',
    txt_global_std_addyourmileage: 'Add your mileage',
    txt_global_std_totalnooftrips: 'Total No. of trips',
    txt_global_std_thatwasaverygooddrive: 'That was a very good drive!',
    txt_global_std_weeklyheat: 'Weekly heat',
    txt_global_std_enerfyanalytics_upsell: 'Enerfy Analytics – easy-to-use data analysis tool for powerful reporting.',
    txt_global_std_mediumrisk: 'Medium risk',
    txt_global_std_ConfirmFia_Text12: 'Be sure to arrive at the venue 10 minutes before your scheduled time. Our staff will help you register and find your car.',
    txt_global_std_finishadriveatscoretypescoreorbettertounlockthisbadge: 'Finish a drive at {scoretype} {score} or better to unlock this badge.',
    txt_global_std_pleaseenterthenameofyourcontactperson: 'Please enter the name of your contact person',
    txt_global_std_sendinvitetocountfriends: 'Send invite to {count} friends?',
    txt_global_std_insurancedetails: 'Insurance details',
    txt_global_std_kintorefer50friendslocked: 'Unlock this achievement and earn ¥5000 by referring 50 Kinto users.',
    txt_global_std_userhasnopermissions: 'User has no permissions',
    txt_global_std_v2level: 'Level',
    txt_global_std_soonyouwillbeabletochallengeotherdriversinsafeandecofriendlydriving: 'Soon you will be able to challenge other drivers in safe and eco-friendly driving. ',
    txt_global_std_civilstatus: 'Civil status',
    txt_global_std_qhowdoisignupforcovercubeinsurance: 'Q: How do I sign up for Covercube insurance?',
    txt_global_std_ifyouhaveone: '-if you have one',
    txt_global_std_drivingstatisticstabs: 'Drivingstatistics tabs',
    txt_global_std_signinsurance: 'Sign insurance',
    txt_global_std_p2pcheckyouremail: 'Check your email',
    txt_global_std_whatisyourbesttiptodrivesmart: 'What is your best tip to drive smart?',
    txt_global_std_call: 'Call',
    txt_global_std_test: 'test.',
    txt_global_std_wanttoshareyourprogresswithyourfriends: 'Want to share your progress with your friends?',
    txt_global_std_yourcar2: 'Your car',
    txt_global_std_fitmarkers: 'Fit markers',
    txt_global_std_autostartobd: 'Automatic start of OBD',
    txt_global_std_theindividualdriverscoreiscalculatedonanaveragebasedonthetop10trips: '• The individual driver score is calculated on an average based on the top 10% trips.',
    txt_global_std_excellent: 'Excellent',
    txt_global_std_email: 'E-mail',
    txt_global_std_sdcrules: 'SDC rules',
    txt_global_std_ridewithmetext: 'Sharing a ride with a friend instead of using two cars saves a lot of CO2! By registering a ridesharing trip both you and your friend will earn points',
    txt_global_std_howwillyouknowaboutmydrivingbehaviour: 'How will you know about my driving behavior?',
    txt_global_std_howdoicompeteinlexuschallengeanswer: 'You automatically participate when you sign up. Don’t worry, the leaderboard will only show your nickname- so you will always stay anonymous. There is a monthly and yearly competition.',
    txt_global_std_enerfyisaproductfromgreaterthanab: 'Enerfy is a product from Greater Than AB.',
    txt_global_std_ecofeedbacktips: '<b>Your eco score measures how eco friendly you drive.</b> In a lot of ways it can be connected to your planning.<br><br>\n' +
      '<b>Adjust your accelerations</b> to avoid unnecessary brakes and stops. Every acceleration and brake consume energy.<br><br>\n' +
      'Together we can <b>make a difference</b> for the future.',
    txt_global_std_therulesforthecompanychallengeareasfollowing: 'The rules for the company challenge are as following:',
    txt_global_std_hhmmss: 'hh:mm:ss',
    txt_global_std_iwantrematch: 'I want rematch!',
    txt_global_std_httpstatuscodesummary: 'HTTP status code summary',
    txt_global_std_errors: 'Errors',
    txt_global_std_availablepointexchange: 'Available point exchange',
    txt_global_std_p2punavailable: 'Unavailable',
    txt_global_std_daysleftuntilrenewal: 'Days left until renewal',
    txt_global_std_trainername: 'Trainer name',
    txt_global_std_required: 'Required',
    txt_global_std_ifyouwantinfoonnotificationscallphonenumberorgotoanchorlinkorgotorouterlinkpage: 'If you want info on notifications call {phoneNumber} or go to {anchorLink} or go to {routerLink} page.',
    txt_global_std_p2pyouborrowedusersvehiclextimes: 'You borrowed {user} {car} {times} times',
    txt_global_std_topthreeworldteams: 'Top 3 world teams',
    txt_global_std_reporting: 'Reporting',
    txt_global_std_attributes: 'Attributes',
    txt_global_std_reportdamage: 'Report damage',
    txt_global_std_welcomebackenerfy: 'Welcome back to {appName}!',
    txt_global_std_p2pfinishandpay: 'Finish',
    txt_global_std_p2pcash: 'Cash',
    txt_global_std_kintofaq7q: 'My car doesn’t have Bluetooth?',
    txt_global_std_maintainalowrisklevelbykeepingyourfocusontheroadandplanahead: 'Maintain a low risk level by keeping your focus on the road and plan ahead.',
    txt_global_std__emailaddress: 'Email address',
    'txt_global_std_Its time for some fun. Join a challenge!': "It's time for some fun. Join a challenge!",
    txt_global_std_fuelinjectorcleaner: 'Fuel injector cleaner',
    txt_global_std_BeforeFirstBill_Text6: 'Sincerely,\r\n{appName}',
    txt_global_std_enteryouremailadress: 'Enter your e-mail',
    txt_global_std_pleasenotethattojoinyoumusthavetheaanzappinstalledonyourmobiledeviceinordertobeabletojointhegroup: 'Please note that to join you must have the DSFB Driver app installed on your mobile device in order to be able to join the team.',
    txt_global_std_avgkm7d: 'Avg km 7D',
    txt_global_std_finished: 'Finished',
    txt_global_std_returntoooverview: 'Return To Overview',
    txt_global_std_turnonbluetoothviayourcarsinfotainmentsystemstereoandfollowtheinstructionsforpairingyourphone: "Turn on Bluetooth via your car's infotainment system/stereo and follow the instructions for pairing your phone.",
    txt_global_std_howdoiearnsavingslexusanswer: 'Savings are generated when you drive safe. For every trip you drive safe, you will generate a saving per kilometer. The safer you drive, the higher the saving! The saving is visible directly after your trip is finished and will be visible in your Lexus wallet.',
    txt_global_std_finishedtraining: 'Finished training',
    txt_global_std_savechangespopupheader: 'Save changes?',
    txt_global_std_repeatyournewpassword: 'Repeat your new password',
    txt_global_std_prefernottosay: 'Prefer not to say',
    txt_global_std_coverimage: 'Cover image:',
    txt_global_std_pleasefocus: 'Please focus!',
    txt_global_std_communication_upsell_pitch_bulletpoint1: 'Enhance user experience and engagement',
    txt_global_std_secretuser: 'Secret',
    txt_global_std_redeemcoupon: 'Redeem coupon',
    txt_global_std_createanaccounttosavealldrivingdata: 'Create an account to save all driving data, track your routes and save your rewards for future trips.',
    txt_global_std_needhelp: 'Need help?',
    txt_global_std_usernameshort: 'Username',
    txt_global_std_zipriskindexhelpsyoudeterminepreciserisklevelbasedondrivinglocation: 'Zip risk index helps you determine precise risk level based on driving location.',
    txt_global_std_setupyourfiasdccompanychallenge: 'Set up your FIA SDC Company Workspace.',
    txt_global_std_mycardoesnthavebluetooth: 'My car doesn’t have Bluetooth?',
    txt_global_std_firstscore: "Let's be the first one with score in the heat",
    txt_global_std_p2pallofyourtripsgatheredinoneplacegetaccesstofeedbackandstatisticsaboutyourdrives: 'All of your trips gathered in one place. Get access to feedback and statistics about your drives.',
    txt_global_std_yousaved: 'You saved',
    txt_global_std_PolicySchedule6: 'until midnight on',
    txt_global_std_youneedtoinputanemail: 'You need to input an Email',
    txt_global_std_noregnumberregistered: 'No reg number registered',
    txt_global_std_socialsecuritynumber: 'Social security number',
    txt_global_std_skeyscore: 'Score',
    txt_global_std_participants: 'Participants',
    txt_global_std_qhowdoisignupforcompanyinsurance: 'Q: How do I sign up for [Company] insurance?',
    txt_global_std_yourpasswordmustconsistofatleastfive: 'Your password must consist of at least five (5) characters.',
    txt_global_std_p2perrorcodeendofbookingisoverlapping: 'End of booking is overlapping',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint1: 'Inspire smarter driving, helping to reduce risk, fuel use and CO2 emissions',
    txt_global_std_youcanfindyourresultshortlyinyourtrips: 'You can find your result shortly in your trips.',
    txt_global_std_p2pbookingfinished: 'Booking finished',
    txt_global_std_ecoscore: 'Eco score',
    txt_global_std_alltabtext: 'All',
    txt_global_std_buynow: 'Buy now',
    txt_global_std_myleague: 'My league',
    txt_global_std_startdrivingtoappearonleaderboard: 'Start driving to appear on the leaderboard!',
    txt_global_std_toprovideyouwithaccuratestatsanddrivingscoreappnameneedstoruninbackgroundmodebrinthenextpopupbrchooseballowallthetimeb: 'To provide you with accurate stats and driving score {appname} needs to run in background mode. <br>In the next popup:<br>Choose:  \n' +
      ' <b>Allow all the time</b>',
    txt_global_std_aninsuranceadjusterwillcontactyouwithinhourshoursofsubmittingyourclaimtheentireclaimsprocesswilldetermineonthecomplexityoftheclaim: 'An insurance adjuster will contact you within {hours} hours of submitting your claim. The entire claims process will determine on the complexity of the claim.',
    txt_global_std_recruiter: 'Recruiter',
    txt_global_std_forthisapptoworklocationservices: `For this app to work properly and to register your trips, we want you to change the permission of location services to "Always". If you don't do it know, you will get this popup later on.`,
    txt_global_std_welcomeletsgetstarted: "Welcome! Let's get started!",
    txt_global_std_uploadgroupimage: 'Upload group image',
    txt_global_std_married: 'Married',
    txt_global_std_PolicySchedule21: 'OFD',
    txt_global_std_inallourappswehavecontactinformationsothatuserscancontactthecompanyfillinyourcompanyscontactinformationhere: 'In all our apps we have contact information so that users can contact the company. Fill in your company’s contact information here.',
    txt_global_std_VilliersFacts: 'Giniel De Villiers has never retired. Even better, only once has he not finished in the Top 10 (in 2007). He has 14 stage victories and in 2009 he won the first Dakar contest in South America.',
    txt_global_std_back: 'Back',
    txt_global_std_advancedadmin1: '  Advanced admin',
    txt_global_std_pleasegivethreeoptionsincaseyourfirstoptionsisalreadyused: 'Please give three options in case your first option(s) is already used.',
    txt_global_std_yourpasswordmustconsistofatleastten: 'Your password must consist of at least ten (10) characters.',
    txt_global_std_completed: 'Completed',
    txt_global_std_documentsentto: 'Document sent to',
    txt_global_std_estimatedsavings: 'Estimated savings',
    txt_global_std_maybesavedwhenfocusingonthisspeed: 'may be saved when focusing on improving in this speed',
    txt_global_std_step1: 'Step 1',
    txt_global_std_p2pdecreasetextsize: 'Decrease text size',
    txt_global_std_recruiterlockedkey: 'Invite a friend to the app to earn this badge. You get this badge when your friend creates an account in the app.',
    txt_global_std_translationtool: 'Translation Tool',
    txt_global_std_insurancecompanydescription: 'For precision pricing and underwriting excellence.',
    txt_global_std_terminate: 'Terminate',
    txt_global_std_cimage4d: 'Red light driving',
    txt_global_std_showofflinedrives: 'Show offline-drives',
    txt_global_std_enerfydynamiccollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: 'Enerfy Dynamic collects location data to enable driving score even when the app is closed or not in use.',
    txt_global_std_cantseeyourcar: "Can't see your car"
  },
  ja: {
    txt_global_std_areyoudrivinganelectricorobd: '電気自動車を運転していますか、または、車載デバイスを取り付けていますか？',
    txt_global_std_nodrivingdata: '走行データがありません',
    txt_global_std_HusseinAbdullaFacts: 'Adel has won the FIA CCR T2 World Championship with Sebastian Delaunay as a Co-driver. In his first full season of cross-country competition, Adel Hussein claimed wins in Portugal, Abu Dhabi and Spain during a successful campaign in the world’s toughest off-road championship.',
    txt_global_std_gotoleaderboard: 'スコアボードに戻る',
    txt_global_std_inordertoconnecttoyourcartheincludedobd: 'In order to connect to your car, the included OBD device, the Enerfy plug-in, must be installed in your vehicle - or - if your vehicle has the option to connect your phone via Bluetooth directly, the Enerfy app has to be running in your phone while driving.',
    txt_global_std_p2pfaq20a: '1.\t「メニュー」から、「ドライブ」を選択すると、直近の走行データと評価を確認できます。\n' +
      '2.\t特定の走行データを選ぶと、フィードバックの詳細が確認できます。',
    txt_global_std_p2pcancelled: 'キャンセル',
    txt_global_std_togetthecorrectstatsofyourdrivingyouneedtogototheappsettingsinyourphoneand: '運転に関する正確な統計情報を取得する\nためには設定で「常に」を選択する\n必要があります。',
    txt_global_std_minimum5characters: '5文字以上',
    txt_global_std_inactivateapp: 'アプリを無効にする',
    txt_global_std_noinsurancesfound: '保険が見つかりません',
    txt_global_std_letstrythatagain: 'もう一度試してみましょう！',
    txt_global_std_apr: '4月',
    txt_global_std_HSolbergMusic: 'POP and rock music that gives enthusiastic spirit in my blood.',
    txt_global_std_youhaveatotalcostof: '合計費用があります',
    txt_global_std_challengedetails: 'チャレンジの詳細：',
    txt_global_std_driversname: 'ドライバーの名前',
    txt_global_std_tomasengemileage: '私はいつも私の家、私の家族につながるすべての道が大好きです',
    txt_global_std_yourinsurancehasbeenrenewed: 'あなたの保険は更新されました。',
    txt_global_std_now: '現在',
    txt_global_std_p2pviewandpay: '表示して支払う',
    txt_global_std_p2pnamecancelledname2sbooking: '{name}が{name2}の予約をキャンセルしました',
    txt_global_std_telephoneclaims: '電話でのご請求',
    txt_global_std_emailalreadyusedpleasetrywithanotheremail: '既に使用されているメールアドレスです',
    'txt_global_std_Al-KuwariBesttip': 'Concentration and try to enjoy the drive.',
    txt_global_std_newtext: '新しいテキスト',
    txt_global_std_p2pemailhello: 'こんにちは ',
    txt_global_std_carmodelform: '車のモデル：',
    txt_global_std_sendreferrallink: '紹介リンクを送信する',
    txt_global_std_failedtoaddusers: 'ユーザーの追加に失敗しました',
    txt_global_std_feedback: 'フィードバック',
    txt_global_std_hoponinletsdrivesmart: '乗り込んでスマートにドライブしましょう',
    txt_global_std_JyrkiainenChamp: 'Best rookie driver in Finnish Championship 2016',
    txt_global_std_hellogreattoseeyou: 'こんにちは、お会いできて光栄です！',
    txt_global_std_p2pmembersinthisgroup: 'グループのメンバー',
    txt_global_std_couldnotjoinevent: 'イベントに参加できません。',
    txt_global_std_cantfindyourcaryet: '車が見つかりません！',
    txt_global_std_allusershavesuccessfullycompletedthisstep: 'すべてのユーザーがこの手順を正常に完了しました。',
    txt_global_std_forgotyourpassword1: 'パスワードをお忘れですか？',
    txt_global_std_paddonDescription: 'Hayden has started driving go-karts at the age of 6. Lived and breathed motorsport all his life.',
    txt_global_std_security: '安全',
    txt_global_std_welcometoapp: '{app}へようこそ！',
    txt_global_std_adjustyouryearlymileage: '年間走行距離を調整する',
    txt_global_std_easilykeeptrackofyourcostandco2emissionsintheapp: 'アプリでコストとCO2排出量を簡単に追跡できます。',
    txt_global_std_p2ppleasechooseexpensetype: '費用の種類を選択してください',
    txt_global_std_electriccar: '電気自動車？',
    txt_global_std_someoneelseispairedtoyourcar: '•他の誰かがあなたの車とペアになっています。',
    txt_global_std_moreattributes: 'その他の属性',
    txt_global_std_disableachievementtooltiptext1: 'この実績は実績ツールなしで作成されました。 <br> したがって、現在このツールで変更することはできません。',
    txt_global_std_atheappwilltrackyourscoresandprovidearatebasedonyourdrivinghabitsyouwillgetthefirstmonthlyratethatisbasedonyourdrivingafteryourfirstsixmonthperiodyourratewillthenchangeonamonthlybasisbasedonyourscoreanddrivinghabitsyouwillreceiveupdatesaftereachtripadvisingyouofyourpotentialsavingsorifyouneedtoimproveyourdrivingscoretoearnadiscount: 'A：アプリはあなたのスコアを追跡し、あなたの運転習慣に基づいてレートを提供します。最初の6か月の期間の後、運転に基づいた最初の月額料金が表示されます。その後、あなたのレートはあなたのスコアと運転習慣に基づいて毎月変化します。各旅行の後に、節約の可能性について、または割引を得るために運転スコアを改善する必要があるかどうかを通知する最新情報を受け取ります。',
    txt_global_std_notsupported: 'サポートされていません',
    txt_global_std_inviteafriendorfamilytoborrow: '車を貸し借りするために  友だちや家族を招待しましょう。',
    txt_global_std_400badrequest: '400不正な要求',
    txt_global_std_TaxInvoice4: 'Currency/Exch Rate',
    txt_global_std_someoneelseispairedtoyourcaryourbluetoothbeacon: '•他の誰かがあなたの車/ Bluetoothビーコンとペアリングされています\n',
    txt_global_std_f_lanzinterests: 'アドベンチャークライミング、リバーラフティング、ジップライン、トレイルランニング、ダンス、ワイルドネイチャーエクスプローラー',
    txt_global_std_password2: 'パスワード',
    txt_global_std_v2sdcscore: 'スコア',
    txt_global_std_trytheappfunctionisnotavailableindemomode: '「アプリを試す」機能はデモモードでは使用できません',
    txt_global_std_leaderboard_tab_daily: '毎日',
    txt_global_std_days: '日数',
    txt_global_std_youcanuploadmaximum9photos: '最大9枚の写真をアップロードできます',
    txt_global_std_connectwithobdreader: 'OBDリーダーと接続',
    txt_global_std_ongoinghead2headchallenges: '開催中の直接対決',
    txt_global_std_easytogetstartedrequestyourzipriskindexviaourapi: '簡単に始められます。当社の API を介して Zip リスク インデックスをリクエストしてください。',
    txt_global_std_avglevel: '平均レベル',
    txt_global_std_idnumber: 'ID番号',
    txt_global_CoreTemplate_enter: '入力',
    txt_global_std_startsin: '開始まで',
    txt_global_std_retrive: '取得する',
    txt_global_std_ok: 'はい',
    txt_global_std_questionsaboutinsurance: '保険についての質問',
    txt_global_std_PaddonChamp: 'PWRC Champion in 2011, 4 Wins New Zealand Rally Championship (2008, 2009, 2013, 2018). Won Rally of Argentina in 2016.',
    txt_global_std_saveyourcar: 'あなたの車を救う',
    txt_global_std_selectteam: 'チームを選ぶ',
    txt_global_std_ayouwillbenotifiedintheappwhenitstimetorenewyourinsuranceifyoudonthaveautomaticrenewalsetupfollowthestepsintheapptoensureyouhavecoverageforthefollowingmonth: 'A：保険を更新する時期になると、アプリで通知されます。自動更新を設定していない場合は、アプリの手順に従って、翌月の補償範囲があることを確認してください。',
    txt_global_std_globalteams: 'グローバルチーム',
    txt_global_std_mystatsthismonth: '今月のステータス',
    txt_global_std_downloadinglatestversionofapp: 'We are now downloading the latest versions of all applications. Please bare with us, it might take up to a minute.',
    txt_global_std_renewedfor: 'のために更新されました',
    txt_global_std_countryroadintersectionDESC: '赤い車のように、しばしば急ブレーキをかけてしまっています。青い車のように、周囲の状況に合わせて速度を調整できると理想的です。',
    txt_global_std_tomasengebesttip: 'できるだけ運転に集中することが目的地に到達するための鍵です',
    txt_global_std_thisensuresyourcarisstilltrackedbyloyaltysystem: 'これにより、車がロイヤルティシステムによって引き続き追跡されます。',
    txt_global_std_nestgroup: 'ネストグループ',
    txt_global_std_replay: 'リプレイ',
    txt_global_std_thisisaskilleddriver: '熟練したドライバーですが、旅行中の短時間だけクールドライビングに集中できなくなることもあります。注意散漫やストレスにより、このドライバーは安全意識を無視する可能性があります。これは多くの場合、ドライバーと話し、彼の配慮を求めることで修正されます。',
    txt_global_std_p2ptypeamessage: 'メッセージを入力する',
    txt_global_std_PolicySchedule20: 'NCD',
    txt_global_std_establishingobddatalink: 'OBDデータリンクを構築',
    txt_global_std_clickrefreshbuttoninapptotryagain: 'Click refresh button in app to try again.',
    txt_global_std_bad2: '悪い',
    txt_global_std_addlogo: 'Add logo',
    txt_global_std_whendrivingonhigherspeedroadsplanningismoreimportantthaneverenteringahighwaytooslowlycancausedangertofellowmotoristsandleadtoqueuesbehindyouobservingthetrafficflowandadaptingyourspeedtotheconditionsisimportanttoavoidunnecessaryqueuesandpanicbrakingnew: '高速道路を運転するときは、計画がこれまで以上に重要になります。高速道路への進入が遅すぎると、他のドライバーに危険をもたらし、後ろに列ができてしまう可能性があります。不必要な行列やパニックブレーキを回避するには、交通の流れを観察し、状況に合わせて速度を調整することが重要です。',
    txt_global_std_howdoiterminatemyinsurance: '保険を解約するにはどうすればよいですか？',
    txt_global_std_manageparticipants: '参加者を管理する',
    txt_global_std_MSIG_InsuranceLetter19: 'Persons or classes of persons entitled to drive*',
    txt_global_std_tryouttrygdrive: 'Tryg Driveを試す',
    txt_global_std_onceyouhavecompletedachallengeitwillappearhere: 'これまでの対戦結果が、\nここに表示されます。',
    txt_global_std_daysdayshourshoursminutesminutes: '{日}日{時間}時間{分}分',
    txt_global_std_p2psendinvite: 'リクエストを送信する',
    txt_global_std_pickstarttime: '開始時間を選択します',
    txt_global_std_setupyourprofiletocontinue: 'プロフィールを設定して続ける',
    txt_global_std_riskindex_upsell: 'リスク指標 – 全体像にアクセスして、リスクを高める世界的な傾向を理解します。',
    txt_global_std_teammembers: 'チームのメンバー',
    txt_global_std_KleinschmidtBesttip: "I find it important to foresee the drive ahead, so you don't need to break, accelerate, break again all the time.",
    txt_global_std_newmobilitycompany: '新しいモビリティ会社',
    txt_global_std_statsforthistrip: '分析結果',
    txt_global_std_cimage24d: '速度ごとに燃料消費が最も少ない回転数を見つけましょう。',
    txt_global_std_hoursshort: '時間',
    txt_global_std_warikan_onboarding19: '※ボタンが表示されない場合は、App StoreかGoogle Play から直接わりかんKINTOを\nダウンロードしてください。',
    txt_global_std_anewaccountwillbecreated: 'A new account will be created',
    txt_global_std_joinname: 'Join {appName}',
    txt_global_std_restrictedtopeoplewithinmyorganization: '（私の組織内の人々に制限されています）',
    txt_global_std_youraveragelevelthismonthisthebasisforyourdiscountyoureceivethediscountwhenyourenewyourinsurancefortheupcomingmonths: '今月の平均レベルが割引の基準になります。今後数か月間保険を更新すると割引が適用されます',
    txt_global_std_units: '単位',
    txt_global_std_listoffunctions: '機能一覧',
    txt_global_std_p2pviewyourbookingsandblockyourcarwhenyouwanttomakeitunavailable: '予約を表示し、車を利用できないようにしたい場合は車をブロックします。',
    txt_global_std_viewactivationcode: 'アクティベーションコードを表示',
    txt_global_std_p2psendyourcode: '友達コードを送る',
    txt_global_std_whenyouactivateyourinsurance: '保険が有効であれば、こちらから情報をご確認いただけます',
    txt_global_std_teamleaderscore: 'チームリーダーのスコア',
    txt_global_std_results: '結果',
    txt_global_std_choosefromlibrary: 'ライブラリから選択',
    txt_global_std_distancefromparking: '駐車場から走行開始までの距離',
    txt_global_std_youhavebeenchallengedtoahead2head: 'あなたは直接対決に挑戦されました。アプリを開いて今すぐ承認してください！',
    txt_global_std_wanttochallengeanewfriend: '新しい友達に挑戦してみませんか？',
    txt_global_std_currentodometervalueinkm: '現在の走行距離（㎞）を入力',
    txt_global_std_HansenMileage: 'I really fancy a good long trip if I can share the ride with my family or friends. ',
    txt_global_std_countryroad3DESC: '赤い車のように、加減速を繰り返してしまっています。青い車のように、一定速度を維持できると理想的です。',
    txt_global_std_collectpointsbyearningbadgeseverybadgeisworthdifferentamountofpointstapabadgetolearnmoreaboutit: 'バッジを獲得して「モビリティポイント」を貯めよう！\nバッジによって、獲得条件やポイント数が異なるので、\n気になるバッジは要チェック！',
    txt_global_std_getstarted: 'はじめる',
    txt_global_std_challenges: 'チャレンジ',
    txt_global_std_may: '5月',
    txt_global_std_documentation2: '報告書',
    txt_global_std_alreadyhaveanenerfypluginclickhere: 'Enerfyのプラグインはお持ちですか？ここをクリック！',
    txt_global_std_drivercpproverview: 'ドライバーの事故パターンに対する、プロファイリングレポート - 概要 ',
    txt_global_std_likeourcarsharingpage: 'Like our Facebook page',
    txt_global_std_youearnedapercentagediscount: '{パーセンテージ}％の割引を獲得しました',
    txt_global_std_uploadyourcompanylogo: '会社のロゴをアップロードする',
    txt_global_std_pushneverdrivenbody2: '{appname}から始めて、次にドライブに行くときに車に接続してください。',
    txt_global_std_kintofaq10q: '運転をどのように評価しているのでしょうか？',
    txt_global_std_p2ptotaltosplit: '分割する合計：',
    txt_global_std_worldranking: '世界ランキング',
    txt_global_std_yourinsuranceisrenewedrenewaldate: 'あなたの保険は更新されます{更新日}。',
    txt_global_std_setupyourcompanychallenge: '会社の課題を設定する',
    txt_global_std_companychallenge: '会社の挑戦',
    txt_global_std_ConfirmFia_Text11: 'パリで開催されるFIA Smart Driving Challengeテストドライブの予約時間枠まで、24時間を切りました！',
    txt_global_std_clickonthesettingsbelowtochangepermission: '以下の設定をクリックして、権限を変更',
    txt_global_std_starttheengineandopentheapp: 'エンジンをかけてアプリを開いてください',
    txt_global_std_supportedfileformats: 'サポートされているファイル形式',
    txt_global_std_demotext3: '走行距離の合計を確認するにはこちら。',
    txt_global_std_savedimagesmarkedwitha: 'マークが付いた画像を保存する',
    txt_global_std_makesureengineisrunning: 'エンジンがかかっていることを確認してください',
    txt_global_std_havealreadybeeninapp: 'Already an user',
    txt_global_std_unpublishachievementpopuptext: '実績を非公開にすると、まだロックを解除していないすべてのユーザーから削除されます。ユーザーがすでにそれを受け取っている場合、他の実績の中でそれを保持しています。まだ受け取っていない場合は、公開停止時に削除されます。',
    txt_global_std_KleinschmidtWhyjoin: 'Because we are the coolest team and we have lots of fun.',
    txt_global_std_risklevel: 'リスクレベル',
    txt_global_std_driveabitmoretogetscore: 'スコアを出すために、一定距離を\n走る必要があります！\nもう少し運転しましょう！',
    txt_global_std_updatedtranslation: '更新された翻訳',
    txt_global_std_countyears: '{count} 年',
    txt_global_std_insurance: '保険',
    txt_global_std_shownewactivities: '新しいアクティビティを表示する',
    txt_global_std_startachallenge: 'チャレンジを開始する',
    txt_global_std_discipline: 'カテゴリー',
    txt_global_std_competition: 'コンペ',
    txt_global_std_notsignedupforsompoclimateheroinsuranceyet: 'SOMPO CLIMATE HERO保険にまだ加入していませんか？',
    txt_global_std_pleaserefreshthepage: 'ページを更新してください',
    txt_global_std_hereyoustartadrivemanuallywhenyouhavefinished: 'ここから手動で運転を始めることが出来ます。\n' +
      '運転を終了する場合には「運転を終了する」を押してください。\n' +
      'GPSを使用することでスコアを計算します。\n' +
      'トンネルや駐車場内での運転はデータに影響する可能性があります。',
    txt_global_std_p2pborrowedtime: '借りた時間',
    txt_global_std_collision123: '衝突',
    txt_global_std_atleast5characters: '少なくとも5文字',
    txt_global_std_p2plendyou: '貸す',
    txt_global_std_signup: '新規登録',
    txt_global_std_calculating: '計算中',
    txt_global_std_30daysavg: '直近30日の平均',
    txt_global_std_pleaseverifyyourenerfyglobalemailadress: 'EnerfyGlobalのメールアドレスを確認してください',
    txt_global_std_p2pmorefunctions: 'その他の機能',
    txt_global_std_scantheqrcodetoaccessthechallenge: 'QR コードをスキャンしてチャレンジにアクセスします。',
    txt_global_std_man: 'おとこ',
    txt_global_std_sharelink1: '共有リンク',
    txt_global_std_refresh: '再読み込み',
    txt_global_std_easilymanagecommunication: 'すべてのチャネルでの通信を簡単に管理できます。一部のメッセージは、最高のカスタマージャーニーのために自動化されています。独自に作成してカスタマイズするか、既存のメール、テキストメッセージ、プッシュ通知、アプリ内通知から選択します。',
    txt_global_std_sep: '9月',
    txt_global_std_p2perrorcodebookingduplicaterequest: 'すでにこのリクエストは送信済みです。',
    txt_global_std_p2pborrowtext: '{name} さん 借りたい車 {vehicle}',
    txt_global_std_youwillearnachievements: '成績を獲得できます。',
    txt_global_std_mysavings: '割引',
    txt_global_std_besuretoaddfourmainpicturesofyourcarfrontleftrightandbacksidesasshowninthediagramsbelow: '車の写真を4枚以上アップロードしてください。\nドライブを開始する前に必要な情報です。\n例：前面、背面、左側面、右側面',
    txt_global_std_creatingyourprofile: 'プロフィールを作成中',
    txt_global_std_sorrytheimagerecognitionfailedpleaseentertheodometervaluemanually: '申し訳ありませんが、画像認識に失敗しました。走行距離計の値を手動で入力してください。',
    txt_global_std_alreadyhaveanaccountlogin: 'すでにアカウントをお持ちですか？ログイン',
    txt_global_std_PolicySchedule33: 'Address',
    txt_global_std_TomczykInterests: 'Classic cars, all kind of sports',
    txt_global_std_p2psafedriverbonusnotincluded: 'セーフドライバーポイントを使わない',
    txt_global_std_takesomephotosofyourcar: '車の写真を何枚か撮ってください',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriendsthedriverwiththebestscorewinstheround: '毎日、友達とスコアで競い合う機能です\n最高スコアのドライバーが勝利します',
    txt_global_std_p2pemailcancelledbookings: 'キャンセルした予約',
    txt_global_std_whatisapayasyoudriveinsurance: 'あなたが保険を運転するときの給与とは何ですか？',
    txt_global_std__log_in: 'ログイン',
    txt_global_std_gobackandfix: '戻って修正する',
    txt_global_std_allyear: '1年',
    txt_global_std_world: '世界',
    txt_global_std_leftuntilrenewal: '更新まで残しました',
    txt_global_std_policynumberalreadyexists: 'ポリシー番号はすでに存在します',
    txt_global_std_buyandinsuranceandstartsavingmoneynow: '保険を購入して節約しましょう',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint2: '的を絞った行動を可能にする新しい洞察を発見する',
    txt_global_std_oct: '10月',
    txt_global_std_coupons: 'クーポン',
    txt_global_std_okaywewillremindyoulater: 'また後で通知します。',
    txt_global_std_successfullypublishedreviewedtranslations: '正常に公開されました レビューされた翻訳',
    txt_global_std_p2pyouhavenobooking: '予約リクエストはありません。',
    txt_global_std_warikan_onboarding5: '1. オーナーとなるKINTO ONE契約者（お客様）がアプリをダウンロード',
    txt_global_std_koscore: 'Økoscore',
    txt_global_std_editprofilepicture: 'プロフィール写真の編集',
    txt_global_std_deactivated: '非アクティブ化',
    txt_global_std_timesincelogin: 'ログイン・駐車後などからの経過時間',
    txt_global_std_selectyourobddevicefromthelistobdmini2: 'リストからOBDデバイスを選択してください:OBD Mini 2',
    txt_global_std_enterthenrofdigitsdigitcodeorclickonthelinksenttophonenr: '{nrOfDigits}-数字コードを入力するか、次の宛先に送信されたリンクをクリックします：{phoneNr}',
    txt_global_std_howtoplay: '遊び方',
    txt_global_std_kintofaq7a: '株式会社KINTOから送付させていただいた機器をシガーソケットに差込み、スマートフォンのBluetooth接続で「Enerfy OBD」を選択してください。なお、お手元に機器がない場合は、以下連絡先にメールでお問合せください。株式会社KINTO　北村　satoshi.kitamura@kinto-jp.com',
    txt_global_std_senddetails: '詳細を送信',
    txt_global_std_youwillreceiveanemailwithyourpersonaldata: 'データのご利用状況について、\nメールでお知らせします。',
    txt_global_std_ihaveanenerfypluginhowdoiconnectitinmycar: 'Enerfyプラグインを持っていますが、車にプラグインするにはどうすればよいですか？',
    txt_global_std_thepasswordhastobe8charactersorlongerandmustinclude1numeralandspecialcharacter: 'パスワードは8文字以上で、1つの数字と特殊文字を含める必要があります',
    txt_global_std_enddate: '終了日',
    txt_global_std_p2ppushbodylendingcomingup: '{name}さんがクルマを借ります',
    txt_global_std_inviteyournetworktostartdrivingsmarter: 'ネットワークを招待して、よりスマートな運転を始めましょう！',
    txt_global_std_calender: 'Calender',
    txt_global_std_p2pnocancelledbookingrequests: 'キャンセルされた予約リクエストはありません',
    txt_global_std_itwillexpireshortlysomakesuretoverifysoonremembertocheckyourspamfolder: '間もなく期限切れになりますので、お早めにご確認ください。スパムフォルダを確認することを忘れないでください。',
    txt_global_std_me: '私',
    txt_global_std_connectingtoyourcar: '車への接続​',
    txt_global_std_ttest94: 'これはテストです',
    txt_global_std_alerthighdivisiontext: '警告！燃費が悪くなる運転スタイルです。',
    'txt_global_std_Al-KhelaifiQuote': 'お見積り準備中...',
    txt_global_std_p2ppushbodyexpensesdenied: '{name}さんが追加費用を承認しませんでした',
    txt_global_std_canitrytheappbeforesigningup: 'サインアップする前にアプリを試すことはできますか？',
    txt_global_std_PolicySchedule24: 'GST',
    txt_global_std_kintoloyaltysystem: 'ロイヤルティ・システム',
    txt_global_std_download: 'ダウンロード',
    txt_global_std_youareinthetop5keepgoing: 'トップ５に入っています。その調子！',
    txt_global_std_buttogetthecorrectstatsofyourdrivingyouneedtogotoappsettingsinyourphoneandchoosebalwaysb: '運転に関する正確な統計情報\nを取得するためには設定で\n「常に」を選択する\n必要があります。',
    txt_global_std_p2ppushbodyrevokeadmin: '{name}さんが "{vehicle}"の管理者権限を取り消しました',
    txt_global_std_filteredon: 'フィルタリング',
    txt_global_std_smallscratches: '小さな傷？',
    txt_global_std_yourinsurancewillberenewedonmonthdayyear: '保険は{月} {日}、{年}に更新されます',
    txt_global_std_placeholdertext: 'プレースホルダーテキスト',
    txt_global_std_thisisaskilledinconsistentdriver: '熟練したドライバーですが、旅行中の短時間だけクールドライビングに集中できなくなることもあります。注意散漫やストレスにより、このドライバーは安全意識を無視する可能性があります。これは多くの場合、ドライバーと話し、彼の配慮を求めることで修正されます。',
    txt_global_std_lockedtitle: 'ロックされた',
    txt_global_std_chooseallowonce: '[1回許可]を選択します',
    txt_global_std_connectionwasabortedbeforethedrivewasvalid: 'ドライブが有効になる前に接続が中止されました。ドライブは破棄されます',
    txt_global_std_preparing: '準備中…',
    txt_global_std_usermustacceptaninvitationtotheteamfirst: 'ユーザーは、最初にチームへの招待を受け入れる必要があります。',
    txt_global_std_p2pfaq7q: '予約をキャンセルできますか？',
    txt_global_std_drivingdatatroubleshooting: '走行データのトラブルシューティング',
    txt_global_std_time: '時間',
    txt_global_std_continuetosetup: 'Continue to setup',
    txt_global_std_fleetcompany: 'フリート会社',
    txt_global_std_p2pleavegroupchat: 'グループを退出する',
    txt_global_std_acceptuserterms: 'ユーザー規約に同意する',
    txt_global_std_selecttype2: 'タイプを選択',
    txt_global_std_mustbeavalidsizeandcanonlyincludenumbersand: '有効なサイズである必要があり、数字と+のみを含めることができます',
    txt_global_std_aiexplainer: 'AI説明者',
    txt_global_std_start: '開始',
    txt_global_std_MSIG_Onboarding12: 'MS First Capital Insurance Ltd',
    txt_global_std_HSolbergChamp: 'Won 5 times the Norwegian Championship (1999-2003)',
    txt_global_std_createanaccountoruseexistingone: 'アカウントを作成するか、既存のアカウントを使用する',
    txt_global_std_youwonyourh2hchallenge: '{user} さんとの Head2Head チャレンジに勝利しました!',
    txt_global_std_Inviteafriedbothwillgetawarded: '友だちを招待して賞金獲得！',
    txt_global_std_burglary123: '強盗',
    txt_global_std_apioverview: 'API の概要',
    txt_global_std_whenthechallengeisaccepteditwillshowuponthestartpage: 'チャレンジが受理されると、スタートページに表示されます。',
    txt_global_std_kintofaq10a: 'Greater Than社が過去16年間に亘って収集した6.5億名の運転履歴に基づいて、AIで事故の起こしづらさを評価しています。',
    txt_global_std_theft: '盗難？',
    txt_global_std_PolicySchedule26: 'NIL EXCESS FOR POLICYHOLDER AND AUTHORISED DRIVERS',
    txt_global_std_PolicySchedule9: 'Tonnage/CC',
    txt_global_std_letmeshowyouhoweverythingworks: 'すべての機能をご紹介！',
    txt_global_std_disableachievementtooltiptext: 'このアチーブメントは、アチーブメント ツールなしで作成されました。 <br> そのため、現在このツールでは変更できません。',
    txt_global_std_apikeymanager: 'APIキーマネージャー',
    txt_global_std_howlongwillittaketoprocessmyclaim: '申し立ての処理にはどのくらい時間がかかりますか？',
    txt_global_std_warikan_onboarding3: '以下のボタンより、わりかんKINTOアプリのダウンロードをお願いいたします。',
    txt_global_std_100km: '100 Km',
    txt_global_std_save: '保存',
    txt_global_std_searchbynameoraccounttype: '名前またはアカウントタイプで検索',
    txt_global_std_whendrivingonhigherspeedroadsplanningismoreimportantthaneverenteringahighwaytooslowlycancausedangertofellowmotoristsandleadtoqueuesbehindyouobservingthetrafficflowandadaptingyourspeedtotheconditionsisimportanttoavoidunnecessaryqueuesandpanicbraking: '高速道路を運転するときは、計画がこれまで以上に重要になります。高速道路への進入が遅すぎると、他のドライバーに危険をもたらし、後ろに列ができてしまう可能性があります。不必要な行列やパニックブレーキを回避するには、交通の流れを観察し、状況に合わせて速度を調整することが重要です。',
    txt_global_std_pushdriveendmessagetitle: 'Drive has ended - {0}',
    'txt_global_std_your@email.com': 'your@email.com',
    txt_global_std_enerfyanalytics_upsell_bulletpoint2: '的を絞った行動を可能にする新しい洞察を発見する',
    txt_global_std_unknownracedriverDescription: 'To be announced',
    txt_global_std_KristofferssonMileage: 'I enjoy driving up north in Sweden a sunny winter day ready for a ski trip.',
    txt_global_std_findteamordriver: '友だちや車両を探す',
    txt_global_std_invitefriendsviaemail: 'Eメールで友だちを招待する',
    txt_global_std_ifyoudonothavebluetoothinthecaryoucanuseachipthatisplacedinthecarsobd2portachipcanbeusedinmostcarsfrom2001orlater: '車にBluetoothが搭載されていない場合は、車のOBD2ポートに配置されているチップを使用できます。チップは2001年以降のほとんどの車で使用できます。',
    txt_global_std_creategroup: 'グループを作成します',
    txt_global_std_yougetthisafterdriving25tripswithtrygdrive: 'これは、Tryg Driveで25回の旅行を運転した後に得られます',
    txt_global_std_getiobd2: 'iOBD2をダウンロード',
    txt_global_std_KleinschmidtDescription: 'She is the first and so far the only woman who has ever taken outright victory in the longest and toughest rally in the world, the Dakar Rally. Her racing career started with motorcycle. After 4 rallies on motorbike she changed to 4 wheels in 1995, and became involved in the development of the most successful rally car, the Mitsubishi Pajero Evo, in which she won the Dakar in 2001. Besides he job as an instructor, she has published her book “Meine Siege bei der Dakar” – My win in Dakar.',
    txt_global_std_distance: '距離',
    txt_global_std_observe: '見る',
    txt_global_std_deletetranslation: '翻訳を削除',
    txt_global_std_damageabroad: '海外での故障',
    txt_global_std_okay: 'OK',
    txt_global_std_demotext4: '節約はスコアから！ボーナスはこちらから。',
    txt_global_std_joinyourteaminthefiasdcandstayactivesmartandsustainablewhenyoutravelbycar: 'Fia SDCに参加して、車で旅行するときもアクティブでスマート、そして持続可能です。',
    txt_global_std_p2ppushtitlelendcar: '{name}さんから貸し出しリクエストが届いています',
    txt_global_std_p2pemailorpasswordwrong: 'メールアドレス・パスワードどちらか、もしくは両方が違うようです。',
    txt_global_std_areyousureyouwanttoadjustyourmileagethepricewillbeadjustedaccordingtothenewmileage: 'マイレージを調整してもよろしいですか？価格は、新しいマイレージに応じて調整されます。',
    txt_global_std_ajustingmilageplan: 'マイレージプランの調整',
    txt_global_std_vsteam: 'VS.チーム',
    txt_global_std_yourscoreisbasedonthedrivingpatternsyoucreatewitheachtripconcentrateonyourdrivingskillstoimproveyourscoreswewillprovideahistoryofallyourtripssoyoucanseehowyouredoing: 'スコアは、各旅行で作成する運転パターンに基づいています。あなたのスコアを向上させるためにあなたの運転技術に集中してください。私たちはあなたのすべての旅行の履歴を提供するので、あなたはあなたがどのようにやっているかを見ることができます。',
    txt_global_std_belowisthelinktoyourenerfyglobalapp: '以下は、Enerfy Global アプリへのリンクです。',
    txt_global_std_youcancontactoneofourlicensedinsuranceagenciesbyclickingonlinkorcallingagentatphoneno: '{link}をクリックするか、{phoneNo}で{agent}に電話することにより、認可された保険代理店の1つに連絡できます。',
    txt_global_std_cimage12h: '上り坂の前には加速しましょう',
    txt_global_std_noreviewstobepublishedpleaseedittranslationsifyouwishtopublish: '公開するレビューはありません。公開する場合は、翻訳を編集してください。',
    txt_global_std_changecar: '車を変更する',
    txt_global_std_emailtoolong: '電子メールは最大 127 文字です。',
    txt_global_std_howdoicompeteinenerfyloyaltychallenge: 'Enerfy Loyalty Challengeに参加するにはどうすればよいですか？',
    txt_global_std_cimage20h: '最高速度で時間はセーブできません。',
    txt_global_std_kretursanalyse: 'Køretursanalyse',
    txt_global_std_enteryourpolicynumbertounlockallthebenefitsofthisapp: 'このアプリのすべての利点のロックを解除するには、ポリシー番号を入力してください。',
    txt_global_std_createyourownleaderboardinthefiasmartdrivingchallengeandchallengeeachothertobecomesmarterandsaferontheroad: 'FIAスマートドライビングチャレンジで独自のリーダーボードを作成し、道路上でよりスマートで安全になるようにお互いに挑戦してください。',
    txt_global_std_cimage21d: '低速でのエコドライブは技術的に簡単です。',
    txt_global_std_avaragescore: '平均スコア',
    txt_global_std_emergencynumber: '救援',
    txt_global_std_tiphighway1DESC: '赤い車のように、高速走行中にも頻繁にブレーキを踏んでしまっています。青い車のように、高速走行時は急減速を避けられると理想的です。',
    txt_global_std_makesureyouarewithintenmeters: 'OBDリーダーから10m以上はなれないでください。',
    txt_global_std_drivingtips: '運転のヒント',
    txt_global_std_introduction: '序章',
    txt_global_std_starting: '開始しています',
    txt_global_std_noactiveparticipantsthisweek: '今週はアクティブな参加者はいません',
    txt_global_std_fieldcannotbeempty: 'フィールドを空にすることはできません',
    txt_global_std_yourchallengeranking: 'あなたのチャレンジランキング',
    txt_global_std_createnewchallenge: '新しいチャレンジを作成',
    txt_global_std_enteravaliddate: '有効な日付を入力してください',
    txt_global_std_p2pemailadressforcommunication: '連絡用メールアドレス',
    txt_global_std_searchcardriver: '車/ドライバーを検索',
    txt_global_std_recruitedby: 'によって募集',
    txt_global_std_containsforbiddencharactersorspace: " 禁止文字が含まれています： '？ ＆ / &#60; &#62; またはスペース",
    txt_global_std_p2psignupandcontinue: '登録して次へ',
    txt_global_std_idonthavebluetoothinmycarcanistillusetheapp: '車にBluetoothが搭載されていませんが、アプリを使用できますか？',
    txt_global_std_allowbluetooth: 'Bluetoothを許可する',
    txt_global_std_youhavenowearnedascoreof4point4outof510timeswithtrygdrive: 'Tryg Driveで5点満点中4.4点のスコアを10回獲得しました',
    txt_global_std_p2pselectedcar: '対象車両',
    txt_global_std_pleaseallowkintoaccesstoyourlocationtogetstartedwiththeapp: 'Kintoから位置情報へのアクセスを許可し、アプリを始めましょう',
    txt_global_std_baselevel: 'ベーシックレベル',
    txt_global_std_ifyoucontinuetodriveonlevel: 'レベル{level}で運転を続けると、保険の{discount}を節約できます。',
    txt_global_std_generateinvitationcode: '招待コードを生成する',
    txt_global_std_MSIG_InsuranceLetter6: 'Motor Vehicles (Third-Party Risks) Rules, 1959 (Malaysia)',
    txt_global_std_p2pconnectyourphonetoyourcarandyouwillunlockallthemagic: 'あなたの電話をあなたの車に接続してください-そしてあなたはすべての魔法の鍵を開けます。',
    txt_global_std_addusersbyuploadingacsvfilewiththeirdetailsthefieldsfornameemailandpermissionsaremandatory: '詳細を含むCSVファイルをアップロードしてユーザーを追加します。名前、電子メール、およびアクセス許可のフィールドは必須です。',
    txt_global_std_fixitnowreminder: '今、直してください',
    txt_global_std_managecarsagreementshere: 'ここで車と契約を管理します。',
    txt_global_std_comparemyactivities: 'トリップを比較',
    txt_global_std_readytodrive: '走行準備中',
    txt_global_std_p2pmycalendar: 'マイカレンダ',
    txt_global_std_earnpointsandsavemoney: 'ポイントをためて、免責額を節約',
    txt_global_std_drivescoresave: '乗って、貯める',
    txt_global_std_highest: '最高',
    txt_global_std_testdeploynb: 'Hej',
    txt_global_std_backtochallengedashboard: 'チャレンジ ダッシュボードに戻る',
    txt_global_std_warikan_onboarding11: 'KINTOカスタマーセンター',
    txt_global_std_takepicturesofyourcar: '車の写真を撮ってください',
    txt_global_std_acommontypeofunsafedrivingbehaviorisputtinghugeeffortintochasingthenexttrafficlightitslikeachallengetogettothenextcrossingasquicklyaspossibleonlytobestuckwaitingforthelighttoturngreendrivingsmoothlyandlookingwellaheadtoanticipatewhatscomingupsavesbothtimeandfuelnew: '安全でない運転行動の一般的なタイプは、次の信号を追いかけようと多大な努力をすることです!信号が青に変わるのを待って立ち往生するだけで、次の交差点にできるだけ早く到達するのは挑戦のようなものです😊スムーズに運転し、何が来るかを予測するために十分に先を見据えることは、時間と燃料の両方を節約します.',
    txt_global_std_addyourlogotypeandchoosecolortheme: 'ロゴタイプを追加し、カラーテーマを選択します',
    txt_global_std_accounttype: '口座の種類',
    txt_global_std_noofdrives: 'ドライブ数',
    txt_global_std_wasanyoneinjured: 'ケガ人はいましたか？',
    txt_global_std_november: '11月',
    txt_global_std_variablepremium: 'さまざまな月間賞品',
    txt_global_std_youneedtoinputapassword: 'パスワードを入力してください',
    txt_global_std_findchallenges: 'チャレンジを探す',
    txt_global_std_easytomonitoryouresgtargets: 'ESGターゲットを簡単に監視',
    txt_global_std_xhours: '{hours}時間',
    txt_global_std_iwanttonotifyyouaboutyourdrivingresults: '運転結果についてお知らせします。 OK？',
    txt_global_std_p2ppercentageused: '利用割合',
    txt_global_std_youraveragerisklevelispoints: 'あなたの平均スコアは{points}です',
    txt_global_std_loyalty_upsell: 'ロイヤルティ ページ – より強い関係を通じてビジネスの成長をサポートします。',
    txt_global_std_p2pwarikanloginorlinkissues: 'Warikan-ログインまたはリンクの問題',
    txt_global_std_enterpassword: 'パスワードを入力する',
    txt_global_std_lastdatereceived: '最終受信日',
    txt_global_std_neverbeeneasier: 'かつてないほど簡単に',
    txt_global_std_activatemyinsurance: '保険をかける',
    txt_global_std_p2pother: 'その他',
    txt_global_std_riskindex_upsell_pitch_bulletpoint3: '国ごと、または郵便番号ごとのグローバルなリスクレベル',
    txt_global_std_appnamecollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: '{appName}は位置データを収集して、アプリが閉じているときや使用されていないときでも運転スコアを有効にします。',
    txt_global_std_whendrivingincityconditionsavoidchasingthenextcrossingortrafficlightbygainingspeedquicklythenslowingquicklythishurtstraveltimeandfueleconomyandincreasescrashrisklookingwellaheadanddrivingsmoothlywillhelpyouanticipateandhandleanyevent: '市街地を走行するときは、次の交差点や信号機を追わずに、すぐに加速してから減速してください。これにより、移動時間と燃費が悪化し、衝突のリスクが高まります。先を見据えてスムーズに運転することで、あらゆる出来事を予測して対処することができます。',
    txt_global_std_p2pwhatdoyouwanttodo: 'トルどちらか選んでください',
    txt_global_std_ourairiskinsighttoolgivesyouthepowertoidentifyfollowupandreportoncsrtargetstrackriskandco2perteammemberandrewardthosewhoexcel: '私たちのAIリスク洞察ツールは、CSR目標を特定、フォローアップ、報告し、チームメンバーごとのリスクとCO2を追跡し、優れた人々に報酬を与える力を提供します。',
    txt_global_std_cimage21h: '低速でのエコドライブは技術的に簡単です。',
    txt_global_std_average: '平均',
    txt_global_std_yourdocumentwassent: 'ドキュメントが送信されました！',
    txt_global_std_followupdate: '追加指導日',
    txt_global_std_won: '優勝',
    txt_global_std_hellolinus: 'こんにちはライナス！',
    txt_global_std_speedchangedescription: '加速、一定速度、減速に使った時間を示すグラフです。全般的なアドバイス',
    txt_global_std_p2pblockedthecar: '{name}が車をブロックしました',
    txt_global_std_above1000unlocked: '週間平均スコア1150以上 =5000ポイント\n',
    txt_global_std_letsdrive: '運転しましょう！',
    txt_global_std_easytogeteverydriverinyournetworkinvolved: 'ネットワーク内のすべてのドライバーを簡単に関与させる',
    txt_global_std_fiasdcisaninitiativetoreducepollutionandincreasesafetyontheraodswhiledrivingmotoristsgettheirdrivingassessedinrealtimebyouraitechnologythatunderstandseachtripslevelofriskandcarbonfootprintjustbymakingmotoristsawareoftheirimpactwecancreateapositivechange: 'Fia SDCは、汚染を減らし、道路の安全性を高めるためのイニシアチブです。運転中、ドライバーは、各旅行のリスクと二酸化炭素排出量のレベルを理解するAIテクノロジーによってリアルタイムで運転を評価します。ドライバーに彼らの影響を認識させるだけで、私たちは前向きな変化を生み出すことができます。',
    txt_global_std_VilliersQuote: 'Quote coming soon...',
    txt_global_std_membersinthisgroup: 'このグループのメンバー',
    txt_global_std_renewaldate: '更新日',
    txt_global_std_1000pointaverageachievementlocked: '1週間平均{scoretype} of {score}以上{scoretype} of {score}運転して、この実績を達成し、1000ポイントを獲得してください。',
    txt_global_std_carriedrisk: '保有リスク',
    txt_global_std_idrivetoomuch: 'I drive too much',
    txt_global_std_headtohead: '対戦',
    txt_global_std_thisisonlyneededthefirsttimeyouconnecttoyourcar: 'これは初めて車に接続する\nときのみ必要です。',
    txt_global_std_reducing: '減らす',
    txt_global_std_p2pgotocostsplit: 'コスト分割に移動',
    txt_global_std_p2psendalinksothatthepersonborrowingyourcarcandownload: 'クルマをシェアするメンバーを\n{appname}に招待しよう',
    txt_global_std_HansenQuote: 'Driving smart is fun! It saves me money and reduces my impact on our precious environment.',
    txt_global_std_PettersolbergShortDescription: '2014年&2015年の世界ラリークロス選手権チャンピオン, WRCで13回チャンピオン獲得, ラリーGB 2002年&2003年のチャンピオン',
    txt_global_std_choosebetweenenteringchassisnumberenginenumberplatenumberorpolicynumber: 'シャーシ番号/エンジン番号、プレート番号、またはポリシー番号を入力するかを選択します。',
    txt_global_std_p2pusesameemailasregisteredonkintoaccount: '* Kintoアカウントに登録されているものと同じメールアドレスを使用してください',
    txt_global_std_chooseaccidenttype: '事故の種類を選択してください',
    txt_global_std_choosefile: 'ファイルを選択',
    txt_global_std_alreadyexistspossibleduplicationinternalservererror: 'すでに存在し、重複の可能性があります。内部サーバーエラー',
    txt_global_std_cimage22d: 'クルーズコントロールは平坦な道路でのみ利用してください。平坦かどうかは、パーキングブレーキが必要かどうかで判断できます。',
    txt_global_std_p2pdoyouwanttobookthedaysinbetweenormakeitseparatebookings: '連続した予約にしますか？\n別々の予約にしますか？',
    txt_global_std_youraveragelevelis: '平均レベル',
    txt_global_std_bottomfinaliststext: 'ワールドチャレンジは、3つのチャレンジヒート、ヒート間の3つの予選期間、1つのグランドファイナルで構成されます。\n' +
      '\n' +
      '各チャレンジヒートの上位3人のドライバーは、12月のビッグファイナルに直接出場します。',
    txt_global_std_anerroroccurredwhilesavingyourchanges: '変更を保存中にエラーが発生しました',
    txt_global_std_activatelocationservice: '位置情報サービスを有効にしてください',
    txt_global_std_p2ppaynow: '今払う',
    txt_global_std_wheresmyoutlet: '出口はどこ？',
    txt_global_std_friendstothekintoapp: 'Kintoアプリに友だちを紹介',
    txt_global_std_createyouraccounttosaveandtrackthecostofyourtripsandgetaccesstoyourpersonaldrivingcoach: 'アカウントを作成して、旅行の費用を節約および追跡し、個人の運転コーチにアクセスします',
    txt_global_std_plugitinyourcaarsobdoutlet: "Plug it in your car's OBD-outlet. Normally located near the steering wheel, (Your dongle will be with you within 1-3 business days)",
    txt_global_std_WelcomeLetter_Text4: 'ようこそ！',
    txt_global_std_HSolbergInterests: 'Cars, rally & racing. Promotion days with my sponsors',
    txt_global_std_areyousureyouwanttoredeemxpointscoupon: '{x}ポイントを交換してもよろしいですか？',
    txt_global_std_p2pclickdatestoseebooking: '日付をクリックして予約を確認',
    txt_global_std_currentodometervalue: '現在の走行距離',
    txt_global_std_alreadyhavefiasdc: 'Already have FIA SDC?',
    txt_global_std_description: '概要',
    txt_global_std_uploadyourdatatoseehowsufficientitis: 'データをアップロードして、どれだけ十分かを確認してください。',
    txt_global_std_platenumber: 'プレートナンバー',
    txt_global_std_invalidcode: '無効なコード',
    txt_global_std_p2pyesremovefriend: 'はい、友達を削除します',
    txt_global_std_paylessnextmonth: '来月の保険料が安くなる',
    txt_global_std_KristofferssonQuote: 'Road safety is very close to my heart. The FIA Smart Driving Challenge will be such fun and smart as it sounds and it will let anyone improve their eco-friendly and safe driving. As I’ve been racing on every road condition you can imagine, I am now really looking forward to transmitting that knowledge to encourage smarter driving amongst all of us.',
    txt_global_std_p2pyour: 'あなたの',
    txt_global_std_youcancontactoneofourlicensedinsuranceagenciesbyclickingonfindanagentonourwebsiteorcallingagentatphonenumber: '当社のウェブサイトで「エージェントを探す」をクリックするか、{phoneNumber}で{agent}に電話することにより、認可された保険代理店の1つに連絡できます。',
    txt_global_std_renewyourinsurance: '保険を更新する',
    txt_global_std_p2pcost: '費用',
    txt_global_std_yesexchangepoints: 'ポイントを交換する',
    txt_global_std_oldpasswordhelp: 'We need your current password to ensure that you are the owner of this account.',
    txt_global_std_offlinetrip: 'オフライン',
    txt_global_std_uploadlogotype: 'ロゴタイプをアップロード',
    txt_global_std_donthaveanaccountsignuplink: 'アカウントをお持ちではありませんか？ {signUpLink}',
    txt_global_std_haveaproblemwiththeappvisitourfaqorcontactus: 'アプリに問題がありますか?よくある質問にアクセスするか、お問い合わせください...',
    txt_global_std_starburstunlockedkey: '4.4レベル以上でドライブしました！Kintoウォレットに300ポイント付与されました。',
    txt_global_std_aonceaclaimsettlementisdeterminedyoucanreceiveacheckoranelectronicpaymentintoyourbankaccount: 'A：請求の決済が決定されると、銀行口座に小切手または電子支払いを受け取ることができます。',
    txt_global_std_completewizard: 'ウィザードを完了してください',
    txt_global_std_enteremailtodostuff: 'メールを入力してメールを受信します。メールをリセットして、選択した新しいパスワードを設定できます。',
    txt_global_std_datasentsuccess: 'データが送信されました。受信トレイをご確認ください。',
    txt_global_std_on: 'オン',
    txt_global_std_whatifichangecar: '車を替えたらどうなりますか？',
    txt_global_std_connecttotheprovidedobddeviceifyourcar: '車にBluetooth 機能がない場合は、お手元のOBDデバイス（Enerfy Mini OBD）に接続してください。',
    txt_global_std_selectyourcarmodeltoseethemostaccuratedataofsavedco2: '車両モデルを選択して、節約された CO2 の最も正確なデータを表示します。',
    txt_global_std_forgotyourpassword: 'Forgot your password?',
    txt_global_std_getacoscoreof15orlowerstartdrivingtogetyourscore: '15％以下のCO₂スコアを取得します。あなたのスコアを取得するために運転を開始してください！',
    txt_global_std_TaxInvoice3: 'Doc No',
    txt_global_std_enerfyplugin: 'Enerfyプラグイン',
    txt_global_std_addnoteshere: 'メモを追加',
    txt_global_std_best: '最高',
    txt_global_std_p2pnogoback2: '退出せず、戻る',
    txt_global_std_fitnessmotion: 'モーション',
    txt_global_std_p2pyourkintonumbercanbefoundwhenyoulogintoyourkintoaccount: 'Kintoアカウントにログインすると、Kinto番号が表示されます。',
    txt_global_std_superstar: '安全運転',
    txt_global_std_doihavetoactivatetheappeverytimeidriveanswer: 'いいえ、アプリはバックグラウンドモードで動作します。スマートフォンでBluetoothと位置情報サービスを常に有効にしてください。',
    txt_global_std_sorrywecouldnotfindthatemailaddresspleasecontactagentsupport: '申し訳ありませんが、そのメールアドレスは見つかりませんでした。 {agent}サポートに連絡してください。',
    txt_global_std_ayoucancontactoneofourlicensedinsuranceagenciesbyclickingonlinkorcallingagentatphoneno: 'A：{link}をクリックするか、{phoneNo}で{agent}に電話することで、認可された保険代理店の1つに連絡できます。',
    txt_global_std_thisisonlyneededthefirsttimeyouconnecttoyourcar1: 'これは初めて車に接続する\nときのみ必要です。',
    txt_global_std_somethingwentwrongandwecouldnotdisplayyourresulthere: 'エラーが生じて今回のドライブデータを今表示できません。',
    txt_global_std_someoneelseispairedtoyourcarnodot: '他の誰かがあなたの車とペアになっています',
    txt_global_std_selectagreementnumber: '契約番号を選択',
    txt_global_std_thisfunctionisunstable: 'この機能は安定しておりません。ご使用の場合はご注意ください。',
    txt_global_std_p2pfriendcalendar: '{name}のカレンダー',
    txt_global_std_hourh: '時',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweek: 'あなたは本当にうまく運転しました！ 1週間の平均{scoretype}は{score}よりも良かったです。',
    txt_global_std_vehicledata: '車両データ',
    txt_global_std_amazingdriver: '素晴らしいドライバー',
    txt_global_std_bothyouandyourfriendwillget10kmfreedistancetodrivewithenerfy: '友だちと一緒に10kmの無料走行をゲット!',
    txt_global_std_confirmyouremailaddress: 'メールアドレスを確認してください',
    txt_global_std_rwmgoback: '閉じる',
    txt_global_std_appcreator_upsell_pitch_bulletpoint1: 'ブランドカラーとロゴタイプを取り入れて、デジタルカスタマージャーニーを最適化します',
    txt_global_std_yourco2overview: 'CO2の概要',
    txt_global_std_excessdiscount: '超過割引',
    txt_global_std_addressline2: '住所2',
    txt_global_std_inbonusforyourinsurance: 'あなたの保険のボーナスで。',
    txt_global_std_didyoureceivethepayment: 'お支払いは受けましたか？',
    txt_global_std_ensammenligningmeddennekreturoggennemsnittetafalledineturedeseneste30dage: 'Ensammenligningmeddennekøreturoggennemsnittetafalle dine ture de seneste30dage。',
    txt_global_std_createyourapp: 'アプリを作成する',
    txt_global_std_dashboard_users: 'ユーザー',
    txt_global_std_ifbluetoothisalreadyactivatedyoucanstillgetthiserror: 'Bluetoothが既に有効になっている場合でも、携帯電話でBluetoothがオンになっていないと、このエラーメッセージが表示されます。',
    txt_global_std_notificationsettings: '通知設定',
    txt_global_std_loadingevents: 'イベントをロード中…',
    txt_global_std_firstname: '名',
    txt_global_std_hassentyouachallenge: 'さんから対戦リクエストが届いています！',
    txt_global_std_wrongpasswordformatminimum8charactersatleastoneuppercaseletteronelowercaseletterandonenumber: 'パスワードの形式が間違っています。最小8文字、少なくとも1つの大文字、1つの小文字、および1つの数字。 ',
    txt_global_std_pigwon: 'head2headの勝者',
    txt_global_std_personalizeyourchallenge: 'チャレンジをパーソナライズ',
    txt_global_std_thereisnopermissions: '権限はありません',
    txt_global_std_claimsdata: '請求データ',
    txt_global_std_badinternetconnection: '悪いインターネット接続。これにより、アプリの遅延が発生し、運転データに影響を与える可能性があります。',
    txt_global_std_test_key22: 'これはテスト2です',
    txt_global_std_6months: '6ヵ月',
    txt_global_std_groupvsgroup: 'グループ対グループ',
    txt_global_std_reportaccidentonline: 'オンラインで事故を報告する',
    txt_global_std_date: '日付',
    txt_global_std_communicate_upsell_pitch_bulletpoint2: '好みのチャネルでユーザーとつながる',
    txt_global_std_v2risklevel: 'リスクレベル',
    txt_global_std_hereyoucanloweryoursubscriptioncostbyupto20permonthbydrivingsafe2: 'ここでは、安全運転を行うことで、サブスクリプションコストを月額最大20％削減できます。',
    txt_global_std_ifyouareaninsurancecustomeryoucanfindyourpolicynumberwhenyoulogintoyourappnameaccount: '保険の顧客の場合は、{appname}アカウントにログインするとポリシー番号を見つけることができます。',
    txt_global_std_above1000streaklocked: '週間平均スコア1150以上 =5000ポイント\n',
    txt_global_std_loadingusers: 'ユーザーを読み込んでいます...',
    txt_global_std_createapikey: 'APIキーを作成する',
    txt_global_std_unlockeddescriptionlabel: 'アプリに表示される、ロック解除された説明',
    txt_global_std_totalpointscollected: '獲得ポイント数の合計',
    txt_global_std_p2ptogetaccesstoallfunctionsinthisappyouneedtobeinvitedbyakintocarowner: 'このアプリのすべての機能にアクセスするには、Kintoの車の所有者から招待される必要があります。',
    txt_global_std_hour: '時間',
    txt_global_std_yourteam: 'あなたのチーム',
    txt_global_std_p2plendcar: 'クルマを貸す',
    txt_global_std_demotext1: 'スマートドライブでCO2を削減しましょう。CO2を削減するにはこちら。',
    txt_global_std_p2pconfirmselection: '選択した日時を確定する',
    txt_global_std_skeylevel: 'レベル',
    txt_global_std_terminateinsurance2: 'Terminate Insurance',
    txt_global_std_achievements: '成績',
    txt_global_std_applyfilters: 'フィルターの追加',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsabouttheagentappandcompeteagainstthemforlowerscores: 'A：アプリを車両のBluetoothとペアリングし、アプリが位置情報サービスを使用できるようにすると、AIのドライバーパターンのライブラリに運転習慣が一致し、スコアが提供されます。あなたの保険料はあなたの車を運転する人と彼らがどれだけうまく運転するかに基づいています。あなたの車を運転している人は誰でも、アプリをダウンロードして彼らのスコアを追跡することをお勧めします。 {agent}アプリについて友達に知らせて、スコアを下げて友達と競います。',
    txt_global_std_carsharingcompany: 'カーシェアリング会社',
    txt_global_std_communicate_upsell_pitch_bulletpoint3: '状況に応じたメッセージを適切なタイミングで伝える',
    txt_global_std_p2pwantstoborrowyour: '借りたい車',
    txt_global_std_editgroup: 'グループを編集',
    txt_global_std_coreresources: 'コア リソース',
    txt_global_std_highrisk: '高リスク​',
    txt_global_std_cosavings: 'CO２ 削減',
    txt_global_std_fitnessreasontext: 'あなたが運転しているか歩いているかを判断するには、フィットネス/モーションが必要です。',
    txt_global_std_p2perrorcostcannotbegreaterthantotalcosttosplit: 'エラー 合計金額よりも高い金額は設定できません。',
    txt_global_std_register: '登録する',
    txt_global_std_Unsubscribe: 'リストから削除するにはここをクリック。',
    txt_global_std_team: 'チーム',
    txt_global_std_greatdriving: '安全な運転です！',
    txt_global_std_aroundtheclock: '24時間体制',
    txt_global_std_enteravalidvehicleidentification: '有効な車両IDを入力してください。',
    txt_global_std_createnewbtntext: '新しく作る',
    txt_global_std_everydayhero: '日常のヒーロー',
    txt_global_std_resultofanalyse: '分析結果',
    txt_global_std_MSIG_Onboarding3: 'which is valid from',
    txt_global_std_p2paccept: '承諾する',
    txt_global_std_cimage3h: '減速を予測しましょう',
    txt_global_std_goldlevel: 'ゴールドレベル',
    txt_global_std_p2puserborrowedcartimesph: '{user} が {car} を {times}回借りました。',
    txt_global_std_smartdriverweeklylockedkey: 'このバッジを獲得するには、1 週間 3.5 以上のスコアを維持してください。',
    txt_global_std_p2ppushbodynewpaymentquestion: '{name}さんがわりかんの支払いをしたので、「支払済み」に変更してください',
    txt_global_std_howdoicompeteinappchallenge: '{appname}チャレンジに参加するにはどうすればよいですか？',
    txt_global_std_forthisapptoworkproperlylocationservicemustbeallowed: 'アプリを正常に動作させるため、デバイスの位置情報サービスを有効にしてください。位置情報の設定で「常に」を選択してください。',
    txt_global_std_mapsarenotavailableforthisperiod: 'この期間のマップはご利用になれません',
    txt_global_std_faq: 'よくある質問',
    txt_global_std_searchdivision: '検索部門',
    txt_global_std_of: 'の',
    txt_global_std_PolicySchedule12: 'Cover Type',
    txt_global_std_chooseyouroption: 'オプションを選択してください：',
    txt_global_std_rwmsheettext: '友だちにアプリダウンロード用の​\n' +
      '招待リンクを送信します。友だち​\n' +
      'がアプリのダウンロードを行い、​\n' +
      'アカウントの作成まで完了すると​\n' +
      '友だちリストに友だちが表示されます。​\n' +
      'その後、ライドシェアした運転と​\n' +
      '友だちを登録してください。',
    txt_global_std_PaddonQuote: "Don't just dream it, achieve it! Do it for the planet!",
    txt_global_std_locked_account: '繰り返しログインに失敗したため、このユーザーはロックされました。1時間後に再度お試しください。',
    txt_global_std_30days: '30日',
    txt_global_std_account: 'konto',
    txt_global_std_challengedashboard: 'チャレンジダッシュボード',
    txt_global_std_youcanonlyregisteraridesharingtriponceperweek: '1週間に1回まで登録できます',
    txt_global_std_thephonenumbercannotincludespecialcharacterslikes: "電話番号に「 '？＆/ &#60; &#62; \\ s＃！％$％^ *-」などの特殊文字を含めることはできません",
    txt_global_std_writemessage: '小さなメッセージを書く',
    txt_global_std_costoverview: 'コストの概要',
    txt_global_std_p2pyouandmembersborrowingyourcarneedtoconnecttheapptoyourcarsbluetooth: '車とアプリをBluetoothで接続してください。',
    txt_global_std_yourreportisready: 'レポートの準備ができました。',
    txt_global_std_needstobeavalidphonenumbermustbeavalidsizeandcanonlyincludenumbersand: '有効な電話番号である必要があります。有効なサイズである必要があり、数字と+のみを含めることができます',
    txt_global_std_week: '週',
    txt_global_std_reviewbeforepublishing: '公開前のレビュー',
    txt_global_std_addnewuser: '新しいユーザーを追加する',
    txt_global_std_p2pfaq3q: 'クルマがどこに停車しているかわからないのですが、どうすればいいですか？',
    txt_global_std_typeofscore: 'スコアの種類',
    txt_global_std_p2pthefollowingbonuswillbegiven: '以下のポイントが付与されます。',
    txt_global_std_JyrkiainenFacts: 'Been growing up surrounded by motorsport in a racing family, daughter of Minna Sillankorva, 1991 Ladies Rally World Champion and nine-time Finnish Champion',
    txt_global_std_yourtripsinsight: 'あなたの旅行の洞察',
    txt_global_std_account1: 'アカウント',
    txt_global_std_toptrips: 'トップトリップ',
    txt_global_std_fax: 'ファックス',
    txt_global_std_highusageparamamount: '使いすぎ {amount}.　使いすぎ{amount}',
    txt_global_std_startyourcarandmakesureyourbluetoothison: '',
    txt_global_std_selectaminimumoftwogroupsthatshouldcompeteagainsteachotherinthechallenge: 'チャレンジで互いに競うグループを 2 つ以上選択します。',
    txt_global_std_examplevalue: '例の値',
    txt_global_std_PolicySchedule1: 'SCHEDULE',
    txt_global_std_p2ppaywith: 'で確定する。',
    txt_global_std_german: 'ドイツ人',
    txt_global_std_presstoconnect: '押して接続',
    txt_global_std_connectiontoyourcar: 'Connection to your car',
    txt_global_std_buyfiasdc: 'FIA SDCを購入する',
    txt_global_std_copiedtoclipboard: 'クリップボードにコピーしました！',
    txt_global_std_clickheretoseewhy: 'その理由はこちらをご覧ください。',
    txt_global_std_therequestwasunacceptableoftenduetomissingarequiredparameter: '多くの場合、必要なパラメーターが欠落しているために、要求は受け入れられませんでした。',
    txt_global_std_thedescriptioncantbelongerthan180characters: '説明は180文字を超えることはできません',
    txt_global_std_previewapp: 'プレビューアプリ',
    txt_global_std_usersuccessfullyadded2: 'ユーザーが正常に追加されました',
    txt_global_std_waitingtodownloadyourapp: 'アプリのダウンロードを待っています',
    txt_global_std_fiaquarterfinalparticipaterule2020: '予選ファイナルに参加するには、トップ20ドライバーの1人、またはトップ3チームのトップ3ドライバーの1人になる必要があります。',
    txt_global_std_aphonenumbermayonlyincludenumerics: 'A phone number may only include numerics',
    txt_global_std_challengesuccessfullyremoved: 'チャレンジは正常に削除されました',
    txt_global_std_topfeatures: '主な機能',
    txt_global_std_p2pkintomonthlyfee: 'キント月額料金',
    txt_global_std_pickyourcolortheme: 'あなたの色のテーマを選んでください',
    txt_global_std_calledsalvage: 'Called Salvage 要請した救援',
    txt_global_std_toreportitnow: '今それを報告します。',
    txt_global_std_cimage9d: 'クルーズコントロールは平坦な道だけにしましょう。それ以外は燃費優先で。',
    txt_global_std_downloadingdata: 'データをダウンロード中',
    txt_global_std_confirm2: '確認',
    txt_global_std_cimage19d: '速度ではなく、燃料を計画的に！',
    txt_global_std_sendchallengeinvitation: 'チャレンジ招待状を送信する',
    txt_global_std_validforxxminutes: ' (コードは6分間有効です)',
    txt_global_std_TaxInvoice6: 'IMPORTANT NOTICE:',
    txt_global_std_male: '男性',
    txt_global_std_getanoverviewofyourcustomerportfolio: '顧客ポートフォリオの概要を把握する',
    txt_global_std_exceededinsureddistance: '年間走行距離を超えているため、全額補償は適用されません。走行距離を調整し、保険適用いたしますのでお問い合わせください。',
    txt_global_std_howwilligetpaidforaclaim: 'クレームの支払いはどのように受けられますか？',
    txt_global_std_pleasecontactsupportlinkalllowercaseifyouhavenotreceivedthereaderafter7businessdays: '7営業日経ってもリーダーが届かない場合は、{contactSupportLinkAllLowerCase}までご連絡ください。',
    txt_global_std_howwewantyoudata: 'データの入手方法',
    txt_global_std_getinsured: '保険に加入する',
    txt_global_std_viewwhoisparticipatinginthechallenge: 'チャレンジに参加している人を表示する',
    txt_global_std_resendtemporarypassword: '一時パスワードを再送する',
    txt_global_std_day: '日次',
    txt_global_std_sendalinksothatthepersondrivingyourcarcandownloadtheapp: '車の運転者がアプリをダウンロードできるようにリンクを送信します。',
    txt_global_std_documentcar: '車両を登録する',
    txt_global_std_competitionrulesapplied: '適用される競技規則',
    txt_global_std_renewinsurance: '保険を更新する',
    txt_global_std_incabtraining: '添乗指導',
    txt_global_std_testingnewfronttranslation3: 'テストnewfronttranslation3',
    txt_global_std_reporthistory: 'レポート履歴',
    txt_global_std_yournicknameshouldcontainatleastthree2: 'ニックネームは3文字以上で特殊文字は使用しないでください。',
    txt_global_std_seeperiod: '詳細',
    txt_global_std_areyousureyouwanttodeletethisitem: 'このアイテムを削除してもよろしいですか?',
    txt_global_std_validto: 'まで有効',
    txt_global_std_downloadpersonaldata: 'ご利用状況の確認',
    txt_global_std_seedemo: 'デモを見る',
    txt_global_std_MSIG_Onboarding6: 'What you have to do now is:',
    txt_global_std_worldchallenge2022: 'ワールドチャレンジ2022',
    txt_global_std_sidekickprivacypolicy: 'プライバシーポリシー',
    txt_global_std_fiaheatrule2020: '10km以上走行している参加者が\nランキング対象です',
    txt_global_all_weekend: '土日,',
    txt_global_std_Fia_Receipt_Text12: 'If you have any questions, please send us an email:',
    txt_global_std_p2pselectedowner: '選択された所有者',
    txt_global_std_MSIG_InsuranceLetter30: 'MS First Capital Insurance Ltd',
    txt_global_std_automotivecompanydescription: '新しい所有モデルとドライバーの関与。',
    txt_global_std_yourinsuranceexpires: '保険の有効期限が切れます：{insuranceenddate}保険の有効期限が切れる前に、走行距離計の新しい写真を撮ることを忘れないでください。',
    txt_global_std_location2: '場所',
    txt_global_std_youneedtofillinallnecessaryfields: '必須項目はすべて記入してください',
    txt_global_std_retry123: 'リトライ',
    txt_global_std_apicreator: 'APIクリエーター',
    txt_global_std_resetyourpassword: 'あなたのパスワードをリセット',
    txt_global_std_hello: 'こんにちは',
    txt_global_std_nogoback: 'いいえ、戻る！',
    txt_global_std_KristofferssonShortDescription: '世界ラリークロス選手権 2017 &2018',
    txt_global_std_p2pdeny: '拒否',
    txt_global_std_youhavereducedtheaveragecoemissionsofyourcarby: 'CO2 を平均で {percent}削減しました',
    txt_global_std_p2pleftthegroup: 'さんがグループを退出しました。',
    txt_global_std_notavailableindemo: 'デモでは利用できません',
    txt_global_std_publishunpublish: '公開/非公開',
    txt_global_std_wanttojoinanevent: 'イベントに参加しますか？',
    txt_global_std_PSolbergWhyjoin: 'Coming soon...',
    txt_global_std_moveyourselfandotherstosecure: '安全な場所に移動する。ケガの場合は電話をかける。',
    txt_global_std_p2pnotavalidprice: '有効な金額を入力してください。',
    txt_global_std_cimage37h: 'さまざまな速度での停止距離を身につけましょう。',
    txt_global_std_hereswhereyouhavethelargestimprovementpossibility: '一番改善しやすいポイントです',
    txt_global_std_areyousureyouwanttodeletethechallengeoncedeletedyouwillnolongerbeabletoseethechallengeresultsparticipantswillstillbeabletoseetheirtripsinthefiasdcapp: 'チャレンジを削除してもよろしいですか？削除すると、チャレンジの結果を表示できなくなります。参加者は引き続きFIASDCアプリで自分の旅行を見ることができます。',
    txt_global_std_year: '年',
    txt_global_std_phonenumbercannotincludecharacterslike6062s: "電話番号「'？＆/＆＃60;＆＃62; \\ s＃！％$％^*-」のような文字を含めることはできません",
    txt_global_std_whenyouhavecompletedyourfirstdrivewithenerfy: 'Enerfyで初ドライブ後に、費用と請求内容をご確認いただけます。',
    txt_global_std_onboardingtype: 'オンボーディングの種類',
    txt_global_std_enteravalidemailaddress: '有効なメールアドレスを入力してください。',
    txt_global_std_showstimesincethevehiclehasbeenparked: '車両が駐車されてからの時間を表示します。',
    txt_global_std_200kmlockedkey: 'このバッジを獲得するには、200 km の距離を運転してください。',
    txt_global_std_twofactorenabled1: '二要素対応',
    txt_global_std_examplemessagesms: 'こんにちは！これはテキスト メッセージの例です。',
    txt_global_std_p2pspecifiedprice: '承諾された予約',
    txt_global_std_oilandoilfilter: 'オイルとオイルフィルター',
    txt_global_std_maximizeheader: '最大化',
    txt_global_std_daysdriven: '運転日数',
    txt_global_std_p2pwithfamilyandfriends: '車をシェアする',
    txt_global_std_p2pfaq9q: 'メンバーが早くクルマを返したい場合、どうすればいいですか？',
    txt_global_std_joinclubsevent: 'クラブのイベントに参加する',
    txt_global_std_thesamegeographicalroutewillbescoredamaximumof10timesinoneday: '•同じ地理的ルートは、1日に最大10回スコアリングされます。',
    txt_global_std_livetopdrivers: 'トップドライバー中継',
    txt_global_std_p2pcostsplitinvoice: 'コスト分割請求書',
    txt_global_std_news: 'アクティビティ',
    txt_global_std_authenticatedrequest: '認証されたリクエスト',
    txt_global_std_enterpasswordtoremove: '削除するパスワードを入力してください',
    txt_global_std_loadingapps: 'アプリを読み込んでいます...',
    txt_global_std_SendMyPosition_Hello: 'Hello',
    txt_global_std_basecolor: 'ベースカラー',
    txt_global_std_enddateisrequired: '終了日が必要です',
    txt_global_std_sendalinksothatthepersondrivingyourcarcandownloadtheappnameapp: '車を運転している人が{appname}アプリをダウンロードできるようにリンクを送信します。',
    txt_global_std_reportaccidentbottomtext: 'レポートから24時間以内に車の写真をアップロードしてください。上の古いレポートをクリックして、写真を追加できます。',
    txt_global_std_therearenoongoinggamesrightnow: '開催中の試合はありません。',
    txt_global_std_loginsignup: 'ログイン / 新規登録',
    txt_global_std_alertweredrivingyoudrivingbody: '運転スコアを取得するには、アプリでドライブを開始および停止することを忘れないでください。',
    txt_global_std_connectyourgarminwatch: 'Garmin ウォッチを接続する',
    txt_global_std_pickendtime: '終了時間を選択',
    txt_global_std_NewPW_Text3: 'は：',
    txt_global_std_BeforeFirstBill_Text2: '支払うのは何ですか？',
    txt_global_std_wildfirelockedkey: '1週間、平均4.4レベル以上を達成すると、Kintoウォレットに5000ポイント付与されます。',
    txt_global_std_p2ptoconfirmprice: '予約を確認してください。',
    txt_global_std_selectgroupsintheformandclickonthegeneratebuttonaccessthecode: 'フォームでグループを選択し、生成ボタンをクリックしてコードにアクセスします',
    txt_global_std_or: 'または',
    txt_global_std_4thdate: '4日',
    txt_global_std_setupmanualstartstop: 'セットアップ：手動スタート＆ストップ',
    txt_global_std_yourdrivingstatsforthistripcomparedtoyour30daysaverage: '30日間の平均と比較した、\n今回の運転統計',
    txt_global_std_wrongformat: 'フォーマットが間違っています！',
    txt_global_std_driventimeperperiod: '周期ごとの駆動時間',
    txt_global_std_speed: '速度',
    txt_global_std_events_overview_total: '合計',
    txt_global_std_MSIG_InsuranceLetter41: 'Road Transport Act, 1987 (Malaysia)',
    txt_global_std_appcreator: 'アプリクリエーター',
    txt_global_std_earnpercentpremiumreductionperperiod: '期間ごとに{percent}のプレミアム削減を獲得',
    txt_global_std_activationcodevalidated: 'アクティベーションコードが検証されました',
    txt_global_std_countday: '{count}日',
    txt_global_std_selectfriend: '友だちを選ぶ',
    txt_global_std_july: '7月',
    txt_global_std_ConfirmFia_Text7: '予約枠の10分前までに会場に集合してください。',
    txt_global_std_KristofferssonChamp: 'World RX Champion 2017 & 2018',
    txt_global_std_wewillnowbuildyourdemoapp: 'デモアプリを作成します。',
    txt_global_std_youdroveextremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedthisbadge: 'あなたは非常によく運転しました！ 1週間の平均{scoretype}は{score}よりも良かったです。このバッジを獲得しました！',
    txt_global_std_congrats: 'おめでとう！',
    txt_global_std_p2ppushtitlehasnowfinalizedthebooking: '予約が終了されました',
    txt_global_std_noinvoicesyet: '運転データはありません',
    txt_global_std_consentInfoNo: '設定で \\consent\\ が無効です。 \\consent\\ が無効の場合、コンペに関する情報やアドバイスを継続的に受け取ることができません。',
    txt_global_std_friends: '友だち',
    txt_global_std_searchbynamegrouporscore: '名前、グループ、またはスコアで検索',
    txt_global_std_balancing: 'バランシング',
    txt_global_std_ChicheritInterests: 'Coming soon...',
    txt_global_std_ayourpolicybeginsonthedateandtimewhenyouelectronicallysigntheapplicationandmakethedownpaymentonyourpolicy: 'A：ポリシーは、アプリケーションに電子署名し、ポリシーの頭金を支払う日時から始まります。',
    txt_global_std_manageagreementshere: 'ここで契約を管理します',
    txt_global_std_inmostcarstheobdsocket: 'ほとんどの車では、OBDソケットはハンドルの下の左側にあります。それでもOBDソケットが見つからない場合は、ここをクリックしてください。',
    txt_global_std_p2pgiveadiscountiffriend: '友だちがあなたの車を安全に運転したら、割引しましょう。',
    txt_global_std_improvementspeedarea: '改善できる速度エリア',
    txt_global_std_otherreasonsthatyourcarisnotshowingupinthelist: 'あなたの車がリストに表示されないその他の理由：',
    txt_global_std_success: '完了しました',
    txt_global_std_phonenumberforinsurance: '保険に加入したときに使用した電話番号を入力します。',
    'txt_global_std_Al-KhelaifiWhyjoin': 'Because I enjoy different types of challenges and I am a competitive driver.',
    txt_global_std_ifyoureferafriendyoubothgetnok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsuranceamountinmarkedfontshowsvalidamountsaddedtothepiggybankpaymentinordertoreceiveadrivingdividend75ofthedistancetraveledmustbecompletedwiththeappandchiptenfoldremembertohaveboththeappandbluetoothonwhenyoudriveandthetripwillberegistered: '友達を紹介すると、貯金箱で250ノルウェークローネがもらえます。これには、保険の開始日から少なくとも1か月間、両方のTrygの顧客である必要があります。マークされたフォントの金額は、貯金箱に追加された有効な金額を示します。支払い\n' +
      '運転配当を受け取るには、移動距離の75％をアプリとチップで10倍完了する必要があります。運転するときはアプリとBluetoothの両方をオンにすることを忘れないでください。そうすれば、旅行が登録されます。',
    txt_global_std_startdrivingsaferwithmefollowthelinkbelowandget10kmdistancetodrivewithenerfy: 'より安全運転で！以下のリンクをクリックし、Enerfyとの10㎞ドライブをゲット。',
    txt_global_std_parkingaccident2: '駐車事故',
    txt_global_std_achievementstitle: '実績',
    txt_global_std_maintainingintendedspeedisutmostimportantforfueleconomymindwanderingandlackoffocusoftencausesspeedvariationupanddowncausingunnecessaryaccelerationshenceincreasesfuelconsumptionnew: '意図した速度を維持することは、燃費にとって最も重要です。頭のふらつきや集中力の欠如は、速度の上下変動を引き起こし、不必要な加速を引き起こし、燃料消費を増加させます。',
    txt_global_std_inthenextpopupbr1makesurebprecisebissettobonbbr2chooseballowonceb: '次のポップアップで：<br> 1. <b>正確</b>が<b>オン</b>に設定されていることを確認します。<br> 2. <b>一度許可</b>を選択します。',
    txt_global_std_p2pgoheretoinvitemembersandstartlendingyourcar: 'メンバーを招待して車の貸し出しを始めるには、ここにアクセスしてください。',
    txt_global_std_p2piamhavingproblemswiththelinkloggingin: 'リンク/ログインに問題があります。',
    txt_global_std_allowpushnotifications: 'プッシュ通知を許可する',
    txt_global_std_currency: 'kr',
    txt_global_std_subscribe: '申し込む',
    txt_global_std_MikkelsenFacts: "Andreas has a broad background in sports. He has competed in alpine skiing and was a member of the national juniors' alpine skiing team. In 2003 and 2004, he competed in motocross, also representing the national juniors' team. He finished third in the world rally championship in 2014, 2015 and 2016, collecting three wins and 20 podiums.",
    txt_global_std_expireson: '有効期限',
    txt_global_std_sentlogfiles: '送信されたログ ファイル',
    txt_global_std_athelowerthescorethemoresavingsyoucanearnyouwillseeyourpotentialsavingswitheachtrip: 'A：スコアが低いほど、CO2排出量をより多く削減できたことになります。',
    txt_global_std_encouragetoavoiddistractions: '気を散らすものを避けるようドライバーに促す',
    txt_global_std_nationalitycolon: '国籍',
    txt_global_std_pleaseselectvehiclesregistrationnumberyouaredriving: '運転している車両の登録番号を確認してください。',
    txt_global_std_latesttrips: '最新のトリップ',
    txt_global_std_avgscore123: 'リスクレベル',
    txt_global_std_other: 'その他',
    txt_global_std_twofactorauthenticationcode: '２段階認証コード',
    txt_global_std_writeamessagetoafriendoragroup: '友達やグループにメッセージを書く',
    txt_global_std_resendactivationcode: 'アクティベーションコードを再発行する',
    txt_global_std_personalizeyouraccount: 'アカウントをパーソナライズする',
    txt_global_std_achievementtool_upsell_pitch: '独自の刺激的なバッジと実績を作成してください。',
    txt_global_std_addemail2: 'メールを追加',
    txt_global_std_addexistinguser: '既存ユーザーを追加する',
    txt_global_std_miles: 'マイル',
    txt_global_std_drivewithanaveragescoretypeofscoreorbetterforaweektounlockthisbadge: 'このバッジのロックを解除するには、平均{scoretype}が{score}以上の状態で1週間運転してください。',
    txt_global_std_p2pfaq6a: '1.\t退出したいグループチャットを開きます。\n2.\t画面右上にある、設定ボタンを選択します。\n3.\t「グループを退出する」を選択します。',
    txt_global_std_writeyourregistrationnumber: '登録番号を入力',
    txt_global_std_p2pcarrentoverview: '借りた車の概要',
    txt_global_std_cimage35h: '発進に最適なギアを探しましょう',
    txt_global_std_Fia_Receipt_Text4: 'You can enter this manually when creating an account.',
    txt_global_std_discovermore: 'もっと見る',
    txt_global_std_BeforeFirstBill_Text5: 'コストは{appName} アプリで記録できます。走行ごとと1か月全体の費用を確認できます。 アプリ内の\\Costs\\ をご確認ください。',
    txt_global_std_coolcatsunisshining: '涼しい猫の太陽が輝いています',
    txt_global_std_riskydecisionwithintrips: '走行中の危険な判断',
    txt_global_std_apireference: 'API リファレンス',
    txt_global_std_defaulthere: '新規登録する。',
    txt_global_std_above1000locked: '週間平均スコア1100! =1000ポイント\n',
    txt_global_std_allownotifications: '通知を許可する',
    txt_global_std_previousbonusperiod: '前のボーナス期間',
    txt_global_std_challengeaverage: 'チャレンジの統計データ',
    txt_global_std_uploadatleastfourphotosofyourcarfrontbackleftandrightsides: '車の写真を4枚以上アップロードしてください。\nドライブを開始する前に必要な情報です。\n例：前面、背面、左側面、右側面\n',
    txt_global_std_teamleaderslatestscore: 'チームリーダーの最新スコア',
    txt_global_std_p2ppleaseselectavehicle: '車を選んでください。',
    txt_global_std_wallet: '財布',
    txt_global_std_earnedpoints: '獲得ポイント',
    txt_global_std_cohabiting: '同棲',
    txt_global_std_radiatorflush: 'ラジエーターフラッシュ',
    txt_global_std_single: 'シングル',
    txt_global_std_p2pincompletebooking: '予約は完了していません。',
    txt_global_std_electric: 'EV',
    txt_global_std_notallowedtoorderanobdreader: 'OBDリーダーの注文は許可されていません',
    txt_global_std_finishadriveatscoretypescoreorbettertounlockthisachievementandearnpointspoints: '{score}以上でドライブを終了し、この実績をアンロックして{points}ポイントを獲得してください。',
    txt_global_std_tinaQuote: 'Power from within & the Power of choice',
    txt_global_std_threecharactersbetweenazand09: 'A～Z、0～9の3文字以上',
    txt_global_std_saveenergyandco2: 'エネルギーとCO2を削減​',
    txt_global_std_trainingdate: '指導日',
    txt_global_std_successsenddetails: '詳細を送信しました',
    txt_global_std_sendinvitetofriend: '友達に招待状を送る',
    txt_global_std_pigsinvitedlocked: 'Sidekickに友達を募集したときにこれを取得します',
    txt_global_std_nodatafoundstartdrivingtoseethisstatistic: '該当するデータがありません。運転を開始した後にこの統計を確認することがで<br>きます。',
    txt_global_std_okdriving: 'OK 続けて!',
    txt_global_std_Fia_Receipt_Text5: '',
    txt_global_std_poweredby: '搭載',
    txt_global_std_demotext6: '安全運転をサポート！5つ星をめざしてご案内します。',
    txt_global_std_addall: 'すべて追加する',
    txt_global_std_insuranceoverview: '保険の概要',
    txt_global_std_HansenInterests: 'Training, motorsport, and barista',
    txt_global_std_useralreadyexists: 'Could not create the account, e-mail already exists!',
    txt_global_std_rwmnodrivestitle: '運転データがありません',
    txt_global_std_editchallenge: 'チャレンジの編集',
    txt_global_std_monthlycost: '月額費用',
    txt_global_std_welcometoyournewapp: '新しいアプリにようこそ',
    txt_global_std_weknowthisiscrazy: '位置情報サービスを設定する​',
    txt_global_std_ayoucanfileaclaimbycontactingphonenoorclickingonfileaclaiminyouragentapp: 'A：{phoneNo}に連絡するか、{agent}アプリで[申し立てを行う]をクリックすると、申し立てを行うことができます。',
    txt_global_std_damageguide: 'ダメージガイド',
    txt_global_std_cimage36h: '障害物に遭遇した場合の急停止はやめましょう。',
    txt_global_std_speedgappoints: '速度差があった地点',
    txt_global_std_howdoisetupautomaticrenewal: '自動更新を設定するにはどうすればよいですか？',
    txt_global_std_availablepoints: '利用可能なポイント',
    txt_global_std_locationservicealways: '位置情報サービス（常に有効）',
    txt_global_std_signupwith: 'Sign up with {name}',
    txt_global_std_enteravalidplatenumber: '有効なプレート番号を入力してください。',
    txt_global_std_p2pusershareofcost: '{name}のわりかん分',
    txt_global_std_finalresult: '最終結果',
    txt_global_std_focus: '集中力',
    txt_global_std_yourbluetoothisntturnedon: 'Bluetoothがオンになっていません',
    txt_global_std_nosplitsavailableforthisrun: 'No splits available for this run　この走行用のスプリットがありません',
    txt_global_std_centralzone: '中央ゾーン',
    txt_global_std_technicalservice: 'テクニカルサービス',
    txt_global_std_cimage9h: 'スピードより燃費を',
    txt_global_std_okdriving2: '運転OK',
    txt_global_std_coverage: '対象',
    txt_global_std_selectaccount: 'アカウントを選択',
    txt_global_std_ifyouhavebluetoothyoucanturnonautomatic: '車にBluetoothが搭載されている場合は、自動起動と自動停止をオンにできます。',
    txt_global_std_p2pgroupnamechanged: '変更後のグループ名',
    txt_global_std_address: '住所',
    txt_global_std_discoverourheats: 'イベントを見る',
    txt_global_std_sendmessage: 'メッセージを送る',
    txt_global_std_p2paddedby: 'によって追加',
    txt_global_std_turnonhazardlights: 'ハザードランプをつける',
    txt_global_std_soon: 'まもなく',
    txt_global_std_alluserscompeteindividually: 'すべて、ユーザーは個別に競争します',
    txt_global_std_ecofriendweeklyunlockedkey: 'この1週間で\n{co2value}%のCO₂排出を\n削減することができました。\n環境にやさしい\n運転をありがとうございます！',
    txt_global_std_thepasswordminimumlengthis8charactersandmustinclude1numeralandspecialcharacter: 'パスワードの最小長は8文字で、1つの数字と特殊文字を含める必要があります',
    txt_global_std_lastname: '姓',
    txt_global_std_translationtool_upsell_pitch: '独自の翻訳と言語の更新を簡単に管理できます。',
    txt_global_std_wewillsendtheobdreadertotheaddressyouhaveregisteredwithusneedtochangeanythingcontactsupport: 'ご登録いただいた住所にOBDリーダーをお送りします。何かを変更する必要がありますか？サポート問い合わせ先。',
    txt_global_std_loyaltypages: 'ロイヤリティページ',
    txt_global_std_managepermissions: '権限の管理',
    txt_global_std_addressline1: '住所1',
    txt_global_std_youcanfindtheoutlethere: '差込口はこちら',
    txt_global_std_whatwillhappeniftheobddeviceisactive: 'What will happen if the OBD device is active but I forget to start the app?',
    'txt_global_std_Al-KhelaifiChamp': 'Two times Champion for Qatar Touring Car Championship. Two times Champion for Qatar National Sprint. 2017 Spa 12H endurance race CUP1 class winner',
    txt_global_std_sharemycar: '私の車を共有する',
    txt_global_std_hereyoucanuploadanyextrainformationregardingyourclaim: 'ここで、申し立てに関する追加情報をアップロードできます。すべてのフィールドはオプションです。',
    txt_global_std_testglobal: 'グローバルテスト',
    txt_global_std_chassisenginenumber: 'シャーシ/エンジン番号',
    txt_global_std_youarenowloggedin: 'ログインできました！',
    txt_global_std_engagingteammembersinasharedmissionisgoodforproductivity: 'チームメンバーを共有ミッションに参加させることは、生産性に役立ちます',
    txt_global_std_letmein: 'Let me in!',
    txt_global_std_badformattingwithkey: 'キー追加には不適切なフォーマット,　',
    txt_global_std_drives2: '運転回数',
    txt_global_std_good: 'よい',
    txt_global_std_questionsandanswers: '質問と回答',
    txt_global_std_uploadfromlist: 'リストからアップロード',
    txt_global_std_somethingtherenewfake: 'そこに何か新しい偽物',
    txt_global_std_drivemorethan1kilometertogetmoredetailedanalyticsaboutyourtrip: '1 km以上運転して、旅行に関するより詳細な分析を取得します。',
    txt_global_std_enteryouractivationcodeyoureceived: '新規登録時の通知あるいはメールに記載のアクティベーションコードを入力してください',
    txt_global_std_cimage16d: '計画的な加速で予定した最高速度を維持するかできるだけ長距離走行しましょう。',
    txt_global_std_yourtripwasnotregisteredifyouhadyourpluginconnected: 'トリップが登録されていません。運転中にプラグインを接続していてもアプリでBluetooth、GPS、位置情報をオンにしてドライブが記録できなかった場合、完了したトリップはプレミアレベル10～15でチャージされます。',
    txt_global_std_byjoiningthechallengeyouacceptthe: 'チャレンジに参加することで、',
    txt_global_std_yourprofilephotowassaved: 'プロフィール写真が保存されました',
    txt_global_std_imagepickerunlocked: 'ロック解除されたバッジ アイコンを選択',
    txt_global_std_ecofriend: 'CO2削減\nボーナス',
    txt_global_std_p2punavaliblebetween: '指定の時間は利用不可',
    txt_global_std_youaregoodtogostartdriving: '準備ができました。運転をスタートしてください！',
    txt_global_std_thiswindowwillcloseinclosesecondsseconds: 'このウインドウは {closeSeconds} 秒後に閉じます',
    txt_global_std_failedtopublishreviews: 'レビューを公開できませんでした!',
    txt_global_std_FiaPassword_Text3: 'は',
    txt_global_std_selectyourcountry: 'あなたの国を選択',
    txt_global_std_writecurrentpassword: '現在のパスワードを入力',
    txt_global_std_compare: '比較する',
    txt_global_std_zoomtofit: '拡大する',
    txt_global_std_head2head2: '友だちと対戦する',
    txt_global_std_p2pyouwillnotbeabletosendorreceivebookingsfromthisfriendanymore: 'この友達との予約の送受信はできなくなります。',
    txt_global_std_addbackground: 'Add background',
    txt_global_std_KristofferssonInterests: 'My family and fitness',
    txt_global_std_passwordforname: '{name} のパスワード',
    txt_global_std_collectedpoints: '収集したポイント',
    txt_global_std_showdataby: 'によるデータの表示',
    txt_global_std_elite: '一流',
    txt_global_std_yourinsurancehasexpired: '保険の有効期限が切れています。',
    txt_global_std_MarklundMusic: 'I like to listen to interesting documentaries on the radio when driving, however, when I need to concentrate, I prefer to have it totally silent.',
    txt_global_std_congratulationsyouhavesuccessfullyconnectedtoyourcar: 'おめでとうございます!\n&nbsp;&nbsp; 無事に接続できました。',
    txt_global_std_p2pcomingsoon: '近日公開',
    txt_global_std_ofcourseyoumayaddphotoslater: 'Of course you may add photos later',
    txt_global_std_pleaseselectaprojectfrommyprojects: ' マイ プロジェクトからプロジェクトを選択してください。',
    txt_global_std_pleasecheckyouremailtoverify: 'メールをチェックして確認してください',
    txt_global_std_connectyourself: '接続してください！',
    txt_global_std_startdate: '開始日',
    txt_global_std_youcannoteditthistranslationfromvisualeditorduetotechnicalreasonspleasecontactusourdeveloperswillupdateitforyou: '技術的な理由により、ビジュアル エディターからこの翻訳を編集することはできません。お問い合わせください。開発者が更新します。',
    txt_global_std_redeem: '交換する',
    txt_global_std_warikan_onboarding8: '4. クルマをシェアするメンバーを招待してシェアグループを作成',
    txt_global_std_loyaltyemailheadertext: 'と報われる！',
    txt_global_std_p2pemailchangedbookings: '変更した予約',
    txt_global_std_enterpasswordtopublishchanges: '変更を公開するにはパスワードを入力してください',
    txt_global_std_badgeisworthxpoints: '付与ポイント：{points}P',
    txt_global_std_welcometoname: '{appName}にようこそ',
    txt_global_std_supportedfiles: '対応ファイル形式：png、svg。最大。ファイルサイズ: X MB。',
    txt_global_std_p2pwearehappythatyouarehere: 'あなたがここにいることを嬉しく思います！',
    txt_global_std_categorytypeheader: 'タイプ',
    txt_global_std_earn3500pointsifyoubook15timesamonth: '月に15回予約すると3500ポイント獲得',
    txt_global_std_paynow: '今払う',
    txt_global_std_showthisreceiptatcheckout: 'チェックアウト時にこの領収書を提示してください。',
    txt_global_std_jointco2savings: '共同CO2節約\n',
    txt_global_std_p2pinvoices: '請求書',
    txt_global_std_sparklockedkey: '1週間、平均4レベル以上を達成すると、Kintoウォレットに1000ポイント付与されます。',
    txt_global_std_thefiasmartdrivingchallengeisaworldchallenge: 'FIA Smart Driving Challengeは、スピードではなくスマートドライブを競う世界規模のチャレンジです。チームリーダーをお手本に、日ごろの運転に磨きをかけましょう',
    txt_global_std_renewyourinsuranceformonthofmonthatalowercost: '{month}の月の保険を低コストで更新します',
    txt_global_std_clubs: 'クラブ',
    txt_global_std_p2penterthetemporarypasswordsenttoyouremail: '申し込み後、メールで送られる\n仮パスワードを入れてください。',
    txt_global_std_counthours: '{count} 時間',
    txt_global_std_zipcodeglobal: '郵便番号',
    txt_global_std_p2pconfirmed: '確認済み',
    txt_global_std_premiuminsurancecode: '保険料コード',
    txt_global_std_adduserstogroup: 'グループにユーザーを追加する',
    txt_global_std_p2pequallydivided: '均等分割',
    txt_global_std_afteryoustartdrivingyourinvoiceswillshowuphere: '運転を開始すると、運転データがここに\n表示されるようになります。',
    txt_global_std_saloon: 'サルーン',
    txt_global_std_awesomestreak: '素晴らしい連勝！',
    txt_global_std_currentstatus: '現在のステータス：',
    txt_global_std_emailisalreadyaddedtobeinvited: '招待するメールはすでに追加されています',
    txt_global_std_hereyoucandocumentyourcar: 'ここで、あなたの車を文書化できます。',
    txt_global_std_include: '含む',
    txt_global_std_web: 'ウェブサイト',
    txt_global_std_p2pgoback: '戻る',
    txt_global_std_carrescue: 'カーレスキュー',
    txt_global_std_p2pyoudonthaveanyfriendsyet: 'まだ友達がいません。',
    txt_global_std_eventname: 'イベント名',
    txt_global_std_this15discountcodecanberedeemedongarmincomonselectedgarminautomotivedevices: 'この 15% 割引コードは、garmin.com で選択した Garmin 車載デバイスで利用できます。',
    txt_global_std_yourdeviceisonitsway: 'デバイスをお送りしました。お手元に届いたら差し込むだけ！ チームに参加して試合に登録しましょう！ ',
    txt_global_std_twoormoregroupscompeteagainsteachother: '2 つ以上のグループが互いに競合する',
    txt_global_std_agreementnumber2: '契約番号：',
    txt_global_std_removingtheuserfromthegroupdoesnotdeletetheusersaccount: 'グループからユーザーを削除しても、ユーザーアカウントは削除されません。',
    txt_global_std_pushdriveendlevel1: 'Wow, you drive is world class! Press here to see details of your drive.',
    txt_global_std_administrateandinviteusers: 'ユーザーを管理および招待します。',
    txt_global_std_ecofriendunlockedkey: 'この1週間で\n{co2value}%のCO₂排出を\n削減することができました。\n環境にやさしい\n運転をありがとうございます！',
    txt_global_std_savemoneyonyourinsurance: '保険の費用を節約する',
    txt_global_std_afollowthesestepstoconnecttheapptoyourcar1pressthedrivebuttonintheapp2startyourcarsengine3yourcarshouldshowupintheapp4selectyourcar: 'A：アプリを車に接続するには、次の手順に従います。\n' +
      '    1.アプリの運転ボタンを押します\n' +
      '    2.車のエンジンを始動します\n' +
      '    3.あなたの車がアプリに表示されます\n' +
      '    4.車を選択します',
    txt_global_std_firstdrive4: '最初のドライブ',
    txt_global_std_p2pnothinghaschanged: '何も変わっていません',
    txt_global_std_recruitmentamount: '募集額',
    txt_global_std_repeatyourpassword: 'Repeat your password',
    txt_global_std_selectcategory: 'カテゴリーの選択',
    txt_global_std_howdoidrivewellanswer: '安全なドライバーは常に意識している<br>ドライバーです。安全運転には運転と<br>周囲の環境に100％集中することが必要<br>です。注意を払い、交通リズム、<br>天候やその他の状況に合わせて速度<br>を調整してください。',
    txt_global_std_choose: '選択してください：',
    txt_global_std_ifyoucannotfindyourfriendorarenotregisteredasauseryourfriendencouragesyoutodownloadtheappnameappandgetaprofile: '友達が見つからない場合やユーザーとして登録されていない場合は、{appname}アプリをダウンロードしてプロフィールを取得することをお勧めします。',
    txt_global_std_addcarinvolved: '事故車両を追加する',
    txt_global_std_tinaDescription: "2008 I was historically in the Middle East by competing with Nasser Saleh Al-Attiyha (Qatar), right now the largest sports profile there - we won two World Cups in 2008, one in the series and one in the Baja series. In this constellation, there has only been one such successful sports team in the world! That we succeeded in our successes, I would like to thank Nasser's mother, who raised her son not to have preconceived opinions regarding his card reader's skin color, sex or religion. We also led Dakar rally in South America in 2009 when we had to break the sixth stage.\n" +
      '\n' +
      'My career in motor sports started in 1990. Since then, I have won three women´s World Championship titles, two second places in Gr. N. rally world championship and two world cup wins in rally raid 2008 & 2009. To my main merits, I expect a second and third place in the famous Dakar rally.\n' +
      '\n' +
      'Perhaps my most famous drivers have been Colin McRae, Jutta Kleinschmidt, Kenneth Eriksson and Ari Vatanen; In addition, I have navigated to about twenty international drivers.\n',
    txt_global_std_youhavenoactivechallengesrightnow: '進行中の対戦はありません',
    txt_global_std_type2: 'タイプ',
    txt_global_std_addfriend: '友だちを追加する',
    txt_global_std_confirmpurchase: '購入を確認します',
    txt_global_std_pullthebartoseehowyourleveleffectsyourdiscount: 'ボタンを動かしてどのようにレベルが\n割引に影響するか確認できます',
    txt_global_std_idontwanttoconnectnow: '今は繋がりたくない',
    txt_global_std_p2ppushtitleyouhaveabookingtofinalize: '確定する予約があります。',
    txt_global_std_stateinsuranceinformation: '{州}保険情報',
    txt_global_std_youhaveexceededthespeedlimitof: '制限速度 {maxSpeed} を超えました。',
    txt_global_std_copyinvitationlink: '招待リンクをコピー',
    txt_global_std_carusage: '車の用途',
    txt_global_std_selectyourcarmodeltoseethemostaccuratedata: '最も正確なデータを表示するには、車のモデルを選択してください',
    txt_global_std_createyourveryowncompanychallengeinthefiasdcandunderstandandmanagetheimpactyourteamhasonroadsafetyandco2emissions: 'FIA SDCで独自の企業チャレンジを作成し、チームが交通安全とCO2排出量に与える影響を理解して管理します。',
    txt_global_std_iobd2: 'iOBD2',
    txt_global_std_safedriverweeklylockedkey: '1週間の平均運転スコアが​\n{score}以上を記録した場合に​\n獲得できるバッジ​\n（獲得上限：1週間に1回まで）​',
    txt_global_std_connecttocarlockedkey: 'Bluetoothでクルマとアプリを繋ぐと獲得できるバッジ\n（獲得上限：１回まで）',
    txt_global_std_p2pearnpointswhenyoudrivesafe: '安全運転でポイントを獲得',
    txt_global_std_connectwithbluetooth: 'ブルートゥースで接続',
    txt_global_std_detailedstatisticsaboutyourdrivingwilldisplayherewhenyouhavedoneyourfirsttrip: 'あなたが最初の旅行をしたとき、あなたの運転についての詳細な統計がここに表示されます。',
    txt_global_std_entertheinvitationcodeyouhavereceivedfromyourworkspaceadministrator: 'ワークスペース管理者から受け取った招待コードを入力してください',
    txt_global_std_division: '分割',
    txt_global_std_selectfriendsoragroup: '友達やグループを選択する',
    txt_global_std_p2ppushtitleexpensesapproved: '追加費用が承認されました',
    txt_global_std_ifyourfriendregisterswiththereferralcodebothyouandyourfriendwillreceivenok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsurancetheamountisenteredinthepiggybankwhentherecruitmentcodehasbeenregisteredyouhaveafulltimeoverviewofyourrecruitsonthesavingsside: '友人が紹介コードで登録すると、あなたとあなたの友人の両方が貯金箱で250ノルウェークローネを受け取ります（これには、保険の開始日から少なくとも1か月間は両方のTrygの顧客である必要があります）。\n' +
      '\n' +
      '採用コードが登録されると、貯金箱に金額が入力されます。あなたは貯蓄側であなたの新入社員のフルタイムの概要を持っています。',
    txt_global_std_stardriverlockedkey: '1回の運転でスコアが{score}以上を​\n記録した場合に獲得できるバッジ​\n（獲得上限：1日1回まで）​',
    txt_global_std_createyourowncompanychallengeinjustthreesteps: 'わずか3つのステップで独自の企業チャレンジを作成してください！',
    txt_global_std_p2puserguides: 'ユーザーガイド',
    txt_global_std_describetheaccident: '事故について記載する',
    txt_global_std_kintoemailheadertext: 'Kintoに参加して報酬を獲得しましょう！',
    txt_global_std_status: 'スターテス',
    txt_global_std_comingsoon: '近日リリース予定',
    txt_global_std_ChicheritWhyjoin: 'Coming soon...',
    txt_global_std_needhelptogetstarted: '開始するためにヘルプが必要ですか?',
    txt_global_std_remainingpointstonextlevel: '{level}レベルまであと{points}ポイント',
    txt_global_std_damagesalvagecall: '故障について',
    txt_global_std_lookslikethisisempty: '空欄です！',
    txt_global_std_monthmon: '月',
    txt_global_std_selectdates: '日付を選択',
    txt_global_std_createyourownappinafewclicks: '数回のクリックで独自のアプリを作成します',
    txt_global_std_usernotfound: 'ユーザーが見つかりません。',
    txt_global_std_recruitfivefriendsandyouallcompete: '友だちを5人募集して第一試合を無料で楽しむ',
    txt_global_std_shootingstarnamekey: '流れ星！',
    txt_global_std_conteactinformation: 'お問い合わせ',
    txt_global_std_bestdriversrightnow: '今最高のドライバー！',
    txt_global_std_nameofapp: 'アプリの名前',
    txt_global_std_quantitytoshow: '表示数',
    txt_global_std_TaxInvoice8: 'Cover terminates on the 61st day if premium is not paid within 60 days of inception date of the policy.',
    txt_global_std_checkifcorrectobdreader: 'OBDリーダーが正しく接続されているか確認してください。',
    txt_global_std_youhaveonlydistancekmleft: '残りわずか{距離} kmです。',
    txt_global_std_p2pfaq8q: '自分がクルマを利用する日に、他のメンバーがクルマを利用できないよう、予約をブロックする方法はありますか？',
    txt_global_std_thisisadrivingappforustobeabletomeasureyourdrivingweneedtoaccesstobbluetoothlocationservicesbandbmotionfitnessb: 'これは運転を測定するアプリです。運転を測定するには、<b>Bluetooth</b>、 <b>位置情報サービス</b>、<b>モーションとフィットネス</b>にアクセスする必要があります。',
    txt_global_std_HansenWhyjoin: 'I will work closely with my teammates and try to combine the two factors of smart driving. Together we will aim to enjoy our driving, push ourselves to do better and drive super smart and safe! By doing so, I believe we can win every race, even the greatest one of them all: the race for saving our planet!',
    txt_global_std_JyrkiainenMileage: 'I have had driving license for only almost a year but I love the route to Finnish Lapland, the scenery becomes more and more snowy all the time!',
    txt_global_std_earn1500pointsifyoubook5timesamonth: '月に5回予約すると1500ポイント獲得\n\n',
    txt_global_std__ongoing: '（進行中の対戦）',
    txt_global_std_p2pdeletingofmessages: '{todelete}メッセージの{nocurrent}を削除する',
    txt_global_std_additionalfiles: '追加ファイル',
    txt_global_std_p2pmystatscomingsoon: '私の統計-近日公開',
    txt_global_std_afriend: '友だち',
    txt_global_std_PolicySchedule29: 'TIERED EXCESS OF UP TO $2,000 WILL APPLY IN THE EVENT OF AN ACCIDENT SUBJECTED TO THE AVG SCORE.',
    txt_global_std_areyouabsolutelysuperdupersurethatyouwanttoexchangeyourpreciouspointsnowandnotsavethemforlater: 'ポイントを保存せず、本当に今交換をしてもよろしいですか？',
    txt_global_std_age: '年齢',
    txt_global_std_supportingcluestowhy: '「なぜ」に関する追加情報',
    txt_global_std_maximizetext: 'ボーナスでトリップ',
    txt_global_std_MikkelsenWhyjoin: 'I will use my experience from rally & racing to help people become better drivers. Being a good driver is not only about being fast but being just as efficient and clever.',
    txt_global_std_bronze: 'ブロンズ',
    txt_global_std_super: 'スーパー/ハイオク？',
    txt_global_std_areyousureyouwanttosignout: 'サインアウトしますか?',
    txt_global_std_sidekicknotifications: 'アプリを最大限に活用するには、Sidekickが通知を送信することを許可してください。',
    txt_global_std_notavailable: '利用不可',
    txt_global_std_hangin: 'がんばれ',
    txt_global_std_wildfireunlockedkey: 'とてもいいドライブでした！1週間で平均4.4レベル以上で、Kintoウォレットに5000ポイント付与されました。',
    txt_global_std_removeuserfrom: 'からユーザーを削除',
    txt_global_std_borrowmycar: '車を借りる',
    txt_global_std_HEAT: '今週',
    txt_global_std_PolicySchedule10: 'Seaters',
    txt_global_std_contactus: 'お問い合わせ',
    txt_global_std_p2pfriendinvitedyouto: '友だちからKintoカーシェアリングに招待されています。',
    txt_global_std_jan: '1月',
    txt_global_std_sentbyenerfyglobalaproductbygreaterthanab: 'Greater Than AB の製品である Enerfy Global によって送信されます。',
    txt_global_std_groupchat: 'グループチャット',
    txt_global_std_newpasswordagain: '新しいパスワードを再入力',
    txt_global_std_allownotificationstogetdrivingtipsafter: '通知をオンにして、走行ごとのアドバイスを獲得してスキルを向上させましょう。',
    txt_global_std_cimage36d: '',
    txt_global_std_enterthetemporarypasswordsenttoyoubyemail: 'メールで送信された一時パスワードを入力します。',
    txt_global_std_loyaltydrivers: '運転手',
    txt_global_std_fiasdc2019w1price2: '3シーズンパッケージ',
    txt_global_std_trips: '運転回数',
    txt_global_std_totalscore1: '平均スコア',
    txt_global_std_attention: '注意',
    txt_global_std_p2preservation: '予約',
    txt_global_std_nothingtoshow: '表示するものはありません',
    txt_global_std_p2ppendingph: '保留中({count}件)',
    txt_global_std_vehicleinfo: '車両情報',
    txt_global_std_wehavereceivedyourreport: 'レポートを受領しました。保険請求につきましては速やかに対応いたします。',
    txt_global_std_warikan_onboarding6_2: '〒450-0002　愛知県名古屋市中村区名駅四丁目8番18号<br>\n名古屋三井ビルディング北館１４Ｆ',
    txt_global_std_50kmlockedkey: 'アプリ接続中の走行距離が​\n50kmに到達した場合に​\n獲得できるバッジ​\n（獲得上限：1回まで）',
    txt_global_std_ayouwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithappnamewithalapseincoverage: 'A：有効期限から{日}日以内に支払いを行い、{appName}で保険契約を継続します。',
    txt_global_std_contact: 'お問い合わせ',
    txt_global_std_this15discountcodecanberedeemedagainstanygarminpndpurchase: 'この 15% 割引コードは、Garmin PND の購入に対して引き換えることができます。',
    txt_global_std_p2pscore: 'スコア：',
    txt_global_std_nodataavailable1: 'データなし',
    txt_global_std_uploadextrareportinfo: '追加のレポート情報をアップロードする',
    txt_global_std_signout: 'サインアウト',
    txt_global_std_herearetheresultsofthechallengename: 'チャレンジの結果',
    txt_global_std_maxxcharactersrule: '最大 {x} 文字',
    txt_global_std_enteryourinformation: 'Enter your information',
    txt_global_std_p2pdrivendistance: '走行距離',
    txt_global_std_earn10000pointsifyouupgradetovalueplan: 'バリュープランにアップグレードすると10000ポイント獲得',
    txt_global_std_nationality: '国籍',
    txt_global_std_tiphighway4DESC: '赤い車のように、加減速を繰り返してしまっています。一定速度を維持できると理想的です。',
    txt_global_std_delete: '削除',
    txt_global_std_startguidetip4: 'アプリでスコアサークルを表示させてください。',
    txt_global_std_howmuch: 'どのくらい',
    txt_global_std_arequesthasbeenreceivedtochangethepasswordforyour: 'のパスワードを変更するリクエストを受け取りました',
    txt_global_std_thislinkexpiresin: 'このリンクの有効期限は',
    txt_global_std_p2preceipt: '領収証',
    txt_global_std_nochallengestojoinyet: '参加できるチャレンジがありません',
    txt_global_std_b1gotosettingsforappnameinyourphonebbrb2allowaccesstobluetoothb: '<b> 1.  スマートフォンの{appname}の設定に移動します。</b> <br> <b> 2.  Bluetoothへのアクセスを許可する</b>',
    txt_global_std_view: 'ビュー',
    txt_global_std_daterange: '日付範囲',
    txt_global_std_youdrovesupremelywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'あなたは最高の運転をしました！ 1週間の平均は{score}よりも優れていました。 {points}ポイント獲得しました！',
    txt_global_std_p2psuccessfullyeditedadminrights: '管理者権限の編集に成功',
    txt_global_std_soclosetoearningachievement: 'あなたは達成を達成し、ポイントを獲得するのにとても近かったです。集中しましょう！',
    txt_global_std_MSIG_Onboarding4: 'onwards. Find your certificate of insurance and other information attached in this email.',
    txt_global_std_yourinvitewassenttoemail: '{email}に招待を送信しました',
    txt_global_std_p2pincreasetextsize: 'テキストサイズを変更する',
    txt_global_std_p2pfindmycarcomingsoon: '私の車を探す-近日公開',
    txt_global_std_monthlysavings: '月ごとの割引',
    txt_global_std_stats: '統計',
    txt_global_std_50offdeductible: '免責額50％オフ',
    txt_global_std_ongoing__: '（進行中の対戦）',
    txt_global_std_startday: '開始日',
    txt_global_std_addcomparison: '比較を追加',
    txt_global_std_awesomedriveroftheday: '今日の素晴らしいドライバー',
    txt_global_std_selectformat2: 'フォーマットを選択',
    txt_global_std_aug: '8月',
    txt_global_std_choosebetweenenteringchassisnumberplatenumberorpolicynumber: 'シャーシ番号、プレート番号、またはポリシー番号の入力から選択します。',
    txt_global_std_PolicySchedule5: 'Insured',
    txt_global_std_cimage8h: 'ゆっくり加速',
    txt_global_std_bevisibleorhiddenintheleaderboard: 'スコアボードに表示する/しない',
    txt_global_std_findyourfriends: '友だちを探す',
    txt_global_std_SulayemQuote: 'Quote coming soon...',
    txt_global_std_p2psplittingthecostbydistancetravelledrequiresbluetoothconnection: '距離による費用分割を行うには\nBluetoothの接続が必要になります。',
    txt_global_std_errorcodes: 'エラーコード',
    txt_global_std_MSIG_InsuranceLetter40: 'SGD3,500.00 SECTION I & II SEPARATELY IS IMPOSED ON THOSE DRIVERS WHO ARE BELOW 22 YEARS OLD AND/OR WHO HAVE LESS THAN 2 YEARS OF DRIVING EXPERIENCE SGD1,000.00 ALL CLAIMS IS IMPOSED ON DRIVERS WHO HAVE DEACTIVED THE DONGLE FOR AT LEAST 14 DAYS IN A CALENDAR YEAR. TIERED EXCESS OF UP TO $2,000.00 WILL APPLY IN THE EVENT OF AN ACCIDENT SUBJECTED TO THE AVG SCORE AN EXCESS OF $100.00 IS APPLICABLE FOR EACH AND EVERY WINDSCREEN CLAIM\t\n',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithcovercubewithalapseincoverage: '有効期限から{days}日以内に支払いを行い、補償範囲を失ってCovercubeでポリシーを継続します。',
    txt_global_std_inserttheobdreaderandstartyourcarwaitforthereadertoshowabluelight: 'OBD リーダーを挿入し、車を始動します。リーダーが青色のライトを表示するまで待ちます。',
    txt_global_std_yourcarisntturnedonnodot: 'あなたの車はオンになっていない',
    txt_global_std_qdoescovercubeacceptdriversthatarenotlicensedintheunitedstates: 'Q：Covercubeは、米国でライセンスされていないドライバーを受け入れますか？',
    txt_global_std_1year: '1年',
    txt_global_std_warikan_onboarding10: '※このメールアドレスは送信専用です。このメールに直接返信いただいても対応できませんのでご了承ください。',
    txt_global_std_welcometoteam: '{team} へようこそ',
    txt_global_std_p2pfaq30a: '毎月オーナーから、請求書が届き、ホーム画面の「やること」に「わりかんの詳細を受け取りました。」と表示されます。もしくは「メニュー」の「わりかん」からも確認ができます。\n' +
      '1.\t「わりかん」を選択後、請求書タブから詳細を確認します。\n' +
      '2.\t請求内容を確認後、「続ける」を選択して支払い金額を確定します。\n' +
      '3.\t支払方法を選択し、「今、支払う」を選択します。',
    txt_global_std_PaddonFacts: 'Hayden has started driving go-karts at the age of 6. Lived and breathed motorsport all his life.',
    txt_global_std_yourobdreaderisonitsway: 'OBDリーダーをお送りしました。３～５営業日で到着予定です。簡単な接続方法は次のステップで。',
    txt_global_std_timezone: 'タイムゾーン',
    txt_global_std_youravgscoreis123: 'あなたの平均スコアは',
    txt_global_std_welcometocovercube_: 'Covercubeへようこそ！',
    txt_global_std_thisisatextmessage: 'これはテキスト メッセージです。',
    txt_global_std_challengefriend2: '友だちと対戦を始める!',
    txt_global_std_Fia_Receipt_Text9: 'Quantity',
    txt_global_std_theworldchampionshipwheresmartnessbeatsspeed: 'スピードよりスマートドライブを競う世界規模のチャレンジ。プロに倣い、スマートドライブの頂点をめざしましょう。',
    txt_global_std_events_overview_monthly: '毎月',
    txt_global_std_wildfirenamekey: 'ファイヤー！',
    txt_global_std_p2pchangedthenameofthegroup: 'さんがグループ名を変更しました。',
    txt_global_std_socialbutterflynamekey: 'バタフライ！',
    txt_global_std_invalidvouchercode: '無効なバウチャーコード',
    txt_global_std_2nd: '2番',
    txt_global_std_p2perrorcodecarmustbebonded: '車は契約する必要があります。',
    txt_global_std_expensessummary: '費用概要',
    txt_global_std_everyday: '毎日',
    txt_global_std_whatwillitcosttodrivewithoutbeing: 'What will it cost to drive without being connected to my car?',
    txt_global_std_p2peasyoverviewinyourcalendar: 'カレンダーの簡単な概要',
    txt_global_std_featured: '特集',
    txt_global_std_youdrovesupremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'あなたは最高の運転をしました！ 1週間の平均{scoretype}は{score}よりも優れていました。 {points}ポイント獲得しました！',
    txt_global_std_contactdetailss: '連絡先の詳細',
    txt_global_std_congratulationsonthistypeoftripyouareanecochampionthanksforbeinganecofriendlydriver: 'おめでとう！このタイプの旅行では、あなたはエコチャンピオンです!環境に優しいドライバーになってくれてありがとう！',
    txt_global_std_networkerrorpleasetryagainlater: 'ネットワークエラーが発生しました。しばらくしてからもう一度お試しください',
    txt_global_std_orientering: 'オリエンテーリング',
    txt_global_std_refer10friendstounlockthisbadge: 'このバッジのロックを解除するには、10人の友達を紹介してください。',
    txt_global_std_downloadapispecification: 'API 仕様のダウンロード',
    txt_global_std_showchanges: '変更を表示しますか？',
    txt_global_std_contributesustainabilitythroughwarikan: 'わりかんKINTOでサステナビリティに貢献',
    txt_global_std_makeitempty: 'ゴミ箱を空にする！',
    txt_global_std_changeemail: 'Eメールを変更する',
    txt_global_std_letsfindyourcar: 'あなたの車を見つけましょう',
    txt_global_std_MSIG_Onboarding13: 'MS First Capital Insurance Ltd | Corporate Office: 6, Raffles Quay, #21-00 | Singapore 048580 | Tel: (65) 6222 2311',
    txt_global_std_profilesetup: 'プロフィール設定',
    txt_global_std_co2saverunlockedkey: 'CO₂排出を{co2value}%\n削減することが\nできました。',
    txt_global_std_MSIG_InsuranceLetter25: "Use only for social, domestic and pleasure purposes and for the Insured's business.",
    txt_global_std_goldusers: 'ゴールドユーザー',
    txt_global_std_iwillfixitlater: '後で修正する',
    txt_global_std_joinwithinvitationcode: '招待コードで参加',
    txt_global_std_statisticsshowingyourpersonalprogress: 'あなたの個人的な進歩を示す統計。',
    txt_global_std_mandatory: '必須',
    txt_global_std_p2pfaq20q: '走行データの確認はできますか？',
    txt_global_std_allowlocationservicetogetaccuratescore: '位置情報サービスをオンにしてカスタマイズされたアドバイスと正確なスコアを取得してください。',
    txt_global_std_templates: 'テンプレート',
    txt_global_std_enterpolicynumberhere: 'ここにポリシー番号を入力してください',
    txt_global_std_shootingstarunlockedkey: 'レベル3.3以上でした！Kintoウォレットに100ポイント付与されました。',
    txt_global_std_addadditionalfiles: 'ファイルを追加する',
    txt_global_std_hasbeeninvitedasaroletoyourteam: 'あなたのチームに{役割}として招待されました',
    txt_global_std_mpltipiip: 'このドライバーは熟練したドライバーですが、運転に一貫性がないことが示されています。ドライバー\n' +
      'は時々、模範的な運転を無視することがあり、その発生日や回数には一貫性がなくランダムです。そし\n' +
      'てこれは、1 日中模範的な運転を無視する場合と、1 日のうち1 回の走行だけ模範的な運転を無視する場',
    txt_global_std_p2phasreceivedyourbooking: 'への\n予約リクエストの内容',
    txt_global_std_forthisapptoworkproperlyweneedtoaccessnobr: '運転履歴を記録するには、Bluetooth、\n位置情報サービス、モーションフィットネス\nをオンにしてください。',
    txt_global_std__resendtemporarypassword: '一時パスワードを再送します。',
    txt_global_std_translationtool_upsell_pitch_bulletpoint2: 'コミュニケーションをブランドのトーンに合わせる',
    txt_global_std_yourdatahasbeensent: 'データが送信されました。受信トレイをご確認ください！',
    txt_global_std_prev: '前回',
    txt_global_std_today: '本日',
    txt_global_std_MSIG_InsuranceLetter21: 'The Insured may also drive a Motor Car not belonging to or hired (under a hire purchase agreement or otherwise) to him or his employer or his partner.',
    txt_global_std_OgierDescription: "2013年から2018年にかけて6回のタイトルを獲得し、現在、World Rally Drivers' Championshipを保持しています。世界ラリー選手権で44回の優勝、WRCで連続6タイトルを果たした彼は、前Citroën WRCチームのSébastien Loeb (９タイトル)に続く偉業を成し遂げました。",
    txt_global_std_terrainhandling: '地形処理',
    txt_global_std_readytogoappsolutionsthatwehavehandpickedforyou: '私たちがあなたのために厳選したすぐに使えるアプリソリューション。',
    txt_global_std_callwithdriver: '現場指導',
    txt_global_std_whatwillhappenififorgettoconnect: 'What will happen if I forget to connect to my car?',
    txt_global_std_filteroptionnotinuse: '使用されていません',
    txt_global_std_youhavenowmaintainedafullweeksdrivingwithanaveragescoreabove4: 'これで、平均スコアが4.0を超えて1週間の運転を維持しました',
    txt_global_std_icannotconnectanswertext1: 'まずは「車への接続」の全ての手順を完了していることを確認してください。それでも接続できない場合は、',
    txt_global_std_anewpasswordhasbeengeneratedforuser: '{user}の新しいパスワードが生成されました',
    txt_global_std_ConfirmFia_Text10: 'SDCテストドライブの準備完了?',
    txt_global_std_fiasdc2019w1price1: '5シーズンパッケージ',
    txt_global_std_p2pselectthedatesandtime: '予約をブロックする 日時を選択する',
    txt_global_std_p2pfaq14q: '予定時刻よりも遅い時間にクルマを返却する場合、どうすればいいですか？',
    txt_global_std_p2pfaq30q: 'かかった費用の支払い方法を教えてください。',
    txt_global_std_groupnames: 'グループ名',
    txt_global_std_youcanrepublishyourtranslationslaterifyouwant: '必要に応じて、後で翻訳を再公開できます。',
    txt_global_std_p2pfaq22q: '追加費用を記録したい。',
    txt_global_std_speedchangeanalysis2: '速度変化の分析',
    txt_global_std_p2pfaqcontactinfo1: '・受付時間　9:00〜18:00（年末年始を除く）',
    txt_global_std_areyousureyouwanttodeletememberfromworkspacethisactioncannotbeundone: 'ワークスペースからメンバーを削除してもよろしいですか？このアクションは元に戻せません。',
    txt_global_std_tripdetailshavebeensenttoemail1: '旅行の詳細がメールで送信されました。',
    txt_global_std_resendactivationcodeto: 'アクティベーションコードをに再送信します',
    txt_global_std_mars: '月',
    txt_global_std_activationcodeoptional: 'アクティベーションコード（オプション）',
    txt_global_std_lockeddescriptionlabel: 'ロックされた説明、アプリに表示',
    txt_global_std_minute: '分',
    txt_global_std_enerfycoachtip1: '1. 100 % 運転に集中する。',
    txt_global_std_p2pcostsplitsettings: 'コスト分割設定',
    txt_global_std_p2pnamesentaneditrequesttoname2: '{name}が編集リクエストを{name2}に送信しました',
    txt_global_std_changebackground: '背景を変更する',
    txt_global_std_ridewithmehowitworks: '操作方法',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithappnamewithalapseincoverage: '有効期限から{日}日以内に支払いを行い、{appName}でポリシーを継続して補償範囲を失います。',
    txt_global_std_youhavereachthemaximumlevel: '最高レベルに達しました！',
    txt_global_std_p2pfriendadminrights: '友達の管理者権限',
    txt_global_std_tipcity1DESC: '赤い色のように、頻繁に加減速してしまっています。青い車のように、一定速度を維持できると理想的です。',
    txt_global_std_ConfirmFia_Text2: 'パリで開催されるFIA Smart Driving Challenge開会式の席は確保済みです。',
    txt_global_std_wearebuildingyourworkspace: '私たちはあなたのワークスペースを構築しています',
    txt_global_std_events: 'イベント',
    txt_global_std_teamage: '年齢',
    txt_global_std_NewPW_Text2: 'ユーザーへの新しいパスワード',
    txt_global_std_safetyalarms: '警告',
    txt_global_std_superadmin: 'スーパー管理者',
    txt_global_std_p2pfaq21a: '「メニュー」の「メンバー」を選択すると、登録しているすべてのメンバーを確認できます。',
    txt_global_std_workshops: '修理工場',
    txt_global_std_failedtogettranslationoverview: 'TranslationOverview の取得に失敗しました',
    txt_global_std_obdreadersnearby: '近くのOBDリーダー',
    txt_global_std_p2pfaq1a: 'これらは近日中に利用できるようになる機能です。今後のアップデートにご期待ください。',
    txt_global_std_numberofexecutions: '実行回数',
    txt_global_std_thisisyourusername: 'This is your username',
    txt_global_std_daysleft: '残り日数',
    txt_global_std_p2paddedexpenses: '追加費用',
    txt_global_std_drivingdata: '運転データ',
    'txt_global_std_Al-KuwariWhyjoin': 'Because I create a family with in the team.',
    txt_global_std_p2pitsalittleemptyinhere: 'メッセージはありません。',
    txt_global_std_oldpassword: '以前のパスワード',
    txt_global_std_permission: '許可',
    txt_global_std_iconback: '{アイコン}戻る',
    txt_global_std_KleinschmidtMileage: 'Too me, my experience of mileage is depending on the current traffic situation and on my personal situation',
    txt_global_std_followupontheonboardingstatusofyourusers: 'ユーザーのオンボーディング ステータスのフォローアップ',
    txt_global_std_jointhechallenge: 'チャレンジに参加する',
    txt_global_std_bronzelevel: 'ブロンズレベル',
    txt_global_std_p2pview: '表示する',
    txt_global_std_KristofferssonWhyjoin: 'If you see a competition as a challenge and your goal is to give 100 % with the conditions you have - then you should join my team.\n',
    txt_global_std_youcollectedpointsduringthepreviosperiod: '以前の期間にためたポイントは{points}です。',
    txt_global_std_noogpsdatafound: 'GPSデータがありません',
    txt_global_std_safetydrivingskillupwithwarikankintobody: 'わりかんKINTOは安全運転のパフォーマンスを上げるお手伝いもします。スコア機能とバッジ獲得はKINTO ONEご契約者以外の皆さんにもお試しいただけます。',
    txt_global_std_ifyouwanttolendyourcartoafriendyoushouldinviteyourfriendtotheappnameappbytappingthebuttonbelow: '車を友人に貸したい場合は、下のボタンをタップして、友人を{appname}アプリに招待する必要があります。',
    txt_global_std_activity: 'アクティビティ',
    txt_global_std_end: '終了',
    txt_global_std_p2pcancelthisbooking: '予約をキャンセルしますか？',
    txt_global_std_heat123: '試合',
    txt_global_std_adgangtillokalitet: 'Adgang til lokalitet',
    txt_global_std_secureyourlocation: '安全を確保する',
    txt_global_std_rwmnofriendstext: '友だちを招待して、ライドシェアを登録して下さい。',
    txt_global_std_yourpolicyisreneweddate: 'ポリシーが更新されました{日付}',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimeinconstantspeedpayingattentiontoyourenvironmenthelpsyoutosmoothlyandsteadilyvaryyourspeedaccordinglythroughoutyourtrip: 'このタイプの旅行では、平均的なエコ チャンピオンは一定の速度で過ごす時間が短くなりました。環境に注意を払うことで、旅行中に速度をスムーズかつ着実に変えることができます。',
    txt_global_std_compilinginformationdrive: '走行情報をコンパイル中...',
    txt_global_std_region: '領域',
    txt_global_std_videotutorials: 'ビデオチュートリアル',
    txt_global_std_PaddonBesttip: 'Be patient it’s my strongest advice. It’s very easy to become tense or impatient with other road users when on the road, but remember, a small speed difference will only make a few minutes difference at the end. So, relax, turn up the music and enjoy your ride.',
    txt_global_std_Fia_Receipt_Text16: 'With this purchase you have agreed to our general conditions of purchase.',
    txt_global_std_findanagent: 'エージェントを探す',
    txt_global_std_insurancenumbernumber: '保険番号：{番号}',
    txt_global_std_p2pwriteamessagetoafriendorgroupregardingabookingoranythingselsethatsonyourmind: '友だちやグループに\n予約などについて\nメッセージを送ってみましょう。',
    txt_global_std_features: '特集',
    txt_global_std_co2savings: 'CO₂ 削減',
    txt_global_std_p2pselectmember: 'メンバーを選ぶ',
    txt_global_std_getsarouteforagiventrip: '指定された旅行のルートを取得します',
    txt_global_std_notheappworksinbackgroundmodemakesureyoualwayskeepbluetoothandlocationservicesactivatedonyourphone: 'いいえ、アプリはバックグラウンドモードで動作します。スマートフォンでBluetoothと位置情報サービスを常にアクティブにしておくようにしてください。',
    txt_global_std_p2ppaid: '支払済み',
    txt_global_std_p2pemailsent: 'メールを送信しました！',
    txt_global_std_acceleration: '加速',
    txt_global_std_nocloudconnection: 'クラウドに接続していません',
    txt_global_std_pointsoverview: 'ポイントの概要',
    txt_global_std_emailnotfound: 'メールが見つかりません',
    txt_global_std_textwelcomewiget: 'これはとても楽しいでしょう！スマートに運転を始めましょう。高得点をご案内します！',
    txt_global_std_PSolbergBesttip: 'Coming soon...',
    txt_global_std_Insurance_WelcomeLetter: 'ウエルカムレター',
    txt_global_std_countdays: '{count} 日',
    txt_global_std_privateorbusiness: '個人と法人、どちらですか？',
    txt_global_std_addphoto: '写真を撮る',
    txt_global_std_private2: '個人',
    txt_global_std_loadingyourdrives: 'ドライブをロード中…',
    txt_global_std_whathappensififorgettorenewbeforemyinsurancehasexpired: '保険の有効期限が切れる前に更新を忘れた場合はどうなりますか？',
    txt_global_std_invitesent: '招待を送信',
    txt_global_std_recommended: '推奨',
    txt_global_std_letsdrivewithwarikankinto: 'わりかんKINTOとドライブしよう！',
    txt_global_std_mobilenumber: '携帯番号',
    txt_global_std_p2pgoheretocreateabookingofyourfriendscar: '友達の車の予約を作成するには、ここにアクセスしてください。',
    txt_global_std_wantmoreindepthanalysis: 'より詳細な分析や高リスク・低リスクのドライバーの特定に役立つ詳細を確認されますか？',
    txt_global_std_monthm: '月',
    txt_global_std_alinktodownloadyourdemoappwillbesenttoemailwhenready: 'デモアプリをダウンロードするためのリンクは、準備ができたら{email}に送信されます。',
    txt_global_std_countminutes: '{count} 分',
    txt_global_std_OSolbergMusic: 'Coming soon...',
    txt_global_std_remainingpoints: 'ポイント残高',
    txt_global_std_cimage13h: '減速しましょう',
    txt_global_std_norweigan: 'ノーウェイガン',
    txt_global_std_ifyouwantinfoonnotificationscallphonenumberorgotoweburlorgotothefaqpagelink: '通知に関する情報が必要な場合は、{phoneNumber}に電話するか、{webUrl}にアクセスしてください。または、{faqPageLink}にアクセスします。',
    txt_global_std_startyourcarsengine: '車のエンジンを始動します',
    txt_global_std_p2pviewless: '表示を減らす',
    txt_global_std_cimage18h: '計画的な平均速度',
    txt_global_std_startdrivingtoappearontheleaderboard: 'ドライブを開始して、スコアボードでスコアをゲット！',
    txt_global_std_p2pselectvehicle: '車両を選択',
    txt_global_std_aonceyoupairtheapptoyourcehiclesbluethoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhatscoreyougetwhendriving: 'アプリを車両のBluetoothとペアリングし、アプリが位置情報サービスを使用できるようにすると、AIのドライバーパターンのライブラリに運転習慣が一致し、スコアが提供されます。保険料は、運転中に得られるスコアに基づいています。',
    txt_global_std_connecttocarunlockedkey: '{user}さん、\nおめでとうございます！\nBluetoothでクルマとアプリが繋がりました！',
    txt_global_std_listview: 'リストビュー',
    txt_global_std_ifyourcarhasbluetoothbutdoesnotshowupintheappyoumightneedtocheckthebluetoothsettingsforbothyourphoneandyourcartryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagain: '車にBluetoothが搭載されているのにアプリに表示されない場合は、スマートフォンと車の両方のBluetooth設定を確認する必要があります。車を取り外してからもう一度追加して、車とスマートフォンの接続をリセットしてみてください。',
    txt_global_std_PolicySchedule2: 'Agency.',
    txt_global_std_invitenewmember: '新しいメンバーを招待する',
    txt_global_std_topimprover: 'トップインプルーバー',
    txt_global_std_advancedsettings: '高度な設定',
    txt_global_std_inviteafriend: '友達を招待する',
    txt_global_std_excellentdriving: 'すばらしい運転です！',
    txt_global_std_conversionkmmi: 'km/miを変更する',
    txt_global_std_p2pborrowcar: '車を借りる',
    txt_global_std_competitionrules: '競技ルール',
    txt_global_std_smspackagefiasdcactivation: 'Welcome to the FIA SDC!\n' +
      'Please click the link down below to automatically activate your purchase. \n' +
      '\n' +
      '[LINK]\n' +
      '\n' +
      'Activation code: {activationcode}',
    txt_global_std_challangeranking: '現在のランキング',
    txt_global_std_gdprterms: 'GDPR条件',
    txt_global_CoreTemplate_more: 'さらに見る',
    txt_global_std_riskmatcha: 'リスクマッチ {type}',
    txt_global_std_newpwdcheckinbox: '受信トレーをご確認ください！',
    txt_global_std_towed: 'けん引',
    txt_global_std_averagecosavings: '平均的な CO₂ 削減量',
    txt_global_std_startguide: 'スタートガイド',
    txt_global_std_bysigningupyouaccepttheusertermsprivacypolicy: 'サインアップすると、{userTerms}と{privacyPolicy}に同意したことになります',
    txt_global_std_cimage34d: '',
    txt_global_std_p2pdonthaveatemporarypasswordregisterat: '仮パスワードがありませんか？\n[わりかんKINTO公式ホームページ]で\nお申し込みください',
    txt_global_std_riskydecisionbetweendays: '数日間の危険な運転判断',
    txt_global_std_exceededlimit: '制限を超えました',
    txt_global_std_accountstartdate: 'アカウント開始日',
    txt_global_std_exchangedpoints: '交換済ポイント数',
    txt_global_std_challengetermsconditions: 'チャレンジ利用規約',
    txt_global_std_animalcollision123: '動物衝突',
    txt_global_std_litre: 'リットル',
    txt_global_std_p2pyoureceivedaninvoicetosplitcost: '費用を分割するための請求書を受け取りました。',
    txt_global_std_PolicySchedule13: 'MARKET VALUE AT THE TIME OF LOSS',
    txt_global_std_appcreator_upsell_pitch_bulletpoint2: 'コストのかかる開発時間なしで、デジタル製品を試験運用して成長させます',
    txt_global_std_timeline2: 'タイムライン',
    txt_global_std_buylater: '後で購入',
    txt_global_std_p2pmakeseparatebookings: '別々の予約',
    txt_global_std_skillscomparison: 'スキルの比較',
    txt_global_std_intraining: '指導中',
    txt_global_std_p2pfaq5q: 'メッセージ機能で写真を拡大する方法を教えてください？',
    txt_global_std_alertdrivingtext: '上手に運転しています。',
    txt_global_std_sonicethatyoudecidedtojoinus: 'ご参加ありがとうございます！',
    txt_global_std_buyheatsnowtocompete: '試合を購入して競争しましょう！',
    txt_global_std_wecanretrievedataformultiplecompletedtripsinasinglerequesthoweverwerecommendnottosendrequestswithdataexceeding50mb: '1 回のリクエストで複数の完了した旅行のデータを取得できます。ただし、50MB を超えるデータのリクエストは送信しないことをお勧めします。',
    txt_global_std_doyouwanttojointeam: 'チームに参加しますか？',
    txt_global_std_inviteafriendtostartachallenge: '友だちを招待して、\n対戦を始めましょう！',
    txt_global_std_driving: 'ドライブ中',
    txt_global_std_allusers: '全てのユーザー',
    txt_global_std_searchbyfilter: 'フィルタで検索',
    txt_global_std_youonlyhavedistancelefttotravelleft: '残っているのは{distanceLeftToTravel}だけです。',
    txt_global_std_selectrecipients: '受信者を選択',
    txt_global_std_startadrivemanually: 'Bluetoothに繋がずに\n運転をはじめる',
    txt_global_std_topdrivers: 'トップドライバー',
    txt_global_std_whenyourfriendusesyourreferralcodetoactivateanewsidekickcarinsurancebothofyouget250krinthepiggybank: 'あなたの友人があなたの紹介コードを使って新しいSidekick自動車保険を有効にすると、あなたとあなたの両方が貯金箱で250クローナを受け取ります。',
    txt_global_std_warikan_onboarding2: 'このたびは、わりかんKINTOアプリのご利用お申し込み、誠にありがとうございます。',
    txt_global_std_achievementtool: 'アチーブメントツール',
    txt_global_std_addexpenses: '費用を追加する\n',
    txt_global_std_communication: 'コミュニケーション',
    txt_global_std_idonthavebluetoothinmycar: '車にBluetoothが搭載されていません',
    txt_global_std_changeswillnotbesaved: '変更が保存されません',
    txt_global_std_1findyourobdportinyourcar2inserttheobdreaderandstartyourcarwaitforthereadertoshowabluelight3turnonyourphonesbluetooth4oncetheapprecognisestheobdreaderyoullmoveautomaticallytothenextstep5selectyourobddevicefromthelistobdmini2: '1: 車の OBD ポートを探します。 2: OBD リーダーを挿入し、車を始動します。リーダーが青色のライトを表示するまで待ちます。 3: 携帯電話の Bluetooth をオンにします。 4: アプリが OBD リーダーを認識すると、自動的に次のステップに進みます。 5: リストから OBD デバイスを選択します: OBD Mini 2',
    txt_global_std_p2pwearegladthatyouwanttousewarikanasawaytoshareyourcar: 'ワリカンを車のシェアリングとしてご利用いただき、誠にありがとうございます。',
    txt_global_std_welcomeback: 'おかえりなさい！',
    txt_global_std_alerthighusagetext: '警告！  使いすぎです。[amount] kr',
    txt_global_std_wrongusernameorpassword: 'ユーザー名かパスワードが間違っています。',
    txt_global_std_p2pborrowed: '借りた車',
    txt_global_std_telephonecontact: '電話',
    txt_global_std_p2pemailnotpaidbookings: '未払いの予約',
    txt_global_std_enterodometervalue: 'オドメーター値を入力してください',
    txt_global_std_stopdrivingq: '走行を中止しますか？',
    txt_global_std_pushnotdrivenforesometimetitle2: '友達がいなくて寂しいです！',
    txt_global_std_byinvitingfriendsyouwillearnloyaltypoints: '友だちを招待すると、ロイヤルティポイントを獲得できます。',
    txt_global_std_100offdeductible: '免責額100％オフ',
    txt_global_std_areyousure: 'よろしいですか？',
    txt_global_std_p2pfaq9a: 'メンバーに「確定した予約」の「クルマを借りる」から「予約を終了」を選択するよう伝えてください。そうすれば、クルマを貸すことができる状態に戻ります。',
    txt_global_std_undertheusersectionextendittoupdateyouraccountdetailsorlogoutfromtheadminportal: 'ユーザーセクションで、アカウントの詳細を更新するか、管理者ポータルからログアウトするように拡張します。',
    txt_global_std_cimage17h: '高速でのアクティブドライブ',
    txt_global_std_MSIG_InsuranceLetter33: 'Company Reg. No. 195000106C',
    txt_global_std_p2pyouwillnotbeabletoseeorwriteanymessagesinthisgroupchat: '退出すると、このグループチャットはご利用できなくなります。',
    txt_global_std_calculatethedates: '日付を計算する',
    txt_global_std_whywouldyou: 'なんで？',
    txt_global_std_workspace: 'ワークスペース',
    txt_global_std_yesterday: '昨日',
    txt_global_std_smartdriverweekly: '毎週のスマートドライバー',
    txt_global_std_effectivedates: '発効日',
    txt_global_std_whatdoineedtodoinordertoconnectmycar: 'What do I need to do in order to connect my car?',
    txt_global_std_yourfriendsnickname: 'お友達のユーザー名',
    txt_global_std_p2pcarborrow: '借りる車',
    txt_global_std_tuneup: '調整',
    txt_global_std_findmycar2: '車を探す',
    txt_global_std_gamificationinspirestosmarterdrivingforlowerco2imprintandfewerroadaccidents: 'ゲーミフィケーションは、CO2の痕跡を減らし、交通事故を減らすためのよりスマートな運転を促します',
    txt_global_std_turnonlocationservicesinyourphone: 'スマートフォンの位置情報サービス（GPS）をオンにしてください',
    txt_global_std_youhavebeenchallengedtoahead2headacceptitnow: 'あなたは直接対決することに挑戦しました。今それを受け入れます。',
    txt_global_std_managetheimpactyourteamhasonroadsafetyandco2emissions: 'チームが交通安全とCO2排出量に与える影響を管理する',
    txt_global_std_startguidetip1: 'OBDリーダーが車両のOBD接続に接続していることを確認してください。',
    txt_global_std_p2pconfirmedph: '確定済み({count}件)',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscores: 'A：アプリを車のBluetoothとペアリングし、アプリが位置情報サービスを使用できるようにすると、 AIが運転者の運転パターンを分析し、CO2排出量の削減効果、リスクレベルを測定します。',
    txt_global_std_statusheader: '状態',
    txt_global_std_25offdeductible: '免責額25オフ',
    txt_global_std_validatedata: 'データを検証する',
    txt_global_std_newtoagent: '{エージェント}は初めてですか？',
    txt_global_std_whydoyouwanttoterminateyourinsurance: 'Why do you want to terminate your insurance?',
    txt_global_std_user: 'ユーザー',
    txt_global_std_ourinsurancesolutionisbaseduponafixedandvariablecost: '私たちの保険ソリューションは、固定費と変動費に基づいています。固定費は、保険車両と車両所有者登録住所に基づいており、車両の使用頻度と使用方法に関係なく、毎月前払いで請求されます。変動費は、保険の適用範囲、走行距離、および運転行動に基づいています。運転のコストを計算して価格を設定するには、付属のOBDデバイス（Enerfyプラグイン）を車両にインストールする必要があります。または、車両にBluetooth経由で携帯電話を直接接続するオプションがある場合、Enerfyアプリは運転中にスマートフォンで実行されている。次に、変動費はプレミアムレベルに基づいて計算されます。つまり、個々の運転行動に基づいて、1から15までの運転レベルが計算されます。ここで、プレミアムレベル1は、キロメートルあたりのコストが最も低いレベルで、プレミアムレベル15は最高。可変コストはドライブごとに累積され、翌月に請求されます。接続されておらず、ドライブが登録されていない場合、プレミアムレベル10で請求されます。',
    txt_global_std_p2pyouneedtocreateabookingbeforeaddingaexpense: 'クルマの借り予約、もしくは運転履歴がまだないようです。費用はクルマの予約を行ってから追加してください。',
    txt_global_std_trygonboarding1: 'ご参加いただき誠にありがとうございます。プロフィールを準備してみましょう。',
    txt_global_std_p2pcostsplit: 'わりかん',
    txt_global_std_KristofferssonBesttip: 'Keep your eyes far ahead to anticipate traffic. This will give you more time to plan your driving.',
    txt_global_std_acceptconnectiontogetascore: 'スコア取得のための接続を許可',
    txt_global_std_p2ppasswordnotmatchedpleasetryagain: 'パスワードが一致しませんでした。もう一度お試しください。',
    txt_global_std_woman: '女性',
    txt_global_std_onesofthebestsdrivers: '最高のドライバーの1人！',
    txt_global_std_youhavedrivenatotalof: '走行距離の合計',
    txt_global_std_anyonewiththelinkcangetinvited: '（リンクを知っている人は誰でも招待できます）',
    txt_global_std_signinwith2: 'Sign in with',
    txt_global_std_pigsinvitedunlocked: '良い！友達を募集したので、貯金箱に250個のクラウンが追加されました',
    txt_global_std_contactinformation: 'お問い合わせ',
    txt_global_std_mpltiplvl7: 'ドライバーのトレーニングをおすすめします。特に集中力と計画性に着目したトレーニングが必要です。\n' +
      'それは、様々な交通状況やその他の条件下でも、決して集中力を途切れさせないということを意味しま\n' +
      'す。私たちヤマト運輸は、「親切な運転」「優先順位を考慮した運転」「危険を予測した運転」「信頼\n' +
      'を得られる運転」に特に注意しながら、常に運転に集中し、計画的に行動します。',
    txt_global_std_failedtoedittranslation: '翻訳の編集に失敗しました!',
    txt_global_std_pigwonlocked: 'この賞を獲得するには、head2headで友達と競って勝ちましょう',
    txt_global_EnerfyActivityDetailTemplate_comparetomy: 'と比較する',
    txt_global_std_letsdothis: 'やってみよう！',
    txt_global_std_yearlymilageused: '使用される年間走行距離',
    txt_global_std_browsetemplates: 'テンプレートを閲覧する',
    txt_global_std_verygood: 'とても良い',
    txt_global_std_kintofaq3q: 'ロイヤルティ・ステータスはどうすれば上がりますか？',
    txt_global_std_contactinfo: 'お問い合わせ',
    txt_global_std_pleasenotethatwewilldetuct: 'カード情報の更新時に、クレジットカードから１SEK引き落としますが、全額払い戻しいたします。 未払いの請求がある場合、更新直後に支払われます。また、お持ちのクレジットカードがネット購入対応であることをご確認ください。',
    txt_global_std_customizeyourapp: 'アプリをカスタマイズする',
    txt_global_std_toreportyourclaim: '請求について報告しますので、次のステップにすすんでください。請求を処理します',
    txt_global_std_p2ppressthedrivebuttontoconnecttoyourcarandstartatripyouwillgetfeedbackandinsights: 'ドライブボタンを押して車に接続し、旅行を開始します。あなたはあなたの運転に関するフィードバックと洞察を得るでしょう。',
    txt_global_std_planetsaver: 'プラネットセーバー',
    txt_global_std_speeddecrease: '減速',
    txt_global_std_CertificateofInsurance: 'Certificate of Insurance',
    txt_global_std_byinvitingfriendsyouwillearnachievements: '友達を招待することで、成果を獲得できます',
    txt_global_std_motorclaimshotline: 'Motor Claims Hotline',
    txt_global_std_friendstotheapp: 'Enerfy Loyalty Appの友達。',
    txt_global_std_dontworryaslongastheenerfyappisinstalledandyouareloggedin: "Don't worry, as long as the Enerfy app is installed and you are logged in, the app will connect automatically to your device.",
    txt_global_std_connectnow: '今すぐ接続',
    txt_global_std_emailwithremovallink: 'アカウントを削除するためのリンクが記載されたメールを送信しました。ご確認ください。',
    txt_global_std_totaltranslatedwords: '総翻訳単語数',
    txt_global_std_cimage24h: '最適な回転数を見つける',
    txt_global_std_baseusers: 'ベースユーザー',
    txt_global_std_keyname: 'キー名',
    txt_global_std_collectpointsbyearningbadgeseverybadgeisworthadifferentamountofpointstapabadgetolearnmoreaboutit: 'バッジを獲得して「わりかんアプリポイント」を貯めよう！\nバッジによって、獲得条件やポイント数が異なるので、気になるバッジは要チェック！',
    txt_global_std_selectcar: '車を選択',
    txt_global_std_pleasetryusinganotherusername: '他のユーザー名をお試しください。',
    txt_global_std_whyterminateinsurance: '{appName} 保険を終了する理由は何ですか?',
    txt_global_std_getdiscountcode: '割引コードを取得',
    txt_global_std_warikan_onboarding4_2: '◎お問い合わせはこちら',
    txt_global_std_confirmconnectionbetweenyourphoneandobdreader: 'スマホとOBDリーダ―の接続を確認してください。',
    txt_global_std_copytoclipboard: 'クリップボードにコピー',
    txt_global_std_p2pregisterkinto: 'Kintoを登録する',
    txt_global_std_accountdetails: 'アカウント詳細',
    txt_global_std_whyshouldanyonejoinyourteam: 'あなたのチームに参加したい人？',
    txt_global_std_pleaseenteravalidemailaddress: '有効なメールアドレスを入力してください',
    txt_global_std_planningfeedbacktips: '<b>（目の前の状況だけでなく）</b>さらに先を予測すると、通常、運転がスムーズになり、障害物も回避しやすくなります。​\n' +
      '\n' +
      '<b>ご自身と同乗者のために、快適なドライブを心がけましょう。</b>',
    txt_global_std_p2pifyouareakintoownerallyouneedtodoisregisteryourcarhereandyoullbereadytogo: 'キントのオーナーなら、ここで車を登録するだけで準備完了です！',
    txt_global_std_p2punread: '未読',
    txt_global_std_competitions: 'コンペ',
    txt_global_std_accountidentification: 'アカウントの確認',
    txt_global_std_starttrip: 'トリップを開始する',
    txt_global_std_youcanexpectthereadertoarrivewithin35businessdays: '読者は3-5営業日以内に到着することが期待できます。',
    txt_global_std_individualdrivers: '個々のドライバー',
    txt_global_std_p2pyoudonthaveanytripsyet: 'まだドライブデータがありません。',
    txt_global_std_tomczykDescription: 'After a karting career, Tom raced in Formula BMW and Formula 3. 2001 he was the youngest DTM driver ever at that time. He becomes DTM champion in 2011, third in 2007, fourth in 2006 and fifth in 2004, having won eight races. In total Tom was 16 years within the DTM championship. 2017 he raced in the IMSA in order to prepare himself for the endurance racing. 2018/19 he is part of the BMW M8 GTE WEC (World Endurance Championship) program.',
    txt_global_std_kintoImprovement3: 'フィードバックをチェックして、節約しましょう！',
    txt_global_std_failedtoremove: '削除に失敗しました',
    txt_global_std_youractivationcodes: 'アクティベーションコード',
    txt_global_std_warikan_onboarding18: '仮のパスワード:',
    txt_global_std_doyouwanttocontinue: 'Do you want to continue',
    txt_global_std_tomasengeinterests: 'レース全般、子供',
    txt_global_std_TaxInvoice12: 'Payment by a policyholder to an agent or a broker is deemed payment to the insurer',
    txt_global_std_MSIG_InsuranceLetter8: 'Type of Cover.',
    txt_global_std_p2ppleaseselectacar: 'クルマを選択してください',
    txt_global_std_history: '履歴',
    txt_global_std_drivtimeover80: '{speed}以上で走行した時間',
    txt_global_std_whyyoushouldusefiasdcforyourbusiness: 'ビジネスにFIASDCを使用する理由',
    txt_global_std_trackingofyourco2emissions: 'CO2排出量の追跡。',
    txt_global_std_sender: '送信者',
    txt_global_std_replaydrive: 'トリップをリプレイ',
    txt_global_std_homeaddress: '自宅住所',
    txt_global_std_teammemberbig: 'チームメンバー\n',
    txt_global_std_p2pbookingpending: '予約保留中',
    txt_global_std_p2pselectedfriend: '選択した友だち',
    txt_global_std_appcreator_upsell_pitch_bulletpoint3: '顧客中心のデジタル ファースト ソリューションの競争で優位に立つ',
    txt_global_std_opensettingsinthephoneturnon: 'スマホの「設定」を開き、Bluetoothをオンにしてアプリを開きます。',
    txt_global_std_pleaseentercompanyname: '会社名を入力してください',
    txt_global_std_therearenoaccountscreatedundertheagreement: '契約に基づいて作成されたアカウントはありません。',
    txt_global_std_p2pfaq14a: 'メッセージ機能を使って、オーナーにクルマの返却が遅れることをお伝えください。\nまた、延長時間分を追加で予約してください。',
    txt_global_std_allow: '許可',
    txt_global_std_vehicleapi: '車両 API',
    txt_global_std_yourmaxspeed: '最高速度',
    txt_global_std_p2pemailforcommunication: '連絡用メール',
    txt_global_std_loyaltystatus: 'ステータス',
    txt_global_std_gps: 'GPS',
    txt_global_std_mpltipdev7pp: 'このドライバーは熟練したドライバーですが、運転に一貫性がないことが示されています。数日間、も\n' +
      'しくは数週間に渡って模範的な運転が実践できていますが、たった1 日だけ、模範的な運転が無視され\n' +
      'ることがあります。ドライバーの運転に対する意識の変化には何らかの原因がありますが、その理由を\n' +
      '特定することは困難です。そこで、ドライバーと簡単な話し合いの場を持ち、なぜこのようなことが起\n' +
      'こるのか、ドライバーの意見を聞いてみることをおすすめします。',
    txt_global_std_youmustpickadate: 'データを選択してください。',
    txt_global_std_riskindex: 'リスク指標',
    txt_global_std_settingssmall: '設定',
    txt_global_std_ended: '終了済',
    txt_global_std_nickname: 'ニックネーム',
    txt_global_std_getoverviewtrips: 'すべてのトリップの概要を見る',
    txt_global_std_cimage23h: 'ROLL SOME EXTRA METERS  もう少し走行しましょう。',
    txt_global_std_itsalittleemptyinhere: 'ここは少し空っぽです',
    txt_global_std_ifbuttondontworktrylink: '上のボタンが機能しない場合は、次のリンクをWebブラウザに貼り付けてください。',
    txt_global_std_teamname1: 'チームの名前',
    txt_global_std_dontstress: 'イライラしないこと',
    txt_global_std_areyousureyouwanttoredeempointstotradepoints: '{pointsToTrade}ポイントを交換してもよろしいですか？',
    txt_global_std_ConfirmFia_Text6: 'Place de la Concorde, 75008 Paris, France',
    txt_global_std_addyourvehicleidentificationtobeabltoenjoyallthebenefitsandclaimyourrewards: '車両IDを追加して、すべての特典を享受し、特典を請求できるようにしてください。',
    txt_global_std_yourfriendslistlooksabitemptyusethebuttonbelowtostartinvitingyourfriends: '友だちリストに対戦できる\n友だちがいません。\n下のボタンから友だちを\n招待して始めましょう！',
    txt_global_std_MSIG_InsuranceLetter27: '* Limitations rendered inoperative by Section 8 of the Motor Vehicles (Third-Party Risks and Compensation) Act (Chapter 189) and Section 95 of the Road Transport Act, 1987 (Malaysia), are not to be included under these headings.',
    txt_global_std_addfriends: '友だちを追加する',
    txt_global_std_checkifcorrectobdreaderisconnected: '適切なOBDリーダーが接続されているか確認してください。',
    txt_global_std_placethewarningtriangleatleast100metersfromthecar: '車から少なくとも100メートルのところに三角表示板を置きます',
    txt_global_std_cimage28h: 'クルーズコントロールに適していません',
    txt_global_std_wall: '外壁',
    txt_global_std_somethingiswrong: '失敗しました',
    txt_global_std_p2pfaq32q: 'かかった費用の「わりかん」方法を教えてください。',
    txt_global_std_accessdeniedyoudonothavepermissiontoaccesstheworkspacepleasecontactyouradministratorifincorrect: 'ワークスペースにアクセスする権限がないため、アクセスが拒否されました。管理者に連絡してください。',
    txt_global_std_ChicheritQuote: 'Coming soon...',
    txt_global_std_insuranceactivesince: 'Insurance active since',
    txt_global_std_MSIG_InsuranceLetter4: 'Motor Vehicles (Third-Party Risks and Compensation) Rules, 1960',
    txt_global_std_enginescanner: 'エンジンスキャナー',
    txt_global_std_bestscore: 'ベストスコア',
    txt_global_std_howitworks1: '使い方',
    txt_global_std_p2pcleaning: '清掃費',
    txt_global_std_MSIG_InsuranceLetter37: '36 Robinson Road #16-01 City House Singapore 068877 Tel: (65) 6507 3848 Fax: (65) 6507 3849',
    txt_global_std_heavydamage: '大きなダメージ？',
    txt_global_std_KristofferssonFacts: 'Johan started his career with International Super Series with Sweden team where he impressed and moved on to Touring Car Championship (STCC) where he won in a single season in 2002. He moved then to the European RX in 2013-2014 season and in 2015 to the FIA WRX where he won 5 rallies and came out 3-rd in overall ranking. Ended runner up in 2016 behind his countryman Mattias Ekström and won it in 2017.',
    txt_global_std_youwilllosedrivedata: '他のユーザーでログインした場合、ドライブを含むすべてのデータが消去されます。',
    txt_global_std_typeoftraining: '運転タイプ',
    txt_global_std_MSIG_InsuranceLetter18: 'Authorised Driver*',
    txt_global_std_setupcompanychallenge_fia_text: 'FIA SDC のチームに参加して、車で旅行するときもアクティブで、スマートで、持続可能な状態を保ちましょう。',
    txt_global_std_selectyourcar: '車を選択してください',
    txt_global_std_youarearockstardriver: 'このトリップでは、素晴らしいドライブでした！現時点で、アドバイスする点は何もありません。',
    txt_global_std_trygroadsideassistance: '道端での援助を試す',
    txt_global_std_MSIG_Password1: 'Hello!',
    txt_global_std_unknown: '不明',
    txt_global_std_youhaveupdatedyourdetails: '詳細を更新しました',
    txt_global_std_cimage31d: '',
    txt_global_std_promocode: 'プロモーションコード',
    txt_global_std_youvefinishedyourdrive: 'ドライブ終了です！',
    txt_global_std_learnmore: 'もっと見る',
    txt_global_std_amazingdriverlockedkey: '1回の運転でスコアが{score}以上を​\n記録した場合に獲得できるバッジ​\n（獲得上限：1日1回まで）',
    txt_global_std_reportaccidentheadtext: '事故が発生した場合は、以下にできるだけ早く報告してください。10秒以内で完了します。',
    txt_global_std_expired: '期限切れ',
    txt_global_std_locationreasontext: '運転スコアを決定するには、位置情報サービスが必要です。位置情報サービスが許可されていない場合、アプリはバックグラウンドモードで動作せず、旅行を自動的に開始しません。',
    txt_global_std_searchbygroupname: 'グループ、名前で検索',
    txt_global_std_MarklundChamp: '2x FIA European Rallycross Champion',
    txt_global_std_insnum: 'Ins. number',
    txt_global_std_itseasytomonitoryouresgtargets: 'ESGターゲットを監視するのは簡単です',
    txt_global_std_kintorecruit5userslocked: 'この目標を達成し、Kintoユーザーを5人紹介すると10000円分が付与されます。',
    txt_global_std_unplugtheobdreaderandplugitbackagain: 'OBDリーダーを外して付け直し、青に点灯するまでお待ちください。',
    txt_global_std_activeparticipantsfor: 'のアクティブな参加者',
    txt_global_std_SignupFia_Text5: 'SDCアプリから予告をご覧いただけます！',
    txt_global_std_restrictions: '制限',
    txt_global_std_stayfocused: '集中する',
    txt_global_std_TomczykFacts: 'After a karting career, Tom raced in Formula BMW and Formula 3. 2001 he was the youngest DTM driver ever at that time. He becomes DTM champion in 2011, third in 2007, fourth in 2006 and fifth in 2004, having won eight races. In total Tom was 16 years within the DTM championship. 2017 he raced in the IMSA in order to prepare himself for the endurance racing. 2018/19 he is part of the BMW M8 GTE WEC (World Endurance Championship) program.',
    txt_global_std_fileinput: 'ファイル入力',
    txt_global_std_theapikeydoesnthavepermissionstoperformtherequest: 'API キーには、リクエストを実行する権限がありません。',
    txt_global_std_latesttrip: '最新の旅行',
    txt_global_std_areyousureyouwanttodeletethegroupdeletingthegroupdoesnotdeletetheusersaccounts: 'グループを削除してもよろしいですか？\n\nグループを削除しても、ユーザーアカウントは削除されません。',
    txt_global_std_theuserwillbeaddedtoyouragreementaccordingtoyourpriceplan: 'ユーザーは、料金プランに従って契約に追加されます。',
    txt_global_std_rwmselectfriendtext: '一緒に乗った友だちを選択して下さい',
    txt_global_std_welcomeresendemail: '新しいアカウントへようこそ！新しいアカウントを確認するためのメールを送信しました。スパムフォルダを確認することを忘れないでください。メールが届きませんでしたか？',
    txt_global_std_automaticstartandstoprequiresaccesstobluetoothlocationservicesgpsandmotionfitness: '自動開始と停止には、Bluetooth、位置情報サービス（GPS）、モーションとフィットネスへのアクセスが必要です。',
    txt_global_std_riskportfoliotracker_upsell_pitch: '本全体を簡単に接続して、以下の実際のリスクと CO2 の影響を 1 つのビューに表示できます。',
    txt_global_std_inviteall: 'すべてを招待する',
    txt_global_std_enterdriverriskweight: 'ドライバのリスク ウェイトを入力してください',
    txt_global_std_warikan_onboardingemailsubject: '【わりかんKINTO】アプリ利用方法のご案内',
    txt_global_std_couldnotsavechanges: '変更内容を保存できません',
    txt_global_std_youraveragelevelispoints: 'あなたの平均レベルは{ポイント}です',
    txt_global_std_connectivity: '接続性',
    txt_global_std_avgrisklevel: '平均スコア',
    txt_global_std_youneedtodrivemorethankmkmduringaheatweekinordertogetascoreintheheatleaderboard: '今週{km}km以上走行している参加者が対象です',
    txt_global_std_driveraverage: 'ドライバー平均',
    txt_global_std_challengeleaderboard_upsell_pitch: 'チーム、部門、顧客、パートナー、およびその他のゲスト向けの課題を整理します。',
    txt_global_std_noofusers: 'ユーザー数',
    txt_global_std_yourunsavedchangeswillbelostsavechangesbeforeclosing: '保存されていない変更は失われます。閉じる前に変更を保存しますか？',
    txt_global_std_emailuniqueinvitations: 'ユニークな招待状をメールで送信',
    txt_global_std_TaxInvoice1: 'TAX INVOICE',
    txt_global_std_starburstnamekey: 'スターバースト！',
    txt_global_std_p2paskakintoownertoinviteyouandyouwillgetaccesstoallthecoolfunctionsinthisapp: 'Kintoの所有者に招待してもらうと、このアプリのすべての便利な機能にアクセスできるようになります。',
    txt_global_std_continue123: '続ける',
    txt_global_std_p2pcoolandfunstatsaboutyourfriendsbookingofyourcar: 'あなたの友人のあなたの車の予約についてのクールで楽しい統計。',
    txt_global_std_ridewithmekey: 'ライドシェア​\nボーナス​',
    txt_global_std_joinrandom: 'ランダムに参加',
    txt_global_std_couldnotcalculatespeedimprovementpossibility: '速度の改善点を計算できません！',
    txt_global_std_trygdemo: 'スキップしてTryg Driveを始める',
    txt_global_std_from2: 'から',
    txt_global_std_driveminimum1kmduringaheatinordertoqualifyforthechallenge: '•チャレンジの資格を得るために、ヒート中に最低1kmをドライブします。',
    txt_global_std_invitedmembers: '招待されたメンバー',
    txt_global_std_p2ppushtitlelendingcomingup: '貸し出し日時が近づいています',
    txt_global_std_communication_upsell_bulletpoint2: '好みのチャネルでユーザーとつながる',
    txt_global_std_startlendingyourcar: '車の貸し出しを始めましょう！',
    txt_global_std_seehowmuchco2savingsyougetwhenyoudrivewell: 'よく運転すると、どれだけのCO2節約が得られるかを確認してください',
    txt_global_std_trip: 'トリップ',
    txt_global_std_opensettingsinyourphonegotoprivacy: 'スマホの「設定」を開きます。「プライバシー」から「位置情報サービス」を開き、FIA Smartに対してオンにしてください。',
    txt_global_std_warikan_onboarding17: 'メールアドレス:',
    txt_global_std_sendmyposition: '位置を送信',
    txt_global_std_buymorekilometers: 'もっと距離を買う',
    txt_global_std_letsstartwiththebasics: '基本から始めましょう',
    txt_global_std_nofiltersyet: 'まだフィルターはありません',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint2: 'ドライバーがマイルストーン/目標を達成したことを祝福する',
    txt_global_std_warikan_onboarding15: '株式会社 KINTO',
    txt_global_std_trysidekick: 'Sidekickをお試しください',
    txt_global_std_carsagreements: '車と契約',
    txt_global_std_thepasswordminimumlengthis8charactersandmustinclude1lowercaseuppercasenumeralandspecialcharacter: 'パスワードの最小長は8文字で、小文字、大文字、数字、および特殊文字を1つ含める必要があります',
    txt_global_std_SignupFia_Text4: 'Are you up for the challenge?',
    txt_global_std_confirmationmail: '予約完了！',
    txt_global_std_invoiceoverview: 'コスト',
    txt_global_std_welcometoappname: '{appName}へようこそ！',
    txt_global_std_whenwillmyratechange: '料金はいつ変更されますか？',
    txt_global_std_gpserror: 'GPSエラー',
    txt_global_std_qhowdoifileaclaim: 'Q：クレームを提出するにはどうすればよいですか？',
    txt_global_std_analyzingtheresults: '今回の運転を分析中',
    txt_global_std_exportexcel: 'XLSをエクスポート',
    txt_global_std_toturnonbluetoothinyourphone: 'スマートフォンでBluetoothをオンにするには、設定に移動して、そこからオンにすることができます。コントロールセンターから下にスワイプしてBluetoothをオンにすることもできます。',
    txt_global_std_manageadvancedusersettingsandsupporthereusethequickaccessbuttonsbelowtoeasilynavigatebetweenuserscarsagreementsandfollowupandhelpoutwiththeironboarding: 'ここで高度なユーザー設定とサポートを管理します。下のクイックアクセスボタンを使用して、ユーザー、車、契約間を簡単に移動し、フォローアップしてオンボーディングを支援します。',
    txt_global_std_target: '目標',
    txt_global_std_inordertoreceiveadrivingdividend75ofthedistancetraveledmustbecompletedwiththeappandchiptenfoldremembertohaveboththeappandbluetoothonwhenyoudriveandthetripwillberegistered: '運転配当を受け取るには、移動距離の75％をアプリとチップで10倍完了する必要があります。運転するときはアプリとBluetoothの両方をオンにすることを忘れないでください。そうすれば、旅行が登録されます。',
    txt_global_std_youcanalwaysgobacktothisvideoguidebyclickingtheinthetopnavigationbar: '[?] をクリックすると、いつでもこのビデオ ガイドに戻ることができます。上部のナビゲーション バーにあります。',
    txt_global_std_wowgoodprogress: 'うわー、良い進歩です！',
    txt_global_std_myinsurance2: '保険',
    txt_global_std_adjustyourannualmileage: '年間走行距離を調整する',
    txt_global_std_changelater: '今変更する',
    txt_global_std_p2pcalendar: 'カレンダー',
    txt_global_std_publicanyonewiththeappcanparticipate: '公開（アプリを持っている人なら誰でも参加できます）',
    txt_global_std_key: '鍵',
    txt_global_std_countryofresidence: '居住国',
    txt_global_std_overviewoftheonboarding: 'オンボーディングの概要',
    txt_global_std_analyzingyourdrive1: 'ドライブの分析',
    txt_global_std_wrongcurrentpassword: '現在のパスワードが間違っています。もう一度やり直してください。',
    txt_global_std_p2penterthedigitcodeorclickonthelinksentto: '{number}に送られた\n6桁のコードを入力してくだ\nさい。\n',
    txt_global_std_messageheader: 'メッセージ ヘッダー',
    txt_global_std_wewilldeducttheinvoicepaymentfromthe: 'We will deduct the invoice payment from you the 1st day each month for the fixed premium in advance and the variable premium for the past month. The deduction will be made on the debit or credit card you used when purchasing the insurance.',
    txt_global_std_unlockbenefitsandrewards: '特典と特典のロックを解除する',
    txt_global_std_new: '新着',
    txt_global_std_activationcodeisnotvalid: 'Activation code is not valid.',
    txt_global_std_fueltype: '燃料の種類',
    txt_global_std_saveandclose: '保存して閉じる',
    txt_global_std_ifyoudonthaveanygroupsyetyoucancreatetheminthegroupsmodule: 'まだグループがない場合は、グループ モジュールで作成できます。',
    txt_global_std_p2ppushtitlenewmessage: '{name}さん',
    txt_global_std_keepasafedistance: '車間距離を保つ',
    txt_global_std_email2: 'Eメール',
    txt_global_std_update: '更新',
    txt_global_std_p2phasconfirmedbooking: 'さん 予約を確定',
    txt_global_std_ifyoudidntmakerequestignore: 'このリクエストを行わなかった場合は、このメールを無視しても問題ありません。',
    txt_global_std_levellevel: 'レベル{level}',
    txt_global_std_howcaniimprovemyscore: 'どうすればスコアを改善できますか？',
    txt_global_std_pressondevicebelowtoaddit: 'Pre以下のリーダーをタップして追加してください',
    txt_global_std_yousignedupforournewsletter: 'ニュースレターへのお申し込みが完了しました',
    txt_global_std_wereanyothercarsinvolved: '他の車両も巻き込みましたか？',
    txt_global_std_p2pfaq26q: 'クルマをメンバーに貸し出す方法を教えてください。',
    txt_global_std_invalidoldpasswordornewpassword: '無効な以前のパスワードまたは新しいパスワード',
    txt_global_std_ecodriving: 'エコドライブ',
    txt_global_std_general: '一般的な場合',
    txt_global_std_PaddonInterests: 'Racing, Golf, Mountain Biking',
    txt_global_std_thisisneededtogiveyouestimatedsavings: 'これは、推定節約額を提供するために必要です。',
    txt_global_std_p2pshowyourreferralcode: '友達の紹介コードを表示する',
    txt_global_std_platenumberornameofcar: 'ナンバーまたは車名',
    txt_global_std_activationcodeisrequired: 'アクティベーションコードが必要です。',
    txt_global_std_motionfitnesswillhelpyoustartandstopdrivesautomaticallysothatyoucanjustfocusonyourdriving: 'Motion＆Fitnessは、ドライブを自動的に開始および停止するのに役立つため、運転に集中できます。',
    txt_global_std_timet: '時間',
    txt_global_std_savechanges2: '変更内容を保存？',
    txt_global_std_p2pfinishandpayquestion: '終了しますか？',
    txt_global_std_reactivateinsurance: '保険を再開する',
    txt_global_std_p2pgoheretofindcostsplitfunctioncheckyourdrivingstatsvisityourfriendsprofileandmore: 'コスト分割機能を見つけたり、運転統計を確認したり、友達のプロフィールにアクセスしたりするには、こちらにアクセスしてください。',
    txt_global_std_firedamage123: '火災被害',
    txt_global_std_firstwithscore: '最高スコアをめざせ！',
    txt_global_std_p2pconfirmedthebooking: 'リクエストは承諾されました',
    txt_global_std_numberofparticipants: '参加者数',
    txt_global_std_HansenShortDescription: 'ヨーロッパラリークロス選手権2016, RX Lite Cup 2015, World Champion in ROK Junior 2010',
    txt_global_std_nextstatus: '次のステータス',
    txt_global_std_somethingwentwrong: '何らかの問題が発生したため、現在この操作が実行できません。',
    txt_global_std_thatsokwhenyouarereadytodriveyoucanconnecthere: '車への接続をスキップします。\n右上の「✖」または画面下のボタンを\nタップし、この画面を閉じてください。',
    txt_global_std_viewalltrips: 'すべての運転を表示',
    txt_global_std_fiasdcecoscore: 'FIASDCエコスコア',
    txt_global_std_nothanks2: '断る',
    txt_global_std_onlylettersnumbersandperiodsareallowed: '文字（a〜z）、数字（0〜9）、およびピリオド（。）のみが許可されます。',
    txt_global_std_15discountonrenewal: '更新の15％割引',
    txt_global_std_yourcarwillstillbeinsuredbutyourpremium: 'Your car will still be insured, but your premium cost might increase due to us billing you at premium level 10 for non connected drives.',
    txt_global_std_howdoisignupfortheinsurance: '保険に加入するにはどうすればよいですか？',
    txt_global_std_youralreadyuseddevices: 'すでに使用しているデバイス',
    txt_global_std_borrowacar: '車を借りる',
    txt_global_std_create: '作成する',
    txt_global_std_totalsavings: '総貯蓄',
    txt_global_std_anticipation: '予測運転',
    txt_global_std_allday: '1日',
    txt_global_std_noeventtoday: '本日のイベントはありません',
    txt_global_std_p2paddexpense: '費用を追加する',
    txt_global_std_signingupforinsurance: '保険に加入する',
    txt_global_std_MSIG_InsuranceLetter9: 'Certificate No.',
    txt_global_std_ridewithmelockedkey: '友だちとライドシェアする​\nと獲得できるバッジ​\n詳細はメニュー画面から​\n確認できます​\n（獲得上限：1週間に1回まで）​',
    txt_global_std_accruedlevel: '未収レベル =',
    txt_global_std_joinenerfy: 'Join {appName}',
    txt_global_std_herunderkandusefleredetaljeromdennetur: 'Herunder kan du se flere detaljer omdennetur。',
    txt_global_std_402requestfailed: '402 - リクエストが失敗しました',
    txt_global_std_invitefriend: '友達を招待する',
    txt_global_std_almosttherewewillnowbuildyourdemoapp: 'もうすぐです。次に、デモアプリをビルドします。',
    txt_global_std_connectedbluetoothid: '接続されたBluetoothID',
    txt_global_std_nosampledata: 'サンプルデータがありません',
    txt_global_std_nicknamesmayonlyincludeletters: 'ニックネームには文字のみを含めることができます',
    txt_global_std_appcreatoroverview: 'App Creator の概要',
    txt_global_std_75offdeductible: '免責額75％オフ',
    txt_global_std_whendrivingincityconditionsavoidchasingthenextcrossingortrafficlightbygainingspeedquicklythenslowingquicklythishurtstraveltimeandfueleconomyandincreasescrashrisklookingwellaheadanddrivingsmoothlywillhelpyouanticipateandhandleanyeventnew: '市街地を走行するときは、次の交差点や信号機を追わずに、すぐに加速してから減速してください。これにより、移動時間と燃費が悪化し、衝突のリスクが高まります。先を見据えてスムーズに運転することで、あらゆる出来事を予測して対処することができます。',
    txt_global_std_HSolbergQuote: `Being a professional driver for so many years now, I have been part of a lot of driving challenges in my career. The first thing that comes in my mind when I am behind the wheel is the “safe driving”. Let's take this challenge together in the FIA Smart Driving Challenge for "safer roads".`,
    txt_global_std_PettersolbergDescription: 'The first person to be crowned an FIA World Champion in two different motorsport disciplines, Petter Solberg followed up his 2003 World Rally Championship success by winning the FIA World Rallycross Championship for two consecutive seasons in 2014 and 2015. Having begun his motorsport career with rallycross outings in his native Norway, Solberg – known as ‘Hollywood’ to his many fans for his crowd-pleasing antics – made his World Rally Championship debut in 1998 before a switch to Subaru for 2000 preceded his first win, on the 2002 Rally GB.',
    txt_global_std_BeforeFirstBill_Text3: '毎月、2種類の保険料をお支払いいただきます（変動と固定）固定は車種に基づき、保険料は一定です。変動は走行によって変わります。安全なスマートドライブであれば、保険料は下がります。',
    txt_global_std_filternofilter: 'フィルターなし',
    txt_global_std_fiasdccompanychallenge: 'FIASDCカンパニーチャレンジ',
    txt_global_std_vehicleidentificationwassaved: '車両識別番号が保存されました',
    txt_global_std_areyousureyouwanttodeletethegroup: 'グループを削除してもよろしいですか？',
    txt_global_std_seeyourprogressandgetinsightsandfeedbackonyourdriving: 'あなたの進歩を見て、あなたの運転に関する洞察とフィードバックを得てください。',
    txt_global_std_asyoumayhaveareportconsistingofmanymembersyouareabletosubmitarequesttodownloadthewholesetifyouwish: '多くのメンバーで構成されるレポートがある場合があるため、必要に応じて、セット全体をダウンロードするリクエストを送信できます。',
    txt_global_std_welcometocompany: '[会社]へようこそ！',
    txt_global_std_secondsec: '秒',
    txt_global_std_useapi: 'API を使用する',
    txt_global_std_howdoicompeteinappchallengeanswer: 'サインアップすると自動的に参加します。心配しないでください。リーダーボードにはニックネームしか表示されないため、常に匿名のままです。月次および年次の競争があります。',
    txt_global_std_youwillsoonbeready: 'まもなく準備完了です！',
    txt_global_std_conversionto: 'に変更する',
    txt_global_std_TidemandShortDescription: '2017年WRC 2チャンピオン, Touring Car Champion 2010.',
    txt_global_std_manageyourteammembersandtheiraccountpermissions: 'チームメンバーとそのアカウント権限を管理する',
    txt_global_std_learnmoreaboutus: '私たちについてもっと知る',
    txt_global_std_sendcodetome: 'Send me the code',
    txt_global_std_ready2go: 'クルマと繋がろう',
    txt_global_std_yourplateshouldcontainacombinationofnumbersandletters: 'プレートには数字と文字の組み合わせを含める必要があります。',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimeinconstantspeedunnecessaryspeedvariationcansometimesindicatedistractionorlackoffocus: 'このタイプの旅行では、平均的なエコ チャンピオンは一定の速度でより多くの時間を費やしました。不必要な速度変動は、注意散漫や集中力の欠如を示している場合があります。',
    txt_global_std_whymustiallowalwaysallowlocationsservices: 'なぜ「常に位置情報を許可する」必要があるのですか?',
    txt_global_std_collect: '収集する',
    txt_global_std_acceptusertermsconditionsandprivacypolicy: 'ユーザー利用規約と個人情報保護方針に同意してください。',
    txt_global_std_atleast10characters: '10文字以上',
    txt_global_std_added: '追加日時',
    txt_global_std_MSIG_InsuranceLetter38: "Any person who is driving on the Insured's order or with their permission.",
    txt_global_std_OgierQuote: 'Coming soon...',
    txt_global_std_tryingtosignyouintoenerfy: 'Trying to sign you in to {appName}…',
    txt_global_std_noreaderyetskipthisstep: 'No reader yet? Skip this step',
    txt_global_std_almostdone: 'まもなく完了',
    txt_global_std_anactivationcodecanbeusedtogetaccesstospecificeventsandchallenges: 'アクティベーションコードを使用して、特定のイベントやチャレンジにアクセスできます。',
    txt_global_std_points123: 'ポイント',
    txt_global_std_forquestionsandfeedbackregardingtheappcontacttheemailabove: '本アプリについての質問とフィードバックをお願いします。',
    txt_global_std_theactivationcodehasbeensent: 'アクティベーション コードが送信されました。',
    txt_global_std_liveonmap: 'マップに表示',
    txt_global_std_customerservice: 'カスタマーサービス',
    txt_global_std_somethingwentwrongpleasetryagainlater: 'エラーです。後ほど再度お試しください。',
    txt_global_std_PolicySchedule17: 'NCDP',
    txt_global_std_savechanges: '変更内容を保存',
    txt_global_std_acceptthedongle: 'スマホにドングルの接続を許可してください',
    txt_global_std_averagespeed: '平均速度',
    txt_global_std_annualprice: '年間価格',
    txt_global_std_youneedtodrivemorethan10kmduringaheatweekinordertogetascoreintheheatleaderboard: '今週10km以上走行している参加者が対象です\n',
    txt_global_std_cimage31h: '加速はしないよう心がけましょう',
    txt_global_std_enteravalidcode: '有効なコードを入力してください。',
    txt_global_std_firstdriveunlocked: 'スコア1000を超えました！= 100ポイント',
    txt_global_std_resendpassword: 'パスワードを再送する',
    txt_global_std_chassinumberalreadyexists: 'シャーシ番号はすでに存在します',
    txt_global_std_nextqualificationfortheendfinalstarts: '決勝戦に向けた次の予選が始まります。',
    'txt_global_std_Al-KuwariChamp': '2nd Place 2016 FIA World Cup for Cross-Country Rallies - T2 - Co-Drivers, 1st 2017 FIA MERC 2 - Co-Driver,1st 2018 FIA MERC 2 - Co-Driver',
    txt_global_std_p2ppushbodyhasnowfinalizedthebooking: '{name}さんが予約を終了しました',
    txt_global_std_enerfyriskportfoliotracker: 'Enerfy リスク ポートフォリオ トラッカー',
    txt_global_std_socialbutterflylockedkey: '10人以上の友だちを紹介すると、Kintoウォレットに300ポイント付与されます。',
    txt_global_std_letsgetstarted: 'はじめよう！',
    txt_global_std_onboardingstatus: 'オンボーディングステータス',
    txt_global_std_fiasdc2019w1price3: '1シーズンパッケージ',
    'txt_global_std_ben-sulayemDescription': '彼はアフリカ、ヨーロッパ、中東、そして世界のラリー選手権で闘い、首のケガからの復帰中にタイトルの1つを獲得しました。',
    txt_global_std_drivingtogetherismorefunaddyourcoworkersorsendthemalink: '一緒に運転するのはもっと楽しいです。同僚を追加するか、リンクを送信してください。',
    txt_global_std_subscribetoupdates: 'アップデートを購読する',
    txt_global_std_p2pfaq16q: '事故を起こした場合どうすればいいですか？',
    txt_global_std_p2pcalendaroverview: 'カレンダーの概要',
    txt_global_std_april: '4月',
    txt_global_std_sendsms: 'SMSを送信',
    txt_global_std_thisisrequired: '必須項目',
    txt_global_std_WelcomeLetter_Text3: '車両保険についてご不明点はお問い合わせください。{appName}の機能についてじゃwww.enerfy.se をご確認ください。',
    txt_global_std_performancechart: 'パフォーマンスチャート',
    txt_global_std_p2paddmember: 'メンバーを追加する',
    txt_global_std_p2pselectcalendar: 'カレンダーを選ぶ',
    txt_global_std_youhave: 'あなたが持っている',
    txt_global_std_premiumdiscount: 'プレミアム割引',
    txt_global_std_comebacklatertherearenochallengeshereatthemoment: '後で戻ってきてください。現時点では、ここに課題はありません。',
    txt_global_std_viewriskandco2levelsregardlessofvehicletypeandlocation: '車種や場所に関係なく、リスクとCO2レベルを表示する',
    txt_global_std_shareyourredeemcode: '利用コードを共有する',
    txt_global_std_p2ppleaseenteravalidpriceabovezero: '1円以上の有効な金額を入力してください。',
    txt_global_std_seedetails: '詳細を見る',
    txt_global_std_p2pnewmessage: 'メッセージを入力する',
    txt_global_std_thisisgonnabesofunletsgetstartedwithyourreallysmartdriving: '楽しいこと間違いなし！スコアを参考にスマートドライブを始めよう！',
    txt_global_std_competitionformat: '競技形式',
    txt_global_std_createanaccounttocollectandstoreyourresultsitsfree: '結果を収集して保存するためのアカウントを作成します。それは無料です！',
    txt_global_std_wrongpasswordpopuptext: '間違ったパスワード',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimedecreasingspeedeffectiveobservationandleavingasafefollowingdistancecanhelpreduceunnecessaryspeedvariations: 'このタイプの旅行では、平均的なエコ チャンピオンは速度を下げるのに費やす時間が短くなりました。効果的に観察し、安全な車間距離を保つことで、不必要な速度変動を減らすことができます。',
    txt_global_std_thereisnoinsurancetoterminate: '満期になる保険はありません',
    txt_global_std_zipdriverriskmorerelevantthancreditscore: 'クレジット スコアよりも関連性の高い Zip-Driver リスク',
    txt_global_std_drivesafethismonth: '今月の安全運転で',
    txt_global_std_p2pnoconfirmedbookingrequests: '確認済みの予約リクエストはありません',
    txt_global_std_vehicletype2: '車両タイプ',
    txt_global_std_safetydrivingskillupwithwarikankinto: 'わりかんKINTOで安全運転スキルアップ！',
    txt_global_std_notuploadeddrivingtime: '走行時間がアップロードされていません',
    txt_global_std_p2pnopendingbookingrequests: '保留中の予約リクエストはありません',
    txt_global_std_youhavenofinishedchallengesyet: 'まだチャレンジは終わっていません',
    txt_global_std_unabletosavesettings: '設定を保存できません',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatuscheckouttheachievementsbelowyoucanearnbybeingaappnamesuperdriver: '安全運転すると、ウォレットに キャッシュバックされます。 さらに、ポイントを獲得するとステータスが上がります。 ポイントが獲得できるボーナスを チェック！',
    txt_global_std_p2pviewyourbookings: '予約が表示されます。',
    txt_global_std_defineaneventthatinitiatesapushnotification: 'プッシュ通知を開始するイベントを定義する',
    txt_global_std_howdoiearnsavingsanswer: 'あなたが安全運転するとき、節約が生成されます。あなたが安全に運転するすべての旅行のために、あなたはキロあたりの節約を生み出します。安全運転をすればするほど、節約額も高くなります。保存は、旅行が終了した直後に表示され、 {appname}ウォレットに表示されます。',
    txt_global_std_adwancedadmin: '上級管理者',
    txt_global_std_p2pmessages: 'メッセージ',
    txt_global_std_templatestabtext: 'テンプレート',
    txt_global_std_takeapicture: '写真を撮る',
    txt_global_std_insuredvehicle: '被保険者の車両',
    txt_global_std_p2pitlookslikethisaccountisalreadyexist: 'パスワードが違います',
    txt_global_std_p2pfaq23q: '「わりかん」方法を変更できますか？',
    txt_global_std_insurancecard: '保険証',
    txt_global_std_whatisanactivationcode: 'アクティベーションコードとは何ですか？',
    txt_global_std_showreasons: '理由を表示',
    txt_global_std_TomczykChamp: '16 years DTM - 2011 DTM Champion',
    txt_global_std_adduserstodifferentgroups: 'ユーザーを別のグループに追加します。',
    txt_global_std_needstobeavalidphonenumbercanonlyincludenumbersand: '有効な電話番号である必要があります。数字と+のみを含めることができます',
    txt_global_std_typeofnotification: '通知の種類',
    txt_global_std_datafailedtosent: 'データ送信に失敗ました。Eメールアドレスを確認し、再送してください。',
    txt_global_std_daydreamingormindwandering: '白昼夢またはマインドワンダリング',
    txt_global_std_p2phasacceptedtherequest: 'がリクエストを承諾',
    txt_global_std_dashboard_groups: 'グループ',
    txt_global_std_carsharing: 'カーシェアリング',
    txt_global_std_estimatedpricebasedonyourperformance: '成績にもとづいた今月のお見積もり金額は',
    txt_global_std_french: 'フランス語',
    txt_global_std_speedincrease: 'スピードアップ',
    txt_global_std_howtogetstartedwithzipdriverriskindex: 'Zip-Driver リスク インデックスの使用方法',
    txt_global_std_dates: '日付',
    txt_global_std_p2pkeepthecommunicationgoingandchatwithyourfriendaboutyourbookings: 'コミュニケーションを続け、予約について友達とチャットしましょう。',
    txt_global_std_alreadyhaveanaccount: 'アカウントはお持ちですか？',
    txt_global_std_PolicySchedule7: 'Year of Registration',
    txt_global_std_loyalty_upsell_pitch_bulletpoint1: '安全で環境に優しい運転を奨励する',
    txt_global_std_yourveryownfiasdccompanychallenge: 'あなただけのFIASDCCompanyチャレンジ',
    txt_global_std_vs: '対',
    txt_global_std_overview2: '概要',
    txt_global_std_p2pselectedmember: '選択したメンバー',
    txt_global_std_p2premembertomarkthemaspaidonceyoureceivethepayment: 'あなたが支払いを受け取ったら、それらを支払い済みとしてマークすることを忘れないでください',
    txt_global_std_thisistheworldsfirstchallengerewardingsmartskillsatthewheelgetyourcompanyinvolvedinsmartdriving: 'これは、ハンドルを握るスマートスキルに報いる世界初の挑戦です。あなたの会社をスマートドライビングに参加させましょう。',
    txt_global_std_privateforthoseinmygroupwiththesameemaildomainname: 'プライベート（同じ電子メールドメイン名を持つ私のグループの人向け）',
    txt_global_std_noinsuranceregistered: '登録済みの保険はありません',
    txt_global_std_yourdroveatscoretypescoreorbetterawesomeyouhaveearnedpointspoints: '{score}以上で運転しました！驚くばかり！ {points}ポイント獲得しました！',
    txt_global_std_subscribetoupdates_2: 'アップデートを購読する',
    txt_global_std_areyousureyouwanttodisablethecommunication: '通信を無効にしてもよろしいですか?',
    txt_global_std_odometer: '走行距離計',
    txt_global_std_acceptbluetooth: 'Bluetoothを受け入れる',
    txt_global_std_yourinsurancehasbeenterminated: '保険が満期になりました',
    txt_global_std_tosignwiththisaccountwejustneedsomecomplementaryinfoaboutyoubeforeyoucanstart: 'このアカウントで署名するには、開始する前に、あなたに関する補足情報が必要です。',
    txt_global_std_MSIG_InsuranceLetter17: 'Excess:',
    txt_global_std_kintofaq4q: 'Kintoチャレンジにはどのように参加するのですか？',
    txt_global_std_MikkelsenMusic: 'I listen to all kind of music when I drive but I would say I mostly listen to pop and electronic dance music.',
    txt_global_std_createnewpushnotification: '新しいプッシュ通知を作成する',
    txt_global_std_notethiscodeexpiresin6minutesbutyoucangenerateanotherbylogginginagain: '注意：このコードは６分間有効です。コードが失効した場合は新たなコードを発行して下さい。',
    txt_global_std_filteroptionactive: 'アクティブ',
    txt_global_std_theconnectiontypeissaved: '接続タイプが保存されます。',
    txt_global_std_reasontoterminatetheinsurance: 'Reason to terminate the insurance',
    txt_global_std_50000pts: '>50000ポイント',
    txt_global_std_youraveragescoreis2: 'あなたの平均スコアは {points}',
    txt_global_std_yourcarmodelistoooldanddoesnthavebluetoothnodot: '車種が古すぎてBluetoothが搭載されていません',
    txt_global_std_bonuspaymentday: 'ボーナス日',
    txt_global_std_toregisteryourdrivingcorrectlyyoumustselectalways: '運転を正しく登録するには、«常に»を選択する必要があります',
    txt_global_std_getthecarinsuranceyoudeservethesaferyoudrivethemoreyousave: 'あなたが値する自動車保険を取得します。安全に運転すればするほど、節約できます。',
    txt_global_std_onboardingstatususeranalytics: 'オンボーディングステータス/ユーザー分析',
    txt_global_std_userguides: 'ユーザーガイド',
    txt_global_std_logout: 'ログアウト',
    txt_global_std_fromfromvalkmuptotovalkm: '{fromVal} kmから{toVal} kmまで。',
    txt_global_std_Fia_Receipt_Text10: 'Amount',
    txt_global_std_p2pexpenses: '費用の追加',
    txt_global_std_apitool: 'API ツール',
    txt_global_std_zipriskindexinsuranceratingcomponent: 'ジップ リスク インデックス - 保険格付けコンポーネント',
    txt_global_std_daydreamingisacommontypeofdriverdistractionyoumaythinkitsharmlessbutanythingthattakesyourmindoffthedrivingtaskincreasescrashriskchangingyourgazefrequentlycanhelpyoumaintainfocusontheenvironmentaroundyou: '白昼夢は、ドライバーの注意散漫の一般的なタイプです。無害だと思うかもしれませんが、運転から離れてしまうと、事故のリスクが高まります。視線を頻繁に変えることで、周囲の環境に集中し続けることができます。',
    txt_global_std_qualified: '認定済み',
    txt_global_std_VilliersMileage: 'I South Africa we have some beautiful passes and the Franschoek pass, close to my home is one of my favourites.',
    txt_global_std_topimprovercomingsoon: 'すぐに来るトップの改善者',
    txt_global_std_savetranslation: '翻訳を保存',
    txt_global_std_heresyour15discountcode: '15% 割引コードはこちら',
    txt_global_std_p2ppushtitlenewpaymentquestion: '支払いがありました',
    txt_global_std_edithistory: '履歴を編集',
    txt_global_std_numberbetweenxandyrule: '数値は {x} から {y} の間でなければなりません',
    txt_global_std_areyouthenextworldchampion: '次の世界チャンピオンはあなた？',
    txt_global_std_qhowdoesthescoringsystemwork: 'Q：リスクレベルはどのように評価していますか？',
    txt_global_std_Fia_Receipt_Text11: 'Total',
    txt_global_std_inorderfortheapptoworkproperlyweneedaccesstoyourphoneslocationaswellasexerciseandfitnessdata: 'アプリが正しく機能するためには、携帯電話の位置と運動およびフィットネスのデータにアクセスする必要があります',
    txt_global_std_drive: '設定',
    txt_global_std_technicaldrivingperformance: '運転成績',
    txt_global_std_p2pnext: '次へ',
    txt_global_std_syncwithgarminconnect: 'Garmin Connect™ と同期',
    txt_global_std_HSolbergBesttip: 'Have flow in the driving, braking less = less accelerations that use energy',
    txt_global_std_thiswindowwillclosein30sec: 'このウインドウは{closeSeconds} 秒後に閉じます',
    txt_global_std_policynumbercouldnotbeverified: 'ポリシー番号を確認できませんでした。',
    txt_global_std_p2pfuel: '燃料',
    txt_global_std_p2pand: 'と',
    txt_global_std_apikey1: 'API キー 1',
    txt_global_std_teamc02savings: '参加者のCO2削減率',
    txt_global_std_policynumber: '保険証券番号',
    txt_global_std_SulayemChamp: '14 times FIA Middle East Rally Champion',
    txt_global_std_PSolbergFacts: 'The first person to be crowned an FIA World Champion in two different motorsport disciplines, Petter Solberg followed up his 2003 World Rally Championship success by winning the FIA World Rallycross Championship for two consecutive seasons in 2014 and 2015. Having begun his motorsport career with rallycross outings in his native Norway, Solberg – known as ‘Hollywood’ to his many fans for his crowd-pleasing antics – made his World Rally Championship debut in 1998 before a switch to Subaru for 2000 preceded his first win, on the 2002 Rally GB.',
    txt_global_std_available: '利用可能',
    txt_global_std_swipetoopenthecontrolcenterandmakesureyouhavebluetoothturnedon: 'スワイプしてコントロールセンターを開き、Bluetoothがオンになっていることを確認します。',
    txt_global_std_nothankyou: 'いいえ、結構です',
    txt_global_std_theinsurancedeleted: '保険が削除されました！',
    txt_global_std_thankyouforallowingustoaccessyourbluetooth: 'Bluetooth設定へのアクセスを許可していただきありがとうございます。プライバシー保護のため、この情報を第三者に共有することはありません。',
    txt_global_std_challange: '友だちと対戦',
    txt_global_std_personaldetails2: '個人の情報',
    txt_global_std_areyousurethatyouwanttocompletelyremoveyouraccount: 'アカウント、ドライブレコード、保険情報を完全に削除しますか？',
    txt_global_std_getstarsaftereachtripforhowgoodsafeandecofriendlyyourdrivewas: '運転するたびに、運転の安全性に応じて0〜100のスコアが得られます。ここであなたの合計スコアを見つけることができます。',
    txt_global_std_p2pgroupname: 'グループ名',
    txt_global_std_youonlyhave: 'あなただけが持っています',
    txt_global_std_uploadintervalsecondssec: '{uploadIntervalSeconds} 秒',
    txt_global_std_namesmayonlyincludeletters: '名前には文字のみを含めることができます',
    txt_global_std_HSolbergFacts: 'Solberg is the elder brother of the 2003 FIA World Rally Champion Petter Solberg and through his Swedish spouse Maud the stepfather of Swedish rally driver Pontus Tidemand. One of the sport’s most experienced drivers, when he’s in the groove he has the power to embarrass the youngsters. ',
    txt_global_std_qdoesagentacceptdriversthatarenotlicensedincountry: 'Q：{agent}は{country}でライセンスされていないドライバーを受け入れますか？',
    txt_global_std_p2ppushbodybookingcomingup: '予約開始は{start}です',
    txt_global_std_nameemailhasinvitedyoutojointheirgrouptostartdrivingsmarter: '{name}（{email}）は、よりスマートな運転を開始するために{group}に参加するようにあなたを招待しました。',
    txt_global_std_opensettingsinthephonelocationturnon: 'スマホの「設定」を開きます。「プライバシー」から「位置情報サービス」を開き、Enerfyに対してオンにしてください。',
    txt_global_std_timeinretardation: '減速の時間',
    txt_global_std_loadingitems: 'アイテムの読み込み',
    txt_global_std_interests: '利息',
    txt_global_std_viamail: '郵送',
    txt_global_std_TaxInvoice10: 'ALL INDIVIDUAL POLICYHOLDERS ARE SUBJECT TO A PAYMENT BEFORE COVER WARRANTY',
    txt_global_std_MSIG_Onboarding2: 'Thank you for choosing MS First Capital Insurance Ltd. You have signed the insurance for the car',
    txt_global_std_teamaveragescorebig: 'チーム平均スコア\n',
    txt_global_std_notspecified: '登録プレートがありません',
    txt_global_std_apitool_upsell_pitch_bulletpoint3: 'ビジネス効率を高めるための簡単で手間のかからないデータ統合',
    txt_global_std_p2pfinishquestion: '終了しますか？',
    txt_global_std_p2pwrongemailorpassword: 'メールアドレスまたはパスワードが間違っています！',
    txt_global_std_ayesagentacceptsdriverswhoarelicensedinothercountries: 'A：はい、{agent}は他の国で認可されたドライバーを受け入れます。',
    txt_global_std_enterinvitationcode: '招待コードを入力',
    txt_global_std_thiswonttakemorethanaminuteyoullbeintheworkspaceonceitsready: 'これには1分もかかりません。準備ができたら、ワークスペースに移動します。',
    txt_global_std_f_lanzbesttip: '常に先を見越して、潜在的な危険がないか地平線をスキャンしてください。同時に景色をお楽しみください。',
    txt_global_std_exportdata: 'データをエクスポート',
    txt_global_std_savingsisgeneratedifriskislowerthanclaims: '保険請求予測よりリスクが低い場合は節約できます。',
    txt_global_std_qualifiedto2021finals: '2021年の決勝戦に出場',
    txt_global_std_documentthedamage: '損傷を文書化する',
    txt_global_std_riskportfoliotracker: 'リスクポートフォリオトラッカー',
    'txt_global_std_Al-KhelaifiFacts': 'Abdulla participates in most motorsport events in Qatar and has several championships in different types of racing like National Drag, Qatar Mile, National Sprint, and QTCC.',
    txt_global_std_from: 'から',
    txt_global_std_callroadsideassistance: 'ロードサイドアシスタンスに電話する',
    txt_global_std_p2ppushtitlehasacceptedthebooking: '予約が承諾されました',
    txt_global_std_loyalty: 'モビリティポイントプログラム',
    txt_global_std_selectcountry: '国を選択',
    txt_global_std_HansenBesttip: 'To me, there are two crucial key factors for smart driving. The first: look and check what’s happening around you. The second: learn and get to know your car, and how it’s reacting to your inputs of the throttle, break and engine breaking. Combining these two crucial factors will take you really far in this competition.',
    txt_global_std_therulesforthecompanychallengeareasfollowingbrtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsbrdriveminimum1kmduringaheatinordertoqualifyforthechallengebrthesamegeographicalroutewillbescoredamaximumof10timesinoneday1: '会社のチャレンジのルールは次のとおりです。\n' +
      '<br>•個々のドライバーのスコアは、上位10％の旅行に基づいて平均して計算されます。\n' +
      '<br>•チャレンジの資格を得るために、ヒート中に最低1kmをドライブします。\n' +
      '<br>•同じ地理的ルートは、1日に最大10回スコアリングされます。',
    txt_global_std_addinjuredperson: 'ケガ人を追加する',
    txt_global_std_okaywewillendthetour: 'さて、ツアーを終了します。',
    txt_global_std_p2pexpensessummary: '経費概要',
    txt_global_std_PolicySchedule28: 'SGD1,000 ALL CLAIMS IS IMPOSED ON DRIVERS WHO HAVE DEACTIVED THE DONGLE FOR AT LEAST 14 DAYS IN A CALENDAR YEAR.',
    txt_global_std_insurancecost: '保険料',
    txt_global_std_couldnotretrievedrivedata: 'トリップのデータを読み込めませんでした',
    txt_global_std_loyaltypointsgainedfor: '予約で獲得したポイント',
    txt_global_std_youversususername: 'あなた対 {user}',
    txt_global_std_splittrips: 'トリップを分割する',
    txt_global_std_topecodrivers: 'トップエコドライバー',
    txt_global_std_speedchangeanalysis: '速度変化の分析',
    txt_global_std_ongoinggames: '開催中のイベント',
    txt_global_std_totalpointsthisweek: '今週の合計ポイント：',
    txt_global_std_statusnondriving: '運転中ではない',
    txt_global_std_unabletosavenote: 'メモを保存できません',
    txt_global_std_schedule: 'スケジュール',
    txt_global_std_deleteapikey: 'API キーの削除',
    txt_global_std_leaderboard: '順位',
    txt_global_std_cherry: 'サクランボ:',
    txt_global_std_p2pendbeforestart: '終了時刻が開始時刻より前でないことを確認してください。',
    txt_global_std_verifyyourphonenumber: '電話番号を確認する',
    txt_global_std_monthshorthand: '月',
    txt_global_std_youarenowconnectedandready: '接続されました。初ドライブをお楽しみください！',
    txt_global_std_youearnedthisbadgextimes: '(累計獲得バッジ数：{x}個)',
    txt_global_std_p2pconfirmprice: '予約を確認する',
    txt_global_std_Fia_Receipt_Text14: 'Shipping and VAT (6%) is included in the price.',
    txt_global_std_p2pincludesafedriverbonus: 'セーフドライバーポイントを使う',
    txt_global_std_isthisanelectriccar: 'EVですか？',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint2: 'リスクの高いドライバーとリスクの低いドライバーを即座に特定',
    txt_global_std_MSIG_Onboarding5: 'Our customers mean everything to us and we are glad to have you on board!',
    txt_global_std_signinwithfacebook: 'Sign in with Facebook',
    txt_global_std_zipdriver_upsell: 'Zip-Driver – 運転場所に基づいて正確なリスク レベルを決定します。',
    txt_global_std_month: '月',
    txt_global_std_uploadcustombadgeicon: 'カスタムバッジアイコンをアップロード',
    txt_global_std_PolicySchedule30: 'The windscreen cover under Endorsement No. 89C is unlimited.',
    txt_global_std_removeaccount: 'アカウントを削除\n',
    txt_global_std_verifyphonenumber: '電話番号の認証に次の認証コードをご使用ください',
    txt_global_std_deletegroup2: 'グループを削除',
    txt_global_std_p2prepair: '修理',
    txt_global_std_repetition: '繰り返し',
    txt_global_std_requestinsuranceactivationcodebysms: 'やあ！ Sidekickアカウントの新しいアクティベーションコードをリクエストしました： {activationcode} Greetings {appname} gang in {companyshort}。',
    txt_global_std_SignupFia_Text6: 'お申し込み受け付けはまもなく。',
    txt_global_std_planningtips: '前方を見ると（目の前だけでなく）、通常は運転がスムーズになり、時間内に障害物を発見する可能性が高くなります。\n' +
      '\n' +
      'あなたとあなたの乗客のために楽しい乗り心地を保証します。',
    txt_global_std_riskindex_upsell_pitch_bulletpoint1: '世界中のドライバーのリスク傾向に関する独自の洞察',
    txt_global_std_endaddress: '終了地点の住所',
    txt_global_std_daysremaininginthisperiod: '本期間中は、残り{days} 日',
    txt_global_std_p2pcoderesent: 'コードを再送しました',
    txt_global_std_didafriendridewithyou: '友だちとライドシェアしましたか？',
    txt_global_std_TomczykMileage: 'The route to the family vacation is above all one of my favorites mileage to drive. ',
    txt_global_std_activationcode: 'アクティベーションコード',
    txt_global_std_recruitfriendsandgetfreeheats: '友だちを募集して無料の試合をゲット！',
    txt_global_std_areyoureadytodrive: '運転する準備はできましたか？',
    txt_global_std_ringsosphoneno: 'リングSOS：{phoneNo}',
    txt_global_std_insuranceactivationkeyalreadycreated: '保険のアクティベーションキーはすでに発行されています',
    txt_global_std_p2pconnecttoyourfriendscarwhenyourbookingstartsyouwillgetfeedbackandinsightsonyourdriving: '予約開始時に友達の車に接続します。あなたはあなたの運転に関するフィードバックと洞察を得るでしょう。',
    txt_global_std_trafficdelaysdeadlinesandrunninglatetheycanallcausestresswhichisnegativeforyourhealthandsafetyallowingextratimeplanningyourrouteandcheckingtravelupdatescanhelptoreducestressandimproveyourfocusnew: 'トラフィックの遅延、締め切り、および遅延;それらはすべてストレスを引き起こす可能性があり、健康と安全に悪影響を及ぼします。余分な時間を確保し、ルートを計画し、旅行の最新情報をチェックすることで、ストレスを軽減し、集中力を高めることができます.',
    txt_global_std_typicallyyouseeadigitalscreendisplayingtworowsofnumbersoneforthecarstotaldistancetravelledandoneforthedailydistances: '通常、デジタル画面には2行の数字が表示されます。1つは車の総走行距離、もう1つは1日の距離です。',
    txt_global_std_TaxInvoice7: 'ALL CORPORATE POLICYHOLDERS ARE SUBJECT TO A PREMIUM PAYMENT WARRANTY For Periods of Insurance of more than 60 Days',
    txt_global_std_geyouwillreceiveanemailwithyourpersonaldata: '個人データが記載されたEメールが送信されます',
    txt_global_std_codehasbeensentto: 'コードが送信されました',
    txt_global_std_theenerfyglobalteam: 'EnerfyGlobalチーム',
    txt_global_std_p2pstartadrivetoaccessinsightsandstatisticsaboutyourdriving: 'ドライブデータを蓄積をすると、あなたの運転に関する分析と統計データを見ることができます。',
    txt_global_std_cimage17d: '高速では計画的かつアクティブに。長距離ドライブでの改善点に気をつけましょう。',
    txt_global_std_removemissingtrip: 'トリップを削除する',
    txt_global_std_howdoyoumeasuremydrivinglexusanswer: 'レクサスアプリは、人工知能を使用して運転を測定します。アプリはクラウドに接続し、運転の1秒ごとを分析し、「プロファイル」に変換します。 AIはあなたのプロファイルを7億5,000万を超える他のユニークな運転プロファイルと比較しています。 AIには、事故に巻き込まれる可能性のある運転パターンを探すための16年以上の経験があります。幸いなことに、ほとんどすべての旅行の後、運転手と乗客は愛する人のもとに安全に帰ります。',
    txt_global_std_notsignedupforacovercubeinsuranceyet: 'Covercube保険にまだ加入していませんか？',
    txt_global_std_iwilldoitlater: '後で',
    txt_global_std_sothedevicecanconnectyourphonewithyourcar: 'このデバイスでスマホと車両が接続されます。',
    txt_global_std_WelcomeLetter_Text2: '本メールには、保険証書や販売前情報をはじめとする保険資料を記載しています。保険の条件については {appName} アプリ、または www.enerfy.se. よりご確認ください。添付資料が間違っている場合は、再送いたしますのでお問い合わせください。',
    txt_global_std_markermovediscount: 'マーカーを移動して、レベルが割引にどのように影響するかを確認します',
    txt_global_std_achachievements: '実績',
    txt_global_std_skip: 'スキップ',
    txt_global_std_wetakeeverystepandmeasuretominimiseourco2footprintenergyconsumptionandroadaccidentsbyinspiringourdriverstosmarterdrivingandtrackingourperformance: '私たちは、ドライバーにスマートな運転とパフォーマンスの追跡を促すことで、CO2フットプリント、エネルギー消費、交通事故を最小限に抑えるためのあらゆる措置を講じています。',
    txt_global_std_p2pfaq11a: '1.\t「メニュー」から「メンバー」を選択します。\n' +
      '2.\t「メンバーを招待する」を選択します。\n' +
      '3.\tアプリのリンクを招待したい相手に送ってください。招待を受け取った人が「わりかんKINTO」をダウンロードし、アカウントを作成すると、自動的にメンバーリストに追加されます。\n',
    txt_global_std_passwordsdonotmatch: 'パスワードが一致していません',
    txt_global_std_sendthemalink: 'アプリを使用できるようにリンクを送信します。',
    txt_global_std_howdoirenewmyinsurance: '保険を更新するにはどうすればよいですか？',
    txt_global_std_35000pts2: '&#60;35000ポイント',
    txt_global_std_p2pselectcar: 'クルマを選ぶ',
    txt_global_std_notconnectedtoenerfycloud: '{appName} クラウドに未接続',
    txt_global_std_dashboard_challenges: '課題',
    txt_global_std_exportcsv: 'CSVをエクスポート',
    txt_global_std_downloadingdrivers: 'ドライバーをダウンロード中',
    txt_global_std_invalidemail: '無効なEメール',
    txt_global_std_succesfullyactivatedinsurance: '保険が有効になりました',
    txt_global_std_hello123: 'こんにちは',
    txt_global_std_usernotfoundinteam: 'チームにユーザーが見つかりません。',
    txt_global_std_notifications: '通知',
    txt_global_std_summaryofdistnacedivenperslectedperiod: '選択した期間ごとの距離ダイビングの概要。',
    txt_global_std_PolicySchedule15: 'The policy is subject to endorsements/clauses',
    txt_global_std_contactsupport: 'サポート問い合わせ先',
    txt_global_std_iconheader: 'ロック解除済みアイコン',
    txt_global_std_userlockedout: '現在、ログイン試行の失敗が多すぎるため、ユーザーはロックアウトされています。',
    txt_global_std_kintosmsandemailtext: 'もっと安全に運転を始めましょう！以下のリンクに従って、Kintoで車で10 kmの距離を走行してください。',
    txt_global_std_since: 'より',
    txt_global_std_unrestrictedinvitelink: '無制限の招待リンク',
    txt_global_std_alertbreaktimetext: '休憩アラーム',
    txt_global_std_areyoudrivinganelectriccar: '電気自動車を運転しますか？',
    txt_global_std_mycardoesnthavebluetoothanswer: '問題ない！ BT-OBDドングルをリクエストしてください。ドングルをケーブルに接続し、車の12v充電器に接続するだけです。次に、通常どおり「車に接続する」の手順に従い、Bluetooth接続中に「Enerfy OBD」という名前を選択します。',
    txt_global_std_firsttimeinapp: 'First time in app',
    txt_global_std_mutualfriends: '共通の友だち',
    txt_global_std_p2pdeletemessage: 'メッセージを削除する',
    txt_global_std_improvetext: 'ボーナスが多かったトリップ',
    txt_global_std_marklundDescription: '2011年の国際大会出場を機に、ポルシェカレラカップスカンジナビア 、 FIA世界ラリー選手権大会、European Rallycross Championship、X-Gamesなどに出場しています。',
    txt_global_std_changeyournumber: 'C電話番号を変更する',
    txt_global_std_error: 'エラー',
    txt_global_std_drivingtechnique: '運転技術',
    txt_global_std_deleteachievementpopuptext: 'あなたの実績は完全に削除されます。ユーザーの進行状況は失われます。すでにアチーブメントを受け取っている人は、引き続きそれを見ることができます。',
    txt_global_std_challengeinvitesent: '対戦リクエストを送信しました',
    txt_global_std_bycheckingthischeckboxandlogginginyoureacceptingour: 'このチェックボックスをオンにしてログインすると、',
    txt_global_std_startdriving: 'ドライブを開始する',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithagentwithalapseincoverage: '有効期限から{日}日以内に支払いを行い、{エージェント}で保険契約を継続します。',
    txt_global_std_thechaser: 'チェイサー',
    txt_global_std_pickyourcoverimage: 'カバー画像を選択してください',
    txt_global_std_tobeabletosendmessagesweneedtoaddyourfriendsletsinvitethem: 'メッセージを送るには、\n友だちを登録する必要があります。\n招待しましょう！\n',
    txt_global_std_pleasechoosewhichtypeofpictures: 'どちらの写真か選択してください',
    txt_global_std_demotext5: '節約はスコアから！ボーナスはこちらから。',
    txt_global_std_p2pverifyphonenumber: '電話番号認証',
    txt_global_std_yourlinkshouldbeavailableinyourinboxin5minutespleaseremembertocheckyourjunk: 'リンクは 5 分以内に受信トレイに表示されます。ジャンクチェックを忘れずに。',
    txt_global_std_startyourcar: '車を始動します。',
    txt_global_std_manydriversrelytooheavilyoncruisecontrolitiscomfortableyesbutlethalforfueleconomyinmanycasesusingcruisecontrolontheslightestuphillordownhillwillcausetheenginetofighthardtosatisfyyourwishofmaintainingtheexactspeedyousetinsteadmanagingyourspeedwithyourfootandaimingforasmoothridewillhelpyousaveahugeamountonfuel: '多くのドライバーは、クルーズ コントロールに過度に依存しています。確かに快適ですが、多くの場合、燃費は致命的です。わずかな上り坂や下り坂でクルーズ コントロールを使用すると、設定した正確な速度を維持したいという欲求を満たすために、エンジンが激しく戦うことになります。代わりに、足で速度を管理し、スムーズな乗り心地を目指すことで、燃料を大幅に節約できます。',
    txt_global_std_bethefirsttoreceivenews: '最新ニュースをいち早く',
    txt_global_std_p2pitstimetosplityourcostformonthofph: '{month}分の費用を分割してください。',
    txt_global_std_enterzipcode: '郵便番号を入力',
    txt_global_std_top3bestdrives: 'トップ3の最高のドライブ',
    txt_global_std_KristofferssonMusic: 'I like to listen to Godsmack.',
    txt_global_std_contactinfogreaterthaneuformoreinformationonhowtoactivatethismodule: 'このモジュールを有効にする方法の詳細については、info@greaterthan.eu にお問い合わせください。',
    txt_global_std_startguidetip3: '画面下部の中央にあるPlayボタンをタップしてください',
    txt_global_std_carsagreementsinsurance: '車と契約/保険',
    txt_global_std_enteravalidchassisnumber: '有効なシャーシ番号を入力してください。',
    txt_global_std_p2pyoudonthaveanycostsplitsorinvoicesyet: '費用の分割や請求書はまだありません。',
    txt_global_std_p2pacceptmarketingandprivacy: 'プライバシーポリシーに同意し、ニュースレターに登録します。',
    txt_global_std_p2pselectfriendsoragroup: '友だち、またはグループを選択する。',
    txt_global_std_cimage4h: '頻繁な停止は予測しましょう',
    txt_global_std_notconnectednoaddress: '未接続 - 住所がありません',
    txt_global_std_savingsisbasedonrisklevelanddistance: '節約はリスクレベルと距離に基づいています。',
    txt_global_std_searchfriends: '友だちを探す',
    txt_global_std_p2pnofriendsfoundpleaseinvitefriend: '友だちが登録されていません。招待してください。',
    txt_global_std_insureddistance_used_100__1: 'Du har nu använt din körsträcka. För att få full kompensation är det viktigt att du inte överskrider antalet kms som valts. Vänligen kontakta eller justera körsträckan här.',
    txt_global_std_p2psendrequest: 'リクエストを送信する',
    txt_global_std_usersupportcenter: 'ユーザーサポートセンター',
    txt_global_std_drivingstartandstopsettings: '運転開始と停止の設定',
    txt_global_std_aaninsuranceadjusterwillcontactyouwithin24hoursofyourclaimbeingsubmittedtheentireclaimsprocesswilldependonthecomplexityoftheclaim: 'A：保険査定人は、請求が提出されてから24時間以内に連絡します。クレームプロセス全体は、クレームの複雑さに依存します。',
    txt_global_std_signedinas: 'としてサインイン',
    txt_global_std_pleaseenablebluetoothlocationservicesandmotionfitness: 'Bluetooth、位置情報サービス、モーション＆フィットネスを有効にしてください。',
    txt_global_std_createanaccounttostartyour30dayfreetrial: 'アカウントを作成して、30日間の無料トライアルを開始します。',
    txt_global_std_members3: 'メンバー',
    txt_global_std_yourchassisshouldcontainacombinationofnumbersandletters: 'シャーシには数字と文字の組み合わせを含める必要があります。',
    txt_global_std_directtoapp: 'Direct to app',
    txt_global_std_cimage10d: 'ハイギアを心がけて回転数を低く維持しましょう。',
    txt_global_std_TidemandInterests: 'Water sports, seeing friends and Motorsports',
    txt_global_std_firsttimeusingtheserviceregisterfromsignuplink: '初めてのご利用ですか？{signUpLinkJapanese}',
    txt_global_std_youhavedeniedthelocationpermission: '位置情報を許可していません',
    txt_global_std_somethingwhentwrongtryagain: '失敗しました、再度お試しください。',
    txt_global_std_yourreporthasbeensentsuccessfully: 'レポートを送信できました！',
    txt_global_std_fromtheleftsidetoolbarhoverorclickontheiconstonavigatethroughtheportaltoitsdifferentcomponentsandpages: '左側のツールバーから、アイコンにカーソルを合わせるかクリックして、ポータル内を移動してさまざまなコンポーネントやページに移動します。',
    txt_global_std_october: '10月',
    'txt_global_std_drivedetailshavebeensentto{email}': 'トリップの詳細を {email}へ送信',
    txt_global_std_payouts: '支払う',
    txt_global_std_choosesetup: '対戦期間の選択​',
    txt_global_std_picturereceived: '写真を受け取りました。',
    txt_global_std_oliversolbergDescription: 'Son of Petter Solberg. He drives his father’s car for the Nordic team.',
    'txt_global_std_H.SolbergDescription': 'Solberg är den äldre bror till 2003 FIA World Rally Champion Petter Solberg och genom sin svenska maka Maud, styvfar till den svenska rallydrivaren Pontus Tidemand. En av sportens mest erfarna förare, när han är i spåret, har han makt att skämma bort ungdomarna.',
    txt_global_std_cimage32h: '加速にはコストがかかります',
    txt_global_std_tryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagain: '•車を取り外してから再度追加して、車と電話の接続をリセットしてみてください。',
    txt_global_std_whendrivingonmotorwaystoomanydriverspumptheacceleratorpedalupanddowninaneverendingpattern100kmhto110kmhthen110kmhto100kmhandsoonthistypeofdrivingusesunnecessaryfuelkeepingasafesuitablefollowingdistancecanhelpavoidthetemptationtovaryspeedunnecessarily: '高速道路を運転しているとき、あまりにも多くのドライバーがアクセル ペダルを終わりのないパターンで上げたり下げたりしています。このタイプの運転は、不必要な燃料を使用します。安全で適切な車間距離を保つことで、不必要に速度を変えようとする誘惑を避けることができます。',
    txt_global_std_reward: 'リワード',
    txt_global_std_changeyourcontactdetails: '連絡先を変更する',
    txt_global_std_cimage3d: 'ハプニングを予測しましょう。',
    txt_global_std_thepumper: 'ポンパー',
    txt_global_std_hereisthetestkey: 'これがテストキーです',
    txt_global_std_headers: 'ヘッダー',
    txt_global_std_3selectyourcarinthelist: '3．  デバイスを下の一覧から選択してください',
    txt_global_std_textaboutwhentranslationswillbevisibleinappetc: 'アプリなどで翻訳がいつ表示されるかについてのテキスト。',
    txt_global_std_useruploadtemplate: 'ユーザーアップロードテンプレート',
    txt_global_std_joinwithactivationcode: 'アクティベーションコードで参加',
    txt_global_std_communityteams: 'コミュニティチーム',
    txt_global_std_resendemail: 'メールを再送',
    txt_global_std_headerpassword: 'パスワード',
    txt_global_std_f_lanzwhyjoin: '日々の路上での課題を学びながら、同時に楽しみましょう。\n楽しみながら学ぶことは常により良い結果に等しい',
    txt_global_std_OgierFacts: "He is the current holder of the World Rally Drivers' Championship, having won the title six times, in 2013, 2014, 2015, 2016, 2017 and 2018. With 44 victories in the World Rally Championship and six consecutive WRC titles, he is the second most successful WRC driver, after former Citroën WRC teammate Sébastien Loeb (nine titles).",
    txt_global_Fia_Receipt_Text1: '購入完了！',
    txt_global_std_teamranking: 'チームランキング',
    txt_global_std_p2pawaitingapproval: '承認待ち',
    txt_global_std_MSIG_InsuranceLetter35: 'MS First Capital Insurance Ltd 6 Raffles Quay #21-00 Singapore 048580 Tel: (65) 6222 2311 Fax: (65) 6222 3547 www.msfirstcapital.com.sg',
    txt_global_std_exclude: '除外する',
    txt_global_std_sendinvite: '招待を送る',
    txt_global_std_milesmi: 'マイル（mi）',
    txt_global_std_finalistsheat1: 'ファイナリスト-ヒート1',
    txt_global_std_whenwouldyouliketoterminateyourinsurance2: '保険の満了日はいつにしますか?',
    txt_global_std_fixitnow: '設定する',
    txt_global_std_p2ppushbodyaddedexpences: '{name}さんが{monthname}分に費用を追加しました',
    txt_global_std_theloyaltysystem: 'ロイヤルティシステム',
    txt_global_std_entertheemailthatyouusedwhenyouregisteredyourinsurance: '保険登録時に使用したメールアドレスを入力してください',
    txt_global_std_choosealwaysorallowwhileusing: '「常に」または「使用中は許可」を選択します',
    txt_global_std_gpsactive: 'GPS有効',
    txt_global_std_discardchanges: '変更を破棄',
    txt_global_std_theuseriscurrentlylockedoutfortoomanyfailedattempts: 'ユーザーは現在、試行の失敗が多すぎるためロックアウトされています',
    txt_global_std_levelprogress: 'レベルの進捗状況',
    txt_global_std_MSIG_InsuranceLetter23: '* Provided that the person driving is permitted in accordance with the licensing or other laws or regulations to drive the Motor Vehicle or has been so permitted and is not disqualified by order of a Court of Law or by reason of any enactment or regulation in that behalf from driving the Motor Vehicle.',
    txt_global_std_accidentguide: '事故に遭われた場合',
    txt_global_std_p2plendyourkintotofamilyandfriends: '家族や友人に車を貸す',
    txt_global_std_BeforeFirstBill_Text1Enerfy: '保険料はクレジットカードからの引き落としになります。初回ご請求時に寄せられるよくあるご質問をまとめました',
    txt_global_std_yourdrivewillendin: 'ドライブは自動的に終了します',
    txt_global_std_howdoyoumeasuremydrivinganswer: '{appname}は人工知能を使用して運転を測定します。アプリはクラウドに接続され、<br>毎秒の運転を分析し、「プロファイル」への変換を行います。変換されたプロファイルは、7億5,000万を超える、ユニークな\n' +
      '運転プロファイルと比較が行われます。<br>更にAIでは16年以上経験の中から事故に<br>巻き込まれる可能性のあるパターンを探します。これまでの大半の事故は重大では<br>なく、運転手と乗客は無事に帰宅することができています。',
    txt_global_std_p2ptemppassword: '仮のパスワード',
    txt_global_std_roadsideassistance: '道端での援助',
    txt_global_std_signupforinsurance: 'Sign up for insurance',
    txt_global_std_100pointtripachievementlocked: '{scoretype} {score}以上でドライブを終了し、この実績をアンロックして100ポイントを獲得してください。',
    txt_global_std_keepingyourmindandeyesonwhatshappeningaroundyouisvitalforyoursafetyandthesafetyofothersphoneactivityandotherdistractionsaffectyourfocusandincreasecrashrisknew: '意図した速度を維持することは、燃費にとって最も重要です。頭のふらつきや集中力の欠如は、速度の上下変動を引き起こし、不必要な加速を引き起こし、燃料消費を増加させます。',
    txt_global_std_baseurl: 'ベース URL',
    txt_global_std_SignupFia_Text1: 'コンペに参加!',
    txt_global_std_leavechallenge: 'チャレンジをやめる',
    txt_global_std_accountcreatedlogin: 'Your account has been created, you can now login!',
    txt_global_std_ihavesoldmycar2: '車を売りました',
    txt_global_std_hi: 'やあ！',
    txt_global_std_photo: 'Photo',
    txt_global_std_TaxInvoice9: 'Insurer is entitled to a pro-rata premium payment for the 60-day cover (subject to minimum S$25).',
    txt_global_std_obdconnected: 'OBD接続',
    txt_global_std_nodatatoshow_loyalty: '表示するデータがありません',
    txt_global_std_ifyoudidntmakethisrequestyoucansafelyignorethisemail: 'このリクエストを行っていない場合は、このメールを無視しても問題ありません。',
    txt_global_std_activationcodeemail1: 'あなたのアクティベーションコード',
    txt_global_std_p2ppushtitlechangeperiodfromto: '予約日時が変更されました',
    txt_global_std_minutesshort: '分',
    txt_global_std_youhavenowreceivedamountyenatcompany: '{company}で使用できる、{amount}円分の\nギフト券に交換されました。',
    txt_global_std_chooseafile: 'ファイルを選択',
    txt_global_std_alertlocationnotalways: 'データを失うことなくドライブを登録するには、ロケーションサービスのアクセス許可を[常に]に設定します。',
    txt_global_std_nodriversonleaderboard: '現在、リーダーボードにドライバーはありません',
    txt_global_std_nextstep: '次へ',
    txt_global_std_registrationnumberalreadyexists: '登録番号はすでに存在します',
    txt_global_std_typeofmessage: 'メッセージの種類',
    txt_global_std_birthdate: '誕生日',
    txt_global_std_badgeisworthxpointsunlocked: '＋{points}ポイント',
    txt_global_std_realtimefeedbackonthego: '外出先でのリアルタイムのフィードバック。',
    txt_global_std_youarereadytogodriveandearnyourfirstbonus: '準備完了！走って最初のボーナスを獲得しましょう',
    txt_global_std_Fia_Receipt_Text13: 'info@fiasmartdrivingchallenge.com',
    txt_global_std_inactive: '無効',
    txt_global_std_retardation: '減速',
    txt_global_std_totalminutes: '合計時間',
    txt_global_std_yesihaveredeemedmypoints: 'はい、ポイントを交換しました！',
    txt_global_std_jointeam: 'チームに参加',
    txt_global_std_p2pblockeddate: 'ブロックを完了しました。',
    txt_global_std_getstartedmanual: '入門マニュアル',
    txt_global_std_bringoutyourcompetitivesideandreceivethehighestscoreoftheevent: 'あなたの競争力を引き出し、イベントの最高スコアを受け取ります。',
    txt_global_std_timeinconstantspeed: '一定速度で走行した時間',
    txt_global_std_claimsdepartment: '請求課',
    txt_global_std_p2pwrongcodetryagain: 'コードが違います。\nもう一度お試しください。',
    txt_global_all_wednesday: '水曜日,',
    txt_global_std_MSIG_InsuranceLetter14: 'Financial Institution',
    txt_global_std_doyouacceptthechallenge: 'この対戦に参加しますか？\n',
    txt_global_std_off: 'オフ',
    txt_global_std_oversigtovernivauetpdintur: 'nivauetpådinturをオーバーシグトします。',
    txt_global_std_allowallthetime: '常に許可する',
    txt_global_std_youcandobetter: 'もうちょっとです…！',
    txt_global_std_yourdrivewasnotregisteredifyouhadyourenerfypluginconnectedwhiledrivingthecarbutdidnothavetheappbluetoothorgpslocationservicesactivesothatitregistersdrivingdatacompletedrunswillbechargedafterwardsinpremiumlevel10to15: 'ドライブが登録されていません。車の運転中にEnerfyプラグインを接続したが、アプリ、Bluetooth、またはGPS /位置情報サービスをアクティブにして運転データを登録しなかった場合、完了した実行は後でプレミアムレベル10〜15で課金されます。',
    txt_global_std_atrustedscoreusedbyfia: 'FIAが使用する信頼できるスコア',
    txt_global_std_thedriverwiththebestscorewinstheround: '最高スコアのドライバーが優勝！',
    txt_global_std_usertermsandconditions: 'ユーザー利用規約',
    txt_global_std_verifyemail: 'Eメールを確認します',
    txt_global_std_authentication: '認証',
    txt_global_std_tomasengechamp: 'F1とインディカーの最初のチェコ人ドライバー、チェコサーキットチャンピオン、ドイツのFFordチャンピオン',
    txt_global_std_carrorewardworkshops: 'キャロ報酬ワークショップ',
    txt_global_std_welcometo2: 'ようこそ',
    txt_global_std_cardnummer: 'カード番号',
    txt_global_std_henningsolbergDescription: 'Solberg は2003年FIA世界ラリー選手権チャンピオン Petter Solbergの兄で、 現在の配偶者Maudの息子Pontus Tidemandもラリー選手 です。最も経験豊かなドライバーの一人で、調子が良いときは若手顔負けの選手です。',
    txt_global_std_cimage10h: 'ハイギア、低回転数を心がけましょう',
    txt_global_std_signedinasv2: 'としてサインイン中',
    txt_global_std_insureddistance_used_80__1: 'Du har nu använt 80% av din årliga valda körsträcka. Vill du justera körsträckan?',
    txt_global_std_p2pbookingmodifiedsuccessfully: '予約が正常に変更されました',
    txt_global_std_ConfirmFia_Text4: '時間',
    txt_global_std_p2pfaqandsupport: 'FAQとサポート',
    txt_global_std_congratulationsyoursetupiscompleteandyouarereadytogo: 'おめでとう！セットアップが完了し、準備が整いました。',
    txt_global_std_nottowed: 'けん引されていない',
    txt_global_std_zipdriver: 'ジップドライバー',
    txt_global_std_userstate: 'ユーザー状態',
    txt_global_std_p2pyoucaninvitexfriendspercar: '1つのクルマにつき{number}名まで追加いただけます',
    txt_global_std_online: 'オンライン',
    txt_global_std_obdreaderonitsway: '途中のOBDリーダー！',
    txt_global_std_cimage19h: '燃料を計画的に',
    txt_global_std_p2pchooseafriendorinviteanewfriend: '友だちを選ぶ または 新しい友だちを招待する',
    txt_global_std_notificationsisagreatwaytostayontopallowthemtoletusguideyoutowardsbecomeanevensmarterdriverandtakeyourdrivingtothenextlevel: '通知は、トップを維持するための優れた方法です。彼らが私たちにあなたをさらに賢いドライバーに導き、あなたの運転を次のレベルに引き上げさせるようにさせてください。',
    txt_global_std_mpltipafpp: 'このドライバーは熟練したドライバーです。しかし、走行中に短時間ですが、模範的な運転に集中でき\n' +
      'なくなることがあるようです。注意散漫になったり、ストレスが溜まるなどして、安全に対する意識が\n' +
      '低くなってしまうことがあります。このような場合は、ドライバーに声をかけ、配慮を求めることで解\n' +
      '決することがあります。',
    txt_global_std_translationtool_upsell_pitch_bulletpoint1: 'アプリをすばやく多言語化',
    txt_global_std_itisa12digitcodethatyoureceivefromtheeventorchallengeorganizer: 'これは、イベントまたはチャレンジの主催者から受け取る12桁のコードです。',
    txt_global_std_searchandmanageagreements: '契約書の検索と管理',
    txt_global_std_afollowthesestepstoconnecttheapptoyourcar1pressthedrivebuttonintheapp2attachtheobddevicetothecableandplugitintothe12vpowercigarettelightersocketinyourcar3startyourcarsengine4selecttheobdreaderinthelistintheappandcontinue: 'A：以下の手順で車とアプリを接続してください。​\n' +
      '1. アプリの設定ボタンをタップします。​\n' +
      '2. ビーコンをケーブルに取り付け、​\n' +
      '車のシガーソケットに接続します。​​\n' +
      '3. 車のエンジンを始動します。​\n' +
      '4. アプリ内のリストからビーコンを選択します。​',
    txt_global_std_calendar: 'カレンダー',
    txt_global_std_p2pnonsequentialdatesselected: '連続していない日付が選択されました',
    txt_global_std_sortby: 'でソート',
    txt_global_std_youcannoteditthisfromvisualeditor: 'ビジュアルエディタからは編集できません。',
    txt_global_std_teamcolon: 'チーム',
    txt_global_std_exportxml: 'XMLをエクスポート',
    txt_global_std_youraveragelevelis123: 'あなたの平均スコア',
    txt_global_std_triptypes: 'トリップの種類',
    txt_global_std_belowisalistofalluserswhosnotcompletedthisstep: '以下は、このステップを完了していないすべてのユーザーのリストです',
    txt_global_std_ChicheritFacts: 'Monster Athlete Guerlain Chicherit steps up to the FIA World Rallycross Championship in 2018 with his own GC Kompetition team driving the new Prodrive-developed Renault Megane RS. A professional skier and four-time world champion in freeriding, Chicherit holds the record for becoming the first man in history to complete an unassisted backflip in a car. Chicherit made his World RX debut in the JRM Mini RX Countryman in 2015.',
    txt_global_std_p2ptotal: '合計',
    txt_global_std_sendasemail: 'メールで送信',
    txt_global_std_advancedusersettingsfor: 'の高度なユーザー設定 {name}',
    txt_global_std_bluetoothreasontext: '車に座っていることを確認するには、Bluetooth接続が必要です。',
    txt_global_std_wrongpassword: '間違ったパスワード',
    txt_global_std_youaredrivingregno: '{regno}を運転中です。',
    txt_global_std_p2pblockmycar: '予約をブロックする',
    txt_global_std_loadingteamleaderboard: 'チームリーダーボードを読み込んでいます...',
    txt_global_std_successfulpasswordchange: 'パスワードが無事変更されました。',
    txt_global_std_dayd: 'd',
    txt_global_std_inviteandinspireeverydriverinyournetworktoparticipateandgetnewdataonyouresgperformance: 'ネットワーク内のすべてのドライバーを招待して刺激し、ESGパフォーマンスに関する新しいデータを取得して参加させます',
    txt_global_std_startadrive: 'トリップを開始',
    txt_global_std_statusdriving: '運転中',
    txt_global_std_tilladnotifikationer: 'Tillad notifikationer',
    txt_global_std_safedriverweeklyunlockedkey: '{user}さん、\nおめでとうございます！\n素晴らしい運転技術により\n直近1週間の平均スコアが\n{score}以上を記録しました！',
    txt_global_std_wecannotfindanydata: 'データが見つかりません！',
    txt_global_std_qhowwilligetpaidforaclaim: 'Q：請求の支払いはどのように受けられますか？',
    txt_global_std_notripsperday: '1日あたりのトリップ数',
    txt_global_std_kintofaqlink1: 'はじめに（日本語）',
    txt_global_std_ranking: 'ランキング',
    txt_global_std_noconnectiontocar: '車に接続されていません',
    txt_global_std_yournicknameshouldcontainatleastthree: 'ニックネームはスペースなしで3文字以上です。',
    txt_global_std_decline: '辞退する',
    txt_global_std_p2pconfirm: '確定する',
    txt_global_std_tobeabletostartadrive: '運転履歴を記録するには、Bluetooth、\n位置情報サービス、モーションフィットネス\nをオンにしてください。',
    txt_global_std_SendMyPosition_Text1: '{appName}アプリより、順位をお送りしました。',
    txt_global_std_enterbasepremium: '基本保険料を入力してください',
    txt_global_std_colortheme: 'カラーテーマ：',
    txt_global_std_1gotosettingsforappnameinyourphonebrb2allowaccesstomotionfitnessb: '1.スマートフォンの{appname}の設定に移動します。<br> <b> 2。 Motion＆Fitnessへのアクセスを許可します。</b>',
    txt_global_std_mostpopular: '最も人気のある',
    txt_global_std_challengeaverage1: 'チャレンジ平均',
    txt_global_std_statistikfordennekretur: 'dennekøreturの統計',
    txt_global_std_OSolbergQuote: '見積もりは間もなく…',
    txt_global_std_invitationsent: '招待状が送られました。',
    txt_global_std_tilladatappensenderdignotifikationernrdererregistreretnyekreture: "App'enの送信者がnotifikationerを掘り、nårdererregistreretnyekøretureでTillad。",
    txt_global_std_p2pcarcare: 'カーケア',
    txt_global_std_p2pemailopenapp: '{appname}を開く',
    'txt_global_std_Al-KuwariMusic': 'Classic music.',
    txt_global_std_pleasefirstfillinyourfirstnameandlastname: '名、姓の順に記入してください。',
    txt_global_std_nicknamenicknamealreadyexists: 'ニックネーム{nickname}は既に存在します',
    txt_global_std_2makesureyourcarsbluetoothison: '2.車のBluetoothがオンになっていることを確認します。',
    txt_global_std_enginemustberunning: 'エンジンをかけてください',
    txt_global_std_qualificationfinalstarts30thmarch: '最終予選は3月30日に始まります。',
    txt_global_std_shareinvite: '招待状を共有',
    txt_global_std_p2pselecteddateandtime: '日時',
    txt_global_std_forthisapptoworkproperlyweneedtoaccessyourlocationbluetoothandphysicalfitnesspermissionthelocationserviceisusedinbackgroundforthedrivingfeaturetoanalyzeyourdrivingwithoptimalresultstheappalsodoesthisinthebackgroundwevalueyourprivacyandwillnotsharethisinformationwithathirdpartyentity: 'このアプリが正しく機能するためには、あなたの場所、Bluetooth、体力の許可にアクセスする必要があります。位置情報サービスは、運転機能のバックグラウンドで使用されます。最適な結果であなたの運転を分析するために、アプリはバックグラウンドでもこれを行います。私たちはあなたのプライバシーを尊重し、この情報を第三者と共有することはありません。',
    txt_global_std_kintofaq9a: 'スコアの算出を始めとした当サービスのみに利用し、位置情報を含む各種情報を第三者に提供することはございません。当サービスの利用終了後には、破棄もしくは個人が特定できない状態にいたします。',
    txt_global_std_qhowdoiconnecttomycarsbluetooth: 'Q：車のBluetoothに接続するにはどうすればよいですか？',
    txt_global_std_passwordchanged: 'パスワード変更済み',
    txt_global_std_deleteaccount: 'アカウントを削除する',
    txt_global_std_repetitionsnumber: '繰り返し回数',
    txt_global_std_collectablebadges: 'コレクションバッジ',
    txt_global_std_youbooked5timesinonemonthyouhaveearned1500points: '1か月に5回予約しました。あなたは1500ポイントを獲得しました。',
    txt_global_std_goodthatyouarekeepingyouraccountsecure: '安全性の確保にご協力いただきありがとうございます。',
    txt_global_std_BeforeFirstBill_Hello: 'こんにちは',
    txt_global_std_drivemorethan1miletogetmoredetailedanalyticsaboutyourtrip: '1マイル以上運転して、旅行に関するより詳細な分析を取得します。',
    txt_global_std_summaryofanalyse: '分析のまとめ',
    txt_global_std_youearnedapercentdiscountbydrivingsafe: '安全運転で{％}％の割引を受けました',
    txt_global_std_thepiggybank: '貯金箱',
    txt_global_std_youareamongthebestappnamedrivers: 'うわー！あなたは最高の{appname}ドライバーです！',
    txt_global_std_step3: 'ステップ 3',
    txt_global_std_upcoming: '開始前',
    txt_global_std_pigsinvited: '友達を紹介する',
    txt_global_std_weregladtohaveyouonboardwatchourvideoguideforsomequicktipstogetyouupandrunning: 'ご参加いただきありがとうございます。ビデオガイドを見て、すぐに使い始められるようにするための簡単なヒントをご覧ください。',
    txt_global_std_youmayhavetogotoyourbluetoothsettingsforbothyourphoneandcar: '携帯電話と車の両方のBluetooth設定を変更する必要があります。',
    txt_global_std_buy2: '購入',
    txt_global_std_p2pborrowyour: '借ります。',
    txt_global_std_f_lanzmusic: 'クラシック、ヒップホップ、ダンス',
    txt_global_std_HSolbergWhyjoin: 'For the first time, my fans and I can compete together to win an FIA Challenge. ',
    txt_global_std_friendsreferral: '友達紹介',
    txt_global_std_qcanisignupforinsurancetobegintoday: 'Q：今日から保険に加入できますか？',
    txt_global_std_MSIG_Onboarding9: '3. Drive!',
    txt_global_std_gooddrivingtechnique: '運転が上手です',
    txt_global_std_howdoifileaclaim: 'クレームを提出するにはどうすればよいですか？',
    txt_global_std_SignupFia_Text3: 'FIA SDCは、世界中のトップレーサーやドライバーがチャレンジするすばらしいイニシアチブです。 スマホアプリだけでチームに参加し、最高のドライバーと闘ってFIA Smart Driver の座を獲得できます。',
    txt_global_std_yourappnamehastobeatleasttwocharacterslong: 'アプリ名は 2 文字以上にする必要があります',
    txt_global_std_appcreator_upsell: 'App Creator – 独自のブランドの運転アプリを数分で作成できます。',
    txt_global_std_advancedusersettings: '高度なユーザー設定',
    txt_global_std_weneedpermissiontoaccessyourbluetoothforthisapptowork: 'アプリが動作するよう、Bluethoothへのアクセスを許可してください。引き続きアプリをご利用になるにはデバイスに移行してください。',
    txt_global_std_p2pfaq10q: '事故を起こした場合どうすればいいですか？',
    txt_global_std_rulesforservice: 'Rules for service',
    txt_global_std_itemsperpage: 'ページあたりのアイテム',
    txt_global_std_idling: 'アイドリング',
    txt_global_std_searchtranslation: '翻訳を検索',
    txt_global_std_fiasmartdrivingchallenge: 'FIA Smart Driving Challenge',
    txt_global_std_p2phasreceivedlendingrequest: 'に 貸し出しリクエストが届きました。',
    txt_global_std_publishchanges: '変更を公開',
    txt_global_std_endinsurance: '保険終了',
    txt_global_std_qhowdoiconnecttomycar: 'Q：車とアプリを接続するにはどうすれば良いですか？',
    txt_global_std_congratulationyoupositiononleaderboardwasincreased: 'おめでとう！ランキングでの順位が上がりました！',
    txt_global_std_TidemandDescription: 'Pontus’ first taste of competitive came in crosskarts, where he became both Nordic and Swedish champion. 2011, the youngest driver to win the Swedish Rally Championship',
    txt_global_std_p2pfaq8a: '自分で運転することがわかっている日や、その他の理由でクルマを共有したくない日には、簡単にクルマの予約をブロックすることができます。\n' +
      '1.\tホーム画面の左上にある「カレンダー」を選択します。\n' +
      '2.\t「クルマを貸す」タブで「予約をブロックする」を選択します。\n' +
      '3.\tクルマの予約をブロックしたい日時を選択します。\n' +
      '4.\t「予約をブロックする」を選択して完了です。',
    txt_global_std_youdrovereallywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'あなたは本当にうまく運転しました！ 1週間の平均は{score}よりも優れていました。 {points}ポイント獲得しました！',
    txt_global_std_SulayemMileage: 'Liwa Oasis – Al Ain road through the Empty Quarter Desert, experiencing the spectacular peaceful beauty of the desert, driving on this road will make you calm and collected.',
    txt_global_std_failedtosendtothefollowingusers: '次のユーザーに送信できませんでした:',
    txt_global_std_repeatyourpassword2: 'パスワードを繰り返します',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint3: '状況に応じたメッセージを適切なタイミングで伝える',
    txt_global_std_pleasereloadortryagainlater: 'ロードし直すか、後ほどお試しください。',
    txt_global_std_inviteuser: 'ユーザーを招待する',
    txt_global_std_nofriendsfound: '友だちが登録されていません。',
    txt_global_std_co2lockedkey: '環境にやさしい運転をして​\nCO₂排出量を{co2value}％削減できた際​\nに獲得できるバッジ',
    txt_global_std_addfriendtolist: 'リストに友達を追加',
    txt_global_std_daysdays: '{日}日',
    txt_global_std_bestdriverstoday: 'Best drivers today',
    txt_global_std_backtoleaderboard: 'リーダーボードに戻る',
    txt_global_std_50kmunlockedkey: 'あなたはアプリで50kmの距離を運転しました！',
    txt_global_std_warikan_onboarding_title_2: '【わりかんKINTO】アプリ　仮パスワードのご案内',
    txt_global_std_allowmotionfitness: 'モーションフィットネスを有効にして、​\n運転の開始と終了を自動的にサポートします。',
    txt_global_std_locationselectalways: '場所-「常に」を選択',
    txt_global_std_sms: 'SMS',
    txt_global_std_idonthaveanaccount: '初めてのご利用ですか？',
    txt_global_std_allowlocationservices: '位置情報サービスを許可する',
    txt_global_std_activatecode: 'コードを有効化',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint3: 'CO2 削減、リスク削減、自動車のない日など、独自の KPI を使用してユーザーにインセンティブを与えます',
    txt_global_std_TaxInvoice11: 'Full premium payment must be made before policy inception at the time of documentation.',
    txt_global_std_hereyoucanseehowyouhavedriven: '走行記録はこちら',
    txt_global_std_appnamewallet: '{appname}ウォレット',
    txt_global_std_canbeearnedwhenyoufocustoimproveinthisspeed: 'この速度の改善に集中すると獲得できます',
    txt_global_std_wildfire: '炎:',
    txt_global_std_letsgo: 'さあ、いってみよう！',
    txt_global_std_theresetlinkwillexpirein20minafterthatyoullneedtosubmitanewrequestinordertochangeyourpassword: 'リセット リンクは 20 分で期限切れになります。その後、パスワードを変更するには、新しいリクエストを送信する必要があります。',
    txt_global_std_warikan_onboarding13: '◎お問い合わせはこちら',
    txt_global_std_challengesomeone: '挑戦する',
    txt_global_std_settingssaved: '設定を保存',
    txt_global_std_p2pviewreceipt: '領収書を見る',
    txt_global_std_greatstreak: '素晴らしい連勝！',
    txt_global_std_vouchercodes: 'バウチャーコード',
    txt_global_std_getstarted2: '始めましょう',
    txt_global_std_p2pdistancetravelled: '走行距離',
    txt_global_std_discount: 'ディスカウント',
    txt_global_std_buymoremilage: 'マイレージを購入する',
    txt_global_std_closed: '終了',
    txt_global_std_addcustombadgeicon: 'カスタムバッジアイコンを追加',
    txt_global_std_feedbackoptions: 'フィードバックの種類',
    txt_global_std_navigation: 'ナビゲーション',
    txt_global_std_willbeaddedtoyoursubscription: 'サブスクリプションに追加されます',
    txt_global_std_fortst: 'Fortsæt',
    txt_global_std_youmustnotuseappwhiledriving: '運転中はアプリの使用は禁止です。',
    txt_global_std_allowbluetoothtocollectdrivingscore: 'Bluetoothをオンにしてスコアを受け取れるようにしてください。',
    txt_global_std_viewpassword: 'パスワードを表示',
    txt_global_std_leaderboard_tab_monthly: '毎月',
    txt_global_std_invitationsentto: 'に送信された招待状',
    txt_global_std_thesebadgesyoucanonlyearnonetime: '1回のみ獲得可能なバッジ',
    txt_global_std_communication_upsell: 'コミュニケーション ツール – パーソナライズされた通知でエンゲージメントを高めます。',
    txt_global_std_ifyoucomeacrosswordsandphrasesyoucanteditbyclickinginthevisualeditoryoucansearchandfindtheminthelistbelow: ' * ビジュアル エディターをクリックしても編集できない単語やフレーズに遭遇した場合は、下のリストで検索して見つけることができます。',
    txt_global_std_typehere: 'ここに入力',
    txt_global_std_endd: '終了',
    txt_global_std_accountpreferences: 'アカウント設定',
    txt_global_std_rules: 'ルール',
    txt_global_std_showonleaderboard: 'ランキングに表示',
    txt_global_std_cimage5h: '坂での技術',
    txt_global_std_MSIG_InsuranceLetter7: 'Type of Policy.',
    txt_global_std_connectingyourgarminwatchiseasyclicktheconnectbuttonbelowtohelpusuncovernewinsightsintocrashriskbyanalyzingbothdrivingandhealthdata: 'Garmin ウォッチの接続は簡単です。下の [接続] ボタンをクリックして、運転データと健康データの両方を分析することで、衝突リスクに関する新しい洞察を明らかにしてください。',
    txt_global_std_showall: 'すべて表示',
    txt_global_std_p2pcostsplitisbasedonhoursthecarisused: 'コスト分割は、車の使用時間に基づいています。',
    txt_global_std_anonymous: '匿名',
    txt_global_std_viewloyaltypoints: 'ポイントを表示する',
    txt_global_std_totaldistance2: '走行距離\n',
    txt_global_std_: '',
    txt_global_std_namecancelledthebooking: '{name}が予約をキャンセルしました。',
    txt_global_std_cimage40d: 'By scrolling, you keep the progress, in addition to saving fuel and the car for wear.',
    txt_global_std_uservsuser: 'ユーザー対ユーザー',
    txt_global_std_powerhuman: 'パワーヒューマン',
    txt_global_std_p2pdividedequallyamongfriendsthatusedcar: '車を使った友達の間で均等に分けられました。',
    txt_global_std_achievementcreatedmessage: 'アチーブメントが作成されました！',
    txt_global_std_p2pitstimetosplityourcostformonthof: 'の月の費用を分割する時が来ました',
    txt_global_std_dontrestrictkey: 'キーを制限しない',
    txt_global_std_usethistemplate: 'このテンプレートを使用',
    txt_global_std_sendagain: 'もう一度送信',
    txt_global_std_mysubscriptioncost: 'サブスクリプション費用',
    txt_global_std_kilometers_driven: '走行距離',
    txt_global_std_typeyourverificationcode: 'Type your verification code',
    txt_global_std_howdoesthescoringsystemwork: 'スコアリングシステムはどのように機能しますか？',
    txt_global_std_orrecruitafriend: 'または友だちを募集する',
    txt_global_std_stop: '終了',
    txt_global_std_youcanuploadmax10photosofyourcar: '車両の写真は9枚までアップロードできます',
    txt_global_std_hereyoufindyourhighestimprovmentpotential: 'もっとも改善の余地があるポイント',
    txt_global_std_easywaytogetinvolved: '参加する簡単な方法',
    txt_global_std_OSolbergFacts: 'Son of Petter Solberg. He drives his father’s car for the Nordic team.',
    txt_global_std_usersuccessfullyadded: 'ユーザーが正常に追加されました',
    txt_global_std_avgrpm: '平均回転数',
    txt_global_std_youearnedapercentagephdiscount: '{percentage}% 割引を獲得しました！',
    txt_global_CoreTemplate_insuranceoverview: '保険の概要',
    txt_global_std_addvehicleidentification: '車両識別番号を追加する',
    txt_global_std_includedkm: '距離を含める',
    txt_global_std_password: 'パスワード',
    txt_global_std_apikey: 'API キー',
    txt_global_std_points2: 'ポイント',
    txt_global_std_saveinsurancekey: 'アクティベーションキーを保存',
    txt_global_std_namekeytitle: 'アプリに表示されるタイトル',
    txt_global_std_cannotfindanybluetoothdevice: 'Bluetoothデバイスが見つかりません。',
    txt_global_std_p2pborrowacartwo: 'クルマを借りる',
    txt_global_std_p2ppushbodynewexpensetoapprove: '{name}さんが{monthname}分に費用を追加しました',
    txt_global_std_userspecific: 'ユーザー固有',
    txt_global_std_deletedtranslationfromreview: 'レビューから翻訳を削除',
    txt_global_std_MSIG_InsuranceLetter31: '(Approved Insurers)',
    txt_global_std_bysigningupyouaccept: 'サインアップすることで同意します',
    txt_global_all_monday: '月曜日,',
    txt_global_std_worldclass: '安全運転\nプロ',
    txt_global_std_typeofchallenge: 'チャレンジの種類',
    txt_global_std_problempersistcontactsupport: '問題が解決しない場合は、サポートに連絡してください。',
    txt_global_std_kintoremainingpointstonextlevel: '{level}レベルまであと{points}ポイント',
    txt_global_std_do: '対処',
    txt_global_std_feb: '2月',
    txt_global_std_apirestrictions: 'API の制限',
    txt_global_std_standard: '標準',
    txt_global_std_MSIG_InsuranceLetter34: 'GST Reg. No. M2-0001676-9',
    txt_global_std_driver: 'ドライバー',
    txt_global_std_loyalty_upsell_pitch: '独自のオンブランド ロイヤルティ プログラムをすばやくカスタマイズして、次のことを行います。',
    txt_global_std_warikan_onboardingmail_temppwd: '【仮パスワード】',
    txt_global_std_SignupFia_Text11: '詳細はこちらから。',
    txt_global_std_savesettings: '設定を保存',
    txt_global_std_compareactivities: 'アクティビティを比較する',
    txt_global_std_errorfetchingtrends: '取得エラー',
    txt_global_std_p2pfaq2q: '予約の日時を変更できますか？',
    txt_global_std_1000pointaverageachievementunlocked: 'あなたは本当にうまく運転しました！ 1週間の平均{scoretype} was better than {score}ていました。 1000ポイントを獲得しました！',
    txt_global_std_emailregisteredintheapp: 'アプリに登録されているメール',
    txt_global_std_p2ppushbodyaddadmin: '{name}さんがあなたに"{vehicle}"の管理者権限を付与しました',
    txt_global_std_keepingyourmindandeyesonwhatshappeningaroundyouisvitalforyoursafetyandthesafetyofothersphoneactivityandotherdistractionsaffectyourfocusandincreasecrashrisk: '自分の身の回りで起こっていることに心と目を向け続けることは、自分自身と他の人の安全にとって不可欠です。電話でのアクティビティやその他の気晴らしは、集中力に影響を与え、クラッシュのリスクを高めます。',
    txt_global_std_fuelprice: '燃料の価格',
    txt_global_std_recoveryhelp: '救援',
    txt_global_std_areyousureyouwanttoterminateInsurance: '保険を終了してよろしいですか？',
    txt_global_std_carrorewards: 'Carroの報酬',
    txt_global_std_recruitedfriends: '募集した友達',
    txt_global_std_changesinreview: 'レビューの変更',
    txt_global_std_youhaveinvitedamountfriends: '{amount}人の友達を招待しました',
    txt_global_std_searchforgroups: 'グループを検索する',
    txt_global_std_sidekickusertermsandconditions: 'ユーザー利用規約',
    txt_global_std_iacceptthe: '私は受け入れます',
    txt_global_std_clientlibraries: 'クライアント ライブラリ',
    txt_global_std_kintofaqlink2: 'アプリのマニュアルを見る（日本語）',
    txt_global_std_apirestrictionsspecifytheenabledapisthatthiskeycancall: 'API 制限は、このキーが呼び出すことができる有効な API を指定します',
    txt_global_std_nextstep2: '次の一歩',
    txt_global_std_MSIG_InsuranceLetter16: 'Market Value At Time Of Loss',
    txt_global_std_signinwithappname: 'Sign in with {appName}',
    txt_global_std_uniquebadges: '記念バッジ',
    txt_global_std_hellothereletsgetstarted: 'こんにちは！さあ、はじめましょう',
    txt_global_std_loyaltydistribution: '忠誠の分配',
    txt_global_std_sendalinktoyourfriendsothattheycandownloadtheapp: '招待リンクを送信して友だちにアプリを紹介しましょう',
    txt_global_std_p2pemailsupport: '・平日の09：00〜18：00のテクニカルアプリサポート',
    txt_global_std_offerendsin: 'オファーはで終了します',
    txt_global_std_invited2: '招待',
    txt_global_std_viewoffer: '費用を確認する',
    txt_global_std_loadingdata: 'データをロード中…',
    txt_global_std_saveandexit: '保存して閉じる',
    txt_global_std_SulayemBesttip: 'Think safety first, always be aware of your surroundings and keep your speed within limits. ',
    txt_global_std_p2pborrow: '借りて',
    txt_global_std_pleaseverifyifyourmileageiscorrectbeforesavingit: '保存する前に、マイレージが正しいかどうかを確認してください',
    txt_global_std_clickthelinkbelowtoconfirmyouremailandfinishcreatingyourenerfyglobalaccount: '下のリンクをクリックしてメールを確認し、Enerf Global アカウントの作成を完了してください。',
    txt_global_std_p2pwantstolendyou: '貸したい',
    txt_global_std_choosehowmanydrivesyouwanttochallengeyourfriendon: '友達と対戦したい期間を選択してください。​\n対戦には期間中の毎日の​\n平均スコアが使用されます。',
    txt_global_std_callsosphonenumber: '救援依頼、電話番号',
    txt_global_std_regnumber: '登録番号',
    txt_global_std_p2pfaq5a: '写真を選択するとズームができるようになります。',
    txt_global_std_yourannualmilagewasunabletoreadfromtheserverpleasetryagainlater: '年間走行距離がサーバーから読み取れませんでした。しばらくしてからもう一度お試しください。',
    txt_global_std_contactforactivation: 'アクティベーションの連絡先',
    txt_global_std_p2pconfirmwith: 'で確認',
    txt_global_std_lastlogfilesent: '最後に送信されたログファイル',
    txt_global_std_removeparticipant: '参加者を削除する',
    txt_global_std_fiatodayrule2020: '本日1km以上走行している参加者が対象です\n',
    txt_global_std_v2ilevel: 'レベル',
    txt_global_std_lostconnectionbig: 'トリップが終了します',
    txt_global_std_p2pfaq22a: '1.\t「メニュー」から「わりかん」を選択します。\n' +
      '2.\t「追加費用」を選択します。\n' +
      '3.\t「費用を追加する」を選択します。\n' +
      '4.\tクルマを選択し、費用の種類を選択、料金を入力し、領収書の画像をアップロードし、保存を選択してください。\n' +
      '5.\t追加した費用は「追加費用」ページに反映されます。',
    txt_global_std_accidentdetails: '事故の詳細',
    txt_global_std_rwmregister: '登録する​',
    txt_global_std_insuranceandpayments: '保険と支払い',
    txt_global_std_notjoinable: '参加不可',
    txt_global_std_yourinsuranceperiodisover: 'あなたの保険期間は終わりました：',
    txt_global_std_join: '参加する',
    txt_global_std_chooseallowalways: '［常に］を選択します',
    txt_global_std_refer10friendstounlockthisachievementandearn300pointstoyour: '10人の友達を紹介してこの実績をアンロックし、300ポイントを獲得しましょう',
    txt_global_std_areyousurethatyouwanttoremovethisdrivenotreversable: 'トリップを削除しますか？一度削除すると元に戻せません。',
    txt_global_std_searchbynameoremailaddress: '名前またはメールアドレスで検索',
    txt_global_std_challengearandomdriver: 'ランダムドライバーに挑戦',
    txt_global_std_howdidyoufeel: '感想は?',
    txt_global_std_theanalysistookalongertime: '分析に時間がかかっています。完了次第お知らせをします。',
    txt_global_std_p2psafedriverbonusincluded: 'セーフドライバーポイントを使う',
    txt_global_std_assigntogroups: 'グループに割り当てる',
    txt_global_std_kilometerpremiumtohigh: 'Kilometer premium to high',
    txt_global_std_groupmanagement: 'グループ経営',
    txt_global_std_website: 'Webサイト',
    txt_global_std_speedvariation: '速度変動',
    txt_global_std_profilesettings: 'プロフィール/設定',
    txt_global_std_yourlocation1: 'あなたの場所',
    txt_global_std_connecttoyourcarbeforelendingittoafriend: 'クルマをメンバーに貸す前にアプリをクルマに接続させましょう',
    txt_global_std_areyousureyouwanttounpublishyourtranslationsifyouunpublishtranslationstheappwillnotshowthislanguageanymore: ' 翻訳を非公開にしてもよろしいですか?翻訳を非公開にすると、アプリはこの言語を表示しなくなります。',
    txt_global_std_adgangtilbluetooth: 'ブルートゥースまでのAdgang',
    txt_global_std_somethingsmissing: '不足しているものがあります…',
    txt_global_std_teamscore: 'チームスコア',
    txt_global_std_ConfirmFia_Text3: '日付',
    txt_global_std_p2pcostsplitisbasedonthepercentageofuse: 'コスト分割は、使用率に基づいています。',
    txt_global_std_p2preceiptsummary: '借りた車の概要',
    txt_global_std_25kmlockedkey: 'このバッジを獲得するには、50 km の距離を運転してください。',
    txt_global_std_PolicySchedule22: 'NCD Protector (If any)',
    txt_global_std_403forbidden: '403禁止します',
    txt_global_std_worldchallenge: 'ワールドチャレンジ',
    txt_global_std_yourinsuranceisnowactivated: '保険は有効です',
    txt_global_std_p2pfaq12q: 'メンバーを削除できますか？',
    txt_global_std_reset: 'リセット',
    txt_global_std_remove: '削除',
    txt_global_std_deleteuseraccount: 'ユーザーアカウントを削除する',
    txt_global_std_addaddress: '住所を追加',
    txt_global_std_recurring: '繰り返し',
    txt_global_std_sendupdate: '更新を送信する',
    txt_global_std_novalidapikeyprovided: '有効な API キーが指定されていません。',
    txt_global_std_bestdriversintotal: 'Bedste bilister i alt',
    txt_global_std_secondss: '秒',
    txt_global_std_selecttheregnumberofyourcar: '車の登録番号を選択してください',
    txt_global_std_NewPW_Text1: '{appName}アカウントの新しいパスワードをリクエストしました。',
    txt_global_std_thislinkexpiresinxtimeandcanonlybusedonce: 'このリンクは6分で期限切れになり、1回だけ使用できます。',
    txt_global_std_sosinternationalaroundtheclock: '24時間体制での国際SOS',
    txt_global_std_drivesafeandecofriendly: '安全で環境にやさしい運転',
    txt_global_std_carstatsthisday: '最近の日の車の統計',
    txt_global_std_youhaventpairedwithacaryet: '車とまだ​接続されていません。',
    txt_global_std_p2powneruserguide: 'クルマオーナー　ユーザーガイド',
    txt_global_std_p2ptodo: 'するために',
    txt_global_std_amount_default: '料金',
    txt_global_std_thisisatestblabla: 'これはテストblablaです。',
    txt_global_std_challengeranking: '現在のランキング',
    txt_global_std_insuranceoverview2: '保険の概要',
    txt_global_std_resetpassword: 'パスワードを再設定する',
    txt_global_std_achievementtool_upsell: '実績ツール – スマートな運転の習慣を簡単に身につけることができます。',
    txt_global_std_verybad: 'とても悪い',
    txt_global_std_thebetteryoudrivethemoreyousave: '運転がうまいほど、節約できます',
    txt_global_std_whymustiallowalwaysallowlocationsservicesanswer: 'AIはリスクスコアを計算し、緊急時に対応\nするために現在位置を常に把握する必要が\nあります。',
    txt_global_std_members2: 'メンバー',
    txt_global_std_getstartedtips: 'アドバイスを開始',
    txt_global_std_ifyourfriendregisterswiththereferralcodebothyouandyourfriendwillreceivenok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsurance: '友人が紹介コードで登録すると、あなたとあなたの友人の両方が貯金箱で250ノルウェークローネを受け取ります（これには、保険の開始日から少なくとも1か月間は両方のTrygの顧客である必要があります）。',
    txt_global_std_mytrips: '私の旅行',
    txt_global_std_tripsapi: '旅行 API',
    txt_global_std_youwillbebilledatpremiumlevel10: 'You will be billed at premium level 10, please refer to your insurance letter for price details.',
    txt_global_std_p2pwantstolendborrow: ' {lendborrow}したい車',
    txt_global_std_kintofaq9q: '個人情報の取扱いはどのようになりますか？',
    txt_global_std_doyouwanttodeletetheapikey: 'API キーを削除しますか?',
    txt_global_std_yourealmostthere: 'もうすぐです！',
    txt_global_std_trygdiscountpopuptext: 'ここでは、Sidekick自動車保険に加入している場合にどれだけ節約できるかを確認できます。節約の可能性は、年間価格、年間走行距離、車種によって計算されます。\n' +
      'この情報を入力していない場合、節約額は年間5,000クローネの価格と20,000kmの年間走行距離に基づいて計算されます。',
    txt_global_std_thesebadgesyoucanearnseveraltimes: '何度も獲得可能なバッジ',
    txt_global_std_doyouwanttodriveforteamnamesteam: '{teamName}のチームのために運転しますか？',
    txt_global_std_p2ptypeamessagedot: 'メッセージを入力する',
    txt_global_std_WelcomeLetter_Text6: 'CEO',
    txt_global_std_welcometoyournewaccountwehavesentyouanemailtoverifyyournewaccountremembertocheckyourspamfolderdidntgetanemail: '新しいアカウントへようこそ！新しいアカウントを確認するためのメールを送信しました。スパムフォルダを確認することを忘れないでください。メールが届きませんでしたか？',
    txt_global_std_searchchallenges: 'チャレンジを探す',
    txt_global_std_yourcarisntturnedon: '•車の電源が入っていない。',
    txt_global_std_100pointtripachievementunlocked: '{scoretype} {score}以上で運転しました！すごい！ 100ポイント獲得しました！',
    txt_global_std_upcomingtraining: '指導予定',
    txt_global_std_estimatedprice: '推定価格',
    txt_global_std_yourmileagewillbesavedintheobddevice: "Your mileage will be saved in the OBD device's built in memory storage and will be uploaded at premium level 10 when you complete your next connected drive.",
    txt_global_std_exportdrives: 'ドライブをエクスポートする',
    txt_global_std_usersoverview: 'ユーザーの概要',
    txt_global_std_PolicySchedule3: 'Cover Note/Ref. No.',
    txt_global_std_youhaveexceededthedrivingmaxtimeof: '最長運転時間 {breakTime}を超えました。',
    txt_global_std_KleinschmidtChamp: 'Dakar Rally Champion 2001',
    txt_global_std_mustbeavalidsizenumbersand: '有効なサイズ、数字、および+である必要があります',
    txt_global_std_reasonterminatedinsurance: '保険を解約する理由',
    txt_global_std_automatic: '自動',
    txt_global_std_tryoutappname: '{appName}を試してみてください',
    txt_global_std_allowingaccesstobluetoothisnecessaryforthisapptoconnecttoyourcarandmeasureyourdriving: 'このアプリがあなたの車に接続してあなたの運転を測定するには、Bluetoothへのアクセスを許可する必要があります。',
    txt_global_std_accidentandclaims: '事故とご請求 ',
    txt_global_std_buyinsurance: '保険を購入する',
    txt_global_std_ifyourcardoesnotshowupinthelistyoumightneedtogotobluetoothsettingsforbothyourphoneandyourcar: '車がリストに表示されない場合は、携帯電話と車の両方のBluetooth設定に移動する必要がある場合があります。',
    txt_global_std_verifycode: 'Verify code',
    txt_global_std_p2pcancelledph: 'キャンセル({count}件)',
    txt_global_std_destination: '目的地',
    txt_global_std_thesepermissionsarenecessaryfortheapptobeabletoregisteryourdrivescorrectlyletsgo: 'これらの個人情報は第三者には共有されることはありません。',
    txt_global_std_willbesenttotheemailregisteredintheonboarding: ' オンボーディングで登録したメールアドレスに送信されます',
    txt_global_std_findyourobdportinyourcar: '車の OBD ポートを探す',
    txt_global_std_yournewappishere: 'あなたの新しいアプリはここにあります！',
    txt_global_std_workspacepermissions: 'ワークスペースの権限',
    txt_global_std_rpmspeednotfluctating: '回転数/一定速度',
    'txt_global_std_Al-KhelaifiInterests': 'Cars, Photography, Motorsports',
    txt_global_std_welcometofiatciaodrive1: 'FIAT CIAO DRIVEへようこそ',
    txt_global_std_addtogroups: 'グループに追加',
    txt_global_std_enteryourpolicynumber: 'ポリシー番号を入力してください',
    txt_global_std_youneedtofinishsettingupyourprofilebeforejoining: 'イベントに参加する前にプロフィールを設定してください。',
    txt_global_std_allteamleaders: 'すべてのチームリーダー',
    txt_global_std_createanaccountforname: 'Create an account for {appName}',
    txt_global_std_apitool_upsell_pitch_bulletpoint1: 'シームレスな統合のための包括的なデジタル オンボーディング',
    txt_global_std_jpfaqtitle: 'アプリを車に接続して、走行\nデータを記録する方法を教え\nてください。',
    txt_global_std_trygtakemetoapp: 'スキップしてTryg Driveを始める',
    txt_global_std_cimage2h: '車間距離を保つ',
    txt_global_std_canisignupforinsurancetobegintoday: '今日から保険に加入できますか？',
    txt_global_std_p2psubmit: '提出する',
    txt_global_std_timesinceparked: '駐車してからの時間',
    txt_global_std_testarenandra: 'Kiểm tra một giây',
    txt_global_std_restrictkey: '制限キー',
    txt_global_std_yourinsurancehasbeenrenewedformonth: 'あなたの保険は{月}の間更新されました。',
    txt_global_std_p2pyouhavechosen: '選択した日付：',
    txt_global_std_5streak: 'ハイタッチ:',
    txt_global_std_p2pfaq13q: 'クルマを予定よりも早く返したい場合、どうすればいいですか？',
    txt_global_std_p2pconfirmedbookings: '確定済みの予約',
    txt_global_std_thepasswordminimumislength8charactersandmustinclude1lowercaseuppercasenumeralandspecialcharacter: 'パスワードの最小長は8文字で、小文字、大文字、数字、および特殊文字を1つ含める必要があります',
    txt_global_std_resetyourenerfyglobalpassword: 'Enerf Global のパスワードをリセットする',
    txt_global_std_welcomebackenerfyfirstname: '{firstName}さん、 {appName}におかえりなさい！',
    txt_global_std_westzone: '西ゾーン',
    txt_global_std_monitoringyourdrivinghasneverbeeneasier: '運転の監視がこれまでになく簡単になりました。',
    txt_global_std_apis: 'API',
    txt_global_std_f_lanzchamp: '2020 KZ2 SAチャンピオン、2019 KZ2 SA副チャンピオン、2018RokファイナルシフターレディースチャンピオンおよびP16全体\n',
    txt_global_std_companyaverage: '会社平均',
    txt_global_std_p2pcostsplits: 'コスト分割',
    txt_global_std_placethewarningtriangle: '車両の100m以上前の見やすい場所に三角表示板を設置する',
    txt_global_std_FiaPassword_Text2: 'FIAスマートドライビングチャレンジアカウントの新しいパスワードをリクエストしました。ユーザーへの新しいパスワード',
    txt_global_std_drivesettingsnotfinished: '運転に関する設定が未完了',
    txt_global_std_allowaccesstomotionfitness: 'Motion＆Fitnessへのアクセスを許可する',
    txt_global_std_myprojects: '私のプロジェクト',
    txt_global_std_60days: '60日',
    txt_global_std_howdoesitwork: 'それはどのように機能しますか？',
    txt_global_std_selectatemplate: 'テンプレートを選択',
    txt_global_std_birthday: '生年月日',
    txt_global_std_sdcrapply2019price3: '300 kr gasoline checks',
    txt_global_std_thisactivationcodeisinvalid: 'アクティベーションコードが無効です',
    txt_global_std_insurancehasbeenactivatedforregno: 'The insurance has been activated for vehicle {regNo}.',
    txt_global_std_nextlevel: '次のレベル',
    txt_global_std_claimshandling: '保険請求の対応',
    txt_global_std_insuranceperiod: '保険期間',
    txt_global_std_activationcodeemail3: 'アプリで起動できる！',
    txt_global_std_cantfinduserinhead2head: 'ユーザーが見つかりませんか？あなたはReferFriendで友達を招待することができます。',
    txt_global_std_actualtimegain: '取得した時間',
    txt_global_std_anodometerorodographisaninstrumentusedformeasuringthedistancetravelledbyavehicleitcanbefoundinthecarsdashboard: '走行距離計または走行距離計は、車両の移動距離を測定するために使用される機器です。それは車のダッシュボードにあります。',
    txt_global_std_p2pfriendscalendars: 'クルマのカレンダー',
    txt_global_std_p2pfaq16a: 'KINTOの事故対応ページをご確認ください。\nhttps://kinto-jp.com/insurance/',
    txt_global_std_stress: 'ストレス',
    txt_global_std_weareunabletorecordyourtrip: '旅行を記録できません！',
    txt_global_std_validbetween: '有効期間',
    txt_global_std_city: '区市町村',
    txt_global_std_daydreamingisacommontypeofdriverdistractionyoumaythinkitsharmlessbutanythingthattakesyourmindoffthedrivingtaskincreasescrashriskchangingyourgazefrequentlycanhelpyoumaintainfocusontheenvironmentaroundyounew: '白昼夢は、ドライバーの注意散漫の一般的なタイプです。無害だと思うかもしれませんが、運転から離れてしまうと、事故のリスクが高まります。視線を頻繁に変えることで、周囲の環境に集中し続けることができます。',
    txt_global_std_whatdoyouwanttodo: '何をしたいですか？',
    txt_global_std_p2pwhenitstimetodrive: '運転するとき',
    txt_global_std_yourdrivingstatsforthistripcomparedtotheaveragedriver: '平均的なドライバーと比較した、\n今回の運転統計',
    txt_global_std_myrewards: '交換ラインアップ',
    txt_global_std_whatisanodometer: 'オドメーターとは何ですか？',
    txt_global_std_Fia_Receipt_Text15: 'Your activation code:',
    txt_global_std_locationservice: '位置情報サービス',
    txt_global_std_resetpasswordforname: '{name} のパスワードをリセットしますか?',
    txt_global_std_sdcrapply2019price1: '1000 kr gasoline checks',
    txt_global_std_driversnow: 'ドライバー',
    txt_global_std_pointsheader: 'ポイント',
    txt_global_std_p2pyouhavexfriendsintheapp: '現在{number}人のメンバーと繋がっています',
    txt_global_std_step4: 'ステップ 4',
    txt_global_std_warikan_onboarding4: 'ご利用手順:',
    txt_global_std_p2plogintomykintoaccount: 'Kintoアカウントにログインします',
    txt_global_std_nobluetoothbrnoworries: 'Bluetoothがありませんか？ <br/>心配ありません！',
    txt_global_std_insuranceactivationkeydoesnotexist: '保険のアクティベーションキーがありません',
    txt_global_std_wearebuildingyourdemoapp: '私たちはあなたのデモアプリを構築しています',
    txt_global_std_reportuser: 'ユーザーを通報する',
    txt_global_std_lendings: '貸付',
    txt_global_std_entervalidphone: '6桁以上の電話番号を入力してください',
    txt_global_std_idriveforaridesharingcompanywillyouinsuremyvehicle: '私は相乗り会社のために運転します、あなたは私の車に保険をかけますか？',
    txt_global_std_youpurchasedcdw5timestoyourbookingyouhaveearned100points: '予約時にCDWを5回購入しました。あなたは100ポイントを獲得しました。\n',
    txt_global_std_WelcomeLetter_Hello: 'こんにちは',
    txt_global_std_enteroptionalactivationcode: 'オプションのアクティベーションコードを入力してください',
    txt_global_std_yourapikey: 'API キー',
    txt_global_std_settingshasbeensaved: '設定が保存されました',
    txt_global_std_loginwithoutaccount: 'Log in without account',
    txt_global_std_participantsfor: 'の参加者',
    txt_global_std_yourfinalscorewillbecalculatednexttime: '最終スコアは次回接続時に計算されます',
    txt_global_std_whenyouhavecompletedyourfirstdriveyouwillseeithere: '初ドライブ後にこちらからご確認いただけます。',
    txt_global_std_turnonyourphonesbluetooth: '携帯電話の Bluetooth をオンにします。',
    txt_global_std_removeuser: 'ユーザーを削除',
    txt_global_std_youneedtoactivateprivacysettings: 'プライバシーの設定を有効にしてください',
    txt_global_std_covercubecollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: 'Covercubeは位置データを収集して、アプリが閉じているときや使用していないときでも運転スコアを有効にします。',
    txt_global_std_MikkelsenChamp: 'IRC champion 2011 & 2012. 3rd place in World Rally Championship (2014, 2015 & 2016). 3 wins in World Rally Championship. Was a part of the FIA young driver excellence academy\n',
    txt_global_std_drivewascancelledbeforeitwasvalidandwillbediscarded: 'ドライブは有効になる前にキャンセルされ、破棄されます',
    txt_global_std_rwmconnecttocar: '接続',
    txt_global_std_insnumber: '保険番号',
    txt_global_std_p2ptotalexpenses: '追加した費用',
    txt_global_std_theusermustacceptaninvitationtothegroupfirst: 'ユーザーは、最初にグループへの招待を受け入れる必要があります。',
    txt_global_std_p2pfaq24a: '4通りの方法があり、自由に選ぶことができます。\n' +
      '（合計費用：KINTO月額料金と各メンバーの追加費用の合計）\n' +
      '\n' +
      '1.\t「利用割合」各メンバーの利用時間の総月間時間（7月の例：24時間ｘ31日＝744時間）に対する割合を算出し、合計費用を掛ける。\n' +
      '2.\t「利用時間」合計費用を総利用時間で割り、利用時間の単価を算出。各利用時間を掛ける。\n' +
      '3.\t「均等分割」合計費用をグループ人数で均等に分割。\n' +
      '4.\t「走行距離」合計費用を月の総走行距離で割り、走行距離の単価を算出。各自の走行距離を掛ける。',
    txt_global_std_active: '実施中',
    txt_global_std_cimage43h: '列では急加速と急発進はやめましょう。',
    txt_global_std_policynumberwassaved: 'ポリシー番号が保存されました',
    txt_global_std_p2pminutes: '分',
    txt_global_std_createddate: '作成日',
    txt_global_std_activecompetitions: '実施中のコンペ',
    txt_global_std_p2pfaq17q: '友だちをメンバーとして追加したい。',
    txt_global_std_chooseoneoption: '1つのオプションを選択してください',
    txt_global_std_howdoiconnecttomycarsbluetooth: '車のBluetoothに接続するにはどうすればよいですか？',
    txt_global_std_viewandredeem: '表示して利用する',
    txt_global_std_insureddistance_used_90__2: 'Snart tomt! Öka årlig körsträcka här.',
    txt_global_std_insuranceterms2: '保険期間',
    txt_global_std_translationtool_upsell_pitch_bulletpoint3: 'より多くの視聴者の前に製品を配置する',
    txt_global_std_ChicheritMusic: 'Coming soon...',
    txt_global_std_whenyouarereadytodrive: '運転する準備ができたら',
    txt_global_std_keepingyourmindandeyesontheroadandtrafficaroundyouisvitalforyoursafetyandthesafetyofotherslookingwellaheadandscanningtheenvironmentwillhelpyoutoanticipatewhatotherroadusersmightdonew: '周囲の道路や交通に注意を払い、目を離さないようにすることは、自分の安全と他の人の安全にとって不可欠です。先を見越して環境をスキャンすると、他の道路利用者が何をするかを予測するのに役立ちます。',
    txt_global_std_couldhavesaved: '保存できた',
    txt_global_std_level123: 'レベル',
    txt_global_std_theapikey: 'API キー',
    txt_global_std_yougetthiswhenyouhaveachieved5tripswithascorebetterthan3point3: '3.3を超えるスコアで5回の旅行を達成すると、これが得られます',
    txt_global_std_andspaces: 'and spaces.',
    txt_global_std_pressconfirmtoadjustyourmilagebonusplan: '「確認」を押して、マイレージボーナスプランを調整します。',
    txt_global_std_OSolbergMileage: 'Coming soon...',
    txt_global_std_settled: 'Settled',
    txt_global_std_group: 'グループ',
    txt_global_std_avgspeed7d: '平均速度 7D ',
    txt_global_std_anotherverificationemailhasbeensentpleasecheckyourinbox: '別の確認メールが送信されました。受信トレイを確認してください',
    txt_global_std_PolicySchedule4: 'Policy No',
    txt_global_std_keepingyourmindandeyesontheroadandtrafficaroundyouisvitalforyoursafetyandthesafetyofotherslookingwellaheadandscanningtheenvironmentwillhelpyoutoanticipatewhatotherroadusersmightdo: '周囲の道路や交通に注意を払い、目を離さないようにすることは、自分の安全と他の人の安全にとって不可欠です。先を見越して環境をスキャンすると、他の道路利用者が何をするかを予測するのに役立ちます。',
    txt_global_std_clickhere2: 'ここをクリック。',
    txt_global_std_feedbackinterval: 'フィードバックの間隔',
    txt_global_std_MSIG_InsuranceLetter44: 'AVGスコアの対象となった事故が発生した場合、最大$ 2,000.00の段階的な超過額が適用されます',
    txt_global_std_p2pthetemporarypasswordisonlyvalidfor72hourspleaserequestanewpassword: '仮パスワードの有効期限は72時間です\n新しい仮パスワードを\nリクエストしてください',
    txt_global_std_joiningheatspleasewait: '試合に出場中、お待ちください…',
    txt_global_std_yourmessagewillbesentat: 'あなたのメッセージは次のアドレスに送信されます:',
    txt_global_std_oncepairedyoullautomaticallymovetothenextstep: 'ペアリングが完了すると、自動的に次のステップに進みます。',
    txt_global_std_youdroveextremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'あなたは非常によく運転しました！ 1週間の平均{scoretype}は{score}よりも優れていました。 {points}ポイント獲得しました！',
    txt_global_std_pioneeronepageuserguide: 'ユーザーガイド',
    txt_global_std_checkifyouareconnectedcorrectobdreader: '適切なOBDリーダ―に接続しているか確認してください。',
    txt_global_std_joinaheat: '試合に出場',
    txt_global_std_selectnewmileageselector: '新しいマイレージを選択',
    txt_global_std_setupcompanychallenge: '会社の課題を設定する',
    txt_global_std_p2pclose: '閉じる',
    txt_global_std_locked: '(ロック)',
    txt_global_std_getyourdrivingscore: '運転に関する設定',
    txt_global_std_competeasindividualsandinteams: '個人としてそしてチームで競争する',
    txt_global_std_AbdullaDescription: 'Adelは、後にFIA控訴裁判となる抗議の後、コ・ドライバーであるHakam RabieとともにFIA CCR T2 世界選手権で優勝しました。クロスカントリーに初めてフルシーズン参加し、世界で最も過酷なオフロード選手権キャンペーン期間中にポルトガル、アブダビ、スペインで優勝を果たしました。',
    txt_global_std_p2pcontactsupport: 'サポートへ問い合わせ',
    txt_global_std_eventcreator: 'イベントクリエーター',
    txt_global_std_leaderboards: 'スコアボード',
    txt_global_std_consentInfo: 'はい = コミュニティに参加してTryg Driveの一員になりましょう。同意するとコンペに参加してアプリの機能をフルにお楽しみいただけます。保険や損害保険商品、コンペなどのサービスに関する最新のニュースやお得情報をEメール、Tryg Drive アプリ、SMSで受け取ることに同意します。お取り引きに関するお問い合わせは、無料でご利用いただけます。いいえ = 設定のYou  \\consent\\ が有効ではありません。 \\consent\\ が無効の場合、Tryg Driveアプリでコンペに関する情報やアドバイスを継続的に受信できません。',
    txt_global_std_howmanycarswereinvolved: '事故に遭った車両は何台ですか？',
    txt_global_std_communicationtool: 'コミュニケーションツール',
    txt_global_std_nothingtoshowhere: 'ここに表示するものはありません',
    txt_global_std_MSIG_InsuranceLetter5: 'Road Transport (Amendment) Act 2019 (Malaysia)',
    txt_global_std_kintorefer50friendsunlocked: 'スコアが1100を超えました! = 300ポイント\n',
    txt_global_std_whenusingthiscodeorlinkuserwillbeonboardedtoyourenerfyglobalteam: 'このコードまたはリンクを使用すると、ユーザーは Enerfy Global チームにオンボーディングされます。',
    txt_global_std_avgtrips7d: '平均走行距離 7D ',
    txt_global_std_whereismycar: '車の位置を確認する',
    txt_global_std_donthaveanaccount: 'アカウントをお持ちではありませんか？',
    txt_global_std_invitecolleaguesorevenfriendsandfamilytoyourchallengetomakeyourcompanychallengemoreinteresting: '同僚や友人、家族をチャレンジに招待して、会社のチャレンジをもっと面白くしましょう！',
    txt_global_std_customfilters: 'カスタムフィルター',
    txt_global_std_tipcity3DESC: '赤い車のように、コーナーで急ブレーキをかけてしまっています。青い車のように、コーナーに差し掛かる前に徐々に減速できると理想的です。',
    txt_global_std_pleasewaitwhileweauthenticateyouremail: 'メールを認証するまでお待ちください',
    txt_global_std_doyouwanttojoinname: '{name}に参加しますか？',
    txt_global_std_whendrivingonmotorwaystoomanydriverspumptheacceleratorpedalupanddowninaneverendingpattern100kmhto110kmhthen110kmhto100kmhandsoonthistypeofdrivingusesunnecessaryfuelkeepingasafesuitablefollowingdistancecanhelpavoidthetemptationtovaryspeedunnecessarilynew: '高速道路を運転しているとき、あまりにも多くのドライバーがアクセル ペダルを終わりのないパターンで上げたり下げたりしています。このタイプの運転は、不必要な燃料を使用します。安全で適切な車間距離を保つことで、不必要に速度を変えようとする誘惑を避けることができます。',
    txt_global_std_reportaccident: '事故を通報する',
    txt_global_std_closingthispopupyouconfirmyouhaveredeemedyourpoints: 'このポップアップを閉じると、ポイントを利用したことを確認できます。',
    txt_global_std_aryoudrivinganelectricorobd: '電気自動車を運転していますか、または、車載デバイスを取り付けていますか？',
    txt_global_std_previewheader: 'プレビュー',
    txt_global_std_activationletter_text15sidekick: 'Sidekickアプリを使用すると、自分の運転スタイルと、より安全なドライバーになる方法について詳しく知ることができます。さらに、良い運転スコアを取得することで保険料を節約できます！',
    txt_global_std_opened: '開催中,　',
    txt_global_std_subheats: '予選',
    txt_global_std_thesevehiclesarealreadyactivated: 'These vehicles are already activated:',
    txt_global_std_avgmaxspeed7d: '平均最高速度 7D ',
    txt_global_std_teamco2: 'チームの CO2 削減',
    txt_global_std_skillcomparison: 'スキルの比較',
    txt_global_std_allowlocationaccess: '位置情報へのアクセスを許可',
    txt_global_std_officialtimingpartner: '公式タイミングパートナー',
    txt_global_std_createnew: '新しく作る',
    txt_global_std_saved: '保存完了',
    txt_global_std_doitlater: '後でやります',
    txt_global_std_yourinsurance: 'Your insurance',
    txt_global_std_yougotthecodefrommail: '保険に加入した際の書面にコードが記載されています。',
    txt_global_std_gallonus: 'USガロン',
    txt_global_std_vehicle2: '車両',
    txt_global_std_pleaseenteryouremailaddress: 'Eメールアドレスを入力してください。',
    txt_global_std_SignupFia_Text2: 'FIA Smart Driving Challengeの最新情報をお届けします。',
    txt_global_std_webclaims: 'ウェブでご請求',
    txt_global_std_xxperofourcustomers: '{perc}お客様の％は、使いやすさのために自動を使用しています。',
    txt_global_std_groups: 'グループ',
    txt_global_std_hereisapreviewofhowyourappwilllookifyouarehappywiththeresultpleasecontinuetopublishyourdemoapp: 'これがアプリの外観のプレビューです。結果に満足している場合は、引き続きデモアプリを公開してください。',
    txt_global_std_amounttopay: '支払う金額',
    txt_global_std_cimage37d: 'さまざまな速度での停止距離を確認してみましょう。',
    txt_global_std_forthebestexperience: '質の良い経験のために',
    txt_global_std_BeforeFirstBill_Text1: '保険料はクレジットカードからの引き落としになります。初回ご請求時に寄せられるよくあるご質問をまとめました。',
    txt_global_std_clickontheareaswhereyourvehiclehasbeendamages: '車両の故障部分をクリックしてください',
    txt_global_std_riskindex_upsell_pitch_bulletpoint2: '運転リスクに対する政治的、健康的、経済的変化の影響',
    txt_global_std_bodyrepair1panel: 'ボディリペア1パネル',
    txt_global_std_tripdata: 'トリップデータ',
    txt_global_std_premiumwithzipdrivingriskindex: 'Zip-Driving リスク インデックス付きプレミアム',
    txt_global_std_3selectyourcarinthelistbelow: '3.以下のリストから車を選択します。',
    txt_global_std_aresetlinkhasbeensenttouser: 'リセットリンクがユーザーに送信されました',
    txt_global_std_idrivetomuch2: 'I drive to much',
    txt_global_std_doyouwanttochallengename: '{名前}に挑戦しますか？',
    txt_global_std_p2pbookingsummary: '予約状況',
    txt_global_std_weekw: '週',
    txt_global_std_ifyouhaveaninsuranceyoucanfindyourpolicynumberinyourinsurancedocuments: 'あなたが保険を持っているならば、あなたはあなたの保険書類であなたの保険番号を見つけることができます。\n' +
      '今手元にありませんか？心配ない！後で設定で追加できます。',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'あなたは本当にうまく運転しました！ 1週間の平均{scoretype}は{score}よりも優れていました。 {points}ポイント獲得しました！',
    txt_global_std_clickdownbelowtoseehowitwent: '下をクリックして、状況を確認してください',
    txt_global_std_set_up_automatic_start_stop: 'セットアップ：自動スタート＆ストップ',
    txt_global_std_youhavebeeninvitedtojoinagroup: 'グループに招待されました',
    txt_global_std_HusseinAbdullaMileage: 'Nothing specific',
    txt_global_std_speedcostanalysis: '速度のコスト分析',
    txt_global_std_co2reduced: 'CO₂削減',
    txt_global_std_cimage39h: 'アクセルペダルから足を離して、なるべく長く走ろう！',
    txt_global_std_youdroveextremelywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'あなたは非常によく運転しました！ 1週間の平均は{score}よりも優れていました。 {points}ポイント獲得しました！',
    txt_global_std_p2pstart: '開始：',
    txt_global_std_searchforusers: 'ユーザーを検索する',
    txt_global_std_distancedriven: '運転距離',
    txt_global_std_drivertrainingrecommended: 'ドライバートレーニング推奨',
    txt_global_std_iunderstandthatthiswillcompletewipeallofmyaccount: 'アカウントデータと走行データが\nすべて削除されることに同意します。',
    txt_global_std_ownerdidnotgetthatnotification: '{name}さんがクルマを借ります\n',
    txt_global_std_p2pwaitingtoconfirmthespecifiedprice: '予約の確認待ち',
    txt_global_std_cannotbeempty: '空にすることはできません',
    txt_global_std_acceptconnectioninapp: 'アプリへの接続を許可',
    txt_global_std_thisuserwillbeaddedtoyourplanatanadditionalcostofuserfeeperuser: 'このユーザーは、ユーザーあたり{userfee}の追加料金でプランに追加されます。',
    txt_global_std_theusermustacceptaninvitationtotheteam: 'ユーザーはチームへの招待を受け入れる必要があります。',
    txt_global_std_acheivementcannotbedeleted: ' この実績は削除できませんのでご注意ください。',
    txt_global_std_refer10friendsachievementunlocked: '10人の友達を紹介しました。いいね！ 300ポイントを獲得しました！',
    txt_global_std_female: '女性',
    txt_global_std_member1: 'メンバー',
    txt_global_std_viewloyaltypoints1: 'ポイントを表示する',
    txt_global_std_manageusersettingsandsupporthereusethequickaccessbuttonsbelowandnavigatebetweenusersbysearchingforthemintheusersview: 'ここでユーザー設定とサポートを管理します。下のクイックアクセスボタンを使用して、ユーザービューでユーザーを検索してユーザー間を移動します。',
    txt_global_std_afteryourfirstnumberofmonthsmonthperiodourappwilltrackyourscoresandprovidearatebasedonyourdrivinghabitsifyouhaveafewbadscoresyouhaveanentirenumberofmonths2monthstoimproveyouwillreceiveupdatesalongthewaywitheachtripadvisingyouofyourpotentialsavingsorifyourneedtoprovidemorefocuswithyourdrivinghabits: '最初の{numberOfMonths}か月の期間の後、アプリはスコアを追跡し、運転習慣に基づいてレートを提供します。悪いスコアがいくつかある場合は、{numberOfMonths2}か月全体を改善する必要があります。旅行のたびに、節約の可能性について、または運転習慣にもっと焦点を当てる必要があるかどうかを通知する最新情報を受け取ります。',
    txt_global_std_sunday: '日曜日',
    txt_global_std_youwillbereadysoon: 'あなたはすぐに準備ができています！',
    txt_global_std_stepcurrentstepoftotalsteps: 'ステップ {currentStep}/{totalSteps}',
    txt_global_std_advancedadmin: '上級管理者',
    txt_global_std_teamnames: 'チーム名',
    txt_global_std_recoverpassword: 'Recover password',
    txt_global_std_dontyouhaveanaccountwithenerfy: "Don't you have an account with {appName}?",
    txt_global_std_cimage1d: '減速',
    txt_global_std_seehowmuchyoucouldsave: 'どれだけ節約できるか確認する',
    txt_global_std_showmehow: 'どうするか教えて',
    txt_global_std_yourownbrandedworkspaceopenforalldriverthatwishtoparticipate: '参加を希望するすべてのドライバーが利用できる独自のブランドワークスペース',
    txt_global_std_friendstothe: '友達に',
    txt_global_std_yougetthiswhenyoufirstachieveascoreabove4point4: 'これは、最初に4.4を超えるスコアを達成したときに取得します',
    txt_global_EnerfySettingsTemplate_other: 'その他,',
    txt_global_std_ConfirmFia_Text1: 'おめでとうございます！',
    txt_global_std_leaderboardpositionincrease: 'リーダーボードの順位が上がりました!',
    txt_global_std_KristofferssonDescription: 'Johan started his career with International Super Series with Sweden team where he impressed and moved on to Touring Car Championship (STCC) where he won in a single season in 2002. He moved then to the European RX in 2013-2014 season and in 2015 to the FIA WRX where he won 5 rallies and came out 3-rd in overall ranking. Ended runner up in 2016 behind his countryman Mattias Ekström and won it in 2017.',
    txt_global_std_settings: '設定',
    txt_global_std_alertdriveservicetitle: '運転中に有効',
    txt_global_std_warikan_onboarding5_2: '株式会社KINTO',
    txt_global_std_p2pbookingmessages: '予約メッセージ',
    txt_global_std_carsharingemptyviewtitle: 'No Activities',
    txt_global_std_moreinformation: '詳しくは',
    txt_global_std_hellothere: 'こんにちは！',
    txt_global_std_ifyourdriverhasproblemswithreceivingtheirpasswordyoucanviewitherenotethatthecurrentpasswordresetswhenyouclickthebutton: 'ドライバーがパスワードを受信できない場合は、ここで表示できます。ボタンをクリックすると、現在のパスワードがリセットされることに注意してください。',
    txt_global_std_profilepreferences: 'プロフィール設定',
    txt_global_std_continue: '次へ',
    txt_global_std_tiphighway2DESC: '赤い車のように、頻繁に前方車両を追いかけ追い越してしまっています。青い車のように、車間距離を保てると理想的です。',
    txt_global_std_p2pcarsharingismorefunwhenyouinviteyourfriendsandfamily: '友達や家族を招待すると、カーシェアリングがもっと楽しくなります！',
    txt_global_std_failedtogetreviewlist: 'レビュー リストの取得に失敗しました',
    txt_global_std_noofparticipants: '参加者数',
    txt_global_std_downloadapp: 'アプリをダウンロードする',
    txt_global_all_weekday: '平日,',
    txt_global_std_choosethecarontheappscreenandwaituntilyougetnotificatedthatthedevicesarepaired: 'アプリ画面で車を選択し、デバイスがペアリングされたという通知が届くまで待ちます',
    txt_global_std_statusinitdriving: '接続済み',
    txt_global_std_p2puploadpicturesofyourcartodocumentanyscratchesordents: '車の傷やへこみを記録する場合は、写真をアップロードしてください。',
    txt_global_std_p2pfinishbooking: '予約を終了する',
    txt_global_std_acceptedpermissions: '承認された権限',
    txt_global_std_publishachievementpopuptext: '実績を公開すると、そのロック状態がアプリ内のユーザーに表示されます。ユーザーがバッジを授与されると、ロック解除された状態で表示されます。',
    txt_global_std_highspeedconditions: '高速条件',
    txt_global_std_eastzone: '東ゾーン',
    txt_global_std_denneerndvendigforatappenkanvidehvornrduholderstilleeribevgelseaccelerererellerbremser: "Denneernødvendigforapp'enkanvide、hvornårduholder Stille、eribevægelse、accelerererellerbremser。",
    txt_global_std_warikan_onboarding12: '※本メールは送信専用です。\nご返信いただいてもお応えいたしかねますのでご了承ください。',
    txt_global_std_HansenChamp: 'EURO RX Champion 2016, RX Lite Cup 2015, World Champion in ROK Junior 2010',
    txt_global_std_communication_upsell_pitch_bulletpoint3: '好みのチャネルでユーザーとつながる',
    txt_global_std_VilliersWhyjoin: 'Because we all need to do what we can to make our roads safer, and also, this is gonna be really fun.',
    txt_global_std_p2psaveandclose: '保存して閉じる',
    txt_global_std_selectanotherdevice: '別のデバイスを選択',
    txt_global_std_adduser: 'ユーザーを追加する',
    txt_global_std_pioneersmartdrivingchallenge: 'Pioneerドライブチャレンジ',
    txt_global_std_exportpdf: 'PDFをエクスポート',
    txt_global_std_creatingaccountwiththirdpartyname: 'Creating account with {thirdPartyName}...',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint1: 'リスクレベルを決定する要因を理解する',
    txt_global_std_locationwithbest: '最も改善しやすい位置。',
    txt_global_std_carbonclean: 'カーボンクリーン',
    txt_global_std_march: '3月',
    txt_global_std_3rddate: '3日',
    txt_global_std_p2ppushbodynewfriend: '{name}さんがメンバーになりました',
    txt_global_std_people: 'People, 人',
    txt_global_std_MSIG_InsuranceLetter20: '1) The Insured',
    txt_global_std_cimage15d: '小さな積み重ねが大切です',
    txt_global_std_youractivatedkeys: 'アクティベーションコード',
    txt_global_std_riskydecisionbetweentrips: '走行中の危険な運転判断',
    txt_global_std_youhavenowachievedyourbestscoresofar: 'これまでに最高のスコアを達成しました（4.4以上）',
    txt_global_std_p2pshareakintocar: 'KINTO家族や友だちと',
    'txt_global_std_Al-KuwariInterests': 'Car racing, Car endurance, Cross Country Rallies, Baja Rallies',
    txt_global_std_foratappenskalfungerekorrektharvibrugforadgangtildintelefonslokationsamtmotionogfitnessdata: "app'en skal fungere korrektの場合、adgang til din telefons lokation samtmotionogフィットネスデータのharvibrug。",
    txt_global_std_p2paddexpenses: '費用の追加',
    txt_global_std_nomapcoordinatesavailableforthisrun: 'このドライブに利用できる座標がありません',
    txt_global_std_cimage22h: 'クルーズコントロールをしない',
    txt_global_std_emergencycontact: '緊急連絡',
    txt_global_std_repetition1: '繰り返し',
    txt_global_std_p2pbookingrequests: '予約リクエスト',
    txt_global_std_addtosubscription: 'サブスクリプションに追加',
    txt_global_std_kintofaq6a1: '以下連絡先にメールでお問合せください。',
    txt_global_std_enteravalidphonenumber: '有効な電話番号を入力してください。',
    txt_global_std_wednesday: '水曜日',
    txt_global_std_describeaccident: '事故について記載する',
    txt_global_std_pointslabel: 'ポイント',
    txt_global_std_drivingskills: '運転技術',
    txt_global_std_takecontrolofyouraccountandprofile: 'アカウントとプロフィールを管理してください。',
    txt_global_std_MSIG_Onboarding1: 'Welcome!',
    txt_global_std_loyaltyoverview: '忠誠の概要',
    txt_global_std_warikanchallengerulesnotes: '【注意事項】\n' +
      '・参加することで、ニックネームとアイコンがほかのユーザーにも表示されます。「参加する」を押下した場合、本注意事項について同意したものとみなします。なお、プロフィール設定画面にてニックネームを非表示にすることも可能です。\n' +
      '・1回につき1km以上の走行が対象となります。\n' +
      '・同じルートは1日2回までの採点となります。\n' +
      '・総走行距離は1km以上であることが条件となります。\n' +
      '・「今週」はスコア平均、「全期間」は自身のドライブの上位10％の平均となります。',
    txt_global_std_VilliersBesttip: 'Always be alert and anticipate what other drivers will do. They might turn right in front of you, so you need be alert to be able to stop in time or avoid them.',
    txt_global_std_nopleasebeadvisedthattheenerfyapp: 'No. Please be advised that the Enerfy app needs to be installed on your phone and that you need to be logged in for your drives to get registered. You also need to have Bluetooth activated on your phone in order to connect.',
    txt_global_std_p2punpaid: '未払い',
    txt_global_std_youhavebeendrivingwithtrygdriveforayear: 'TrygDriveで1年間運転しています！',
    txt_global_std_latest: '最新',
    txt_global_std_co2unlockedkey: 'CO₂排出を{co2value}%\n削減することが\nできました。',
    txt_global_std_PolicySchedule19: 'Gross Premium',
    txt_global_std_afewsimplereasonswhyfiasdcmakesadifferenceforyourbusiness: 'FIASDCがあなたのビジネスに違いをもたらすいくつかの簡単な理由',
    txt_global_std_bestregards: 'よろしくお願いします',
    txt_global_std_distanceunit: '距離単位',
    txt_global_std_pressthedrivebuttonintheapp: 'アプリのドライブボタンを押します',
    txt_global_std_at: 'で',
    txt_global_std_events_overview_season: '季節',
    txt_global_std_p2p2totalcosttosplit: '分割するための総コスト',
    txt_global_std_youpositiononleaderboardwasincreased: 'ランキングでの順位が上がりました！',
    txt_global_std_responses: '反応',
    txt_global_std_avglevel123: 'スコア',
    txt_global_std_PolicySchedule34: 'Registration No',
    txt_global_std_companyname: '会社名',
    txt_global_std_yourealmostdonenowwejustneedtoverifyyouremailaverificationlinkhasbeensentto: 'ほぼ終わりです！今、私たちはあなたの電子メールを確認する必要があります。確認リンクが次の宛先に送信されました。',
    txt_global_std_startatrip: 'トリップを開始する',
    txt_global_std_ayouwillhave60daysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithcovercubewithalapseincoverage: 'A：有効期限から60日以内に支払いを行い、Covercubeで保険契約を継続します。',
    txt_global_std_done: '完了！',
    txt_global_std_MSIG_InsuranceLetter2: 'ORIGINAL',
    txt_global_std_rwmnotnow: '後で',
    txt_global_std_foratopretteforbindelsemellemdinbilogappnskaldergivesadgangtilbluetooth: 'のためにopretteforbindelsemellem dinbilogappénskalderはadgangtilbluetoothを与えます。',
    txt_global_std_youvsecochampions: 'あなたvsエコチャンピオン',
    txt_global_std_carsharingcompanydescription: 'これについての短い文。',
    txt_global_std_circle: '円:',
    txt_global_std_p2pfaq1q: 'アプリ内のいくつかの機能が使用できないのはなぜですか？',
    txt_global_std_selectlanglabel: '言語を選択する',
    txt_global_std_gennemsnitsbilist: 'Gennemsnitsバイリスト',
    txt_global_std_loyaltysystemdesc: '特典は、以前のロイヤリティ期間の最終結果に基づいていることに注意してください。ポイントはアチーブメントを取得することで獲得でき、新しい期間の開始時にリセットされます。',
    txt_global_std_connectiontype: '接続タイプ',
    txt_global_std_manual_start_and_stop_requires_access_to_location_services_and_motion_fitness: '手動で開始および停止するには、位置情報サービス（GPS）およびモーションとフィットネスにアクセスする必要があります。',
    txt_global_std_pluginthedeviceinyourcar: 'デバイスを車両にプラグイン',
    txt_global_std_yourdrivingskills: 'あなたの運転技術',
    txt_global_std_keepcollectingloyaltypointseventhoughsomeoneelseisdrivingyourcar: '他の誰かがあなたの車を運転しているにもかかわらず、ロイヤルティポイントを集め続けてください！',
    txt_global_std_anyspeedgainisanaccelerationnomatterhowsmallsoplanandmakegooduseofeveryspeedincrease: 'どんなに小さくても、速度の増加は加速です。したがって、すべての速度増加を計画して有効に活用してください。',
    txt_global_std_validatedactivationcode: '検証済みのアクティベーション コード',
    txt_global_std_p2pchoosehowtosplitcost: 'コストを分割する方法を選択する',
    txt_global_std_yourlinkshouldbeavailableinyourinboxinminutespleaseremembertocheckyourjunk: 'リンクは{number}分で受信トレイに表示されます。あなたのがらくたをチェックすることを忘れないでください。',
    txt_global_std_userwithoutinsurance: '{appName}-user without insurance',
    txt_global_std_successfullyunpublishedlanguage: '言語の非公開に成功しました',
    txt_global_std_p2pfaq24q: '費用を「わりかん」する方法は何がありますか？',
    txt_global_std_nointernetconnection2: 'インターネット接続がありません！',
    txt_global_std_bluetoothlink: 'Bluetoothリンク',
    txt_global_std_OgierMusic: 'Coming soon...',
    txt_global_std_congratulations2: 'おめでとう',
    txt_global_std_events_overview_today: '試合',
    txt_global_std_userhasbeenscheduledforremoval: '{{email}}は{{timestamp}}にアカウントが削除されるよう設定されました。',
    txt_global_std_fileaclaimdirectlyintheappbyclickingfileclaimlinkorbycallingphonenumber: '{fileClaimLink}をクリックするか、{phoneNumber}を呼び出すことにより、アプリで直接申し立てを行います。',
    txt_global_std_p2ppushtitleexpensesdenied: '追加費用が承認されませんでした',
    txt_global_std_HSolbergMileage: 'Love to drive on snow and ice, that is the ultimate driving fun.',
    txt_global_std_info: 'インフォメーション',
    txt_global_std_newpasswordsaved: '新しいパスワード保存',
    txt_global_std_texttofriend: '友人へのテキスト',
    txt_global_std_connected2: '接続済み',
    txt_global_std_p2piwanttoborrowakinto: 'キントを借りたい',
    txt_global_std_welcometoenerfyfirstname: '{appName}にようこそ！',
    txt_global_std_charactercountexceeded: '文字数超過',
    txt_global_std_roadconditionswithbest: '改善しやすい道路のコンディション',
    txt_global_std_opensettingsonyourphoneandmakesuretohavebluetoothactivated: '携帯電話の設定を開き、Bluetooth が有効になっていることを確認します。',
    txt_global_std_compareriskandco2levelsacrossyourorganization: '組織全体のリスクとCO2レベルを比較します',
    txt_global_std_yourmessagehasbeencreated: 'メッセージが作成されました。',
    txt_global_std_redeemed: '償還',
    txt_global_std_specialimagefilenameid: 'カスタム画像',
    txt_global_std_improveheader: '最高スコア',
    txt_global_std_yesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforagentinsurance: 'はい、アプリをダウンロードして「アプリを試す」オプションを選択できます。アプリを使って運転し、{agent}保険に加入した場合にどれだけ節約できるかを確認できます。',
    txt_global_std_select: '選択する',
    txt_global_std_p2pimakintoowner: '私はキントのオーナーです',
    txt_global_std_searchbynamegrouporaccounttype: '名前、グループ、またはアカウントの種類で検索',
    txt_global_std_tripsinsight: '旅行の洞察',
    txt_global_std_removeaccountpluspersonaldata: 'アカウントを削除する',
    txt_global_std_drivertobeannounced: '発表予定のドライバー',
    txt_global_std_dennekretur: 'Dennekøretur',
    txt_global_std_p2puploadreceipt: '領収書の画像をアップロード',
    txt_global_std_dailytrips: '日次トリップ数',
    txt_global_std_thisisanexampletitle: 'タイトル例です',
    txt_global_std_invitationcode: '招待コード',
    txt_global_std_p2pnamecancelledbooking: '{name}予約をキャンセルしました',
    txt_global_std_connecttocar: '車に接続する',
    txt_global_std_experiencethebenefits: 'Experience the benefits with {appName}',
    txt_global_std_p2pfaq13a: '1.\tホームメニューの「確定した予約」から該当の予約を選択します。\n2.\t画面上の「予約を終了する」を選択してください。',
    txt_global_std_discountcodetermsconditions: '割引コード利用規約',
    txt_global_std_Fia_Receipt_Text6: 'YOUR RECEIPT:',
    txt_global_std_p2pfaq21q: 'グループのメンバーを確認したい。',
    txt_global_std_addemail: 'メールを追加',
    txt_global_std_Fia_Receipt_Text8: 'Your order',
    txt_global_std_p2pfaq25a: '1.\t「メニュー」から「走行データ」を選択します。\n' +
      '2.\tあなたの運転履歴のデータを確認できます。\n' +
      '3.\t「シェアのデータ」タブを選択すると、メンバーの履歴と合わせたグループでの走行データが確認できます。',
    txt_global_std_driverslog: 'ドライバーのログ',
    txt_global_std_achievementcannotbedeleted: 'この実績は削除できませんのでご注意ください。',
    txt_global_std_format2: 'フォーマット',
    txt_global_std_addexpenseserror: '費用の種類、金額、画像を追加してください。',
    txt_global_std_invitedrivers: 'ドライバーを招待する',
    txt_global_std_endtime: '終了時間',
    txt_global_std_2attachtheobddevicetothecableandplugitintothe12vpowercigarettelightersocketinyourcar: '2. OBDデバイスをケーブルに接続し、車の12v電源（シガーライター）ソケットに差し込みます。',
    txt_global_std_alreadyhaveaninsurance: 'すでに保険に加入していますか？',
    txt_global_std_weareproudyouwanttobe: '{appName}にようこそ！',
    txt_global_std_icannotconnectanswertext2: '<br>までご連絡ください。',
    txt_global_std_invitefriends: '友だちを招待する',
    txt_global_std_successfullyaddedtogroups: 'グループに正常に追加されました',
    txt_global_std_yes: 'はい',
    txt_global_std_joinenerfyandgetawarded: 'Enerfyに参加して賞金獲得！ ',
    txt_global_std_entert: '入力',
    txt_global_std_acceptfitnessmotion: 'フィットネス/モーションを受け入れる',
    txt_global_std_deepdiveintodrivers: 'ドライバーと契約者を確認する：パフォーマンス、事故確率、エコドライブ、CO2排出量など <br /> 一度有効にしたレポートは、何度でもダウンロードすることができます',
    txt_global_std_congratulationsyoujustclimbedtheleaderboardviewyourranking: 'おめでとうございます - リーダーボードにランクインしました!あなたのランキングを表示します。',
    txt_global_std_ecoscorescore: '{score}エコスコア',
    txt_global_std_welcome: 'ようこそ！',
    txt_global_std_onlylettersaznumbers09andperiodsareallowed: '文字（az）、数字（0-9）、およびピリオド（。）のみが許可されます。',
    txt_global_std_finalistsheat3: 'ファイナリスト-ヒート3',
    txt_global_std_username: 'ユーザー名',
    txt_global_std_customnotification: 'カスタム通知',
    txt_global_std_friend: '友だち',
    'txt_global_std_Al-KuwariDescription': 'As a co-driver, Nasser has won 2nd Place in 2016 FIA Cross Country co-driver, 1st in 2017 and 2018 MERC 2 – co-driver, 9 times Qatar Championships as a co-driver and 4 time champion in Kuwait Championships.',
    txt_global_std_checkyouremailformoredetails: '本ギフト券を利用するための詳細は\n本アプリに登録のメールアドレス宛\nにお送りします。',
    txt_global_std_f_lanzquote: '情熱があるところに、努力は情熱的に流れます',
    txt_global_std_loginorcreateaccounthere: 'ログインするか、アカウントを作成してください',
    txt_global_std_PSolbergMusic: 'Coming soon...',
    txt_global_std_bronzeusers: 'ブロンズユーザー',
    txt_global_std_internalservererrorjsonnotvalid: '内部サーバーエラー（JSONが無効）',
    txt_global_std_cimage2d: '前方車両までは少なくとも3秒の間隔を保ちましょう。',
    txt_global_std_loyaltynumber: 'ロイヤリティID',
    txt_global_std_newmobilitycompanydescription: 'スマートなオンデマンドモビリティ。',
    txt_global_std_anerroroccurredwhiletryingtousetheactivationkey: 'アクティベーションキー使用の際にエラーが発生しました',
    txt_global_std_p2pselectafriendtoviewallbookingsoftheircar: 'オーナーのクルマの予約状況を確認できます。',
    txt_global_std_mystatsthisperiod: 'この期間の私の統計',
    txt_global_std_second: '秒',
    txt_global_std_calculationexample: '計算例',
    txt_global_std_invitationlink: '招待リンク',
    txt_global_std_oilchange: 'オイル交換',
    txt_global_std_waxing: 'ワックスがけ',
    txt_global_std_teamco2savings: '参加者のCO2削減率',
    txt_global_std_driversselected: '選択されたドライバー',
    txt_global_std_ayescovercubeacceptsdriverswhoarelicensedinothercountries: 'A：はい、Covercubeは他の国で認可されたドライバーを受け入れます。',
    txt_global_std_minutemin: '分',
    txt_global_std_sparknamekey: 'スパーク！',
    txt_global_std_endsin: '残り',
    txt_global_std_p2phasspecifiedprice: 'リクエストが承諾されました。',
    txt_global_std_consentInfoYes: 'コミュニティに参加してTryg Driveの一員になりましょう。 同意するとコンペに参加してアプリの機能をフルにお楽しみいただけます。 保険や損害保険商品、コンペなどのサービスに関する最新のニュースやお得情報をEメール、Tryg Drive アプリ、SMSで受け取ることに同意します。お取り引きに関するお問い合わせは、無料でご利用いただけます。',
    txt_global_std_achieved: '達成',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatus: '実績は、ポイントを集めてロイヤルティステータスを獲得するのに役立ちます。 {appname}スーパードライバーになることで獲得できるアチーブメントをご覧ください。',
    txt_global_std_drivername: 'ドライバー氏名',
    txt_global_std_constantspeeddescription: '最高速度を抑えてみてください。最高速度が高いからといって平均速度が速くなるわけではありません。',
    txt_global_std_southzone: '南ゾーン',
    txt_global_std_thisisagorillatest: 'これはゴリラのテストです',
    txt_global_std_withtheenerfyriskportfoliotrackeryouget: 'Enerfy Risk Portfolio Trackerを使用することで、実際のリスクとCO2への影響を一度に把握することができます',
    txt_global_std_p2pnonsequentialdatesselected2: '選択された連続しない日付',
    txt_global_std_unlockatbronzelevel: 'ブロンズレベルでアンロック',
    txt_global_std_insureddistance_used_90__1: 'Du har nu använt 90% av din årliga valda körsträcka. Kom ihåg att justera körsträckan så att du undviker förkortning vid skador. Du kan justera körsträckan här.',
    txt_global_std_duedate2: '締切',
    txt_global_std_p2ppushbodytimetosplitcost: '{monthname}分のわりかんをしてください',
    txt_global_std_wanttoshareyourprogress: 'あなたの進歩を共有したいですか？',
    txt_global_std_p2plendtext: '{name}  貸したい車 {vehicle}',
    txt_global_std_OgierInterests: 'Coming soon...',
    txt_global_std_kintoImprovement4: 'もう少し！このトリップで ￥{savings}獲得できたはず。集中しましょう！',
    txt_global_std_apitool_upsell_pitch: 'この包括的な開発者のエントリ ポイントを使用すると、すぐに起動して実行できます。',
    txt_global_std_welcometoenerfyglobalworkspace: 'Enerf Global ワークスペースへようこそ!',
    txt_global_std_p2pcreateanameforthisgroup: 'グループ名を決める',
    txt_global_std_youdonothaveanyupcomingchallengesatthemoment: '現在、今後の課題はありません。',
    txt_global_std_exploretheappmanual: 'アプリのマニュアルを見る',
    txt_global_std_status1: '状態',
    txt_global_std_ayesyoucanswitchyourinsuranceanytimemostinsurancecompaniesdonothaveanearlyterminationpenaltybutyouwillneedtoinquirewithyourcurrentinsurancecarrier: 'A：はい、いつでも保険を切り替えることができます。ほとんどの保険会社には早期解約ペナルティはありませんが、現在の保険会社に問い合わせる必要があります。',
    txt_global_std_edityourcar: '車を編集する',
    txt_global_std_signinwith: 'Sign in with {name}',
    txt_global_std_addmultipleusers: '複数のユーザーを追加する',
    txt_global_std_onlyshowdriversthatneedaction: '対応が必要なドライバーのみを表示する',
    txt_global_std_p2pborrowedfrom: 'クルマを借りる',
    txt_global_std_p2pyoucaninvitexfriends: 'あと{number}人のメンバーを招待できます',
    txt_global_std_competeonlyasindividualsorbothindividuallyandinteamsteamscouldbedifferentdivisionsatyourcompany: '個人としてのみ、または個人とチームの両方で競争します。チームは、会社のさまざまな部門である可能性があります。',
    txt_global_std_doyouwanttocancelconnectionandchangeobdreader: '接続を中止して、OBDリーダーを変更しますか？',
    txt_global_std_yesyoumustadviseyouragentthatyourvehicleisusedforaridesharingcompanyandtheywillapplytheridesharingendorsementtoyourpolicy: 'はい、あなたはあなたの車が相乗り会社に使用されていることをあなたのエージェントに通知しなければなりません、そして彼らはあなたの方針に相乗りの承認を適用します。',
    txt_global_std_pointexchange: 'ポイント交換',
    txt_global_std_createatrip: '旅行を作成する',
    txt_global_std_removeaccount_subject: 'アカウント削除の手続きについて',
    txt_global_std_p2pchoosepaymentmethod: 'お支払い方法を選択してください',
    txt_global_std_pleasewaitaminuteortwobeforeyourefreshthisviewtogetnewsuggestions: 'Please wait a minute or two before you refresh this view to get new suggestions.,　新しい提案がありますので、ビューの再読み込みを数分お待ちください。',
    txt_global_std_p2psentaneditrequest: '編集リクエストを送信しました',
    txt_global_std_accelerationdescription: 'ゆっくり加速すると、安全で燃費もよくなります。',
    txt_global_std_drivesafeandecofriendlysavemoneyonyourinsurance: '安全で環境にやさしい運転をして、​</br>\nさらにCO2排出量も削減しましょう',
    txt_global_std_TaxInvoice13: 'The warranty is complied with once payment is made to the Insurer or agent or broker before policy inception',
    txt_global_std_readdocumentation: 'ドキュメントを読む',
    txt_global_std_nodriversinthislistyet: 'このリストにはまだドライバーがありません',
    txt_global_std_temporarypassword_: '仮パスワード',
    txt_global_std_thischallenge: 'この挑戦',
    txt_global_std_inyourcar: '車両で',
    txt_global_std_p2ptobepaid: '終了してください。',
    txt_global_std_division2: '分割',
    txt_global_std_cropyourprofileimage: 'Crop your profile image',
    txt_global_std_MikkelsenInterests: 'Motocross, Alpine-skiing, fishing and all sports in general.',
    txt_global_std_congratulations: 'おめでとうございます！',
    txt_global_std_acceptpermissions: '許可を受け入れる',
    txt_global_std_p2pshareyourcarwithfamilyandfriends: 'あなたの車を家族や友人と共有する',
    txt_global_std_previousstep: '一つ前の手順',
    txt_global_std_cimage29h: 'クルージング速度まで上げる-計画済み',
    txt_global_std_PSolbergChamp: 'World RX Champion 2014 & 2015, WRC Champion 13 times, Rally GB Champion 2002 & 2003',
    txt_global_std_p2pselectfriend: 'メンバーを選ぶ',
    txt_global_std_p2pthischatmightnotbeprivatealladminsandownersofthecarcanseethischat: 'このチャットは、すべて\nの管理者と所有者に公開されます。',
    txt_global_std_p2pfaq2a: '1.\tホーム画面の「確定した予約」から該当の予約を選び、「予約内容を変更」を選択します。\n' +
      '2.\t新しい日付と時間を入力し「変更内容を送信する」を選択します。\n' +
      '3.\tオーナーが新しい予約日時を承認してくれるのを待ちます。',
    txt_global_std_p2ppushtitlelendcarnomessage: '貸し出しリクエストが届きました',
    txt_global_std_earnings: '収益',
    txt_global_std_greatdrivertoday: '今日の素晴らしいドライバー',
    txt_global_std_JyrkiainenInterests: 'Motorsport obviously, jogging, golf, skiing, driving snowmobile in Finnish Lapland',
    txt_global_std_mileageinkm: '走行距離（㎞）',
    txt_global_std_hours: '時間',
    txt_global_std_parkedvsstartoftrip: '駐車場 vs 走行開始',
    txt_global_std_milesdriven: '走行マイル数',
    txt_global_std_getitfilled: '満タン！',
    txt_global_std_collectpointstounlock: 'ポイントを集めてロックを解除します。',
    txt_global_std_youmustfillinthemileage: '走行距離を入力してください',
    txt_global_std_achievementsreceived: '受け取った実績',
    txt_global_std_registerakinto: 'Kintoを登録する',
    txt_global_std_rwmnotripalreadyregisterforthisweektext: 'ライドシェアの登録、ポイントの獲得は1週間に1回までになります。​',
    txt_global_std_NewPW_Text4: '心から{appName}',
    txt_global_std_ontheinjury: 'ケガの場合',
    txt_global_std_p2pdeletemessages: 'メッセージを消去する',
    txt_global_std_p2prewardyourfriend: '友だちにポイントを付与してください。',
    txt_global_std_actions: '行動',
    txt_global_std_loyaltycertificate: '証明書',
    txt_global_std_mystatsthisday: '最新のステータス',
    txt_global_std_kintorecruit5usersunlocked: '10人紹介! =300ポイント\n',
    txt_global_std_whenwillyoudeducttheinvoicepayment: 'When will you deduct the invoice payment?',
    txt_global_std_phone: '電話',
    txt_global_std_zipdriver_upsell_pitch: '郵便番号/郵便番号リスク データベースを使用した地理的分析。',
    txt_global_std_p2pbooksequentialdates: '連続した予約',
    txt_global_std_turnonthewarninglight: '警告灯をオンにします',
    txt_global_std_insurancepolicydocuments: '保険証券',
    txt_global_std_youdroveatscoretypescoreorbettergreatyouhaveearnedpointspoints: '{score}以上で運転しました！すごい！ {points}ポイント獲得しました！',
    txt_global_std_OSolbergInterests: 'Coming soon...',
    txt_global_std_qidonthavebluetoothinmycarcanistillusetheapp: 'Q：車にBluetoothが搭載されていませんが、アプリを使用できますか？',
    txt_global_std_qhowdoirenewmyinsurance: 'Q：保険を更新するにはどうすればよいですか？',
    txt_global_std_MSIG_InsuranceLetter12: 'Period Of Insurance',
    txt_global_std_acommontypeofunsafedrivingbehaviorisputtinghugeeffortintochasingthenexttrafficlightitslikeachallengetogettothenextcrossingasquicklyaspossibleonlytobestuckwaitingforthelighttoturngreendrivingsmoothlyandlookingwellaheadtoanticipatewhatscomingupsavesbothtimeandfuel: '安全でない運転行動の一般的なタイプは、次の信号を追いかけようと多大な努力をすることです!信号が青に変わるのを待って立ち往生するだけで、次の交差点にできるだけ早く到達するのは挑戦のようなものです😊スムーズに運転し、何が来るかを予測するために十分に先を見据えることは、時間と燃料の両方を節約します.',
    txt_global_std_youmayhavetogoto: 'あなたは行かなければならないかもしれません',
    txt_global_std_what: '何が',
    txt_global_std_wanttobuyinsurance: '保険を購入したいですか？',
    txt_global_std_unlockbenefitsenterpolicynumber: '特典のロックを解除する-ポリシー番号を入力します',
    txt_global_std_timing: 'タイミング',
    txt_global_std_bydrivingsafeandsmartrememberto: '安全でスマートなドライブで。',
    txt_global_std_p2pcompleted: '完了',
    txt_global_std_whenusingthiscodeorlinkuserwillbeonboardedtoyourenerfyglobalteamandthefollowingsubgroupgroups: 'このコードまたはリンクを使用すると、ユーザーは Enerfy Global チームと次のサブグループにオンボーディングされます',
    txt_global_std_p2precommended: '推奨',
    txt_global_std_avgspeed: '平均速度',
    txt_global_std_photos: 'Photos',
    txt_global_std_pickyourteam: 'チームを選ぶ',
    txt_global_std_p2phoursused: '利用時間',
    txt_global_std_automatichelpsyourecord: '自動接続は、アプリを開くのを忘れた場合に旅行を記録するのに役立ちます。',
    txt_global_std_invitationqrcode: '招待QRコード',
    txt_global_std_connectengine: 'エンジンをかけて接続してください',
    txt_global_std_driverforscore: '{appname}をドライブしてスコアを取得',
    txt_global_std_cimage26d: '',
    txt_global_std_ConfirmFia_Text8: 'お待ちしております！',
    txt_global_std_ringphoneno: '呼び出し音{phoneNo}',
    txt_global_std_northzone: '北ゾーン',
    txt_global_std_p2pdistancetravelledrequiresbluetooth: '移動距離にはBluetoothが必要です',
    txt_global_std_name: '名前',
    txt_global_std_customize: 'カスタマイズ',
    txt_global_std_p2pwelcometokintowarikan: '金東割館へようこそ！',
    txt_global_std_cartype: '車種',
    txt_global_std_unknownstreet: '不明な道路',
    txt_global_std_p2ppushbodyyouhaveabookingtofinalize: '予約は確定していませんのでご注意ください。',
    txt_global_std_imagepickerlocked: 'ロックされたバッジのアイコンを選択',
    txt_global_std_vouchercodesaved: 'バウチャーコードを保存',
    txt_global_std_enerfyanalytics_upsell_pitch: 'パーソナライズされたレポートまたは事前定義されたレポートを使用して、データを完全に理解します。',
    txt_global_std_warikan_onboarding1_2: 'わりかんKINTOご利用者様',
    txt_global_std_selectdate: '日付を選択',
    txt_global_std_invite10friendstojointhechallengeforabetterworld: 'より良い世界への挑戦に10人の友人を招待してください。',
    txt_global_std_activate: '有効にする',
    txt_global_std_makesureyouandothersareinasafeplaceifanyoneisinjuredcallphoneno: 'あなたと他の人が安全な場所にいることを確認してください。負傷者がいる場合は、{phoneNo}までお電話ください。',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatuscheckoutbelowtheachievementsyoucanearnbybeinga: '実績は、ポイントを集めてロイヤルティステータスを獲得するのに役立ちます。を達成することで獲得できる成果を以下で確認してください。',
    txt_global_std_oncetheapprecognisestheobdreaderyoullmoveautomaticallytothenextstep: 'アプリが OBD リーダーを認識すると、自動的に次のステップに進みます。',
    txt_global_std_greatdrive: '素晴らしいドライブ！',
    txt_global_std_thankyouforyourorderenerfy: 'ご注文ありがとうございます。Enerfy のプラグインは翌営業日に発送いたします。到着には約１～３営業日かかります。',
    txt_global_std_internalservererrorpleasecontactinfogreaterthaneulicensekeynotvalid: '内部サーバーエラーです。info@ greaterthan.euにお問い合わせください（ライセンスキーが無効です）',
    txt_global_std_countrycode: '国コード',
    txt_global_std_astronautssuperlonggroupnamegroup23: '宇宙飛行士、超ロンググループ名、グループ23',
    txt_global_std_welcomeletsstart: 'いらっしゃいませ！はじめましょう！',
    txt_global_std_car2: '車',
    txt_global_std_tripsperperiod: '期間あたりの旅行',
    txt_global_std_p2pdateandtime: '日時',
    txt_global_std_p2pwehavesentanewtemporarypasswordtoyouremailaddress: '新しい仮パスワードを発行しました\nメールをご確認ください',
    txt_global_std_map2: '地図',
    txt_global_std_enteravalidpolicynumber: '有効な保険証券番号を入力してください。',
    txt_global_std_sendnow: '今すぐ送信',
    txt_global_std_qrcode: 'QRコード',
    txt_global_std_wrongemailorpassword: 'メールアドレスもしくはパスワードが間違っています',
    txt_global_std_randomperson: 'Random person,　ランダム？任意？',
    txt_global_std_3gotobluetoothsettinginyourmobilemakesureyourmobilesbluetoothisturnedon: '3.携帯電話のBluetooth設定に移動し、携帯電話のBluetoothがオンになっていることを確認します。',
    txt_global_std_step5: 'ステップ',
    txt_global_std_currentinsurancecompany: '現在の保険会社',
    txt_global_std_mysettings: '設定',
    txt_global_std_areyousureyouwanttoremoveuserfromthegroup: 'グループからユーザーを削除してもよろしいですか？',
    txt_global_std_youhaventdriventhismonth: '今月は運転していません',
    txt_global_std_checkyourinbox: '受信ボックスをチェック',
    txt_global_std_communication_upsell_bulletpoint3: '状況に応じたメッセージを適切なタイミングで伝える',
    txt_global_std_reportfilled: '報告記入中',
    txt_global_std_Alert_WereYouDrivingBody: '運転スコアを取得するには、アプリでドライブを開始および停止することを忘れないでください。',
    txt_global_std_invitemoreusers: 'より多くのユーザーを招待する',
    txt_global_std_searchmenu: 'メニューを検索',
    txt_global_std_header: 'Header',
    txt_global_std_p2pconnecttocarcomingsoon: '車に接続-近日公開',
    txt_global_std_actual: '実際のスコア',
    txt_global_std_or2: 'また',
    txt_global_std_communication_upsell_pitch_bulletpoint2: '好みのチャネルでユーザーとつながる',
    txt_global_std_cimage20d: '計画的なドライブで時間を節約できます。最高速度であまり時間はセーブできません。',
    txt_global_std_internetconnectionlost: '接続切断。これにより、アプリの遅延が発生し、運転データに影響を与える可能性があります。',
    txt_global_std_turnonyourcar: '車の電源を入れます',
    txt_global_std_p2pemailnewbookings: '新しい予約',
    txt_global_std_alertdrivenotstarted: '警告！走行中のようですが、ドライブがスタートしていません。',
    txt_global_std_manageyourteammembersandtheiraccountpermissionshere: 'ここでチームメンバーとそのアカウント権限を管理します。',
    'txt_global_std_Al-KuwariQuote': 'お見積り準備中...',
    txt_global_std_startbonusno: '開始ボーナス',
    txt_global_std_saving: '運転評価',
    txt_global_std_nationalinsuranceno: '保険番号',
    txt_global_std_ChicheritDescription: 'Guerlain ChicheritはGC Kompetitionとともに、プロ仕様のルノーメガーヌＲＳでFIA世界ラリークロス選手権に挑みました。プロのスキーヤーとしてフリーライディングの世界選手権で4回の優勝経験がある彼は、車でアシストなしのバックフリップを世界で初めて成し遂げました。2015年のJRM Mini RX Countrymanで世界ラリーへのデビューを果たしました。',
    txt_global_CoreTemplate_Offline: 'オフラインのトリップ,',
    txt_global_std_youhaveunsavedsettingsquitanyway: '設定を保存せず終了しますか？',
    txt_global_std_p2ppushtitlehasdeniedthebooking: '予約がキャンセルされました',
    txt_global_std_MikkelsenMileage: "I always enjoy driving in my hometown Oslo when back in Norway. I'm traveling quite a lot, so too me it's always nice to come home. However, I also enjoy driving in the alps since the views there are amazing.",
    txt_global_std_inviteyourfriendsandfamilytojointhechallengeforabetterworld: 'より良い世界への挑戦に参加するためにあなたの友人や家族を招待してください',
    txt_global_std_yourcarnowhasanactivatedinsurance: '車両の保険は有効です。',
    txt_global_std_weadaptthestartscreen: 'We adapt the start screen according to your selections.',
    txt_global_std_highlightedtheareainwhichyouhavethemostimprovementpotentialinthemapandchart: '一番改善しやすいポイントを、上のマップとチャートでハイライトしました。ご確認ください！',
    txt_global_std_theusermustacceptaninvitationtotheteamfirst: 'ユーザーは、最初にチームへの招待を受け入れる必要があります。',
    txt_global_std_caniswitchtoagentifialreadyhaveanactiveinsurancepolicy: 'すでに有効な保険契約がある場合、{agent}に切り替えることはできますか？',
    txt_global_std_createnewemail: '新しいメールを作成',
    txt_global_std_p2pfaq15q: '追加費用を記録したい。',
    txt_global_std_yourpasswordwaschanged: 'パスワードを変更しました！',
    txt_global_std_sparkunlockedkey: 'とてもよいドライブでした！1週間、平均4レベル以上だったので、Kintoウォレットに1000ポイント付与されました。',
    txt_global_std_joinaheattoenterthefiasdc: '試合に出場してSmart Driving Challengeに参加！',
    txt_global_std_turnonbluetooth: 'Bluetoothをオンにする',
    txt_global_std_yeary: '年',
    txt_global_std_HusseinAbdullaWhyjoin: 'Spirit of challenge',
    txt_global_std_rwmnotripalreadyregisterforthisweektitle: '既に今週のライドシェアが​\n登録されています',
    txt_global_std_JyrkiainenWhyjoin: 'Together we can have fun and improve our efficient driving skills with FIA Smart Driving Challenge!',
    txt_global_std_cimage34h: '列待ちでイライラしないこと',
    txt_global_std_manageadvancedusersettingsandsupporthereusethequickaccessbuttonsbelowtoeasilynavigatebetweenusersagreementsandfollowupandhelpoutwithonboarding: 'ここで詳細なユーザー設定とサポートを管理します。以下のクイック アクセス ボタンを使用して、ユーザー、契約、フォローアップの間を簡単に移動し、オンボーディングを支援します。',
    txt_global_std_yesagentacceptsdriverswhohavebeenlicensedinothercountries: 'はい、{agent}は他の国で認可されたドライバーを受け入れます。',
    txt_global_std_MSIG_InsuranceLetter45: '100.00ドルの超過額は、各ウィンドスクリーンのクレームに適用されます',
    txt_global_std_datespan: '期間',
    txt_global_std_top3besttrips: 'トップ 3 の最高の旅行',
    txt_global_std_welldone: 'よくできました',
    txt_global_std_practicaltimesaving: '実際の短縮時間',
    txt_global_std_thelowerthescorethemoresavingsyoucanearnyouwillseeyourpotentialsavingswitheachtrip: 'スコアが低いほど、より多くの節約を得ることができます。あなたは各旅行であなたの潜在的な節約を見るでしょう。',
    txt_global_std_payment: '支払い',
    txt_global_std_remembertostartandstop: 'ポイントと報酬を集めるために、アプリでドライブを開始および停止することを忘れないでください。',
    txt_global_std_searchbartitleandpointstext: 'タイトルやポイントで探す',
    txt_global_std_currentstandings: '現在の順位',
    txt_global_std_kintofaq4a: 'アプリに登録すると、自動的に参加できます。リストにはニックネームしか表示されませんのでご心配なく。チャレンジは2020年11月1日から2021年1月31にかけて開催されます。優勝した方は、スウエーデン北部で楽しむアイスフィッシングの旅にご招待いたします。',
    txt_global_std_p2ppushbodynewinvoicetopay: '{vehicletype}のわりかん明細が届いています',
    txt_global_std_workshop: '修理工場',
    txt_global_CoreTemplate_help: 'ヘルプ',
    txt_global_std_drivingmenu: '運転',
    txt_global_std_weareimpressed: 'うわー {user}、私たちは感銘を受けました!',
    txt_global_std_youhavenowdriven5tripsbetterthan3point3: 'これで、3.3よりも5回のトリップが改善されました',
    txt_global_std_qcaniswitchtoagentifialreadyhaveanactiveinsurancepolicy: 'Q：すでに有効な保険契約がある場合、{agent}に切り替えることはできますか？',
    txt_global_std_savingsistobeusedforsubscriptiondeduction: '節約で、サブスクリプションが割引になります。',
    txt_global_std_p2pfaq31a: '1.\t「メニュー」から「走行データ」を選択します。\n' +
      '2.\tあなたの運転履歴のデータを確認できます。特定の走行データを選ぶと、フィードバックの詳細が確認できます。',
    txt_global_std_campaignname: 'キャンペーン名',
    txt_global_std_tomasengedescriptionshort: '元プロレーシングドライバーであり、F1で最初のチェコ人ドライバー。\n彼は運転中の沈黙が大好きです。',
    txt_global_std_invalidtime: '無効な時間です。',
    txt_global_std_kilometerpremiumtoohigh: 'Kilometer premium too high',
    txt_global_std_redeemablepoints: '交換可能なポイント',
    txt_global_std_nofriendsselected: '友だちが選択されていません',
    txt_global_std_propernumber: '適切な電話番号である必要があります',
    txt_global_std_ConfirmFia_Hello: '予約完了です！',
    txt_global_std_readthecarsengineerrorsusingtheiobd2app: 'iOBD2アプリでエンジンのエラーを読み込んでください。',
    txt_global_std_createchallenge: 'チャレンジを作成する',
    txt_global_std_defaulticonimage: 'デフォルト',
    txt_global_std_insuranceterms: 'Insurance terms',
    txt_global_std_p2pfaq4q: '送信したメッセージを消去できますか？',
    txt_global_std_confirmandupdateaccount: 'アカウントを確認して更新します',
    txt_global_std_notesaved: 'メモを保存',
    txt_global_std_youhaveinvited: '招待しました',
    txt_global_std_p2pmaintenance: 'メンテナンス',
    txt_global_std_viewalldrivingdata: 'すべての運転データを表示',
    txt_global_std_audiofeedback: '音声フィードバック',
    txt_global_std_p2pfaq3a: '近日中に、クルマを探す機能を追加する予定です。今のところは、チャットでオーナーに、お聞きください。',
    txt_global_std_encouragetostayfocused: 'ドライバーに集中力を保つよう促す',
    txt_global_std_nicetohaveyouonboard: 'ようこそ！',
    txt_global_std_warikan_onboarding14: '混雑時など繋がりにくいことが予想されますのでご了承ください。',
    txt_global_std_p2pseeyourbookingsorcheckavailabilityofyourfriendscar: '予約を確認するか、友達の車の空き状況を確認してください。',
    txt_global_std_dateandtime: '日時',
    txt_global_std_totalistheleaderboardforthechallenge: '全期間の総合ランキングです',
    txt_global_std_updatetext: 'テキストを更新',
    txt_global_std_total: '全期間',
    txt_global_std_SulayemFacts: 'He has competed in African, European, Middle East and World Championship Rallies. He won one of his titles while recovering from a broken neck',
    txt_global_std_congratulationsyouhaveverifiedyouremailyouwillberedirectedshortly: 'おめでとうございます。メールを確認しました。間もなくリダイレクトされます',
    txt_global_std_PSolbergQuote: 'お見積りは間もなく…',
    txt_global_std_tryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagainnodot: '車を取り外してからもう一度追加して、スマートフォンへの車の接続をリセットしてみてください',
    txt_global_std_cimage25h: '最適な回転数',
    txt_global_std_p2pbookingcancelled: '予約がキャンセルされました',
    txt_global_std_drivingyieldtotalamount: '運転歩留まり（合計額）',
    'txt_global_std_drivingtimeabove{speed}': '{speed}以上の運転時間',
    txt_global_std_saveanduploadreport: 'レポートを保存してアップロードする',
    txt_global_std_pleasechooseaninsurance: '保険を選択してください',
    txt_global_std_techsupport: 'テクニカルサポート',
    txt_global_std_p2piacceptthe: '以下規約に同意します。',
    txt_global_std_safedriverweekly: '安全運転\nボーナス',
    txt_global_std_pleasemakesurethatyouareconnectedtoyourcar: '車に接続していることを確認して、もう一度お試しください。',
    'txt_global_std_{count}points': '{count} ポイント',
    txt_global_std_bestoptionifyoudonthavebt: '車にBluetooth接続がない場合は、これが最適なオプションです。',
    txt_global_std_planaheadbeprepared: '事前に計画し、準備する',
    txt_global_std_top: 'トップ',
    txt_global_std_howdoidrivewell: '運転を改善するためにはどうしたらいいですか?',
    txt_global_std_fellowdriver: '仲間のドライバー',
    txt_global_std_apopupintheappwillaskyoutoconnect: 'A popup in the app will ask you to connect (May take upto 2 minutes)',
    txt_global_std_qhowdoiterminatemyinsurance: 'Q：保険を解約するにはどうすればよいですか？',
    txt_global_std_incorrectlyformattedcsvfilemakesuretofollowtheexactformatofthetemplate: '.csv ファイルの形式が正しくありません。テンプレートの正確な形式に従っていることを確認してください。',
    txt_global_std_startaddress: '開始地点の住所',
    txt_global_std_collectablecategory: 'コレクタブル',
    txt_global_std_andallowitwevalueyourprivacy: ' プライバシー保護のため、この情報を第三者に共有することはありません。',
    txt_global_std_9digitactivationcode: '携帯電話とメールに送信された9桁のアクティベーションコードを入力します。',
    txt_global_std_linktopolicies: 'ポリシーへのリンク',
    txt_global_std_couldnotchangeyourpassword: 'Could not change your password!',
    txt_global_std_december: '12月',
    txt_global_std_SignupFia_Text9: '#FIASmartDrivingChallenge',
    txt_global_std_5000pointaverageachievementlocked2: '1週間平均{scoretype} of {score}以上の{scoretype} of {score}運転し、この実績を達成して5000ポイントを獲得します。',
    txt_global_std_nicedrive: '素敵なドライブ！',
    txt_global_std_uniquecategory: '個性的',
    txt_global_std_appName: '{appName}',
    txt_global_std_howdoiearnaloyaltystatus: 'ロイヤリティステータスを獲得するにはどうすればよいですか？',
    txt_global_std_p2pcontinueandpay: '続行して支払う',
    txt_global_std_planahead: '予測しながら運転する​',
    txt_global_std_p2ppushtitleaddedexpences: '費用が追加されました',
    txt_global_std_competeagainsteachotherwintogether: '互いに競争する-一緒に勝つ',
    txt_global_std_forbothyourphoneandcar: 'あなたの電話と車の両方のために。',
    txt_global_std_nodatatoshow: '表示するデータがありません',
    txt_global_std_HansenDescription: 'Kevin Hansen, the youngest ever FIA European Supercar champion and 2016 FIA Rookie of the year started his career at only five years of age in karting. Progressing through the classes of karting, Kevin switched to RX in 2012 and quickly showed he was there to succeed. Winning several championships during 2013-2015, Kevin reached his dream in 2016 by winning EuroRX. Since 2017, Kevin has been a World Rallycross regular and has finished 8th twice in the World Championship, and in 2018 he became a factory driver of Peugeot at the age of 19!',
    txt_global_std_p2pyouborrowed: 'あなたは借りました',
    txt_global_std_manual: 'マニュアル',
    txt_global_std_resetpasswordwrongpasswordformat: 'パスワードの形式が間違っています。最小8文字、少なくとも1つの大文字、1つの小文字、および1つの数字。',
    txt_global_std_fileaclaim: 'レポートする',
    txt_global_std_drivercppr: 'ドライバーの事故パターンに対する、プロファイリングレポート',
    txt_global_std_membernumber: '会員番号',
    txt_global_std_amazingdriverunlockedkey: 'あなたは素晴らしい{user}です！今日は{score}を超えるスコアを獲得しました。',
    txt_global_std_openinghoursinsurance: '8～20時（平日）、10～18時（土日）',
    txt_global_std_howdoicompeteinlexuschallenge: 'レクサスチャレンジに参加するにはどうすればよいですか？',
    txt_global_std_p2perrorcodebookingmaynotbeearlierthannow: '有効な日付・時間帯を入力してください。',
    txt_global_std_p2paddedcaraslendable: '貸し出し可能な自動車を追加しました。',
    txt_global_std_activateitnow: '今すぐアクティブ化',
    txt_global_std_choosetypeofapp: 'アプリの種類を選択してください',
    txt_global_std_challengeafriend3: '新しい友だちを招待する',
    txt_global_std_f_lanzdescriptionshort: '南アフリカで最高の女性レーシングドライバー。彼女はダンスが大好きで、野生のネイチャーエクスプローラー',
    txt_global_std_carbondioxide: '二酸化炭素は、乾燥空気より密度が60％高い無色の気体です。1つの炭素原子に2つの酸素原子が結合したもので、微量気体として地球の大気に自然に存在します。',
    txt_global_std_p2pfaq19a: 'Android携帯をご使用の場合、「電池を最適化する」機能がオンになっていると、走行データが記録されない場合があります。\n' +
      '1.\tご使用のスマートフォンの「設定」より、「アプリと通知」を選択。\n' +
      '2.\tアプリと通知の詳細設定を選択し、「特別なアプリアクセス」を選択します。\n' +
      '3.\t特別なアプリアクセスより、「電気の最適化」を選択します。\n' +
      '4.\tプルダウンより「すべてのアプリ」を選択し、「わりかんKINTO」を選択します。\n' +
      '5.\tダイアログより「最適化しない」を選択します。\n',
    txt_global_std_add: '追加',
    txt_global_std_documentyourcar: 'あなたの車を記録する',
    txt_global_std_schema: 'スキーマ',
    txt_global_std_visualeditor: 'ビジュアルエディター',
    txt_global_std_estimatedsurcharge: '保険料の割引率（推定）',
    txt_global_std_plugindevice: 'Plug in device',
    txt_global_std_therecouldbeotherreasonswhyyourcarsbluetoothdoesntshowupinthelist: '車のBluetoothがリストに表示されない理由は他にもあります。',
    txt_global_std_silverusers: 'シルバーユーザー',
    txt_global_std_clickhere: 'Click here!',
    txt_global_std_p2pemailifyouremailclientdoesnotacceptbutton: 'お使いのメールソフトでボタンが押せない場合は、こちらのリンクをクリックしてください。',
    txt_global_std_toconnectyourcar: '車両を接続する',
    txt_global_std_nousersfoundtryreloadingthepage: '利用者が見つかりません。ページをリロードしてみてください。',
    txt_global_std_co2: 'CO2',
    txt_global_std_improvementpossibility: '改善の余地',
    txt_global_std_unpublish: '非公開',
    txt_global_std_textmessage: 'SNS',
    txt_global_std_cimage43d: '',
    txt_global_std_set_up_manual_start_stop: 'セットアップ：手動スタート＆ストップ',
    txt_global_std_pwdtenandtwice: 'パスワードは10文字以上で、2回入力してください！',
    txt_global_std_cimage30h: '加速は計画的に',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint3: 'リアルタイムの地理的リスクエクスポージャーに関する正確な洞察を得る',
    txt_global_std_zipdrivingriskindex: 'Zip-Driving リスク インデックス',
    txt_global_std_p2pfaq18q: 'アプリをクルマに接続して、走行データを記録する方法を教えてください。',
    txt_global_std_maxfilesize: '最大ファイルサイズ',
    txt_global_std_togetmorefriendssuggestionsyoucanloadyourfriendsfromfacebook: 'Facebookから友だちをロードするとアドバイスをもらえます。',
    txt_global_std_youcanemailthiscertificatetogetdiscountswithdifferentvendors: '認定証をメールで送ることで、割引などの特典を受けることができます。',
    txt_global_std_toprovideyouwithaccuratestatsanddrivingscoreappnameneedstoruninbackgroundmode: '正確な統計と運転スコアを提供するには、{appname}をバックグラウンドモードで実行する必要があります。',
    txt_global_std_carwash: '洗車',
    txt_global_std_ABC: '症状の確認',
    txt_global_std_whoisthesmartestdriver: '最も賢いドライバーは誰ですか？',
    txt_global_std_enerfyanalytics_upsell_bulletpoint1: 'リスクレベルを決定する要因を理解する',
    txt_global_std_api: 'API',
    txt_global_std_adgangtilmotionogfitnessdata: 'Adgang til motion og Fitnessdata',
    txt_global_std_p2ptotaltopaytoph: '{user}に支払う合計',
    txt_global_std_ConfirmFia_Text71: '万が一参加できない場合は、以下のリンクより予約をキャンセルしてください。',
    txt_global_std_electriccarorobd: '電気自動車またはOBD？',
    'txt_global_std_Al-KuwariMileage': 'I like to drive long distances I find myself in long distances.',
    txt_global_std_oliversolbergShortDescription: 'なし、',
    txt_global_std_p2pwaitingfor: '待機中',
    txt_global_std_iwanttoreceiveinformationandupdatesviaemail: 'メールで情報や最新情報を受け取りたい。',
    txt_global_std_fullname: 'フルネーム',
    txt_global_std_3rd: '3番',
    txt_global_std_p2ppushbodyhasdeniedthebooking: '{start}からの予約がキャンセルされました',
    txt_global_std_OSolbergChamp: 'Coming soon...',
    txt_global_std_referfriendsgetrewards: '友達を紹介します。報酬を取得します。',
    txt_global_std_p2ppushtitlesetpriceforbookingto: '予約が確定しました。',
    txt_global_std_pleasewait: 'お待ちください',
    txt_global_std_joinevent: 'イベントに参加',
    txt_global_std_points1: 'ポイント',
    txt_global_std_pair: 'ペアリングする',
    txt_global_std_demotext2: '運転が上手なほど、数値は低くなります。レベルを確認するにはこちら。',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriend: '毎日のドライブで友だちと競う。',
    txt_global_all_thursday: '木曜日,',
    txt_global_std_youneedtoselectatype2: 'タイプを選択する必要があります',
    txt_global_std_gallonimp: 'ガロン',
    txt_global_std_socialbutterflyunlockedkey: '10人以上の友だちを紹介しました！Kintoウォレットに300ポイント付与されました。',
    txt_global_std_findaservicecenter: 'サービスセンターを探す',
    txt_global_std_pickyourteamleader2: 'チームリーダーを選ぶ',
    txt_global_std_401unauthorized: '401 - 無許可',
    txt_global_std_smssent: 'SMS送信完了！',
    txt_global_std_p2psplittingthecostforyourkintosubscribtionwithyourfriendsiseasy: 'Kintoサブスクリプションの費用を友達と分割するのは簡単です！',
    txt_global_std_iveforgottenmypassword: '私は自分のパスワードを忘れました',
    txt_global_std_OSolbergBesttip: 'Coming soon...',
    txt_global_std_connectedtoobd: 'OBDに接続',
    txt_global_std_emailorusername: 'Email or username',
    txt_global_std_documentsandpolicies: 'ドキュメントとポリシー',
    txt_global_std_sendreport2: 'レポートを送信する',
    txt_global_std_skillslevel7d: 'スキルレベル 7D',
    txt_global_std_login2: 'ログイン',
    txt_global_std_MSIG_Onboarding8: '2. Follow the steps in the app.',
    txt_global_std_privacysettingssaved: 'プライバシー設定保存',
    'txt_global_std_Al-KhelaifiDescription': 'Abdulla participates in most motorsport events in Qatar and has several championships in different types of racing like National Drag, Qatar Mile, National Sprint, and QTCC. ',
    txt_global_std_temporarypassword: '仮パスワード',
    txt_global_std_clickthelinkbelowtoconfirmemail: '以下のリンクをクリックしてメールを確認し、EnerfyGlobalアカウントの作成を完了してください。',
    txt_global_std_lendmycar: '車を貸して',
    txt_global_std_ifyourcardoesnthavebluetooth: '車にBluetoothがありません。',
    txt_global_std_tripanalytics: '運転分析',
    txt_global_std_getstartedfolks: '始めましょう！',
    txt_global_std_takecareofinjury: '手当てをする',
    txt_global_std_drivenkmperyear: '年間走行キロ',
    txt_global_std_sos: '救援',
    txt_global_std_showmodal: 'Show Modal,　モーダルウインドウを表示する',
    txt_global_std_hiheresmylocation: 'ここにいます',
    txt_global_std_pushnotdrivenforesometimebody2: '次回運転するときは必ず接続してください。',
    txt_global_std_p2pgotocarsharing: 'カーシェアリングに進む。',
    txt_global_std_keeponpracticingandyoucangetabetterprice: '練習を続けてください、そうすればあなたはあなたの保険でより良い価格を得ることができます。',
    txt_global_std_distancekm2: '距離/km',
    txt_global_std_dontallow: '許可しない',
    txt_global_std_insurancecontactdetails: '保険のお問い合わせ',
    txt_global_std_agreementnumber: '契約番号',
    txt_global_std_warikan_onboarding7: '3. 以下の情報を入力してアカウントを作成',
    txt_global_std_dennetur: 'Dennetur。',
    txt_global_std_successfullypublishedlanguage: '正常に公開された言語',
    txt_global_std_registeryouraccount: 'アカウントを登録する',
    txt_global_std_countryroadroundaboutDESC: '赤い車のように、高速で交差点に入ってしまっています。青い車のように、適切に速度調整できると理想的です',
    txt_global_std_p2ppushtitletimetosplitcost: '今月のわりかんをお忘れなく',
    txt_global_std_period: '期間',
    txt_global_std_MSIG_Onboarding11: 'Drive safetly',
    txt_global_std_totaldistance: '走行距離',
    txt_global_std_usersuccessfullyremoved: 'ユーザーが正常に削除されました',
    txt_global_std_eventualinsurancebonus: 'Eventual insurance bonus\n',
    txt_global_std_activatebluetoothandconnect: '3. BluetoothをオンにしてOBDリーダーを接続してください',
    txt_global_std_locationservicetroubleshooting: '位置情報サービストラブルシューティング',
    txt_global_std_vehicleidentification: '車両識別',
    txt_global_std_dsadsadsadsa: 'dadsasd',
    txt_global_std_phonenumber: '電話番号',
    txt_global_std_ayessompoacceptsdriverswhoarelicensedinothercountries: 'A：はい、損保は他の国で認可されたドライバーを受け入れます。',
    txt_global_std_somethingwentwrongwhenattemptingtoredeempointspleasetryagainlater: 'ポイント交換の際に何らかの問題が発生しました。あとでもう一度お試しください。',
    txt_global_std_1startyourcarsengine: '1.エンジンをスタートさせます',
    txt_global_std_moreoptions: 'オプションをもっと見る',
    txt_global_std_minutem: '分',
    txt_global_std_learnmorehere: '詳細はこちら',
    txt_global_std_iaccepttheusertermslinkandconditionsprivacypolicylink: '{userTermsLinkJapanese}{andSign}{privacyPolicyLinkJapanese}に同意します。',
    txt_global_std_messagebody: 'メッセージ本文',
    txt_global_std_safedriver: 'セーフドライバー',
    txt_global_std_optionnotavailableyet: 'このオプションは準備中です！',
    txt_global_std_insuredvehicles: '被保険者の車両',
    txt_global_std_VilliersInterests: 'Motor racing, all-terrain racing, circuit racing',
    txt_global_std_reviewenerfy: 'レビュー',
    txt_global_std_downloadqrcode: 'QRコードをダウンロード',
    txt_global_std_nocountryselected: '国が選択されていません',
    txt_global_std_MarklundMileage: 'I love driving the route to home. When you travel a lot, you really appreciate to just be home and the feeling of getting closer and closer is really satisfying. I live in the North of Sweden where the roads are sometimes all empty and where the andscape of lot of woods and wilderness makes me calm and happy. \n',
    txt_global_std_clickheretobuyfiasdc: 'FIA SDCを購入するにはここをクリック',
    txt_global_std_yesyoucanswitchyourinsuranceanytimemostinsurancecompaniesdonothaveanearlyterminationpenaltyyouwillneedtoinquirewithyourcurrentinsurancecarrier: 'はい、いつでも保険を切り替えることができます。ほとんどの保険会社には早期退職ペナルティはありません。現在の保険会社に問い合わせる必要があります。',
    txt_global_std_p2pskip: 'スキップ',
    txt_global_std_primarybluetoothid: 'プライマリBluetoothID',
    txt_global_std_nofinishedchallenges: '過去の対戦はありません',
    txt_global_std_selectcountrycode: '国コードを選択してください',
    txt_global_std_youbooked10timesinonemonthyouhaveearned2500points: '1か月に10回予約しました。あなたは2500ポイントを獲得しました。',
    txt_global_std_typeyourcodefrominsuranceletter: 'Type your code from the insurance welcome letter',
    txt_global_std_wecantfindanydrives: 'ドライブが見つかりません！',
    txt_global_std_watchexplainervideo: '解説動画をご覧ください。',
    txt_global_std_thiskeydoesnothaveatranslationinthislanguage: 'このキーはこの言語に翻訳されていません',
    txt_global_std_documentandpolicyes: 'Documents and policyes',
    txt_global_std_loyaltypoints: 'ポイント',
    txt_global_std_couldnotconnect: '接続できませんでした',
    txt_global_std_countmonths: '{count} か月',
    txt_global_std_closestyou: '最寄りのガソリンスタンド',
    txt_global_std_viewcost: 'コストを表示',
    txt_global_std_theinsurancehasbeenactivated: 'The insurance has been activated.',
    txt_global_std_createnewtextmessage: '新しいテキスト メッセージを作成する',
    txt_global_std_tomasengemusic: 'レースカーのように、運転中の沈黙が大好きなので、車の中で何も聞いていません',
    txt_global_std_cimage5d: '坂を活用しましょう。上り坂はスピードを出さず、下り坂はアクセルを離して燃費よく。',
    txt_global_std_smsclientonboardingpincode: '認証番号：{pincode}\nこの番号をわりかん{companyname}アプリの画面で入力してください。番号の有効期限は1時間です。',
    txt_global_std_remembertodrivesmart: 'いつもスマートドライブで',
    txt_global_std_countryroad4DESC: '赤い車のように、高速でカーブを曲がろうとして急ブレーキを踏んでしまっています。青い車のように、スピードを落としてカーブに入れると理想的です。',
    txt_global_std_p2ppleasecontactsupport: '変更の際にはサポートにお問い合わせください。',
    txt_global_std_MarklundFacts: 'Started racing internationally 2011 and have since then been competing in series as Porsche Carrera Cup Scandinavia, FIA World/European Rallycross Championship and X-Games.',
    txt_global_std_to_make_a_change_in_your_insurance_policy: '保険契約を変更するには、{url}でログインするか、{email}または{phone}でサポートに連絡してください。',
    txt_global_std_spooring: 'スプーリング',
    txt_global_std_inviteapersonyouwantotshareinsurancewith: '保険を共有したい人を招待する。',
    txt_global_std_yourusername: 'ユーザーネーム',
    txt_global_std_superdriver: 'スーパードライバー！',
    txt_global_std__enterthetemporarypasswordsendtoyoubyemail: 'メールで送信された一時パスワードを入力します。',
    txt_global_std_kintofaq3a: 'ポイントを貯めればステータスは上がります！安全運転を心がけるKintoドライバーなら、ポイントの取得方法はいろいろあります。詳細は「成績について」をご確認ください。',
    txt_global_std_usethistemplateasabadgeinyourappcustomizeitinthenextstep: 'このテンプレートをアプリのバッジとして使用します。次のステップでカスタマイズします。',
    txt_global_std_TomczykBesttip: 'Stay focused on your driving style and drive “defensively”. ',
    txt_global_std_writeanewpassword: '新しいパスワードを入力',
    txt_global_std_userhasnogroups: 'ユーザーにはグループがありません',
    txt_global_std_nextqualificationforthegrandfinalindecemberstarts: '12月のグランドファイナルに向けた次の予選が始まります。',
    txt_global_std_paddonQualification: 'パッドン用資格認定文',
    txt_global_std_4th: '4番',
    txt_global_std_p2pchatwithyourfriends: 'お友達とチャット',
    txt_global_std_clickthebuttonbelowtoresetyourenerfyglobalpassword: 'Enerfy Global のパスワードをリセットするには、下のボタンをクリックしてください。',
    txt_global_std_lostconnectionsmall: '続けるにはエンジンをかけてください',
    txt_global_std_enterdetails: '詳細を入力してください',
    txt_global_std_entergender: '性別を入力してください',
    txt_global_std_makesuretheobdreaderisreadytobepaired: 'OBDリーダーがペアリングできることを確認してください。準備ができると青く光ります',
    txt_global_std_yourpolicyshouldcontaineight8numbers: 'ポリシーには8つの数字を含める必要があります。',
    txt_global_std_step2: 'ステップ 2',
    txt_global_std_FiaPassword_Text1: 'こんにちは！',
    txt_global_std_v2score: 'スコア',
    txt_global_std_youraveragescoreis123: 'あなたの平均スコアは',
    txt_global_std_saveinformation: '保存情報',
    txt_global_std_conclusion: '結論',
    txt_global_std_p2pspecifyprice: '予約を承諾する',
    txt_global_std_p2pyouhaveanexpensetoapprove: '承認する費用があります',
    txt_global_std_redirectingyou: '別画面へ移行.',
    txt_global_std_developerpage: '開発者ページ',
    txt_global_std_p2pspecifyegfuel: '具体的に（例: ガソリン）',
    txt_global_std_p2ptotaltopayto: '支払う合計',
    txt_global_std_ayesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforcovercubeinsurance: 'A：はい、アプリをダウンロードして「アプリを試す」オプションを選択できます。アプリで運転して、Covercube保険に加入した場合にどれだけ節約できるかを確認できます。',
    txt_global_std_sendinvitation: '招待する',
    txt_global_std_2makesureyourcarsbluetoothisonorthatyourbluetoothbeaconisconnected: '2.車のBluetoothがオンになっていること、またはBluetoothビーコンが接続されていることを確認します。',
    txt_global_std_p2premovefriend: '友達を削除する',
    txt_global_std_noaccountsignuphere: 'アカウントがありませんか？ここでサインアップ',
    txt_global_std_p2ppayments: '支払い',
    txt_global_std_internalservererror: '内部サーバーエラー',
    txt_global_std_thisyear: '今年',
    txt_global_std_costforspeedover80: '{speed}以上の速度にかかる費用',
    txt_global_std_timeframes: '時間軸',
    txt_global_std_p2ppleaseenteratemporarypassword2: '仮のパスワードを入力してください。',
    txt_global_std_TidemandQuote: "I always enjoy a good challenge, especially a challenge that has a bigger purpose. To drive smart and responsibly is something that concerns us all and by improving our eco-friendly driving in our everyday life, we're investing in our future. I'm all in!",
    txt_global_std_locateyourobdsocket: '1. OBDソケットを設置してください',
    txt_global_std_noconnectiontomapserver: 'マップサーバーに接続できません',
    txt_global_std_hiwelcometoappname: 'やあ！ {appName}へようこそ！',
    txt_global_std_unitskmmi: '単位 ㎞/mi',
    txt_global_std_warikan_onboarding3_2: 'なお、仮パスワードの有効期限は72時間となっております。\n' +
      '超過後は、「わりかんKINTO」アプリの「仮パスワードを再送信」より再度メールを受け取っていただき、手続きをお願いいたします。<br>\n' +
      '※本メールは送信専用です。ご返信いただいてもお応えいたしかねますので、お問い合わせは下記へお願いいたします。',
    txt_global_std_pushnotification: 'プッシュ通知',
    txt_global_std_willigetnotifiedififorgottostart: 'Will I get notified if I forget to start the Enerfy app?',
    txt_global_std_backtolist: ' 一覧に戻る',
    txt_global_std_startdrivingtogetascore: 'ドライブを開始してスコアをゲット！',
    txt_global_std_enerfyanalytics2: 'Enerfy アナリティックス',
    txt_global_std_p2ppushbodyexpensesthismonthquestion: '{monthname}分の追加費用を忘れずに追加してください',
    txt_global_std_totalthismonth: '今月の合計',
    txt_global_std_youcannotsetafuturedate: '未来の日付は設定できません',
    txt_global_std_myprofile: 'プロフィール',
    txt_global_std_achievementsavedmessage: '実績が保存されました！',
    txt_global_std_export2: 'エクスポートする',
    txt_global_std_timelocationaretheonlymandatoryfields: '時間と場所のみが必須フィールドです。',
    txt_global_std_daydreamingormindwanderingnew: '白昼夢またはマインドワンダリング',
    txt_global_std_stardriver: 'スタードライバー',
    txt_global_std_p2phide: '隠す',
    txt_global_std_riskydecisionwithintripsbetweendays: '走行中または数日間の危険な運転判断',
    txt_global_std_connecttoyourcar: '車への接続',
    'txt_global_std_Al-KuwariFacts': 'As a co-driver, Nasser has won 2nd Place in 2016 FIA Cross Country Co-driver, 1st in 2017  and 2018 MERC 2 –co-driver, 9 times Qatar Championships as a co-driver and 4 time champion in Kuwait Championships.',
    txt_global_std_averagescoreofparticipants: '参加者の平均スコア',
    txt_global_std_createaccountlater: '後でアカウントを作成',
    txt_global_std_p2pamount: '料金',
    txt_global_std_KleinschmidtInterests: 'Sports, computer technology, flying',
    txt_global_std_drivendistanceperperiod: '1周期あたりの走行距離',
    txt_global_all_tuesday: '火曜日,',
    txt_global_std_p2pleavethisgroupchatquestion: 'グループを退出しますか？',
    txt_global_std_90days: '90日',
    txt_global_std_co2saver: 'CO₂削減',
    txt_global_std_25km: '25 Km',
    txt_global_std_tipcityparking2DESC: '赤い車のように、他の車が迫っているなか車線変更してしまっています。青い車のように、車間距離を十分とって車線変更できると理想的です。',
    txt_global_std_inviteafriendyouallwillgetareward: '友達を招待すると、全員が報酬を受け取ります',
    txt_global_std_shortcuts: 'ショートカット',
    txt_global_std_toprovideyouwithadrivingscoreandstatsaboutyourdrivingappnameneedsaccesstolocationservices: '{appname}はドライブ情報を適正にトラッキングするため、アプリが使用されていない際にも位置情報を収集します。',
    txt_global_std_poor: 'もう少し',
    txt_global_std_after6monthsyouwillgetarenewalofferbasedonyourdrivinguntilthenyoucankeepimprovingyourdrivingfollowyourprogressandseeyourestimatedfuturesavingshere: '6か月後、運転に基づいて更新オファーが表示されます。それまでは、運転を改善し続けることができます。進捗状況を確認し、ここで将来の節約額を確認してください。',
    txt_global_std_thecruisebeliever: 'クルーズ信者',
    txt_global_std_cimage6d: '追い越しはやめましょう',
    txt_global_std_KleinschmidtShortDescription: 'ダカール・ラリー2001 チャンピオン',
    txt_global_std_senddocumentto2: '書類の送り先',
    txt_global_std_saveunits: '単位を保存',
    txt_global_std_perusermonth: 'ユーザーあたり/月',
    txt_global_std_nameheader: '実績の称号',
    txt_global_std_changeyouraddress: '住所を変更する',
    txt_global_std_translationstoapprove: '承認する翻訳',
    txt_global_std_head2head: 'You have been challenged in Head to Head!',
    txt_global_std_MSIG_Password3: 'is',
    txt_global_std_privateonlyformyorg: 'プライベート（私の組織のみ）',
    txt_global_std_settimeperiodforhowlongyourdatawillbestored: 'データが保存される期間を設定します',
    txt_global_std_404notfound: 'リソースが見つかりません！',
    txt_global_std_ayesyoumustadviseyouragentthatyourvehicleisusedforaridesharingcompanyandtheywillapplytheridesharingendorsementtoyourpolicy: 'A：はい、あなたはあなたの車がライドシェアリング会社に使用されていることをあなたのエージェントに知らせなければなりません、そして彼らはあなたのポリシーにライドシェアリングの承認を適用します。',
    txt_global_std_thelinkwillbeavailableforxdays: 'リンクは {x} 日間有効です。',
    txt_global_std_200ok: '200 - OK',
    txt_global_std_trygdrivehelpsyouwithmotivationandtipstodrivebettersaferandmoreecofriendly: 'Sidekickは、より良く、より安全に、より環境にやさしい運転をするための動機とヒントを提供します。',
    txt_global_std_earnpointsbydrivingsafe: '安全運転でポイントを獲得しましょう。',
    txt_global_std_privacy: 'プライバシー',
    txt_global_std_riskscore: 'リスクスコア',
    txt_global_std_addaphoto: 'Add a photo',
    txt_global_std_p2ppushbodysetpriceforbookingto: '{name} さんが予約を確認しました： {currprice}',
    txt_global_std_easyas123: 'とても簡単',
    txt_global_std_actualtranslation: '実際の翻訳',
    txt_global_std_androidallowallthetime: '運転に関する正確な統計情報を取得​\nするためには、設定で「常に」選択​\nする必要があります。',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint3: '安全性と持続可能性の報告に関する詳細な洞察',
    txt_global_std_heattab: '人種',
    txt_global_std_createnewgroup: '新しいグループを作成する',
    txt_global_std_cimage14d: '下り坂を利用して速度を上げよう。',
    txt_global_std_xkm: '{km} Km',
    txt_global_std_pigsavedlocked: '50クラウンごとに、このアチーブメントを取得します',
    txt_global_std_created: '作成した',
    txt_global_std_TaxInvoice14: 'THIS IS A COMPUTER GENERATED DOCUMENT, NO SIGNATURE IS REQUIRED',
    txt_global_std_requiredformatcolumnastext: '必須、列をテキストとしてフォーマット',
    txt_global_std_therulesforthecompanychallengeareasfollowingbrtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsbrdriveminimum1kmduringaheatinordertoqualifyforthechallengebrthesamegeographicalroutewillbescoredamaximumof10timesinoneday: '会社のチャレンジのルールは次のとおりです。\n' +
      '<br>•個々のドライバーのスコアは、上位10％の旅行に基づいて平均して計算されます。\n' +
      '<br>•チャレンジの資格を得るために、ヒート中に最低1kmをドライブします。\n' +
      '<br>•同じ地理的ルートは、1日に最大10回スコアリングされます。',
    txt_global_std_drivingtogetherismorefun: '一緒に運転するのはもっと楽しい',
    txt_global_std_currentpassword: '現在のパスワード',
    txt_global_std_unavailable: '利用できません',
    'txt_global_std_emailalreadyusedpleasetrywithanotheremail.': '既に使用されているメールアドレスです',
    txt_global_std_loadingpleasewait: '読み込み中。。。待って下さい',
    txt_global_std_availablefromlevel: 'レベルから利用可能',
    txt_global_std_p2ptobesettled: '請求金額',
    txt_global_std_makeyourselection: 'Please make your selection below:',
    txt_global_std_focusfeedbacktips: '<b>道路から目を離さず</b>、しっかり集中して運転してください。周囲、電話、同乗者などに気を取られる場合があります。​<br> <br>\n' +
      '<b>疲れやストレスがある状態で運転すると</b>、注意散漫になり、リスクが高まるおそれがあります。​<br> <br>\n' +
      '<b>安全運転を心がけましょう。</b>',
    txt_global_std_SulayemWhyjoin: 'As a 14 times FIA motorsport champion, I am passionate about driving and motorsports. intrigued by the advances in technology in the motoring industry that we are currently experiencing. We need to embrace these technologies and be a part of the solution to positively impact our environment.',
    txt_global_std_great: 'すばらしい',
    txt_global_std_TidemandBesttip: "To make smart driving as easy as possible - start by never stressing behind the wheel and always keep an eye on what's going on around you and adapt the speed in good time. ",
    txt_global_std_enterpasswordtodeleteuseraccount: 'パスワードを入力してユーザーアカウントを削除します',
    txt_global_std_p2pfromthismonth: '今月の追加費用',
    txt_global_std_dolinkonthedamage: 'ダメージについて{リンク}を行う',
    txt_global_std_rwmnoeligibletripstext: '運転した翌日までに<br>登録を完了して下さい​',
    txt_global_std_yourchangesweresaved: '変更内容を保存しました',
    txt_global_std_pickyourteamleader: 'チームリーダーを選びましょう',
    txt_global_std_mar: '3月',
    txt_global_std_averagemonthsmonths: '平均{月}か月',
    txt_global_std_doesagentacceptdriversthatarenotlicensedincountry: '{agent}は{country}でライセンスされていないドライバーを受け入れますか？',
    txt_global_std_majorDescription: 'Anders enas gurkans hämnd 19',
    txt_global_std_p2pnamesentalendrequesttoname2: '{name}が{name2}に貸し出しリクエストを送信しました',
    txt_global_std_subject: '主題',
    txt_global_std_rank: '評価する',
    txt_global_std_OgierWhyjoin: 'Coming soon...',
    txt_global_std_teamleaderboard: 'チームのスコアボード',
    txt_global_std_hereyoucanseehowmuchyouwouldhavegottenbackifyouhadhadatrygdrivecarinsurancethebonusiscalculatedbasedontheannualinsurancepriceyourkilometersdrivenperyearandthecartypebasedonthenumberplateyouhaveprovidedifyouhavenotstatedacurrentinsurancepricenumberofkilometersperyearandanumberplatethebonusiscalculatedonthebasisofanannualinsurancepriceofdkk5000andanannualmileageof20000km: 'ここでは、TrygDrive自動車保険に加入していた場合にどれだけの利益が得られたかを確認できます。ボーナスは、年間保険価格に基づいて計算されます。あなたが提供したナンバープレートに基づく年と車種。現在の保険価格を記載していない場合は、1キロメートルあたりのキロメートル数。年とナンバープレート、ボーナスはDKK5,000の年間保険価格に基づいて計算されます。年間走行距離は20,000kmです。\n',
    txt_global_std_sendchallenge: 'チャレンジを送信',
    txt_global_std_stats2: '統計',
    txt_global_std_p2pnomessages: 'メッセージはありません。',
    txt_global_std_securetheaccidentsite: '事故現場を確保する',
    txt_global_std_cashback: 'キャッシュバック',
    txt_global_std_selectwhatyouwanttouse: 'Select what you want to use.',
    txt_global_std_p2pwaitingforacceptanceandprice: '承諾待ち',
    txt_global_std_MikkelsenBesttip: 'I always make sure to have plenty of space to the cars in front of me. That always saves you from many situations and allows you to drive more comfortably, smartly and efficiently.',
    txt_global_std_badgetitleplaceholder: 'バッジのタイトル',
    txt_global_std_getsexpenseandextradataofgiventrips: '指定された旅行の費用と追加データを取得します',
    txt_global_std_garminconnected: '接続されたガーミン',
    txt_global_std_fiasdcisbettertogether: 'FIASDCは一緒に優れています',
    txt_global_std_chooseateamleaderthatyouwanttocompeteforwerecommendoneoftheseteamleadersbasedonyourlocationandgoal: '競争したいチームリーダーを選択してください。あなたの場所と目標に基づいて、これらのチームリーダーの1人をお勧めします。',
    txt_global_std_termsconditions: '利用規約',
    txt_global_std_tipcity2DESC: '赤い車のように、コーナーで急ブレーキをかけてしまっています。青い車のように、コーナーに差し掛かる前に徐々に減速できると理想的です。',
    txt_global_std_lighttheme: '軽いテーマ',
    txt_global_std_activationemail: 'アクティベーション電子メール',
    txt_global_std_highcostperkilometer: '距離に対して高コスト.',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimeincreasingspeedunnecessaryharshaccelerationisbadfortheenvironmentandforroadsafety: 'このタイプの旅行では、平均的なエコ チャンピオンは速度を上げるのにより多くの時間を費やしました。不必要な急加速は、環境にも交通安全にも悪影響を及ぼします。',
    txt_global_std_savings: '運転評価',
    txt_global_std_opensettingsandmakesureyourbluetoothison: '設定を開き、Bluetoothがオンになっていることを確認します。',
    txt_global_std_navigatebetweenmodules: 'モジュール間を移動する',
    txt_global_std_fiasdcnewsletter: 'FIA SDC ニュースレター',
    txt_global_std_emailclaims: 'Eメールでのご請求',
    txt_global_std_connectingto: 'への接続',
    txt_global_std_mypoints: 'ポイント残高',
    txt_global_std_pressthedrivebuttontogetstarted: 'ドライブボタンを押して開始します。',
    txt_global_std_makeaclaim: '主張する',
    txt_global_std_carsharestats: 'シェアのデータ',
    txt_global_std_failsenddetails: '詳細を送信できませんでした',
    txt_global_std_getshareablelink: '共有可能なリンクを取得する',
    txt_global_std_imagerecognitioncompleted: '画像認識完了',
    txt_global_std_startdrive2: '運転を始める',
    txt_global_std_cimage7d: '状況が許せば、クルージング速度まですばやく加速しましょう。',
    txt_global_std_unknownracedriverShortDescription: 'なし',
    'txt_global_std_Al-KhelaifiMusic': 'Nothing specific. Calm music on the radio. Nothing distracting.',
    txt_global_std_p2ppushbodyhasacceptedthebooking: '{name}さんが予約を承認しました',
    txt_global_std_teamposition: 'チームの順位',
    txt_global_std_sendallpersonaldatatoyouremail: 'すべての個人データをEメールに送信しますか？',
    txt_global_std_belowyoucanseemoredetailsaboutthistrip: '以下に分析結果を表示します。',
    txt_global_std_applications: 'アプリケーション',
    txt_global_std_examplemessagepush: 'Yamada Hanako からサンプル リクエストを受け取りました。アプリを開いて今すぐ返信しましょう。',
    txt_global_std_topdriversnow: '現在のトップドライバー',
    txt_global_std_nousersfound: '利用者が見つかりません',
    txt_global_std_yourhavebeeninvitedby: 'あなたは招待されました:',
    txt_global_std_p2pgroupchatsettings: 'グループチャットの設定',
    txt_global_std_p2pviewall: 'すべて表示する',
    txt_global_std_MarklundQuote: '見積もりは間もなく…',
    txt_global_std_yourposition: '順位',
    txt_global_std_notethisinformationwillbevisibletoanyonewhodownloadstheapp: '注: この情報は、アプリをダウンロードするすべてのユーザーに表示されます。',
    txt_global_std_numoftrips: '{numoftrips} トリップ',
    txt_global_std_selectyourcarsbluetooth: '車のBluetoothを選択してください',
    txt_global_std_otherinformation: 'その他の情報',
    txt_global_std_wanttojointhechallenge: 'チャレンジに参加しますか？',
    txt_global_std_whenyouarereadytodriveyoucanconnect: '運転する準備ができたら、車に接続して、ここで最初の旅行を開始できます。',
    txt_global_std_both2: '両方',
    txt_global_std_MSIG_Onboarding14: 'Your certificate of insurance is attached and password protected with your NRIC/FIN/UEN in lower case.',
    txt_global_std_ilendthecar: 'I lend the car',
    txt_global_std_applanguage: 'アプリの言語',
    txt_global_std_searchonname: '名前で探す',
    txt_global_std_set: '決定',
    txt_global_std_content: 'コンテンツ',
    txt_global_std_goback: '戻る',
    txt_global_std_to: '至,　',
    txt_global_std_illegalxrule: '違法な単語: {word}',
    txt_global_std_agentofferselectronicfundstransferfromyourcheckingaccountorrecurringcreditcardpaymentfromyourcreditdebitcardyoucansignupforthesepaymentplanswhenyoustartyourpolicyoranytimeduringyourpolicytermbyloggingintoweburl: '{agent}は、当座預金口座からの電子送金、またはクレジットカード/デビットカードからの定期的なクレジットカード支払いを提供します。 {webUrl}にログインすることにより、ポリシーを開始するとき、またはポリシー期間中いつでも、これらの支払いプランにサインアップできます。',
    txt_global_std_resetviewpassword: 'パスワードのリセットと表示',
    txt_global_std_aagentofferselectronicfundstransferfromyourcheckingaccountorrecurringcreditcardpaymentfromyourcreditdebitcardyoucansignupforthesepaymentplanswhenyoustartyourpolicyoranytimeduringyourpolicytermbyloggingintolink: 'A：{agent}は、当座預金口座からの電子送金、またはクレジットカード/デビットカードからの定期的なクレジットカード支払いを提供します。 {link}にログインすることで、ポリシーを開始するとき、またはポリシー期間中いつでも、これらの支払いプランにサインアップできます。',
    txt_global_std_whathappensnow: '今、何が起きた？',
    txt_global_std_iflocationserviceisoffyourperformancegetworse: '位置情報がオフになっていると、成績が下がります。',
    txt_global_std_unlocked: '(ロック解除)',
    txt_global_std_seconds: '秒',
    txt_global_std_anemailhasbeensentwithyournewpassword: 'パスワード再設定用のメールが送信されました。\nメールをご確認ください。',
    txt_global_std_accidentfaq: '事故に関するよくある質問',
    txt_global_std_timeandlocationistheonlymandatoryfield: '時間と場所が唯一の必須フィールドです。 24時間以内にあなたの車の写真をアップロードしてください。',
    txt_global_std_riskportfoliotracker_upsell: 'Enerfy Risk Portfolio Tracker - オペレーショナル エクセレンスへの鍵。',
    txt_global_std_vouchercode: 'バウチャーコード',
    txt_global_std_getstartedguide: 'スタートガイド',
    txt_global_std_OgierChamp: 'Won 6 times the WRC and 1 time the J-WRC',
    txt_global_std_heatsmall: '熱',
    txt_global_std_yourmonthwithtrygdrive: 'TrygDriveで1か月！',
    txt_global_std_getspagedlistoftrips: '旅行のページ付きリストを取得します',
    txt_global_std_p2phasreceivedyoureditrequest: '編集リクエストを受け取りました',
    txt_global_std_startingdrive: 'トリップを開始...',
    txt_global_std_addyourbrandcolors: 'ブランドカラーを追加する',
    txt_global_std_introductionwelcome: 'ようこそ',
    txt_global_std_yourquoteformonthofmonth: '{月}の月の見積もり',
    txt_global_std_parameters: 'パラメーター',
    txt_global_std_openlog: 'ログを開く',
    txt_global_std_kintofaq6q: 'マニュアル通りに進めても接続できませんが、どうすればよいでしょうか？',
    txt_global_std_activeachievements: 'アクティブな成果',
    txt_global_std_dontyouhaveacode: "Don't you have any code?",
    txt_global_std_namemustacceptaninvitationtotheteamfirst: '{name} は、最初にチームへの招待を受け入れる必要があります。',
    txt_global_std_allowaccesstobluetooth: 'Bluetoothへのアクセスを許可する',
    txt_global_std_eco1: 'エコ',
    txt_global_std_p2phasspecifiedaprice: 'リクエストが承諾されました。',
    txt_global_std_share: '共有',
    txt_global_std_p2pfaq23a: '1.\t「メニュー」から「わりかん」を選択します。\n' +
      '2.\t「設定」を選択し、わりかんの方法を選んでください。「利用割合」「利用時間」「均等分割」「走行距離」から選べます。',
    txt_global_std_p2pfaq15a: '1.\t「メニュー」から「わりかん」を選択します。\n' +
      '2.\t「追加費用」を選択します。\n' +
      '3.\t「費用を追加する」を選択します。\n' +
      '4.\tクルマを選択し、費用の種類を選択、料金を入力し、領収書の画像をアップロードし、保存を選択してください。\n' +
      '5.\t追加した費用は「追加費用」ページに反映されます。\n',
    txt_global_std_youmayhavetogotobluetoothsettingforbothyourphoneandcar: 'デバイスと車のBluetooth設定をONにしてください。',
    txt_global_std_ecofriendweeklylockedkey: '環境にやさしい運転をして\nCO₂排出量を{co2value}%削減した​\n運転を1週間続けることが​\nできた際に獲得できるバッジ',
    txt_global_std_thisaccountdoesnothaveapaymentplanaddpaymentplantoaccesspaymentspage: 'このアカウントには支払いプランがありません。支払いプランを追加して、支払いページにアクセスします。',
    txt_global_std_getupandrunninginnotimewithourwhitelabelappsolution: 'ホワイトラベルアプリソリューションを使用して、すぐに起動して実行できます。',
    txt_global_std_PolicySchedule27: 'SGD3,500.00 SECTION I & II SEPARATELY IS IMPOSED ON THOSE DRIVERS WHO ARE BELOW 22 YEARS OLD AND/OR WHO HAVE LESS THAN 2 YEARS OF DRIVING EXPERIENCE.',
    txt_global_std_yesiamsure: 'はい、交換します',
    txt_global_std_youhavebeeninvitedby: 'あなたは次の人から招待されました：',
    txt_global_std_p2pinvoiced: '請求済み',
    txt_global_std_redeemfriendscode: '友だちコード',
    txt_global_std_advancedadmin_upsell_pitch_bulletpoint1: '意思決定を通知するための情報とサポート',
    txt_global_std_drivetime: '運転時間',
    txt_global_std_showalldrives: 'すべてのドライブを表示',
    txt_global_std_drivesafeandpaylessforyourfiat500subscription: '安全に運転し、フィアット500サブスクリプションの支払いを減らします',
    txt_global_std_kintosmsmailtext: 'Kintoアプリでもっと安全に！アプリをダウンロードして、Kintoとともにドライブを始めよう。',
    txt_global_std_photosofyourcarscurrentcondition: '車両の現状写真を準備しておくと、事故などの際に便利です。',
    txt_global_std_logsaved: 'ログを保存',
    txt_global_std_createanaccounttostartyour30dayfreetrialnocreditcardrequired: 'アカウントを作成して、30日間の無料トライアルを開始します。クレジットカードは必要ありません。',
    txt_global_std_p2pfaq6q: 'グループチャットから退出できますか？',
    txt_global_std_p2pfaq17a: 'メンバーとして登録されるには、オーナーから招待リンクを受け取る必要があります。オーナーに招待リンクを送ってもらうよう、メッセージを送りましょう。',
    txt_global_std_ifyouareinneedofroadsideassistanceplease: 'ロードサイドアシスタンスが必要な場合は、',
    txt_global_std_wouldntitbenicetobeabletosaythatasacompanyyoumakenoticabledifferenceinloweringtheimpactvehiclesanddrivinghaveontheworldstartyourownchallengeandmakethatimpacttoday: '会社として、あなたは車と運転が世界に与える影響を減らすことに顕著な違いをもたらすと言えるのは素晴らしいことではないでしょうか？あなた自身の挑戦を始めて、その影響を与えてください-今日！',
    txt_global_std_warikan_onboarding16: '〒450-0002　愛知県名古屋市中村区名駅四丁目8番18号　名古屋三井ビルディング北館１４Ｆ',
    txt_global_std_MarklundBesttip: 'First of all, plan your driving and make sure to have enough time, stress is never a smart way of driving. Keep your eyes up front and be alert on what’s going on ahead of you, maybe someone brakes? Then maybe you have time to lift the throttle instead of instant and hard braking? Be patient and adopt your driving style and speed to circumstances such as traffic and weather conditions. I know it’s hard but it makes you a smarter driver.\n',
    txt_global_std_p2pnocarsavailable: '利用できる車はありません。',
    txt_global_std_selectyourcarinthelistsompo: '3.   &nbsp;&nbsp;ビーコンを下の一覧から選択します',
    txt_global_std_passwordchangefailed: 'パスワード変更に失敗しました',
    txt_global_std_ihaveanaccount: 'アカウントがある',
    txt_global_std_notnowlater: '後で',
    txt_global_std_yourpasswordshouldcontainatleast5charactersandmustbeenteredcorrectlytwice: 'パスワードは5文字以上です。正確に2回入力してください。',
    txt_global_std_rwmnodrivestext: 'この機能を使うには、事前に​\n車と接続し、友だちとライド​\nシェアする必要があります。​\nまた友だちとライド​シェア\nした後に登録して下さい。',
    txt_global_std_gooddriving2: '平均的な運転です',
    txt_global_std_p2ptoconfirmbooking: 'さん 予約を確定',
    txt_global_std_createcommunicationthatreachesout: '手を差し伸べるコミュニケーションを作成する',
    txt_global_std_signedinasusername: '{userName}\nとしてサインイン',
    txt_global_std_connectyourobdreader: 'OBDリーダーを車に接続してください',
    txt_global_std_documentanyscratchesordingsonyourcar: '車両のキズやへこみについて報告する',
    txt_global_std_OgierBesttip: 'Coming soon...',
    txt_global_std_calmdriversoftengetbetterscore: '落ち着いたドライバーはスコアが良いことが多いです',
    txt_global_std_thepasswordcontainsforbiddencharacters6062orspace: "パスワードには禁止文字が含まれています：'＆/＆＃60; ＆＃62; 、。 =またはスペース",
    txt_global_std_owner2: '所有者',
    txt_global_std_theapikeynamecreatedcreationdatewillbedeletedimmediatelyandpermanentlyoncedeleteditcannolongerbeusertomakeapirequests: '{creationdate} に作成された API キー {name} は、ただちに完全に削除されます。削除すると、ユーザーは API リクエストを行うことができなくなります。',
    txt_global_std_byconnectingtoyourcarwithyoursmartphone: 'By connecting to your car with your smartphone and the Enerfy app, your drive will be analyzed by our AI database, where your drive will be compared with more than 500 million driving profiles which will validate how safe and environmentally friendly you drive.',
    txt_global_std_publishapp: 'アプリを公開する',
    txt_global_std_emergencyroadsideassistance: '緊急通報',
    txt_global_std_avaragescoreofparticipants: '参加者の平均点',
    txt_global_std_invite: '招待する',
    txt_global_std_PolicySchedule18: 'PDP And SLEC',
    txt_global_std_ensammenligningmeddennekreturogetgennemsnitafalleandrebilisterskrselsadfrd: 'Ensammenligningmeddennekøreturogetgennemsnitaf alleandrebilisterskørselsadfærd。',
    txt_global_std_mine: '私の',
    txt_global_std_wehavesentanewverificationcodetophoneno: '新しい確認コードを{phoneNo}に送信しました。',
    txt_global_std_youneedtoacceptallofthepermissionsinoderfortheapp: 'アプリが適切に機能するためには、すべての許可を受け入れる必要があります',
    txt_global_std_earngreatrewards: '-素晴らしい報酬を獲得しましょう！',
    txt_global_std_currentstatsper: 'ランキング',
    txt_global_std_signupletter: 'リストに掲載！',
    txt_global_std_joinedparticipants: '参加者',
    txt_global_std_all: '全て',
    txt_global_std_ttest96: 'これはテストです',
    txt_global_std_earn100pointswhenyoupurchasecdw5timestoyourbooking: '予約時にCDWを5回購入すると100ポイントを獲得できます',
    txt_global_std_anticipate: '予測運転',
    txt_global_std_p2pyouhavebookingswithoutregistereddistance: '走行データが登録されていない予約があります。',
    txt_global_std_ifyourecruitafriendbothyouandyourfriendget250kronerinthepiggybank: '友達を募集すると、あなたと友達の両方が貯金箱で250クローネを獲得します。',
    txt_global_std_PolicySchedule16: '2, 25, 57, 72, 89C, E28, E29, E33, E45, E48J, E6',
    txt_global_std_p2pcancelbooking: '予約を<br> キャンセル',
    txt_global_std_upload123: 'アップロードする',
    txt_global_std_qhowdoisignupforagentinsurance: 'Q：{agent}保険に加入するにはどうすればよいですか？',
    txt_global_std_country: '国名',
    txt_global_std_p2pdiscount: '割引',
    txt_global_std_yourpassword: 'パスワード',
    txt_global_std_timeandplace2: '時間と場所',
    txt_global_std_starburtslockedkey: '4.4レベル以上でドライブすると、Kintoウォレットに300ポイント付与されます。',
    txt_global_std_MSIG_InsuranceLetter39: 'NIL FOR INSURED AND AUTHORISED DRIVERS',
    txt_global_std_machinedamage123: '機械損傷',
    txt_global_std_heresyourauthenticationcode: '認証コードはこちら',
    txt_global_std_editpermissionsfor: 'の編集権限',
    txt_global_std_fromthetoprightappsiconyoucannavigatebetweendifferentmoduleswithintheworkspace: '右上のアプリアイコンから、ワークスペース内のさまざまなモジュール間を移動できます',
    txt_global_std_therulesforthecompanychallengeareasfollowingtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsdriveminimum1kmduringaheatinordertoqualifyforthechallengethesamegeographicalroutewillbescoredamaximumof10timesinoneday: '会社のチャレンジのルールは次のとおりです。\n' +
      '•個々のドライバーのスコアは、上位10％の旅行に基づいて平均して計算されます。\n' +
      '•チャレンジの資格を得るために、ヒート中に最低1kmをドライブします。\n' +
      '•同じ地理的ルートは、1日に最大10回スコアリングされます。',
    txt_global_std_somethingwhenwrongwithyourpasswordpleasetryanotherpassword: 'パスワードがエラーです。別のパスワードでお試しください。',
    txt_global_std_maintainingintendedspeedisutmostimportantforfueleconomymindwanderingandlackoffocusoftencausesspeedvariationupanddowncausingunnecessaryaccelerationshenceincreasesfuelconsumption: '意図した速度を維持することは、燃費にとって最も重要です。頭のふらつきや集中力の欠如は、速度の上下変動を引き起こし、不必要な加速を引き起こし、燃料消費を増加させます。',
    txt_global_std_singleaccident2: '単発事故',
    txt_global_std_reportandtrackyourresults: '結果を報告および追跡する',
    txt_global_std_MarklundInterests: 'Snowmobiling, Nature/wildlife, Family and friends',
    txt_global_std_theactivationcodehastobe36characters: 'アクティベーションコードは36文字以内です',
    'txt_global_std_Al-KhelaifiMileage': 'Umm Bab to Dukhan Highway (Route 39). Because of the elevation changes and the scenery.',
    txt_global_std_p2peditbooking: '予約の編集',
    txt_global_std_failedtogettranslations: '翻訳を取得できませんでした',
    txt_global_std_cost2: 'コスト',
    txt_global_std_youwillbemailedyourpersonaldata: '個人データはメールでお送りします',
    txt_global_std_hideadvancedsettings: '詳細設定を非表示',
    txt_global_std_publishtranslations: '翻訳を公開する',
    txt_global_std_kilometerkm: 'キロメートル（km）',
    txt_global_std_smartdriverweeklyunlockedkey: 'このバッジを獲得するには、1 週間 3.5 以上のスコアを維持してください',
    txt_global_std_ttest95: 'これはテストです',
    txt_global_std_HusseinAbdullaInterests: 'Cars, Rallies, Racing',
    txt_global_std_chataboutabookingorstartagroupchatwithyourinvitedfriends: '予約についてチャットするか、招待された友達とグループチャットを開始します。',
    txt_global_std_unregistereddriving: '未登録のドライブ',
    txt_global_std_ConfirmFia_Text5: '場所',
    txt_global_std_february: '2月',
    txt_global_std_OrderConfirmation: 'FIA Smart Driving Challenge注文確認',
    txt_global_std_thereisnotworkshopstoshow: '表示するワークショップはありません。',
    txt_global_std_PolicySchedule31: 'An excess of $100.00 is applicable for every windscreen claim, after which, the windscreen cover will be automatically reinstated.',
    txt_global_std_noofdrivers: 'ドライバー数',
    txt_global_std_jointheglobalcommunityreducingco2emission: 'CO2排出量を削減するグローバルコミュニティに参加してください。',
    txt_global_std_accentcolor: 'アクセントの色',
    txt_global_std_enteredodometervalue: '入力済みの走行距離',
    txt_global_std_minutes: '分',
    txt_global_std_youralreadyboundeddevices: '使用しているデバイス',
    txt_global_std_permissions: '許可',
    txt_global_CoreTemplate_cost: 'コスト',
    txt_global_std_300pointTripAchievementlocked: '{scoretype} {score}以上でドライブを終了し、この実績をアンロックして300ポイントを獲得してください。',
    txt_global_std_everythingworkedasexpected: 'すべてが期待どおりに機能しました。',
    txt_global_std_about: '約',
    txt_global_std_yourfriends: '友だち',
    txt_global_std_ontheleaderboardpageyoucamseechallengesoverview: 'リーダーボードページで、課題の概要を確認できます。',
    txt_global_std_thetotalscoremeasuringbothyourrisklevelandecodriving: 'リスクレベルとエコドライブの両方を測定する合計スコア。',
    txt_global_std_schedulesend: 'スケジュール送信',
    txt_global_std_typeofaccident: '事故の種類',
    txt_global_std_upto6000kmmilage: '最大6000キロ。',
    txt_global_std_p2ptogetaninvitationlinkfromakintoownerifyouarehavingtroublewiththelinkpleasecontactsupportandwewillhelpyou: 'キントカーを借りるには、キントオーナーから招待状のリンクをもらう必要があります。リンクに問題がある場合は、サポートにご連絡ください。サポートさせていただきます',
    txt_global_std_decideyourownview: 'ビューを選ぶ',
    txt_global_std_youmustwriteareason: '理由を記入してください。',
    txt_global_std_passwordisnotvalid: 'パスワードが無効です。',
    txt_global_std_imageuploadfailed: '画像をアップロードできませんでした。もう一度お試しください。',
    txt_global_std_achievementsdesc: 'アプリで実績を上げてユーザーを引き付けます。アチーブメントはアプリ内のバッジであり、ユニークまたは収集品にすることができます。',
    txt_global_std_notification: '通知',
    txt_global_std_p2pyouhaveselectedmultipledays: '複数の日付を選択しました。',
    txt_global_std_silver: 'シルバー',
    txt_global_std_speedareawithbest: '最も改善が見込まれる速度範囲',
    txt_global_std_p2pinvitenewfriend: '新しい友だちを招待する',
    txt_global_std_declined: '定価',
    txt_global_std_redeemedpoints: '交換ポイント',
    txt_global_std_usingtheapp: 'アプリの使用',
    txt_global_std_findworkshop: 'ワークショップを探す',
    txt_global_std_Fia_Receipt_Text7: 'Order ID',
    txt_global_std_kintofaq2a: '安全なドライバーは注意深い、ということです。運転と周囲の状況に100%集中しましょう。交通の流れや雨などの状況を考慮して、注意深く運転してください。また、速度は状況に合わせて調整してください。',
    txt_global_std_errorloggingintoyouraccountpleasetryagain: 'アカウントへのログイン エラーです。もう一度お試しください。',
    txt_global_std_qidriveforaridesharingcompanywillyouinsuremyvehicle: 'Q：私は相乗り会社のために運転します、あなたは私の車に保険をかけますか？',
    txt_global_std_yourscore: 'スコア',
    txt_global_std_leavewithoutsaving: '保存せずに残す',
    txt_global_std_PolicySchedule32: 'Signed for and on behalf of the Company',
    txt_global_std_pushdriveendlevel4: 'Now you are driving risky. Tap here to see what you can improve.',
    txt_global_std_p2pallcars: 'すべてのクルマ',
    txt_global_std_gold: 'ゴールド',
    'txt_global_std_Compare with my': '私のxxxと比較する',
    txt_global_std_notdrivingthiscar: 'この車を運転していませんか？',
    txt_global_std_checkhowitwent: 'どうだったかチェック',
    txt_global_std_5discountonrenewal: '更新の5％割引',
    txt_global_std_fix: '修正',
    txt_global_std_cantfindyourcargoto: '車が見つかりませんか？',
    txt_global_std_adduserstogroups: 'グループにユーザーを追加する',
    txt_global_std_wereyoudriving: '運転していましたか？',
    txt_global_std_viewinvoice: '請求書を確認する',
    txt_global_std_paidaftertheendoftheinsuranceyear: '保険年度終了後に支払われます。',
    txt_global_std_forfurtherinstructionspleasechooseyourconnectionmethod: '詳細な手順については、接続方法を選択してください',
    txt_global_std_details: '詳細',
    txt_global_std_notsigneduptryg: 'まだサイドキック保険に加入していませんか？',
    txt_global_std_p2pyescancelbooking: 'はい、予約をキャンセルします。',
    txt_global_std_mmyy: 'MM/YY',
    txt_global_std_orderednewpassword: '注文した新しいパスワード',
    txt_global_std_kintofaq1a: 'アプリの起動は必要ございません。初回の設定以降は、Bluetoothならびに位置情報を有効にしていただければ、バックグラウンドで自動動作します',
    txt_global_std_translations: '翻訳',
    txt_global_std_v2stargrade: 'レベル',
    txt_global_std_poins: 'ポイント',
    txt_global_std_usethiskeyinyourapplicationby: 'このキーをアプリケーションで次のように使用します。',
    txt_global_std_gooddrivingperformance: '優良運転',
    txt_global_std_driveforthefuture: '未来へのドライブ',
    txt_global_std_weareinareyou: '私たちは入っています-あなたですか？',
    txt_global_std_fleetcompanydescription: 'ドライバーのリスク管理とCO2への影響。',
    txt_global_std_wasthecartowed2: '車両をけん引しましたか？',
    txt_global_std_insuranceusage: 'ご利用になった保険',
    txt_global_std_yourpolicybeginsonthedateandtimewhenyouelectronicallysigntheapplicationandmakethedownpaymentonyourpolicy: 'ポリシーは、アプリケーションに電子的に署名し、ポリシーの頭金を支払う日時から始まります。',
    txt_global_std_areyousureyouwanttoendyourcurrentrun: 'トリップを終了してよろしいですか？',
    txt_global_std_ohno: '高い​',
    txt_global_std_keeptrackhowmanykilometersyouhavedriven: '走行したキロメートル数を追跡します',
    txt_global_std_nextloayaltylevelprogressbar: '次のロイヤルティーレベルまでの進捗度',
    txt_global_std_VilliersMusic: 'When I drive, I listen to any good music, I am not too fussy about music, to be honest.',
    txt_global_std_garminwatchconnected: 'ガーミンウォッチが接続されました',
    txt_global_std_drivesafeandecofriendlyearngreatrewards: '安全で環境に優しい運転で\n報酬を獲得しましょう！',
    txt_global_std_forquestionscallagentatphonenumberphoneno: '質問がある場合は、電話番号{phoneNo}で{agent}に電話してください',
    txt_global_std_p2ppushtitlenewexpensetoapprove: '費用が追加されました',
    txt_global_std_unlockeddescription: 'バッジを受け取ったときのロック解除',
    txt_global_std_totalscore2: 'スコア',
    txt_global_std_insuranceinformation: '保険情報',
    txt_global_std_doihavetoactivatetheappeverytimeidrive: '運転するたびにアプリを起動する必要がありますか?',
    txt_global_std_NewPW_Hello: 'こんにちは',
    txt_global_std_namecannotbetoolong: '名前は長すぎてはいけません',
    txt_global_std_recruitafriend: '友だちを募集する',
    txt_global_std_filters: 'フィルタ',
    txt_global_std_yourinvitationwassuccessfullysent: '招待が送信されました！',
    txt_global_std_currentlynotopdrivers: '現在、トップドライバーはいません',
    txt_global_std_MSIG_InsuranceLetter3: 'Motor Vehicles (Third-Party Risks and Compensation) Act (Chapter 189)',
    txt_global_std_finishedhead2head: '完成したヘッド2ヘッドチャレンジ',
    txt_global_std_warikan_onboarding1: 'わりかんKINTOご利用者様',
    txt_global_std_p2pwecouldnotverifyyourkintonumberpleaselogintoyourkintoaccountandclickonthelinktodownloadthewarikanapp: 'キント番号を確認できませんでした。 Kintoアカウントにログインし、リンクをクリックしてWarikanアプリをダウンロードしてください',
    txt_global_std_topdriversthishour: '現在のトップドライバー',
    txt_global_std_pushinsurancerenewalodoremtitle: '{appname}更新',
    txt_global_std_score: 'スコア',
    txt_global_std_yougetthiswhenyouhavescoredover4point410times: 'これは、4.4を10回以上超えると得られます。',
    txt_global_std_myaccount: 'マイアカウント',
    txt_global_std_anemailaddressisrequired: 'メールアドレスは必須です',
    txt_global_std_howdoigetagoodscore: 'スコアを上げるには？',
    txt_global_std_p2pmarkaspaid: '支払いを完了とする。',
    txt_global_std_SulayemInterests: 'Racing, Motorsports, Classic cars',
    txt_global_std_reportclaim: 'クレームの報告',
    txt_global_std_thismonth: '今月',
    txt_global_std_totalamountofaccountscreated: '作成されたアカウントの合計量',
    txt_global_std_myleaguetext: '{league} で走行中です。スマートドライブでランクインをめざせ！',
    txt_global_std_atthechallengedashboardyoucanviewyourchallengesandcreatenewonestocompeteindrivingsafeandecofriendly: 'チャレンジダッシュボードでは、チャレンジを表示し、新しいチャレンジを作成して、安全で環境に優しい運転を競うことができます。',
    txt_global_std_donthaveanaccountsignupsignuplink: 'アカウントをお持ちではありませんか？サインアップ{signuplink}',
    txt_global_std__login: 'ログイン',
    txt_global_std_thisactionwillremoveyouraccount: 'アカウントと個人データが削除されるため、実行できません！',
    txt_global_std_howdoiearnaloyaltystatusanswer: 'ポイントを集めることでロイヤリティステータスを獲得することができます。ポイン<br>ト・実績は安全運転が認められるドライバーに与えられます。詳しくは「実績セレク<br>ション」をご覧ください。',
    txt_global_std_MSIG_Password4: 'Sincerely, MS First Capital',
    txt_global_std_translationtoolvisualeditor: '翻訳ツール - ビジュアル エディター',
    txt_global_std_redeemyourrewards: '特典を利用する',
    txt_global_std_nocode: 'No code? Click here',
    txt_global_std_f_lanzmileage: '200kmのネイチャートリップ。ここ南アフリカでは、街をすぐに脱出し、風光明媚なNtureDriveを楽しむことができます。非常にオープンな空間と心は集中して処理し、同時にリラックスすることができます。',
    txt_global_std_pushdriveendlevel2: 'Very good drive. If you want to see more info, please tap here.',
    txt_global_std_youredone: '完了です！',
    txt_global_std_numberno: 'No.、',
    txt_global_std_VillersDescription: 'Giniel De Villiersは一度もリタイアしたことがありません。さらに、トップ10に入らなかったのは2007年の一度だけです。2009年のダカール・ラリーでは第14ステージを制し、初優勝を飾りました。',
    txt_global_std_youneedtoinputavalidemailformat: '有効なEメール形式を入力してください',
    txt_global_std_JyrkiainenDescription: 'Been growing up surrounded by motorsport in a racing family, daughter of Minna Sillankorva, 1991 Ladies Rally World Champion and nine-time Finnish Champion',
    txt_global_std_youcannotopenthispopupagain: 'このポップアップを再度開くことはできません。',
    txt_global_std_addlicense: 'ライセンスを追加',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint1: '自宅や職場ではなく、実際の運転場所に基づいて運転リスクを評価する',
    txt_global_std_numbercantinclude6062s: '数値に「」を含めることはできませんか？＆/＆＃60;＆＃62; \\ s＃！％$％^ *-',
    txt_global_std_gointoapp: 'アプリに移動',
    txt_global_std_movethemarkertoseehowyourlevelaffectsyourdiscount: 'マーカーを移動して、レベルが割引にどのように影響するかを確認します',
    txt_global_std_profile: 'プロフィール',
    txt_global_std_TidemandMusic: "A well-composed mix of all genres from different times. When I drive it's all about finding the right vibe for the mood, I'm in. ",
    txt_global_std_SignupFia_Text10: 'FIA Smart Driving Challenge は Fédération Internationale de l’Automobileと Greater Thanが所有し調整しています。 サイトはこちら',
    txt_global_std_cimage39d: '停止距離は想像以上に長いものです。',
    txt_global_std_youhavebeeninvited: '招待されました！',
    txt_global_std_disconnectallotherdevicesconnectedtotheobd: 'OBDリーダーに接続されている他のデバイスをすべて外してください。一度に接続できるのは1台のみです。',
    txt_global_std_yourdrivewillautomaticallyending: 'ドライブは自動的に終了します',
    txt_global_std_monday: '月曜日',
    txt_global_std_activateyourinsurance: 'アクティベーションコードを<br>入力する',
    txt_global_std_addyourcurrentmileagevalueinkmtakingapictureofyourodometeroursystemcouldaddthisvalueusingimagerecognition: '走行距離計の写真を撮って、現在の走行距離（km単位の値）を追加します。私たちのシステムは、画像認識を使用してこの価値を追加することができます！',
    txt_global_std_totalco2savings: 'CO2削減率',
    txt_global_std_finalistsnationalchallenges: 'ファイナリスト-全国的な課題',
    txt_global_std_youhavereferred10friendsnice: 'あなたは10人の友達を紹介しました！いいね！',
    txt_global_std_heats: 'イベント',
    txt_global_std_addedodometervaluemileage: 'オドメーター値（走行距離）を追加',
    txt_global_std_usercannotberemoved: '{{email}}を削除することができませんでした。後ほど再度お試しください。\n再度同じエラーが起こった場合、サポートまでお問い合わせください。',
    txt_global_std_p2pcarlend: '貸す車',
    txt_global_std_rowsperpage: '1ページの表示数',
    txt_global_std_100kmunlockedkey: 'あなたはアプリで100kmの距離を\n運転しました！',
    txt_global_std_pleaseenteryourpasswordtodeletethischallenge: 'このチャレンジを削除するには、パスワードを入力してください',
    txt_global_std_distractions: '気晴らし',
    txt_global_std_motorclaimshotline1: 'モータークレームホットライン',
    txt_global_std_actionsheader: '行動',
    txt_global_std_manualstartstopofdrive: '手動での運転開始・終了方法',
    txt_global_std_PolicySchedule8: 'Make/Body Type',
    txt_global_std_pioneergetstartedguide: 'スタートガイド',
    txt_global_std_therearenocostsyet: 'You have no costs yet.,　まだコストは発生していません。',
    txt_global_std_p2pmessage: 'メッセージ',
    txt_global_std_befocused: '集中力を高めましょう​',
    txt_global_std_MSIG_InsuranceLetter42: 'SGD3,500.00セクションIおよびIIは、22歳未満のドライバー、および/または2年未満の運転経験のあるドライバーに個別に課されます。',
    txt_global_std_policynumber1: '規約の番号',
    txt_global_std_p2pend: '終了：',
    txt_global_std_silverlevel: 'シルバーレベル',
    txt_global_std_yourrecenttrips: '最近の旅行',
    txt_global_std_support: 'サポート',
    txt_global_std_addyourcurrentmileagevalueinkm: '現在の走行距離を追加します（km単位の値）',
    txt_global_std_joined: '出場',
    txt_global_std_turnoncarengine: 'エンジンをかける',
    txt_global_std_youcanstillusetheapptogetinsightsandstatisticsonyourdriving: 'あなたはまだあなたの運転に関する洞察と統計を得るためにアプリを使うことができます。',
    txt_global_std_connectyourdevice: '2. デバイスを接続してください',
    txt_global_std_youarealreadydrivingwhenyouhavefinished: '今は運転しています。運転を終了すると、ここに車が表示されます。',
    txt_global_std_qdoessompoacceptdriversthatarenotlicensedintheunitedstates: 'Q：損保は米国でライセンスされていないドライバーを受け入れますか？',
    txt_global_std_p2pmemberuserguide: 'メンバー　ユーザーガイド',
    txt_global_std_diesel: 'ディーゼル',
    txt_global_std_lokaliteterndvendigforatvurderedinkrselsadfrdogberegnedinkrescore: 'Lokaliteternødvendigforatvurderedinkørsels-adfærdogberegnedinkørescore。',
    txt_global_std_turnonbluetoothinyourphone: 'スマートフォンでBluetoothをオンにします。',
    txt_global_std_MSIG_InsuranceLetter29: 'Issued at Singapore on',
    txt_global_std_january: '1月',
    txt_global_std_warning: '警告',
    txt_global_std_youdroveatscoretypescoreorbettergreatyouhaveunlockedthisbadge: '{scoretype} {score}以上で運転しました！すごい！このバッジのロックを解除しました。',
    txt_global_std_drivingsmartmeansusinglessenergyandlessenergymeanslowerco2emissions: 'スマートに運転することは、より少ないエネルギーを使用することを意味し、より少ないエネルギーは、より少ないCO2排出量を意味します。',
    txt_global_std_pigsavedunlocked: '50クラウンごとにこの賞を獲得します',
    txt_global_std_qwhenwillmyratechange: 'Q：料金はいつ変更されますか？',
    txt_global_std_alertmaxspeedtext: '最高速度アラーム',
    txt_global_std_entertheemailaddressthatyouhaveregisteredonyouragentaccount: '{agent}アカウントに登録したメールアドレスを入力してください',
    txt_global_std_oneuniformmeasurementregardlessofvehicletypeandgeography: '車種や地域に関係なく1回の均一測定',
    txt_global_std_august: '8月',
    txt_global_std_cimage25d: '時速80㎞でギアが一番高い時の回転数を確認し、どのギアでもこの回転数を下回るようにしましょう。',
    txt_global_std_ridewithmeunlockedkey: 'おめでとうございます！​<br>\n' +
      'それぞれの車に乗る代わりに、​<br>\n' +
      '友だちとライドシェアすると​ <br>\n' +
      'CO2を大幅に削減できます。​<br>\n' +
      '(獲得上限：1週間に1回まで）​',
    txt_global_std_p2pbyname: '名前で',
    txt_global_std_obdreader: 'OBDリーダー',
    txt_global_std_howdoicompeteinenerfyloyaltychallengeanswer: 'サインアップすると自動的に参加します。心配しないでください。リーダーボードにはニックネームのみが表示されるため、常に匿名のままです。月次および年次の競争があります。',
    txt_global_all_saturday: '土曜日,',
    txt_global_std_p2pnamewantstoborrowvehicle: '{name}が{vehicle}の予約リクエストが届きました。',
    txt_global_std_activatebluetoothsothedevicecanconnectyourphonewithyourcar: 'Bluetoothをオンにしてスマホと車両が接続できるようにしてください',
    txt_global_std_BeforeFirstBill_Text4: 'コストを記録できますか？',
    txt_global_std_PSolbergMileage: 'Coming soon...',
    txt_global_std_editapikey: 'API キーの編集',
    txt_global_std_p2pfaq4a: '消したいメッセージを長めに選択すると、「メッセージを消去する」のボタンが出てきます。',
    txt_global_std_progressstatus: '進捗状況',
    txt_global_std_p2ppushtitlerevokeadmin: 'クルマの管理者権限が取り消されました',
    txt_global_std_sdcrapply2019price2: '500 kr gasoline checks',
    txt_global_std_preparingyournewenerfyhome: 'Preparing your new {appName} home...',
    txt_global_std_networkoffline: 'ネットワークに問題があります。\n後ほどお試しください。',
    txt_global_std_Alert_WereYouDrivingTitle: '運転していましたか？',
    txt_global_std_loyaltysmsmailtext: '私と一緒に安全運転を始めましょう！以下のリンクをたどって、ドライブを開始してください',
    txt_global_std_invitefriendsgetawards: '友達を招待して賞をもらう',
    txt_global_std_failedtopublishedlanguage: '公開言語に失敗しました!',
    txt_global_std_unlocklimitheader: '制限解除',
    txt_global_std_SignupFia_Text8: 'ソーシャルメディアでぜひフォローを',
    txt_global_std_percentagedissconnectedtripsmax25: '{percentageDissconnectedTrips}％切断されたトリップ（最大25％）',
    txt_global_std_users: 'ユーザー',
    txt_global_std_orderobd: 'OBDを注文する',
    txt_global_std_challengesent2: 'チャレンジ送信！',
    txt_global_std_verifyyourchanges: '変更内容を確認',
    txt_global_std_belowisyourlocationsoyoucaneasilydescribewhereyouaretotheroadassistantmanager: '以下はあなたの場所ですので、ロードアシスタントマネージャーにあなたがどこにいるかを簡単に説明することができます。',
    txt_global_std_trafficdelaysdeadlinesandrunninglatetheycanallcausestresswhichisnegativeforyourhealthandsafetyallowingextratimeplanningyourrouteandcheckingtravelupdatescanhelptoreducestressandimproveyourfocus: 'トラフィックの遅延、締め切り、および遅延;それらはすべてストレスを引き起こす可能性があり、健康と安全に悪影響を及ぼします。余分な時間を確保し、ルートを計画し、旅行の最新情報をチェックすることで、ストレスを軽減し、集中力を高めることができます.',
    txt_global_std_TaxInvoice2: 'Date',
    txt_global_std_current: '現時点の：',
    txt_global_std_refer10friendsachievementlocked: '10人の友達を紹介してこの実績をアンロックし、300ポイントを獲得してください。',
    txt_global_std_removephoto: 'Remove photo',
    txt_global_std_pleaseenteravalidemail: '有効なメールアドレスを入力してください',
    txt_global_std_p2pblockcar: '予約をブロックする',
    txt_global_std_countryroadfilechangeDESC: '赤い車のように、前方の車を追い抜こうとして強引な車線変更をしてしまっています。青い車のように、同一レーンを落ち着いて走行できると理想的です。',
    txt_global_std_discountinformation: '月々の平均レベルが割引の基準になります。\n割引は保険が更新された際に適用されます。',
    txt_global_std_business2: '法人',
    txt_global_std_cimage30d: '減速の後の加速は無駄づかいです。',
    txt_global_std_30daysfreetrialnocreditcardrequired: '30日間の無料トライアル。クレジットカードは必要ありません。',
    txt_global_std_onceyoupairtheapptoyourvehiclesbluetoothandchangethesettingstoallowlocationserviceswematchyourdrivinghabitstoexistingdriversandprovideascoreinsuranceisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsabouttheagentappandcompeteagainstthemforlowerscores: 'アプリを車両のBluetoothとペアリングし、位置情報サービスを許可するように設定を変更すると、運転習慣が既存のドライバーと一致し、スコアが提供されます。保険は、誰があなたの車を運転するか、そして彼らがどれだけうまく運転するかに基づいています。あなたの車を運転している人は誰でも、アプリをダウンロードして彼らのスコアを追跡することをお勧めします。 {agent}アプリについて友達に伝え、スコアを下げるために友達と競います。',
    txt_global_std_locationservicesnotsettoalwaysallow: '位置情報サービスが常に許可に設定されていない',
    txt_global_std_yourfriendsname: '友だちの名前',
    txt_global_std_accepted: '受け付けました',
    txt_global_std_thesaferyoudrivethehigherthesaving: '安全運転するほど、節約できる！',
    txt_global_std_clear: 'クリア',
    txt_global_std_achievementcannotbedeletedpopup: 'この実績は削除できません。',
    txt_global_std_alluploadedphotoswillbemarkedwithacheckmark: 'アップされた写真には✓が付きます。',
    txt_global_std_p2paddedbyyou: 'あなたが追加しました',
    txt_global_std_partlyavailable: '一部利用可能',
    txt_global_std_p2pbookingconfirmed: '確定した予約',
    txt_global_std_avgtimeonroad7d: '平均走行時間 7D',
    txt_global_std_createyouraccounttosaveyourtripsandearnbonusetc: 'アカウントを作成して旅行を節約し、ボーナスを獲得し、個人の運転コーチにアクセスしてください。後でアカウントを作成できます。',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint1: '予測リスク/持続可能性管理のための包括的なソリューション',
    txt_global_std_warikan_onboarding9: '5. クルマの利用日時をカレンダーから選択し予約',
    txt_global_std_exampleemail: 'name@domain.com',
    txt_global_std_startguidetip2: 'Bluetoothと位置情報サービスがオンになっていることを確認してください',
    txt_global_std_getspecialoffers: 'スペシャルオファー',
    txt_global_std_acceptfreemiumterms: 'Accept terms and policies',
    txt_global_std_MikkelsenQuote: 'It’s an honour to be selected as team-leader in this exciting project! I am competing at the elite-level of motorsport, and if I, as a driver, can inspire others to become smart, safe and environmental-friendly drivers, I’m happy to do that. Join my team today, and let’s drive smarter! ',
    txt_global_std_searchbartext: 'xxx、xxx、または xxx で検索',
    txt_global_std_qhowlongwillittaketoprocessmyclaim: 'Q：請求の処理にはどのくらい時間がかかりますか？',
    txt_global_std_PolicySchedule23: 'Premium',
    txt_global_std_insurancecompany: '保険会社',
    txt_global_std_TaxInvoice15: 'Entered by STELLAL',
    txt_global_std_thepasswordhasbeenreset: 'パスワードがリセットされました。',
    txt_global_std_youreinvited: 'ご招待されています！',
    txt_global_std_resetpasswordsuccess: '成功！パスワードのリセットリンクについては、電子メールを確認してください。',
    txt_global_std_enerfycoachtip3: '3. 急いでいても、スピードを出さない。',
    txt_global_std_whatwillhappenifmyphonelosessignalorifmyphone: 'What will happen if my phone loses signal or if my phone runs out of battery?',
    txt_global_std_bestregardscomma: 'よろしくお願いします、',
    txt_global_std_competeindividuallyonly: '個別にのみ競争する',
    txt_global_std_paid: '支払い済み',
    txt_global_std_friendwasremoved: '友達が削除されました',
    txt_global_std_makesureyourengineison: 'エンジンがかかっていますか？',
    txt_global_std_confirmandcreateaccount: 'アカウントを確認して作成します',
    txt_global_std_pigsaved: '50クラウンが節約されました',
    txt_global_std_qcanitrytheappbeforesigningup: 'Q：サインアップする前にアプリを試すことはできますか？',
    txt_global_std_HusseinAbdullaQuote: 'お見積りは間もなく…',
    txt_global_std_p2ppushtitleborrowcar: '{name}さんから予約リクエストが届いています',
    txt_global_std_home: 'ホーム',
    txt_global_std_youareabouttopublishtranslationspleasemakesureyouhavereviewedalltranslationsbeforeproceeding: '翻訳を公開しようとしています。続行する前に、すべての翻訳を確認してください。',
    txt_global_std_patternprofiling: 'パターンプロファイリング',
    txt_global_std_qualifications: '資格認定',
    txt_global_std_p2pfaq32a: '1.\t月初めに、「わりかん」のリマインダーがホーム画面に自動で表示されます。または「メニュー」から「わりかん」を選択します。\n' +
      '2.\t該当月の「わりかん」を選択します。\n' +
      '3.\tプルダウンから、4つの分割方法を選択し、どのように費用を分割するか検討します。「利用割合」「利用時間」「均等分担」「走行距離」の中から選べます。\n' +
      '4.\t次にメンバー別に請求する費用を確認します。えんぴつマークを選択すると、金額の調整ができます。\n' +
      '5.\t「費用を請求する」を選択し、メンバーに費用を請求します。\n' +
      '6.\t費用をメンバーから受け取ったら、「支払いを完了とする」を選択します。',
    txt_global_std_SulayemMusic: 'Smooth Jazz',
    txt_global_std_competeforthesebadges: 'これらのバッジを競う',
    txt_global_std_youaretheowner: 'アカウントを確認するために現在のパスワードが必要です。',
    txt_global_std_staytunedonupcomingchallenges: '今後のチャレンジもお楽しみに',
    txt_global_std_statstotal: '合計',
    txt_global_std_signin: 'Sign in',
    txt_global_std_p2ptypeofexpenses: '費用の種類\n',
    txt_global_std_estimatedcost: '見積り保険料',
    txt_global_std_automatic_start_and_stop_requires_access_to_bluetooth_location_services_and_motion_fitness: '自動開始と停止には、Bluetooth、位置情報サービス（GPS）、モーションとフィットネスへのアクセスが必要です。',
    txt_global_std_defaultfirsttimeusingtheserviceregisterfromsignuplink: '初めてのご利用ですか？{signUpLink}',
    txt_global_std_p2pcalculationswillnotbecorrect: '計算があっていない可能性があります。',
    txt_global_std_youcanfileaclaimbyclickingonfileaclaiminyourenerfyapp: 'Enerfyアプリで[申し立てを行う]をクリックすると、申し立てを行うことができます。',
    txt_global_std_illdothislater: 'これは後で行います',
    txt_global_std_dataintervalupload: '20秒',
    txt_global_std_youneedtofillindriversname: 'ドライバー名を記入してください',
    txt_global_std_challengeafriend: '友だちと対戦を始める!',
    txt_global_std_sorrytheimagerecognitionfailed: '申し訳ありませんが、画像認識に失敗しました',
    txt_global_std_emailaddress: 'Eメールアドレス',
    txt_global_std_p2pmycars: 'クルマを貸す',
    txt_global_std_howdoiearnsavings: '節約するにはどうすればよいですか？',
    txt_global_std_getupdatesregardingnews: 'ニュースとチャレンジの最新情報！',
    txt_global_std_closestworkshop: '最寄りの修理工場',
    txt_global_std_sidekickprofilepreferences: 'プロファイル設定',
    txt_global_std_stardriverunlockedkey: '{user}さん、​\n今日も運転お疲れ様でした！​\n安全な運転により​\n3.5以上のスコアを​\n記録しました！',
    txt_global_std_atthecompanychallengepageyoucancreatenewprivatechallengeswhereyoucancompeteindrivingsafeandecofriendly: '会社のチャレンジページでは、安全で環境にやさしい運転を競うことができる新しいプライベートチャレンジを作成できます。',
    txt_global_std_therecouldbeotherreasonswhyyourcarsbluetoothyourbluetoothbeacondoesntshowupinthelist: 'あなたの理由は他にもあるかもしれません\n車のBluetooth / Bluetoothビーコンがに表示されない\nリスト：',
    txt_global_std_rwmnofriendstitle: '選択できる友だちがいません',
    txt_global_std_p2ppushtitlecancelledperiodfromto: '予約がキャンセルされました',
    'txt_global_std_sorryiobd2errorcodereadingnotsupportedbywindowsphone.': '申し訳ございません、 iOBD2エラーコード読み込みはウインドウズフォンには対応しておりません。',
    txt_global_std_warikan_onboarding3_1: 'なお、仮パスワードの有効期限は72時間となっております。\n' +
      '超過後は、「わりかんKINTO」アプリの「仮パスワードを再送信」より再度メールを受け取っていただき、手続きをお願いいたします。\n' +
      '※本メールは送信専用です。ご返信いただいてもお応えいたしかねますので、お問い合わせは下記へお願いいたします。',
    txt_global_std_english: '英語',
    txt_global_std_pleaseconfirmyourlicenseplate: 'ナンバープレートを確認してください',
    txt_global_std_aifyourcardoesnthavebluetoothpleasecontactsupportatphonenotoorderanobdreaderthatyouwillneedtoplugintoyourcar: 'A：車にBluetoothが搭載されていない場合は、{phoneNo}のサポートに連絡して、車に接続する必要があるOBDリーダーを注文してください。',
    txt_global_std_ignore: 'スキップ',
    txt_global_std_someviewslookdifferentdependingonifthereisdrivingdataornotyoucanlogintoyouraccountintheapporusethedifferentuserstatestoviewandtranslatetheappwithorwithoutdrivingdata: '※走行データの有無で一部見え方が異なります。アプリでアカウントにログインするか、さまざまなユーザー状態を使用して、運転データの有無にかかわらずアプリを表示および翻訳できます。',
    txt_global_std_phonenumberdoesnotexist: '電話番号がありません！',
    txt_global_std_themescreendesign: 'テーマ/画面デザイン',
    txt_global_std_my_saving: '私の節約',
    txt_global_std_finalistsheat2: 'ファイナリスト-ヒート2',
    txt_global_std_addusersto: 'ユーザーを追加する',
    txt_global_std_yourchallenges: '参加中のチャレンジ',
    txt_global_std_gender: '性別',
    txt_global_std_youraddress: '住所',
    txt_global_std_company: '会社名',
    txt_global_std_MSIG_InsuranceLetter13: 'Insured Estimated Value',
    txt_global_std_reminderinsurance: 'リマインダー',
    txt_global_std_bluetoothtroubleshooting: 'Bluetoothトラブルシューティング',
    txt_global_std_cimage28d: 'クルーズコントロールは平坦な道路だけにしましょう。それ以外は燃費よく。',
    txt_global_std_joinussignupforourcommunityandbecomeapartofus: 'コミュニティに登録しよう！承認すると、コンペに参加したり友だちと競争できます。',
    txt_global_std_p2pwarningexpenses: '費用は最初に追加してください。後から追加することはできません。',
    txt_global_std_youupgradedtovalueplanyouhaveearned10000points: 'バリュープランにアップグレードしました。あなたは10000ポイントを獲得しました。',
    txt_global_std_igetit: '了解！',
    txt_global_std_registeryourridesharingtripwithin1dayafterthetriphasended: '運転した翌日までに登録を完了して下さい​',
    txt_global_std_darktheme: '暗いテーマ',
    txt_global_std_designandsendpushnotificationstoyourteam: 'プッシュ通知をすぐに設計してチームに送信するか、最高のカスタマージャーニーをスケジュールします。',
    txt_global_std_tipcityparking1DESC: '赤い車のように、他の車が迫っているなか車線変更してしまっています。青い車のように、車間距離を十分とって車線変更できると理想的です。',
    txt_global_all_friday: '金曜日,',
    txt_global_std_p2prequestanewpassword: '新しい仮パスワードをリクエスト',
    txt_global_std_newpassword: '新しいパスワード',
    txt_global_std_seewherepoliciesarewrittenvswheretheyaredriving: '保険証券が有効な場所 vs 現在の走行場所',
    txt_global_std_shareyourinvitecode: '招待コードを共有',
    txt_global_std_ridewithme: 'ライドシェア​ボーナス',
    txt_global_std_yourfriendemail: '友だちの名前@email.com',
    txt_global_std_p2pchataboutabookingorstartagroupchatwithyourinvitedfriends: '予約についてチャットするか、招待された友達とグループチャットを開始します。',
    txt_global_std_yougetthisachievementafterdrivingafullweekandmaintaininganaveragescoreofover4: '丸1週間運転し、4.0以上の平均スコアを維持すると、この成果が得られます。',
    txt_global_std_setupyourcompanychallengeandinviteyourcoworkerstothefiasmartdrivingchallengecompeteagainsteachotherinafunwaytobecomebetterdriverssavemoreco2andimproveyourscore: '会社のチャレンジを設定し、同僚をFIAスマートドライビングチャレンジに招待します。より良いドライバーになり、より多くのCO2を節約し、スコアを向上させるために、楽しい方法で互いに競争してください。',
    txt_global_std_howdoestheappwork: 'アプリはどのように機能しますか？',
    txt_global_std_activationcodeforname: '{名前}のアクティベーションコード',
    txt_global_std_1stdate: '1日',
    txt_global_std_choosebetweenenteringchassinumberplatenumberorpolicynumber: 'シャッシ番号、プレート番号、またはポリシー番号の入力から選択します。',
    txt_global_std_gotochallenge: 'チャレンジに行く',
    txt_global_std_p2pfaq12a: 'メンバーを削除すると、そのメンバーは新しく予約ができなくなります。\n' +
      '1.\t「メニュー」から「メンバー」を選択します。\n' +
      '2.\t削除したいメンバーを選択すると、プロフィールが表示されます。\n' +
      '3.\t「設定」を選択します。\n' +
      '4.\t「メンバーを削除する」を選択すると、削除完了です。',
    txt_global_std_redeemedon: '償還',
    txt_global_std_saturday: '土曜日',
    txt_global_std_bluetooth: 'Bluetooth',
    txt_global_std_yourlatesttripwasbetterthanappnameaverage: 'すばらしい！運転は平均以上です。',
    txt_global_std_p2pyouhavereachedthemaximumamountoffriendspercar: '招待できるメンバーの最大人数に達しました',
    txt_global_std_getstartedandgainaccessto: '始めて、にアクセスしてください',
    txt_global_std_createcustom: 'カスタムを作成',
    txt_global_std_loyaltylevel: '忠誠レベル',
    txt_global_std_you: 'あなた',
    txt_global_std_teamaveragescore: '参加者の平均スコア',
    txt_global_std_cropimage: 'クロップ画像',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriends: '友達と毎日の運転で競います。最高得点のドライバーが勝ちます！',
    txt_global_std_driverspolicy: 'ドライバー保険',
    txt_global_std_pleasemakesurethatyouareconnectedtoyourcarandtryagain: '車に接続していることを確認してから、もう一度お試しください。',
    txt_global_std_deletechallenge: 'チャレンジを削除',
    txt_global_std_everydayheroeswearegatheringtomakeanimpactwedrivetoreduceourcoemissionsbybeingmoremindfulawareandactiveinthewaywetransportourselvesfromoneadventuretothenextbeittheweekendgetawayortogetthekidsfromsoccerpracticewemaketheworldsaferandbetterhowmuchletsfindout: '日常のヒーロー-私たちは影響を与えるために集まっています。私たちは、ある冒険から次の冒険へと自分自身を移動する方法（週末の休暇であれ、子供たちをサッカーの練習から解放することであれ）をより注意深く、意識し、積極的に行うことによって、CO²排出量を削減するよう努めています。私たちは世界をより安全でより良いものにします。いくら？確認してみましょう！',
    txt_global_std_mysaving: '私の節約',
    txt_global_std_theparameterswerevalidbuttherequestfailed: 'パラメータは有効でしたが、リクエストは失敗しました。',
    txt_global_std_automotivecompany: '自動車会社',
    txt_global_std_p2pfinish: '終了',
    txt_global_std_findmycar: '車を探す',
    txt_global_std_individual: '個人',
    txt_global_std_kmleftyourinsuranceisrenewed: '残りキロ。\nあなたの保険は更新されます',
    txt_global_std_p2pbookedby: '予約者',
    txt_global_std_p2pfaq18a: '1.\t画面下のメニューバーから「接続・設定」を選択します。\n' +
      '2.\tBluetooth、位置情報サービス、モーションとフィットネスの設定を許可してください。位置情報サービスは、正確なデータの取得のために「常に」を選択してください。\n' +
      '3.\tエンジンをスタートさせたら、Bluetoothを通してクルマとアプリを接続してください。アプリ上にクルマの名前が表示されたら「クルマを選択」を選択します。\n' +
      '4.\t走行を開始すると、リアルタイムで運転に対するフィードバックを受け取ることができます。\n' +
      '5.\t運転が終了すると、走行データは自動的にアプリに保存されます。\n',
    txt_global_std_optional2: '(オプション)',
    txt_global_std_atleast8characters: '8文字以上。',
    txt_global_std_uploadimage: 'Upload image',
    txt_global_std_p2ppushtitlenewfriend: '新しいメンバーが加わりました',
    txt_global_std_p2paddmembers: 'メンバーを追加する',
    txt_global_std_allowlocationtoparticipateinfiasdc: '位置情報をオンにしてFIA SDCに参加してください',
    txt_global_std_bookingchat: '予約チャット',
    txt_global_std_summaryoftimedrivenperselectedperiod: '選択した期間ごとの駆動時間の概要。',
    txt_global_std_timeandcanonlybeusedonce: '1 回のみ使用できます。',
    txt_global_std_PolicySchedule11: 'Chassis No',
    txt_global_std_bad: '悪い',
    txt_global_std_averagescore: '平均スコア',
    txt_global_std_MSIG_InsuranceLetter1: 'CERTIFICATE OF INSURANCE',
    txt_global_std_yourinsurancewasautomaticallyrenewalformonth: 'あなたの保険は{月}の間自動的に更新されました。',
    txt_global_std_unitsaved: '単位',
    txt_global_std_pleasenotethattojoinyoumusthavethefiasdcappinstalledonyourmobiledeviceinordertobeabletojointhegroup: 'グループに参加するには、モバイルデバイスにFIASDCアプリがインストールされている必要があることに注意してください。',
    txt_global_std_p2plendmycar: '車を貸す',
    txt_global_std_typeofbusiness: '業種',
    txt_global_std_close: '閉じる',
    txt_global_std_datetimetogt: 'GTまでの日時',
    txt_global_std_risklevelandscoring: 'リスクレベルとスコア',
    txt_global_std_teammemberbig1: 'チームメンバー',
    txt_global_std_thereaderwillnotbevisibleinyourphonesbluetoothsettings: "The reader will not be visible in your phone's bluetooth settings",
    txt_global_std_kintoloyaltylevels: 'Kintoのロイヤルティレベル：',
    txt_global_std_p2pmember: 'メンバー',
    txt_global_std_presspaynowwhenyouarereadytocheckoutintheservicecenter: 'サービスセンターでチェックアウトする準備ができたら、[今すぐ支払う]を押します。',
    txt_global_std_tttest99: 'これはテストです',
    txt_global_std_p2phours: '時間',
    txt_global_std_yourbonus: 'あなたのボーナス',
    txt_global_std_carstatsthismonth: '今月のデータ​',
    txt_global_std_signinwithemail: 'Sign in with Email',
    txt_global_std_p2powner: 'オーナー名',
    txt_global_std_carroreward: 'キャロ報酬',
    txt_global_std_pleaseenteravalidphonenumberincludingareacode: '市外局番を含む有効な電話番号を入力してください',
    txt_global_std_somethinghasgonewrong: '失敗しました！',
    txt_global_std_Fia_Receipt_Text3: 'Welcome to FIA Smart Driving Challenge, 2021! A SMS is sent to you with your unique activation code, follow the instructions to get started.',
    txt_global_std_completedaconnecteddrive: 'コネクテッドドライブを完了',
    txt_global_std_1st: '1番',
    txt_global_std_requiredformatcolumnastext2: '必須（列をテキストとしてフォーマット）',
    txt_global_std_OnboardingEmail: 'MS First Capital Insuranceへようこそ!',
    txt_global_std_ourpremierworkshops: '当社の優良修理工場',
    txt_global_std_connect: '接続',
    txt_global_std_p2pfaq11q: 'メンバーを招待する方法を教えてください。',
    txt_global_std_sparet: 'スペアレット',
    txt_global_std_connecttoobdreader: 'OBDリーダ―に接続してください',
    txt_global_std_drives: 'ドライブ',
    'txt_global_std_timein{state}': '{state}にかかった時間',
    txt_global_std_bekindtotheenvironment: '地球にやさしく',
    txt_global_std_2minutesagoshorthand: '2分前',
    txt_global_std_selectthetypeofinjurythatyouwishtoregisterbelowandfollowtheinstructions: '登録したい傷害の種類を以下より選択し、案内に従ってください。',
    txt_global_std_earnpointsandachievethenextlevel: 'ポイントを獲得して次のレベルを達成しましょう！',
    txt_global_std_avoidhardacceleration: '急加速はやめましょう',
    txt_global_std_deleteachivementpopuptext: '実績は完全に削除されます。ユーザーの進行状況は失われます。すでにアチーブメントを受け取っている人は、引き続きそれを見ることができます。',
    txt_global_std_notes: 'メモ',
    txt_global_std_head2headchallenge: '対戦',
    txt_global_std_yourrequestedauthenticationcode: '再リクエストされた認証コード:',
    txt_global_std_leveloverview: '分析結果',
    'txt_global_std_t brikken': 'お問い合わせ',
    txt_global_std_cropyourbackground: 'Crop your background',
    txt_global_std_yourein: 'ご利用開始',
    'txt_global_std_countryroadcatch-up2DESC': '赤い車のように、高速で前方の車に迫ってしまっています。青い車のように、一定速度を維持できると理想的です。',
    txt_global_std_howdoyoumeasuremydriving: '運転はどのように測定されますか?',
    txt_global_std_managegroups: 'グループを管理する',
    txt_global_std_unlockedtitle: 'ロック解除',
    txt_global_std_encouragelowriskdrivingviaapotentialsavingspertrip: 'トリップごとの節約によって安全運転を促進',
    txt_global_std_actiontimestamp: 'アクション日',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsaboutthecovercubeappandcompeteagainstthemforlowerscores: 'A：アプリを車両のBluetoothとペアリングし、アプリが位置情報サービスを使用できるようにすると、AIのドライバーパターンのライブラリに運転習慣が一致し、スコアが提供されます。あなたの保険料はあなたの車を運転する人と彼らがどれだけうまく運転するかに基づいています。あなたの車を運転している人は誰でも、アプリをダウンロードして彼らのスコアを追跡することをお勧めします。 Covercubeアプリについて友達に知らせて、スコアを下げるために友達と競います。',
    txt_global_std_accountremoval: 'アカウントの削除',
    txt_global_std_carsharingemptyviewlent: 'Du har inte lånat någon bil än. Dina körningar kommer sammanställas direkt i appen.',
    txt_global_std_p2pifyouwanttoupdatetheemailusedasusername: 'メールアドレスをユーザー名として利用の場合、',
    txt_global_std_thismaytakeafewseconds: '数秒かかります',
    txt_global_std_youhavebeenchallengedby: 'と対決しています',
    txt_global_std_smartdriving: 'スマートドライビング',
    txt_global_std_preparingsomethings: '準備中…',
    txt_global_std_wecannotfindthecaridentityselectregno: '運転している車両の登録番号を選択してください。',
    txt_global_std_weareherforeyoucontact: 'サポートが必要な場合はご連絡ください！',
    txt_global_std_focustips: '道路に目を離さず、運転に完全に集中してください。周囲、電話、車内の人などに気を取られやすい場合があります。\n' +
      '\n' +
      '疲れたりストレスを感じたりして運転すると、集中力が高まり、リスクが高くなる可能性があります。\n' +
      '\n' +
      '私たちはあなたのことを気にかけています。',
    txt_global_std_gas: 'ガソリン',
    txt_global_std_qhowcaniimprovemyscore: 'Q：スコアを改善するにはどうすれば良いですか？',
    txt_global_std_somefacts: '実例',
    txt_global_std_MSIG_InsuranceLetter24: 'Limitations as to use',
    txt_global_std_paymentpalndoesnotexistonthisaccountgotoeventcreatorandcreateaddpaymentplan: 'このアカウントには支払い枠がありません。イベントの作成者に移動し、追加の支払いプランを作成します。',
    txt_global_std_ReminderMail: 'SDCテストドライブの準備はできましたか?',
    txt_global_std_kintoImprovement2: 'Kintoが見守っています！次回は落ち着いて！',
    txt_global_std_yourinsurancehasbeencanceled: 'あなたの保険はキャンセルされました',
    txt_global_std_base: 'ベーシック',
    txt_global_std_petrol: 'ガソリン',
    txt_global_std_endtour: 'ツアー終了',
    txt_global_std_carregistrationnumber: '車の登録番号',
    txt_global_std_starttime: '開始時間',
    txt_global_std_who: '誰が',
    txt_global_std_rwmcontinue: '続ける',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedthisbadge: 'あなたは本当にうまく運転しました！ 1週間の平均{scoretype}は{score}よりも良かったです。このバッジを獲得しました！',
    txt_global_std_2faenabled: '二要素対応',
    txt_global_std_p2pborrowacar: 'クルマを借りる',
    txt_global_std_youalwaysstarttheinsuranceyearwithnok1000inthepiggybankyouwillbepaidtheseifyouhavebeenasafedriverthroughouttheinsuranceyearifyouchoosetoterminatetheinsurancebeforetheendoftheyearacalculationwillbemadeonthebasisoftheperiodyouhadtheinsurance: 'あなたはいつも貯金箱で1000ノルウェークローネで保険年度を開始します。あなたが保険年度を通して安全な運転手であったならば、あなたはこれらを支払われます。年末までに保険を解約することを選択した場合、保険に加入した期間に基づいて計算が行われます。',
    txt_global_std_jpfaqanswer: '1. 画面下のメニューバーから「接続」を選択します。\n' +
      '\n' +
      '2. Bluetooth、位置情報サービス、\n' +
      'モーションフィットネスの設定を許可し\n' +
      'ください。位置情報サービスは、正確\n' +
      'データ取得のために「常に」を選択して\n' +
      'ください。\n' +
      '\n' +
      '3. エンジンをスタートさせたら\n' +
      'Bluetoothを通して車とアプリを接続して\n' +
      'ください。アプリ上に車の名前が表示され\n' +
      'たら「車を選択」を選択します。\n' +
      '\n' +
      '4. 走行を開始すると、リアルタイムで運転\n' +
      'に対するフィードバックを受け取ることが\n' +
      'できます。\n' +
      '\n' +
      '5. 運転が終了すると、走行データは自動的\n' +
      'にアプリに保存されます。',
    txt_global_std_manydriversrelytooheavilyoncruisecontrolitiscomfortableyesbutlethalforfueleconomyinmanycasesusingcruisecontrolontheslightestuphillordownhillwillcausetheenginetofighthardtosatisfyyourwishofmaintainingtheexactspeedyousetinsteadmanagingyourspeedwithyourfootandaimingforasmoothridewillhelpyousaveahugeamountonfuelnew: '多くのドライバーは、クルーズ コントロールに過度に依存しています。確かに快適ですが、多くの場合、燃費は致命的です。わずかな上り坂や下り坂でクルーズ コントロールを使用すると、設定した正確な速度を維持したいという欲求を満たすために、エンジンが激しく戦うことになります。代わりに、足で速度を管理し、スムーズな乗り心地を目指すことで、燃料を大幅に節約できます。',
    txt_global_std_leaderboardisempty: 'スコアボードが空欄です。',
    txt_global_std_tttest78: 'テスト\nasd',
    txt_global_std_pleasecompletethesesteps: 'ステップを完了してください',
    txt_global_std_rwmsheettitle: '新しい友だちを招待する',
    txt_global_std_dontforgettocheckfeedback: 'あなたの運転を改善し始めるためにあなたのフィードバックをチェックすることを忘れないでください。',
    txt_global_std_youwillbenotifiedintheappwhenitstimetorenewyourinsuranceifyoudonthaveautomaticrenewalsetupfollowthestepsintheapptoensureyouhavecoveragefornextmonth: '保険を更新する時期になると、アプリで通知されます。自動更新を設定していない場合は、アプリの手順に従って、来月の補償範囲があることを確認してください。',
    txt_global_std_keepgooddistancebetweencars: '適切な車間距離を保ちましょう',
    txt_global_std_unit: '単位',
    txt_global_std_JyrkiainenBesttip: 'Be patient and proactive. Follow surroundings and traffic. It’s also good to check the fuel consumption. ',
    txt_global_std_personaldatapreferences: '個人データ設定',
    txt_global_std_p2pwecouldnotfindanybookingchatswhenyoucreateabookingyouwillbeabletochataboutthatbookinghere: '予約チャットはありません。\n予約を確定すると、\n予約に関するチャットが\nできるようになります。',
    txt_global_std_adressline1: '住所欄1',
    txt_global_std_PaddonWhyjoin: "In our team you will win! I'm a very competitive person and will do all I can to work with all our team mates to ensure we put our best foot forward. While this will be a great competition it’s important not to forget the important messages, this challenge carries on. We're in to strive for being safer and more complete drivers, something we all can share and inspire many more people to be.",
    txt_global_std_downloadtheappnameapptodrivemycar: 'クルマを借りるには、わりかん{appname}アプリをダウンロードしてください。',
    txt_global_std_p2pwecouldnotfindyou: '私たちはあなたを見つけることができませんでした',
    txt_global_std_enablemotionfitnesstogetsupportforstartingandendingyourtripsautomatically: 'モーションフィットネスを有効にして、​\n運転の開始と終了を自動的にサポートします。',
    txt_global_std_pushinsurancerenewalodorembody: '保険期間が終了し、更新されます。走行距離計の値を更新する必要があります。',
    txt_global_std_p2ppushtitleaddadmin: 'クルマの管理者権限が追加されました',
    txt_global_std_p2ppushbodyexpensesapproved: '{name}さんが追加費用を承認しました',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint2: '自宅や職場ではなく、実際の運転場所に基づいて運転リスクを評価する',
    txt_global_std_iamapassenger: '同乗者です',
    txt_global_std_ongoing: '進行中の対戦',
    txt_global_std_kintoEncouragement3: 'すばらしい！直近のトリップはKinto平均を上回りました！',
    txt_global_std_areyousureyouwanttoredeempoints: 'ポイント交換しますか？',
    txt_global_std_pwdfiveandtwice: 'パスワードは最低英字５文字です。\nパスワードは２回書いてください。',
    txt_global_std_selectdamagereport: '故障レポートを選択する',
    txt_global_std_autojoinchallenges: '自動参加チャレンジ',
    txt_global_std_noreviewsselectedtobepublished: '公開するレビューが選択されていません!',
    txt_global_std_namesmayonlyincludelettersandspaces: '名前には文字とスペースのみ含めることができます。',
    txt_global_std_kintofaq2q: 'どうすればうまく運転できますか？',
    txt_global_std_p2pyesdelete: 'はい、削除します',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimedecreasingspeedleavingasafefollowingdistanceenablesyoutodecreaseyourspeedsteadilyandsmoothly: 'このタイプの旅行では、平均的なエコ チャンピオンは速度を下げるのにより多くの時間を費やしました。安全な車間距離を保つことで、着実かつスムーズに速度を落とすことができます。',
    txt_global_std_ecotips: 'あなたのエコスコアは、あなたが運転する環境にやさしい度合いを測定します。多くの方法でそれはあなたの計画に接続することができます。\n' +
      '\n' +
      '不必要なブレーキや停止を避けるために加速を調整してください。すべての加速とブレーキはエネルギーを消費します。\n' +
      '\n' +
      '一緒に私たちは未来のために違いを生むことができます。',
    txt_global_std_nicestreak: 'いい連勝！',
    txt_global_std_finalizepersonalinformation: '個人情報を確定する',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint2: '好みのチャネルでユーザーとつながる',
    txt_global_std_downloadthefiasdcappbyclickingthelinkonyourmobilephone: '携帯電話のリンクをクリックして、FIA SDC アプリをダウンロードします。',
    txt_global_std_presstoloadmore: 'タップしてさらに読み込む',
    txt_global_std_alertweredrivingyoudrivingtitle: '運転していましたか？',
    txt_global_std_awardedbadges: '受賞バッジ',
    txt_global_std_hereyouloweryoursubscriptioncostbydrivingsafe: 'ここでは、安全運転によってサブスクリプションコストを削減します。',
    txt_global_std_activateinsurance: '有効な保険',
    txt_global_std_settingupprofile: 'プロフィールの設定中',
    txt_global_std_completeinfoandcreateaccount: '情報を入力してアカウントを作成する',
    txt_global_std_kintofaq1q: '運転のたびにアプリの起動が必要でしょうか？',
    txt_global_std_yourmessagehasbeensent: 'メッセージが送信されました。',
    txt_global_std_choosingbalwaysallowbisnecessarytogetcorrectstatsaboutyourdriving: '運転に関する正しい統計情報を取得するには、<b>常に許可</b>を選択する必要があります。',
    txt_global_std_ayesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforagentinsurance: 'A：はい、アプリをダウンロードして「アプリを試す」オプションを選択できます。アプリを使って運転し、{agent}保険に加入した場合にどれだけ節約できるかを確認できます。',
    txt_global_std_servicedandsoldby: 'によってサービスおよび販売',
    txt_global_std_p2pwhenyougiveyourfriendadminrightstheycanviewandhandlebookingsofyourcar: 'メンバーに管理者権限を与えることで、メンバーがあなたのクルマの予約状況を管理できます。',
    txt_global_std_mydrivingstats: '運転履歴',
    'txt_global_std_Click the plus sign to create a new challenge.': '+マークをクリックして新しいチャレンジを作成。',
    txt_global_std_alreadyhaveacovercubepolicy: 'Covercubeポリシーはすでにありますか？',
    txt_global_std_TidemandWhyjoin: 'Because together we are unbeatable and always aim for the top! ',
    txt_global_std_PaddonMileage: 'I love driving on the back-country roads of New Zealand and exploring new places. The west coast of the South Island is a very special place, as you feel so isolated at the same time drive on amazing twisty scenic roads.',
    txt_global_std_WelcomeLetter_Text5: '敬具',
    txt_global_std_kintofaq8a: '安全運転の一言に尽きます。安全運転すれば、走行距離に応じて節約が加算されます。安全運転した分、節約できるというわけです！トリップが終了した時点で、節約分はそのままKINTOウォレットに反映されます。',
    txt_global_std_accident: '事故',
    txt_global_std_enerfycoachtip2: '2. 低速でも、前方車両との車間距離を保つ。',
    txt_global_std_learnmoreaboutpermissions: '権限の詳細',
    txt_global_std_rwmyoucanonlyregistertripsmadewithinthelastday: '登録できるのは、前日までの運転のみです。',
    txt_global_std_nooftrips: 'トリップ数',
    txt_global_std_p2pongoing: '進行中の対戦',
    txt_global_std_p2pbooking: '予約',
    txt_global_std_setuplocationservices: '位置情報サービスを設定する',
    txt_global_std_cancel: 'キャンセル',
    txt_global_std_VilliersChamp: '4 times SA Touring car champion, 7 Dakar podiums, 1 Dakar win, 3 times Marocco rally winner, 2008 won the Portugal Rally, 2008 winner Dos Sertoes rally Brazil.',
    txt_global_std_p2psafedriverdiscount: 'セーフドライバー割引',
    txt_global_std_setupcomplete: 'セットアップが完了しました。',
    txt_global_std_enablemotionfitness: 'スマートフォンでモーションとフィットネスを有効にします。',
    txt_global_std_gettingtheappready: 'アプリを準備中',
    txt_global_std_TomczykMusic: 'What I listen to when driving depends on my mood.  I like hardrock a lot, like Dio and Manowar, but I also listen to current music charts.',
    txt_global_std_youhavealreadyorderedanobdreader: 'あなたはすでにOBDリーダーを注文しました',
    txt_global_std_loyaltylevelrewards: 'このロイヤルティレベルは{reward}に相当します。',
    txt_global_std_theenerfyteam: 'Enerfyチーム',
    txt_global_std_invitetherestofyourteam: 'チームの他のメンバーを招待する',
    txt_global_std_teams: 'チーム',
    txt_global_std_joinchallenge: 'チャレンジに参加',
    txt_global_std_coach: 'フィードバック',
    txt_global_std_appcreator_upsell_pitch: 'コーディングを必要とせずに、独自の運転アプリを構築してカスタマイズします。',
    txt_global_std_c02overview: 'C02の概要',
    txt_global_std_youhavenowdriven25tripswithtrygdrive: 'これで、Tryg Driveで25回のトリップを運転しました',
    txt_global_std_thankyou: 'ありがとうございます！',
    txt_global_std_gotoyourpreferredservicecenterandshowthemthispopup: 'ご希望のサービスセンターに移動して、このポップアップを表示してください。',
    txt_global_std_mystats: '走行データ',
    txt_global_std_p2pamountshouldbeshownonthereceipt: '金額は領収証に表示されます。',
    txt_global_std_p2pviewexpenses: '経費を見る',
    txt_global_std_cimage11d: '停車中はエンジンを切りましょう',
    txt_global_std_p2pemailbestregards: '敬具　<br> {appname} チーム',
    txt_global_std_p2ppleaseuploadareceiptpicture: '領収書画面をアップロードしてください',
    txt_global_std_wecannotfindthecaridentityenteractivationcode: '車両のIDが確認できません。初回のメールに記載のアクティベーションコードを入力してください。',
    txt_global_std_onboardingnotificationsheader: '通知',
    txt_global_std_takethefootofthepedalandletthecarcoastasmuchasitmakesensesavesfuel: 'ペダルの足を踏み入れて、燃料を節約するために車を惰性で走らせます。',
    txt_global_std_sonicethatyoudecidedtojoinusletssetupyourprofile: 'ご参加ありがとうございます！プロフィールを設定しましょう。',
    txt_global_std_mystatstext: 'ステータス',
    txt_global_std_successfullyupdatedtranslation: '翻訳が正常に更新されました',
    txt_global_std_nice: 'ナイス！',
    txt_global_std_origin: '起点',
    txt_global_std_p2pemailnewsavailableinappcarSharing: '{appname} からの新しいニュース：カーシェアリング',
    txt_global_std_inviteusers: 'ユーザーを招待',
    txt_global_std_choosethetypeofbusinessthatdescribesyourcompanythebest: 'あなたの会社を最もよく表すビジネスの種類を選択してください。',
    txt_global_std_polish: '研磨',
    txt_global_std_startdrive: 'トリップを開始',
    txt_global_std_icannotconnect: '接続できませんか？',
    txt_global_std_gameonyourchallengehasbeenaccepted: 'ゲームスタート！あなたの挑戦は受け入れられました！',
    txt_global_std_earnsavingsintoyourkintowalletforsafedriving: '安全運転をめざし、節約分はKintoウォレットへ',
    txt_global_std_p2pmaximum9999999allowedforanexpense: '{currency}9,999,999以内の金額を記入してください',
    txt_global_std_100kmlockedkey: 'アプリ接続中の走行距離が​\n100kmに到達した場合に​\n獲得できるバッジ​\n（獲得上限：1回まで）',
    txt_global_std_challenge: 'チャレンジ',
    txt_global_std_dragme: 'ドラッグ！',
    txt_global_std_namesmayonlyincludeletters1: '名前には文字のみを含めることができます。',
    txt_global_std_Fia_Receipt_Text17: 'Greater Than, Karlavägen 60, 114 49 Stockholm Sweden. Organisation number: 556608-3258',
    txt_global_std_PolicySchedule25: 'Total Due',
    txt_global_std_p2padditionalexpense: '追加費用',
    txt_global_std_preferredrequirementaccuracy: '優先要件の精度',
    txt_global_std_signupandsavemoneyonyourinsurance: '新規登録して保険料を節約',
    txt_global_std_search: '検索',
    txt_global_std_p2pfaqcontactinfo2: '・混雑時などつながりにくいことが予想されますのでご了承ください',
    txt_global_std_emailhasbeensent2: 'メールが送信されました！',
    txt_global_std_communication_upsell_pitch: '独自のメッセージとコミュニケーションのスケジュールを簡単に設計できます。',
    txt_global_std_endday: '終了日',
    txt_global_std_pointstounlock: 'ロックを解除するポイント。',
    txt_global_std_ifyouhaveaninsuranceyoucanfindyourpolicynumberinyourappnameaccountdonthaveitathandrightnownoworriesyoucanadditlaterinsettings: '保険に加入している場合は、{appname}アカウントで保険番号を見つけることができます。\n' +
      '今手元にありませんか？心配ない！後で設定で追加できます。',
    txt_global_std_allmonth: '1か月',
    txt_global_std_ChicheritChamp: 'Coming soon...',
    txt_global_std_gettheapp: 'アプリを取得する',
    txt_global_std_everymonth: '毎月',
    txt_global_std_youaredriving: '運転中です。',
    txt_global_std_secondarycolor: '二次色',
    txt_global_std_cimage11h: 'エンジンを切りましょう',
    txt_global_std_latest10: '最新の10',
    txt_global_std_p2papprove: '承認する',
    txt_global_std_yourdatais: 'あなたのデータは=',
    txt_global_std_numberofrepetitions: '繰り返し回数',
    txt_global_std_warikan_onboarding2_2: 'この度は、「わりかんKINTO」アプリのご利用お申し込み、誠にありがとうございます。\n' +
      '以下の仮パスワードをアプリの画面に入力のうえ、アカウント作成をお願いいたします。',
    txt_global_all_sunday: '日曜日,',
    txt_global_std_authenticating: '認証',
    txt_global_std_HusseinAbdullaChamp: '2016 won FIA T2 World Champion',
    txt_global_std_nodataavailable: 'データなし',
    txt_global_std_signinginwiththirdpartyname: 'Signing in with {thirdPartyName}...',
    txt_global_std_fiaquarterfinalqualifyrule2020: '予選ファイナルの資格を得るには、少なくとも4つのヒートに参加する必要があります。',
    txt_global_std_RemoveAccount_text1: 'わりかんKINTOご利用者様\n' +
      '\n' +
      'このメールは、アカウントの削除の手続きをされたお客様にお送りしております。\n' +
      '　\n' +
      '一度削除したデータは復元できませんのでご注意ください。予約情報、および、運転履歴などを含む一切のデータが削除されます。\n' +
      '　\n' +
      'アカウントを削除するには、以下のリンクをクリックしてください。\n' +
      '\n' +
      '{{removelink}}\u000b\n' +
      '\n' +
      '※本メールは送信専用です。\n' +
      'ご返信いただいてもお応えいたしかねますのでご了承ください。\n' +
      '\n' +
      '◎お問い合わせはこちら\n' +
      'KINTOカスタマーセンター\n' +
      'info_w@kinto-jp.com\n' +
      '\n' +
      '株式会社 KINTO\n' +
      '〒450-0002　愛知県名古屋市中村区名駅四丁目8番18号　名古屋三井ビルディング北館１４Ｆ\n' +
      'https://kinto-jp.com/\n',
    txt_global_std_cardwithmoreinformationaboutgdpr: 'GDPRに関する詳細情報が記載されたカード',
    txt_global_std_bluetoothsystem: 'Bluetoothシステム',
    txt_global_std_nophotosadded: '写真が追加されていません',
    txt_global_std_SignupFia_Text7: 'アプリをダウンロードしよう！',
    txt_global_std_fiasdcsmartscore: 'FIASDCスマートスコア',
    txt_global_std_youcanaddtranslationbyclickinghere: 'ここをクリックして翻訳を追加できます。',
    'txt_global_std_de-villiersDescription': 'Giniel De Villiers has never retired. Even better, only once has he not finished in the Top 10 (in 2007). He has 14 stage victories and in 2009 he won the first Dakar contest in South America.',
    txt_global_std_digitaltraining: 'オンライン指導',
    txt_global_std_valuedisconnctedtripsofmaxmaxvalue: '{value}％の接続されていないトリップ（最大{maxvalue}％）',
    txt_global_std_energysaved: '省エネルギー',
    txt_global_std_reactivateyourinsurancetokeepyourcoverage: 'あなたの補償範囲を維持するためにあなたの保険を再開してください。',
    txt_global_std_theoreticaltimesaving: '理論上の時間短縮',
    txt_global_std_splits: 'スプリット',
    txt_global_std_MSIG_Onboarding10: 'Do you have any questions? Contact our support team at 0123456789, or email us at info@msfc.com.sg.',
    txt_global_std_incorrectpassword: 'パスワードが正しくありません',
    txt_global_std_youhavebeeninvitedtojoin: '参加するよう招待されました:',
    txt_global_std_finishingyourscore: 'スコアを集計中...',
    txt_global_std_requestssent: 'リクエスト送信済み',
    txt_global_std_companychallengename2: '会社チャレンジ名',
    txt_global_std_pleaseseethefinishedreport: '完成したレポートをご覧ください。',
    txt_global_std_creationdate: '作成日',
    txt_global_std_p2pfaq10a: 'KINTOの事故対応ページをご確認ください。\nhttps://kinto-jp.com/insurance/',
    txt_global_std_policyholder: '保険契約者',
    txt_global_std_rwmnoeligibletripstitle: '前日の運転データ<br>がありません',
    txt_global_std_myscore: 'スコア',
    txt_global_std_riskindex_upsell_pitch: 'ニュース、データ、リスク分析を組み合わせたこの洗練されたツールを使用して知識を行動に移し、特定します。',
    txt_global_std_shootingstarlockedkey: 'レベル3.3以上でのドライブを達成すると、Kintoウォレットに100ポイント付与されます。',
    txt_global_std_p2papply: '適用する',
    txt_global_std_termsofservice: 'Terms of Service & Privacy Policy',
    txt_global_std_youneedtoaddatleast8characters: '少なくとも8文字を追加する必要があります。 1つの小文字（a-z）、1つの大文字（A-Z）、1つの数字（0-9）を含みます。',
    txt_global_std_reallynicerun: '本当にいい走り',
    txt_global_std_changingyouremaildoesnotchangeyourusername: 'Eメールを変更してもユーザー名は変更されません',
    txt_global_std_influencer: 'フレンド',
    txt_global_std_agreementcreated: '契約が作成されました',
    txt_global_std_orderobddeviceto: 'OBDデバイスを次のように注文します。',
    txt_global_std_p2pfaq19q: '走行データが記録されていない区間があります。',
    txt_global_std_letsdrivewithwarikankintopushbody: 'わりかんKINTOとドライブをして、バッジやポイントを集めませんか？集めたポイントは景品と交換することができます！スコア機能とバッジ獲得はKINTO ONEご契約者以外の皆さんにもお試しいただけます。',
    txt_global_std_analytics: '分析',
    txt_global_std_p2pforgotwhereyouparkedyourcarnoworrieswegotyoucoveredjustpressthisbuttonandwewillfinditforyou: '車を駐車した場所を忘れましたか？心配いりません、私たちはあなたをカバーしました。このボタンを押すだけで、私たちはあなたのためにそれを見つけます！',
    txt_global_std_kintofaq5a: '変更後の車で初回の設定をお願いします。合わせて、お手数ですが、以下連絡先にメールにてご連絡ください。株式会社KINTO　北村　satoshi.kitamura@kinto-jp.com',
    txt_global_std_annualcarinsuranceprice: '年間自動車保険価格',
    txt_global_std_p2paddedmemberstothegroup: 'さんがグループにメンバーを追加しました。',
    txt_global_std_acceptlocationservices: '位置情報サービスを受け入れる',
    txt_global_std_youcancontactyourinsuranceagency: 'あなたはあなたの保険代理店に連絡することができます。',
    txt_global_std_savechangespopuptext: '保存していない変更は失われます。閉じる前に変更を保存しますか?',
    txt_global_std_change: '変更する',
    txt_global_std_whatwillhappenifidonotconnectmycar: 'What will happen if I do not connect my car?',
    txt_global_std_SendMyPosition_Text2: '敬具、 {appName}',
    txt_global_std_tomasengequote: 'あなたがあなたの目標を達成したいなら、あなたはそれのためにすべてをしなければなりません',
    txt_global_std_ayourscoreisbasedonthedrivingpatternsyoucreatewitheachtripconcentrateonyourdrivingskillstoimproveyourscoreswewillprovideahistoryofallyourtripssoyoucanseehowyouredoing: 'A：スコアは、運転毎の運転パターンに基づいています。あなたのスコアを向上させるためには、より安全運転を心がけてください。',
    txt_global_std_ifyouwanttolendyourcartoafriendyoushouldinviteyourfriendtotheappbytappingthebuttonbelowthisensuresyourcarisstilltrackedbyloyaltysystem: '友達に車を貸したい場合は、下のボタンをタップして友達をアプリに招待してください。これにより、車がロイヤルティシステムによって引き続き追跡されます。',
    txt_global_std_acceptconsent: '同意を受け入れる',
    txt_global_std_bycontinuingyouaccept: 'By continuing you accept our',
    txt_global_std_5000pointaverageachievementlocked: '1週間平均{scoretype) of {score}以上{scoretype) of {score}運転してこの実績をアンロックし、5000ポイントを獲得します。',
    txt_global_std_kintoquestions: 'Kinto に関する質問：',
    txt_global_std_co2besparelse: 'Co2ベスパレルス',
    txt_global_std_wewillnotbeabletoanalyzeyourdriveand: 'We will not be able to analyze your drive and you will be billed at premium level 10.',
    txt_global_std_apitool_upsell_pitch_bulletpoint2: 'ドライバーデータの高速かつ安全な転送',
    txt_global_std_yourdatahasbeensent2: 'ご利用情報について、ご確認ください。',
    txt_global_std_HansenMusic: 'Listening to music while driving can take away a lot of focus. But getting out on highways and cruising, I truly enjoy listen to music that makes me engage and sing along.',
    txt_global_std_insureddistance_used_100__2: 'Ut! Öka årlig körsträcka här.',
    txt_global_std_uploadpicturestooldaccidentreport: 'レポートから24時間以内に車の写真をアップロードしてください。下の古いレポートをクリックして、写真を追加できます。',
    txt_global_std_edituser: 'ユーザーの編集',
    txt_global_std_opendocument: 'ドキュメントを開く',
    txt_global_std_qdoihavetoactivatetheappeverytimeidrive: 'Q：運転するたびにアプリを起動する必要がありますか？',
    txt_global_std_orderobdreader: 'OBDリーダーを注文する',
    txt_global_std_friday: '金曜日',
    txt_global_std_otherdocuments: 'その他の文書',
    txt_global_std_insuranceisrenewedplaceholder: '残り63kmです。\nあなたの保険は更新されます2021-10-29',
    txt_global_std_manageapikeys: 'APIキーを管理する',
    txt_global_std_zipcode2: '郵便番号',
    txt_global_std_minutesandcanonlybeusedonce: '分で、一度しか使用できません。',
    txt_global_std_couldnotconnect1: '接続できませんでした！',
    txt_global_std_brakevca: 'ブレーキV / C / A',
    txt_global_std_insurancestatus: '保険の状態',
    txt_global_std_takecareoftheinjured: '負傷者の世話をする',
    txt_global_std_deactivateuserfrom: '次のユーザーを非アクティブ化します。',
    txt_global_std_jointaveragescore: '共同平均スコア',
    txt_global_std_duration: '期間',
    txt_global_std_tryglocationservice: '位置情報へのアクセスを許可 - 常に選択',
    txt_global_std_p2ppleaseselectpaymentmethod: '支払い方法を選択してください',
    txt_global_std_hellothisisanonboardingtext: 'こんにちは、これはオンボーディングテキストです。',
    txt_global_std_manualstartstoprequiresaccess: '手動で開始および停止するには、位置情報サービス（GPS）およびモーションとフィットネスにアクセスする必要があります。',
    txt_global_std_yournumber: '番号',
    txt_global_std_startcarengine: '車のエンジンを始動します。',
    txt_global_std_yourfriendsemail: '友だちのEメール',
    txt_global_std_theamountisenteredinthepiggybankwhentherecruitmentcodehasbeenregisteredyouhaveafulltimeoverviewofyourrecruitsonthesavingsside: '採用コードが登録されると、貯金箱に金額が入力されます。あなたは貯蓄側であなたの新入社員のフルタイムの概要を持っています。',
    txt_global_std_p2pyouhavenocar: '車がありません',
    txt_global_std_gohome: '家に帰る',
    txt_global_std_connectingtocar: '車とアプリを接続する',
    txt_global_std_beonestepahead: 'あと一歩',
    txt_global_std_skillup: 'スキルアップ',
    txt_global_std_TidemandChamp: 'WRC 2 Champion 2017, Touring Car Champion 2010',
    txt_global_std_cimage1h: '最高速度を下げる',
    txt_global_std_MSIG_Password2: 'You have requested a new password for your MS First Capital-account. Your new password to the user',
    txt_global_std_pleasenotethatifyouhavealargerproportionofdisconnectedtripsyoumaynotbepaidadrivingdividendremembertodrivewiththeappconnected: '切断された旅行の割合が多い場合、運転配当が支払われない場合があることに注意してください。アプリを接続した状態で運転することを忘れないでください。',
    txt_global_std_2pairyourphonewithyourcarviabluetooth: '2．  スマートフォンと車のBluetoothを繋げます​',
    txt_global_std_cimage14h: '下り坂を利用しよう',
    txt_global_std_TidemandMileage: "I prefer smaller roads with twists and turns surrounded by beautiful scenery compared to highways. It's more fun to drive and you never know what you might find after the next corner. Sweden or anywhere else in the world doesn't matter, discovering new roads is always fun.",
    txt_global_std_p2psplityourcostfor: '{month} 費用を分割する',
    txt_global_std_category: 'カテゴリー',
    txt_global_std_cantfindyourchallenge: 'チャレンジが見つかりませんか？',
    txt_global_std_OSolbergWhyjoin: 'Coming soon...',
    txt_global_std_inspiringsmarterdrivinghelpsreducefueluseco2footprintandcrashes: 'よりスマートな運転を刺激することで、燃料使用量、CO2排出量、衝突事故を減らすことができます',
    txt_global_std_HusseinAbdullaMusic: 'Calm music',
    'txt_global_std_Al-KhelaifiBesttip': 'Commuting home after work.',
    txt_global_std_loading: 'ロード中…',
    txt_global_std_p2pnogoback: 'いいえ、戻る',
    txt_global_std_nov: '11月',
    txt_global_std_drivenotregistered: 'ドライブが登録されていません',
    txt_global_std_exploretheapp: 'Explore the app',
    txt_global_std_ridewithmesubtitle: '友だちとライドシェア<br>をして300ポイント獲得！​',
    txt_global_std_login1: 'ログイン',
    txt_global_std_successfullycreatedgroup: 'グループが正常に作成されました',
    txt_global_std_carinformation: '車情報',
    txt_global_std_removealldatafromthisaccount: 'アカウントからすべてのデータを削除しますか?',
    txt_global_std_september: '9月',
    txt_global_std_TomczykWhyjoin: "My goal is not just the obviously aim to win. It's also the fun part of joining in and fighting during the heats to become qualified as the best and smartest team.",
    txt_global_std_ratedrivingriskbasedonactualdrivinglocationsnothomeorworkplace: '自宅や職場ではなく、実際の運転場所に基づいて運転リスクを評価します。',
    txt_global_std_june: '6月',
    txt_global_std_weliketorewardyousoeverytripyoudrivesafeyouwillearn: '安全運転する度に、節約できます。節約分は、そのまますぐにウォレットに反映されます。',
    txt_global_std_invitecoworkersandstartcompetingagainsteachothertobecomebetterdriversandsavemoreco2: '同僚を招待し、お互いに競争を始めて、より良いドライバーになり、より多くのCO2を節約します。',
    txt_global_std_PSolbergInterests: 'Coming soon...',
    txt_global_std_tirerotationunload: 'タイヤの回転、アンロード…',
    txt_global_std_p2ptounlockyouraccesstotheappandthebenefitsregisteratthewebsite: 'アプリへのアクセスと特典のロックを解除するには、[ウェブサイト]で登録してください',
    txt_global_std_whatmusicdoyouliketolistentowhenyoudrive: 'ドライブ中に聞きたい曲は?',
    txt_global_std_yourinsurancewasautomaticallyrenewedformonthwithadiscountonyourpremium: '保険料が割引されて、保険が{month}の間自動的に更新されました。',
    txt_global_std_thisisatestphraseforonboarding: 'これは、オンボーディングのテストフレーズです。',
    txt_global_std_cimage29d: '状況が許せば、ギアは2段階で。',
    txt_global_std_issomeoneelsegoingtodriveyourcar2: '他の誰かがあなたの車を運転するつもりですか？アプリを使用できるようにリンクを送信します。',
    txt_global_std_reportaccidentheresmile: '事故を報告する:-)',
    txt_global_std_locationaccess: '位置情報へのアクセスを許可',
    txt_global_std_p2ppushtitlebookingcomingup: '予約日時が近づいています',
    txt_global_std_whatifichangecarlexusanswer: 'レクサスチームにメールで通知します。次に、「車に接続」の手順をもう一度実行して、新しい車に再接続します。',
    txt_global_std_p2pyoucanfindcarsrharingin: 'カーシェアリングは以下のメニュー (・・・) から選択します。',
    txt_global_std_registerridesharingtrip: 'ライドシェアを登録する',
    txt_global_std_optional: 'オプション',
    txt_global_std_yourinsurancewasautomaticallyrenewedformonth: 'あなたの保険は{month}の間自動的に更新されました。',
    txt_global_std_qhowdoisetupautomaticrenewal: 'Q：自動更新を設定するにはどうすればよいですか？',
    txt_global_std_exampleofvalidemailaddresses: '有効なEメールアドレスの例',
    txt_global_std_willbedeletedimmediatelyandpermanentlyoncedeleteditcannolongerbeusertomakeapirequests: 'すぐに永久に削除されます。削除すると、ユーザーは API リクエストを行うことができなくなります。',
    txt_global_std_drivewellchallenge: 'ドライブウェルチャレンジ',
    txt_global_std_gotoaservicecentertoredeemyourrewards: '特典を利用するには、サービスセンターにアクセスしてください。',
    txt_global_std_removeuserfromgroup: 'グループからユーザーを削除する',
    txt_global_std_p2pto: 'か',
    txt_global_std_customemail: 'カスタムメール',
    txt_global_std_p2ppending: '保留中',
    txt_global_std_youraveragescoreis: '平均レベル',
    txt_global_std_wehavetoaccesslocationservicesinthebackgroundwhile: '運転に集中している間は、バックグラウンドで位置情報サービスにアクセスする必要があります。このポップアップが表示されたら、「常に許可」を選択してください。',
    txt_global_std_MSIG_InsuranceLetter28: 'I/We HEREBY CERTIFY that the Policy to which this Certificate relates is issued in accordance with the provisions of the Motor Vehicles (Third-Party Risks and Compensation) Act (Chapter 189) and Part IV of the Road Transport Act, 1987 (Malaysia)',
    txt_global_std_avgrisklevel123: '平均リスクレベル',
    txt_global_std_avgscore: '平均レベル',
    txt_global_std_p2pfaq29q: 'クルマを借りる方法を教えてください。',
    txt_global_std_deepdiveintodrivercrash: 'ドライバーの事故確率やリスクなどの統計データを確認する',
    txt_global_std_contactdetailsfromyouraccountpleaseeditdetailsifneeded: 'アカウントの連絡先の詳細。必要に応じて詳細を編集してください。',
    txt_global_std_thischallengeactiveandupcomingchallenges: 'この課題、アクティブで今後の課題',
    txt_global_std_p2pfaq29a: '1.\tホーム画面から「クルマを借りる」を選択します。\n' +
      '2.\t借りたいクルマのオーナーを選びクルマを選択します。\n' +
      '3.\t日時を選択します。\n' +
      '4.\tメッセージを入力し（任意）最後に「リクエストを送信する」を選択します。\n' +
      '5.\t予約リクエストはメンバーが承認、または拒否するまで保留されます。',
    txt_global_std_last10drives: '最新の10トリップ',
    txt_global_std_nousersfoundinviteuserstogetstarted: '利用者が見つかりません。ユーザーを招待して開始します。',
    txt_global_std_edit: '編集する',
    txt_global_std_statusnotconnected: '未接続',
    txt_global_std_reasonforpoints: 'ポイントの理由',
    txt_global_std_appquestions: 'アプリに関する質問',
    txt_global_std_connecting: '接続中',
    txt_global_std_back1: '戻る',
    txt_global_std_max30characters: '最大 30 文字',
    txt_global_std_fileisattached: 'ファイルが添付されています',
    txt_global_std_yourpwwasnotchanged: 'パスワードは変更されていません。別の新しいパスワードを試すか、有効な現在のパスワードを書いてください！',
    txt_global_std_badges: 'バッジ',
    txt_global_std_p2pnotincluded: '含みません',
    txt_global_std_p2pdistancetravellednotrecommended: '走行距離はお勧めしません。',
    txt_global_std_addpolicynumber: 'ポリシー番号を追加します',
    txt_global_std_entervalidmessage: '友達にテキストを入力してください',
    txt_global_std_visibleinmaps: 'マップで表示',
    txt_global_std_challengename: 'チャレンジ名',
    txt_global_std_MSIG_InsuranceLetter22: "2) Any other person who is driving on the Insured's order or with his permission.",
    txt_global_std_areyousureyouwanttodeletethismessage: 'このメッセージを削除してもよろしいですか?',
    txt_global_std_takemetotheappinstead: 'アプリに進む',
    txt_global_std_cantfindanybluetoothdevice: 'bluetoothデバイスが見つかりません。',
    txt_global_std_youareoffline: 'You are offline',
    txt_global_std_co2savingsscore: 'CO2の節約',
    txt_global_std_p2pfaq31q: '走行データはどこから確認できますか？',
    txt_global_std_improvementareaforspeed: '速度に関する改善ポイント',
    txt_global_std_createyouraccount: 'アカウントを作成',
    txt_global_std_anonymoususer: '匿名ユーザー',
    txt_global_std_pointslefttoreach: '達成までのポイント',
    txt_global_std_thistrip: '今回の運転',
    txt_global_std_weeklyachievements: '毎週の成果',
    txt_global_std_duedate: 'Due date',
    txt_global_std_options: 'オプション',
    txt_global_std_consentleaderboard: 'コンペに同意する&お問い合わせ',
    txt_global_std_MSIG_InsuranceLetter32: 'Authorised Signature',
    txt_global_std_namehasinvitedyoutojointheirgroup: '{name}があなたを彼らの{group}に招待しました',
    txt_global_std_35000pts: '&#62;35000ポイント',
    txt_global_std_totaldistancekm: '総走行距離 (km)',
    txt_global_std_mydrives: '最近の運転',
    txt_global_std_comment: 'コメント',
    txt_global_std_pluginthedevice: 'デバイスをプラグイン',
    txt_global_std_thecontactinformationyousubmithereiswhatwewillusetocontactyouregardingtheappandsendyoutheappsdownloadlink: 'ここで送信する連絡先情報は、アプリに関して連絡し、アプリのダウンロード リンクを送信するために使用されます。',
    txt_global_std_enteryearlyprice: '年間価格を入力してください',
    txt_global_std_welcometo: 'へようこそ',
    txt_global_std_repeatthechallengedailyweeklymonthlyorannually: '毎日、毎週、毎月、または毎年チャレンジを繰り返します。',
    txt_global_std_totaltime: 'Total time',
    txt_global_std_downloadreport: 'レポートをダウンロード',
    txt_global_std_referafriend: '友だちを紹介する',
    txt_global_std_p2pnoconfirmedbookings: '表示する予約はありません。',
    txt_global_std_p2pyourshareofcost: '費用のあなたの分け前',
    txt_global_std_p2ppushbodycancelledperiodfromto: '{from}からの予約がキャンセルされました',
    txt_global_std_welcometoenerfy: 'あらたなドライブ体験へようこそ。',
    txt_global_std_enerfyanalyticsriskportfoliotrackerapp: 'Enerfy Analyticsリスクポートフォリオトラッカー、アプリ',
    txt_global_std_locationservicemustbeontodrivewithdimmedscreen: 'Location Service must be ON to drive with dimmed screenで走行するには位置情報サービスをオンにしてください。データが 消去されます。',
    txt_global_std_weekly: '毎週',
    txt_global_std_exporttranslations: '翻訳のエクスポート',
    txt_global_std_pleasereachouttothesupportifyouseethesameerrorwhenyoutrynexttimelater: '後ほどお試しの際にもエラーが表示されましたら\nサポートまでお問い合わせください。',
    txt_global_std_tojointeam: 'チームに参加するには:',
    txt_global_std_cimage15h: '細かいところに集中しましょう',
    txt_global_std_numbersonly: '数字のみ',
    txt_global_std_youmustchooseareason: '理由を選択してください',
    txt_global_std_theoreticaltimegain: 'Theoretical time gain理論上の取得時間',
    txt_global_std_TaxInvoice5: 'KINDLY MAKE CHEQUE PAYABLE TO MS FIRST CAPITAL INSURANCE LTD',
    txt_global_std_why: 'なぜ',
    txt_global_std_p2pyourexpenses: 'あなたの追加費用',
    txt_global_std_japanese: '日本',
    txt_global_std_recruiterunlockedkey: '{user}さん、\nお友だちを招待いただき、\nありがとうございます！\nぜひチャレンジや直接対決の\n機能も利用してみてください！',
    txt_global_std_downloadingevents: 'イベントをダウンロード中',
    txt_global_std_drivenotstarted: 'ドライブが開始されていません',
    txt_global_std_sendon: '送信:',
    txt_global_std_updatecarddetails: 'カード情報を更新',
    txt_global_std_policyholderinformation: '保険契約者情報',
    txt_global_std_settingupclientcredentials: 'クライアント資格情報の設定',
    txt_global_std_howmanypeoplewereinjured: 'ケガ人は何人ですか？',
    txt_global_std_writefriendspromocode: '友だちのプロモーションコードを入力',
    txt_global_std_uploadfiles: 'ファイルをアップロード',
    txt_global_std_p2prequestsenttoname: '{name}さんにリクエスト送信済み',
    txt_global_std_constantspeed: '一定のスピード',
    txt_global_std_noteam: 'チームなし',
    txt_global_std_wehavesentatemporarypasswordtoyouremailaddress: 'あなたのメールアドレスに一時的なパスワードを送信しました。',
    txt_global_std_p2pallopenexpenses: '先月までの未精算追加費用',
    txt_global_std_analyzeyourdrivinghabitstoimprove: '運転の癖を分析して運転スキルを改善しましょう。',
    txt_global_std_cimage32d: '',
    txt_global_std_howcanwehelpyou: 'お手伝いしましょうか？\n',
    txt_global_std_chassinumber: 'シャーシ番号',
    txt_global_std_insurancedocuments: '保険書類',
    txt_global_std_p2pacceptedbooking: '規定の料金',
    txt_global_std_yourchallangeranking: '現在のランキング',
    txt_global_std_dec: '12月',
    txt_global_std_enerfyanalytics: 'エネルギー分析',
    txt_global_std_addusers: 'ユーザーを追加する',
    txt_global_std_p2pparking: ' 駐車場代 ',
    txt_global_std_pointsexpires: 'ポイントの有効期限が切れます',
    txt_global_std_yourbonuswillbepaidoutinafewdays: 'ボーナスは数日で支払われます',
    txt_global_std_avg: '平均',
    txt_global_std_underwrittenby: '引受',
    txt_global_std_p2ppushtitleexpensesthismonthquestion: '今月の追加費用はありますか？',
    txt_global_std_goheretocreateabookingofyourfriendscar: '友達の車の予約を作成するには、ここにアクセスしてください。',
    txt_global_std_cimage35d: '例えば、下り坂の場合は高いギアで発進しましょう。',
    txt_global_std_tomasengewhyjoin: '私はハンドルの後ろで最高になるのが大好きです、そして私に加わるすべての人が同じ目標を持っていると確信しています。',
    txt_global_std_youhavenotfinishedanychallengesyet: 'あなたはまだ挑戦を終えていません。',
    txt_global_std_jul: '7月',
    txt_global_std_p2pfilter: 'フィルタ',
    txt_global_std_youremail: 'Eメール',
    txt_global_std_hi123: 'やあ',
    txt_global_std_kintofaq8q: 'どうすれば節約できますか？',
    txt_global_std_civilistatusmarriedcohabitingsingle: '市民のステータス（既婚、同棲、独身）',
    txt_global_std_whenyoudrivesafelyandwithgoodflowweputmoneyinyourpiggybankyouwillbepaidthesewhentheinsuranceyearisovertogetherwithmoneyforkilometersyoumaynothaveusedthetotalamountincludesstartbonusmoneysavedforsafedrivingandmoneyforfriendrecruitment: 'あなたが安全にそして良い流れで運転するとき、私たちはあなたの貯金箱にお金を入れます。保険年度が終了すると、使用しなかった可能性のあるキロメートルのお金と一緒にこれらが支払われます。合計金額には、スタートボーナス、安全運転のために節約されたお金、友人の募集のためのお金が含まれています。',
    txt_global_std_p2p1booking: '（１件の予約）',
    txt_global_std_p2ppushbodylendcar: '{name}さんから貸し出しリクエストが届いています\n',
    txt_global_std_damagereport: '故障レポート',
    txt_global_std_areyousureyouwanttoenablethecommunication: '通信を有効にしてもよろしいですか?',
    txt_global_std_nowitstimetoconnecttoyourcarsbluetooth: 'ビーコンをシガーソケットに接続\nしてください。車のエンジンを\n始動し、Bluetoothがオンになっ\nているのを確認してください。',
    txt_global_std_thisisneededbeforeyoustartyourfirstdrive: 'これは、最初のドライブを開始する前に必要です。',
    txt_global_std_getstartedinnotimewithourtutorials: 'チュートリアルですぐに始めましょう!',
    txt_global_std_p2punblockcar: 'ブロックを解除する',
    txt_global_std_zipdriverriskindexinsuranceratingcomponent: 'Zip-Driver リスク指数 - 保険格付けコンポーネント',
    txt_global_std_topimprovers: 'トップスコア向上者',
    txt_global_std_establishingbluetoothlink: 'Bluetoothリンクを構築',
    txt_global_std_drivewithanaveragescoretypeofscoreorbetterforaweektounlockthisachievementandearnpointspoints: '1週間平均{scoretype}の{score}以上で運転して、この実績を達成し、{points}ポイントを獲得します。',
    txt_global_std_sharelink: '共有リンク',
    txt_global_std_warikan_onboarding6: '2. わりかんKINTOアプリを起動し「新規登録」を選択',
    txt_global_std_insurancerenew: '残りは{km} kmです。あなたの保険は{日付}に更新されます。',
    txt_global_std_p2ppartlyavailable: '一部利用可能',
    txt_global_std_loadmore: 'タップしてさらに読み込む',
    txt_global_std_MikkelsenDescription: 'スポーツ界での経験が豊かなAndreasは、アルペンスキーのナショナル・ジュニアチームのメンバーでした。 2003年と2004年には、スキー同様ナショナル・ジュニアチームのメンバーとしてモトクロスに出場、2014年から2016年には世界ラリー選手権で3位を獲得し、優勝3回と入賞20回を果たしました。',
    txt_global_std_safedriverdiscountpercent: 'セーフドライバー割引{パーセント}％',
    txt_global_std_iunderstand: '了解',
    txt_global_std_MSIG_InsuranceLetter36: 'Claims & Motor Underwriting Dept:',
    txt_global_std_referfriend: '友達を紹介します。',
    txt_global_std_p2presendcode: 'コードを再送する',
    txt_global_std_p2pbecomefriendstoviewthisprofile: 'このプロフィールを表示するには友達になる必要があります。',
    txt_global_std_p2pthisemailadressisusedforustosendcommunication: 'このメールアドレスは、パスワードを忘れた場合などに\nメールを送信するために使用されます。',
    txt_global_std_no: 'いいえ',
    txt_global_std_p2pmytripscomingsoon: '私の旅行-近日公開',
    txt_global_std_pigwonunlocked: 'おめでとう！あなたはhead2headで競争に勝ちました',
    txt_global_std_registrationnumber2: '登録番号',
    txt_global_std_notyetdecided: 'Not yet decided, 決定されていません',
    txt_global_std_driversidentificationnumber: 'ドライバーのID番号',
    txt_global_std_insuranceidcard: '保険証',
    txt_global_std_repeatchallenge: '再チャレンジ？',
    txt_global_std_rewardandendorsesmartbehavior: 'スマートな行動に報酬を与え、支持する',
    txt_global_std_KleinschmidtFacts: 'She is the first and so far the only woman who has ever taken outright victory in the longest and toughest rally in the world, the Dakar Rally. Her racing career started with motorcycle. After 4 rallies on motorbike she changed to 4 wheels in 1995, and became involved in the development of the most successful rally car, the Mitsubishi Pajero Evo, in which she won the Dakar in 2001. Beside her job as a speaker, she has published her book “Mein Sieg bei der Dakar” – My win in Dakar.',
    txt_global_std_MSIG_InsuranceLetter10: 'Vehicle No / Chassis No',
    txt_global_std_qwhathappensififorgettorenewbeforemyinsurancehasexpired: 'Q：保険の有効期限が切れる前に更新を忘れた場合はどうなりますか？',
    txt_global_std_community: 'コミュニティ',
    txt_global_std_estimateddiscount: '保険料の割引率（推定）',
    txt_global_std_score2: 'スコア',
    txt_global_std_addprice: '価格を追加',
    txt_global_std_p2phowmuchdoyouwanttocharge: 'いくらチャージしますか？',
    txt_global_std_p2pkintonumber: 'キントナンバー',
    txt_global_std_p2pfaq7a: '1.\tホーム画面「確定した予約」から該当の予約を選択します。\n2.\t左上の「予約をキャンセル」を選択します。',
    txt_global_std_kintofaq5q: '車を変更しましたが、どうすればよいでしょうか？',
    txt_global_std_Fia_Receipt_Text1: 'Your purchase is completed!',
    txt_global_std_postalcode: '郵便番号',
    txt_global_std_weareinthischallengetomaketheworldsaferandbettertogetherjoinustohelpmakeanimpact: '私たちは、世界をより安全でより良いものにするために、この課題に取り組んでいます。インパクトを与えるために私たちに参加してください！',
    txt_global_std_anotheappworksinbackgroundmodemakesureyoualwayskeepbluetoothandlocationservicesactivatedonyourphone: 'A：いいえ。アプリはバックグラウンドモードで動作します。スマートフォンのBluetoothと位置情報サービスが常に「オン」になっていることを確認してください。',
    txt_global_std_p2pstatus: '状態',
    txt_global_std_p2pshareofmonthlyfee: '月額料金のシェア',
    txt_global_std_rwmselectthetripsyouwereridesharing: 'ライドシェアした運転を選択して下さい​',
    txt_global_std_ecoscore123: 'エコスコア',
    txt_global_std_p2pgreatyourcostsplitwassenttoyourfriends: 'すごい！あなたの費用分割はあなたの友人に送られました。',
    txt_global_std_activationletter_text14: 'アプリの指示に従ってください。',
    txt_global_std_advancedadmin_upsell_pitch_bulletpoint3: 'ドキュメント、ポリシー、その他のインテリジェントな管理ツールがすべて 1 か所に',
    txt_global_std_youarebeingredirectedtodashboard: 'ダッシュボードにリダイレクトされています。',
    txt_global_std_sportscorescore: 'スポーツスコア {score}',
    txt_global_std_yourlatestscore: 'あなたの最新スコア',
    txt_global_std_MSIG_InsuranceLetter11: 'Name of Insured',
    txt_global_std_codewasnotfound: 'コードが見つかりませんでした',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimeincreasingspeedreadingtheroadaheadcanhelpyoudeterminewhetheritissuitabletobuildspeed: 'このタイプの旅行では、平均的なエコ チャンピオンは速度を上げるのに費やす時間が短くなりました。この先の道のりを読むことは、それが速度を上げるのに適しているかどうかを判断するのに役立ちます。',
    txt_global_std_p2pbluetoothconnectionrequired: 'Bluetooth接続が必要です。',
    txt_global_std_topteamdrivers: 'トップチームドライバー',
    txt_global_std_mycar: 'マイカー',
    txt_global_std_weeks: '週',
    txt_global_std_cimage13d: 'Let your speed decrease go down slightly going up hill.',
    txt_global_std_km: 'km',
    txt_global_std_p2ptoyotawalletcomingsoon: 'トヨタウォレット（近日発売）',
    txt_global_std_weresettingupyourchallenge: '私たちはあなたの挑戦を設定しています',
    txt_global_std_greatrun: '素晴らしい走り',
    txt_global_std_welcometocovercube: 'Covercubeへようこそ！',
    txt_global_std_sidekickhelpsyouwiththemotivationandtipstodrivebettersaferandmoreecofriendly: 'Sidekickは、より良く、より安全に、より環境にやさしい運転をするための動機とヒントを提供します。',
    txt_global_std_bysigningupiacceptthetermsandconditionsandprivacypolicy: 'サインアップすることにより、利用規約に同意します\nおよびプライバシーポリシー。',
    txt_global_std_p2ppushbodynewmessage: '新しいメッセージの最初の行',
    txt_global_std_JyrkiainenMusic: 'Pop and rap music.',
    txt_global_std_p2ptimes: 'タイムズ',
    txt_global_std_totalvalid: '合計',
    txt_global_std_2pairyourphonewithyourcarsbluetoothsystem: '2.電話を車のBluetoothシステムとペアリングします。',
    txt_global_std_schedulemessage: 'スケジュール メッセージ',
    txt_global_std_activatebluetooth: 'Bluetoothをオン',
    txt_global_std_p2paddyourexpensesbeforethelastdayofthemonthtogetthemincludedinthenextcostsplit: '追加費用は月末までに \n記録してください。',
    txt_global_std_p2pcurioustoknowhowyoudrivekeeptrackofyourprogressandsestatsaboutyourscoreco2savingsdistanceandmore: 'あなたがどのように運転するか知りたいですか？進捗状況を追跡し、スコア、CO2節約、距離などに関する統計を確認します。',
    txt_global_std_rwmsendinvitelink: '招待リンクを送信する​',
    txt_global_std_p2pdocumentmycarcomingsoon: '車両について報告する-近日公開',
    txt_global_std_p2pmessagedeleted: 'メッセージが削除されました！',
    txt_global_std_clickthroughtheappintheframeandfindthetranslationyouwanttoeditorfindtheminthelistontherightside: 'フレーム内のアプリをクリックして、編集する翻訳を見つけるか、右側のリストで見つけます。',
    txt_global_std_allset: '準備完了！',
    txt_global_std_p2pwaitingforacceptance: '承諾待ち',
    txt_global_std_createaccounttext: '新規登録',
    txt_global_std_costsplit: 'コスト分割',
    txt_global_std_p2pcancelledthebooking: 'リクエストは承諾されませんでした',
    txt_global_std_help: 'ヘルプ',
    txt_global_std_youcannowclosethiswindoworreturntothedashboard: 'これで、このウィンドウを閉じるか、ダッシュボードに戻ることができます。',
    txt_global_std_getshareabledomainlink: '共有可能なドメインリンクを取得する',
    txt_global_std_lowrisk: '低リスク',
    txt_global_std_tttest98: 'これはテストです 98',
    txt_global_std_p2pcostsplitisbasedonkilometersdriven: 'コスト分割は、走行距離に基づいています。',
    txt_global_std_pleasechooseareason: '理由を選択してください',
    txt_global_std_selectedreader: '選択したリーダー',
    txt_global_std_domainrestrictedinvitelink: 'ドメイン制限付き招待リンク',
    txt_global_std_challenge2: 'チャレンジ',
    txt_global_std_startcarengineandpleasemakesurebluetoothoncarsideisenabledifthisisyourfirsttimeconnectingyourphonetocarpleaseconnectbluetoothfromcarside: '車のエンジンを始動して、\n' +
      '車側のBluetoothがオンに\n' +
      'なっていることを\n' +
      '確認してください。\n' +
      '初回接続の場合、車から\n' +
      'スマートフォンにBluetooth\n' +
      '接続してください。',
    txt_global_std_10discountonrenewal: '更新時に10％割引',
    txt_global_std_treessaved: '保存された木',
    txt_global_std_JyrkiainenQuote: 'FIA Smart Driving Challenge is a great way to show that environmental questions also matter in motorsport. Compete against your friends and drive smart!',
    txt_global_std_couldnotvalidateyourcode: 'コードを有効化できません！コードを確認して再入力してください！ ',
    txt_global_std_preview1: 'プレビュー',
    txt_global_std_tojoingroup: 'グループに参加するには：',
    'txt_global_std_costofspeedabove{speed}': '{speed}以上の速度にかかる費用',
    txt_global_std__entertheemailthatyouusedwhenyouregisteredyourinsurance: '保険の登録時に使用したメールアドレスを入力してください。',
    txt_global_std_above1000streakunlocked: '週間平均スコア1150以上=5000ポイント\n',
    txt_global_std_apitool_upsell: 'API ツール – 開発者がデータ統合を行うためのゲートウェイ。',
    txt_global_std_choosenumberbetween1and9: '1 から 9 までの数字を選択してください',
    txt_global_std_importtranslations: '翻訳のインポート',
    txt_global_std_renewyourinsuranceformonthandgetadiscountonyourpremium: '{month}月の保険分が更新されました',
    txt_global_std_compareyourtripresultstothebestecodriverswecallthemecochampions: 'あなたの旅行結果を最高のエコドライバーと比較してください。私たちは彼らをエコチャンピオンと呼んでいます。',
    txt_global_std_signup1: '新規登録する。',
    txt_global_std_notvalidchassinumber: '無効なシャーシ番号',
    txt_global_std_describepurpose: '目的を説明する',
    txt_global_std_latesttripdate: '最新の旅行: {date}',
    txt_global_std_insurancecustomer: 'Insurance customer',
    txt_global_std_connecttoanothercar: '別の車に接続する',
    txt_global_std_KleinschmidtMusic: 'When driving I prefer to listen to the radio and to information.',
    txt_global_std_reviewchanges: '変更の確認',
    txt_global_std_p2puserexpenses: '{name} の追加費用',
    txt_global_std_partnercode: 'パートナーコード',
    txt_global_std_bluetoothsettings: 'Bluetooth設定',
    txt_global_std_p2pincluded: '含む',
    txt_global_std_p2pstarttime: '開始時間：',
    txt_global_std_rwminviteanewfriend: '新しい友だちを招待する',
    txt_global_std_calculateyourco2savings: 'CO2 削減量を計算する',
    txt_global_std_currentbonusperiod: '現在のボーナス期間：',
    txt_global_std_200km: '200 Km',
    txt_global_std_youreceivedarenewalofferformonth: '{month}の更新オファーを受け取りました。',
    txt_global_std_activationcodestartdate: 'アクティベーションコードの開始日',
    txt_global_std_previous: '過去',
    txt_global_std_uploadphotosofthedamagesorscratchesonyourcar: '故障、またはキズの写真をアップロードしてください。',
    txt_global_std_yourcarmodelistoooldanddoesnthavebluetooth: '•車のモデルが古すぎてBluetoothが搭載されていない。',
    txt_global_std_contributesustainabilitythroughwarikanbody: '環境にやさしい運転を意識しながら楽しくドライブしませんか？わりかんKINTOはそんなドライブもお手伝いします！スコア機能とバッジ獲得はKINTO ONEご契約者以外の皆さんにもお試しいただけます',
    txt_global_std_cityconditions: '都市条件',
    txt_global_std_cimage18d: '計画的な平均速度で最高速度よりも時間を稼げます。',
    txt_global_std_1startyourcar: '1．  車のエンジンを始動します',
    txt_global_std_accessdeniedyoudonothavepermissiontoaccesstheworkspacepleasecontactyouradministratorifincorrect1: 'ワークスペースにアクセスする権限がないため、アクセスが拒否されました。管理者に連絡してください。',
    txt_global_std_yourchangesaresaved: '変更が保存されました。',
    txt_global_std_challengeleaderboard_upsell: 'チャレンジ ダッシュボード – カスタム チャレンジで安全運転を奨励します。',
    txt_global_std_distancekm1: '走行距離 (km)',
    txt_global_std_p2pthispasswordhasexpired: 'パスワードの期限が切れました',
    txt_global_std_p2psendinvoices: '費用を請求する',
    txt_global_std_HansenFacts: 'Kevin Hansen, the youngest ever FIA European Supercar champion and 2016 FIA Rookie of the year',
    txt_global_std_drivers2: 'ドライバー',
    txt_global_std_p2pfaq26a: '1.\tホーム画面から「予約」を選択します。\n' +
      '2.\t「クルマを貸す」を選択します。\n' +
      '3.\tクルマを貸し出したいメンバーを選択します。\n' +
      '4.\t日時を選択します。\n' +
      '5.\tメッセージを入力し（任意）最後に「リクエストを送信する」を選択します。\n' +
      '6.\t貸し出しリクエストはメンバーが承認、または拒否するまで保留されます。',
    txt_global_std_doyouwanttoresetthepasswordforuser: '{user}のパスワードをリセットしますか？',
    txt_global_std_2makesureyourphoneispairedtoyourcarviabluetooth: '2.スマートフォンがBluetooth経由で車とペアリングされていることを確認します。',
    txt_global_std_carsharingemptyviewborrowed: 'Lend out the car and it will be a bit more fun!',
    txt_global_std_summaryoftripsperslectecdperiod: '選択した期間ごとの旅行の概要。',
    txt_global_std_newreport: '新しいレポート',
    txt_global_std_cardwithmoreinformationaboutpermissions: '権限に関する詳細情報が記載されたカード',
    txt_global_std_p2pbookingof: '予約した車',
    txt_global_std_MSIG_Onboarding7: '1. Click on the link you received by text.',
    txt_global_std_qhowdoestheappwork: 'Q：アプリにはどのような機能がありますか？',
    txt_global_std_kintoloyaltysystemdesc: '特典は、前回のロイヤルティ期間の最終結果に基づいて付与されます。ポイントは成績によって付与され、新しい期間が始まるとリセットされます。',
    txt_global_std_p2pyourfriendinvitedyoutoborrowtheircar: 'あなたの友人はあなたに彼らの車を借りるように誘った。',
    txt_global_std_place: '場所',
    txt_global_std_inorderforustoshowyouvariousstatisticsitisimportantthatthephoneissettouselocationalways: 'さまざまな統計情報を表示するには、電話が「常に」位置情報を使用するように設定されていることが重要です。',
    txt_global_std_gotosettingsonyourcarscreenandpairthebluetoothwithyourphone: '車の画面の設定に移動し、Bluetooth をスマートフォンとペアリングします',
    txt_global_std_cimage6h: '不要な追い越しはやめましょう',
    txt_global_std_youhavenotenabledyourprivacyundersettings: '設定の\\privacy\\ を有効にしていません。 \\privacy\\が有効でないと、アプリで提供する楽しいコンペやアドバイスを受信できません。 \\privacy\\ を有効にして、コミュニティに参加しましょう。',
    txt_global_std_downloadcsvtemplate: 'CSVテンプレートをダウンロード',
    txt_global_std_countryroadroundabout2DESC: '赤い車のように、高速で交差点に入ってしまっています。青い車のように、適切に速度調整できると理想的です。',
    txt_global_std_cimage12d: '',
    txt_global_std_pleaseenteravalidcellphonenumber: '有効な携帯電話番号を入力してください',
    txt_global_std_sendorder: '注文を送信',
    txt_global_std_brokenitems: '壊れたアイテム？',
    txt_global_std_selectatemplatedesc: 'このテンプレートをアプリのバッジとして使用します。次のステップでカスタマイズします。',
    txt_global_std_sendlink: 'メンバーを招待する',
    txt_global_std_leavewithoutchanges: '変更せずに残す',
    txt_global_std_toestablishaconnectiontoyourcarappnameneedstoaccesstobluetooth: '車と接続するには、{appname}がBluetoothにアクセスする必要があります。',
    txt_global_std_p2pleavethisgroup: 'グループを退出する',
    txt_global_std_PolicySchedule14: 'Named Drivers',
    txt_global_std_p2pbookingnotifications: '予約通知',
    txt_global_std_bestdriversoftheday: 'その日の最高のドライバー',
    txt_global_std_customtabtext: 'カスタム実績',
    txt_global_std_MarklundWhyjoin: 'We’ll make a difference towards the better only by changing our driving style. In my team we’ll focus on making difference, with the aim to win and on the way there, we’ll have fun. I like competitions and I like winning, but I also like to see my future kids grow up in a safe and healthy place with me there. That’s what’s most important. \n',
    txt_global_std_thepersonyouinvitegetaccessyoalldrivesandcost: '招待された人は、あなたのトリップや保険料を利用できます。',
    txt_global_std_drivewithanaverageofscoreorbetterforaweektounlockthisachievementandearnpointspoints: '1週間平均{score}以上で運転して、この成果を達成し、{points}ポイントを獲得してください。',
    txt_global_std_advancedadmin_upsell_pitch: '独自のデジタル カスタマー サクセス ジャーニーを簡単にナビゲートします。',
    txt_global_std_answer: '回答',
    txt_global_std_thursday: '木曜日',
    txt_global_std_cimage40h: 'EVERY ADDITIONAL METER ROLLED IMPROVES RESULT',
    txt_global_std_simplyprovideyourpersonaldetailssowecansetupyouradminaccountnextyoullbeabletostartaddingteammemberstojoinyourfiasdccompanychallenge: '管理者アカウントを設定できるように、個人情報を入力するだけです。次に、FIA SDC Company Challengeに参加するために、チームメンバーの追加を開始できるようになります。',
    txt_global_std_datetimedistance: '日付、日時、距離、',
    txt_global_std_identifyfollowupandreportonsafetyandsustainabilitytargets: '安全性と持続可能性の目標を特定し、フォローアップし、報告する',
    txt_global_std_sharecertificate: '認定証をシェアする',
    txt_global_std_coporateoffice: '会社所在地',
    txt_global_std_p2pemailstartingsoon: 'しばらくお待ちください。',
    txt_global_std_p2pfaq27q: '管理者権限とはなんですか？',
    txt_global_std_firstdrive: '最初のドライブ',
    txt_global_std_acaddyourbrandcolors: 'ブランドカラーを追加',
    txt_global_std_improvementpotential: '改善の余地',
    txt_global_std_thepasswordcontainsforbiddencharactersorspace: "パスワードに禁止文字が含まれています： '？ ＆ / &#60;  &#62; またはスペース",
    txt_global_std_typeofachievement: '実績の種類',
    txt_global_std_finishdrive: '運転を終了する​',
    txt_global_std_youshouldaddapictureofyourodometer: '走行距離計の写真を追加する必要があります',
    txt_global_std_connectingtogps: 'GPSに接続中',
    txt_global_std_app: 'アプリ',
    txt_global_std_Fia_Receipt_Text2: 'Click the link to start competing in FIA SDC:',
    txt_global_std_2nddate: '2日',
    txt_global_std_startd: '開始',
    txt_global_std_youcanalsouploadpicturesthatmightberelevant: '事故の際に役立つ写真をアップロードできます。',
    txt_global_std_missingbonuspayout: 'ボーナスの支払いがありません',
    txt_global_std_publish: '公開',
    txt_global_std_daysshort: '日',
    txt_global_std_areyoudriving: '運転していますか？',
    txt_global_std_timelefttoreactivateinsurance: '保険を再開するための残り時間',
    txt_global_std_fivereasonswhyjoiningfiasdcisasmartbusinessdecision: 'FIASDCに参加することが賢明なビジネス上の決定である5つの理由：',
    txt_global_std_orenteractivationcode: 'またはアクティベーションコードを入力',
    txt_global_std_youdonothaveanyongoingchallengesatthemoment: '現在進行中の課題はありません。',
    txt_global_std_locationserviceneedstobeontoworkcorrectly: 'マップが正確に機能するよう、位置情報サービスをオンにしてください。',
    txt_global_std_forthisapptoworkproperlyweneedtoaccess: '運転履歴を記録するには​\n' +
      'Bluetooth、位置情報サービス、​\n' +
      'モーションフィットネスを​\n' +
      'オンにしてください。​\n' +
      'これらの個人情報は第三者に​\n' +
      '共有されることはありません。',
    txt_global_std_translationtool_upsell: '翻訳ツール – 独自の方法で伝えることにより、ブランドの価値を反映します。',
    txt_global_std_cimage23d: 'Every additional meter rolled have an impact on speed decrease or stop. 走行距離が増えると減速あるいは停止します。',
    txt_global_std_analyzingdotdot: '分析しています...',
    txt_global_std_insureddistance_used_100__3: 'Oi! Se till att du inte riskerar förkortning vid skador. Justera körsträckan så att den är korrekt.',
    txt_global_std_youmayaddaphotolater: '写真はあとで追加できます',
    txt_global_std_sendassms: 'SMSとして送信',
    txt_global_std_selectinsurancetoterminate: '解約する保険を選択する',
    txt_global_std_nowitstimetosetupyourcompanychallenge: 'それでは、会社のチャレンジを設定しましょう。',
    txt_global_std_youvehavegatheredatotalof: '合計で',
    txt_global_std_alsochallengeyourselfandengageyourfriendsinaworldcompetition: 'また、自分自身に挑戦し、友達を世界大会に参加させましょう。',
    txt_global_std_youmusthaveinternetconnection: 'スコアをライブで受信するにはインターネット接続が必要です。',
    txt_global_std_p2pnomessagesyet: 'メッセージはまだありません。',
    txt_global_std_invalidinput: '無効入力',
    txt_global_std_cimage7h: 'クルージング速度まですばやく',
    txt_global_std_p2pdenyy: '拒否',
    txt_global_std_surcharge: '追加料金',
    txt_global_std_p2pgotosupport: 'サポートに移動',
    txt_global_std_MSIG_InsuranceLetter43: 'SGD1,000.00すべての請求は、暦年で少なくとも14日間ドングルを無効にしたドライバーに課されます。',
    txt_global_std_cimage16h: '加速は計画的に。',
    txt_global_std_timeinacceleration: '加速した時間',
    txt_global_std_planning: '計画',
    txt_global_std_p2ptimeused: '使用時間',
    txt_global_std_whenyouvestartedadriveyoucanseeyourcarslocation: '運転を開始すると、車の位置が表示されます。',
    txt_global_std_myfriends: '友だち',
    txt_global_std_1000drive: 'ハイタッチ:',
    txt_global_std_cimage26h: '低回転数でのギア',
    txt_global_std_p2pyoublocked: 'ブロックしました。',
    txt_global_std_reportaccident2: '事故を報告する',
    txt_global_std_bycheckingthisboxandlogginginyoureacceptingour: 'このチェックボックスをオンにしてログインすると、',
    txt_global_std_wesharethislater: '後ほど共有します…',
    txt_global_std_syncingwithyourgarminconnectaccountiseasyclicktheconnectbuttonbelowtohelpusuncovernewinsightsintocrashriskbyanalyzingbothdrivingandhealthdata: 'Garmin Connect™ アカウントとの同期は簡単です。下の [接続] ボタンをクリックして、運転データと健康データの両方を分析することにより、衝突リスクに関する新しい洞察を明らかにするのに役立ててください。',
    txt_global_std_showadvancedsettings: '詳細設定を表示する',
    txt_global_std_rewardthosewhoexcel: '優秀な人にご褒美を！',
    txt_global_std_kintofaq6a2: '',
    txt_global_std_uploadintervalminutesmin: '{uploadIntervalMinutes} 分',
    txt_global_std_p2penterkintonumber: 'キント番号を入力してください',
    txt_global_std_startadrivetogetascore: 'ドライブを開始してスコアをゲット！',
    txt_global_std_orgenerateinvitationcodeandlinkformultipleuse: 'または、複数回使用するための招待コードとリンクを生成します',
    txt_global_std_atrustedscorevettedbythefia: 'FIAによって精査された信頼できるスコア',
    txt_global_std_timetoconnecttoyourcar: '車に接続する時間',
    txt_global_std_MSIG_InsuranceLetter26: 'The Policy does not cover use for hire or reward, racing, pacemaking, reliability trial, speed-testing, the carriage of goods other than samples in connection with any trade or business or use for any purpose in connection with the Motor Trade.',
    txt_global_std_acontactyourinsuranceagencytoterminateyourinsurance: 'A：保険を終了するには、保険代理店に連絡してください。',
    txt_global_std_selectinsurance: '保険を選択する',
    txt_global_std_nameunavailable: '名前がありません',
    txt_global_std_p2pwarningdeletebooking: '一度消去した予約は元に戻せません。',
    txt_global_std_numberoftimes: '何度か',
    txt_global_std_menu: 'メニュー',
    txt_global_std_enteravalidlicenseplate: '有効なナンバーを入力してください',
    txt_global_std_tuesday: '火曜日',
    txt_global_std_endinsuranceforname: '{名前}の保険を終了しますか？',
    txt_global_std_enerfyanalytics_upsell_bulletpoint3: 'データからより多くの価値を引き出して ROI を改善する',
    txt_global_std_greatprogress: '大きな進歩です！',
    txt_global_std_manageyourgroups: 'グループを管理する',
    txt_global_std_colleaguespartnersandinvitedguestscanjoinyourbrandedworkspace: '同僚、パートナー、招待客がブランドのワークスペースに参加できます',
    txt_global_std_enablepushnotifications: 'プッシュ通知を有効にする',
    txt_global_std_messages: 'メッセージ',
    txt_global_std_p2puploadaprofileimage: 'プロフィール画像をアップロードする',
    txt_global_std_createyourownworkspaceinthefiasmartdrivingchallengeyoucancreatemanyworkspacessuitableforcustomersandotherpartnersinviteandgettoknowmoreaboutdrivingandyourco2imprint: 'FIAスマートドライビングチャレンジで独自のワークスペースを作成します。顧客や他のパートナーに適した多くのワークスペースを作成できます。運転とあなたのCO2インプリントについてもっと知りましょう。',
    txt_global_std_makeapayment: '支払いをする',
    txt_global_std_dashboard: 'ダッシュボード',
    txt_global_std_addyourlogotypeandchoosecolorthemeexpandtoseeapreviewofthechallenge: 'ロゴタイプを追加し、カラー テーマを選択します。展開してチャレンジのプレビューを表示します。',
    txt_global_std_selectaparent: '親を選択してください',
    txt_global_std_accept: '参加する',
    txt_global_std_p2pfaq27a: 'メンバーにオーナーの権限の一部を与えることができます。\nできること：予約の管理（他のメンバーからの予約の承認・拒否、予約時間の変更など）',
    txt_global_std_bluetoothid: 'Bluetooth ID',
    txt_global_std_timelocationaretheonlymandatoryfieldspleaseuploadpicturesofyourcarwithin24hours: '時間と場所のみが必須フィールドです。 24時間以内にあなたの車の写真をアップロードしてください。',
    txt_global_std_cantfindyourevent: 'チャレンジが見つかりませんか？',
    txt_global_std_step: 'ステップ',
    txt_global_std_privacypolicy: '個人情報保護方針',
    txt_global_std_improveyourdrivingandtheplanet: 'あなたの運転と地球を改善する',
    txt_global_std_pleasetryagain: 'もう一度やり直してください',
    txt_global_std_youcanfindotheruserguidesifyougotothefaqinthemoremenu: '詳細メニューのFAQにアクセスすると、他のユーザーガイドを見つけることができます。',
    txt_global_std_remembertotakepicturesofthedamageandsavethedatetimeandplace: '損傷の写真を撮り、日付、時間、場所を保存することを忘れないでください。',
    txt_global_std_topecodriver: 'トップエコドライバー',
    txt_global_std_p2pclosethispopup: 'ポップアップを閉じる',
    txt_global_std_highestscore: '最高得点',
    txt_global_std_totaldistance3: '総距離',
    txt_global_std_pushdriveendlevel3: 'Good drive. Tap me to see your feedback.',
    txt_global_std_viewmarketingmessages: 'アクティビティのニュースを見る',
    txt_global_std_p2pfaq28q: 'メンバーに予約管理者としての権限を与えたい。',
    txt_global_std_logininformation: 'Login information',
    txt_global_std_bonus: 'ボーナス',
    txt_global_std_uploadyourlogoandaddyourcolorstomaketheappyourown: 'ロゴをアップロードし、色を追加して、アプリを独自のものにします。',
    txt_global_std_activepoints: 'アクティブポイント',
    txt_global_std_addyourpolicynumbertobeabletoenjoyallthebenefitsandclaimyourrewards: 'ポリシー番号を追加して、すべての特典を享受し、特典を請求できるようにしてください。',
    txt_global_std_rewards: 'ポイント交換',
    txt_global_std_yourthreemostimportantdrivingtips: '運転の際の重要な3ポイント',
    txt_global_std_KleinschmidtQuote: 'Together we can make the world a better place! Join this challenge and be part of a great contribution to environmentally friendly and safer driving. Improve your smart driving skills, have a lot of fun and an exciting competition!',
    txt_global_std_avgdriver: '世界平均',
    txt_global_std_top3bestdriverscomingsoon: 'すぐに来るトップ3の最高のドライバー',
    txt_global_std_avgcosavings: '平均CO₂の節約',
    txt_global_std_p2pminimumbookingtime: '最小予約時間は30分です',
    txt_global_std_p2pchoosecar: '車を選ぶ',
    txt_global_std_syncedwithgarminconnect: 'Garmin Connect™ と同期',
    txt_global_std_entertheactivationcodeyouhavereceivedfromyourworkspaceadministrator: 'ワークスペース管理者から受け取ったアクティベーション コードを入力してください',
    txt_global_std_send2: '送信',
    txt_global_std_removingaparticipantfromachallengedoesnotdeletetheuserortheirdrivingdataintheappiftheyhavealreadydriven: 'チャレンジから参加者を削除しても、すでに運転している場合は、アプリ内のユーザーまたはその運転データは削除されません。',
    txt_global_std_readmore1: '続きを読む',
    txt_global_std_carstats: '車の統計',
    txt_global_std_comingup: 'まもなく',
    txt_global_std_viewall: 'すべて見る',
    txt_global_std_showonmap: 'マップ上で表示する',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint3: 'データからより多くの価値を引き出して ROI を改善する',
    txt_global_std_activationcodeemail2: 'これがあなたのアクティベーションコードです',
    txt_global_std_syncyourgarminconnectaccount: 'Garmin Connect™ アカウントを同期する',
    txt_global_std_changepassword: 'パスワードを変更する',
    txt_global_std_garmin15discountcodeforparticipantsandprizesformonthlywinners: '参加者には Garmin 15% 割引コード、毎月の当選者には賞品を進呈。',
    txt_global_std_sooncompleted: 'まもなく完了！',
    txt_global_std_maycontactmeonphoneemailandsms: 'はい, 保険、事故予防商品、コンペ、その他のサービスに関するニュースやオファーの受信についてGreater Thanからの電話、Eメール、{appName}アプリ、SMSでのコンタクトに同意します。You can always please read and preferred your references for marketing.',
    txt_global_std_skilluplockedkey: 'このバッジを獲得するには、スコアを{score}以上に1週間維持してください。',
    txt_global_std_mypage: 'マイページ',
    txt_global_std_MSIG_InsuranceLetter15: 'To',
    txt_global_std_ChicheritMileage: 'Coming soon...',
    txt_global_std_insurancenumber2: 'Ins. number',
    txt_global_std_activitydetails: 'アクティビティの詳細',
    txt_global_std_lockeddescription: 'バッジが<br />まだ受け取っていない場合はロックされています',
    txt_global_std_TomczykQuote: 'The FIA smart driving challenge encouraged me to drive more economical, safer and with more attention. After your drive, you can see in realtime how good you drove and compare it to other drivers all around the world. \n' +
      'Join my team and let us achieve the maximum points together. Challenge yourself and try to be the best smart driver in the world.',
    txt_global_std_level2: 'レベル',
    txt_global_std_ChicheritBesttip: 'Coming soon...',
    txt_global_std_p2plendborrow: '予約',
    txt_global_std_p2pchoosecarcarstolend: '貸す車を選ぶ',
    txt_global_std_newchallenge: 'チャレンジの新規作成',
    txt_global_std_p2pfaq28a: 'メンバーを選んで、クルマの管理者権限を与えることができます。\n' +
      '1.\t「メニュー」から「メンバー」を選択します。\n' +
      '2.\tメンバーを選び、プロフィールを表示します。\n' +
      '3.\t「設定」を選択します。\n' +
      '4.\t権限を与えたいクルマのボタンを操作し、権限を与えます。',
    txt_global_std_yourobdreaderhasalreadybeenorderedandyoucanexpectthereadertoarrivewithin35businessdays: 'OBDリーダーはすでに注文されており、リーダーは3〜5営業日以内に到着する予定です。',
    txt_global_std_p2ppushtitleborrowcarnomessage: '予約リクエストが届きました',
    txt_global_std_regno2: '登録番号',
    txt_global_std_PaddonMusic: 'When driving, I’d like to listen to relaxing/mellow music like Coldplay, Mumford and Sons etc.',
    txt_global_std_sdclevel: 'SDC レベル',
    txt_global_std_ifyoudonthavebluetoothinyourcaryoucanpluginaobdreaderanobdreadercanbepluggedintocarsmanufacturedyear2001andlater: '車にBluetoothが搭載されていない場合は、OBDリーダーを接続できます。\n\nOBDリーダーは、2001年以降に製造された車に接続できます。',
    txt_global_std_validateyourdata: 'データを検証する',
    txt_global_std_kintoEncouragement2: '地球にやさしい運転です。平均と比較して {percent}% 節約しています。',
    txt_global_std_entercodehere: 'ここにコードを入力してください',
    txt_global_std_youbooked15timesinonemonthyouhaveearned3500points: '1か月に15回予約しました。あなたは3500ポイントを獲得しました。',
    txt_global_std_p2pgotomykinto: '私のキントに行く',
    txt_global_std_enteremailaddressyoucanentermorethanoneifyouseparatethembyacomma: 'メールアドレスを入力してください。カンマで区切ると複数入力できます。',
    txt_global_std_trytheapp: 'アプリをお試しください',
    txt_global_std_firstdrivelocked: '初めて運転するとこの目標を達成できます。',
    txt_global_std_onceaclaimsettlementisdeterminedyoucanreceiveacheckoranelectronicpaymentintoyourbankaccount: '請求の決済が決定されると、銀行口座に小切手または電子支払いを受け取ることができます。',
    txt_global_std_disabled: '無効',
    txt_global_std_youmustpickaninsurance: '保険を選択してください',
    txt_global_std_changenow: '今変更する',
    txt_global_std_areyousureyouwanttoremoveuserfromthegroupremovingtheuserfromthegroupdoesnotdeletetheusersaccount: 'グループからユーザーを削除してもよろしいですか？\n\nグループからユーザーを削除しても、ユーザーアカウントは削除されません。',
    txt_global_std_earn2500pointsifyoubook10timesamonth: '月に10回予約すると2500ポイント獲得',
    txt_global_std_p2perrorcodestartofbookingisoverlapping: '予約開始時間が重複しています。',
    txt_global_std_createaccount: '新規登録',
    txt_global_std_activationcode2: 'アクティベーションコード',
    txt_global_std_thisisatranslation: 'これは翻訳です。',
    txt_global_std_p2pseefriendcode: 'フレンドコードを表示または引き換える',
    txt_global_std_activatebluetoothinsettings: 'スマートフォンでBluetoothを有効にする',
    txt_global_std_challengeaverage2: 'チャレンジ平均:',
    txt_global_std_usermanagement: 'グループ経営',
    txt_global_std_publishyourdemoapp: 'デモアプリを公開する',
    txt_global_std_dateoftraining: '運転指導日',
    txt_global_std_saveandcontinue: '保存して続ける',
    txt_global_std_maxcharacters: '最大。 15文字',
    txt_global_std_locationofaccident: '事故の場所',
    txt_global_std_leaderboardoverview: 'リーダーボードの概要',
    txt_global_std_thechallengenamecantbelongerthan30characters: 'チャレンジ名は30文字を超えることはできません',
    txt_global_std_createnewachievement: '新しい実績を作成する',
    txt_global_std_matched: '一致',
    txt_global_std_pleasereportyouraccidentusingtheclaimsformassoonaspossible: 'できるだけ早くクレームフォームを使用して事故を報告してください。',
    txt_global_std_retardationdescription: '計画的にドライブプランを立てましょう。アクセルを早めに話してエネルギーをセーブしましょう。',
    txt_global_std_cropyourteamimage: 'Crop your team image',
    txt_global_std_p2ppleaseselectadate: '日付を選択してください',
    txt_global_std_examplephone: '12 34 56 78',
    txt_global_std_onboardingnotificationstext: 'アプリで実行するアクションがある場合に、​\n{appName}が通知を送信できるようにします。',
    txt_global_std_verygooddriving: '運転がとても上手です！',
    txt_global_std_p2ppushbodychangeperiodfromto: '{name}さんが予約日時を変更しました',
    txt_global_std_motorclaimshotline2: 'モータークレームホットライン',
    txt_global_std_uploadphoto: '写真を選択',
    txt_global_std_challengeleaderboard: 'チャレンジリーダーボード',
    txt_global_std_doyouhaveanymileagethatyoufancy: 'Do you have any mileage that you fancy the most to drive, why this one?',
    txt_global_std_byinvitingfriends: '友だちを招待すると、',
    txt_global_std_kintoImprovement1: '注意して運転してください！Kintoが見守っています！',
    txt_global_std_p2pyoublockedyourcar: '予約をブロックしています。',
    txt_global_std_skillupunlockedkey: '素晴らしい仕事{ユーザー}！スコアを{score}以上に1週間維持しました。',
    txt_global_std_100000pts: '> 100000ポイント',
    txt_global_std_p2pforfamilyfriends: '家族&友だち向け',
    txt_global_std_foryourowndocumentation: '車両登録用',
    txt_global_std_OgierMileage: 'Coming soon...',
    txt_global_std_jun: '6月',
    txt_global_std_TidemandFacts: 'Pontus’ first taste of competitive came in cross-karts, where he became both Nordic and Swedish champion. 2011, the youngest driver to win the Swedish Rally Championship',
    txt_global_std_thisfeatureisnotavailableyet: '認定証シェアの機能は\n現在ご利用いただけません。',
    txt_global_std_reason1: '理由',
    txt_global_std_createyourownchallengetoinspiresmartdrivingexpandtoseeapreviewofthechallenge: 'スマートな運転を刺激する独自の課題を作成してください!展開してチャレンジのプレビューを表示します。',
    txt_global_std_friendfinder: 'フレンドファインダー',
    txt_global_std_p2pfaq25q: '走行データはどこから確認できますか？',
    txt_global_std_agreements: '協定',
    txt_global_std_lexusweekend: 'レクサス・ウィークエンド',
    txt_global_std_5000pointaverageachievementunlocked: 'あなたは非常によく運転しました！ 1週間の平均{scoretype} was better than {score}ていました。 5000ポイントを獲得しました！',
    txt_global_std_carstatstext: '車の統計',
    txt_global_std_claims: '請求',
    txt_global_std_sendinvitationby: '以下の方法で招待状を送信する',
    txt_global_std_wewouldrecommenddrivertraining: 'ドライバーの集中力と計画に特に注意を払ってドライバー トレーニングを行うことをお勧めします。つまり、周囲の交通状況や状況への集中力を失うことはありません。私たちヤマトは、優しさ、優先順位、感情的な自制心、信頼に特に注意を払い、常に運転に集中し、計画を立てています。',
    txt_global_std_tttest97: 'これはテストです 97',
    txt_global_std_howtogetstarted: 'どうやって始めるのか',
    txt_global_std_searchandmanageusers: 'ユーザーの検索と管理',
    txt_global_std_fixedpremium: '保険料月額固定',
    txt_global_std_createanaccount: 'アカウントを作成する',
    txt_global_std_open: '開く',
    txt_global_std_connectyourcarandstartadrive: '運転を開始するときは「設定」をタップしてください。',
    txt_global_std_whatifichangecaranswer: '{appname}チームにメールでご連絡<br>ください。その後「車への接続」<br>の手順を実行することで新しい車<br>へ再接続を行います。',
    txt_global_std_greaterthansprivacypolicy: 'Greater Thanのプライバシーポリシー',
    txt_global_std_followupontheonboardingstatusofyourusersclickonastatusbarbelowtoseeandhelpyourusersthatmightbestuck: 'ユーザーのオンボーディング ステータスをフォローアップするには、下のステータス バーをクリックして、立ち往生している可能性のあるユーザーを確認して支援します。',
    txt_global_std_p2pwelcomekintoowner: 'ようこそ、金東オーナー！',
    txt_global_std_p2ppleasetypeprice: '料金を入力してください',
    txt_global_std_unpublishtranslations: '翻訳を非公開にする',
    txt_global_std_p2pendtime: '終了時間：',
    txt_global_std_clickheretocallsupport: 'サポートに電話するには、{こちら}をクリックしてください',
    txt_global_std_50km: '50 Km',
    txt_global_std_loyalty_upsell_pitch_bulletpoint3: 'プログラムの可視性を高めて、可能な限り最高の ROI を実現します',
    txt_global_std_kintoEncouragement1: 'さすが！あなたもKintoベストドライバーの仲間入り！',
    txt_global_std_publishapptest: 'アプリテストを公開する',
    txt_global_std_startadrive_: 'ドライブを開始します',
    txt_global_std_300pointTripAchievementunlocked: '{scoretype} {score}以上で運転しました！驚くばかり！ 300ポイントを獲得しました！',
    txt_global_std_p2pemailunreadmessages: '未読メッセージ',
    txt_global_std_ecofriendlockedkey: '環境にやさしい運転をして、1 週間で {co2value}% 以上の CO2 を節約して、このバッジを獲得しましょう。',
    txt_global_std_p2ppushtitlenewinvoicetopay: '今月のわりかん明細が届きました',
    txt_global_std_rateapp: 'アプリを評価',
    txt_global_std_reportcentersworkshops: 'センター とワークショップに報告する',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint1: 'ゲーミフィケーションを通じてドライバーに新しいスキルの習得を奨励する',
    txt_global_std_invitelink: 'リンクを招待',
    txt_global_std_cimage8d: '速度がばらつく状況では加速は緩やかに',
    txt_global_std_loyalty_upsell_pitch_bulletpoint2: 'スマートドライバーに報酬を与えて、エンゲージメントと維持を促進します',
    txt_global_std_p2plendmycartwo: 'クルマを貸す',
    txt_global_std_HusseinAbdullaBesttip: 'Patience',
    txt_global_std_youwillcomebacktothesesettingsthenexttimeyoupressthedrivebutton: '「運転回数」ボタンを押すと、\nこれらの設定の変更ができます。',
    txt_global_std_advancedadmin_upsell: 'Advanced Admin – 強力なユーザー エクスペリエンスのためのシンプルな自己管理ポータル。',
    txt_global_std_selecttime: '時間を選択',
    txt_global_std_deletingthegroupdoesnotdeletetheusersaccounts: 'グループを削除しても、ユーザーアカウントは削除されません。',
    txt_global_std_addyourmileage: 'マイレージを追加',
    txt_global_std_totalnooftrips: 'トリップ数',
    txt_global_std_thatwasaverygooddrive: 'いい運転でした！',
    txt_global_std_weeklyheat: '週次',
    txt_global_std_enerfyanalytics_upsell: 'Enerfy Analytics – 強力なレポート作成のための使いやすいデータ分析ツール。',
    txt_global_std_mediumrisk: '中リスク',
    txt_global_std_ConfirmFia_Text12: '予約枠の10分前までに会場に集合してください。登録と車両手配まで、スタッフがサポートいたします。',
    txt_global_std_finishadriveatscoretypescoreorbettertounlockthisbadge: '{scoretype} {score}以上でドライブを終了して、このバッジのロックを解除します。',
    txt_global_std_pleaseenterthenameofyourcontactperson: '連絡先の名前を入力してください',
    txt_global_std_sendinvitetocountfriends: '{count} 人招待しますか？',
    txt_global_std_insurancedetails: '保険の詳細',
    txt_global_std_kintorefer50friendslocked: 'この目標を達成し、Kintoユーザーを50人紹介すると5000円が付与されます。',
    txt_global_std_userhasnopermissions: 'ユーザーには権限がありません',
    txt_global_std_v2level: 'スコア',
    txt_global_std_soonyouwillbeabletochallengeotherdriversinsafeandecofriendlydriving: '= もうすぐエコで安全な運転に\n他のドライバーと挑戦できます',
    txt_global_std_civilstatus: '市民のステータス',
    txt_global_std_qhowdoisignupforcovercubeinsurance: 'Q：Covercube保険に加入するにはどうすればよいですか？',
    txt_global_std_ifyouhaveone: '-if you have one',
    txt_global_std_drivingstatisticstabs: '運転統計タブ',
    txt_global_std_signinsurance: '保険を申し込む',
    txt_global_std_p2pcheckyouremail: 'メールが送信されました',
    txt_global_std_whatisyourbesttiptodrivesmart: 'スマートドライブのための最大のヒントは?',
    txt_global_std_call: '電話をかける',
    txt_global_std_test: 'テスト',
    txt_global_std_wanttoshareyourprogresswithyourfriends: 'あなたの進歩をあなたの友人と共有したいですか？',
    txt_global_std_yourcar2: '車両',
    txt_global_std_fitmarkers: 'マーカーを合わせる,　',
    txt_global_std_autostartobd: 'OBDの自動スタート',
    txt_global_std_theindividualdriverscoreiscalculatedonanaveragebasedonthetop10trips: '•個々のドライバーのスコアは、上位10％の旅行に基づいて平均して計算されます。',
    txt_global_std_excellent: '低い',
    txt_global_std_email: 'Eメール',
    txt_global_std_sdcrules: 'SDC規則',
    txt_global_std_ridewithmetext: 'それぞれが車に乗る代わりに、<br>ライドシェアする\n' +
      'ことでCO2を大幅に削減することができます。\n' +
      'ライドシェアした運転を登録すると、<br>運転した人、同乗した人それぞれに\n' +
      'ポイントが付与されます。',
    txt_global_std_howwillyouknowaboutmydrivingbehaviour: 'How will you know about my driving behavior?',
    txt_global_std_howdoicompeteinlexuschallengeanswer: 'サインアップすると自動的に参加します。心配しないでください。リーダーボードにはニックネームしか表示されないため、常に匿名のままです。月次および年次の競争があります。',
    txt_global_std_enerfyisaproductfromgreaterthanab: 'EverfyはGreater Than社のプロダクトです。',
    txt_global_std_ecofeedbacktips: '<b>エコスコアは、環境にやさしい運転を測っています。</b>さまざまな意味で、計画性のある運転のしかたにも関連しています。<br>\n' +
      '\n' +
      '<b>加速を調整</b>して、不要なブレーキや停止を回避しましょう。加速とブレーキは、その都度エネルギーを消費します。​<br>\n' +
      '\n' +
      'みんなで協力すれば<b>未来を変える</b>ことができます。\n',
    txt_global_std_therulesforthecompanychallengeareasfollowing: '会社のチャレンジのルールは次のとおりです。',
    txt_global_std_hhmmss: 'hh',
    txt_global_std_iwantrematch: 'もう一度挑戦したい！',
    txt_global_std_httpstatuscodesummary: 'HTTP ステータス コードの概要',
    txt_global_std_errors: 'エラー',
    txt_global_std_availablepointexchange: '利用可能なポイント交換',
    txt_global_std_p2punavailable: '利用不可',
    txt_global_std_daysleftuntilrenewal: '保険更新までの残日数',
    txt_global_std_trainername: '指導者',
    txt_global_std_required: '必須',
    txt_global_std_ifyouwantinfoonnotificationscallphonenumberorgotoanchorlinkorgotorouterlinkpage: '通知に関する情報が必要な場合は、{phoneNumber}に電話するか、{anchorLink}に移動するか、{routerLink}ページに移動してください。',
    txt_global_std_p2pyouborrowedusersvehiclextimes: '{user} の {car} を{times}回借りました',
    txt_global_std_topthreeworldteams: '世界のトップ３チーム',
    txt_global_std_reporting: '報告中',
    txt_global_std_attributes: '属性',
    txt_global_std_reportdamage: '被害を報告する',
    txt_global_std_welcomebackenerfy: '{appName}におかえりなさい！',
    txt_global_std_p2pfinishandpay: '終了する',
    txt_global_std_p2pcash: '現金',
    txt_global_std_kintofaq7q: '車にBluetooth機能がありませんが、どうすればよいでしょうか？',
    txt_global_std_maintainalowrisklevelbykeepingyourfocusontheroadandplanahead: '道路に集中し、事前に計画を立てることで、リスクレベルを低く維持します。',
    txt_global_std__emailaddress: '電子メールアドレス',
    'txt_global_std_Its time for some fun. Join a challenge!': '楽しいチャレンジに参加!',
    txt_global_std_fuelinjectorcleaner: '燃料噴射装置クリーナー',
    txt_global_std_BeforeFirstBill_Text6: '敬具、{appName}',
    txt_global_std_enteryouremailadress: 'Enter your e-mail',
    txt_global_std_pleasenotethattojoinyoumusthavetheaanzappinstalledonyourmobiledeviceinordertobeabletojointhegroup: 'チームに参加するには、モバイル デバイスに FIA SDC アプリがインストールされている必要があります。',
    txt_global_std_avgkm7d: '平均km 7D',
    txt_global_std_finished: '終了',
    txt_global_std_returntoooverview: '概要に戻る',
    txt_global_std_turnonbluetoothviayourcarsinfotainmentsystemstereoandfollowtheinstructionsforpairingyourphone: '車のインフォテインメント システム/ステレオ経由で Bluetooth をオンにし、指示に従って携帯電話をペアリングします。',
    txt_global_std_howdoiearnsavingslexusanswer: 'あなたが安全運転するとき、貯蓄が生成されます。あなたが安全に運転するすべての旅行のために、あなたはキロあたりの節約を生み出します。安全運転をすればするほど、節約額も高くなります。節約額は旅行が終了した直後に表示され、Lexusウォレットに表示されます。',
    txt_global_std_finishedtraining: '指導済み',
    txt_global_std_savechangespopupheader: '変更内容を保存？',
    txt_global_std_repeatyournewpassword: '新しいパスワードを繰り返します',
    txt_global_std_prefernottosay: 'どちらかというと言いたくない',
    txt_global_std_coverimage: '表紙画像：',
    txt_global_std_pleasefocus: '集中してください！',
    txt_global_std_communication_upsell_pitch_bulletpoint1: '独自のメッセージとコミュニケーションのスケジュールを簡単に設計できます。',
    txt_global_std_secretuser: '匿名ユーザー',
    txt_global_std_redeemcoupon: 'クーポンを利用する',
    txt_global_std_createanaccounttosavealldrivingdata: 'アカウントを作成すると、すべての走行データ、ルート履歴、リワードを保存できます。',
    txt_global_std_needhelp: '助けが必要？',
    txt_global_std_usernameshort: 'ユーザー名',
    txt_global_std_zipriskindexhelpsyoudeterminepreciserisklevelbasedondrivinglocation: 'Zip リスク インデックスは、運転場所に基づいて正確なリスク レベルを判断するのに役立ちます。',
    txt_global_std_setupyourfiasdccompanychallenge: 'FIASDCカンパニーチャレンジを設定します。',
    txt_global_std_mycardoesnthavebluetooth: '私の車にはBluetoothが搭載されていませんか？',
    txt_global_std_firstscore: '最高スコアをめざせ！',
    txt_global_std_p2pallofyourtripsgatheredinoneplacegetaccesstofeedbackandstatisticsaboutyourdrives: 'すべての旅行が1か所に集まりました。ドライブに関するフィードバックと統計にアクセスできます。',
    txt_global_std_yousaved: '保存されました',
    txt_global_std_PolicySchedule6: 'until midnight on',
    txt_global_std_youneedtoinputanemail: 'Eメールを入力してください',
    txt_global_std_noregnumberregistered: '登録番号がありません',
    txt_global_std_socialsecuritynumber: '社会保障番号',
    txt_global_std_skeyscore: 'スコア',
    txt_global_std_participants: '参加者数',
    txt_global_std_qhowdoisignupforcompanyinsurance: 'Q：[会社]保険に加入するにはどうすればよいですか？',
    txt_global_std_yourpasswordmustconsistofatleastfive: 'パスワードは5文字以上です。',
    txt_global_std_p2perrorcodeendofbookingisoverlapping: '予約終了時間が重複しています。',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint1: 'よりスマートな運転を促し、リスク、燃料の使用、CO2 排出量の削減を支援',
    txt_global_std_youcanfindyourresultshortlyinyourtrips: '後ほど、「ドライブ」からお確かめください',
    txt_global_std_p2pbookingfinished: '予約が終了しました',
    txt_global_std_ecoscore: 'Eco score',
    txt_global_std_alltabtext: '全て',
    txt_global_std_buynow: '今購入する',
    txt_global_std_myleague: 'マイリーグ',
    txt_global_std_startdrivingtoappearonleaderboard: 'Start driving to appear on the leaderboard!',
    txt_global_std_toprovideyouwithaccuratestatsanddrivingscoreappnameneedstoruninbackgroundmodebrinthenextpopupbrchooseballowallthetimeb: '正確な統計と運転スコアを提供するには、{appname}をバックグラウンドモードで実行する必要があります。 <br>次のポップアップで：<br>選択： <b>常に許可</b>',
    txt_global_std_aninsuranceadjusterwillcontactyouwithinhourshoursofsubmittingyourclaimtheentireclaimsprocesswilldetermineonthecomplexityoftheclaim: '保険査定人は、請求を提出してから{時間}時間以内に連絡します。クレームプロセス全体が、クレームの複雑さを決定します。',
    txt_global_std_recruiter: '採用担当者',
    txt_global_std_forthisapptoworklocationservices: 'アプリを利用して運転データを登録するために、位置情報へのアクセスを\u000b「常に許可」にすることをお勧めします。\u000b今変更しない場合、\u000bこのポップアップは再度表示されます。\n' +
      '\n',
    txt_global_std_welcomeletsgetstarted: 'いらっしゃいませ！始めましょう！',
    txt_global_std_uploadgroupimage: 'グループ画像をアップロード',
    txt_global_std_married: '既婚',
    txt_global_std_PolicySchedule21: 'OFD',
    txt_global_std_inallourappswehavecontactinformationsothatuserscancontactthecompanyfillinyourcompanyscontactinformationhere: 'ユーザーが会社に連絡できるように、すべてのアプリに連絡先情報があります。ここに会社の連絡先情報を入力してください。',
    txt_global_std_VilliersFacts: 'Giniel De Villiers has never retired. Even better, only once has he not finished in the Top 10 (in 2007). He has 14 stage victories and in 2009 he won the first Dakar contest in South America.',
    txt_global_std_back: 'バック',
    txt_global_std_advancedadmin1: '  高度な管理者',
    txt_global_std_pleasegivethreeoptionsincaseyourfirstoptionsisalreadyused: '最初のオプションがすでに使用されている場合に備えて、3つのオプションを指定してください。',
    txt_global_std_yourpasswordmustconsistofatleastten: 'パスワードは10文字以上で設定してください。',
    txt_global_std_completed: '完了',
    txt_global_std_documentsentto: '報告書を送信する',
    txt_global_std_estimatedsavings: '運転評価',
    txt_global_std_maybesavedwhenfocusingonthisspeed: 'この速度の改善に集中すれば節約できます',
    txt_global_std_step1: 'ステップ 1',
    txt_global_std_p2pdecreasetextsize: 'テキストサイズを変更する',
    txt_global_std_recruiterlockedkey: '新たなメンバーを招待した後、​\nアカウントの登録まで完了したら​\n獲得できるバッジ',
    txt_global_std_translationtool: '翻訳ツール',
    txt_global_std_insurancecompanydescription: '正確な価格設定と優れた引受のために。',
    txt_global_std_terminate: '終了する',
    txt_global_std_cimage4d: '信号無視',
    txt_global_std_showofflinedrives: 'オフラインのドライブを表示する',
    txt_global_std_enerfydynamiccollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: 'Enerfy Dynamicは位置データを収集して、アプリが閉じているときや使用されていないときでも運転スコアを有効にします。',
    txt_global_std_cantseeyourcar: 'あなたの車が見えない'
  },
  fr: {
    txt_global_std_areyoudrivinganelectricorobd: '電気自動車を運転していますか、または、車載デバイスを取り付けていますか？',
    txt_global_std_nodrivingdata: ' Aucune donnée de conduite',
    txt_global_std_HusseinAbdullaFacts: 'Adel a gagné le championnat mondial de la FIA CCR T2 avec Sébastian Delaunay en tant que copilote. Dans sa première saison du championnat de cross-country Adel Hussein a obtenu des victoires au Portugal, Abu-Dhabi et Espagne pendant une campagne réussie dans le championnat du hors-piste des plus difficiles au monde. ',
    txt_global_std_Intro_Hello: 'Hei!',
    txt_global_std_gotoleaderboard: 'Voir le classement',
    txt_global_std_wrongdaterange: 'Wrong date range',
    txt_global_std_inordertoconnecttoyourcartheincludedobd: 'In order to connect to your car, the included OBD device, the Enerfy plug-in, must be installed in your vehicle - or - if your vehicle has the option to connect your phone via Bluetooth directly, the Enerfy app has to be running in your phone while driving.',
    txt_global_std_p2pfaq20a: "1. Sélectionnez « Conduire » dans le « Menu » pour vérifier les dernières données de conduite et l'évaluation.\n" +
      '2. Sélectionnez des données de conduite spécifiques pour voir les détails du retour.',
    txt_global_std_p2pcancelled: 'Annulé',
    txt_global_std_togetthecorrectstatsofyourdrivingyouneedtogototheappsettingsinyourphoneand: "Pour obtenir les statistiques correctes de votre conduite, vous devez accéder aux paramètres de l'application sur votre téléphone et choisir Autoriser toujours les services de localisation.",
    txt_global_std_minimum5characters: 'Au moins 5 caractères',
    txt_global_std_unabletoloadtrips: 'Unable to load trips',
    txt_global_std_Head2Head_Text2: 'Click on the following link to accept your challenge',
    txt_global_std_inactivateapp: "Désactiver l'application",
    txt_global_std_PaymentTrouble3_Text8Moderna: 'If you have already paid your premium, you may disregard this message. Contact us if you have questions or need help with the payment of your insurance: 0200-265 265.',
    txt_global_std_noinsurancesfound: 'No insurances found',
    txt_global_std_letstrythatagain: 'Réessayons!',
    txt_global_std_apr: ' Avr.',
    txt_global_std_HSolbergMusic: 'POP and rock music that gives enthusiastic spirit in my blood.',
    txt_global_std_youhaveatotalcostof: 'Vous avez un coût total de',
    txt_global_std_challengedetails: 'Détails du défi :',
    txt_global_std_driversname: 'Nom du conducteur',
    txt_global_std_tomasengemileage: "J'aime toujours tous les chemins qui mènent à ma maison, à ma famille",
    txt_global_std_InsuranceMail_Text30Moderna: 'Fullständiga villkor och mer information hittar du på www.modernasmartflex.se.',
    txt_global_std_yourinsurancehasbeenrenewed: 'Votre assurance a été renouvelée.',
    txt_global_std_now: ' À venir',
    txt_global_std_p2pviewandpay: 'Voir et payer',
    txt_global_std_challengedyouin: 'Challenged you in',
    txt_global_std_p2pnamecancelledname2sbooking: '{name} a annulé la réservation de {name2}',
    txt_global_std_telephoneclaims: 'Réclamations téléphoniques',
    txt_global_std_emailalreadyusedpleasetrywithanotheremail: "Adresse e-Mail déjà utilisée. S'il vous plaît essayez avec un autre email.",
    'txt_global_std_Al-KuwariBesttip': 'Concentration and try to enjoy the drive.',
    txt_global_std_newtext: 'Nouveau texte',
    txt_global_std_p2pemailhello: 'Bonjour',
    txt_global_std_carmodelform: 'Modèle de voiture:',
    txt_global_std_sendreferrallink: 'Envoyer un lien de parrainage',
    txt_global_std_failedtoaddusers: "Échec de l'ajout d'utilisateurs",
    txt_global_std_feedback: 'Conseils',
    txt_global_std_hoponinletsdrivesmart: 'Montez à bord, conduisez intelligemment',
    txt_global_sidekick_invitehowitworkslink: 'How the invitation works',
    txt_global_std_report: 'Report',
    txt_global_std_JyrkiainenChamp: 'La meilleure pilote Rookie dans le championnat de Finlande en 2016',
    txt_global_std_hellogreattoseeyou: 'Bonjour, ravi de vous voir !',
    txt_global_std_p2pmembersinthisgroup: 'Membres de ce groupe',
    txt_global_std_couldnotjoinevent: 'Impossible de rejoindre l&#39;événement.',
    txt_global_std_cantfindyourcaryet: 'Vous ne trouvez pas encore votre voiture!',
    txt_global_std_allusershavesuccessfullycompletedthisstep: 'Tous les utilisateurs ont réussi cette étape.',
    txt_global_std_forgotyourpassword1: 'Mot de passe oublié?',
    txt_global_std_paddonDescription: 'Hayden a débuté avec le go-kart depuis l’âge de 6 ans. Depuis toujours, il vit et respire le sport automobile',
    txt_global_std_security: 'Sécurité',
    txt_global_std_welcometoapp: 'Bienvenue sur {app} !',
    txt_global_std_HighUsageAlarm_Text9: 'Venlig hilsen {appName}-Teamet',
    txt_global_std_adjustyouryearlymileage: 'Ajustez votre kilométrage annuel',
    txt_global_std_TermConfirmPlugin_Text8: 'Sincerely,\r\n{appName}',
    txt_global_std_easilykeeptrackofyourcostandco2emissionsintheapp: "Suivez facilement vos coûts et vos émissions de CO2 dans l'application.",
    txt_global_std_p2ppleasechooseexpensetype: 'Veuillez choisir le type de dépense',
    txt_global_std_electriccar: 'Voiture électrique?',
    txt_global_std_someoneelseispairedtoyourcar: '• Une autre personne est associée à votre voiture.',
    txt_global_std_moreattributes: "Plus d'attributs",
    txt_global_std_disableachievementtooltiptext1: "Cette réussite a été créée sans l'outil de réussite. <br> Par conséquent, il ne peut actuellement pas être modifié dans cet outil.",
    txt_global_std_atheappwilltrackyourscoresandprovidearatebasedonyourdrivinghabitsyouwillgetthefirstmonthlyratethatisbasedonyourdrivingafteryourfirstsixmonthperiodyourratewillthenchangeonamonthlybasisbasedonyourscoreanddrivinghabitsyouwillreceiveupdatesaftereachtripadvisingyouofyourpotentialsavingsorifyouneedtoimproveyourdrivingscoretoearnadiscount: "R : L'application suivra vos scores et fournira un taux basé sur vos habitudes de conduite. Vous obtiendrez le premier taux mensuel basé sur votre conduite après votre première période de six mois. Votre tarif changera ensuite sur une base mensuelle en fonction de votre score et de vos habitudes de conduite. Vous recevrez des mises à jour après chaque voyage vous informant de vos économies potentielles ou si vous devez améliorer votre score de conduite pour obtenir une réduction.",
    txt_global_std_notsupported: ' Non supporté',
    txt_global_std_inviteafriendorfamilytoborrow: 'Invitez un ami ou un membre de votre famille à emprunter votre voiture {appname}',
    txt_global_std_400badrequest: '400 - Mauvaise demande',
    txt_global_std_TaxInvoice4: 'Devise / Taux de change',
    txt_global_std_someoneelseispairedtoyourcaryourbluetoothbeacon: "• Quelqu'un d'autre est associé à votre voiture/votre balise Bluetooth\n",
    txt_global_std_keenonwinningjoinacompetition: 'Keen on winning? Join a competition!',
    txt_global_std_f_lanzinterests: 'Escalade aventure, Rafting, Tyrolienne, Course sur sentier, Danse, Explorateur de la nature sauvage',
    txt_global_std_password2: 'Mot de passe',
    txt_global_std_v2sdcscore: 'Score',
    txt_global_std_trytheappfunctionisnotavailableindemomode: `La fonction "Essayer l'application" n'est pas disponible en mode démo`,
    txt_global_std_location: 'Location',
    txt_global_std_leaderboard_tab_daily: 'du quotidien',
    txt_global_std_iaminmycarandIneedhelpimat: "I am in my car and I need help. I'm at",
    txt_global_std_days: 'Jours',
    txt_global_std_youcanuploadmaximum9photos: 'Vous pouvez télécharger au maximum 9 photos',
    txt_global_std_Invite_Hello: 'Hello',
    txt_global_std_connectwithobdreader: 'Connectez-vous avec le lecteur OBD',
    txt_global_std_ongoinghead2headchallenges: 'En cours Head 2 Head défis',
    txt_global_std_easytogetstartedrequestyourzipriskindexviaourapi: 'Facile à démarrer, demandez votre indice de risque Zip via notre API.',
    txt_global_std_avglevel: 'Avg. level',
    txt_global_std_idnumber: ' Numéro de carte d’identité',
    txt_global_std_automessage_level_2__3: '#Superhelt',
    txt_global_CoreTemplate_enter: ' Entrer',
    'txt_global_std_Select the OBD reader you have': 'Select the OBD reader you have',
    txt_global_std_startsin: 'Commence dans',
    txt_global_std_retrive: ' Récupérer',
    txt_global_std_ok: ' Ok',
    txt_global_std_questionsaboutinsurance: 'Questions sur les assurances',
    txt_global_std_PaddonChamp: 'Champion du PWRC en 2011. 4 victoires du Rallye de la Nouvelle Zélande. En 2016 il gagne le Rallye d’Argentine. ',
    txt_global_edj_business: 'Business',
    txt_global_std_invitecodesent: 'Invite code sent',
    txt_global_std_saveyourcar: 'Sauvez votre voiture',
    txt_global_std_selectteam: 'Choisis une équipe',
    txt_global_std_ayouwillbenotifiedintheappwhenitstimetorenewyourinsuranceifyoudonthaveautomaticrenewalsetupfollowthestepsintheapptoensureyouhavecoverageforthefollowingmonth: "R : Vous serez averti dans l'application lorsqu'il sera temps de renouveler votre assurance. Si vous n'avez pas configuré le renouvellement automatique, suivez les étapes de l'application pour vous assurer d'avoir une couverture pour le mois suivant.",
    txt_global_std_globalteams: 'Équipes mondiales',
    txt_global_std_mystatsthismonth: 'Mes stats ce mois-ci',
    txt_global_std_downloadinglatestversionofapp: ' Nous allons maintenant procéder au téléchargement des dernières versions de toutes les applications. Veuillez patienter, cela peut prendre jusqu’à une minute.',
    txt_global_std_theftandburglary: 'Theft and Burglary',
    txt_global_std_renewedfor: 'Renouvelé pour',
    txt_global_std_countryroadintersectionDESC: 'Le conducteur de la voiture bleue est planifié et concentré et traverse le passage à niveau en douceur. Le conducteur de la voiture rouge ne modifie pas sa vitesse en fonction de la situation de conduite et doit souvent utiliser des freins puissants.',
    txt_global_std_tomasengebesttip: 'Se concentrer au maximum sur la conduite est une clé pour réussir à atteindre votre destination',
    txt_global_std_creditcard: 'Credit card',
    txt_global_std_thisensuresyourcarisstilltrackedbyloyaltysystem: 'Cela garantit que votre voiture est toujours suivie par le système de fidélité.',
    txt_global_std_nestgroup: 'Groupe de nidification',
    txt_global_std_replay: ' Rejouer',
    txt_global_std_thisisaskilleddriver: "Il s'agit d'un conducteur qualifié, cependant, parfois lors d'un voyage et seulement pour une courte période de temps, ce conducteur a tendance à perdre le focus sur Cool Driving. Certaines distractions ou certains stress peuvent amener ce conducteur à ignorer la sensibilisation à la sécurité. Ceci est souvent résolu en discutant avec le conducteur et en demandant sa considération.",
    txt_global_std_p2ptypeamessage: 'Tapez un message',
    txt_global_std_PolicySchedule20: 'MNT',
    txt_global_std_establishingobddatalink: ' Connexion au système de diagnostic embarqué en cours',
    'txt_global_std_You are good to go!': 'You are good to go!',
    txt_global_std_Footer_Tryg: 'Tryg | CVR-Nr: 24 26 06 66 | Klausdalsbrovej 601| 2750 Ballerup |',
    txt_global_std_clickrefreshbuttoninapptotryagain: "Réactualisez l'application et ressayez.",
    txt_global_std_bad2: 'Mal',
    txt_global_std_addlogo: ' Ajouter un logo',
    txt_global_std_whendrivingonhigherspeedroadsplanningismoreimportantthaneverenteringahighwaytooslowlycancausedangertofellowmotoristsandleadtoqueuesbehindyouobservingthetrafficflowandadaptingyourspeedtotheconditionsisimportanttoavoidunnecessaryqueuesandpanicbrakingnew: "Lorsque vous conduisez sur des routes à grande vitesse, la planification est plus importante que jamais. S'engager trop lentement sur une autoroute peut mettre en danger les autres automobilistes et entraîner des files d'attente derrière vous. Observer le flux de la circulation et adapter sa vitesse aux conditions est important pour éviter les files d'attente inutiles et les freinages d'urgence.",
    txt_global_std_Insurance_OrderConfirm: 'Order confirmation',
    txt_global_std_howdoiterminatemyinsurance: 'Comment résilier mon assurance ?',
    txt_global_std_manageparticipants: 'Gérer les participants',
    txt_global_std_stopcolon: 'Stop:',
    txt_global_std_MSIG_InsuranceLetter19: 'Personnes ou catégories de personnes autorisées à conduire *',
    txt_global_std_tryouttrygdrive: 'Essayez Tryg Drive',
    txt_global_std_onceyouhavecompletedachallengeitwillappearhere: 'Une fois que vous avez terminé un défi, il apparaîtra ici.',
    txt_global_std_daysdayshourshoursminutesminutes: '{days} jours {hours} heures {minutes} minutes',
    txt_global_std_p2psendinvite: 'Envoyer une invitation',
    txt_global_std_pickstarttime: "Choisissez l'heure de début",
    txt_global_std_setupyourprofiletocontinue: 'Configurez votre profil pour continuer',
    txt_global_std_riskindex_upsell: "Indice de risque - accédez à une vue d'ensemble pour comprendre les tendances mondiales en matière de conduite des risques.",
    txt_global_std_teammembers: 'Membres de l&#39;équipe:',
    txt_global_std_KleinschmidtBesttip: "I find it important to foresee the drive ahead, so you don't need to break, accelerate, break again all the time.",
    txt_global_std_newmobilitycompany: 'Nouvelle société de mobilité',
    txt_global_std_statsforthistrip: 'Statistiques pour ce voyage',
    txt_global_std_cimage24d: ' Trouvez le régime moteur qui consomme le moins de carburant.',
    txt_global_std_hoursshort: 'h',
    txt_global_std_warikan_onboarding19: " Si le bouton ne s'affiche pas, veuillez télécharger KINTO directement depuis l'App Store ou Google Play.",
    txt_global_std_anewaccountwillbecreated: ' Un nouveau compte sera créé',
    txt_global_std_joinname: 'Join {appName}',
    txt_global_std_restrictedtopeoplewithinmyorganization: '(réservé aux personnes de mon organisation)',
    txt_global_std_youraveragelevelthismonthisthebasisforyourdiscountyoureceivethediscountwhenyourenewyourinsurancefortheupcomingmonths: 'Votre niveau moyen ce mois-ci est la base de votre remise. Vous recevez le rabais lorsque vous renouvelez votre assurance pour les mois à venir',
    txt_global_std_units: ' Unités',
    txt_global_std_automessage_level_13__2: 'Oops! Watch out!',
    txt_global_std_listoffunctions: 'Liste des fonctions',
    txt_global_std_DamageReport_Text3: 'Do you need a car or help with anything regarding your claim, you are welcome to contact Moderna Försäkringar at 0200 - 213 213.',
    txt_global_std_writeyourcommentshere: 'Write your comments here',
    txt_global_std_p2pviewyourbookingsandblockyourcarwhenyouwanttomakeitunavailable: 'Consultez vos réservations et bloquez votre voiture lorsque vous souhaitez la rendre indisponible.',
    txt_global_std_viewactivationcode: "Afficher le code d'activation",
    txt_global_std_p2psendyourcode: 'Envoyez votre code ami',
    txt_global_std_whenyouactivateyourinsurance: 'When you activate your insurance, you will get information about it here',
    txt_global_std_teamleaderscore: 'Score du chef d&#39;équipe',
    txt_global_std_results: 'Résultats',
    txt_global_std_exporttopdforexcelisveryeasy: 'Export to PDF or excel is very easy. Get started after your first trip.',
    txt_global_std_choosefromlibrary: 'Choisissez dans la bibliothèque',
    txt_global_std_distancefromparking: 'Distance du parking et début du trajet',
    txt_global_std_unabletoloadteams: 'Unable to load teams',
    txt_global_std_youhavebeenchallengedtoahead2head: "Vous avez été défié en tête à tête. Ouvrez l'application et acceptez-la maintenant !",
    txt_global_std_wanttochallengeanewfriend: 'Vous voulez défier un nouvel ami?',
    txt_global_std_currentodometervalueinkm: 'Entrez votre valeur actuelle de l&#39;odomètre (km)',
    txt_global_std_HansenMileage: 'I really fancy a good long trip if I can share the ride with my family or friends. ',
    txt_global_std_countryroad3DESC: 'The blue car driver use slow pace to drive focused and smoothly.\n' +
      '\n' +
      'The red car driver does not plan the driving and uses gas and brake unnecessary often.  ',
    txt_global_std_Insurance_DayPremiumText: '* The daily premium will be charged after 7 days if you have not created and verified an {appName} Account.',
    txt_global_std_collectpointsbyearningbadgeseverybadgeisworthdifferentamountofpointstapabadgetolearnmoreaboutit: 'Accumulez des points en gagnant des badges. Chaque badge vaut un nombre de points différent. Appuyez sur un badge pour en savoir plus.',
    txt_global_std_getstarted: ' Commencer',
    txt_global_std_Reciept_Text2Moderna: 'Thank you for choosing Moderna Smart Flex.',
    txt_global_std_challenges: 'Challenges',
    txt_global_std_may: ' Mai',
    txt_global_std_documentation2: 'Documentation',
    txt_global_std_alreadyhaveanenerfypluginclickhere: 'Vous avez déjà un plug-in Enerfy? Cliquez ici!',
    txt_global_std_drivercpproverview: "Rapport de profilage des modèles d'accidents du conducteur - aperçu",
    txt_global_std_helpbeyondtheordinary: 'Help beyond the ordinary, only a click away',
    txt_global_std_likeourcarsharingpage: 'Like our Facebook page',
    txt_global_std_youearnedapercentagediscount: 'Vous avez obtenu une remise de {percentage} %',
    txt_global_std_acompletedriverslogthatfulfill: "A complete driver's log that fullfils legal requirements.",
    txt_global_std_uploadyourcompanylogo: 'Téléchargez le logo de votre entreprise',
    txt_global_std_pushneverdrivenbody2: 'Commencez avec {appname} , connectez-vous à votre voiture la prochaine fois que vous partez en voiture!',
    txt_global_std_kintofaq10q: 'Comment mesurez-vous ma conduite?',
    txt_global_std_p2ptotaltosplit: 'Total à diviser :',
    txt_global_std_worldranking: 'Classement mondial',
    txt_global_std_yourinsuranceisrenewedrenewaldate: 'Votre assurance est renouvelée {renewaldate}.',
    txt_global_std_setupyourcompanychallenge: "Configurez votre défi d'entreprise",
    txt_global_std_companychallenge: "Défi de l'entreprise",
    txt_global_std_ConfirmFia_Text11: ' Il ne reste que 24 heures avant que vous puissiez participer au test de conduite Smart Driving Challenge de la FIA à Paris !',
    txt_global_std_clickonthesettingsbelowtochangepermission: 'Cliquez sur les paramètres ci-dessous pour modifier l&#39;autorisation',
    txt_global_std_starttheengineandopentheapp: 'Démarrez le moteur et ouvrez l&#39;application.',
    txt_global_std_Insurance_Rate: 'Price per level',
    txt_global_std_insertyourbonuscodeyourfriendsentyou: 'Insert bonus code your friend sent you',
    txt_global_std_supportedfileformats: 'Formats de fichiers pris en charge',
    txt_global_std_demotext3: 'Voyez combien vous avez conduit au total.',
    txt_global_std_youcannotjoinaclosedteam: 'You cannot join a closed team.',
    txt_global_std_savedimagesmarkedwitha: 'Images enregistrées marquées d&#39;un',
    txt_global_std_makesureengineisrunning: 'Assurez-vous que le moteur soit en marche',
    txt_global_std_havealreadybeeninapp: ' Vous êtes déjà utilisateur ?',
    txt_global_std_unpublishachievementpopuptext: "Lorsque vous annulez la publication d'une réussite, elle sera supprimée pour tous les utilisateurs qui ne l'ont pas encore déverrouillée. Si un utilisateur l'a déjà reçu, il l'aura toujours parmi ses autres réalisations. Si vous ne l'avez pas encore reçu, il sera supprimé lors de la dépublication.",
    txt_global_std_validat: 'Valid at',
    txt_global_std_InsuranceMail_Text27: 'Machine insurance is applicable for cars that are no more than 8 years old, calculated from the first registered day and cars that have driven no more than 10 000 swedish miles. A lower deductible applies if the car has driven no more than 6,000 swedish miles.',
    txt_global_std_KleinschmidtWhyjoin: 'Because we are the coolest team and we have lots of fun.',
    txt_global_std_vehicledetailedreport: 'Detailed vehicle report',
    txt_global_std_risklevel: 'Niveau de risque',
    txt_global_std_driveabitmoretogetscore: ' Conduisez un peu plus pour obtenir un score',
    txt_global_std_updatedtranslation: 'Traduction mise à jour',
    txt_global_std_countyears: ' {count} années',
    txt_global_std_Insurance_Bill: 'INVOICE',
    txt_global_std_insurance: 'Insurance',
    txt_global_std_shownewactivities: 'Afficher les nouvelles activités',
    txt_global_std_startachallenge: 'Entrez dans la compétition',
    txt_global_std_discipline: 'Discipline',
    txt_global_std_competition: ' Compétition',
    txt_global_std_notsignedupforsompoclimateheroinsuranceyet: "Pas encore souscrit à l'assurance Sompo Climate Hero ?",
    txt_global_std_pleaserefreshthepage: 'Veuillez actualiser la page',
    txt_global_std_VerStep_Text2: 'Sista steget för att teckna {appName} är att ange dina kortuppgifter. Det gör du, på ett säkert sätt, genom att klicka på länken nedan.',
    txt_global_std_hereyoustartadrivemanuallywhenyouhavefinished: 'Here you start a drive manually. When you have finished your drive you click stop drive to get your score. We use GPS to give you a score. If you drive in to a tunnel or a parking garage, your drive can be affected.',
    txt_global_std_Age: 'Ålder',
    txt_global_std_p2pborrowedtime: 'Temps emprunté',
    txt_global_std_collision123: 'Collision',
    txt_global_std_atleast5characters: ' Au moins 5 caractères',
    txt_global_std_p2plendyou: 'vous prêter',
    txt_global_std_signup: ' S’inscrire',
    txt_global_std_calculating: ' Calculs en cours',
    txt_global_std_30daysavg: '30 jours en moyenne',
    txt_global_std_pleaseverifyyourenerfyglobalemailadress: 'Veuillez vérifier votre adresse e-mail Enerfy Global',
    txt_global_std_p2pmorefunctions: 'Plus de fonctions',
    txt_global_std_scantheqrcodetoaccessthechallenge: 'Scannez le QR code pour accéder au défi.',
    txt_global_std_man: 'Homme',
    txt_global_std_helptextsavingsstartbonustitle: 'Startbonus',
    txt_global_std_cutoffdate: 'Cut off date',
    txt_global_std_sharelink1: 'Lien de partage',
    txt_global_std_refresh: 'Rafraîchir',
    txt_global_std_easilymanagecommunication: "Gérez facilement la communication sur tous les canaux. Certains des messages sont automatisés pour le meilleur parcours client. Créez et personnalisez le vôtre ou choisissez entre des e-mails, des SMS, des notifications push et des notifications intégrées à l'application.",
    txt_global_std_sep: ' Sep.',
    txt_global_std_p2perrorcodebookingduplicaterequest: 'Vous avez déjà envoyé une demande pour cette heure.',
    txt_global_std_p2pborrowtext: '{name} souhaite emprunter votre {vehicle}',
    txt_global_std_print: 'Print',
    txt_global_std_youwillearnachievements: 'vous gagnerez des succès',
    txt_global_std_startodometer: 'Start odometer',
    txt_global_std_IfStolenProperty: 'Stals egendom vid skadan',
    txt_global_std_addedanewchallenge: 'Added a new challenge',
    txt_global_std_mysavings: 'Mes économies',
    txt_global_std_besuretoaddfourmainpicturesofyourcarfrontleftrightandbacksidesasshowninthediagramsbelow: "Assurez-vous d'ajouter quatre images principales de votre voiture (côtés avant, gauche, droit et arrière) comme indiqué dans les schémas ci-dessous.",
    txt_global_std_creatingyourprofile: 'Créer votre profil',
    txt_global_std_sorrytheimagerecognitionfailedpleaseentertheodometervaluemanually: "Désolé, la reconnaissance d'image a échoué. Veuillez entrer la valeur du compteur kilométrique manuellement.",
    txt_global_std_Insurance_DayPremium4days: 'Daily premium 4 days*',
    txt_global_std_alreadyhaveanaccountlogin: 'Vous avez déjà un compte? Connexion',
    txt_global_std_PolicySchedule33: 'Adresse',
    txt_global_std_TomczykInterests: 'Voitures classiques, tout type de sports',
    txt_global_std_p2psafedriverbonusnotincluded: 'Bonus Safedriver non inclus',
    txt_global_std_takesomephotosofyourcar: 'Prendre des photos de votre voiture',
    txt_global_std_OtherComments: 'Övriga kommentarer',
    txt_global_std_InsuranceMail_Text24: '25% of daily rental fee',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriendsthedriverwiththebestscorewinstheround: 'Rivalisez dans votre conduite quotidienne contre vos amis. Le pilote avec le meilleur score remporte la manche !',
    txt_global_std_p2pemailcancelledbookings: 'Réservations annulées',
    txt_global_std_detailsaboutthedriveisshownehereexport: 'details about the trip is shown here. Export easily in PDF and Excel.',
    txt_global_std_whatisapayasyoudriveinsurance: 'Qu&#39;est-ce qu&#39;un salaire lorsque vous conduisez?',
    txt_global_std__log_in: 'Connexion',
    txt_global_std_gobackandfix: 'Revenir en arrière et réparer',
    txt_global_std_allyear: ' Toute l’année',
    txt_global_std_world: ' Monde',
    txt_global_std_leftuntilrenewal: "Reste jusqu'au renouvellement",
    txt_global_std_policynumberalreadyexists: 'Le numéro de police existe déjà',
    txt_global_std_buyandinsuranceandstartsavingmoneynow: 'Achetez une assurance et commencez à économiser de l&#39;argent maintenant',
    txt_global_std_PreSaleDetails_Text28: 'Traffic Accidents and Emergency Assistance',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint2: 'Découvrez de nouvelles informations pour permettre une action ciblée',
    txt_global_std_oct: ' Oct.',
    txt_global_std_HighMileage_Text4: 'i {appName}-appen. Da går du inn på',
    txt_global_std_NotCreatedAccount3_Text8: 'Hvis du har spørgsmål til dine forsikringer, er du velkommen til at ringe på 70 11 20 20 eller skrive til os på trygdrive@tryg.dk.',
    txt_global_std_coupons: 'Bons de réduction',
    txt_global_std_okaywewillremindyoulater: "D'accord, nous vous rappellerons plus tard",
    txt_global_std_successfullypublishedreviewedtranslations: 'Traductions révisées publiées avec succès',
    txt_global_std_p2pyouhavenobooking: "Vous n'avez aucune demande de réservation",
    txt_global_std_accidentReportSent: 'Report successfully reported',
    txt_global_std_warikan_onboarding5: "1. C propriétaire KINTO ONE entrepreneur (client) télécharge l'application",
    txt_global_std_createateamandenjoytheenerfycommunity: 'Create a team and enjoy the {appName} community.',
    txt_global_std_koscore: 'Økoscore',
    txt_global_std_editprofilepicture: 'Éditer la photo de profil',
    txt_global_std_deactivated: 'Désactivé',
    txt_global_std_timesincelogin: 'Temps écoulé depuis la connexion, garé, etc.',
    txt_global_std_selectyourobddevicefromthelistobdmini2: 'Sélectionnez votre appareil OBD dans la liste : OBD Mini 2',
    txt_global_std_enterthenrofdigitsdigitcodeorclickonthelinksenttophonenr: 'Entrez le {nrOfDigits} - code à chiffres ou cliquez sur le lien envoyé à : {phoneNr}',
    txt_global_std_unabletoload: 'Unable to load - {what}',
    txt_global_std_howtoplay: 'Comment jouer',
    txt_global_std_kintofaq7a: 'Aucun problème! Demandez un dongle BT-OBD et il fera la magie! Connectez simplement le dongle au câble et branchez-le à votre chargeur 12v dans la voiture. Ensuite, suivez les étapes «Se connecter à la voiture» comme d&#39;habitude et choisissez le nom «Enerfy OBD» lors de la connexion Bluetooth.',
    txt_global_std_senddetails: 'Envoyer les détails',
    txt_global_std_youwillreceiveanemailwithyourpersonaldata: 'Vous recevrez un e-mail avec vos données personnelles.',
    txt_global_std_ihaveanenerfypluginhowdoiconnectitinmycar: 'J&#39;ai un plug-in Enerfy, comment le brancher dans ma voiture?',
    txt_global_std_thepasswordhastobe8charactersorlongerandmustinclude1numeralandspecialcharacter: 'Le mot de passe doit comporter 8 caractères ou plus et doit inclure 1 chiffre et un caractère spécial',
    txt_global_std_Insurance_Sevendays: 'Seven days starting guarantee',
    txt_global_std_enddate: ' Date de fin',
    txt_global_std_p2ppushbodylendingcomingup: 'Veuillez noter que &quot; {name}&quot; will lend your car. Starts &quot;{start} &quot;',
    txt_global_std_inviteyournetworktostartdrivingsmarter: 'Invitez votre réseau à commencer à conduire plus intelligemment !',
    txt_global_std_TermConfirmSub_Text9: 'Thank you for your time with us!',
    txt_global_std_calender: 'Calandre',
    txt_global_std_p2pnocancelledbookingrequests: 'Aucune demande de réservation annulée',
    txt_global_std_itwillexpireshortlysomakesuretoverifysoonremembertocheckyourspamfolder: 'Il expirera sous peu, alors assurez-vous de le vérifier rapidement. Pensez à vérifier votre dossier spam.',
    txt_global_std_me: ' Moi',
    txt_global_std_connectingtoyourcar: 'Connexion à votre voiture',
    txt_global_std_ttest94: "c'est un test",
    txt_global_std_alerthighdivisiontext: 'Warning! Your driving style generates high cost per kilometer.',
    'txt_global_std_Al-KhelaifiQuote': 'Citation à venir ...',
    txt_global_std_p2ppushbodyexpensesdenied: "{name} n'a pas approuvé la dépense",
    txt_global_std_canitrytheappbeforesigningup: "Puis-je essayer l'application avant de m'inscrire ?",
    txt_global_std_PolicySchedule24: 'TPS',
    txt_global_std_kintoloyaltysystem: 'Le système de fidélité',
    txt_global_std_download: 'Télécharger',
    txt_global_std_youareinthetop5keepgoing: 'Vous êtes dans le top 5, continuez!',
    txt_global_std_buttogetthecorrectstatsofyourdrivingyouneedtogotoappsettingsinyourphoneandchoosebalwaysb: "... mais pour obtenir les statistiques correctes de votre conduite, vous devez accéder aux paramètres de l'application sur votre téléphone et choisir <b> Toujours. </b>",
    txt_global_std_p2ppushbodyrevokeadmin: 'Vos droits administrateur pour "{vehicle}" has been revoked by {name} .',
    txt_global_std_filteredon: 'Filtré sur',
    txt_global_std_cwelcome4: 'On?',
    txt_global_std_smallscratches: 'Petites rayures ?',
    txt_global_std_yourinsurancewillberenewedonmonthdayyear: 'Votre assurance sera renouvelée le {month} {day}, {year}',
    txt_global_std_yourteamneedsaname: 'Your team needs a name!',
    txt_global_std_placeholdertext: "Texte d'espace réservé",
    txt_global_std_thisisaskilledinconsistentdriver: "Il s'agit d'un conducteur qualifié, cependant, parfois lors d'un voyage et seulement pour une courte période de temps, ce conducteur a tendance à perdre le focus sur Cool Driving. Certaines distractions ou certains stress peuvent amener ce conducteur à ignorer la sensibilisation à la sécurité. Ceci est souvent résolu en discutant avec le conducteur et en demandant sa considération.",
    txt_global_std_lockedtitle: 'Fermé à clé',
    txt_global_std_chooseallowonce: 'Choisissez Autoriser une fois',
    'txt_global_std_Ready to drive': 'Ready to drive',
    txt_global_std_connectionwasabortedbeforethedrivewasvalid: 'La connexion a été abandonnée avant que le lecteur ne soit valide, le lecteur sera ignoré',
    txt_global_std_preparing: 'En train de préparer...',
    txt_global_std_Insurance_VariablePremium: 'Variable driving cost',
    txt_global_std_usermustacceptaninvitationtotheteamfirst: "L'utilisateur doit d'abord accepter une invitation à l'équipe.",
    txt_global_std_getbonusandsavemoney: 'Get bonus and save money',
    txt_global_std_p2pfaq7q: 'Comment puis-je annuler une réservation?',
    txt_global_std_DamageDate: 'Skadedatum',
    txt_global_std_drivingdatatroubleshooting: 'Diagnistic des données de conduite',
    txt_global_std_TermConfirmSub_Text4: 'The {appName} Plug-in, which you have been subscribed for 99 SEK per month, you return to us in the original packaging to the address:',
    txt_global_std_time: ' Heure',
    txt_global_std_continuetosetup: ' Continuer vers l’installation',
    txt_global_std_fleetcompany: 'Société de flotte',
    txt_global_std_p2pleavegroupchat: 'Quitter le chat en groupe',
    txt_global_std_acceptuserterms: 'Accepter les conditions d&#39;utilisation',
    txt_global_std_selecttype2: 'Sélectionner le genre',
    txt_global_std_NotCreatedAccount3_Text9: 'Venlig hilsen {appName}-Teamet',
    txt_global_std_payperkmandgetlowinsurance: 'Pay per km and get a low insurance premium each month.',
    txt_global_std_mustbeavalidsizeandcanonlyincludenumbersand: 'Doit être une taille valide et ne peut inclure que des chiffres et +',
    txt_global_std_aiexplainer: 'AI-Explainer',
    txt_global_std_start: 'Départ',
    txt_global_std_cantpublishafterenddate: 'Can not publish after end date',
    txt_global_std_MSIG_Onboarding12: 'MS First Capital',
    txt_global_std_HSolbergChamp: 'Il a emporté cinq fois la coupe dans le championnat de Norvège (1999-2003)',
    txt_global_std_createanaccountoruseexistingone: 'Créer un compte ou utiliser un existant',
    txt_global_std_youwonyourh2hchallenge: 'Vous avez gagné votre défi Head2Head contre {user} !',
    txt_global_std_Inviteafriedbothwillgetawarded: 'Invitez un ami et vous serez récompensés tous les deux!',
    txt_global_std_burglary123: 'Cambriolage',
    txt_global_std_apioverview: "Présentation de l'API",
    txt_global_std_whenthechallengeisaccepteditwillshowuponthestartpage: 'Lorsque le défi est accepté, il apparaîtra sur la page de démarrage.',
    txt_global_std_kintofaq10a: 'L&#39;application Kinto utilise l&#39;intelligence artificielle pour mesurer votre conduite. L&#39;application se connecte au cloud et analysera chaque seconde de votre conduite - transformée en «un profil». L&#39;IA compare vos profils à plus de 650 millions d&#39;autres profils de conduite uniques. L&#39;IA a plus de 16 ans d&#39;expérience pour rechercher la probabilité d&#39;un modèle de conduite d&#39;être impliqué dans un accident. Heureusement, après presque chaque voyage, le conducteur et les passagers rentrent chez eux en toute sécurité chez leurs proches.',
    txt_global_std_theft: 'Vol?',
    txt_global_std_PreSaleDetails_Text25: 'The insurance covers fire damage to electrical cables for short circuit. The deductible is 1500 SEK.',
    txt_global_std_PolicySchedule26: 'AUCUN EXCÉDENT POUR LE TITULAIRE ET LES CONDUCTEURS AUTORISÉS',
    txt_global_std_numberofbonuslevels: '{numtrips} trips with bonus',
    txt_global_std_PolicySchedule9: 'Tonnage / CC',
    txt_global_std_whenyouhavemadeadrvie: 'When you have made a trip',
    txt_global_std_RecieptMail_Text7: 'DELIVERY',
    txt_global_std_letmeshowyouhoweverythingworks: 'Laissez-moi vous montrer comment tout fonctionne ici!',
    txt_global_std_disableachievementtooltiptext: "Ce succès a été créé sans l'outil de succès. <br> Par conséquent, il ne peut actuellement pas être modifié dans cet outil.",
    txt_global_std_apikeymanager: 'GESTIONNAIRE DE CLÉS API',
    txt_global_std_howlongwillittaketoprocessmyclaim: 'Combien de temps faudra-t-il pour traiter ma réclamation?',
    txt_global_std_warikan_onboarding3: "Veuillez télécharger l'application Warikan KINTO à partir de l'URL ci-dessous.\n",
    txt_global_std_100km: '100 Km',
    txt_global_std_save: 'Enregistrer',
    txt_global_std_searchbynameoraccounttype: 'Rechercher par nom ou type de compte',
    txt_global_std_whendrivingonhigherspeedroadsplanningismoreimportantthaneverenteringahighwaytooslowlycancausedangertofellowmotoristsandleadtoqueuesbehindyouobservingthetrafficflowandadaptingyourspeedtotheconditionsisimportanttoavoidunnecessaryqueuesandpanicbraking: "Lorsque vous conduisez sur des routes à grande vitesse, la planification est plus importante que jamais. S'engager trop lentement sur une autoroute peut mettre en danger les autres automobilistes et entraîner des files d'attente derrière vous. Observer le flux de la circulation et adapter sa vitesse aux conditions est important pour éviter les files d'attente inutiles et les freinages d'urgence.",
    txt_global_std_pushdriveendmessagetitle: 'Køretur er slut - {0}',
    'txt_global_std_your@email.com': ' Votre@email.com',
    txt_global_std_enerfyanalytics_upsell_bulletpoint2: 'Découvrez de nouvelles informations pour permettre une action ciblée',
    txt_global_std_unknownracedriverDescription: 'Être annoncé',
    txt_global_std_level: 'Level',
    txt_global_std_KristofferssonMileage: 'I enjoy driving up north in Sweden a sunny winter day ready for a ski trip.',
    txt_global_std_findteamordriver: 'Trouver une équipe ou un pilote',
    txt_global_std_invitefriendsviaemail: ' Inviter des amis par e-mail',
    txt_global_std_ifyoudonothavebluetoothinthecaryoucanuseachipthatisplacedinthecarsobd2portachipcanbeusedinmostcarsfrom2001orlater: "Si vous n'avez pas de Bluetooth dans la voiture, vous pouvez utiliser une puce placée dans le port OBD2 de la voiture. Une puce peut être utilisée dans la plupart des voitures à partir de 2001 ou plus tard.",
    txt_global_std_creategroup: 'Créer un groupe',
    txt_global_std_yougetthisafterdriving25tripswithtrygdrive: 'Vous obtenez cela après avoir conduit 25 trajets avec Tryg Drive',
    txt_global_std_getiobd2: ' Obtenir iOBD2',
    txt_global_std_KleinschmidtDescription: 'Elle est la première et, jusqu’à présent, la seule femme d’avoir sa première victoire incontestable du circuit le plus long et le plus difficile au monde, le Rallye de Dakar. Son parcours a débuté à la moto. Après quatre rallyes en moto elle passe à quatre roues, en 1995, et s’engage dans le développement du véhicule à plus de succès du rallye, le Mitsubishi Pajero Evo, avec lequel elle emporte la victoire du Rallye de Dakar en 2001. En plus de son travail en tant que speaker, elle a publié son livre « Mein Siege bei der Dakar » (Ma victoire à Dakar).',
    txt_global_std_distance: 'Distance',
    txt_global_std_FirstDrive_Span1: 'You also got',
    txt_global_std_eco: 'Eco',
    txt_global_std_observe: ' Observer',
    txt_global_std_FirstDrive_Text13: '{appName}-Teamet',
    txt_global_std_deletetranslation: 'Supprimer la traduction',
    txt_global_std_damageabroad: 'Damage abroad',
    txt_global_std_okay: ' Ok',
    txt_global_std_demotext4: 'Voir votre bonus ici.',
    txt_global_std_ActivationLetter_Text11tryg: 'Du vil få besked fra PostNord om, at du skal afhente en pakke fra Tryg. Pakken indeholder den OBD-dongle, som du skal montere i din bil for at optjene bonus med TrygDrive.',
    txt_global_std_joinyourteaminthefiasdcandstayactivesmartandsustainablewhenyoutravelbycar: 'Rejoignez votre équipe dans le Fia SDC et restez actif, intelligent et durable lorsque vous voyagez en voiture.',
    txt_global_std_p2ppushtitlelendcar: '{name} - prêter ma voiture?',
    txt_global_std_p2pemailorpasswordwrong: "Adresse e-mail / mot de passe - les deux ou l'un des deux est incorrect",
    txt_global_std_areyousureyouwanttoadjustyourmileagethepricewillbeadjustedaccordingtothenewmileage: 'Êtes-vous sûr de vouloir ajuster votre kilométrage ? Le prix sera ajusté en fonction du nouveau kilométrage.',
    txt_global_std_ajustingmilageplan: 'Ajustement du plan kilométrique',
    txt_global_std_vsteam: 'VS. Équipe',
    txt_global_std_yourscoreisbasedonthedrivingpatternsyoucreatewitheachtripconcentrateonyourdrivingskillstoimproveyourscoreswewillprovideahistoryofallyourtripssoyoucanseehowyouredoing: 'Votre score est basé sur les habitudes de conduite que vous créez à chaque trajet. Concentrez-vous sur vos compétences de conduite pour améliorer vos scores. Nous vous fournirons un historique de tous vos voyages afin que vous puissiez voir comment vous allez.',
    txt_global_std_belowisthelinktoyourenerfyglobalapp: 'Vous trouverez ci-dessous le lien vers votre application Enerfy Global.',
    txt_global_std_youcancontactoneofourlicensedinsuranceagenciesbyclickingonlinkorcallingagentatphoneno: "Vous pouvez contacter l'une de nos agences d'assurance agréées en cliquant sur {link} ou en appelant {agent} au {phoneNo}",
    txt_global_std_cimage12h: ' GAGNEZ DE LA VITESSE AVANT DE MONTER UNE CÔTE',
    txt_global_std_noreviewstobepublishedpleaseedittranslationsifyouwishtopublish: 'Aucun avis à publier, veuillez modifier les traductions si vous souhaitez publier.',
    txt_global_std_changecar: 'Changer de voiture',
    txt_global_std_emailtoolong: "L'e-mail peut contenir jusqu'à 127 caractères.",
    txt_global_std_howdoicompeteinenerfyloyaltychallenge: 'Comment participer au défi de fidélité Enerfy?',
    txt_global_std_odostarts: 'Odo. Start',
    txt_global_std_cimage20h: ' UNE VITESSE MAX ÉLEVÉE N’ASSURE AUCUN GAIN DE TEMPS',
    txt_global_std_TermConfirmSub_Text2: 'will be terminated from',
    txt_global_std_kretursanalyse: 'Køretursanalyse',
    txt_global_std_enteryourpolicynumbertounlockallthebenefitsofthisapp: 'Entrez votre numéro de police pour débloquer tous les avantages de cette application.',
    txt_global_std_isanyonehurt: 'Is anyone hurt?',
    txt_global_std_createyourownleaderboardinthefiasmartdrivingchallengeandchallengeeachothertobecomesmarterandsaferontheroad: 'Créez votre propre classement dans le FIA Smart Driving Challenge et mettez-vous au défi de devenir plus intelligent et plus sûr sur la route.',
    txt_global_std_cimage21d: ' Sur le plan technique, il est plus facile d’adopter une conduite économe à une vitesse réduite.',
    txt_global_std_avaragescore: 'Note moyenne',
    txt_global_std_emergencynumber: ' Urgence',
    txt_global_std_tiphighway1DESC: 'Le chauffeur de voiture bleue conduit concentré et entrer sur la route en toute sécurité. Le conducteur de la voiture rouge ne modifie pas sa vitesse en fonction de la situation de conduite et doit utiliser les freins.',
    txt_global_std_makesureyouarewithintenmeters: "Assurez-vous d'être à moins de 10 m. de distance du Plug-in",
    txt_global_std_drivingtips: 'Conseils de conduite',
    txt_global_std_introduction: 'Introduction',
    txt_global_std_starting: ' Départ',
    txt_global_std_noactiveparticipantsthisweek: 'Aucun participant actif cette semaine',
    txt_global_edj_orgaddress: 'Start address',
    txt_global_std_fieldcannotbeempty: 'Le champ ne peut pas être vide',
    txt_global_std_yourchallengeranking: 'Classement de votre challenge',
    txt_global_std_createnewchallenge: ' Créer un nouveau défi',
    txt_global_std_NotActivatedAccount_Text3Moderna: 'Do you need help with creating an account? Please contact us at: modernasmartflex@modernaforsakringar.se or 0200-265 265.',
    txt_global_std_enteravaliddate: 'Entrez une date valide',
    txt_global_sidekick_invitemoremenutitle: 'Invite a friend',
    txt_global_std_p2pemailadressforcommunication: 'Adresse e-mail pour la communication',
    txt_global_std_searchcardriver: 'Recherche voiture/chauffeur',
    txt_global_std_recruitedby: 'Recruté par',
    txt_global_std_containsforbiddencharactersorspace: " contient des caractères interdits : ' ? & / &#60; &#62;  ou espace",
    txt_global_std_p2psignupandcontinue: 'Inscrivez-vous et continuez',
    txt_global_std_HighUsageAlarm_Text6: 'Har du brug for hjælp?',
    txt_global_std_idonthavebluetoothinmycarcanistillusetheapp: "Je n'ai pas de Bluetooth dans ma voiture, puis-je quand même utiliser l'application ?",
    txt_global_std_allowbluetooth: 'Autoriser Bluetooth',
    txt_global_std_youhavenowearnedascoreof4point4outof510timeswithtrygdrive: 'Vous avez maintenant obtenu un score de 4,4 sur 5 10 fois avec Tryg Drive',
    txt_global_std_p2pselectedcar: 'Voiture sélectionnée',
    txt_global_std_pleaseallowkintoaccesstoyourlocationtogetstartedwiththeapp: 'Veuillez autoriser Kinto à accéder à votre emplacement pour commencer à utiliser l&#39;application.',
    'txt_global_std_pssst!getinsuracenowandrecieveaspecialgift!': 'Pssst! Get insurance now and recieve a special gift!',
    txt_global_std_baselevel: 'Niveau de base',
    txt_global_std_ifyoucontinuetodriveonlevel: 'Si vous continuez à conduire au niveau {level}, vous pourriez économiser {discount} sur votre assurance.',
    txt_global_std_generateinvitationcode: "Générer un code d'invitation",
    txt_global_std_amessageandrecipientsarerequired: 'A message and recipients are required!',
    txt_global_std_MSIG_InsuranceLetter6: 'Règlement de 1959 sur les véhicules à moteur (risques de tiers) (Malaisie)',
    txt_global_std_p2pconnectyourphonetoyourcarandyouwillunlockallthemagic: 'Connectez votre téléphone à votre voiture - et vous débloquerez toute la magie.',
    txt_global_std_addusersbyuploadingacsvfilewiththeirdetailsthefieldsfornameemailandpermissionsaremandatory: "Ajoutez des utilisateurs en téléchargeant un fichier CSV avec leurs détails. Les champs pour le nom, l'e-mail et les autorisations sont obligatoires.",
    txt_global_std_automessage_level_14__1: 'Ups! Now you need to focus more on driving to be safe on the road.',
    txt_global_std_fixitnowreminder: 'Répare le maintenant',
    txt_global_std_managecarsagreementshere: 'Gérez les voitures et les accords ici.',
    txt_global_std_comparemyactivities: ' Comparer mes trajets',
    txt_global_std_WhichDamageOccurred: 'Vilka skador uppkom',
    txt_global_std_readytodrive: 'Prêt à conduire',
    txt_global_std_p2pmycalendar: 'Mon agenda',
    txt_global_std_earnpointsandsavemoney: 'Gagnez des points et économisez sur votre franchise',
    txt_global_std_drivescoresave: 'Conduire. But. Sauver.',
    txt_global_std_highest: 'Record',
    txt_global_std_Adjust_Text11: 'Du har kjørt lengre enn hva du først antok. Vi har derfor justert årlig kjørelengde fra',
    txt_global_std_sentyouco2: 'sent you CO2',
    txt_global_std_testdeploynb: 'Hej',
    txt_global_std_backtochallengedashboard: 'Retour au tableau de bord du défi',
    txt_global_std_reportOther: 'Other',
    txt_global_std_warikan_onboarding11: 'Centre client KINTO',
    txt_global_std_FriendRequest_Text6: '{appName}-Teamet',
    txt_global_std_takepicturesofyourcar: 'Prendre des photos de votre voiture',
    txt_global_std_acommontypeofunsafedrivingbehaviorisputtinghugeeffortintochasingthenexttrafficlightitslikeachallengetogettothenextcrossingasquicklyaspossibleonlytobestuckwaitingforthelighttoturngreendrivingsmoothlyandlookingwellaheadtoanticipatewhatscomingupsavesbothtimeandfuelnew: "Un type courant de comportement de conduite dangereux consiste à déployer d'énormes efforts pour courir après le prochain feu de circulation ! C'est comme un défi d'arriver au prochain passage à niveau le plus rapidement possible pour être bloqué en attendant que le feu passe au vert 😊 Conduire en douceur et regarder loin devant pour anticiper ce qui s'en vient permet d'économiser du temps et du carburant.",
    txt_global_std_addyourlogotypeandchoosecolortheme: 'Ajoutez votre logotype et choisissez le thème de couleur',
    txt_global_std_cropyourpopupimage: 'Crop image',
    txt_global_std_accounttype: 'Type de compte',
    txt_global_std_noofdrives: 'Nombre de lecteurs',
    txt_global_std_wasanyoneinjured: 'Quelqu&#39;un a-t-il été blessé?',
    txt_global_std_november: ' Novembre',
    txt_global_std_variablepremium: 'Prime mensuelle variable',
    txt_global_std_youneedtoinputapassword: ' Vous devez saisir un mot de passe',
    txt_global_std_pricemonth: 'Monthly price',
    txt_global_std_findchallenges: 'Trouver un challenge',
    txt_global_std_easytomonitoryouresgtargets: 'Suivi facile de vos objectifs ESG',
    txt_global_std_xhours: '{hours} heures',
    txt_global_std_iwanttonotifyyouaboutyourdrivingresults: 'Je veux vous informer de vos résultats de conduite. D&#39;accord?',
    txt_global_std_p2ppercentageused: 'Pourcentage utilisé',
    'txt_global_std_Choose reader': 'Choose reader',
    txt_global_std_youraveragerisklevelispoints: 'Votre niveau de risque moyen est de {points}',
    txt_global_std_loyalty_upsell: "Pages de fidélité - soutiennent la croissance de l'entreprise grâce à des relations plus solides.",
    txt_global_std_p2pwarikanloginorlinkissues: 'Warikan - Problèmes de connexion ou de lien',
    txt_global_std_enterpassword: 'Entrer le mot de passe',
    txt_global_std_lastdatereceived: 'Dernière date de réception',
    txt_global_std_neverbeeneasier: 'Jamais été aussi simple',
    'txt_global_std_3) Started your engine.': '3) Started your engine.',
    txt_global_std_activatemyinsurance: 'Activer mon assurance',
    txt_global_std_p2pother: 'Autre',
    txt_global_std_riskindex_upsell_pitch_bulletpoint3: "Niveaux de risque à l'échelle mondiale, par pays ou par code postal/code postal",
    txt_global_std_appnamecollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: "{appName} collecte des données de localisation pour activer le score de conduite même lorsque l'application est fermée ou non utilisée.",
    txt_global_std_whendrivingincityconditionsavoidchasingthenextcrossingortrafficlightbygainingspeedquicklythenslowingquicklythishurtstraveltimeandfueleconomyandincreasescrashrisklookingwellaheadanddrivingsmoothlywillhelpyouanticipateandhandleanyevent: "Lorsque vous conduisez en ville, évitez de courir après le prochain croisement ou feu de circulation en accélérant rapidement puis en ralentissant rapidement. Cela nuit au temps de trajet et à l'économie de carburant, et augmente le risque d'accident. Regarder loin devant et conduire en douceur vous aidera à anticiper et à gérer tout événement.",
    txt_global_std_zipcode: 'Zip code',
    txt_global_std_p2pwhatdoyouwanttodo: "Qu'est-ce que tu veux faire?",
    txt_global_std_ourairiskinsighttoolgivesyouthepowertoidentifyfollowupandreportoncsrtargetstrackriskandco2perteammemberandrewardthosewhoexcel: "Notre outil d'analyse des risques par IA vous donne le pouvoir d'identifier, de suivre et de rendre compte des objectifs de RSE, de suivre les risques et le CO2 par membre de l'équipe et de récompenser ceux qui excellent.",
    txt_global_std_cimage21h: ' UNE VITESSE RÉDUITE EST PLUS FACILE SUR LE PLAN TECHNIQUE',
    txt_global_std_average: ' Moyenne',
    txt_global_std_yourdocumentwassent: 'Votre document a été envoyé!',
    txt_global_std_mykilometerpremiumwastohigh: 'Kilometer premium to high',
    txt_global_std_followupdate: 'Date de suivi',
    txt_global_std_won: ' Gagné',
    txt_global_std_hellolinus: 'salut Linus !',
    txt_global_std_speedchangedescription: ' Ce graphique vous présente la durée totale passée à accélérer, ralentir et en vitesse constante. Conseils : Donnez-vous pour objectif de passer plus de temps à ralentir qu’à accélérer.',
    txt_global_std_surroundings: 'Surroundings',
    txt_global_std_p2pblockedthecar: '{name} a bloqué la voiture',
    txt_global_std_above1000unlocked: 'Vous avez roulé au-dessus de 1000! Impressionnant!',
    txt_global_std_letsdrive: 'Conduisons!',
    txt_global_std_easytogeteverydriverinyournetworkinvolved: 'Facile à impliquer tous les conducteurs de votre réseau',
    txt_global_std_fiasdcisaninitiativetoreducepollutionandincreasesafetyontheraodswhiledrivingmotoristsgettheirdrivingassessedinrealtimebyouraitechnologythatunderstandseachtripslevelofriskandcarbonfootprintjustbymakingmotoristsawareoftheirimpactwecancreateapositivechange: "Fia SDC est une initiative visant à réduire la pollution et à accroître la sécurité sur les routes. Pendant qu'ils conduisent, les automobilistes font évaluer leur conduite en temps réel par notre technologie d'intelligence artificielle, qui comprend le niveau de risque et l'empreinte carbone de chaque trajet. Simplement en sensibilisant les automobilistes à leur impact, nous pouvons créer un changement positif.",
    'txt_global_std_0km/hco2tonne': '0 km/h CO2 tonnes',
    txt_global_std_VilliersQuote: 'Quote coming soon...',
    txt_global_std_membersinthisgroup: 'Membres de ce groupe',
    txt_global_std_renewaldate: ' Date de renouvellement',
    txt_global_std_1000pointaverageachievementlocked: 'Conduisez avec un {scoretype} of {score} ou mieux pendant une semaine pour débloquer ce succès et gagner 1000 points.',
    txt_global_std_carriedrisk: 'Risque porté',
    txt_global_std_idrivetoomuch: 'Je conduis trop',
    txt_global_std_headtohead: 'Head to head',
    txt_global_std_thisisonlyneededthefirsttimeyouconnecttoyourcar: "Cela n'est nécessaire que la première fois que vous vous connectez à votre voiture.",
    txt_global_std_reducing: 'réduire',
    txt_global_std_p2pgotocostsplit: 'Aller à la répartition des coûts',
    txt_global_std_p2psendalinksothatthepersonborrowingyourcarcandownload: "Envoyez un lien pour que la personne qui emprunte votre voiture puisse télécharger l'application {appname}.",
    txt_global_std_HansenQuote: 'Conduire de façon “smart” c’est du fun ! Ça me fait épargner de l’argent et réduire mon l’impacte dans notre environnement précieux. ',
    txt_global_std_createsanaccountforthefirsttime: 'Creates an account for the first time',
    txt_global_std_PoliceInformation: 'Polisanmälan',
    txt_global_std_PettersolbergShortDescription: 'Champion du monde RX 2014 et 2015, champion du WRC 13 fois, champion du rallye de Grande-Bretagne 2002 et 2003',
    txt_global_std_choosebetweenenteringchassisnumberenginenumberplatenumberorpolicynumber: 'Choisissez entre le numéro de châssis / numéro de moteur, le numéro de plaque ou le numéro de police.',
    txt_global_std_p2pusesameemailasregisteredonkintoaccount: '* Utilisez le même email que celui enregistré sur le compte Kinto',
    txt_global_std_chooseaccidenttype: 'Choisissez le type d&#39;accident',
    txt_global_std_choosefile: 'Choisir le fichier...',
    txt_global_std_ActivationLetter_Text1: 'Thank you for choosing Enerfy - Insurance with Moderna. You have signed an insurance for the vehicle',
    txt_global_std_alreadyexistspossibleduplicationinternalservererror: 'Existe déjà, duplication possible. Erreur Interne du Serveur',
    txt_global_std_cimage22d: ' Utilisez le régulateur de vitesse exclusivement sur des routes plates. Les routes sont considérées comme plates lorsqu’il n’est pas nécessaire d’utiliser le frein à main.',
    txt_global_std_p2pdoyouwanttobookthedaysinbetweenormakeitseparatebookings: 'Voulez-vous réserver les jours intermédiaires ou faire des réservations séparées?',
    txt_global_std_youraveragelevelis: 'Your average level is',
    txt_global_std_bottomfinaliststext: 'Le World Challenge consiste en 3 manches de challenge, 3 périodes qualificatives entre les manches et 1 grande finale.\n' +
      '\n' +
      'Les 3 meilleurs pilotes de chaque manche du challenge seront directement qualifiés pour la grande finale en décembre.',
    txt_global_std_TermConfirmPlugin_Text5: 'Finally, we need you to enter you cars mileage for all the information to match when the insurance expires. Click on the link down below to log in and enter the number.',
    txt_global_std_anerroroccurredwhilesavingyourchanges: 'Une erreur s’est produite lors de la sauvegarde de vos modifications',
    txt_global_std_activatelocationservice: 'Activer le service de localisation (toujours activé)',
    txt_global_std_p2ppaynow: 'Payez maintenant',
    txt_global_std_wheresmyoutlet: ' Où se situe ma prise ?',
    txt_global_std_friendstothekintoapp: 'amis de l&#39;appli Kinto',
    txt_global_std_createyouraccounttosaveandtrackthecostofyourtripsandgetaccesstoyourpersonaldrivingcoach: 'Créez votre compte pour enregistrer et suivre le coût de vos déplacements et accéder à votre coach de conduite personnel',
    txt_global_std_damages: 'Damages',
    txt_global_std_plugitinyourcaarsobdoutlet: ' Branchez-le sur la prise du système de diagnostic embarqué de votre voiture. Elle se trouve en principe près du volant (votre boitier électronique vous sera livrée dans un délai de 1 à 3 jours ouvrés)',
    txt_global_std_WelcomeLetter_Text4: 'Bienvenue !',
    txt_global_std_grandTheftAuto: 'Grand theft Auto',
    txt_global_std_HSolbergInterests: 'Voitures, Rallye et course automobile, participer dans différents vernissages avec ses sponsors',
    txt_global_std_areyousureyouwanttoredeemxpointscoupon: 'Voulez-vous vraiment échanger {x} points ?',
    txt_global_std_p2pclickdatestoseebooking: 'Cliquez sur les dates dans le calendrier pour voir vos réservations.',
    txt_global_std_yourlocation: 'Your location',
    txt_global_std_currentodometervalue: 'Valeur odométrique actuelle',
    'txt_global_std_The gloves are off! When {opponentName} accepts the challenge you can begin driving.': 'The gloves are off! When {opponentName} accepts the challenge you can begin driving.',
    txt_global_std_singlecollision: 'Single collision',
    txt_global_std_alreadyhavefiasdc: 'Vous avez déjà le SDC FIA?',
    'txt_global_std_Yes, I have a reader': 'Yes, I have a reader',
    txt_global_std_description: ' Description',
    txt_global_std_helptextsavingstotaltitle: 'Kjøreutbytte (totalbeløp)',
    txt_global_std_uploadyourdatatoseehowsufficientitis: 'Téléchargez vos données pour voir si elles sont suffisantes.',
    txt_global_std_platenumber: 'Numéro de la plaque',
    txt_global_std_invalidcode: 'Code invalide',
    txt_global_std_p2pyesremovefriend: 'Oui, supprimer un ami',
    txt_global_std_paylessnextmonth: 'payer moins le mois prochain',
    txt_global_std_KristofferssonQuote: 'La sécurité routière est un sujet qui me tient à cœur ! Le FIA Smart Driving Challenge sera aussi amusant et « smart » que cela puisse apparaître, et donnera l’opportunité à tout le monde d’améliorer leur écoconduite et la sécurité routière. Ayant participé dans toutes les courses automobiles sur les conditions de terrains différents, je me réjouis de transmettre cette expérience et de promouvoir le « smart driving » parmis nous.',
    txt_global_std_p2pyour: 'Votre',
    txt_global_std_latitudelongitudelatitudelongitude: "Latitude, longitude: {latitude}, {longitude}'",
    txt_global_std_map: 'Map',
    txt_global_std_youcancontactoneofourlicensedinsuranceagenciesbyclickingonfindanagentonourwebsiteorcallingagentatphonenumber: "Vous pouvez contacter l'une de nos agences d'assurance agréées en cliquant sur Trouver un agent sur notre site Web ou en appelant {agent} au {phoneNumber}.",
    txt_global_std_renewyourinsurance: 'Renouveler votre assurance',
    txt_global_std_p2pcost: 'Coût',
    txt_global_std_yesexchangepoints: "Oui points d'échange",
    txt_global_std_oldpasswordhelp: 'Nous avons besoin de votre mot de passe actuel pour nous assurer que ce compte vous appartient.\n',
    txt_global_std_offlinetrip: ' Hors ligne',
    txt_global_std_uploadlogotype: 'Télécharger le logo',
    txt_global_std_donthaveanaccountsignuplink: "Vous n'avez pas de compte ? {signUpLink}",
    txt_global_std_haveaproblemwiththeappvisitourfaqorcontactus: "Vous avez un problème avec l'application ? Consultez notre FAQ ou contactez-nous...",
    txt_global_std_starburstunlockedkey: 'Vous avez conduit au niveau 4,4 ou mieux! Impressionnant! 300 points ont été ajoutés à votre portefeuille Kinto.',
    txt_global_std_aonceaclaimsettlementisdeterminedyoucanreceiveacheckoranelectronicpaymentintoyourbankaccount: "R : Une fois le règlement d'une réclamation déterminé, vous pouvez recevoir un chèque ou un paiement électronique sur votre compte bancaire.",
    txt_global_std_completewizard: 'Assistant complet',
    txt_global_std_enteremailtodostuff: 'Entrez votre e-mail pour recevoir un e-mail, où vous pourrez réinitialiser et définir un nouveau mot de passe de votre choix.',
    txt_global_std_enterodometervaluefor: 'Enter odometer value for',
    txt_global_std_datasentsuccess: 'You data has been sent, please check your inbox!',
    txt_global_std_on: ' On',
    txt_global_std_whatifichangecar: 'Et si je change de voiture?',
    txt_global_std_connecttotheprovidedobddeviceifyourcar: 'Connectez-vous à l&#39;appareil OBD fourni (Enerfy Mini OBD) si votre voiture n&#39;a pas de Bluetooth',
    txt_global_std_selectyourcarmodeltoseethemostaccuratedataofsavedco2: 'Sélectionnez votre modèle de voiture pour voir les données les plus précises de CO2 économisé.',
    txt_global_std_forgotyourpassword: ' Vous avez oublié votre mot de passe ?',
    txt_global_std_getacoscoreof15orlowerstartdrivingtogetyourscore: 'Obtenez un score CO₂ de 15 % ou moins. Commencez à conduire pour obtenir votre score !',
    txt_global_std_HighMileage_Text2: 'Din totale kjørelengde så langt er',
    txt_global_std_TaxInvoice3: 'Doc non',
    txt_global_std_enerfyplugin: 'Plug-in Enerfy',
    txt_global_std_addnoteshere: ' Ajouter des notes à cet endroit',
    txt_global_std_best: ' Meilleur(e)',
    txt_global_std_p2pnogoback2: 'Non, reviens',
    txt_global_std_fitnessmotion: 'Fitness / mouvement',
    txt_global_std_p2pyourkintonumbercanbefoundwhenyoulogintoyourkintoaccount: 'Votre numéro Kinto peut être trouvé lorsque vous vous connectez à votre compte Kinto.',
    txt_global_std_superstar: 'Superstar',
    txt_global_std_doihavetoactivatetheappeverytimeidriveanswer: 'Non, l&#39;application fonctionne en mode arrière-plan! Assurez-vous de toujours activer le Bluetooth et les services de localisation sur votre téléphone.',
    txt_global_std_sorrywecouldnotfindthatemailaddresspleasecontactagentsupport: "Désolé, nous n'avons pas pu trouver cette adresse e-mail. Veuillez contacter l'assistance {agent}.",
    txt_global_std_ayoucancontactoneofourlicensedinsuranceagenciesbyclickingonlinkorcallingagentatphoneno: "R : Vous pouvez contacter l'une de nos agences d'assurance agréées en cliquant sur {link} ou en appelant {agent} au {phoneNo}.",
    txt_global_std_thisisonlyneededthefirsttimeyouconnecttoyourcar1: "Cela n'est nécessaire que la première fois que vous vous connectez à votre voiture.",
    txt_global_std_somethingwentwrongandwecouldnotdisplayyourresulthere: 'Une erreur s&#39;est produite et nous n&#39;avons pas pu afficher votre résultat ici.',
    txt_global_std_someoneelseispairedtoyourcarnodot: "Quelqu'un d'autre est associé à votre voiture",
    txt_global_std_selectagreementnumber: 'Sélectionnez le numéro de contrat',
    txt_global_std_thisfunctionisunstable: ' Cette fonctionnalité est instable et devrait être utilisée avec précaution.',
    txt_global_std_p2pfriendcalendar: "L'agenda de {name}",
    txt_global_std_hourh: ' h',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweek: 'Vous avez vraiment bien conduit! Votre {scoretype} moyen était meilleur que {score} pendant une semaine!',
    txt_global_std_vehicledata: 'Données du véhicule',
    txt_global_std_amazingdriver: 'Pilote incroyable',
    txt_global_std_bothyouandyourfriendwillget10kmfreedistancetodrivewithenerfy: 'À la fois, votre ami et vous aurez 10 km de distance à parcourir avec Enerfy!',
    txt_global_std_confirmyouremailaddress: 'Confirmez votre adresse email',
    txt_global_std_rwmgoback: 'Retourner',
    txt_global_std_appcreator_upsell_pitch_bulletpoint1: 'Optimisez votre parcours client digital en intégrant les couleurs et le logotype de la marque',
    txt_global_std_yourco2overview: 'Votre aperçu CO₂',
    txt_global_std_InsuranceChange_Text2: 'övergått och blivit en',
    txt_global_std_excessdiscount: 'Remise excédentaire',
    txt_global_std_addressline2: 'Adresse Ligne 2',
    txt_global_std_inbonusforyourinsurance: 'en prime pour votre assurance.',
    txt_global_std_didyoureceivethepayment: 'Avez-vous reçu le paiement ?',
    txt_global_std_Insurance_FixedPremium: 'Fixed monthly premium',
    txt_global_std_ensammenligningmeddennekreturoggennemsnittetafalledineturedeseneste30dage: 'En sammenligning med denne køretur og gennemsnittet af alle dine ture de seneste 30 jours.',
    txt_global_std_createyourapp: 'Créez votre application',
    txt_global_std_dashboard_users: 'Utilisateurs',
    txt_global_std_ifbluetoothisalreadyactivatedyoucanstillgetthiserror: 'Si Bluetooth est déjà activé, vous pouvez toujours recevoir ce message d&#39;erreur si votre Bluetooth n&#39;est pas activé sur votre téléphone.',
    txt_global_std_notificationsettings: 'Paramètres de notification',
    txt_global_std_loadingevents: 'Téléchargement en cours...',
    txt_global_std_firstname: ' Prénom',
    txt_global_std_hassentyouachallenge: 'vous a lancé un défi !',
    txt_global_std_wrongpasswordformatminimum8charactersatleastoneuppercaseletteronelowercaseletterandonenumber: 'Mauvais format de mot de passe. Minimum 8 caractères, au moins une lettre majuscule, une lettre minuscule et un chiffre. ',
    txt_global_std_pigwon: 'Vainqueur de head2head',
    txt_global_std_personalizeyourchallenge: 'Personnalisez votre défi',
    txt_global_std_thereisnopermissions: "Il n'y a pas d'autorisations",
    txt_global_std_claimsdata: 'Données sur les réclamations',
    txt_global_std_badinternetconnection: "Mauvaise connexion Internet. Cela peut entraîner des retards dans l'application et affecter vos données de conduite.",
    txt_global_std_loginwithanotheraccount: 'Connectez-vous avec un autre compte',
    txt_global_std_InsuranceMail_TextYes: 'Yes',
    txt_global_std_callus: 'Call Us',
    txt_global_std_test_key22: "c'est un essai 2",
    txt_global_std_6months: '6 mois',
    txt_global_std_groupvsgroup: 'Groupe contre groupe',
    txt_global_std_reportaccidentonline: 'Signaler un accident en ligne',
    txt_global_std_cardetails: 'Car details',
    txt_global_std_date: ' Date',
    txt_global_std_communicate_upsell_pitch_bulletpoint2: 'Connectez-vous avec les utilisateurs sur leurs canaux préférés',
    txt_global_std_v2risklevel: 'Niveau de risque',
    txt_global_std_parkingAccident: 'Parking Accident',
    txt_global_std_PreSaleDetails_Text50: 'The insurance covers legal expenses for certain disputes involving the insured vehicle. Legal protection does not apply for criminal cases where suspicion of criminal prosecution or to any part refers to intentional or grossly negligent acts such as gross negligence, drunk driving or driving without a license. The deductible is 20%, at least 1500 SEK, maximum amount is stated in the insurance policy.',
    txt_global_std_hereyoucanloweryoursubscriptioncostbyupto20permonthbydrivingsafe2: "Ici, vous pouvez réduire le coût de votre abonnement jusqu'à 20 % par mois en conduisant prudemment.",
    txt_global_std_ifyouareaninsurancecustomeryoucanfindyourpolicynumberwhenyoulogintoyourappnameaccount: "Si vous êtes un client d'assurance, vous pouvez trouver votre numéro de police lorsque vous vous connectez à votre compte {appname}.",
    txt_global_edj_purpose: 'Purpose',
    txt_global_std_above1000streaklocked: 'Débloquez ce succès en conduisant avec un score supérieur à 1000 5 fois.',
    txt_global_std_Gdpr_Text2: 'Nous avons reçu votre demande de suppression des informations de votre compte. Veuillez noter que la suppression de votre compte signifie que toutes les données, y compris les dossiers de conduite, les équipes, les dates de course et les compétitions seront supprimées. Les données supprimées ne peuvent pas être récupérées.',
    txt_global_std_loadingusers: 'Chargement des utilisateurs...',
    txt_global_std_qualifiedecoleague: 'Qualified ECO league',
    txt_global_std_createapikey: 'Créer une clé API',
    txt_global_std_unlockeddescriptionlabel: "Description déverrouillée, affichée dans l'application",
    txt_global_std_totalpointscollected: 'Total des points collectés',
    txt_global_std_NotCreatedAccount5_Hello: 'Hello',
    txt_global_std_p2ptogetaccesstoallfunctionsinthisappyouneedtobeinvitedbyakintocarowner: 'Pour accéder à toutes les fonctions de cette application, vous devez être invité par un propriétaire de voiture Kinto.',
    txt_global_std_hour: ' Heure',
    txt_global_std_PreSaleDetails_Text54: 'Replaces damage to your own car from a traffic accident, even if you are at fault. The insurance also covers other external accidents such as driving into a ditch and willful damage by third parties. Vehicle damage does not include damage by corrosion, pitting, cold, moisture or humidity, or damage that comes thereof.',
    txt_global_std_yourteam: 'Votre équipe',
    txt_global_std_PreSaleDetails_Text62: 'We may terminate your insurance if you or another insured grossly neglect your obligations, or for other exceptional reasons.',
    txt_global_std_p2plendcar: 'Prêter une voiture',
    txt_global_std_PaymentTrouble2_Text3Enerfy: 'SEK for your car insurance.',
    txt_global_std_demotext1: 'Voyez combien de CO2 vous avez économisé en conduisant intelligemment.',
    txt_global_std_p2pconfirmselection: 'Confirmer la sélection',
    txt_global_std_skeylevel: 'Niveau',
    txt_global_std_terminateinsurance2: 'Résiliation de l&#39;assurance',
    txt_global_std_achievements: 'Réalisations',
    txt_global_std_applyfilters: 'Appliquer des filtres',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsabouttheagentappandcompeteagainstthemforlowerscores: "R : Une fois que vous associez l'application au Bluetooth de votre véhicule et autorisez l'application à utiliser les services de localisation, nous comparons vos habitudes de conduite à la bibliothèque de modèles de conduite de notre IA et fournissons un score. Votre prime d'assurance est basée sur qui conduit votre véhicule et sur sa qualité de conduite. La personne qui conduit votre véhicule est encouragée à télécharger l'application et à suivre ses scores. Parlez de l'application {agent} à vos amis et affrontez-les pour obtenir des scores inférieurs.",
    txt_global_std_carsharingcompany: "Société d'autopartage",
    txt_global_std_TerminationMail: 'Your car insurance has expired',
    txt_global_std_communicate_upsell_pitch_bulletpoint3: 'Communiquer des messages contextuels au bon moment',
    txt_global_std_p2pwantstoborrowyour: ' veut emprunter votre',
    txt_global_std_editgroup: 'Modifier le groupe',
    txt_global_std_coreresources: 'Ressources de base',
    txt_global_std_highrisk: 'Risque élevé',
    txt_global_std_cosavings: 'Économies de CO²',
    txt_global_std_fitnessreasontext: 'La forme physique / le mouvement sont nécessaires pour déterminer si vous conduisez ou marchez.',
    txt_global_std_p2perrorcostcannotbegreaterthantotalcosttosplit: 'Erreur : le coût ne peut pas être supérieur au coût total à fractionner',
    txt_global_std_register: 'registre',
    txt_global_std_Unsubscribe: ' Cliquez ici pour vous désinscrire de cette liste.',
    txt_global_std_team: 'Équipe',
    txt_global_std_makecoach: 'Make coach',
    txt_global_std_greatdriving: 'Bonne conduite!',
    txt_global_std_aroundtheclock: ' 24 heures sur 24',
    txt_global_std_enteravalidvehicleidentification: 'Entrez une identification de véhicule valide.',
    txt_global_std_createnewbtntext: 'Créer un nouveau',
    txt_global_std_everydayhero: 'Héros du quotidien',
    txt_global_std_TermConfirmSub_Text8: 'If in the future you would regret your choice of termination, you are welcome to contact us. We will be happy to help you aquire the insurance again.',
    txt_global_std_resultofanalyse: "Résultat d'analyse",
    txt_global_std_MSIG_Onboarding3: 'qui est valable à partir de',
    txt_global_std_p2paccept: "J'accepte",
    txt_global_std_cimage3h: ' ANTICIPEZ LES RÉDUCTIONS DE VITESSE',
    txt_global_std_goldlevel: 'Niveau or',
    txt_global_std_drivewelltogetagoodscore: 'Drive well to get a good score. The better the score, the less you will pay!',
    txt_global_std_p2puserborrowedcartimesph: '{user} a emprunté {car} {times} fois',
    txt_global_std_plugyourpluginintothecar: '2. Plug your plug-in into the car',
    txt_global_std_smartdriverweeklylockedkey: 'Pour gagner ce badge, gardez un score supérieur à 3,5 pendant une semaine.',
    txt_global_std_HighDrivingScore: 'Varsel: Oppsigelse grunnet kjørescore',
    txt_global_std_p2ppushbodynewpaymentquestion: 'Did you receive a payment from {name}? Make sure to mark it as paid.',
    txt_global_std_Insurance_Information2: 'If you have questions about your invoice you are welcome to contact our customer service department. \r\n' +
      'E-mail: info@enerfy.se. Phone: +46 (0)8-555 932 00',
    txt_global_std_couldnotloadheadtoheaddata: 'Could not load Head to Head data',
    txt_global_std_howdoicompeteinappchallenge: 'Comment participer au {appname} ?',
    txt_global_std_forthisapptoworkproperlylocationservicemustbeallowed: 'Pour que cette application fonctionne correctement, le service de localisation doit être autorisé sur votre appareil. Veuillez sélectionner "Toujours" dans les paramètres de localisation.',
    txt_global_std_mapsarenotavailableforthisperiod: ' Les cartes ne sont pas disponibles pour la période donnée',
    txt_global_std_faq: 'FAQ',
    txt_global_std_searchdivision: 'Service de recherche',
    txt_global_std_of: 'de',
    txt_global_std_nameOfOwner: 'Evetuellt namn på ägare',
    txt_global_std_PolicySchedule12: 'Type de couverture',
    txt_global_std_ActivationLetter_Text10: 'Drive Safetly!',
    txt_global_std_chooseyouroption: 'Choisissez votre option :',
    txt_global_std_rwmsheettext: "Envoyez un lien à votre ami pour qu'il puisse télécharger l'application Pioneer. Une fois qu'ils auront créé un compte, ils apparaîtront dans votre liste d'amis. Vous pourrez alors enregistrer un trajet où vous avez partagé un trajet.",
    txt_global_std_PaddonQuote: 'Il ne suffit pas de rêver, il faut réaliser vos rêves ! Faite-le pour la planète !',
    txt_global_std_locked_account: 'Cet utilisateur a été verrouillé pendant trop de tentatives de connexion infructueuses. Réessayez plus tard.',
    txt_global_std_Adjust_Text2: 'Vi bekrefter at du har endret din årlige kjørelengde fra',
    txt_global_std_30days: '30 jours',
    txt_global_std_account: 'konto',
    txt_global_std_HighUsageAlarm_Text3: 'Stress ikke når du kører, heller ikke når du har travlt ',
    txt_global_std_challengedashboard: 'Tableau de bord du défi',
    txt_global_std_youcanonlyregisteraridesharingtriponceperweek: "Vous ne pouvez enregistrer un trajet en covoiturage qu'une fois par semaine",
    txt_global_std_wecannothookusupagainstenerfycloudmakesureyourphonehasaworkingconnectiontothenternet: 'We can not hook us up against {appName} cloud, make sure your phone has a working connection to the Internet.',
    txt_global_std_thephonenumbercannotincludespecialcharacterslikes: `Le numéro de téléphone ne peut pas inclure de caractères spéciaux tels que "'?&/&#60; &#62;\\s#!%$%^*-`,
    txt_global_std_writemessage: 'Écrire un petit message',
    txt_global_std_costoverview: 'Aperçu des coûts',
    txt_global_std_p2pyouandmembersborrowingyourcarneedtoconnecttheapptoyourcarsbluetooth: "Vous et les membres empruntant votre voiture devez connecter l'application au système Bluetooth de votre voiture avant de conduire.",
    txt_global_edj_destaddress: 'End address',
    txt_global_std_yourreportisready: 'Votre rapport est prêt !',
    txt_global_std_needstobeavalidphonenumbermustbeavalidsizeandcanonlyincludenumbersand: 'Doit être un numéro de téléphone valide. Doit être une taille valide et ne peut inclure que des chiffres et +',
    txt_global_std_week: ' Semaine',
    txt_global_std_reviewbeforepublishing: 'Réviser avant de publier',
    txt_global_std_addnewuser: ' Ajouter un nouvel utilisateur',
    txt_global_std_p2pfaq3q: 'Que faire si je ne trouve pas où la voiture est garée?',
    txt_global_std_typeofscore: 'Type de pointage',
    txt_global_std_p2pthefollowingbonuswillbegiven: 'Le bonus suivant sera donné.',
    txt_global_std_JyrkiainenFacts: 'Suvi a grandi dans une famille de pilotes de courses, entouré par le sport automobile, fille de Minna Sillankorva, championne féminine du WRC en 1991 et neuf fois championne de Finlande.',
    txt_global_std_yourtripsinsight: 'Aperçu de vos trajets',
    txt_global_std_account1: 'Compte',
    txt_global_std_getanoverviewoverbonus: 'Get an overview of your bonus',
    txt_global_std_baddriving: 'Bad driving',
    txt_global_std_toptrips: 'Meilleurs voyages',
    txt_global_std_fax: 'Fax',
    txt_global_std_youhavedrivenintheriskzone: 'You have driven in the risk zone',
    txt_global_std_highusageparamamount: ' Consommation élevée {amount}.',
    txt_global_std_startyourcarandmakesureyourbluetoothison: 'Démarrez votre voiture et assurez-vous que votre Bluetooth est activé.',
    txt_global_std_selectaminimumoftwogroupsthatshouldcompeteagainsteachotherinthechallenge: "Sélectionnez un minimum de deux groupes qui doivent s'affronter dans le défi.",
    txt_global_std_examplevalue: 'Exemple de valeur',
    txt_global_std_Insurance_Text5: 'Icke registrerade körningar',
    txt_global_std_PolicySchedule1: 'PROGRAMME',
    txt_global_std_p2ppaywith: 'Payer avec',
    txt_global_std_german: 'Allemand',
    txt_global_std_presstoconnect: 'Appuyez sur pour vous connecter',
    txt_global_std_connectiontoyourcar: ' Connexion à votre voiture',
    txt_global_std_TermConfirmPlugin_Text3: 'Any eventual credit or claim will be settled within 30 days.',
    txt_global_std_buyfiasdc: 'Acheter FIA SDC',
    txt_global_std_copiedtoclipboard: 'Copié dans le presse-papier!',
    txt_global_std_clickheretoseewhy: 'Cliquez ici pour voir pourquoi',
    txt_global_std_therequestwasunacceptableoftenduetomissingarequiredparameter: "La demande était inacceptable, souvent en raison de l'absence d'un paramètre obligatoire.",
    txt_global_std_thedescriptioncantbelongerthan180characters: 'La description ne peut pas dépasser 180 caractères',
    txt_global_std_previewapp: "Aperçu de l'application",
    txt_global_std_usersuccessfullyadded2: 'Utilisateur ajouté avec succès',
    txt_global_std_InsuranceChangeModerna: 'There are some changes on your car insurance',
    txt_global_std_waitingtodownloadyourapp: 'En attente de téléchargement de votre application',
    txt_global_std_fiaquarterfinalparticipaterule2020: 'Pour participer à la finale de qualification, vous devez vous placer dans le top des 20 meilleurs conducteurs ou dans le top 3 des conducteurs des top 3 équipes.',
    txt_global_std_aphonenumbermayonlyincludenumerics: ' Un numéro de téléphone ne peut comprendre que des chiffres',
    txt_global_std_profileerror: 'Profile error',
    txt_global_std_coachandownermaynowseeyou: 'Coach and owner may now see you!',
    txt_global_std_challengesuccessfullyremoved: 'Défi supprimé avec succès',
    txt_global_std_topfeatures: 'Principales fonctionnalités',
    txt_global_std_p2pkintomonthlyfee: 'Frais mensuels Kinto',
    txt_global_std_pickyourcolortheme: 'Choisissez votre thème de couleur',
    txt_global_std_getinsurance: 'Get insurance',
    txt_global_std_calledsalvage: 'Called Salvage',
    txt_global_std_toreportitnow: 'de le signaler maintenant.',
    txt_global_std_ShareCar_Text1: 'Nu kan du dela bil med',
    txt_global_std_couldnotloadprofile: 'Could not load profile',
    txt_global_std_cimage9d: ' Utilisez le régulateur de vitesse exclusivement sur les routes plates, maintenez l’accélérateur dans les autres cas.',
    txt_global_std_downloadingdata: 'Téléchargement de données',
    txt_global_std_Process: 'Beskriv händelseförloppet',
    txt_global_std_confirm2: 'Confirmer',
    txt_global_std_Turn_Page: 'Turn page to see your specification >',
    txt_global_std_cimage19d: ' Maintenez l’accélérateur et NON PAS la vitesse !',
    txt_global_std_sendchallengeinvitation: 'Envoyer une invitation de défi',
    txt_global_std_validforxxminutes: '(Valable 6 minutes)',
    txt_global_std_TaxInvoice6: 'AVIS IMPORTANT:',
    txt_global_std_male: ' Masculin',
    txt_global_std_getanoverviewofyourcustomerportfolio: "Obtenez une vue d'ensemble de votre portefeuille clients",
    txt_global_std_exceededinsureddistance: 'You have now exceeded your annual mileage and will not receive full compensation for damage. Please contact us so we can adjust the mileage and make sure you are properly insured.',
    txt_global_std_howwilligetpaidforaclaim: 'Comment serai-je payé pour une réclamation?',
    txt_global_std_youhavenotbeendrivinginthisperiod: 'You have not been driving in this period',
    txt_global_std_pleasecontactsupportlinkalllowercaseifyouhavenotreceivedthereaderafter7businessdays: "Veuillez {contactSupportLinkAllLowerCase} si vous n'avez pas reçu le lecteur après 7 jours ouvrables.",
    txt_global_std_howwewantyoudata: 'Comment nous voulons vos données',
    txt_global_std_PaymentTrouble3_Text9: 'Sincerely,\r\n{appName}',
    txt_global_std_acceptedahead2headrequest: 'You accepted a challenge',
    txt_global_std_getinsured: 'Soyez assuré',
    txt_global_std_wantstobeyourfriend: 'Wants to be your friend!',
    txt_global_std_bonuscode: 'Bonus code',
    txt_global_std_viewwhoisparticipatinginthechallenge: 'Voir qui participe au défi',
    txt_global_std_smartestdriveroverfivedayswins: 'Smartest driver over five days wins.',
    txt_global_std_resendtemporarypassword: 'Renvoyer le mot de passe temporaire',
    txt_global_std_day: ' Jour',
    txt_global_std_sendalinksothatthepersondrivingyourcarcandownloadtheapp: "Envoyez un lien pour que la personne conduisant votre voiture puisse télécharger l'application.",
    txt_global_std_vehicle: 'Vehicle',
    txt_global_std_documentcar: 'Voiture de document',
    txt_global_std_competitionrulesapplied: 'Règles du concours appliquées',
    txt_global_std_renewinsurance: "Renouveler l'assurance",
    txt_global_std_incabtraining: 'Formation en cabine',
    txt_global_std_testingnewfronttranslation3: 'testnewfronttranslation3',
    txt_global_std_reporthistory: 'Historique du rapport',
    txt_global_std_yournicknameshouldcontainatleastthree2: 'Votre pseudo doit contenir au moins trois (3) caractères et aucun caractère spécial.',
    txt_global_std_seeperiod: 'Détails',
    txt_global_std_areyousureyouwanttodeletethisitem: 'Êtes-vous sûr de bien vouloir supprimer cet élément?',
    txt_global_std_NotCreatedAccount2: 'Reminder: You have not created an {appName}-account',
    txt_global_std_validto: ' Valide pour',
    txt_global_std_downloadpersonaldata: 'Téléchargement de données',
    txt_global_std_seedemo: 'Voir la démo',
    txt_global_std_MSIG_Onboarding6: 'Ce que vous devez faire maintenant, c&#39;est:',
    txt_global_std_worldchallenge2022: 'Défi mondial 2022',
    txt_global_std_sidekickprivacypolicy: 'politique de confidentialité',
    txt_global_std_fiaheatrule2020: "Vous devez conduire au moins 10 km lors d'une manche (semaine) pour obtenir un score dans le classement de la semaine.",
    txt_global_std_payforhowyoudrive: 'Pay for how you drive',
    txt_global_all_weekend: ' Week-end',
    txt_global_std_NotCreatedAccount5_Text2: "If you don't verify your insurance within 5 days your Enerfy - Insurance with Moderna will transfer to a",
    txt_global_std_Fia_Receipt_Text12: 'Si vous avez quelques questions, veuillez nous contacter au :',
    txt_global_std_p2pselectedowner: 'Propriétaire sélectionné',
    txt_global_std_MSIG_InsuranceLetter30: 'MS First Capital Insurance Limited',
    txt_global_std_automotivecompanydescription: 'Nouveaux modèles de propriété et engagement des chauffeurs.',
    txt_global_std_yourinsuranceexpires: "Votre assurance expire : {insuranceenddate} N'oubliez pas de prendre une nouvelle photo de votre odomètre avant l'expiration de votre assurance.",
    txt_global_std_invitetosidekicktext: 'Both you and your friend will get awarded with 500kr when your friend use your code at activation of a new Sidekcik car insurance.',
    txt_global_std_location2: 'Emplacement',
    txt_global_std_okigotit: 'Ok, I got it',
    txt_global_std_youneedtofillinallnecessaryfields: 'Vous devez remplir tous les champs nécessaires',
    txt_global_std_retry123: 'Retenter',
    txt_global_std_apicreator: "Créateur d'API",
    txt_global_std_invitedby: 'Invited by',
    txt_global_std_teamdetails: 'Team details',
    txt_global_std_resetyourpassword: 'Réinitialisez votre mot de passe',
    txt_global_std_hello: 'Bonjour',
    txt_global_std_nogoback: 'Non, page précédent!',
    txt_global_std_PaymentTrouble2: 'Still trouble with payment',
    txt_global_std_KristofferssonShortDescription: 'Champion du monde RX 2017 et 2018',
    txt_global_std_p2pdeny: 'Nier',
    txt_global_std_youhavereducedtheaveragecoemissionsofyourcarby: 'Vous avez réduit les émissions moyennes de CO₂ de votre voiture de {percent}',
    txt_global_std_p2pleftthegroup: 'a quitté le groupe',
    txt_global_std_notavailableindemo: 'Non disponible en démo',
    txt_global_std_publishunpublish: 'Publier / Dépublier',
    txt_global_std_wanttojoinanevent: ' Vous souhaitez rejoindre un évènement ?',
    txt_global_std_PSolbergWhyjoin: 'Coming soon...',
    txt_global_std_moveyourselfandotherstosecure: 'Move yourself and others to a secure location. In case of an injury, call: ',
    txt_global_std_PreSaleDetails_Text69: 'Your personal information is treated in accordance with the Data Protection Act. Personal information covered includes name, address, social security number, economic circumstances and health status. The data relates to you as a customer but may include ex co-insured. The information is collected from you as a customer, but can also be obtained from ex one of our partners or intermediaries. Personal data is processed for us to fulfill our obligations to you, the customer, such as the assessment of the application, investigation of insurance matters and the administration of your insurance contracts. Personal information may also be used for marketing and as a basis for risk assessment, analysis, business development and statistics. Data can for that purpose be disclosed to cooperation partners, intermediaries and other companies within the Group. The information may by law have to be disclosed to the authorities. The data is stored no longer than necessary. For complete information on the processing of personal data, see the insurance. By submitting your personal information, you consent to the processing of personal data. Moderna Försäkringar is the data controller. If you wish to obtain information on which personal data about you is being treated or request correction, you can send a signed request to Moderna Försäkringar, PUL Attorney, PO Box 7830, 103 98 Stockholm. You can also request that your information not be used for direct marketing.',
    txt_global_std_p2pnotavalidprice: 'Pas un prix valide',
    txt_global_std_cimage37h: ' DÉCOUVREZ LA DISTANCE QUE PARCOURT VOTRE VÉHICULE EN ROUE LIBRE À DIFFÉRENTES VITESSES.',
    txt_global_std_hereswhereyouhavethelargestimprovementpossibility: ' Voici comment obtenir des performances améliorées',
    txt_global_std_areyousureyouwanttodeletethechallengeoncedeletedyouwillnolongerbeabletoseethechallengeresultsparticipantswillstillbeabletoseetheirtripsinthefiasdcapp: "Voulez-vous vraiment supprimer le défi ? Une fois supprimé, vous ne pourrez plus voir les résultats du défi. Les participants pourront toujours voir leurs trajets dans l'application FIA SDC.",
    txt_global_std_year: ' Année',
    txt_global_std_phonenumbercannotincludecharacterslike6062s: 'Le numéro de téléphone ne peut pas inclure de caractères tels que " ?&/&#60; &#62;\\s# !%$%^*-',
    txt_global_std_whenyouhavecompletedyourfirstdrivewithenerfy: 'Lorsque vous aurez terminé votre premier trajet avec Enerfy, vous pourrez visualiser vos coûts et vos factures.',
    txt_global_std_onboardingtype: "Type d'intégration",
    txt_global_std_invitedyoutoa: 'Invited you to a',
    txt_global_std_enteravalidemailaddress: ' Saisissez une adresse e-mail valide.',
    txt_global_std_showstimesincethevehiclehasbeenparked: 'Indique le temps depuis que le véhicule a été garé.',
    txt_global_std_200kmlockedkey: 'Parcourez une distance de 200 km pour gagner ce badge.',
    txt_global_std_twofactorenabled1: 'Deux facteurs activés',
    txt_global_std_examplemessagesms: 'Bonjour! Ceci est un exemple de message texte.',
    txt_global_std_p2pspecifiedprice: 'Prix spécifié',
    txt_global_std_oilandoilfilter: 'Huile et filtre à huile',
    txt_global_std_maximizeheader: ' Optimiser',
    txt_global_std_daysdriven: 'Jours conduits',
    txt_global_std_p2pwithfamilyandfriends: 'en famille et entre amis',
    txt_global_std_p2pfaq9q: 'Et si mon ami rend la voiture plus tôt?',
    txt_global_std_joinclubsevent: "Rejoindre l'événement du Club",
    txt_global_std_thesamegeographicalroutewillbescoredamaximumof10timesinoneday: '• Un même parcours géographique sera noté 10 fois maximum dans une journée.',
    txt_global_std_livetopdrivers: 'Top pilotes en direct',
    txt_global_std_p2pcostsplitinvoice: 'Facture à frais partagés',
    txt_global_std_nopermissionstoviewotherteams: 'No permissions to view other teams.',
    txt_global_std_news: ' Activité',
    txt_global_std_authenticatedrequest: 'Demande authentifiée',
    txt_global_std_enterpasswordtoremove: 'Entrez le mot de passe pour supprimer',
    txt_global_std_loadingapps: 'Chargement des applications...',
    txt_global_std_SendMyPosition_Hello: ' Bonjour',
    txt_global_std_basecolor: 'Couleur de base',
    txt_global_std_enddateisrequired: 'La date de fin est requise',
    txt_global_std_sendalinksothatthepersondrivingyourcarcandownloadtheappnameapp: "Envoyez un lien pour que la personne qui conduit votre voiture puisse télécharger l'application {appname}.",
    txt_global_std_nowthatyouhavedrivenacoupleoftimes: 'Now that you have driven a couple of times you may have noticed that your premium rate can shift. Do you want to know our tricks on how to reach a lower, cheaper premium rate?',
    txt_global_std_reportaccidentbottomtext: 'Veuillez télécharger des photos de votre voiture dans les 24 heures suivant votre rapport. Vous pouvez cliquer sur votre ancien rapport ci-dessus pour y ajouter des photos.',
    txt_global_std_therearenoongoinggamesrightnow: 'Il ny a pas de jeux en cours pour le moment.',
    txt_global_std_loginsignup: ' Se connecter/S’inscrire',
    txt_global_std_alertweredrivingyoudrivingbody: "N'oubliez pas de démarrer et d'arrêter vos trajets dans l'application pour obtenir votre score de conduite.",
    txt_global_std_connectyourgarminwatch: 'Connectez votre montre Garmin',
    txt_global_std_pickendtime: "Choisissez l'heure de fin",
    txt_global_std_NewPW_Text3: ' est à présent :',
    txt_global_std_BeforeFirstBill_Text2: 'What am I going to pay?',
    txt_global_std_wildfirelockedkey: 'Conduisez avec un niveau moyen de 4,4 ou mieux pendant une semaine pour débloquer ce succès et gagner 5000 points à votre portefeuille Kinto.',
    txt_global_std_p2ptoconfirmprice: 'pour confirmer le prix spécifié',
    txt_global_std_selectgroupsintheformandclickonthegeneratebuttonaccessthecode: 'Sélectionnez les groupes dans le formulaire et cliquez sur le bouton générer pour accéder au code',
    txt_global_std_or: ' ou',
    txt_global_std_4thdate: '4ème',
    txt_global_std_setupmanualstartstop: 'Configuration : démarrage et arrêt manuels',
    txt_global_std_TerminationConfirm_Text1: 'We hereby confirm that your car insurance for',
    txt_global_std_yourdrivingstatsforthistripcomparedtoyour30daysaverage: 'Vos statistiques de conduite pour ce trajet par rapport à votre moyenne sur 30 jours.',
    txt_global_std_wrongformat: 'Mauvais format!',
    txt_global_std_driventimeperperiod: 'Temps de conduite par période',
    txt_global_std_speed: ' Vitesse',
    txt_global_std_VerStep_Text1: 'Du har hittills gjort det viktigaste: bestämt vilken typ av bilförsäkring du vill ha och valt när den ska börja gälla.',
    txt_global_std_events_overview_total: 'Total',
    txt_global_std_BasicInformation: 'Grunduppgifter',
    txt_global_std_MSIG_InsuranceLetter41: 'Loi de 1987 sur les transports routiers (Malaisie)',
    txt_global_std_appcreator: "Créateur d'applications",
    txt_global_std_earnpercentpremiumreductionperperiod: 'Bénéficiez de {percent} de réduction de prime par période',
    txt_global_std_activationcodevalidated: "Code d'activation validé",
    txt_global_std_countday: '{compte} jour',
    txt_global_std_selectfriend: ' Choisir un ami',
    txt_global_std_july: ' Juillet',
    txt_global_sidekick_invitehowitworkstext: 'When your friend signs up with your purchase code, you and your friend will receive 250 kr in your piggy bank (apply if you have been at {company} for at least one month). The amount is transferred to the piggy bank when the acquisition code has been registered. You have a complete overview of your listings on the savings page.',
    txt_global_std_ConfirmFia_Text7: ' N’oubliez pas d’arriver sur place 10 minutes avant la tranche horaire choisie.',
    txt_global_std_KristofferssonChamp: 'Champion du WRX en 2017 et 2018',
    txt_global_std_wewillnowbuildyourdemoapp: 'Nous allons maintenant créer votre application de démonstration.',
    txt_global_std_Insurance_Contact: 'Contact',
    txt_global_std_youdroveextremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedthisbadge: 'Vous avez extrêmement bien conduit! Votre {scoretype} moyen était meilleur que {score} pendant une semaine! Vous avez gagné ce badge!',
    txt_global_std_congrats: 'Toutes nos félicitations!',
    txt_global_std_p2ppushtitlehasnowfinalizedthebooking: 'Réservation finalisée',
    txt_global_std_buttontype: 'Activity',
    txt_global_std_noinvoicesyet: 'Pas encore de factures',
    txt_global_std_consentInfoNo: 'Vous n&#39;avez pas activé votre &quot;consentement&quot; dans les paramètres. Lorsque le &quot;consentement&quot; n&#39;est pas activé, nous ne pourrons pas partager en permanence toutes les compétitions intéressantes et les conseils fournis dans l&#39;application Tryg Drive.',
    txt_global_std_friends: ' Amis',
    txt_global_std_SingelCollision: 'Singelolycka',
    txt_global_std_popupcreated: 'Popup created',
    txt_global_std_mileagerequired: 'You must fill in the mileage',
    txt_global_std_forexampledistancetimeetc: 'For example distance, time, costs, position on map & moch more.',
    txt_global_std_searchbynamegrouporscore: 'Recherche par nom, groupe ou score',
    txt_global_std_balancing: 'Équilibrage',
    txt_global_std_ChicheritInterests: 'Coming soon...',
    txt_global_std_ActivationLetter_Text8: 'Type in your verification code:',
    txt_global_std_ayourpolicybeginsonthedateandtimewhenyouelectronicallysigntheapplicationandmakethedownpaymentonyourpolicy: "R : Votre police prend effet à la date et à l'heure où vous signez électroniquement la proposition et versez la mise de fonds sur votre police.",
    txt_global_std_yearlydevelopment: 'Yearly development',
    txt_global_std_manageagreementshere: 'Gérer les accords ici',
    txt_global_std_countinvitessent: '{count} invites sent!',
    txt_global_std_inmostcarstheobdsocket: 'Dans la plupart des voitures, la prise OBD se trouve à gauche sous le volant. Si vous ne trouvez toujours pas la prise OBD, cliquez ici.',
    txt_global_std_p2pgiveadiscountiffriend: 'Donnez une réduction si votre ami conduit votre voiture de manière sécuritaire.',
    txt_global_std_improvementspeedarea: ' Perfectionnement du régime',
    txt_global_std_otherreasonsthatyourcarisnotshowingupinthelist: "Autres raisons pour lesquelles votre voiture n'apparaît pas dans la liste :",
    txt_global_std_success: 'Succès!',
    txt_global_std_phonenumberforinsurance: 'Entrez le numéro de téléphone utilisé lors de votre souscription à votre assurance.',
    'txt_global_std_Al-KhelaifiWhyjoin': 'Because I enjoy different types of challenges and I am a competitive driver.',
    txt_global_std_ifyoureferafriendyoubothgetnok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsuranceamountinmarkedfontshowsvalidamountsaddedtothepiggybankpaymentinordertoreceiveadrivingdividend75ofthedistancetraveledmustbecompletedwiththeappandchiptenfoldremembertohaveboththeappandbluetoothonwhenyoudriveandthetripwillberegistered: "Si vous parrainez un ami, vous obtenez tous les deux 250 NOK dans la tirelire. Cela nécessite que vous soyez tous les deux clients de Tryg pendant au moins un mois après la date de début de l'assurance. Montant dans la police marquée, montre les montants valides ajoutés à la tirelire. Paiement\n" +
      "Afin de recevoir un dividende de conduite, 75 % de la distance parcourue doit être parcourue avec l'application et la puce dix fois. N'oubliez pas d'avoir à la fois l'application et le Bluetooth activés lorsque vous conduisez, et le trajet sera enregistré.",
    txt_global_std_yourcar: 'Your car',
    txt_global_std_startdrivingsaferwithmefollowthelinkbelowandget10kmdistancetodrivewithenerfy: 'Commencez à conduire en toute sécurité avec moi! Suivez le lien ci-dessous et obtenez 10 km de distance avec Enerfy.',
    txt_global_std_lookslikeyouhavenotjoinedanyteamyet: 'Ooops. Looks like you have not joined or created any teams yet.',
    txt_global_std_parkingaccident2: 'Accident de stationnement',
    txt_global_std_achievementstitle: 'Réalisations',
    txt_global_std_challengesomeoneelse: 'Challenge someone else',
    txt_global_std_maintainingintendedspeedisutmostimportantforfueleconomymindwanderingandlackoffocusoftencausesspeedvariationupanddowncausingunnecessaryaccelerationshenceincreasesfuelconsumptionnew: "Le maintien de la vitesse prévue est extrêmement important pour l'économie de carburant. L'errance de l'esprit et le manque de concentration provoquent souvent des variations de vitesse de haut en bas, provoquant des accélérations inutiles, augmentant ainsi la consommation de carburant.",
    txt_global_std_inthenextpopupbr1makesurebprecisebissettobonbbr2chooseballowonceb: 'Dans la fenêtre contextuelle suivante: <br> 1. Assurez-vous que <b> Précis </b> est défini sur: <b> Activé. </b> <br> 2. Choisissez <b> Autoriser une fois </b>',
    txt_global_std_p2pgoheretoinvitemembersandstartlendingyourcar: 'Allez ici pour inviter des membres et commencer à prêter votre voiture.',
    txt_global_std_ShareCar_Text8: 'Ladda ner appen här',
    txt_global_std_p2piamhavingproblemswiththelinkloggingin: "J'ai des problèmes avec le lien / la connexion.",
    txt_global_std_allowpushnotifications: 'Autoriser les notifications push',
    txt_global_std_currency: ' kr',
    txt_global_std_subscribe: ' Souscrire',
    txt_global_std_MikkelsenFacts: 'Andreas a une vaste expérience dans le monde du sport. Il a concouru dans le ski alpin et a été membre de l’équipe national des jeunes du ski. En 2003 et 2004 il a participé à la compétition du motocross, en représentant aussi l’équipe national des jeunes. Il a terminé troisième dans le WRC en 2014, 2015, 2016, en encaissant trois victoires et en prenant place vingt fois sur le podium.',
    txt_global_std_expireson: 'Expire le',
    txt_global_std_sentlogfiles: 'Fichiers journaux envoyés',
    txt_global_std_athelowerthescorethemoresavingsyoucanearnyouwillseeyourpotentialsavingswitheachtrip: 'R : Plus le score est bas, plus vous pouvez économiser. Vous verrez vos économies potentielles à chaque voyage.',
    'txt_global_std_Enerfy setup': '{appName} setup',
    txt_global_std_totalscore: 'Total score',
    txt_global_std_encouragetoavoiddistractions: 'Encouragez le conducteur à éviter les distractions',
    txt_global_std_nationalitycolon: 'Nationalité:',
    txt_global_std_pleaseselectvehiclesregistrationnumberyouaredriving: 'Veuillez sélectionner le numéro d&#39;immatriculation du véhicule que vous conduisez.',
    'txt_global_std_fuel0km/h': 'Fuel 0km/h',
    txt_global_std_latesttrips: 'Derniers trajets',
    txt_global_std_avgscore123: 'Score moy',
    txt_global_std_other: ' Autre',
    txt_global_std_singleaccident: 'Single Accident',
    txt_global_std_twofactorauthenticationcode: "Code d'authentification à deux facteurs",
    txt_global_std_writeamessagetoafriendoragroup: 'Écrivez un message à un ami ou à un groupe',
    txt_global_std_resendactivationcode: "Renvoyer le code d'activation",
    txt_global_std_personalizeyouraccount: 'Personnalisez votre compte',
    txt_global_std_achievementtool_upsell_pitch: 'Créez vos propres badges et réalisations inspirants pour;',
    txt_global_std_Adjust_Text4: 'Bruker du ikke hele kjørelengden vil prisen for ubrukt kjørelengde bli betalt ut med sparegrisen ved fornyelse.',
    txt_global_std_addemail2: 'ajouter un e-mail',
    txt_global_std_Compensation: 'Ersättning',
    txt_global_std_addexistinguser: ' Ajouter un utilisateur existant',
    txt_global_std_miles: 'Miles',
    txt_global_std_drivewithanaveragescoretypeofscoreorbetterforaweektounlockthisbadge: 'Conduisez avec un {scoretype} moyen de {score} ou mieux pendant une semaine pour déverrouiller ce badge.',
    txt_global_std_p2pfaq6a: '1.Ouvrez le chat de groupe que vous souhaitez quitter.\n' +
      "2.Appuyez sur l'icône des paramètres en haut à droite dans l'en-tête de la discussion de groupe.\n" +
      '3.Choisissez quitter ce chat de groupe.',
    txt_global_std_writeyourregistrationnumber: 'Ecrivez votre numéro d&#39;enregistrement',
    txt_global_std_p2pcarrentoverview: 'Aperçu de la location de voiture',
    txt_global_std_emergency: 'Emergency',
    txt_global_std_cimage35h: ' TROUVEZ LE RAPPORT IDÉAL POUR DÉMARRER',
    txt_global_std_Insurance_AmountCharged: 'Charged amount',
    txt_global_std_Fia_Receipt_Text4: 'Vous pouvez l’introduire manuellement quand vous créez votre compte.',
    txt_global_std_discovermore: 'Découvrir plus',
    txt_global_std_cwelcome6: 'Vrooom vrom!',
    txt_global_std_BeforeFirstBill_Text5: 'You can always keep track of your costs in the {appName} app, there you see the cost for every single drive and the overall amount for the month. Go to "Costs" in the app to see your costs.',
    txt_global_std_coolcatsunisshining: 'le soleil du chat frais brille',
    txt_global_std_riskydecisionwithintrips: 'Décision risquée dans les voyages',
    txt_global_std_apireference: 'Référence API',
    txt_global_std_defaulthere: 'ici',
    txt_global_std_above1000locked: 'Conduisez avec un score supérieur à 1000 pour débloquer ce succès.',
    txt_global_std_Kilometer: 'kilometer',
    txt_global_std_allownotifications: 'Autoriser les notifications',
    txt_global_std_sidekickhowinviteworks: 'When your friend signs up with your purchase code, you and your friend will receive 250 kr in your piggy bank (apply if you have been at Tryg for at least one month). The amount is transferred to the piggy bank when the acquisition code has been registered. You have a complete overview of your listings on the savings page.',
    txt_global_std_previousbonusperiod: 'Période de bonus précédente',
    txt_global_std_unabletoaccesslocationservices: 'Unable to access location services.',
    txt_global_std_challengeaverage: 'Moyenne du défi',
    txt_global_std_uploadatleastfourphotosofyourcarfrontbackleftandrightsides: 'Téléchargez au moins 4 photos de votre voiture. Avant, arrière, côtés gauche et droit.',
    txt_global_std_teamleaderslatestscore: 'Dernier score des Teamleaders:',
    txt_global_std_p2ppleaseselectavehicle: 'Veuillez sélectionner un véhicule',
    txt_global_std_wallet: 'Portefeuille',
    txt_global_std_earnedpoints: 'Points gagnés',
    txt_global_std_cohabiting: 'Cohabitation',
    txt_global_std_radiatorflush: 'Radiateur affleurant',
    txt_global_std_BloodSample: 'Har blod/utandningsprov tagits',
    txt_global_std_single: 'Célibataire',
    txt_global_std_p2pincompletebooking: 'Réservation incomplète',
    txt_global_std_electric: 'Électrique',
    txt_global_std_notallowedtoorderanobdreader: 'Pas autorisé à commander un lecteur OBD',
    txt_global_std_finishadriveatscoretypescoreorbettertounlockthisachievementandearnpointspoints: 'Terminez une course à {score} ou mieux pour déverrouiller cette réussite et gagner {points} points.',
    txt_global_std_tinaQuote: 'Power from within & the Power of choice',
    txt_global_std_threecharactersbetweenazand09: 'trois caractères entre AZ et 0-9',
    txt_global_std_saveenergyandco2: "Économisez de l'énergie et du CO₂",
    txt_global_std_trainingdate: 'Date de la formation',
    txt_global_edj_fueltype: 'Fuel type',
    txt_global_std_successsenddetails: 'Détails envoyés avec succès',
    txt_global_std_NotCreatedAccount1_Text2: 'Download the {appName} app in your smartphone',
    txt_global_std_sendinvitetofriend: 'Envoyer une invitation à un ami',
    txt_global_std_pigsinvitedlocked: 'Vous obtenez ceci lorsque vous avez recruté un ami pour Sidekick',
    txt_global_std_nodatafoundstartdrivingtoseethisstatistic: 'Aucune donnée disponible. Commencez à conduire pour voir cette statistique !',
    txt_global_std_okdriving: 'Ok conduire!',
    txt_global_std_Fia_Receipt_Text5: '',
    txt_global_std_poweredby: 'Alimenté par',
    txt_global_std_BikerName: 'Namn på cyklist/gångtrafikant',
    txt_global_std_demotext6: 'Je vous aide à conduire en toute sécurité! Avec des conseils, je vais vous aider sur la route pour obtenir cinq étoiles.',
    txt_global_std_lent: 'Lent',
    txt_global_std_addall: ' Ajouter tous les éléments',
    txt_global_std_insuranceoverview: 'Insurance overview',
    txt_global_std_yearmodel: 'Year model',
    txt_global_std_HansenInterests: 'Entrainement, sport d’automobile, barista',
    txt_global_std_useralreadyexists: 'Impossible de créer le compte, l&#39;e-mail existe déjà!',
    txt_global_std_rwmnodrivestitle: "Vous n'avez pas encore de voyages.",
    txt_global_std_editchallenge: 'Modifier le défi',
    txt_global_std_monthlycost: 'Coût mensuel',
    'txt_global_std_Pinpointing your position...': 'Pinpointing your position...',
    txt_global_std_goodplaning: 'Good planing',
    txt_global_std_welcometoyournewapp: 'Bienvenue dans votre nouvelle application.',
    txt_global_std_weknowthisiscrazy: "Nous savons que c'est fou ...",
    txt_global_std_ayoucanfileaclaimbycontactingphonenoorclickingonfileaclaiminyouragentapp: 'R : Vous pouvez déposer une réclamation en contactant {phoneNo} ou en cliquant sur Déposer une réclamation dans votre application {agent}.',
    txt_global_std_damageguide: 'Guide des dommages',
    txt_global_std_cimage36h: ' ÉVITEZ D’ARRÊTER COMPLÈTEMENT LE VÉHICULE AU NIVEAU DES OBSTACLES',
    txt_global_std_speedgappoints: "Points d'écart de vitesse",
    txt_global_std_averageforstretch: 'Average for stretch',
    txt_global_std_Footer_Moderna_2: 'Enerfy är en produkt från Greater Than AB. | Karlavägen 58 | 114 49 Stockholm | 08-555 932 00 | info@enerfy.se | Org.nr 556965-2885 | www.enerfy.se | ',
    txt_global_std_howdoisetupautomaticrenewal: 'Comment configurer le renouvellement automatique ?',
    txt_global_std_availablepoints: 'Points disponibles',
    txt_global_std_locationservicealways: 'Service de localisation (toujours)',
    'txt_global_std_You will see possible filters in the tree menu to the left. Ex. When a report is clicked and both vehicles and drivers show a checkbox then report will be filtered on drivers/vehicles that are checked.': 'You will see possible filters in the tree menu to the left. Ex. When a report is clicked and both vehicles and drivers show a checkbox then report will be filtered on drivers/vehicles that are checked.',
    txt_global_std_signupwith: ' S’inscrire avec {name}',
    txt_global_std_NotCreatedAccount5_Text2Moderna: "If you don't verify your insurance within 5 days your Moderna Smart Flex will transfer to a",
    txt_global_std_enteravalidplatenumber: 'Entrez un numéro de plaque valide.',
    txt_global_std_p2pusershareofcost: '{name} part des frais',
    txt_global_std_finalresult: ' Résultat final',
    txt_global_std_makeyoufirstdrivewithenerfyareyou: "Make your first trip with {appName} Are you in your car? Let's start.",
    txt_global_std_focus: 'Concentrer',
    txt_global_std_yourbluetoothisntturnedon: "Votre Bluetooth n'est pas activé",
    txt_global_std_nosplitsavailableforthisrun: ' Aucune portion disponible pour ce trajet',
    txt_global_std_centralzone: 'Zone centrale',
    txt_global_std_PreSaleDetails_Text67: "The Insurer is Tryg Forsikring A / S Denmark, postadress: Klausdalsbrovej 601, DK-2750 Ballerup, Denmark, CVR.nr. 24260666, website: www.tryg.dk. Moderna Försäkringar is under the Danish Financial Supervisory Authority's supervision.",
    txt_global_std_technicalservice: ' Service technique',
    txt_global_std_cimage9h: ' MAINTENEZ L’ACCÉLÉRATEUR PLUTÔT QUE LA VITESSE',
    txt_global_std_okdriving2: 'Ok conduire',
    txt_global_std_coverage: ' Couverture',
    txt_global_std_selectaccount: 'Sélectionnez un compte',
    txt_global_std_ifyouhavebluetoothyoucanturnonautomatic: "Si vous avez Bluetooth dans votre voiture, vous pouvez activer le démarrage et l'arrêt automatiques.",
    txt_global_std_automessage_level_1__3: '#Superhelt',
    txt_global_std_p2pgroupnamechanged: 'Le nom du groupe a changé',
    txt_global_std_yougotthehighestsportscoreever: 'You got the highest Sport score ever',
    txt_global_std_helptextsavingsstartbonustext: 'Du starter alltid forsikringsåret med 1000 kr i sparegrisen. Disse får du utbetalt hvis du har vært en trygg sjåfør gjennom forsikringsåret. Velger du å avslutte forsikringen før året er omme, blir det gjort en beregning på bakgrunn av perioden du har hatt forsikringen.',
    'txt_global_std_Pair connect': 'Pair connect',
    txt_global_std_address: ' Adresse',
    txt_global_std_discoverourheats: 'Calendrier des manches',
    txt_global_std_distancekm: 'Distance/km',
    txt_global_std_BillMail: 'Invoice from {appName}',
    txt_global_std_sendmessage: 'ENVOYER LE MESSAGE',
    txt_global_std_p2paddedby: 'Ajouté par',
    txt_global_std_PreSaleDetails_Text43: 'Theft',
    txt_global_std_createnewmessage: 'Create new message',
    txt_global_std_turnonhazardlights: 'Turn on hazard lights',
    txt_global_std_soon: 'bientôt.',
    txt_global_std_alluserscompeteindividually: 'Tous, les utilisateurs concourent individuellement',
    txt_global_std_ecofriendweeklyunlockedkey: "Vous avez économisé plus de {co2value} % de CO₂ cette semaine en conduisant de manière écologique. Merci de vous soucier de l'environnement!",
    txt_global_std_thepasswordminimumlengthis8charactersandmustinclude1numeralandspecialcharacter: 'La longueur minimale du mot de passe est de 8 caractères et doit inclure 1 chiffre et un caractère spécial',
    txt_global_std_accidenttype: 'Accident type',
    txt_global_std_teamsthismonth: 'Teams this month',
    txt_global_std_lastname: ' Nom de famille',
    txt_global_std_translationtool_upsell_pitch: 'Gérez sans effort vos propres traductions et mises à jour linguistiques vers;',
    txt_global_std_wewillsendtheobdreadertotheaddressyouhaveregisteredwithusneedtochangeanythingcontactsupport: "Nous enverrons le lecteur OBD à l'adresse que vous avez enregistrée chez nous. Besoin de changer quoi que ce soit ? Contactez le support.",
    txt_global_std_loyaltypages: 'Pages de fidélité',
    txt_global_std_managepermissions: 'Gérer les autorisations',
    txt_global_std_addressline1: 'Adresse 1',
    txt_global_std_youcanfindtheoutlethere: 'Vous pouvez trouver la sortie ici',
    txt_global_std_whatwillhappeniftheobddeviceisactive: 'What will happen if the OBD device is active but I forget to start the app?',
    'txt_global_std_Al-KhelaifiChamp': 'Deux victoires au championnat du Qatar Touring Cars. Deux fois champion du Qatar National Sprint. Gagnant du Spa 12H endurance race CUP1 race en 2017.',
    txt_global_std_sharemycar: 'Partager ma voiture',
    txt_global_std_hereyoucanuploadanyextrainformationregardingyourclaim: 'Ici, vous pouvez télécharger des informations supplémentaires concernant votre réclamation. Tous les champs sont facultatifs.',
    txt_global_std_testglobal: 'test global',
    txt_global_std_PreSaleDetails_Text31: 'Replacement costs, for example, wheel change for puncture and rental cars for a maximum of three days for breakdowns and theft where the repair lasts longer than an hour.',
    txt_global_std_Insurance_Zipcode: 'Zipcode',
    txt_global_std_chassisenginenumber: 'Numéro de châssis / moteur',
    txt_global_std_cwelcome12: 'Have a nice day',
    txt_global_std_BeforeBill_Text5: 'Tänk på mjuka accelerationer.',
    txt_global_std_youarenowloggedin: 'Vous êtes maintenant connecté(e)!',
    txt_global_std_engagingteammembersinasharedmissionisgoodforproductivity: "Engager les membres de l'équipe dans une mission partagée est bon pour la productivité",
    txt_global_std_letmein: ' Faites-moi entrer !',
    txt_global_std_badformattingwithkey: 'Mauvais formatage avec clé',
    txt_global_std_drives2: 'Disques',
    txt_global_std_good: ' Bien',
    txt_global_std_questionsandanswers: ' Questions et réponses',
    txt_global_std_uploadfromlist: 'Télécharger à partir de la liste',
    txt_global_std_somethingtherenewfake: 'Quelque chose de nouveau faux',
    txt_global_std_drivemorethan1kilometertogetmoredetailedanalyticsaboutyourtrip: "Conduisez plus d'un kilomètre pour obtenir des analyses plus détaillées sur votre voyage.",
    txt_global_std_enteryouractivationcodeyoureceived: 'Entrez le code d&#39;activation que vous avez reçu dans votre lettre d&#39;activation ou votre courrier électronique lors de votre inscription.',
    txt_global_std_cimage16d: ' Planifiez chaque augmentation de votre vitesse pour maintenir la vitesse maximale souhaitée ou pour rester en roue libre sur la plus longue distance possible.',
    txt_global_std_VerStep_Hello: 'Vad roligt att du vill teckna vår bilförsäkring',
    txt_global_std_PreSaleDetails_Text34: 'All risk',
    txt_global_std_yourtripwasnotregisteredifyouhadyourpluginconnected: 'Votre voyage n&#39;a pas été enregistré. Si votre plug-in était connecté alors que vous conduisiez la voiture mais que l&#39;application, les services Bluetooth ou GPS / localisation n&#39;étaient pas activés pour enregistrer les données de conduite, les trajets effectués sont ensuite facturés aux niveaux premium 10 à 15.',
    txt_global_std_Gdpr_Text1: 'Bienvenue!',
    txt_global_std_thisteamisclosed: 'This team is closed',
    txt_global_std_byjoiningthechallengeyouacceptthe: 'En rejoignant le défi, vous acceptez le',
    txt_global_std_InsuranceMail: 'Insuranceletter',
    txt_global_std_yourprofilephotowassaved: 'Votre photo de profil a été enregistrée',
    txt_global_std_imagepickerunlocked: "Choisissez l'icône du badge déverrouillé",
    txt_global_std_ecofriend: 'Ami écolo',
    txt_global_std_p2punavaliblebetween: 'Indisponible entre',
    txt_global_std_youaregoodtogostartdriving: 'Vous êtes prêt à partir, commencez à conduire!',
    txt_global_std_BillTimePremium: 'Invoice from {appName}',
    txt_global_std_confirmtermination: 'Confirm termination',
    txt_global_std_thiswindowwillcloseinclosesecondsseconds: ' Cette fenêtre se fermera dans {closeSeconds} secondes',
    txt_global_std_failedtopublishreviews: 'Échec de la publication des avis !',
    txt_global_std_reportYes: 'Yes',
    txt_global_std_FiaPassword_Text3: 'est',
    txt_global_std_selectyourcountry: 'Sélectionnez votre pays',
    txt_global_std_writecurrentpassword: 'Écrire le mot de passe actuel',
    txt_global_std_compare: ' Comparer',
    txt_global_std_zoomtofit: "Zoom pour s'adapter",
    'txt_global_std_doyoulikerewards?': 'Do you like rewards?',
    txt_global_std_Footer_1: 'Enerfy is a product from Greater Than AB. Greater Than AB is an intermediary of the insurance.\r\n' +
      'Karlavägen 58 | 114 49 STOCKHOLM | 08-555 932 00 | info@enerfy.se | Org.nr 556965-2885 | www.enerfy.se',
    txt_global_std_head2head2: 'Défie tes amis',
    txt_global_std_p2pyouwillnotbeabletosendorreceivebookingsfromthisfriendanymore: 'Vous ne pourrez plus envoyer ni recevoir de réservations de cet ami.',
    txt_global_std_addbackground: ' Ajouter un arrière-plan',
    txt_global_std_PreSaleDetails_Text56: 'Supplementary insurance can be underwritten in addition to or included as enhanced basic coverage in your insurance package, depending on the extent of your insurance. Below is an overview of the additional insurance that you can subscribe to your vehicle that is semi- or fully insured.',
    txt_global_std_KristofferssonInterests: 'Ma famille et le fitness',
    txt_global_std_passwordforname: 'Mot de passe pour {nom}',
    txt_global_std_collectedpoints: 'POINTS COLLECTÉS',
    txt_global_std_showdataby: 'Afficher les données par',
    txt_global_std_elite: ' Élite',
    txt_global_std_format: 'Format',
    txt_global_std_yourinsurancehasexpired: 'Votre assurance a expiré.',
    txt_global_std_MarklundMusic: 'I like to listen to interesting documentaries on the radio when driving, however, when I need to concentrate, I prefer to have it totally silent.',
    txt_global_std_congratulationsyouhavesuccessfullyconnectedtoyourcar: 'Toutes nos félicitations! Vous vous êtes connecté avec succès à votre voiture.',
    txt_global_std_p2pcomingsoon: 'Bientôt disponible',
    txt_global_std_ofcourseyoumayaddphotoslater: ' Bien évidemment, vous pourrez toujours ajouter des photos ultérieurement',
    txt_global_std_pleaseselectaprojectfrommyprojects: ' Veuillez sélectionner un projet dans Mes projets.',
    txt_global_std_pleasecheckyouremailtoverify: 'Veuillez vérifier votre e-mail pour vérifier',
    txt_global_std_connectyourself: 'Connectez-vous!',
    txt_global_std_startdate: ' Date de début',
    txt_global_std_youcannoteditthistranslationfromvisualeditorduetotechnicalreasonspleasecontactusourdeveloperswillupdateitforyou: "Vous ne pouvez pas modifier cette traduction à partir de l'éditeur visuel pour des raisons techniques. Veuillez nous contacter, nos développeurs le mettront à jour pour vous.",
    txt_global_std_redeem: 'Racheter',
    txt_global_std_warikan_onboarding8: '4. Invitez les membres à partager la voiture pour créer un groupe de partage',
    txt_global_edj_odometer: 'Mileage (km)',
    txt_global_std_loyaltyemailheadertext: 'et soyez récompensé!',
    txt_global_std_youjustadvancedtothenextlevelintheleague: 'You just advanced to the next level in the league.',
    txt_global_std_p2pemailchangedbookings: 'Réservations modifiées',
    txt_global_std_enterpasswordtopublishchanges: 'Entrez le mot de passe pour publier les modifications',
    'txt_global_std_Using GPS through location services grants you access to great functionality like replaying your runs on a map, track your runs in background mode etc. During the next step you will be asked to grant Enerfy access to your location data. You can always choose to turn if on/off afterwards in your phone settings.': 'Using GPS through location services grants you access to great functionality like replaying your runs on a map, track your runs in background mode etc. During the next step you will be asked to grant {appName} access to your location data. You can always choose to turn if on/off afterwards in your phone settings.',
    txt_global_std_DescribeInjury: 'Beskriv skadan',
    txt_global_std_badgeisworthxpoints: 'Le badge vaut {points} points',
    txt_global_std_welcometoname: 'Bienvenue sur {appName}',
    txt_global_std_bleeding: 'Bleeding',
    txt_global_std_supportedfiles: 'Format de fichier pris en charge : png, svg. Max. taille du fichier : X Mo.',
    txt_global_std_p2pwearehappythatyouarehere: 'Nous sommes heureux que vous soyez ici!',
    txt_global_std_updateyoursettings: 'Update you settings',
    txt_global_std_NotCreatedAccount5_Text1: "You still haven't verified your insurance. We just want to make sure that everything works fine for you, and that you understand how you'll get started with your car insurance. ",
    txt_global_std_categorytypeheader: 'Taper',
    txt_global_std_earn3500pointsifyoubook15timesamonth: 'Gagnez 3500 points en réservant 15 fois par mois',
    txt_global_std_paynow: 'Payez maintenant',
    txt_global_std_showthisreceiptatcheckout: 'Montrez ce reçu à la caisse.',
    txt_global_std_jointco2savings: 'Économies de CO₂\n',
    txt_global_std_p2pinvoices: 'Factures',
    txt_global_edj_privatedistance: 'Private (km)',
    txt_global_std_NotActivatedAccount_Hello: 'Hello',
    txt_global_std_sparklockedkey: 'Conduisez avec un niveau moyen de 4 ou mieux pendant une semaine pour débloquer ce succès et gagner 1000 points à votre portefeuille Kinto.',
    txt_global_std_thefiasmartdrivingchallengeisaworldchallenge: "Le FIA Smart Driving Challenge est un défi mondial où l'intelligence prévaut sur la vitesse. Laissez-vous inspirer par votre chef d’équipe et faites de votre conduite quotidienne votre meilleure alliée pour décrocher la couronne.",
    txt_global_std_totalprice: 'Total price',
    'txt_global_std_challenge{friend}': 'Challenge {friend}',
    txt_global_std_renewyourinsuranceformonthofmonthatalowercost: 'Renouvelez votre assurance pour le mois de {month} à moindre coût',
    txt_global_std_clubs: 'Clubs',
    txt_global_std_nowyouhavedrivenwithenerfy: 'Now you have driven with {appName} for the first time! Your premium rate may change from drive to drive.',
    txt_global_std_p2penterthetemporarypasswordsenttoyouremail: 'Entrez le mot de passe temporaire envoyé à votre email',
    txt_global_std_counthours: ' {count} heures',
    txt_global_std_zipcodeglobal: 'Code postal',
    txt_global_std_p2pconfirmed: 'Confirmé',
    txt_global_std_premiuminsurancecode: "Code d'assurance des primes",
    txt_global_std_adduserstogroup: 'Ajouter des utilisateurs au groupe',
    txt_global_std_p2pequallydivided: 'À parts égales',
    txt_global_std_afteryoustartdrivingyourinvoiceswillshowuphere: "Une fois que vous avez commencé à conduire, vos factures s'afficheront ici.",
    txt_global_std_collisionimpact: 'Collision Impact',
    txt_global_std_usagemonth: 'Monthly usage',
    txt_global_std_saloon: 'Salon',
    txt_global_std_takesomeofmypersonaltips: 'Have some of my personal advice',
    txt_global_std_awesomestreak: 'Super séquence!',
    txt_global_std_currentstatus: 'Statut actuel:',
    txt_global_std_emailisalreadyaddedtobeinvited: "L'e-mail est déjà ajouté pour être invité",
    txt_global_std_hereyoucandocumentyourcar: 'Ici, vous pouvez documenter votre voiture.',
    txt_global_std_include: ' Classer',
    txt_global_std_InsuranceChange_Hello: 'Hej',
    txt_global_std_foryourcompensation: 'For your compensation',
    txt_global_std_web: ' Web',
    txt_global_std_p2pgoback: 'retour',
    txt_global_std_carrescue: 'Car rescue',
    txt_global_std_p2pyoudonthaveanyfriendsyet: "Vous n'avez pas encore d'amis.",
    txt_global_std_eventname: "Nom de l'événement",
    txt_global_std_this15discountcodecanberedeemedongarmincomonselectedgarminautomotivedevices: 'Ce code de réduction de 15 % peut être utilisé sur garmin.com sur certains appareils automobiles Garmin.',
    txt_global_std_yourdeviceisonitsway: "Votre Plug-in est en cours d'envoie. Quand vous le recevez, tout ce que vous devez faire c'est de brancher l'appareil dans sa prise dans la voiture! Entre temps vous pouvez rejoindre une équipe ainsi que les manches!",
    txt_global_std_twoormoregroupscompeteagainsteachother: "Deux groupes ou plus s'affrontent",
    txt_global_std_agreementnumber2: "Numéro d'agrément:",
    txt_global_std_changerecipients: 'Edit recipients',
    txt_global_std_feedbacklatesttrip: 'Latest trip',
    txt_global_std_removingtheuserfromthegroupdoesnotdeletetheusersaccount: "La suppression de l'utilisateur du groupe ne supprime pas le compte de l'utilisateur.",
    txt_global_std_moderna_where: 'All Sweden',
    txt_global_std_pushdriveendlevel1: 'Wow, din kørsel er i verdensklasse! Tryk hende for at se detaljer om din kørtur.',
    txt_global_std_teamNameisnowinyourrearviewmirror: '{teamName} is now in your rearview mirror...',
    txt_global_std_ClearingNumber: 'Clearingnummer',
    txt_global_std_administrateandinviteusers: 'Administrer et inviter des utilisateurs.',
    txt_global_std_ecofriendunlockedkey: "Vous avez économisé plus de {co2value} % de CO₂ cette semaine en conduisant de manière écologique. Merci de vous soucier de l'environnement!",
    txt_global_std_savemoneyonyourinsurance: 'Économisez de l&#39;argent sur votre assurance.',
    txt_global_std_afollowthesestepstoconnecttheapptoyourcar1pressthedrivebuttonintheapp2startyourcarsengine3yourcarshouldshowupintheapp4selectyourcar: "R : Suivez ces étapes pour connecter l'application à votre voiture :\n" +
      "    1. Appuyez sur le bouton de conduite dans l'application\n" +
      '    2. Démarrez le moteur de votre voiture\n' +
      "    3. Votre voiture devrait apparaître dans l'application\n" +
      '    4. Sélectionnez votre voiture\n',
    txt_global_std_firstdrive4: 'Premier trajet',
    txt_global_std_unabletoloadchallengeleaderboard: 'Unable to load challenge leaderboard',
    txt_global_std_p2pnothinghaschanged: "Rien n'a changé",
    txt_global_std_recruitmentamount: 'Montant du recrutement',
    txt_global_std_repeatyourpassword: 'Répétez votre mot de passe',
    txt_global_std_TerminationConfirmPlugin: 'Termination Confirm',
    'txt_global_std_Connecting to Enerfy reader...': 'Connecting to {appName} reader...',
    txt_global_std_selectcategory: 'Choisir une catégorie',
    txt_global_std_sendto: 'Send to',
    txt_global_std_notavalidstartdate: 'Not a valid start date',
    txt_global_std_howdoidrivewellanswer: 'Un conducteur sûr est un conducteur averti. La conduite sécuritaire consiste à rester concentré à 100% sur votre conduite et sur votre environnement. Restez vigilant et tenez compte du rythme de la circulation et d&#39;autres circonstances, telles que la pluie, etc. AJUSTEZ TOUJOURS VOTRE VITESSE AUX CONDITIONS PRÉALABLES.',
    txt_global_std_choose: 'Choisir:',
    txt_global_std_ifyoucannotfindyourfriendorarenotregisteredasauseryourfriendencouragesyoutodownloadtheappnameappandgetaprofile: "Si vous ne trouvez pas votre ami ou n'êtes pas enregistré en tant qu'utilisateur, votre ami vous encourage à télécharger l'application {appname} et à obtenir un profil",
    txt_global_std_addcarinvolved: 'Ajouter voiture impliquée',
    txt_global_edj_note: 'Note',
    txt_global_std_tinaDescription: "2008 I was historically in the Middle East by competing with Nasser Saleh Al-Attiyha (Qatar), right now the largest sports profile there - we won two World Cups in 2008, one in the series and one in the Baja series. In this constellation, there has only been one such successful sports team in the world! That we succeeded in our successes, I would like to thank Nasser's mother, who raised her son not to have preconceived opinions regarding his card reader's skin color, sex or religion. We also led Dakarrally in South America in 2009 when we had to break the sixth stage.\n" +
      '\n' +
      'My career in motor sports started in 1990. Since then, I have won three women´s World Championship titles, two second places in Gr. N. rally world championship and two world cup wins in rally raid 2008 & 2009. To my main merits, I expect a second and third place in the famous Dakar rally.\n' +
      '\n' +
      'Perhaps my most famous drivers have been Colin McRae, Jutta Kleinschmidt, Kenneth Eriksson and Ari Vatanen; In addition, I have navigated to about twenty international drivers.\n',
    txt_global_std_youhavenoactivechallengesrightnow: 'Vous n&#39;avez pas de défis actifs en ce moment',
    txt_global_std_type2: 'Taper',
    txt_global_std_Invite_Text5: 'Create an account with your email-address and click on the link in this email to join your new team.',
    txt_global_std_yousentafriendrequest: 'You sent a friend request',
    txt_global_std_NotCreatedAccount3_Text1: "You haven't created an {appName} account for your car insurance yet. If you don't create an account within a day, your car insurance will transfer to a day premium that you pay for every day.",
    txt_global_std_addfriend: ' Ajouter un ami',
    txt_global_std_confirmpurchase: "Confirmer l'achat",
    txt_global_std_pullthebartoseehowyourleveleffectsyourdiscount: 'Tirez sur la barre pour voir comment votre niveau affecte votre remise',
    txt_global_std_idontwanttoconnectnow: 'Je ne veux pas me connecter maintenant',
    txt_global_std_p2ppushtitleyouhaveabookingtofinalize: 'Vous avez une réservation à finaliser',
    txt_global_std_stateinsuranceinformation: "{état} Informations sur l'assurance",
    txt_global_std_youhaveexceededthespeedlimitof: ' Vous avez dépassé la limite de vitesse de {maxSpeed} km/h.',
    txt_global_std_copyinvitationlink: "Copier le lien d'invitation",
    txt_global_std_carusage: 'Utilisation de la voiture',
    txt_global_std_selectyourcarmodeltoseethemostaccuratedata: 'Sélectionnez votre modèle de voiture pour voir les données les plus précises',
    txt_global_std_createyourveryowncompanychallengeinthefiasdcandunderstandandmanagetheimpactyourteamhasonroadsafetyandco2emissions: "Créez votre propre défi d'entreprise dans le FIA SDC et comprenez et gérez l'impact de votre équipe sur la sécurité routière et les émissions de CO2.",
    txt_global_std_invitefriendsandteams: 'Invite friends/team',
    txt_global_std_iobd2: ' iOBD2',
    txt_global_std_learncoach: 'Learn Coach',
    txt_global_std_safedriverweeklylockedkey: 'Pour obtenir ce badge, conservez un score supérieur à {score} pendant une semaine.',
    txt_global_std_connecttocarlockedkey: 'Connectez votre application à la voiture via Bluetooth pour gagner ce badge.',
    txt_global_std_p2pearnpointswhenyoudrivesafe: 'Gagnez des points lorsque vous conduisez prudemment',
    txt_global_std_connectwithbluetooth: 'Connectez-vous avec Bluetooth',
    txt_global_std_detailedstatisticsaboutyourdrivingwilldisplayherewhenyouhavedoneyourfirsttrip: "Des statistiques détaillées sur votre conduite s'afficheront ici lorsque vous aurez effectué votre premier trajet.",
    txt_global_std_yourfairinsurance: 'You fair insurance',
    txt_global_std_entertheinvitationcodeyouhavereceivedfromyourworkspaceadministrator: "Entrez le code d'invitation que vous avez reçu de votre administrateur d'espace de travail",
    txt_global_std_division: 'DIVISION',
    txt_global_std_NotDriving_Text1: "We can't see that you have driven with your new car insurance yet. For everything to work properly, we need to get driving data from you. If you haven't driven during those days, there is no problem!",
    txt_global_std_selectfriendsoragroup: 'Sélectionnez des amis ou un groupe',
    txt_global_std_p2ppushtitleexpensesapproved: 'Expenses approved',
    txt_global_std_ifyourfriendregisterswiththereferralcodebothyouandyourfriendwillreceivenok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsurancetheamountisenteredinthepiggybankwhentherecruitmentcodehasbeenregisteredyouhaveafulltimeoverviewofyourrecruitsonthesavingsside: "Si votre ami s'inscrit avec le code de parrainage, vous et votre ami recevrez 250 NOK dans la tirelire (cela nécessite que vous soyez tous les deux clients de Tryg depuis au moins un mois après la date de début de l'assurance).\n" +
      '\n' +
      "Le montant est inscrit dans la tirelire lors de l'enregistrement du code de recrutement. Vous avez une vue d'ensemble à temps plein de vos recrues du côté de l'épargne.",
    txt_global_std_stardriverlockedkey: 'Terminez un trajet avec un score de {score} ou mieux pour déverrouiller ce badge. Vous pouvez gagner ce badge une fois par jour.',
    txt_global_std_createyourowncompanychallengeinjustthreesteps: "Créez votre propre défi d'entreprise en seulement trois étapes !",
    txt_global_std_BillMail_Text7: 'Sincerely,\r\n{appName}',
    txt_global_std_p2puserguides: "Guides d'utilisation",
    txt_global_std_describetheaccident: 'Décrivez l&#39;accident',
    txt_global_std_overspeedalarm: 'Speed alarm',
    txt_global_std_kintoemailheadertext: 'Rejoignez Kinto et soyez récompensé!',
    txt_global_std_status: 'Statut',
    txt_global_std_comingsoon: ' Disponible prochainement !',
    txt_global_std_ChicheritWhyjoin: 'Coming soon...',
    txt_global_std_moreinfo: 'Read more',
    txt_global_std_Insurance_LicenseNumber: 'Registration number',
    txt_global_std_InsuranceAtUs: 'Har du försäkring hos oss',
    txt_global_std_needhelptogetstarted: "Besoin d'aide pour démarrer ?",
    txt_global_std_remainingpointstonextlevel: '{points} points restants à {level}',
    txt_global_std_damagesalvagecall: 'For damages',
    txt_global_std_lookslikethisisempty: 'On dirait que c&#39;est vide!',
    txt_global_std_monthmon: ' mois',
    txt_global_std_selectdates: 'Sélectionnez des dates',
    txt_global_std_createyourownappinafewclicks: 'Créez votre propre application en quelques clics',
    txt_global_std_usernotfound: 'Utilisateur non trouvé.',
    txt_global_std_recruitfivefriendsandyouallcompete: 'Recrutez 5 amis et vous participez tous à la première manche gratuitement',
    txt_global_std_shootingstarnamekey: 'Étoile filante!',
    txt_global_std_conteactinformation: 'Informations de contact',
    txt_global_std_bestdriversrightnow: 'Les meilleurs pilotes du moment !',
    txt_global_std_reason: 'Reason:',
    txt_global_std_BillMail_Text5: 'SEK, good job! ',
    txt_global_std_nameofapp: "Nom de l'application",
    txt_global_std_quantitytoshow: 'Quantité à afficher',
    txt_global_std_TaxInvoice8: 'La couverture prend fin le 61e jour si la prime n’est pas payée dans les 60 jours suivant la date d’établissement de la police.',
    txt_global_std_checkifcorrectobdreader: 'Vérifiez si le Plug-in est branché correctement',
    txt_global_std_filtersortlist: 'Filter / sort list',
    txt_global_std_youhaveonlydistancekmleft: 'Il ne vous reste que {distance}km.',
    txt_global_std_p2pfaq8q: 'Comment rendre ma voiture indisponible les jours où je l’utilise moi-même?',
    txt_global_std_thisisadrivingappforustobeabletomeasureyourdrivingweneedtoaccesstobbluetoothlocationservicesbandbmotionfitnessb: 'Ceci est une application de conduite. Pour que nous puissions mesurer votre conduite, nous devons accéder à <b> Bluetooth, aux services de localisation </b> et à <b> mouvement et fitness. </b>',
    txt_global_std_HansenWhyjoin: 'I will work closely with my teammates and try to combine the two factors of smart driving. Together we will aim to enjoy our driving, push ourselves to do better and drive super smart and safe! By doing so, I believe we can win every race, even the greatest one of them all: the race for saving our planet!',
    txt_global_std_JyrkiainenMileage: 'I have had driving license for only almost a year but I love the route to Finnish Lapland, the scenery becomes more and more snowy all the time!',
    txt_global_std_Reminder_Text2: 'is not paid and your insurance with us will terminate in 14 days if not paid. We ask that you please pay your premium as soon as possible.',
    txt_global_std_earn1500pointsifyoubook5timesamonth: 'Gagnez 1500 points si vous réservez 5 fois par mois\n\n',
    txt_global_std__ongoing: '(En cours)',
    txt_global_std_p2pdeletingofmessages: 'Suppression de {nocurrent} messages sur {todelete}',
    txt_global_std_additionalfiles: 'Fichiers supplémentaires',
    txt_global_std_p2pmystatscomingsoon: 'Mes statistiques - À venir',
    txt_global_std_needmoremilagetitle: "Need more milages? We'll help you",
    txt_global_std_reachhighcostsinashorttime: 'Reach high costs in a short time',
    txt_global_std_afriend: ' Un ami',
    txt_global_std_PolicySchedule29: 'Des dépassements d’un maximum de 2 000 dollars s’appliqueront en cas d’accident faisant l’objet du score AVG.',
    txt_global_std_yourrunsandpremiumwillbeshownhere: 'your trips and premium will be shown here.',
    txt_global_std_areyouabsolutelysuperdupersurethatyouwanttoexchangeyourpreciouspointsnowandnotsavethemforlater: 'Êtes-vous absolument super sûr de vouloir échanger vos précieux points maintenant et de ne pas les conserver pour plus tard ?',
    txt_global_std_supportingcluestowhy: `Indices à l'appui du "Pourquoi"`,
    txt_global_std_maximizetext: ' Trajets avec bonus',
    txt_global_std_MikkelsenWhyjoin: 'I will use my experience from rally & racing to help people become better drivers. Being a good driver is not only about being fast but being just as efficient and clever.',
    txt_global_std_bronze: 'Bronze',
    txt_global_std_super: ' Génial',
    txt_global_std_areyousureyouwanttosignout: ' Êtes-vous sûr de vouloir vous déconnecter ?',
    txt_global_std_sidekicknotifications: "Pour une meilleure expérience de l'application, veuillez autoriser Sidekick à vous envoyer des notifications.",
    txt_global_std_notavailable: 'Indisponible',
    txt_global_std_hangin: ' Tenez bon',
    txt_global_std_PaymentTrouble2_Text8: '* If your insurance is not paid, the TFF will take over the debt and the handling of the unpaid premium for the time the policy has been in effect with {appName}. The TFF charges a very high daily fee for every day that your car is not insured. Traffic insurance is obligatory and means that you are insured against personal injury and damage to property of another.',
    txt_global_std_wildfireunlockedkey: 'Vous avez extrêmement bien conduit! Votre niveau moyen était meilleur que 4,4 pendant une semaine! 5000 points ont été ajoutés à votre portefeuille Kinto.',
    txt_global_std_removeuserfrom: "Supprimer l'utilisateur de",
    txt_global_std_borrowmycar: 'Emprunter ma voiture',
    txt_global_std_HEAT: 'MANCHE',
    txt_global_std_PolicySchedule10: 'Seaters',
    txt_global_std_contactus: 'Contactez nous',
    txt_global_std_p2pfriendinvitedyouto: 'Votre ami vous a invité à Kinto Car Sharing',
    txt_global_std_terminateinsurance: 'Terminate insurance',
    txt_global_std_jan: ' Jan.',
    txt_global_std_sentbyenerfyglobalaproductbygreaterthanab: 'Envoyé par Enerfy Global, un produit de Greater Than AB.',
    txt_global_std_groupchat: 'Discussion de groupe',
    txt_global_std_newpasswordagain: 'Entrez à nouveau le nouveau mot de passe',
    txt_global_std_allownotificationstogetdrivingtipsafter: 'Autorisez les notifications à obtenir des conseils de conduite après chaque trajet et améliorez vos compétences de conduite.',
    txt_global_std_cimage36d: ' ',
    txt_global_std_InsuranceChangeModerna_Hello: 'Hej',
    txt_global_std_enterthetemporarypasswordsenttoyoubyemail: 'Saisissez le mot de passe temporaire qui vous a été envoyé par e-mail.',
    txt_global_std_loyaltydrivers: 'Conducteurs',
    txt_global_std_preview: 'Preview',
    txt_global_std_addvehicles: 'Add vehicles',
    txt_global_std_Insurance_PaymentInformation: 'Payment information',
    txt_global_std_fiasdc2019w1price2: '3 Startpaket',
    txt_global_std_trips: ' Trajets',
    txt_global_std_totalscore1: 'Score total',
    txt_global_std_takephotosofaccident: 'Remember to take photos of the accident and save date, time and place.',
    txt_global_std_attention: 'Attention',
    txt_global_std_p2preservation: 'Réservation',
    txt_global_std_nothingtoshow: 'Rien à montrer',
    txt_global_std_p2ppendingph: '{count}En attente',
    'txt_global_std_Network error': 'Network error',
    txt_global_std_vehicleinfo: ' Infos du véhicule',
    txt_global_std_wehavereceivedyourreport: 'Nous avons reçu votre rapport et votre demande sera traitée sous peu',
    txt_global_std_warikan_onboarding6_2: '〒450-0002　愛知県名古屋市中村区名駅四丁目8番18号　名古屋三井ビルディング北館１４Ｆ',
    txt_global_std_betterthanavgco2: 'Better than average CO2',
    txt_global_std_50kmlockedkey: 'Parcourez une distance de 50 km pour obtenir ce badge.',
    txt_global_std_ayouwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithappnamewithalapseincoverage: "R : Vous disposerez de {days} jours à compter de la date d'expiration pour effectuer un paiement et poursuivre votre police avec {appName} avec une interruption de couverture.",
    txt_global_std_contact: ' Contact',
    txt_global_std_ecosafesaving: 'EcoSafe saving',
    txt_global_std_PreSaleDetails_Text68: 'The Personal Data Act',
    txt_global_std_this15discountcodecanberedeemedagainstanygarminpndpurchase: 'Ce code de réduction de 15 % peut être échangé contre tout achat de Garmin PND.',
    txt_global_std_yourinsuranceletterwassuccessfullysentto: 'Your insurance letter was successfully sent to:',
    txt_global_std_p2pscore: 'Score:',
    txt_global_std_nodataavailable1: 'pas de données disponibles',
    txt_global_std_uploadextrareportinfo: 'Importer des informations supplémentaires sur le rapport',
    txt_global_std_erroroccuredpleasetrylater: 'Error occured, please try later.',
    txt_global_std_MachineDamageWhen: 'När skedde maskinskadan',
    txt_global_std_signout: ' Se déconnecter',
    txt_global_std_herearetheresultsofthechallengename: 'Voici les résultats du nom du Challenge.',
    txt_global_std_maxxcharactersrule: 'Max {x} caractères',
    txt_global_std_enteryourinformation: ' Saisissez vos informations',
    txt_global_std_PersonalNumber: 'Personnummer',
    txt_global_std_p2pdrivendistance: 'Distance parcourue',
    txt_global_std_earn10000pointsifyouupgradetovalueplan: 'Gagnez 10000 points si vous passez au plan de valeur',
    txt_global_std_nationality: 'Nationalité',
    txt_global_std_tiphighway4DESC: 'Le conducteur de la voiture rouge utilise les freins et l’essence plus souvent',
    txt_global_std_delete: 'Effacer',
    txt_global_std_startguidetip4: 'Laissez l&#39;application se charger jusqu&#39;à ce que le cercle de partition apparaisse.',
    txt_global_std_PreSaleDetails_Text49: 'Legal Expenses',
    txt_global_std_mileagekm: 'Mileage in km',
    txt_global_std_howmuch: 'Combien',
    txt_global_std_tripsmissingpurpose: 'Trips missing purpose',
    txt_global_std_arequesthasbeenreceivedtochangethepasswordforyour: 'Une demande a été reçue pour modifier le mot de passe de votre',
    txt_global_std_thislinkexpiresin: 'Ce lien expire dans',
    txt_global_std_p2preceipt: 'Le reçu',
    txt_global_std_nochallengestojoinyet: 'Pas encore de défis à rejoindre',
    txt_global_std_b1gotosettingsforappnameinyourphonebbrb2allowaccesstobluetoothb: "<b> 1. Accédez aux paramètres de {appname} sur votre téléphone. </b> <br> <b> 2. Autoriser l'accès à Bluetooth </b>",
    txt_global_std_view: ' Vue',
    txt_global_std_daterange: 'Plage de dates',
    txt_global_std_youdrovesupremelywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Vous avez très bien conduit! Votre moyenne était meilleure que {score} pendant une semaine! Vous avez gagné {points} points!',
    txt_global_std_p2psuccessfullyeditedadminrights: "Droits d'administrateur modifiés avec succès",
    txt_global_std_soclosetoearningachievement: 'Vous étiez si près de gagner un exploit et de gagner des points de fidélité. Concentrons-nous!',
    txt_global_std_MSIG_Onboarding4: 'en avant. Trouvez votre certificat d&#39;assurance et d&#39;autres informations jointes dans cet email.',
    txt_global_std_yourinvitewassenttoemail: ' Votre invitation a été envoyée à',
    txt_global_std_p2pincreasetextsize: 'Augmenter la taille du texte',
    txt_global_std_Accept_Text2: 'Hilsen, \r\nSidekick-gjengen i Tryg Forsikring',
    txt_global_std_p2pfindmycarcomingsoon: 'Trouver ma voiture - Prochainement',
    txt_global_std_monthlysavings: 'Économies mensuelles',
    txt_global_std_stats: 'Statistiques',
    txt_global_std_50offdeductible: '50% de réduction sur la franchise',
    txt_global_std_ongoing__: '(En cours)',
    txt_global_std_startday: 'Commence jour',
    txt_global_std_buttonlink: 'Button',
    txt_global_std_addcomparison: ' Ajouter un comparatif',
    txt_global_std_awesomedriveroftheday: 'Super chauffeur du jour',
    txt_global_std_selectformat2: 'sélectionner le format',
    txt_global_std_aug: ' Août',
    txt_global_std_choosebetweenenteringchassisnumberplatenumberorpolicynumber: 'Choisissez entre le numéro de châssis, le numéro de plaque ou le numéro de police.',
    txt_global_std_PolicySchedule5: 'Assuré',
    txt_global_std_cimage8h: ' ACCÉLÉREZ EN DOUCEUR',
    txt_global_std_bevisibleorhiddenintheleaderboard: 'Être visible / caché dans le classement',
    txt_global_std_findyourfriends: ' Retrouver vos amis',
    txt_global_std_SulayemQuote: 'Quote coming soon...',
    txt_global_std_p2psplittingthecostbydistancetravelledrequiresbluetoothconnection: 'La division du coût par la distance parcourue nécessite une connexion Bluetooth.',
    'txt_global_std_2. Make your choices if applicable.': '2. Make your choices if applicable.',
    txt_global_std_InsuranceMail_Text11: 'Glass',
    txt_global_std_errorcodes: ' Codes d’erreur',
    txt_global_std_MSIG_InsuranceLetter40: 'La somme de 3 500,00 SGD sur les SECTIONS I et II est imposée séparément aux conducteurs de moins de 22 ans et / ou ayant moins de deux ans d’expérience de la conduite.',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithcovercubewithalapseincoverage: "Vous aurez {days} jours à compter de la date d'expiration pour effectuer un paiement et poursuivre votre police avec Covercube avec une déchéance de couverture.",
    txt_global_std_inserttheobdreaderandstartyourcarwaitforthereadertoshowabluelight: 'Insérez le lecteur OBD et démarrez votre voiture. Attendez que le lecteur affiche une lumière bleue.',
    txt_global_std_FireWhen: 'När upptäcktes brandskadan',
    txt_global_std_PreSaleDetails_PreSaleDetails: 'Pre sale details',
    txt_global_std_yourcarisntturnedonnodot: "Votre voiture n'a pas été démarrée.",
    txt_global_std_qdoescovercubeacceptdriversthatarenotlicensedintheunitedstates: 'Q : Covercube accepte-t-il les conducteurs qui ne sont pas autorisés aux États-Unis ?',
    txt_global_std_1year: '1 an',
    txt_global_std_warikan_onboarding10: "* Cette adresse e-mail est uniquement destinée à l'envoi. Veuillez noter que nous ne pouvons pas répondre directement à cet e-mail.\n",
    txt_global_std_yousentamessage: 'You sent a message',
    txt_global_std_welcometoteam: 'Bienvenue dans {équipe}',
    txt_global_std_p2pfaq30a: `Chaque mois, vous recevez une facture du propriétaire, et le message "Vous avez reçu le détail de la facture" s'affiche dans "Que faire" sur l'écran d'accueil. Alternativement, vous pouvez le vérifier à partir de "Warikan" dans "Menu".\n` +
      `1. Après avoir sélectionné "Warikan", vérifiez les détails dans l'onglet Facture.\n` +
      '2. Après avoir confirmé les détails de facturation, sélectionnez « Continuer » pour confirmer le montant du paiement.\n' +
      '3. Sélectionnez un mode de paiement et sélectionnez "Payer maintenant".',
    txt_global_std_PaddonFacts: 'Hayden a débuté avec le go-kart depuis l’âge de 6 ans. Depuis toujours, il vit et respire le sport automobile',
    txt_global_std_yourobdreaderisonitsway: 'Votre lecteur OBD est en route et arrivera dans 1-2 jours ouvrables. Suivez l’étape suivante pour l’installer facilement.',
    txt_global_std_timezone: 'Fuseau horaire',
    txt_global_std_youravgscoreis123: 'Votre score moyen est de',
    txt_global_std_OwnerContactDetails: 'Ägarens kontaktuppgifter',
    txt_global_std_welcometocovercube_: 'Bienvenue sur Covercube !',
    'txt_global_std_0km/hco2tonnes': '0 km/h CO2 tonnes',
    txt_global_std_automessage_level_14__2: 'There is money to save by driving better!',
    txt_global_std_InjuryPersonType: 'Typ av person',
    txt_global_std_sidekickinvite: 'Invite to Sidekick',
    txt_global_std_thisisatextmessage: 'Ceci est un SMS.',
    txt_global_std_challengefriend2: 'Défi ami!',
    txt_global_std_Fia_Receipt_Text9: 'La quantité',
    txt_global_std_theworldchampionshipwheresmartnessbeatsspeed: ' , le défi mondial dans lequel l’intelligence dépasse la vitesse. Participez dans le cadre de vos trajets quotidiens et laissez-vous accompagner par des professionnels pour devenir le conducteur le plus intelligent au monde.',
    txt_global_std_events_overview_monthly: 'Mensuel',
    txt_global_std_wildfirenamekey: 'Incendies!',
    txt_global_std_p2pchangedthenameofthegroup: 'changé le nom du groupe',
    txt_global_std_socialbutterflynamekey: 'Papillon social!',
    txt_global_std_invalidvouchercode: ' Code invalide',
    txt_global_std_2nd: ' 2e',
    txt_global_std_p2perrorcodecarmustbebonded: 'La voiture doit être collée.',
    txt_global_std_expensessummary: 'Résumé des dépenses',
    txt_global_std_everyday: ' Quotidien',
    txt_global_std_BillMail_Text4: 'This period, your eco safe driving has saved you',
    txt_global_std_whatwillitcosttodrivewithoutbeing: 'What will it cost to drive without being connected to my car?',
    txt_global_std_p2peasyoverviewinyourcalendar: 'Aperçu facile dans votre calendrier',
    txt_global_std_featured: 'EN CE MOMENT',
    txt_global_std_youdrovesupremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Vous avez très bien conduit! Votre {scoretype} moyen était meilleur que {score} pendant une semaine! Vous avez gagné {points} points!',
    txt_global_std_contactdetailss: 'Détails du contact',
    txt_global_std_congratulationsonthistypeoftripyouareanecochampionthanksforbeinganecofriendlydriver: "Toutes nos félicitations! Sur ce type de voyage, vous êtes un éco champion ! Merci d'être un conducteur éco-responsable !",
    txt_global_std_networkerrorpleasetryagainlater: 'Erreur réseau, veuillez réessayer plus tard',
    txt_global_std_orientering: 'Orientation',
    txt_global_std_refer10friendstounlockthisbadge: 'Parrainez 10 amis pour débloquer ce badge.',
    txt_global_std_downloadapispecification: "Télécharger la spécification de l'API",
    txt_global_std_Insurance_FixedPremiumText: 'Fixed monthly premium comprehensive insurance',
    txt_global_std_showchanges: 'Afficher les modifications ?',
    txt_global_std_contributesustainabilitythroughwarikan: 'Contribuez à la durabilité grâce à Warikan KINTO',
    txt_global_std_makeitempty: ' Ne pas mettre de valeur !',
    txt_global_std_WorkShop: 'Verkstad',
    txt_global_std_BillMail_Hello: 'Hello!',
    txt_global_std_changeemail: 'Changer l&#39;e-mail',
    txt_global_std_letsfindyourcar: 'Trouvez votre',
    txt_global_std_chooseaccident: 'Choose accident',
    txt_global_std_cwelcome13: 'Have a great day',
    txt_global_std_MSIG_Onboarding13: 'MS First Capital | Siège social: 6, Raffles Quay, n ° 21-00 | Singapour 048580 | Tél.: (65) 6222 2311',
    txt_global_std_profilesetup: ' Paramètres du profil',
    txt_global_std_co2saverunlockedkey: 'Bon travail! Vous avez économisé plus de {co2value} % de CO₂ pendant ce voyage en conduisant de manière écologique.',
    txt_global_std_MSIG_InsuranceLetter25: 'Utilisez uniquement à des fins sociales, domestiques et de loisir et pour les affaires de l&#39;assuré.',
    txt_global_std_goldusers: 'Utilisateurs Or',
    txt_global_std_iwillfixitlater: 'Je vais le réparer plus tard',
    txt_global_CoreTemplate_other: 'Other',
    txt_global_std_myspecialservices: 'My special services',
    txt_global_std_joinwithinvitationcode: "Rejoindre avec le code d'invitation",
    txt_global_std_statisticsshowingyourpersonalprogress: 'Statistiques montrant vos progrès personnels.',
    txt_global_std_mandatory: 'Obligatoire',
    txt_global_std_p2pfaq20q: 'Est-il possible de vérifier les données de conduite ?',
    txt_global_std_allowlocationservicetogetaccuratescore: 'Autoriser le service de localisation à obtenir des conseils personnalisés et un score précis.',
    txt_global_std_templates: 'Modèles',
    txt_global_std_enterpolicynumberhere: 'Entrez le numéro de police ici',
    txt_global_std_agentname: 'Agent name',
    txt_global_std_congratulationsthatwasyourbestever: 'Congratulations, that was your best ever',
    txt_global_std_shootingstarunlockedkey: 'Vous avez conduit au niveau 3.3 ou mieux! Génial! 100 points ont été ajoutés à votre portefeuille Kinto.',
    txt_global_std_addadditionalfiles: 'Ajouter des fichiers supplémentaires',
    txt_global_std_savefuelandreduceemissions: 'Save fuel and reduce emissions.',
    txt_global_std_hasbeeninvitedasaroletoyourteam: 'A été invité en tant que {rôle} dans votre équipe',
    txt_global_std_mpltipiip: 'Ceci est un conducteur qualifié mais indique une incohérence de conduite. Mais dans ce cas ça arrive pendant une journée ici et là mais aussi une journée avec Cool Driving à 95% puis pendant un court laps de temps, Cool Driving est ignoré.\n' +
      "C'est une combinaison d'ignorer Cool Driving pendant une journée ou juste pour un voyage pendant une journée.\n" +
      'Pour commencer, nous recommandons que le formateur avec le conducteur essaie de comprendre quel peut être le problème.',
    txt_global_std_p2phasreceivedyourbooking: 'a reçu votre demande de réservation.',
    txt_global_std_forthisapptoworkproperlyweneedtoaccessnobr: 'Pour pouvoir enregistrer un lecteur, vous devez\n' +
      'autoriser le bluetooth, les services de localisation et\n' +
      'mouvement et remise en forme.',
    txt_global_std__resendtemporarypassword: 'Renvoyer le mot de passe temporaire.',
    txt_global_std_translationtool_upsell_pitch_bulletpoint2: 'Faites correspondre les communications au ton de votre marque',
    txt_global_std_yourdatahasbeensent: 'Vos données ont été envoyées, merci de consulter vos emails!\n',
    txt_global_std_invitehowitworkslink: 'How the invitation works',
    txt_global_std_AccountOwner: 'Kontohavare',
    txt_global_std_prev: 'Précédent',
    txt_global_std_today: ' Aujourd’hui',
    txt_global_std_MSIG_InsuranceLetter21: 'L’assuré peut également conduire une voiture n’appartenant pas à elle ou louée (en vertu d’un contrat de location-vente ou autrement) à lui-même, à son employeur ou à son partenaire.',
    txt_global_std_OgierDescription: "He is the current holder of the World Rally Drivers' Championship, having won the title six times, in 2013, 2014, 2015, 2016, 2017 and 2018. With 44 victories in the World Rally Championship and six consecutive WRC titles, he is the second most successful WRC driver, after former Citroën WRC teammate Sébastien Loeb (nine titles).",
    txt_global_std_wehavenoticedthatyouhaveincreased: 'We have noticed that you have increased your premium rate. Did you know that you can get a better rate by driving smarter?',
    txt_global_std_glassdamage: 'Glass damage',
    txt_global_std_terrainhandling: 'Manipulation du terrain',
    txt_global_std_withroundtheclockcallinincaseof: 'With around the clock call in case of an accident.',
    txt_global_std_NotDrivingForATime_Text2: 'Kör du ej uppkopplad under en längre period kommer dessa körningar ändå att registreras. Din {appName} Plug-in har nämligen ett minne, vilket gör att alla dina körningar lagras i läsaren. När du nästa gång kopplar upp dig (med telefonen) kommer dessa körningar att laddas ned i din {appName}app och inkluderas i din nästa faktura för den månaden. Kostnaden kan då bli högre än vad du är van vid eftersom alla dina ouppkopplade körningar inkluderas.',
    txt_global_std_readytogoappsolutionsthatwehavehandpickedforyou: "Solutions d'applications prêtes à l'emploi que nous avons triées sur le volet pour vous.",
    txt_global_std_visitteamprofile: 'Visit team profile',
    txt_global_std_callwithdriver: 'Appel avec chauffeur',
    txt_global_std_whatwillhappenififorgettoconnect: 'What will happen if I forget to connect to my car?',
    txt_global_std_PreSaleDetails_Text36: 'All risk is mandatory if you signed a semi- or fully comprehensive insurance.',
    txt_global_std_filteroptionnotinuse: 'Hors service',
    txt_global_std_youhavenowmaintainedafullweeksdrivingwithanaveragescoreabove4: 'Vous avez maintenant maintenu une semaine complète de conduite avec un score moyen supérieur à 4,0',
    txt_global_std_icannotconnectanswertext1: 'Assurez-vous de suivre toutes les étapes de la section «Se connecter à la voiture». S&#39;il vous plaît envoyer un courriel',
    txt_global_std_anewpasswordhasbeengeneratedforuser: 'Un nouveau mot de passe a été généré pour {user}',
    txt_global_std_ConfirmFia_Text10: ' Êtes-vous prêt(e) pour le test de conduite SDC ?',
    txt_global_std_fiasdc2019w1price1: '5 Startpaket',
    txt_global_std_p2pselectthedatesandtime: 'Sélectionnez les dates et heures auxquelles vous souhaitez rendre votre voiture indisponible',
    txt_global_std_logvehicles: 'Log vehicles',
    txt_global_std_p2pfaq14q: 'Et si je rends la voiture trop tard?',
    txt_global_std_p2pfaq30q: "S'il vous plaît dites-moi comment payer le coût.",
    txt_global_std_priceanual: 'Price anual',
    txt_global_std_groupnames: 'Noms de groupe',
    txt_global_std_youcanrepublishyourtranslationslaterifyouwant: 'Vous pouvez republier vos traductions plus tard si vous le souhaitez.',
    txt_global_std_p2pfaq22q: 'Je veux enregistrer des coûts supplémentaires.',
    txt_global_std_speedchangeanalysis2: 'Analyse du changement de vitesse',
    txt_global_std_p2pfaqcontactinfo1: "・ Horaires de la réception de 9h00 à 18h00 (hors vacances de fin d'année et de nouvel an)",
    txt_global_std_NotCreatedAccount6_Hello: 'Hello',
    txt_global_std_areyousureyouwanttodeletememberfromworkspacethisactioncannotbeundone: "Voulez-vous vraiment supprimer le membre de l'espace de travail ? Cette action ne peut pas être annulée.",
    txt_global_std_tripdetailshavebeensenttoemail1: 'Les détails du voyage ont été envoyés par e-mail.',
    txt_global_std_resendactivationcodeto: "Renvoyer le code d'activation à",
    txt_global_std_sendteamchallenge: 'Send team challenge',
    txt_global_std_mars: ' Mars',
    txt_global_std_activationcodeoptional: "Code d'activation (facultatif)",
    txt_global_std_yourdrivingstyleisabithecticrightnow: 'Your driving style is a bit hectic right now.',
    txt_global_std_lockeddescriptionlabel: "Description verrouillée, affichée dans l'application",
    txt_global_std_minute: ' Minute',
    txt_global_std_invoicesettings: 'Invoice settings',
    txt_global_std_enerfycoachtip1: ' 1. Restez entièrement concentré sur votre conduite.',
    txt_global_std_p2pcostsplitsettings: 'Paramètres de répartition des coûts',
    txt_global_std_p2pnamesentaneditrequesttoname2: '{name} a envoyé une demande de modification à {name2}',
    txt_global_std_changebackground: ' Changer l’image d’arrière-plan',
    txt_global_std_ridewithmehowitworks: 'Comment ça fonctionne',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithappnamewithalapseincoverage: "Vous aurez {days} jours à compter de la date d'expiration pour effectuer un paiement et continuer votre police avec {appName} avec une expiration de la couverture.",
    txt_global_std_youhavereachthemaximumlevel: 'Vous avez atteint le niveau maximum!',
    txt_global_std_p2pfriendadminrights: "Droits d'administrateur des amis",
    txt_global_std_tipcity1DESC: 'Le conducteur de la voiture bleue reste à bonne distance des voitures environnantes. Le conducteur de la voiture rouge pousse et freine souvent sans nécessité.',
    txt_global_std_ConfirmFia_Text2: ' Vous avez à présent une place réservée pour le Smart Driving Challenge de la FIA à Paris.',
    txt_global_std_wearebuildingyourworkspace: 'Nous construisons votre espace de travail',
    txt_global_std_events: ' Évènements',
    txt_global_std_teamage: 'Âge:',
    txt_global_std_TermConfirmPlugin_Text2: 'will be terminated from',
    txt_global_std_NewPW_Text2: ' Votre nouveau mot de passe',
    txt_global_std_insuranceletter: 'Insurance letter',
    txt_global_std_safetyalarms: 'Safety alarms',
    txt_global_std_superadmin: 'super administrateur',
    txt_global_std_p2pfaq21a: 'Vous pouvez vérifier tous les membres enregistrés en sélectionnant « Membres » dans le « Menu ».',
    txt_global_edj_avgspeed: 'Avg speed (km/h)',
    txt_global_std_workshops: 'Ateliers',
    txt_global_std_Accept_Hello: 'Hei!',
    txt_global_std_failedtogettranslationoverview: "Échec de l'obtention de la vue d'ensemble de la traduction",
    txt_global_std_obdreadersnearby: 'Lecteurs OBD à proximité',
    txt_global_std_p2pfaq1a: "Ce sont des fonctions vraiment sympas qui seront bientôt disponibles dans l'application. Alors, gardez un œil sur les mises à jour!",
    txt_global_std_numberofexecutions: "Nombre d'exécutions",
    txt_global_std_thisisyourusername: ' Voici votre nom d’utilisateur',
    txt_global_std_daysleft: ' Jours restants',
    txt_global_std_p2paddedexpenses: 'Dépenses',
    txt_global_std_drivingdata: 'Données de conduite',
    txt_global_std_InsuranceMail_Text30: 'You will find complete terms at www.enerfy.se, in your Enerfy app or at "My Pages" at enerfy.se. See the terms MF-E 2017-03-01 and MF-M 2017-01-01.',
    txt_global_std_Witness: 'Vittnen',
    'txt_global_std_Al-KuwariWhyjoin': 'Because I create a family with in the team.',
    txt_global_std_p2pitsalittleemptyinhere: "C'est un peu vide ici",
    txt_global_std_IntroductionMail: 'Innføring i forsikringsprodukt',
    txt_global_std_nodrivershavejoinedyet: 'No drivers have joined yet.',
    txt_global_std_Recruit_Text3: 'Hälsningar',
    txt_global_std_oldpassword: ' Ancien mot de passe',
    txt_global_std_permission: 'Autorisation',
    txt_global_std_bonusactivatedtitle: 'Bonusen registrerad',
    txt_global_std_keepaneyeonyourinsurancecostseachmonth: 'Keep an eye on your insurance costs each month',
    txt_global_std_iconback: '{icône} Retour',
    txt_global_std_KleinschmidtMileage: 'Too me, my experience of mileage is depending on the current traffic situation and on my personal situation',
    txt_global_std_followupontheonboardingstatusofyourusers: "Suivi du statut d'intégration de vos utilisateurs",
    txt_global_std_usage: 'Usage',
    txt_global_edj_orgodometer: 'Mileage start (km)',
    txt_global_std_paymentmail: 'Payment mail',
    txt_global_std_jointhechallenge: 'Rejoignez le défi',
    txt_global_std_bronzelevel: 'Niveau Bronze',
    txt_global_std_p2pview: 'Vue',
    txt_global_std_KristofferssonWhyjoin: 'If you see a competition as a challenge and your goal is to give 100 % with the conditions you have - then you should join my team.\n',
    txt_global_std_youcollectedpointsduringthepreviosperiod: 'Vous avez collecté {points} points au cours de la période précédente.',
    txt_global_std_NotCreatedAccount3: 'Reminder: You still have not created an account',
    txt_global_std_noogpsdatafound: ' Aucune donnée GPS',
    txt_global_std_safetydrivingskillupwithwarikankintobody: 'Apprentissage de la conduite sécuritaire avec Warikan KINTO',
    txt_global_std_ifyouwanttolendyourcartoafriendyoushouldinviteyourfriendtotheappnameappbytappingthebuttonbelow: "Si vous souhaitez prêter votre voiture à un ami, vous devez inviter votre ami à rejoindre l'application {appname} en appuyant sur le bouton ci-dessous.",
    txt_global_std_activity: ' Activité',
    txt_global_std_end: ' Fin',
    txt_global_std_p2pcancelthisbooking: 'Annuler cette réservation?',
    txt_global_std_heat123: 'Chaleur',
    txt_global_std_adgangtillokalitet: 'Adgang til lokalitet',
    txt_global_std_secureyourlocation: 'Secure your location',
    txt_global_std_rwmnofriendstext: 'Invitez un ami pour pouvoir enregistrer un trajet en covoiturage.',
    txt_global_std_yourpolicyisreneweddate: 'Votre police est renouvelée {date}',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimeinconstantspeedpayingattentiontoyourenvironmenthelpsyoutosmoothlyandsteadilyvaryyourspeedaccordinglythroughoutyourtrip: "Sur ce type de trajet, l'éco-champion moyen passe moins de temps à vitesse constante. Faire attention à votre environnement vous aide à faire varier votre vitesse en douceur et régulièrement tout au long de votre trajet.",
    txt_global_std_compilinginformationdrive: ' Compilation des informations concernant votre conduite…',
    txt_global_std_region: 'Région',
    txt_global_std_videotutorials: 'Tutoriels vidéos',
    txt_global_std_PaddonBesttip: 'Be patient it’s my strongest advice. It’s very easy to become tense or impatient with other road users when on the road, but remember, a small speed difference will only make a few minutes difference at the end. So, relax, turn up the music and enjoy your ride.',
    txt_global_std_Fia_Receipt_Text16: 'Avec cet achat, vous avez accepté nos conditions générales d&#39;achat.',
    txt_global_std_findanagent: 'Trouver un agent',
    txt_global_std_type: 'Type',
    txt_global_std_insurancenumbernumber: "Numéro d'assurance : {numéro}",
    txt_global_std_automessage_level_13__3: 'Take a look at feedback to get tips on how to get a better driving score.',
    txt_global_std_p2pwriteamessagetoafriendorgroupregardingabookingoranythingselsethatsonyourmind: 'Écrivez un message à un ami ou à un groupe concernant une réservation ou toute autre chose que vous pensez.',
    txt_global_std_pricekm: 'Price/km',
    txt_global_std_features: 'Infos',
    txt_global_std_co2savings: 'Économies de CO₂',
    txt_global_std_p2pselectmember: 'Sélectionnez un membre',
    txt_global_std_getsarouteforagiventrip: 'Obtient un itinéraire pour un trajet donné',
    txt_global_std_notheappworksinbackgroundmodemakesureyoualwayskeepbluetoothandlocationservicesactivatedonyourphone: "Non, l'application fonctionne en arrière-plan. Assurez-vous de toujours garder Bluetooth et les services de localisation activés sur votre téléphone.",
    txt_global_std_paymentmethodcol: 'Payment method:',
    txt_global_std_p2ppaid: 'Payé',
    txt_global_std_p2pemailsent: 'Email envoyé!',
    txt_global_std_distanceprivatekm: 'Private trips (km)',
    txt_global_std_acceleration: ' Accélération',
    txt_global_std_nocloudconnection: ' Aucune connexion au cloud',
    txt_global_std_pointsoverview: 'Aperçu des points',
    txt_global_std_emailnotfound: 'Email non trouvé',
    txt_global_std_textwelcomewiget: 'Ça va être tellement amusant! Commençons à conduire intelligemment. Je serai là pour vous guider à travers les scores élevés!',
    txt_global_std_PSolbergBesttip: 'Coming soon...',
    txt_global_std_asafecomprehensiveinsurance: 'A safe comprehensive insurance',
    txt_global_std_Insurance_WelcomeLetter: ' Lettre de bienvenue',
    txt_global_std_countdays: ' {count} jours',
    txt_global_std_privateorbusiness: ' Privé ou professionnel ?',
    txt_global_std_addphoto: ' Ajouter une photo',
    txt_global_std_private2: 'Privé',
    txt_global_std_loadingyourdrives: 'Chargement de vos lecteurs ...',
    txt_global_std_NotCreatedAccount6: 'Your {appName}-insurance has now been transferred to a Moderna-insurance',
    txt_global_std_Insurance_LastEdit: 'Latest Change',
    txt_global_std_whathappensififorgettorenewbeforemyinsurancehasexpired: "Que se passe-t-il si j'oublie de renouveler avant l'expiration de mon assurance ?",
    txt_global_std_invitesent: ' Invitation envoyée',
    txt_global_std_recommended: 'conseillé',
    txt_global_std_letsdrivewithwarikankinto: 'Roulons avec Warikan KINTO !',
    txt_global_std_mobilenumber: ' Numéro de mobile',
    txt_global_std_p2pgoheretocreateabookingofyourfriendscar: 'Allez ici pour créer une réservation de la voiture de votre ami.',
    txt_global_std_wantmoreindepthanalysis: 'Vous souhaitez une analyse plus approfondie et des informations détaillées avec une identification instantanée des facteurs à haut et à faible risque ?',
    txt_global_std_monthm: ' m',
    txt_global_std_alinktodownloadyourdemoappwillbesenttoemailwhenready: "Un lien pour télécharger votre application de démonstration sera envoyé à {email} lorsqu'il sera prêt.",
    txt_global_std_PreSaleDetails_Text12: 'Deductible ',
    txt_global_std_howmany: 'How many?',
    txt_global_std_countminutes: ' {count} minutes',
    txt_global_std_ximprovements: 'Improvements',
    txt_global_std_PreSaleDetails_Text46: 'The insurance covers damage to equipment or failures that occur suddenly and unexpectedly on some components of the car. Breakdown insurance is not valid for cars older than eight years or who have driven more than 10,000 Swedish miles. As soon as one of these limits have passed, the insurance expires. Breakdown insurance does not include damage caused by animals, damage to the wheel bearing and suspension system. Deductible 1500 SEK, over 6000 Swedish miles 3,000 SEK, additional deductible imported car 6000 SEK.',
    txt_global_std_OSolbergMusic: 'Coming soon...',
    txt_global_std_remainingpoints: 'Points restants',
    txt_global_std_cimage13h: ' LAISSEZ VOTRE VITESSE CHUTER',
    txt_global_std_norweigan: 'Norvégien',
    txt_global_std_reportwassent: 'Report was sent',
    txt_global_std_ifyouwantinfoonnotificationscallphonenumberorgotoweburlorgotothefaqpagelink: 'Si vous souhaitez obtenir des informations sur les notifications, appelez {phoneNumber} ou accédez à {webUrl}. Ou allez à la {faqPageLink}.',
    txt_global_std_startyourcarsengine: 'Démarrez le moteur de votre voiture',
    txt_global_std_p2pviewless: 'Voir moins',
    txt_global_std_cimage18h: ' VITESSE MOYENNE PLANIFIÉE',
    txt_global_std_startdrivingtoappearontheleaderboard: 'Commencez à conduire pour obtenir un score sur le classement!',
    txt_global_std_p2pselectvehicle: 'Sélectionnez un véhicule',
    txt_global_std_aonceyoupairtheapptoyourcehiclesbluethoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhatscoreyougetwhendriving: "Une fois que vous associez l'application au Bluetooth de votre véhicule et autorisez l'application à utiliser les services de localisation, nous faisons correspondre vos habitudes de conduite à la bibliothèque de modèles de conduite de notre IA et fournissons un score. Votre prime d'assurance est basée sur le score que vous obtenez lorsque vous conduisez.",
    txt_global_std_connecttocarunlockedkey: 'Bien fait! Vous êtes connecté à la voiture et êtes prêt à conduire. Fais attention!',
    txt_global_std_listview: 'Vue liste',
    txt_global_std_ifyourcarhasbluetoothbutdoesnotshowupintheappyoumightneedtocheckthebluetoothsettingsforbothyourphoneandyourcartryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagain: "Si votre voiture est équipée du Bluetooth, mais n'apparaît pas dans l'application, vous devrez peut-être vérifier les paramètres Bluetooth de votre téléphone et de votre voiture. Essayez de réinitialiser la connexion de votre voiture à votre téléphone en la supprimant, puis en l'ajoutant à nouveau.",
    txt_global_std_PolicySchedule2: 'Agence.',
    txt_global_std_invitenewmember: 'Inviter un nouveau membre',
    txt_global_std_topimprover: 'Meilleur améliorant',
    txt_global_std_advancedsettings: 'Réglages avancés',
    txt_global_std_inviteafriend: 'Inviter des amis',
    txt_global_std_excellentdriving: 'Excellente conduite!',
    txt_global_std_conversionkmmi: ' Conversion km/mi',
    txt_global_std_p2pborrowcar: 'Emprunter une voiture',
    txt_global_std_competitionrules: 'Règlement du concours',
    txt_global_std_smspackagefiasdcactivation: 'Bienvenue au FIA SDC! \n\n[LINK]\n\nCode d´activation: {activationcode} ',
    txt_global_std_challangeranking: 'Classement de votre challenge',
    txt_global_edj_totaldistance: 'Total (km)',
    txt_global_std_automessage_level_4__1: 'Driving score {score}! Good job!',
    txt_global_std_gdprterms: 'Termes du RGPD',
    txt_global_CoreTemplate_more: ' Plus',
    txt_global_std_riskmatcha: 'Match de risque {type}',
    txt_global_std_newpwdcheckinbox: "S'il vous plaît vérifier votre boîte de réception!",
    txt_global_std_towed: 'Remorqué',
    txt_global_std_averagecosavings: 'Économies moyennes de CO₂',
    txt_global_std_startguide: 'Guide de démarrage',
    txt_global_std_bysigningupyouaccepttheusertermsprivacypolicy: 'En vous inscrivant, vous acceptez les {userTerms} et {privacyPolicy}',
    txt_global_std_cimage34d: ' ',
    txt_global_std_p2pdonthaveatemporarypasswordregisterat: "Vous n'avez pas de mot de passe temporaire ?\n" +
      'Inscrivez-vous sur [Site Web Kinto]',
    txt_global_std_riskydecisionbetweendays: 'Décision risquée entre les jours',
    txt_global_std_exceededlimit: 'Limite dépassée',
    txt_global_std_accountstartdate: 'Date de début du compte',
    txt_global_std_exchangedpoints: 'Points échangés',
    txt_global_std_gooddriving: 'Good driving',
    txt_global_std_PreSaleDetails_Text30: 'Assistance',
    txt_global_std_specialservices: 'Special services',
    txt_global_std_challengetermsconditions: 'Termes et conditions du défi',
    txt_global_std_animalcollision123: 'Collision animale',
    txt_global_std_litre: ' Litre',
    txt_global_std_unabletosendchallenge: 'Unable to send challenge',
    txt_global_std_p2pyoureceivedaninvoicetosplitcost: 'Vous avez reçu une facture pour diviser les coûts.',
    txt_global_std_PolicySchedule13: 'VALEUR DU MARCHÉ AU MOMENT DE LA PERTE',
    txt_global_std_appcreator_upsell_pitch_bulletpoint2: 'Pilotez et développez votre offre digitale sans temps de développement coûteux',
    txt_global_std_timeline2: 'Chronologie',
    txt_global_std_buylater: 'Acheter plus tard',
    txt_global_std_p2pmakeseparatebookings: 'Faire des réservations séparées',
    txt_global_std_skillscomparison: ' Comparaison des compétences',
    txt_global_std_intraining: 'Dans la formation',
    txt_global_std_p2pfaq5q: 'Comment agrandir une image dans les messages?',
    txt_global_std_sdsmode: 'Sport mode',
    txt_global_std_alertdrivingtext: ' Vous êtes actuellement en train de conduire.',
    txt_global_std_sonicethatyoudecidedtojoinus: 'Si gentil que vous avez décidé de nous rejoindre!',
    txt_global_std_Recruit_Hello: 'Hej!',
    txt_global_std_VehicleOwnerPhone: 'Fordonägarens telefonnummer',
    txt_global_std_buyheatsnowtocompete: 'Achetez des manches maintenant pour participer!',
    txt_global_std_wecanretrievedataformultiplecompletedtripsinasinglerequesthoweverwerecommendnottosendrequestswithdataexceeding50mb: 'Nous pouvons récupérer les données de plusieurs trajets effectués en une seule requête. Cependant, nous vous recommandons de ne pas envoyer de demandes avec des données dépassant 50 Mo.',
    txt_global_std_doyouwanttojointeam: 'Voulez-vous rejoigner une équipe',
    txt_global_std_inviteafriendtostartachallenge: 'Invitez un ami à lancer un défi!',
    txt_global_std_driving: ' En direct',
    txt_global_std_allusers: 'Tous les utilisateurs',
    txt_global_std_address2: 'Address 2',
    txt_global_std_searchbyfilter: 'Rechercher par filtre',
    txt_global_std_youonlyhavedistancelefttotravelleft: 'Il ne vous reste plus que {distanceLeftToTravel}.',
    txt_global_std_ActivationLetter_Text3: 'You will find your insurance letter and pre-sale information in this email.',
    txt_global_std_PreSaleDetails_Text15: 'You have to take care of your car so that damage is, as much is possible, prevented. If you have not done this, your damage replacement is reduced or canceled.',
    txt_global_std_selectrecipients: 'Select recipients',
    txt_global_std_startadrivemanually: 'Start måling nu',
    txt_global_std_topdrivers: ' Meilleurs conducteurs',
    txt_global_std_whenyourfriendusesyourreferralcodetoactivateanewsidekickcarinsurancebothofyouget250krinthepiggybank: 'Lorsque votre ami utilise votre code de parrainage pour activer une nouvelle assurance automobile Sidekick, vous recevez tous les deux 250 kr dans la tirelire.',
    txt_global_std_warikan_onboarding2: "Merci d'avoir postulé pour utiliser l'application Warikan KINTO.",
    txt_global_std_achievementtool: 'Outil de réalisation',
    txt_global_std_CalledGarage: 'Har ni kontaktat verkstad',
    txt_global_std_NewCar_Text2: 'Husk at flytte din {appName}-dongle til din nye bil. Du skal gøre præcis, som du gjorde, da du tegnede forsikringen: Sæt donglen i bilen og aktiver Bluetooth på telefonen.',
    txt_global_std_addexpenses: 'Ajouter des dépenses',
    txt_global_std_communication: 'Communication',
    txt_global_std_idonthavebluetoothinmycar: "Je n'ai pas de Bluetooth dans ma voiture",
    txt_global_std_changeswillnotbesaved: 'Les modifications ne seront pas sauvegardés',
    txt_global_std_1findyourobdportinyourcar2inserttheobdreaderandstartyourcarwaitforthereadertoshowabluelight3turnonyourphonesbluetooth4oncetheapprecognisestheobdreaderyoullmoveautomaticallytothenextstep5selectyourobddevicefromthelistobdmini2: "1 : Trouvez votre port OBD dans votre voiture. 2 : Insérez le lecteur OBD et démarrez votre voiture. Attendez que le lecteur affiche une lumière bleue. 3 : Activez le Bluetooth de votre téléphone. 4 : Une fois que l'application reconnaît le lecteur OBD, vous passez automatiquement à l'étape suivante. 5 : Sélectionnez votre appareil OBD dans la liste : OBD Mini 2",
    txt_global_std_p2pwearegladthatyouwanttousewarikanasawaytoshareyourcar: 'Nous sommes heureux que vous souhaitiez utiliser Warikan pour partager votre voiture.',
    txt_global_std_removemember: 'Remove member',
    txt_global_std_welcomeback: 'Ravis de vous revoir !',
    txt_global_std_alerthighusagetext: 'Warning! High usage [amount] kr.',
    txt_global_std_wrongusernameorpassword: 'Nom d&#39;utilisateur ou mot de passe incorrect',
    txt_global_std_p2pborrowed: 'emprunté',
    txt_global_std_telephonecontact: 'Téléphone',
    txt_global_std_p2pemailnotpaidbookings: 'Réservations non payées',
    txt_global_std_enterodometervalue: 'Entrez la valeur du compteur kilométrique',
    txt_global_std_thatmeansyouarethemosttechnicaldriverinthewholecommunity: 'That means you are the most technical driver in the whole community.',
    txt_global_std_stopdrivingq: ' Mettre fin à la conduite ?',
    txt_global_std_drivelesssavemore: 'Drive less, save more',
    txt_global_std_pushnotdrivenforesometimetitle2: 'Tu nous manques notre ami!',
    txt_global_std_byinvitingfriendsyouwillearnloyaltypoints: 'En invitant des amis, vous gagnerez des points de fidélité',
    txt_global_std_100offdeductible: '100% de réduction sur la franchise',
    txt_global_std_latestupdate: 'Latest update',
    txt_global_std_areyousure: 'Êtes-vous sûr?',
    txt_global_std_p2pfaq9a: "Demandez à votre ami d'accéder au récapitulatif de la réservation et appuyez sur terminer la réservation. La réservation sera alors terminée et votre voiture sera à nouveau disponible pour le prêt.",
    txt_global_std_undertheusersectionextendittoupdateyouraccountdetailsorlogoutfromtheadminportal: "Sous la section utilisateur, développez-la pour mettre à jour les détails de votre compte ou vous déconnecter du portail d'administration.",
    txt_global_std_cimage17h: ' CONDUITE ACTIVE SUR L’AUTOROUTE',
    txt_global_std_Insurance_VAT: 'Value-added-tax ',
    txt_global_std_MSIG_InsuranceLetter33: 'Société Reg. N ° 195000106C',
    txt_global_std_p2pyouwillnotbeabletoseeorwriteanymessagesinthisgroupchat: 'Vous ne pourrez ni voir ni écrire de messages dans cette discussion de groupe.',
    txt_global_std_calculatethedates: 'Calculer les dates',
    txt_global_std_whywouldyou: 'Pourquoi voudrais-tu?',
    txt_global_std_workspace: 'Espace de travail',
    txt_global_std_yesterday: 'Hier',
    txt_global_std_smartdriverweekly: 'Chauffeur intelligent hebdomadaire',
    txt_global_std_BeforeBill_Text2Moderna: 'Har du fler frågor om din bilförsäkring? Du kan alltid nå oss på telefon 0200-265 265 eller besöka vår hemsida på www.modernasmart.se',
    txt_global_std_private: 'Private',
    txt_global_std_effectivedates: "Dates d'entrée en vigueur",
    txt_global_std_whatdoineedtodoinordertoconnectmycar: 'What do I need to do in order to connect my car?',
    txt_global_std_yourfriendsnickname: 'le nom d&#39;utilisateur de vos amis',
    txt_global_edj_periodend: 'Period end',
    txt_global_std_Adjust_Text12: 'Hvis du overskrider årlig kjørelengde vil du ikke få full erstatning ved skade. Dette ønsker vi å unngå. ',
    txt_global_std_NotCreatedAccount3_Text4Moderna: 'Do you need help with creating an account? Please contact us at: modernasmartflex@modernaforsakringar.se or 0200-265 265.',
    txt_global_std_p2pcarborrow: 'Auto',
    txt_global_std_tuneup: 'Mise au point',
    txt_global_std_findmycar2: 'Trouver ma voiture',
    txt_global_std_sdcscore: 'SDC Score:',
    txt_global_std_gamificationinspirestosmarterdrivingforlowerco2imprintandfewerroadaccidents: "La gamification incite à une conduite plus intelligente pour une empreinte CO2 moindre et moins d'accidents de la route",
    txt_global_std_turnonlocationservicesinyourphone: 'Activez les services de localisation (GPS) sur votre téléphone.',
    txt_global_std_unabletofindarandomuser: 'Unable to find a random user',
    txt_global_std_youhavebeenchallengedtoahead2headacceptitnow: 'Vous avez été défié en tête à tête. Acceptez-le maintenant.',
    txt_global_std_managetheimpactyourteamhasonroadsafetyandco2emissions: "Gérez l'impact de votre équipe sur la sécurité routière et les émissions de CO2",
    txt_global_std_addrecipients: 'Add recipients',
    txt_global_std_startguidetip1: 'Assurez-vous que le lecteur OBD est connecté à la prise OBD du véhicule.',
    txt_global_std_p2pconfirmedph: '{count}Confirmé',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscores: "R : Une fois que vous avez couplé l'application au Bluetooth de votre véhicule et autorisé l'application à utiliser les services de localisation, nous faisons correspondre vos habitudes de conduite à la bibliothèque de modèles de conducteur de notre IA et fournissons un score. Votre prime d'assurance est basée sur les conducteurs de votre véhicule et leur qualité de conduite. Quiconque conduit votre véhicule est encouragé à télécharger l'application et à suivre ses scores.",
    txt_global_std_statusheader: 'Statut',
    txt_global_std_newdrivingexperience: 'New driving experience',
    txt_global_std_25offdeductible: '25% de réduction sur la franchise',
    txt_global_std_validatedata: 'Valider les données',
    txt_global_std_newtoagent: 'Nouveau dans {agent} ?',
    txt_global_std_whydoyouwanttoterminateyourinsurance: 'Pourquoi voulez-vous mettre fin à votre assurance?',
    txt_global_std_user: 'Utilisateur',
    txt_global_std_ourinsurancesolutionisbaseduponafixedandvariablecost: 'Notre solution d&#39;assurance est basée sur un coût fixe et variable. Le coût fixe est basé sur le véhicule assuré et l&#39;adresse enregistrée du propriétaire du véhicule, et est facturé mensuellement à l&#39;avance, quelle que soit la fréquence et la manière dont le véhicule est utilisé. Le coût variable est basé sur la couverture d&#39;assurance, le kilométrage et votre comportement de conduite. Pour calculer et évaluer le coût de votre conduite, le dispositif OBD inclus (plug-in Enerfy) doit être installé dans votre véhicule - ou - si votre véhicule a la possibilité de connecter directement votre téléphone via Bluetooth - l&#39;application Enerfy doit courir dans votre smartphone pendant la conduite. Votre coût variable est ensuite calculé en fonction des niveaux de prime, c&#39;est-à-dire des niveaux de conduite de un (1) à (15) quinze, en fonction de votre comportement de conduite individuel, où le niveau de prime 1 est le niveau avec le coût par kilomètre le plus bas et le niveau de prime 15 est le plus haut. Le coût variable est cumulé pour chaque disque et facturé le mois suivant. Si vous n&#39;êtes pas connecté et que vos disques ne sont pas enregistrés, vous serez facturé au niveau premium 10.',
    txt_global_std_p2pyouneedtocreateabookingbeforeaddingaexpense: "Vous devez créer une réservation avant d'ajouter une dépense.",
    txt_global_std_trygonboarding1: 'Nous sommes vraiment ravis que vous ayez décidé de nous rejoindre! Continuons à préparer votre profil.',
    txt_global_std_ActivationLetter_No1: 'med',
    txt_global_std_p2pcostsplit: 'Répartition des coûts',
    txt_global_std_KristofferssonBesttip: 'Keep your eyes far ahead to anticipate traffic. This will give you more time to plan your driving.',
    txt_global_std_acceptconnectiontogetascore: 'Accepter la connexion pour obtenir un score',
    txt_global_std_Insurance_Period: 'Insurance Period',
    txt_global_std_p2ppasswordnotmatchedpleasetryagain: 'Le mot de passe ne correspond pas. Veuillez réessayer.',
    txt_global_std_woman: 'Femme',
    txt_global_std_onesofthebestsdrivers: "L'un des meilleurs pilotes !",
    txt_global_std_youhavedrivenatotalof: 'Vous avez conduit un total de',
    txt_global_std_anyonewiththelinkcangetinvited: '(toute personne disposant du lien peut être invitée)',
    txt_global_std_beforeyoubeginmakesure: 'Before you begin, make sure to set up the following',
    txt_global_std_signinwith2: ' Ouvrir une session avec',
    txt_global_std_pigsinvitedunlocked: 'Joli! Vous avez maintenant 250 couronnes de plus dans votre tirelire car vous avez recruté un ami',
    txt_global_std_contactinformation: ' Informations de contact',
    txt_global_std_RecieptMail_Text13: 'Contact our customer service department with eventual payment questions.',
    txt_global_std_HighMileage: 'Varsel: Overskrider kjørelengde',
    txt_global_std_TerminationMail_Text6: 'Do you have any objections or questions about this? Contact us at info@enerfy.se or 08-555 93 200.',
    txt_global_std_mpltiplvl7: 'Nous recommandons la formation des conducteurs avec une attention particulière sur la concentration et la planification du conducteur. Autrement dit, ne perdez jamais de vue le trafic et les conditions qui vous entourent. Nous sommes Yamato et nous nous concentrons et planifions toujours la conduite avec une attention particulière à la gentillesse, à la priorisation, à la maîtrise de soi émotionnelle et à la confiance',
    txt_global_std_Insurance_Reciept: 'RECIEPT',
    txt_global_std_failedtoedittranslation: 'Échec de la modification de la traduction !',
    txt_global_std_pigwonlocked: 'Rivalisez et gagnez contre un ami dans head2head pour recevoir ce prix',
    txt_global_EnerfyActivityDetailTemplate_comparetomy: ' Comparer avec :',
    txt_global_std_howitworks: 'Comment ça fonctionne',
    txt_global_std_letsdothis: 'Allons-y!',
    txt_global_std_yearlymilageused: 'Kilométrage annuel utilisé',
    txt_global_std_browsetemplates: 'Parcourir les modèles',
    txt_global_std_verygood: ' Très bon',
    txt_global_std_PaymentTrouble3_Text3: 'is not paid and your car insurance will terminate within 48 hours if not paid.',
    txt_global_std_kintofaq3q: 'Comment gagner un statut de fidélité?',
    txt_global_std_contactinfo: ' Infos de contact',
    txt_global_std_pleasenotethatwewilldetuct: 'Veuillez noter que nous déduirons 1 SEK de votre carte lors de la mise à jour des détails de votre carte. Le montant sera remboursé en totalité. Si vous avez des factures impayées, celles-ci seront payées peu de temps après la mise à jour. Assurez-vous également que votre carte est ouverte aux achats sur Internet.',
    txt_global_std_customizeyourapp: 'Personnalisez votre application',
    txt_global_std_toreportyourclaim: 'Pour signaler votre demande, suivez simplement les quelques étapes suivantes et votre demande sera traitée sous peu.',
    'txt_global_std_Share on Twitter': 'Share on Twitter',
    txt_global_std_p2ppressthedrivebuttontoconnecttoyourcarandstartatripyouwillgetfeedbackandinsights: 'Appuyez sur le bouton de conduite pour vous connecter à votre voiture et commencer un voyage. Vous obtiendrez des commentaires et des informations sur votre conduite.',
    txt_global_std_planetsaver: 'Économiseur de planète',
    txt_global_std_speeddecrease: 'Diminution de la vitesse',
    txt_global_std_CertificateofInsurance: 'Certificat d&#39;assurance',
    txt_global_std_byinvitingfriendsyouwillearnachievements: 'En invitant des amis, vous gagnerez des succès',
    txt_global_std_PaymentTrouble2_Text5: 'If we still can not make the payment within 14 days, your insurance will terminate, see attached document. After 14 days the TFF * will take over the case, and your car will no longer be insured with {appName}.',
    txt_global_std_motorclaimshotline: 'Motor Claims Hotline',
    txt_global_std_FirstDrive_Text10: 'What happens if I forget my phone when I am going to drive?',
    txt_global_std_friendstotheapp: 'amis de l&#39;application Enerfy Loyalty.',
    txt_global_std_dontworryaslongastheenerfyappisinstalledandyouareloggedin: "Don't worry, as long as the Enerfy app is installed and you are logged in, the app will connect automatically to your device.",
    txt_global_std_BillMail_Text2: 'SEK from your specified payment card for your insurance for',
    txt_global_std_connectnow: 'Se connecter maintenant',
    txt_global_std_emailwithremovallink: 'Succès! Un email a été envoyé avec un lien de suppression, veuillez vérifier votre boîte de réception!',
    txt_global_std_PreSaleDetails_Text4: 'It’s your choice, but we’re here for you if you need help.',
    txt_global_std_totaltranslatedwords: 'Total des mots traduits',
    txt_global_std_cimage24h: ' TROUVEZ LE RÉGIME MOTEUR OPTIMAL',
    txt_global_std_enterdescription: 'Enter description',
    txt_global_std_baseusers: 'Utilisateurs de base',
    'txt_global_std_Challenge to a Head to Head': 'Challenge to a Head to Head',
    txt_global_std_keyname: 'Nom de la clé',
    txt_global_std_collectpointsbyearningbadgeseverybadgeisworthadifferentamountofpointstapabadgetolearnmoreaboutit: 'Collectez des points en gagnant des badges. Chaque badge vaut un nombre différent de points. Appuyez sur un badge pour en savoir plus.',
    txt_global_std_selectcar: 'Sélectionnez une voiture',
    txt_global_std_pleasetryusinganotherusername: "S'il vous plaît essayez d'utiliser un autre nom d'utilisateur.",
    txt_global_std_whyterminateinsurance: 'Pourquoi mettre fin à {appName} Insurance?',
    txt_global_std_enjoyandgetvalueback: 'Enjoy and get value back',
    txt_global_std_NotCreatedAccount4_Text3: 'Do you need help with creating an account? Please contact us at: info@enerfy.se.',
    txt_global_std_getdiscountcode: 'Obtenir un code de réduction',
    txt_global_std_warikan_onboarding4_2: '◎お問い合わせはこちら\nKINTOカスタマーセンター',
    txt_global_std_confirmconnectionbetweenyourphoneandobdreader: 'Confirmez la connexion entre votre téléphone et le lecteur OBD.',
    txt_global_std_copytoclipboard: 'Copier dans le presse-papier',
    txt_global_std_p2pregisterkinto: 'Inscrire Kinto',
    txt_global_std_ActivationLetter_Text11: 'Sincerely,\r\n{appName}',
    txt_global_std_Renew_Hello: 'Hei!',
    txt_global_std_accountdetails: 'Détails du compte',
    txt_global_std_Insurance_Cost: 'Sum',
    txt_global_std_whyshouldanyonejoinyourteam: 'Pourquoi les participants devraient choisir votre équipe?',
    txt_global_std_pleaseenteravalidemailaddress: "S'il vous plaît, mettez une adresse email valide",
    txt_global_std_planningfeedbacktips: '<b> Regarder plus loin </b> (et pas seulement juste devant vous) se traduit généralement par une conduite plus douce et une plus grande chance de découvrir des obstacles à temps. <br> <br>\n' +
      '<b> Assurer un trajet agréable </b> pour vous et vos passagers.',
    txt_global_std_InsuranceMail_Text28: 'Machine insurance is applicable for cars that are no more than 8 years old, calculated from the first registered day or cars that have driven no more than 10 000 swedish miles. A lower deductible applies if the car has driven no more than 6,000 swedish miles.',
    txt_global_std_p2pifyouareakintoownerallyouneedtodoisregisteryourcarhereandyoullbereadytogo: "Si vous êtes propriétaire de Kinto, tout ce que vous avez à faire est d'enregistrer votre voiture ici et vous serez prêt à partir!",
    txt_global_std_p2punread: 'non lu',
    txt_global_std_competitions: ' Compétitions',
    txt_global_std_accountidentification: 'Identification du compte',
    txt_global_std_starttrip: ' Lancer le trajet',
    txt_global_std_youcanexpectthereadertoarrivewithin35businessdays: 'Vous pouvez vous attendre à ce que le lecteur arrive dans les 3 à 5 jours ouvrables.',
    txt_global_std_buttonurl: 'Link Url',
    txt_global_std_gotocompetitionlist: 'Go to competition list',
    txt_global_std_individualdrivers: 'Chauffeurs individuels',
    txt_global_std_confirmregistrationnumberoninsuredcar: 'Confirm registration number on insured car',
    txt_global_std_p2pyoudonthaveanytripsyet: "Vous n'avez pas encore de voyages.",
    txt_global_std_Insurance_Distance: 'Distance',
    txt_global_std_addanotherinsurance: 'Add another insurance',
    txt_global_std_tomczykDescription: 'Après une carrière dans le karting Tom a concouru dans la Formule BMW et la Formule 3. En 2001, il était le pilote le plus jeune de la DTM, devenue champion de cette compétition en 2011, 8 fois au podium de la DTM. En 2017 il a concouru dans l’IMSA pour se préparer pour le championnat d’endurance. Depuis 2018 il fait partie de la BMW M8 GTE dans la compétition du Championnat du monde d’endurance.',
    txt_global_std_bundlerevstart: 'Start date insurance',
    txt_global_std_kintoImprovement3: 'N`oubliez pas de consulter vos commentaires pour commencer à réaliser des économies!',
    txt_global_std_failedtoremove: 'Échec de la suppression',
    txt_global_std_youractivationcodes: "Votre code d'activation",
    txt_global_std_warikan_onboarding18: 'Mot de passe temporaire',
    txt_global_std_doyouwanttocontinue: ' Souhaitez-vous continuer ?',
    txt_global_std_PaymentTrouble1: 'Trouble with payment',
    txt_global_std_tomasengeinterests: 'Course en général, les enfants',
    txt_global_std_TaxInvoice12: 'Le paiement par un preneur d&#39;assurance à un agent ou à un courtier est réputé être un paiement à l&#39;assureur',
    txt_global_std_MSIG_InsuranceLetter8: 'Type de couverture.',
    txt_global_std_receiveinvoiceviaemail: 'Receieve invoice via email',
    txt_global_std_p2ppleaseselectacar: 'Veuillez sélectionner une voiture',
    txt_global_std_history: 'L&#39;histoire',
    txt_global_std_editpopup: 'Edit popup',
    txt_global_std_RenewMail: 'Informer om utbetaling av kjøreutbytte',
    txt_global_std_drivtimeover80: ' Temps de conduite au-dessus de {speed}',
    txt_global_std_whyyoushouldusefiasdcforyourbusiness: 'Pourquoi devriez-vous utiliser FIA SDC pour votre entreprise',
    txt_global_std_trackingofyourco2emissions: 'Suivi de vos émissions de CO2.',
    txt_global_std_sender: 'Expéditeur',
    txt_global_std_replaydrive: ' Rejouer le trajet',
    txt_global_std_parkingdamage: 'Parking Damage',
    txt_global_std_homeaddress: ' Adresse du domicile',
    txt_global_std_regno: 'Reg. No.',
    txt_global_std_teammemberbig: "MEMBRE DE L'ÉQUIPE\n",
    txt_global_std_p2pbookingpending: 'Réservation en attente',
    txt_global_std_p2pselectedfriend: 'Ami sélectionné',
    txt_global_std_appcreator_upsell_pitch_bulletpoint3: "Prenez une longueur d'avance dans la course aux solutions centrées sur le client et axées sur le numérique",
    txt_global_std_opensettingsinthephoneturnon: "Ouvrez les paramètres de votre téléphone portable, activez le Bluetooth et ouvrez l'application.",
    txt_global_std_return: 'Return',
    txt_global_std_automessage_level_13__1: 'Uh-oh!',
    txt_global_std_pleaseentercompanyname: "Veuillez saisir le nom de l'entreprise",
    txt_global_std_therearenoaccountscreatedundertheagreement: "Aucun compte n'a été créé dans le cadre de l'accord.",
    txt_global_std_readmore: 'Read more',
    txt_global_std_p2pfaq14a: "Nous vous recommandons d'informer le propriétaire de la voiture dans les messages de chat si vous savez que vous allez être en retard.",
    txt_global_std_allow: 'Permettre',
    txt_global_std_vehicleapi: 'API de véhicule',
    txt_global_std_yourmaxspeed: ' Votre vitesse max',
    txt_global_std_p2pemailforcommunication: 'Courriel de communication',
    txt_global_std_member: 'Member',
    txt_global_std_inviteresponse: 'Invite response',
    txt_global_std_loyaltystatus: 'Statut de fidélité',
    txt_global_std_gps: ' GPS',
    txt_global_std_mpltipdev7pp: "Ceci est un conducteur qualifié mais indique une incohérence de conduite. Pendant plusieurs jours ou parfois pendant des semaines, la Cool Driving est pratiquée. Mais alors seulement pour une journée, Cool Driving est ignoré. Il y a quelque chose qui pousse le conducteur à changer d'attitude envers la conduite et il nous est difficile de déterminer pourquoi. Nous recommandons donc une brève discussion avec le conducteur et lui demandons son avis sur la raison pour laquelle cela se produit.",
    txt_global_std_newmessage: 'New message',
    txt_global_std_chatwithus: 'Chat with us',
    txt_global_std_youmustpickadate: 'Vous devez choisir une date',
    txt_global_std_riskindex: 'Indice de risque',
    txt_global_std_settingssmall: 'réglages',
    txt_global_std_ended: 'Terminé',
    txt_global_std_PaymentTrouble2_Text6Moderna: 'If you have already paid your premium, you may disregard this message. Do you have questions or need assistance with the payment? You are welcome to contact our customer service at: modernasmartflex@modernaforsakringar.se or 0200-265 265.',
    txt_global_std_nickname: ' Pseudonyme',
    txt_global_std_getoverviewtrips: ' Aperçu de l’ensemble des trajets',
    txt_global_std_reportwasnotsent: 'Report was not sent',
    txt_global_std_cimage23h: ' RESTEZ EN ROUE LIBRE SUR QUELQUES MÈTRES SUPPLÉMENTAIRES',
    txt_global_std_itsalittleemptyinhere: "C'est un peu vide ici",
    txt_global_std_ifbuttondontworktrylink: 'Si le bouton ci-dessus ne fonctionne pas, collez ce lien dans votre navigateur Web :',
    txt_global_std_buttonurlempty: 'Button url is empty',
    txt_global_std_teamname1: "Nom de l'équipe",
    txt_global_std_invitetosidekicktitle: 'Invite to Sidekick &\r\nget awarded',
    txt_global_std_dontstress: 'Ne stresse pas',
    txt_global_std_areyousureyouwanttoredeempointstotradepoints: 'Voulez-vous vraiment échanger {pointsToTrade} points ?',
    txt_global_std_ConfirmFia_Text6: ' Place de la Concorde, 75008 Paris, France',
    txt_global_std_addyourvehicleidentificationtobeabltoenjoyallthebenefitsandclaimyourrewards: 'Ajoutez votre identification de véhicule pour pouvoir profiter de tous les avantages et réclamer vos récompenses!',
    txt_global_std_takeiteasyandexploretheappwhileyouwaitforyourfriendstoaccept: 'Take it easy and explore the app while you wait for your friends to accept.',
    txt_global_std_Invite_Text4: 'Just download the app "{appName}" in your smartphone.',
    txt_global_std_currentecoleague: 'Current ECO league',
    txt_global_std_yourfriendslistlooksabitemptyusethebuttonbelowtostartinvitingyourfriends: "Votre liste d'amis semble un peu vide. Utilisez le bouton ci-dessous pour commencer à inviter vos amis.",
    txt_global_std_MSIG_InsuranceLetter27: '* Les limitations rendues inopérantes par l&#39;article 8 de la loi sur les véhicules à moteur (risques liés aux tiers et indemnisation) (chapitre 189) et l&#39;article 95 de la loi sur les transports routiers de 1987 (Malaisie) ne doivent pas être incluses dans ces positions.',
    txt_global_std_addfriends: 'Ajouter des amis',
    txt_global_std_usertoreport: 'User to report',
    txt_global_std_addapurpose: 'Add a purpose!',
    txt_global_std_checkifcorrectobdreaderisconnected: 'Vérifiez si le lecteur OBD correct est connecté.',
    txt_global_std_placethewarningtriangleatleast100metersfromthecar: 'Placer le triangle de présignalisation à au moins 100 mètres de la voiture',
    txt_global_std_cimage28h: ' IL N’EST PAS JUDICIEUX D’UTILISER LE RÉGULATEUR DE VITESSE',
    txt_global_std_NotCreatedAccount3_Text4: 'Do you need help with creating an account? Please contact us at: info@enerfy.se.',
    txt_global_std_wall: 'Wall',
    txt_global_std_somethingiswrong: 'Quelque chose ne va pas',
    txt_global_std_p2pfaq32q: "\nS'il vous plaît dites-moi comment diviser le coût.",
    txt_global_std_accessdeniedyoudonothavepermissiontoaccesstheworkspacepleasecontactyouradministratorifincorrect: "Accès refusé. Vous n'êtes pas autorisé à accéder à l'espace de travail. Veuillez contacter votre administrateur en cas d'erreur.",
    txt_global_std_images: 'Images',
    txt_global_std_Insurance_City: 'City',
    txt_global_std_ChicheritQuote: 'Coming soon...',
    txt_global_std_insuranceactivesince: 'Assurance active depuis',
    txt_global_std_MSIG_InsuranceLetter4: 'Règles de 1960 concernant les véhicules à moteur (risques de tiers et indemnisation)',
    txt_global_std_NotCreatedAccount4_Text1: "We can't see that you have verified your insurance by entering your verification code in the {appName} app. For everything to work properly you need to verify your insurance in the {appName} app with your personal verification code: ",
    txt_global_std_enginescanner: 'Scanner de moteur',
    txt_global_std_InsuranceChangeModerna_Text2: 'Hälsningar,\r\n{appName}',
    txt_global_std_Reciept_Text2: 'Thank you for choosing Enerfy - Insurance with Moderna.',
    txt_global_std_exportto: 'Export to',
    txt_global_std_bestscore: 'Meilleur score',
    txt_global_std_howitworks1: 'Comment ça fonctionne',
    txt_global_std_p2pcleaning: 'Nettoyage',
    txt_global_std_MSIG_InsuranceLetter37: '36 Robinson Road # 16-01 City House Singapore 068877 Tél.: (65) 6507 3848 Télécopieur: (65) 6507 3849',
    txt_global_std_heavydamage: 'De gros dégâts ?',
    txt_global_std_PreSaleDetails_Text8: 'Who is the insurance for? ',
    txt_global_std_KristofferssonFacts: 'Johan a débuté sa carrière dans la Super séries International avec l’équipe de Suède et a poursuivi avec le championnat de Touring Car (STCC) où il en sort vainqueur dans sa première saison, en 2002. Il continue sa poursuite en passant au Rallye-cross d’Europe la sauison suivant et la saison d’après dans le rallye-cross du monde (WRX) où il emporte 5 rallyes et termine sa saison en troisième place du classement, en 2015. Il termine à la deuxième place l’année suivant derrière son compatriote, Ekström, et en sort vainqueur en 2017.',
    txt_global_std_youwilllosedrivedata: 'You will lose all data, including drives, if you login with another user.',
    txt_global_std_typeoftraining: 'Type de formation',
    txt_global_std_MSIG_InsuranceLetter18: 'Conducteur autorisé *',
    txt_global_std_setupcompanychallenge_fia_text: 'Rejoignez votre équipe dans le FIA SDC et restez actif, intelligent et durable lorsque vous voyagez en voiture.',
    txt_global_std_selectyourcar: 'Sélectionnez votre voiture',
    txt_global_std_tripsandstatistics: 'Trips & statistics',
    txt_global_std_youarearockstardriver: 'Votre conduite a été excellente lors de ce voyage! Nous n&#39;avons aucun autre conseil à vous donner pour le moment. Tu es une rockstar!',
    txt_global_std_trygroadsideassistance: 'Tryg assistance routière',
    txt_global_std_MSIG_Password1: 'Bonjour!',
    txt_global_std_openperiod: 'Open period',
    txt_global_std_unknown: 'Inconnu',
    txt_global_std_Insurance_EcoSafeDisc: 'Includes EcoSafe-discount with',
    txt_global_std_youhaveupdatedyourdetails: 'Vous avez mis à jour vos coordonnées',
    txt_global_std_cimage31d: ' ',
    txt_global_std_promocode: 'Code promo',
    txt_global_std_youvefinishedyourdrive: 'Votre trajet est terminé!',
    txt_global_std_DrivingScore_Text3: 'Vi ønsker å hjelpe deg til å bli en enda bedre sjåfør. I appen kan du gå inn på kjørescore for å få tips til hva du kan gjøre for å forbedre deg.',
    txt_global_std_learnmore: 'Apprendre encore plus',
    txt_global_std_NotCreatedAccount3_Text5: 'Sincerely,\r\n{appName}',
    txt_global_std_amazingdriverlockedkey: 'Terminez un trajet avec un score de {score} ou mieux pour déverrouiller ce badge. Vous pouvez gagner ce badge une fois par jour.',
    txt_global_std_NotCreatedAccount1_Text1: "You haven't created an {appName} account for your car insurance. We understand if you haven't had time to do that yet, but you need to create an account for your car insurance to work properly. You create an account by following these steps:",
    txt_global_std_automessage_level_3__2: 'Wow! Such driving. We like!',
    txt_global_std_reportaccidentheadtext: 'Si vous êtes impliqué dans un accident, veuillez le signaler dès que possible ci-dessous, cela ne prend pas plus de 10 secondes.',
    txt_global_std_expired: 'Expiré',
    txt_global_std_wheredidyoudrive: 'Where did you drive?',
    txt_global_std_locationreasontext: 'Des services de localisation sont nécessaires pour déterminer votre score de conduite.',
    txt_global_std_searchbygroupname: 'Recherche par groupe, nom',
    txt_global_std_MarklundChamp: '2x champion d’Europe du Rallye-Cross',
    txt_global_std_insnum: "Numéro d'assurance",
    txt_global_std_itseasytomonitoryouresgtargets: 'Il est facile de surveiller vos objectifs ESG',
    txt_global_std_kintorecruit5userslocked: 'Débloquez ce succès et gagnez 10 000 ¥ en recrutant 5 utilisateurs Kinto.',
    txt_global_std_unplugtheobdreaderandplugitbackagain: "Débranchez le Plug-in et rebranchez-le à nouveau. Attendez jusqu'à ce que le clignotant bleu s'alume.",
    txt_global_std_activeparticipantsfor: 'Participants actifs pour',
    txt_global_std_SignupFia_Text5: ' Téléchargez l’application FIA SDC pour obtenir un aperçu de ce qui vous attend !',
    txt_global_std_restrictions: 'Restrictions',
    txt_global_std_stayfocused: 'Reste concentrée',
    txt_global_std_TomczykFacts: 'Après une carrière dans le karting Tom a concouru dans la Formule BMW et la Formule 3. En 2001, il était le pilote le plus jeune de la DTM, devenue champion de cette compétition en 2011, 8 fois au podium de la DTM. En 2017 il a concouru dans l’IMSA pour se préparer pour le championnat d’endurance. Depuis 2018 il fait partie de la BMW M8 GTE dans la compétition du Championnat du monde d’endurance.',
    txt_global_std_Insurance_Extended: 'Automatically renewed each month',
    txt_global_std_fileinput: 'Entrée de fichier',
    txt_global_std_theapikeydoesnthavepermissionstoperformtherequest: "La clé d'API n'a pas les autorisations nécessaires pour exécuter la requête.",
    txt_global_std_latesttrip: 'Dernier voyage',
    txt_global_std_challengeyourselfensfriends: 'Challenge yourself & your smartest friends.',
    txt_global_std_areyousureyouwanttodeletethegroupdeletingthegroupdoesnotdeletetheusersaccounts: 'Voulez-vous vraiment supprimer le groupe ?\n' +
      '\n' +
      'La suppression du groupe ne supprime pas les comptes des utilisateurs.',
    txt_global_std_theuserwillbeaddedtoyouragreementaccordingtoyourpriceplan: "L'utilisateur sera ajouté à votre contrat en fonction de votre plan tarifaire.",
    txt_global_std_rwmselectfriendtext: "Sélectionnez l'ami/les amis qui ont roulé avec vous",
    txt_global_std_timespan: 'Time span',
    txt_global_std_welcomeresendemail: "Bienvenue sur votre nouveau compte ! Nous vous avons envoyé un e-mail pour vérifier votre nouveau compte. Pensez à vérifier votre dossier spam. Vous n'avez pas reçu d'e-mail ?",
    txt_global_CoreTemplate_insurance: 'Insurance',
    txt_global_std_automaticstartandstoprequiresaccesstobluetoothlocationservicesgpsandmotionfitness: "Le démarrage et l'arrêt automatiques nécessitent l'accès à Bluetooth, aux services de localisation (GPS) et à Motion & Fitness.",
    txt_global_std_riskportfoliotracker_upsell_pitch: "Connectez facilement votre livre entier pour une vue unique sur le risque réel et l'impact CO2 pour :",
    txt_global_std_inviteall: 'Invite tout le monde',
    txt_global_std_enterdriverriskweight: 'Saisir la pondération du risque du conducteur',
    txt_global_std_warikan_onboardingemailsubject: 'KINTO Co., Ltd. | 4-8-18 Meieki, Nakamura-ku, ville de Nagoya Bâtiment Nagoya Mitsui Bâtiment nord 14F',
    txt_global_std_couldnotsavechanges: 'La sauvegarde de vos modifications a échoué',
    txt_global_std_youraveragelevelispoints: 'Votre niveau moyen est de {points}',
    txt_global_std_connectivity: 'Connectivité',
    txt_global_std_DrivingScore_Text2: 'I Tryg Forsikring har vi andre bilforsikringer som kan passe deg bedre. Disse kan du få en oversikt over ved å trykke på denne linken:',
    txt_global_std_avgrisklevel: 'Moy. Niveau de risque',
    txt_global_std_youneedtodrivemorethankmkmduringaheatweekinordertogetascoreintheheatleaderboard: "Vous devez parcourir plus de {km} km au cours d'une manche (semaine) pour obtenir un score dans le classement de la manche.",
    txt_global_std_driveraverage: 'Moyenne des conducteurs',
    txt_global_std_challengeleaderboard_upsell_pitch: 'Organisez un défi pour les équipes, les divisions, les clients, les partenaires et les autres invités afin de :',
    txt_global_std_noofusers: "Nombre d'utilisateurs",
    txt_global_std_yourunsavedchangeswillbelostsavechangesbeforeclosing: 'Vos modifications non enregistrées seront perdues. Enregistrer les modifications avant de fermer ?',
    txt_global_std_emailuniqueinvitations: 'Envoyer des invitations uniques par e-mail',
    txt_global_std_FireDiscoveredWho: 'Vem upptäckte brandskadan',
    txt_global_std_youremailalreadyhasanenerfyaccount: 'Your email already has an account',
    txt_global_std_TaxInvoice1: 'FACTURE FISCALE',
    'txt_global_std_Turn on location access': 'Turn on location access',
    txt_global_std_starburstnamekey: 'Starburst!',
    txt_global_std_PreSaleDetails_Text19: 'Below is an overview of some - but not all - of the most common claims events that the insurance scope applies to.',
    txt_global_std_p2paskakintoownertoinviteyouandyouwillgetaccesstoallthecoolfunctionsinthisapp: 'Demandez à un propriétaire de Kinto de vous inviter et vous aurez accès à toutes les fonctions intéressantes de cette application.',
    txt_global_std_doyoudareacceptthechallenge: 'Do you dare accept the challenge?',
    txt_global_std_continue123: 'Continuer',
    txt_global_std_p2pcoolandfunstatsaboutyourfriendsbookingofyourcar: 'Des statistiques sympas et amusantes sur la réservation de votre voiture par votre ami.',
    txt_global_std_ridewithmekey: 'Monte avec moi',
    txt_global_std_joinrandom: 'rejoindre au hasard',
    txt_global_std_bussslaget: 'Busssssslaget',
    txt_global_std_couldnotcalculatespeedimprovementpossibility: 'Impossible de calculer la possibilité d&#39;amélioration de la vitesse!',
    txt_global_std_trygdemo: 'Passer et démarrer avec Tryg Drive',
    txt_global_std_from2: 'de',
    txt_global_std_driveminimum1kmduringaheatinordertoqualifyforthechallenge: "• Roulez minimum 1 km lors d'une manche afin de vous qualifier pour le challenge.",
    txt_global_std_invitedmembers: 'Membres invités',
    txt_global_std_p2ppushtitlelendingcomingup: 'Prêt à venir',
    txt_global_std_communication_upsell_bulletpoint2: 'Connectez-vous avec les utilisateurs sur leurs canaux préférés',
    txt_global_std_NotCreatedAccount1_Text7: 'Sincerely,\r\n{appName}',
    txt_global_std_CounterPart: 'Motparten',
    txt_global_std_startlendingyourcar: 'Commencez à prêter votre voiture !',
    txt_global_std_seehowmuchco2savingsyougetwhenyoudrivewell: "Voyez combien d'économies de co2 vous obtenez lorsque vous conduisez bien",
    txt_global_std_trip: ' Trajet',
    txt_global_std_opensettingsinyourphonegotoprivacy: 'Accédez aux paramètres de votre téléphone portable. Allez sur la page "Privé". Ouvrez le service de localisation et activez FIA SMART.',
    txt_global_std_warikan_onboarding17: 'adresse mail',
    txt_global_std_sendmyposition: ' Envoyer ma position',
    txt_global_std_buymorekilometers: 'Buy more kilometers',
    txt_global_std_PreSaleDetails_Text55: 'Supplementary insurance',
    txt_global_std_PreSaleDetails_Text48: 'The insurance covers emergency assistance in case of theft, accident or other breakdown (besides fuel shortage or puncture). Deductible 1500 SEK.',
    txt_global_std_letsstartwiththebasics: 'Commençons par les bases',
    txt_global_std_getplaceandtime: 'Get place and time',
    txt_global_std_nofiltersyet: 'Pas encore de filtres',
    txt_global_std_BeforeBill_Hello: 'Hej!',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint2: 'Félicitez les conducteurs pour avoir atteint leurs jalons/objectifs',
    txt_global_std_warikan_onboarding15: 'Formulaire de demande :\n',
    txt_global_std_trysidekick: 'Essayez Sidekick',
    txt_global_std_carsagreements: 'Voitures & accords',
    txt_global_std_thepasswordminimumlengthis8charactersandmustinclude1lowercaseuppercasenumeralandspecialcharacter: 'La longueur minimale du mot de passe est de 8 caractères et doit inclure 1 minuscule, une majuscule, un chiffre et un caractère spécial',
    txt_global_std_TermConfirmPlugin_Text1: 'We hereby confirm that your car insurance for',
    txt_global_std_NotCreatedAccount2_Text1: "You still haven't created an {appName} account for your car insurance. We want to make sure that everything has gone well for you. Your insurance have now transferred to a day premium that you pay for every day as your starting-period have passed. Create your {appName} account now to take advantage of all the benefits {appName} offers.",
    txt_global_std_SignupFia_Text4: ' Êtes-vous prêt à relever le défi ?',
    txt_global_std_confirmationmail: ' Vous êtes désormais inscrit !',
    txt_global_std_invoiceoverview: ' Coût',
    txt_global_std_welcometoappname: 'Bienvenue sur {appName} !',
    txt_global_std_whenwillmyratechange: 'Quand mon tarif changera-t-il ?',
    txt_global_std_gpserror: ' Erreur GPS',
    txt_global_std_qhowdoifileaclaim: 'Q : Comment déposer une réclamation ?',
    txt_global_std_analyzingtheresults: 'Résultats',
    txt_global_std_exportexcel: ' Exporter au format XLS',
    txt_global_std_toturnonbluetoothinyourphone: 'Pour activer Bluetooth sur votre téléphone, vous pouvez accéder à vos paramètres et l&#39;activer à partir de là. Vous pouvez également faire glisser votre doigt vers le bas pour activer Bluetooth à partir de votre centre de contrôle.',
    txt_global_std_idrivetomuch: 'I drive to much',
    txt_global_std_manageadvancedusersettingsandsupporthereusethequickaccessbuttonsbelowtoeasilynavigatebetweenuserscarsagreementsandfollowupandhelpoutwiththeironboarding: "Gérez les paramètres utilisateur avancés et le support ici. Utilisez les boutons d'accès rapide ci-dessous pour naviguer facilement entre les utilisateurs, les voitures et les accords et suivre et aider à leur intégration.",
    txt_global_std_target: 'Objectif',
    txt_global_std_inordertoreceiveadrivingdividend75ofthedistancetraveledmustbecompletedwiththeappandchiptenfoldremembertohaveboththeappandbluetoothonwhenyoudriveandthetripwillberegistered: "Afin de recevoir un dividende de conduite, 75 % de la distance parcourue doit être parcourue avec l'application et la puce dix fois. N'oubliez pas d'avoir à la fois l'application et le Bluetooth activés lorsque vous conduisez, et le trajet sera enregistré.",
    txt_global_std_youcanalwaysgobacktothisvideoguidebyclickingtheinthetopnavigationbar: 'Vous pouvez toujours revenir à ce guide vidéo en cliquant sur le ? dans la barre de navigation supérieure.',
    txt_global_std_wowgoodprogress: 'Wow, bonne progression !',
    txt_global_std_myinsurance2: 'Mon assurance',
    txt_global_std_adjustyourannualmileage: 'Ajustez votre kilométrage annuel',
    txt_global_std_changelater: 'Changer plus tard',
    txt_global_std_p2pcalendar: 'Calendrier',
    txt_global_std_publicanyonewiththeappcanparticipate: "Public (toute personne disposant de l'application peut participer)",
    txt_global_std_key: 'Clé',
    txt_global_std_wannaplayagame: 'Wanna play a game?',
    txt_global_std_countryofresidence: 'Pays de résidence',
    txt_global_std_overviewoftheonboarding: "Aperçu de l'intégration",
    txt_global_std_analyzingyourdrive1: 'Analyser votre lecteur',
    txt_global_std_xtool_where: 'Global',
    txt_global_std_TheftOfCar: 'Stöld av fordon',
    txt_global_std_wrongcurrentpassword: 'Mot de passe actuel incorrect, veuillez réessayer.',
    txt_global_std_WelcomeLetter_Text1Moderna: 'Thank you for choosing Moderna Smart Flex. Our customers mean everything to us so we are very excited to have you a customer.',
    txt_global_std_competitioncreated: 'Competition created',
    txt_global_std_p2penterthedigitcodeorclickonthelinksentto: 'Saisissez le code à 6 chiffres ou cliquez sur le lien envoyé à :',
    txt_global_std_messageheader: 'Entête de message',
    txt_global_std_wewilldeducttheinvoicepaymentfromthe: 'We will deduct the invoice payment from you the 1st day each month for the fixed premium in advance and the variable premium for the past month. The deduction will be made on the debit or credit card you used when purchasing the insurance.',
    txt_global_std_unlockbenefitsandrewards: 'Débloquez des avantages et des récompenses',
    txt_global_std_new: 'Nouveau',
    txt_global_std_activationcodeisnotvalid: ' Le code d’activation n’est pas valide.',
    txt_global_std_createcompetition: 'Create Competition',
    txt_global_std_fueltype: ' Type de carburant',
    txt_global_std_saveandclose: 'Sauver et fermer',
    txt_global_std_ifyoudonthaveanygroupsyetyoucancreatetheminthegroupsmodule: "Si vous n'avez pas encore de groupes, vous pouvez en créer dans le module Groupes.",
    txt_global_std_p2ppushtitlenewmessage: 'Nouveau message de {name}',
    txt_global_std_NotCreatedAccount1_Text8: 'Hvis du har spørgsmål til dine forsikringer, er du velkommen til at ringe på 70 11 20 20 eller skrive til os på trygdrive@tryg.dk.',
    txt_global_std_keepasafedistance: 'Gardez une distance de sécurité',
    txt_global_std_email2: 'E-mail',
    txt_global_std_update: 'Mise à jour',
    txt_global_std_p2phasconfirmedbooking: ' a confirmé la réservation',
    txt_global_std_ifyoudidntmakerequestignore: "Si vous n'avez pas fait cette demande, vous pouvez ignorer cet e-mail en toute sécurité.",
    txt_global_std_levellevel: ' Niveau {level}',
    txt_global_std_FirstDrive_Text6: 'You can improve your variable driving cost by:',
    txt_global_std_howcaniimprovemyscore: 'Comment puis-je améliorer mon score ?',
    txt_global_std_pressondevicebelowtoaddit: 'Voulez-vous ajouter un nouvel appareil?',
    txt_global_std_Verifying: 'Verifying',
    txt_global_std_yousignedupforournewsletter: 'Vous vous êtes inscrit à notre newsletter!',
    txt_global_std_cwelcome11: 'Have a nice evening',
    txt_global_std_wereanyothercarsinvolved: 'Y avait-il d&#39;autres voitures impliquées?',
    txt_global_std_p2pfaq26q: "S'il vous plaît dites-moi comment partager une voiture avec des amis.",
    txt_global_std_VerStep_Text3: 'Du kommer att tas vidare till en sida där du anger dina kortuppgifter och klickar på ”Betala”. När köpet är genomfört har du tecknat din bilförsäkring!',
    txt_global_std_invalidoldpasswordornewpassword: ' Ancien ou nouveau mot de passe invalide',
    txt_global_std_ecodriving: ' Éco-conduite',
    txt_global_std_general: ' Général',
    txt_global_std_PaddonInterests: 'Course automobile, Golf, VTT',
    txt_global_std_thisisneededtogiveyouestimatedsavings: 'Cela est nécessaire pour vous donner une estimation des économies.',
    txt_global_std_p2pshowyourreferralcode: 'Montrez le code de parrainage à votre ami',
    txt_global_std_platenumberornameofcar: 'Numéro de plaque ou nom de la voiture',
    txt_global_std_PreSaleDetails_Text6: 'When is the insurance valid?',
    'txt_global_std_Enerfy will attempt to automatically pair connect with your OBD reader. This process may take up to a minute. If the connection is unsuccessful, you can try manually pair connecting with the reader in your phone settings menu.': '{appName} will attempt to automatically pair connect with your OBD reader. This process may take up to a minute. If the connection is unsuccessful, you can try manually pair connecting with the reader in your phone settings menu.',
    txt_global_std_activationcodeisrequired: 'Le code d`activation est requis.',
    txt_global_std_motionfitnesswillhelpyoustartandstopdrivesautomaticallysothatyoucanjustfocusonyourdriving: 'Motion & Fitness vous aidera à démarrer et à arrêter les lecteurs automatiquement afin que vous puissiez vous concentrer uniquement sur votre conduite.',
    txt_global_std_TerminationMail_Text2: "According to Swedish law, all registered vehicles must carry third party insurance. We have therefore today transferred this claim to the Motor Insurers' Association which is now responsible for your case.",
    txt_global_std_timet: 'H',
    txt_global_std_savechanges2: 'Sauvegarder les modifications?',
    txt_global_std_connectwithyourfriends: 'Connect with your friends!',
    txt_global_std_p2pfinishandpayquestion: 'Terminer et payer?',
    txt_global_std_reactivateinsurance: "Réactiver l'assurance",
    txt_global_std_p2pgoheretofindcostsplitfunctioncheckyourdrivingstatsvisityourfriendsprofileandmore: 'Allez ici pour trouver la fonction de partage des coûts, vérifiez vos statistiques de conduite, visitez le profil de vos amis et plus encore.',
    txt_global_std_firedamage123: 'Dégâts du feu',
    txt_global_std_firstwithscore: 'Soyons le premier avec le score dans la chaleur',
    txt_global_std_p2pconfirmedthebooking: 'confirmé la réservation.',
    txt_global_std_TermConfirmPlugin_Hello: 'Hello,',
    txt_global_std_numberofparticipants: 'Nombre de participants',
    txt_global_std_HansenShortDescription: 'EURO RX Champion 2016, RX Lite Cup 2015, Champion du Monde ROK Junior 2010',
    txt_global_std_nextstatus: 'Statut suivant',
    txt_global_std_somethingwentwrong: 'Quelque chose a mal tourné',
    txt_global_std_thatsokwhenyouarereadytodriveyoucanconnecthere: 'C&#39;est bon! Lorsque vous êtes prêt à conduire, vous pouvez vous connecter à votre voiture ici.',
    txt_global_std_viewalltrips: 'Voir tous les trajets',
    txt_global_std_TerminationMail_Text6Moderna: 'Do you have any objections or questions about this? Contact us at modernasmartflex@modernaforsakringar.se or 0200-265 265.',
    txt_global_std_fiasdcecoscore: 'Score FIA SDC Eco',
    txt_global_std_nothanks2: 'Non merci',
    txt_global_std_onlylettersnumbersandperiodsareallowed: 'Seuls les lettres (a-z), les chiffres (0-9) et les points (.) sont autorisés.',
    txt_global_std_15discountonrenewal: '15% de réduction sur le renouvellement',
    txt_global_std_yourcarwillstillbeinsuredbutyourpremium: 'Your car will still be insured, but your premium cost might increase due to us billing you at premium level 10 for non connected drives.',
    txt_global_std_howdoisignupfortheinsurance: "Comment souscrire à l'assurance ?",
    txt_global_std_youralreadyuseddevices: 'Vos appareils déjà utilisés',
    txt_global_std_navigatetomycar: 'Navigate to my car',
    txt_global_std_borrowacar: 'Emprunter une voiture',
    txt_global_std_create: ' Créer',
    txt_global_std_totalsavings: 'économies totales',
    txt_global_std_IfPersonInjuries: 'Finns det några personskador',
    txt_global_std_ActivationLetter_Text2Moderna: 'Du kan alltid nå oss på telefon 0200-265 265, besök gärna www.modernasmart.se för att få mer information och fullständiga villkor.',
    txt_global_std_anticipation: 'Anticipation',
    txt_global_std_allday: ' Toute la journée',
    txt_global_std_noeventtoday: 'Aucun événement aujourd&#39;hui',
    txt_global_std_p2paddexpense: 'Ajouter une dépense',
    txt_global_std_signingupforinsurance: 'Souscrire à une assurance',
    txt_global_std_MSIG_InsuranceLetter9: 'Numéro de certificat',
    txt_global_std_ridewithmelockedkey: 'Un ami a-t-il roulé avec vous ? Enregistrez un trajet en covoiturage pour obtenir ce badge. Vous pouvez trouver cette fonction dans le menu avec les trois points. Vous pouvez gagner ce badge une fois par semaine.',
    txt_global_std_accruedlevel: 'Niveau cumulé =',
    txt_global_std_joinenerfy: 'Join {appName}',
    txt_global_std_herunderkandusefleredetaljeromdennetur: 'Herunder kan du se flere detaljer om denne tur.',
    txt_global_std_NewCar_Text4: 'Hvis du har problemer med app eller dongle kan du kontakte os på 44 20 50 05. ',
    txt_global_std_402requestfailed: '402 - Échec de la demande',
    txt_global_std_invitefriend: 'Inviter des amis',
    txt_global_std_almosttherewewillnowbuildyourdemoapp: 'Presque là. Nous allons maintenant créer votre application de démonstration.',
    txt_global_std_connectedbluetoothid: 'Identifiant Bluetooth connecté',
    txt_global_std_nosampledata: 'Aucunes données',
    txt_global_std_nicknamesmayonlyincludeletters: ' Les pseudonymes ne peuvent inclure que des lettres,',
    txt_global_std_appcreatoroverview: "Présentation du créateur d'applications",
    txt_global_std_75offdeductible: '75% de rabais sur la franchise',
    txt_global_std_teampage: 'Team page',
    txt_global_std_whendrivingincityconditionsavoidchasingthenextcrossingortrafficlightbygainingspeedquicklythenslowingquicklythishurtstraveltimeandfueleconomyandincreasescrashrisklookingwellaheadanddrivingsmoothlywillhelpyouanticipateandhandleanyeventnew: "Lorsque vous conduisez en ville, évitez de courir après le prochain croisement ou feu de circulation en accélérant rapidement puis en ralentissant rapidement. Cela nuit au temps de trajet et à l'économie de carburant, et augmente le risque d'accident. Regarder loin devant et conduire en douceur vous aidera à anticiper et à gérer tout événement.",
    txt_global_std_HSolbergQuote: 'Exerçant la profession de pilote de course depuis des année, j’ai dû participer aux nombreux challenges dans ma carrière. La première chose dont je pense quand je suis au volant c’est la conduite en sécurité. Soyons part ensemble de ce challenge dans le FIA Smart Driving Challenge pour la « sécurité routière ».',
    txt_global_std_PettersolbergDescription: 'The first person to be crowned an FIA World Champion in two different motorsport disciplines, Petter Solberg followed up his 2003 World Rally Championship success by winning the FIA World Rallycross Championship for two consecutive seasons in 2014 and 2015. Having begun his motorsport career with rallycross outings in his native Norway, Solberg – known as ‘Hollywood’ to his many fans for his crowd-pleasing antics – made his World Rally Championship debut in 1998 before a switch to Subaru for 2000 preceded his first win, on the 2002 Rally GB.',
    txt_global_std_wasthecartowed: 'Was the car towed?',
    txt_global_std_BeforeFirstBill_Text3: 'You will pay two premiums each month; a variable and a fixed premium. The fixed premium is the same and does not change, it is based on the type of car you have. The variable premium is based entirely on how you drive. If you drive smart and safely, your premium becomes cheaper.',
    txt_global_std_Reciept_Hello: 'Hello!',
    txt_global_std_filternofilter: 'Pas de filtre',
    txt_global_std_fiasdccompanychallenge: 'Challenge Entreprise FIA SDC',
    txt_global_std_PaymentTrouble2_Text1: 'Still problems with payment',
    txt_global_std_vehicleidentificationwassaved: "L'identification du véhicule a été enregistrée",
    txt_global_std_PreSaleDetails_Text41: 'If you change your mind',
    txt_global_std_areyousureyouwanttodeletethegroup: 'Voulez-vous vraiment supprimer le groupe ?',
    txt_global_std_owner: 'Owner',
    txt_global_std_seeyourprogressandgetinsightsandfeedbackonyourdriving: 'Consultez vos progrès et obtenez des informations et des commentaires sur votre conduite.',
    txt_global_std_RecieptMail_Text9Moderna: 'SHOP SAFETLY WITH MODERNA SMART FLEX',
    txt_global_std_asyoumayhaveareportconsistingofmanymembersyouareabletosubmitarequesttodownloadthewholesetifyouwish: "Comme vous pouvez avoir un rapport composé de nombreux membres, vous pouvez soumettre une demande pour télécharger l'ensemble si vous le souhaitez.",
    txt_global_std_TerminationConfirm_Hello: 'Hello',
    txt_global_std_welcometocompany: 'Bienvenue dans [Entreprise] !',
    txt_global_std_secondsec: ' Sec.',
    txt_global_std_tidler_where: 'Stockholm',
    txt_global_std_useapi: "Utiliser l'API",
    txt_global_std_howdoicompeteinappchallengeanswer: 'Vous participez automatiquement lorsque vous vous inscrivez. Ne vous inquiétez pas, le classement n&#39;affichera que votre surnom - vous resterez donc toujours anonyme. Il y a un concours mensuel et annuel.',
    txt_global_std_Insurance_To: 'to',
    txt_global_std_youwillsoonbeready: 'Vous serez bientôt prêt!',
    txt_global_std_conversionto: ' Convertir en',
    txt_global_std_TidemandShortDescription: 'Champion WRC 2 2017, Champion Touring Car 2010.',
    txt_global_std_manageyourteammembersandtheiraccountpermissions: 'Gérez les membres de votre équipe et leurs autorisations de compte',
    txt_global_std_learnmoreaboutus: 'En savoir plus sur nous',
    txt_global_std_sendcodetome: 'Send me the code',
    txt_global_std_ready2go: 'Ready2Go',
    txt_global_std_yourplateshouldcontainacombinationofnumbersandletters: 'Votre assiette doit contenir une combinaison de chiffres et de lettres.',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimeinconstantspeedunnecessaryspeedvariationcansometimesindicatedistractionorlackoffocus: 'Sur ce type de trajet, le champion écolo moyen passe plus de temps à vitesse constante. Une variation de vitesse inutile peut parfois indiquer une distraction ou un manque de concentration.',
    txt_global_std_RecieptMail_Text8: 'Your {appName} Plug-in will be delivered 1-3 days after you placed your order.',
    'txt_global_std_This is a group of average Enerfy drivers, no profile data available.': 'This is a group of average {appName} drivers, no profile data available.',
    txt_global_std_whymustiallowalwaysallowlocationsservices: 'Pourquoi dois-je autoriser &quot;toujours autoriser les services de localisation&quot;?',
    txt_global_std_collect: 'Collecter',
    txt_global_std_acceptusertermsconditionsandprivacypolicy: 'Acceptez les termes, conditions et politique de confidentialité de l&#39;utilisateur',
    txt_global_std_atleast10characters: 'Au moins 10 caractères',
    txt_global_std_added: 'Ajoutée',
    txt_global_std_MSIG_InsuranceLetter38: 'Toute personne qui conduit sur l&#39;ordre de l&#39;assuré ou avec son autorisation.',
    txt_global_std_OgierQuote: 'Coming soon...',
    txt_global_std_tryingtosignyouintoenerfy: 'Tentative d’inscription sur {appName}…',
    txt_global_std_noreaderyetskipthisstep: ' Pas encore de lecteur ? Veuillez passer à l’étape suivante.',
    txt_global_std_introslide4text: 'Read about what {appName} can provide you!',
    txt_global_std_almostdone: 'Presque terminé',
    txt_global_std_anactivationcodecanbeusedtogetaccesstospecificeventsandchallenges: "Un code d'activation peut être utilisé pour accéder à des événements et des challenges spécifiques.",
    txt_global_std_ActivationLetter_No2: 'på forsikringsavtale',
    txt_global_std_points123: 'Points',
    txt_global_std_forquestionsandfeedbackregardingtheappcontacttheemailabove: "Pour des questions et des commentaires concernant l'application, contactez l'e-mail ci-dessus.",
    txt_global_std_theactivationcodehasbeensent: "Le code d'activation a été envoyé.",
    txt_global_std_liveonmap: 'En direct sur la carte',
    txt_global_std_customerservice: ' Service client',
    txt_global_std_somethingwentwrongpleasetryagainlater: 'Quelque chose c&#39;est mal passé. Merci d&#39;essayer plus tard.',
    txt_global_std_PolicySchedule17: 'NCDP,',
    txt_global_std_NotCreatedAccount1_Text3: "Click on 'New User' and 'Create Account' in the app",
    txt_global_std_savechanges: 'Sauvegarder les modifications',
    txt_global_std_acceptthedongle: 'Merci de permettre de connecter le dongle aussi à votre téléphone',
    txt_global_std_averagespeed: 'Vitesse moyenne',
    txt_global_std_annualprice: 'Prix annuel',
    txt_global_std_youneedtodrivemorethan10kmduringaheatweekinordertogetascoreintheheatleaderboard: 'Vous devez conduire plus de 10 km pendant une manche (semaine) pour obtenir un score dans le classement de la manche.',
    txt_global_std_cimage31h: ' ESSAYEZ D’ÉVITER LES PETITES ACCÉLÉRATIONS',
    txt_global_std_enteravalidcode: 'Entrez un code valide.',
    txt_global_std_NotCreatedAccount5_Text4: 'Call or email us at: info@enerfy.se or 08 – 555 93 200, and we will help you!',
    txt_global_std_firstdriveunlocked: 'Vous avez conduit et reçu un score! Bon travail!',
    txt_global_std_resendpassword: 'Renvoyer Mot de passe',
    txt_global_std_chassinumberalreadyexists: 'Chassinumber existe déjà',
    txt_global_std_nextqualificationfortheendfinalstarts: 'Début de la prochaine qualification pour la Grande Finale',
    'txt_global_std_Al-KuwariChamp': 'Deuxième place de copilote en 2016 dans WRC – T2, Première place de copilote en 2017 et 2018 dans la FIA MERC 2',
    txt_global_std_p2ppushbodyhasnowfinalizedthebooking: '{name} a maintenant finalisé la réservation.',
    txt_global_std_enerfyriskportfoliotracker: 'Enerfy Risk Portfolio Tracker',
    txt_global_std_InsuranceMail_Text17: 'Legal expenses ',
    txt_global_std_socialbutterflylockedkey: 'Parrainez 10 amis pour débloquer ce succès et gagnez 300 points sur votre portefeuille Kinto.',
    txt_global_std_letsgetstarted: 'Commençons!',
    txt_global_std_onboardingstatus: "Statut d'intégration",
    'txt_global_std_gettingposition…': 'Getting position…',
    txt_global_std_fiasdc2019w1price3: '1 emballage de départ',
    txt_global_std_invalidstartdate: 'Invalid start date.',
    'txt_global_std_ben-sulayemDescription': 'Il a participé aux compétitions en Afrique, Europe, Moyen-Orient et au Championnat Mondial du Rallye',
    txt_global_std_drivingtogetherismorefunaddyourcoworkersorsendthemalink: 'Conduire ensemble est plus amusant, ajoutez vos collègues ou envoyez-leur un lien.',
    txt_global_std_subscribetoupdates: 'Abonnez-vous aux mises à jour',
    txt_global_std_p2pfaq16q: "Et si j'endommage la voiture?",
    txt_global_std_position: 'Position',
    txt_global_std_p2pcalendaroverview: 'Aperçu du calendrier',
    txt_global_std_april: ' Avril',
    txt_global_std_VerStep_Text4: 'Tack för att du tecknar {appName}.',
    txt_global_std_novideochoosen: 'No video selected',
    txt_global_std_sendsms: ' Envoyer SMS',
    txt_global_std_thisisrequired: 'Il vous est demandé ce-ci',
    txt_global_std_CarInjuries: 'Vilka skador har fordonet',
    txt_global_std_WelcomeLetter_Text3: 'If you have any questions about your car insurance please contact us or visit our website www.enerfy.se to get more information on how {appName} works. ',
    txt_global_std_performancechart: 'Tableau des performances',
    txt_global_std_p2paddmember: 'Ajouter un membre',
    txt_global_std_p2pselectcalendar: 'Sélectionnez le calendrier',
    txt_global_std_youhave: 'Tu as',
    txt_global_std_premiumdiscount: 'Remise premium',
    txt_global_std_comebacklatertherearenochallengeshereatthemoment: "Revenez plus tard, il n'y a pas de défis ici pour le moment.",
    txt_global_std_BillMail_Text1: 'We will during the day draw',
    txt_global_std_viewriskandco2levelsregardlessofvehicletypeandlocation: "Visualisez les niveaux de risque et de CO2, quel que soit le type de véhicule et l'emplacement",
    txt_global_std_shareyourredeemcode: "Partagez votre code d'échange",
    txt_global_std_p2ppleaseenteravalidpriceabovezero: 'Veuillez saisir un prix valide supérieur à 0',
    txt_global_std_seedetails: 'Voir les détails',
    txt_global_std_clicktheplussigntocreateanewmessage: 'Click the plus sign to create a new message',
    txt_global_std_p2pnewmessage: 'Nouveau message',
    txt_global_std_thisisgonnabesofunletsgetstartedwithyourreallysmartdriving: 'Ça va être tellement amusant! Commençons par votre conduite intelligente. Je serai là pour vous marquer vraiment bien.',
    txt_global_std_competitionformat: 'Forme du concours',
    txt_global_std_createanaccounttocollectandstoreyourresultsitsfree: "Créez un compte pour collecter et stocker vos résultats. C'est gratuit!",
    txt_global_std_rpm: 'RPM',
    txt_global_std_wrongpasswordpopuptext: 'Mauvais mot de passe',
    txt_global_std_WorkShopNumber: 'Telefonnummer till verkstad',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimedecreasingspeedeffectiveobservationandleavingasafefollowingdistancecanhelpreduceunnecessaryspeedvariations: 'Sur ce type de trajet, le champion écolo moyen passe moins de temps à réduire sa vitesse. Une observation efficace et une distance de suivi sûre peuvent aider à réduire les variations de vitesse inutiles.',
    txt_global_std_thereisnoinsurancetoterminate: "Il n'y a pas d'assurance pour mettre fin",
    txt_global_std_NotCreatedAccount4: "You haven't verified your insurance",
    txt_global_std_documentwassent: 'Document was sent',
    txt_global_std_InsuranceMail_Text4: 'Included',
    txt_global_std_zipdriverriskmorerelevantthancreditscore: 'Le risque Zip-Driver est plus pertinent que le pointage de crédit',
    txt_global_std_drivesafethismonth: 'Conduisez prudemment ce mois-ci',
    txt_global_std_p2pnoconfirmedbookingrequests: 'Aucune demande de réservation confirmée',
    txt_global_std_vehicletype2: 'Type de véhicule',
    txt_global_std_safetydrivingskillupwithwarikankinto: 'Apprentissage de la conduite sécuritaire avec Warikan KINTO',
    txt_global_std_holmgrens_where: 'South Sweden',
    txt_global_std_InsuranceMail_Text10: 'Glass repair',
    txt_global_std_notuploadeddrivingtime: ' Durée de conduite non transférée',
    txt_global_std_p2pnopendingbookingrequests: 'Aucune demande de réservation en attente',
    txt_global_std_youhavenofinishedchallengesyet: "Vous n'avez pas encore de défis terminés",
    txt_global_std_unabletosavesettings: ' Impossible de sauvegarder les paramètres',
    txt_global_std_okletschallengesomeone: "Ok, let's challenge someone!",
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatuscheckouttheachievementsbelowyoucanearnbybeingaappnamesuperdriver: 'Les succès vous aident à accumuler des points pour gagner votre statut de fidélité. Découvrez ci-dessous les succès que vous pouvez gagner en devenant un super pilote {appname}!',
    txt_global_std_p2pviewyourbookings: 'Consultez vos réservations.',
    txt_global_std_defineaneventthatinitiatesapushnotification: 'Définir un événement qui déclenche une notification push',
    txt_global_std_howdoiearnsavingsanswer: 'Des économies sont générées lorsque vous conduisez prudemment. Pour chaque trajet que vous conduisez en toute sécurité, vous générez une économie au kilomètre. Plus vous conduisez en sécurité, plus vous économisez! L&#39;enregistrement est visible directement après la fin de votre voyage et sera visible dans votre portefeuille {appname} .',
    txt_global_std_adwancedadmin: 'Administrateur avancé',
    txt_global_std_recovererror: 'Recover error',
    txt_global_std_p2pmessages: 'messages',
    txt_global_std_templatestabtext: 'Modèles',
    txt_global_std_takeapicture: 'Prendre une photo',
    txt_global_std_xtool_description: 'Read engine fault codes in mobile.',
    txt_global_std_RecieptMail_Text3: 'Included {appName} Plug-in',
    txt_global_std_progress: 'Progress',
    txt_global_std_insuredvehicle: 'Véhicule assuré',
    txt_global_std_p2pitlookslikethisaccountisalreadyexist: 'Il semble que ce compte existe déjà',
    'txt_global_std_Want to earn 100 more?': 'Want to earn 100 more?',
    txt_global_std_ShareCar_Text5: 'Ladda ned appen ”{appName}” i din smartphone och skapa ett konto',
    txt_global_std_p2pfaq23q: 'Est-il possible de changer la façon de diviser le coût',
    txt_global_std_PreSaleDetails_Text38: "Greater Than has the right to determine whether an injury is to be repaired, replaced with cash or replaced. Compensation is based on the property's market value. Some equipment is valued, however, according to the replacement table that can be found in the full terms. Some examples of such equipment is tires, alloy wheels, audio and video equipment, etc.",
    txt_global_std_insurancecard: "Carte d'assurance",
    txt_global_std_whatisanactivationcode: "Qu'est-ce qu'un code d'activation ?",
    txt_global_std_showreasons: 'Montrer les raisons',
    txt_global_std_friendrequest: 'Friend request',
    txt_global_std_TomczykChamp: '16 ans dans la DTM, Couronné champion de la DTM en 2011',
    txt_global_std_adduserstodifferentgroups: 'Ajoutez des utilisateurs à différents groupes.',
    txt_global_std_wouldyouliketoenablepictures: 'Would you like to enable pictures?',
    txt_global_std_needstobeavalidphonenumbercanonlyincludenumbersand: 'Doit être un numéro de téléphone valide. Ne peut inclure que des chiffres et +',
    txt_global_std_typeofnotification: 'type de notification',
    'txt_global_std_Connection failed. Try again or pair manually.': 'Connection failed. Try again or pair manually.',
    txt_global_std_datafailedtosent: 'Une erreur s’est produite lors de l’envoi de vos données, merci de vérifier votre email puis réessayez!',
    txt_global_std_daydreamingormindwandering: 'Rêverie ou vagabondage mental',
    txt_global_std_p2phasacceptedtherequest: 'a accepté la demande.',
    txt_global_std_InsuranceMail_Text18: '25% lowest amount 1500',
    txt_global_std_dashboard_groups: 'Groupes',
    txt_global_std_carsharing: 'Car sharing',
    txt_global_std_estimatedpricebasedonyourperformance: 'Prix estimé en fonction de vos performances',
    txt_global_std_french: 'Français',
    txt_global_edj_regno: 'Reg no',
    txt_global_std_speedincrease: 'Augmentation de la vitesse',
    txt_global_std_howtogetstartedwithzipdriverriskindex: "Comment démarrer avec l'indice de risque Zip-Driver",
    txt_global_std_dates: 'Rendez-vous',
    txt_global_std_Intro_Text3: 'Med Sidekick bilforsikring kan du velge mellom «Delkasko», «Kasko» og «Kasko Ekstra». De ulike dekningene variere i omfang og pris. Trykker du på denne link (',
    txt_global_std_p2pkeepthecommunicationgoingandchatwithyourfriendaboutyourbookings: 'Maintenez la communication et discutez avec votre ami de vos réservations.',
    txt_global_std_alreadyhaveanaccount: 'Vous avez déjà un compte?',
    txt_global_std_PolicySchedule7: 'Année d&#39;inscription',
    txt_global_sidekick_invitetext: 'Both you and your friend will get awarded with 500kr when your friend use your code at activation of a new {company} car insurance.',
    txt_global_std_loyalty_upsell_pitch_bulletpoint1: "Encourager une conduite sûre et respectueuse de l'environnement",
    txt_global_std_safetywarnings: 'Safety warnings',
    txt_global_std_youwillnolongerbedrivinginsured: 'You will no longer be driving insured.',
    txt_global_std_NotActivatedAccount_Text4: 'Sincerely,\r\n{appName}',
    txt_global_std_yourveryownfiasdccompanychallenge: 'Votre propre FIA SDC Company Challenge',
    txt_global_std_NotCreatedAccount1: 'You have not created an {appName}-account',
    txt_global_std_vs: ' vs',
    txt_global_std_overview2: 'Vue d&#39;ensemble',
    txt_global_std_p2pselectedmember: 'Membre sélectionné',
    txt_global_std_p2premembertomarkthemaspaidonceyoureceivethepayment: "N'oubliez pas de les marquer comme payés une fois que vous recevez le paiement.",
    txt_global_std_thisistheworldsfirstchallengerewardingsmartskillsatthewheelgetyourcompanyinvolvedinsmartdriving: "Il s'agit du premier défi au monde récompensant les compétences intelligentes au volant. Impliquez votre entreprise dans la conduite intelligente.",
    txt_global_std_privateforthoseinmygroupwiththesameemaildomainname: 'Privé (pour ceux de mon groupe avec le même nom de domaine de messagerie)',
    txt_global_std_noinsuranceregistered: 'Aucune assurance enregistrée',
    txt_global_std_startbonus: 'Startbonus',
    txt_global_std_yourdroveatscoretypescoreorbetterawesomeyouhaveearnedpointspoints: 'Vous avez conduit à {score} ou mieux! Impressionnant! Vous avez gagné {points} points!',
    txt_global_std_subscribetoupdates_2: 'Abonnez-vous aux mises à jour',
    txt_global_std_haveacompleteplatform: 'Have a complete platform with drive journal, eco-driving, find your car, competitions, fault codes & much more.',
    txt_global_std_areyousureyouwanttodisablethecommunication: 'Voulez-vous vraiment désactiver la communication ?',
    txt_global_std_FriendRequest_Text3: 'Drive Safetly!',
    txt_global_std_odometer: ' Compteur kilométrique',
    txt_global_std_acceptbluetooth: 'Accepter Bluetooth',
    txt_global_std_yourinsurancehasbeenterminated: 'Votre assurance a été résiliée!',
    txt_global_std_PreSaleDetails_Text75: 'You have as an individual the opportunity to turn to the Traffic Accident Board, www.trafikskadenamnden.se, hearing injuries due to traffic. Consumer Complaints Board. Www.arn.se, ARN does not examine causation issues. Liability Insurance Personal Injury Board and Personal Insurance Board, www.forsakringsnamnder.se. You always have the option to bring an action in court. If you have an insurance policy that includes a legal step, this legal protection also may apply in a dispute against us. The conditions are stated in the policy condition. ',
    txt_global_std_tosignwiththisaccountwejustneedsomecomplementaryinfoaboutyoubeforeyoucanstart: 'Pour signer avec ce compte, nous avons juste besoin de quelques informations complémentaires vous concernant avant de pouvoir commencer !',
    txt_global_std_MSIG_InsuranceLetter17: 'Excès:',
    txt_global_std_BeforeBill: 'You will soon recieve your monthly invoice',
    txt_global_std_kintofaq4q: 'Comment participer au Kinto Challenge?',
    txt_global_std_MikkelsenMusic: 'I listen to all kind of music when I drive but I would say I mostly listen to pop and electronic dance music.',
    txt_global_std_createnewpushnotification: 'Créer une nouvelle notification push',
    txt_global_std_notethiscodeexpiresin6minutesbutyoucangenerateanotherbylogginginagain: 'Remarque : Ce code expire dans 6 minutes, mais vous pouvez en générer un autre en vous reconnectant.',
    txt_global_std_filteroptionactive: 'Actif',
    txt_global_std_theconnectiontypeissaved: 'Le type de connexion est enregistré.',
    txt_global_std_reasontoterminatetheinsurance: 'Raison de la résiliation de l&#39;assurance',
    txt_global_std_50000pts: '> 50 000 points',
    txt_global_std_youraveragescoreis2: 'Votre score moyen est de {points}',
    txt_global_std_yourcarmodelistoooldanddoesnthavebluetoothnodot: "Votre modèle de voiture est trop ancien et n'a pas de Bluetooth.",
    txt_global_std_bonuspaymentday: 'Bonus payment day',
    txt_global_std_toregisteryourdrivingcorrectlyyoumustselectalways: 'Pour enregistrer correctement votre conduite, vous devez sélectionner « Toujours »',
    txt_global_std_getthecarinsuranceyoudeservethesaferyoudrivethemoreyousave: "Obtenez l'assurance automobile que vous méritez. Plus vous conduisez prudemment, plus vous économisez.",
    txt_global_std_onboardingstatususeranalytics: "Statut d'intégration / analyse des utilisateurs",
    txt_global_std_userguides: "Guides d'utilisation",
    txt_global_std_logout: 'Se déconnecter',
    txt_global_std_Insurance_Text2: 'Betalas i efterskott och baseras på bilmodell. Den fasta månadspremien håller bilen skyddad när den står stilla. Abonnerar du på {appName} tjänstepaket Inkluderas den i priset för varje månad. Har du köpt {appName} Plug-in inkluderas priset i den fasta månadspremien för den första månaden.',
    txt_global_std_fromfromvalkmuptotovalkm: "De {fromVal} km jusqu'à {toVal} km.",
    txt_global_std_timetorestalarm: 'Time to rest alarm',
    txt_global_std_Fia_Receipt_Text10: 'Le total',
    txt_global_std_p2pexpenses: 'Dépenses',
    txt_global_std_apitool: 'Outil API',
    txt_global_std_zipriskindexinsuranceratingcomponent: "Indice de risque Zip - Composante de notation d'assurance",
    txt_global_std_daydreamingisacommontypeofdriverdistractionyoumaythinkitsharmlessbutanythingthattakesyourmindoffthedrivingtaskincreasescrashriskchangingyourgazefrequentlycanhelpyoumaintainfocusontheenvironmentaroundyou: "La rêverie est un type courant de distraction du conducteur. Vous pensez peut-être que c'est inoffensif, mais tout ce qui vous détourne de la tâche de conduite augmente le risque d'accident. Changer fréquemment de regard peut vous aider à rester concentré sur l'environnement qui vous entoure.",
    txt_global_std_qualified: 'Qualifiés',
    txt_global_std_VilliersMileage: 'I South Africa we have some beautiful passes and the Franschoek pass, close to my home is one of my favourites.',
    txt_global_std_topimprovercomingsoon: 'Top améliorant à venir',
    txt_global_std_savetranslation: 'Enregistrer la traduction',
    txt_global_std_heresyour15discountcode: 'Voici votre code de réduction de 15 %',
    txt_global_std_p2ppushtitlenewpaymentquestion: 'New payment?',
    txt_global_std_edithistory: 'Modifier l&#39;historique',
    txt_global_std_numberbetweenxandyrule: 'Le nombre doit être compris entre {x} et {y}',
    txt_global_std_areyouthenextworldchampion: 'Serez-vous le prochain champion du monde ?',
    txt_global_std_qhowdoesthescoringsystemwork: 'Q : Comment fonctionne le système de notation ?',
    txt_global_std_Fia_Receipt_Text11: 'Total',
    txt_global_std_nearestyou: 'nearest you',
    txt_global_std_inorderfortheapptoworkproperlyweneedaccesstoyourphoneslocationaswellasexerciseandfitnessdata: "Pour que l'application fonctionne correctement, nous avons besoin d'accéder à l'emplacement de votre téléphone ainsi qu'aux données d'exercice et de forme physique",
    txt_global_std_drive: ' Conduite',
    txt_global_std_technicaldrivingperformance: ' Détails techniques des performances de conduite',
    txt_global_std_NotActivatedAccount_Text1Moderna: 'You haven’t used your Moderna Smart Flex yet. For your car insurance to work properly, you need to install the {appName} Plug-in. Just follow the steps in the app and then you’re done.',
    txt_global_std_missingdetails: 'Missing details',
    txt_global_std_p2pnext: 'Suivant',
    txt_global_std_syncwithgarminconnect: 'Synchroniser avec Garmin Connect™',
    txt_global_std_HSolbergBesttip: 'Have flow in the driving, braking less = less accelerations that use energy',
    txt_global_std_thiswindowwillclosein30sec: ' Cette fenêtre se fermera dans {closeSeconds} secondes',
    txt_global_std_policynumbercouldnotbeverified: "Le numéro de police n'a pas pu être vérifié.",
    txt_global_std_p2pfuel: 'Carburant',
    txt_global_std_PreSaleDetail: 'Pre-sale details',
    txt_global_std_p2pand: 'et',
    txt_global_std_apikey1: 'Clé API 1',
    txt_global_std_teamc02savings: "Equipe d'économies c02",
    txt_global_std_policynumber: 'Policy number',
    txt_global_std_SulayemChamp: '14 fois champion de rallye du FIA Moyen Orient',
    txt_global_std_PSolbergFacts: 'The first person to be crowned an FIA World Champion in two different motorsport disciplines, Petter Solberg followed up his 2003 World Rally Championship success by winning the FIA World Rallycross Championship for two consecutive seasons in 2014 and 2015. Having begun his motorsport career with rallycross outings in his native Norway, Solberg – known as ‘Hollywood’ to his many fans for his crowd-pleasing antics – made his World Rally Championship debut in 1998 before a switch to Subaru for 2000 preceded his first win, on the 2002 Rally GB.',
    txt_global_std_available: 'Disponible',
    txt_global_std_swipetoopenthecontrolcenterandmakesureyouhavebluetoothturnedon: 'Balayez pour ouvrir le centre de contrôle et assurez-vous que Bluetooth est activé.',
    txt_global_std_cwelcome7: 'Heeeeey!',
    txt_global_std_searchheadtohead: 'Search Head to Head',
    txt_global_std_nothankyou: 'Non, merci',
    txt_global_std_theinsurancedeleted: "L'Assurance supprimée !",
    txt_global_std_thankyouforallowingustoaccessyourbluetooth: 'Merci de nous avoir permis d&#39;accéder à vos paramètres Bluetooth. Nous apprécions votre confidentialité et ne partagerons pas ces informations avec une entité tierce.',
    txt_global_std_challange: 'Défi',
    txt_global_std_addteam: 'Add team',
    txt_global_std_personaldetails2: 'Détails personnels',
    txt_global_std_areyousurethatyouwanttocompletelyremoveyouraccount: 'Etes-vous sûr de vouloir supprimer complètement votre compte, vos dossiers de conduite, vos informations d&#39;assurance?',
    txt_global_std_getstarsaftereachtripforhowgoodsafeandecofriendlyyourdrivewas: 'Après chaque trajet, vous obtenez un score compris entre 0 et 100 en fonction de la sécurité de votre conduite. Ici vous pouvez trouver votre score total.',
    txt_global_std_p2pgroupname: 'Nom de groupe',
    txt_global_std_AmountPerKilometer: 'kr/km',
    txt_global_std_youonlyhave: "Vous n'avez que",
    txt_global_std_uploadintervalsecondssec: ' {uploadIntervalSeconds} s',
    txt_global_std_namesmayonlyincludeletters: ' Les noms ne peuvent inclure que des lettres',
    txt_global_std_HSolbergFacts: 'Solberg est le frère ainé du champion du monde du WRC en 2003, Petter Solberg, et par sa femme suédoise, Maud, le beau-père du pilote suédois Pontus Tidemand. Un des pilotes les plus expérimentés au monde des sports automobile. Quand il a la pêche il a le potentiel de faire les plus jeunes se sentir gênés. ',
    txt_global_std_qdoesagentacceptdriversthatarenotlicensedincountry: 'Q : {agent} accepte-t-il les conducteurs qui ne sont pas autorisés dans {country} ?',
    txt_global_std_p2ppushbodybookingcomingup: 'Veuillez noter que la réservation commence "{start}".',
    txt_global_std_nameemailhasinvitedyoutojointheirgrouptostartdrivingsmarter: '{name} ({email}) vous a invité à rejoindre son {group} pour commencer à conduire plus intelligemment.',
    'txt_global_std_Set it up for your first run': 'Set it up for your first run',
    txt_global_std_opensettingsinthephonelocationturnon: 'Ouvrez &quot;Paramètres&quot; sur votre téléphone. Accédez à Confidentialité, ouvrez le service de localisation et activez Enerfy.',
    txt_global_std_timeinretardation: ' Temps passé à ralentir',
    txt_global_std_activatebonuserror: 'Something went wrong.',
    txt_global_std_loadingitems: 'Chargement des éléments',
    txt_global_std_interests: 'Intéressé à',
    txt_global_std_viamail: ' Par e-mail',
    txt_global_std_TaxInvoice10: 'TOUS LES TITULAIRES INDIVIDUELS SONT SOUMIS À UN PAIEMENT AVANT LA GARANTIE DE LA GARANTIE',
    txt_global_std_MSIG_Onboarding2: 'Merci d’avoir choisi MS First Capital Car Insurance. Vous avez signé l&#39;assurance pour la voiture',
    txt_global_std_teamaveragescorebig: "SCORE MOYEN DE L'ÉQUIPE\n",
    txt_global_std_notspecified: 'Non précisé',
    txt_global_std_apitool_upsell_pitch_bulletpoint3: "Intégration facile et sans effort des données pour améliorer l'efficacité de l'entreprise",
    txt_global_std_p2pfinishquestion: 'Terminer?',
    txt_global_std_InsuranceChange: 'There are some changes on your car insurance',
    txt_global_std_typeimage: 'Image',
    txt_global_std_Insurance_Phone: 'Telephone',
    txt_global_std_invalidmessage: 'Invalid message',
    txt_global_std_p2pwrongemailorpassword: 'E-mail ou mot de passe erroné !',
    txt_global_std_ayesagentacceptsdriverswhoarelicensedinothercountries: "R : Oui, {agent} accepte les chauffeurs titulaires d'une licence dans d'autres pays.",
    txt_global_std_enterinvitationcode: "Entrez le code d'invitation",
    txt_global_std_PreSaleDetails_Text5: 'Enerfy - Insurance with Moderna has comprehensive basic coverage. There are some supplemental options. If any protection is particularly important to you, find out if it is covered by the insurance by contacting our customer service at info@enerfy.se or 08-555 93 200.',
    txt_global_std_thiswonttakemorethanaminuteyoullbeintheworkspaceonceitsready: "Cela ne prendra pas plus d'une minute, vous serez dans l'espace de travail une fois qu'il sera prêt.",
    txt_global_std_InsuranceMail_Text6: 'Traffic',
    txt_global_std_f_lanzbesttip: "Regardez toujours devant vous, scrutez l'horizon à la recherche de dangers potentiels. Profitez en même temps de la vue.",
    txt_global_std_exportdata: ' Exporter les données',
    txt_global_std_savingsisgeneratedifriskislowerthanclaims: 'Une économie est générée si le risque est inférieur aux réclamations calculées',
    txt_global_std_tipsfromthecoach: 'Tip from the coach',
    txt_global_std_qualifiedto2021finals: 'Qualifié pour la finale 2021',
    txt_global_std_documentthedamage: 'Documenter les dégâts',
    txt_global_std_riskportfoliotracker: 'Suivi du portefeuille de risques',
    txt_global_std_PaymentTrouble3_Hello: 'Hello',
    'txt_global_std_Al-KhelaifiFacts': 'Abdulla participe à la plupart des événements de sport automobile. Il a participé à plusieurs championnats dans différents types de courses comme la National Drag, Qatar Mile, National Sprint and QTTC',
    txt_global_std_registrationno: 'Registration No',
    txt_global_std_from: ' Depuis',
    txt_global_std_callroadsideassistance: "Appeler l'assistance routière",
    txt_global_std_p2ppushtitlehasacceptedthebooking: 'Réservation acceptée',
    txt_global_std_loyalty: 'Fidélité',
    'txt_global_std_Enerfy will attempt to automatically pair connect with your OBD reader. This process may take up to a minute. If the connection is unsuccessful, you can try again.': '{appName} will attempt to automatically pair connect with your OBD reader. This process may take up to a minute. If the connection is unsuccessful, you can try again.',
    txt_global_std_selectcountry: 'Choisissez le pays',
    txt_global_std_unabletoloadfriendlist: 'Unable to load friend list',
    txt_global_std_HansenBesttip: 'To me, there are two crucial key factors for smart driving. The first: look and check what’s happening around you. The second: learn and get to know your car, and how it’s reacting to your inputs of the throttle, break and engine breaking. Combining these two crucial factors will take you really far in this competition.',
    txt_global_std_therulesforthecompanychallengeareasfollowingbrtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsbrdriveminimum1kmduringaheatinordertoqualifyforthechallengebrthesamegeographicalroutewillbescoredamaximumof10timesinoneday1: 'Les règles du challenge entreprise sont les suivantes :\n' +
      '<br>• Le score individuel du conducteur est calculé sur une moyenne basée sur les 10 % des meilleurs trajets.\n' +
      "<br>• Roulez minimum 1 km lors d'une manche afin de vous qualifier pour le challenge.\n" +
      '<br>• Un même parcours géographique sera noté 10 fois maximum en une journée.',
    txt_global_std_addinjuredperson: 'Ajouter une personne blessée',
    txt_global_std_okaywewillendthetour: "D'accord, nous allons terminer la visite.",
    txt_global_std_p2pexpensessummary: 'Sommaire des dépenses',
    txt_global_std_PolicySchedule28: '1 000 SGD TOUTES LES RÉCLAMATIONS S&#39;IMPOSENT SUR LES CONDUCTEURS QUI ONT DÉSACTIVÉ LE DONGLE PENDANT AU MOINS 14 JOURS PAR ANNÉE CALENDRIER.',
    txt_global_std_learnhowtofixcellulardataissuesonyouriphone: 'Learn how to fix cellular data issues on your iPhone',
    txt_global_std_insurancecost: "Cout d'assurance",
    txt_global_std_couldnotretrievedrivedata: ' Impossible de récupérer les données du trajet',
    txt_global_std_loyaltypointsgainedfor: 'Points de fidélité gagnés pour la réservation',
    txt_global_std_youversususername: 'Vous contre {utilisateur}',
    txt_global_std_splittrips: ' Séparer le trajet',
    txt_global_std_topecodrivers: 'Top éco-conducteurs',
    txt_global_std_endodometer: 'End odometer',
    txt_global_std_speedchangeanalysis: ' ANALYSE DES VARIATIONS DE VITESSE',
    txt_global_std_ongoinggames: 'MANCHES EN COURS',
    txt_global_std_totalpointsthisweek: 'Total de points cette semaine:',
    txt_global_std_statusnondriving: ' À l’arrêt',
    txt_global_std_unabletosavenote: ' Impossible de sauvegarder la note',
    txt_global_std_schedule: 'Programme',
    txt_global_std_ShareCar_Text6: 'I appens meny klickar du på alternativet ”Dela bil” och ”Jag ska låna bil”',
    txt_global_std_deleteapikey: 'Supprimer la clé API',
    txt_global_std_leaderboard: ' Classement',
    txt_global_std_cherry: 'Cerise!',
    txt_global_std_PreSaleDetails_Text1: 'Pre sale details Enerfy - Insurance with Moderna',
    txt_global_std_Retry: 'Retry',
    txt_global_std_VerificationStep: 'Last step to sign your car insurance',
    txt_global_std_Intro_Text11: 'Vi vil gjerne at flere unge sjåfører skal være trygge og ansvarlige i trafikken. Ved å verve en venn til Sidekick vil både du og din venn få 250 kr i sparegrisen. Det du trenger å gjøre er å gi din vervekode til din venn, slik at denne kan registreres når hun eller han tegner Sidekick-forsikring. Din personlige vervekode finner du i appen under "Sidekick vervekode" i Mer-menyen.',
    txt_global_std_p2pendbeforestart: "Veuillez vous assurer que l'heure de fin n'est pas antérieure à l'heure de début!",
    txt_global_std_verifyyourphonenumber: 'Vérifiez votre numéro de téléphone',
    txt_global_std_monthshorthand: 'mois',
    txt_global_std_youarenowconnectedandready: 'Vous êtes maintenant connecté et prêt à démarrer votre premier disque!',
    txt_global_std_wecannotfindanyinsurance: 'Please contact customer service to help you activate the insurance.',
    txt_global_std_youearnedthisbadgextimes: 'Vous avez gagné ce badge {x} fois',
    txt_global_std_p2pconfirmprice: 'Confirmer le prix',
    txt_global_std_Fia_Receipt_Text14: 'Les frais d’envoie et les taxes (6%) sont inclus au prix.',
    txt_global_std_p2pincludesafedriverbonus: 'Inclut le bonus safedriver',
    txt_global_std_activatebonus: 'Activate bonus',
    txt_global_std_isthisanelectriccar: 'Est-ce une voiture électrique?',
    txt_global_std_descriptionemptyortolong: 'Description empty or to long',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint2: 'Identification instantanée des conducteurs à haut risque et à faible risque',
    txt_global_std_MSIG_Onboarding5: 'Nos clients signifient tout pour nous et nous sommes heureux de vous avoir à bord!',
    txt_global_std_compareandchallenge: 'Compare & challenge',
    txt_global_std_signinwithfacebook: 'Sign in with Facebook',
    txt_global_std_zipdriver_upsell: 'Zip-Driver - détermine le niveau de risque précis en fonction du lieu de conduite.',
    txt_global_std_month: ' Mois',
    txt_global_std_uploadcustombadgeicon: "Télécharger l'icône de badge personnalisé",
    txt_global_std_helptemplatetitle: 'Help',
    txt_global_std_PolicySchedule30: 'La couverture de pare-brise sous Endorsement No. 89C est illimitée.',
    txt_global_std_removeaccount: 'Supprimer le compte',
    txt_global_std_heading: 'Heading',
    txt_global_std_verifyphonenumber: "Vérifier le numéro de téléphone à l'aide du code de sécurité",
    txt_global_std_ActivationLetter_Text13: 'Hilsen, \r\n{appName}-gjengen i Tryg Forsikring',
    txt_global_std_needmoremilagetext: 'The mileage you have chosen will be automatically adjusted if you drive longer than expected. That way you will always be properly insured. However, it may be advisable to be at the forefront. Then you avoid postpay. Also let us know if you want to adjust the mileage so you do not pay more per month than you may have.',
    txt_global_std_deletegroup2: 'Supprimer le groupe',
    txt_global_std_p2prepair: 'réparation',
    txt_global_std_repetition: 'Répétition',
    txt_global_std_RecieptMail_Text14: 'Telephone',
    txt_global_std_requestinsuranceactivationcodebysms: 'Salut! Vous avez demandé un nouveau code d&#39;activation pour votre compte {appname} : {activationcode} Salutations {appname} gang in {companyshort}.',
    txt_global_std_SignupFia_Text6: ' Les inscriptions ouvriront très prochainement.',
    txt_global_std_NotCreatedAccount5_Text3: 'at Moderna Försäkringar.',
    txt_global_std_planningtips: 'Regarder plus loin (et pas juste devant vous) se traduit généralement par une conduite plus douce et une plus grande chance de découvrir des obstacles à temps.\n' +
      '\n' +
      'Assurer une conduite agréable pour vous et vos passagers.',
    txt_global_std_riskindex_upsell_pitch_bulletpoint1: 'Des informations uniques sur les tendances des risques liés aux conducteurs dans le monde',
    txt_global_std_endaddress: ' Destination',
    txt_global_std_daysremaininginthisperiod: '{days} jours restants dans cette période',
    txt_global_std_p2pcoderesent: 'Code renvoyé',
    txt_global_std_didafriendridewithyou: 'Un ami a-t-il roulé avec vous ?',
    txt_global_std_abctext1: 'Create free airways. Look, listen, feel if the injured person is breathing. If not: Give artificial respiration. Recovery position',
    txt_global_std_TomczykMileage: 'The route to the family vacation is above all one of my favorites mileage to drive. ',
    txt_global_std_activationcode: "Code d'activation",
    txt_global_std_recruitfriendsandgetfreeheats: 'Recrutez des amis et obtenez des manches gratuites!',
    txt_global_std_PreSaleDetails_Text33: 'Includes both rental car and assistance insurance (see above). Additionally, Combo gives you a 50% deductible reduction in cases of willful damage caused by third parties. If the car has Collision Damage Warranty, you may receive up to 3000 SEK from the Collision Damage Warranty deductible. If the car does not have vehicle damage insurance or vehicle damage warranty, you may receive up to 3000 SEK. The insurance expires when the car reaches 15 years old (at the renewal of the calendar year) counted from the first registration date.',
    txt_global_std_areyoureadytodrive: 'Prêt à conduire ?',
    txt_global_std_ringsosphoneno: 'Sonnerie SOS : {phoneNo}',
    txt_global_std_insuranceactivationkeyalreadycreated: 'Clé d&#39;activation de l&#39;assurance déjà créée',
    txt_global_std_p2pconnecttoyourfriendscarwhenyourbookingstartsyouwillgetfeedbackandinsightsonyourdriving: 'Connectez-vous à la voiture de votre ami lorsque votre réservation commence. Vous obtiendrez des commentaires et des informations sur votre conduite.',
    txt_global_std_trafficdelaysdeadlinesandrunninglatetheycanallcausestresswhichisnegativeforyourhealthandsafetyallowingextratimeplanningyourrouteandcheckingtravelupdatescanhelptoreducestressandimproveyourfocusnew: 'Retards de circulation, échéances et retards ; ils peuvent tous causer du stress, ce qui est négatif pour votre santé ET votre sécurité. Accorder plus de temps, planifier votre itinéraire et vérifier les mises à jour de voyage peuvent aider à réduire le stress et à améliorer votre concentration.',
    txt_global_std_Reciept_Text4: 'Sincerely,\r\n{appName}',
    txt_global_std_typicallyyouseeadigitalscreendisplayingtworowsofnumbersoneforthecarstotaldistancetravelledandoneforthedailydistances: 'En règle générale, vous voyez un écran numérique affichant deux rangées de chiffres, une pour la distance totale parcourue par la voiture et une pour les distances quotidiennes.',
    txt_global_std_TaxInvoice7: 'TOUS LES POLITIQUES DE L&#39;ENTREPRISE SONT SOUMIS À UNE GARANTIE DE PAIEMENT DE PRIMES Pour des périodes d&#39;assurance de plus de 60 jours',
    txt_global_std_geyouwillreceiveanemailwithyourpersonaldata: 'Vous recevrez un e-mail avec vos données personnelles.',
    txt_global_std_BeforeBill_Text3: 'Tips på smart körning:',
    txt_global_std_codehasbeensentto: ' Le code a été envoyé à',
    txt_global_std_theenerfyglobalteam: "L'équipe d'EnerfyGlobal",
    txt_global_std_burglary: 'Burglary',
    txt_global_std_NewCar_Text1: 'Vi kan se, at du har registreret en ny bil på din {appName} forsikring. For at aktivere forsikringen, skal du klikke på ’Mere’ og herefter ’Aktiver forsikring’ i {appName} app’en. Her skal du skrive din nye aktiveringskode:',
    txt_global_std_p2pstartadrivetoaccessinsightsandstatisticsaboutyourdriving: 'Démarrez une conduite pour accéder à des informations et des statistiques sur votre conduite.',
    txt_global_edj_logtype: 'Log type',
    txt_global_std_cimage17d: ' Planifiez et soyez actif sur autoroute. Découvrez comment améliorer votre conduite sur de plus longues distances.',
    txt_global_std_removemissingtrip: ' Retirer le trajet',
    txt_global_std_howdoyoumeasuremydrivinglexusanswer: 'L&#39;application Lexus utilise l&#39;intelligence artificielle pour mesurer votre conduite. L&#39;application se connecte au cloud et analysera chaque seconde de votre conduite - transformée en «profil». L&#39;IA compare vos profils à plus de 750 millions d&#39;autres profils de conduite uniques. L&#39;IA a plus de 16 ans d&#39;expérience pour rechercher la probabilité d&#39;un modèle de conduite d&#39;être impliqué dans un accident. Heureusement, après presque chaque voyage, les conducteurs et les passagers rentrent chez eux en toute sécurité chez leurs proches.',
    txt_global_std_notsignedupforacovercubeinsuranceyet: 'Pas encore souscrit à une assurance Covercube ?',
    txt_global_std_iwilldoitlater: 'je le ferai plus tard',
    txt_global_std_sothedevicecanconnectyourphonewithyourcar: 'afin que l&#39;appareil puisse connecter votre téléphone à votre voiture.',
    txt_global_std_WelcomeLetter_Text2: 'In this email, you will find your insurance documents including your insurance letter and pre-sale information. The complete insurance terms can be found in the {appName} app and at www.enerfy.se. If something in the attached documents is incorrect, please contact us so that we can correct it. ',
    txt_global_std_nowthatyouhavecreatedyouraccount: 'Now that you have created your account, simply plug in your {appName}-reader in your car and start driving!',
    txt_global_std_markermovediscount: 'Déplacez le marqueur pour voir comment votre niveau affecte votre remise',
    txt_global_std_achachievements: 'Réalisations',
    txt_global_std_skip: 'Passer',
    txt_global_std_wetakeeverystepandmeasuretominimiseourco2footprintenergyconsumptionandroadaccidentsbyinspiringourdriverstosmarterdrivingandtrackingourperformance: "Nous prenons toutes les mesures nécessaires pour minimiser notre empreinte carbone, notre consommation d'énergie et nos accidents de la route en inspirant nos chauffeurs à une conduite plus intelligente et en suivant nos performances",
    txt_global_std_p2pfaq11a: '1.Appuyez sur le menu avec les trois points.\n' +
      '2.Accédez à la page de vos amis.\n' +
      '3.Appuyez sur le bouton Inviter des amis.\n' +
      "4. Envoyez un lien d'invitation à votre ami. Lorsque votre ami télécharge l'application via le lien, il apparaîtra dans votre liste d'amis.",
    txt_global_std_privatetrips: 'Private trips',
    'txt_global_std_Get improved functionality by allowing Enerfy access to your location.': 'Get improved functionality by allowing {appName} access to your location.',
    txt_global_std_passwordsdonotmatch: 'Les mots de passe ne correspondent pas',
    txt_global_std_unabletosendfriendinvite: 'Unable to send friend invite!',
    txt_global_std_sendthemalink: "Envoyez-leur un lien pour qu'ils puissent utiliser l'application.",
    txt_global_std_howdoirenewmyinsurance: 'Comment renouveler mon assurance ?',
    txt_global_std_BeforeFirstBill: 'You will soon recieve your first invoice!',
    txt_global_std_35000pts2: '&#60;35 000 points',
    txt_global_std_p2pselectcar: 'Sélectionnez une voiture',
    txt_global_std_notconnectedtoenerfycloud: ' Non connecté au cloud de {appName}.',
    txt_global_std_dashboard_challenges: 'Défis',
    txt_global_std_DamageReport_Text2: "and can confirm that it's now being handled by Moderna Försäkringar, which will take care of your case. In this email you will also find a separate copy of your damage report.",
    txt_global_std_exportcsv: ' Exporter au format CSV',
    txt_global_std_downloadingdrivers: 'Téléchargement des pilotes',
    txt_global_std_teamstatistics: 'Team statistics',
    txt_global_std_invalidemail: 'Email invalide',
    txt_global_std_succesfullyactivatedinsurance: 'Assurance activée avec succès',
    txt_global_std_hello123: 'Bonjour',
    txt_global_std_trytofocusontheroadfollowtraffixflowkeepdistance: 'Try to focus on the road, follow traffic flow and keep distance, drive according to weather conditions and follow speed limits.',
    txt_global_std_usernotfoundinteam: "Utilisateur introuvable dans l'équipe.",
    txt_global_std_notifications: 'Les notifications',
    txt_global_std_summaryofdistnacedivenperslectedperiod: 'Résumé de la distance parcourue par période sélectionnée.',
    txt_global_std_checkyourconnectionandtryagain: 'Check your connection and try again',
    txt_global_std_PolicySchedule15: 'La politique est soumise à avenants / clauses',
    txt_global_std_contactsupport: 'Contactez le support',
    txt_global_std_iconheader: 'Icône déverrouillé',
    txt_global_std_userlockedout: "L'utilisateur est actuellement verrouillé pour trop de tentatives de connexion infructueuses.",
    txt_global_std_kintosmsandemailtext: 'Commencez à conduire en toute sécurité avec moi! Suivez le lien ci-dessous et parcourez 10 km pour rouler avec Kinto.',
    txt_global_std_since: ' Depuis',
    txt_global_std_ShareCar_Text11: 'Kör försiktigt!',
    txt_global_std_unrestrictedinvitelink: "Lien d'invitation illimité",
    txt_global_std_alertbreaktimetext: ' Alarme de repos activée.',
    txt_global_std_insurancevehicles: 'Insurance vehicles',
    txt_global_std_areyoudrivinganelectriccar: 'Conduisez-vous une véhicule électrique?',
    txt_global_std_mycardoesnthavebluetoothanswer: 'Aucun problème! Demandez un dongle BT-OBD et il fera la magie! Connectez simplement le dongle au câble et branchez-le à votre chargeur 12v dans la voiture. Ensuite, suivez les étapes «Se connecter à la voiture» comme d&#39;habitude et choisissez le nom «Enerfy OBD» lors de la connexion Bluetooth.',
    txt_global_std_firsttimeinapp: ' Première connexion à l’application',
    txt_global_std_connectyourcarfollowyourdrive: 'Connect your car & follow your trips in real-time.',
    txt_global_std_mutualfriends: ' Amis en commun',
    txt_global_std_Insurance_Level: 'Level',
    txt_global_std_p2pdeletemessage: 'Supprimer le message',
    txt_global_std_improvetext: ' Trajets avec bonus à la suite',
    txt_global_std_marklundDescription: 'Il a commencé la course automobile au niveau international en 2011 et a depuis participé dans les séries comme Porsche Carrera Cup de Scandinavie, FIA Mondial/Européen Rallye-cross et X-Games.',
    txt_global_std_changeyournumber: 'Changer votre numéro',
    txt_global_std_error: 'Erreur',
    txt_global_std_drivingtechnique: ' Technique de conduite',
    txt_global_std_deleteachievementpopuptext: 'Votre réussite sera définitivement supprimée. La progression de vos utilisateurs sera perdue. Ceux qui ont déjà reçu le succès pourront toujours le voir.',
    txt_global_std_challengeinvitesent: 'Invitation au défi envoyée',
    txt_global_std_bycheckingthischeckboxandlogginginyoureacceptingour: 'En cochant cette case et en vous connectant, vous acceptez notre',
    txt_global_std_startdriving: 'Commencer à conduire',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithagentwithalapseincoverage: "Vous aurez {days} jours à compter de la date d'expiration pour effectuer un paiement et continuer votre police avec {agent} avec une déchéance de couverture.",
    txt_global_std_thechaser: 'Le chasseur',
    txt_global_std_pickyourcoverimage: 'Choisissez votre image de couverture',
    txt_global_std_tobeabletosendmessagesweneedtoaddyourfriendsletsinvitethem: 'Pour pouvoir envoyer des messages, nous devons ajouter vos amis. Invitons-les!',
    txt_global_std_pleasechoosewhichtypeofpictures: 'Veuillez choisir quel type de photos',
    txt_global_std_demotext5: 'Voir votre bonus chanceux ici.',
    txt_global_std_p2pverifyphonenumber: 'Vérifier le numéro de téléphone',
    txt_global_std_yourlinkshouldbeavailableinyourinboxin5minutespleaseremembertocheckyourjunk: "Votre lien devrait être disponible dans votre boîte de réception dans 5 minutes. N'oubliez pas de vérifier vos indésirables.",
    txt_global_std_startyourcar: 'Démarrez votre voiture.',
    'txt_global_std_Using GPS through location access allows for great functionality like replaying your runs on a map. Make sure location access is activated in your phone settings.': 'Using GPS through location access allows for great functionality like replaying your runs on a map. Make sure location access is activated in your phone settings.',
    txt_global_std_manydriversrelytooheavilyoncruisecontrolitiscomfortableyesbutlethalforfueleconomyinmanycasesusingcruisecontrolontheslightestuphillordownhillwillcausetheenginetofighthardtosatisfyyourwishofmaintainingtheexactspeedyousetinsteadmanagingyourspeedwithyourfootandaimingforasmoothridewillhelpyousaveahugeamountonfuel: "De nombreux conducteurs comptent trop sur le régulateur de vitesse. C'est confortable, oui, mais mortel pour l'économie de carburant dans de nombreux cas. L'utilisation du régulateur de vitesse à la moindre montée ou descente obligera le moteur à se battre pour satisfaire votre souhait de maintenir la vitesse exacte que vous avez définie. Au lieu de cela, gérer votre vitesse avec votre pied et viser une conduite en douceur vous aidera à économiser énormément de carburant !",
    txt_global_std_bethefirsttoreceivenews: 'Soyez le premier à être informé',
    txt_global_std_lookingforusertochallenge: 'Looking for user to challenge...',
    txt_global_std_p2pitstimetosplityourcostformonthofph: 'Il est temps de diviser votre coût pour le mois de {month}',
    txt_global_std_enterzipcode: 'Entrer le code postal',
    txt_global_std_top3bestdrives: 'Top 3 des meilleurs disques',
    txt_global_std_slideintrotext2: 'Simple overview of your insurance and your trips',
    txt_global_std_KristofferssonMusic: 'I like to listen to Godsmack.',
    txt_global_std_contactinfogreaterthaneuformoreinformationonhowtoactivatethismodule: "Contactez info@greaterthan.eu pour plus d'informations sur l'activation de ce module",
    txt_global_std_80percofswedesdrivewell: '80% of the Swedes drive well',
    txt_global_std_PaymentTrouble2_Text1Moderna: 'a lack of funds in your bank account',
    txt_global_std_startguidetip3: 'Cliquez sur le bouton Lecture en bas au centre de l&#39;écran.',
    txt_global_std_driverperiodic: 'Driver periodic',
    txt_global_std_getahighecoscoreontrip: 'Get a high Eco-score on your trip.',
    txt_global_std_lists: 'Lists',
    txt_global_std_carsagreementsinsurance: 'Voitures & contrats / assurances',
    txt_global_std_NotActivatedAccount_Text1: 'You haven’t used your Enerfy - Insurance with Moderna yet. For your car insurance to work properly, you need to install the {appName} Plug-in. Just follow the steps in the app and then you’re done.',
    txt_global_std_enteravalidchassisnumber: 'Entrez un numéro de châssis valide.',
    txt_global_std_RecieptMail_Text1: 'Specification',
    txt_global_std_p2pyoudonthaveanycostsplitsorinvoicesyet: "Vous n'avez pas encore de répartition des coûts ou de factures.",
    txt_global_std_p2pacceptmarketingandprivacy: 'Acceptez la politique de confidentialité et inscrivez-vous à la newsletter.',
    txt_global_std_getyourprice: 'Get your price',
    txt_global_std_p2pselectfriendsoragroup: 'Sélectionnez des amis ou un groupe',
    txt_global_std_cimage4h: ' PRÉVOYEZ DE VOUS ARRÊTER FRÉQUEMMENT',
    txt_global_CoreTemplate_yourmostusedfunctions: 'Your most used functions',
    txt_global_std_notconnectednoaddress: ' Aucune connexion – aucune adresse',
    txt_global_std_savingsisbasedonrisklevelanddistance: 'L&#39;épargne est basée sur le niveau de risque et la distance',
    txt_global_std_searchfriends: ' Chercher des amis',
    txt_global_std_Recruit_Text1: 'Använd min kod',
    txt_global_std_p2pnofriendsfoundpleaseinvitefriend: 'Aucun ami trouvé, veuillez inviter un ami',
    txt_global_std_insureddistance_used_100__1: 'Du har nu använt din körsträcka. För att få full kompensation är det viktigt att du inte överskrider antalet kms som valts. Vänligen kontakta eller justera körsträckan här.',
    txt_global_std_p2psendrequest: 'Envoyer une demande',
    txt_global_std_usersupportcenter: "Centre d'assistance aux utilisateurs",
    txt_global_std_experiencethebenefitswithinsurance: 'Experience the benefits with {appName} - Insurance with Moderna now!',
    txt_global_std_drivingstartandstopsettings: "Paramètres de démarrage et d'arrêt de conduite",
    txt_global_std_aaninsuranceadjusterwillcontactyouwithin24hoursofyourclaimbeingsubmittedtheentireclaimsprocesswilldependonthecomplexityoftheclaim: "R : Un expert en sinistres vous contactera dans les 24 heures suivant la soumission de votre réclamation. L'ensemble du processus de réclamation dépendra de la complexité de la réclamation.",
    txt_global_std_signedinas: 'Logué en tant que',
    'txt_global_std_3) Press Next and let Enerfy try to find the device.': '3) Press Next and let {appName} try to find the device.',
    txt_global_std_pleaseenablebluetoothlocationservicesandmotionfitness: 'Veuillez activer Bluetooth, les services de localisation et Motion & Fitness.',
    'txt_global_std_top5%': 'Top 5%',
    txt_global_std_createanaccounttostartyour30dayfreetrial: 'Créez un compte pour commencer votre essai gratuit de 30 jours.',
    txt_global_std_members3: 'membres',
    txt_global_std_DrivingScore_Hello: 'Hei!',
    txt_global_std_timeframe: 'Time frame',
    txt_global_std_yourchassisshouldcontainacombinationofnumbersandletters: 'Votre châssis doit contenir une combinaison de chiffres et de lettres.',
    txt_global_std_directtoapp: ' Directement vers l’application',
    txt_global_std_cimage10d: ' Roulez avec le rapport le plus élevé possible et maintenez un régime moteur faible.',
    txt_global_std_TidemandInterests: 'Sports aquatiques, Sports automobiles, passer son temps avec des amis',
    txt_global_std_firsttimeusingtheserviceregisterfromsignuplink: 'Première fois que vous utilisez le service ? Inscrivez-vous depuis {signUpLink}',
    txt_global_std_youhavedeniedthelocationpermission: 'Vous avez refusé l&#39;autorisation de localisation',
    txt_global_std_somethingwhentwrongtryagain: ' Il y a eu une erreur, veuillez réessayer.',
    txt_global_std_Intro_Text12: 'Innstillinger-Sparing-Verving',
    txt_global_std_PaymentTrouble3_Text7: 'TFF will take out a very high fee for every day the car is uninsured, which your car will be within 48 hours if you do not pay. All registered vehicles must have third party insurance in accordance to Swedish law.',
    txt_global_std_yourreporthasbeensentsuccessfully: 'Votre rapport a été envoyé avec succès!',
    txt_global_std_fromtheleftsidetoolbarhoverorclickontheiconstonavigatethroughtheportaltoitsdifferentcomponentsandpages: "Dans la barre d'outils de gauche, survolez ou cliquez sur les icônes pour naviguer dans le portail vers ses différents composants et pages.",
    txt_global_std_october: ' Octobre',
    txt_global_std_Insurance_EcoSafe: 'EcoSafe discount*',
    'txt_global_std_drivedetailshavebeensentto{email}': ' Les détails du trajet ont été envoyés à {email}',
    txt_global_std_payouts: 'Paiements',
    txt_global_std_choosesetup: 'Choisissez la configuration',
    txt_global_std_InsuranceMail_Text31: 'Maskin upp till 5000 mil',
    txt_global_std_Otherperson: 'Annan person',
    txt_global_std_picturereceived: 'Image reçue',
    txt_global_std_oliversolbergDescription: 'Son of Petter Solberg. He drives his father’s car for the Nordic team.',
    'txt_global_std_H.SolbergDescription': 'Solberg är den äldre bror till 2003 FIA World Rally Champion Petter Solberg och genom sin svenska maka Maud, styvfar till den svenska rallydrivaren Pontus Tidemand. En av sportens mest erfarna förare, när han är i spåret, har han makt att skämma bort ungdomarna.',
    txt_global_std_PaymentTrouble4: 'Last reminder: Trouble with payment',
    txt_global_std_agent: 'Agent',
    txt_global_std_cimage32h: ' CHAQUE ACCÉLÉRATION COÛTE DE L’ARGENT',
    txt_global_std_tryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagain: "• Essayez de réinitialiser la connexion de votre voiture à votre téléphone en le supprimant, puis en l'ajoutant à nouveau.",
    txt_global_std_whendrivingonmotorwaystoomanydriverspumptheacceleratorpedalupanddowninaneverendingpattern100kmhto110kmhthen110kmhto100kmhandsoonthistypeofdrivingusesunnecessaryfuelkeepingasafesuitablefollowingdistancecanhelpavoidthetemptationtovaryspeedunnecessarily: "Sur les autoroutes, trop de conducteurs poussent la pédale d'accélérateur de haut en bas sans fin : 100 km/h à 110 km/h, puis 110 km/h à 100 km/h et ainsi de suite ! Ce type de conduite consomme inutilement du carburant. Garder une distance de suivi sûre et appropriée peut aider à éviter la tentation de varier inutilement la vitesse.",
    txt_global_std_NotCreatedAccount4_Text2: "We just need to know that everything works fine. It would be sad if something was wrong or didn't work as it should.",
    txt_global_std_reward: ' Récompense',
    txt_global_std_changeyourcontactdetails: 'Changer vos coordonnées',
    txt_global_std_cimage3d: ' Essayez d’anticiper ce qui va se passer',
    txt_global_std_thepumper: 'La pompe',
    txt_global_std_hereisthetestkey: 'Voici la clé de test',
    txt_global_std_headers: 'En-têtes',
    txt_global_std_TerminationMail_Text5: 'However, you must still pay the unpaid insurance premium period and any traffic insurance fee to come to the Motors Insurers Association.',
    txt_global_std_3selectyourcarinthelist: '3. Sélectionnez votre voiture dans la liste:\n',
    txt_global_std_textaboutwhentranslationswillbevisibleinappetc: "Texte indiquant quand les traductions seront visibles dans l'application, etc.",
    txt_global_std_useruploadtemplate: "Modèle de téléchargement d'utilisateur",
    txt_global_std_joinwithactivationcode: "Joindre avec le code d'activation",
    txt_global_std_communityteams: 'Équipes communautaires',
    txt_global_std_resendemail: "Ré-envoyer l'email",
    txt_global_std_Insurance_Charged: 'CHARGED',
    txt_global_std_rememberthatacarinsuranceisrequiredbylawinordertodrive: 'Remember that a car insurance is required by law inorder to drive.',
    txt_global_std_headerpassword: ' Mot de passe',
    txt_global_std_f_lanzwhyjoin: 'Tout en découvrant les défis auxquels nous sommes confrontés quotidiennement sur les routes, profitons-en en même temps.\n' +
      "Apprendre en s'amusant, c'est toujours de meilleurs résultats",
    txt_global_std_OgierFacts: "He is the current holder of the World Rally Drivers' Championship, having won the title six times, in 2013, 2014, 2015, 2016, 2017 and 2018. With 44 victories in the World Rally Championship and six consecutive WRC titles, he is the second most successful WRC driver, after former Citroën WRC teammate Sébastien Loeb (nine titles).",
    txt_global_Fia_Receipt_Text1: 'Vous êtes connecté',
    txt_global_std_allinformationaboutyourinsurance: 'All information about your insurance placed on a single spot',
    txt_global_std_teamranking: 'Classement par équipe',
    txt_global_std_p2pawaitingapproval: "En attente d'approbation",
    txt_global_std_TermConfirmSub_Text10: 'Sincerely,\r\n{appName}',
    txt_global_std_MSIG_InsuranceLetter35: 'MS First Capital Insurance Limited 6 Raffles Quay # 21-00 Singapour 048580 Tél.: (65) 6222 2311 Télécopie: (65) 6222 3547 www.msfirstcapital.com.sg',
    txt_global_std_PreSaleDetails_Text37: 'Compensation and valuation rules',
    txt_global_std_exclude: ' Exclure',
    txt_global_std_sendinvite: 'Envoyer une invitation',
    txt_global_std_FirstDrive_Text4: "We measure your premium through your smartphone and the {appName} Plug-in that you click into the car. Thanks to the {appName} Plug-in, your trip is analyzed while you drive and continuously reported to our 'cloud' that analyze and send information to your mobile phone showing which premium rate your driving reflects. ",
    txt_global_std_milesmi: 'Milles (mi)',
    txt_global_std_finalistsheat1: 'Finalistes - Manche 1',
    'txt_global_std_The picture above shows where you can usually find the OBD connector in your vehicle. OBD connector is standard in cars since 1996 in the USA and since 2001 in the EU.': 'The picture above shows where you can usually find the OBD connector in your vehicle. OBD connector is standard in cars since 1996 in the USA and since 2001 in the EU.',
    txt_global_std_whenwouldyouliketoterminateyourinsurance2: 'Quand souhaitez-vous mettre fin à votre assurance?',
    txt_global_std_fixitnow: 'Répare le maintenant',
    txt_global_std_letslookforyourfriends: "Let's look for your friends!",
    txt_global_std_p2ppushbodyaddedexpences: 'Vérifiez les dépenses lors de la réservation. De: {bookingperiod}',
    txt_global_std_theloyaltysystem: 'Le système de fidélité',
    txt_global_std_entertheemailthatyouusedwhenyouregisteredyourinsurance: "Entrez l'email que vous avez utilisé lors de l'enregistrement de votre assurance",
    txt_global_std_choosealwaysorallowwhileusing: `Choisissez "Toujours" ou "Autoriser lors de l'utilisation".`,
    txt_global_std_gpsactive: ' GPS actif',
    txt_global_std_discardchanges: 'Annuler les modifications',
    txt_global_std_theuseriscurrentlylockedoutfortoomanyfailedattempts: "L'utilisateur est actuellement verrouillé pour trop de tentatives infructueuses",
    txt_global_std_NotCreatedAccount1_Text5Moderna: 'When you signed up for Moderna Smart Flex you got seven days to get started. Enter your verification code for the insurance in your {appName} app, otherwise you will have to pay for day premium. It now remains three days of your starting-period.',
    txt_global_std_levelprogress: 'Progression de niveau',
    txt_global_std_ActivationLetter_Text5: 'What you need to do now: ',
    txt_global_std_MSIG_InsuranceLetter23: '* Dans la mesure où la personne qui conduit est autorisée à conduire le véhicule automobile conformément à la licence ou à toute autre loi ou réglementation, ou a été autorisée à le faire et n&#39;est pas empêchée par une ordonnance d&#39;un tribunal judiciaire ou en raison d&#39;un texte législatif ou réglementaire en ce sens de conduire le véhicule à moteur.',
    txt_global_std_accidentguide: 'Accident guide',
    txt_global_std_p2plendyourkintotofamilyandfriends: 'prêtezvotrek à la famille et aux amis',
    txt_global_std_InsuranceMail_Text19: 'Vehicle damage',
    txt_global_std_BeforeFirstBill_Text1Enerfy: 'We will soon draw money from your card for your car insurance. We have compiled the most common questions that our customers have when receiving their first invoice. ',
    txt_global_std_automessage_level_15__3: 'Now you are driving unsafe and unprofitable. Remember that you can save money driving safer.',
    txt_global_std_yourdrivewillendin: ' Votre trajet prendra fin dans :',
    txt_global_std_Intro_Text1: 'Takk for at du ønsker å bruke vår Sidekick bilforsikring! For at du skal få mest mulig glede av forsikringen ønsker vi å gi deg en kort og enkel forklaring av hvordan produktet fungerer. Dette vil vi gjøre i 4 enkle steg:',
    txt_global_std_howdoyoumeasuremydrivinganswer: '{appname} utilise l&#39;intelligence artificielle pour mesurer votre conduite. L&#39;application se connecte au cloud et analysera chaque seconde de votre conduite transformée en «profil». L&#39;IA compare vos profils à plus de 750 millions d&#39;autres profils de conduite uniques. L&#39;IA a plus de 16 ans d&#39;expérience pour rechercher la probabilité d&#39;un modèle de conduite d&#39;être impliqué dans un accident. Heureusement, après presque chaque voyage, les conducteurs et les passagers rentrent chez eux en toute sécurité chez leurs proches.',
    txt_global_std_p2ptemppassword: 'Mot de passe temporaire',
    txt_global_std_roadsideassistance: 'Assistance routière',
    txt_global_std_signupforinsurance: 'Sign up for insurance',
    txt_global_std_100pointtripachievementlocked: 'Finish a drive at {scoretype} {score} or better to unlock this achievement and earn 100 points. ',
    txt_global_std_keepingyourmindandeyesonwhatshappeningaroundyouisvitalforyoursafetyandthesafetyofothersphoneactivityandotherdistractionsaffectyourfocusandincreasecrashrisknew: "Le maintien de la vitesse prévue est extrêmement important pour l'économie de carburant. L'errance de l'esprit et le manque de concentration provoquent souvent des variations de vitesse de haut en bas, provoquant des accélérations inutiles, augmentant ainsi la consommation de carburant.",
    txt_global_std_baseurl: 'URL de base',
    txt_global_std_SignupFia_Text1: 'Vous êtes inscrit!',
    txt_global_std_leavechallenge: ' Quitter le défi',
    txt_global_std_accountcreatedlogin: 'Votre compte a été créé, vous pouvez maintenant vous connecter!',
    txt_global_std_ihavesoldmycar2: 'J&#39;ai vendu ma voiture',
    txt_global_std_hi: 'Bienvenue!',
    txt_global_std_photo: ' Photo',
    txt_global_std_TaxInvoice9: 'L&#39;assureur a droit à un paiement de prime au prorata de la couverture de 60 jours (sous réserve d&#39;un minimum de 25 USD).',
    txt_global_std_obdconnected: ' Système de diagnostic embarqué connecté',
    txt_global_std_ActivationLetter_Text8Business: 'Är ni flera förare kan ni upprepa processen i flera olika telefoner.',
    txt_global_std_nodatatoshow_loyalty: 'Aucune donnée à afficher',
    txt_global_std_InsuranceMail_Text12: '35 % lowest amount 1000 ',
    txt_global_std_ifyoudidntmakethisrequestyoucansafelyignorethisemail: "Si vous n'avez pas fait cette demande, vous pouvez ignorer cet e-mail en toute sécurité.",
    txt_global_std_howheadtoheadworks: 'How head to head works:',
    txt_global_std_activationcodeemail1: "Votre code d'activation",
    txt_global_std_p2ppushtitlechangeperiodfromto: 'Période modifiée',
    txt_global_std_minutesshort: 'm',
    txt_global_std_youhavenowreceivedamountyenatcompany: 'Vous avez maintenant reçu {amount} yens chez {company}',
    txt_global_std_NotCreatedAccount4_Text4: 'Sincerely,\r\n{appName}',
    txt_global_std_chooseafile: 'Choisissez un fichier',
    txt_global_std_alertlocationnotalways: 'Set Location Service permission to Always to register drives without data loss.',
    txt_global_std_teamlocation: 'Driver location',
    txt_global_std_nodriversonleaderboard: 'Pas de conducteurs visible sur la carte',
    txt_global_std_nextstep: 'Suivant',
    txt_global_std_registrationnumberalreadyexists: "Le numéro d'enregistrement existe déjà",
    txt_global_std_typeofmessage: 'Type de message',
    txt_global_std_TerminationConfirm_Confirm: 'Termination Confirmation',
    txt_global_std_birthdate: 'Date de naissance',
    txt_global_std_badgeisworthxpointsunlocked: 'Le badge vaut {points} points',
    txt_global_std_realtimefeedbackonthego: 'Commentaires en temps réel lors de vos déplacements.',
    txt_global_std_youarereadytogodriveandearnyourfirstbonus: 'Vous êtes prêt à partir! Conduisez et gagnez votre premier bonus :)',
    txt_global_std_Fia_Receipt_Text13: 'info@fiasmartdrivingchallenge.com',
    txt_global_std_inactive: ' Inactif',
    txt_global_std_retardation: ' Retard',
    txt_global_std_Footer_Moderna: 'Moderna Försäkringar, filial till Tryg Forsikring A/S | Bolagsverket | Org. Nr. 516403-8662 | Erhvervsstyrelsen | CVR-No. 24260 666 | Klausdalsbrovej 601 | DK-2750 Ballerup | Danmark ',
    txt_global_std_totalminutes: 'Temps total',
    txt_global_std_yesihaveredeemedmypoints: "Oui, j'ai échangé mes points!",
    txt_global_std_jointeam: 'Rejoignez',
    txt_global_std_p2pblockeddate: 'Date bloquée',
    txt_global_std_getstartedmanual: 'Manuel de démarrage',
    txt_global_std_whenyoulentthecartheenerfyapp: 'When you lend the car, the Enerfy app must be running in the background and Bluetooth enabled on the phone. When you choose to return the car, your driving will be compiled at a cost based on how you run with Enerfy - Insurance with Moderna. That sum, you can easily swish the car owner when the car is backed up.',
    txt_global_std_Insurance_Currency: 'SEK',
    txt_global_std_bringoutyourcompetitivesideandreceivethehighestscoreoftheevent: "Faites ressortir votre côté compétitif et recevez le meilleur score de l'événement.",
    txt_global_std_TerminationMail_Hello: 'Termination of car insurance',
    txt_global_std_NotCreatedAccount4_Hello: 'Hello',
    txt_global_std_InsuranceApp_Text2: 'Drive Safetly!',
    txt_global_std_timeinconstantspeed: ' Temps en vitesse constante',
    txt_global_std_reportisloadedherewhenresultisreturned: 'Report is loaded here when result is returned.',
    txt_global_std_claimsdepartment: 'Service des réclamations',
    txt_global_std_p2pwrongcodetryagain: 'Mauvais code. Réessayer',
    txt_global_all_wednesday: ' Mercredi',
    txt_global_std_MSIG_InsuranceLetter14: 'Institution financière',
    txt_global_std_doyouacceptthechallenge: 'tu relèves le défi?',
    txt_global_std_off: ' Off',
    txt_global_std_oversigtovernivauetpdintur: 'Oversigt over nivauet på din tur.',
    txt_global_std_allowallthetime: 'Laisser tout le temps',
    txt_global_std_youcandobetter: 'Tu peux faire mieux!',
    txt_global_std_areyouinthiscar: 'Are you sitting in this car',
    txt_global_std_entervalidationcode: 'Enter validation code',
    txt_global_std_yourdrivewasnotregisteredifyouhadyourenerfypluginconnectedwhiledrivingthecarbutdidnothavetheappbluetoothorgpslocationservicesactivesothatitregistersdrivingdatacompletedrunswillbechargedafterwardsinpremiumlevel10to15: "Votre lecteur n'a pas été enregistré. Si vous aviez votre plug-in Enerfy connecté pendant que vous conduisez la voiture mais que l'application, le Bluetooth ou le GPS / services de localisation n'étaient pas actifs pour enregistrer les données de conduite, les courses terminées seront ensuite facturées dans les niveaux premium 10 à 15.",
    txt_global_std_atrustedscoreusedbyfia: 'Un score de confiance utilisé par la FIA',
    txt_global_std_thedriverwiththebestscorewinstheround: 'Le pilote avec le meilleur score remporte la manche!',
    txt_global_std_usertermsandconditions: "Conditions générales d'utilisation",
    txt_global_std_automessage_level_1__2: 'Wow! Such driving. We like!',
    txt_global_std_verifyemail: 'Vérifier les courriels',
    txt_global_std_yougotmessage: 'You got message!',
    txt_global_std_Insurance_Text3: 'Rörlig körpremie',
    txt_global_std_authentication: 'Authentification',
    txt_global_std_InsuranceMail_DaypremiumCost: 'per day until the insurance is verified.',
    txt_global_std_tomasengechamp: "Premier pilote tchèque en F1 et Indycar, champion du circuit tchèque, champion d'Allemagne Fford",
    txt_global_std_carrorewardworkshops: 'Ateliers de récompense Carro',
    txt_global_std_welcometo2: 'Bienvenue à',
    txt_global_std_cardnummer: 'N ° de carte.',
    txt_global_std_henningsolbergDescription: 'Solberg est le frère ainé du champion du monde du WRC en 2003, Petter Solberg, et par sa femme suédoise, Maud, le beau-père du pilote suédois Pontus Tidemand. Un des pilotes les plus expérimentés au monde des sports automobile. Quand il a la pêche il a le potentiel de faire les plus jeunes se sentir gênés. ',
    txt_global_std_cimage10h: ' ROULEZ AVEC UN RÉGIME MOTEUR FAIBLE ET AVEC UN RAPPORT ÉLEVÉ',
    txt_global_std_signedinasv2: 'Connecté en tant que',
    txt_global_std_insureddistance_used_80__1: 'Du har nu använt 80% av din årliga valda körsträcka. Vill du justera körsträckan?',
    txt_global_std_Insurance_Email: 'E-mail',
    txt_global_std_p2pbookingmodifiedsuccessfully: 'Réservation modifiée avec succès',
    txt_global_std_ConfirmFia_Text4: ' Heure',
    txt_global_std_p2pfaqandsupport: 'FAQ et assistance',
    txt_global_std_holmgrens_description: 'Book service and repair vehicle.',
    txt_global_std_congratulationsyoursetupiscompleteandyouarereadytogo: 'Toutes nos félicitations! Votre configuration est terminée et vous êtes prêt à partir.',
    txt_global_std_nottowed: 'Non remorqué',
    txt_global_std_zipdriver: 'Pilote Zip',
    txt_global_std_userstate: 'État utilisateur',
    txt_global_std_p2pyoucaninvitexfriendspercar: 'Vous pouvez inviter {number} amis par voiture',
    txt_global_std_online: ' En ligne',
    txt_global_std_activatebonustemplatetitle: 'Activate bonu',
    txt_global_std_obdreaderonitsway: 'Lecteur OBD en route !',
    txt_global_std_cimage19h: ' MAINTENEZ VOTRE ACCÉLÉRATEUR',
    txt_global_std_p2pchooseafriendorinviteanewfriend: 'Choisissez un ami ou invitez un nouvel ami',
    txt_global_std_notificationsisagreatwaytostayontopallowthemtoletusguideyoutowardsbecomeanevensmarterdriverandtakeyourdrivingtothenextlevel: 'Les notifications sont un excellent moyen de rester au top. Permettez-leur de nous laisser vous guider pour devenir un conducteur encore plus "smart" et faire passer votre conduite au niveau supérieur.',
    txt_global_std_mpltipafpp: "Il s'agit d'un conducteur qualifié, cependant, parfois lors d'un voyage et seulement pour une courte période de temps, ce conducteur a tendance à perdre le focus sur Cool Driving. Certaines distractions ou certains stress peuvent amener ce conducteur à ignorer la sensibilisation à la sécurité. Ceci est souvent résolu en discutant avec le conducteur et en demandant sa considération.",
    txt_global_std_Head2Head_Text3: 'Drive Safetly!',
    txt_global_std_translationtool_upsell_pitch_bulletpoint1: 'Rendez rapidement votre application multilingue',
    txt_global_std_itisa12digitcodethatyoureceivefromtheeventorchallengeorganizer: "Il s'agit d'un code à 12 chiffres que vous recevez de l'organisateur de l'événement ou du challenge.",
    txt_global_std_searchandmanageagreements: 'Rechercher et gérer des accords',
    txt_global_std_afollowthesestepstoconnecttheapptoyourcar1pressthedrivebuttonintheapp2attachtheobddevicetothecableandplugitintothe12vpowercigarettelightersocketinyourcar3startyourcarsengine4selecttheobdreaderinthelistintheappandcontinue: "R : Suivez ces étapes pour connecter l'application à votre voiture :\n" +
      "1. Appuyez sur le bouton de conduite dans l'application\n" +
      "2. Connectez le périphérique OBD au câble et branchez-le sur la prise d'alimentation 12 V (allume-cigare) de votre voiture.\n" +
      '3. Démarrez le moteur de votre voiture\n' +
      "4. Sélectionnez le lecteur OBD dans la liste de l'application et continuez.",
    txt_global_std_calendar: 'Calendrier',
    txt_global_std_p2pnonsequentialdatesselected: 'Dates non séquentielles sélectionnées',
    txt_global_std_sortby: ' Trier par',
    txt_global_std_NotActivatedAccount: 'You have not activated your car insurance',
    txt_global_std_youcannoteditthisfromvisualeditor: "Vous ne pouvez pas le modifier à partir de l'éditeur visuel.",
    txt_global_std_savetimeandplace: 'Save time and place. Take picture.',
    txt_global_std_anerroroccured: 'Error occured, please try later.',
    txt_global_std_PreSaleDetails_Text13: 'When an injury occurs, you usually bear some of the cost yourself, called a deductible. You can see the traffic, theft and vehicle damage deductibles in your insurance policy. Other deductibles are shown in the relevant section or in your insurance policy.',
    txt_global_std_teamcolon: 'Équipe:',
    txt_global_std_exportxml: ' Exporter au format XML',
    'txt_global_std_Could not connect to your car. Have you completed the following steps?': 'Could not connect to your car. Have you completed the following steps?',
    txt_global_std_youraveragelevelis123: 'Votre niveau moyen est',
    txt_global_std_triptypes: ' Types de trajets',
    txt_global_std_PreSaleDetails_Text72: 'If we do not agree',
    txt_global_std_belowisalistofalluserswhosnotcompletedthisstep: "Vous trouverez ci-dessous une liste de tous les utilisateurs qui n'ont pas terminé cette étape",
    txt_global_std_ChicheritFacts: 'Monster Athlete Guerlain Chicherit steps up to the FIA World Rallycross Championship in 2018 with his own GC Kompetition team driving the new Prodrive-developed Renault Megane RS. A professional skier and four-time world champion in freeriding, Chicherit holds the record for becoming the first man in history to complete an unassisted backflip in a car. Chicherit made his World RX debut in the JRM Mini RX Countryman in 2015.',
    txt_global_std_Insurance_Plugin: '{appName} Plug-in',
    txt_global_std_p2ptotal: 'Total:',
    txt_global_std_sendasemail: 'Envoyer par e-mail',
    txt_global_std_advancedusersettingsfor: 'Paramètres utilisateur avancés pour',
    txt_global_std_introslide1text: 'A digital insurance for you who drive less or better than everybody else.',
    txt_global_std_bluetoothreasontext: 'Une connexion Bluetooth est nécessaire pour vous assurer que vous êtes assis dans votre voiture.',
    txt_global_std_Insurance_Text4: 'Du betalar för dina sträckor som du har kört för under perioden som anges. Beloppet för varje enskild körning summeras med den EcoSafe-besparing du har kört till dig.',
    txt_global_std_wrongpassword: 'Mauvais mot de passe',
    txt_global_std_youaredrivingregno: ' Vous êtes en train de conduire {regno}',
    txt_global_std_p2pblockmycar: 'Bloquer ma voiture',
    txt_global_std_loadingteamleaderboard: 'Chargement en cours du classement',
    txt_global_std_successfulpasswordchange: 'Vous avez changé votre mot de passe avec succès.',
    txt_global_std_dayd: ' j',
    txt_global_std_inviteandinspireeverydriverinyournetworktoparticipateandgetnewdataonyouresgperformance: 'Invitez et inspirez chaque conducteur de votre réseau à participer et à obtenir de nouvelles données sur votre performance ESG',
    txt_global_std_startadrive: ' Commencer un trajet',
    txt_global_std_unabletoexportdata: 'Unable to export data',
    txt_global_std_statusdriving: ' En conduite',
    txt_global_std_tilladnotifikationer: 'Notification Tillad',
    txt_global_std_automessage_level_14__3: 'Take a look at the feedback to get tips on how to get a better driving score.',
    txt_global_std_Insurance_VATtext: 'and VAT ',
    txt_global_std_safedriverweeklyunlockedkey: 'Excellent travail {utilisateur} ! Vous avez conservé un score supérieur à {score} pendant une semaine.',
    txt_global_std_wecannotfindanydata: 'Nous ne pouvons trouver aucune donnée!',
    'txt_global_std_Start drive': 'Start drive',
    txt_global_std_qhowwilligetpaidforaclaim: 'Q : Comment serai-je payé pour une réclamation ?',
    txt_global_std_notripsperday: 'Nombre de trajets par jour',
    txt_global_std_kintofaqlink1: 'Manuel de mise en route (japonais)',
    txt_global_std_ShareCar_Text2: 'Det betyder att du kan låna bilen, köra försäkrad med Enerfy - Försäkring med Moderna och dessutom smidigt ordna med betalning efteråt för kostnader för bränslet m.m.',
    txt_global_std_ranking: ' Classement',
    txt_global_std_WelcomeLetter: 'Welcome to {appName}',
    txt_global_std_BeforeBill_Text6: 'Kör inte på för höga varvtal.',
    txt_global_std_youmustchoseareason: 'You must choose a reason',
    txt_global_std_noconnectiontocar: 'Pas de connexion à la voiture',
    txt_global_std_yournicknameshouldcontainatleastthree: ' Votre pseudonyme doit contenir au minimum trois (3) caractères sans espaces.',
    txt_global_std_decline: ' Décliner',
    txt_global_std_p2pconfirm: 'Confirmer',
    txt_global_std_tobeabletostartadrive: "Pour pouvoir démarrer un entraînement, nous avons besoin d'un accès au Bluetooth, aux services de localisation et au motion fitness.",
    txt_global_std_SendMyPosition_Text1: ' vous a envoyé sa position à partir de l’application {appName}.',
    txt_global_std_enterbasepremium: 'Entrer la prime de base',
    txt_global_std_colortheme: 'Thème de couleur :',
    txt_global_std_1gotosettingsforappnameinyourphonebrb2allowaccesstomotionfitnessb: "1. Accédez aux paramètres de {appname} sur votre téléphone. <br> <b> 2. Autorisez l'accès à Motion & Fitness.</b>",
    txt_global_std_Reciept_Text3: 'Drive Safetly!',
    txt_global_std_mostpopular: 'Le plus populaire',
    txt_global_std_signinaftersignuperrortryloggingin: 'Sign in after signup error. Try logging in.',
    txt_global_std_challengeaverage1: 'Moyenne du défi',
    txt_global_std_statistikfordennekretur: 'Statistiques pour denne køretur',
    txt_global_std_OSolbergQuote: 'Citation à venir ...',
    txt_global_std_invitationsent: 'Invitation envoyée.',
    txt_global_std_tilladatappensenderdignotifikationernrdererregistreretnyekreture: "Tillad à App'en expéditeur dig notifikationer, når der er registreret nye køreture.",
    txt_global_std_p2pcarcare: 'Entretien de la voiture',
    txt_global_std_p2pemailopenapp: 'OUVRIR {appname}',
    'txt_global_std_Al-KuwariMusic': 'Classic music.',
    txt_global_std_introslide2header: 'Save money by driving',
    txt_global_std_pleasefirstfillinyourfirstnameandlastname: ' Veuillez d’abord indiquer votre prénom et votre nom.',
    txt_global_std_nicknamenicknamealreadyexists: 'Le pseudo {nickname} existe déjà',
    txt_global_std_Insurance_Text7: 'EcoSafe-besparing',
    txt_global_std_2makesureyourcarsbluetoothison: '2. Assurez-vous que le Bluetooth de votre voiture est activé.',
    txt_global_std_enginemustberunning: ' Le moteur doit être en marche',
    txt_global_std_qualificationfinalstarts30thmarch: 'La finale de qualification commence le 30 mars.',
    txt_global_std_shareinvite: "Partager l'invitation",
    txt_global_std_p2pselecteddateandtime: 'Date et heure sélectionnées',
    txt_global_std_forthisapptoworkproperlyweneedtoaccessyourlocationbluetoothandphysicalfitnesspermissionthelocationserviceisusedinbackgroundforthedrivingfeaturetoanalyzeyourdrivingwithoptimalresultstheappalsodoesthisinthebackgroundwevalueyourprivacyandwillnotsharethisinformationwithathirdpartyentity: "Pour que cette application fonctionne correctement, nous devons accéder à votre emplacement, Bluetooth et autorisation de forme physique. Le service de localisation est utilisé en arrière-plan pour la fonction de conduite. Pour analyser votre conduite avec des résultats optimaux, l'application le fait également en arrière-plan. Nous respectons votre vie privée et ne partagerons pas ces informations avec une entité tierce.",
    txt_global_std_PaymentTrouble2_Text8Enerfy: '* If your insurance is not paid, the TFF will take over the debt and the handling of the unpaid premium for the time the policy has been in effect with {appName}. The TFF charges a very high daily fee for every day that your car is not insured. Traffic insurance is obligatory and means that you are insured against personal injury and damage to property of another.',
    txt_global_std_kintofaq9a: 'Parce que l&#39;IA doit connaître la route pour pouvoir calculer le score et vous aider en cas d&#39;urgence. L&#39;application Kinto ne partagera jamais la position ou toute autre donnée avec un tiers.',
    txt_global_std_qhowdoiconnecttomycarsbluetooth: 'Q : Comment puis-je me connecter au Bluetooth de ma voiture ?',
    txt_global_std_passwordchanged: 'Mot de passe changé',
    txt_global_std_confirm: 'Confirm',
    txt_global_std_deleteaccount: 'Supprimer le compte',
    txt_global_std_repetitionsnumber: 'Nombre de répétitions',
    txt_global_std_collectablebadges: 'Insignes à collectionner',
    txt_global_std_youbooked5timesinonemonthyouhaveearned1500points: 'Vous avez réservé 5 fois en un mois. Vous avez gagné 1500 points.',
    txt_global_std_goodthatyouarekeepingyouraccountsecure: "C'est bien que vous gardiez votre compte en sécurité.",
    txt_global_std_business: 'Business',
    txt_global_std_BeforeFirstBill_Hello: 'Hello',
    txt_global_std_drivemorethan1miletogetmoredetailedanalyticsaboutyourtrip: 'Conduisez plus de 1 mile pour obtenir des analyses plus détaillées sur votre voyage.',
    txt_global_std_summaryofanalyse: "Résumé de l'analyse",
    txt_global_std_PreSaleDetails_Text18: 'Which types of claims are covered by the insurance?',
    txt_global_std_youearnedapercentdiscountbydrivingsafe: 'Vous avez obtenu une remise de {percent} % en conduisant prudemment',
    txt_global_std_thepiggybank: 'La tirelire',
    txt_global_std_youareamongthebestappnamedrivers: 'Hou la la! Vous êtes parmi les meilleurs pilotes {appname} !',
    txt_global_std_step3: 'Étape 3',
    txt_global_std_upcoming: ' À venir',
    txt_global_std_pigsinvited: 'Parrainez des amis',
    txt_global_std_weregladtohaveyouonboardwatchourvideoguideforsomequicktipstogetyouupandrunning: 'Nous sommes heureux de vous avoir à bord. Regardez notre guide vidéo pour quelques conseils rapides pour vous aider à démarrer.',
    txt_global_std_youmayhavetogotoyourbluetoothsettingsforbothyourphoneandcar: 'Vous devrez peut-être accéder aux paramètres Bluetooth de votre téléphone et de votre voiture.',
    txt_global_std_buy2: 'Acheter',
    txt_global_std_p2pborrowyour: 'emprunter votre',
    txt_global_std_Insurance_AmountToPay: 'Amount to pay',
    txt_global_std_invitetitle: 'Invite to {company}',
    txt_global_std_f_lanzmusic: 'Classique, Hip Hop et Danse',
    txt_global_std_HSolbergWhyjoin: 'For the first time, my fans and I can compete together to win an FIA Challenge. ',
    txt_global_std_annulationdate: 'Terminated insurance date ',
    txt_global_std_friendsreferral: "Référence d'amis",
    txt_global_std_qcanisignupforinsurancetobegintoday: "Q : Puis-je souscrire à une assurance pour commencer aujourd'hui ?",
    txt_global_std_driverdetailedreport: 'Detailed driver report',
    txt_global_std_FriendRequest_Text4: '/{appName}',
    txt_global_std_keepautostartenabled: 'Keep auto start enabled?',
    txt_global_std_MSIG_Onboarding9: '3. Conduisez!',
    txt_global_std_gooddrivingtechnique: ' Bonne technique de conduite',
    txt_global_std_ShareCar_Text7: 'Sedan är det klart för att köra, men se till att {appName}appen är igång i bakgrunden under tiden som du kör',
    txt_global_std_PreSaleDetails_Text20: 'Motor Insurance',
    txt_global_std_howdoifileaclaim: 'Comment déposer une réclamation ?',
    txt_global_std_getfeedbackandgetbetter: 'Get feedback and get better',
    txt_global_std_SignupFia_Text3: ' Le FIA SDC est une initiative qui verra les plus grandes stars des circuits et des pilotes du monde entier s’affronter lors d’un défi épique. En utilisant l’application pour smartphone, vous pourrez rejoindre une équipe, défier les meilleurs pilotes au monde et essayer de remporter le titre FIA du conducteur le plus "smart".',
    txt_global_std_yourappnamehastobeatleasttwocharacterslong: 'Le nom de votre application doit comporter au moins deux caractères',
    txt_global_std_appcreator_upsell: 'App Creator - créez votre propre application de conduite de marque en quelques minutes.',
    txt_global_std_advancedusersettings: 'Paramètres utilisateur avancés',
    txt_global_std_weneedpermissiontoaccessyourbluetoothforthisapptowork: 'Nous avons besoin d&#39;une autorisation pour accéder à votre Bluetooth pour que cette application fonctionne. Pour continuer à utiliser cette application, vous devez vous rendre sur votre appareil',
    txt_global_std_Insurance_PaymentPeriod: 'Payment period',
    txt_global_std_p2pfaq10q: 'Et si mon ami endommage ma voiture?',
    txt_global_std_rulesforservice: ' Règles du service',
    txt_global_std_yourinvoicewillsoonbevisiblehere: 'Your invoice will soon be visible here.',
    txt_global_std_itemsperpage: 'objets par page',
    'txt_global_std_Connect your reader to your phone': 'Connect your reader to your phone',
    txt_global_std_idling: ' Régime de ralenti',
    txt_global_std_searchtranslation: 'Recherche Traduction',
    txt_global_std_fiasmartdrivingchallenge: ' FIA Smart Driving Challenge',
    txt_global_std_PreSaleDetails_Text45: 'Machine',
    txt_global_std_HighUsageAlarm_Text7: 'Har du brug for hjælp med installationen, eller har du spørgsmål til {appName}-app’en, er du meget velkommen til at ringe på 44 20 50 05. ',
    txt_global_std_tripnotconnected: 'Trip not connected',
    txt_global_std_helptextsavingsinviteamounttitle: 'Vervebeløp',
    txt_global_std_p2phasreceivedlendingrequest: ' a reçu votre demande de prêt.',
    txt_global_std_Signature_Text4: 'Moderna Försäkringar är en del av Tryg - en av Nordens största försäkringskoncerner',
    txt_global_std_publishchanges: 'Publier les modifications',
    txt_global_std_endinsurance: "Fin de l'assurance",
    txt_global_std_PreSaleDetails_Text70: 'Common claims database for the insurance industry',
    txt_global_std_qhowdoiconnecttomycar: 'Q : Comment puis-je me connecter à ma voiture ?',
    txt_global_std_latestdata: 'Latest data',
    txt_global_std_congratulationyoupositiononleaderboardwasincreased: 'Félicitations ! Votre position sur le classement a été augmentée !',
    txt_global_std_TidemandDescription: 'Le premier gout de Pontus dans la compétition est le cross-karts, où il devient le champion de la Suède et des pays du Nord. En 2011 il est le pilote le plus jeune a avoir remporté le championnat de rallye de Suède. ',
    txt_global_std_p2pfaq8a: "Vous pouvez facilement bloquer votre voiture les jours où vous savez que vous la conduirez vous-même ou que pour d'autres raisons, vous ne souhaitez pas partager votre voiture.\n" +
      '1. aller à la maison\n' +
      '2.Appuyez sur le bouton Calendrier en haut à gauche\n' +
      "3.Puis choisissez la date et l'heure auxquelles vous souhaitez bloquer votre voiture.",
    txt_global_std_NotCreatedAccount3_Text7: 'Vi vil gerne sikre os, at du har alt, hvad du skal bruge for at komme i gang med {appName}. Har du brug for hjælp med installationen, eller har du spørgsmål til {appName}-app’en, er du meget velkommen til at ringe på 44 20 50 05. ',
    txt_global_std_youdrovereallywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Vous avez vraiment bien conduit! Votre moyenne était meilleure que {score} pendant une semaine! Vous avez gagné {points} points!',
    txt_global_std_mailsentutc: 'Mail sent (UTC)',
    txt_global_std_paymentdate: 'Payment date',
    txt_global_std_SulayemMileage: 'Liwa Oasis – Al Ain road through the Empty Quarter Desert, experiencing the spectacular peaceful beauty of the desert, driving on this road will make you calm and collected.',
    txt_global_std_TermConfirmSub_Text6: 'If you do not return your {appName} Plug-in, we will charge you the amount of the Plug-in which is 995 SEK.',
    txt_global_std_failedtosendtothefollowingusers: "Échec de l'envoi aux utilisateurs suivants :",
    txt_global_std_repeatyourpassword2: 'Répétez votre mot de passe',
    txt_global_std_joinedanewteam: 'Joined a new team',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint3: 'Communiquer des messages contextuels au bon moment',
    txt_global_std_pleasereloadortryagainlater: 'Veuillez recharger ou réessayer plus tard.',
    txt_global_std_inviteuser: 'Inviter un utilisateur',
    txt_global_std_nofriendsfound: 'Aucun ami trouvé.',
    txt_global_std_co2lockedkey: 'Conduisez de manière écologique et économisez plus de {co2value} CO₂ par trajet pour gagner ce badge.',
    txt_global_std_addfriendtolist: ' Ajouter un ami à la liste',
    txt_global_std_daysdays: '{jours} jours',
    txt_global_std_bestdriverstoday: 'Best drivers today',
    txt_global_std_nointernetconnection: 'No internet connection',
    txt_global_std_backtoleaderboard: 'Retour au classement',
    txt_global_std_50kmunlockedkey: "Vous avez parcouru une distance de 50 km avec l'application !",
    txt_global_std_warikan_onboarding_title_2: '【わりかんKINTO】アプリ　仮パスワードのご案内',
    txt_global_std_price: 'Price',
    txt_global_std_allowmotionfitness: 'Autoriser Motion & Fitness',
    txt_global_std_locationselectalways: 'Emplacement - sélectionnez "toujours"',
    txt_global_std_sms: ' SMS',
    txt_global_std_idonthaveanaccount: 'Je n&#39;ai pas de compte?',
    txt_global_std_allowlocationservices: 'Autoriser les services de localisation',
    txt_global_std_chooseVehicle: 'Choose vehicle',
    txt_global_std_activatecode: "Code d'activation",
    txt_global_std_achievementtool_upsell_pitch_bulletpoint3: 'Incitez les utilisateurs à utiliser vos propres KPI, y compris les réductions de CO2, les réductions des risques, les journées sans voiture, etc.',
    txt_global_std_TaxInvoice11: 'Le paiement complet de la prime doit être effectué avant la création de la police au moment de la documentation.',
    txt_global_std_hereyoucanseehowyouhavedriven: 'Ici vous pouvez voir comment vous avez conduit.',
    txt_global_std_appnamewallet: 'Portefeuille {appname}',
    txt_global_std_canbeearnedwhenyoufocustoimproveinthisspeed: ' peut être obtenu(e) lorsque vous cherchez à améliorer cette vitesse (depends on the subject)',
    txt_global_std_wildfire: 'Incendies!',
    txt_global_std_letsgo: 'Allons-y',
    txt_global_std_theresetlinkwillexpirein20minafterthatyoullneedtosubmitanewrequestinordertochangeyourpassword: 'Le lien de réinitialisation expirera dans 20 minutes, après quoi vous devrez soumettre une nouvelle demande afin de changer votre mot de passe.',
    txt_global_std_cwelcome3: 'Hi {name}!',
    txt_global_std_warikan_onboarding13: "Horaires de la réception de 9h00 à 18h00 Ouvert toute l'année (hors vacances de fin d'année et du jour de l'an)\n",
    txt_global_std_challengesomeone: ' Défier quelqu’un',
    txt_global_std_RecieptMail: 'Reciept',
    txt_global_std_settingssaved: ' Paramètres sauvegardés',
    txt_global_std_p2pviewreceipt: 'Voir le reçu',
    txt_global_std_greatstreak: 'Grande séquence!',
    txt_global_std_vouchercodes: 'Voucher codes',
    txt_global_std_getstarted2: 'Commencer',
    txt_global_std_p2pdistancetravelled: 'Distance parcourue',
    txt_global_edj_fuelprice: 'Fuel price',
    txt_global_std_discount: 'Discount',
    txt_global_std_buymoremilage: ' Acheter davantage de kilométrage',
    txt_global_std_closed: ' Fermé',
    txt_global_std_addcustombadgeicon: 'Ajouter une icône de badge personnalisé',
    txt_global_std_feedbackoptions: ' Options de feedback',
    txt_global_std_navigation: 'La navigation',
    txt_global_std_willbeaddedtoyoursubscription: 'sera ajouté à votre abonnement',
    txt_global_std_fortst: 'Fortsæt',
    txt_global_std_youmustnotuseappwhiledriving: ' Vous ne devez pas utiliser l’application pendant que vous conduisez.',
    txt_global_std_Insurance_PaymentDay2: 'Payment day ',
    txt_global_std_unabletosavelog: 'Unable to save log',
    txt_global_std_allowbluetoothtocollectdrivingscore: 'Autorisez Bluetooth à collecter votre score de conduite.',
    txt_global_std_viewpassword: 'Afficher le mot de passe',
    txt_global_std_firedamage: 'Fire damage',
    txt_global_std_leaderboard_tab_monthly: 'Mensuel',
    txt_global_std_invitationsentto: 'Invitation envoyée à',
    txt_global_std_ShareCar_Text10: 'kan säkert svara på dina frågor, annars kan du höra av dig till vår kundservice på',
    txt_global_std_Insurance_Text11Moderna: 'Vid eventuella frågor kring din betalning är du välkommen att kontakta vår supportavdelning. E-post: modernasmartflex@modernaforsakringar.se. Telefon: 0200-265 265.',
    txt_global_std_pleaseuploadyourlogfilestotheenerfycloud: 'Please upload your log to the {appName} cloud. This helps us to assist you, should you experience any problem. Click on row below to upload one file or click on this text to upload all.',
    txt_global_std_ActivationLetter_HelloModerna: 'Välkommen till Moderna Smart Flex',
    txt_global_std_thesebadgesyoucanonlyearnonetime: "Ces badges, vous ne pouvez les gagner qu'une seule fois.",
    txt_global_std_communication_upsell: "Outil de communication – stimulez l'engagement avec des notifications personnalisées.",
    txt_global_std_sentyounummessages: 'Sent you {nummessages} messages',
    txt_global_std_ifyoucomeacrosswordsandphrasesyoucanteditbyclickinginthevisualeditoryoucansearchandfindtheminthelistbelow: " * Si vous rencontrez des mots et des phrases que vous ne pouvez pas modifier en cliquant dans l'éditeur visuel, vous pouvez les rechercher et les trouver dans la liste ci-dessous.",
    txt_global_std_typehere: 'Écrivez ici',
    txt_global_std_endd: ' Fin',
    txt_global_std_accountpreferences: 'Préférences de compte',
    txt_global_std_TerminationConfirmSubscription: 'Termination Confirm',
    txt_global_std_rules: 'Règles',
    txt_global_std_showonleaderboard: 'Afficher dans le classement',
    txt_global_std_cimage5h: ' TECHNIQUE EN CÔTE',
    txt_global_std_MSIG_InsuranceLetter7: 'Type de politique.',
    txt_global_std_Footer_2: 'Moderna Försäkringar is the insurer to the product \r\n' +
      'Moderna Försäkringar, branch to Tryg Forsikring A/S | Bolagsverket | Org.nr 516403-8662 | Tryg Forsikring A/S \r\n' +
      'Erhvervsstyrelsen | CVR-No. 24260 666 | DK-2750 Ballerup | Denmark',
    txt_global_std_connectingyourgarminwatchiseasyclicktheconnectbuttonbelowtohelpusuncovernewinsightsintocrashriskbyanalyzingbothdrivingandhealthdata: "Connecter votre montre Garmin est facile ! Cliquez sur le bouton Connect ci-dessous pour nous aider à découvrir de nouvelles informations sur le risque d'accident en analysant à la fois les données de conduite et de santé.",
    txt_global_std_PaymentTrouble3: 'Reminder: Trouble with payment',
    txt_global_std_doyouneedenerfyplugin: 'Do you need {appName} Plug-in?',
    txt_global_std_showall: 'Tout voir',
    txt_global_std_p2pcostsplitisbasedonhoursthecarisused: "La répartition des coûts est basée sur les heures d'utilisation de la voiture.",
    txt_global_std_anonymous: 'anonyme',
    txt_global_std_viewloyaltypoints: 'Afficher les points de fidélité',
    txt_global_std_totaldistance2: 'Distance totale',
    txt_global_std_: 'Dans la plupart des voitures, la prise OBD se trouve à gauche sous le volant. Si vous ne trouvez toujours pas la prise OBD, cliquez ici.',
    txt_global_std_namecancelledthebooking: '{name} réservation annulée',
    txt_global_std_cimage40d: ' Faites défiler pour sauvegarder la progression. De plus, vous allez économiser du carburant et éviter l’usure de la voiture.',
    'txt_global_std_Enerfy reader indicator light': '{appName} reader indicator light',
    txt_global_std_uservsuser: 'Utilisateur contre utilisateur',
    txt_global_std_powerhuman: 'Pouvoir Humain',
    txt_global_std_p2pdividedequallyamongfriendsthatusedcar: 'Divisé également entre les amis qui ont utilisé la voiture.',
    txt_global_std_achievementcreatedmessage: 'Les réalisations ont été créées !',
    txt_global_std_p2pitstimetosplityourcostformonthof: 'Il est temps de partager votre coût pour le mois de',
    txt_global_std_dontrestrictkey: 'Ne restreignez pas la clé',
    txt_global_std_usethistemplate: 'Utilisez ce modèle',
    txt_global_std_sendagain: 'Envoyer à nouveau',
    txt_global_std_mysubscriptioncost: "Mon coût d'abonnement",
    txt_global_InvoiceOverviewTemplate_creditcard: 'Card',
    txt_global_std_kilometers_driven: 'Kilomètres parcourus',
    txt_global_std_typeyourverificationcode: ' Tapez votre code de vérification',
    txt_global_std_howdoesthescoringsystemwork: 'Comment fonctionne le système de notation ?',
    txt_global_std_orrecruitafriend: 'Ou recruter un ami',
    txt_global_std_stop: 'Arrivée',
    'txt_global_std_Drive Wizard': 'Drive Wizard',
    txt_global_std_youcanuploadmax10photosofyourcar: 'Vous pouvez télécharger un maximum de 9 photos de votre voiture',
    txt_global_std_hereyoufindyourhighestimprovmentpotential: ' Vous trouverez ici votre marge de progression maximale ',
    txt_global_std_easywaytogetinvolved: "Un moyen facile de s'impliquer",
    txt_global_std_OSolbergFacts: 'Son of Petter Solberg. He drives his father’s car for the Nordic team.',
    txt_global_std_InsuranceMail_Text29: 'Insurance terms',
    txt_global_std_usersuccessfullyadded: 'Utilisateur ajouté avec succès',
    txt_global_std_ActivationLetter_Text6: 'Download the {appName} app',
    txt_global_std_avgrpm: ' Moy. tours/min',
    txt_global_std_youearnedapercentagephdiscount: 'Vous avez obtenu une remise de {percentage}%',
    txt_global_CoreTemplate_insuranceoverview: 'Insurance overview',
    txt_global_std_addvehicleidentification: "Ajouter l'identification du véhicule",
    txt_global_std_includedkm: 'Kilomètres inclus',
    txt_global_std_password: ' Mot de passe',
    txt_global_std_apikey: 'Clé API',
    txt_global_std_points2: 'Points',
    txt_global_std_monthlydevelopment: 'Monthly development',
    txt_global_std_AnimalOwnerPhone: 'Eventuellt nummer till ägare',
    txt_global_std_keeptrackofyourtrips: 'Keep track of your trips',
    txt_global_std_saveinsurancekey: 'Enregistrer la clé d&#39;assurance',
    txt_global_std_namekeytitle: "Titre, affiché dans l'application",
    txt_global_std_cannotfindanybluetoothdevice: 'Impossible de trouver un appareil Bluetooth.',
    txt_global_std_p2pborrowacartwo: 'Emprunter une voiture',
    txt_global_std_p2ppushbodynewexpensetoapprove: '{name} added expenses for the month of {monthname}.',
    txt_global_std_userspecific: "Spécifique à l'utilisateur",
    txt_global_std_deletedtranslationfromreview: 'traduction supprimée de Review',
    txt_global_std_MSIG_InsuranceLetter31: '(Assureurs agréés)',
    txt_global_std_bysigningupyouaccept: 'En vous inscrivant, vous acceptez nos ',
    txt_global_edj_starttime: 'Start time',
    txt_global_all_monday: ' Lundi',
    txt_global_std_worldclass: 'Classe mondiale',
    txt_global_std_healthandsafety: 'Health & safety',
    txt_global_std_typeofchallenge: 'Type de défi',
    txt_global_std_problempersistcontactsupport: "Si le problème persiste, veuillez contacter l'assistance.",
    txt_global_std_kintoremainingpointstonextlevel: '{points} points remaining to {level}',
    txt_global_std_do: ' Faire',
    txt_global_std_feb: ' Fév.',
    'txt_global_std_waiting…': 'Waiting...',
    txt_global_std_iborrowacar: "I'll borrow a car",
    txt_global_std_apirestrictions: "Restrictions d'API",
    txt_global_std_AccountNumber: 'Kontonummer',
    txt_global_std_standard: 'la norme',
    txt_global_std_PaymentTrouble3_Text1: 'Your car insurance for',
    txt_global_std_reportanaccidentfastandeasy: 'Report an accident fast and easy',
    txt_global_std_MSIG_InsuranceLetter34: 'GST Reg. N ° M2-0001676-9',
    txt_global_std_driver: ' Conducteur',
    txt_global_std_loyalty_upsell_pitch: 'Personnalisez rapidement votre propre programme de fidélité sur la marque pour;',
    txt_global_std_warikan_onboardingmail_temppwd: '【仮パスワード】',
    txt_global_std_AdjustmentMileage: 'Bekreftelse: Justering av kjørelengde',
    txt_global_std_SignupFia_Text11: '.',
    txt_global_std_savesettings: ' Sauvegarder les paramètres',
    txt_global_std_compareactivities: ' Comparer les activités',
    txt_global_std_telluswhatyouthinkofenerfy: 'Tell us what you think of {appName}',
    txt_global_std_errorfetchingtrends: 'Erreur lors de la récupération des tendances',
    txt_global_std_p2pfaq2q: "Comment puis-je modifier la date et l'heure d'une réservation?",
    txt_global_std_1000pointaverageachievementunlocked: 'Vous avez vraiment bien roulé! Votre {scoretype} was better than {score} pendant une semaine! Vous avez gagné 1000 points!',
    txt_global_std_emailregisteredintheapp: "E-mail enregistré dans l'application",
    txt_global_std_p2ppushbodyaddadmin: 'Les droits d&#39;administrateur pour {vehicle} has been added by {name} .',
    txt_global_std_keepingyourmindandeyesonwhatshappeningaroundyouisvitalforyoursafetyandthesafetyofothersphoneactivityandotherdistractionsaffectyourfocusandincreasecrashrisk: "Garder votre esprit et vos yeux sur ce qui se passe autour de vous est vital pour votre sécurité et celle des autres. L'activité téléphonique et d'autres distractions affectent votre concentration et augmentent le risque d'accident.",
    txt_global_std_Intro_Text7: 'Steg 3: Kjørescore og sparing',
    txt_global_std_fuelprice: ' Prix du carburant',
    txt_global_std_recoveryhelp: 'Aide de récupération',
    txt_global_std_areyousureyouwanttoterminateInsurance: 'Êtes-vous sûr de vouloir mettre fin à l&#39;assurance?',
    txt_global_std_carrorewards: 'Récompenses Carro',
    txt_global_std_FirstDrive_Span2: 'EcoSafe-discount on your first drive, well done!',
    txt_global_std_recruitedfriends: 'Amis recrutés',
    txt_global_std_Insurance_Text6: 'Om du kör utan att du är uppkopplad mäts dina körningar som ”icke registrerade körningar” och du blir tilldelad minst premienivå 10 för varje körning. Kör uppkopplad för att ta del av EcoSafe-besparing på dina körningar. Kontrollera därför att du har telefonen med dig, med Bluetooth aktiverad, när du kör.',
    txt_global_std_gototip: 'Go to tip',
    txt_global_std_changesinreview: "Modifications en cours d'examen",
    txt_global_std_youhaveinvitedamountfriends: 'Vous avez invité {amount} amis',
    txt_global_std_searchforgroups: 'Rechercher des groupes',
    txt_global_std_animalcollision: 'Animal Collision',
    txt_global_std_sidekickusertermsandconditions: "conditions d'utilisation",
    txt_global_std_couldnotrespondtochallenge: 'Could not respond to challenge',
    txt_global_std_totalpoints: 'Total point',
    txt_global_std_iacceptthe: "J'accepte le",
    txt_global_std_clientlibraries: 'Bibliothèques clientes',
    txt_global_std_kintofaqlink2: 'Explorez le manuel de l&#39;application (japonais)',
    txt_global_std_apirestrictionsspecifytheenabledapisthatthiskeycancall: "Les restrictions d'API spécifient les API activées que cette clé peut appeler",
    txt_global_std_nextstep2: "L'étape suivante",
    txt_global_std_MSIG_InsuranceLetter16: 'Valeur marchande au moment de la perte',
    txt_global_std_signinwithappname: ' Ouvrir une session avec {appName}',
    txt_global_std_NotDriving_Text2: 'We just want to know if you have driven or not during these days, to check that everything works fine with your car insurance and your {appName} Plug-in. It would be sad if something was wrong or didnt work properly. Click on the following link, which will take you to a form on "My Pages", which only takes a few minutes to complete:',
    txt_global_std_uniquebadges: 'Insignes uniques',
    txt_global_std_hellothereletsgetstarted: 'Bonjour! Commençons.',
    txt_global_std_loyaltydistribution: 'Répartition de la fidélité',
    txt_global_std_sendalinktoyourfriendsothattheycandownloadtheapp: "Envoyez un lien à votre ami pour qu'il puisse télécharger l'application.",
    txt_global_std_p2pemailsupport: 'Pour le support technique des applications en semaine de 9h00 à 18h00',
    txt_global_std_offerendsin: "L'offre se termine dans",
    txt_global_std_invited2: 'Invité',
    txt_global_std_viewoffer: "Voir l'offre",
    txt_global_std_loadingdata: ' Chargement des données...',
    txt_global_std_saveandexit: 'Sauvegarder et quitter',
    txt_global_std_SulayemBesttip: 'Think safety first, always be aware of your surroundings and keep your speed within limits. ',
    txt_global_std_pleasecomebackatalatertime: 'Please come back at a later time.',
    txt_global_std_challengefriend: 'Challenge friend',
    txt_global_std_p2pborrow: 'Emprunter',
    txt_global_std_pleaseverifyifyourmileageiscorrectbeforesavingit: "Veuillez vérifier si votre kilométrage est correct avant de l'enregistrer",
    txt_global_std_incommon: 'In common',
    txt_global_std_Insurance_PaymentMethod: 'Payment method',
    txt_global_std_clickthelinkbelowtoconfirmyouremailandfinishcreatingyourenerfyglobalaccount: 'Cliquez sur le lien ci-dessous pour confirmer votre e-mail et terminer la création de votre compte Enerfy Global.',
    txt_global_std_p2pwantstolendyou: ' veut te prêter',
    txt_global_std_choosehowmanydrivesyouwanttochallengeyourfriendon: 'Choisissez le nombre de disques sur lesquels vous voulez défier votre ami.',
    txt_global_std_callsosphonenumber: ' Appeler les urgences au :',
    txt_global_std_BeforeBill_Text1Moderna: 'Vid månadsskiftet kommer vi att dra pengar för din bilförsäkring hos {appName}. Vill du veta hur mycket du har kört för hittills den här månaden kan du gå in på ”Mer” i {appName}-appen och klicka på ”Kostnad”. Där kan du se hur mycket du har kommit upp till hittills för den här månaden.',
    txt_global_std_regnumber: 'Reg. nombre',
    txt_global_std_p2pfaq5a: "Appuyez sur l'image, puis effectuez un zoom à l'aide de vos doigts.",
    txt_global_std_yourannualmilagewasunabletoreadfromtheserverpleasetryagainlater: "Votre kilométrage annuel n'a pas pu être lu à partir du serveur, veuillez réessayer plus tard.",
    txt_global_std_contactforactivation: "Contact pour l'activation",
    txt_global_std_p2pconfirmwith: 'Confirmer avec',
    txt_global_std_Licensenumber: 'Registreringsnummer',
    txt_global_std_lastlogfilesent: 'Dernier fichier journal envoyé',
    txt_global_std_removeparticipant: 'Supprimer un participant',
    txt_global_std_fiatodayrule2020: 'Vous devez conduire au moins 1 km pour obtenir un score dans le classement du jour.',
    txt_global_std_v2ilevel: 'Niveau',
    txt_global_std_lostconnectionbig: 'Le voyage se terminera',
    txt_global_CoreTemplate_both: 'Both',
    txt_global_std_p2pfaq22a: '1. Sélectionnez "Warikan" dans "Menu".\n' +
      '2. Sélectionnez "Coûts supplémentaires".\n' +
      '3. Sélectionnez Ajouter un coût.\n' +
      "4. Sélectionnez la voiture, sélectionnez le type de dépense, saisissez les frais, téléchargez l'image du reçu et sélectionnez Enregistrer.\n" +
      '5. Les coûts supplémentaires seront reflétés sur la page « Coûts supplémentaires ».',
    txt_global_std_accidentdetails: 'Détails de l&#39;accident',
    txt_global_std_rwmregister: "S'inscrire",
    txt_global_std_insuranceandpayments: 'Assurance et paiements',
    txt_global_std_notjoinable: 'Non joignable',
    txt_global_std_yourinsuranceperiodisover: "Votre période d'assurance est terminée :",
    txt_global_std_join: 'Rejoignez',
    txt_global_std_chooseallowalways: 'Choisissez Autoriser toujours',
    txt_global_std_refer10friendstounlockthisachievementandearn300pointstoyour: 'Parrainez 10 amis pour débloquer ce succès et gagnez 300 points à votre',
    txt_global_std_Reminder_Text5: 'Sincerely,\r\n{appName}',
    txt_global_std_areyousurethatyouwanttoremovethisdrivenotreversable: ' Êtes-vous sûr de vouloir retirer ce trajet ? Cette action est irréversible.',
    txt_global_std_InsuranceApp_Text1: 'Thanks again for choosing Enerfy - Insurance with Moderna! In this email you will find your insurance letter. If you notice some incorrect information, please contact our customer service at info@enerfy.se. Then we can solve the problem quick and easy for you.',
    txt_global_std_searchbynameoremailaddress: 'Rechercher par nom ou adresse e-mail',
    txt_global_std_Insurance_HasCharged: 'You have been charged',
    txt_global_std_challengearandomdriver: 'Défiez un conducteur au hasard',
    txt_global_std_howdidyoufeel: ' Qu’en pensez-vous ?',
    txt_global_std_invitetosidekicksubtitle: 'get rewarded!',
    txt_global_std_theanalysistookalongertime: 'Cette analyse a pris plus de temps que prévu, mais ne vous inquiétez pas, nous vous enverrons une notification push une fois l&#39;opération terminée.',
    txt_global_std_p2psafedriverbonusincluded: 'Bonus Safedriver inclus',
    txt_global_std_assigntogroups: 'Attribuer à des groupes',
    txt_global_std_kilometerpremiumtohigh: 'Kilomètre premium à élevé',
    txt_global_std_groupmanagement: 'Gestion de groupe',
    txt_global_std_website: 'Site Internet',
    txt_global_std_nowyoubelongtotheabsolutebestamongyourfriends: 'Now you belong to the absolute best among your friends.',
    txt_global_std_speedvariation: 'Variation de vitesse',
    txt_global_std_profilesettings: ' Profil/Paramètres',
    txt_global_std_yourlocation1: 'Votre emplacement',
    txt_global_std_connecttoyourcarbeforelendingittoafriend: 'Connectez-vous à votre voiture avant de la prêter à un ami',
    txt_global_std_areyousureyouwanttounpublishyourtranslationsifyouunpublishtranslationstheappwillnotshowthislanguageanymore: " Voulez-vous vraiment annuler la publication de vos traductions ? Si vous annulez la publication des traductions, l'application n'affichera plus cette langue.",
    txt_global_std_Invite_Text9: 'Sincerely,\r\n{appName}',
    txt_global_std_adgangtilbluetooth: 'Connexion au Bluetooth',
    txt_global_std_somethingsmissing: 'Quelque chose manque...',
    txt_global_std_teamscore: "SCORE DE L'EQUIPE",
    txt_global_std_ConfirmFia_Text3: ' Date',
    txt_global_std_p2pcostsplitisbasedonthepercentageofuse: "La répartition des coûts est basée sur le pourcentage d'utilisation.",
    txt_global_std_p2preceiptsummary: 'Récapitulatif du reçu',
    txt_global_std_25kmlockedkey: 'Parcourez une distance de 50 km pour gagner ce badge.',
    txt_global_std_PolicySchedule22: 'NCD Protector (le cas échéant)',
    txt_global_std_403forbidden: '403 - Interdit',
    txt_global_std_worldchallenge: 'Défi mondial',
    txt_global_std_yourinsuranceisnowactivated: 'Votre assurance est maintenant activée',
    txt_global_std_congratulationsyouwonover: 'Congratulations, you won over',
    txt_global_std_p2pfaq12q: 'Puis-je supprimer un ami?',
    txt_global_std_reset: ' Réinitialiser',
    txt_global_std_remove: 'Retirer',
    txt_global_std_otherteams: 'Other teams',
    txt_global_std_deleteuseraccount: 'Supprimer le compte utilisateur',
    txt_global_std_addaddress: ' Ajouter une adresse',
    txt_global_std_recurring: 'Récurrent',
    txt_global_std_sendupdate: 'Envoyer la mise à jour',
    txt_global_std_novalidapikeyprovided: 'Aucune clé API valide fournie.',
    txt_global_std_itstimetostarttalkingtoyourfriends: "It's time to start talking to your friends!",
    txt_global_std_bestdriversintotal: 'Bedste bilister i alt',
    'txt_global_std_Connect to car': 'Connect to car',
    txt_global_std_secondss: ' s',
    txt_global_std_selecttheregnumberofyourcar: 'Sélectionnez le numéro de série de votre voiture',
    txt_global_std_NewPW_Text1: ' Vous avez demandé à obtenir un nouveau mot de passe pour votre compte {appName}.',
    txt_global_std_thislinkexpiresinxtimeandcanonlybusedonce: "Ce lien expire dans 6 minutes et ne peut être utilisé qu'une seule fois.",
    txt_global_std_sosinternationalaroundtheclock: ' Numéro d’urgence international, 24h/24',
    txt_global_std_drivesafeandecofriendly: "Conduisez prudemment et respectueux de l'environnement",
    txt_global_std_carstatsthisday: 'Statistiques de voiture pour le dernier jour',
    txt_global_std_youhaventpairedwithacaryet: "Vous n'avez pas encore jumelé avec une voiture.",
    txt_global_std_p2powneruserguide: "Guide d'utilisation du propriétaire",
    txt_global_std_WorstGroups_Hello: 'Hello',
    txt_global_std_p2ptodo: 'Faire',
    txt_global_std_amount_default: 'Montant',
    txt_global_std_thisisatestblabla: 'Ceci est un test blabla',
    txt_global_std_challengeranking: 'Classement de votre challenge',
    txt_global_std_insuranceoverview2: 'Aperçu de l&#39;assurance',
    txt_global_std_resetpassword: 'Envoyer',
    txt_global_std_achievementtool_upsell: "Outil de réalisations - facilite la formation d'une habitude de conduite intelligente.",
    txt_global_std_verybad: ' Très mauvais',
    txt_global_std_thebetteryoudrivethemoreyousave: 'Plus vous conduisez, plus vous économisez.',
    txt_global_std_whymustiallowalwaysallowlocationsservicesanswer: 'Parce que l&#39;IA doit connaître la route pour pouvoir calculer le score et vous aider en cas d&#39;urgence.',
    txt_global_std_members2: 'Membres',
    txt_global_std_getstartedtips: ' Astuces pour bien débuter',
    txt_global_std_ifyourfriendregisterswiththereferralcodebothyouandyourfriendwillreceivenok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsurance: "Si votre ami s'inscrit avec le code de parrainage, vous et votre ami recevrez 250 NOK dans la tirelire (cela nécessite que vous soyez tous les deux clients de Tryg depuis au moins un mois après la date de début de l'assurance).",
    txt_global_std_mytrips: 'Mes excursions',
    txt_global_std_tripsapi: 'API Voyages',
    txt_global_std_youwillbebilledatpremiumlevel10: 'You will be billed at premium level 10, please refer to your insurance letter for price details.',
    txt_global_std_reportNo: 'No',
    txt_global_std_p2pwantstolendborrow: 'veut {lendborrow}',
    'txt_global_std_joinpiggyonyoufirstbonusadventure!': 'Join piggy on you first bonus-adventure!',
    txt_global_std_kintofaq9q: 'Pourquoi dois-je autoriser les services de localisation «toujours autoriser»?',
    txt_global_EnerfyTeamsTemplate_Imin: "I'm in!",
    txt_global_std_DrivingScore_Text1: 'Kjørescoren din har vært under gjenomsnittet fra perioden',
    txt_global_std_HighMileage_Text7: 'Hvis du ønsker nærmere forklaring kontakt oss via chat i {appName}-appen eller ring 04040.',
    txt_global_std_doyouwanttodeletetheapikey: 'Voulez-vous supprimer la clé API ?',
    txt_global_std_yourealmostthere: 'Tu y es presque!',
    txt_global_std_trygdiscountpopuptext: 'Ici, vous pouvez voir combien vous économiseriez si vous aviez une assurance automobile Sidekick. Les économies potentielles sont calculées en fonction de votre prix annuel, du kilométrage annuel et du modèle de voiture.\n' +
      "Si vous n'avez pas saisi ces informations, les économies sont calculées sur la base d'un prix annuel de 5 000 NOK et d'un kilométrage annuel de 20 000 km.",
    txt_global_std_thesebadgesyoucanearnseveraltimes: 'Ces badges, vous pouvez gagner plusieurs fois.',
    txt_global_std_inviteafriendyouthink: 'Invite a friend you think want to lend the car',
    txt_global_std_doyouwanttodriveforteamnamesteam: "Voulez-vous conduire pour l'équipe de {teamName} ?",
    txt_global_std_p2ptypeamessagedot: 'Tapez un message ...',
    txt_global_std_WelcomeLetter_Text6: 'PDG',
    txt_global_std_welcometoyournewaccountwehavesentyouanemailtoverifyyournewaccountremembertocheckyourspamfolderdidntgetanemail: "Bienvenue sur votre nouveau compte ! Nous vous avons envoyé un e-mail pour vérifier votre nouveau compte. Pensez à vérifier votre dossier spam. Vous n'avez pas reçu d'e-mail ?",
    txt_global_std_PaymentTrouble1_Text6: 'Your bank experiencing temporary payment problems',
    txt_global_std_searchchallenges: ' Chercher un défi',
    txt_global_std_openbalance: 'Open balance',
    txt_global_std_yourcarisntturnedon: "• Votre voiture n'a pas été démarrée.",
    txt_global_std_100pointtripachievementunlocked: 'Vous avez conduit à {scoretype} {score} ou mieux! Génial! Vous avez gagné 100 points!',
    txt_global_std_sharetheposition: 'Share the positon',
    txt_global_std_abctext3: 'Prevent cooling • No drink • Rest • Legs in a high position • Caution • Recovery position',
    txt_global_std_upcomingtraining: 'Formation à venir',
    txt_global_std_estimatedprice: 'Prix estimé',
    txt_global_std_yourmileagewillbesavedintheobddevice: "Your mileage will be saved in the OBD device's built in memory storage and will be uploaded at premium level 10 when you complete your next connected drive.",
    txt_global_std_exportdrives: 'Exporter des disques',
    txt_global_std_usersoverview: 'Présentation des utilisateurs',
    txt_global_std_PolicySchedule3: 'Note de couverture / Réf. Non.',
    txt_global_std_youhaveexceededthedrivingmaxtimeof: ' Vous avez dépassé le temps maximum de conduite de {breakTime}.',
    txt_global_std_KleinschmidtChamp: 'Champion du Rallye de Dakar en 2011',
    txt_global_std_mustbeavalidsizenumbersand: 'Doit être une taille valide, des nombres et +',
    txt_global_std_statistics: 'Statistics',
    txt_global_std_reasonterminatedinsurance: 'Assurance annulée',
    txt_global_std_FirstDrive_Text5: 'How do I improve my premium so that it will be cheaper?',
    txt_global_std_automatic: 'Automatique',
    txt_global_std_tryoutappname: 'Essayez {appName}',
    txt_global_std_allowingaccesstobluetoothisnecessaryforthisapptoconnecttoyourcarandmeasureyourdriving: "Autoriser l'accès au Bluetooth est nécessaire pour que cette application se connecte à votre voiture et mesure votre conduite.",
    txt_global_std_accidentandclaims: 'Accidents & réclamations',
    txt_global_std_Intro_Text15: 'Ved spørsmål kan du gå inn på vår Sidekick-side (',
    txt_global_std_buyinsurance: 'Acheter une assurance',
    txt_global_std_TerminationMail_Text7: 'Sincerely,\r\n{appName}',
    txt_global_std_ifyourcardoesnotshowupinthelistyoumightneedtogotobluetoothsettingsforbothyourphoneandyourcar: "Si votre voiture n'apparaît pas dans la liste, vous devrez peut-être accéder aux paramètres Bluetooth de votre téléphone et de votre voiture.",
    txt_global_std_verifycode: ' Vérifier le code',
    txt_global_std_p2pcancelledph: '{count}Annulé',
    txt_global_std_destination: ' Destination',
    txt_global_std_thesepermissionsarenecessaryfortheapptobeabletoregisteryourdrivescorrectlyletsgo: "Ces autorisations sont nécessaires pour que l'application puisse enregistrer correctement vos données.",
    txt_global_std_willbesenttotheemailregisteredintheonboarding: " Sera envoyé à l'e-mail enregistré dans l'onboarding",
    txt_global_std_findyourobdportinyourcar: 'Trouvez votre port OBD dans votre voiture',
    txt_global_std_yournewappishere: 'Votre nouvelle application est ici !',
    txt_global_std_workspacepermissions: "Autorisations de l'espace de travail",
    txt_global_std_rpmspeednotfluctating: ' Le régime moteur/la vitesse ne varie pas',
    'txt_global_std_Al-KhelaifiInterests': 'Voitures, Photo, Motorsport',
    txt_global_std_welcometofiatciaodrive1: 'Bienvenue chez FIAT CIAO DRIVE.',
    txt_global_std_InsuranceMail_Text32: 'Personskada',
    txt_global_std_addtogroups: 'Ajouter aux groupes',
    txt_global_std_collision: 'Collision',
    txt_global_std_enteryourpolicynumber: 'Entrez votre numéro de police',
    txt_global_std_youneedtofinishsettingupyourprofilebeforejoining: ' Vous devez finir de paramétrer votre profil avant de rejoindre un évènement.',
    txt_global_std_allteamleaders: "tous les chefs d'équipe",
    txt_global_std_createanaccountforname: ' Créer un compte pour {appName}',
    txt_global_std_apitool_upsell_pitch_bulletpoint1: 'Intégration numérique complète pour une intégration transparente',
    txt_global_std_jpfaqtitle: 'アプリを車に接続して、走行\nデータを記録する方法を教えてく\nださい。',
    txt_global_std_trygtakemetoapp: 'Passer et démarrer avec Tryg Drive',
    txt_global_std_cimage2h: ' MAINTENEZ LA DISTANCE',
    txt_global_std_seeyourcarsexactposition: "See your car's exact position after a drive",
    txt_global_std_challengeafriendandracefor5days: 'Challenge a friend and race for 5 days. Best Driver Wins!',
    txt_global_std_PreSaleDetails_Text57: 'Rental Car',
    txt_global_std_canisignupforinsurancetobegintoday: "Puis-je souscrire à une assurance pour commencer aujourd'hui?",
    txt_global_std_p2psubmit: 'Soumettre',
    txt_global_std_exportyourdrivestodrivejournal: 'Export your drives to a flexible drive journal',
    txt_global_std_Insurance_Letter: 'Insurance letter',
    txt_global_std_timesinceparked: 'Temps écoulé depuis le stationnement',
    txt_global_std_testarenandra: 'Kiểm tra một giây',
    txt_global_std_Adjust_Text7: 'med virkning fra i dag.',
    txt_global_std_restrictkey: 'Restreindre la clé',
    txt_global_std_yourinsurancehasbeenrenewedformonth: 'Votre assurance a été renouvelée pour {mois}.',
    txt_global_std_p2pyouhavechosen: 'Tu as choisi:',
    txt_global_std_5streak: '5 séquences!',
    txt_global_std_p2pfaq13q: 'Et si je rends la voiture tôt?',
    txt_global_std_p2pconfirmedbookings: 'Réservations confirmées',
    txt_global_std_thepasswordminimumislength8charactersandmustinclude1lowercaseuppercasenumeralandspecialcharacter: 'Le mot de passe doit comporter au moins 8 caractères et doit inclure 1 minuscule, une majuscule, un chiffre et un caractère spécial.',
    txt_global_std_resetyourenerfyglobalpassword: 'Réinitialisez votre mot de passe Enerfy Global',
    txt_global_std_welcomebackenerfyfirstname: 'Ravis de vous revoir sur {appName}, {firstName} !',
    txt_global_std_distancebusinesskm: 'Business trips (km)',
    txt_global_std_westzone: 'Zone Ouest',
    txt_global_std_introslide3header: 'Connect with {appName} Plug-in',
    txt_global_std_monitoringyourdrivinghasneverbeeneasier: "Surveiller votre conduite n'a jamais été aussi simple.",
    txt_global_std_apis: 'Apis',
    txt_global_std_f_lanzchamp: 'Championne KZ2 SA 2020, Vice-Championne KZ2 SA 2019, Championne Rok Final Shifter Dames 2018 et P16 au général\n',
    txt_global_std_companyaverage: "Moyenne de l'entreprise",
    txt_global_std_p2pcostsplits: 'Répartition des coûts',
    txt_global_std_placethewarningtriangle: 'Place the warning triangle in a visible location at least 100 meters in front of the car',
    txt_global_std_FiaPassword_Text2: 'Vous avez demandé un nouveau mot de passe pour votre compte FIA ​​Smart Driving Challenge. Votre nouveau mot de passe pour l&#39;utilisateur',
    txt_global_std_drivesettingsnotfinished: 'Paramètres du lecteur non terminés',
    txt_global_std_allowaccesstomotionfitness: "Autoriser l'accès à Motion & Fitness",
    txt_global_std_myprojects: 'Mes projets',
    txt_global_std_60days: '60 jours',
    txt_global_std_howdoesitwork: 'Comment ça marche?',
    txt_global_std_selectatemplate: 'Sélectionnez un modèle',
    txt_global_std_birthday: ' Date de naissance',
    'txt_global_std_This is top 5% Enerfy drivers, no profile data available.': 'This is top 5% {appName} drivers, no profile data available.',
    txt_global_std_sdcrapply2019price3: '300 kr i bensincheckar',
    txt_global_std_thisactivationcodeisinvalid: 'Ce code d&#39;activation est invalide',
    txt_global_std_insurancehasbeenactivatedforregno: 'The insurance has been activated for vehicle {regNo}.',
    txt_global_std_nextlevel: 'niveau suivant',
    txt_global_std_claimshandling: 'Claims handling',
    txt_global_std_insuranceperiod: "Période d'assurance",
    txt_global_std_activationcodeemail3: "Vous pouvez l'activer dans l'application !",
    txt_global_std_cantfinduserinhead2head: 'Vous ne trouvez pas votre utilisateur? Vous pouvez inviter un ami dans ReferFriend.',
    txt_global_std_actualtimegain: ' Gain de temps actuel',
    txt_global_std_WelcomeLetter_Text3Moderna: 'If you have any questions about your car insurance please contact us or visit our website www.modernasmartflex.se to get more information on how {appName} works. ',
    txt_global_std_Insurance_Page: 'Page',
    txt_global_std_anodometerorodographisaninstrumentusedformeasuringthedistancetravelledbyavehicleitcanbefoundinthecarsdashboard: 'Un odomètre ou odographe est un instrument utilisé pour mesurer la distance parcourue par un véhicule. Il peut être trouvé dans le tableau de bord de la voiture.',
    txt_global_std_p2pfriendscalendars: "Calendriers d'amis",
    txt_global_std_p2pfaq16a: "Vous pouvez utiliser l'assurance Kinto. Cependant, veuillez en informer le responsable et discuter entre vous de la manière de résoudre le problème.",
    txt_global_std_stress: 'Stresser',
    txt_global_std_weareunabletorecordyourtrip: 'Nous ne pouvons pas enregistrer votre voyage!',
    txt_global_std_validbetween: 'Valide entre',
    txt_global_std_city: ' Ville',
    txt_global_std_daydreamingisacommontypeofdriverdistractionyoumaythinkitsharmlessbutanythingthattakesyourmindoffthedrivingtaskincreasescrashriskchangingyourgazefrequentlycanhelpyoumaintainfocusontheenvironmentaroundyounew: "La rêverie est un type courant de distraction du conducteur. Vous pensez peut-être que c'est inoffensif, mais tout ce qui vous détourne de la tâche de conduite augmente le risque d'accident. Changer fréquemment de regard peut vous aider à rester concentré sur l'environnement qui vous entoure.",
    txt_global_std_whatdoyouwanttodo: ' Que voulez-vous faire ?',
    txt_global_std_p2pwhenitstimetodrive: 'Quand il est temps de conduire',
    txt_global_std_TermConfirmSub_Text4Moderna: 'The {appName} Plug-in, which you have been subscribed for 69 SEK per month, you return to us in the original packaging to the address:',
    txt_global_std_yourdrivingstatsforthistripcomparedtotheaveragedriver: 'Vos statistiques de conduite pour ce trajet par rapport au conducteur moyen.',
    txt_global_std_myrewards: 'Mes récompenses',
    txt_global_std_whatisanodometer: "Qu'est-ce qu'un odomètre ?",
    txt_global_std_Fia_Receipt_Text15: 'Votre code d&#39;activation:',
    txt_global_std_agentemail: 'Agent e-mail',
    txt_global_std_doyouwanttocancelbounding: 'Are you sure you want to cancel the bounding?',
    txt_global_std_locationservice: 'Service de localisation',
    txt_global_std_resetpasswordforname: 'Réinitialiser le mot de passe pour {name} ?',
    txt_global_std_sdcrapply2019price1: '1000 kr gasoline checks',
    txt_global_std_driversnow: 'pilotes maintenant',
    txt_global_std_pointsheader: 'Points',
    txt_global_std_p2pyouhavexfriendsintheapp: "Vous avez {number} amis dans l'application",
    txt_global_std_step4: 'Étape 4',
    txt_global_std_warikan_onboarding4: "Mode d'emploi:",
    txt_global_std_p2plogintomykintoaccount: 'Connectez-vous à mon compte Kinto',
    txt_global_std_nobluetoothbrnoworries: 'Pas de Bluetooth ? <br/>Pas de soucis !',
    txt_global_std_insuranceactivationkeydoesnotexist: 'La clé d&#39;activation de l&#39;assurance n&#39;existe pas',
    txt_global_std_wearebuildingyourdemoapp: 'Nous construisons votre application de démonstration',
    txt_global_std_reportuser: ' Signaler l’utilisateur',
    txt_global_std_lendings: 'Prêts',
    txt_global_std_entervalidphone: 'Entrez le numéro de téléphone avec au moins 6 chiffres',
    txt_global_std_idriveforaridesharingcompanywillyouinsuremyvehicle: 'Je conduis pour une société de covoiturage, assurerez-vous mon véhicule ?',
    txt_global_std_youpurchasedcdw5timestoyourbookingyouhaveearned100points: 'Vous avez acheté CDW 5 fois à votre réservation. Vous avez gagné 100 points.\n',
    txt_global_std_cwelcome1: 'With Sidekick we can help you to be a safe superhero on the road!',
    txt_global_std_WelcomeLetter_Hello: 'Bonjour',
    txt_global_std_enteroptionalactivationcode: "Code d'activation (facultatif)",
    txt_global_std_yourapikey: 'Votre clé API',
    txt_global_std_settingshasbeensaved: 'Les paramètres ont été enregistrés',
    txt_global_std_loginwithoutaccount: ' Ouvrir une session sans compte',
    txt_global_std_participantsfor: 'Participants pour',
    txt_global_std_yourfinalscorewillbecalculatednexttime: ' Votre score final sera calculé lorsque vous serez à nouveau connecté',
    txt_global_std_whenyouhavecompletedyourfirstdriveyouwillseeithere: 'Lorsque vous aurez terminé votre premier trajet, vous le verrez ici.',
    txt_global_std_turnonyourphonesbluetooth: 'Activez le Bluetooth de votre téléphone.',
    txt_global_std_removeuser: "Supprimer l'utilisateur",
    txt_global_std_youneedtoactivateprivacysettings: 'Vous devez activer les paramètres de confidentialité',
    txt_global_std_covercubecollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: "Covercube collecte des données de localisation pour activer le score de conduite même lorsque l'application est fermée ou non utilisée.",
    txt_global_std_MikkelsenChamp: 'Champion de IRC en 2011 et 2012. Troisième place dans le WRC (2014,2015,2016). Trois victoires dans WRC. Faisait partie des jeunes pilotes de l’académie d’excellence de la FIA\n',
    txt_global_std_drivewascancelledbeforeitwasvalidandwillbediscarded: 'Le lecteur a été annulé avant sa validité et sera supprimé',
    txt_global_std_rwmconnecttocar: 'Se connecter à la voiture',
    txt_global_std_insnumber: "Numéro d'assurance",
    txt_global_std_p2ptotalexpenses: 'Dépenses totales',
    txt_global_std_theusermustacceptaninvitationtothegroupfirst: "L'utilisateur doit d'abord accepter une invitation au groupe.",
    txt_global_std_p2pfaq24a: 'Il existe quatre méthodes, et vous pouvez choisir librement.\n' +
      '(Coût total : frais mensuels KINTO plus frais supplémentaires pour chaque membre)\n' +
      '\n' +
      `1. "Ratio d'utilisation" Calculez le rapport entre le temps d'utilisation de chaque membre et le temps mensuel total (exemple de juillet : 24 heures x 31 jours = 744 heures) et multipliez par le coût total.\n` +
      `2. "Durée d'utilisation" Divisez le coût total par la durée d'utilisation totale pour calculer le prix unitaire de la durée d'utilisation. Multipliez chaque temps d'utilisation.\n` +
      '3. "Répartition égale" Le coût total est divisé également par le nombre de personnes dans le groupe.\n' +
      '4. "Kilométrage" Calculez le prix unitaire du kilométrage en divisant le coût total par le kilométrage total du mois. Multipliez votre kilométrage.',
    txt_global_std_active: ' Activer',
    txt_global_std_cimage43h: ' LORSQUE VOUS ÊTES À L’ARRÊT DANS UNE FILE, ÉVITEZ DE DÉMARRER POUR VOUS RÉ-ARRÊTER IMMÉDIATEMENT',
    txt_global_std_policynumberwassaved: 'Le numéro de police a été enregistré',
    txt_global_std_trackhowsmartyoudrive: 'Track how smart you drive, get stats and feedback.',
    txt_global_std_p2pminutes: 'heures',
    txt_global_std_createddate: 'Date de création',
    txt_global_std_activecompetitions: 'Compétitions actives',
    txt_global_std_p2pfaq17q: 'Comment ajouter des amis?',
    txt_global_std_chooseoneoption: 'Choisissez une option',
    txt_global_std_howdoiconnecttomycarsbluetooth: 'Comment me connecter au Bluetooth de ma voiture ?',
    txt_global_std_viewandredeem: 'Afficher et utiliser',
    txt_global_std_insureddistance_used_90__2: 'Snart tomt! Öka årlig körsträcka här.',
    txt_global_std_insuranceterms2: "Conditions d'assurance",
    txt_global_std_translationtool_upsell_pitch_bulletpoint3: 'Positionnez vos produits devant un public plus large',
    txt_global_std_ChicheritMusic: 'Coming soon...',
    txt_global_std_WhenDiscovered: 'När upptäcktes skadan',
    txt_global_std_InsuranceChangeModerna_Text1: 'Det har blivit en omfattningsförändring av din bilförsäkring hos oss. I och med detta har dina premier förändrat. Se bifogat försäkringsbrev i detta mail.',
    'txt_global_std_Connected to Enerfy reader': 'Connected to {appName} reader',
    txt_global_std_whenyouarereadytodrive: 'Commencer',
    txt_global_std_PreSaleDetails_Text63: 'Incorrect or incomplete information',
    txt_global_std_keepingyourmindandeyesontheroadandtrafficaroundyouisvitalforyoursafetyandthesafetyofotherslookingwellaheadandscanningtheenvironmentwillhelpyoutoanticipatewhatotherroadusersmightdonew: "Garder votre esprit et vos yeux sur la route et la circulation autour de vous est vital pour votre sécurité et celle des autres. Regarder loin devant et scruter l'environnement vous aidera à anticiper ce que pourraient faire les autres usagers de la route.",
    txt_global_std_couldhavesaved: 'Aurait pu sauver',
    txt_global_std_Insurance_Quantity: 'Quantity',
    txt_global_std_NotCreatedAccount5_Text4Moderna: 'Call or email us at: 0200-265 265 or modernasmartflex@modernaforsakringar.se, and we will help you!',
    txt_global_std_level123: 'Niveau',
    txt_global_std_theapikey: 'La clé API',
    txt_global_std_ActivationLetter_Text7: 'Create an account in the app',
    txt_global_std_yougetthiswhenyouhaveachieved5tripswithascorebetterthan3point3: 'Vous obtenez cela lorsque vous avez réalisé 5 voyages avec un score meilleur que 3,3',
    txt_global_std_andspaces: ' et des espaces.',
    txt_global_std_pressconfirmtoadjustyourmilagebonusplan: 'Appuyez sur « confirmer » pour ajuster votre plan de bonus de kilométrage.',
    txt_global_std_OSolbergMileage: 'Coming soon...',
    txt_global_std_yousentahead2headrequest: 'You sent a challenge',
    txt_global_std_settled: 'Colonisé',
    txt_global_std_TermConfirmSub_Text5: 'Greater Than, Karlavägen 58, 114 49 Stockholm',
    txt_global_std_group: 'Grouper',
    txt_global_std_avgspeed7d: 'Vitesse moyenne 7D',
    txt_global_EnerfyLeaderboardTemplate_currleaguetext: ' Vous êtes dans la division',
    txt_global_std_TermConfirmSub_Hello: 'Hello,',
    txt_global_std_anotherverificationemailhasbeensentpleasecheckyourinbox: 'Un autre e-mail de vérification a été envoyé, veuillez vérifier votre boîte de réception',
    txt_global_std_PolicySchedule4: 'Numéro de police',
    txt_global_std_learnmoreandbuy: 'Learn more and buy',
    txt_global_std_keepingyourmindandeyesontheroadandtrafficaroundyouisvitalforyoursafetyandthesafetyofotherslookingwellaheadandscanningtheenvironmentwillhelpyoutoanticipatewhatotherroadusersmightdo: "Garder votre esprit et vos yeux sur la route et la circulation autour de vous est vital pour votre sécurité et celle des autres. Regarder loin devant et scruter l'environnement vous aidera à anticiper ce que pourraient faire les autres usagers de la route.",
    txt_global_std_clickhere2: 'Cliquez ici.',
    txt_global_std_feedbackinterval: ' Intervalle du feedback',
    txt_global_std_MSIG_InsuranceLetter44: 'UN FRAIS SUPÉRIEUR JUSQU&#39;À 2 000,00 $ S&#39;APPLIQUERA EN CAS D&#39;ACCIDENT SOUMIS AU SCORE AVG',
    txt_global_std_p2pthetemporarypasswordisonlyvalidfor72hourspleaserequestanewpassword: 'Le mot de passe temporaire est uniquement\n' +
      'valable 72 heures. Veuillez demander un nouveau mot de passe.',
    txt_global_std_joiningheatspleasewait: 'Téléchargement des manches, en cours...',
    txt_global_std_yourmessagewillbesentat: 'Votre message sera envoyé à :',
    txt_global_std_addacar: 'Add a car',
    txt_global_std_Adjust_Text8: 'Vi vil oppdaterere forsikringsinformasjonen på «Min side» og i appen.',
    txt_global_std_oncepairedyoullautomaticallymovetothenextstep: "Une fois jumelé, vous passerez automatiquement à l'étape suivante.",
    txt_global_std_youdroveextremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Vous avez extrêmement bien conduit! Votre {scoretype} moyen était meilleur que {score} pendant une semaine! Vous avez gagné {points} points!',
    txt_global_std_pioneeronepageuserguide: "Mode d'emploi",
    txt_global_std_checkifyouareconnectedcorrectobdreader: 'Vérifiez si vous êtes connecté au bon lecteur OBD.',
    txt_global_std_joinaheat: 'Rejoindre une manche',
    txt_global_std_selectnewmileageselector: 'Sélectionnez un nouveau kilométrage',
    txt_global_std_setupcompanychallenge: "Mettre en place un défi d'entreprise",
    txt_global_std_insertbonuscode: 'Insert bonus code you got from your friend',
    txt_global_std_numberofbonuslevelsinarow: '{numtrips} bonus trips in a row!',
    txt_global_std_p2pclose: 'Fermer',
    txt_global_std_locked: '(Fermé à clé)',
    txt_global_std_getyourdrivingscore: 'Obtenez votre score de conduite !',
    txt_global_std_PreSaleDetails_Text35: "Compensation is provided for incidents including, damage to the car's interior caused by a sudden and unforeseen event, but also gives compensation for the cost of transcoding and car keys if the car keys are lost. Maximum amount 60 000 SEK, deductible 1200 SEK.",
    txt_global_std_competeasindividualsandinteams: "Concourez en tant qu'individus et en équipes",
    txt_global_std_AbdullaDescription: 'Adel a gagné le championnat mondial de la FIA CCR T2 avec Sébastian Delaunay en tant que copilote. Dans sa première saison du championnat de cross-country Adel Hussein a obtenu des victoires au Portugal, Abu-Dhabi et Espagne pendant une campagne réussie dans le championnat du hors-piste des plus difficiles au monde. ',
    txt_global_std_p2pcontactsupport: 'Contactez le support',
    txt_global_std_eventcreator: "Créateur d'événement",
    txt_global_std_leaderboards: ' Classements',
    txt_global_std_consentInfo: 'Oui = Rejoignez-nous dès le début, rejoignez notre communauté et faites partie de Tryg Drive. Une fois que vous avez donné votre consentement, vous pouvez participer à nos compétitions et obtenir un accès complet aux fonctionnalités de l&#39;application. Tryg peut me contacter par courrier électronique, via l&#39;application Tryg Drive et par SMS afin de recevoir des nouvelles et des offres spéciales de Tryg A / S concernant l&#39;assurance, les produits d&#39;assurance non-vie, les concours et les autres services Tryg. Vous pouvez toujours demander des demandes de marketing facilement et gratuitement. Non = Vous n&#39;avez pas activé votre &quot;consentement&quot; dans les paramètres. Lorsque le &quot;consentement&quot; n&#39;est pas activé, nous ne pourrons pas partager en permanence toutes les compétitions intéressantes et les conseils fournis dans l&#39;application Tryg Drive.',
    txt_global_std_howmanycarswereinvolved: 'Combien de voitures étaient impliquées?',
    txt_global_std_communicationtool: 'Outil de communication',
    txt_global_std_nothingtoshowhere: 'Rien à montrer ici',
    txt_global_std_Insurance_Service: 'Damage / Claim service',
    txt_global_std_MSIG_InsuranceLetter5: 'Loi de 1987 sur les transports routiers (Malaisie)',
    txt_global_std_ActivationLetter_Text12: 'Lykke til!',
    txt_global_std_kintorefer50friendsunlocked: 'Je vous remercie! Vous avez référé 50 utilisateurs Kinto. 5000 ¥ ont été envoyés à votre portefeuille Kinto.',
    txt_global_std_whenusingthiscodeorlinkuserwillbeonboardedtoyourenerfyglobalteam: "Lorsque vous utilisez ce code ou ce lien, l'utilisateur sera intégré à votre équipe Enerfy Global.",
    txt_global_std_avgtrips7d: 'Trajets moyens 7D',
    txt_global_std_whereismycar: 'Où est ma voiture?',
    txt_global_std_donthaveanaccount: "Vous n'avez pas de compte ?",
    txt_global_std_invitecolleaguesorevenfriendsandfamilytoyourchallengetomakeyourcompanychallengemoreinteresting: "Invitez des collègues ou même des amis et de la famille à votre défi pour rendre votre défi d'entreprise plus intéressant !",
    txt_global_std_customfilters: 'FILTRES PERSONNALISÉS',
    txt_global_std_mynumber: 'My number',
    txt_global_edj_private: 'Private',
    txt_global_std_tipcity3DESC: 'Le conducteur de la voiture bleue ralentit naturellement et traverse le passage à niveau en douceur. Le conducteur de la voiture rouge accélère et freine trop fort avant le passage à niveau.',
    'txt_global_std_(Or similar OBD-reader)': '(Or similar OBD-reader)',
    txt_global_std_WelcomeLetter_Text2Moderna: 'In this email, you will find your insurance documents including your insurance letter and pre-sale information. The complete insurance terms can be found in the {appName} app and at www.modernasmartflex.se. If something in the attached documents is incorrect, please contact us so that we can correct it. ',
    txt_global_std_pleasewaitwhileweauthenticateyouremail: 'Veuillez patienter pendant que nous authentifions votre e-mail',
    txt_global_std_doyouwanttojoinname: 'Voulez-vous rejoindre {name} ?',
    txt_global_std_whendrivingonmotorwaystoomanydriverspumptheacceleratorpedalupanddowninaneverendingpattern100kmhto110kmhthen110kmhto100kmhandsoonthistypeofdrivingusesunnecessaryfuelkeepingasafesuitablefollowingdistancecanhelpavoidthetemptationtovaryspeedunnecessarilynew: "Sur les autoroutes, trop de conducteurs poussent la pédale d'accélérateur de haut en bas sans fin : 100 km/h à 110 km/h, puis 110 km/h à 100 km/h et ainsi de suite ! Ce type de conduite consomme inutilement du carburant. Garder une distance de suivi sûre et appropriée peut aider à éviter la tentation de varier inutilement la vitesse.",
    txt_global_std_reportaccident: 'Report accident',
    txt_global_std_closingthispopupyouconfirmyouhaveredeemedyourpoints: 'En fermant cette fenêtre contextuelle, vous confirmez que vous avez échangé vos points.',
    txt_global_std_aryoudrivinganelectricorobd: '電気自動車を運転していますか、または、車載デバイスを取り付けていますか？',
    txt_global_std_previewheader: 'Aperçu',
    txt_global_std_activationletter_text15sidekick: "Avec l'application Sidekick, vous pouvez en savoir plus sur votre propre style de conduite et sur la façon dont vous pouvez devenir un conducteur plus prudent. De plus, vous pouvez économiser de l'argent sur l'assurance en obtenant un bon score de conduite !",
    txt_global_std_opened: ' Ouvert',
    txt_global_std_subheats: 'Sous-chaleurs',
    txt_global_std_thesevehiclesarealreadyactivated: ' Ces véhicules ont déjà été activés :',
    txt_global_std_avgmaxspeed7d: 'Vitesse maximale moyenne 7D',
    txt_global_std_PreSaleDetails_Text32: 'Combo - Rental car, Assistance and deductible reduction for damage',
    txt_global_std_teamco2: 'Économies de CO2 en équipe',
    txt_global_std_skillcomparison: ' Comparaison des compétences',
    txt_global_std_allowlocationaccess: "Autoriser l'accès à l'emplacement",
    txt_global_std_officialtimingpartner: 'PARTENAIRE DE CHRONOMÉTRAGE OFFICIEL',
    txt_global_std_createnew: 'Créer un nouveau',
    txt_global_std_saved: ' Sauvegardé',
    txt_global_std_doitlater: 'je le ferais plus tard',
    txt_global_std_yourinsurance: 'Votre assurance',
    txt_global_std_yougotthecodefrommail: 'You got the code from mail when you signed the incurance.',
    txt_global_std_gallonus: ' Gallon US',
    txt_global_std_Intro_Text16: '). Du kan også kontakte oss på chat i appen eller ringe oss på 04040.',
    txt_global_std_vehicle2: 'Véhicule',
    txt_global_std_pleaseenteryouremailaddress: ' Veuillez entrer votre adresse e-mail.',
    txt_global_std_SignupFia_Text2: ' Vous êtes sur la liste pour recevoir en avant-première les actualités et les informations concernant le FIA Smart Driving Challenge, le premier défi au monde en matière de conduite éco-responsable.',
    txt_global_std_NotDriving: "You haven't driven with your new car insurance",
    txt_global_std_webclaims: 'Revendications Web',
    txt_global_std_tidler_description: 'Inspection, wash and collection service.',
    txt_global_std_InsuranceMailFromApp: 'Your Insuranceletters',
    txt_global_std_xxperofourcustomers: "{perc} % de nos clients utilisent l'automatique en raison de sa facilité d'utilisation.",
    txt_global_std_groups: 'Groupes',
    txt_global_std_VehicleOwner: 'Fordonets ägare',
    txt_global_std_hereisapreviewofhowyourappwilllookifyouarehappywiththeresultpleasecontinuetopublishyourdemoapp: "Voici un aperçu de l'apparence de votre application. Si vous êtes satisfait du résultat, veuillez continuer à publier votre application de démonstration.",
    txt_global_std_amounttopay: 'Montant à payer',
    txt_global_std_cimage37d: ' Essayez de rester en roue libre à des vitesses différentes.',
    txt_global_std_forthebestexperience: 'Pour la meilleure expérience',
    txt_global_std_BeforeFirstBill_Text1: 'We will soon draw money from your card for your car insurance. We have compiled the most common questions that our customers have when receiving their first invoice. ',
    txt_global_std_clickontheareaswhereyourvehiclehasbeendamages: 'Cliquez sur les zones où votre véhicule a été endommagé',
    txt_global_std_riskindex_upsell_pitch_bulletpoint2: "L'impact des changements politiques, sanitaires et économiques sur le risque de conduite",
    txt_global_std_bodyrepair1panel: 'Carrosserie 1 panneau',
    txt_global_std_tripdata: 'Données de trajet',
    txt_global_std_premiumwithzipdrivingriskindex: 'Premium avec indice de risque Zip-Driving',
    txt_global_std_3selectyourcarinthelistbelow: '3. Sélectionnez votre voiture dans la liste ci-dessous.',
    'txt_global_std_selectthetypeofinjurythatyouwishtoregisterbelowandfollowtheinstructions.': 'Select the type of injury that you wish to register below and follow the instructions.',
    txt_global_std_aresetlinkhasbeensenttouser: "Un lien de réinitialisation a été envoyé à l'utilisateur",
    txt_global_std_idrivetomuch2: 'I drive too much',
    txt_global_std_doyouwanttochallengename: 'Voulez-vous défier {name} ?',
    txt_global_std_p2pbookingsummary: 'Résumé de la réservation',
    txt_global_std_weekw: ' Sem.',
    txt_global_std_export: 'Export',
    txt_global_std_ifyouhaveaninsuranceyoucanfindyourpolicynumberinyourinsurancedocuments: "Si vous avez une assurance, vous pouvez trouver votre numéro de police dans vos documents d'assurance.\n" +
      "Vous ne l’avez pas sous la main en ce moment? Pas de soucis! Vous pouvez l'ajouter plus tard dans les paramètres.",
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Vous avez vraiment bien conduit! Votre {scoretype} moyen était meilleur que {score} pendant une semaine! Vous avez gagné {points} points!',
    txt_global_std_PreSaleDetails_Text23: 'With a Partial Casco you get, in addition to liability insurance, protection for theft, rescue and justice, fire, glass and machine damage.',
    txt_global_std_clickdownbelowtoseehowitwent: 'Cliquez ci-dessous pour voir comment ça s&#39;est passé',
    txt_global_std_admin: 'Admin',
    txt_global_std_ActivationLetter_Text2: 'that is valid from ',
    txt_global_std_set_up_automatic_start_stop: 'Configuration : démarrage et arrêt automatiques',
    txt_global_std_youhavebeeninvitedtojoinagroup: 'Vous avez été invité à rejoindre un groupe',
    txt_global_std_HusseinAbdullaMileage: 'Nothing specific',
    txt_global_std_speedcostanalysis: ' ANALYSE DU COÛT DU RÉGIME',
    txt_global_std_co2reduced: 'CO₂ réduit',
    txt_global_std_cimage39h: ' RELÂCHEZ PLUS TÔT L’ACCÉLÉRATEUR – RESTEZ PLUS LONGTEMPS EN ROUE LIBRE',
    txt_global_std_youdroveextremelywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Vous avez extrêmement bien conduit! Votre moyenne était meilleure que {score} pendant une semaine! Vous avez gagné {points} points!',
    txt_global_std_p2pstart: 'Début:',
    txt_global_std_searchforusers: 'Rechercher des utilisateurs',
    txt_global_std_distancedriven: 'Distance parcourue',
    txt_global_std_drivertrainingrecommended: 'Formation à la conduite recommandée',
    txt_global_std_iunderstandthatthiswillcompletewipeallofmyaccount: ' Je comprends que cette action va supprimer définitivement mon compte ainsi que toutes mes archives de conduite.',
    txt_global_std_ownerdidnotgetthatnotification: "Le propriétaire n'a pas reçu cette notification",
    txt_global_std_p2pwaitingtoconfirmthespecifiedprice: 'En attente de confirmation du prix spécifié.',
    txt_global_std_cannotbeempty: 'Ne peux pas être vide',
    txt_global_std_acceptconnectioninapp: ' Accepter la connexion à l’application',
    txt_global_std_thisuserwillbeaddedtoyourplanatanadditionalcostofuserfeeperuser: 'Cet utilisateur sera ajouté à votre forfait moyennant un coût supplémentaire de {userfee} par utilisateur.',
    txt_global_std_theusermustacceptaninvitationtotheteam: "L'utilisateur doit accepter une invitation à l'équipe.",
    txt_global_std_acheivementcannotbedeleted: ' Veuillez noter que cette réalisation ne peut pas être supprimée.',
    txt_global_std_refer10friendsachievementunlocked: 'Vous avez parrainé 10 amis! Agréable! Vous avez gagné 300 points!',
    txt_global_std_female: ' Féminin',
    txt_global_std_member1: 'membre',
    txt_global_std_viewloyaltypoints1: 'Afficher les points de fidélité',
    txt_global_std_manageusersettingsandsupporthereusethequickaccessbuttonsbelowandnavigatebetweenusersbysearchingforthemintheusersview: "Gérez les paramètres utilisateur et l'assistance ici. Utilisez les boutons d'accès rapide ci-dessous et naviguez entre les utilisateurs en les recherchant dans la vue des utilisateurs.",
    txt_global_std_Label: 'Märke',
    txt_global_std_afteryourfirstnumberofmonthsmonthperiodourappwilltrackyourscoresandprovidearatebasedonyourdrivinghabitsifyouhaveafewbadscoresyouhaveanentirenumberofmonths2monthstoimproveyouwillreceiveupdatesalongthewaywitheachtripadvisingyouofyourpotentialsavingsorifyourneedtoprovidemorefocuswithyourdrivinghabits: 'Après votre première période de {numberOfMonths} mois, notre application suivra vos scores et fournira un taux basé sur vos habitudes de conduite. Si vous avez quelques mauvais scores, vous avez {numberOfMonths2} mois entiers pour vous améliorer. Vous recevrez des mises à jour en cours de route à chaque voyage, vous informant de vos économies potentielles ou si vous avez besoin de vous concentrer davantage sur vos habitudes de conduite.',
    txt_global_std_sunday: 'Dimanche',
    txt_global_std_youwillbereadysoon: 'Vous serez bientôt prêt!',
    txt_global_std_stepcurrentstepoftotalsteps: 'Étape {currentStep} sur {totalSteps}',
    txt_global_std_NotCreatedAccount6_Text1Moderna: "Your Moderna Smart Flex have now transferred to an annual, regular car insurance at Moderna Försäkringar, since you haven't verified your insurance in a month.",
    txt_global_std_advancedadmin: 'Administrateur avancé',
    txt_global_std_teamnames: "Noms d'équipe",
    txt_global_std_recoverpassword: ' Récupérer le mot de passe',
    txt_global_std_dontyouhaveanaccountwithenerfy: ' Avez-vous un compte sur {appName} ?',
    txt_global_std_ActivationLetter_Text1Moderna: 'Tack för att du har valt Smart Flex. Du har tecknat en försäkring för bilen',
    txt_global_std_cimage1d: ' Vitesse réduite',
    txt_global_std_Insurance_Information4: 'Insurance information',
    txt_global_std_seehowmuchyoucouldsave: 'Voyez combien vous pourriez économiser',
    txt_global_std_Recruit_Text2: 'för att teckna bilförsäkringen {appName}, där du kan få tillbaka pengar om du kör bra, och dessutom få 250 kr att köra för.',
    txt_global_std_showmehow: 'Me montrer comment',
    txt_global_std_yourownbrandedworkspaceopenforalldriverthatwishtoparticipate: 'Votre propre espace de travail de marque ouvert à tous les conducteurs qui souhaitent participer',
    txt_global_std_friendstothe: 'amis au',
    txt_global_std_timetoaddfriendsyoucan: 'Time to add friends? You can follow their progress in the Leaderboard!',
    txt_global_std_yougetthiswhenyoufirstachieveascoreabove4point4: 'Vous obtenez cela lorsque vous atteignez un score supérieur à 4,4',
    txt_global_EnerfySettingsTemplate_other: ' Autres',
    txt_global_std_ConfirmFia_Text1: ' Félicitations',
    txt_global_std_leaderboardpositionincrease: 'Votre position dans le classement a augmenté !',
    txt_global_std_KristofferssonDescription: 'Johan a débuté sa carrière dans la Super séries International avec l’équipe de Suède et a poursuivi avec le championnat de Touring Car (STCC) où il en sort vainqueur dans sa première saison, en 2002. Il continue sa poursuite en passant au Rallye-cross d’Europe la sauison suivant et la saison d’après dans le rallye-cross du monde (WRX) où il emporte 5 rallyes et termine sa saison en troisième place du classement, en 2015. Il termine à la deuxième place l’année suivant derrière son compatriote, Ekström, et en sort vainqueur en 2017.',
    txt_global_std_Intro_Text8: 'Med Sidekick kan du spare opptil 30% av forsikringspremien på god kjøring. Etter hver kjøretur vil du få en score fra 0-100 som viser hvor godt du har kjørt. For hver kjøretur over en kilometer og med score over 50, vil du få et beløp i sparegrisen din. Se på det som en belønning for god kjøring!',
    txt_global_std_settings: ' Paramètres',
    txt_global_std_youractivationcodecannotbefound: 'Your activation code cannot be found.',
    txt_global_std_alertdriveservicetitle: 'Service de conduite',
    txt_global_std_aftereachfinisheddrive: 'After each finished trip',
    txt_global_std_choseareason: 'Chose a reason',
    txt_global_std_HighMileage_Text6: 'og justerer kjørelengden der. Der vil du også finne vår anbefaling til kjørelengde. Hvis du overskrider årlig kjørelengde vil du ikke få full erstatning ved skade. Dette ønsker vi å unngå!',
    txt_global_std_warikan_onboarding5_2: '株式会社KINTO',
    txt_global_std_p2pbookingmessages: 'Messages de réservation',
    txt_global_std_carsharingemptyviewtitle: 'No Activities',
    txt_global_std_moreinformation: "Plus d'information",
    txt_global_std_hellothere: 'Bonjour!',
    txt_global_std_ifyourdriverhasproblemswithreceivingtheirpasswordyoucanviewitherenotethatthecurrentpasswordresetswhenyouclickthebutton: 'Si votre chauffeur rencontre des problèmes pour recevoir son mot de passe, vous pouvez le consulter ici. Notez que le mot de passe actuel est réinitialisé lorsque vous cliquez sur le bouton.',
    txt_global_std_profilepreferences: 'Préférences de profil',
    txt_global_std_continue: ' Continuer',
    txt_global_std_tiphighway2DESC: 'Le conducteur de la voiture bleue est bien planifié et garde un bon rythme et reste à bonne distance des voitures environnantes. Le conducteur de la voiture rouge fusionne constamment et adapte la voiture devant lui.',
    txt_global_std_p2pcarsharingismorefunwhenyouinviteyourfriendsandfamily: 'Le partage de voiture est plus amusant lorsque vous invitez vos amis et votre famille à se joindre à nous!',
    txt_global_std_areyousurethatyouwanttoterminateyourinsurance: 'Are you sure that you want to terminate your insurance?',
    txt_global_std_failedtogetreviewlist: "Impossible d'obtenir la liste des avis",
    txt_global_std_noofparticipants: 'Nbre de participants',
    txt_global_std_downloadapp: "Télécharger l'application",
    txt_global_all_weekday: ' En semaine',
    txt_global_std_Invite_Text3: 'Not an {appName}-user? No problem!',
    txt_global_std_choosethecarontheappscreenandwaituntilyougetnotificatedthatthedevicesarepaired: "Choisissez la voiture sur l'écran de l'application et attendez jusqu'à ce que vous soyez averti que les appareils sont couplés",
    txt_global_std_statusinitdriving: ' Connecté',
    txt_global_std_p2puploadpicturesofyourcartodocumentanyscratchesordents: 'Téléchargez des photos de votre voiture pour documenter des égratignures ou des bosses.',
    txt_global_std_p2pfinishbooking: 'Terminer la réservation',
    txt_global_std_acceptedpermissions: 'Autorisations acceptées',
    txt_global_std_publishachievementpopuptext: "Lors de la publication d'une réussite, son état verrouillé deviendra visible pour les utilisateurs dans l'application. Une fois qu'un utilisateur a reçu un badge, il s'affichera dans son état déverrouillé.",
    txt_global_std_highspeedconditions: 'Conditions de grande vitesse',
    txt_global_std_eastzone: 'Zone Est',
    txt_global_std_denneerndvendigforatappenkanvidehvornrduholderstilleeribevgelseaccelerererellerbremser: "Denne er nødvendig for at app'en kan vide, hvornår du holder stille, er i bevægelse, accelererer eller bremser.",
    txt_global_std_warikan_onboarding12: 'Tél：0120-075-912',
    txt_global_std_HansenChamp: 'Champion du Rallye-cross d’Europe en 2016, Rallye-Cross Lite Cup 2015, Champion du monde du ROK Junior en 2010',
    txt_global_std_connectionerrorwhileleavingchallenge: 'Connection error while leaving challenge',
    txt_global_std_communication_upsell_pitch_bulletpoint3: 'Connectez-vous avec les utilisateurs sur leurs canaux préférés',
    txt_global_std_VilliersWhyjoin: 'Because we all need to do what we can to make our roads safer, and also, this is gonna be really fun.',
    txt_global_std_p2psaveandclose: 'Sauver et fermer',
    txt_global_std_selectanotherdevice: 'Sélectionnez un autre appareil',
    txt_global_std_adduser: 'Ajouter un utilisateur',
    txt_global_std_pioneersmartdrivingchallenge: 'Défi de conduite intelligente des pionniers',
    txt_global_std_registrationnumber: 'Registration number',
    txt_global_std_exportpdf: ' Exporter au format PDF',
    txt_global_std_othermail: 'Other mail',
    txt_global_std_creatingaccountwiththirdpartyname: ' Création du compte avec {thirdPartyName}...',
    txt_global_std_automessage_level_3__1: 'YES!',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint1: 'Comprendre les facteurs qui déterminent le niveau de risque',
    txt_global_std_locationwithbest: 'Emplacement avec le meilleur potentiel d&#39;amélioration',
    txt_global_std_carbonclean: 'Carbone propre',
    txt_global_std_march: ' Mars',
    txt_global_std_3rddate: '3ème',
    txt_global_std_p2ppushbodynewfriend: 'Vous êtes maintenant ami avec {name} .',
    txt_global_std_thisteamisopenforalltojoin: 'This team is open for all to join',
    txt_global_std_people: ' Personne',
    txt_global_std_MSIG_InsuranceLetter20: '1) l&#39;assuré',
    txt_global_std_cimage15d: ' N’oubliez pas que ce sont les petites astuces qui font la différence.',
    txt_global_std_youractivatedkeys: 'Vos clés activées',
    txt_global_std_riskydecisionbetweentrips: 'Décision risquée entre les voyages',
    txt_global_std_youhavenowachievedyourbestscoresofar: 'Vous avez maintenant atteint votre meilleur score jusqu&#39;à présent (plus de 4,4)',
    txt_global_std_p2pshareakintocar: 'Partager une voiture KINTO',
    'txt_global_std_Al-KuwariInterests': 'Voiture de course, Endurance, Rallye-cross, Baja Rallyes',
    txt_global_std_foratappenskalfungerekorrektharvibrugforadgangtildintelefonslokationsamtmotionogfitnessdata: "Pour à app'en skal fungere korrekt, har vi brug pour adgang til din telefons lokation samt motion og fitness data.",
    txt_global_std_p2paddexpenses: 'Ajouter des dépenses',
    txt_global_std_BeforeBill_Text4: 'Håll avståndet för att undvika tvärnitar.',
    txt_global_std_nomapcoordinatesavailableforthisrun: ' Aucune coordonnée cartographique pour ce trajet',
    txt_global_std_cimage22h: ' ÉVITEZ LE RÉGULATEUR DE VITESSE',
    txt_global_std_emergencycontact: "Personne à contacter en cas d'urgence",
    txt_global_std_repetition1: 'Répétition',
    txt_global_std_p2pbookingrequests: 'Demandes de réservation',
    txt_global_std_addtosubscription: "Ajouter à l'abonnement",
    txt_global_std_PaymentTrouble2_Text4: 'If you have not already reviewed your payment information, we ask that you do so now.',
    txt_global_std_kintofaq6a1: 'Assurez-vous de suivre toutes les étapes de la section «Se connecter à la voiture». S&#39;il vous plaît envoyer un courriel',
    txt_global_std_yourfriendrequestwasaccepted: 'Your friend request was accepted',
    txt_global_std_enteravalidphonenumber: 'Entrez un numéro de téléphone valide.',
    txt_global_std_wednesday: 'Mercredi',
    txt_global_std_describeaccident: 'Décrire l&#39;accident',
    txt_global_std_pointslabel: 'Points',
    txt_global_std_automessage_level_4__2: 'Approved :)',
    txt_global_std_PaymentTrouble1_Text10Moderna: 'If you have already paid your premium, you may contact our customer service at: modernasmartflex@modernaforsakringar.se or telephone:',
    txt_global_std_drivingskills: 'Mes compétences',
    txt_global_std_takecontrolofyouraccountandprofile: 'Prenez le contrôle de votre compte et de votre profil.',
    txt_global_std_MSIG_Onboarding1: 'Bienvenue!',
    txt_global_std_loyaltyoverview: 'Aperçu de la fidélité',
    txt_global_std_warikanchallengerulesnotes: '【Remarques】\n' +
      `・En vous inscrivant, votre surnom et votre icône seront affichés aux autres utilisateurs. En cliquant sur "Rejoindre le concours", vous acceptez d'être lié par les termes et conditions de cet accord. Vous pouvez également masquer votre surnom sur l'écran des paramètres de votre profil.\n` +
      '・La distance doit être supérieure à 1 km par trajet.\n' +
      "・Le même voyage sera noté jusqu'à deux fois par jour.\n" +
      '・La distance totale doit être supérieure à 1 km.\n' +
      '・La chaleur correspond à la moyenne des scores et le total correspond à la moyenne des 10 % de vos meilleurs trajets.',
    txt_global_std_VilliersBesttip: 'Always be alert and anticipate what other drivers will do. They might turn right in front of you, so you need be alert to be able to stop in time or avoid them.',
    txt_global_std_TerminationConfirm_Text6: 'Sincerely,\r\n{appName}',
    txt_global_std_nopleasebeadvisedthattheenerfyapp: 'No. Please be advised that the Enerfy app needs to be installed on your phone and that you need to be logged in for your drives to get registered. You also need to have Bluetooth activated on your phone in order to connect.',
    txt_global_std_premium: 'Premium',
    txt_global_std_p2punpaid: 'Non payé',
    txt_global_std_youhavebeendrivingwithtrygdriveforayear: 'Vous conduisez avec TrygDrive depuis un an!',
    txt_global_std_latest: ' Dernier',
    txt_global_std_trygfromabroad: 'Tryg from abroad',
    txt_global_std_co2unlockedkey: 'Bon travail! Vous avez économisé plus de {co2value} % de CO₂ pendant ce voyage en conduisant de manière écologique.',
    txt_global_std_abctext2: 'High position with finger pressure • Apply pressure joint • Wrap the entire band • Tie over the wound',
    'txt_global_std_OBD status': 'OBD status',
    txt_global_std_PolicySchedule19: 'Primes brutes',
    txt_global_std_afewsimplereasonswhyfiasdcmakesadifferenceforyourbusiness: 'Quelques raisons simples pour lesquelles FIA SDC fait la différence pour votre entreprise',
    txt_global_std_bestregards: 'Cordialement',
    txt_global_std_distanceunit: 'Unité de distance',
    txt_global_std_pressthedrivebuttonintheapp: "Appuyez sur le bouton de conduite dans l'application",
    txt_global_std_ReportPolice: 'Är skadan polisanmäld',
    txt_global_std_card: 'Card',
    txt_global_std_at: 'à',
    txt_global_std_events_overview_season: 'Saison',
    txt_global_std_NewCar_Text3: 'Har du brug for hjælp?',
    txt_global_std_FirstDrive_Text7: "Do not rush while driving, even if you're in a hurry.",
    txt_global_std_p2p2totalcosttosplit: 'Coût total à diviser',
    txt_global_std_youpositiononleaderboardwasincreased: 'Votre position sur le classement a été augmentée !',
    txt_global_std_helptextsavingsinviteamounttext: 'Verver du en venn, får dere begge 250 kr i sparegrisen. Utbetalingen betinger at begge er kunde hos Tryg i minst to måneder. Beløp i markert skrift, viser gyldige beløp som er lagt til i sparegrisen.',
    txt_global_std_responses: 'Réponses',
    txt_global_std_avglevel123: 'Moy. niveau',
    txt_global_std_PolicySchedule34: 'N ° d&#39;enregistrement',
    txt_global_std_companyname: 'Nom de la compagnie',
    txt_global_std_yourealmostdonenowwejustneedtoverifyyouremailaverificationlinkhasbeensentto: "Vous avez presque fini! Il ne nous reste plus qu'à vérifier votre adresse e-mail. Un lien de vérification a été envoyé à :",
    txt_global_std_startatrip: ' Commencer un trajet',
    txt_global_std_ayouwillhave60daysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithcovercubewithalapseincoverage: "R : Vous aurez 60 jours à compter de la date d'expiration pour effectuer un paiement et continuer votre police avec Covercube avec une déchéance de couverture.",
    txt_global_std_done: 'Terminé!',
    txt_global_std_MSIG_InsuranceLetter2: 'COPIE',
    txt_global_std_rwmnotnow: 'Pas maintenant',
    txt_global_std_foratopretteforbindelsemellemdinbilogappnskaldergivesadgangtilbluetooth: 'Pour à oprette forbindelse mellem din bil og appén skal der donne adgang til bluetooth.',
    txt_global_std_FriendRequest_Text1: 'You have a new friend request!',
    txt_global_std_youvsecochampions: 'Vous contre les éco-champions',
    txt_global_std_carsharingcompanydescription: 'Petite phrase à ce sujet.',
    txt_global_std_circle: 'Cercle!',
    txt_global_std_p2pfaq1q: "Pourquoi certains boutons de l'application sont-ils grisés?",
    txt_global_std_selectlanglabel: 'Choisir la langue',
    txt_global_std_gennemsnitsbilist: 'Gennemsnits bilist',
    txt_global_std_loyaltysystemdesc: 'Veuillez noter que vos avantages sont basés sur les résultats finaux de la période de fidélité précédente. Les points sont gagnés en obtenant des succès et sont réinitialisés au début d&#39;une nouvelle période.',
    txt_global_std_connectiontype: 'Type de connexion',
    txt_global_std_manual_start_and_stop_requires_access_to_location_services_and_motion_fitness: "Le démarrage et l'arrêt manuels nécessitent l'accès aux services de localisation (GPS) et au mouvement et à la forme physique.",
    txt_global_std_pluginthedeviceinyourcar: 'Branchez l&#39;appareil dans votre voiture.',
    txt_global_std_yourdrivingskills: 'Vos compétences de conduite',
    txt_global_std_RecieptMail_Text15: 'With kind regards from {appName}.',
    'txt_global_std_Share on Facebook': 'Share on Facebook',
    txt_global_std_keepcollectingloyaltypointseventhoughsomeoneelseisdrivingyourcar: "Continuez à accumuler des points de fidélité même si quelqu'un d'autre conduit votre voiture!",
    txt_global_std_anyspeedgainisanaccelerationnomatterhowsmallsoplanandmakegooduseofeveryspeedincrease: 'Tout gain de vitesse est une accélération, aussi petite soit-elle. Alors, planifiez et faites bon usage de chaque augmentation de vitesse.',
    txt_global_std_validatedactivationcode: "Code d'activation validé",
    txt_global_std_p2pchoosehowtosplitcost: 'Choisissez comment diviser les coûts',
    txt_global_std_yourlinkshouldbeavailableinyourinboxinminutespleaseremembertocheckyourjunk: "Votre lien devrait être disponible dans votre boîte de réception dans {number} minutes. N'oubliez pas de vérifier vos indésirables.",
    txt_global_std_userwithoutinsurance: '{appName}-user without insurance',
    txt_global_std_successfullyunpublishedlanguage: 'Langue non publiée avec succès',
    txt_global_std_p2pfaq24q: 'De quelle manière pouvez-vous diviser le coût',
    txt_global_std_NotCreatedAccount1_Text6Moderna: 'Do you need help with creating an account? Please contact us at: modernasmartflex@modernaforsakringar.se or 0200-265 265.',
    txt_global_std_nointernetconnection2: 'Pas de connexion Internet!',
    txt_global_std_bluetoothlink: ' Connexion Bluetooth',
    txt_global_std_OgierMusic: 'Coming soon...',
    txt_global_std_congratulations2: 'Toutes nos félicitations',
    txt_global_std_events_overview_today: 'Aujourd&#39;hui',
    txt_global_std_Insurance_Totalcost: 'Total amount',
    txt_global_std_userhasbeenscheduledforremoval: "La suppression de l'utilisateur {{email}} a été programmée à {{timestamp}}.",
    txt_global_std_fileaclaimdirectlyintheappbyclickingfileclaimlinkorbycallingphonenumber: "Déposez une réclamation directement dans l'application en cliquant sur {fileClaimLink} ou en appelant {phoneNumber}.",
    txt_global_std_p2ppushtitleexpensesdenied: 'Expenses denied',
    txt_global_std_PreSaleDetails_Text42: 'When you take out the insurance, you have a right of withdrawal, which allows you to cancel the purchase within 14 days. The withdrawal period begins the day you sign the policy and are informed of the withdrawal right and what is included in the insurance. If you want to exercise your right you can, for example, contact our customer service. If you have already paid your monthly premium, you get back the premium less the time the insurance was valid. If the right of withdrawal is exercised, Greater Than has the right to require monthly premiums corresponding to the period the insurance is in force.',
    txt_global_std_HSolbergMileage: 'Love to drive on snow and ice, that is the ultimate driving fun.',
    txt_global_std_Reminder_Reminder: 'Reminder',
    txt_global_std_info: ' Informations',
    txt_global_std_newpasswordsaved: ' Nouveau mot de passe sauvegardé',
    txt_global_std_texttofriend: 'Texte à un ami',
    txt_global_std_andnotfromyouragegender: 'and not from your age, gender or where you live.',
    txt_global_std_connected2: 'Lié',
    txt_global_std_InsuranceMail_Text15: 'Machine additional deductible for imported car',
    txt_global_std_p2piwanttoborrowakinto: 'Je veux emprunter un Kinto',
    txt_global_std_FirstDrive_Text8: 'Keep distance to the car in the front, even at low speeds.',
    txt_global_std_welcometoenerfyfirstname: 'Bienvenue sur {appName} !',
    txt_global_std_MobilePhone: 'Mobiltelefon',
    txt_global_std_charactercountexceeded: 'Nombre de caractères dépassé',
    txt_global_std_roadconditionswithbest: 'Conditions de route présentant le meilleur potentiel d&#39;amélioration',
    txt_global_std_opensettingsonyourphoneandmakesuretohavebluetoothactivated: 'Ouvrez les paramètres de votre téléphone et assurez-vous que le Bluetooth est activé.',
    txt_global_std_compareriskandco2levelsacrossyourorganization: 'Comparez les niveaux de risque et de CO2 dans votre organisation',
    txt_global_std_yourmessagehasbeencreated: 'Votre message a été créé !',
    txt_global_std_redeemed: 'Racheté',
    txt_global_std_PreSaleDetails_Text39: 'Claims Settlement',
    txt_global_std_lastweek: 'Last week',
    txt_global_std_specialimagefilenameid: 'Image personnalisée',
    txt_global_std_improveheader: ' Meilleure marque',
    txt_global_std_yesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforagentinsurance: "Oui, vous pouvez télécharger l'application et choisir l'option « essayer l'application ». Vous pourrez conduire avec l'application et voir combien vous pourriez économiser si vous souscriviez à l'assurance {agent}.",
    txt_global_std_select: 'Sélectionner',
    txt_global_std_Insurance_EcoSafeText: '* Through eco safe and energy efficient driving you have gained an EcoSafe discount',
    txt_global_std_youarenowinvisible: 'You are now invisible!',
    txt_global_std_p2pimakintoowner: 'Je suis propriétaire de Kinto',
    txt_global_std_searchbynamegrouporaccounttype: 'Rechercher par nom, groupe ou type de compte',
    txt_global_std_tripsinsight: 'Trajets',
    txt_global_std_removeaccountpluspersonaldata: ' Supprimer le compte + les données personnelles',
    txt_global_std_drivertobeannounced: ' Le conducteur va être annoncé',
    txt_global_std_dennekretur: 'Denne køretur',
    txt_global_std_p2puploadreceipt: "Téléchargez l'image de votre reçu",
    txt_global_std_dailytrips: 'Voyages quotidiens',
    txt_global_std_thisisanexampletitle: 'Ceci est un exemple de titre',
    txt_global_std_invitationcode: "Code d'invitation",
    txt_global_std_p2pnamecancelledbooking: '{name} réservation annulée',
    txt_global_std_Insurance_Name: 'Name',
    txt_global_std_connecttocar: 'Connectez-vous à la voiture',
    txt_global_std_experiencethebenefits: ' Profitez des avantages de {appName}',
    txt_global_std_p2pfaq13a: '1.Accédez au récapitulatif de votre réservation.\n' +
      '2.Appuyez sur le bouton Terminer la réservation.',
    txt_global_std_moderna_description: 'Insure even the home!',
    'txt_global_std_Please check your internet connection': 'Please check your internet connection',
    txt_global_std_discountcodetermsconditions: 'Conditions générales du code de réduction',
    txt_global_std_Fia_Receipt_Text6: 'VOTRE REÇU:',
    txt_global_std_p2pfaq21q: 'Je veux vérifier les membres du groupe.',
    txt_global_std_addemail: 'Ajouter un e-mail',
    txt_global_std_Fia_Receipt_Text8: 'Votre commande',
    txt_global_std_youcannotselectmorethantwomenuitems: 'You cannot select more than two menu items.',
    txt_global_std_totaltrips: 'Total trips',
    txt_global_std_enterteamname: 'Enter team name',
    txt_global_std_teamprivacy: 'Team privacy',
    txt_global_std_pursuealowerpremium: 'Pursue a lower premium!',
    txt_global_std_p2pfaq25a: '1. Sélectionnez « Données de conduite » dans le « Menu ».\n' +
      '2. Vous pouvez vérifier les données de votre historique de conduite.\n' +
      `3. Si vous sélectionnez l'onglet "Partager les données", vous pouvez vérifier les données de conduite du groupe ainsi que l'historique des membres.`,
    txt_global_std_driverslog: ' Journal du conducteur',
    txt_global_std_achievementcannotbedeleted: 'Veuillez noter que cette réalisation ne peut pas être supprimée.',
    'txt_global_std_Please select your reader to proceed.': 'Please select your reader to proceed.',
    txt_global_std_format2: 'Format',
    txt_global_std_addexpenseserror: 'Veuillez ajouter votre type de dépenses, votre coût et votre image.',
    txt_global_std_invitedrivers: 'Inviter des chauffeurs',
    txt_global_std_endtime: ' Heure de fin',
    txt_global_std_2attachtheobddevicetothecableandplugitintothe12vpowercigarettelightersocketinyourcar: "2. Connectez le périphérique OBD au câble et branchez-le sur la prise d'alimentation 12 V (allume-cigare) de votre voiture.",
    txt_global_std_alreadyhaveaninsurance: 'Vous avez déjà une assurance ?',
    txt_global_std_weareproudyouwanttobe: ' Nous sommes fiers que vous souhaitiez faire partie de {appName} !',
    txt_global_std_icannotconnectanswertext2: 'pour aider.',
    txt_global_std_invitefriends: ' Inviter des amis',
    txt_global_std_successfullyaddedtogroups: 'Ajouté aux groupes avec succès',
    txt_global_std_yes: ' Oui',
    txt_global_std_joinenerfyandgetawarded: 'Rejoignez Enerfy et soyez récompensé!',
    txt_global_std_ecovehicles: 'ECO vehicles',
    txt_global_std_entert: ' Entrer',
    txt_global_std_acceptfitnessmotion: 'Accepter fitness / mouvement',
    txt_global_std_deepdiveintodrivers: "Découvrez en profondeur les conducteurs et les titulaires de police : performances, probabilité d'accident, conduite écologique, émissions, etc. <br /> Une fois que vous avez activé un rapport, vous pouvez le télécharger autant de fois que vous le souhaitez.",
    txt_global_std_congratulationsyoujustclimbedtheleaderboardviewyourranking: 'Félicitations ! Vous venez de grimper dans le classement ! Consultez votre classement.',
    txt_global_std_ecoscorescore: ' Score écologique {score}',
    txt_global_std_welcome: 'Bienvenue !',
    txt_global_std_onlylettersaznumbers09andperiodsareallowed: 'Seuls les lettres (az), les chiffres (0-9) et les points (.) sont autorisés.',
    txt_global_std_connectionerrorwhilejoiningchallenge: 'Connection error while joining challenge',
    txt_global_std_finalistsheat3: 'Finalistes - Série 3',
    txt_global_std_username: ' Nom d’utilisateur',
    txt_global_std_customnotification: 'Avis personnalisé',
    txt_global_std_friend: ' Ami',
    'txt_global_std_Al-KuwariDescription': 'Nasser a emporté 2 titres au MERC 2  en deux années successives, 2017 et 2018, et deuxième au classement de la FIA Cross-country en 2016 en tant que copilote. Il a emporté pas moins que neuf fois le championnat de Qatar et quatre fois le rallye de Kuwait, toujours en tant que copilote.',
    txt_global_std_NotDrivingForATime: "You haven't driven with your car insurance for a while",
    txt_global_std_checkyouremailformoredetails: 'Vérifiez votre e-mail pour plus de détails.',
    txt_global_std_f_lanzquote: "Là où réside la passion, l'effort coule passionnément",
    txt_global_std_loginorcreateaccounthere: 'Connectez-vous ou créez un compte ici',
    txt_global_std_averagepoint: 'Average points',
    txt_global_std_TerminationConfirm: 'Termination Confirm',
    txt_global_std_PSolbergMusic: 'Coming soon...',
    txt_global_std_bronzeusers: 'Utilisateurs Bronze',
    txt_global_std_internalservererrorjsonnotvalid: 'Erreur de serveur interne (JSON non valide)',
    txt_global_std_cimage2d: ' Conservez au minimum 3 secondes d’écart avec le véhicule qui vous précède.',
    txt_global_std_PreSaleDetails_Text14: 'As an insurance policy holder, what should I consider?',
    txt_global_std_loyaltynumber: 'Numéro de fidélité',
    txt_global_std_newmobilitycompanydescription: 'Mobilité intelligente à la demande.',
    txt_global_std_anerroroccurredwhiletryingtousetheactivationkey: "Une erreur s'est produite lors de l'utilisation de la clée d'activation",
    txt_global_std_p2pselectafriendtoviewallbookingsoftheircar: 'Sélectionnez un ami pour afficher toutes les réservations de sa voiture.',
    txt_global_std_mystatsthisperiod: 'Mes statistiques cette période',
    txt_global_std_second: ' Seconde',
    txt_global_std_calculationexample: 'Exemple de calcul',
    txt_global_std_invitationlink: "Lien d'invitation",
    txt_global_std_FirstDrive_Text9: 'Stay 100% focused on your driving.',
    txt_global_std_PaymentTrouble1_Text9: 'We will try to process your payment again shortly, but please review your payment information in the app in case it needs to be updated. We will inform you again if the payment cannot be processed.',
    txt_global_std_oilchange: 'Vidange',
    txt_global_std_waxing: 'Fartage',
    txt_global_std_teamco2savings: "ÉCONOMIES DE CO2 DE L'ÉQUIPE",
    txt_global_std_ecomode: 'Eco mode',
    txt_global_std_driversselected: 'pilotes sélectionnés',
    txt_global_std_ayescovercubeacceptsdriverswhoarelicensedinothercountries: "R : Oui, Covercube accepte les conducteurs titulaires d'un permis dans d'autres pays.",
    txt_global_std_minutemin: ' min',
    txt_global_std_sparknamekey: 'Étincelle!',
    txt_global_std_endsin: 'Se termine',
    txt_global_std_p2phasspecifiedprice: ' a spécifié un prix.',
    txt_global_std_consentInfoYes: 'Rejoignez-nous dès le début, rejoignez notre communauté et faites partie de Tryg Drive. Une fois que vous avez donné votre consentement, vous pouvez participer à nos compétitions et obtenir un accès complet aux fonctionnalités de l&#39;application. Tryg peut me contacter par courrier électronique, via l&#39;application Tryg Drive et par SMS afin de recevoir des nouvelles et des offres spéciales de Tryg A / S concernant l&#39;assurance, les produits d&#39;assurance non-vie, les concours et les autres services Tryg. Vous pouvez toujours demander des demandes de marketing facilement et gratuitement.',
    txt_global_std_achieved: 'Atteint',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatus: 'Les réalisations vous aident à collecter des points pour gagner votre statut de fidélité. Découvrez ci-dessous les réalisations que vous pouvez gagner en étant un super pilote {appname} !',
    txt_global_std_drivername: ' Nom du conducteur',
    txt_global_std_ShareCar_Text4: 'Kom igång:',
    txt_global_std_constantspeeddescription: ' Essayez de réduire votre vitesse maximale. Une vitesse de pointe importante n’est pas forcément synonyme de vitesse moyenne élevée.',
    txt_global_std_southzone: 'Zone sud',
    txt_global_std_thisisagorillatest: 'Ceci est un test de gorille',
    txt_global_std_withtheenerfyriskportfoliotrackeryouget: "Avec Enerfy Risk Portfolio Tracker, vous obtenez une vue d'ensemble du risque réel et de l'impact sur le CO2.",
    'txt_global_std_afteryourfirsttripwithenerfyyoucaneasilyfindyour...': 'After your first trip with {appName} you can easily find your car by navigating to its exakt position. Get started by driving with {appName} now.',
    txt_global_std_p2pnonsequentialdatesselected2: 'Dates non séquentielles sélectionnées',
    txt_global_std_unlockatbronzelevel: 'Débloquer au niveau bronze',
    txt_global_std_insureddistance_used_90__1: 'Du har nu använt 90% av din årliga valda körsträcka. Kom ihåg att justera körsträckan så att du undviker förkortning vid skador. Du kan justera körsträckan här.',
    txt_global_std_dailydevelopment: 'Daily development',
    txt_global_std_duedate2: 'Date d&#39;échéance',
    txt_global_std_p2ppushbodytimetosplitcost: 'Split your cost for {monthname}.',
    txt_global_std_wanttoshareyourprogress: 'Envie de partager vos progrès ?',
    txt_global_std_p2plendtext: '{name} veut vous prêter {vehicle}',
    txt_global_std_OgierInterests: 'Coming soon...',
    txt_global_std_kintoImprovement4: 'Si proche! Vous auriez pu gagner {savings} ¥ sur ce voyage. Concentrons-nous!',
    txt_global_std_apitool_upsell_pitch: "Soyez opérationnel en un rien de temps avec ce point d'entrée complet pour les développeurs, pour;",
    txt_global_std_welcometoenerfyglobalworkspace: "Bienvenue dans l'espace de travail mondial Enerfy !",
    txt_global_std_p2pcreateanameforthisgroup: 'Créez un nom pour ce groupe',
    txt_global_std_ReportOwnerMail: 'Anmälarens e-post',
    txt_global_std_youdonothaveanyupcomingchallengesatthemoment: "Vous n'avez pas de défis à venir pour le moment.",
    txt_global_std_exploretheappmanual: 'Explorez le manuel de l&#39;application',
    txt_global_std_status1: 'Statut',
    txt_global_std_ayesyoucanswitchyourinsuranceanytimemostinsurancecompaniesdonothaveanearlyterminationpenaltybutyouwillneedtoinquirewithyourcurrentinsurancecarrier: "R : Oui, vous pouvez changer d'assurance à tout moment. La plupart des compagnies d'assurance n'ont pas de pénalité de résiliation anticipée, mais vous devrez vous renseigner auprès de votre assureur actuel.",
    txt_global_std_edityourcar: 'Modifiez votre voiture',
    txt_global_std_signinwith: ' Ouvrir une session pour {name}',
    txt_global_std_addmultipleusers: 'Ajouter plusieurs utilisateurs',
    txt_global_std_onlyshowdriversthatneedaction: 'Afficher uniquement les conducteurs nécessitant une action',
    txt_global_std_p2pborrowedfrom: 'Emprunté de',
    txt_global_std_p2pyoucaninvitexfriends: 'Vous pouvez inviter {number} amis',
    txt_global_std_competeonlyasindividualsorbothindividuallyandinteamsteamscouldbedifferentdivisionsatyourcompany: "Concourir uniquement en tant qu'individus ou à la fois individuellement et en équipe. Les équipes peuvent être différentes divisions de votre entreprise.",
    txt_global_std_collisionandtrailerdamage: 'Collision & Trailer Damage',
    txt_global_std_doyouwanttocancelconnectionandchangeobdreader: ' Souhaitez-vous vraiment annuler la connexion et changer le lecteur de diagnostic embarqué ?',
    txt_global_std_yesyoumustadviseyouragentthatyourvehicleisusedforaridesharingcompanyandtheywillapplytheridesharingendorsementtoyourpolicy: "Oui, vous devez informer votre agent que votre véhicule est utilisé pour une société de covoiturage et il appliquera l'avenant Covoiturage à votre police.",
    txt_global_std_pointexchange: 'Échange de points',
    txt_global_std_NotActivatedAccount_Text2Moderna: 'If you don’t plan on driving anytime soon, we would very much appreciate it if you let us know via ”My Pages” in the app. Otherwise, we must continuously check your cars meter readings to make sure that everything is in order.',
    txt_global_std_createatrip: 'Créer un voyage',
    txt_global_std_removeaccount_subject: 'Supprimer le compte',
    txt_global_std_p2pchoosepaymentmethod: 'Choisissez le mode de paiement',
    txt_global_std_pleasewaitaminuteortwobeforeyourefreshthisviewtogetnewsuggestions: ' Veuillez patienter une minute ou deux avant de rafraîchir cet aperçu et obtenir d’autres suggestions.',
    txt_global_std_IfWitness: 'Finns det några vittnen till händelsen',
    txt_global_std_p2psentaneditrequest: 'a envoyé une demande de modification',
    txt_global_std_exportfrom: 'Export from ',
    txt_global_std_accelerationdescription: ' Une accélération tout en souplesse est bien souvent plus sûre et elle vous permet, en plus, d’économiser du carburant.',
    txt_global_std_drivesafeandecofriendlysavemoneyonyourinsurance: "Conduisez prudemment et respectueux de l'environnement - Économisez de l'argent sur votre assurance.",
    'txt_global_std_action/reason': 'Action/reason',
    txt_global_std_TaxInvoice13: 'La garantie est respectée une fois le paiement effectué à l’assureur ou à l’agent ou au courtier avant l’entrée en vigueur de la police.',
    txt_global_std_asaninsurancecustomerofours: 'As an insurance customer of ours, you can quickly and easily lend your car to a neighbor or friend. Through our solution you get compensation for all costs such as insurance, wear and burn.',
    txt_global_std_readdocumentation: 'Lire la documentation',
    txt_global_std_nodriversinthislistyet: 'Pas encore de conducteurs dans la liste',
    txt_global_std_temporarypassword_: 'Mot de passe temporaire',
    txt_global_std_FirstDrive_Text11: 'You are always insured with us. But to take advantage of the EcoSafe-discount, which is up to 70%, you have to bring your smartphone to communicate with {appName} Plug-in. Drives without your smartphone will be based on the average premium level our customers usually drive in, which is level 10.',
    txt_global_std_thischallenge: 'Ce défi',
    txt_global_std_therearenodrivesrecordedforchosenperiod: 'There are no trips recorded for chosen period.',
    txt_global_std_addyounameandcountryetcenerfybecomesmorefun: 'Add your name and country etc! {appName} becomes much more fun and personal having your details filled in.',
    txt_global_std_inyourcar: 'dans votre voiture',
    txt_global_std_p2ptobepaid: 'Être payé',
    txt_global_std_division2: 'Division',
    txt_global_std_cropyourprofileimage: ' Rogner votre image de profil',
    txt_global_std_Invite_Text6: '(Enter the code:',
    txt_global_std_MikkelsenInterests: 'Motocross, Ski alpin, la pêche à la ligne, sports en général.',
    txt_global_std_congratulations: 'Congratulations',
    txt_global_std_acceptpermissions: 'Accepter les autorisations',
    txt_global_std_PaymentTrouble3_Text2: 'SEK for the period',
    txt_global_std_createteam: 'Create team',
    txt_global_std_p2pshareyourcarwithfamilyandfriends: 'Partagez votre voiture avec votre famille et vos amis',
    txt_global_std_PreSaleDetails_Text65: 'Other Information',
    txt_global_std_previousstep: 'Étape précédente',
    txt_global_std_cimage29h: ' ATTEIGNEZ VOTRE VITESSE DE CROISIÈRE – PLANIFIÉE',
    txt_global_std_PSolbergChamp: 'World RX Champion 2014 & 2015, WRC Champion 13 times, Rally GB Champion 2002 & 2003',
    txt_global_std_p2pselectfriend: 'Sélectionnez un ami',
    txt_global_std_p2pthischatmightnotbeprivatealladminsandownersofthecarcanseethischat: "Ce chat n'est peut-être pas privé. Tous les administrateurs et propriétaires de la voiture peuvent voir ce chat.",
    txt_global_std_p2pfaq2a: '1.Accédez au récapitulatif de la réservation pour cette réservation et appuyez sur le bouton Modifier la réservation.\n' +
      "2.Réglez la nouvelle date et l'heure et appuyez sur envoyer la mise à jour.\n" +
      '3.Attendez que votre ami confirme votre nouvelle heure proposée.',
    txt_global_std_p2ppushtitlelendcarnomessage: 'Nouvelle demande de prêt reçue',
    txt_global_std_lastday: 'Last day',
    txt_global_std_nicethatyourehere: "Nice that you're here! Let's get started! Set up your insurance in a few quick steps.",
    txt_global_std_earnings: 'Gains',
    txt_global_std_greatdrivertoday: "Super pilote aujourd'hui",
    txt_global_std_JyrkiainenInterests: 'Sports automobiles, jogging, golf, faire du ski, conduire de la motoneige dans la Laponie finlandaise\t',
    txt_global_std_mileageinkm: 'Kilométrage en km',
    txt_global_std_hours: ' Heures',
    txt_global_std_parkedvsstartoftrip: 'Stationné vs début de voyage',
    txt_global_std_milesdriven: 'Miles parcourus',
    txt_global_std_getitfilled: ' Faites le plein !',
    txt_global_std_okletsfindthem: "OK, let's find them!",
    txt_global_std_collectpointstounlock: 'Collectez des points pour débloquer.',
    txt_global_std_groupsadministration: 'Group administration',
    txt_global_std_youmustfillinthemileage: 'Vous devez remplir le kilométrage',
    txt_global_std_continueWithEarlierReport: 'Continue with earlier report',
    txt_global_std_achievementsreceived: 'réalisations reçues',
    txt_global_std_registerakinto: 'Enregistrer un Kinto',
    txt_global_std_rwmnotripalreadyregisterforthisweektext: "Nous sommes désolés, vous ne pouvez enregistrer un trajet en covoiturage et gagner des points qu'une fois par semaine.",
    txt_global_std_NewPW_Text4: ' Cordialement, {appName}',
    txt_global_std_ontheinjury: 'on the injury',
    txt_global_std_p2pdeletemessages: 'Supprimer les messages',
    txt_global_std_p2prewardyourfriend: 'Récompensez votre ami',
    txt_global_std_actions: 'Actions',
    txt_global_std_loyaltycertificate: 'Certificat de fidélité',
    txt_global_std_InsuranceMail_Text23: 'Rental',
    txt_global_std_mystatsthisday: 'Mes statistiques pour le dernier jour',
    txt_global_std_kintorecruit5usersunlocked: 'Je vous remercie! Vous avez recruté 5 utilisateurs Kinto. 10 000 ¥ ont été envoyés à votre portefeuille Kinto.',
    txt_global_std_whenwillyoudeducttheinvoicepayment: 'When will you deduct the invoice payment?',
    txt_global_std_phone: ' Téléphone',
    txt_global_std_zipdriver_upsell_pitch: "Analyse géographique à l'aide d'une base de données de risques de codes postaux/codes postaux pour ;",
    txt_global_std_messagetextisrequired: 'Message text is required!',
    txt_global_std_p2pbooksequentialdates: 'Réserver des dates séquentielles',
    txt_global_std_turnonthewarninglight: 'Allumez le voyant',
    txt_global_std_insurancepolicydocuments: "Documents de police d'assurance",
    txt_global_std_youdroveatscoretypescoreorbettergreatyouhaveearnedpointspoints: 'Vous avez conduit à {score} ou mieux! Génial! Vous avez gagné {points} points!',
    txt_global_std_OSolbergInterests: 'Coming soon...',
    txt_global_std_qidonthavebluetoothinmycarcanistillusetheapp: "Q : Je n'ai pas de Bluetooth dans ma voiture, puis-je quand même utiliser l'application ?",
    txt_global_std_drivesinbadgroups: 'Drives in bad groups',
    txt_global_std_PreSaleDetails_Text7: "Your insurance is valid from the date specified in the policy. Supplementary insurance that you take while your insurance is valid begins the day after you signed the supplementary insurance. The insurance is valid for the specified vehicle. Remember that, by law, you must have liability insurance from the day you become the owner of the car, the old owner's liability insurance does not apply to you.",
    txt_global_std_qhowdoirenewmyinsurance: 'Q : Comment renouveler mon assurance ?',
    'txt_global_std_youvs{challenger}': 'You vs {challenger}',
    txt_global_std_MSIG_InsuranceLetter12: 'Période d&#39;assurance',
    txt_global_std_acommontypeofunsafedrivingbehaviorisputtinghugeeffortintochasingthenexttrafficlightitslikeachallengetogettothenextcrossingasquicklyaspossibleonlytobestuckwaitingforthelighttoturngreendrivingsmoothlyandlookingwellaheadtoanticipatewhatscomingupsavesbothtimeandfuel: "Un type courant de comportement de conduite dangereux consiste à déployer d'énormes efforts pour courir après le prochain feu de circulation ! C'est comme un défi d'arriver au prochain passage à niveau le plus rapidement possible pour être bloqué en attendant que le feu passe au vert 😊 Conduire en douceur et regarder loin devant pour anticiper ce qui s'en vient permet d'économiser du temps et du carburant.",
    txt_global_std_youmayhavetogoto: 'Vous devrez peut-être aller à',
    txt_global_std_what: 'Quoi',
    txt_global_std_wanttobuyinsurance: 'Vous souhaitez souscrire une assurance ?',
    txt_global_std_unlockbenefitsenterpolicynumber: 'Déverrouiller les avantages - Entrez le numéro de police',
    txt_global_std_timing: 'Horaire',
    txt_global_std_bydrivingsafeandsmartrememberto: 'En conduisant prudemment et intelligemment. Se souvenir de:',
    txt_global_std_yourhead2headrequestwasaccepted: 'Your challenge was accepted',
    txt_global_std_p2pcompleted: 'Terminé',
    txt_global_std_client: 'Client',
    txt_global_std_whenusingthiscodeorlinkuserwillbeonboardedtoyourenerfyglobalteamandthefollowingsubgroupgroups: "Lorsque vous utilisez ce code ou ce lien, l'utilisateur sera intégré à votre équipe Enerfy Global et aux sous-groupes/groupes suivants",
    txt_global_std_p2precommended: 'conseillé',
    txt_global_std_cwelcome8: 'Hallo',
    txt_global_std_avgspeed: ' Vitesse moy.',
    txt_global_std_InsuranceMail_Text9: 'Theft',
    txt_global_std_photos: ' Photos',
    txt_global_std_pickyourteam: 'Choisissez votre équipe',
    txt_global_std_p2phoursused: 'Heures utilisées',
    txt_global_std_automatichelpsyourecord: "La connexion automatique vous aide à enregistrer vos trajets au cas où vous oublieriez d'ouvrir l'application.",
    txt_global_std_savingstemplatetitle: 'Savings',
    txt_global_std_invitationqrcode: "Code QR d'invitation",
    txt_global_std_randomchallenge: 'Random challenge',
    txt_global_std_connectengine: 'Start the engine to connect',
    txt_global_std_driverforscore: 'Conduisez {appname} pour un score',
    txt_global_std_cimage26d: ' ',
    txt_global_std_ConfirmFia_Text8: ' À très bientôt !',
    txt_global_std_ringphoneno: 'Sonner {phoneNo}',
    txt_global_std_northzone: 'Zone nord',
    txt_global_std_NotActivatedAccount_Text3: 'Do you need help with creating an account? Please contact us at: info@enerfy.se',
    txt_global_std_p2pdistancetravelledrequiresbluetooth: 'La distance parcourue nécessite Bluetooth',
    txt_global_std_howdoesthiswork: 'How does this work?',
    txt_global_std_name: ' Nom',
    txt_global_std_customize: 'Personnaliser',
    txt_global_std_p2pwelcometokintowarikan: 'Bienvenue à Kinto Warikan!',
    txt_global_std_cartype: 'Type de voiture',
    txt_global_std_unknownstreet: 'Rue inconnue',
    txt_global_std_p2ppushbodyyouhaveabookingtofinalize: 'Veuillez noter qu&#39;une réservation n&#39;est pas réglée',
    txt_global_std_imagepickerlocked: "Choisissez l'icône du badge verrouillé",
    txt_global_std_Insurance_CarModel: 'Model year',
    txt_global_std_vouchercodesaved: 'Voucher code saved',
    txt_global_std_enerfyanalytics_upsell_pitch: 'Obtenez une compréhension complète de vos données avec des rapports personnalisés ou prédéfinis pour;',
    txt_global_std_warikan_onboarding1_2: 'わりかんKINTOご利用者様',
    txt_global_std_inviteafriendviaemail: 'Invite a friend via email',
    txt_global_std_PreSaleDetails_Text58: 'If the insured vehicle cannot be used due to damage that is compensable, compensation will be 75% of the daily cost and cost per kilometer. Instead of a rental car, compensation can be given in the form of cash compensation amounting to 100 SEK a day. Compensation is paid for a maximum of 60 days. The insurance expires at the renewal of the calendar year as the car reaches 15 years, counted from the first registration date.',
    txt_global_std_Insurance_VariableDrivingCost: 'Variable driving cost',
    txt_global_std_selectdate: 'Sélectionner une date',
    txt_global_std_invite10friendstojointhechallengeforabetterworld: 'Invitez 10 amis à rejoindre le défi pour un monde meilleur.',
    txt_global_std_activate: 'Activer compte',
    txt_global_std_makesureyouandothersareinasafeplaceifanyoneisinjuredcallphoneno: "Assurez-vous que vous et les autres êtes dans un endroit sûr. Si quelqu'un est blessé, appelez : {phoneNo}",
    txt_global_std_FirstDrive_Text3: 'How will you measure my premium?',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatuscheckoutbelowtheachievementsyoucanearnbybeinga: 'Les réalisations vous aident à collecter des points pour gagner votre statut de fidélité. Découvrez ci-dessous les réalisations que vous pouvez gagner en étant un',
    txt_global_std_oncetheapprecognisestheobdreaderyoullmoveautomaticallytothenextstep: "Une fois que l'application reconnaît le lecteur OBD, vous passerez automatiquement à l'étape suivante.",
    txt_global_std_greatdrive: 'Super lecteur!',
    txt_global_std_thankyouforyourorderenerfy: 'Nous vous remercions de votre commande. Votre plug-in Enerfy vous sera envoyé le jour ouvrable suivant. La livraison estimée est de 1-3 jours ouvrables.',
    txt_global_std_internalservererrorpleasecontactinfogreaterthaneulicensekeynotvalid: 'Erreur de serveur interne, veuillez contacter info@greaterthan.eu (clé de licence non valide)',
    txt_global_std_countrycode: 'Code postal',
    txt_global_std_automessage_level_1__1: "Yeah! You have got a driving score on {score}. That's how it's done!",
    txt_global_std_astronautssuperlonggroupnamegroup23: 'Astronautes, Nom de groupe super long, Groupe 23',
    txt_global_std_spectocreateaccount: 'User name must be an e-mail address and your password at least 5 characters.',
    txt_global_std_welcomeletsstart: 'Bienvenue! Commençons!',
    txt_global_std_car2: 'Auto',
    txt_global_std_Adjust_Text10: 'Hilsen\r\nSidekick-gjengen Tryg Forsikring',
    txt_global_std_tripsperperiod: 'Voyages par période',
    txt_global_std_p2pdateandtime: 'Date et heure',
    txt_global_std_p2pwehavesentanewtemporarypasswordtoyouremailaddress: 'Nous avons envoyé un nouveau mot de passe temporaire à votre adresse e-mail.',
    txt_global_std_map2: 'Carte',
    txt_global_std_enteravalidpolicynumber: 'Entrez un numéro de police valide.',
    txt_global_std_searchteams: 'Search teams',
    txt_global_std_insurancenumber: 'Insurance number',
    txt_global_std_sendnow: 'Envoyer maintenant',
    txt_global_std_qrcode: 'QR Code',
    txt_global_std_wrongemailorpassword: 'Mauvais email ou mot de passe',
    txt_global_std_randomperson: ' Personne au hasard',
    txt_global_sidekick_invitesendcodetolabel: 'Send invitation code to',
    txt_global_std_3gotobluetoothsettinginyourmobilemakesureyourmobilesbluetoothisturnedon: '3. Accédez aux paramètres Bluetooth de votre mobile, assurez-vous que le Bluetooth de votre mobile est activé.',
    txt_global_std_step5: 'Marcher',
    txt_global_std_currentinsurancecompany: 'Compagnie d&#39;assurance actuelle',
    txt_global_std_mysettings: ' Mes paramètres',
    txt_global_std_areyousureyouwanttoremoveuserfromthegroup: "Voulez-vous vraiment supprimer l'utilisateur du groupe ?",
    txt_global_std_youhaventdriventhismonth: 'Vous n&#39;avez pas conduit ce mois-ci',
    txt_global_std_checkyourinbox: 'Vérifiez votre boîte de réception',
    txt_global_std_communication_upsell_bulletpoint3: 'Communiquer des messages contextuels au bon moment',
    txt_global_std_reportfilled: 'Rapport rempli',
    txt_global_std_Alert_WereYouDrivingBody: 'N&#39;oubliez pas de démarrer et d&#39;arrêter vos trajets dans l&#39;application pour obtenir votre score de conduite.',
    txt_global_std_invitemoreusers: "Inviter plus d'utilisateurs",
    txt_global_std_searchmenu: ' Menu de recherche',
    txt_global_std_header: 'Entête',
    txt_global_std_p2pconnecttocarcomingsoon: 'Connectez-vous à la voiture - Bientôt disponible',
    txt_global_std_actual: 'Actuel',
    txt_global_std_or2: 'ou',
    txt_global_std_invitesubtitle: 'get rewarded!',
    txt_global_std_communication_upsell_pitch_bulletpoint2: 'Connectez-vous avec les utilisateurs sur leurs canaux préférés',
    txt_global_std_cimage20d: ' En planifiant votre conduite, vous gagnez du temps. Une vitesse maximale élevée n’assure qu’un gain de temps négligeable.',
    txt_global_std_NotCreatedAccount6_Text3: 'Sincerely,\r\n{appName}',
    txt_global_std_internetconnectionlost: "Connexion perdue. Cela peut entraîner des retards dans l'application et affecter vos données de conduite.",
    txt_global_std_turnonyourcar: 'Allumez votre voiture',
    txt_global_std_p2pemailnewbookings: 'Nouvelles réservations',
    txt_global_std_alertdrivenotstarted: ' Attention ! Vous êtes apparemment en train de circuler, mais aucun trajet n’a été lancé.',
    txt_global_std_manageyourteammembersandtheiraccountpermissionshere: 'Gérez les membres de votre équipe et leurs autorisations de compte ici.',
    'txt_global_std_Al-KuwariQuote': 'Citation à venir ...',
    txt_global_std_startbonusno: 'Bonus de démarrage',
    txt_global_std_PaymentTrouble2_Text5Enerfy: 'If we still can not make the payment within 14 days, your insurance will terminate, see attached document. After 14 days the TFF * will take over the case, and your car will no longer be insured with {appName}.',
    txt_global_std_saving: 'Sauvegarde',
    txt_global_std_nationalinsuranceno: 'Assurance nationale. Non',
    txt_global_std_ChicheritDescription: 'Monster Athlete Guerlain Chicherit steps up to the FIA World Rallycross Championship in 2018 with his own GC Kompetition team driving the new Prodrive-developed Renault Megane RS. A professional skier and four-time world champion in freeriding, Chicherit holds the record for becoming the first man in history to complete an unassisted backflip in a car. Chicherit made his World RX debut in the JRM Mini RX Countryman in 2015.',
    txt_global_CoreTemplate_Offline: ' Trajet hors ligne',
    txt_global_std_youhaveunsavedsettingsquitanyway: ' Vous avez des paramètres non sauvegardés. Souhaitez-vous malgré tout quitter la page ?',
    txt_global_std_cwelcome5: 'Honk and drive!',
    txt_global_std_p2ppushtitlehasdeniedthebooking: 'Réservation refusée',
    txt_global_std_MikkelsenMileage: "I always enjoy driving in my hometown Oslo when back in Norway. I'm traveling quite a lot, so too me it's always nice to come home. However, I also enjoy driving in the alps since the views there are amazing.",
    txt_global_std_inviteyourfriendsandfamilytojointhechallengeforabetterworld: 'Invitez vos amis et votre famille à rejoindre le défi pour un monde meilleur',
    txt_global_std_yourcarnowhasanactivatedinsurance: 'Your car now has an activated insurance',
    txt_global_std_weadaptthestartscreen: ' Nous adaptons l’écran d’accueil à vos critères.',
    txt_global_std_highlightedtheareainwhichyouhavethemostimprovementpotentialinthemapandchart: 'Nous avons mis en évidence la zone dans laquelle vous avez le plus grand potentiel d’amélioration sur la carte et le graphique ci-dessus, allez voir par vous-même!',
    txt_global_std_theusermustacceptaninvitationtotheteamfirst: "L'utilisateur doit d'abord accepter une invitation à rejoindre l'équipe.",
    txt_global_std_caniswitchtoagentifialreadyhaveanactiveinsurancepolicy: "Puis-je passer à {agent} si j'ai déjà une police d'assurance active ?",
    txt_global_std_createnewemail: 'Créer un nouvel e-mail',
    txt_global_std_p2pfaq15q: 'Comment ajouter les dépenses liées à la voiture?',
    txt_global_std_yourpasswordwaschanged: 'Votre mot de passe a été modifié!',
    txt_global_std_sparkunlockedkey: 'Vous avez vraiment bien roulé! Votre niveau moyen était meilleur que 4 pendant une semaine! 1000 points ont été ajoutés à votre portefeuille Kinto.',
    txt_global_std_joinaheattoenterthefiasdc: 'Rejoignez une manche et adhérez au Smart Driving Challenge!',
    txt_global_std_turnonbluetooth: 'Activez le Bluetooth',
    txt_global_std_yeary: ' An.',
    txt_global_std_HusseinAbdullaWhyjoin: 'Spirit of challenge',
    txt_global_std_rwmnotripalreadyregisterforthisweektitle: 'Le trajet en covoiturage était déjà enregistré pour cette semaine',
    txt_global_std_JyrkiainenWhyjoin: 'Together we can have fun and improve our efficient driving skills with FIA Smart Driving Challenge!',
    txt_global_std_cimage34h: ' IL EST INUTILE D’APPUYER SUR L’ACCÉLÉRATEUR DANS LES EMBOUTEILLAGES',
    txt_global_std_manageadvancedusersettingsandsupporthereusethequickaccessbuttonsbelowtoeasilynavigatebetweenusersagreementsandfollowupandhelpoutwithonboarding: "Gérez les paramètres utilisateur avancés et le support ici. Utilisez les boutons d'accès rapide ci-dessous pour naviguer facilement entre les utilisateurs, les accords et le suivi et aider à l'intégration.",
    txt_global_std_yesagentacceptsdriverswhohavebeenlicensedinothercountries: "Oui, {agent} accepte les conducteurs titulaires d'un permis dans d'autres pays.",
    txt_global_std_MSIG_InsuranceLetter45: 'UN EXCÈS DE 100,00 $ EST APPLICABLE POUR CHAQUE ET CHAQUE RÉCLAMATION DE PARE-BRISE',
    txt_global_std_passwordmustcontainatleast5characters: 'Password must contain at least 5 characters',
    txt_global_std_datespan: ' Période',
    txt_global_std_top3besttrips: 'Top 3 des meilleurs voyages',
    txt_global_std_welldone: ' Félicitations',
    txt_global_std_practicaltimesaving: ' Gain de temps concret',
    txt_global_edj_duration: 'Duration (hh:mm)',
    txt_global_std_thelowerthescorethemoresavingsyoucanearnyouwillseeyourpotentialsavingswitheachtrip: 'Plus le score est bas, plus vous pouvez économiser. Vous verrez vos économies potentielles à chaque voyage.',
    txt_global_std_payment: 'Paiement',
    txt_global_std_remembertostartandstop: "N'oubliez pas de démarrer et d'arrêter vos trajets dans l'application pour collecter des points et des récompenses.",
    txt_global_std_searchbartitleandpointstext: 'Recherche par titre ou points',
    txt_global_std_currentstandings: ' Classement général:',
    txt_global_std_kintofaq4a: 'Vous participez automatiquement lorsque vous vous inscrivez. Ne vous inquiétez pas, le classement n&#39;affichera que votre surnom - vous resterez donc toujours anonyme. Il y a un concours mensuel et annuel.',
    txt_global_std_p2ppushbodynewinvoicetopay: 'You received an invoice for cost split of {vehicletype}',
    txt_global_CoreTemplate_help: ' Aide',
    txt_global_std_drivingmenu: 'conduite',
    txt_global_std_weareimpressed: 'Wow {utilisateur}, nous sommes impressionnés !',
    txt_global_std_youhavenowdriven5tripsbetterthan3point3: 'Vous avez désormais effectué 5 trajets mieux que 3,3',
    txt_global_std_qcaniswitchtoagentifialreadyhaveanactiveinsurancepolicy: "Q : Puis-je passer à {agent} si j'ai déjà une police d'assurance active ?",
    txt_global_std_savingsistobeusedforsubscriptiondeduction: 'L&#39;épargne doit être utilisée pour la déduction d&#39;abonnement',
    txt_global_std_p2pfaq31a: '1. Sélectionnez « Données de conduite » dans le « Menu ».\n' +
      '2. Vous pouvez vérifier les données de votre historique de conduite. Vous pouvez voir les détails du retour en sélectionnant des données de conduite spécifiques.',
    txt_global_std_campaignname: 'Nom de la campagne',
    txt_global_std_tomasengedescriptionshort: 'Ancien pilote de course professionnel et premier pilote tchèque en F1.\n' +
      'Il aime le silence en conduisant.',
    txt_global_std_insurances: 'Insurances',
    txt_global_std_PreSaleDetails_Text3: 'This is only an overview of the information of your insurance terms and not the complete document. You have the right to have this information before you sign your insurance and it’s important that you read it. In case of injury, the complete insurance terms and your insurance letter constitute the agreement. The terms include more restrictions and duty of care then the ones written here. You can find the complete insurance terms at www.enerfy.se.',
    txt_global_std_invalidtime: 'Heure invalide.',
    txt_global_std_updatingmember: 'Updating member...',
    txt_global_std_kilometerpremiumtoohigh: 'Prime kilométrique trop élevée',
    txt_global_std_redeemablepoints: 'points échangeables',
    txt_global_std_nofriendsselected: ' Aucun ami sélectionné',
    txt_global_std_propernumber: 'Doit être un numéro de téléphone correct',
    txt_global_std_ConfirmFia_Hello: ' Vous êtes désormais inscrit(e) !',
    txt_global_std_readthecarsengineerrorsusingtheiobd2app: ' Lire les erreurs moteur de la voiture à l’aide de l’application iOBD2',
    txt_global_std_createchallenge: 'Créer un défi',
    txt_global_std_defaulticonimage: 'Défaut',
    txt_global_std_insuranceterms: 'Insurance terms',
    txt_global_std_p2pfaq4q: 'Comment supprimer un message?',
    txt_global_std_confirmandupdateaccount: 'Confirmer et mettre à jour le compte',
    txt_global_std_notesaved: ' Note sauvegardée',
    txt_global_std_Reminder_Hello: 'Hello',
    txt_global_std_youhaveinvited: 'Vous avez invité',
    txt_global_std_p2pmaintenance: 'Maintenance',
    txt_global_std_viewalldrivingdata: 'Afficher toutes les données de conduite',
    txt_global_std_audiofeedback: ' Feedback audio',
    txt_global_std_p2pfaq3a: "Bientôt, nous ajouterons une fonctionnalité qui vous aidera à trouver votre voiture. Pour l'instant, nous vous recommandons de demander à votre ami dans les messages de chat.",
    txt_global_std_encouragetostayfocused: 'Encourager à rester concentré pendant la conduite pour éviter les décisions risquées',
    txt_global_std_nicetohaveyouonboard: 'Ravis de vous accueillir à bord!',
    txt_global_std_warikan_onboarding14: "Veuillez noter qu'il peut être difficile de se connecter lorsqu'il y a beaucoup de monde.",
    txt_global_std_p2pseeyourbookingsorcheckavailabilityofyourfriendscar: 'Consultez vos réservations ou vérifiez la disponibilité de la voiture de votre ami.',
    txt_global_std_dateandtime: "Date et l'heure",
    txt_global_std_totalistheleaderboardforthechallenge: 'Total est le leader du challenge',
    txt_global_std_Footer_Tryg_2: 'Tel. 70 11 20 20 | www.tryg.dk/drive | www.tryg.dk |',
    txt_global_std_updatetext: 'Mettre à jour le texte',
    txt_global_std_total: ' Total',
    txt_global_std_SulayemFacts: 'Il a participé aux compétitions en Afrique, Europe, Moyen-Orient et au Championnat Mondial du Rallye',
    txt_global_std_congratulationsyouhaveverifiedyouremailyouwillberedirectedshortly: 'Félicitations, vous avez vérifié votre adresse e-mail. Vous serez redirigé sous peu',
    txt_global_std_PSolbergQuote: 'Citation à venir ...',
    txt_global_std_tryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagainnodot: "Essayez de réinitialiser la connexion de votre voiture à votre téléphone en le supprimant, puis en l'ajoutant à nouveau",
    txt_global_std_cimage25h: ' RÉGIME MOTEUR OPTIMAL',
    txt_global_std_p2pbookingcancelled: 'Réservation annulée',
    txt_global_std_drivingyieldtotalamount: 'Rendement de conduite (montant total)',
    'txt_global_std_drivingtimeabove{speed}': ' Durée du trajet au-dessus de {speed}',
    txt_global_std_FirstDrive_Text2: 'We have compiled the most common questions our customers have after they have started driving with {appName}.',
    txt_global_std_RecieptMail_Text11: 'Return shipping is paid for by you, the customer. Your Enerfy Plug-in must be returned in the original packaging. If you already paid for your insurance after you regret your purchase, you get the money back with a deduction for the time that the policy was in effect.',
    txt_global_std_Intro_Text5: 'Steg 2: Appen',
    txt_global_std_saveanduploadreport: 'Enregistrer et télécharger le rapport',
    txt_global_std_pleasechooseaninsurance: 'Veuillez choisir une assurance',
    txt_global_std_techsupport: ' Assistance technique',
    txt_global_std_p2piacceptthe: "J'accepte le",
    txt_global_std_safedriverweekly: 'Conducteur sûr chaque semaine',
    txt_global_std_pleasemakesurethatyouareconnectedtoyourcar: 'Veuillez vous assurer que vous êtes connecté à votre voiture et réessayer.',
    'txt_global_std_{count}points': '{count} points',
    txt_global_std_bestoptionifyoudonthavebt: "C'est la meilleure option si vous n'avez pas de connexion Bluetooth dans votre voiture.",
    txt_global_std_actiontime: 'Action time',
    txt_global_std_planaheadbeprepared: "Planifiez à l'avance, soyez prêt",
    txt_global_std_nobuttonlink: 'No button',
    txt_global_std_top: ' Meilleur',
    'txt_global_std_WinPhone GPS': 'WinPhone GPS',
    txt_global_std_howdoidrivewell: 'Comment bien conduire?',
    txt_global_std_drivejournal: 'Drivers log',
    txt_global_std_fellowdriver: 'camarade conducteur',
    txt_global_std_apopupintheappwillaskyoutoconnect: ' Une fenêtre pop-up apparaîtra dans l’application pour vous demander de vous connecter (cela peut prendre jusqu’à 2 minutes).',
    txt_global_std_myinformation: 'My information',
    txt_global_std_qhowdoiterminatemyinsurance: 'Q : Comment résilier mon assurance ?',
    txt_global_std_incorrectlyformattedcsvfilemakesuretofollowtheexactformatofthetemplate: 'Fichier .csv au format incorrect. Assurez-vous de suivre le format exact du modèle !',
    txt_global_std_TermConfirmSub_Text3: 'Any eventual credit or claim will be settled within 30 days.',
    txt_global_std_startaddress: ' Point de départ',
    txt_global_std_collectablecategory: 'À collectionner',
    txt_global_std_andallowitwevalueyourprivacy: 'et le permettre. Nous apprécions votre confidentialité et ne partagerons pas ces informations avec une entité tierce.',
    txt_global_std_PreSaleDetails_Text44: 'Fo¨rsa¨kringen ersa¨tter sto¨ld, tillgrepp samt uppsa°tlig skadego¨relse i samband med dessa ha¨ndelser. Bilen ska vara la°st. Fo¨r viss egendom finns det beloppsbegra¨nsningar t.ex. ljud-, bild och navigationsutrustning 30 000 kr, extraljus 10 000 kr. Fler begra¨nsningar finns i villkoret. Fo¨rsa¨kringen ga¨ller inte vid olovligt brukande.',
    txt_global_std_9digitactivationcode: "Saisissez le code d'activation à 9 chiffres envoyé sur votre téléphone et par e-mail.",
    txt_global_std_PropertyType: 'Typ av egendom',
    txt_global_std_linktopolicies: 'Lien vers les politiques',
    txt_global_std_couldnotchangeyourpassword: 'Impossible de changer votre mot de passe!',
    txt_global_std_december: ' Décembre',
    txt_global_std_SignupFia_Text9: '#FIASmartDrivingChallenge',
    txt_global_std_5000pointaverageachievementlocked2: 'Conduisez avec un {scoretype} of {score} ou mieux pendant une semaine pour débloquer ce succès et gagner 5000 points.',
    txt_global_std_inhereyoumakeyourinsuranceoptionsheart: 'In here you make your insurance options. Tap on the heart.',
    txt_global_std_nicedrive: 'Belle promenade!',
    txt_global_std_uniquecategory: 'Unique',
    txt_global_std_appName: ' {appName}',
    txt_global_std_howdoiearnaloyaltystatus: 'Comment gagner un statut de fidélité?',
    txt_global_std_p2pcontinueandpay: 'Continuer et payer',
    txt_global_std_planahead: "Planifier à l'avance",
    txt_global_std_p2ppushtitleaddedexpences: '{name} dépenses ajoutées',
    txt_global_std_sendthefollowingmessage: 'Send the following message ',
    txt_global_std_competeagainsteachotherwintogether: 'Affrontez-vous les uns les autres - gagnez ensemble',
    txt_global_std_forbothyourphoneandcar: 'pour votre téléphone et votre voiture.',
    txt_global_std_nodatatoshow: 'Aucune donnée à afficher!',
    txt_global_std_HansenDescription: 'Kevin Hansen, le plus jeune champion de la FIA Supercar et le FIA Rookie de l’année en 2016.',
    txt_global_std_insurancedrivers: 'Insurance drivers',
    txt_global_std_p2pyouborrowed: 'tu as emprunté',
    txt_global_std_automessage_level_15__2: "Hi {name}. Your driving style is considered risky. We recommend you improve your driving for your own and others' safety.",
    txt_global_std_manual: 'Manuel',
    txt_global_std_resetpasswordwrongpasswordformat: 'Format de mot de passe incorrect. Minimum 8 caractères, au moins une lettre majuscule, une lettre minuscule et un chiffre.',
    txt_global_std_fileaclaim: 'Déposer une plainte',
    txt_global_std_drivercppr: 'Rapport de profilage des modèles de collision du conducteur',
    txt_global_std_membernumber: 'Numéro de membre',
    txt_global_std_notnow: 'Not now',
    txt_global_std_amazingdriverunlockedkey: "Vous êtes incroyable {user} ! Vous avez obtenu un score supérieur à {score} aujourd'hui !",
    txt_global_std_openinghoursinsurance: ' 8h-20 h en semaine, 10h-18 h le week-end',
    txt_global_std_howdoicompeteinlexuschallenge: 'Comment participer au Lexus Challenge?',
    txt_global_std_p2perrorcodebookingmaynotbeearlierthannow: 'La réservation ne peut pas être plus tôt que maintenant.',
    txt_global_std_p2paddedcaraslendable: 'Voiture ajoutée comme prêtable',
    txt_global_std_activateitnow: 'Activez-le maintenant',
    txt_global_std_choosetypeofapp: "Choisissez le type d'application",
    txt_global_std_yesplease: 'Yes please!',
    txt_global_std_FirstDrive_Text12: 'Sincerely,\r\n{appName}',
    txt_global_std_challengeafriend3: 'Défiez un ami !',
    txt_global_std_f_lanzdescriptionshort: 'La meilleure pilote de course féminine en Afrique du Sud.\n' +
      'Elle aime danser.',
    txt_global_std_DamageTime: 'Tid för skadan',
    txt_global_std_searchonvehicleordriver: 'Search on vehicle or driver',
    txt_global_std_carbondioxide: 'Le dioxyde de carbone est un gaz incolore ayant une densité d&#39;environ 60% supérieure à celle de l&#39;air sec. Le dioxyde de carbone consiste en un atome de carbone lié de manière covalente à deux atomes d&#39;oxygène. Il se produit naturellement dans l&#39;atmosphère terrestre sous forme de gaz à l&#39;état de traces.',
    txt_global_std_p2pfaq19a: 'Si vous utilisez un téléphone Android, les données de conduite peuvent ne pas être enregistrées si la fonction "Optimiser la batterie" est activée.\n' +
      '1. Sélectionnez "Applications et notifications" dans "Paramètres" sur votre smartphone.\n' +
      "2. Sélectionnez les paramètres avancés d'application et de notification, puis sélectionnez Accès spécial aux applications.\n" +
      `3. Sélectionnez "Optimisation de l'électricité" dans l'accès spécial à l'application.\n` +
      '4. Sélectionnez « Toutes les applications » dans le menu déroulant, puis sélectionnez « Warikan KINTO ».\n' +
      '5. Sélectionnez "Ne pas optimiser" dans la boîte de dialogue.',
    txt_global_std_PreSaleDetails_Text29: 'Traffic Accident insurance provides extra compensation if you or anyone traveling in the car suffered a personal injury due to traffic. Compensation is provided, including, 3 600 SEK if you become hospitalized, medical disability up to 600 000 SEK. The insurance provides no compensation for medical disability that is less than 5%.',
    txt_global_std_add: 'Ajouter',
    txt_global_std_documentyourcar: 'Documentez votre voiture',
    txt_global_std_schema: 'Schéma',
    txt_global_std_visualeditor: 'Éditeur visuel',
    txt_global_std_estimatedsurcharge: 'Supplément estimé',
    txt_global_std_button: 'Button',
    txt_global_std_plugindevice: ' Branchez l’appareil',
    txt_global_std_therecouldbeotherreasonswhyyourcarsbluetoothdoesntshowupinthelist: "Il peut y avoir d'autres raisons pour lesquelles le Bluetooth de votre voiture n'apparaît pas dans la liste:",
    txt_global_std_silverusers: 'Utilisateurs Argent',
    txt_global_std_clickhere: ' Cliquez ici !',
    txt_global_std_p2pemailifyouremailclientdoesnotacceptbutton: 'Si votre client de messagerie n&#39;accepte pas le bouton, cliquez sur ce lien à la place.',
    txt_global_std_toconnectyourcar: ' Pour connecter votre voiture :',
    txt_global_std_nousersfoundtryreloadingthepage: 'Aucun utilisateur trouvé. Essayez de recharger la page.',
    txt_global_std_co2: ' CO2',
    txt_global_std_reportdeleteimages: 'Delete images',
    txt_global_std_AdjustmentMileageNew: 'Bekreftelse: Justering av kjørelengde',
    txt_global_std_drivesharing: 'Drive',
    txt_global_std_improvementpossibility: ' Améliorations possibles',
    txt_global_std_unpublish: 'Annuler la publication',
    txt_global_std_youwonthecompetion: 'You won the competion',
    txt_global_std_textmessage: 'Message texte',
    txt_global_std_machinedamage: 'Machine Damage',
    txt_global_std_cimage43d: ' ',
    txt_global_std_createnewteam: 'Create new team!',
    txt_global_std_FriendRequest_Text2: 'Click on the following link to accept your friend request',
    txt_global_std_set_up_manual_start_stop: 'Configuration : démarrage et arrêt manuels',
    txt_global_std_pwdtenandtwice: 'Votre mot de passe doit contenir au moins dix (10) caractères et doit être écrit deux fois!',
    txt_global_std_cimage30h: ' PLANIFIEZ CHAQUE ACCÉLÉRATION AFIN DE VOUS Y HABITUER',
    txt_global_std_typeinyourenerfypassword: 'Type in your {appName}-password:',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint3: "Obtenez des informations précises sur l'exposition au risque géographique en temps réel",
    txt_global_std_zipdrivingriskindex: 'Indice de risque Zip-Driving',
    txt_global_std_DamageReport_Hello: 'Hello',
    txt_global_std_p2pfaq18q: "Veuillez me dire comment connecter l'application à la voiture et enregistrer les données de conduite.",
    txt_global_std_maxfilesize: 'Taille maximale du fichier',
    txt_global_std_togetmorefriendssuggestionsyoucanloadyourfriendsfromfacebook: ' Pour que d’autres amis vous soient suggérés, vous pouvez charger la liste de vos amis depuis Facebook.',
    txt_global_std_youcanemailthiscertificatetogetdiscountswithdifferentvendors: 'Vous pouvez envoyer ce certificat par e-mail pour obtenir des réductions auprès de différents fournisseurs.',
    txt_global_std_PreSaleDetails_Text74: "You can also get advice in matters relating to insurance or the claims by contacting the Consumers Insurance Bureau, www.konsumenternas.se. You can also contact the Swedish Consumer Agency, www.konsumentverket.se, the municipal consumer guidance for advice and help, for contact information see your municipality's website.",
    txt_global_std_toprovideyouwithaccuratestatsanddrivingscoreappnameneedstoruninbackgroundmode: 'Pour vous fournir des statistiques précises et un score de conduite, {appname} doit fonctionner en arrière-plan.',
    txt_global_std_carwash: 'Lave-Auto',
    txt_global_std_ABC: 'ABC',
    txt_global_std_whoisthesmartestdriver: 'Qui est le conducteur le plus intelligent ?',
    txt_global_std_enerfyanalytics_upsell_bulletpoint1: 'Comprendre les facteurs qui déterminent le niveau de risque',
    txt_global_std_api: 'API',
    txt_global_std_NotCreatedAccount3_Text2Moderna: 'Experience the benefits of Moderna Smart Flex by activating the insurance and create an account in the Smart Flex-app in your smartphone.',
    txt_global_std_adgangtilmotionogfitnessdata: 'Adgang til motion og fitnessdata',
    txt_global_std_p2ptotaltopaytoph: 'Total à payer à {user}',
    txt_global_std_ConfirmFia_Text71: ' Si vous ne pouvez pas participer à l’évènement, veuillez annuler votre inscription en cliquant sur le lien suivant :',
    txt_global_std_electriccarorobd: 'Voiture électrique ou OBD?',
    txt_global_std_NotCreatedAccount3_Text6: 'Har du brug for hjælp?',
    'txt_global_std_Al-KuwariMileage': 'I like to drive long distances I find myself in long distances.',
    txt_global_std_oliversolbergShortDescription: 'Aucun',
    txt_global_std_p2pwaitingfor: 'Attendre',
    txt_global_std_iwanttoreceiveinformationandupdatesviaemail: 'Je souhaite recevoir des informations et des mises à jour par e-mail.',
    txt_global_std_senddocumentto: 'Send document to:',
    txt_global_std_fullname: 'Nom complet',
    txt_global_std_3rd: ' 3e',
    txt_global_std_p2ppushbodyhasdeniedthebooking: '{name} a refusé la réservation.',
    txt_global_std_StolenProperty: 'Stulen egendom',
    txt_global_std_OSolbergChamp: 'Coming soon...',
    txt_global_std_referfriendsgetrewards: 'Référez des amis. Obtenir des récompenses.',
    txt_global_std_p2ppushtitlesetpriceforbookingto: 'Le prix de la réservation est fixé',
    txt_global_std_pleasewait: "S'il vous plaît, attendez",
    txt_global_std_joinevent: ' Rejoindre',
    txt_global_std_points1: 'points',
    txt_global_std_pair: 'Paire',
    txt_global_std_connected: 'Connected',
    txt_global_std_demotext2: 'Voyez à quel point votre score va atteindre.',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriend: 'Rivaliser dans votre conduite quotidienne contre votre ami.',
    txt_global_all_thursday: ' Jeudi',
    txt_global_std_youneedtoselectatype2: 'Vous devez sélectionner un type',
    txt_global_std_gallonimp: ' Gallon Imp',
    txt_global_std_acceptedafriendrequest: 'You accepted a friend request',
    txt_global_std_HighUsageAlarm_Hello: 'Hello',
    txt_global_std_socialbutterflyunlockedkey: 'Vous avez parrainé 10 amis! Agréable! 300 points ont été ajoutés à votre portefeuille Kinto.',
    txt_global_std_findaservicecenter: 'Trouver un centre de service',
    txt_global_std_pickyourteamleader2: "Choisissez votre chef d'équipe",
    txt_global_std_401unauthorized: '401 - Non autorisé',
    txt_global_std_smssent: ' SMS envoyé !',
    txt_global_std_p2psplittingthecostforyourkintosubscribtionwithyourfriendsiseasy: 'Partager le coût de votre abonnement Kinto avec vos amis est facile !',
    txt_global_std_iveforgottenmypassword: "J'ai oublié mon mot de passe",
    txt_global_std_Intro_Text13: 'Når vervingen er bekreftet, vil pengene bli lagt i sparegrisen din og være en del av kjøreutbyttet. Dette krever at både du og din venn har en aktiv Sidekick-forsikring en måned etter at din venn har tegnet forsikring.',
    txt_global_std_members: 'Members',
    txt_global_std_OSolbergBesttip: 'Coming soon...',
    txt_global_std_connectedtoobd: ' Connecté(e) au système de diagnostic embarqué',
    txt_global_std_emailorusername: ' E-mail ou nom d’utilisateur',
    txt_global_std_documentsandpolicies: 'Documents et politiques',
    txt_global_std_sendreport2: 'Envoyer un rapport',
    txt_global_std_skillslevel7d: 'Niveau de compétence 7D',
    txt_global_std_drivegood: 'Drive good',
    txt_global_std_login2: 'Connexion',
    txt_global_std_MSIG_Onboarding8: '2. Suivez les étapes de l&#39;application.',
    'txt_global_std_Give Enerfy access to location services': 'Give {appName} access to location services',
    txt_global_std_privacysettingssaved: ' Paramètres de confidentialité sauvegardés',
    'txt_global_std_Al-KhelaifiDescription': 'Abdulla participe à la plupart des événements de sport automobile. Il a participé à plusieurs championnats dans différents types de courses comme la National Drag, Qatar Mile, National Sprint and QTTC',
    txt_global_std_temporarypassword: 'Mot de passe temporaire',
    txt_global_std_clickthelinkbelowtoconfirmemail: 'Cliquez sur le lien ci-dessous pour confirmer votre e-mail et terminer la création de votre compte Enerfy Global.',
    txt_global_std_lendmycar: 'Prête ma voiture',
    txt_global_std_ifyourcardoesnthavebluetooth: "If your car doesn't have bluetooth",
    txt_global_std_tripanalytics: 'Analyse de voyage',
    txt_global_std_getstartedfolks: 'Lancez-vous les gars !',
    txt_global_std_takecareofinjury: 'Take care of injury',
    txt_global_std_drivenkmperyear: 'Km parcourus par an',
    txt_global_std_sos: ' Urgence',
    txt_global_std_showmodal: 'Montrer modal',
    txt_global_std_summary: 'Summary',
    txt_global_std_hiheresmylocation: 'Bonjour, voici mon emplacement:',
    txt_global_std_pushnotdrivenforesometimebody2: 'Assurez-vous de vous connecter la prochaine fois que vous conduisez.',
    txt_global_std_teamname: 'Team name',
    txt_global_std_p2pgotocarsharing: 'Aller au covoiturage',
    txt_global_std_keeponpracticingandyoucangetabetterprice: 'Continuez à pratiquer et vous pouvez obtenir un meilleur prix sur votre assurance.',
    txt_global_std_distancekm2: 'Distance/km',
    txt_global_std_dontallow: 'Ne permettent pas',
    txt_global_std_insurancecontactdetails: 'Coordonnées de l&#39;assurance',
    txt_global_std_agreementnumber: "Numéro d'agrément",
    txt_global_std_warikan_onboarding7: '3. Entrez les informations suivantes pour créer un compte\n',
    txt_global_std_dennetur: 'Denne tur.',
    txt_global_std_successfullypublishedlanguage: 'Langue publiée avec succès',
    txt_global_std_quickoptions: 'Quick options',
    txt_global_std_registeryouraccount: 'Enregistrer votre compte',
    txt_global_std_countryroadroundaboutDESC: 'Le conducteur de la voiture bleue ajuste la vitesse avant d´entrer dans le rond-point. Le conducteur de la voiture rouge ne s´adapte pas assez vite pour entrer dans le rond-point en douceur.',
    txt_global_std_Moderna_Hello: 'Hej',
    txt_global_std_p2ppushtitletimetosplitcost: '費用分割の時期になりました。\n',
    txt_global_std_period: ' Période',
    txt_global_std_MSIG_Onboarding11: 'Conduisez prudemment,',
    txt_global_std_totaldistance: 'Total distance',
    txt_global_std_usersuccessfullyremoved: 'Utilisateur supprimé avec succès',
    txt_global_std_eventualinsurancebonus: 'Eventual insurance bonus\n',
    txt_global_std_activatebluetoothandconnect: '3. Activer Bluetooth et connecter le lecteur OBD',
    txt_global_std_locationservicetroubleshooting: 'Diagnostic du service de localisation',
    txt_global_std_vehicleidentification: 'Identification du véhicule',
    txt_global_std_dsadsadsadsa: 'papasasd',
    txt_global_std_introslide4header: 'Want to know mer?',
    txt_global_std_phonenumber: ' Numéro de téléphone',
    txt_global_std_ayessompoacceptsdriverswhoarelicensedinothercountries: "R : Oui, Sompo accepte les conducteurs titulaires d'un permis dans d'autres pays.",
    txt_global_std_somethingwentwrongwhenattemptingtoredeempointspleasetryagainlater: "Une erreur s'est produite lors de la tentative d'échange de points, veuillez réessayer plus tard",
    txt_global_std_1startyourcarsengine: '1. Démarrez le moteur de votre voiture.',
    txt_global_std_moreoptions: 'Plus d&#39;options',
    txt_global_std_FireStart: 'Hur startade branden',
    txt_global_std_minutem: ' m',
    txt_global_std_tothisemail: 'To this email',
    txt_global_std_learnmorehere: 'En savoir plus ici',
    txt_global_std_iaccepttheusertermslinkandconditionsprivacypolicylink: "J'accepte les {userTermsLink} et les conditions & {privacyPolicyLink}",
    txt_global_std_messagebody: 'Corps du message',
    txt_global_std_safedriver: 'Conducteur sûr',
    txt_global_std_optionnotavailableyet: ' Cette option sera prochainement disponible !',
    txt_global_std_insuredvehicles: 'Véhicules assurés',
    txt_global_std_VilliersInterests: 'Courses moto, courses tout-terrain, course de circuit',
    txt_global_std_Insurance_Information2Moderna: 'If you have questions about your invoice you are welcome to contact our customer service department. \r\n' +
      'E-mail: modernasmartflex@modernaforsakringar.se. Phone: 0200-265 265',
    txt_global_std_ParkingWhen: 'När parkerades fordonet',
    txt_global_std_reviewenerfy: ' Noter',
    txt_global_std_downloadqrcode: 'Télécharger le code QR',
    txt_global_std_nocountryselected: ' Aucun pays sélectionné',
    txt_global_std_MarklundMileage: 'I love driving the route to home. When you travel a lot, you really appreciate to just be home and the feeling of getting closer and closer is really satisfying. I live in the North of Sweden where the roads are sometimes all empty and where the andscape of lot of woods and wilderness makes me calm and happy. \n',
    txt_global_std_clickheretobuyfiasdc: 'Cliquez ici pour acheter FIA SDC',
    txt_global_std_yesyoucanswitchyourinsuranceanytimemostinsurancecompaniesdonothaveanearlyterminationpenaltyyouwillneedtoinquirewithyourcurrentinsurancecarrier: "Oui, vous pouvez changer d'assurance à tout moment. La plupart des compagnies d'assurance n'ont pas de pénalité de résiliation anticipée. Vous devrez vous renseigner auprès de votre assureur actuel.",
    txt_global_std_p2pskip: 'Passer',
    txt_global_std_primarybluetoothid: 'Identifiant Bluetooth principal',
    txt_global_std_PreSaleDetails_Text11: "The insurance is valid in the countries that are connected to the so-called Green Card system. On the Motor Association's website, www.tff.se, you can see which countries require a green card. A Green Card is available from our customer service.",
    txt_global_std_nofinishedchallenges: 'Aucun défi terminé',
    txt_global_std_selectcountrycode: 'Sélectionnez le code du pays',
    txt_global_std_unabletoloadmessages: 'Unable to load messages',
    txt_global_std_youbooked10timesinonemonthyouhaveearned2500points: 'Vous avez réservé 10 fois en un mois. Vous avez gagné 2500 points.',
    txt_global_std_typeyourcodefrominsuranceletter: 'Type your code from the insurance welcome letter',
    txt_global_std_wecantfindanydrives: 'Nous ne pouvons pas trouver de lecteurs!',
    txt_global_std_watchexplainervideo: 'Regarder la vidéo explicative',
    txt_global_std_thiskeydoesnothaveatranslationinthislanguage: "cette clé n'a pas de traduction dans cette langue",
    txt_global_std_documentandpolicyes: 'Documents et politiques',
    txt_global_std_loyaltypoints: 'Points de fidélité',
    txt_global_std_couldnotconnect: "N'a pas pu se connecter",
    txt_global_std_countmonths: ' {count} mois',
    txt_global_std_closestyou: 'plus près de toi',
    txt_global_std_viewcost: 'Voir le coût',
    txt_global_std_theinsurancehasbeenactivated: 'The insurance has been activated.',
    txt_global_std_createnewtextmessage: 'Créer un nouveau SMS',
    txt_global_std_itsemptyhere: "It's empty here!",
    txt_global_std_tomasengemusic: "Je n'écoute rien dans la voiture car j'aime le silence en conduisant - comme dans une voiture de course",
    txt_global_std_Reminder_Text1: 'Your premium payment for the period',
    txt_global_std_cimage5d: ' Utilisez les techniques en côte, restez stable sur l’accélérateur, perdez de la vitesse en montée, restez en roue libre en descente.',
    txt_global_std_smsclientonboardingpincode: 'Here comes your {pinlength} digits PIN code: {pincode}. PIN code is valid for {validminutes} minutes.',
    txt_global_std_remembertodrivesmart: ' N’oubliez pas d’adopter une conduite responsable',
    txt_global_std_countryroad4DESC: 'Le conducteur de la voiture bleue ralentit et s´approche doucement de la courbe. Le conducteur de la voiture rouge ne modifie pas sa vitesse en fonction de la courbure et est contraint de freiner.',
    txt_global_std_p2ppleasecontactsupport: "veuillez contacter l'assistance.",
    txt_global_std_MarklundFacts: 'Il a commencé la course automobile au niveau international en 2011 et a depuis participé dans les séries comme Porsche Carrera Cup de Scandinavie, FIA Mondial/Européen Rallye-cross et X-Games.',
    'txt_global_std_No, I want one': 'No, I want one',
    txt_global_std_to_make_a_change_in_your_insurance_policy: "Pour modifier votre police d'assurance, veuillez vous connecter à {url} ou contacter l'assistance à {email} ou {phone}.",
    txt_global_std_makessmartdrivingfant: 'Makes smart driving fantastic',
    txt_global_std_spooring: 'Spooring',
    txt_global_std_inviteapersonyouwantotshareinsurancewith: 'Invite a person you want to share your insurance with.',
    txt_global_std_yourusername: ' Votre nom d’utilisateur',
    txt_global_std_vehicleyear: 'Vehicle year',
    txt_global_std_nosir: 'No sir',
    txt_global_std_superdriver: 'super pilote!',
    txt_global_std__enterthetemporarypasswordsendtoyoubyemail: 'Saisissez le mot de passe temporaire qui vous a été envoyé par e-mail.',
    txt_global_std_kintofaq3a: 'Vous gagnez votre statut de fidélité en collectant des points! Vous obtenez des points des différentes réalisations disponibles. Des réalisations sont données à nos pilotes Kinto fidèles et sûrs! Apprenez-en plus sur ce qu&#39;il faut dans la section «Réalisations».',
    txt_global_std_usethistemplateasabadgeinyourappcustomizeitinthenextstep: "Utilisez ce modèle comme badge dans votre application. Personnalisez-le à l'étape suivante.",
    txt_global_std_TomczykBesttip: 'Stay focused on your driving style and drive “defensively”. ',
    txt_global_std_writeanewpassword: 'Entrez un nouveau mot de passe',
    txt_global_std_Accept_Text1: 'Takk! Du har vervet en venn til Sidekick! Når han eller hun har vært kunde i to måneder, får dere begge 250 kroner i Sidekick-sparegrisen. Hurra! ',
    txt_global_std_userhasnogroups: "L'utilisateur n'a pas de groupes",
    txt_global_std_nextqualificationforthegrandfinalindecemberstarts: 'Début de la prochaine qualification pour la Grande Finale de décembre',
    txt_global_Head2HeadTemplate_round: 'Round',
    txt_global_std_paddonQualification: 'Texte de qualification de test pour Paddon',
    txt_global_std_4th: ' 4e',
    txt_global_std_p2pchatwithyourfriends: 'Discutez avec vos amis',
    txt_global_std_clickthebuttonbelowtoresetyourenerfyglobalpassword: 'Cliquez sur le bouton ci-dessous pour réinitialiser votre mot de passe Enerfy Global.',
    txt_global_std_lostconnectionsmall: 'démarrer le moteur pour continuer',
    txt_global_std_enterdetails: 'Entrez les détails',
    txt_global_std_entergender: 'Entrez le sexe',
    txt_global_std_makesuretheobdreaderisreadytobepaired: "Assurez-vous que le Plug-in est prêt à l'utilisation.",
    txt_global_std_yourpolicyshouldcontaineight8numbers: 'Votre police doit contenir huit (8) chiffres.',
    txt_global_std_NotCreatedAccount3_Hello: 'Hello',
    txt_global_std_step2: 'Étape 2',
    txt_global_std_note: 'Note',
    txt_global_std_allimportantinformationaboutyourcarinsurancecollectedinoneplace: 'All important information about your car insurance collected in one place!',
    txt_global_std_FiaPassword_Text1: 'Bienvenue!',
    txt_global_std_v2score: 'Score\n',
    txt_global_std_youraveragescoreis123: 'Votre score moyen est de',
    txt_global_std_saveinformation: 'Enregistrer les informations',
    txt_global_std_conclusion: 'Conclusion',
    'txt_global_std_doyouthinkenerfyisfantastictoohelpus…': 'Do you think {appName} is fantastic too? Help us spread the word, thanks for taking your time to leave a feedback.',
    txt_global_std_p2pspecifyprice: 'Spécifiez le prix',
    txt_global_std_p2pyouhaveanexpensetoapprove: 'Vous avez une dépense à approuver',
    txt_global_std_redirectingyou: 'Vous rediriger ...',
    txt_global_std_developerpage: 'Développeur',
    txt_global_std_p2pspecifyegfuel: 'Précisez (ex. carburant)',
    txt_global_std_p2ptotaltopayto: 'Total à payer',
    txt_global_std_NotCreatedAccount2_Text3Moderna: 'Do you need help with creating an account? Please contact us at: modernasmartflex@modernaforsakringar.se or 0200-265 265.',
    txt_global_std_toapp: 'To app',
    txt_global_std_ayesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforcovercubeinsurance: "R : Oui, vous pouvez télécharger l'application et choisir l'option « essayer l'application ». Vous pourrez conduire avec l'application et voir combien vous pourriez économiser si vous souscriviez à l'assurance Covercube.",
    txt_global_std_automessage_level_2__2: 'Wow! Such driving. We like!',
    txt_global_std_sendinvitation: ' Envoyer l’invitation',
    txt_global_std_2makesureyourcarsbluetoothisonorthatyourbluetoothbeaconisconnected: '2. Assurez-vous que le Bluetooth de votre voiture est activé ou que votre balise Bluetooth est connectée.',
    txt_global_std_p2premovefriend: 'Supprimer un ami',
    txt_global_std_noaccountsignuphere: 'Pas de compte? Inscrivez-vous ici',
    txt_global_std_p2ppayments: 'Paiements',
    txt_global_std_internalservererror: 'Erreur Interne du Serveur',
    txt_global_std_thisyear: 'Cette année',
    txt_global_std_costforspeedover80: ' Coût de la vitesse au-dessus de {speed}',
    txt_global_std_timeframes: 'Délais',
    txt_global_std_p2ppleaseenteratemporarypassword2: 'Veuillez saisir un mot de passe temporaire.',
    txt_global_std_Gdpr_Text5: 'Si vous avez des questions, veuillez nous contacter à info@fiasmartdrivingchallenge.com ou au +46 8 555 932 00.',
    txt_global_std_editinformation: 'Edit information',
    txt_global_std_Intro_Text4: 'får du en kort forklaring av hva de ulike dekningene inneholder. Forsikringsvilkår finner du på «Min side».',
    txt_global_std_AcceptRecruitFriend: 'Takk! Du har vervet en venn till {appName} ',
    txt_global_std_TidemandQuote: 'J’aime toujours les bons challenges, particulièrement quand le challenge d’une importance majeure. Conduire de manière intelligente et responsable c’est quelque chose qui nous concerne tous et en améliorant notre façon de conduire respectueuse de l’environnement lors de nos déplacements au quotidien, nous investissons à notre future. Je suis partant !',
    txt_global_std_locateyourobdsocket: '1. Localisez votre prise OBD',
    txt_global_std_noconnectiontomapserver: ' Connexion au serveur cartographique impossible',
    txt_global_std_hiwelcometoappname: 'Salut! Bienvenue sur {appName} !',
    txt_global_std_unitskmmi: ' Unités km/mi',
    txt_global_std_warikan_onboarding3_2: 'なお、仮パスワードの有効期限は72時間となっております。\n' +
      '後 後 わりかん わりかん を 再 再 手続き 受け取っ 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き\n' +
      '本 送信 専用 専用 ます も ます ます ます ます も は お問い合わせ は は ます へ ます ます も ます',
    txt_global_std_pushnotification: 'Notification push',
    txt_global_std_willigetnotifiedififorgottostart: 'Will I get notified if I forget to start the Enerfy app?',
    txt_global_std_backtolist: 'Retour à la liste',
    txt_global_std_startdrivingtogetascore: 'Commencez à conduire pour obtenir un score!',
    txt_global_std_enerfyanalytics2: 'Analyse énergétique',
    txt_global_std_totaltripdistance: 'Total trips distance',
    txt_global_std_p2ppushbodyexpensesthismonthquestion: 'Make sure to add your expenses for {monthname}.',
    txt_global_std_NotCreatedAccount5_Text5: 'Sincerely,\r\n{appName}',
    txt_global_std_yourenowdrivingforteamName: "You're now driving for {teamName}!",
    txt_global_std_totalthismonth: 'Total ce mois',
    txt_global_std_youcannotsetafuturedate: 'Vous ne pouvez pas définir une date future',
    txt_global_std_myprofile: ' Mon profil',
    txt_global_std_achievementsavedmessage: 'Les réalisations ont été enregistrées !',
    txt_global_std_export2: 'Exportation',
    txt_global_std_timelocationaretheonlymandatoryfields: "L'heure et le lieu sont les seuls champs obligatoires.",
    txt_global_std_HighUsageAlarm: 'High monthly cost',
    txt_global_std_daydreamingormindwanderingnew: 'Rêverie ou vagabondage mental',
    txt_global_std_stardriver: 'Pilote vedette',
    txt_global_std_p2phide: 'Cacher',
    txt_global_std_riskydecisionwithintripsbetweendays: 'Décision risquée dans les voyages et entre les jours',
    txt_global_std_Insurance_RoadTax: 'The sum includes statutory\r\ntraffic tax',
    txt_global_std_connecttoyourcar: 'Connectez-vous à votre voiture',
    'txt_global_std_Al-KuwariFacts': 'Nasser a emporté 2 titres au MERC 2  en deux années successives, 2017 et 2018, et deuxième au classement de la FIA Cross-country en 2016 en tant que copilote. Il a emporté pas moins que neuf fois le championnat de Qatar et quatre fois le rallye de Kuwait, toujours en tant que copilote.',
    txt_global_std_TerminationMail_Text1: "Your car insurance has expired as of today's date due to lack of payment.",
    txt_global_std_averagescoreofparticipants: 'Note moyenne des participants',
    txt_global_std_miscinformation: 'Misc information',
    txt_global_std_createaccountlater: 'Créer un compte plus tard',
    txt_global_std_p2pamount: 'Montant',
    txt_global_std_KleinschmidtInterests: 'Sports, technologie informatique, voler',
    txt_global_std_drivendistanceperperiod: 'Distance parcourue par période',
    txt_global_all_tuesday: ' Mardi',
    txt_global_std_p2pleavethisgroupchatquestion: 'Quitter ce chat de groupe?',
    txt_global_std_90days: '90 jours',
    txt_global_std_Adjust_Text9: 'Hvis du sjekker at beløpsgrensen i nettbanken er tilfredsstillende i forhold til ny pris, så skal vi fikse resten.',
    txt_global_std_co2saver: 'Économiseur de CO₂',
    txt_global_std_25km: '25 Km',
    txt_global_std_RecieptMail_And: 'and',
    txt_global_std_tipcityparking2DESC: 'Le conducteur de la voiture bleue alerte les voitures environnantes à l`aide dun indicateur et ralentit. Le conducteur de la voiture rouge n`utilise pas l`indicateur avant le stationnement en parallèle.',
    txt_global_std_inviteafriendyouallwillgetareward: 'invitez un ami, vous recevrez tous une récompense',
    txt_global_std_shortcuts: ' Raccourcis',
    txt_global_std_toprovideyouwithadrivingscoreandstatsaboutyourdrivingappnameneedsaccesstolocationservices: 'Pour vous fournir un score de conduite et des statistiques sur votre conduite, {appname} doit accéder aux services de localisation.',
    txt_global_std_Reminder_Text3: 'If you have already paid your premium, you may disregard this message. All registered vehicles must, in accordance with Swedish law carry third party insurance. If you do not pay your premium within 14 days, we are required by law to notify the registration office that the insurance has expired. Then the Motor Insurers Association (TFF) will charge an additional high fee for every day that your car is not insured. The fee TFF * charges is much higher than traffic insurance premium we charge.',
    txt_global_std_poor: ' Médiocre',
    txt_global_std_after6monthsyouwillgetarenewalofferbasedonyourdrivinguntilthenyoucankeepimprovingyourdrivingfollowyourprogressandseeyourestimatedfuturesavingshere: 'Après 6 mois, vous recevrez une offre de renouvellement en fonction de votre conduite. Jusque-là, vous pouvez continuer à améliorer votre conduite. Suivez vos progrès et voyez vos économies futures estimées ici.',
    txt_global_std_thecruisebeliever: 'Le croyant de la croisière',
    txt_global_std_cimage6d: ' Évitez de dépasser d’autres véhicules',
    txt_global_std_KleinschmidtShortDescription: 'Champion du Dakar 2001',
    txt_global_std_senddocumentto2: 'Envoyer le document à:',
    'txt_global_std_ifyouwantenerfytostartyourdrivesautomaticallythenpressyes..': 'If you want {appName} to start your drives automatically then press YES else close with (X). Please be aware that {appName} will be a bit active in the background and some battery will be used for this. If you know that you will not drive for a longer time then please close {appName} in order to save battery.',
    txt_global_std_PreSaleDetails_Text17: "The insurance does not cover damage that occurs during competitions or training for competitions or the speed of driving under racing scenarios (such as 'street racing' or rallies). Or injury that occurs when the vehicle is used on racing tracks or similar, regardless of whether the area is fenced or not.",
    txt_global_std_saveunits: ' Sauvegarder les unités',
    txt_global_std_perusermonth: 'par utilisateur/mois',
    txt_global_std_nameheader: 'Titre de réalisation',
    txt_global_std_changeyouraddress: 'Changer votre adresse',
    txt_global_std_translationstoapprove: 'Traductions à approuver',
    txt_global_std_MSIG_Password3: 'is',
    txt_global_std_shock: 'Shock',
    txt_global_std_privateonlyformyorg: 'Privé (uniquement pour mon organisation)',
    txt_global_std_NotDrivingForATime_Text1: 'Vi ser att du inte har kört på ett tag med din bilförsäkring.',
    txt_global_std_settimeperiodforhowlongyourdatawillbestored: 'Définir la durée pendant laquelle vos données seront stockées',
    txt_global_std_404notfound: 'Ressource introuvable!',
    txt_global_std_ayesyoumustadviseyouragentthatyourvehicleisusedforaridesharingcompanyandtheywillapplytheridesharingendorsementtoyourpolicy: "R : Oui, vous devez informer votre agent que votre véhicule est utilisé pour une entreprise de covoiturage et il appliquera l'avenant de covoiturage à votre police.",
    txt_global_std_thelinkwillbeavailableforxdays: 'Le lien sera disponible pendant {x} jours.',
    txt_global_std_200ok: "200 - D'accord",
    txt_global_std_PaymentTrouble1_Text1: 'Problems with payment',
    txt_global_std_trygdrivehelpsyouwithmotivationandtipstodrivebettersaferandmoreecofriendly: "Sidekick vous aide avec la motivation et des conseils pour conduire mieux, plus sûr et plus respectueux de l'environnement.",
    txt_global_std_earnpointsbydrivingsafe: 'Gagnez des points en conduisant prudemment.',
    txt_global_std_privacy: ' Confidentialité',
    txt_global_std_riskscore: 'Note de risque',
    txt_global_std_Insurance_New: 'NEW SUBSCRIPTION',
    txt_global_std_sentyouamessage: 'Sent you a message',
    txt_global_std_addaphoto: ' Ajouter une photo',
    txt_global_std_p2ppushbodysetpriceforbookingto: '{name} set price for booking to: {currprice}',
    txt_global_std_co2tonnes: 'CO2 tonnes',
    txt_global_std_easyas123: 'Facile comme 1-2-3',
    txt_global_std_actualtranslation: 'Traduction réelle',
    txt_global_std_androidallowallthetime: "…mais pour obtenir les statistiques correctes de votre conduite, vous devez accéder aux paramètres de l'application - autorisations et choisir <b>Autoriser tout le temps</b>",
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint3: 'Informations détaillées pour les rapports sur la sécurité et la durabilité',
    txt_global_std_heattab: 'Course',
    txt_global_std_createnewgroup: 'Créer un nouveau groupe',
    txt_global_std_cimage14d: ' Profitez de la descente pour gagner de la vitesse',
    txt_global_std_xkm: '{km} Km',
    txt_global_std_pigsavedlocked: 'Pour chaque tranche de 50 couronnes, vous obtenez ce succès',
    txt_global_std_created: 'établi',
    txt_global_std_TaxInvoice14: 'CECI EST UN DOCUMENT GÉNÉRÉ PAR UN ORDINATEUR, AUCUNE SIGNATURE N&#39;EST REQUISE.',
    txt_global_std_requiredformatcolumnastext: 'Obligatoire, formater la colonne sous forme de texte',
    txt_global_std_startobdautomatically: 'Start OBD automatically',
    txt_global_std_therulesforthecompanychallengeareasfollowingbrtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsbrdriveminimum1kmduringaheatinordertoqualifyforthechallengebrthesamegeographicalroutewillbescoredamaximumof10timesinoneday: 'Les règles du challenge entreprise sont les suivantes :\n' +
      '<br>• Le score individuel du conducteur est calculé sur une moyenne basée sur les 10 % des meilleurs trajets.\n' +
      "<br>• Roulez minimum 1 km lors d'une manche afin de vous qualifier pour le challenge.\n" +
      '<br>• Un même parcours géographique sera noté 10 fois maximum en une journée.',
    txt_global_std_drivingtogetherismorefun: 'Conduire ensemble est plus motivant !',
    txt_global_std_currentpassword: ' Mot de passe actuel',
    txt_global_std_unavailable: 'Indisponible',
    'txt_global_std_emailalreadyusedpleasetrywithanotheremail.': "Adresse e-Mail déjà utilisée. S'il vous plaît essayez avec un autre email.",
    txt_global_std_loadingpleasewait: 'Chargement, veuillez patienter',
    txt_global_std_availablefromlevel: 'Disponible à partir du niveau',
    txt_global_std_p2ptobesettled: 'À régler:',
    txt_global_std_makeyourselection: ' Veuillez effectuer votre choix parmi les options ci-dessous :',
    txt_global_std_focusfeedbacktips: "<b> Gardez les yeux sur la route </b> et concentrez-vous entièrement sur la conduite. Parfois, il est facile d'être distrait par l'environnement, les téléphones, les personnes dans la voiture, etc. <br> <br>\n" +
      '<b> Conduire fatigué ou stressé </b> peut affecter la concentration et vous exposer à un risque plus élevé. <br> <br>\n' +
      '<b> Nous nous soucions de vous. </b>',
    txt_global_std_SulayemWhyjoin: 'As a 14 times FIA motorsport champion, I am passionate about driving and motorsports. intrigued by the advances in technology in the motoring industry that we are currently experiencing. We need to embrace these technologies and be a part of the solution to positively impact our environment.',
    txt_global_std_great: ' Génial',
    txt_global_std_TidemandBesttip: "To make smart driving as easy as possible - start by never stressing behind the wheel and always keep an eye on what's going on around you and adapt the speed in good time. ",
    txt_global_std_enterpasswordtodeleteuseraccount: 'Entrez le mot de passe pour supprimer le compte utilisateur',
    txt_global_std_NotCreatedAccount2_Text4: 'Sincerely,\r\n{appName}',
    txt_global_std_p2pfromthismonth: 'A partir de ce mois',
    txt_global_std_dolinkonthedamage: 'Faire {link} sur les dégâts',
    txt_global_std_rwmnoeligibletripstext: "Enregistrez votre trajet en covoiturage dans un délai d'un jour après la fin du trajet.",
    txt_global_std_yourchangesweresaved: 'Modifications sauvegardées',
    txt_global_std_pickyourteamleader: 'Choisissez votre team leader',
    txt_global_std_mar: ' Mar.',
    'txt_global_std_worktime0km/h': 'Work time 0km/h',
    txt_global_std_averagemonthsmonths: '{months} mois en moyenne',
    txt_global_std_doesagentacceptdriversthatarenotlicensedincountry: "{agent} accepte-t-il les conducteurs qui ne sont pas titulaires d'un permis en {pays} ?",
    txt_global_std_Signature_Text2: 'Fredrik Lihammer',
    txt_global_std_majorDescription: 'Anders test',
    txt_global_std_RecieptMail_Text4: '7 days starting guarantee',
    txt_global_std_p2pnamesentalendrequesttoname2: '{name} a envoyé une demande de prêt à {name2}',
    txt_global_std_subject: 'Matière',
    txt_global_std_rank: ' Rang',
    txt_global_std_OgierWhyjoin: 'Coming soon...',
    txt_global_std_teamleaderboard: 'Classement par équipes',
    txt_global_std_hereyoucanseehowmuchyouwouldhavegottenbackifyouhadhadatrygdrivecarinsurancethebonusiscalculatedbasedontheannualinsurancepriceyourkilometersdrivenperyearandthecartypebasedonthenumberplateyouhaveprovidedifyouhavenotstatedacurrentinsurancepricenumberofkilometersperyearandanumberplatethebonusiscalculatedonthebasisofanannualinsurancepriceofdkk5000andanannualmileageof20000km: "Ici vous pouvez voir combien vous auriez récupéré si vous aviez eu une assurance voiture Tryg Drive. Le bonus est calculé sur la base du prix annuel de l'assurance, vos kilomètres parcourus par. année et le type de voiture en fonction de la plaque d'immatriculation que vous avez fournie. Si vous n'avez pas indiqué de prix d'assurance en vigueur, le nombre de kilomètres par. année et une plaque d'immatriculation, le bonus est calculé sur la base d'un prix d'assurance annuel de 5 000 DKK. et un kilométrage annuel de 20 000 km.\n",
    txt_global_std_sendchallenge: 'Envoyer le défi',
    txt_global_std_stats2: 'Statistiques',
    txt_global_std_p2pnomessages: 'Pas de messages',
    txt_global_std_securetheaccidentsite: "Sécuriser le lieu de l'accident",
    txt_global_std_cashback: 'Cashback',
    txt_global_std_youmusthaveinsuranceyourself: 'You must have an insurance yourself.',
    txt_global_std_Adjust_Text3: 'km til',
    txt_global_std_selectwhatyouwanttouse: ' Sélectionnez ce que vous souhaitez utiliser',
    txt_global_std_PreSaleDetails_Text27: 'The insurance covers windshields smashed or ruptured by ex. stones. Insurance does not cover glass, including, ceiling or spotlights. Deductible 35% lowest amount 1 000 SEK. No deductible is paid during repairs.',
    txt_global_std_p2pwaitingforacceptanceandprice: "En attente d'acceptation et de prix.",
    txt_global_std_MikkelsenBesttip: 'I always make sure to have plenty of space to the cars in front of me. That always saves you from many situations and allows you to drive more comfortably, smartly and efficiently.',
    txt_global_std_Gdpr_Text6: 'Cordialement, Equipe FIA ​​Smart',
    txt_global_std_badgetitleplaceholder: "Titre de l'insigne",
    txt_global_std_getsexpenseandextradataofgiventrips: 'Obtient les dépenses et les données supplémentaires des trajets donnés',
    txt_global_std_invitehowitworkstext: 'When your friend signs up with your purchase code, you and your friend will receive {sum} in your piggy bank (apply if you have been at {company} for at least one month). The amount is transferred to the piggy bank when the acquisition code has been registered. You have a complete overview of your listings on the savings page.',
    txt_global_std_garminconnected: 'Garmin connecté',
    txt_global_std_fiasdcisbettertogether: 'FIA SDC est mieux ensemble',
    txt_global_std_chooseateamleaderthatyouwanttocompeteforwerecommendoneoftheseteamleadersbasedonyourlocationandgoal: "Choisissez un chef d'équipe pour lequel vous voulez concourir. Nous vous recommandons de choisir l'un de ces chefs d'équipe en fonction de votre emplacement et de vos objectifs.",
    txt_global_std_termsconditions: 'Termes et conditions',
    txt_global_std_tipcity2DESC: 'Le conducteur de la voiture bleue utilise un ralentissement naturel pour traverser le passage à niveau en douceur. Le conducteur de la voiture rouge accélère et freine trop fort avant le passage à niveau.',
    txt_global_std_lighttheme: 'Thème lumière',
    txt_global_std_PreSaleDetails_Text60: 'The insurance is paid monthly. You may at any time terminate it.',
    txt_global_std_activationemail: "E-mail d'activation",
    txt_global_std_highcostperkilometer: ' Coût au kilomètre élevé',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimeincreasingspeedunnecessaryharshaccelerationisbadfortheenvironmentandforroadsafety: "Sur ce type de voyage, le champion écolo moyen passe plus de temps à augmenter la vitesse. Les fortes accélérations inutiles sont mauvaises pour l'environnement et pour la sécurité routière.",
    txt_global_std_savings: 'Des économies',
    txt_global_std_ActivationLetter_No3: 'Du har valgt årlig kjørelengde',
    txt_global_std_opensettingsandmakesureyourbluetoothison: 'Ouvrez les paramètres et assurez-vous que votre Bluetooth est activé.',
    txt_global_std_NotCreatedAccount2_Text3: 'Do you need help with creating an account? Please contact us at: info@enerfy.se',
    txt_global_std_InsuranceChange_Text4: 'Hälsningar,\r\n{appName}',
    txt_global_std_reloadpage: 'Reload page',
    txt_global_std_navigatebetweenmodules: 'Naviguer entre les modules',
    txt_global_std_fiasdcnewsletter: 'FIA SDC NOUVELLES',
    txt_global_std_emailclaims: 'Réclamations par courrier électronique',
    txt_global_std_InsuranceMail_Text26: 'If you experience damages from theft during the same insurance period, a higher deductible will apply. See the complete insurance term. ',
    txt_global_std_connectingto: 'Connexion à',
    txt_global_std_mypoints: 'Mes points',
    txt_global_std_pressthedrivebuttontogetstarted: 'Appuyez sur le bouton de conduite pour commencer.',
    txt_global_std_makeaclaim: 'Faire une demande',
    txt_global_std_carsharestats: 'Statistiques de partage de voiture',
    txt_global_std_BeforeBill_Text8: 'eller kontakta kundservice: info@enerfy.se',
    'txt_global_std_Could not access location services. Activate in your phone settings for additional Enerfy functionality.': 'Could not access location services. Activate in your phone settings for additional {appName} functionality.',
    txt_global_std_failsenddetails: 'Échec de l&#39;envoi des détails',
    txt_global_std_getshareablelink: 'Obtenir un lien partageable',
    txt_global_std_imagerecognitioncompleted: "Reconnaissance d'image terminée",
    txt_global_std_startdrive2: 'Start drive',
    txt_global_std_cimage7d: ' Accélérez rapidement pour atteindre la vitesse de croisière lorsque la circulation le permet.',
    txt_global_std_lookhereyouhavesomfriendsfromfacebookinenerfy: 'Look here, you have some friends from Facebook in {appName}',
    txt_global_std_InsuranceChange_Text1: 'Det har blivit en omfattningsförändring av din bilförsäkring hos oss genom att din nuvarande ',
    txt_global_std_unknownracedriverShortDescription: 'Aucun',
    'txt_global_std_Al-KhelaifiMusic': 'Nothing specific. Calm music on the radio. Nothing distracting.',
    txt_global_std_p2ppushbodyhasacceptedthebooking: '{name} a accepté la réservation.',
    txt_global_std_youdonothavepicturesenabledinsettings: "You don't have pictures enabled in your settings!",
    txt_global_std_NotDrivingForATime_Hello: 'Hello',
    txt_global_std_uploadpicture: 'Upload Picture',
    txt_global_std_wanttocreateanaccount: 'Want to create an account?',
    txt_global_std_teamposition: 'Position d&#39;équipe:',
    txt_global_std_sendallpersonaldatatoyouremail: 'Envoyer toutes les données personnelles à votre email?',
    txt_global_std_belowyoucanseemoredetailsaboutthistrip: 'Ci-dessous, vous pouvez voir plus de détails sur ce voyage.',
    txt_global_edj_distance: 'Distance (km)',
    txt_global_std_startedaheadtoheadwith: 'Started a Head to Head with',
    txt_global_std_ContactDetails: 'Kontaktuppgifter',
    txt_global_std_applications: ' Applications',
    txt_global_std_examplemessagepush: "Vous avez reçu un exemple de demande de John Doe. Ouvrez l'application pour répondre maintenant !",
    txt_global_std_topdriversnow: 'Meilleurs pilotes maintenant',
    txt_global_std_loginerror: 'Login error, please try again later.',
    txt_global_std_nousersfound: 'Aucun utilisateur trouvé',
    txt_global_std_yourhavebeeninvitedby: 'Vous avez été invité par :',
    txt_global_std_p2pgroupchatsettings: 'Paramètres de discussion de groupe',
    txt_global_std_p2pviewall: 'Voir tout',
    txt_global_std_MarklundQuote: 'Citation à venir ...',
    txt_global_std_PaymentTrouble1_Text5: 'A lack of funds in your bank account',
    txt_global_std_yourposition: 'Ta position:',
    txt_global_std_notethisinformationwillbevisibletoanyonewhodownloadstheapp: "Remarque : Ces informations seront visibles par toute personne téléchargeant l'application.",
    txt_global_std_numoftrips: ' {numoftrips} trajets',
    txt_global_std_NotCreatedAccount3_Text3: 'Type your personal verification code in the app:',
    txt_global_std_selectyourcarsbluetooth: 'Sélectionnez votre voiture bluetooth',
    txt_global_std_otherinformation: 'Les autres informations',
    txt_global_std_HighMileage_Text5: 'kjørelengde',
    txt_global_std_wanttojointhechallenge: 'Voulez-vous rejoindre la compétition?',
    txt_global_std_InsuranceMail_Text8: 'Fire',
    txt_global_std_popuppublished: 'Published',
    txt_global_std_whenyouarereadytodriveyoucanconnect: 'Lorsque vous êtes prêt à conduire, vous pouvez vous connecter à votre voiture et commencer votre premier trajet en cliquant ici.',
    txt_global_std_sendtoafriend: 'Send to a friend',
    txt_global_std_both2: 'Tous les deux',
    txt_global_std_MSIG_Onboarding14: 'Votre certificat d&#39;assurance est joint et protégé par un mot de passe avec votre NRIC ou votre FIN.',
    txt_global_std_ilendthecar: ' J’ai prêté la voiture',
    txt_global_std_applanguage: "Langue de l'application",
    txt_global_std_wheredoyouwanttostartlooking: 'Where do you want to start looking?',
    txt_global_std_searchonname: ' Chercher un nom',
    txt_global_std_invoice: 'Invoice',
    txt_global_std_TerminationConfirm_Text4: 'If in the future you would regret your choice of termination, you are welcome to contact us. We will be happy to help you aquire the insurance again.',
    txt_global_std_set: 'Ensemble',
    txt_global_std_content: ' Contenu',
    txt_global_std_goback: 'Retourner',
    txt_global_std_to: ' Vers',
    txt_global_std_Reminder_Text4: 'Contact us if you have questions or need help with the payment of your insurance: 08-555 93 200.',
    txt_global_std_illegalxrule: 'Mot illégal : {word}',
    txt_global_std_agentofferselectronicfundstransferfromyourcheckingaccountorrecurringcreditcardpaymentfromyourcreditdebitcardyoucansignupforthesepaymentplanswhenyoustartyourpolicyoranytimeduringyourpolicytermbyloggingintoweburl: '{agent} propose un transfert électronique de fonds depuis votre compte courant ou un paiement récurrent par carte de crédit depuis votre carte de crédit/débit. Vous pouvez vous inscrire à ces plans de paiement lorsque vous démarrez votre police ou à tout moment pendant la durée de votre police en vous connectant à {webUrl}.',
    txt_global_std_addmembers: 'Add members',
    txt_global_std_invitetext: 'Both you and your friend will get awarded with {sum} when your friend use your code at activation of a new {company} car insurance.',
    txt_global_std_resetviewpassword: 'Réinitialiser et afficher le mot de passe',
    txt_global_std_PreSaleDetails_Text10: 'Where is the insurance valid?',
    txt_global_std_aagentofferselectronicfundstransferfromyourcheckingaccountorrecurringcreditcardpaymentfromyourcreditdebitcardyoucansignupforthesepaymentplanswhenyoustartyourpolicyoranytimeduringyourpolicytermbyloggingintolink: 'R : {agent} propose un transfert électronique de fonds à partir de votre compte courant ou un paiement récurrent par carte de crédit à partir de votre carte de crédit/débit. Vous pouvez vous inscrire à ces plans de paiement lorsque vous démarrez votre police ou à tout moment pendant la durée de votre police en vous connectant à {link}.',
    txt_global_std_whathappensnow: 'Que se passe-t-il maintenant ?',
    txt_global_std_iflocationserviceisoffyourperformancegetworse: "Si le service de localisation est désactivé la performance de l'application s'affaiblie.",
    txt_global_std_unlocked: '(Débloqué)',
    txt_global_std_seconds: ' Secondes',
    txt_global_std_anemailhasbeensentwithyournewpassword: ' Un e-mail avec votre nouveau mot de passe vous a été envoyé.',
    txt_global_std_accidentfaq: 'FAQ sur les accidents',
    txt_global_std_Head2Head_Text1: 'You have been challenged in Head to Head!',
    txt_global_std_timeandlocationistheonlymandatoryfield: "L'heure et le lieu sont le seul champ obligatoire. Veuillez télécharger des photos de votre voiture dans les 24 heures.",
    txt_global_std_riskportfoliotracker_upsell: "Enerfy Risk Portfolio Tracker - votre clé vers l'excellence opérationnelle.",
    txt_global_std_vouchercode: 'Voucher code',
    txt_global_std_getstartedguide: 'Guide de démarrage',
    txt_global_std_OgierChamp: 'Won 6 times the WRC and 1 time the J-WRC',
    txt_global_std_heatsmall: 'Chaleur',
    txt_global_std_NewCar_Text6: '{appName}-Teamet',
    'txt_global_std_Connect your Enerfy reader to your car': 'Connect your {appName} reader to your car',
    txt_global_std_ReportOwnerPhone: 'Anmälarens telefonnummer',
    txt_global_std_theterminationdatecantbethecurrentdate: "The termination date can't be the current date!",
    txt_global_std_yourmonthwithtrygdrive: 'Votre mois avec TrygDrive!',
    txt_global_std_getspagedlistoftrips: 'Obtient la liste paginée des trajets',
    txt_global_std_p2phasreceivedyoureditrequest: 'a reçu votre demande de modification',
    txt_global_std_NewActivationCode_Text2: 'Hilsen, \r\nSidekick-gjengen i Tryg Forsikring',
    txt_global_std_startingdrive: ' Démarrage du trajet...',
    txt_global_std_RecieptMail_Text6: 'Comprehensive insurance',
    txt_global_std_addyourbrandcolors: 'Ajoutez les couleurs de votre marque',
    txt_global_std_TermConfirmSub_Text7: 'Finally, we need you to enter you cars mileage for all the information to match when the insurance expires. Click on the link down below to log in and enter the number.',
    txt_global_std_introductionwelcome: 'Bienvenue',
    txt_global_std_teamtype: 'Team type',
    txt_global_std_yourquoteformonthofmonth: 'Votre devis pour le mois de {month}',
    txt_global_std_ActivationLetter_Text9: 'If you have any questions, please feel free to contact our customer service via telephone at 08-555 93 200, or via email at info@enerfy.se',
    txt_global_std_parameters: 'Paramètres',
    txt_global_std_PaymentTrouble1_Text2: 'We were unable to process your payment of',
    txt_global_std_openlog: 'Ouvrir le journal',
    txt_global_std_DrivingScore_Text5: 'Hilsen, \r\n{appName}-gjengen i Tryg Forsikring',
    txt_global_std_kintofaq6q: 'Je ne peux pas se connecter?',
    txt_global_std_activeachievements: 'Réalisations actives',
    txt_global_std_dontyouhaveacode: ' Vous n’avez pas de code ?',
    txt_global_std_namemustacceptaninvitationtotheteamfirst: "{name} doit d'abord accepter une invitation dans l'équipe.",
    txt_global_std_seeyourdrive: 'See your drive',
    txt_global_std_allowaccesstobluetooth: "Autoriser l'accès à Bluetooth",
    txt_global_std_eco1: 'Eco',
    txt_global_std_p2phasspecifiedaprice: 'a spécifié un prix',
    txt_global_std_unabletosaveteam: 'Unable to save team',
    txt_global_std_share: ' Partager',
    txt_global_std_p2pfaq23a: '1. Sélectionnez "Warikan" dans "Menu".\n' +
      `2. Sélectionnez "Paramètres" et sélectionnez la méthode de modification. Vous pouvez choisir parmi « Ratio d'utilisation », « Durée d'utilisation », « Division égale » et « Kilométrage ».`,
    txt_global_std_TerminationConfirm_Text5: 'Thank you for your time with us!',
    txt_global_std_p2pfaq15a: "Cette application n'a pas de fonction de règlement des dépenses. Par conséquent, veuillez accepter et régler les frais avec la personne directement et conformément à la loi.",
    txt_global_std_youmayhavetogotobluetoothsettingforbothyourphoneandcar: 'Vous devrez peut-être accéder aux paramètres Bluetooth pour votre téléphone et votre voiture.',
    txt_global_std_ecofriendweeklylockedkey: 'Conduisez de manière écologique et économisez plus de {co2value} CO₂ en une semaine pour gagner ce badge.',
    txt_global_std_TerminationMail_Text4: 'When you start paying for liability insurance again, your new insurance begins on the date the payment comes to the insurance company.',
    txt_global_std_thisaccountdoesnothaveapaymentplanaddpaymentplantoaccesspaymentspage: "Ce compte n'a pas de plan de paiement. Ajoutez un plan de paiement pour accéder à la page des paiements.",
    txt_global_std_ShareCar: 'Nu kan du dela bil!',
    txt_global_std_getupandrunninginnotimewithourwhitelabelappsolution: "Soyez opérationnel en un rien de temps grâce à notre solution d'application en marque blanche.",
    txt_global_std_Value: 'Värde',
    txt_global_std_changepaymentpopuptext: 'We will deduct 1 kr from your account when you update your card details. The sum will be refunded. Any unpaid invoices will be paid shortly.',
    txt_global_std_PolicySchedule27: '3 500,00 SGD Les sections I et II sont imposées séparément aux conducteurs de moins de 22 ans et / ou ayant moins de 2 ans d&#39;expérience de conduite.',
    txt_global_std_yesiamsure: 'Oui je suis sûr!',
    txt_global_std_Insurance_Information3: 'Sincerely, {appName}',
    txt_global_std_Insurance_LevelNotRegistrated: 'Non registered trips',
    txt_global_std_youhavebeeninvitedby: 'Vous avez été invité par :',
    txt_global_std_p2pinvoiced: 'Facturé',
    txt_global_std_redeemfriendscode: 'Utiliser le code d&#39;amis',
    txt_global_std_advancedadmin_upsell_pitch_bulletpoint1: 'Information et soutien pour éclairer vos décisions',
    txt_global_std_drivetime: ' Temps de conduite',
    txt_global_std_showalldrives: 'Afficher tous les lecteurs',
    txt_global_std_drivesafeandpaylessforyourfiat500subscription: 'Roulez prudemment et payez moins cher votre abonnement Fiat 500',
    txt_global_std_kintosmsmailtext: 'Commencez à conduire en toute sécurité avec moi! Suivez le lien ci-dessous et commencez à conduire avec Kinto.',
    txt_global_std_photosofyourcarscurrentcondition: 'Il peut être bon de disposer de photos de l&#39;état actuel de votre voiture si quelque chose devait arriver à votre voiture.',
    txt_global_std_logsaved: ' Journal sauvegardé',
    txt_global_std_createanaccounttostartyour30dayfreetrialnocreditcardrequired: 'Créez un compte pour commencer votre essai gratuit de 30 jours. Pas de carte de crédit nécessaire.',
    txt_global_std_p2pfaq6q: 'Comment quitter une discussion de groupe?',
    txt_global_std_p2pfaq17a: "Vous devez être invité par un propriétaire de Kinto pour l'ajouter en tant qu'ami. Veuillez demander à votre ami de vous envoyer un lien d'invitation.",
    txt_global_std_ifyouareinneedofroadsideassistanceplease: "Si vous avez besoin d'une assistance routière, veuillez",
    txt_global_std_wouldntitbenicetobeabletosaythatasacompanyyoumakenoticabledifferenceinloweringtheimpactvehiclesanddrivinghaveontheworldstartyourownchallengeandmakethatimpacttoday: "Ne serait-il pas agréable de pouvoir dire qu'en tant qu'entreprise, vous faites une différence notable en réduisant l'impact des véhicules et de la conduite sur le monde ? Lancez votre propre défi et ayez cet impact - aujourd'hui !",
    txt_global_std_warikan_onboarding16: 'KINTO Co., Ltd. | 4-8-18 Meieki, Nakamura-ku, ville de Nagoya Bâtiment Nagoya Mitsui Bâtiment nord 14F',
    txt_global_std_MarklundBesttip: 'First of all, plan your driving and make sure to have enough time, stress is never a smart way of driving. Keep your eyes up front and be alert on what’s going on ahead of you, maybe someone brakes? Then maybe you have time to lift the throttle instead of instant and hard braking? Be patient and adopt your driving style and speed to circumstances such as traffic and weather conditions. I know it’s hard but it makes you a smarter driver.\n',
    txt_global_std_NewActivationCode: 'Din nye aktiveringskode',
    txt_global_std_p2pnocarsavailable: 'Aucune voiture disponible',
    txt_global_std_PreSaleDetails_Text2: 'Important information about the terms of your insurance coverage ',
    txt_global_std_selectyourcarinthelistsompo: '3. Sélectionnez votre voiture dans la liste :',
    txt_global_std_passwordchangefailed: 'Échec du changement de mot de passe',
    txt_global_std_ihaveanaccount: 'J’ai un compte',
    txt_global_std_driverlatestactivity: 'Driver latest activity',
    txt_global_std_notnowlater: 'Pas maintenant',
    txt_global_std_yourpasswordshouldcontainatleast5charactersandmustbeenteredcorrectlytwice: 'Votre mot de passe doit contenir au moins 5 caractères et être renseigné deux fois.',
    txt_global_std_rwmnodrivestext: 'Vous devez vous connecter à votre voiture et conduire avant de pouvoir utiliser cette fonction. Revenez pour enregistrer votre voyage après avoir partagé un trajet avec des amis.',
    txt_global_std_gooddriving2: 'Bonne conduite!',
    txt_global_std_p2ptoconfirmbooking: ' pour confirmer la réservation',
    txt_global_std_Signature_Text3: 'Ansvarig sälj och Service',
    txt_global_std_cwelcome10: 'God night',
    txt_global_std_createcommunicationthatreachesout: "Créer une communication qui s'étend",
    txt_global_std_signedinasusername: 'Connecté en tant que {userName}',
    txt_global_std_connectyourobdreader: 'Connectez votre lecteur OBD à votre voiture',
    txt_global_std_showmap: 'Show map',
    txt_global_std_documentanyscratchesordingsonyourcar: 'Documentez les éraflures ou les coups sur votre voiture.',
    txt_global_std_OgierBesttip: 'Coming soon...',
    txt_global_ReceiptTemplate_rate: 'Rate',
    txt_global_std_calmdriversoftengetbetterscore: ' Les conducteurs calmes obtiennent souvent un meilleur score',
    txt_global_std_thepasswordcontainsforbiddencharacters6062orspace: "Le mot de passe contient des caractères interdits : ' & / &#60; &#62; , . = ou espace",
    txt_global_std_owner2: 'Propriétaire',
    txt_global_std_theapikeynamecreatedcreationdatewillbedeletedimmediatelyandpermanentlyoncedeleteditcannolongerbeusertomakeapirequests: 'La clé API {name}, créée le {creationdate} sera supprimée immédiatement et définitivement. Une fois supprimé, il ne peut plus être utilisé pour faire des requêtes API.',
    txt_global_std_byconnectingtoyourcarwithyoursmartphone: 'By connecting to your car with your smartphone and the Enerfy app, your drive will be analyzed by our AI database, where your drive will be compared with more than 500 million driving profiles which will validate how safe and environmentally friendly you drive.',
    txt_global_std_publishapp: "Publier l'application",
    txt_global_std_emergencyroadsideassistance: "Assistance routière d'urgence",
    txt_global_std_avaragescoreofparticipants: 'Note moyenne des participants',
    txt_global_std_invite: 'Inviter',
    txt_global_std_PolicySchedule18: 'PDP et SLEC',
    txt_global_std_ensammenligningmeddennekreturogetgennemsnitafalleandrebilisterskrselsadfrd: 'En sammenligning med denne køretur og et gennemsnit af alle andre bilisters kørselsadfærd.',
    txt_global_std_mine: ' À moi',
    txt_global_std_wehavesentanewverificationcodetophoneno: 'Nous avons envoyé un nouveau code de vérification à {phoneNo}.',
    txt_global_std_goodjob: 'Good job!',
    txt_global_std_youneedtoacceptallofthepermissionsinoderfortheapp: 'Vous devez accepter toutes les autorisations pour que l&#39;application fonctionne correctement',
    txt_global_std_earngreatrewards: '- Gagnez de superbes récompenses !',
    txt_global_std_currentstatsper: 'Statistiques actuelles par',
    txt_global_std_signupletter: ' Vous êtes sur la liste !',
    txt_global_std_joinedparticipants: 'Participants',
    txt_global_sidekick_invitesubtitle: 'get rewarded!',
    txt_global_std_all: 'Tout',
    txt_global_std_ttest96: "c'est un test",
    txt_global_std_earn100pointswhenyoupurchasecdw5timestoyourbooking: 'Gagnez 100 points lorsque vous achetez CDW 5 fois à votre réservation',
    txt_global_std_anticipate: 'Anticiper',
    txt_global_std_p2pyouhavebookingswithoutregistereddistance: 'Vous avez des réservations sans distance enregistrée.',
    txt_global_std_ifyourecruitafriendbothyouandyourfriendget250kronerinthepiggybank: 'Si vous recrutez un ami, vous et votre ami recevez 250 couronnes dans la tirelire.',
    txt_global_std_NotCreatedAccount1_Text4: 'Type in your personal verification code:',
    txt_global_std_PolicySchedule16: '2, 25, 57, 72, 89C, E28, E29, E33, E45, E48J, E6,',
    txt_global_std_p2pcancelbooking: 'Annuler la réservation',
    txt_global_std_upload123: 'Télécharger',
    txt_global_std_qhowdoisignupforagentinsurance: "Q : Comment souscrire à l'assurance {agent} ?",
    txt_global_std_country: ' Pays',
    txt_global_std_p2pdiscount: 'remise',
    txt_global_std_yourpassword: ' Votre mot de passe',
    txt_global_std_timeandplace2: 'Lieu et heure',
    txt_global_std_PaymentTrouble1_Text8: 'Click on the following link to update your payment information',
    txt_global_std_starburtslockedkey: 'Terminez un entraînement au niveau 4,4 ou mieux pour débloquer ce succès et gagnez 300 points sur votre portefeuille Kinto.',
    txt_global_std_MSIG_InsuranceLetter39: 'NIL pour les conducteurs autorisés',
    txt_global_std_machinedamage123: 'Dommages à la machine',
    txt_global_std_heresyourauthenticationcode: "Voici votre code d'authentification :",
    txt_global_std_editpermissionsfor: 'Modifier les autorisations pour',
    txt_global_std_youdrivebetterthantheaveragedriver: 'You drive better than the average driver! Keep driving the way you do!',
    txt_global_std_fromthetoprightappsiconyoucannavigatebetweendifferentmoduleswithintheworkspace: "À partir de l'icône des applications en haut à droite, vous pouvez naviguer entre les différents modules de l'espace de travail",
    txt_global_std_therulesforthecompanychallengeareasfollowingtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsdriveminimum1kmduringaheatinordertoqualifyforthechallengethesamegeographicalroutewillbescoredamaximumof10timesinoneday: 'Les règles du challenge entreprise sont les suivantes :\n' +
      '• Le score individuel du conducteur est calculé sur une moyenne basée sur les 10 % des meilleurs trajets.\n' +
      "• Roulez minimum 1 km lors d'une manche afin de vous qualifier pour le challenge.\n" +
      '• Un même parcours géographique sera noté 10 fois maximum dans une journée.',
    txt_global_std_somethingwhenwrongwithyourpasswordpleasetryanotherpassword: 'Quelque chose ne va pas avec votre mot de passe. Veuillez essayer un autre mot de passe.',
    txt_global_std_FirstDrive_Text1: 'How fun that you have getting started with {appName} and performed your first drive!',
    txt_global_std_maintainingintendedspeedisutmostimportantforfueleconomymindwanderingandlackoffocusoftencausesspeedvariationupanddowncausingunnecessaryaccelerationshenceincreasesfuelconsumption: "Le maintien de la vitesse prévue est extrêmement important pour l'économie de carburant. L'errance de l'esprit et le manque de concentration provoquent souvent des variations de vitesse de haut en bas, provoquant des accélérations inutiles, augmentant ainsi la consommation de carburant.",
    txt_global_std_singleaccident2: 'Accident unique',
    txt_global_std_reportandtrackyourresults: 'Rapportez et suivez vos résultats',
    txt_global_std_MarklundInterests: 'Motoneige, Nature/vie sauvage, Famille et amis',
    txt_global_std_whyterminateenerfyinsurance: 'Why terminate {appName} Insurance?',
    txt_global_std_GDPR: 'Demande de suppression des informations de compte',
    txt_global_std_Reminder: "Reminder: Your premium isn't paid",
    txt_global_std_AttachedFile: 'Attached files',
    txt_global_std_theactivationcodehastobe36characters: 'Le code d&#39;activation doit comporter 36 caractères.',
    'txt_global_std_Al-KhelaifiMileage': 'Umm Bab to Dukhan Highway (Route 39). Because of the elevation changes and the scenery.',
    txt_global_std_p2peditbooking: 'Modifier la réservation',
    txt_global_std_failedtogettranslations: "Échec de l'obtention des traductions",
    txt_global_std_cost2: 'Coût',
    txt_global_std_youwillbemailedyourpersonaldata: 'Vos données personnelles vous seront envoyées par mail',
    txt_global_std_hideadvancedsettings: 'Masquer les paramètres avancés',
    txt_global_std_publishtranslations: 'Publier des traductions',
    txt_global_std_kilometerkm: 'Kilomètre (km)',
    txt_global_std_smartdriverweeklyunlockedkey: 'Pour gagner ce badge, gardez un score supérieur à 3,5 pendant une semaine',
    txt_global_std_ttest95: "c'est un test",
    txt_global_std_HusseinAbdullaInterests: 'Voitures, Rallye, Course automobiles',
    txt_global_std_chataboutabookingorstartagroupchatwithyourinvitedfriends: "Discutez d'une réservation ou démarrez une discussion de groupe avec vos amis invités.",
    txt_global_std_unregistereddriving: 'Conduite non enregistrée',
    txt_global_std_ConfirmFia_Text5: ' Lieu',
    txt_global_std_february: ' Février',
    txt_global_std_OrderConfirmation: 'Orderconfirmation',
    txt_global_std_InsuranceApp_Text3: 'Sincerely,\r\n{appName}',
    txt_global_std_thereisnotworkshopstoshow: "Il n'y a pas d'ateliers à montrer.",
    txt_global_std_PolicySchedule31: 'Un excédent de 100,00 $ est applicable pour chaque réclamation de pare-brise, après quoi, le pare-brise sera automatiquement rétabli.',
    txt_global_std_noofdrivers: 'Nbre de chauffeurs',
    txt_global_std_jointheglobalcommunityreducingco2emission: 'Rejoignez la communauté mondiale réduisant les émissions de CO2.',
    txt_global_std_myteams: 'My teams',
    txt_global_std_accentcolor: "Couleur d'accentuation",
    txt_global_std_enteredodometervalue: 'Valeur de l&#39;odomètre entrée',
    txt_global_std_minutes: ' Minutes',
    txt_global_std_youralreadyboundeddevices: 'Already bounded devices:',
    txt_global_std_permissions: 'Autorisations',
    txt_global_std_TerminationConfirm_Text3: 'Any eventual credit or claim will be settled within 30 days.',
    txt_global_CoreTemplate_cost: ' Coût',
    txt_global_std_300pointTripAchievementlocked: 'Terminez un lecteur à {scoretype} {score} ou mieux pour débloquer ce succès et gagner 300 points.',
    txt_global_std_everythingworkedasexpected: 'Tout a fonctionné comme prévu.',
    txt_global_std_nolocationaddressavailable: 'No location address available.',
    txt_global_std_about: 'À propos',
    txt_global_std_yourfriends: ' Vos amis',
    txt_global_std_ontheleaderboardpageyoucamseechallengesoverview: 'Sur la page Classement, vous pouvez voir un aperçu des défis.',
    txt_global_std_thetotalscoremeasuringbothyourrisklevelandecodriving: 'Le score total mesurant à la fois votre niveau de risque et votre conduite écologique.',
    txt_global_std_schedulesend: "Programmer l'envoi",
    txt_global_std_typeofaccident: "Type d'accident",
    txt_global_std_upto6000kmmilage: "Jusqu'à 6000 km.",
    txt_global_std_p2ptogetaninvitationlinkfromakintoownerifyouarehavingtroublewiththelinkpleasecontactsupportandwewillhelpyou: "Pour emprunter une voiture Kinto, vous devez obtenir un lien d'invitation d'un propriétaire de Kinto. Si vous rencontrez des problèmes avec le lien, veuillez contacter le support et nous vous aiderons",
    txt_global_std_decideyourownview: ' Personnaliser votre affichage',
    txt_global_std_youmustwriteareason: 'Tu dois écrire une raison',
    txt_global_std_passwordisnotvalid: "Le mot de passe n'est pas valide.",
    txt_global_std_imageuploadfailed: "Impossible de télécharger l'image, veuillez réessayer",
    txt_global_std_achievementsdesc: "Engagez vos utilisateurs avec des réalisations dans votre application. Les réalisations sont des badges intégrés à l'application et peuvent être uniques ou à collectionner.",
    txt_global_std_notification: ' Notification',
    txt_global_std_p2pyouhaveselectedmultipledays: 'Vous avez sélectionné plusieurs jours',
    txt_global_std_finish: 'Finish',
    txt_global_std_silver: 'argent',
    txt_global_std_speedareawithbest: 'Zone de vitesse avec le meilleur potentiel d&#39;amélioration',
    txt_global_std_p2pinvitenewfriend: 'Inviter un nouvel ami',
    txt_global_std_declined: 'Diminué',
    txt_global_std_redeemedpoints: 'Points échangés',
    txt_global_std_usingtheapp: "Utilisation de l'application",
    txt_global_std_findworkshop: 'Trouver un atelier',
    txt_global_std_Fia_Receipt_Text7: 'Numéro de références de votre commande',
    txt_global_std_kintofaq2a: 'Un conducteur sûr est un conducteur averti. La conduite sécuritaire consiste à rester concentré à 100% sur votre conduite et sur votre environnement. Restez vigilant et tenez compte du rythme de la circulation et d&#39;autres circonstances, telles que la pluie, etc. AJUSTEZ TOUJOURS VOTRE VITESSE AUX CONDITIONS PRÉALABLES.',
    txt_global_std_HighUsageAlarm_Text1: "We see that you have accrued an expensive monthly cost during a short period of time by driving in high and expensive premium rates. That's why we wanted to send you some tips, based on your driving, that will help you drive at lower and cheaper premium rates.",
    txt_global_std_errorloggingintoyouraccountpleasetryagain: 'Erreur lors de la connexion à votre compte, veuillez réessayer !',
    txt_global_std_qidriveforaridesharingcompanywillyouinsuremyvehicle: 'Q : Je conduis pour une société de covoiturage, assurerez-vous mon véhicule ?',
    txt_global_std_yourscore: 'Mon score',
    txt_global_std_leavewithoutsaving: 'Quitter sans enregistrer',
    txt_global_std_PolicySchedule32: 'Signé pour et au nom de la société',
    txt_global_std_pushdriveendlevel4: 'Nu kører du risikabelt. Klik hende for at se, hvad du kan forbedre.',
    'txt_global_std_1) Plugged your OBD reader in to your car.': '1) Plugged your OBD reader in to your car.',
    txt_global_std_p2pallcars: 'Toutes les voitures',
    txt_global_std_gold: 'Or',
    txt_global_std_HighUsageAlarm_Text8: 'Hvis du har spørgsmål til dine forsikringer, er du velkommen til at ringe på 70 11 20 20 eller skrive til os på trygdrive@tryg.dk.',
    'txt_global_std_Compare with my': ' Comparer avec ma/mes (depends on the next word)',
    txt_global_std_notdrivingthiscar: 'Vous ne conduisez pas cette voiture?',
    txt_global_std_checkhowitwent: 'Vérifiez comment ça s&#39;est passé',
    txt_global_std_latestknownlocation: 'Latest known location',
    txt_global_std_5discountonrenewal: '5% de réduction sur le renouvellement',
    txt_global_std_fix: 'Réparer',
    'txt_global_std_Select the driving styles you want to compete in': 'Select the driving styles you want to compete in',
    txt_global_std_businesstrips: 'Business trips',
    txt_global_std_cantfindyourcargoto: 'Vous ne trouvez pas votre voiture? Aller à',
    txt_global_std_adduserstogroups: 'Ajouter des utilisateurs à des groupes',
    txt_global_std_wereyoudriving: 'Conduisais-tu ?',
    txt_global_std_viewinvoice: 'Voir la facture',
    txt_global_std_youhavedrivengreat: 'You have driven great!',
    txt_global_std_PaymentTrouble2_Text7: 'Sincerely,\r\n{appName}',
    txt_global_std_paidaftertheendoftheinsuranceyear: "Payé après la fin de l'année d'assurance.",
    txt_global_std_timeandplace: 'Time & place',
    txt_global_std_invitesendcodetolabel: 'Send invitation code to',
    txt_global_std_forfurtherinstructionspleasechooseyourconnectionmethod: "Pour plus d'instructions, veuillez choisir votre méthode de connexion",
    txt_global_std_details: ' Détails',
    txt_global_std_notsigneduptryg: 'Pas encore souscrit à une assurance Sidekick ?',
    txt_global_std_p2pyescancelbooking: 'Oui, annuler la réservation',
    txt_global_std_mmyy: 'MM / AA',
    txt_global_std_orderednewpassword: 'Nouveau mot de passe commandé',
    txt_global_std_notactivated: 'Not activated',
    txt_global_std_kintofaq1a: 'Non, l&#39;application fonctionne en mode arrière-plan! Assurez-vous de toujours activer les services Bluetooth et de localisation sur votre téléphone.',
    txt_global_std_translations: 'Traductions',
    txt_global_std_ourinsuranceisbasedonsafedriving: 'Our insurance premium is based on your safe driving.',
    txt_global_std_v2stargrade: 'Score',
    txt_global_std_poins: 'Points',
    txt_global_std_usethiskeyinyourapplicationby: 'Utilisez cette clé dans votre application par..........',
    txt_global_std_aftereverydrive: 'After every drive',
    txt_global_std_gooddrivingperformance: ' Bonne performance de conduite',
    txt_global_std_chooseoneoftheoptionsbelow: 'Choose one of the options below.',
    txt_global_std_driveforthefuture: 'Conduire pour l&#39;avenir.',
    txt_global_std_weareinareyou: 'Nous sommes dans - et vous ?',
    txt_global_std_fleetcompanydescription: 'Gestion du risque conducteur et impact CO2.',
    txt_global_std_borrowed: 'Borrowed',
    txt_global_std_wasthecartowed2: 'La voiture a-t-elle été remorquée?',
    txt_global_std_NotCreatedAccount1_Text5: 'When you signed up for Enerfy - Insurance with Moderna you got seven days to get started. Enter your verification code for the insurance in your {appName} app, otherwise you will have to pay for day premium. It now remains three days of your starting-period.',
    txt_global_std_insuranceusage: 'Utilisation de l&#39;assurance',
    txt_global_std_yourpolicybeginsonthedateandtimewhenyouelectronicallysigntheapplicationandmakethedownpaymentonyourpolicy: "Votre police prend effet à la date et à l'heure où vous signez électroniquement la proposition et versez la mise de fonds sur votre police.",
    txt_global_std_areyousureyouwanttoendyourcurrentrun: ' Êtes-vous certain(e) de vouloir mettre fin à votre trajet actuel ?',
    txt_global_std_friendsandsocial: 'Friends & social',
    txt_global_std_ohno: 'Oh non',
    txt_global_std_keeptrackhowmanykilometersyouhavedriven: 'Gardez une trace du nombre de kilomètres que vous avez parcourus',
    txt_global_std_nextloayaltylevelprogressbar: 'Barre de progression du niveau de fidélité suivant',
    txt_global_std_VilliersMusic: 'When I drive, I listen to any good music, I am not too fussy about music, to be honest.',
    txt_global_std_garminwatchconnected: 'Montre Garmin connectée',
    txt_global_std_drivesafeandecofriendlyearngreatrewards: 'Conduisez prudemment et écologiquement\n' +
      '- Gagnez de superbes récompenses !',
    txt_global_std_forquestionscallagentatphonenumberphoneno: 'Pour toute question, appelez {agent} au numéro de téléphone {phoneNo}',
    txt_global_std_p2ppushtitlenewexpensetoapprove: 'New expenses to approve',
    txt_global_std_unlockeddescription: 'Déverrouillé Lorsque le badge a été reçu',
    txt_global_std_totalscore2: 'Score total',
    txt_global_std_insuranceinformation: "Information sur l'assurance",
    txt_global_std_doihavetoactivatetheappeverytimeidrive: 'Dois-je activer l&#39;application à chaque fois que je conduis?',
    txt_global_std_InsuranceMail_Text21: 'All risks',
    txt_global_std_NewPW_Hello: ' Bonjour',
    txt_global_std_namecannotbetoolong: 'Le nom ne peut pas être trop long',
    txt_global_std_recruitafriend: 'Recruter un ami',
    txt_global_std_filters: 'filtres',
    txt_global_std_PaymentTrouble1_Text4: 'This may be due to:',
    txt_global_std_yourinvitationwassuccessfullysent: 'Votre invitation a été envoyée avec succès!',
    txt_global_std_currentlynotopdrivers: 'Aucun pilote de pointe actuellement',
    txt_global_std_MSIG_InsuranceLetter3: 'Loi sur les véhicules à moteur (risques liés à des tiers et indemnisation) (chapitre 189)',
    txt_global_std_finishedhead2head: 'Défi tête 2 terminé',
    txt_global_std_warikan_onboarding1: 'Entrepreneur KINTO ONE',
    txt_global_std_p2pwecouldnotverifyyourkintonumberpleaselogintoyourkintoaccountandclickonthelinktodownloadthewarikanapp: "Nous n'avons pas pu vérifier votre numéro Kinto. Veuillez vous connecter à votre compte Kinto et cliquer sur le lien pour télécharger l'application Warikan",
    txt_global_std_topdriversthishour: 'Top pilotes cette heure',
    txt_global_std_pushinsurancerenewalodoremtitle: 'Renouvellement de {appname}',
    txt_global_std_noruns: "Make your first run with {appName}! Are you in your car? Let's start.",
    txt_global_std_score: 'Score',
    txt_global_std_BurglaryInCar: 'Inbrott I bil',
    txt_global_std_logdrivers: 'Log drivers',
    txt_global_std_yougetthiswhenyouhavescoredover4point410times: 'Vous obtenez ceci lorsque vous avez marqué plus de 4,4 10 fois',
    txt_global_std_myaccount: 'Mon compte',
    txt_global_std_anemailaddressisrequired: ' Une adresse e-mail est nécessaire',
    txt_global_std_howdoigetagoodscore: 'Comment puis-je obtenir un bon score?',
    txt_global_std_p2pmarkaspaid: 'Marquer comme payé',
    txt_global_std_SulayemInterests: 'Course automobile, Sports automobiles, Voitures classiques',
    txt_global_std_BeforeBill_Text10: 'Hälsningar,\r\n{appName}',
    txt_global_std_reportclaim: 'Signaler une réclamation',
    txt_global_std_thismonth: 'Ce mois-ci',
    txt_global_std_closedteam: 'Closed team',
    txt_global_std_totalamountofaccountscreated: 'Montant total des comptes créés',
    txt_global_std_PaymentTrouble1_Text10: 'If you have already paid your premium, you may contact our customer service at: info@enerfy.se or telephone:',
    txt_global_std_myleaguetext: 'Vous conduisez dans la division {league}. Continuez à adopter une conduite intelligente pour grimper dans le classement.',
    txt_global_std_thiswillclosein5seconds: 'This will close in 5 seconds.....',
    txt_global_std_atthechallengedashboardyoucanviewyourchallengesandcreatenewonestocompeteindrivingsafeandecofriendly: "Sur le tableau de bord des défis, vous pouvez afficher vos défis et en créer de nouveaux pour concourir en conduisant en toute sécurité et en respectant l'environnement.",
    txt_global_std_typevideo: 'Video',
    txt_global_std_donthaveanaccountsignupsignuplink: "Vous n'avez pas de compte ? S'inscrire{signuplink}",
    txt_global_std__login: 'Connexion',
    txt_global_std_thisactionwillremoveyouraccount: ' Cette action va supprimer votre compte et vos données personnelles. Cette action est irréversible !',
    txt_global_std_howdoiearnaloyaltystatusanswer: 'Vous gagnez votre statut de fidélité en collectant des points! Vous obtenez des points des différentes réalisations disponibles. Des réalisations sont données à nos chauffeurs {appname} fidèles et sûrs! Apprenez-en plus à ce sujet dans la section «Réalisations».',
    txt_global_std_MSIG_Password4: 'Cordialement, MS First Capital',
    txt_global_std_translationtoolvisualeditor: 'Outil de traduction - éditeur visuel',
    txt_global_std_redeemyourrewards: 'Échangez vos récompenses',
    txt_global_std_nocode: 'No code? Click here',
    txt_global_std_f_lanzmileage: "Balade nature de 200 km. Ici, en Afrique du Sud, nous pouvons rapidement nous échapper de la ville et profiter d'une pittoresque Nture Drive. Espace très ouvert et l'esprit peut se concentrer et traiter, et se détendre en même temps.",
    txt_global_std_InsuranceMail_Text13: 'Machine',
    txt_global_std_pushdriveendlevel2: 'Rigtigt godt arbejdet. Hvis du vil se blot info, så klik på hende.',
    txt_global_std_youredone: 'Vous avez terminé!',
    txt_global_std_numberno: ' No.',
    txt_global_std_VillersDescription: 'Giniel De Villiers n’est pas encore en retraite. Encore mieux, Il n’a fini qu’une fois en dehors du top 10 (2007). Il compte 14 victoires d’étapes et en 2009 il a emporté la première compétition du Dakar de l’Amérique du Sud.',
    txt_global_std_youneedtoinputavalidemailformat: ' Vous devez saisir une adresse e-mail valide',
    txt_global_std_JyrkiainenDescription: 'Suvi a grandi dans une famille de pilotes de courses, entouré par le sport automobile, fille de Minna Sillankorva, championne féminine du WRC en 1991 et neuf fois championne de Finlande.',
    txt_global_std_youcannotopenthispopupagain: 'Vous ne pouvez pas ouvrir à nouveau cette fenêtre contextuelle.',
    'txt_global_std_Activate GPS': 'Activate GPS',
    txt_global_std_addlicense: 'AJOUTER UNE LICENCE',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint1: 'Évaluez le risque de conduite en fonction des lieux de conduite réels, et non du domicile ou du lieu de travail',
    txt_global_std_NotCreatedAccount1_Text6: 'Do you need help with creating an account? Please contact us at: info@enerfy.se',
    txt_global_std_numbercantinclude6062s: `Le nombre ne peut pas inclure "'?&/&#60; &#62;\\s# !%$%^*-`,
    txt_global_std_gointoapp: ' Se rendre dans l’application',
    txt_global_std_movethemarkertoseehowyourlevelaffectsyourdiscount: 'Déplacez le marqueur pour voir comment votre niveau affecte votre remise',
    txt_global_std_profile: ' Profil',
    txt_global_std_ourinsurancetermswassuccessfullysentto: 'Our insurance terms was successfully sent to:',
    txt_global_std_TidemandMusic: "A well-composed mix of all genres from different times. When I drive it's all about finding the right vibe for the mood, I'm in. ",
    txt_global_std_SignupFia_Text10: 'Le FIA Smart Driving Challenge appartient à la Fédération Internationale de l’Automobile et il est organisé par la FIA et par Greater Than. Pour plus d’informations, veuillez visiter ',
    txt_global_std_cimage39d: ' Votre véhicule peut parcourir en roule libre de plus longues distances que ce que vous pensez.',
    txt_global_std_youhavebeeninvited: 'Vous avez été invité !',
    txt_global_std_disconnectallotherdevicesconnectedtotheobd: "Déconnectez les autres apparéils du Plug-in, il ne peut ëtre connecté qu'à un appareil à la fois.",
    txt_global_std_yourdrivewillautomaticallyending: 'Votre lecteur se termine automatiquement',
    txt_global_std_monday: 'Lundi',
    txt_global_std_activateyourinsurance: 'Activer votre assurance',
    txt_global_std_addyourcurrentmileagevalueinkmtakingapictureofyourodometeroursystemcouldaddthisvalueusingimagerecognition: "Ajoutez votre kilométrage actuel (valeur en km) en prenant une photo de votre odomètre. Notre système pourrait ajouter cette valeur en utilisant la reconnaissance d'image!",
    txt_global_std_totalco2savings: 'Économies totales de CO2',
    txt_global_std_NewCar_Hello: 'Hej',
    txt_global_std_receipt: 'Receipt',
    txt_global_std_finalistsnationalchallenges: 'finalistes - DÉFIS NATIONAUX',
    txt_global_std_youhavereferred10friendsnice: 'Vous avez parrainé 10 amis! Bon!',
    txt_global_std_heats: 'Manches',
    'txt_global_std_3. Press Generate': '3. Press "Generate"',
    txt_global_std_addedodometervaluemileage: 'Valeur du compteur kilométrique ajoutée (kilométrage)',
    txt_global_std_usercannotberemoved: "L'utilisateur {{email}} ne peut pas être supprimé.",
    txt_global_std_p2pcarlend: 'Auto',
    txt_global_std_rowsperpage: 'Lignes par page',
    txt_global_std_100kmunlockedkey: "Vous avez parcouru une distance de 100 km avec l'application !",
    txt_global_std_pleaseenteryourpasswordtodeletethischallenge: 'Veuillez entrer votre mot de passe pour supprimer ce défi',
    txt_global_std_distractions: 'Distractions',
    txt_global_std_motorclaimshotline1: 'Hotline pour les réclamations automobiles',
    txt_global_std_actionsheader: 'Actions',
    txt_global_std_manualstartstopofdrive: 'Manual start/stop of drive',
    txt_global_std_uploadvideo: 'Upload video',
    txt_global_std_PolicySchedule8: 'Marque / Type de corps',
    txt_global_std_pioneergetstartedguide: 'Guide de démarrage',
    txt_global_std_Insurance_PaymentPeriod2: 'Payment period',
    txt_global_std_therearenocostsyet: 'Il n&#39;y a pas encore de frais!',
    txt_global_std_p2pmessage: 'Message',
    txt_global_std_befocused: 'Concentre-toi',
    txt_global_std_firststepcompleted: 'First step completed!',
    txt_global_std_MSIG_InsuranceLetter42: 'SGD3,500.00 LES SECTION I &amp; II SONT SÉPARÉMENT IMPOSÉES À CES CONDUCTEURS DE MOINS DE 22 ANS ET / OU QUI ONT MOINS DE 2 ANS D&#39;EXPÉRIENCE DE CONDUITE.',
    txt_global_std_sdsscore: 'Sport Score',
    txt_global_std_policynumber1: 'Numéro de police',
    txt_global_std_p2pend: 'Fin:',
    txt_global_std_silverlevel: "Niveau d'argent",
    txt_global_std_yourrecenttrips: 'Vos voyages récents',
    txt_global_std_Insurance_Text10: 'Pengarna för din bilförsäkring dras automatiskt varje månad från det bankkort som angavs vid tecknandet av försäkringen. Vill du byta kortuppgifter går du in på ”Kostnad” i {appName}appen och väljer ”Uppdatera kortuppgifter”.',
    txt_global_std_probablythemostentertaining: 'Probably the most entertaining way of saving money, time and CO2 there is.',
    txt_global_std_support: 'Support',
    txt_global_std_addyourcurrentmileagevalueinkm: 'Ajoutez votre kilométrage actuel (valeur en km)',
    txt_global_std_joined: 'Rejoint',
    txt_global_std_turnoncarengine: ' Mettre en route le moteur',
    txt_global_std_youcanstillusetheapptogetinsightsandstatisticsonyourdriving: "Vous pouvez toujours utiliser l'application pour obtenir des informations et des statistiques sur votre conduite.",
    txt_global_std_invitetosidekicktextlink: 'How the invitation works',
    txt_global_std_connectyourdevice: '2. Connectez votre appareil',
    txt_global_std_introslide1header: '{appName} - Insurance with Moderna',
    txt_global_std_Intro_Text14: 'Håper dette var oppklarende!',
    txt_global_std_youarealreadydrivingwhenyouhavefinished: 'Vous conduisez déjà. Lorsque vous avez terminé votre trajet, vous trouverez votre voiture ici.',
    txt_global_std_qdoessompoacceptdriversthatarenotlicensedintheunitedstates: 'Q : Est-ce que Sompo accepte les conducteurs qui ne sont pas autorisés aux États-Unis ?',
    txt_global_std_carmodel: 'Car model',
    txt_global_std_p2pmemberuserguide: "Guide d'utilisation des membres",
    txt_global_std_diesel: ' Gazole',
    txt_global_std_lokaliteterndvendigforatvurderedinkrselsadfrdogberegnedinkrescore: 'Lokalitet er nødvendig for at vurdere din kørsels-adfærd og beregne din kørescore.',
    txt_global_std_turnonbluetoothinyourphone: 'Activez le Bluetooth sur votre téléphone.',
    txt_global_std_BikerPhone: 'Nummer till cyklist/gångtrafikant',
    txt_global_std_MSIG_InsuranceLetter29: 'Publié à Singapour le',
    txt_global_std_NewActivationCode_Text1: 'Her kommer din nye aktiveringskode:',
    txt_global_std_PaymentTrouble3_Text6: 'the Motor Insurers Association will take over the case.',
    txt_global_std_january: ' Janvier',
    txt_global_std_warning: ' Avertissement',
    txt_global_std_youdroveatscoretypescoreorbettergreatyouhaveunlockedthisbadge: 'Vous avez conduit à {scoretype} {score} ou mieux! Génial! Vous avez déverrouillé ce badge.',
    txt_global_std_drivingsmartmeansusinglessenergyandlessenergymeanslowerco2emissions: `Conduire de manière "smart" signifie conduire de manière plus sûre pour sauver des vies sur la route et utiliser moins d'énergie pour réduire l'impact sur l'environnement.`,
    txt_global_std_pigsavedunlocked: 'Pour chaque tranche de 50 couronnes, vous obtenez ce prix',
    txt_global_std_qwhenwillmyratechange: 'Q : Quand mon tarif changera-t-il ?',
    'txt_global_std_Start without a reader': 'Start without a reader',
    txt_global_std_alertmaxspeedtext: ' Alarme de vitesse maximale activée.',
    txt_global_std_entertheemailaddressthatyouhaveregisteredonyouragentaccount: "Entrez l'adresse e-mail que vous avez enregistrée sur votre compte {agent}",
    txt_global_std_oneuniformmeasurementregardlessofvehicletypeandgeography: 'Une mesure uniforme quel que soit le type de véhicule et la géographie',
    txt_global_std_unabletoaddmembers: 'Unable to add members',
    txt_global_std_august: ' Août',
    txt_global_std_flexiblesafenessinpocket: 'Flexible safeness in the pocket',
    txt_global_std_cimage25d: ' Regardez quel est le régime moteur à 80 km/h avec le rapport le plus élevé. Essayez ensuite de rester sous ce régime avec chaque rapport.',
    txt_global_std_ridewithmeunlockedkey: "Bon travail! Rouler ensemble dans une voiture au lieu de prendre des voitures séparées permet d'économiser beaucoup de CO2 ! Vous pouvez gagner ce badge une fois par semaine.",
    txt_global_std_p2pbyname: 'De nom',
    txt_global_std_obdreader: 'Système OBD',
    txt_global_std_howdoicompeteinenerfyloyaltychallengeanswer: 'Vous participez automatiquement lorsque vous vous inscrivez. Ne vous inquiétez pas, le classement n&#39;affichera que votre surnom - vous resterez donc toujours anonyme. Il y a une compétition mensuelle et annuelle.',
    txt_global_all_saturday: ' Samedi',
    txt_global_std_p2pnamewantstoborrowvehicle: '{name} veut emprunter {vehicle}',
    txt_global_std_activatebluetoothsothedevicecanconnectyourphonewithyourcar: 'Activer Bluetooth pour que l&#39;appareil puisse connecter votre téléphone à votre voiture',
    txt_global_std_BeforeFirstBill_Text4: 'Can I keep track of my costs?',
    txt_global_std_PSolbergMileage: 'Coming soon...',
    txt_global_std_editapikey: 'Modifier la clé API',
    txt_global_std_p2pfaq4a: 'Appuyez et maintenez le message que vous souhaitez supprimer, et une boîte de dialogue apparaîtra dans laquelle vous pouvez supprimer le message.',
    txt_global_std_progressstatus: 'Le statut de la progression',
    txt_global_std_p2ppushtitlerevokeadmin: 'Droits d&#39;administrateur révoqués',
    txt_global_std_sdcrapply2019price2: '500 kr gasoline checks',
    txt_global_std_preparingyournewenerfyhome: ' Préparation de la page d’accueil de votre nouvelle  {appName}',
    txt_global_std_networkoffline: ' Réseau hors ligne',
    txt_global_std_Alert_WereYouDrivingTitle: 'Conduisais-tu ?',
    txt_global_std_Insurance_CarBrand: 'Make',
    txt_global_std_loyaltysmsmailtext: 'Commencez à conduire en toute sécurité avec moi! Suivez le lien ci-dessous et commencez à conduire avec',
    txt_global_std_invitefriendsgetawards: 'Inviter des amis. Obtenez des récompenses.',
    txt_global_std_failedtopublishedlanguage: 'Échec de la langue publiée !',
    txt_global_std_unlocklimitheader: 'Déverrouiller la limite',
    txt_global_std_PreSaleDetails_Text21: "Your car is legally required to have traffic insurance. Traffic insurance covers personal injuries and damage to another person's property for up to 300 million SEK. Traffic insurance does not cover damage to your own vehicle.",
    txt_global_std_myinsurance: 'My insurance',
    txt_global_std_SignupFia_Text8: ' Ne manquez aucune information – N’hésitez pas à nous suivre sur les réseaux sociaux',
    txt_global_std_percentagedissconnectedtripsmax25: '{percentageDissconnectedTrips} % de trajets déconnectés (sur un maximum de 25 %)',
    txt_global_std_users: ' Utilisateurs',
    txt_global_std_orderobd: 'Commande OBD',
    txt_global_std_reports: 'Reports',
    txt_global_std_challengesent2: 'Défi envoyé!',
    txt_global_std_verifyyourchanges: 'Vérifiez vos modifications',
    txt_global_std_RecieptMail_Text10: "According to the law on distance and off premises contracts, customers have the right to withdraw for 14 days. If you regret the purchase, contact {appName}'s customer service for more information and assistance.",
    txt_global_std_belowisyourlocationsoyoucaneasilydescribewhereyouaretotheroadassistantmanager: 'Vous trouverez ci-dessous votre emplacement, afin que vous puissiez facilement décrire où vous vous trouvez au gestionnaire de Road Assistant.',
    txt_global_std_trafficdelaysdeadlinesandrunninglatetheycanallcausestresswhichisnegativeforyourhealthandsafetyallowingextratimeplanningyourrouteandcheckingtravelupdatescanhelptoreducestressandimproveyourfocus: 'Retards de circulation, échéances et retards ; ils peuvent tous causer du stress, ce qui est négatif pour votre santé ET votre sécurité. Accorder plus de temps, planifier votre itinéraire et vérifier les mises à jour de voyage peuvent aider à réduire le stress et à améliorer votre concentration.',
    txt_global_std_Footer_3: 'Vid försenad betalning debiteras dröjsmålsränta i enlighet med räntelagen ',
    txt_global_std_TaxInvoice2: 'Date',
    txt_global_std_current: 'Courant:',
    txt_global_std_WhoDiscovered: 'Vem upptäckte skadan/inbrottet',
    txt_global_std_refer10friendsachievementlocked: 'Parrainez 10 amis pour débloquer ce succès et gagner 300 points.',
    txt_global_std_removephoto: ' Retirer la photo',
    txt_global_std_pleaseenteravalidemail: 'Veuillez entrer une adresse e-mail valide.',
    txt_global_std_chooseinsurance: 'Choose insurance',
    txt_global_std_emailhasbeensent: 'E-mail has been sent!',
    txt_global_std_p2pblockcar: 'Bloquer la voiture',
    txt_global_std_countryroadfilechangeDESC: 'Le conducteur de la voiture bleue choisit une voie et conduit concentré. Le conducteur de la voiture rouge suit la voiture devant lui et change de voie de manière stressante.',
    txt_global_std_discountinformation: 'Votre niveau moyen ce mois-ci est la base de votre remise. Vous recevez le rabais lorsque vous renouvelez votre assurance pour les mois à venir',
    txt_global_std_business2: 'Entreprise',
    txt_global_std_NotCreatedAccount1_Hello: 'Hello',
    txt_global_std_cimage30d: ' Une accélération suivie d’un ralentissement est synonyme de perte d’argent.',
    txt_global_std_30daysfreetrialnocreditcardrequired: "30 jours d'essai gratuit. Pas de carte de crédit nécessaire.",
    txt_global_std_onceyoupairtheapptoyourvehiclesbluetoothandchangethesettingstoallowlocationserviceswematchyourdrivinghabitstoexistingdriversandprovideascoreinsuranceisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsabouttheagentappandcompeteagainstthemforlowerscores: "Une fois que vous avez couplé l'application au Bluetooth de votre véhicule et modifié les paramètres pour autoriser les services de localisation, nous adaptons vos habitudes de conduite aux conducteurs existants et vous fournissons un score. L'assurance est basée sur qui conduit votre véhicule et à quel point ils conduisent bien. Quiconque conduit votre véhicule est encouragé à télécharger l'application et à suivre ses scores. Parlez de l'application {agent} à vos amis et affrontez-les pour obtenir des scores inférieurs.",
    txt_global_std_ActivationLetter_Hello: 'Welcome!',
    txt_global_std_locationservicesnotsettoalwaysallow: 'Les services de localisation ne sont pas définis sur Toujours autoriser',
    txt_global_std_yourfriendsname: ' Nom de vos amis',
    txt_global_std_PreSaleDetails_Text66: 'Moderna Försäkringar is a branch of Tryg Forsikring A / S (Reg No 516403- 8662), Box 7830, 103 98 Stockholm, tel. 0200-259 259, www. modernaforsakringar.se.',
    txt_global_std_accepted: 'Accepté',
    txt_global_std_thesaferyoudrivethehigherthesaving: 'Plus votre conduite est sûre - plus vous économisez!',
    txt_global_std_clear: 'Dégager',
    txt_global_std_achievementcannotbedeletedpopup: 'Cette réussite ne peut pas être supprimée.',
    txt_global_std_alluploadedphotoswillbemarkedwithacheckmark: 'Toutes les photos téléchargées seront marquées d&#39;une coche.',
    txt_global_std_NewCar_Text5: 'Venlig hilsen',
    txt_global_std_p2paddedbyyou: 'Ajouté par vous',
    txt_global_std_invitecodelabel: 'Send invitation code to',
    txt_global_std_partlyavailable: 'Partiellement disponible',
    txt_global_edj_businessdistance: 'Business (km)',
    txt_global_std_p2pbookingconfirmed: 'Réservation confirmée',
    txt_global_std_avgtimeonroad7d: 'Temps moyen sur route 7D',
    txt_global_std_createyouraccounttosaveyourtripsandearnbonusetc: 'Créez votre compte pour enregistrer vos voyages, et avoir accès à votre coach de conduite personnel. Vous pouvez créer votre compte ultérieurement.',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint1: 'Solution complète pour la gestion prédictive des risques/de la durabilité',
    txt_global_std_warikan_onboarding9: "5. Sélectionnez la date et l'heure d'utilisation de la voiture dans le calendrier et faites une réservation",
    txt_global_std_exampleemail: ' nom@domaine.com',
    txt_global_std_startguidetip2: 'Assurez-vous que le Bluetooth et le service de localisation sont activés.',
    txt_global_std_getspecialoffers: 'Obtenir des offres spéciales',
    txt_global_std_paymentmethodcard: 'Payment method card',
    txt_global_std_acceptfreemiumterms: 'Accept terms and policies',
    txt_global_std_MikkelsenQuote: 'C’est une honneur d’’être sélectionné chef d’équipe de ce projet passionnant ! Je pilote dans un niveau d’élite dans le monde du sport automobile, et si je puisse, en tant que conducteur, inspirer d’autres à devenir des conducteurs smart, sûres et responsable de l’environnement, je serais content de faire ça. Rejoignez mon équipe aujourd’hui et allons-y pour le « Smart Driving ».',
    txt_global_std_searchbartext: 'Recherche par xxx, xxx ou xxx',
    txt_global_std_qhowlongwillittaketoprocessmyclaim: 'Q : Combien de temps faudra-t-il pour traiter ma demande ?',
    txt_global_std_PolicySchedule23: 'Prime',
    txt_global_std_NotDriving_Text3: 'Sincerely,\r\n{appName}',
    txt_global_std_insurancecompany: 'Compagnie d&#39;assurance',
    txt_global_std_TaxInvoice15: 'Entré par STELLAL',
    txt_global_std_thepasswordhasbeenreset: 'Le mot de passe a été réinitialisé.',
    txt_global_std_youreinvited: 'Tu es invité!',
    txt_global_std_resetpasswordsuccess: 'Succès! Vérifiez votre e-mail pour un lien de réinitialisation du mot de passe.',
    txt_global_std_enerfycoachtip3: ' 3. Ne vous précipitez pas, même si vous êtes en retard.',
    txt_global_sidekick_invitecodesent: 'Invite code sent',
    txt_global_std_whatwillhappenifmyphonelosessignalorifmyphone: 'What will happen if my phone loses signal or if my phone runs out of battery?',
    txt_global_std_bestregardscomma: 'Meilleures salutations,',
    txt_global_std_competeindividuallyonly: 'Concourir individuellement uniquement',
    txt_global_std_paid: 'Payé',
    txt_global_std_friendwasremoved: "L'ami a été supprimé",
    txt_global_std_makesureyourengineison: ' Assurez-vous que le moteur soit en marche',
    txt_global_std_confirmandcreateaccount: 'Confirmer et créer un compte',
    txt_global_std_pigsaved: '50 couronnes sauvées',
    txt_global_std_qcanitrytheappbeforesigningup: "Q : Puis-je essayer l'application avant de m'inscrire ?",
    txt_global_std_simplyconnectyourcartosmartphone: 'Simply connect your car to your Smartphone and discover the new way forward.',
    txt_global_std_DrivingScore_Text4: 'Hvis du er uenig i vår vurdering eller ønsker nærmere beskrivelse av hvordan du kan forbedre din kjørescore, kontakt oss på chat i appen eller ring oss på 04040.',
    txt_global_std_HusseinAbdullaQuote: 'Citation à venir ...',
    txt_global_std_p2ppushtitleborrowcar: '{name} - emprunter une voiture?',
    txt_global_std_home: 'Accueil',
    txt_global_std_youareabouttopublishtranslationspleasemakesureyouhavereviewedalltranslationsbeforeproceeding: "Vous êtes sur le point de publier des traductions, assurez-vous d'avoir examiné toutes les traductions avant de continuer.",
    txt_global_std_patternprofiling: 'Profilage de modèle',
    txt_global_std_Intro_Text9: 'I begynnelsen av hvert år får du 1000 kr i sparegrisen. Du har mulighet til å spare dette beløpet, i tillegg opptil 30% av årspremien med trygg kjøring. Om du kjører mindre i løpet året enn oppgitt, vill du få utbetalt kjøreutbyttet ditt som er din belønning for å være en trygg sjåfør. Med høy score vil du også få mulighet til rabatt på neste års forsikringspremie.',
    txt_global_std_qualifications: 'Qualifications:',
    txt_global_std_p2pfaq32a: `1. En début de mois, un rappel "Warikan" s'affichera automatiquement sur l'écran d'accueil. Ou sélectionnez "Warikan" dans "Menu".\n` +
      '2. Sélectionnez "Warikan" pour le mois.\n' +
      "3. Dans le menu déroulant, sélectionnez quatre méthodes de fractionnement et réfléchissez à la manière de fractionner le coût. Vous pouvez choisir parmi « Ratio d'utilisation », « Durée d'utilisation », « Partage égal » et « Kilométrage ».\n" +
      '4. Ensuite, vérifiez le coût à facturer pour chaque membre. Vous pouvez ajuster le montant en sélectionnant la marque de crayon.\n' +
      '5. Sélectionnez Facturer les dépenses pour facturer les membres.\n' +
      '6. Lorsque vous recevez le coût du membre, sélectionnez « Paiement complet ».',
    txt_global_std_SulayemMusic: 'Smooth Jazz',
    txt_global_std_competeforthesebadges: 'Rivaliser pour ces badges',
    txt_global_std_youaretheowner: 'Nous avons besoin de votre mot de passe actuel pour nous assurer que ce compte vous appartient.\n',
    txt_global_std_staytunedonupcomingchallenges: 'Être informé des prochains défis',
    txt_global_std_statstotal: 'Total\n',
    txt_global_std_signin: ' Ouvrir une session',
    txt_global_std_p2ptypeofexpenses: 'Type de dépenses',
    txt_global_std_estimatedcost: 'Coût estimé',
    txt_global_std_automatic_start_and_stop_requires_access_to_bluetooth_location_services_and_motion_fitness: "Le démarrage et l'arrêt automatiques nécessitent l'accès à Bluetooth, aux services de localisation (GPS) et à Motion & Fitness.",
    txt_global_std_defaultfirsttimeusingtheserviceregisterfromsignuplink: 'Première fois que vous utilisez le service ? Inscrivez-vous depuis {signUpLink}',
    txt_global_std_p2pcalculationswillnotbecorrect: 'Les calculs ne seront pas corrects.',
    txt_global_std_youcanfileaclaimbyclickingonfileaclaiminyourenerfyapp: 'Vous pouvez déposer une réclamation en cliquant sur Déposer une réclamation dans votre application Enerfy.',
    txt_global_std_illdothislater: 'je ferai ça plus tard',
    txt_global_std_PaymentTrouble3_Text4: 'Click on the following link to update your payment information',
    txt_global_std_RecieptMail_DayPremium: 'day premium.',
    txt_global_std_InsuranceMail_Text20: 'Vehicle damage animal collision ',
    txt_global_std_dataintervalupload: ' 20 sec.',
    txt_global_std_youneedtofillindriversname: 'Vous devez renseigner le nom du pilote',
    txt_global_std_challengeafriend: 'Défiez un ami!',
    txt_global_std_sorrytheimagerecognitionfailed: "Désolé, la reconnaissance d'image a échoué",
    txt_global_std_emailaddress: ' Adresse e-mail',
    txt_global_std_p2pmycars: 'Mes voitures',
    txt_global_std_howdoiearnsavings: 'Comment faire des économies?',
    txt_global_std_getupdatesregardingnews: 'Recevez des mises à jour concernant les nouvelles et les défis!',
    txt_global_std_closestworkshop: 'Atelier le plus proche',
    txt_global_std_sidekickprofilepreferences: 'Préférences de profil',
    txt_global_std_stardriverunlockedkey: "Vous êtes une star, {utilisateur} ! Vous avez obtenu un score supérieur à {score} aujourd'hui !",
    txt_global_std_atthecompanychallengepageyoucancreatenewprivatechallengeswhereyoucancompeteindrivingsafeandecofriendly: "Sur la page Company Challenge, vous pouvez créer de nouveaux défis privés où vous pouvez concourir en conduisant en toute sécurité et en respectant l'environnement.",
    txt_global_std_therecouldbeotherreasonswhyyourcarsbluetoothyourbluetoothbeacondoesntshowupinthelist: "Il peut y avoir d'autres raisons pour lesquelles votre\n" +
      "Bluetooth de la voiture/votre balise Bluetooth ne s'affiche pas dans le\n" +
      'liste:',
    txt_global_std_rwmnofriendstitle: "Vous n'avez pas encore d'amis",
    txt_global_std_p2ppushtitlecancelledperiodfromto: 'La réservation avec la période {from} a été annulée.',
    'txt_global_std_sorryiobd2errorcodereadingnotsupportedbywindowsphone.': ' Désolé, le code d’erreur iOBD2 signifie que la lecture n’est pas supportée sur les téléphones Windows.',
    txt_global_std_warikan_onboarding3_1: 'なお、仮パスワードの有効期限は72時間となっております。\n' +
      '後 後 わりかん わりかん を 再 再 手続き 受け取っ 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き 手続き\n' +
      '本 送信 専用 専用 ます も ます ます ます ます も は お問い合わせ は は ます へ ます ます も ます',
    txt_global_std_english: 'Anglais',
    txt_global_std_pleaseconfirmyourlicenseplate: 'Veuillez confirmer votre plaque d&#39;immatriculation:',
    txt_global_std_aifyourcardoesnthavebluetoothpleasecontactsupportatphonenotoorderanobdreaderthatyouwillneedtoplugintoyourcar: "R : Si votre voiture ne dispose pas de Bluetooth, veuillez contacter l'assistance à l'adresse {phoneNo} pour commander un lecteur OBD que vous devrez brancher sur votre voiture.",
    txt_global_std_ignore: ' Ignorer',
    txt_global_sidekick_invitetemplatetitle: 'Invite a friend',
    txt_global_std_someviewslookdifferentdependingonifthereisdrivingdataornotyoucanlogintoyouraccountintheapporusethedifferentuserstatestoviewandtranslatetheappwithorwithoutdrivingdata: "* Certaines vues semblent différentes selon qu'il existe ou non des données de conduite. Vous pouvez vous connecter à votre compte dans l'application ou utiliser les différents états d'utilisateur pour afficher et traduire l'application avec ou sans données de conduite.",
    txt_global_std_phonenumberdoesnotexist: "Le numéro de téléphone n'existe pas !",
    txt_global_std_themescreendesign: "Thème / Conception d'écran",
    txt_global_std_my_saving: 'MON ÉCONOMIE',
    txt_global_std_finalistsheat2: 'Finalistes - Manche 2',
    txt_global_std_addusersto: 'Ajouter des utilisateurs à',
    txt_global_std_yourchallenges: 'Vos défis',
    txt_global_std_gender: ' Sexe',
    txt_global_std_youraddress: 'Votre adresse',
    txt_global_std_company: ' Entreprise',
    txt_global_std_MSIG_InsuranceLetter13: 'Valeur estimée assurée',
    txt_global_std_reminderinsurance: 'Rappel',
    txt_global_std_bluetoothtroubleshooting: 'Diagnostic du Bluetooth',
    txt_global_std_cimage28d: ' Utilisez le régulateur de vitesse exclusivement sur une route plate. Autrement, maintenez une accélération stable.',
    txt_global_std_joinussignupforourcommunityandbecomeapartofus: 'Rejoignez-nous, inscrivez-vous pour notre communauté et devenez une partie de nous. Une fois que vous avez consenti, vous pouvez participer à nos compétitions ou défier un ami.',
    txt_global_std_p2pwarningexpenses: "Assurez-vous d'ajouter d'abord vos dépenses. Vous ne pourrez pas le faire plus tard.",
    txt_global_std_youupgradedtovalueplanyouhaveearned10000points: 'Vous êtes passé au plan de valeur. Vous avez gagné 10000 points.',
    txt_global_std_NotCreatedAccount6_Text1: "Your Enerfy - Insurance with Moderna have now transferred to an annual, regular car insurance at Moderna Försäkringar, since you haven't verified your insurance in a month.",
    txt_global_std_igetit: 'J&#39;ai compris!',
    txt_global_std_registeryourridesharingtripwithin1dayafterthetriphasended: "Enregistrez votre trajet en covoiturage dans un délai d'un jour après la fin du trajet",
    txt_global_std_darktheme: 'Thème sombre',
    txt_global_std_designandsendpushnotificationstoyourteam: 'Concevez et envoyez des notifications push à votre équipe en un rien de temps, ou planifiez-les pour le meilleur parcours client.',
    txt_global_std_tipcityparking1DESC: 'Le conducteur de la voiture bleue utilise un indicateur et attend jusquà ce quil y ait un écart plus grand entre les voitures. La voiture rouge quitte la place de stationnement sans utiliser d’indicateurs ni attendre un vide naturel dans la circulation.',
    txt_global_all_friday: ' Vendredi',
    txt_global_std_p2prequestanewpassword: 'Demander un nouveau mot de passe',
    txt_global_std_newpassword: ' Nouveau mot de passe',
    txt_global_std_youareincurrentleagueleague: 'You are currently in league:',
    txt_global_std_cwelcome2: 'Good morning {name}',
    txt_global_std_seewherepoliciesarewrittenvswheretheyaredriving: 'Voyez où les politiques sont écrites par rapport à où elles conduisent',
    txt_global_std_shareyourinvitecode: 'Partagez votre code d&#39;invitation',
    txt_global_std_ridewithme: 'Monte avec moi',
    txt_global_std_driversLicence: 'Drivers Licence',
    txt_global_std_yourfriendemail: 'votre-ami@email.com',
    txt_global_std_youremergencycontactshasbeensaved: 'Your emergency contacts has been saved.',
    txt_global_std_p2pchataboutabookingorstartagroupchatwithyourinvitedfriends: "Discutez d'une réservation ou démarrez une discussion de groupe avec vos amis invités.",
    txt_global_std_yougetthisachievementafterdrivingafullweekandmaintaininganaveragescoreofover4: 'Vous obtenez ce résultat après avoir conduit une semaine complète et maintenu un score moyen supérieur à 4,0',
    txt_global_std_Insurance_StarterPack: 'Starter pack',
    txt_global_std_setupyourcompanychallengeandinviteyourcoworkerstothefiasmartdrivingchallengecompeteagainsteachotherinafunwaytobecomebetterdriverssavemoreco2andimproveyourscore: 'Configurez votre Company Challenge et invitez vos collaborateurs au FIA Smart Driving Challenge. Affrontez-vous de manière amusante pour devenir de meilleurs conducteurs, économiser plus de CO2 et améliorer votre score.',
    txt_global_std_howdoestheappwork: "Comment fonctionne l'application ?",
    txt_global_std_activationcodeforname: "Code d'activation pour {nom}",
    txt_global_std_WorstGroups_Text1: 'We see that you have driven in some high and expensive premium rates recently. By driving smart, you can lower your premium. Therefore, we thought we would give you some tips based on your driving that can help you drive in the lower and cheaper premium rates.',
    txt_global_std_1stdate: '1er',
    txt_global_std_choosebetweenenteringchassinumberplatenumberorpolicynumber: 'Choisissez entre entrer le numéro de chassi, le numéro de plaque ou le numéro de police.',
    txt_global_std_gotochallenge: 'Aller au défi',
    txt_global_std_p2pfaq12a: 'Oui, si vous supprimez un ami, vous ne pourrez pas créer de nouvelles réservations avec cet ami.\n' +
      'Accédez à la page de vos amis à partir du menu Plus (trois points)\n' +
      '2.Sélectionnez votre ami pour voir son profil.\n' +
      '3.Appuyez sur le bouton des paramètres.\n' +
      '4.Choisissez Supprimer un ami.',
    txt_global_std_redeemedon: 'Utilisé le',
    txt_global_std_saturday: 'Samedi',
    txt_global_std_bluetooth: ' Bluetooth',
    txt_global_std_yourlatesttripwasbetterthanappnameaverage: 'Bon travail! Votre dernier voyage était meilleur que la moyenne de {appname} !',
    txt_global_std_p2pyouhavereachedthemaximumamountoffriendspercar: "Vous avez atteint le nombre maximum d'amis par voiture",
    txt_global_std_getstartedandgainaccessto: 'Commencez et accédez à',
    txt_global_std_createcustom: 'Créer personnalisé',
    txt_global_std_loyaltylevel: 'Niveau de fidélité',
    txt_global_std_you: ' Vous',
    txt_global_std_teamaveragescore: "Score moyen de l'équipe",
    txt_global_std_cropimage: "Rogner l'image",
    txt_global_std_competeinyoureverydaydrivingagainstyourfriends: 'Participez à votre conduite quotidienne contre vos amis. Le pilote avec le meilleur score remporte la manche!',
    txt_global_std_driverspolicy: 'Politique des chauffeurs',
    txt_global_std_pleasemakesurethatyouareconnectedtoyourcarandtryagain: 'Assurez-vous que vous êtes connecté à votre voiture et réessayez.',
    txt_global_std_deletechallenge: 'Supprimer le défi',
    'txt_global_std_When the indicator light is flashing blue the reader is properly plugged in to the OBD port on your car. A steady blue light means the reader is connected to your phone via bluetooth. If there is no indicator light, the reader is either in sleep mode or not properly plugged into the OBD port.': 'When the indicator light is flashing blue the reader is properly plugged in to the OBD port on your car. A steady blue light means the reader is connected to your phone via bluetooth. If there is no indicator light, the reader is either in sleep mode or not properly plugged into the OBD port.',
    txt_global_std_everydayheroeswearegatheringtomakeanimpactwedrivetoreduceourcoemissionsbybeingmoremindfulawareandactiveinthewaywetransportourselvesfromoneadventuretothenextbeittheweekendgetawayortogetthekidsfromsoccerpracticewemaketheworldsaferandbetterhowmuchletsfindout: "Héros du quotidien - nous nous rassemblons pour avoir un impact. Nous nous efforçons de réduire nos émissions de CO² en étant plus attentifs, conscients et actifs dans la façon dont nous nous transportons d'une aventure à l'autre (que ce soit l'escapade du week-end ou pour amener les enfants à l'entraînement de football). Nous rendons le monde plus sûr et meilleur. Combien? Découvrons-le!",
    txt_global_std_mysaving: 'MON ÉCONOMIE',
    txt_global_std_theparameterswerevalidbuttherequestfailed: 'Les paramètres étaient valides mais la demande a échoué.',
    txt_global_std_automotivecompany: 'Entreprise automobile',
    txt_global_std_p2pfinish: 'terminer',
    txt_global_std_BillMail_Text3: 'month',
    txt_global_std_findmycar: ' Trouver ma voiture',
    txt_global_std_individual: ' Personnalisé',
    txt_global_std_kmleftyourinsuranceisrenewed: 'km à gauche.\nVotre assurance est renouvelée',
    txt_global_std_p2pbookedby: 'Réservé par',
    txt_global_std_InsuranceMail_Text22: 'Assistance ',
    txt_global_std_p2pfaq18a: `1. Sélectionnez "Connect / Settings" dans la barre de menu en bas de l'écran.\n` +
      '2. Autorisez Bluetooth, les services de localisation, les paramètres de mouvement et de fitness. Pour les services de localisation, sélectionnez "Toujours" pour une acquisition de données précise.\n' +
      `3. Après avoir démarré le moteur, connectez la voiture et l'application via Bluetooth. Lorsque le nom de la voiture s'affiche sur l'application, sélectionnez "Sélectionner une voiture".\n` +
      '4. Lorsque vous commencez à conduire, vous recevrez des commentaires en temps réel sur votre conduite.\n' +
      "5. Lorsque la conduite est terminée, les données de conduite sont automatiquement enregistrées dans l'application.",
    txt_global_std_optional2: '(Optionnel)',
    txt_global_std_atleast8characters: 'Au moins 8 caractères.',
    txt_global_std_uploadimage: ' Télécharger l’image',
    txt_global_std_p2ppushtitlenewfriend: 'Nouvel ami',
    txt_global_std_p2paddmembers: 'Ajouter des membres',
    txt_global_std_PreSaleDetails_Text51: 'Comprehensive Insurance',
    txt_global_std_allowlocationtoparticipateinfiasdc: "Autoriser l'emplacement à participer au FIA SDC",
    txt_global_std_bookingchat: 'Chat de réservation',
    txt_global_std_summaryoftimedrivenperselectedperiod: 'Récapitulatif du temps de conduite par période sélectionnée.',
    txt_global_std_timeandcanonlybeusedonce: "temps et ne peut être utilisé qu'une seule fois.",
    txt_global_std_generate: 'Generate',
    txt_global_std_PolicySchedule11: 'N ° de châssis',
    txt_global_std_bad: ' Mauvais(e)',
    txt_global_std_averagescore: 'Score moy',
    txt_global_std_InsuranceApp_Text1Moderna: 'Thanks again for choosing Moderna Smart Flex! In this email you will find your insurance letter. If you notice some incorrect information, please contact our customer service at modernasmartflex@modernaforsakringar.se. Then we can solve the problem quick and easy for you.',
    txt_global_std_MSIG_InsuranceLetter1: 'CERTIFICAT D&#39;ASSURANCE',
    txt_global_std_damage: 'Damage',
    txt_global_std_yourinsurancewasautomaticallyrenewalformonth: 'Votre assurance a été renouvelée automatiquement pour {month}.',
    txt_global_std_breathing: 'Breathing',
    txt_global_std_unitsaved: ' Unité sauvegardée',
    txt_global_std_vehicletype: 'Vehicle type',
    txt_global_std_pleasenotethattojoinyoumusthavethefiasdcappinstalledonyourmobiledeviceinordertobeabletojointhegroup: "Veuillez noter que pour rejoindre le groupe, vous devez avoir installé l'application FIA SDC sur votre appareil mobile afin de pouvoir rejoindre le groupe.",
    txt_global_std_p2plendmycar: 'Prête ma {carname}',
    txt_global_std_typeofbusiness: 'Type de commerce',
    txt_global_std_close: ' Fermer',
    txt_global_std_datetimetogt: 'Date heure à GT',
    txt_global_std_risklevelandscoring: 'Niveau de risque et notation',
    txt_global_std_teammemberbig1: "MEMBRE DE L'ÉQUIPE",
    txt_global_std_thereaderwillnotbevisibleinyourphonesbluetoothsettings: ' Le lecteur ne sera pas visible dans les paramètres Bluetooth de votre téléphone.',
    txt_global_std_HighUsageAlarm_Text4: 'Hold god afstand til den forankørende, også i lave hastigheder ',
    txt_global_std_kintoloyaltylevels: 'Niveaux de fidélité Kinto:',
    txt_global_std_p2pmember: 'Membre',
    txt_global_std_presspaynowwhenyouarereadytocheckoutintheservicecenter: 'Appuyez sur «Payer maintenant» lorsque vous êtes prêt à payer dans le centre de service.',
    txt_global_std_tttest99: "c'est un test",
    txt_global_std_p2phours: 'heures',
    txt_global_std_yourbonus: 'Votre prime',
    txt_global_std_Invite_Text2: 'Click on the following link to confirm and accept your new team',
    txt_global_std_carstatsthismonth: 'Statistiques de voitures ce mois-ci',
    txt_global_std_Intro_Text17: 'Hilsen,\r\n{appName}-gjengen i Tryg Forsikring',
    txt_global_std_signinwithemail: ' Ouvrir une session avec votre e-mail',
    txt_global_std_p2powner: 'Propriétaire',
    txt_global_std_carroreward: 'Récompense Carro',
    txt_global_std_pleaseenteravalidphonenumberincludingareacode: "Veuillez saisir un numéro de téléphone valide, y compris l'indicatif régional",
    txt_global_std_somethinghasgonewrong: 'Quelque chose a mal tourné!',
    txt_global_std_Fia_Receipt_Text3: 'Utilisez ce code d’activation pour activer dans l’application de la FIA SMART pour activer votre achat.',
    txt_global_edj_destodometer: 'Mileage end (km)',
    txt_global_std_completedaconnecteddrive: 'Terminé un lecteur connecté',
    txt_global_std_WhoReportDamage: 'Vem är det som anmäler skadan',
    txt_global_std_1st: ' 1er(e)',
    txt_global_std_nousershavebeenadded: 'Nu users have been added.',
    txt_global_std_requiredformatcolumnastext2: 'Obligatoire (formater la colonne sous forme de texte)',
    txt_global_std_OnboardingEmail: 'Bienvenue chez MS First Capital Car Insurance!',
    txt_global_std_ourpremierworkshops: 'Nos premiers ateliers',
    txt_global_std_Insurance_Text11: 'Vid eventuella frågor kring din betalning är du välkommen att kontakta vår supportavdelning. E-post: info@enerfy.se. Telefon: 08 - 555 932 00.',
    txt_global_std_connect: 'Relier',
    txt_global_std_p2pfaq11q: 'Comment puis-je inviter mes amis à Warikan Kinto?',
    txt_global_std_sparet: 'Épargne',
    txt_global_std_connecttoobdreader: 'Se connecter au lecteur OBD',
    txt_global_std_drives: ' conduites',
    'txt_global_std_timein{state}': ' Heure {state}',
    txt_global_std_bekindtotheenvironment: 'Soyez gentil avec l&#39;environnement.',
    txt_global_std_noimageuploaded: 'No image uploaded',
    'txt_global_std_Paired your OBD reader to your phone via bluetooth.': 'Paired your OBD reader to your phone via bluetooth.',
    txt_global_std_2minutesagoshorthand: 'il y a 2 m',
    txt_global_std_buy: 'Buy',
    txt_global_std_thetestbuildofenerfydoesnotsupportanonymoususersatthistime: 'The build of {appName} does not support anonymous users at this time',
    txt_global_std_selectthetypeofinjurythatyouwishtoregisterbelowandfollowtheinstructions: 'Select the type of injury that you wish to register below and follow the instructions',
    txt_global_std_earnpointsandachievethenextlevel: 'Gagnez des points et atteignez le niveau suivant!',
    txt_global_std_avoidhardacceleration: ' Évitez les accélérations brusques',
    txt_global_std_deleteachivementpopuptext: 'Votre réussite sera définitivement supprimée. La progression de vos utilisateurs sera perdue. Ceux qui ont déjà reçu le succès pourront toujours le voir.',
    txt_global_std_notes: ' Notes',
    txt_global_std_writeyourmessagehere: 'Write your message here',
    txt_global_std_head2headchallenge: 'Head 2 Head Challenge',
    txt_global_std_yourrequestedauthenticationcode: "Votre code d'authentification demandé :",
    txt_global_std_leveloverview: 'Aperçu des niveaux',
    'txt_global_std_t brikken': ' Informations de contact',
    txt_global_std_cropyourbackground: ' Rogner votre arrière-plan',
    txt_global_std_changepaymentpopuptitle: 'Change card details for {insuranceNumber}',
    txt_global_std_vehicles: 'Vehicles',
    txt_global_std_yourein: ' Ça roule !',
    'txt_global_std_countryroadcatch-up2DESC': 'Le conducteur de la voiture rouge s´approche à grande vitesse et balance la voiture devant le conducteur. Le conducteur de la voiture bleue utilise une allure plus lente et conduit de manière concentrée et en douceur.',
    txt_global_std_howdoyoumeasuremydriving: 'Comment mesurez-vous ma conduite?',
    txt_global_std_Gdpr_Text4: 'Supprimer le compte en cliquant sur le lien ci-dessous:',
    txt_global_std_managegroups: 'Gérer les groupes',
    txt_global_std_InsuranceOwner: 'Försäkringstagaren',
    txt_global_std_purpose: 'Purpose',
    txt_global_std_unlockedtitle: 'Débloqué',
    txt_global_std_vandalism: 'Vandalism',
    txt_global_std_miscdamage: 'Misc damage',
    txt_global_std_encouragelowriskdrivingviaapotentialsavingspertrip: 'Encourager la conduite à faible risque via une économie potentielle par trajet',
    txt_global_std_actiontimestamp: "Le temps d'action",
    txt_global_std_NotCreatedAccount3_Text2: 'Experience the benefits of Enerfy - Insurance with Moderna by activating the insurance and create an account in the Enerfy app in your smartphone.',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsaboutthecovercubeappandcompeteagainstthemforlowerscores: "R : Une fois que vous avez associé l'application au Bluetooth de votre véhicule et autorisé l'application à utiliser les services de localisation, nous faisons correspondre vos habitudes de conduite à la bibliothèque de modèles de conduite de notre IA et vous fournissons un score. Votre prime d'assurance dépend de la personne qui conduit votre véhicule et de sa qualité de conduite. Quiconque conduit votre véhicule est encouragé à télécharger l'application et à suivre ses scores. Parlez de l'application Covercube à vos amis et affrontez-les pour obtenir des scores inférieurs.",
    txt_global_std_accountremoval: ' Supprimer le compte',
    txt_global_std_carsharingemptyviewlent: 'Du har inte lånat någon bil än. Dina körningar kommer sammanställas direkt i appen.',
    txt_global_std_p2pifyouwanttoupdatetheemailusedasusername: "Si vous souhaitez mettre à jour l'email utilisé comme nom d'utilisateur,",
    txt_global_std_thismaytakeafewseconds: 'Cela peut prendre quelques secondes.',
    txt_global_std_youhavebeenchallengedby: 'Vous avez été contesté par',
    txt_global_std_bonusactivatedtext: 'Så gøy at du har fått deg Sidekick bilforsikring! Du kan hele tiden se status over belønningene dine i sparegrisen.',
    txt_global_std_smartdriving: 'Conduite "smart"',
    txt_global_std_preparingsomethings: 'Préparer des choses ...',
    txt_global_std_wecannotfindthecaridentityselectregno: ' Sélectionnez la plaque d’immatriculation du véhicule que vous conduisez.',
    txt_global_std_weareherforeyoucontact: ' Nous sommes là pour vous aider. N’hésitez pas à nous contacter !',
    txt_global_std_focustips: "Gardez les yeux sur la route et concentrez-vous entièrement sur la conduite. Parfois, il est facile d'être distrait par l'environnement, les téléphones, les personnes dans la voiture, etc.\n" +
      '\n' +
      'Conduire fatigué ou stressé peut affecter la concentration et vous exposer à un risque plus élevé.\n' +
      '\n' +
      'Nous nous soucions de vous.',
    txt_global_std_gas: ' Essence',
    txt_global_std_qhowcaniimprovemyscore: 'Q : Comment puis-je améliorer mon score ?',
    txt_global_std_whenwouldyouliketoterminateyourinsurance: 'When would you like to terminate your insurance?',
    txt_global_std_somefacts: 'Faits marquants',
    txt_global_std_TerminationConfirm_Text2: 'has been terminated from',
    txt_global_std_WorstGroups_Text2: 'Sincerely,\r\n{appName}',
    txt_global_std_MSIG_InsuranceLetter24: 'Limites d&#39;utilisation *',
    txt_global_std_paymentpalndoesnotexistonthisaccountgotoeventcreatorandcreateaddpaymentplan: "Le plan de paiement n'existe pas sur ce compte. Accédez au créateur de l'événement et créez un plan de paiement supplémentaire.",
    txt_global_std_ReminderMail: ' Êtes-vous prêt à participer au test de conduite FIA SDC ?',
    txt_global_std_Reciept: 'Reciept of your payment',
    txt_global_std_Adjust_Text13: 'Har du bruk for nærmere forklaring, kontakt oss via chat i Sidekick-appen eller ring 04040.',
    txt_global_std_Insurance_PaymentMethod2: 'Choice of payment',
    txt_global_std_kintoImprovement2: 'Hé - nous nous soucions de vous! Allez-y doucement la prochaine fois!',
    txt_global_std_Insurance_Text8: 'Genom att tänka på hur du kör kan du få en lägre rörlig körpremie. Ju lägre premienivå dina körningar klassas in i, desto mer spar du på dina enskilda körningar. Kör smart för att spara pengar och få en låg premiekostnad.',
    txt_global_std_yourinsurancehasbeencanceled: 'Votre assurance a été annulée',
    txt_global_std_base: 'Base',
    txt_global_std_petrol: ' Essence',
    txt_global_std_endtour: 'Fin de la visite',
    txt_global_std_carregistrationnumber: "Numéro d'immatriculation de la voiture",
    txt_global_std_starttime: ' Heure de début',
    txt_global_std_who: 'Qui',
    txt_global_std_rwmcontinue: 'Continuez',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedthisbadge: 'Vous avez vraiment bien conduit! Votre {scoretype} moyen était meilleur que {score} pendant une semaine! Vous avez gagné ce badge!',
    txt_global_std_2faenabled: 'Deux facteurs activés',
    txt_global_std_p2pborrowacar: 'Emprunter un {carname}',
    txt_global_std_youalwaysstarttheinsuranceyearwithnok1000inthepiggybankyouwillbepaidtheseifyouhavebeenasafedriverthroughouttheinsuranceyearifyouchoosetoterminatetheinsurancebeforetheendoftheyearacalculationwillbemadeonthebasisoftheperiodyouhadtheinsurance: "Vous commencez toujours l'année d'assurance avec 1000 NOK dans la tirelire. Vous serez payé si vous avez été un conducteur prudent tout au long de l'année d'assurance. Si vous choisissez de résilier l'assurance avant la fin de l'année, un calcul sera effectué sur la base de la période d'assurance.",
    txt_global_std_jpfaqanswer: '1. 画面下のメニューバーから「接続」を選択します。\n' +
      '2. Bluetooth、位置情報サービス、モーション\n' +
      'フィットネスの設定を許可してください。位置情報\n' +
      'サービスは、正確なデータ取得のために「常に」\n' +
      'を選択してください。\n' +
      '3. エンジンをスタートさせたら、Bluetoothを通して\n' +
      'クルマとアプリを接続してください。アプリ上に\n' +
      'クルマの名前が表示されたら「クルマを選択」を選択します。\n' +
      '4. 走行を開始すると、リアルタイムで運転に対する\n' +
      'フィードバックを受け取ることができます。\n' +
      '5. 運転が終了すると、走行データは自動的にアプリ\n' +
      'に保存されます。',
    txt_global_std_manydriversrelytooheavilyoncruisecontrolitiscomfortableyesbutlethalforfueleconomyinmanycasesusingcruisecontrolontheslightestuphillordownhillwillcausetheenginetofighthardtosatisfyyourwishofmaintainingtheexactspeedyousetinsteadmanagingyourspeedwithyourfootandaimingforasmoothridewillhelpyousaveahugeamountonfuelnew: "De nombreux conducteurs comptent trop sur le régulateur de vitesse. C'est confortable, oui, mais mortel pour l'économie de carburant dans de nombreux cas. L'utilisation du régulateur de vitesse à la moindre montée ou descente obligera le moteur à se battre pour satisfaire votre souhait de maintenir la vitesse exacte que vous avez définie. Au lieu de cela, gérer votre vitesse avec votre pied et viser une conduite en douceur vous aidera à économiser énormément de carburant !",
    txt_global_std_leaderboardisempty: ' Le classement est vide',
    txt_global_std_tttest78: 'test\ncomme',
    txt_global_std_pleasecompletethesesteps: ' Veuillez compléter ces étapes',
    txt_global_std_InsuranceMail_Text1: 'Price per level (sek/km) - Variable driving cost',
    txt_global_std_rwmsheettitle: 'Invite un ami',
    txt_global_std_points: 'Score',
    txt_global_std_nofriendsordteamsinvited: 'No friends or teams invited',
    txt_global_std_odoends: 'Odo. End',
    txt_global_std_dontforgettocheckfeedback: 'N&#39;oubliez pas de consulter vos commentaires pour commencer à améliorer votre conduite.',
    txt_global_std_youwillbenotifiedintheappwhenitstimetorenewyourinsuranceifyoudonthaveautomaticrenewalsetupfollowthestepsintheapptoensureyouhavecoveragefornextmonth: "Vous serez averti dans l'application lorsqu'il sera temps de renouveler votre assurance. Si vous n'avez pas configuré le renouvellement automatique, suivez les étapes de l'application pour vous assurer d'avoir une couverture pour le mois prochain.",
    txt_global_edj_periodstart: 'Period start',
    txt_global_std_keepgooddistancebetweencars: ' Maintenez la distance de sécurité',
    txt_global_std_ActivationLetterRedLine: 'Activation Moderna Smart Flex',
    txt_global_std_unit: ' Unité',
    txt_global_std_JyrkiainenBesttip: 'Be patient and proactive. Follow surroundings and traffic. It’s also good to check the fuel consumption. ',
    txt_global_std_personaldatapreferences: ' Préférences de données personnelles',
    txt_global_std_FirstDrive_Hello: 'Hello',
    txt_global_std_p2pwecouldnotfindanybookingchatswhenyoucreateabookingyouwillbeabletochataboutthatbookinghere: "Nous n'avons trouvé aucun chat de réservation. Lorsque vous créez une réservation, vous pourrez discuter de cette réservation ici.",
    txt_global_std_adressline1: 'Adresse ligne 1',
    txt_global_std_PaddonWhyjoin: "In our team you will win! I'm a very competitive person and will do all I can to work with all our team mates to ensure we put our best foot forward. While this will be a great competition it’s important not to forget the important messages, this challenge carries on. We're in to strive for being safer and more complete drivers, something we all can share and inspire many more people to be.",
    txt_global_std_downloadtheappnameapptodrivemycar: "Téléchargez l'application {appname} pour conduire ma voiture",
    txt_global_std_p2pwecouldnotfindyou: "Nous n'avons pas pu vous trouver",
    txt_global_std_enablemotionfitnesstogetsupportforstartingandendingyourtripsautomatically: "Activez le mouvement et la forme physique pour obtenir de l'aide pour démarrer et terminer vos voyages automatiquement.",
    txt_global_std_pushinsurancerenewalodorembody: 'Votre période d&#39;assurance arrive à son terme et sera renouvelée. Vous devez mettre à jour votre valeur d&#39;odomètre.',
    txt_global_std_p2ppushtitleaddadmin: 'Droits d&#39;administrateur ajoutés',
    txt_global_std_p2ppushbodyexpensesapproved: '{name} approved your added expenses.',
    txt_global_std_BeforeBill_Text1: 'Vid månadsskiftet kommer vi att dra pengar för din bilförsäkring hos Enerfy - Försäkring med Moderna. Vill du veta hur mycket du har kört för hittills den här månaden kan du gå in på ”Mer” i {appName}appen och klicka på ”Kostnad”. Där kan du se hur mycket du har kommit upp till hittills för den här månaden.',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint2: 'Évaluez le risque de conduite en fonction des lieux de conduite réels, et non du domicile ou du lieu de travail',
    txt_global_std_iamapassenger: ' Je suis passager',
    txt_global_std_ongoing: 'En cours',
    txt_global_std_kintoEncouragement3: 'Bon travail! Votre dernier voyage a été meilleur que la moyenne de Kinto!',
    txt_global_std_areyousureyouwanttoredeempoints: 'Êtes-vous sûr de vouloir échanger des points ?',
    txt_global_std_pwdfiveandtwice: 'Votre mot de passe doit contenir au moins 5 caractères et être renseigné deux fois.',
    txt_global_std_selectdamagereport: 'Select Damage Report',
    txt_global_std_Insurance_Address: 'Address',
    txt_global_std_autojoinchallenges: 'Rejoindre automatiquement les défis',
    txt_global_std_driverimprovement: 'Driver improvement',
    txt_global_std_noreviewsselectedtobepublished: 'Aucun avis sélectionné pour être publié !',
    txt_global_std_namesmayonlyincludelettersandspaces: 'Les noms ne peuvent inclure que des lettres et des espaces.',
    txt_global_std_kintofaq2q: 'Comment bien conduire?',
    'txt_global_std_l/100km': 'l/100km',
    txt_global_std_p2pyesdelete: 'Oui, supprimer',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimedecreasingspeedleavingasafefollowingdistanceenablesyoutodecreaseyourspeedsteadilyandsmoothly: 'Sur ce type de trajet, le champion écolo moyen passe plus de temps à réduire sa vitesse. Laisser une distance de suivi de sécurité vous permet de diminuer votre vitesse régulièrement et en douceur.',
    txt_global_std_ecotips: 'Votre éco-score mesure à quel point vous conduisez écologique. De bien des façons, cela peut être lié à votre planification.\n' +
      '\n' +
      "Ajustez vos accélérations pour éviter les freins et les arrêts inutiles. Chaque accélération et chaque frein consomment de l'énergie.\n" +
      '\n' +
      "Ensemble, nous pouvons faire une différence pour l'avenir.",
    txt_global_std_nicestreak: 'Belle séquence!',
    txt_global_std_RecieptMail_Text5: 'until',
    txt_global_std_Insurance_CostInsurance: 'Sum insurance',
    txt_global_std_finalizepersonalinformation: 'Finaliser les informations personnelles',
    txt_global_std_doyouwantenerfyinsurance: 'Want to sign up for insurance?',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint2: 'Connectez-vous avec les utilisateurs sur leurs canaux préférés',
    txt_global_std_downloadthefiasdcappbyclickingthelinkonyourmobilephone: "Téléchargez l'application FIA SDC en cliquant sur le lien sur votre téléphone mobile",
    txt_global_std_presstoloadmore: ' Appuyer pour charger davantage',
    txt_global_std_alertweredrivingyoudrivingtitle: 'Conduisais-tu ?',
    txt_global_std_awardedbadges: 'Insignes décernés',
    txt_global_std_selectwhereweshouldlookforyourfriends: 'Select where we should look for your friends.',
    txt_global_std_hereyouloweryoursubscriptioncostbydrivingsafe: 'Ici, vous réduisez le coût de votre abonnement en conduisant en toute sécurité.',
    txt_global_std_damagedetails: 'Damage details',
    txt_global_std_activateinsurance: 'Activate insurance',
    txt_global_std_PreSaleDetails_Text9: 'The insurance applies to those who are insured, but only if you are the actual civil owner and main user of the vehicle. This means that you can be withheld compensation if you have registered as the owner and have taken out insurance for a vehicle for which you are not the actual owner or principle user.',
    txt_global_std_settingupprofile: 'Mise en place du profil',
    txt_global_std_bonuscash: 'Bonus cash',
    txt_global_std_completeinfoandcreateaccount: 'Compléter les informations et créer un compte',
    txt_global_std_InsuranceMail_Text16: 'Rescue',
    txt_global_std_kintofaq1q: 'Dois-je activer l&#39;application à chaque fois que je conduis?',
    txt_global_std_sendreport: 'Send report',
    txt_global_std_yourmessagehasbeensent: 'Votre message a été envoyé!',
    txt_global_std_enterregistrationnumber: 'Enter registration number',
    txt_global_std_here: 'here',
    txt_global_std_choosingbalwaysallowbisnecessarytogetcorrectstatsaboutyourdriving: 'Le choix de <b> Toujours autoriser </b> est nécessaire pour obtenir des statistiques correctes sur votre conduite.',
    txt_global_std_butyougetagreatoverviewofyoutrips: 'But you get a great overview of your drives here when driving with {appName}s insurance.',
    txt_global_std_ayesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforagentinsurance: `R : Oui, vous pouvez télécharger l'application et choisir l'option "essayer l'application". Vous pourrez conduire avec l'application et voir combien vous pourriez économiser en souscrivant à l'assurance {agent}.`,
    txt_global_std_servicedandsoldby: 'Entretenu et vendu par',
    txt_global_std_p2pwhenyougiveyourfriendadminrightstheycanviewandhandlebookingsofyourcar: "Lorsque vous donnez à votre ami les droits d'administrateur, il peut afficher et gérer les réservations de votre voiture.",
    txt_global_std_mydrivingstats: 'Mes statistiques de conduite',
    'txt_global_std_Click the plus sign to create a new challenge.': ' Appuyez sur le signe + pour créer un nouveau défi',
    txt_global_std_alreadyhaveacovercubepolicy: 'Vous avez déjà une police Covercube ?',
    txt_global_std_TidemandWhyjoin: 'Because together we are unbeatable and always aim for the top! ',
    txt_global_std_PaddonMileage: 'I love driving on the back-country roads of New Zealand and exploring new places. The west coast of the South Island is a very special place, as you feel so isolated at the same time drive on amazing twisty scenic roads.',
    txt_global_std_WelcomeLetter_Text5: 'Cordialement',
    txt_global_std_kintofaq8a: 'Des économies sont générées lorsque vous conduisez prudemment. Pour chaque trajet que vous conduisez en toute sécurité, vous générez une économie au kilomètre. Plus vous conduisez en sécurité, plus vous économisez! L&#39;économie est visible directement après la fin de votre voyage et sera visible dans votre portefeuille Kinto.',
    txt_global_std_accident: 'Accident',
    'txt_global_std_1) Open Settings on your phone.': '1) Open Settings on your phone.',
    txt_global_std_enerfycoachtip2: ' 2. Maintenez les distances de sécurité avec la voiture qui vous précède, même à vitesse réduite.',
    txt_global_std_learnmoreaboutpermissions: 'En savoir plus sur les autorisations',
    txt_global_std_rwmyoucanonlyregistertripsmadewithinthelastday: 'Vous ne pouvez enregistrer que les trajets effectués au cours de la dernière journée',
    txt_global_std_nooftrips: 'Nbre de trajets',
    txt_global_std_p2pongoing: 'En cours',
    txt_global_std_enerfyscore: 'Enerfy score',
    txt_global_std_p2pbooking: 'Réservation',
    txt_global_std_setuplocationservices: 'Configurer les services de localisation',
    txt_global_std_enablethephonesbluetooth: "1. Enable the phone's Bluetooth",
    txt_global_std_cancel: ' Annuler',
    txt_global_std_BeforeBill_Text2: 'Vill du inte öka månadens kostnad kan du välja att låta bilen stå stilla för resten av månaden eller tänka på att köra smart för att hålla kostnaden låg.',
    txt_global_std_startadrivetoraiseyourposition: 'Start a drive to raise your position',
    txt_global_std_VilliersChamp: '4x champion du SA Touring Car, 7x au podium du Dakar, 1 victoire du Dakar, 3 victoires du Rallye du Maroc, Gagnant du Rallye du Portugal en 2008, Gagnant du Rallye du Brésil en 2008',
    txt_global_std_p2psafedriverdiscount: 'Rabais Safedriver',
    txt_global_std_setupcomplete: 'Configuration terminée !',
    txt_global_std_enablemotionfitness: 'Activez Motion & Fitness sur votre téléphone.',
    txt_global_std_Adjust_Text6: 'til',
    txt_global_std_gettingtheappready: "Préparation de l'installation",
    txt_global_std_TomczykMusic: 'What I listen to when driving depends on my mood.  I like hardrock a lot, like Dio and Manowar, but I also listen to current music charts.',
    txt_global_std_next: 'Next',
    txt_global_std_youhavealreadyorderedanobdreader: 'Vous avez déjà commandé un lecteur OBD',
    txt_global_std_loyaltylevelrewards: 'Ce niveau de fidélité vous donne droit à une {reward}',
    txt_global_std_theenerfyteam: "L'équipe Energy",
    txt_global_std_invitetherestofyourteam: 'Invitez le reste de votre équipe',
    txt_global_sidekick_invitettitle: 'Invite to Sidekick',
    txt_global_std_InsuranceMail_Text14: 'Machine over 6000 swedish miles',
    txt_global_std_connecttothecar: 'Connect to the car',
    txt_global_std_RecieptMail_Text9: 'SHOP SAFETLY WITH ENERFY',
    txt_global_std_teams: ' Équipes',
    txt_global_std_joinchallenge: ' Rejoindre un défi',
    txt_global_std_coach: ' Coach',
    txt_global_std_appcreator_upsell_pitch: 'Créez et personnalisez votre propre application de conduite, sans codage nécessaire, pour ;',
    txt_global_std_pleasejoinourteam: 'Please join our team!',
    txt_global_std_c02overview: 'Aperçu CO₂',
    txt_global_std_youhavenowdriven25tripswithtrygdrive: 'Vous avez désormais effectué 25 trajets avec Tryg Drive',
    txt_global_std_thankyou: 'Je vous remercie!',
    txt_global_std_gotoyourpreferredservicecenterandshowthemthispopup: 'Accédez à votre centre de service préféré et montrez-leur cette fenêtre contextuelle.',
    txt_global_std_PreSaleDetails_Text24: 'Fire',
    txt_global_std_mystats: 'Mes stats',
    txt_global_std_p2pamountshouldbeshownonthereceipt: '* Le montant doit être indiqué sur le reçu.',
    txt_global_std_p2pviewexpenses: 'Voir les dépenses',
    txt_global_std_cimage11d: ' Coupez toujours le moteur lorsque vous êtes au point mort',
    txt_global_std_p2pemailbestregards: 'Meilleures salutations,<br> l&#39;équipe {appname}',
    txt_global_std_p2ppleaseuploadareceiptpicture: 'Veuillez télécharger une photo du reçu',
    txt_global_std_wecannotfindthecaridentityenteractivationcode: 'We cannot find the car identity, enter the activation code you have in the welcome mail.',
    txt_global_std_onboardingnotificationsheader: 'Notifications',
    txt_global_std_takethefootofthepedalandletthecarcoastasmuchasitmakesensesavesfuel: 'Prenez le pied de la pédale et laissez la voiture rouler autant que cela a du sens, économisez du carburant.',
    txt_global_std_smartbonus: 'Smart bonus',
    txt_global_std_enginestall: 'Engine stall',
    txt_global_std_sonicethatyoudecidedtojoinusletssetupyourprofile: 'Génial que vous avez décidé de nous rejoindre! Permettez-nous de configurer votre profil.',
    txt_global_std_mystatstext: 'Mes stats',
    txt_global_std_successfullyupdatedtranslation: 'Traduction mise à jour avec succès',
    txt_global_std_nice: ' Parfait !',
    txt_global_std_origin: ' Commencement',
    txt_global_std_p2pemailnewsavailableinappcarSharing: 'Actualités disponibles dans {appname} - Car Sharing',
    txt_global_std_yourruns: 'Your trips',
    txt_global_std_businessb: 'B',
    txt_global_std_automessage_level_4__3: 'Good driving!',
    txt_global_std_inviteusers: 'Inviter des utilisateurs',
    txt_global_std_choosethetypeofbusinessthatdescribesyourcompanythebest: "Choisissez le type d'entreprise qui décrit le mieux votre entreprise.",
    txt_global_std_polish: 'polonais',
    txt_global_std_startdrive: 'Commencer à conduire',
    txt_global_std_icannotconnect: 'Je ne peux pas se connecter?',
    txt_global_std_gameonyourchallengehasbeenaccepted: 'Jeu sur! Votre défi a été accepté !',
    txt_global_std_earnsavingsintoyourkintowalletforsafedriving: 'Gagnez des économies dans votre portefeuille kinto - pour une conduite en toute sécurité:',
    txt_global_std_p2pmaximum9999999allowedforanexpense: 'Maximum {currency} 9 999 999 autorisé pour une dépense',
    txt_global_std_100kmlockedkey: 'Conduisez une distance de 100 km pour gagner ce badge.',
    txt_global_std_challenge: ' Défi',
    txt_global_std_dragme: ' Déplacez-moi !',
    txt_global_std_namesmayonlyincludeletters1: 'Les noms ne peuvent contenir que des lettres.',
    txt_global_std_Fia_Receipt_Text17: 'Greater Than, Karlavägen 60, 11449 Stockholm Sweden. Organisation number: 556608-3258',
    txt_global_std_PolicySchedule25: 'Total dû',
    txt_global_std_p2padditionalexpense: 'Dépense supplémentaire',
    txt_global_std_preferredrequirementaccuracy: "Précision d'exigence préférée",
    txt_global_std_signupandsavemoneyonyourinsurance: 'Inscrivez-vous et économisez de l&#39;argent sur votre assurance',
    txt_global_std_search: ' Chercher',
    txt_global_std_p2pfaqcontactinfo2: "・ Veuillez noter qu'il peut être difficile de se connecter lorsqu'il y a beaucoup de monde.",
    txt_global_std_emailhasbeensent2: 'E-mail envoyé!',
    txt_global_std_communication_upsell_pitch: 'Concevez facilement votre propre calendrier de messagerie et de communication pour;',
    txt_global_std_endday: 'Jour de fin',
    txt_global_std_pointstounlock: 'points à débloquer.',
    txt_global_std_ifyouhaveaninsuranceyoucanfindyourpolicynumberinyourappnameaccountdonthaveitathandrightnownoworriesyoucanadditlaterinsettings: 'Si vous avez une assurance, vous pouvez trouver votre numéro de police dans votre compte {appname}.\n' +
      "Vous ne l’avez pas sous la main en ce moment? Pas de soucis! Vous pouvez l'ajouter plus tard dans les paramètres.",
    txt_global_std_allmonth: ' Tout le mois',
    txt_global_std_removefriend: 'Remove friend',
    txt_global_std_ChicheritChamp: 'Coming soon...',
    txt_global_std_gettheapp: "Obtenir l'application",
    txt_global_std_everymonth: ' Mensuel',
    txt_global_std_youaredriving: ' Vous êtes en train de conduire',
    txt_global_std_secondarycolor: 'Couleur secondaire',
    txt_global_std_cimage11h: ' COUPEZ LE MOTEUR',
    txt_global_std_latest10: ' 10 derniers',
    txt_global_std_p2papprove: 'Approuver',
    txt_global_std_yourdatais: 'Vos données sont =',
    txt_global_std_BillMail_Text6: 'For invoice specifications for the next period, please see the attached document. ',
    txt_global_std_numberofrepetitions: 'Nombre de répétitions',
    txt_global_std_warikan_onboarding2_2: 'この度は、「わりかんKINTO」アプリのご利用お申し込み、誠にありがとうございます。\n。',
    txt_global_std_BillFirstBuy: 'Invoice from {appName}',
    txt_global_all_sunday: ' Dimanche',
    txt_global_std_authenticating: 'Authentification',
    txt_global_std_othervehiclesinvolved: 'Other vehicles involved?',
    txt_global_std_HusseinAbdullaChamp: 'Couronné champion du monde de la FIA T2 en 2016',
    txt_global_std_nodataavailable: 'Pas de données disponibles',
    txt_global_std_signinginwiththirdpartyname: ' Ouvrir une session avec {thirdPartyName}...',
    txt_global_std_invitemoremenutitle: 'Invite a friend',
    txt_global_std_fiaquarterfinalqualifyrule2020: 'Vous devez participer à au moins 4 manches pour vous qualifier pour la finale de qualification.',
    txt_global_std_RemoveAccount_text1: 'Hello\n' +
      'We have received your desire to delete your information in your account. You should be aware that deleted data can not be recovered. Your account and all data about you will be deleted, including driving records, teams, race dates and competitions.\n' +
      '\n' +
      'If you are an insurance customer, your information will not be deleted until 1 year after you have completed your insurance and all cases are closed. Deleting your account does not end your insurance.\n' +
      ' \n' +
      'Remove account by clicking on below link:\n' +
      '{{removelink}}\n' +
      ' \n' +
      'Do you have any questions please contact us at info@enerfy.se or 08-555 932 00',
    txt_global_std_cardwithmoreinformationaboutgdpr: "Carte avec plus d'informations sur GDPR",
    txt_global_std_NotCreatedAccount2_Text2: 'Download the {appName} app in your smartphone to create an account and type in your personal verification code:',
    txt_global_std_bluetoothsystem: 'Système Bluetooth',
    txt_global_std_nophotosadded: 'Aucune photo ajoutée',
    txt_global_std_RecieptMail_Text2: 'Starter pack',
    txt_global_std_TermConfirmPlugin_Text4: 'Even if you have terminated your car insurance, you can still use our other services with the {appName} Plug-in, like the driving journal, Find your car and use our driving coach.',
    txt_global_std_SignupFia_Text7: ' Téléchargez l’application !',
    txt_global_std_PaymentTrouble2_Text2: 'We are still unable to process your payment of',
    txt_global_std_fiasdcsmartscore: 'Score FIA SDC',
    txt_global_std_Insurance_Chat: 'Chat',
    txt_global_std_youcanaddtranslationbyclickinghere: 'Vous pouvez ajouter une traduction en cliquant ici.',
    txt_global_std_cwelcome14: 'Running out today? Go for a high driving score!',
    'txt_global_std_de-villiersDescription': 'Giniel De Villiers has never retired. Even better, only once has he not finished in the Top 10 (in 2007). He has 14 stage victories and in 2009 he won the first Dakar contest in South America.',
    txt_global_std_digitaltraining: 'Formation numérique',
    txt_global_std_valuedisconnctedtripsofmaxmaxvalue: '{value}% trajets interrompus (sur max {maxvalue}%)',
    txt_global_std_energysaved: 'Énergie économisée',
    txt_global_std_reactivateyourinsurancetokeepyourcoverage: 'Réactivez votre assurance pour conserver votre couverture.',
    txt_global_std_theoreticaltimesaving: ' Gain de temps théorique',
    txt_global_std_splits: ' Séparations',
    txt_global_std_MSIG_Onboarding10: 'Avez-vous des questions? Contactez notre équipe d&#39;assistance au 0123456789 ou envoyez-nous un courrier électronique à l&#39;adresse info@msfc.com.sg.',
    txt_global_std_incorrectpassword: 'Mot de passe incorrect',
    txt_global_std_youhavebeeninvitedtojoin: 'Vous avez été invité à rejoindre :',
    txt_global_std_finishingyourscore: ' Calcul de votre score.',
    txt_global_std_requestssent: ' Demande envoyée',
    txt_global_std_companychallengename2: "Nom du défi de l'entreprise",
    txt_global_std_pleaseseethefinishedreport: 'Veuillez consulter le rapport final sur',
    txt_global_std_creationdate: 'Date de création',
    txt_global_std_p2pfaq10a: "Veuillez visiter https://kinto-jp.com/insurance/ pour plus d'informations.",
    txt_global_std_NotCreatedAccount1_Text9: 'Venlig hilsen {appName}-Teamet',
    txt_global_std_policyholder: 'Assuré',
    txt_global_std_rwmnoeligibletripstitle: "Nous n'avons trouvé aucun voyage au cours de la dernière journée.",
    txt_global_std_myscore: 'Mon score',
    txt_global_std_riskindex_upsell_pitch: 'Transformez les connaissances en action grâce à cet outil sophistiqué combinant actualités, données et analyse des risques pour identifier ;',
    txt_global_std_shootingstarlockedkey: 'Terminez un entraînement au niveau 3.3 ou mieux pour débloquer ce succès et gagnez 100 points sur votre portefeuille Kinto.',
    txt_global_std_p2papply: 'Appliquer',
    txt_global_std_termsofservice: ' Conditions d’utilisation et politique de confidentialité',
    txt_global_std_message: 'Message',
    txt_global_std_youneedtoaddatleast8characters: 'Vous devez ajouter au moins 8 caractères. Incluant 1 minuscule (az), 1 majuscule (AZ) et 1 chiffre (0-9).',
    txt_global_std_savedraft: 'Save draft',
    txt_global_std_reallynicerun: 'Vraiment belle course',
    txt_global_std_changingyouremaildoesnotchangeyourusername: 'Modifier votre email ne modifie pas votre nom d’utilisateur',
    txt_global_std_influencer: 'Influenceur',
    txt_global_std_agreementcreated: 'Accord créé',
    txt_global_std_PaymentTrouble1_Text3: 'SEK for your car insurance.',
    txt_global_std_orderobddeviceto: 'Commandez un appareil OBD à :',
    txt_global_std_unabletoloadfriendsuggestions: 'Unable to load friend suggestions',
    txt_global_std_p2pfaq19q: 'Il y a des sections où les données de conduite ne sont pas enregistrées.',
    txt_global_std_unabletoloadteammembers: 'Unable to load team members',
    txt_global_std_letsdrivewithwarikankintopushbody: "Conduisons avec Warikan KINTO et accumulons des badges et des points ! Les points pourront être échangés contre des cadeaux ! La fonction de scoring et les acquisitions de badges sont disponibles même si vous n'êtes pas utilisateur de KINTO ONE.",
    txt_global_std_analytics: 'Analytique',
    txt_global_std_p2pforgotwhereyouparkedyourcarnoworrieswegotyoucoveredjustpressthisbuttonandwewillfinditforyou: 'Vous avez oublié où vous avez garé votre voiture? Pas de soucis, nous sommes là pour vous. Appuyez simplement sur ce bouton et nous le trouverons pour vous!',
    txt_global_std_kintofaq5a: 'Informez l&#39;équipe Kinto par e-mail. Puis reconnectez-vous à votre nouvelle voiture en suivant à nouveau les étapes «Se connecter à la voiture».',
    txt_global_std_putphoto: 'Put photo',
    txt_global_std_unabletosendfriendrequests: 'Unable to send friend requests!',
    txt_global_std_NewCar: 'Ny bil på din {appName}-forsikring',
    txt_global_std_annualcarinsuranceprice: 'Prix annuel de l&#39;assurance automobile',
    txt_global_std_p2paddedmemberstothegroup: 'a ajouté des membres au groupe',
    txt_global_std_acceptlocationservices: 'Accepter les services de localisation',
    txt_global_std_youcancontactyourinsuranceagency: 'Vous pouvez contacter votre assureur.',
    txt_global_std_automessage_level_2__1: "Yeah! You have got a driving score on {score}. That's how it's done!",
    txt_global_std_savechangespopuptext: 'Vos modifications non enregistrées seront perdues. Enregistrer les modifications avant de fermer ?',
    txt_global_std_change: ' Changer',
    txt_global_std_whatwillhappenifidonotconnectmycar: 'What will happen if I do not connect my car?',
    txt_global_std_SendMyPosition_Text2: ' Cordialement, {appName}',
    txt_global_std_Intro_Text10: 'Steg 4: Venneverving',
    txt_global_std_tomasengequote: 'Si vous voulez atteindre vos objectifs, vous devez tout faire pour cela',
    txt_global_std_ayourscoreisbasedonthedrivingpatternsyoucreatewitheachtripconcentrateonyourdrivingskillstoimproveyourscoreswewillprovideahistoryofallyourtripssoyoucanseehowyouredoing: 'R : Votre score est basé sur les habitudes de conduite que vous créez à chaque trajet. Concentrez-vous sur vos compétences de conduite pour améliorer vos scores. Nous vous fournirons un historique de tous vos voyages afin que vous puissiez voir comment vous allez.',
    txt_global_std_PaymentTrouble3_Text8: 'If you have already paid your premium, you may disregard this message. Contact us if you have questions or need help with the payment of your insurance: 08-555 93 200.',
    txt_global_std_ifyouwanttolendyourcartoafriendyoushouldinviteyourfriendtotheappbytappingthebuttonbelowthisensuresyourcarisstilltrackedbyloyaltysystem: "Si vous souhaitez prêter votre voiture à un ami, vous devez inviter votre ami à rejoindre l'application en appuyant sur le bouton ci-dessous. Cela garantit que votre voiture est toujours suivie par le système de fidélité.",
    txt_global_std_TermConfirmSub_Text1: 'We hereby confirm that your car insurance for',
    txt_global_std_acceptconsent: 'Accepter le consentement',
    txt_global_std_bycontinuingyouaccept: ' En continuant, vous acceptez nos',
    txt_global_std_5000pointaverageachievementlocked: 'Conduisez avec un {scoretype) of {score} ou mieux pendant une semaine pour débloquer ce succès et gagner 5000 points.',
    txt_global_std_kintoquestions: 'Questions KINTO:',
    txt_global_std_co2besparelse: 'Co2 en parallèle',
    txt_global_std_wewillnotbeabletoanalyzeyourdriveand: 'We will not be able to analyze your drive and you will be billed at premium level 10.',
    txt_global_std_apitool_upsell_pitch_bulletpoint2: 'Transfert rapide et sécurisé des données du conducteur',
    txt_global_std_yourdatahasbeensent2: 'Vos données ont été envoyées, veuillez vérifier votre boîte de réception!',
    txt_global_std_HansenMusic: 'Listening to music while driving can take away a lot of focus. But getting out on highways and cruising, I truly enjoy listen to music that makes me engage and sing along.',
    txt_global_std_PaymentTrouble2_Text6: 'If you have already paid your premium, you may disregard this message. Do you have questions or need assistance with the payment? You are welcome to contact our customer service at: info@enerfy.se or 08 - 555 932 00.',
    txt_global_std_insureddistance_used_100__2: 'Ut! Öka årlig körsträcka här.',
    txt_global_std_uploadpicturestooldaccidentreport: 'Veuillez télécharger des photos de votre voiture dans les 24 heures suivant votre rapport. Vous pouvez cliquer sur votre ancien rapport ci-dessous pour y ajouter des photos.',
    txt_global_std_edituser: "Modifier l'utilisateur",
    txt_global_std_failedtosend: 'Failed to send',
    txt_global_std_opendocument: ' Ouvrir le document',
    txt_global_std_qdoihavetoactivatetheappeverytimeidrive: "Q : Dois-je activer l'application à chaque fois que je conduis ?",
    txt_global_std_orderobdreader: 'Commander un lecteur OBD',
    txt_global_std_friday: 'Vendredi',
    txt_global_std_Insurance_DayPremium: 'Daily premium',
    'txt_global_std_If you need more detailed information, use the back button to review the instructions again.': 'If you need more detailed information, use the back button to review the instructions again.',
    txt_global_std_otherdocuments: 'Autres documents',
    txt_global_std_insuranceisrenewedplaceholder: 'Il ne vous reste plus que 63 km.\n' +
      'Votre assurance est renouvelée 2021-10-29',
    txt_global_std_PaymentTrouble2_Text3: 'SEK for your car insurance.',
    txt_global_std_manageapikeys: 'Gérer les clés API',
    txt_global_std_zipcode2: 'Code postal',
    txt_global_std_minutesandcanonlybeusedonce: "minutes et ne peut être utilisé qu'une seule fois.",
    txt_global_std_couldnotconnect1: 'N&#39;a pas pu se connecter!',
    txt_global_std_brakevca: 'Frein V / C / A',
    txt_global_std_insurancestatus: "Statut d'assurance",
    txt_global_std_takecareoftheinjured: 'Prendre soin des blessés',
    txt_global_std_deactivateuserfrom: "Désactiver l'utilisateur de :",
    txt_global_std_Insurance_Specification: 'SPECIFICATION',
    txt_global_std_jointaveragescore: 'Score moy',
    txt_global_edj_stoptime: 'Stop time',
    txt_global_std_lastmonth: 'Last month',
    txt_global_std_receiveinvoicevialetter: 'Receieve invoice via letter',
    txt_global_std_duration: ' Durée',
    txt_global_std_tryglocationservice: 'Allow access to location - Select "Always"',
    txt_global_std_p2ppleaseselectpaymentmethod: 'Veuillez sélectionner le mode de paiement',
    txt_global_std_hellothisisanonboardingtext: "Bonjour, ceci est un texte d'intégration.",
    txt_global_std_manualstartstoprequiresaccess: "Le démarrage et l'arrêt manuels nécessitent l'accès aux services de localisation (GPS) et au mouvement et à la forme physique.",
    txt_global_std_yournumber: 'Ton numéro',
    txt_global_std_startcarengine: '1. Démarrez le moteur de votre voiture.',
    txt_global_std_iwanttotakeabreakin: 'I want to take a break in:',
    txt_global_std_yourfriendsemail: ' E-mail de vos amis',
    txt_global_std_theamountisenteredinthepiggybankwhentherecruitmentcodehasbeenregisteredyouhaveafulltimeoverviewofyourrecruitsonthesavingsside: "Le montant est inscrit dans la tirelire lors de l'enregistrement du code de recrutement. Vous avez une vue d'ensemble à temps plein de vos recrues du côté de l'épargne.",
    txt_global_std_p2pyouhavenocar: "Tu n'as pas de voiture",
    txt_global_std_gohome: 'rentrer chez soi',
    txt_global_std_connectingtocar: 'Connexion à la voiture',
    txt_global_std_TerminationMail_Text1Enerfy: "Your car insurance has expired as of today's date due to lack of payment.",
    txt_global_std_beonestepahead: 'Avoir une longueur d&#39;avance',
    txt_global_std_skillup: 'Augmenter de niveau',
    txt_global_std_TidemandChamp: 'Champion du WRC 2 en 2017 et du Touring Car en 2010',
    txt_global_std_cimage1h: ' RÉDUISEZ VOTRE VITESSE MAXIMALE',
    txt_global_std_ShareCar_Text12: 'Hälsningar,\r\n{appName}',
    txt_global_std_MSIG_Password2: 'Vous avez demandé un nouveau mot de passe pour votre compte MS First Capital. Votre nouveau mot de passe pour l&#39;utilisateur',
    txt_global_std_pleasenotethatifyouhavealargerproportionofdisconnectedtripsyoumaynotbepaidadrivingdividendremembertodrivewiththeappconnected: "Veuillez noter que si vous avez une plus grande proportion de trajets déconnectés, il se peut que vous ne receviez pas de dividende de conduite. N'oubliez pas de conduire avec l'application connectée.",
    txt_global_std_2pairyourphonewithyourcarviabluetooth: '2. Associez votre téléphone à votre voiture via Bluetooth.',
    txt_global_std_cimage14h: ' PROFITEZ DE LA DESCENTE',
    txt_global_std_TidemandMileage: "I prefer smaller roads with twists and turns surrounded by beautiful scenery compared to highways. It's more fun to drive and you never know what you might find after the next corner. Sweden or anywhere else in the world doesn't matter, discovering new roads is always fun.",
    txt_global_std_p2psplityourcostfor: 'Divisez votre coût pour',
    txt_global_std_category: 'Catégorie',
    txt_global_std_cantfindyourchallenge: 'Vous ne trouvez pas votre défi ?',
    txt_global_std_Head2Head_Text4: 'Sincerely,\r\n{appName}',
    txt_global_std_havefullcontrolofyoutrips: 'Have full control of your trips',
    txt_global_std_OSolbergWhyjoin: 'Coming soon...',
    txt_global_std_inspiringsmarterdrivinghelpsreducefueluseco2footprintandcrashes: "Inspirer une conduite plus intelligente aide à réduire la consommation de carburant, l'empreinte carbone et les accidents",
    txt_global_std_HusseinAbdullaMusic: 'Calm music',
    'txt_global_std_As a bonus, we have given you 1000 enerfy coins.': 'As a bonus, we have given you 1000 {appName} coins.',
    'txt_global_std_Al-KhelaifiBesttip': 'Commuting home after work.',
    txt_global_std_loading: 'Chargement...',
    txt_global_std_PersonInjuries: 'Personskador',
    txt_global_std_p2pnogoback: 'Non, reviens',
    txt_global_std_nov: ' Nov.',
    txt_global_std_drivenotregistered: 'Lecteur non enregistré',
    txt_global_std_exploretheapp: ' Découvrez l’application',
    txt_global_std_send: 'Send',
    txt_global_std_doyouwanttojointeamName: 'Do you want to join {teamName}?',
    txt_global_std_buttontext: 'Button text',
    txt_global_std_ridewithmesubtitle: 'Gagnez 300 points pour le covoiturage !',
    txt_global_std_login1: "S'identifier",
    txt_global_std_addImages: 'Add Images',
    txt_global_std_successfullycreatedgroup: 'Groupe créé avec succès',
    txt_global_std_privatep: 'P',
    txt_global_std_joinedteam: 'Joined team',
    txt_global_std_carinformation: 'Informations sur la voiture',
    txt_global_std_removealldatafromthisaccount: 'Supprimer toutes les données de ce compte?',
    txt_global_std_september: ' Septembre',
    txt_global_std_TomczykWhyjoin: "My goal is not just the obviously aim to win. It's also the fun part of joining in and fighting during the heats to become qualified as the best and smartest team.",
    txt_global_std_PreSaleDetails_Text52: 'With a comprehensive insurance, in addition to liability insurance and half insurance, you will also get protection for:',
    txt_global_std_ratedrivingriskbasedonactualdrivinglocationsnothomeorworkplace: 'Évaluez le risque de conduite en fonction des lieux de conduite réels, et non du domicile ou du lieu de travail.',
    txt_global_std_RecieptMail_Text12: 'INFORMATION',
    txt_global_std_june: ' Juin',
    txt_global_std_weliketorewardyousoeverytripyoudrivesafeyouwillearn: 'Nous aimons vous récompenser - ainsi, chaque voyage que vous conduisez en toute sécurité vous fera gagner une économie. Plus vous conduisez en sécurité, plus vous économisez! Les économies sont immédiatement collectées ici dans votre portefeuille.',
    txt_global_std_invitecoworkersandstartcompetingagainsteachothertobecomebetterdriversandsavemoreco2: 'Invitez des collègues et commencez à vous affronter pour devenir de meilleurs conducteurs et économiser plus de CO2.',
    txt_global_std_PSolbergInterests: 'Coming soon...',
    txt_global_std_tirerotationunload: 'Permutation des pneus, déchargement…',
    txt_global_std_p2ptounlockyouraccesstotheappandthebenefitsregisteratthewebsite: "Pour déverrouiller votre accès à l'application et aux avantages, inscrivez-vous sur le [Site Web]",
    txt_global_std_whatmusicdoyouliketolistentowhenyoudrive: 'Quel type de musique aimez-vous?',
    txt_global_std_yourinsurancewasautomaticallyrenewedformonthwithadiscountonyourpremium: 'Votre assurance a été renouvelée automatiquement pour {mois} avec une remise sur votre prime.',
    txt_global_std_thisisatestphraseforonboarding: "Il s'agit d'une phrase de test pour l'intégration.",
    txt_global_std_cimage29d: ' Lorsque la circulation le permet. Changez de rapport en deux étapes.',
    txt_global_std_issomeoneelsegoingtodriveyourcar2: "Est-ce que quelqu'un d'autre va conduire votre voiture ? Envoyez-leur un lien pour qu'ils puissent utiliser l'application.",
    txt_global_std_reportaccidentheresmile: 'Signalez les accidents ici! :-)',
    txt_global_std_Insurance_CustomerService: 'Customer Service',
    txt_global_std_locationaccess: "Accès à l'emplacement",
    txt_global_std_p2ppushtitlebookingcomingup: 'Réservation à venir',
    txt_global_std_whatifichangecarlexusanswer: 'Informez l&#39;équipe Lexus par e-mail. Reconnectez-vous ensuite à votre nouvelle voiture en suivant à nouveau les étapes «Se connecter à la voiture».',
    txt_global_std_p2pyoucanfindcarsrharingin: 'Vous pouvez trouver le partage de voiture dans le menu (trois points) ci-dessous.',
    txt_global_std_registerridesharingtrip: 'Enregistrer un trajet en covoiturage',
    txt_global_std_optional: 'Facultatif',
    txt_global_std_yourinsurancewasautomaticallyrenewedformonth: 'Votre assurance a été renouvelée automatiquement pour {month}.',
    txt_global_std_qhowdoisetupautomaticrenewal: 'Q : Comment configurer le renouvellement automatique ?',
    txt_global_std_exampleofvalidemailaddresses: ' Exemple d’une adresse mail valide :',
    txt_global_std_willbedeletedimmediatelyandpermanentlyoncedeleteditcannolongerbeusertomakeapirequests: 'seront supprimés immédiatement et définitivement. Une fois supprimé, il ne peut plus être utilisé pour faire des requêtes API.',
    txt_global_std_sds: 'Sport',
    txt_global_std_Signature_Text1: 'Med vänliga hälsningar',
    txt_global_std_WelcomeLetter_Text1: 'Thank you for choosing Enerfy - Insurance with Moderna. Our customers mean everything to us so we are very excited to have you a customer.',
    txt_global_std_pleasewarnmeifidrivefasterthan: 'Warn me if I drive faster than',
    txt_global_std_drivewellchallenge: 'Défi Conduisez bien',
    txt_global_std_Insurance_Text12: 'Vänliga hälsningar från oss på {appName}',
    txt_global_std_gotoaservicecentertoredeemyourrewards: 'Rendez-vous dans un centre de service pour échanger vos récompenses.',
    txt_global_std_removeuserfromgroup: "Supprimer l'utilisateur du groupe",
    txt_global_std_currentsdcleague: 'Current Sport league',
    txt_global_std_p2pto: 'à',
    txt_global_std_customemail: 'E-mail personnalisé',
    txt_global_std_p2ppending: 'En attente',
    txt_global_std_Adjust_Text5: 'Din faste månedspris vil bli endret fra',
    txt_global_std_youraveragescoreis: 'Votre score moyen est',
    txt_global_std_wehavetoaccesslocationservicesinthebackgroundwhile: 'Nous devons accéder aux services de localisation en arrière-plan pendant que vous vous concentrez sur la conduite. Lorsque cette fenêtre apparaît, veuillez sélectionner "Toujours autoriser".',
    txt_global_std_MSIG_InsuranceLetter28: 'JE CERTIFIE / nous certifions que la police à laquelle se rapporte le présent certificat est émise conformément aux dispositions de la loi sur les véhicules à moteur (risques liés à des tiers et indemnisation) (chapitre 189) et la partie IV de la loi de 1987 sur les transports routiers (Malaisie)',
    txt_global_std_avgrisklevel123: 'Moy. niveau de risque',
    txt_global_std_avgscore: 'Score moy',
    txt_global_std_p2pfaq29q: "S'il vous plaît dites-moi comment emprunter une voiture",
    txt_global_std_deepdiveintodrivercrash: "Analyse approfondie de la probabilité d'accident du conducteur, du risque porté et d'autres statistiques",
    txt_global_std_PreSaleDetails_Text53: 'Vehicle Damage',
    txt_global_std_contactdetailsfromyouraccountpleaseeditdetailsifneeded: 'Coordonnées de votre compte, veuillez modifier les détails si nécessaire.',
    txt_global_std_thischallengeactiveandupcomingchallenges: 'Ce défi, les défis actifs et à venir',
    txt_global_std_p2pfaq29a: "1. Sélectionnez « Louer une voiture » sur l'écran d'accueil.\n" +
      '2. Sélectionnez le propriétaire de la voiture que vous souhaitez louer et sélectionnez la voiture.\n' +
      "3. Sélectionnez la date et l'heure.\n" +
      '4. Saisissez votre message (facultatif) et enfin sélectionnez « Envoyer la demande ».\n' +
      "5. La demande de réservation sera maintenue jusqu'à ce que le membre l'approuve ou la rejette.",
    txt_global_std_Intro_Text6: 'Appen «Tryg Sidekick» lastes ned i App store eller Google Play. Skriv inn verifiseringskoden som står i velkomstmailen og opprett egen bruker. Installer så brikken i bilens OBD-inngang når du har mottatt denne i posten. Følg videre instruksjonene i appen.',
    txt_global_std_last10drives: ' 10 derniers trajets',
    txt_global_std_nousersfoundinviteuserstogetstarted: 'Aucun utilisateur trouvé. Invitez les utilisateurs à commencer.',
    txt_global_std_edit: ' Éditer',
    txt_global_std_statusnotconnected: ' Non connecté',
    txt_global_std_slideintrotext1: 'PAY AS YOU DRIVE Car Insurance',
    txt_global_std_reasonforpoints: 'Raison des points',
    txt_global_std_appquestions: 'Questions sur l&#39;APP:',
    txt_global_std_sentmail: 'Sent mail',
    txt_global_std_connecting: ' Connexion',
    txt_global_std_back1: 'Arrière',
    txt_global_std_max30characters: '30 caractères maximum',
    txt_global_std_fileisattached: 'Le fichier est joint',
    txt_global_std_yourpwwasnotchanged: 'Votre mot de passe n&#39;a pas été modifié. Essayez un autre nouveau mot de passe ou écrivez un mot de passe actuel valide!',
    txt_global_std_badges: 'insignes',
    txt_global_std_p2pnotincluded: 'Non inclus',
    txt_global_std_p2pdistancetravellednotrecommended: 'Distance parcourue non recommandée.',
    txt_global_std_addpolicynumber: 'Ajouter un numéro de police',
    txt_global_std_atcmp: 'at',
    txt_global_std_entervalidmessage: 'Saisissez un texte pour votre ami',
    txt_global_std_visibleinmaps: 'Visible dans les cartes',
    txt_global_std_getanoverviewofyourinsurancecost: 'Get an overview of your insurance cost',
    txt_global_std_challengename: 'Nom du défi',
    txt_global_std_MSIG_InsuranceLetter22: '2) Toute autre personne qui conduit sur l&#39;ordre de l&#39;assuré ou avec sa permission.',
    txt_global_std_areyousureyouwanttodeletethismessage: 'Etes-vous sur de vouloir supprimer ce message?',
    txt_global_std_takemetotheappinstead: "Emmenez-moi à l'application à la place",
    txt_global_std_cantfindanybluetoothdevice: 'Je ne trouve aucun appareil Bluetooth.',
    txt_global_std_nothanks: 'No thanks',
    txt_global_std_Insurance_Information: 'Information',
    txt_global_std_youareoffline: 'Vous êtes déconnecté',
    txt_global_std_co2savingsscore: 'Économies de CO₂',
    txt_global_std_p2pfaq31q: 'Où puis-je vérifier les données de conduite ?',
    txt_global_std_improvementareaforspeed: ' Perfectionnement du régime',
    txt_global_std_PreSaleDetails_Text71: 'Moderna Försäkringar, together with the insurance joint claims register (GSR), is entitled to register reported injuries. The company also has the right to provide information to the alarm service for investigation of unclear information and searches for stolen property.',
    txt_global_std_createyouraccount: 'Créez votre compte',
    txt_global_std_Gdpr_Text3: "Si vous êtes un client d'assurance, vos informations ne seront supprimées que 1 an après la fin de votre assurance et que toutes les affaires soient fermées. Notez que la suppression de votre compte ne met pas fin à votre assurance.",
    txt_global_std_anonymoususer: ' Un utilisateur anonyme',
    txt_global_std_BeforeBill_Text9: 'Kör försiktigt!',
    txt_global_std_pointslefttoreach: 'points restants à atteindre',
    txt_global_std_unabletoloadusers: 'Unable to load users',
    txt_global_std_InsuranceApp_Hello: 'Hello',
    txt_global_std_thistrip: 'Ce voyage',
    txt_global_std_weeklyachievements: 'Réalisations hebdomadaires',
    txt_global_std_Intro_Text2: 'Steg 1: Forsikringsprodukt',
    txt_global_std_Renew_Text3: 'Hilsen,\r\n{appName}-gjengen i Tryg Forsikring',
    txt_global_std_duedate: 'Duedate',
    txt_global_std_accepttheinvitationtoteamName: 'Accept the invitation to {teamName}?',
    txt_global_std_options: 'Choix',
    txt_global_std_consentleaderboard: 'Consentement à nos compétitions & contact',
    txt_global_std_MSIG_InsuranceLetter32: 'Signature autorisée',
    txt_global_std_Renew_Text2: 'Bra kjørt! Kjøreutbytte vil bli utbetalt til din konto',
    txt_global_std_namehasinvitedyoutojointheirgroup: '{name} vous a invité à rejoindre son {group}',
    txt_global_std_35000pts: '&#62;35 000 points',
    txt_global_std_totaldistancekm: 'Distance totale (km)',
    txt_global_std_mydrives: 'Mes trajets',
    txt_global_std_comment: 'Commentaire',
    txt_global_std_pluginthedevice: 'Branchez l&#39;appareil',
    txt_global_std_thecontactinformationyousubmithereiswhatwewillusetocontactyouregardingtheappandsendyoutheappsdownloadlink: "Les informations de contact que vous soumettez ici sont celles que nous utiliserons pour vous contacter au sujet de l'application et vous envoyer le lien de téléchargement de l'application.",
    txt_global_std_editteam: 'Edit team',
    txt_global_std_enteryearlyprice: 'Entrez le prix annuel',
    txt_global_std_welcometo: 'Bienvenue sur',
    txt_global_std_repeatthechallengedailyweeklymonthlyorannually: 'Répétez le défi quotidiennement, hebdomadairement, mensuellement ou annuellement.',
    txt_global_std_totaltime: 'Temps total',
    txt_global_std_downloadreport: 'Télécharger le rapport',
    txt_global_std_referafriend: 'Référez un ami',
    txt_global_std_drivingismorfuntogetherletusfindfriends: 'Driving is more fun together! Let us find your friends already using {appName}, and invite new friends to discover {appName}',
    txt_global_std_p2pnoconfirmedbookings: 'Aucune réservation confirmée à afficher.',
    txt_global_std_p2pyourshareofcost: 'Votre part des frais :',
    txt_global_std_reportSave: 'Save',
    txt_global_std_p2ppushbodycancelledperiodfromto: 'Veuillez noter que la réservation {from} a été annulée. Une autre réservation a été générée et acceptée qui chevauche cette réservation non acceptée.',
    txt_global_std_welcometoenerfy: 'Bienvenue dans une toute nouvelle expérience de conduite.',
    txt_global_std_enerfyanalyticsriskportfoliotrackerapp: 'Enerfy Analytics Risk Portfolio Tracker, application',
    txt_global_std_locationservicemustbeontodrivewithdimmedscreen: 'Le service de localisation doit être activé pour éviter la perte possible de données informatiques.',
    txt_global_std_weekly: 'Hebdomadaire',
    txt_global_std_getanoverviewofyourinsureddrives: 'Get an overview of your insured drives',
    txt_global_std_Adjust_Text1: 'Endret kjørelengde',
    txt_global_std_exporttranslations: 'Exporter les traductions',
    txt_global_std_teamwassavedsuccessfully: 'Team was saved successfully',
    txt_global_std_pleasereachouttothesupportifyouseethesameerrorwhenyoutrynexttimelater: 'Veuillez contacter le support si vous voyez la même erreur lors de la prochaine tentative ultérieure.',
    txt_global_std_tojointeam: "Pour rejoindre l'équipe :",
    txt_global_std_cimage15h: ' CONCENTREZ-VOUS SUR LES DÉTAILS',
    txt_global_std_numbersonly: 'Chiffres uniquement',
    txt_global_std_youmustchooseareason: 'Vous devez choisir une raison',
    txt_global_std_theoreticaltimegain: ' Gain de temps théorique',
    txt_global_std_TaxInvoice5: 'Assurez-vous que votre chèque soit payable à MS FIRST CAPITAL INSURANCE LTD',
    txt_global_std_why: 'Pourquoi',
    txt_global_std_p2pyourexpenses: 'Vos dépenses',
    txt_global_std_japanese: 'Japonais',
    txt_global_std_recruiterunlockedkey: "Super, {utilisateur} ! Vous avez invité un ami sur l'application !",
    txt_global_std_downloadingevents: 'Téléchargement des données',
    txt_global_std_drivenotstarted: ' La conduite n’a pas encore débutée',
    txt_global_std_sendon: 'Envoyer sur :',
    txt_global_std_updatecarddetails: 'Mettre à jour les détails de la carte',
    txt_global_std_policyholderinformation: "Informations sur le preneur d'assurance",
    txt_global_std_PreSaleDetails_Text64: 'If you intentionally or negligently provided incorrect or incomplete information when the insurance policy was signed your policy may be reduced by a specified deduction. In more serious cases, compensation can be withheld completely. In assessing, the degree of intent or negligence are of importance as is the extent and the conditions under which we would have given insurance had we known the true facts and circumstances.',
    txt_global_std_settingupclientcredentials: "Configuration des informations d'identification du client",
    txt_global_std_howmanypeoplewereinjured: 'Combien de personnes ont été blessées?',
    txt_global_std_writefriendspromocode: 'Écrire des amis Code promo',
    txt_global_std_uploadfiles: ' Envoyer des fichiers',
    txt_global_std_p2prequestsenttoname: 'Demande envoyée à {name}',
    txt_global_std_constantspeed: ' vitesse constante',
    txt_global_std_noteam: 'Pas déquipe',
    txt_global_std_wehavesentatemporarypasswordtoyouremailaddress: 'Nous avons envoyé un mot de passe temporaire à votre adresse e-mail.',
    txt_global_std_p2pallopenexpenses: 'Toutes les dépenses ouvertes',
    txt_global_std_analyzeyourdrivinghabitstoimprove: 'Analysez vos habitudes de conduite pour améliorer vos compétences de conduite.',
    txt_global_std_cimage32d: ' ',
    txt_global_std_howcanwehelpyou: 'Comment pouvons-nous vous aider?\n',
    txt_global_std_chassinumber: 'Numéro Chassi',
    txt_global_std_insurancedocuments: 'Documents d&#39;assurance',
    txt_global_std_DamageHow: 'Hur startade skadan',
    txt_global_std_p2pacceptedbooking: 'Réservation acceptée',
    txt_global_std_yourchallangeranking: 'Votre classement',
    'txt_global_std_2) Paired your Enerfy reader to your phone via bluetooth. Is the indicator light on your reader green?': '2) Paired your {appName} reader to your phone via bluetooth. Is the indicator light on your reader green?',
    txt_global_std_TermConfirmPlugin_Text7: 'Thank you for your time with us!',
    txt_global_std_dec: ' Déc.',
    txt_global_std_NotCreatedAccount4_Text3Moderna: 'Do you need help with creating an account? Please contact us at: modernasmartflex@modernaforsakringar.se or 0200-265 265.',
    txt_global_std_enerfyanalytics: 'Analyse énergétique',
    txt_global_std_addusers: ' Ajouter des utilisateurs',
    txt_global_std_p2pparking: 'Parking',
    txt_global_std_overview: 'Overview',
    txt_global_std_pointsexpires: 'Les points expirent',
    txt_global_std_yourbonuswillbepaidoutinafewdays: 'Votre bonus sera payé dans quelques jours',
    txt_global_std_avg: ' Moy.',
    txt_global_std_inviteteam: 'Invite team',
    txt_global_std_underwrittenby: 'Soumis par',
    txt_global_std_p2ppushtitleexpensesthismonthquestion: 'Expenses this month?',
    txt_global_std_goheretocreateabookingofyourfriendscar: 'Allez ici pour créer une réservation de la voiture de votre ami.',
    txt_global_std_cimage35d: ' Par exemple, démarrez avec un rapport plus élevé lorsque vous êtes en côte.',
    txt_global_std_tomasengewhyjoin: "J'aime être le meilleur derrière le volant et je suis sûr que tous ceux qui me rejoignent ont le même objectif.",
    txt_global_std_youhavenotfinishedanychallengesyet: "Vous n'avez encore terminé aucun défi.",
    txt_global_std_jul: ' Jul.',
    txt_global_std_Invite_Text7: 'on the page.)',
    txt_global_std_p2pfilter: 'Filtre',
    txt_global_std_youremail: ' Votre e-mail',
    txt_global_std_hi123: 'Salut',
    txt_global_std_kintofaq8q: 'Comment faire des économies?',
    txt_global_std_civilistatusmarriedcohabitingsingle: 'Etat civil (marié, cohabitant, célibataire)',
    txt_global_std_whenyoudrivesafelyandwithgoodflowweputmoneyinyourpiggybankyouwillbepaidthesewhentheinsuranceyearisovertogetherwithmoneyforkilometersyoumaynothaveusedthetotalamountincludesstartbonusmoneysavedforsafedrivingandmoneyforfriendrecruitment: "Lorsque vous conduisez en toute sécurité et avec un bon débit, nous mettons de l'argent dans votre tirelire. Ces derniers vous seront versés à la fin de l'année d'assurance, ainsi que l'argent pour les kilomètres que vous n'avez peut-être pas utilisés. Le montant total comprend le bonus de départ, l'argent économisé pour une conduite sûre et l'argent pour le recrutement d'amis.",
    txt_global_std_p2p1booking: '(1 réservation)',
    txt_global_std_origpurpose: 'Orig purpose',
    txt_global_std_p2ppushbodylendcar: '{name} vous a envoyé une demande de prêt',
    txt_global_std_damagereport: 'Damage report',
    txt_global_std_areyousureyouwanttoenablethecommunication: 'Voulez-vous vraiment activer la communication ?',
    txt_global_std_nowitstimetoconnecttoyourcarsbluetooth: 'Connectez-vous au Bluetooth de votre voiture.',
    txt_global_std_BeforeBill_Text7: 'Har du fler frågor om din bilförsäkring? Gå in på vår FAQ-sida',
    txt_global_std_thisisneededbeforeyoustartyourfirstdrive: 'Ceci est nécessaire avant de démarrer votre premier trajet.',
    txt_global_std_getstartedinnotimewithourtutorials: 'Lancez-vous en un rien de temps grâce à nos tutoriels !',
    txt_global_std_p2punblockcar: 'Débloquer la voiture',
    txt_global_std_zipdriverriskindexinsuranceratingcomponent: "Indice de risque Zip-Driver - Composante de notation d'assurance",
    txt_global_std_topimprovers: 'Meilleurs améliorants',
    txt_global_std_establishingbluetoothlink: ' Connexion Bluetooth en cours',
    txt_global_std_drivewithanaveragescoretypeofscoreorbetterforaweektounlockthisachievementandearnpointspoints: 'Conduisez avec un {scoretype} moyen de {score} ou mieux pendant une semaine pour déverrouiller cette réussite et gagner {points} points.',
    txt_global_std_sharelink: 'Lien de partage',
    txt_global_std_warikan_onboarding6: `2. Lancez l'application Warikan KINTO et sélectionnez "S'inscrire"\n`,
    txt_global_std_insurancerenew: 'Il ne vous reste plus que {km} km. Votre assurance est renouvelée le {date}.',
    txt_global_std_p2ppartlyavailable: 'Partiellement disponible',
    txt_global_std_loadmore: ' Appuyer pour charger davantage',
    txt_global_std_MikkelsenDescription: 'Andreas a une vaste expérience dans le monde du sport. Il a concouru dans le ski alpin et a été membre de l’équipe national des jeunes du ski. En 2003 et 2004 il a participé à la compétition du motocross, en représentant aussi l’équipe national des jeunes. Il a terminé troisième dans le WRC en 2014, 2015, 2016, en encaissant trois victoires et en prenant place vingt fois sur le podium.',
    txt_global_std_safedriverdiscountpercent: 'Remise Safedriver {pourcent} %',
    txt_global_std_iunderstand: ' Je comprends',
    txt_global_std_MSIG_InsuranceLetter36: 'Département des sinistres et de la tarification automobile:',
    txt_global_std_referfriend: 'Référez un ami.',
    txt_global_std_p2presendcode: 'Renvoyer le code',
    txt_global_std_activatebonusinfo: 'Pengene vil vises i sparegrisen din med det samme, men vil først være gyldig etter at vennen din har vært kunde i to måneder. I sparegrisen kan du hele tiden se oppdatert status på hva du har spart inkludert belønninger. ',
    txt_global_std_p2pbecomefriendstoviewthisprofile: 'Devenez amis pour voir ce profil',
    txt_global_std_p2pthisemailadressisusedforustosendcommunication: "Cette adresse e-mail nous permet d'envoyer des communications, par exemple si vous oubliez votre mot de passe.",
    txt_global_std_no: ' Non',
    txt_global_std_listpopups: 'List Popups',
    txt_global_std_p2pmytripscomingsoon: 'Mes voyages - À venir',
    txt_global_std_pigwonunlocked: 'Toutes nos félicitations! Vous avez gagné un concours dans head2head',
    txt_global_std_registrationnumber2: 'Numéro d&#39;enregistrement',
    txt_global_std_notyetdecided: 'Pas encore décidé',
    txt_global_std_driversidentificationnumber: 'Numéro d&#39;identification du conducteur',
    txt_global_std_insuranceidcard: "Carte d'identité d'assurance",
    txt_global_std_repeatchallenge: 'Répéter le défi ?',
    txt_global_std_rewardandendorsesmartbehavior: 'Récompensez et approuvez les comportements intelligents',
    txt_global_std_KleinschmidtFacts: 'Elle est la première et, jusqu’à présent, la seule femme d’avoir sa première victoire incontestable du circuit le plus long et le plus difficile au monde, le Rallye de Dakar. Son parcours a débuté à la moto. Après quatre rallyes en moto elle passe à quatre roues, en 1995, et s’engage dans le développement du véhicule à plus de succès du rallye, le Mitsubishi Pajero Evo, avec lequel elle emporte la victoire du Rallye de Dakar en 2001. En plus de son travail en tant que speaker, elle a publié son livre « Mein Siege bei der Dakar » (Ma victoire à Dakar).',
    txt_global_std_MSIG_InsuranceLetter10: 'Numéro de véhicule / Numéro de châssis',
    txt_global_std_qwhathappensififorgettorenewbeforemyinsurancehasexpired: "Q : Que se passe-t-il si j'oublie de renouveler avant l'expiration de mon assurance ?",
    txt_global_std_community: 'communauté',
    txt_global_std_estimateddiscount: 'Remise estimée',
    txt_global_std_score2: 'Score',
    txt_global_std_addprice: ' Ajouter un prix',
    txt_global_std_Adjust_Hello: 'Hei!',
    txt_global_std_p2phowmuchdoyouwanttocharge: 'Combien voulez-vous facturer?',
    txt_global_std_p2pkintonumber: 'Numéro Kinto',
    txt_global_std_HighMileage_Text3: 'Hvis du ønsker å justere opp kjørelengden kan du gjøre dette',
    txt_global_std_p2pfaq7a: '1.Accédez au récapitulatif de la réservation pour cette réservation.\n' +
      "2.Appuyez sur le bouton d'annulation de réservation en haut à gauche.",
    txt_global_std_kintofaq5q: 'Et si je change de voiture?',
    txt_global_std_Fia_Receipt_Text1: 'Vous êtes connecté',
    txt_global_std_postalcode: ' Code postal',
    txt_global_std_weareinthischallengetomaketheworldsaferandbettertogetherjoinustohelpmakeanimpact: 'Nous sommes dans ce défi de rendre le monde plus sûr et meilleur - ensemble. Rejoignez-nous pour aider à avoir un impact!',
    txt_global_std_anotheappworksinbackgroundmodemakesureyoualwayskeepbluetoothandlocationservicesactivatedonyourphone: "R : Non, l'application fonctionne en arrière-plan. Assurez-vous de toujours garder Bluetooth et les services de localisation activés sur votre téléphone.",
    txt_global_std_p2pstatus: 'Statut',
    txt_global_std_p2pshareofmonthlyfee: 'Part des frais mensuels',
    txt_global_std_VehicleOwnermail: 'Fordonägarens e-post',
    txt_global_std_rwmselectthetripsyouwereridesharing: 'Sélectionnez les trajets que vous faisiez en covoiturage',
    txt_global_std_ecoscore123: 'Score Eco',
    txt_global_std_p2pgreatyourcostsplitwassenttoyourfriends: 'Génial! Votre partage des coûts a été envoyé à vos amis.',
    txt_global_std_activationletter_text14: "Suivez les instructions dans l'application.",
    txt_global_std_advancedadmin_upsell_pitch_bulletpoint3: 'Documents, politiques et autres outils de gestion intelligents au même endroit',
    txt_global_std_youarebeingredirectedtodashboard: 'Vous êtes redirigé vers le tableau de bord.',
    txt_global_std_sportscorescore: ' Score sportif {score}',
    txt_global_std_yourlatestscore: 'Votre dernier score:',
    txt_global_std_MSIG_InsuranceLetter11: 'Nom de l&#39;assuré',
    txt_global_std_codewasnotfound: "Le code n'a pas été trouvé",
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimeincreasingspeedreadingtheroadaheadcanhelpyoudeterminewhetheritissuitabletobuildspeed: "Sur ce type de voyage, le champion écolo moyen passe moins de temps à augmenter la vitesse. La lecture de la route à venir peut vous aider à déterminer s'il convient d'augmenter la vitesse.",
    txt_global_std_p2pbluetoothconnectionrequired: 'Connexion Bluetooth requise',
    txt_global_std_engineandelectricaldamage: 'Engine & Electrical Damage',
    txt_global_std_Referencenumber: 'Diarienummer',
    txt_global_std_topteamdrivers: 'Meilleurs pilotes déquipe',
    txt_global_std_InsuranceMail_Text5: 'Deductible ',
    txt_global_std_TerminationMail_Text3: "The Motor Insurers Association will charge a fee from the date you choose to not insure your vehicle or pause your registration with the Transport Agency. Notice that the Motor Insurers Agency's charges a very high fee for every day that your car is not insured.",
    txt_global_std_mycar: ' Ma voiture',
    txt_global_std_weeks: ' Semaines',
    txt_global_std_cimage13d: ' Diminuez lentement votre vitesse dans une montée.',
    txt_global_std_km: 'km',
    txt_global_std_p2ptoyotawalletcomingsoon: 'Portefeuille Toyota (à venir)',
    txt_global_std_weresettingupyourchallenge: 'Nous mettons en place votre challenge',
    txt_global_std_greatrun: 'Grande course',
    txt_global_std_welcometocovercube: 'Bienvenue sur Covercube !',
    'txt_global_std_Do you have an Enerfy reader?': 'Do you have an {appName} reader?',
    txt_global_std_doyouwanttoleaveteamName: 'Do you want to leave {teamName}?',
    txt_global_std_sidekickhelpsyouwiththemotivationandtipstodrivebettersaferandmoreecofriendly: "Sidekick vous aide avec la motivation et des conseils pour conduire mieux, plus sûr et plus respectueux de l'environnement.",
    txt_global_std_bysigningupiacceptthetermsandconditionsandprivacypolicy: "En m'inscrivant, j'accepte les Termes et Conditions\n" +
      'et politique de confidentialité.',
    txt_global_std_p2ppushbodynewmessage: 'première ligne du nouveau message',
    txt_global_std_top50insurance: 'Top 50 insurance',
    txt_global_std_JyrkiainenMusic: 'Pop and rap music.',
    txt_global_std_p2ptimes: 'fois',
    txt_global_std_totalvalid: 'Total valide',
    txt_global_std_makemember: 'Make member',
    txt_global_std_2pairyourphonewithyourcarsbluetoothsystem: '2. Associez votre téléphone au système Bluetooth de votre voiture.',
    txt_global_std_schedulemessage: 'Planifier un message',
    txt_global_std_activatebluetooth: 'Activer Bluetooth',
    txt_global_std_p2paddyourexpensesbeforethelastdayofthemonthtogetthemincludedinthenextcostsplit: 'Ajoutez vos dépenses avant le dernier jour du mois pour les inclure dans la prochaine répartition des coûts.',
    txt_global_std_p2pcurioustoknowhowyoudrivekeeptrackofyourprogressandsestatsaboutyourscoreco2savingsdistanceandmore: 'Curieux de savoir comment vous conduisez? Suivez vos progrès et consultez les statistiques sur votre score, les économies de CO2, la distance et plus encore.',
    txt_global_std_rwmsendinvitelink: "Envoyer le lien d'invitation",
    txt_global_std_Animal: 'Djur',
    txt_global_std_p2pdocumentmycarcomingsoon: 'Documenter ma voiture - Bientôt',
    txt_global_std_recommendedsize: 'Recommended size',
    txt_global_std_Head2Head: 'You have been challenged in Head to Head!',
    txt_global_std_p2pmessagedeleted: 'Message supprimé!',
    txt_global_std_clickthroughtheappintheframeandfindthetranslationyouwanttoeditorfindtheminthelistontherightside: "Cliquez sur l'application dans le cadre et recherchez la traduction que vous souhaitez modifier, ou recherchez-la dans la liste sur le côté droit.",
    txt_global_std_startcolon: 'Start:',
    txt_global_std_allset: 'Tout est prêt!',
    txt_global_std_TermConfirmPlugin_Text6: 'If in the future you would regret your choice of termination, you are welcome to contact us. We will be happy to help you aquire the insurance again.',
    txt_global_std_p2pwaitingforacceptance: "En attente d'acceptation.",
    txt_global_std_createaccounttext: 'Créer un compte',
    txt_global_std_costsplit: 'Répartition des coûts',
    txt_global_std_p2pcancelledthebooking: 'annulé la réservation.',
    txt_global_std_help: ' Aide',
    txt_global_std_youcannowclosethiswindoworreturntothedashboard: 'Vous pouvez maintenant fermer cette fenêtre ou revenir au tableau de bord.',
    txt_global_std_DamageReport_Text1: 'We have recieved your complete damage report for your',
    txt_global_std_getshareabledomainlink: 'Obtenir un lien de domaine partageable',
    txt_global_std_popupdraftsaved: 'Draft saved',
    txt_global_std_lowrisk: 'Faible risque',
    txt_global_std_tttest98: "c'est un essai 98",
    txt_global_std_p2pcostsplitisbasedonkilometersdriven: 'La répartition des coûts est basée sur les kilomètres parcourus.',
    txt_global_std_pleasechooseareason: "S'il vous plaît choisir une raison",
    txt_global_std_searchmembers: 'Search members',
    txt_global_std_selectedreader: ' Lecteur sélectionné',
    txt_global_std_domainrestrictedinvitelink: "Lien d'invitation restreint au domaine",
    txt_global_std_challenge2: 'DÉFI',
    txt_global_std_PreSaleDetails_Text73: 'Our goal is customer satisfaction, if you are not satisfied with the decision in a case, you can turn to the one who dealt with your case. There is also the option to turn to the Moderna Försäkringar Complaints Manager, who ensures that the complaint is handled, klagomalsansvarig@modernaforsakringar.se',
    txt_global_std_startcarengineandpleasemakesurebluetoothoncarsideisenabledifthisisyourfirsttimeconnectingyourphonetocarpleaseconnectbluetoothfromcarside: 'Démarrez le moteur de la voiture et assurez-vous que le Bluetooth côté voiture est activé.\n' +
      "Si c'est la première fois que vous connectez votre téléphone à la voiture, veuillez connecter le Bluetooth du côté de la voiture.",
    txt_global_std_10discountonrenewal: '10% de réduction sur le renouvellement',
    txt_global_std_DamageReport_Text5: 'Sincerely,\r\n{appName}',
    txt_global_std_treessaved: 'Arbres sauvés',
    txt_global_std_JyrkiainenQuote: 'Le FIA Smart Driving Challenge est une bonne manière d’approuver que le sujet de l’environnement soit aussi important dans le monde du sport automobile. Rivalisez avec vos amis et conduisez de manière "smart" !',
    txt_global_std_couldnotvalidateyourcode: 'Impossible de valider votre code! Assurez-vous que vous avez entré le code correctement et qu&#39;il est valide, puis réessayez!',
    txt_global_std_sendemergencymessage: 'Send emergency message',
    txt_global_std_preview1: 'Aperçu',
    txt_global_std_lendyourcar: 'Lend your car',
    txt_global_std_tojoingroup: 'Pour rejoindre le groupe :',
    'txt_global_std_costofspeedabove{speed}': ' coût de la vitesse au-dessus de {speed}',
    txt_global_std_Insurance_Amount: 'Amount',
    txt_global_std__entertheemailthatyouusedwhenyouregisteredyourinsurance: "Entrez l'email que vous avez utilisé lors de l'enregistrement de votre assurance.",
    txt_global_std_ActivationLetter: 'Activation Enerfy - Insurance with Moderna',
    txt_global_std_above1000streakunlocked: 'Vous avez conduit au-dessus d&#39;un score de 1000, 5 fois. Un travail fantastique!',
    txt_global_std_Insurance_RoadTaxTotalcost: 'The total amount includes statutory traffic tax',
    txt_global_std_apitool_upsell: "API Tool - la passerelle pour les développeurs vers l'intégration de données.",
    txt_global_std_choosenumberbetween1and9: 'Choisissez un nombre entre 1 et 9',
    txt_global_std_importtranslations: 'Importer des traductions',
    txt_global_std_renewyourinsuranceformonthandgetadiscountonyourpremium: 'Renouvelez votre assurance pour {mois} et obtenez une réduction sur votre prime',
    txt_global_std_compareyourtripresultstothebestecodriverswecallthemecochampions: 'Comparez vos résultats de voyage aux meilleurs éco-conducteurs. Nous les appelons éco champions.',
    txt_global_std_NotCreatedAccount6_Text2: 'Do you have any questions about your new car insurance, you are welcome to contact Moderna Försäkringar at: 0200 – 259 259',
    txt_global_std_signup1: "S'inscrire",
    txt_global_std_notvalidchassinumber: 'Numéro de chasse non valide',
    txt_global_std_describepurpose: ' Description de l’objectif',
    txt_global_std_latesttripdate: 'Dernier voyage : {date}',
    txt_global_std_insurancecustomer: 'Insurance customer',
    txt_global_std_connecttoanothercar: 'Connectez-vous à une autre voiture',
    txt_global_std_KleinschmidtMusic: 'When driving I prefer to listen to the radio and to information.',
    txt_global_std_lateststarttime: 'Latest starttime',
    txt_global_std_reviewchanges: 'Examiner les modifications',
    txt_global_std_p2puserexpenses: '{name} dépenses',
    txt_global_std_partnercode: 'Partner code',
    txt_global_std_bluetoothsettings: 'paramètres Bluetooth',
    txt_global_std_p2pincluded: 'Inclus',
    txt_global_std_p2pstarttime: 'Heure de début:',
    txt_global_std_rwminviteanewfriend: 'Inviter un nouvel ami',
    txt_global_std_calculateyourco2savings: 'Calculez vos économies de CO2',
    txt_global_std_insuranceinactivated: 'Insurance Inactivated',
    txt_global_std_currentbonusperiod: 'Période de bonus actuelle :',
    txt_global_std_200km: '200 Km',
    txt_global_std_terminationreason: 'Reason terminated insurence',
    txt_global_std_qualifiedsportleague: 'Qualified Sport league',
    txt_global_std_youreceivedarenewalofferformonth: 'Vous avez reçu une offre de renouvellement pour {month}.',
    txt_global_std_Recruit_Text4: '{appName}',
    txt_global_std_activationcodestartdate: "Date de début du code d'activation",
    txt_global_std_previous: 'précédent',
    txt_global_std_WorkShopName: 'Namn på verkstad',
    txt_global_std_uploadphotosofthedamagesorscratchesonyourcar: 'Téléchargez des photos des dommages ou des égratignures sur votre voiture',
    txt_global_std_yourcarmodelistoooldanddoesnthavebluetooth: '• Votre modèle de voiture est trop ancien et ne dispose pas de Bluetooth.',
    txt_global_std_contributesustainabilitythroughwarikanbody: "Pourquoi ne pas prendre plaisir à conduire tout en étant sensibilisé à l'éco-conduite ? Warikan KINTO est là pour vous aider ! La fonction de scoring et les acquisitions de badges sont disponibles même si vous n'êtes pas utilisateur de KINTO ONE",
    txt_global_std_cityconditions: 'Conditions de la ville',
    txt_global_std_cimage18d: ' Une vitesse moyenne bien planifiée vous permet de gagner plus de temps qu’une vitesse de pointe élevée',
    txt_global_std_howyoucansavemorefuel: 'How you can save more fuel',
    txt_global_std_1startyourcar: '1. Démarrez votre voiture.',
    txt_global_std_accessdeniedyoudonothavepermissiontoaccesstheworkspacepleasecontactyouradministratorifincorrect1: "Accès refusé. Vous n'êtes pas autorisé à accéder à l'espace de travail. Veuillez contacter votre administrateur en cas d'erreur.",
    txt_global_std_yourchangesaresaved: 'Vos modifications sont enregistrées !',
    txt_global_std_challengeleaderboard_upsell: 'Tableau de bord des défis - encouragez une conduite sûre avec des défis personnalisés.',
    txt_global_std_distancekm1: 'Distance (km)',
    txt_global_std_p2pthispasswordhasexpired: 'Ce mot de passe a expiré',
    txt_global_std_introslide3text: 'Get access to other services like Find your car, driving journal, driving coach, competitions etc.',
    txt_global_std_p2psendinvoices: 'Envoyer des factures',
    txt_global_std_HansenFacts: 'Kevin Hansen, le plus jeune champion de la FIA Supercar et le FIA Rookie de l’année en 2016',
    txt_global_std_drivers2: 'Conducteurs',
    txt_global_std_p2pfaq26a: `1. Sélectionnez "Réserver" à partir de l'écran d'accueil.\n` +
      '2. Sélectionnez "Prêter une voiture".\n' +
      '3. Sélectionnez le membre pour lequel vous souhaitez louer une voiture.\n' +
      "4. Sélectionnez la date et l'heure.\n" +
      '5. Saisissez votre message (facultatif) et enfin sélectionnez « Envoyer la demande ».\n' +
      "6. La demande de prêt sera mise en attente jusqu'à ce que le membre l'approuve ou la rejette.",
    txt_global_std_doyouwanttodeletethispopup: 'Do you want to delete this popup',
    txt_global_std_doyouwanttoresetthepasswordforuser: 'Voulez-vous réinitialiser le mot de passe de {user} ?',
    txt_global_std_2makesureyourphoneispairedtoyourcarviabluetooth: '2. Assurez-vous que votre téléphone est couplé à votre voiture via Bluetooth.',
    txt_global_std_carsharingemptyviewborrowed: 'Lend out the car and it will be a bit more fun!',
    txt_global_std_summaryoftripsperslectecdperiod: 'Récapitulatif des trajets par période sélectionnée.',
    txt_global_std_newreport: 'Nouveau rapport',
    txt_global_std_cardwithmoreinformationaboutpermissions: "Carte avec plus d'informations sur les autorisations",
    txt_global_std_p2pbookingof: ' réservation de',
    txt_global_edj_unit: 'Unit',
    txt_global_std_MSIG_Onboarding7: '1. Cliquez sur le lien que vous avez reçu par texte.',
    txt_global_std_qhowdoestheappwork: "Q : Comment fonctionne l'application ?",
    txt_global_std_kintoloyaltysystemdesc: 'Veuillez noter que vos avantages sont basés sur les résultats finaux de la période de fidélité précédente. Les points sont gagnés en obtenant des succès et sont réinitialisés au début d&#39;une nouvelle période.',
    txt_global_std_p2pyourfriendinvitedyoutoborrowtheircar: 'Votre ami vous a invité à emprunter sa voiture.',
    txt_global_std_morefunwithreader: 'Congratulations! Great that you have chosen {appName}. Start your first driving experience by starting a drive now.',
    txt_global_std_place: ' Lieu',
    txt_global_std_receiptinterval: 'Receipt interval',
    txt_global_std_inorderforustoshowyouvariousstatisticsitisimportantthatthephoneissettouselocationalways: 'Afin que nous puissions vous montrer diverses statistiques, il est important que le téléphone soit configuré pour utiliser la localisation "toujours"',
    txt_global_std_gotosettingsonyourcarscreenandpairthebluetoothwithyourphone: "Accédez aux paramètres sur l'écran de votre voiture et associez le Bluetooth à votre téléphone",
    txt_global_std_cimage6h: ' ÉVITEZ LES DÉPASSEMENTS INUTILES',
    txt_global_std_buttontextemptyortolong: 'Button text empty or to long',
    txt_global_std_youhavenotenabledyourprivacyundersettings: 'Vous n&#39;avez pas activé votre &quot;confidentialité&quot; sous Paramètres. Lorsque la &quot;confidentialité&quot; n&#39;est pas activée, nous ne pouvons pas partager avec vous toutes les compétitions intéressantes et les astuces fournies dans l&#39;application. Activez ensuite &quot;privacy&quot; et rejoignez notre communauté dès aujourd&#39;hui.',
    txt_global_std_downloadcsvtemplate: 'Télécharger le modèle CSV',
    txt_global_std_documentation: 'Documentation',
    txt_global_std_countryroadroundabout2DESC: 'Le conducteur de la voiture bleue traverse le rond-point en douceur. Le conducteur de la voiture rouge conduit sans concentration et utilise plus souvent ses freins et son équipement.',
    txt_global_std_ParkingWho: 'Vem parkerade fordonet',
    txt_global_std_InsuranceMail_Text3: 'The insurance extent',
    txt_global_std_cimage12d: ' ',
    txt_global_std_pleaseenteravalidcellphonenumber: 'Veuillez saisir un numéro de téléphone portable valide',
    txt_global_std_sendorder: "Envoyer l'ordre",
    txt_global_std_brokenitems: 'Objets cassés ?',
    txt_global_std_HowManyMiles: 'Hur många mil har bilen gått',
    txt_global_std_selectatemplatedesc: "Utilisez ce modèle comme badge dans votre application. Personnalisez-le à l'étape suivante.",
    txt_global_std_sendlink: 'Envoyer un lien',
    txt_global_std_leavewithoutchanges: 'Laisser sans modifications',
    txt_global_std_toestablishaconnectiontoyourcarappnameneedstoaccesstobluetooth: 'Pour établir une connexion avec votre voiture, {appname} doit accéder au Bluetooth.',
    txt_global_std_p2pleavethisgroup: 'Quitter ce groupe',
    txt_global_std_PolicySchedule14: 'Pilotes nommés',
    txt_global_std_DamageReport_Text4: 'We at {appName} are happy to help you if you have any other questions. Contact us at info@enerfy.se or 08-555 93 200.',
    txt_global_std_p2pbookingnotifications: 'Notifications de réservation',
    txt_global_std_bestdriversoftheday: 'Les meilleurs pilotes du jour',
    txt_global_std_customtabtext: 'Réalisations personnalisées',
    txt_global_std_MarklundWhyjoin: 'We’ll make a difference towards the better only by changing our driving style. In my team we’ll focus on making difference, with the aim to win and on the way there, we’ll have fun. I like competitions and I like winning, but I also like to see my future kids grow up in a safe and healthy place with me there. That’s what’s most important. \n',
    txt_global_std_drivedetailshavebeensenttoemail: 'Trip details have been sent to {email}',
    txt_global_std_thepersonyouinvitegetaccessyoalldrivesandcost: 'The person you invite, get access to all your trips and cost.',
    txt_global_std_drivewithanaverageofscoreorbetterforaweektounlockthisachievementandearnpointspoints: 'Conduisez avec une moyenne de {score} ou mieux pendant une semaine pour déverrouiller cette réussite et gagner {points} points.',
    txt_global_std_advancedadmin_upsell_pitch: 'Naviguez facilement dans votre propre parcours de réussite client numérique pour;',
    txt_global_std_invalidenddate: 'Invalid end date.',
    txt_global_std_answer: ' Réponse',
    txt_global_std_thursday: 'Jeudi',
    txt_global_std_Insurance_Text1: 'Fast månadspremie',
    txt_global_std_cimage40h: ' CHAQUE MÈTRE PARCOURU EN ROUE LIBRE AMÉLIORE LES RÉSULTATS',
    txt_global_std_simplyprovideyourpersonaldetailssowecansetupyouradminaccountnextyoullbeabletostartaddingteammemberstojoinyourfiasdccompanychallenge: "Fournissez simplement vos informations personnelles afin que nous puissions configurer votre compte administrateur. Ensuite, vous pourrez commencer à ajouter des membres d'équipe pour rejoindre votre FIA SDC Company Challenge !",
    txt_global_std_datetimedistance: 'Date, heure, distance',
    txt_global_std_identifyfollowupandreportonsafetyandsustainabilitytargets: 'Identifier, suivre et rendre compte des objectifs de sécurité et de durabilité',
    txt_global_std_sharecertificate: "Certificat d'actions",
    txt_global_std_listcompetitions: 'List Competitions',
    txt_global_std_coporateoffice: 'Coporate Office',
    txt_global_std_p2pemailstartingsoon: 'Bientôt',
    txt_global_std_p2pfaq27q: "Qu'est-ce que les privilèges d'administrateur ?",
    txt_global_std_Insurance_Car: 'Car',
    txt_global_std_firstdrive: 'First drive',
    txt_global_std_HighUsageAlarm_Text2: 'Sincerely,\r\n{appName}',
    txt_global_std_acaddyourbrandcolors: 'Ajoutez les couleurs de votre marque',
    txt_global_std_invitedfriends: 'Invited friends',
    txt_global_std_improvementpotential: ' Marge de progression',
    txt_global_std_thepasswordcontainsforbiddencharactersorspace: "Le mot de passe contient des caractères interdits : ' ? & / &#60; &#62; ou espace",
    txt_global_std_invited: 'Invited',
    txt_global_std_typeofachievement: 'Type de réalisation',
    txt_global_std_finishdrive: ' Terminer le trajet',
    txt_global_std_youshouldaddapictureofyourodometer: 'Vous devriez ajouter une photo de votre odomètre',
    txt_global_std_connectingtogps: ' Connexion au GPS en cours',
    txt_global_std_WorstGroups: 'Tips on cheaper driving',
    txt_global_std_app: 'app',
    txt_global_std_DamageWhere: 'Var finns skadorna på fordonet',
    txt_global_std_Fia_Receipt_Text2: 'Cliquez sur le lien pour commencer à concourir au FIA SDC:',
    txt_global_std_2nddate: '2ème',
    txt_global_std_invitetemplatetitle: 'Invite a friend',
    txt_global_std_startd: ' Début',
    txt_global_std_youcanalsouploadpicturesthatmightberelevant: 'Vous pouvez également télécharger des images qui pourraient être pertinentes en cas d&#39;accident.',
    txt_global_std_missingbonuspayout: 'Paiement de bonus manquant',
    txt_global_std_publish: 'Published',
    txt_global_std_daysshort: 'ré',
    txt_global_std_areyoudriving: ' Êtes-vous en train de conduire ',
    txt_global_std_timelefttoreactivateinsurance: "Temps restant pour réactiver l'assurance",
    txt_global_std_fivereasonswhyjoiningfiasdcisasmartbusinessdecision: 'Cinq raisons pour lesquelles rejoindre FIA SDC est une décision commerciale intelligente :',
    txt_global_std_ContactDetailsCounterPart: 'Uppgifter om motparten',
    txt_global_std_orenteractivationcode: 'Ou entrez le code d&#39;activation:',
    txt_global_std_typeofpopup: 'Popup type',
    txt_global_std_youdonothaveanyongoingchallengesatthemoment: "Vous n'avez pas de défis en cours pour le moment.",
    txt_global_std_locationserviceneedstobeontoworkcorrectly: 'Le service de localisation doit être activé pour que la fonctionalité de la carte fonctionne de manière correcte.',
    txt_global_std_forthisapptoworkproperlyweneedtoaccess: 'Pour que cette application fonctionne correctement, nous devons accéder à votre position, à Bluetooth et à Fitness / Motion. Nous apprécions votre confidentialité et ne partagerons pas ces informations avec une entité tierce.',
    txt_global_std_translationtool_upsell: 'Outil de traduction - reflète les valeurs de votre marque en communiquant à votre manière.',
    txt_global_std_cimage23d: ' Chaque mètre parcouru en roue libre a un effet sur le ralentissement ou l’arrêt du véhicule.',
    txt_global_std_saverecipients: 'Save recipients',
    txt_global_std_Invite_Text8: 'Click on the following link to accept your invite',
    txt_global_std_usageyear: 'Yearly usage',
    txt_global_std_challengesent: 'Challenge sent',
    txt_global_std_HighMileage_Text1: 'Vi vil informere om at det er fare for at du vil overskride avtalt årlig kjørelengde på',
    txt_global_std_analyzingdotdot: 'En cours d&#39;analyse...',
    txt_global_std_insureddistance_used_100__3: 'Oi! Se till att du inte riskerar förkortning vid skador. Justera körsträckan så att den är korrekt.',
    txt_global_std_youmayaddaphotolater: 'Vous pouvez ajouter une photo plus tard',
    txt_global_std_sendassms: 'Envoyer par SMS',
    txt_global_std_findfriends: 'Find friends',
    txt_global_std_ReporterOwner: 'Vem är det som anmäler skadan',
    txt_global_std_cost: 'Cost',
    txt_global_std_selectinsurancetoterminate: 'Choisissez une assurance pour résilier',
    txt_global_std_nowitstimetosetupyourcompanychallenge: "Il est maintenant temps de mettre en place votre défi d'entreprise !",
    txt_global_std_youvehavegatheredatotalof: 'Vous avez rassemblé un total de',
    txt_global_std_alsochallengeyourselfandengageyourfriendsinaworldcompetition: 'Aussi, mettez-vous au défi et engagez vos amis dans une compétition mondiale.',
    txt_global_std_youmusthaveinternetconnection: ' Vous devez disposer d’une connexion internet pour recevoir les scores en direct.',
    txt_global_std_p2pnomessagesyet: 'Pas encore de messages',
    txt_global_std_invalidinput: 'Entrée invalide',
    txt_global_std_PreSaleDetails_Text16: 'For example, you should specifically make sure that the driver is sober, has a drivers license, is of the required age and is not driving a vehicle with a driving ban. The vehicle should not be used for practice driving if the teacher or student do not meet the requirements for practice driving. The vehicle must be locked with an approved theft prevention system. Directly imported cars with model year 1996 or later must be equipped with one of the Theft Prevention Association approved electronic immobilizers. The keys may not be stored in or around the vehicle.',
    txt_global_std_cimage7h: ' ACCÉLÉREZ POUR ATTEINDRE LA VITESSE DE CROISIÈRE',
    txt_global_std_p2pdenyy: 'Refuser',
    'txt_global_std_1. Clickselect a report above.': '1. Click/select a report above.',
    txt_global_std_surcharge: 'Surtaxe',
    txt_global_std_PreSaleDetails_Text61: 'If the insurance is not paid on time, we have the right to terminate it 14 days after written notice was sent. If you pay the insurance before the end of this period, it continues to apply.',
    txt_global_std_p2pgotosupport: 'Aller au support',
    txt_global_std_MSIG_InsuranceLetter43: 'SGD1,000.00 TOUTES LES RÉCLAMATIONS SONT IMPOSÉES AUX CONDUCTEURS QUI ONT DÉSACTIVÉ LE DONGLE AU MOINS 14 JOURS AU COURS D&#39;UNE ANNÉE CALENDRIER.',
    txt_global_std_cimage16h: ' PLANIFIEZ VOTRE AUGMENTATION DE VITESSE',
    txt_global_std_timeinacceleration: ' Temps passé à accélérer',
    txt_global_std_Insurance_DamageAbroad: 'Damage abroad',
    txt_global_std_Insurance_PaymentDay: 'Payment day',
    txt_global_std_planning: 'Planification',
    txt_global_std_p2ptimeused: 'Temps utilisé',
    txt_global_std_theinsurancethatrewardsyou: 'The insurance that rewards you for smart and enviromental friendly driving',
    txt_global_std_whenyouvestartedadriveyoucanseeyourcarslocation: 'Lorsque vous avez démarré, vous pouvez voir l&#39;emplacement de votre voiture.',
    txt_global_std_findyourfriendsorinvitethemtodiscoverenerfy: 'Find your friends or invite them to discover {appName}',
    txt_global_std_myfriends: ' Mes amis',
    txt_global_std_1000drive: '1000 en voiture!',
    txt_global_std_cimage26h: ' RAPPORT AVEC UN FAIBLE RÉGIME',
    txt_global_std_insurancehasbeenterminated: 'We have received your termination.',
    txt_global_std_p2pyoublocked: 'Tu as bloqué',
    txt_global_std_reportaccident2: 'Signaler un accident',
    txt_global_std_bycheckingthisboxandlogginginyoureacceptingour: 'En cochant cette case et en vous connectant, vous acceptez notre',
    txt_global_std_wesharethislater: ' Nous communiquerons plus tard sur ce point',
    txt_global_std_syncingwithyourgarminconnectaccountiseasyclicktheconnectbuttonbelowtohelpusuncovernewinsightsintocrashriskbyanalyzingbothdrivingandhealthdata: "La synchronisation avec votre compte Garmin Connect™ est facile ! Cliquez sur le bouton Connect ci-dessous pour nous aider à découvrir de nouvelles informations sur le risque d'accident en analysant à la fois les données de conduite et de santé.",
    'txt_global_std_3) Your OBD reader will now appear in the list. Select it to pair the reader with your phone.': '3) Your OBD reader will now appear in the list. Select it to pair the reader with your phone.',
    txt_global_std_showadvancedsettings: 'Afficher les paramètres avancés',
    txt_global_std_rewardthosewhoexcel: 'Récompensez ceux qui excellent !',
    txt_global_std_paidindays: 'Payment in {days} days',
    txt_global_std_kintofaq6a2: 'pour aider.',
    txt_global_std_uploadintervalminutesmin: ' {uploadIntervalMinutes} m',
    txt_global_std_NotDriving_Hello: 'Hello',
    txt_global_std_meandsettings: 'Me & settings',
    txt_global_std_p2penterkintonumber: 'Entrez le numéro Kinto',
    txt_global_std_startadrivetogetascore: 'Lancez un lecteur pour obtenir un score!',
    txt_global_std_orgenerateinvitationcodeandlinkformultipleuse: "Ou générer un code d'invitation et un lien pour une utilisation multiple",
    txt_global_std_atrustedscorevettedbythefia: 'Un score de confiance approuvé par la FIA',
    txt_global_std_tryagain: 'Try again',
    txt_global_std_timetoconnecttoyourcar: 'Il est temps de se connecter à votre voiture',
    txt_global_std_MSIG_InsuranceLetter26: 'La police ne couvre pas l&#39;utilisation à la location ou la récompense, les courses, le pacemaking, les essais de fiabilité, les tests de vitesse, le transport de marchandises autres que des échantillons liés à un commerce ou une utilisation ou à une fin quelconque liée au commerce automobile.',
    txt_global_std_acontactyourinsuranceagencytoterminateyourinsurance: "R : Contactez votre agence d'assurance pour résilier votre assurance.",
    txt_global_std_selectinsurance: 'Select Insurance',
    txt_global_std_nameunavailable: 'Nom non disponible',
    txt_global_std_p2pwarningdeletebooking: 'Cela supprimera définitivement la réservation.',
    txt_global_std_numberoftimes: 'Nombre de fois',
    txt_global_std_Insurance_Text9: 'Uppdatera kortuppgifter',
    txt_global_std_menu: ' Menu',
    txt_global_std_InsuranceMail_Text25: 'Further deductible can occurr. See complete Insurance terms.',
    txt_global_std_enteravalidlicenseplate: 'Entrez une plaque d&#39;immatriculation valide!',
    txt_global_std_createpopup: 'Create Popup',
    txt_global_std_tuesday: 'Mardi',
    txt_global_std_Renew_Text1: 'Nå er det snart tid for fornyelse av din avtale. Det betyr at du får utbetalt ditt kjøreutbytte. I dag er ditt kjøreutbytte på',
    txt_global_std_endinsuranceforname: "Mettre fin à l'assurance pour {name} ?",
    txt_global_std_addanote: 'Add a note!',
    txt_global_std_enerfyanalytics_upsell_bulletpoint3: 'Tirez davantage parti des données pour améliorer le retour sur investissement',
    txt_global_std_greatprogress: 'Grand progrès!',
    txt_global_std_car: 'Car',
    txt_global_std_manageyourgroups: 'Gérez vos groupes',
    txt_global_std_PreSaleDetails_Text40: 'It is important that you notify us as soon as possible after the damage occurrs. You are required to participate in inspections that we wish to perform. Damaged items must be retained if we do not permit otherwise. If you override your obligations under the terms, your compensation can be reduced or withheld. Compensation is usually reduced by 25% but by serious negligence, you may be completely without compensation. If the claims, by intent or gross negligence, failed to mention or concealed something of importance and this has been of importance to the decision on the right to compensation, an appropriate reduction may be made in accordance to the circumstances.',
    txt_global_std_colleaguespartnersandinvitedguestscanjoinyourbrandedworkspace: 'Les collègues, partenaires et invités peuvent rejoindre votre espace de travail de marque',
    txt_global_std_enablepushnotifications: 'Activer les notifications push',
    txt_global_std_duelscore: 'Duel score',
    'txt_global_std_When the indicator light is red the reader is properly plugged in to the OBD port on your car. A green light means the reader is connected to your phone via bluetooth. If there is no indicator light, the reader is either in sleep mode or not properly plugged into the OBD port.': 'When the indicator light is red the reader is properly plugged in to the OBD port on your car. A green light means the reader is connected to your phone via bluetooth. If there is no indicator light, the reader is either in sleep mode or not properly plugged into the OBD port.',
    txt_global_std_messages: ' Messages',
    txt_global_std_drivers: 'Drivers',
    txt_global_std_p2puploadaprofileimage: 'Télécharger une image de profil',
    txt_global_std_createyourownworkspaceinthefiasmartdrivingchallengeyoucancreatemanyworkspacessuitableforcustomersandotherpartnersinviteandgettoknowmoreaboutdrivingandyourco2imprint: 'Créez votre propre espace de travail dans le FIA Smart Driving Challenge. Vous pouvez créer de nombreux espaces de travail, adaptés aux clients et autres partenaires. Invitez et apprenez-en plus sur la conduite et votre empreinte CO2.',
    txt_global_std_makeapayment: 'Effectuer un paiement',
    txt_global_std_byenteringyourphonenumberassociatedwithswish: "By entering your phone number associated with swish, the lender of your car can easily copy your number and then swisha your money via a click in the app. The money is based on driver's driving and represents your insurance cost while borrowing your car. If you do not have Swish, you can move on to the next step.",
    txt_global_std_InsuranceMail_Text2: 'The variable driving premium is based on your mileage. It becomes cheaper the more environmentally friendly and safe you drive. If you do not drive connected, your driving premium will be based on level 10 or higher.',
    txt_global_std_dashboard: 'Tableau de bord',
    txt_global_std_addyourlogotypeandchoosecolorthemeexpandtoseeapreviewofthechallenge: 'Ajoutez votre logotype et choisissez le thème de couleur. Développez pour voir un aperçu du défi.',
    txt_global_std_updatecardinformation: 'Update card information',
    txt_global_std_selectaparent: 'Sélectionnez un parent',
    txt_global_std_accept: ' Accepter',
    txt_global_std_p2pfaq27a: 'Vous pouvez donner aux membres certains des privilèges du propriétaire.\n' +
      "Ce que vous pouvez faire : Gérer les réservations (approuver/refuser les réservations des autres membres, modifier l'heure de réservation, etc.)",
    txt_global_std_bluetoothid: 'Identifiant Bluetooth',
    txt_global_std_timelocationaretheonlymandatoryfieldspleaseuploadpicturesofyourcarwithin24hours: "L'heure et le lieu sont les seuls champs obligatoires. Veuillez télécharger des photos de votre voiture dans les 24 heures.",
    txt_global_std_cantfindyourevent: 'Vous ne trouvez pas votre événement ?',
    txt_global_std_step: 'Étape',
    txt_global_std_ihavesoldmycar: 'I have sold my car',
    txt_global_std_privacypolicy: ' Politique de confidentialité',
    txt_global_std_improveyourdrivingandtheplanet: 'Améliorez votre conduite et protégez la planète',
    txt_global_std_pleasetryagain: 'Veuillez réessayer',
    txt_global_std_Insurance_Reminder: 'REMINDER',
    txt_global_std_youcanfindotheruserguidesifyougotothefaqinthemoremenu: "Vous pouvez trouver d'autres guides d'utilisation si vous accédez à la FAQ dans le menu Plus.",
    txt_global_std_mystatistics: 'My statistics',
    txt_global_std_remembertotakepicturesofthedamageandsavethedatetimeandplace: "N'oubliez pas de prendre des photos des dommages, et enregistrez la date, l'heure et le lieu.",
    txt_global_std_topecodriver: 'Top éco-conducteur',
    txt_global_std_receiveinvoiceviasms: 'Receieve invoice via sms',
    txt_global_std_p2pclosethispopup: 'Fermer cette popup',
    txt_global_std_highestscore: 'Score le plus élevé',
    txt_global_std_sentyouafriendrequest: 'Sent you a friend request',
    txt_global_std_slideintrotext4: 'Safety & Info- help is only one click away',
    txt_global_std_totaldistance3: 'Distance totale',
    txt_global_std_pushdriveendlevel3: 'Gud kørsel. Tryk på mig for at se din feedback.',
    txt_global_std_Reciept_Text1: 'Welcome! In this email you will find your reciept of your recent payment.',
    txt_global_std_PreSaleDetails_Text59: 'Insurance Validity',
    txt_global_std_emptycompetitions: 'Time to have fun! Join a competition!',
    txt_global_std_viewmarketingmessages: ' Afficher les nouvelles dans Activité',
    txt_global_std_tex2: 'T.e.x. 2',
    txt_global_std_p2pfaq28q: "Je veux ajouter des droits d'administrateur à un ami",
    txt_global_std_logininformation: 'Informations de connexion',
    txt_global_std_bonus: ' Bonus',
    txt_global_std_uploadyourlogoandaddyourcolorstomaketheappyourown: "Téléchargez votre logo et ajoutez vos couleurs pour personnaliser l'application.",
    txt_global_std_activepoints: 'Points actifs',
    txt_global_std_addyourpolicynumbertobeabletoenjoyallthebenefitsandclaimyourrewards: 'Ajoutez votre numéro de police pour pouvoir profiter de tous les avantages et réclamer vos récompenses !',
    txt_global_std_rewards: 'Récompenses',
    txt_global_std_yourthreemostimportantdrivingtips: ' Les 3 astuces de conduite importantes',
    txt_global_std_KleinschmidtQuote: 'Ensemble nous pouvons rendre le monde meilleur ! Rejoignez cette compétition et participez à la grande contribution respectueux d l’environnement et à la sécurité de conduite. Améliorez vos capacités de la conduite « smart » et amusez-vous dans cette compétition fantastique !',
    txt_global_std_avgdriver: 'Moy. Chauffeur',
    txt_global_std_top3bestdriverscomingsoon: 'Top 3 des meilleurs pilotes à venir',
    txt_global_std_avgcosavings: 'Moy. Économies de CO₂',
    txt_global_std_yourcarhasnowanactivatedinsurance: 'Your car now has an activated insurance',
    txt_global_std_p2pminimumbookingtime: 'Le temps minimum de réservation est de 30 minutes',
    txt_global_std_p2pchoosecar: 'Choisissez une voiture',
    txt_global_std_syncedwithgarminconnect: 'Synchronisé avec Garmin Connect™',
    txt_global_std_entertheactivationcodeyouhavereceivedfromyourworkspaceadministrator: "Entrez le code d'activation que vous avez reçu de votre administrateur d'espace de travail",
    txt_global_std_send2: 'Envoyer',
    txt_global_std_helloyoufindmycarheremylocationislocationatposition: 'Hello! You find my car here. My location is {location} at {position}.',
    txt_global_EnerfyLeaderboardTemplate_keepituptext: ' Continuez à adopter une conduite éco-responsable et vous grimperez rapidement au classement !',
    txt_global_std_removingaparticipantfromachallengedoesnotdeletetheuserortheirdrivingdataintheappiftheyhavealreadydriven: "Supprimer un participant d'un défi ne supprime pas l'utilisateur ou ses données de conduite dans l'application, s'il a déjà conduit.",
    txt_global_std_drivingisalotmorefunwithanenerfyreader: 'Driving is a lot more fun with an {appName} Plug In',
    txt_global_std_readmore1: 'Lire la suite',
    txt_global_std_carstats: 'Statistiques de voiture',
    txt_global_std_comingup: 'À venir',
    txt_global_std_viewall: 'Voir tout',
    txt_global_std_PaymentTrouble3_Text5: 'If the premium is not paid before 28th',
    txt_global_std_showonmap: 'Montrer sur la carte',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint3: 'Tirez davantage parti des données pour améliorer le retour sur investissement',
    txt_global_std_activationcodeemail2: "Voici votre code d'activation",
    txt_global_std_syncyourgarminconnectaccount: 'Synchronisez votre compte Garmin Connect™',
    txt_global_std_changepassword: 'Changer de mot de passe',
    txt_global_std_garmin15discountcodeforparticipantsandprizesformonthlywinners: 'Code de réduction Garmin de 15 % pour les participants et prix pour les gagnants du mois.',
    txt_global_std_sooncompleted: ' Bientôt fini !',
    txt_global_std_maycontactmeonphoneemailandsms: 'Oui, {appName} and Greater Than may contact me on phone , par email , par {appName} et par SMS afin de recevoir des nouvelles et des offres spéciales de Greater Than concernant l’assurance, les produits de prévention des blessures, la concurrence et les essais d’autres services. Vous pouvez toujours lire et préférer vos références pour le marketing.',
    txt_global_std_skilluplockedkey: 'Pour obtenir ce badge, conservez un score supérieur à {score} pendant une semaine.',
    txt_global_std_documenttheaccident: 'Document the Accident',
    txt_global_std_mypage: ' Ma page',
    txt_global_std_MSIG_InsuranceLetter15: 'À',
    txt_global_std_ChicheritMileage: 'Coming soon...',
    txt_global_std_insurancenumber2: 'Numéro d&#39;assurance',
    txt_global_std_activitydetails: ' Détails de l’activité',
    txt_global_std_lockeddescription: "Verrouillé Lorsque le badge n'a pas <br />encore été reçu",
    txt_global_std_TomczykQuote: 'Le FIA Smart Driving Challenge m’a encouragé de conduire de manière économique, sûre et avec plus d’attention. Après votre déplacement, ayant eu un score en temps réel, vous pouvez consulter votre résultat et le comparer avec les autres compétiteurs dans le monde entier.\n' +
      'Rejoignez mon équipe et laissons-nous gagner le maximum des points ensemble. Relevez le défi en essayant de devenir le meilleur et le plus « smart » conducteur au monde.',
    txt_global_std_level2: 'Niveau',
    txt_global_std_ChicheritBesttip: 'Coming soon...',
    txt_global_std_p2plendborrow: 'Prêter emprunter',
    txt_global_std_p2pchoosecarcarstolend: 'Choisissez la voiture / voitures à prêter',
    txt_global_std_ecodrivers: 'ECO drivers',
    txt_global_std_newchallenge: 'Nouveau défi',
    txt_global_std_p2pfaq28a: "Vous pouvez sélectionner des membres et leur donner des privilèges d'administrateur pour la voiture.\n" +
      '1. Sélectionnez « Membres » dans le « Menu ».\n' +
      '2. Sélectionnez un membre pour afficher votre profil.\n' +
      '3. Sélectionnez Paramètres.\n' +
      "4. Appuyez sur le bouton de la voiture à laquelle vous souhaitez autoriser l'autorisation.",
    txt_global_std_FriendRequest_Text5: 'Venlig hilsen',
    txt_global_std_Insurance_SeeBelow: 'See below',
    txt_global_std_PreSaleDetails_Text26: 'Glass',
    txt_global_std_yourobdreaderhasalreadybeenorderedandyoucanexpectthereadertoarrivewithin35businessdays: 'Votre lecteur OBD a déjà été commandé et vous pouvez vous attendre à ce que le lecteur arrive dans les 3 à 5 jours ouvrables.',
    txt_global_std_p2ppushtitleborrowcarnomessage: 'Nouvelle demande d&#39;emprunt reçue',
    txt_global_std_regno2: 'Reg. No',
    txt_global_std_otherpleasspecifyinthefieldbelow: 'Ohter',
    txt_global_std_PaddonMusic: 'When driving, I’d like to listen to relaxing/mellow music like Coldplay, Mumford and Sons etc.',
    txt_global_std_sdclevel: 'Niveau SDC:',
    txt_global_std_ifyoudonthavebluetoothinyourcaryoucanpluginaobdreaderanobdreadercanbepluggedintocarsmanufacturedyear2001andlater: "Si vous n'avez pas de Bluetooth dans votre voiture, vous pouvez brancher un lecteur OBD.\n" +
      '\n' +
      'Un lecteur OBD peut être branché sur les voitures fabriquées à partir de 2001.',
    txt_global_std_validateyourdata: 'Validez vos données',
    txt_global_std_kintoEncouragement2: 'Votre conduite sauve la planète! Vous économisez {percent} % contre la moyenne.',
    txt_global_std_InsuranceChange_Text3: 'I och med detta har dina premier förändrat. Se bifogat försäkringsbrev i mailet.',
    txt_global_std_entercodehere: 'Entrez le code ici',
    txt_global_std_youbooked15timesinonemonthyouhaveearned3500points: 'Vous avez réservé 15 fois en un mois. Vous avez gagné 3500 points.',
    txt_global_std_getaninsurance: 'Get an insurance',
    txt_global_std_p2pgotomykinto: 'Aller à mon Kinto',
    txt_global_std_savetheenvironment: 'Save the environment',
    txt_global_std_enteremailaddressyoucanentermorethanoneifyouseparatethembyacomma: "Entrez l'adresse e-mail, vous pouvez en saisir plusieurs si vous les séparez par une virgule.",
    txt_global_std_trytheapp: "Essayez l'application",
    txt_global_std_firstdrivelocked: 'Vous débloquez ce succès lorsque vous conduisez votre première fois.',
    txt_global_std_onceaclaimsettlementisdeterminedyoucanreceiveacheckoranelectronicpaymentintoyourbankaccount: "Une fois le règlement d'une réclamation déterminé, vous pouvez recevoir un chèque ou un paiement électronique sur votre compte bancaire.",
    txt_global_std_disabled: 'désactivé',
    txt_global_std_youmustpickaninsurance: 'Vous devez choisir une assurance',
    txt_global_std_changenow: 'Change maintenant',
    txt_global_std_areyousureyouwanttoremoveuserfromthegroupremovingtheuserfromthegroupdoesnotdeletetheusersaccount: "Voulez-vous vraiment supprimer l'utilisateur du groupe ?\n" +
      '\n' +
      "La suppression de l'utilisateur du groupe ne supprime pas le compte de l'utilisateur.",
    txt_global_std_earn2500pointsifyoubook10timesamonth: 'Gagnez 2500 points si vous réservez 10 fois par mois',
    txt_global_std_p2perrorcodestartofbookingisoverlapping: 'Le début de la réservation se chevauche.',
    txt_global_std_createaccount: 'Créer un compte',
    txt_global_std_activationcode2: 'Code d&#39;activation',
    txt_global_std_thisisatranslation: 'Ceci est une traduction.',
    txt_global_std_PaymentTrouble1_Text7: 'Your payment / bank card is invalid or expired',
    txt_global_std_Insurance_Terms: 'Insurance terms',
    txt_global_std_p2pseefriendcode: "Afficher ou utiliser les codes d'ami",
    txt_global_std_Incidentplace: 'Skadeplats',
    txt_global_std_activatebluetoothinsettings: ' Activez le Bluetooth sur votre téléphone',
    txt_global_std_challengeaverage2: 'Moyenne du défi :',
    txt_global_std_usermanagement: 'Gestion de groupe',
    txt_global_std_helptextsavingstotaltext: 'Når du kjører trygt og med god flyt, legger vi penger i sparegrisen din. Disse får du utbetalt når forsikringsåret er omme, sammen med penger for kilometere du eventuelt ikke har brukt. Totalbeløpet inkluderer startbonus, penger spart for trygg kjøring og penger for venneverving.',
    txt_global_std_publishyourdemoapp: 'Publiez votre application de démonstration',
    txt_global_std_dateoftraining: 'Date de la formation',
    txt_global_std_gotopopuplist: 'Go to popup list',
    txt_global_std_saveandcontinue: 'Sauvegarder et continuer',
    txt_global_std_maxcharacters: 'Max. 15 caractères',
    txt_global_std_locationofaccident: "Lieu de l'accident",
    txt_global_std_leaderboardoverview: 'Aperçu du classement',
    txt_global_std_thechallengenamecantbelongerthan30characters: 'Le nom du défi ne peut pas dépasser 30 caractères',
    txt_global_std_createnewachievement: 'Créer une nouvelle réalisation',
    txt_global_std_matched: 'Apparié',
    'txt_global_std_Location services are active': 'Location services are active',
    txt_global_std_pleasereportyouraccidentusingtheclaimsformassoonaspossible: 'Veuillez déclarer votre accident en utilisant le formulaire de réclamation dans les plus brefs délais.',
    txt_global_std_retardationdescription: ' Planifiez votre conduite. Économisez l’énergie du véhicule en relâchant plus tôt la pédale de frein.',
    txt_global_std_PreSaleDetails_Text47: 'Emergency Assistance',
    txt_global_std_cropyourteamimage: ' Rogner l’image de votre équipe',
    txt_global_std_p2ppleaseselectadate: 'Veuillez sélectionner une date',
    txt_global_std_examplephone: '12 34 56 78',
    txt_global_std_onboardingnotificationstext: "Autorisez {{appName}} à vous envoyer des notifications lorsque vous avez des actions à effectuer dans l'application.",
    txt_global_std_verygooddriving: 'Très bonne conduite!',
    txt_global_std_p2ppushbodychangeperiodfromto: '{name} changé de période. De: {from} à: {to}',
    txt_global_std_somethingwentwrongpleasetryagain: 'Something went wrong, please try again.',
    txt_global_std_ecosafeearned: 'EcoSafe Earned',
    txt_global_std_HighMileage_Hello: 'Hei!',
    txt_global_std_NotCreatedAccount5: "You still haven't verified your insurance",
    txt_global_std_Insurance_Product: 'Product',
    txt_global_std_motorclaimshotline2: 'Hotline pour les réclamations automobiles',
    txt_global_std_uploadphoto: ' Télécharger la photo',
    txt_global_std_challengeleaderboard: 'Classement du défi',
    txt_global_std_boundlerevend: 'End date insurance',
    txt_global_std_doyouhaveanymileagethatyoufancy: 'Avez-vous un trajet que vous adorez faire en particulier, et pourquoi?',
    txt_global_std_byinvitingfriends: 'En invitant des amis',
    txt_global_std_kintoImprovement1: 'S`il vous plait, conduisez prudemment! Nous nous soucions de vous!',
    txt_global_std_p2pyoublockedyourcar: 'Vous avez bloqué votre voiture',
    txt_global_std_skillupunlockedkey: 'Excellent travail {utilisateur} ! Vous avez conservé un score supérieur à {score} pendant une semaine.',
    txt_global_std_amount: 'Amount',
    txt_global_std_NotCreatedAccount2_Hello: 'Hello',
    txt_global_std_100000pts: '> 100 000 points',
    txt_global_std_tomakethisactionyouneedtohaveanaccountyoudoiteasilywithanemailaddressfacebookortwitteraccount: 'To make this action you need to have an account. You do it easily with an email address, Facebook or Twitter account.',
    txt_global_std_p2pforfamilyfriends: 'Pour la famille et les amis',
    txt_global_std_foryourowndocumentation: 'Pour votre propre documentation :)',
    txt_global_std_OgierMileage: 'Coming soon...',
    txt_global_std_xmaximize: 'Maximize',
    txt_global_std_jun: ' Jun.',
    txt_global_std_TidemandFacts: 'Le premier gout de Pontus dans la compétition est le cross-karts, où il devient le champion de la Suède et des pays du Nord. En 2011 il est le pilote le plus jeune a avoir remporté le championnat de rallye de Suède. ',
    txt_global_std_thisfeatureisnotavailableyet: "Cette fonctionnalité n'est pas encore disponible",
    txt_global_std_reason1: 'raison',
    txt_global_std_sendtoemail: 'Send to email',
    txt_global_std_createyourownchallengetoinspiresmartdrivingexpandtoseeapreviewofthechallenge: 'Créez votre propre défi pour inspirer la conduite intelligente ! Développez pour voir un aperçu du défi.',
    txt_global_std_friendfinder: "Recherche d'amis",
    txt_global_std_p2pfaq25q: 'Où puis-je vérifier les données de conduite ?',
    txt_global_std_agreements: 'les accords',
    txt_global_std_lexusweekend: 'Week-end Lexus',
    txt_global_std_chooseyourmatchinginsurance: 'Choose your matching insurance',
    txt_global_std_5000pointaverageachievementunlocked: 'Vous avez extrêmement bien conduit! Votre {scoretype} était mieux que {score} pendant une semaine! Vous avez gagné 5000 points!',
    txt_global_std_carstatstext: 'Statistiques de voiture',
    txt_global_std_claims: 'Réclamations',
    txt_global_std_sendinvitationby: 'Envoyer une invitation par',
    txt_global_std_wewouldrecommenddrivertraining: 'Nous recommandons la formation des conducteurs avec une attention particulière sur la concentration et la planification du conducteur. Autrement dit, ne perdez jamais de vue le trafic et les conditions qui vous entourent. Nous sommes Yamato et nous concentrons et planifions toujours la conduite avec une attention particulière à la gentillesse, à la priorisation, à la maîtrise de soi émotionnelle et à la confiance.',
    txt_global_std_tttest97: "c'est un essai 97",
    txt_global_std_howtogetstarted: 'Comment commencer',
    txt_global_std_searchandmanageusers: 'Rechercher et gérer les utilisateurs',
    txt_global_std_ShareCar_Text9: 'Har du frågor om hur det fungerar att låna bilen med {appName}?',
    txt_global_std_Insurance_Number: 'Insurance number',
    txt_global_edj_nonobd: 'NonOBD',
    txt_global_std_fixedpremium: 'Prime fixe',
    txt_global_std_insuranceoverviewuser: 'Insurance overview user',
    txt_global_std_namewasremovedfromtheteam: '{name} was removed from the team',
    txt_global_std_createanaccount: 'Créer un compte',
    txt_global_std_open: ' Ouvrir',
    txt_global_std_connectyourcarandstartadrive: 'Connectez votre voiture et démarrez pour voir vos économies estimées. Appuyez sur le bouton de conduite pour commencer.',
    txt_global_std_sharingiscaring: 'Sharing is caring',
    txt_global_std_whatifichangecaranswer: 'Informez l&#39;équipe {appname} par e-mail. Reconnectez-vous ensuite à votre nouvelle voiture en suivant à nouveau les étapes «Se connecter à la voiture».',
    txt_global_std_greaterthansprivacypolicy: 'notre Politique de confidentialité.',
    txt_global_std_followupontheonboardingstatusofyourusersclickonastatusbarbelowtoseeandhelpyourusersthatmightbestuck: "Suivez l'état d'intégration de vos utilisateurs, cliquez sur une barre d'état ci-dessous pour voir et aider vos utilisateurs qui pourraient être bloqués.",
    txt_global_std_p2pwelcomekintoowner: 'Bienvenue, propriétaire de Kinto!',
    txt_global_std_p2ppleasetypeprice: 'Veuillez saisir le prix',
    txt_global_std_unpublishtranslations: 'Annuler la publication des traductions',
    txt_global_std_p2pendtime: 'Heure de fin:',
    txt_global_std_clickheretocallsupport: "Cliquez {ici} pour appeler l'assistance",
    txt_global_std_50km: '50 Km',
    txt_global_std_loyalty_upsell_pitch_bulletpoint3: 'Augmentez la visibilité de votre programme pour offrir le meilleur retour sur investissement possible',
    txt_global_std_kintoEncouragement1: 'Hou la la! Vous êtes parmi les meilleurs pilotes Kinto!',
    txt_global_std_publishapptest: "Publier le test de l'application",
    txt_global_std_startadrive_: 'Démarrer un lecteur',
    txt_global_std_300pointTripAchievementunlocked: 'Vous avez conduit à {scoretype} {score} ou mieux! Impressionnant! Vous avez gagné 300 points!',
    txt_global_std_p2pemailunreadmessages: 'Messages non lus',
    txt_global_std_ecofriendlockedkey: 'Conduisez de manière écologique et économisez plus de {co2value} % de CO₂ en une semaine pour gagner ce badge.',
    txt_global_std_p2ppushtitlenewinvoicetopay: 'New invoice to pay',
    txt_global_std_rateapp: ' Noter l’application',
    txt_global_std_CarFound: 'Är fordonet återfunnet',
    txt_global_std_sentyouahead2headrequest: 'Sent you a challenge',
    txt_global_std_fuel: 'Fuel',
    txt_global_std_reportcentersworkshops: 'Centres de rapport et ateliers',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint1: 'Encouragez les conducteurs à acquérir de nouvelles compétences grâce à la gamification',
    txt_global_std_invitelink: "Lien d'invitation",
    txt_global_std_cimage8d: ' Accélérez tout en douceur lorsque la circulation vous oblige à varier considérablement votre vitesse.',
    txt_global_std_loyalty_upsell_pitch_bulletpoint2: "Récompenser les conducteurs intelligents pour encourager l'engagement et la rétention",
    txt_global_std_TeamInvite: 'You have been invited to a team',
    txt_global_std_somethingwentwronginsurancenotterminated: 'Something went wrong! Insurance is not terminated.',
    txt_global_std_p2plendmycartwo: 'Prête ma voiture',
    txt_global_std_HusseinAbdullaBesttip: 'Patience',
    txt_global_std_connectyourcar: 'Connect you car!',
    txt_global_std_youwillcomebacktothesesettingsthenexttimeyoupressthedrivebutton: "Vous reviendrez à ces paramètres la prochaine fois que vous appuierez sur le bouton d'entraînement.",
    txt_global_std_advancedadmin_upsell: "Advanced Admin - portail d'auto-gestion simple pour une expérience utilisateur puissante.",
    txt_global_std_selecttime: "Sélectionnez l'heure",
    txt_global_std_deletingthegroupdoesnotdeletetheusersaccounts: 'La suppression du groupe ne supprime pas les comptes des utilisateurs.',
    txt_global_std_addyourmileage: 'Ajoutez votre kilométrage',
    txt_global_std_totalnooftrips: 'Nombre total de voyages',
    txt_global_std_thatwasaverygooddrive: 'C&#39;était un très bon trajet!',
    txt_global_std_weeklyheat: 'Chaleur hebdomadaire',
    txt_global_std_dogooddeeds: 'Do good deeds & drive smart & get a cash bonus after one year',
    txt_global_std_enerfyanalytics_upsell: "Enerfy Analytics - outil d'analyse de données facile à utiliser pour des rapports puissants.",
    txt_global_std_mediumrisk: 'Risque moyen',
    txt_global_std_ConfirmFia_Text12: ' Veillez à arriver sur les lieux 10 minutes avant l’heure prévue. Notre équipe vous aidera à vous enregistrer et à trouver votre voiture.',
    txt_global_std_PreSaleDetails_Text22: 'Half Insurance (Partial casco)',
    txt_global_std_finishadriveatscoretypescoreorbettertounlockthisbadge: 'Terminez un trajet à {scoretype} {score} ou mieux pour déverrouiller ce badge.',
    txt_global_std_pleaseenterthenameofyourcontactperson: 'Veuillez entrer le nom de votre personne de contact',
    txt_global_std_sendinvitetocountfriends: ' Envoyer une invitation à vos {count} amis ?',
    txt_global_std_insurancedetails: 'Insurance details',
    txt_global_std_kintorefer50friendslocked: 'Débloquez ce succès et gagnez 5000 ¥ en parrainant 50 utilisateurs Kinto.',
    txt_global_std_userhasnopermissions: "L'utilisateur n'a aucune autorisation",
    txt_global_std_v2level: 'Niveau',
    txt_global_std_soonyouwillbeabletochallengeotherdriversinsafeandecofriendlydriving: "Bientôt, vous pourrez défier d'autres conducteurs dans une conduite sûre et écologique.",
    txt_global_std_civilstatus: 'Etat civil',
    txt_global_std_qhowdoisignupforcovercubeinsurance: "Q : Comment souscrire à l'assurance Covercube ?",
    txt_global_std_ifyouhaveone: '-if you have one',
    txt_global_std_drivingstatisticstabs: 'Onglets statistiques de conduite',
    txt_global_std_signinsurance: 'Sign insurance',
    txt_global_std_p2pcheckyouremail: 'Vérifiez votre e-mail',
    txt_global_std_introslide2text: 'See the cost after every drive that becomes lower by driving smart.',
    txt_global_std_NotCreatedAccount2_Text5: 'Venlig hilsen {appName}-Teamet',
    txt_global_std_whatisyourbesttiptodrivesmart: 'Quel est votre meilleur astuce pour conduire de manière "smart"?',
    txt_global_std_call: ' Appeler',
    txt_global_std_Invite_Text1: 'has invited you to the {appName}team',
    txt_global_std_test: 'tester',
    txt_global_std_wanttoshareyourprogresswithyourfriends: 'Vous voulez partager vos progrès avec vos amis ?',
    txt_global_std_yourcar2: 'Ta voiture',
    txt_global_std_fitmarkers: 'Ajustement des marqueurs',
    txt_global_std_PaymentTrouble1_Text11: 'Sincerely,\r\n{appName}',
    txt_global_std_autostartobd: ' Démarrage automatique du système de diagnostic embarqué',
    txt_global_std_theindividualdriverscoreiscalculatedonanaveragebasedonthetop10trips: '• Le score individuel du conducteur est calculé sur une moyenne basée sur les 10 % des meilleurs trajets.',
    txt_global_std_excellent: ' Excellent',
    txt_global_std_email: ' E-mail',
    txt_global_std_sdcrules: 'Règles du SDC',
    txt_global_std_ridewithmetext: "Partager un trajet avec un ami au lieu d'utiliser deux voitures permet d'économiser beaucoup de CO2 ! En enregistrant un trajet en covoiturage, vous et votre ami gagnerez des points.",
    txt_global_std_howwillyouknowaboutmydrivingbehaviour: 'How will you know about my driving behavior?',
    txt_global_std_howdoicompeteinlexuschallengeanswer: 'Vous participez automatiquement lorsque vous vous inscrivez. Ne vous inquiétez pas, le classement n&#39;affichera que votre surnom - vous resterez donc toujours anonyme. Il y a un concours mensuel et annuel.',
    txt_global_std_enerfyisaproductfromgreaterthanab: 'Enerfy est un produit de Greater Than AB.',
    txt_global_std_InsuranceMail_Daypremium: 'Day premium is paid at ',
    txt_global_std_cwelcome9: 'Rise and shine',
    txt_global_std_ecofeedbacktips: "<b> Votre éco-score mesure le degré de respect de l'environnement que vous conduisez. </b> Il peut être lié de nombreuses manières à votre planification. <br> <br>\n" +
      "<b> Ajustez vos accélérations </b> pour éviter les freins et les arrêts inutiles. Chaque accélération et chaque frein consomment de l'énergie. <br> <br>\n" +
      'Ensemble, nous pouvons <b> faire une différence </b> pour l’avenir.',
    txt_global_std_therulesforthecompanychallengeareasfollowing: 'Les règles du challenge entreprise sont les suivantes :',
    txt_global_std_hhmmss: ' Hh:mm:ss',
    'txt_global_std_Your friend request to {firstName} has been sent': 'Your friend request to {firstName} has been sent.',
    txt_global_std_iwantrematch: 'Je veux une revanche!',
    txt_global_std_httpstatuscodesummary: "Résumé du code d'état HTTP",
    txt_global_std_yourteamcouldreallyuseapicture: 'Add a photo to create your team!',
    txt_global_std_errors: 'les erreurs',
    txt_global_std_availablepointexchange: 'Échange de points disponibles',
    txt_global_std_p2punavailable: 'Indisponible',
    txt_global_std_daysleftuntilrenewal: "Jours restants jusqu'au renouvellement",
    txt_global_std_trainername: 'Nom du formateur',
    txt_global_std_ActivationLetter_Text4: 'Our customers mean everything to us and we are happy to have you as a customer. You are fully insured from your chosen start date and have 7 days to get started with {appName} Plug-in.',
    txt_global_std_required: 'Obligatoire',
    txt_global_std_ifyouwantinfoonnotificationscallphonenumberorgotoanchorlinkorgotorouterlinkpage: 'Si vous souhaitez obtenir des informations sur les notifications, appelez {phoneNumber} ou accédez à {anchorLink} ou accédez à la page {routerLink}.',
    txt_global_std_Insurance_AmountCaps: 'AMOUNT',
    txt_global_std_personaldetails: 'Personal details',
    txt_global_std_p2pyouborrowedusersvehiclextimes: 'Vous avez emprunté {user} {car} {times} fois',
    txt_global_std_topthreeworldteams: 'Top 3 des équipes mondiales',
    txt_global_std_reporting: 'Faire rapport',
    txt_global_std_attributes: 'Les attributs',
    txt_global_std_checkyouremail: "Check your e-mail. If you dont have a code yet you can come back later, you'll find this under More.",
    txt_global_std_reportdamage: 'Signaler des dommages',
    txt_global_std_Insurance_IncludePlugIn: 'included {appName} Starter Pack',
    txt_global_std_welcomebackenerfy: 'Ravis de vous revoir sur {appName} !',
    txt_global_std_p2pfinishandpay: 'Terminer et payer',
    txt_global_std_mylatesttrips: 'My latest trips',
    txt_global_std_p2pcash: 'En espèces',
    txt_global_std_readmoreabout: 'Read more about',
    txt_global_std_kintofaq7q: 'Ma voiture n&#39;a pas de Bluetooth?',
    txt_global_std_premiumgroups: 'Premium rates',
    txt_global_std_slideintrotext3: 'Keep track of your costs',
    txt_global_std_maintainalowrisklevelbykeepingyourfocusontheroadandplanahead: "Maintenez un niveau de risque faible en restant concentré sur la route et en planifiant à l'avance.",
    txt_global_std__emailaddress: 'Adresse e-mail',
    'txt_global_std_Its time for some fun. Join a challenge!': ' Le moment est venu de se divertir. Rejoignez un défi !',
    txt_global_std_fuelinjectorcleaner: 'Nettoyant injecteur de carburant',
    txt_global_std_BeforeFirstBill_Text6: 'Sincerely,\r\n{appName}',
    txt_global_std_fulllimitedinsurance: 'Full/limited insurance',
    txt_global_std_enteryouremailadress: ' Saisissez votre e-mail',
    txt_global_std_pleasenotethattojoinyoumusthavetheaanzappinstalledonyourmobiledeviceinordertobeabletojointhegroup: "Veuillez noter que pour rejoindre l'équipe, vous devez avoir installé l'application FIA SDC sur votre appareil mobile afin de pouvoir rejoindre l'équipe.",
    txt_global_std_avgkm7d: 'Moy km 7D',
    txt_global_std_finished: 'Terminé',
    txt_global_std_Insurance_Billnumber: 'Invoice number',
    txt_global_std_returntoooverview: "Retour à l'aperçu",
    txt_global_std_turnonbluetoothviayourcarsinfotainmentsystemstereoandfollowtheinstructionsforpairingyourphone: "Activez Bluetooth via le système d'infodivertissement/stéréo de votre voiture et suivez les instructions pour coupler votre téléphone.",
    txt_global_std_howdoiearnsavingslexusanswer: 'Des économies sont générées lorsque vous conduisez prudemment. Pour chaque trajet que vous conduisez en toute sécurité, vous générez une économie au kilomètre. Plus vous conduisez en sécurité, plus vous économisez! L&#39;économie est visible directement après la fin de votre voyage et sera visible dans votre portefeuille Lexus.',
    txt_global_std_finishedtraining: 'Formation terminée',
    txt_global_std_savechangespopupheader: 'Sauvegarder les modifications?',
    txt_global_std_repeatyournewpassword: 'Répétez votre nouveau mot de passe',
    txt_global_std_topay: 'To pay',
    txt_global_std_prefernottosay: 'Je préfère ne pas le dire',
    txt_global_std_Reminder_Text6: '* If your car insurance is not paid, The Motor Insurers Association (TFF) takes over the debt and the handling of the unpaid premium for the time the policy has been effective with {appName}. TFF charge an extra high daily fee for every day that your car is not insured. Traffic insurance is obligatory and means that you are insured against personal injury and damage to property of another.',
    txt_global_std_RecruitFriend: 'You have been recruited to {appName} by a friend',
    txt_global_std_HighMileage_Text8: 'Hilsen,\r\n{appName}-gjengen i Tryg Forsikring',
    txt_global_std_coverimage: 'Image de couverture:',
    txt_global_std_pleasefocus: "S'il vous plaît, concentrez-vous!",
    txt_global_std_communication_upsell_pitch_bulletpoint1: 'Concevez facilement votre propre calendrier de messagerie et de communication pour;',
    txt_global_std_NewActivationCode_Hello: 'Hei!',
    txt_global_std_secretuser: 'Secret',
    txt_global_std_redeemcoupon: 'Utiliser le coupon',
    txt_global_std_createanaccounttosavealldrivingdata: 'Create an account to save all driving data, track your routes and save your rewards for future trips.',
    txt_global_std_needhelp: "Besoin d'aide?",
    txt_global_std_usernameshort: ' Nom d’utilisateur',
    txt_global_std_zipriskindexhelpsyoudeterminepreciserisklevelbasedondrivinglocation: "L'indice de risque Zip vous aide à déterminer le niveau de risque précis en fonction du lieu de conduite.",
    txt_global_std_setupyourfiasdccompanychallenge: 'Configurez votre FIA SDC Company Challenge.',
    txt_global_std_mycardoesnthavebluetooth: 'Ma voiture n&#39;a pas de Bluetooth?',
    txt_global_std_firstscore: 'Soyons le premier avec le score dans la chaleur',
    txt_global_std_p2pallofyourtripsgatheredinoneplacegetaccesstofeedbackandstatisticsaboutyourdrives: 'Tous vos voyages réunis en un seul endroit. Accédez aux commentaires et aux statistiques sur vos lecteurs.',
    txt_global_std_yousaved: ' Vous avez sauvegardé',
    txt_global_std_PolicySchedule6: 'jusqu&#39;à minuit',
    txt_global_std_youneedtoinputanemail: ' Vous devez saisir un e-mail',
    txt_global_std_noregnumberregistered: 'Aucun numéro d&#39;enregistrement enregistré',
    txt_global_std_socialsecuritynumber: ' Numéro de sécurité sociale',
    txt_global_std_youarearisingstar: 'You are a rising star',
    txt_global_std_skeyscore: 'Score',
    txt_global_std_participants: 'Participants',
    txt_global_std_qhowdoisignupforcompanyinsurance: 'Q : Comment puis-je souscrire à une assurance [Entreprise] ?',
    txt_global_std_yourpasswordmustconsistofatleastfive: ' Votre mot de passe doit contenir au minimum cinq (5) caractères.',
    txt_global_std_p2perrorcodeendofbookingisoverlapping: 'La fin de la réservation se chevauche',
    txt_global_std_ShareCar_Text3: 'Det enda du behöver göra är att komma överens med bilägaren om hur ni gör med bilnycklarna, resten fixar vi så fort du har laddat ned {appName}appen.',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint1: 'Inspirez une conduite plus intelligente, contribuant à réduire les risques, la consommation de carburant et les émissions de CO2',
    txt_global_std_headingemptyortolong: 'Heading empty or to long',
    txt_global_std_ecosavings: 'Eco savings',
    txt_global_std_youcanfindyourresultshortlyinyourtrips: 'Vous pourrez retrouver votre résultat sous peu dans vos déplacements.',
    txt_global_std_HighUsageAlarm_Text5: 'Koncentrere dig 100 % om at køre bil – brug aldrig mobilen når du kører ',
    txt_global_std_p2pbookingfinished: 'Réservation terminée',
    txt_global_std_ecoscore: 'ECO score',
    txt_global_std_alltabtext: 'Tout',
    txt_global_std_buynow: 'Acheter maintenant',
    txt_global_std_myleague: ' Ma division',
    txt_global_std_startdrivingtoappearonleaderboard: 'Commencez à conduire pour apparaître dans le classement!',
    txt_global_std_toprovideyouwithaccuratestatsanddrivingscoreappnameneedstoruninbackgroundmodebrinthenextpopupbrchooseballowallthetimeb: 'Pour vous fournir des statistiques précises et un score de conduite, {appname} doit fonctionner en arrière-plan. <br> Dans la fenêtre contextuelle suivante: <br> Choisissez: <b> Autoriser tout le temps </b>',
    txt_global_std_aninsuranceadjusterwillcontactyouwithinhourshoursofsubmittingyourclaimtheentireclaimsprocesswilldetermineonthecomplexityoftheclaim: "Un expert en sinistres vous contactera dans les {heures} heures suivant la soumission de votre réclamation. L'ensemble du processus de réclamation déterminera la complexité de la réclamation.",
    'txt_global_std_2) Make sure that Bluetooth is turned on.': '2) Make sure that Bluetooth is turned on.',
    txt_global_std_recruiter: 'Recruteur',
    txt_global_std_forthisapptoworklocationservices: `Pour que cette application fonctionne correctement et pour enregistrer vos voyages, nous voulons que vous changiez l'autorisation des services de localisation sur "Toujours". Si vous ne le faites pas, vous obtiendrez ce popup plus tard.`,
    txt_global_std_notavalidenddate: 'Not a valid end date',
    txt_global_std_welcomeletsgetstarted: 'Bienvenue! Commençons!',
    txt_global_std_uploadgroupimage: "Télécharger l'image du groupe",
    txt_global_std_married: 'Marié',
    txt_global_std_NotActivatedAccount_Text2: 'If you don’t plan on driving anytime soon, we would very much appreciate it if you let us know via ”My Pages”. Otherwise, we must continuously check your cars meter readings to make sure that everything is in order.',
    txt_global_std_PolicySchedule21: 'OFD',
    txt_global_std_inallourappswehavecontactinformationsothatuserscancontactthecompanyfillinyourcompanyscontactinformationhere: "Dans toutes nos applications, nous avons des informations de contact afin que les utilisateurs puissent contacter l'entreprise. Remplissez ici les coordonnées de votre entreprise.",
    txt_global_std_VilliersFacts: 'Giniel De Villiers n’est pas encore en retraite. Encore mieux, Il n’a fini qu’une fois en dehors du top 10 (2007). Il compte 14 victoires d’étapes et en 2009 il a emporté la première compétition du Dakar de l’Amérique du Sud. ',
    txt_global_std_back: ' Retour',
    txt_global_std_advancedadmin1: '  Administrateur avancé',
    txt_global_std_writeanote: 'Write a note',
    txt_global_std_pleasegivethreeoptionsincaseyourfirstoptionsisalreadyused: 'Veuillez donner trois options au cas où votre ou vos premières options sont déjà utilisées.',
    txt_global_std_yourpasswordmustconsistofatleastten: 'Votre mot de passe doit comprendre au moins dix (10) caractères.',
    txt_global_std_completed: 'Terminé',
    txt_global_std_documentsentto: 'Document envoyé à',
    txt_global_std_estimatedsavings: 'Économies estimées',
    txt_global_std_maybesavedwhenfocusingonthisspeed: ' peuvent être sauvegardés lorsque vous cherchez à améliorer cette vitesse',
    txt_global_std_step1: 'Étape 1',
    txt_global_std_p2pdecreasetextsize: 'Diminuer la taille du texte',
    txt_global_std_recruiterlockedkey: "Invitez un ami à l'application pour gagner ce badge. Vous obtenez ce badge lorsque votre ami crée un compte dans l'application.",
    txt_global_std_translationtool: 'Outil de traduction',
    txt_global_std_insurancecompanydescription: 'Pour une tarification précise et une excellente souscription.',
    txt_global_std_terminate: ' Interrompre',
    txt_global_std_cimage4d: ' Conduite aux feux rouges',
    txt_global_std_showofflinedrives: 'Afficher les lecteurs hors ligne',
    txt_global_std_enerfydynamiccollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: "Enerfy Dynamic collecte des données de localisation pour activer le score de conduite même lorsque l'application est fermée ou non utilisée.",
    txt_global_std_cantseeyourcar: 'Je ne peux pas voir votre voiture'
  },
  de: {
    txt_global_std_areyoudrivinganelectricorobd: '電気自動車を運転していますか、または、車載デバイスを取り付けていますか？',
    txt_global_std_nodrivingdata: 'Keine Fahrdaten',
    txt_global_std_HusseinAbdullaFacts: 'Adel hat mit Sebastian Delaunay als Co-Pilot die FIA CCR T2-Weltmeisterschaft gewonnen. In seiner ersten kompletten Saison im Cross-Country-Wettbewerb gewann Adel Hussein während einer erfolgreichen Kampagne in der härtesten Offroad-Meisterschaft der Welt, Siege in Portugal, Abu Dhabi und Spanien.',
    txt_global_std_gotoleaderboard: 'Gehen Sie zur Rangliste',
    txt_global_std_inordertoconnecttoyourcartheincludedobd: 'In order to connect to your car, the included OBD device, the Enerfy plug-in, must be installed in your vehicle - or - if your vehicle has the option to connect your phone via Bluetooth directly, the Enerfy app has to be running in your phone while driving.',
    txt_global_std_p2pfaq20a: '1. Wählen Sie „Fahren“ aus dem „Menü“, um die aktuellen Fahrdaten und Auswertungen abzurufen.\n' +
      '2. Wählen Sie bestimmte Fahrdaten aus, um die Details des Feedbacks anzuzeigen.',
    txt_global_std_p2pcancelled: 'Abgebrochen',
    txt_global_std_togetthecorrectstatsofyourdrivingyouneedtogototheappsettingsinyourphoneand: 'Um die richtigen Fahrstatistiken zu erhalten, müssen Sie in den App-Einstellungen Ihres Telefons die Option Immer in Ortungsdiensten zulassen auswählen.',
    txt_global_std_minimum5characters: 'Mindestens 5 Zeichen',
    txt_global_std_inactivateapp: 'App deaktivieren',
    txt_global_std_noinsurancesfound: 'Keine Versicherungen gefunden',
    txt_global_std_letstrythatagain: 'Lass es uns noch einmal versuchen!',
    txt_global_std_apr: 'Apr',
    txt_global_std_HSolbergMusic: 'Pop- und Rockmusik, welche mir Enthusiasmus im Blut gibt.',
    txt_global_std_youhaveatotalcostof: 'Sie haben Gesamtkosten von',
    txt_global_std_challengedetails: 'Herausforderungsdetails:',
    txt_global_std_driversname: 'Name des Fahrers',
    txt_global_std_tomasengemileage: 'Ich liebe immer alle Wege die zu meiner Heimat führen, zu meiner Familie',
    txt_global_std_yourinsurancehasbeenrenewed: 'Ihre Versicherung wurde erneuert.',
    txt_global_std_now: 'Jetzt',
    txt_global_std_p2pviewandpay: 'Ansehen und bezahlen',
    txt_global_std_p2pnamecancelledname2sbooking: '{name} hat die Buchung von {name2} storniert',
    txt_global_std_telephoneclaims: 'Telefonische Ansprüche',
    txt_global_std_emailalreadyusedpleasetrywithanotheremail: 'E-Mail bereits verwendet. Bitte versuchen Sie es mit einer anderen E-Mail.',
    'txt_global_std_Al-KuwariBesttip': 'Konzentration und versuchen Sie die Fahrt zu genießen.',
    txt_global_std_newtext: 'Neuer Text',
    txt_global_std_p2pemailhello: 'Hallo ',
    txt_global_std_carmodelform: 'Auto Model:',
    txt_global_std_sendreferrallink: 'Empfehlungslink senden',
    txt_global_std_failedtoaddusers: 'Benutzer konnten nicht hinzugefügt werden',
    txt_global_std_feedback: 'Feedback',
    txt_global_std_hoponinletsdrivesmart: 'Steigen Sie ein und fahren Sie schlau',
    txt_global_std_JyrkiainenChamp: 'Bester Anfängerfahrer der finnischen Championships 2016',
    txt_global_std_hellogreattoseeyou: 'Hallo, schön dich zu sehen!',
    txt_global_std_p2pmembersinthisgroup: 'Mitglieder dieser Gruppe',
    txt_global_std_couldnotjoinevent: 'Event konnte nicht betreten werden.',
    txt_global_std_cantfindyourcaryet: 'Kann Ihr Auto noch nicht finden!',
    txt_global_std_allusershavesuccessfullycompletedthisstep: 'Alle Benutzer haben diesen Schritt erfolgreich abgeschlossen.',
    txt_global_std_forgotyourpassword1: 'Passwort vergessen?',
    txt_global_std_paddonDescription: 'Von der Kindheit an, lebt und atmet Hayden den Motorsport und hat im Alter von 6 Jahren mit dem Fahren von Gokarts begonnen.  ',
    txt_global_std_security: 'Sicherheit',
    txt_global_std_welcometoapp: 'Willkommen bei {app}!',
    txt_global_std_adjustyouryearlymileage: 'Passen Sie Ihren Jahreskilometerstand an',
    txt_global_std_easilykeeptrackofyourcostandco2emissionsintheapp: 'Behalten Sie Ihre Kosten und CO2-Emissionen ganz einfach in der App im Blick.',
    txt_global_std_p2ppleasechooseexpensetype: 'Bitte Spesenart wählen',
    txt_global_std_electriccar: 'Elektroauto?',
    txt_global_std_someoneelseispairedtoyourcar: '• Jemand anderes ist mit Ihrem Auto gekoppelt.',
    txt_global_std_moreattributes: 'Mehr Attribute',
    txt_global_std_disableachievementtooltiptext1: 'Dieser Erfolg wurde ohne das Achievement Tool erstellt. <br> Daher kann es in diesem Tool derzeit nicht geändert werden.',
    txt_global_std_atheappwilltrackyourscoresandprovidearatebasedonyourdrivinghabitsyouwillgetthefirstmonthlyratethatisbasedonyourdrivingafteryourfirstsixmonthperiodyourratewillthenchangeonamonthlybasisbasedonyourscoreanddrivinghabitsyouwillreceiveupdatesaftereachtripadvisingyouofyourpotentialsavingsorifyouneedtoimproveyourdrivingscoretoearnadiscount: 'A: Die App verfolgt Ihre Punktzahlen und bietet eine Rate, die auf Ihren Fahrgewohnheiten basiert. Sie erhalten die erste monatliche Rate, die auf Ihrer Fahrweise basiert, nach Ihrem ersten Halbjahr. Ihr Tarif ändert sich dann monatlich basierend auf Ihrer Punktzahl und Ihren Fahrgewohnheiten. Sie erhalten nach jeder Fahrt Updates, die Sie über Ihre möglichen Einsparungen informieren oder wenn Sie Ihre Fahrbewertung verbessern müssen, um einen Rabatt zu erhalten.',
    txt_global_std_notsupported: 'Nicht unterstützt',
    txt_global_std_inviteafriendorfamilytoborrow: 'Laden Sie einen Freund oder ein Familienmitglied ein, Ihr Auto {Appname} auszuleihen',
    txt_global_std_400badrequest: '400 - Ungültige Anfrage',
    txt_global_std_TaxInvoice4: 'Währung / Wechselkurs',
    txt_global_std_someoneelseispairedtoyourcaryourbluetoothbeacon: '• Eine andere Person ist mit Ihrem Auto/Ihrem Bluetooth-Beacon gekoppelt\n',
    txt_global_std_f_lanzinterests: 'Abenteuerklettern, Rafting, Ziplining, Trailrunning, Tanzen, Wild Nature Explorer',
    txt_global_std_password2: 'Passwort',
    txt_global_std_v2sdcscore: 'Ergebnis',
    txt_global_std_trytheappfunctionisnotavailableindemomode: 'Die Funktion „App testen“ ist im Demomodus nicht verfügbar',
    txt_global_std_leaderboard_tab_daily: 'Täglich',
    txt_global_std_days: 'Tage',
    txt_global_std_youcanuploadmaximum9photos: 'Sie können maximal 9 Fotos hochladen',
    txt_global_std_connectwithobdreader: 'Mit OBD-Lesegerät verbinden',
    txt_global_std_ongoinghead2headchallenges: 'Laufende Head 2 Head-Herausforderungen',
    txt_global_std_easytogetstartedrequestyourzipriskindexviaourapi: 'Einfach loslegen, fordern Sie Ihren Zip-Risikoindex über unsere API an.',
    txt_global_std_avglevel: 'Avg. level',
    txt_global_std_idnumber: 'ID Nummer ',
    txt_global_CoreTemplate_enter: 'Eingabe',
    txt_global_std_startsin: 'Beginnt in',
    txt_global_std_retrive: 'Abrufen',
    txt_global_std_ok: 'Ok',
    txt_global_std_questionsaboutinsurance: 'Fragen zur Versicherung',
    txt_global_std_PaddonChamp: 'PWRC-Champion im Jahr 2011, 4 Siege bei der Neuseeland Rallye-Meisterschaft (2008, 2009, 2013, 2018) Rallye Argentinien 2016 gewonnen.',
    txt_global_std_saveyourcar: 'Speichern Sie Ihr Auto',
    txt_global_std_selectteam: 'Team auswählen',
    txt_global_std_ayouwillbenotifiedintheappwhenitstimetorenewyourinsuranceifyoudonthaveautomaticrenewalsetupfollowthestepsintheapptoensureyouhavecoverageforthefollowingmonth: 'A: Sie werden in der App benachrichtigt, wenn es Zeit ist, Ihre Versicherung zu erneuern. Wenn Sie keine automatische Verlängerung eingerichtet haben, befolgen Sie die Schritte in der App, um sicherzustellen, dass Sie für den folgenden Monat versichert sind.',
    txt_global_std_globalteams: 'Globale Teams',
    txt_global_std_mystatsthismonth: 'Meine Statistiken in diesem Monat',
    txt_global_std_downloadinglatestversionofapp: 'Wir laden jetzt die neuesten Versionen aller Anwendungen herunter. Bitte haben Sie Verständnis, dies kann bis zu einer Minute dauern.',
    txt_global_std_renewedfor: 'Verlängert für',
    txt_global_std_countryroadintersectionDESC: 'Der blaue Autofahrer ist planbar und konzentriert und fährt reibungslos durch die Kreuzung. Der rote Autofahrer passt seine Geschwindigkeit nicht an die Fahrsituation an und muss häufig hart bremsen.',
    txt_global_std_tomasengebesttip: 'Sich so weit wie möglich auf das Fahren zu konzentrieren ist ein Schlüssel, um Ihr Ziel erfolgreich zu erreichen',
    txt_global_std_thisensuresyourcarisstilltrackedbyloyaltysystem: 'Dies stellt sicher, dass Ihr Auto weiterhin vom Treue-System verfolgt wird.',
    txt_global_std_nestgroup: 'Nestgruppe',
    txt_global_std_replay: 'Wiederholen',
    txt_global_std_thisisaskilleddriver: 'Dies ist ein erfahrener Fahrer, aber manchmal während einer Fahrt und nur für kurze Zeit neigt dieser Fahrer dazu, den Fokus auf cooles Fahren zu verlieren. Einige Ablenkungen oder Stress können dazu führen, dass dieser Fahrer das Sicherheitsbewusstsein ignoriert. Dies wird oft behoben, indem man mit dem Fahrer spricht und um seine Rücksicht bittet.',
    txt_global_std_p2ptypeamessage: 'Geben Sie eine Nachricht ein',
    txt_global_std_PolicySchedule20: 'NCD',
    txt_global_std_establishingobddatalink: 'Aufbau der OBD-Datenverbindung',
    txt_global_std_clickrefreshbuttoninapptotryagain: 'Klicken Sie in der App auf Aktualisieren, um es erneut zu versuchen.',
    txt_global_std_bad2: 'Schlecht',
    txt_global_std_addlogo: 'Logo  hinzufügen',
    txt_global_std_whendrivingonhigherspeedroadsplanningismoreimportantthaneverenteringahighwaytooslowlycancausedangertofellowmotoristsandleadtoqueuesbehindyouobservingthetrafficflowandadaptingyourspeedtotheconditionsisimportanttoavoidunnecessaryqueuesandpanicbrakingnew: 'Beim Fahren auf Straßen mit hoher Geschwindigkeit ist Planung wichtiger denn je. Wenn Sie zu langsam auf eine Autobahn auffahren, können Sie andere Autofahrer gefährden und zu Staus hinter Ihnen führen. Das Beobachten des Verkehrsflusses und das Anpassen der Geschwindigkeit an die Bedingungen ist wichtig, um unnötige Staus und Panikbremsungen zu vermeiden.',
    txt_global_std_howdoiterminatemyinsurance: 'Wie kündige ich meine Versicherung?',
    txt_global_std_manageparticipants: 'Teilnehmer verwalten',
    txt_global_std_MSIG_InsuranceLetter19: 'Personen oder Personengruppen mit Fahrerlaubnis *',
    txt_global_std_tryouttrygdrive: 'Probieren Sie Tryg Drive aus',
    txt_global_std_onceyouhavecompletedachallengeitwillappearhere: 'Sobald Sie eine Herausforderung abgeschlossen haben, wird sie hier angezeigt.',
    txt_global_std_daysdayshourshoursminutesminutes: '{days} Tage {hours} Stunden {minutes} Minuten',
    txt_global_std_p2psendinvite: 'Sende Einladung',
    txt_global_std_pickstarttime: 'Startzeit auswählen',
    txt_global_std_setupyourprofiletocontinue: 'Richten Sie Ihr Profil ein, um fortzufahren',
    txt_global_std_riskindex_upsell: 'Risikoindex – Greifen Sie auf das Gesamtbild zu, um globale Trends beim Fahren von Risiken zu verstehen.',
    txt_global_std_teammembers: 'Teammitglied',
    txt_global_std_KleinschmidtBesttip: 'Vorrausschauendes Fahren finde ich wichtig, damit nicht ständig beschleunigt, gebremst und beschleunigt wird. ',
    txt_global_std_newmobilitycompany: 'Neues Mobilitätsunternehmen',
    txt_global_std_statsforthistrip: 'Statistiken für diese Reise',
    txt_global_std_cimage24d: 'Finden Sie die Drehzahl, die den niedrigsten Kraftstoffverbrauch bei Geschwindigkeit liefert.',
    txt_global_std_hoursshort: 'h',
    txt_global_std_warikan_onboarding19: ' Wenn die Schaltfläche nicht angezeigt wird, laden Sie KINTO bitte direkt aus dem App Store oder Google Play herunter.',
    txt_global_std_anewaccountwillbecreated: 'Ein neues Konto wird erstellt',
    txt_global_std_joinname: 'Teilnehmen',
    txt_global_std_restrictedtopeoplewithinmyorganization: '(beschränkt auf Personen innerhalb meiner Organisation)',
    txt_global_std_youraveragelevelthismonthisthebasisforyourdiscountyoureceivethediscountwhenyourenewyourinsurancefortheupcomingmonths: 'Ihr durchschnittliches Niveau in diesem Monat ist die Grundlage für Ihren Rabatt. Sie erhalten den Rabatt, wenn Sie Ihre Versicherung für die nächsten Monate verlängern',
    txt_global_std_units: 'Einheit / Lektion',
    txt_global_std_listoffunctions: 'Liste der Funktionen',
    txt_global_std_p2pviewyourbookingsandblockyourcarwhenyouwanttomakeitunavailable: 'Sehen Sie sich Ihre Buchungen an und sperren Sie Ihr Auto, wenn Sie es nicht verfügbar machen möchten.',
    txt_global_std_viewactivationcode: 'Aktivierungscode anzeigen',
    txt_global_std_p2psendyourcode: 'Senden Sie Ihren Freundescode',
    txt_global_std_whenyouactivateyourinsurance: '',
    txt_global_std_teamleaderscore: 'Teamleiter Punktzahl',
    txt_global_std_results: 'Resultate',
    txt_global_std_choosefromlibrary: 'Aus der Bibliothek auswählen',
    txt_global_std_distancefromparking: 'Entfernung vom Parkplatz und Fahrtbeginn',
    txt_global_std_youhavebeenchallengedtoahead2head: 'Sie wurden zu einem Kopf-an-Kopf-Rennen herausgefordert. Öffnen Sie die App und akzeptieren Sie sie jetzt!',
    txt_global_std_wanttochallengeanewfriend: 'Willst du einen neuen Freund herausfordern?',
    txt_global_std_currentodometervalueinkm: 'Geben Sie Ihren aktuellen Kilometerstand (km) ein',
    txt_global_std_HansenMileage: 'Ich mag wirklich eine gute lange Reise, wenn ich die Fahrt mit meiner Familie oder Freunden teilen kann.',
    txt_global_std_countryroad3DESC: 'Der blaue Autofahrer verwendet langsames Tempo, um konzentriert und sanft zu fahren. Der rote Autofahrer plant das Fahren nicht und verbraucht unnötig oft Benzin und Bremsen.',
    txt_global_std_collectpointsbyearningbadgeseverybadgeisworthdifferentamountofpointstapabadgetolearnmoreaboutit: 'Sammeln Sie Punkte, indem Sie Abzeichen verdienen. Jedes Abzeichen ist eine unterschiedliche Anzahl von Punkten wert. Tippen Sie auf ein Abzeichen, um mehr darüber zu erfahren.',
    txt_global_std_getstarted: 'Legen Sie los',
    txt_global_std_challenges: 'Herausforderngen',
    txt_global_std_may: 'Mai',
    txt_global_std_documentation2: 'Dokumentation',
    txt_global_std_alreadyhaveanenerfypluginclickhere: 'Haben Sie bereits ein Enerfy-Plug-in? Klicke hier!',
    txt_global_std_drivercpproverview: 'Profilerstellungsbericht zu Fahrerunfallmustern – Übersicht',
    txt_global_std_youearnedapercentagediscount: 'Sie haben einen Rabatt von {percentage} % erhalten',
    txt_global_std_uploadyourcompanylogo: 'Laden Sie Ihr Firmenlogo hoch',
    txt_global_std_pushneverdrivenbody2: '{appname} Sie mit {appname} und verbinden Sie sich das nächste Mal mit Ihrem Auto, wenn Sie eine Fahrt machen!',
    txt_global_std_kintofaq10q: 'Wie messen Sie mein Fahren?',
    txt_global_std_p2ptotaltosplit: 'Insgesamt zu teilen:',
    txt_global_std_worldranking: 'Weltrangliste',
    txt_global_std_yourinsuranceisrenewedrenewaldate: 'Ihre Versicherung wird erneuert {renewaldate}.',
    txt_global_std_setupyourcompanychallenge: 'Richten Sie Ihre Unternehmens-Challenge ein',
    txt_global_std_companychallenge: 'Unternehmen Herausforderung',
    txt_global_std_ConfirmFia_Text11: 'Es sind nur noch weniger als 24 Stunden bis zu Ihrer geplanten Testfahrt der FIA Smart Driver Challenge in Paris!',
    txt_global_std_clickonthesettingsbelowtochangepermission: 'Klicken Sie auf die Einstellungen unten, um die Berechtigung zu ändern',
    txt_global_std_starttheengineandopentheapp: 'Starten Sie den Motor und öffnen Sie die App.',
    txt_global_std_supportedfileformats: 'Unterstützte Dateiformate',
    txt_global_std_demotext3: 'Sehen Sie, wie viel Sie insgesamt gefahren sind.',
    txt_global_std_savedimagesmarkedwitha: 'Saved images marked with a',
    txt_global_std_makesureengineisrunning: 'Stellen Sie sicher, dass der Motor läuft.',
    txt_global_std_havealreadybeeninapp: 'Bereits ein Benutzer',
    txt_global_std_unpublishachievementpopuptext: 'Wenn Sie die Veröffentlichung eines Erfolgs rückgängig machen, wird er für alle Benutzer entfernt, die ihn noch nicht freigeschaltet haben. Wenn ein Benutzer es bereits erhalten hat, wird es immer noch unter seinen anderen Errungenschaften sein. Wenn Sie es noch nicht erhalten haben, wird es beim Aufheben der Veröffentlichung entfernt.',
    txt_global_std_KleinschmidtWhyjoin: 'Weil wir das coolste Team sind und viel Spaß haben.',
    txt_global_std_risklevel: 'Risikostufe',
    txt_global_std_driveabitmoretogetscore: 'Fahren Sie ein bisschen mehr, um eine Punktzahl zu erhalten',
    txt_global_std_updatedtranslation: 'Aktualisierte Übersetzung',
    txt_global_std_countyears: '{zählen} Jahre',
    txt_global_std_insurance: 'Versicherung',
    txt_global_std_shownewactivities: 'Neuigkeiten anzeigen',
    txt_global_std_startachallenge: 'Beginnen Sie eine Herausforderung',
    txt_global_std_discipline: 'Disziplin',
    txt_global_std_competition: 'Wettbewerb',
    txt_global_std_notsignedupforsompoclimateheroinsuranceyet: 'Sie haben noch keine Sompo Climate Hero Versicherung abgeschlossen?',
    txt_global_std_pleaserefreshthepage: 'Bitte aktualisieren Sie die Seite',
    txt_global_std_hereyoustartadrivemanuallywhenyouhavefinished: 'Here you start a drive manually. When you have finished your drive you click stop drive to get your score. We use GPS to give you a score. If you drive in to a tunnel or a parking garage, your drive can be affected.',
    txt_global_std_p2pborrowedtime: 'Geliehene Zeit',
    txt_global_std_collision123: 'Kollision',
    txt_global_std_atleast5characters: 'Mindestens 5 Zeichen',
    txt_global_std_p2plendyou: 'dir ausleihen',
    txt_global_std_signup: 'Anmelden ',
    txt_global_std_calculating: 'Berechnen',
    txt_global_std_30daysavg: '30 Tage Durchschn.',
    txt_global_std_pleaseverifyyourenerfyglobalemailadress: 'Bitte überprüfen Sie Ihre E-Mail-Adresse von Enerfy Global',
    txt_global_std_p2pmorefunctions: 'Mehr Funktionen',
    txt_global_std_scantheqrcodetoaccessthechallenge: 'Scannen Sie den QR-Code, um auf die Herausforderung zuzugreifen.',
    txt_global_std_man: 'Mann',
    txt_global_std_sharelink1: 'Einen Link teilen',
    txt_global_std_refresh: 'Aktualisierung',
    txt_global_std_easilymanagecommunication: 'Einfache Verwaltung der Kommunikation in allen Kanälen. Einige der Nachrichten sind für die beste Customer Journey automatisiert. Erstellen und passen Sie Ihre eigenen an oder wählen Sie zwischen bestehenden E-Mails, Textnachrichten, Push-Benachrichtigungen und In-App-Benachrichtigungen.',
    txt_global_std_sep: 'Sep',
    txt_global_std_p2perrorcodebookingduplicaterequest: 'Sie haben für diese Zeit bereits eine Anfrage gesendet.',
    txt_global_std_p2pborrowtext: '{name} möchte Ihr {vehicle} ausleihen',
    txt_global_std_youwillearnachievements: 'Sie werden Erfolge erzielen',
    txt_global_std_mysavings: 'Meine Ersparnisse',
    txt_global_std_besuretoaddfourmainpicturesofyourcarfrontleftrightandbacksidesasshowninthediagramsbelow: 'Stellen Sie sicher, dass Sie vier Hauptbilder Ihres Autos (Vorder-, Links-, Rechts- und Rückseite) hinzufügen, wie in den folgenden Abbildungen gezeigt.',
    txt_global_std_creatingyourprofile: 'Profil erstellen',
    txt_global_std_sorrytheimagerecognitionfailedpleaseentertheodometervaluemanually: 'Entschuldigung, die Bilderkennung ist fehlgeschlagen. Bitte geben Sie den Kilometerstand manuell ein.',
    txt_global_std_alreadyhaveanaccountlogin: 'Sie haben bereits ein Konto? Einloggen',
    txt_global_std_PolicySchedule33: 'Adresse',
    txt_global_std_TomczykInterests: 'Oldtimer, alle Sportarten',
    txt_global_std_p2psafedriverbonusnotincluded: 'Safedriver Bonus nicht inbegriffen',
    txt_global_std_takesomephotosofyourcar: 'Machen Sie Fotos von Ihrem Auto',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriendsthedriverwiththebestscorewinstheround: 'Treten Sie in Ihrem täglichen Fahren gegen Ihre Freunde an. Der Fahrer mit der besten Punktzahl gewinnt die Runde!',
    txt_global_std_p2pemailcancelledbookings: 'Stornierte Buchungen',
    txt_global_std_whatisapayasyoudriveinsurance: 'Was ist eine Bezahlung, wenn Sie eine Versicherung fahren?',
    txt_global_std__log_in: 'Einloggen',
    txt_global_std_gobackandfix: 'Geh zurück und repariere',
    txt_global_std_allyear: 'Das ganze Jahr',
    txt_global_std_world: 'Welt',
    txt_global_std_leftuntilrenewal: 'Bleibt bis zur Erneuerung',
    txt_global_std_policynumberalreadyexists: 'Die Richtliniennummer ist bereits vorhanden',
    txt_global_std_buyandinsuranceandstartsavingmoneynow: 'Kaufen Sie jetzt eine Versicherung und sparen Sie bares Geld',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint2: 'Gewinnen Sie neue Erkenntnisse, um zielgerichtetes Handeln zu ermöglichen',
    txt_global_std_oct: 'Okt',
    txt_global_std_coupons: 'Gutscheine',
    txt_global_std_okaywewillremindyoulater: 'Okay, wir erinnern dich später',
    txt_global_std_successfullypublishedreviewedtranslations: 'Erfolgreich veröffentlichte überprüfte Übersetzungen',
    txt_global_std_p2pyouhavenobooking: 'Sie haben keine Buchungsanfragen',
    txt_global_std_warikan_onboarding5: '1. C-Besitzer KINTO ONE Auftragnehmer (Kunde) lädt die App herunter',
    txt_global_std_koscore: 'Økoscore',
    txt_global_std_editprofilepicture: 'Profilbild bearbeiten',
    txt_global_std_deactivated: 'Deaktiviert',
    txt_global_std_timesincelogin: 'Zeit seit Anmeldung, geparkt etc',
    txt_global_std_selectyourobddevicefromthelistobdmini2: 'Wählen Sie Ihr OBD-Gerät aus der Liste aus: OBD Mini 2',
    txt_global_std_enterthenrofdigitsdigitcodeorclickonthelinksenttophonenr: 'Geben Sie den {nrOfDigits}-Zifferncode ein oder klicken Sie auf den gesendeten Link: {phoneNr}',
    txt_global_std_howtoplay: 'Spielanleitung',
    txt_global_std_kintofaq7a: 'Kein Problem! Fordern Sie einen BT-OBD-Dongle an und er wird die Magie machen! Schließen Sie einfach den Dongle an das Kabel an und stecken Sie ihn in Ihr 12-V-Ladegerät im Auto. Befolgen Sie dann wie gewohnt die Schritte „Mit Auto verbinden“ und wählen Sie während der Bluetooth-Verbindung den Namen „Enerfy OBD“.',
    txt_global_std_senddetails: 'Senden Sie Details',
    txt_global_std_youwillreceiveanemailwithyourpersonaldata: 'Sie erhalten eine E-Mail mit Ihren persönlichen Daten',
    txt_global_std_ihaveanenerfypluginhowdoiconnectitinmycar: 'Ich habe ein Enerfy-Plug-In. Wie stecke ich es in mein Auto?',
    txt_global_std_thepasswordhastobe8charactersorlongerandmustinclude1numeralandspecialcharacter: 'Das Passwort muss mindestens 8 Zeichen lang sein und 1 Ziffer und ein Sonderzeichen enthalten',
    txt_global_std_enddate: 'Endtermin',
    txt_global_std_p2ppushbodylendingcomingup: 'Bitte beachten Sie, dass &quot; {name}&quot; will lend your car. Starts &quot;{start} &quot;',
    txt_global_std_inviteyournetworktostartdrivingsmarter: 'Laden Sie Ihr Netzwerk ein, intelligenter zu fahren!',
    txt_global_std_calender: 'Kalender',
    txt_global_std_p2pnocancelledbookingrequests: 'Keine stornierten Buchungsanfragen',
    txt_global_std_itwillexpireshortlysomakesuretoverifysoonremembertocheckyourspamfolder: 'Es läuft in Kürze ab, also vergewissern Sie sich bald. Denken Sie daran, Ihren Spam-Ordner zu überprüfen.',
    txt_global_std_me: 'Ich',
    txt_global_std_connectingtoyourcar: 'Verbinden mit Ihrem Auto',
    txt_global_std_ttest94: 'das ist ein Test',
    txt_global_std_alerthighdivisiontext: 'Warnung! Ihr Fahrstil verursacht hohe Kosten pro Kilometer.',
    'txt_global_std_Al-KhelaifiQuote': 'Zitat kommt bald ...',
    txt_global_std_p2ppushbodyexpensesdenied: '{name} hat die Ausgabe nicht genehmigt',
    txt_global_std_canitrytheappbeforesigningup: 'Kann ich die App ausprobieren, bevor ich mich anmelde?',
    txt_global_std_PolicySchedule24: 'GST',
    txt_global_std_kintoloyaltysystem: 'Das Treue-System',
    txt_global_std_download: 'Download',
    txt_global_std_youareinthetop5keepgoing: 'Sie sind in den Top 5, machen Sie weiter!',
    txt_global_std_buttogetthecorrectstatsofyourdrivingyouneedtogotoappsettingsinyourphoneandchoosebalwaysb: '... aber um die korrekten Statistiken Ihres Fahrens zu erhalten, müssen Sie zu den App-Einstellungen in Ihrem Telefon gehen und <b> Immer auswählen.</b>',
    txt_global_std_p2ppushbodyrevokeadmin: 'Ihre Administratorrechte für "{vehicle}" has been revoked by {name} .',
    txt_global_std_filteredon: 'Gefiltert',
    txt_global_std_smallscratches: 'Kleine Kratzer?',
    txt_global_std_yourinsurancewillberenewedonmonthdayyear: 'Ihre Versicherung wird am {Monat} {Tag}, {Jahr} verlängert',
    txt_global_std_placeholdertext: 'Platzhaltertext',
    txt_global_std_thisisaskilledinconsistentdriver: 'Dies ist ein erfahrener Fahrer, aber manchmal während einer Fahrt und nur für kurze Zeit neigt dieser Fahrer dazu, den Fokus auf cooles Fahren zu verlieren. Einige Ablenkungen oder Stress können dazu führen, dass dieser Fahrer das Sicherheitsbewusstsein ignoriert. Dies wird oft behoben, indem man mit dem Fahrer spricht und um seine Rücksicht bittet.',
    txt_global_std_lockedtitle: 'Gesperrt',
    txt_global_std_chooseallowonce: 'Wählen Sie Einmal zulassen',
    txt_global_std_connectionwasabortedbeforethedrivewasvalid: 'Die Verbindung wurde abgebrochen, bevor das Laufwerk gültig war. Das Laufwerk wird verworfen',
    txt_global_std_preparing: 'Vorbereiten ...',
    txt_global_std_usermustacceptaninvitationtotheteamfirst: 'Der Benutzer muss zuerst eine Einladung zum Team annehmen.',
    txt_global_std_p2pfaq7q: 'Wie kann ich eine Buchung stornieren?',
    txt_global_std_drivingdatatroubleshooting: 'Problembehandlung Fahrdaten',
    txt_global_std_time: 'Zeit',
    txt_global_std_continuetosetup: 'Weiter zu Einstellungen',
    txt_global_std_fleetcompany: 'Flottenunternehmen',
    txt_global_std_p2pleavegroupchat: 'Gruppenchat verlassen',
    txt_global_std_acceptuserterms: 'Benutzerbedingungen akzeptieren',
    txt_global_std_selecttype2: 'Art auswählen',
    txt_global_std_mustbeavalidsizeandcanonlyincludenumbersand: 'Muss eine gültige Größe haben und darf nur Zahlen und + enthalten',
    txt_global_std_aiexplainer: 'KI-Erklärer',
    txt_global_std_start: 'Start',
    txt_global_std_MSIG_Onboarding12: 'MS First Capital',
    txt_global_std_HSolbergChamp: '5 Mal die norwegische Meisterschaft gewonnen',
    txt_global_std_createanaccountoruseexistingone: 'Erstellen Sie ein Konto oder verwenden Sie ein vorhandenes',
    txt_global_std_youwonyourh2hchallenge: 'Du hast deine Head2Head-Challenge gegen {user} gewonnen!',
    txt_global_std_Inviteafriedbothwillgetawarded: 'Lade einen Freund ein und ihr werdet beide ausgezeichnet!',
    txt_global_std_burglary123: 'Einbruch',
    txt_global_std_apioverview: 'API-Übersicht',
    txt_global_std_whenthechallengeisaccepteditwillshowuponthestartpage: 'Wenn die Herausforderung angenommen wurde, wird sie auf der Startseite angezeigt.',
    txt_global_std_kintofaq10a: 'Die Kinto-App verwendet künstliche Intelligenz, um Ihr Fahren zu messen. Die App stellt eine Verbindung zur Cloud her und analysiert jede Sekunde Ihres Fahrens - verwandelt in ein „Profil“. Die KI vergleicht Ihre Profile mit über 650 Millionen anderen einzigartigen Fahrprofilen. Die KI verfügt über mehr als 16 Jahre Erfahrung, um die Wahrscheinlichkeit eines Fahrmusters für einen Unfall zu ermitteln. Glücklicherweise kehren Fahrer und Passagiere nach fast jeder Reise sicher zu ihren Lieben nach Hause zurück.',
    txt_global_std_theft: 'Der Diebstahl?',
    txt_global_std_PolicySchedule26: 'NIL EXCESS FÜR POLICYHOLDER UND AUTORISIERTE FAHRER',
    txt_global_std_PolicySchedule9: 'Tonnage / CC',
    txt_global_std_letmeshowyouhoweverythingworks: 'Lassen Sie sich zeigen, wie hier alles funktioniert!',
    txt_global_std_disableachievementtooltiptext: 'Dieser Erfolg wurde ohne das Achivement-Tool erstellt. <br> Daher kann es derzeit in diesem Tool nicht geändert werden.',
    txt_global_std_apikeymanager: 'API-SCHLÜSSEL-MANAGER',
    txt_global_std_howlongwillittaketoprocessmyclaim: 'Wie lange dauert die Bearbeitung meiner Reklamation?',
    txt_global_std_warikan_onboarding3: 'Bitte laden Sie die Warikan KINTO-App von der folgenden URL herunter.\n',
    txt_global_std_100km: '100 Kilometer',
    txt_global_std_save: 'Gespeichert',
    txt_global_std_searchbynameoraccounttype: 'Suchen Sie nach Name oder Kontotyp',
    txt_global_std_whendrivingonhigherspeedroadsplanningismoreimportantthaneverenteringahighwaytooslowlycancausedangertofellowmotoristsandleadtoqueuesbehindyouobservingthetrafficflowandadaptingyourspeedtotheconditionsisimportanttoavoidunnecessaryqueuesandpanicbraking: 'Beim Fahren auf Straßen mit hoher Geschwindigkeit ist Planung wichtiger denn je. Wenn Sie zu langsam auf eine Autobahn auffahren, können Sie andere Autofahrer gefährden und zu Staus hinter Ihnen führen. Das Beobachten des Verkehrsflusses und das Anpassen der Geschwindigkeit an die Bedingungen ist wichtig, um unnötige Staus und Panikbremsungen zu vermeiden.',
    txt_global_std_pushdriveendmessagetitle: 'Køretur er slut - {0}',
    'txt_global_std_your@email.com': 'Ihre@email.com',
    txt_global_std_enerfyanalytics_upsell_bulletpoint2: 'Gewinnen Sie neue Erkenntnisse, um zielgerichtetes Handeln zu ermöglichen',
    txt_global_std_unknownracedriverDescription: 'Wird noch angekündigt',
    txt_global_std_KristofferssonMileage: 'Ich liebe es, in Schweden an einem sonnigen Wintertag für einen Skitrip in den Norden zu fahren.',
    txt_global_std_findteamordriver: 'Finden Sie Ihr Team oder Ihren Fahrer',
    txt_global_std_invitefriendsviaemail: 'Laden Sie Freunde per E-Mail ein',
    txt_global_std_ifyoudonothavebluetoothinthecaryoucanuseachipthatisplacedinthecarsobd2portachipcanbeusedinmostcarsfrom2001orlater: 'Wenn Sie kein Bluetooth im Auto haben, können Sie einen Chip verwenden, der in den OBD2-Anschluss des Autos eingesetzt wird. Ein Chip kann in den meisten Autos ab 2001 verwendet werden.',
    txt_global_std_creategroup: 'Gruppe erstellen',
    txt_global_std_yougetthisafterdriving25tripswithtrygdrive: 'Sie erhalten dies nach 25 Fahrten mit Tryg Drive',
    txt_global_std_getiobd2: 'Holen Sie sich iOBD2',
    txt_global_std_KleinschmidtDescription: 'Sie ist die erste und bisher einzige Frau, die bei der längsten und härtesten Rallye der Welt, der Rallye Dakar, den richtigen Sieg errungen hat. Ihre Rennkarriere begann mit dem Motorrad. Nach vier Rallyes mit dem Motorrad wechselte sie 1995 auf vier Räder und engagierte sich in der Entwicklung des erfolgreichsten Rallyeautos, des Mitsubishi Pajero Evo, in dem sie 2001 die Dakar gewann. Außerdem hat sie einen Job als Ausbilder veröffentlicht hier Buch "Meine Siege bei der Dakar" - Mein Sieg in Dakar.',
    txt_global_std_distance: 'Entfernung ',
    txt_global_std_observe: 'Beobachten',
    txt_global_std_deletetranslation: 'Übersetzung löschen',
    txt_global_std_damageabroad: 'Schaden im Ausland',
    txt_global_std_okay: 'Okay',
    txt_global_std_demotext4: 'Sehen Sie Ihren Bonus hier.',
    txt_global_std_joinyourteaminthefiasdcandstayactivesmartandsustainablewhenyoutravelbycar: 'Schließen Sie sich Ihrem Team im Fia SDC an und bleiben Sie aktiv, intelligent und nachhaltig, wenn Sie mit dem Auto reisen.',
    txt_global_std_p2ppushtitlelendcar: '{name} - mein Auto leihen?',
    txt_global_std_p2pemailorpasswordwrong: 'E-Mail-Adresse / Passwort - beide oder einer von ihnen ist falsch',
    txt_global_std_areyousureyouwanttoadjustyourmileagethepricewillbeadjustedaccordingtothenewmileage: 'Möchten Sie Ihren Kilometerstand wirklich anpassen? Der Preis wird dem neuen Kilometerstand angepasst.',
    txt_global_std_ajustingmilageplan: 'Kilometerplan anpassen',
    txt_global_std_vsteam: 'VS. Team',
    txt_global_std_yourscoreisbasedonthedrivingpatternsyoucreatewitheachtripconcentrateonyourdrivingskillstoimproveyourscoreswewillprovideahistoryofallyourtripssoyoucanseehowyouredoing: 'Ihre Punktzahl basiert auf den Fahrmustern, die Sie bei jeder Fahrt erstellen. Konzentrieren Sie sich auf Ihre Fahrkünste, um Ihre Punktzahl zu verbessern. Wir stellen Ihnen einen Verlauf aller Ihrer Reisen zur Verfügung, damit Sie sehen können, wie es Ihnen geht.',
    txt_global_std_belowisthelinktoyourenerfyglobalapp: 'Unten ist der Link zu Ihrer Enerfy Global App.',
    txt_global_std_youcancontactoneofourlicensedinsuranceagenciesbyclickingonlinkorcallingagentatphoneno: 'Sie können eine unserer lizenzierten Versicherungsagenturen kontaktieren, indem Sie auf {link} klicken oder {agent} unter {phoneNo} anrufen.',
    txt_global_std_cimage12h: 'Gewinnen Sie an Geschwindigkeit bevor es bergauf geht',
    txt_global_std_noreviewstobepublishedpleaseedittranslationsifyouwishtopublish: 'Keine zu veröffentlichenden Rezensionen, bitte bearbeiten Sie die Übersetzungen, wenn Sie sie veröffentlichen möchten.',
    txt_global_std_changecar: 'Auto wechseln',
    txt_global_std_emailtoolong: 'E-Mail darf maximal 127 Zeichen lang sein.',
    txt_global_std_howdoicompeteinenerfyloyaltychallenge: 'Wie nehme ich an der Enerfy Loyalty Challenge teil?',
    txt_global_std_cimage20h: 'Hohe Höchstgeschwindigkeit führt zu keinem Zeitgewinn.',
    txt_global_std_kretursanalyse: 'Köretursanalyse',
    txt_global_std_enteryourpolicynumbertounlockallthebenefitsofthisapp: 'Geben Sie Ihre Versicherungsnummer ein, um alle Vorteile dieser App freizuschalten.',
    txt_global_std_createyourownleaderboardinthefiasmartdrivingchallengeandchallengeeachothertobecomesmarterandsaferontheroad: 'Erstellen Sie Ihre eigene Bestenliste in der FIA Smart Driving Challenge und fordern Sie sich gegenseitig heraus, klüger und sicherer auf der Straße zu werden.',
    txt_global_std_cimage21d: 'Es ist technisch einfacher, bei niedrigeren Geschwindigkeiten umweltfreundlicher zu fahren.',
    txt_global_std_avaragescore: 'Durchschnittliche Punktzahl',
    txt_global_std_emergencynumber: 'Notruf',
    txt_global_std_tiphighway1DESC: 'Der blaue Autofahrer fährt konzentriert und fährt sicher auf die Autobahn. Der rote Autofahrer passt die Geschwindigkeit nicht an die Fahrsituation an und muss bremsen.',
    txt_global_std_makesureyouarewithintenmeters: 'Stellen Sie sicher, dass Sie sich innerhalb von 10 m vom OBD-Leser befinden.',
    txt_global_std_drivingtips: 'Tipps zum Fahren',
    txt_global_std_introduction: 'Einführung',
    txt_global_std_starting: 'Beginnen',
    txt_global_std_noactiveparticipantsthisweek: 'Keine aktiven Teilnehmer diese Woche',
    txt_global_std_fieldcannotbeempty: 'Feld darf nicht leer sein',
    txt_global_std_yourchallengeranking: 'Ihr Challenge-Ranking',
    txt_global_std_createnewchallenge: 'Neuen Wettbewerb erstellen',
    txt_global_std_enteravaliddate: 'Gib ein gültiges Datum ein',
    txt_global_std_p2pemailadressforcommunication: 'E-Mail-Adresse für die Kommunikation',
    txt_global_std_searchcardriver: 'Auto/Fahrer suchen',
    txt_global_std_recruitedby: 'Rekrutiert von',
    txt_global_std_containsforbiddencharactersorspace: " enthält verbotene Zeichen: ' ? & / &#60; &#62; oder Leerzeichen",
    txt_global_std_p2psignupandcontinue: 'Anmelden und fortfahren',
    txt_global_std_idonthavebluetoothinmycarcanistillusetheapp: 'Ich habe kein Bluetooth in meinem Auto, kann ich die App trotzdem verwenden?',
    txt_global_std_allowbluetooth: 'Bluetooth zulassen',
    txt_global_std_youhavenowearnedascoreof4point4outof510timeswithtrygdrive: 'Mit Tryg Drive haben Sie jetzt 10 Punkte von 4,4 von 5 Punkten erzielt',
    txt_global_std_p2pselectedcar: 'Ausgewähltes Auto',
    txt_global_std_pleaseallowkintoaccesstoyourlocationtogetstartedwiththeapp: 'Bitte erlauben Sie Kinto den Zugriff auf Ihren Standort, um mit der App zu beginnen.',
    txt_global_std_baselevel: 'Basis Level',
    txt_global_std_ifyoucontinuetodriveonlevel: 'Wenn Sie weiterhin auf Stufe {level} fahren, können Sie bei Ihrer Versicherung {discount} sparen.',
    txt_global_std_generateinvitationcode: 'Einladungscode generieren',
    txt_global_std_MSIG_InsuranceLetter6: 'Vorschriften für Kraftfahrzeuge (Drittparteirisiken), 1959 (Malaysia)',
    txt_global_std_p2pconnectyourphonetoyourcarandyouwillunlockallthemagic: 'Verbinden Sie Ihr Telefon mit Ihrem Auto - und Sie werden die ganze Magie freischalten.',
    txt_global_std_addusersbyuploadingacsvfilewiththeirdetailsthefieldsfornameemailandpermissionsaremandatory: 'Fügen Sie Benutzer hinzu, indem Sie eine CSV-Datei mit ihren Details hochladen. Die Felder für Name, E-Mail und Berechtigungen sind Pflichtfelder.',
    txt_global_std_fixitnowreminder: 'Reparier es jetzt',
    txt_global_std_managecarsagreementshere: 'Verwalten Sie hier Autos und Verträge.',
    txt_global_std_comparemyactivities: 'Meine Fahrten vergleichen',
    txt_global_std_readytodrive: 'Sind Sie bereit zu Fahren?',
    txt_global_std_p2pmycalendar: 'Mein Kalender',
    txt_global_std_earnpointsandsavemoney: 'Sammeln Sie Punkte und sparen Sie Geld bei Ihrem Selbstbehalt',
    txt_global_std_drivescoresave: 'Fahrt. Ergebnis. Sparen.',
    txt_global_std_highest: 'Höchste',
    txt_global_std_testdeploynb: 'Hej',
    txt_global_std_backtochallengedashboard: 'Zurück zum Herausforderungs-Dashboard',
    txt_global_std_warikan_onboarding11: 'KINTO-Kundencenter',
    txt_global_std_takepicturesofyourcar: 'Machen Sie Fotos von Ihrem Auto',
    txt_global_std_acommontypeofunsafedrivingbehaviorisputtinghugeeffortintochasingthenexttrafficlightitslikeachallengetogettothenextcrossingasquicklyaspossibleonlytobestuckwaitingforthelighttoturngreendrivingsmoothlyandlookingwellaheadtoanticipatewhatscomingupsavesbothtimeandfuelnew: 'Eine häufige Art von unsicherem Fahrverhalten ist es, mit großem Aufwand die nächste Ampel zu jagen! Es ist wie eine Herausforderung, so schnell wie möglich zur nächsten Kreuzung zu gelangen, nur um dort festzusitzen und darauf zu warten, dass die Ampel auf Grün schaltet.',
    txt_global_std_addyourlogotypeandchoosecolortheme: 'Fügen Sie Ihr Logo hinzu und wählen Sie ein Farbthema',
    txt_global_std_accounttype: 'Konto Typ',
    txt_global_std_noofdrives: 'Anzahl Laufwerke',
    txt_global_std_wasanyoneinjured: 'Wurde jemand verletzt?',
    txt_global_std_november: 'November',
    txt_global_std_variablepremium: 'Variable monatliche Prämie',
    txt_global_std_youneedtoinputapassword: 'Sie müssen ein Passwort eingeben',
    txt_global_std_findchallenges: 'Herausforderungen finden',
    txt_global_std_easytomonitoryouresgtargets: 'Einfache Überwachung Ihrer ESG-Ziele',
    txt_global_std_xhours: '{hours} Stunden',
    txt_global_std_iwanttonotifyyouaboutyourdrivingresults: 'Ich möchte Sie über Ihre Fahrergebnisse informieren. OK?',
    txt_global_std_p2ppercentageused: 'Prozentsatz verwendet',
    txt_global_std_youraveragerisklevelispoints: 'Ihr durchschnittliches Risikoniveau beträgt {points}',
    txt_global_std_loyalty_upsell: 'Treueseiten – unterstützen das Geschäftswachstum durch stärkere Beziehungen.',
    txt_global_std_p2pwarikanloginorlinkissues: 'Warikan - Login oder Link Probleme',
    txt_global_std_enterpassword: 'Passwort eingeben',
    txt_global_std_lastdatereceived: 'Letztes Datum erhalten',
    txt_global_std_neverbeeneasier: 'Nie war es einfacher',
    txt_global_std_activatemyinsurance: 'Aktiviere meine Versicherung',
    txt_global_std_p2pother: 'Andere',
    txt_global_std_riskindex_upsell_pitch_bulletpoint3: 'Risikostufen weltweit, pro Land oder pro Postleitzahl',
    txt_global_std_appnamecollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: '{appName} sammelt Standortdaten, um die Fahrbewertung zu ermöglichen, auch wenn die App geschlossen oder nicht verwendet wird.',
    txt_global_std_whendrivingincityconditionsavoidchasingthenextcrossingortrafficlightbygainingspeedquicklythenslowingquicklythishurtstraveltimeandfueleconomyandincreasescrashrisklookingwellaheadanddrivingsmoothlywillhelpyouanticipateandhandleanyevent: 'Wenn Sie in der Stadt fahren, vermeiden Sie es, die nächste Kreuzung oder Ampel zu jagen, indem Sie schnell beschleunigen und dann schnell verlangsamen. Dies beeinträchtigt die Reisezeit und den Kraftstoffverbrauch und erhöht das Unfallrisiko. Weit vorausschauend und reibungslos zu fahren hilft Ihnen, jedes Ereignis vorherzusehen und zu bewältigen.',
    txt_global_std_p2pwhatdoyouwanttodo: 'Was möchten Sie tun?',
    txt_global_std_ourairiskinsighttoolgivesyouthepowertoidentifyfollowupandreportoncsrtargetstrackriskandco2perteammemberandrewardthosewhoexcel: 'Unser AI Risk Insight Tool gibt Ihnen die Möglichkeit, CSR-Ziele zu identifizieren, zu verfolgen und darüber zu berichten, Risiken und CO2 pro Teammitglied zu verfolgen und diejenigen zu belohnen, die sich auszeichnen.',
    txt_global_std_cimage21h: 'Niedrigere Geschwindigkeit macht es technisch einfacher',
    txt_global_std_average: 'Durchschnittlich',
    txt_global_std_yourdocumentwassent: 'Ihr Dokument wurde gesendet!',
    txt_global_std_followupdate: 'Follow-up-Termin',
    txt_global_std_won: 'Gewonnen',
    txt_global_std_hellolinus: 'hallo linus!',
    txt_global_std_speedchangedescription: 'Diese Grafik zeigt Ihnen die Gesamtzeit, die Sie für Beschleunigung, konstante Geschwindigkeit und Verzögerung aufgewendet haben. Allgemeine Tipps: Streben Sie mehr Zeit in die Verzögerung als in die Beschleunigung.',
    txt_global_std_p2pblockedthecar: '{name} hat das Auto blockiert',
    txt_global_std_above1000unlocked: 'Du bist über 1000 gefahren! Genial!',
    txt_global_std_letsdrive: 'Fahren wir los!',
    txt_global_std_easytogeteverydriverinyournetworkinvolved: 'Es ist einfach, jeden Fahrer in Ihrem Netzwerk einzubeziehen',
    txt_global_std_fiasdcisaninitiativetoreducepollutionandincreasesafetyontheraodswhiledrivingmotoristsgettheirdrivingassessedinrealtimebyouraitechnologythatunderstandseachtripslevelofriskandcarbonfootprintjustbymakingmotoristsawareoftheirimpactwecancreateapositivechange: 'Fia SDC ist eine Initiative zur Reduzierung der Umweltverschmutzung und Erhöhung der Sicherheit auf den Straßen. Während der Fahrt lassen Autofahrer ihre Fahrweise in Echtzeit von unserer KI-Technologie bewerten, die das Risikoniveau und den CO2-Fußabdruck jeder Fahrt versteht. Nur indem wir die Autofahrer auf ihre Auswirkungen aufmerksam machen, können wir eine positive Veränderung bewirken.',
    txt_global_std_VilliersQuote: 'Zitat kommt bald....',
    txt_global_std_membersinthisgroup: 'Mitglieder dieser Gruppe',
    txt_global_std_renewaldate: 'Verlängerungsdatum',
    txt_global_std_1000pointaverageachievementlocked: 'Fahren Sie eine Woche lang mit einem durchschnittlichen {scoretype} of {score} oder besser, um diesen Erfolg freizuschalten und 1000 Punkte zu verdienen.',
    txt_global_std_carriedrisk: 'Getragenes Risiko',
    txt_global_std_idrivetoomuch: 'Ich fahre zu viel',
    txt_global_std_headtohead: 'Kopf an Kopf',
    txt_global_std_thisisonlyneededthefirsttimeyouconnecttoyourcar: 'Dies ist nur erforderlich, wenn Sie zum ersten Mal eine Verbindung zu Ihrem Auto herstellen.',
    txt_global_std_reducing: 'reduzieren',
    txt_global_std_p2pgotocostsplit: 'Zur Kostenaufteilung gehen',
    txt_global_std_p2psendalinksothatthepersonborrowingyourcarcandownload: 'Senden Sie einen Link, damit die Person, die Ihr Auto ausleiht, die App {appname} herunterladen kann.',
    txt_global_std_HansenQuote: 'Smart fahren macht Spaß! Es spart mir Geld und verringert meine Auswirkungen auf unsere wertvolle Umwelt.',
    txt_global_std_PettersolbergShortDescription: 'RX-Weltmeister 2014 & 2015, 13 Mal WRC-Champion, Rally GB-Meister 2002 & 2003',
    txt_global_std_choosebetweenenteringchassisnumberenginenumberplatenumberorpolicynumber: 'Wählen Sie zwischen der Eingabe von Fahrgestellnummer / Motornummer, Kennzeichen oder Richtliniennummer.',
    txt_global_std_p2pusesameemailasregisteredonkintoaccount: '* Verwenden Sie dieselbe E-Mail-Adresse wie auf dem Kinto-Konto registriert',
    txt_global_std_chooseaccidenttype: 'Wählen Sie die Unfallart',
    txt_global_std_choosefile: 'Datei wählen...',
    txt_global_std_alreadyexistspossibleduplicationinternalservererror: 'Bereits vorhanden, mögliche Vervielfältigung. Interner Serverfehler',
    txt_global_std_cimage22d: 'Verwenden Sie den Tempomat nur auf ebenen Straßen. Straßen können als flach eingestuft werden, wenn Sie die Feststellbremse nicht benötigen.',
    txt_global_std_p2pdoyouwanttobookthedaysinbetweenormakeitseparatebookings: 'Möchten Sie die Tage dazwischen buchen oder separate Buchungen vornehmen?',
    txt_global_std_youraveragelevelis: 'Your average level is',
    txt_global_std_bottomfinaliststext: 'Die World Challenge besteht aus 3 Challenge Heats, 3 Qualifikationsperioden zwischen den Heats und 1 Grand Final.\n' +
      '\n' +
      'Die Top 3 Fahrer jedes Challenge Heats qualifizieren sich direkt für das große Finale im Dezember.',
    txt_global_std_anerroroccurredwhilesavingyourchanges: 'Beim Speichern Ihrer Änderungen ist ein Fehler aufgetreten',
    txt_global_std_activatelocationservice: 'Standortdienst aktivieren (Immer an)',
    txt_global_std_p2ppaynow: 'Zahlen Sie jetzt',
    txt_global_std_wheresmyoutlet: 'Wo ist mein Outlet?',
    txt_global_std_friendstothekintoapp: 'Freunde der Kinto-App',
    txt_global_std_createyouraccounttosaveandtrackthecostofyourtripsandgetaccesstoyourpersonaldrivingcoach: 'Erstellen Sie Ihr Konto, um die Kosten Ihrer Reisen zu sparen und zu verfolgen und Zugang zu Ihrem persönlichen Fahrbus zu erhalten',
    txt_global_std_plugitinyourcaarsobdoutlet: '',
    txt_global_std_WelcomeLetter_Text4: 'Willkommen!',
    txt_global_std_HSolbergInterests: 'Autos, Rallye & Rennen. Promotionstage bei meinen Sponsoren',
    txt_global_std_areyousureyouwanttoredeemxpointscoupon: 'Sind Sie sicher, dass Sie {x} Punkte einlösen möchten?',
    txt_global_std_p2pclickdatestoseebooking: 'Klicken Sie im Kalender auf Daten, um Ihre Buchungen anzuzeigen.',
    txt_global_std_currentodometervalue: 'Aktueller Odometervalue',
    txt_global_std_alreadyhavefiasdc: 'Haben Sie schon FIA SDC?',
    txt_global_std_description: 'Beschreibung ',
    txt_global_std_uploadyourdatatoseehowsufficientitis: 'Laden Sie Ihre Daten hoch, um zu sehen, wie ausreichend sie sind.',
    txt_global_std_platenumber: 'Kennzeichen',
    txt_global_std_invalidcode: 'Ungültiger Code',
    txt_global_std_p2pyesremovefriend: 'Ja, Freund entfernen',
    txt_global_std_paylessnextmonth: 'zahle nächsten Monat weniger',
    txt_global_std_KristofferssonQuote: 'Verkehrssicherheit liegt mir sehr am Herzen. Die FIA ​​Smart Driving Challenge wird Spaß bringen und gleichzeitig kann jeder sein umweltfreundliches und sicheres Fahren verbessern. Da ich auf fast allen Straßengegenheiten unterwegs war, die Sie sich nur vorstellen können, freue ich mich jetzt sehr darauf, dieses Wissen zu vermitteln, um ein intelligenteres Fahren unter uns allen zu fördern.',
    txt_global_std_p2pyour: 'Ihre',
    txt_global_std_youcancontactoneofourlicensedinsuranceagenciesbyclickingonfindanagentonourwebsiteorcallingagentatphonenumber: 'Sie können eine unserer lizenzierten Versicherungsagenturen kontaktieren, indem Sie auf unserer Website auf Agent suchen klicken oder {agent} unter {phoneNumber} anrufen.',
    txt_global_std_renewyourinsurance: 'Erneuern Sie Ihre Versicherung',
    txt_global_std_p2pcost: 'Kosten',
    txt_global_std_yesexchangepoints: 'Ja Tauschpunkte',
    txt_global_std_oldpasswordhelp: 'Wir benötigen Ihr aktuelles Passwort, um sicherzustellen, dass Sie der Inhaber dieses Kontos sind.',
    txt_global_std_offlinetrip: 'Offline',
    txt_global_std_uploadlogotype: 'Logo hochladen',
    txt_global_std_donthaveanaccountsignuplink: 'Sie haben kein Konto? {signUpLink}',
    txt_global_std_haveaproblemwiththeappvisitourfaqorcontactus: 'Haben Sie ein Problem mit der App? Besuchen Sie unsere FAQ oder kontaktieren Sie uns...',
    txt_global_std_starburstunlockedkey: 'Du bist auf Level 4.4 oder besser gefahren! Genial! Ihrem Kinto Wallet wurden 300 Punkte hinzugefügt.',
    txt_global_std_aonceaclaimsettlementisdeterminedyoucanreceiveacheckoranelectronicpaymentintoyourbankaccount: 'A: Sobald eine Schadensregulierung festgestellt wurde, können Sie einen Scheck oder eine elektronische Zahlung auf Ihr Bankkonto erhalten.',
    txt_global_std_completewizard: 'Beenden Sie den Assistenten',
    txt_global_std_enteremailtodostuff: 'Geben Sie Ihre E-Mail-Adresse ein, um eine E-Mail zu erhalten, in der Sie ein neues Passwort Ihrer Wahl zurücksetzen und festlegen können.',
    txt_global_std_datasentsuccess: 'Ihre Daten wurden gesendet, bitte überprüfen Sie Ihren Posteingang!',
    txt_global_std_on: 'auf',
    txt_global_std_whatifichangecar: 'Was ist, wenn ich das Auto wechsle?',
    txt_global_std_connecttotheprovidedobddeviceifyourcar: 'Stellen Sie eine Verbindung zum mitgelieferten OBD-Gerät (Enerfy Mini OBD) her, wenn Ihr Auto kein Bluetooth hat',
    txt_global_std_selectyourcarmodeltoseethemostaccuratedataofsavedco2: 'Wählen Sie Ihr Automodell aus, um die genauesten Daten zum eingesparten CO2 anzuzeigen.',
    txt_global_std_forgotyourpassword: 'Haben Sie Ihr Passwort vergessen?',
    txt_global_std_getacoscoreof15orlowerstartdrivingtogetyourscore: 'Erhalten Sie einen CO₂-Wert von 15 % oder weniger. Fahren Sie los, um Ihre Punktzahl zu erhalten!',
    txt_global_std_TaxInvoice3: 'Doc Nr',
    txt_global_std_enerfyplugin: 'Enerfy Plug-in',
    txt_global_std_addnoteshere: 'Notizen hier einfügen',
    txt_global_std_best: 'Beste',
    txt_global_std_p2pnogoback2: 'Nein, geh zurück',
    txt_global_std_fitnessmotion: 'Fitness / Bewegung',
    txt_global_std_p2pyourkintonumbercanbefoundwhenyoulogintoyourkintoaccount: 'Ihre Kinto-Nummer finden Sie, wenn Sie sich bei Ihrem Kinto-Konto anmelden.',
    txt_global_std_superstar: 'Superstar',
    txt_global_std_doihavetoactivatetheappeverytimeidriveanswer: 'Nein, die App arbeitet im Hintergrundmodus! Stellen Sie sicher, dass Bluetooth und Ortungsdienste auf Ihrem Telefon immer aktiviert sind.',
    txt_global_std_sorrywecouldnotfindthatemailaddresspleasecontactagentsupport: 'Leider konnten wir diese E-Mail-Adresse nicht finden. Bitte wenden Sie sich an den {agent}-Support.',
    txt_global_std_ayoucancontactoneofourlicensedinsuranceagenciesbyclickingonlinkorcallingagentatphoneno: 'A: Sie können eine unserer lizenzierten Versicherungsagenturen kontaktieren, indem Sie auf {link} klicken oder {agent} unter {phoneNo} anrufen.',
    txt_global_std_thisisonlyneededthefirsttimeyouconnecttoyourcar1: 'Dies ist nur erforderlich, wenn Sie zum ersten Mal eine Verbindung zu Ihrem Auto herstellen.',
    txt_global_std_somethingwentwrongandwecouldnotdisplayyourresulthere: 'Es ist ein Fehler aufgetreten und wir konnten Ihr Ergebnis hier nicht anzeigen.',
    txt_global_std_someoneelseispairedtoyourcarnodot: 'Jemand anderes ist mit deinem Auto gekoppelt',
    txt_global_std_selectagreementnumber: 'Vertragsnummer auswählen',
    txt_global_std_thisfunctionisunstable: 'Diese Funktion ist instabil und sollte mit Vorsicht verwendet werden.',
    txt_global_std_p2pfriendcalendar: 'Kalender von {name}',
    txt_global_std_hourh: 'h',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweek: 'Du bist wirklich gut gefahren! Ihr durchschnittlicher {scoretype} war eine Woche lang besser als {score}!',
    txt_global_std_vehicledata: 'Fahrzeugdaten',
    txt_global_std_amazingdriver: 'Erstaunlicher Fahrer',
    txt_global_std_bothyouandyourfriendwillget10kmfreedistancetodrivewithenerfy: 'Sie und Ihr Freund erhalten 10 km freie Strecke, um mit Enerfy zu fahren!',
    txt_global_std_confirmyouremailaddress: 'Bestätigen Sie Ihre E-Mail-Adresse',
    txt_global_std_rwmgoback: 'Geh zurück',
    txt_global_std_appcreator_upsell_pitch_bulletpoint1: 'Optimieren Sie Ihre digitale Customer Journey, indem Sie Markenfarben und Logos integrieren',
    txt_global_std_yourco2overview: 'Ihre CO2-Übersicht',
    txt_global_std_excessdiscount: 'Überschüssiger Rabatt',
    txt_global_std_addressline2: 'Adresszeile 2',
    txt_global_std_inbonusforyourinsurance: 'als Bonus für Ihre Versicherung.',
    txt_global_std_didyoureceivethepayment: 'Haben Sie die Zahlung erhalten?',
    txt_global_std_ensammenligningmeddennekreturoggennemsnittetafalledineturedeseneste30dage: 'En sammenligning med dennne køretur og gennemsnittet af all dine ture de seneste 30 dage.',
    txt_global_std_createyourapp: 'Erstellen Sie Ihre Anwendung',
    txt_global_std_dashboard_users: 'Benutzer',
    txt_global_std_ifbluetoothisalreadyactivatedyoucanstillgetthiserror: 'Wenn Bluetooth bereits aktiviert ist, wird diese Fehlermeldung weiterhin angezeigt, wenn Ihr Bluetooth in Ihrem Telefon nicht eingeschaltet ist.',
    txt_global_std_notificationsettings: 'Benachrichtigungseinstellungen',
    txt_global_std_loadingevents: 'Events werden geladen',
    txt_global_std_firstname: 'Vorname ',
    txt_global_std_hassentyouachallenge: 'hat Ihnen eine Herausforderung geschickt!',
    txt_global_std_wrongpasswordformatminimum8charactersatleastoneuppercaseletteronelowercaseletterandonenumber: 'Falsches Passwortformat. Mindestens 8 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl. ',
    txt_global_std_pigwon: 'Gewinner von head2head',
    txt_global_std_personalizeyourchallenge: 'Personalisieren Sie Ihre Herausforderung',
    txt_global_std_thereisnopermissions: 'Es gibt keine Berechtigungen',
    txt_global_std_claimsdata: 'Schadensdaten',
    txt_global_std_badinternetconnection: 'Schlechte Internetverbindung. Dies kann zu Verzögerungen in der App führen und sich auf Ihre Fahrdaten auswirken.',
    txt_global_std_test_key22: 'das ist ein test 2',
    txt_global_std_6months: '6 Monate',
    txt_global_std_groupvsgroup: 'Gruppe gegen Gruppe',
    txt_global_std_reportaccidentonline: 'Unfall online melden',
    txt_global_std_date: 'Datum',
    txt_global_std_communicate_upsell_pitch_bulletpoint2: 'Verbinden Sie sich mit Benutzern auf ihren bevorzugten Kanälen',
    txt_global_std_v2risklevel: 'Risikostufe',
    txt_global_std_hereyoucanloweryoursubscriptioncostbyupto20permonthbydrivingsafe2: 'Hier können Sie Ihre Abonnementkosten um bis zu 20 % pro Monat senken, indem Sie sicher fahren.',
    txt_global_std_ifyouareaninsurancecustomeryoucanfindyourpolicynumberwhenyoulogintoyourappnameaccount: 'Wenn Sie ein Versicherungskunde sind, finden Sie Ihre Versicherungsnummer, wenn Sie sich bei Ihrem Konto {appname} anmelden.',
    txt_global_std_above1000streaklocked: 'Schalte diesen Erfolg frei, indem du 5 Mal mit einer Punktzahl über 1000 fährst.',
    txt_global_std_Gdpr_Text2: 'Wir haben Ihre Anfrage erhalten, die Informationen in Ihrem Konto zu löschen. Bitte beachten Sie, dass das Entfernen Ihres Kontos bedeutet, dass alle Daten, einschließlich Fahrdaten, Teams, Renndaten und Wettkämpfe, gelöscht werden. Gelöschte Daten können nicht wiederhergestellt werden.',
    txt_global_std_loadingusers: 'Benutzer werden geladen...',
    txt_global_std_createapikey: 'API-Schlüssel erstellen',
    txt_global_std_unlockeddescriptionlabel: 'Freigeschaltete Beschreibung, in App angezeigt',
    txt_global_std_totalpointscollected: 'Gesamtpunktzahl gesammelt',
    txt_global_std_p2ptogetaccesstoallfunctionsinthisappyouneedtobeinvitedbyakintocarowner: 'Um auf alle Funktionen dieser App zugreifen zu können, müssen Sie von einem Kinto-Autobesitzer eingeladen werden.',
    txt_global_std_hour: 'Stunde',
    txt_global_std_yourteam: 'Ihr Team',
    txt_global_std_p2plendcar: 'Auto ausleihen',
    txt_global_std_demotext1: 'Sehen Sie, wie viel Co2 Sie beim Fahren mit dem Smart gespart haben.',
    txt_global_std_p2pconfirmselection: 'Auswahl bestätigen',
    txt_global_std_skeylevel: 'Niveau',
    txt_global_std_terminateinsurance2: 'Versicherung kündigen',
    txt_global_std_achievements: 'Erfolge',
    txt_global_std_applyfilters: 'Filter anwenden',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsabouttheagentappandcompeteagainstthemforlowerscores: 'A: Sobald Sie die App mit dem Bluetooth Ihres Fahrzeugs gekoppelt haben und der App die Verwendung von Ortungsdiensten erlauben, gleichen wir Ihre Fahrgewohnheiten mit der Bibliothek unserer KI mit Fahrermustern ab und stellen eine Punktzahl bereit. Ihre Versicherungsprämie richtet sich danach, wer Ihr Fahrzeug fährt und wie gut er fährt. Jeder, der Ihr Fahrzeug fährt, wird ermutigt, die App herunterzuladen und seine Punktzahlen zu verfolgen. Erzählen Sie Ihren Freunden von der {agent}-App und treten Sie gegen sie an, um niedrigere Punktzahlen zu erzielen.',
    txt_global_std_carsharingcompany: 'Carsharing-Unternehmen',
    txt_global_std_communicate_upsell_pitch_bulletpoint3: 'Kommunizieren Sie kontextbezogene Botschaften zum richtigen Zeitpunkt',
    txt_global_std_p2pwantstoborrowyour: ' will deine ausleihen',
    txt_global_std_editgroup: 'Gruppe bearbeiten',
    txt_global_std_coreresources: 'Kernressourcen',
    txt_global_std_highrisk: 'Hohes Risiko',
    txt_global_std_cosavings: 'CO²-Einsparung',
    txt_global_std_fitnessreasontext: 'Fitness / Bewegung sind erforderlich, um festzustellen, ob Sie fahren oder gehen.',
    txt_global_std_p2perrorcostcannotbegreaterthantotalcosttosplit: 'Fehler - Kosten dürfen nicht höher sein als die Gesamtkosten für die Aufteilung',
    txt_global_std_register: 'Registrieren',
    txt_global_std_Unsubscribe: 'Klicken Sie hier, um diese Liste abzubestellen.',
    txt_global_std_team: 'Team',
    txt_global_std_greatdriving: 'Großartiges Fahren!',
    txt_global_std_aroundtheclock: 'Rund um die Uhr',
    txt_global_std_enteravalidvehicleidentification: 'Geben Sie eine gültige Fahrzeugidentifikation ein.',
    txt_global_std_createnewbtntext: 'Erstelle neu',
    txt_global_std_everydayhero: 'Held des Alltags',
    txt_global_std_resultofanalyse: 'Ergebnis der Analyse',
    txt_global_std_MSIG_Onboarding3: 'das gilt ab',
    txt_global_std_p2paccept: 'Akzeptieren',
    txt_global_std_cimage3h: 'Sehen Sie Geschwindigkeitsbegrenzungen voraus',
    txt_global_std_goldlevel: 'Gold Level',
    txt_global_std_p2puserborrowedcartimesph: '{user} hat {auto} {times} mal ausgeliehen',
    txt_global_std_smartdriverweeklylockedkey: 'Um dieses Abzeichen zu erhalten, müssen Sie eine Woche lang eine Punktzahl von über 3,5 erreichen.',
    txt_global_std_p2ppushbodynewpaymentquestion: 'Did you receive a payment from {name}? Make sure to mark it as paid.',
    txt_global_std_howdoicompeteinappchallenge: 'Wie {appname} ich an der {appname} Challenge teil?',
    txt_global_std_forthisapptoworkproperlylocationservicemustbeallowed: 'Damit diese App ordnungsgemäß funktioniert, muss der Ortungsdienst auf Ihrem Gerät zulässig sein. Bitte wählen Sie "Immer" in den Standorteinstellungen.',
    txt_global_std_mapsarenotavailableforthisperiod: 'Karten sind für diesen Zeitraum nicht verfügbar',
    txt_global_std_faq: 'FAQ',
    txt_global_std_searchdivision: 'Suchabteilung',
    txt_global_std_of: 'von',
    txt_global_std_PolicySchedule12: 'Abdeckungstyp',
    txt_global_std_chooseyouroption: 'Wählen Sie Ihre Option:',
    txt_global_std_rwmsheettext: 'Senden Sie einen Link an Ihren Freund, damit er die Pioneer-App herunterladen kann. Sobald sie ein Konto erstellt haben, werden sie in Ihrer Freundesliste angezeigt. Sie können dann eine Fahrt registrieren, bei der Sie eine Fahrt geteilt haben.',
    txt_global_std_PaddonQuote: 'Träume Sie es nicht nur, erreichen Sie es! Machen Sie es für den Planeten!',
    txt_global_std_locked_account: 'Dieser Benutzer wurde für zu viele fehlgeschlagene Anmeldeversuche gesperrt. Versuchen Sie es später erneut.',
    txt_global_std_30days: '30 Tage',
    txt_global_std_account: 'konto',
    txt_global_std_challengedashboard: 'Herausforderungs-Dashboard',
    txt_global_std_youcanonlyregisteraridesharingtriponceperweek: 'Sie können nur einmal pro Woche eine Mitfahrgelegenheit anmelden',
    txt_global_std_thephonenumbercannotincludespecialcharacterslikes: `Die Telefonnummer darf keine Sonderzeichen wie "'?&/<>\\s#!%$%^*- enthalten.`,
    txt_global_std_writemessage: 'Schreibe eine kleine Nachricht',
    txt_global_std_costoverview: 'Kostenübersicht',
    txt_global_std_p2pyouandmembersborrowingyourcarneedtoconnecttheapptoyourcarsbluetooth: 'Sie und Mitglieder, die Ihr Auto ausleihen, müssen die App vor der Fahrt mit dem Bluetooth-System Ihres Autos verbinden.',
    txt_global_std_yourreportisready: 'Ihr Bericht ist fertig!',
    txt_global_std_needstobeavalidphonenumbermustbeavalidsizeandcanonlyincludenumbersand: 'Muss eine gültige Telefonnummer sein. Muss eine gültige Größe haben und darf nur Zahlen und + enthalten',
    txt_global_std_week: 'Wochen',
    txt_global_std_reviewbeforepublishing: 'Überprüfung vor der Veröffentlichung',
    txt_global_std_addnewuser: 'Neuen Benutzer  hinzufügen',
    txt_global_std_p2pfaq3q: 'Was ist, wenn ich nicht finde, wo das Auto geparkt ist?',
    txt_global_std_typeofscore: 'Art der Partitur',
    txt_global_std_p2pthefollowingbonuswillbegiven: 'Der folgende Bonus wird gegeben.',
    txt_global_std_JyrkiainenFacts: 'Aufgewachsen im Motorsport in einer Rennsportfamilie, Tochter von Minna Sillankorva, 1991 Weltmeisterin der Damenrallye und neunfache finnische Meisterin',
    txt_global_std_yourtripsinsight: 'Ihre Reiseeinblicke',
    txt_global_std_account1: 'Konto',
    txt_global_std_toptrips: 'Top-Reisen',
    txt_global_std_fax: 'Fax',
    txt_global_std_highusageparamamount: 'Hoher Verbrauch {Betrag}.',
    txt_global_std_startyourcarandmakesureyourbluetoothison: 'Starten Sie Ihr Auto und stellen Sie sicher, dass Ihr Bluetooth aktiviert ist.',
    txt_global_std_selectaminimumoftwogroupsthatshouldcompeteagainsteachotherinthechallenge: 'Wählen Sie mindestens zwei Gruppen aus, die in der Challenge gegeneinander antreten sollen.',
    txt_global_std_examplevalue: 'Beispielwert',
    txt_global_std_PolicySchedule1: 'ZEITPLAN',
    txt_global_std_p2ppaywith: 'Bezahlen mit',
    txt_global_std_german: 'Deutsch',
    txt_global_std_presstoconnect: 'Drücken Sie, um eine Verbindung herzustellen',
    txt_global_std_connectiontoyourcar: 'Mit Ihrem Auto verbinden',
    txt_global_std_buyfiasdc: 'Kaufen Sie FIA SDC',
    txt_global_std_copiedtoclipboard: 'In die Zwischenablage kopiert!',
    txt_global_std_clickheretoseewhy: 'Klicken Sie hier, um zu sehen, warum',
    txt_global_std_therequestwasunacceptableoftenduetomissingarequiredparameter: 'Die Anfrage war nicht akzeptabel, oft weil ein erforderlicher Parameter fehlte.',
    txt_global_std_thedescriptioncantbelongerthan180characters: 'Die Beschreibung darf nicht länger als 180 Zeichen sein',
    txt_global_std_previewapp: 'Vorschau-App',
    txt_global_std_usersuccessfullyadded2: 'Benutzer erfolgreich hinzugefügt',
    txt_global_std_waitingtodownloadyourapp: 'Warten auf den Download Ihrer App',
    txt_global_std_fiaquarterfinalparticipaterule2020: 'Um am Qualifikationsfinale teilnehmen zu können, müssen Sie einer der Top 20 Fahrer oder einer der Top 3 Fahrer der Top 3 Teams sein.',
    txt_global_std_aphonenumbermayonlyincludenumerics: 'Eine Telefonnummer darf nur Ziffern enthalten,',
    txt_global_std_challengesuccessfullyremoved: 'Herausforderung erfolgreich entfernt',
    txt_global_std_topfeatures: 'Top-Features',
    txt_global_std_p2pkintomonthlyfee: 'Kinto monatliche Gebühr',
    txt_global_std_pickyourcolortheme: 'Wählen Sie Ihr Farbthema',
    txt_global_std_calledsalvage: 'Rettung gerufen',
    txt_global_std_toreportitnow: 'jetzt zu melden.',
    txt_global_std_cimage9d: 'Verwenden Sie den Tempomat nur auf flachen Strecken, halten Sie das Gas ansonsten unverändert.',
    txt_global_std_downloadingdata: 'Daten herunterladen',
    txt_global_std_confirm2: 'Bestätigen',
    txt_global_std_cimage19d: 'Behalten Sie das Gas - NICHT - die Geschwindigkeit konstant!',
    txt_global_std_sendchallengeinvitation: 'Challenge-Einladung senden',
    txt_global_std_validforxxminutes: '(Gültig für 6 Minuten)',
    txt_global_std_TaxInvoice6: 'WICHTIGER HINWEIS:',
    txt_global_std_male: 'Männlich ',
    txt_global_std_getanoverviewofyourcustomerportfolio: 'Verschaffen Sie sich einen Überblick über Ihr Kundenportfolio',
    txt_global_std_exceededinsureddistance: 'Sie haben jetzt Ihre Jahreskilometer überschritten und erhalten keinen vollen Schadensersatz. Bitte kontaktieren Sie uns, damit wir den Kilometerstand anpassen und sicherstellen können, dass Sie ordnungsgemäß versichert sind.',
    txt_global_std_howwilligetpaidforaclaim: 'Wie werde ich für einen Anspruch bezahlt?',
    txt_global_std_pleasecontactsupportlinkalllowercaseifyouhavenotreceivedthereaderafter7businessdays: 'Bitte {contactSupportLinkAllLowerCase}, wenn Sie das Lesegerät nach 7 Werktagen noch nicht erhalten haben.',
    txt_global_std_howwewantyoudata: 'Wie wir Ihre Daten wollen',
    txt_global_std_getinsured: 'Versichern Sie sich',
    txt_global_std_viewwhoisparticipatinginthechallenge: 'Sehen Sie, wer an der Herausforderung teilnimmt',
    txt_global_std_resendtemporarypassword: 'Temporäres Passwort erneut senden',
    txt_global_std_day: 'Tag',
    txt_global_std_sendalinksothatthepersondrivingyourcarcandownloadtheapp: 'Senden Sie einen Link, damit die Person, die Ihr Auto fährt, die App herunterladen kann.',
    txt_global_std_documentcar: 'Dokument Auto',
    txt_global_std_competitionrulesapplied: 'Wettbewerbsregeln angewendet',
    txt_global_std_renewinsurance: 'Versicherung erneuern',
    txt_global_std_incabtraining: 'Im Taxitraining',
    txt_global_std_testingnewfronttranslation3: 'testennewfronttranslation3',
    txt_global_std_reporthistory: 'Verlauf melden',
    txt_global_std_yournicknameshouldcontainatleastthree2: 'Ihr Spitzname sollte mindestens drei (3) Zeichen und keine Sonderzeichen enthalten.',
    txt_global_std_seeperiod: 'Einzelheiten',
    txt_global_std_areyousureyouwanttodeletethisitem: 'Möchten Sie dieses Element wirklich löschen?',
    txt_global_std_validto: 'Gültig bis',
    txt_global_std_downloadpersonaldata: 'Persönliche Daten herunterladen',
    txt_global_std_seedemo: 'Siehe Demo',
    txt_global_std_MSIG_Onboarding6: 'Was Sie jetzt tun müssen, ist:',
    txt_global_std_worldchallenge2022: 'Weltherausforderung 2022',
    txt_global_std_sidekickprivacypolicy: 'Datenschutz-Bestimmungen',
    txt_global_std_fiaheatrule2020: 'Sie müssen während eines Laufs (Woche) mehr als 10 km fahren, um eine Punktzahl in der Hitze-Bestenliste zu erzielen.',
    txt_global_all_weekend: 'Wochenende',
    txt_global_std_Fia_Receipt_Text12: 'Wenn Sie Fragen haben, senden Sie uns bitte eine E-Mail:',
    txt_global_std_p2pselectedowner: 'Ausgewählter Besitzer',
    txt_global_std_MSIG_InsuranceLetter30: 'MS First Capital Insurance Limited',
    txt_global_std_automotivecompanydescription: 'Neue Eigentumsmodelle und Fahrerengagement.',
    txt_global_std_yourinsuranceexpires: 'Ihre Versicherung läuft ab: {insuranceenddate} Denken Sie daran, vor Ablauf Ihrer Versicherung ein neues Foto von Ihrem Kilometerzähler zu machen.',
    txt_global_std_location2: 'Ort',
    txt_global_std_youneedtofillinallnecessaryfields: 'Sie müssen alle erforderlichen Felder ausfüllen',
    txt_global_std_retry123: 'Wiederholen',
    txt_global_std_apicreator: 'API-Ersteller',
    txt_global_std_resetyourpassword: 'Setze dein Passwort zurück',
    txt_global_std_hello: 'Hallo',
    txt_global_std_nogoback: 'Nein, gehen Sie zurück!',
    txt_global_std_KristofferssonShortDescription: 'World RX Champion 2017 & 2018',
    txt_global_std_p2pdeny: 'Verweigern',
    txt_global_std_youhavereducedtheaveragecoemissionsofyourcarby: 'Sie haben den durchschnittlichen CO₂-Ausstoß Ihres Autos um reduziert {percent}',
    txt_global_std_p2pleftthegroup: 'hat die Gruppe verlassen',
    txt_global_std_notavailableindemo: 'Nicht als Demo verfügbar',
    txt_global_std_publishunpublish: 'Veröffentlichen / Veröffentlichung aufheben',
    txt_global_std_wanttojoinanevent: 'Möchten Sie an der Veranstaltung teilnehmen?',
    txt_global_std_PSolbergWhyjoin: 'demnächst',
    txt_global_std_moveyourselfandotherstosecure: 'Bringen Sie sich und andere an einen sicheren Ort. Im Falle einer Verletzung rufen Sie an:',
    txt_global_std_p2pnotavalidprice: 'Kein gültiger Preis',
    txt_global_std_cimage37h: 'Lernen Sie wie weit Ihr Fahrzeug bei verschiedenen Geschwindigkeiten rollt.',
    txt_global_std_hereswhereyouhavethelargestimprovementpossibility: 'Hier haben Sie die größte Verbesserungsmöglichkeit',
    txt_global_std_areyousureyouwanttodeletethechallengeoncedeletedyouwillnolongerbeabletoseethechallengeresultsparticipantswillstillbeabletoseetheirtripsinthefiasdcapp: 'Möchten Sie die Herausforderung wirklich löschen? Nach dem Löschen können Sie die Challenge-Ergebnisse nicht mehr sehen. Die Teilnehmer können ihre Reisen weiterhin in der FIA SDC-App sehen.',
    txt_global_std_year: 'Jahr',
    txt_global_std_phonenumbercannotincludecharacterslike6062s: `Telefonnummer darf keine Zeichen wie "'?&/&#60; &#62;\\s#!%$%^*- enthalten`,
    txt_global_std_whenyouhavecompletedyourfirstdrivewithenerfy: 'Wenn Sie Ihre erste Fahrt mit Enerfy abgeschlossen haben, können Sie Ihre Kosten und Rechnungen einsehen.',
    txt_global_std_onboardingtype: 'Onboarding-Typ',
    txt_global_std_enteravalidemailaddress: 'Geben Sie eine gültige E-Mailadresse an.',
    txt_global_std_showstimesincethevehiclehasbeenparked: 'Zeigt die Zeit an, seit das Fahrzeug geparkt wurde.',
    txt_global_std_200kmlockedkey: 'Fahren Sie eine Strecke von 200 km, um dieses Abzeichen zu erhalten.',
    txt_global_std_twofactorenabled1: 'Zwei Faktor aktiviert',
    txt_global_std_examplemessagesms: 'Hallo! Dies ist ein Beispiel für eine Textnachricht.',
    txt_global_std_p2pspecifiedprice: 'Spezifizierter Preis',
    txt_global_std_oilandoilfilter: 'Öl und Ölfilter',
    txt_global_std_maximizeheader: 'Maximieren',
    txt_global_std_daysdriven: 'Tage gefahren',
    txt_global_std_p2pwithfamilyandfriends: 'mit Familie und Freunden',
    txt_global_std_p2pfaq9q: 'Was ist, wenn mein Freund das Auto vorzeitig zurückgibt?',
    txt_global_std_joinclubsevent: 'Nehmen Sie an der Veranstaltung des Clubs teil',
    txt_global_std_thesamegeographicalroutewillbescoredamaximumof10timesinoneday: '• Die gleiche geografische Route wird maximal 10 Mal an einem Tag gewertet.',
    txt_global_std_livetopdrivers: 'Live-Top-Fahrer',
    txt_global_std_p2pcostsplitinvoice: 'Kostenaufteilungsrechnung',
    txt_global_std_news: 'Aktivität',
    txt_global_std_authenticatedrequest: 'Authentifizierte Anfrage',
    txt_global_std_enterpasswordtoremove: 'Geben Sie das Passwort zum Entfernen ein',
    txt_global_std_loadingapps: 'Anwendungen werden geladen...',
    txt_global_std_SendMyPosition_Hello: 'Hello',
    txt_global_std_basecolor: 'Grundfarbe',
    txt_global_std_enddateisrequired: 'Enddatum ist erforderlich',
    txt_global_std_sendalinksothatthepersondrivingyourcarcandownloadtheappnameapp: 'Senden Sie einen Link, damit die Person, die Ihr Auto fährt, die App {appname} herunterladen kann.',
    txt_global_std_reportaccidentbottomtext: 'Bitte laden Sie innerhalb von 24 Stunden nach Ihrer Meldung Bilder Ihres Autos hoch. Sie können oben auf Ihren alten Bericht klicken, um Bilder hinzuzufügen.',
    txt_global_std_therearenoongoinggamesrightnow: 'Derzeit gibt es keine laufenden Spiele.',
    txt_global_std_loginsignup: 'Einloggen / Anmelden',
    txt_global_std_alertweredrivingyoudrivingbody: 'Denken Sie daran, Ihre Fahrten in der App zu starten und zu stoppen, um Ihre Fahrpunktzahl zu erhalten.',
    txt_global_std_connectyourgarminwatch: 'Verbinden Sie Ihre Garmin-Uhr',
    txt_global_std_pickendtime: 'Wählen Sie die Endzeit',
    txt_global_std_NewPW_Text3: 'ist:',
    txt_global_std_BeforeFirstBill_Text2: 'Was werde ich bezahlen?',
    txt_global_std_wildfirelockedkey: 'Fahren Sie eine Woche lang mit einem Durchschnittslevel von 4,4 oder besser, um diesen Erfolg freizuschalten und 5000 Punkte für Ihre Kinto-Brieftasche zu sammeln.',
    txt_global_std_p2ptoconfirmprice: 'um den angegebenen Preis zu bestätigen',
    txt_global_std_selectgroupsintheformandclickonthegeneratebuttonaccessthecode: 'Wählen Sie Gruppen im Formular aus und klicken Sie auf die Schaltfläche Generieren, um auf den Code zuzugreifen',
    txt_global_std_or: 'oder',
    txt_global_std_4thdate: '4',
    txt_global_std_setupmanualstartstop: 'Einrichtung: Manueller Start & Stopp',
    txt_global_std_yourdrivingstatsforthistripcomparedtoyour30daysaverage: 'Ihre Fahrstatistiken für diese Reise im Vergleich zu Ihrem 30-Tage-Durchschnitt.',
    txt_global_std_wrongformat: 'Falsches Format!',
    txt_global_std_driventimeperperiod: 'Fahrzeit pro Periode',
    txt_global_std_speed: 'Geschwindigkeit',
    txt_global_std_events_overview_total: 'Gesamt',
    txt_global_std_MSIG_InsuranceLetter41: 'Gesetz über den Straßenverkehr, 1987 (Malaysia)',
    txt_global_std_appcreator: 'App-Ersteller',
    txt_global_std_earnpercentpremiumreductionperperiod: 'Verdienen Sie {percent} Prämienreduzierung pro Periode',
    txt_global_std_activationcodevalidated: 'Aktivierungscode validiert',
    txt_global_std_countday: '{count} Tag',
    txt_global_std_selectfriend: 'Freund auswählen ',
    txt_global_std_july: 'Juli ',
    txt_global_std_ConfirmFia_Text7: 'Denken Sie daran, 10 Minuten vor dem von Ihnen gewählten Zeitfenster am Veranstaltungsort anzukommen.',
    txt_global_std_KristofferssonChamp: 'World RX Champion 2017 & 2018',
    txt_global_std_wewillnowbuildyourdemoapp: 'Wir bauen nun Ihre Demo-App.',
    txt_global_std_youdroveextremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedthisbadge: 'Du bist sehr gut gefahren! Ihr durchschnittlicher {scoretype} war eine Woche lang besser als {score}! Du hast dir dieses Abzeichen verdient!',
    txt_global_std_congrats: 'Herzliche Glückwünsche!',
    txt_global_std_p2ppushtitlehasnowfinalizedthebooking: 'Buchung abgeschlossen',
    txt_global_std_noinvoicesyet: 'Noch keine Rechnungen',
    txt_global_std_consentInfoNo: 'Sie haben Ihre &quot;Einwilligung&quot; in den Einstellungen nicht aktiviert. Wenn &quot;Zustimmung&quot; nicht aktiviert ist, können wir nicht alle aufregenden Wettbewerbe und Tipps, die in der Tryg Drive-App bereitgestellt werden, fortlaufend teilen.',
    txt_global_std_friends: 'Freunde',
    txt_global_std_searchbynamegrouporscore: 'Suchen Sie nach Name, Gruppe oder Punktzahl',
    txt_global_std_balancing: 'Ausbalancieren',
    txt_global_std_ChicheritInterests: 'Demnächst...',
    txt_global_std_ayourpolicybeginsonthedateandtimewhenyouelectronicallysigntheapplicationandmakethedownpaymentonyourpolicy: 'A: Ihre Police beginnt mit dem Datum und der Uhrzeit, an dem Sie den Antrag elektronisch unterschreiben und die Anzahlung auf Ihre Police leisten.',
    txt_global_std_manageagreementshere: 'Verwalten Sie hier Vereinbarungen',
    txt_global_std_inmostcarstheobdsocket: 'Bei den meisten Fahrzeugen befindet sich die OBD-Buchse links unter dem Lenkrad. Wenn Sie die OBD-Buchse immer noch nicht finden können, klicken Sie hier.',
    txt_global_std_p2pgiveadiscountiffriend: 'Geben Sie einen Rabatt, wenn Ihr Freund Ihr Auto auf sichere Weise fährt.',
    txt_global_std_improvementspeedarea: 'Geschwindigkeitsbereich verbessert',
    txt_global_std_otherreasonsthatyourcarisnotshowingupinthelist: 'Andere Gründe, warum Ihr Auto nicht in der Liste angezeigt wird:',
    txt_global_std_success: 'Erfolg!',
    txt_global_std_phonenumberforinsurance: 'Geben Sie die Telefonnummer ein, die Sie bei der Anmeldung zu Ihrer Versicherung verwendet haben.',
    'txt_global_std_Al-KhelaifiWhyjoin': 'Weil ich verschiedene Arten von Herausforderungen mag und ein wettbewerbsfähiger Fahrer bin.',
    txt_global_std_ifyoureferafriendyoubothgetnok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsuranceamountinmarkedfontshowsvalidamountsaddedtothepiggybankpaymentinordertoreceiveadrivingdividend75ofthedistancetraveledmustbecompletedwiththeappandchiptenfoldremembertohaveboththeappandbluetoothonwhenyoudriveandthetripwillberegistered: 'Wenn Sie einen Freund empfehlen, erhalten Sie beide NOK 250 im Sparschwein. Dies setzt voraus, dass Sie beide mindestens einen Monat nach Versicherungsbeginn Kunde von Tryg sind. Betrag in markierter Schrift, zeigt gültige Beträge an, die dem Sparschwein hinzugefügt wurden. Zahlung\n' +
      'Um eine Fahrdividende zu erhalten, müssen 75% der zurückgelegten Strecke mit der App und dem Chip zehnfach zurückgelegt werden. Denken Sie daran, während der Fahrt sowohl die App als auch Bluetooth eingeschaltet zu haben, damit die Fahrt registriert wird.',
    txt_global_std_startdrivingsaferwithmefollowthelinkbelowandget10kmdistancetodrivewithenerfy: 'Fahren Sie mit mir sicherer! Folgen Sie dem Link unten und Sie erhalten 10 km Entfernung, um mit Enerfy zu fahren.',
    txt_global_std_parkingaccident2: 'Parkunfall',
    txt_global_std_achievementstitle: 'Erfolge',
    txt_global_std_maintainingintendedspeedisutmostimportantforfueleconomymindwanderingandlackoffocusoftencausesspeedvariationupanddowncausingunnecessaryaccelerationshenceincreasesfuelconsumptionnew: 'Die Beibehaltung der beabsichtigten Geschwindigkeit ist äußerst wichtig für den Kraftstoffverbrauch. Gedankenschweifen und mangelnde Konzentration verursachen oft Geschwindigkeitsschwankungen nach oben und unten, was zu unnötigen Beschleunigungen führt und somit den Kraftstoffverbrauch erhöht.',
    txt_global_std_inthenextpopupbr1makesurebprecisebissettobonbbr2chooseballowonceb: 'Im nächsten Popup: <br> 1. Stellen Sie sicher, dass <b> Präzise </b> auf: <b> Ein eingestellt ist. </b> <br> 2. Wählen Sie <b> Einmal zulassen. </b>',
    txt_global_std_p2pgoheretoinvitemembersandstartlendingyourcar: 'Laden Sie hier Mitglieder ein und leihen Sie Ihr Auto aus.',
    txt_global_std_p2piamhavingproblemswiththelinkloggingin: 'Ich habe Probleme mit dem Link / der Anmeldung.',
    txt_global_std_allowpushnotifications: 'Push-Benachrichtigungen zulassen',
    txt_global_std_currency: 'kr',
    txt_global_std_subscribe: 'Subscribe',
    txt_global_std_MikkelsenFacts: 'Andreas hat einen breiten sportlichen Hintergrund. Er hat im Alpinen Skisport mitgemacht und war Mitglied des Alpinen Skiteams der nationalen Junioren. 2003 und 2004 nahm er an Motocross-Rennen teil und vertrat auch die Junioren-Nationalmannschaft. Er wurde in den Jahren 2014, 2015 und 2016 Dritter der Rallye-Weltmeisterschaft und gewann drei Siege und 20 Podiumsplätze.',
    txt_global_std_expireson: 'Läuft aus am',
    txt_global_std_sentlogfiles: 'Protokolldateien gesendet',
    txt_global_std_athelowerthescorethemoresavingsyoucanearnyouwillseeyourpotentialsavingswitheachtrip: 'A: Je niedriger die Punktzahl, desto mehr Einsparungen können Sie erzielen. Sie sehen Ihr Einsparpotenzial bei jeder Fahrt.',
    txt_global_std_encouragetoavoiddistractions: 'Ermutigen Sie den Fahrer, Ablenkungen zu vermeiden',
    txt_global_std_nationalitycolon: 'Nationalität:',
    txt_global_std_pleaseselectvehiclesregistrationnumberyouaredriving: 'Bitte wählen Sie die Fahrzeugnummer, die Sie fahren.',
    txt_global_std_latesttrips: 'Die letzten Fahrten',
    txt_global_std_avgscore123: 'Durchschn. Ergebnis',
    txt_global_std_other: 'Andere',
    txt_global_std_twofactorauthenticationcode: 'Zwei-Faktor-Authentifizierungscode',
    txt_global_std_writeamessagetoafriendoragroup: 'Schreiben Sie eine Nachricht an einen Freund oder eine Gruppe',
    txt_global_std_resendactivationcode: 'Aktivierungscode erneut senden',
    txt_global_std_personalizeyouraccount: 'Personalisieren Sie Ihr Konto',
    txt_global_std_achievementtool_upsell_pitch: 'Erstellen Sie Ihre eigenen inspirierenden Abzeichen und Erfolge, um;',
    txt_global_std_addemail2: 'E-Mail hinzufügen',
    txt_global_std_addexistinguser: 'Bestehenden Benutzer hinzufügen',
    txt_global_std_miles: 'Meilen',
    txt_global_std_drivewithanaveragescoretypeofscoreorbetterforaweektounlockthisbadge: 'Fahren Sie eine Woche lang mit einem durchschnittlichen {Scoretype} von {Score} oder besser, um dieses Abzeichen freizuschalten.',
    txt_global_std_p2pfaq6a: '1.Öffnen Sie den Gruppenchat, den Sie verlassen möchten.\n' +
      '2.Drücken Sie auf das Einstellungssymbol oben rechts in der Gruppenchat-Kopfzeile.\n' +
      '3.Wählen Sie diesen Gruppenchat verlassen.',
    txt_global_std_writeyourregistrationnumber: 'Schreiben Sie Ihre Registrierungsnummer',
    txt_global_std_p2pcarrentoverview: 'Mietwagenübersicht',
    txt_global_std_cimage35h: 'Finden Sie den optimalen Gang beim Start',
    txt_global_std_Fia_Receipt_Text4: 'Sie können dies beim Anlegen eines Kontos manuell eingeben.',
    txt_global_std_discovermore: 'Entdecke mehr',
    txt_global_std_BeforeFirstBill_Text5: 'Sie können Ihre Kosten jederzeit in der App {appName} verfolgen. Dort werden die Kosten für jedes einzelne Fahrt und der Gesamtbetrag für den Monat angezeigt. Gehen Sie in der App zu "Kosten", um Ihre Kosten anzuzeigen.',
    txt_global_std_coolcatsunisshining: 'kühle Katzensonne scheint',
    txt_global_std_riskydecisionwithintrips: 'Riskante Entscheidung innerhalb von Reisen',
    txt_global_std_apireference: 'API-Referenz',
    txt_global_std_defaulthere: 'hier',
    txt_global_std_above1000locked: 'Fahre mit einer Punktzahl über 1000, um diesen Erfolg freizuschalten.',
    txt_global_std_allownotifications: 'Benachrichtigungen zulassen',
    txt_global_std_previousbonusperiod: 'Vorheriger Bonuszeitraum',
    txt_global_std_challengeaverage: 'Herausforderungsdurchschnitt',
    txt_global_std_uploadatleastfourphotosofyourcarfrontbackleftandrightsides: 'Laden Sie mindestens 4 Fotos Ihres Autos hoch. Vorne, hinten, linke und rechte Seite.',
    txt_global_std_teamleaderslatestscore: 'Teamleiters neueste Punktzahl:',
    txt_global_std_p2ppleaseselectavehicle: 'Bitte wählen Sie ein Fahrzeug aus',
    txt_global_std_wallet: 'Brieftasche',
    txt_global_std_earnedpoints: 'Verdiente Punkte',
    txt_global_std_cohabiting: 'Zusammenleben',
    txt_global_std_radiatorflush: 'Kühler spülen',
    txt_global_std_single: 'Single',
    txt_global_std_p2pincompletebooking: 'Unvollständige Buchung',
    txt_global_std_electric: 'Elektrisch',
    txt_global_std_notallowedtoorderanobdreader: 'Es ist nicht erlaubt, einen OBD-Leser zu bestellen',
    txt_global_std_finishadriveatscoretypescoreorbettertounlockthisachievementandearnpointspoints: 'Beende eine Fahrt mit {score} oder besser, um diesen Erfolg freizuschalten und {points} Punkte zu sammeln.',
    txt_global_std_tinaQuote: 'Power from within & the Power of choice',
    txt_global_std_threecharactersbetweenazand09: 'drei Zeichen zwischen AZ und 0-9',
    txt_global_std_saveenergyandco2: 'Sparen Sie Energie und CO₂',
    txt_global_std_trainingdate: 'Schulungsdatum',
    txt_global_std_successsenddetails: 'Details erfolgreich gesendet',
    txt_global_std_sendinvitetofriend: 'Einladung an einen Freund senden',
    txt_global_std_pigsinvitedlocked: 'Das bekommst du, wenn du einen Freund für Sidekick angeworben hast',
    txt_global_std_nodatafoundstartdrivingtoseethisstatistic: 'Keine Daten gefunden. Fahren Sie los, um diese Statistik zu sehen!',
    txt_global_std_okdriving: 'Ok fahren!',
    txt_global_std_Fia_Receipt_Text5: '',
    txt_global_std_poweredby: 'unterstützt von',
    txt_global_std_demotext6: 'Ich helfe Ihnen, sicher zu fahren! Mit Tipps helfe ich dir auf dem Weg, fünf Sterne zu bekommen.',
    txt_global_std_addall: 'Alles hinzufügen',
    txt_global_std_insuranceoverview: 'Versicherungsübersicht',
    txt_global_std_HansenInterests: 'Training, Motorsport und Barista',
    txt_global_std_useralreadyexists: 'Konnte nicht das Konto erstellen, E-Mail existiert bereits!',
    txt_global_std_rwmnodrivestitle: 'Sie haben noch keine Reisen.',
    txt_global_std_editchallenge: 'Herausforderung bearbeiten',
    txt_global_std_monthlycost: 'Monatliche Kosten',
    txt_global_std_welcometoyournewapp: 'Willkommen zu deiner neuen App.',
    txt_global_std_weknowthisiscrazy: 'Wir wissen, dass das verrückt ist ...',
    txt_global_std_ayoucanfileaclaimbycontactingphonenoorclickingonfileaclaiminyouragentapp: 'A: Sie können einen Anspruch geltend machen, indem Sie sich an {phoneNo} wenden oder in Ihrer {agent}-App auf Anspruch einreichen klicken.',
    txt_global_std_damageguide: 'Schadensanleitung',
    txt_global_std_cimage36h: 'Versuchen Sie bei Hindernissen das komplette Anhalten zu vermeiden.',
    txt_global_std_speedgappoints: 'Geschwindigkeitslücken',
    txt_global_std_howdoisetupautomaticrenewal: 'Wie richte ich die automatische Verlängerung ein?',
    txt_global_std_availablepoints: 'Verfügbare Punkte',
    txt_global_std_locationservicealways: 'Ortungsservice (immer)',
    txt_global_std_signupwith: 'Mit {Name} anmelden',
    txt_global_std_enteravalidplatenumber: 'Geben Sie eine gültige Kennzeichen-Nummer ein.',
    txt_global_std_p2pusershareofcost: '{name} Kostenanteil',
    txt_global_std_finalresult: 'Endergebnis',
    txt_global_std_focus: 'Fokus',
    txt_global_std_yourbluetoothisntturnedon: 'Ihr Bluetooth ist nicht eingeschaltet',
    txt_global_std_nosplitsavailableforthisrun: 'Für diesen Lauf sind keine Splits verfügbar',
    txt_global_std_centralzone: 'Zentrale Zone',
    txt_global_std_technicalservice: 'Technischer Dienst',
    txt_global_std_cimage9h: 'Lieber Konstantfahrt als Geschwindigkeit',
    txt_global_std_okdriving2: 'Ok fahren',
    txt_global_std_coverage: 'Deckung',
    txt_global_std_selectaccount: 'Konto auswählen',
    txt_global_std_ifyouhavebluetoothyoucanturnonautomatic: 'Wenn Sie Bluetooth in Ihrem Auto haben, können Sie den automatischen Start und Stopp aktivieren.',
    txt_global_std_p2pgroupnamechanged: 'Gruppenname geändert',
    txt_global_std_address: 'Adresse',
    txt_global_std_discoverourheats: 'Entdecken Sie unsere Veranstaltungen',
    txt_global_std_sendmessage: 'NACHRICHT SENDEN',
    txt_global_std_p2paddedby: 'Hinzugefügt von',
    txt_global_std_turnonhazardlights: 'Warnblinkanlage einschalten',
    txt_global_std_soon: 'bald.',
    txt_global_std_alluserscompeteindividually: 'Alle Benutzer konkurrieren einzeln',
    txt_global_std_ecofriendweeklyunlockedkey: 'Sie haben diese Woche über {co2value} % CO₂ eingespart, indem Sie umweltfreundlich gefahren sind. Danke, dass Sie sich um die Umwelt kümmern!',
    txt_global_std_thepasswordminimumlengthis8charactersandmustinclude1numeralandspecialcharacter: 'Die Mindestlänge des Passworts beträgt 8 Zeichen und muss 1 Ziffer und ein Sonderzeichen enthalten',
    txt_global_std_lastname: 'Nachname',
    txt_global_std_translationtool_upsell_pitch: 'Verwalten Sie mühelos Ihre eigenen Übersetzungen und Sprachaktualisierungen für;',
    txt_global_std_wewillsendtheobdreadertotheaddressyouhaveregisteredwithusneedtochangeanythingcontactsupport: 'Wir senden den OBD-Leser an die von Ihnen bei uns registrierte Adresse. Müssen Sie etwas ändern? Kontaktieren Sie Support.',
    txt_global_std_loyaltypages: 'Treueseiten',
    txt_global_std_managepermissions: 'Berechtigungen verwalten',
    txt_global_std_addressline1: 'Anschrift Zeile 1',
    txt_global_std_youcanfindtheoutlethere: 'Die Outlet finden Sie hier',
    txt_global_std_whatwillhappeniftheobddeviceisactive: 'What will happen if the OBD device is active but I forget to start the app?',
    'txt_global_std_Al-KhelaifiChamp': 'Zweimaliger Meister der Katar-Tourenwagen-Meisterschaft. Zweimaliger Champion im nationalen Sprint von Katar. 2017 Spa 12H Langstreckenrennen der Klasse CUP1',
    txt_global_std_sharemycar: 'Teile mein Auto',
    txt_global_std_hereyoucanuploadanyextrainformationregardingyourclaim: 'Hier können Sie zusätzliche Informationen zu Ihrem Anspruch hochladen. Alle Felder sind optional.',
    txt_global_std_testglobal: 'global testen',
    txt_global_std_chassisenginenumber: 'Fahrgestell- / Motornummer',
    txt_global_std_youarenowloggedin: 'Sie sind nun eingeloggt!',
    txt_global_std_engagingteammembersinasharedmissionisgoodforproductivity: 'Die Einbindung von Teammitgliedern in eine gemeinsame Mission ist gut für die Produktivität',
    txt_global_std_letmein: 'Lassen Sie mich rein',
    txt_global_std_badformattingwithkey: 'Falsche Formatierung mit Schlüssel',
    txt_global_std_drives2: 'Fährt',
    txt_global_std_good: 'Gut',
    txt_global_std_questionsandanswers: 'Fragen & Antworten ',
    txt_global_std_uploadfromlist: 'Aus Liste hochladen',
    txt_global_std_somethingtherenewfake: 'Etwas neues Fake',
    txt_global_std_drivemorethan1kilometertogetmoredetailedanalyticsaboutyourtrip: 'Fahren Sie mehr als 1 Kilometer, um detailliertere Analysen zu Ihrer Reise zu erhalten.',
    txt_global_std_enteryouractivationcodeyoureceived: 'Geben Sie Ihren Aktivierungscode ein, den Sie bei der Anmeldung in Ihrem Aktivierungsschreiben oder Ihrer E-Mail erhalten haben.',
    txt_global_std_cimage16d: 'Planen Sie jede Geschwindigkeitssteigerung, um die geplante Höchstgeschwindigkeit beizubehalten oder so viele Meter wie möglich zu rollen.',
    txt_global_std_yourtripwasnotregisteredifyouhadyourpluginconnected: 'Ihre Reise wurde nicht registriert. Wenn Sie Ihr Plug-in angeschlossen hatten, als Sie mit dem Auto gefahren sind, aber die App, Bluetooth oder GPS / Ortungsdienste nicht aktiviert waren, um Fahrdaten aufzuzeichnen, werden die zurückgelegten Fahrten anschließend mit den Premium-Stufen 10 bis 15 berechnet.',
    txt_global_std_Gdpr_Text1: 'Hallo!',
    txt_global_std_byjoiningthechallengeyouacceptthe: 'Indem Sie an der Challenge teilnehmen, akzeptieren Sie die',
    txt_global_std_yourprofilephotowassaved: 'Ihr Profilfoto wurde gespeichert',
    txt_global_std_imagepickerunlocked: 'Wählen Sie das entsperrte Abzeichen-Symbol',
    txt_global_std_ecofriend: 'Umweltfreund',
    txt_global_std_p2punavaliblebetween: 'Nicht verfügbar zwischen',
    txt_global_std_youaregoodtogostartdriving: 'Sie sind gut zu gehen, fahren Sie los!',
    txt_global_std_thiswindowwillcloseinclosesecondsseconds: 'Dieses Fenster wird in {closeSeconds} Sekunden geschlossen',
    txt_global_std_failedtopublishreviews: 'Bewertungen konnten nicht veröffentlicht werden!',
    txt_global_std_FiaPassword_Text3: 'ist',
    txt_global_std_selectyourcountry: 'Wähle dein Land',
    txt_global_std_writecurrentpassword: 'Schreiben Sie das aktuelle Passwort',
    txt_global_std_compare: 'Vergleichen',
    txt_global_std_zoomtofit: 'Zum Anpassen zoomen',
    txt_global_std_head2head2: 'Kopf 2 Kopf',
    txt_global_std_p2pyouwillnotbeabletosendorreceivebookingsfromthisfriendanymore: 'Sie können keine Buchungen mehr von diesem Freund senden oder empfangen.',
    txt_global_std_addbackground: 'Hintergrund hinzufügen',
    txt_global_std_KristofferssonInterests: 'Meine Familie und Fitness',
    txt_global_std_passwordforname: 'Passwort für {name}',
    txt_global_std_collectedpoints: 'GESAMMELTE PUNKTE',
    txt_global_std_showdataby: 'Daten anzeigen nach',
    txt_global_std_elite: 'Elite',
    txt_global_std_yourinsurancehasexpired: 'Ihre Versicherung ist abgelaufen.',
    txt_global_std_MarklundMusic: 'Ich höre mir beim Autofahren gerne interessante Dokumentationen an. Wenn ich mich aber konzentrieren muss, ziehe ich es vor, ganz still zu sein.',
    txt_global_std_congratulationsyouhavesuccessfullyconnectedtoyourcar: 'Herzliche Glückwünsche! Sie haben erfolgreich eine Verbindung zu Ihrem Auto hergestellt.',
    txt_global_std_p2pcomingsoon: 'Kommt bald',
    txt_global_std_ofcourseyoumayaddphotoslater: 'Natürlich können Sie später Fotos hinzufügen',
    txt_global_std_pleaseselectaprojectfrommyprojects: ' Bitte wählen Sie ein Projekt aus Meine Projekte aus.',
    txt_global_std_pleasecheckyouremailtoverify: 'Bitte überprüfen Sie Ihre E-Mail zur Bestätigung',
    txt_global_std_connectyourself: 'Verbinden Sie sich',
    txt_global_std_startdate: 'Anfangsdatum',
    txt_global_std_youcannoteditthistranslationfromvisualeditorduetotechnicalreasonspleasecontactusourdeveloperswillupdateitforyou: 'Sie können diese Übersetzung aus technischen Gründen nicht im visuellen Editor bearbeiten. Bitte kontaktieren Sie uns, unsere Entwickler werden es für Sie aktualisieren.',
    txt_global_std_redeem: 'Einlösen',
    txt_global_std_warikan_onboarding8: '4. Laden Sie die Mitglieder ein, das Auto zu teilen, um eine gemeinsame Gruppe zu erstellen',
    txt_global_std_loyaltyemailheadertext: 'und belohnt werden!',
    txt_global_std_p2pemailchangedbookings: 'Geänderte Buchungen',
    txt_global_std_enterpasswordtopublishchanges: 'Geben Sie das Passwort ein, um Änderungen zu veröffentlichen',
    txt_global_std_badgeisworthxpoints: 'Das Abzeichen ist {points} Punkte wert',
    txt_global_std_welcometoname: 'Willkommen zu {appName}',
    txt_global_std_supportedfiles: 'Unterstützte Dateiformate: png, svg. max. Dateigröße: X MB.',
    txt_global_std_p2pwearehappythatyouarehere: 'Wir freuen uns, dass Sie hier sind!',
    txt_global_std_categorytypeheader: 'Typ',
    txt_global_std_earn3500pointsifyoubook15timesamonth: 'Sammeln Sie 3500 Punkte, wenn Sie 15 Mal im Monat buchen',
    txt_global_std_paynow: 'Zahlen Sie jetzt',
    txt_global_std_showthisreceiptatcheckout: 'Zeigen Sie diese Quittung an der Kasse.',
    txt_global_std_jointco2savings: 'Gemeinsame CO2-Einsparung\n',
    txt_global_std_p2pinvoices: 'Rechnungen',
    txt_global_std_sparklockedkey: 'Fahren Sie eine Woche lang mit einem Durchschnittslevel von 4 oder besser, um diesen Erfolg freizuschalten und 1000 Punkte für Ihre Kinto-Brieftasche zu sammeln.',
    txt_global_std_thefiasmartdrivingchallengeisaworldchallenge: '',
    txt_global_std_renewyourinsuranceformonthofmonthatalowercost: 'Verlängern Sie Ihre Versicherung für den Monat {month} zu einem geringeren Preis',
    txt_global_std_clubs: 'Vereine',
    txt_global_std_p2penterthetemporarypasswordsenttoyouremail: 'Geben Sie das temporäre Passwort ein, das Sie per E-Mail erhalten haben',
    txt_global_std_counthours: '{zählen} Stunden',
    txt_global_std_zipcodeglobal: 'Postleitzahl',
    txt_global_std_p2pconfirmed: 'Bestätigt',
    txt_global_std_premiuminsurancecode: 'Prämienversicherungscode',
    txt_global_std_adduserstogroup: 'Benutzer zur Gruppe hinzufügen',
    txt_global_std_p2pequallydivided: 'Gleich geteilt',
    txt_global_std_afteryoustartdrivingyourinvoiceswillshowuphere: 'Nachdem Sie losgefahren sind, werden Ihre Rechnungen hier angezeigt.',
    txt_global_std_saloon: 'Saloon',
    txt_global_std_awesomestreak: 'Super Streifen!',
    txt_global_std_currentstatus: 'Aktueller Status:',
    txt_global_std_emailisalreadyaddedtobeinvited: 'E-Mail wurde bereits hinzugefügt, um eingeladen zu werden',
    txt_global_std_hereyoucandocumentyourcar: 'Hier können Sie Ihr Auto dokumentieren.',
    txt_global_std_include: 'Umfassen',
    txt_global_std_web: 'Web',
    txt_global_std_p2pgoback: 'geh zurück',
    txt_global_std_carrescue: 'Abschleppdienst',
    txt_global_std_p2pyoudonthaveanyfriendsyet: 'Du hast noch keine Freunde.',
    txt_global_std_eventname: 'Veranstaltungsname',
    txt_global_std_this15discountcodecanberedeemedongarmincomonselectedgarminautomotivedevices: 'Dieser 15 %-Rabattcode kann auf garmin.com auf ausgewählten Garmin-Automobilgeräten eingelöst werden.',
    txt_global_std_yourdeviceisonitsway: 'Ihr Gerät ist unterwegs. Wenn es ankommt, müssen Sie es anschließen! In der Zwischenzeit können Sie einem Team beitreten und sich für die Heats anmelden!',
    txt_global_std_twoormoregroupscompeteagainsteachother: 'Zwei oder mehr Gruppen treten gegeneinander an',
    txt_global_std_agreementnumber2: 'Übereinkommensnummer:',
    txt_global_std_removingtheuserfromthegroupdoesnotdeletetheusersaccount: 'Durch das Entfernen des Benutzers aus der Gruppe wird das Benutzerkonto nicht gelöscht.',
    txt_global_std_pushdriveendlevel1: 'Wow, din kørsel er i verdensklasse! Tryk hende for at se detaljer om din kørtur.',
    txt_global_std_administrateandinviteusers: 'Benutzer verwalten und einladen.',
    txt_global_std_ecofriendunlockedkey: 'Sie haben diese Woche über {co2value} % CO₂ eingespart, indem Sie umweltfreundlich gefahren sind. Danke, dass Sie sich um die Umwelt kümmern!',
    txt_global_std_savemoneyonyourinsurance: 'Sparen Sie Geld bei Ihrer Versicherung.',
    txt_global_std_afollowthesestepstoconnecttheapptoyourcar1pressthedrivebuttonintheapp2startyourcarsengine3yourcarshouldshowupintheapp4selectyourcar: 'A: Befolgen Sie diese Schritte, um die App mit Ihrem Auto zu verbinden:\n' +
      '    1. Drücken Sie die Fahrtaste in der App\n' +
      '    2. Starten Sie den Motor Ihres Autos\n' +
      '    3. Dein Auto sollte in der App angezeigt werden\n' +
      '    4. Wählen Sie Ihr Auto\n',
    txt_global_std_firstdrive4: 'Erste Fahrt',
    txt_global_std_p2pnothinghaschanged: 'Nichts hat sich verändert',
    txt_global_std_recruitmentamount: 'Einstellungsbetrag',
    txt_global_std_repeatyourpassword: 'Wiederhole dein Passwort',
    txt_global_std_selectcategory: 'Kategorie wählen',
    txt_global_std_howdoidrivewellanswer: 'Ein sicherer Fahrer ist ein bewusster Fahrer. Beim sicheren Fahren geht es darum, sich zu 100% auf das Fahren und die Umgebung zu konzentrieren. Bleiben Sie wachsam und berücksichtigen Sie den Verkehrsrhythmus und andere Umstände wie Regen usw. PASSEN SIE IHRE GESCHWINDIGKEIT IMMER AN DIE VORHANDENEN BEDINGUNGEN AN.',
    txt_global_std_choose: 'Wählen:',
    txt_global_std_ifyoucannotfindyourfriendorarenotregisteredasauseryourfriendencouragesyoutodownloadtheappnameappandgetaprofile: 'Wenn Sie Ihren Freund nicht finden können oder nicht als Benutzer registriert sind, fordert Ihr Freund Sie auf, die App {appname} herunterzuladen und ein Profil zu erstellen',
    txt_global_std_addcarinvolved: 'Fahrzeug hinzufügen',
    txt_global_std_tinaDescription: "2008 bin ich im Nahen Osten gegen Nasser Saleh Al-Attiyha (Katar), heute der größte Sportprofil dort, angetreten - wir haben 2008 zwei Weltmeisterschaften gewonnen, einen in der Serie und einen in der Baja-Serie. In dieser Konstellation gab es weltweit nur eine solche erfolgreiche Sportmannschaft! Dass es uns gelungen ist, erfolgreich zu sein, möchte ich Nasser's Mutter danken, die ihren Sohn dazu erzogen hat, keine Vorurteile hinsichtlich Hautfarbe, Geschlecht oder Religion seines Kartenlesers zu haben. Wir haben auch die Rallye Dakar 2009 in Südamerika angeführt, als wir die sechste Etappe abbrechen mussten. Meine Karriere im Motorsport begann 1990. Seitdem habe ich drei Frauen-Weltmeistertitel gewonnen, zwei zweite Plätze in Gr. Die Rallye-Weltmeisterschaft von N. und zwei Weltcup-Rallye-Razzien 2008 und 2009. Zu meinen Hauptleistungen zähle ich einen zweiten und dritten Platz bei der berühmten Rallye Dakar. Meine berühmtesten Fahrer waren wahrscheinlich Colin McRae, Jutta Kleinschmidt, Kenneth Eriksson und Ari Vatanen. Außerdem bin ich bei etwa zwanzig internationalen Fahrern gesteuert. ",
    txt_global_std_youhavenoactivechallengesrightnow: 'Sie haben momentan keine aktiven Herausforderungen',
    txt_global_std_type2: 'Typ',
    txt_global_std_addfriend: 'Freund  hinzufügen',
    txt_global_std_confirmpurchase: 'Bestätige den Kauf',
    txt_global_std_pullthebartoseehowyourleveleffectsyourdiscount: 'Ziehen Sie an der Stange, um zu sehen, wie sich Ihr Level auf Ihren Rabatt auswirkt',
    txt_global_std_idontwanttoconnectnow: 'Ich möchte mich jetzt nicht verbinden',
    txt_global_std_p2ppushtitleyouhaveabookingtofinalize: 'Sie müssen eine Buchung abschließen',
    txt_global_std_stateinsuranceinformation: '{state} Versicherungsinformationen',
    txt_global_std_youhaveexceededthespeedlimitof: 'Sie haben die Höchstgeschwindigkeit von {maxSpeed} km / h überschritten.',
    txt_global_std_copyinvitationlink: 'Einladungslink kopieren',
    txt_global_std_carusage: 'Autonutzung',
    txt_global_std_selectyourcarmodeltoseethemostaccuratedata: 'Wählen Sie Ihr Automodell aus, um die genauesten Daten anzuzeigen',
    txt_global_std_createyourveryowncompanychallengeinthefiasdcandunderstandandmanagetheimpactyourteamhasonroadsafetyandco2emissions: 'Erstellen Sie Ihre ganz eigene Unternehmensherausforderung im FIA SDC und verstehen und verwalten Sie die Auswirkungen Ihres Teams auf die Verkehrssicherheit und die CO2-Emissionen.',
    txt_global_std_iobd2: '',
    txt_global_std_safedriverweeklylockedkey: 'Um dieses Abzeichen zu erhalten, müssen Sie eine Woche lang eine Punktzahl über {score} halten.',
    txt_global_std_connecttocarlockedkey: 'Verbinden Sie Ihre App über Bluetooth mit dem Auto, um dieses Abzeichen zu erhalten.',
    txt_global_std_p2pearnpointswhenyoudrivesafe: 'Sammeln Sie Punkte, wenn Sie sicher fahren',
    txt_global_std_connectwithbluetooth: 'Mit Bluetooth verbinden',
    txt_global_std_detailedstatisticsaboutyourdrivingwilldisplayherewhenyouhavedoneyourfirsttrip: 'Detaillierte Statistiken zu Ihrer Fahrt werden hier angezeigt, wenn Sie Ihre erste Fahrt gemacht haben.',
    txt_global_std_entertheinvitationcodeyouhavereceivedfromyourworkspaceadministrator: 'Geben Sie den Einladungscode ein, den Sie von Ihrem Workspace-Administrator erhalten haben',
    txt_global_std_division: 'AUFTEILUNG',
    txt_global_std_selectfriendsoragroup: 'Wählen Sie Freunde oder eine Gruppe aus',
    txt_global_std_p2ppushtitleexpensesapproved: 'Expenses approved',
    txt_global_std_ifyourfriendregisterswiththereferralcodebothyouandyourfriendwillreceivenok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsurancetheamountisenteredinthepiggybankwhentherecruitmentcodehasbeenregisteredyouhaveafulltimeoverviewofyourrecruitsonthesavingsside: 'Wenn sich Ihr Freund mit dem Empfehlungscode anmeldet, erhalten sowohl Sie als auch Ihr Freund NOK 250 im Sparschwein (dafür müssen Sie beide mindestens einen Monat nach Versicherungsbeginn Kunde von Tryg sein).\n' +
      '\n' +
      'Der Betrag wird nach der Registrierung des Einstellungscodes in das Sparschwein eingegeben. Auf der Sparseite haben Sie einen Vollzeit-Überblick über Ihre Rekruten.',
    txt_global_std_stardriverlockedkey: 'Beende eine Fahrt bei Punktzahl {score} oder besser, um dieses Abzeichen freizuschalten. Sie können sich dieses Abzeichen einmal pro Tag verdienen.',
    txt_global_std_createyourowncompanychallengeinjustthreesteps: 'Erstellen Sie in nur drei Schritten Ihre eigene Company Challenge!',
    txt_global_std_p2puserguides: 'Benutzerleitfäden',
    txt_global_std_describetheaccident: 'Beschreiben Sie den Unfall',
    txt_global_std_kintoemailheadertext: 'Mach bei Kinto mit und werde belohnt!',
    txt_global_std_status: 'Status',
    txt_global_std_comingsoon: 'Demnächst!',
    txt_global_std_ChicheritWhyjoin: 'Demnächst...',
    txt_global_std_needhelptogetstarted: 'Benötigen Sie Hilfe beim Einstieg?',
    txt_global_std_remainingpointstonextlevel: '{points} verbleibende Punkte bis {level}',
    txt_global_std_damagesalvagecall: 'Für Schäden',
    txt_global_std_lookslikethisisempty: 'Sieht aus wie dies leer ist!',
    txt_global_std_monthmon: 'Mon',
    txt_global_std_selectdates: 'Wählen Sie Daten aus',
    txt_global_std_createyourownappinafewclicks: 'Erstellen Sie mit wenigen Klicks Ihre eigene App',
    txt_global_std_usernotfound: 'Benutzer wurde nicht gefunden.',
    txt_global_std_recruitfivefriendsandyouallcompete: "Rekrutieren Sie 5 Freunde und der erste 'Heat' ist für Sie umsonst",
    txt_global_std_shootingstarnamekey: 'Sternschnuppe!',
    txt_global_std_conteactinformation: 'Kontaktinformation',
    txt_global_std_bestdriversrightnow: 'Beste Fahrer im Moment!',
    txt_global_std_nameofapp: 'Name der App',
    txt_global_std_quantitytoshow: 'Menge zu zeigen',
    txt_global_std_TaxInvoice8: 'Der Versicherungsschutz endet am 61. Tag, wenn die Prämie nicht innerhalb von 60 Tagen nach Vertragsbeginn gezahlt wird.',
    txt_global_std_checkifcorrectobdreader: 'Prüfen Sie, ob der OBD-Reader korrekt angeschlossen ist.',
    txt_global_std_youhaveonlydistancekmleft: 'Sie haben nur noch {distance}km übrig.',
    txt_global_std_p2pfaq8q: 'Wie kann ich mein Auto an Tagen, an denen ich es selbst benutze, nicht verfügbar machen?',
    txt_global_std_thisisadrivingappforustobeabletomeasureyourdrivingweneedtoaccesstobbluetoothlocationservicesbandbmotionfitnessb: 'Dies ist eine Fahr-App. Damit wir Ihr Fahren messen können, müssen wir auf <b> Bluetooth, Ortungsdienste </b> und <b> Bewegung und Fitness zugreifen. </b>',
    txt_global_std_HansenWhyjoin: 'Ich werde eng mit meinen Teamkollegen zusammenarbeiten und versuchen, die beiden Faktoren des intelligenten Fahrens zu kombinieren. Gemeinsam wollen wir Spaß am Fahren haben, uns selbst verbessern, intelligent und sicher fahren! Ich glaube, dass wir damit jedes Rennen gewinnen können, auch das größte von allen: das Rennen um unseren Planeten zu retten!',
    txt_global_std_JyrkiainenMileage: 'Ich habe erst seit fast einem Jahr einen Führerschein, aber ich liebe die Strecke zum finnischen Lappland, die Landschaft wird immer schneereicher.',
    txt_global_std_earn1500pointsifyoubook5timesamonth: 'Verdienen Sie 1500 Punkte, wenn Sie 5 Mal im Monat buchen\n\n',
    txt_global_std__ongoing: '(laufend)',
    txt_global_std_p2pdeletingofmessages: 'Löschen von {nocurrent} von {todelete} Nachrichten',
    txt_global_std_additionalfiles: 'Zusätzliche Dateien',
    txt_global_std_p2pmystatscomingsoon: 'Meine Statistiken - Bald erhältlich',
    txt_global_std_afriend: 'Ein Freund',
    txt_global_std_PolicySchedule29: 'Bei einem der AVG-Wertung unterliegenden Unfall wird ein Überschuss von bis zu 2.000 USD fällig.',
    txt_global_std_areyouabsolutelysuperdupersurethatyouwanttoexchangeyourpreciouspointsnowandnotsavethemforlater: 'Sind Sie sich absolut sicher, dass Sie Ihre wertvollen Punkte jetzt eintauschen und nicht für später aufsparen möchten?',
    txt_global_std_age: 'Alter',
    txt_global_std_supportingcluestowhy: 'Unterstützende Hinweise zum „Warum“',
    txt_global_std_maximizetext: 'Bonusfahrten',
    txt_global_std_MikkelsenWhyjoin: 'Ich werde meine Erfahrung aus Rallye & Rennsport nutzen, um Menschen dabei zu unterstützen, bessere Fahrer zu werden. Ein guter Fahrer zu sein bedeutet nicht nur schnell zu sein, sondern ebenso effizient und intelligent zu sein.',
    txt_global_std_bronze: 'Bronze',
    txt_global_std_super: 'Super',
    txt_global_std_areyousureyouwanttosignout: 'Möchten Sie sich wirklich abmelden?',
    txt_global_std_sidekicknotifications: 'Um die App optimal zu nutzen, erlauben Sie Sidekick, Ihnen Benachrichtigungen zu senden.',
    txt_global_std_notavailable: 'Nicht verfügbar',
    txt_global_std_hangin: 'Bleiben Sie dran',
    txt_global_std_wildfireunlockedkey: 'Du bist sehr gut gefahren! Ihr durchschnittliches Niveau war eine Woche lang besser als 4,4! Ihrem Kinto Wallet wurden 5000 Punkte hinzugefügt.',
    txt_global_std_removeuserfrom: 'Benutzer entfernen aus',
    txt_global_std_borrowmycar: 'Leih dir mein Auto aus',
    txt_global_std_HEAT: '',
    txt_global_std_PolicySchedule10: 'Sitzplätze',
    txt_global_std_contactus: 'Kontaktiere uns',
    txt_global_std_p2pfriendinvitedyouto: 'Ihr Freund hat Sie zu Kinto Car Sharing eingeladen',
    txt_global_std_jan: 'Jan',
    txt_global_std_sentbyenerfyglobalaproductbygreaterthanab: 'Gesendet von Enerfy Global, einem Produkt von Greater Than AB.',
    txt_global_std_groupchat: 'Gruppenchat',
    txt_global_std_newpasswordagain: 'Neues Passwort nochmal',
    txt_global_std_allownotificationstogetdrivingtipsafter: 'Lassen Sie zu, dass Benachrichtigungen nach jeder Fahrt Tipps zum Fahren geben und bringen Sie Ihre Fahrkünste auf die nächste Stufe.',
    txt_global_std_cimage36d: '',
    txt_global_std_enterthetemporarypasswordsenttoyoubyemail: 'Geben Sie das Ihnen per E-Mail zugesandte temporäre Passwort ein.',
    txt_global_std_loyaltydrivers: 'Treiber',
    txt_global_std_fiasdc2019w1price2: '3 Saisons Paket ',
    txt_global_std_trips: 'Reisen',
    txt_global_std_totalscore1: 'Gesamtpunktzahl',
    txt_global_std_attention: 'Beachtung',
    txt_global_std_p2preservation: 'Reservierung',
    txt_global_std_nothingtoshow: 'Nichts anzuzeigen',
    txt_global_std_p2ppendingph: '{count}Ausstehend',
    txt_global_std_vehicleinfo: 'Verifizieren Sie die Info',
    txt_global_std_wehavereceivedyourreport: 'Wir haben Ihren Bericht erhalten und Ihr Antrag wird in Kürze bearbeitet',
    txt_global_std_warikan_onboarding6_2: '450-0002',
    txt_global_std_50kmlockedkey: 'Fahren Sie eine Distanz von 50 km, um dieses Abzeichen zu erhalten.',
    txt_global_std_ayouwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithappnamewithalapseincoverage: 'A: Sie haben ab dem Ablaufdatum {days} Tage Zeit, um eine Zahlung zu leisten und Ihre Police bei {appName} mit einer Deckungslücke fortzusetzen.',
    txt_global_std_contact: 'Kontakt Information',
    txt_global_std_this15discountcodecanberedeemedagainstanygarminpndpurchase: 'Dieser 15 %-Rabattcode kann für jeden Kauf eines Garmin PND eingelöst werden.',
    txt_global_std_p2pscore: 'Ergebnis:',
    txt_global_std_nodataavailable1: 'keine Daten verfügbar',
    txt_global_std_uploadextrareportinfo: 'Laden Sie zusätzliche Berichtsinformationen hoch',
    txt_global_std_signout: 'Anmelden ',
    txt_global_std_herearetheresultsofthechallengename: 'Hier sind die Ergebnisse des Challenge-Namens.',
    txt_global_std_maxxcharactersrule: 'Maximal {x} Zeichen',
    txt_global_std_enteryourinformation: 'Geben Sie Ihre Information ein',
    txt_global_std_p2pdrivendistance: 'Angetriebene Distanz',
    txt_global_std_earn10000pointsifyouupgradetovalueplan: 'Verdienen Sie 10000 Punkte, wenn Sie auf den Wertplan upgraden',
    txt_global_std_nationality: 'Nationalität ',
    txt_global_std_tiphighway4DESC: 'Der rote Autofahrer verwendet häufiger Bremsen und Benzin',
    txt_global_std_delete: 'Löschen',
    txt_global_std_startguidetip4: 'Lassen Sie die App laden, bis der Score-Kreis angezeigt wird.',
    txt_global_std_howmuch: 'Wie viel',
    txt_global_std_arequesthasbeenreceivedtochangethepasswordforyour: 'Es wurde eine Anfrage zur Änderung des Passworts für Sie empfangen',
    txt_global_std_thislinkexpiresin: 'Dieser Link läuft ab in',
    txt_global_std_p2preceipt: 'Kassenbon',
    txt_global_std_nochallengestojoinyet: 'Noch keine Herausforderungen zum Mitmachen',
    txt_global_std_b1gotosettingsforappnameinyourphonebbrb2allowaccesstobluetoothb: '1. Gehen Sie zu den Einstellungen für {Anwendungsname} in Ihrem Telefon. </b> <br> <b> 2. Ermöglichen Sie den Zugriff auf Bluetooth </b>',
    txt_global_std_view: 'Ansicht ',
    txt_global_std_daterange: 'Datumsbereich',
    txt_global_std_youdrovesupremelywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Du bist super gut gefahren! Ihr Durchschnitt war eine Woche lang besser als {score}! Sie haben {points} Punkte verdient!',
    txt_global_std_p2psuccessfullyeditedadminrights: 'Administratorrechte erfolgreich bearbeitet',
    txt_global_std_soclosetoearningachievement: 'Sie waren so nah dran, einen Erfolg zu erzielen und Treuepunkte zu sammeln. Konzentrieren wir uns!',
    txt_global_std_MSIG_Onboarding4: 'weiter. Finden Sie Ihre Versicherungsbescheinigung und andere Informationen in dieser E-Mail.',
    txt_global_std_yourinvitewassenttoemail: 'Ihre Einladung wurde an {E-Mail} gesendet.',
    txt_global_std_p2pincreasetextsize: 'Erhöhen Sie die Textgröße',
    txt_global_std_p2pfindmycarcomingsoon: 'Finde mein Auto - kommt bald',
    txt_global_std_monthlysavings: 'Monatliche Einsparungen',
    txt_global_std_stats: 'Statistiken',
    txt_global_std_50offdeductible: '50% vom Selbstbehalt',
    txt_global_std_ongoing__: '(laufend)',
    txt_global_std_startday: 'Tag beginnen',
    txt_global_std_addcomparison: 'Vergleich hinzufügen',
    txt_global_std_awesomedriveroftheday: 'Super Fahrer des Tages',
    txt_global_std_selectformat2: 'Format auswählen',
    txt_global_std_aug: 'Aug ',
    txt_global_std_choosebetweenenteringchassisnumberplatenumberorpolicynumber: 'Wählen Sie zwischen der Eingabe von Fahrgestellnummer, Kennzeichen oder Richtliniennummer.',
    txt_global_std_PolicySchedule5: 'forsikrede',
    txt_global_std_cimage8h: 'Beschleunigen Sie leicht',
    txt_global_std_bevisibleorhiddenintheleaderboard: 'In der Bestenliste sichtbar / versteckt sein',
    txt_global_std_findyourfriends: 'Finden Sie Ihre Freunde ',
    txt_global_std_SulayemQuote: 'Zitat kommt bald',
    txt_global_std_p2psplittingthecostbydistancetravelledrequiresbluetoothconnection: 'Die Aufteilung der Kosten nach zurückgelegter Entfernung erfordert eine Bluetooth-Verbindung.',
    txt_global_std_errorcodes: 'Geben Sie die Codes ein ',
    txt_global_std_MSIG_InsuranceLetter40: 'Für Fahrer, die jünger als 22 Jahre sind und / oder weniger als 2 Jahre Fahrerfahrung haben, werden SGD 3.500,00 für ABSCHNITT I und II gesondert berechnet.',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithcovercubewithalapseincoverage: 'Ab dem Ablaufdatum haben Sie {Tage} Tage Zeit, um eine Zahlung zu leisten und Ihren Versicherungsschutz bei Covercube bei Erlöschen des Versicherungsschutzes fortzusetzen.',
    txt_global_std_inserttheobdreaderandstartyourcarwaitforthereadertoshowabluelight: 'Setzen Sie das OBD-Lesegerät ein und starten Sie Ihr Auto. Warten Sie, bis das Lesegerät ein blaues Licht anzeigt.',
    txt_global_std_yourcarisntturnedonnodot: 'Dein Auto ist nicht eingeschaltet',
    txt_global_std_qdoescovercubeacceptdriversthatarenotlicensedintheunitedstates: 'F: Akzeptiert Covercube Fahrer, die nicht in den USA lizenziert sind?',
    txt_global_std_1year: '1 Jahr',
    txt_global_std_warikan_onboarding10: '* Diese E-Mail-Adresse dient nur zum Senden. Bitte beachten Sie, dass wir auf diese E-Mail nicht direkt antworten können.\n',
    txt_global_std_welcometoteam: 'Willkommen bei {team}',
    txt_global_std_p2pfaq30a: 'Jeden Monat erhalten Sie eine Rechnung vom Eigentümer und die Meldung "Sie haben die Details der Rechnung erhalten" wird unter "Was ist zu tun" auf dem Startbildschirm angezeigt. Alternativ können Sie es von "Warikan" im "Menü" überprüfen.\n' +
      '1. Nachdem Sie "Warikan" ausgewählt haben, überprüfen Sie die Details auf der Registerkarte "Rechnung".\n' +
      '2. Nachdem Sie die Rechnungsdetails bestätigt haben, wählen Sie "Weiter", um den Zahlungsbetrag zu bestätigen.\n' +
      '3. Wählen Sie eine Zahlungsmethode aus und wählen Sie "Jetzt bezahlen".',
    txt_global_std_PaddonFacts: 'Von der Kindheit an, lebt und atmet Hayden den Motorsport und hat im Alter von 6 Jahren mit dem Fahren von Gokarts begonnen.  ',
    txt_global_std_yourobdreaderisonitsway: 'Ihr OBD-Leser ist unterwegs und wird in 1-2 Werktagen eintreffen. Folgen Sie dem nächsten Schritt, um es einfach zu installieren.',
    txt_global_std_timezone: 'Zeitzone',
    txt_global_std_youravgscoreis123: 'Ihre durchschnittliche Punktzahl ist',
    txt_global_std_welcometocovercube_: 'Willkommen bei Covercube!',
    txt_global_std_thisisatextmessage: 'Dies ist eine Textnachricht.',
    txt_global_std_challengefriend2: 'Freund herausfordern!',
    txt_global_std_Fia_Receipt_Text9: 'Qualität ',
    txt_global_std_theworldchampionshipwheresmartnessbeatsspeed: 'Die weltweite Herausforderung, wo Intelligenz Geschwindigkeit schlägt. Treten Sie beim täglichen Fahren an und lassen Sie sich von den Profis trainieren, um der intelligenteste Fahrer der Welt zu werden.',
    txt_global_std_events_overview_monthly: 'Monatlich',
    txt_global_std_wildfirenamekey: 'Lauffeuer!',
    txt_global_std_p2pchangedthenameofthegroup: 'hat den Namen der Gruppe geändert',
    txt_global_std_socialbutterflynamekey: 'Sozialer Schmetterling!',
    txt_global_std_invalidvouchercode: 'Ungültiger Gutscheincode',
    txt_global_std_2nd: '2er',
    txt_global_std_p2perrorcodecarmustbebonded: 'Auto muss gebunden sein.',
    txt_global_std_expensessummary: 'Ausgabenübersicht',
    txt_global_std_everyday: 'Jeden Tag ',
    txt_global_std_whatwillitcosttodrivewithoutbeing: 'What will it cost to drive without being connected to my car?',
    txt_global_std_p2peasyoverviewinyourcalendar: 'Einfacher Überblick in Ihrem Kalender',
    txt_global_std_featured: '',
    txt_global_std_youdrovesupremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Du bist super gut gefahren! Ihr durchschnittlicher {scoretype} war eine Woche lang besser als {score}! Sie haben {points} Punkte verdient!',
    txt_global_std_contactdetailss: 'Kontaktdetails',
    txt_global_std_congratulationsonthistypeoftripyouareanecochampionthanksforbeinganecofriendlydriver: 'Herzliche Glückwünsche! Bei dieser Reiseart sind Sie Öko-Champion! Danke, dass Sie ein umweltfreundlicher Fahrer sind!',
    txt_global_std_networkerrorpleasetryagainlater: 'Netzwerkfehler, bitte versuchen Sie es später noch einmal',
    txt_global_std_orientering: 'Orientierung',
    txt_global_std_refer10friendstounlockthisbadge: 'Empfehlen Sie 10 Freunde, um dieses Abzeichen freizuschalten.',
    txt_global_std_downloadapispecification: 'API-Spezifikation herunterladen',
    txt_global_std_showchanges: 'Änderungen anzeigen?',
    txt_global_std_contributesustainabilitythroughwarikan: 'Tragen Sie mit Warikan KINTO zur Nachhaltigkeit bei',
    txt_global_std_makeitempty: 'Leeren Sie es!',
    txt_global_std_changeemail: 'Ändern Sie die E-Mail',
    txt_global_std_letsfindyourcar: 'Lass uns dein Auto finden',
    txt_global_std_MSIG_Onboarding13: 'MS First Capital | Unternehmenszentrale: 6, Raffles Quay, Nr. 21-00 | Singapur 048580 | Tel .: (65) 6222 2311',
    txt_global_std_profilesetup: 'Profileinstellungen',
    txt_global_std_co2saverunlockedkey: 'Gut gemacht! Sie haben bei dieser Fahrt über {co2value} % CO₂ eingespart, indem Sie umweltfreundlich gefahren sind.',
    txt_global_std_MSIG_InsuranceLetter25: 'Verwendung nur für soziale, häusliche und Vergnügungszwecke sowie für das Geschäft des Versicherten.',
    txt_global_std_goldusers: 'Gold-Benutzer',
    txt_global_std_iwillfixitlater: 'Ich werde es später beheben',
    txt_global_std_joinwithinvitationcode: 'Mit Einladungscode anmelden',
    txt_global_std_statisticsshowingyourpersonalprogress: 'Statistiken zeigen Ihren persönlichen Fortschritt.',
    txt_global_std_mandatory: 'Verpflichtend',
    txt_global_std_p2pfaq20q: 'Können die Fahrdaten überprüft werden?',
    txt_global_std_allowlocationservicetogetaccuratescore: 'Ermöglichen Sie dem Location Service, personalisierte Tipps und genaue Ergebnisse zu erhalten.',
    txt_global_std_templates: 'Vorlagen',
    txt_global_std_enterpolicynumberhere: 'Geben Sie hier die Versicherungsnummer ein',
    txt_global_std_shootingstarunlockedkey: 'Du bist auf Level 3.3 oder besser gefahren! Groß! Ihrem Kinto Wallet wurden 100 Punkte hinzugefügt.',
    txt_global_std_addadditionalfiles: 'Fügen Sie zusätzliche Dateien hinzu',
    txt_global_std_hasbeeninvitedasaroletoyourteam: 'Wurde als {role} in Ihr Team eingeladen',
    txt_global_std_mpltipiip: 'Dies ist ein geübter Fahrer, weist aber auf eine unbeständige Fahrweise hin. Aber in diesem Fall passiert es hier und da einen Tag lang, aber auch einen Tag mit Cool Driving für 95%, dann wird Cool Driving für kurze Zeit ignoriert.\n' +
      'Dies ist eine Kombination aus Ignorieren von Cool Driving während eines Tages oder nur für eine Fahrt während eines Tages.\n' +
      'Zunächst empfehlen wir, dass der Trainer zusammen mit dem Fahrer versucht, herauszufinden, was das Problem sein könnte.',
    txt_global_std_p2phasreceivedyourbooking: 'hat Ihre Buchungsanfrage erhalten.',
    txt_global_std_forthisapptoworkproperlyweneedtoaccessnobr: 'Um ein Laufwerk registrieren zu können, müssen Sie\n' +
      'Bluetooth, Ortungsdienste und . zulassen\n' +
      'Bewegung & Fitness.',
    txt_global_std__resendtemporarypassword: 'Temporäres Passwort erneut senden.',
    txt_global_std_translationtool_upsell_pitch_bulletpoint2: 'Passen Sie die Kommunikation an den Tonfall Ihrer Marke an',
    txt_global_std_yourdatahasbeensent: 'Ihre Daten wurden gesendet. Bitte überprüfen Sie Ihren E-Mail Posteingang!',
    txt_global_std_prev: 'Vorher',
    txt_global_std_today: 'Heute',
    txt_global_std_MSIG_InsuranceLetter21: 'Der Versicherte kann auch ein Kraftfahrzeug führen, das ihm oder seinem Arbeitgeber oder seinem Partner nicht gehört oder von ihm (im Rahmen eines Mietkaufvertrags oder auf andere Weise) gemietet wurde.',
    txt_global_std_OgierDescription: "Er ist der derzeitige Inhaber der World Rally Drivers 'Championship, nachdem er den Titel sechs Mal in den Jahren 2013, 2014, 2015, 2016, 2017 und 2018 gewonnen hat Zweit erfolgreichster WRC-Pilot nach dem ehemaligen Citroën WRC-Teamkollegen Sébastien Loeb (neun Titel).",
    txt_global_std_terrainhandling: 'Geländebehandlung',
    txt_global_std_readytogoappsolutionsthatwehavehandpickedforyou: 'Ready-to-go App-Lösungen, die wir für Sie handverlesen haben.',
    txt_global_std_callwithdriver: 'Rufen Sie mit dem Fahrer an',
    txt_global_std_whatwillhappenififorgettoconnect: 'What will happen if I forget to connect to my car?',
    txt_global_std_filteroptionnotinuse: 'Nicht in Gebrauch',
    txt_global_std_youhavenowmaintainedafullweeksdrivingwithanaveragescoreabove4: 'Sie haben jetzt eine ganze Woche mit einer durchschnittlichen Punktzahl über 4,0 gefahren',
    txt_global_std_icannotconnectanswertext1: 'Stellen Sie sicher, dass Sie alle Schritte unter „Mit Auto verbinden“ ausführen. Bitte mailen',
    txt_global_std_anewpasswordhasbeengeneratedforuser: 'Für {user} wurde ein neues Passwort generiert',
    txt_global_std_ConfirmFia_Text10: 'Sind Sie bereit für Ihre SDC-Testfahrt?',
    txt_global_std_fiasdc2019w1price1: '5 Saisons Packet ',
    txt_global_std_p2pselectthedatesandtime: 'Wählen Sie die Daten und die Uhrzeit aus, zu denen Ihr Auto nicht verfügbar sein soll',
    txt_global_std_p2pfaq14q: 'Was ist, wenn ich das Auto zu spät zurückgebe?',
    txt_global_std_p2pfaq30q: 'Bitte sagen Sie mir, wie ich die Kosten bezahlen soll.',
    txt_global_std_groupnames: 'Gruppennamen',
    txt_global_std_youcanrepublishyourtranslationslaterifyouwant: 'Sie können Ihre Übersetzungen später erneut veröffentlichen, wenn Sie möchten.',
    txt_global_std_p2pfaq22q: 'Ich möchte zusätzliche Kosten erfassen.',
    txt_global_std_speedchangeanalysis2: 'Geschwindigkeitsänderungsanalyse',
    txt_global_std_p2pfaqcontactinfo1: '・ Empfangszeiten 9:00 bis 18:00 Uhr (ausgenommen die Jahresend- und Neujahrsfeiertage)',
    txt_global_std_areyousureyouwanttodeletememberfromworkspacethisactioncannotbeundone: 'Möchten Sie das Mitglied wirklich aus dem Arbeitsbereich löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
    txt_global_std_tripdetailshavebeensenttoemail1: 'Reisedetails wurden per E-Mail gesendet.',
    txt_global_std_resendactivationcodeto: 'Aktivierungscode erneut senden an',
    txt_global_std_mars: 'März ',
    txt_global_std_activationcodeoptional: 'Aktivierungscode (optional)',
    txt_global_std_lockeddescriptionlabel: 'Gesperrte Beschreibung, in App angezeigt',
    txt_global_std_minute: 'Minute',
    txt_global_std_enerfycoachtip1: '1. Seien Sie zu 100% auf Ihr Fahren konzentriert.',
    txt_global_std_p2pcostsplitsettings: 'Kostenaufteilungseinstellungen',
    txt_global_std_p2pnamesentaneditrequesttoname2: '{name} hat eine Bearbeitungsanforderung an {name2} gesendet',
    txt_global_std_changebackground: 'Hintergrundbild ändern',
    txt_global_std_ridewithmehowitworks: 'Wie es funktioniert',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithappnamewithalapseincoverage: 'Ab dem Ablaufdatum haben Sie {days} Tage Zeit, um eine Zahlung zu leisten und Ihren Versicherungsschutz bei {appName} mit Erlöschen des Versicherungsschutzes fortzusetzen.',
    txt_global_std_youhavereachthemaximumlevel: 'Du hast das maximale Level erreicht!',
    txt_global_std_p2pfriendadminrights: 'Administratorrechte für Freunde',
    txt_global_std_tipcity1DESC: 'Der blaue Autofahrer hält einen angemessenen Abstand zu umliegenden Autos. Der rote Autofahrer schiebt unnötig oft Gang und bremst.',
    txt_global_std_ConfirmFia_Text2: '',
    txt_global_std_wearebuildingyourworkspace: 'Wir bauen Ihren Arbeitsplatz',
    txt_global_std_events: 'Ereignisse ',
    txt_global_std_teamage: 'Alter:',
    txt_global_std_NewPW_Text2: 'Ihr neues Passwort für den Benutzer',
    txt_global_std_safetyalarms: 'Sicherheitsalarme',
    txt_global_std_superadmin: 'höchster Vorgesetzter',
    txt_global_std_p2pfaq21a: 'Sie können alle registrierten Mitglieder überprüfen, indem Sie "Mitglieder" aus dem "Menü" auswählen.',
    txt_global_std_workshops: 'Werkstätten',
    txt_global_std_failedtogettranslationoverview: 'TranslationOverview konnte nicht abgerufen werden',
    txt_global_std_obdreadersnearby: 'OBD-Reader in der Nähe',
    txt_global_std_p2pfaq1a: 'Das sind wirklich coole Funktionen, die bald in der App verfügbar sein werden. Halten Sie also Ausschau nach Updates!',
    txt_global_std_numberofexecutions: 'Anzahl der Hinrichtungen',
    txt_global_std_thisisyourusername: 'Dies ist dein Benutzername',
    txt_global_std_daysleft: 'Tage übrig',
    txt_global_std_p2paddedexpenses: 'Kosten',
    txt_global_std_drivingdata: 'Fahrdaten',
    'txt_global_std_Al-KuwariWhyjoin': 'Weil ich eine Famile innerhalb des Team erschaffe.',
    txt_global_std_p2pitsalittleemptyinhere: 'Hier drinnen ist es ein bisschen leer',
    txt_global_std_oldpassword: 'Altes Passwort ',
    txt_global_std_permission: 'Genehmigung',
    txt_global_std_iconback: '{Symbol} Zurück',
    txt_global_std_KleinschmidtMileage: '',
    txt_global_std_followupontheonboardingstatusofyourusers: 'Nachverfolgung des Onboarding-Status Ihrer Benutzer',
    txt_global_std_jointhechallenge: 'Nehmen Sie an der Herausforderung teil',
    txt_global_std_bronzelevel: 'Bronze Level',
    txt_global_std_p2pview: 'Aussicht',
    txt_global_std_KristofferssonWhyjoin: 'Wenn Sie einen Wettbewerb als Herausforderung sehen und Ihr Ziel ist es, 100% zu geben, dann sollten Sie sich meinem Team anschließen.',
    txt_global_std_youcollectedpointsduringthepreviosperiod: 'Sie haben in der vorherigen Periode {points} Punkte gesammelt.',
    txt_global_std_noogpsdatafound: 'Keine GPS Daten',
    txt_global_std_safetydrivingskillupwithwarikankintobody: 'Fahrsicherheit verbessern mit Warikan KINTO',
    txt_global_std_ifyouwanttolendyourcartoafriendyoushouldinviteyourfriendtotheappnameappbytappingthebuttonbelow: 'Wenn Sie Ihr Auto einem Freund leihen möchten, sollten Sie Ihren Freund zur {Appname} App einladen, indem Sie auf die Schaltfläche unten tippen.',
    txt_global_std_activity: 'Aktivität',
    txt_global_std_end: 'Ende / Ziel',
    txt_global_std_p2pcancelthisbooking: 'Diese Buchung stornieren?',
    txt_global_std_heat123: '',
    txt_global_std_adgangtillokalitet: 'Adgang til lokalitet',
    txt_global_std_secureyourlocation: 'Sichern Sie sich Ihren Standort',
    txt_global_std_rwmnofriendstext: 'Laden Sie einen Freund ein, um eine Mitfahrgelegenheit registrieren zu können.',
    txt_global_std_yourpolicyisreneweddate: 'Ihre Richtlinie wird am {date} verlängert',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimeinconstantspeedpayingattentiontoyourenvironmenthelpsyoutosmoothlyandsteadilyvaryyourspeedaccordinglythroughoutyourtrip: 'Auf dieser Art von Reise verbrachte der durchschnittliche Öko-Champion weniger Zeit mit konstanter Geschwindigkeit. Wenn Sie auf Ihre Umgebung achten, können Sie Ihre Geschwindigkeit während der gesamten Fahrt reibungslos und stetig anpassen.',
    txt_global_std_compilinginformationdrive: 'Informationen zu Ihrer Fahrt zusammenstellen ..',
    txt_global_std_region: 'Region',
    txt_global_std_videotutorials: 'Videoanleitungen',
    txt_global_std_PaddonBesttip: 'Mein beater Rat für Sie ist, seien Sie geduldig. Es ist sehr leicht, auf der Straße mit anderen Verkehrsteilnehmern angespannt oder ungeduldig zu werden. Denken Sie jedoch daran, dass ein kleiner Geschwindigkeitsunterschied am Ende nur wenige Minuten ausmacht. Also entspannen Sie sich, drehen Sie die Musik auf und genießen Sie Ihre Fahrt.',
    txt_global_std_Fia_Receipt_Text16: 'Mit diesem Kauf haben Sie unseren allgemeinen Einkaufsbedingungen zugestimmt.',
    txt_global_std_findanagent: 'Finden Sie einen Agenten',
    txt_global_std_insurancenumbernumber: 'Versicherungsnummer: {number}',
    txt_global_std_p2pwriteamessagetoafriendorgroupregardingabookingoranythingselsethatsonyourmind: 'Schreiben Sie einem Freund oder einer Gruppe eine Nachricht bezüglich einer Buchung oder etwas anderem, was Sie denken.',
    txt_global_std_features: '',
    txt_global_std_co2savings: 'CO₂-Einsparungen',
    txt_global_std_p2pselectmember: 'Mitglied auswählen',
    txt_global_std_getsarouteforagiventrip: 'Ruft eine Route für eine bestimmte Reise ab',
    txt_global_std_notheappworksinbackgroundmodemakesureyoualwayskeepbluetoothandlocationservicesactivatedonyourphone: 'Nein, die App funktioniert im Hintergrundmodus. Stellen Sie sicher, dass Bluetooth und Standortdienste auf Ihrem Telefon immer aktiviert sind.',
    txt_global_std_p2ppaid: 'Bezahlt',
    txt_global_std_p2pemailsent: 'E-Mail gesendet!',
    txt_global_std_acceleration: 'Beschleunigung',
    txt_global_std_nocloudconnection: 'Keine Verbindung zur Cloud',
    txt_global_std_pointsoverview: 'Punkteübersicht',
    txt_global_std_emailnotfound: 'Email wurde nicht gefunden',
    txt_global_std_textwelcomewiget: 'Das wird so lustig! Fangen wir an, klug zu fahren. Ich werde da sein, um Sie durch die hohe Punktzahl zu führen!',
    txt_global_std_PSolbergBesttip: 'demnächst',
    txt_global_std_Insurance_WelcomeLetter: 'Willkommensbrief',
    txt_global_std_countdays: '{zählen}  Tage',
    txt_global_std_privateorbusiness: 'Privat oder geschäftlich?',
    txt_global_std_addphoto: 'Foto hinzufügen',
    txt_global_std_private2: 'Privatgelände',
    txt_global_std_loadingyourdrives: 'Laden Sie Ihre Laufwerke ...',
    txt_global_std_whathappensififorgettorenewbeforemyinsurancehasexpired: 'Was passiert, wenn ich die Verlängerung vor Ablauf meiner Versicherung vergesse?',
    txt_global_std_invitesent: 'Einladung gesendet',
    txt_global_std_recommended: 'Empfohlen',
    txt_global_std_letsdrivewithwarikankinto: 'Lass uns mit Warikan KINTO fahren!',
    txt_global_std_mobilenumber: 'Handynummer',
    txt_global_std_p2pgoheretocreateabookingofyourfriendscar: 'Gehen Sie hier, um eine Buchung für das Auto Ihres Freundes zu erstellen.',
    txt_global_std_wantmoreindepthanalysis: 'Möchten Sie tiefergehende Analysen und detaillierte Einblicke mit sofortiger Identifizierung von Fahrern mit hohem und niedrigem Risiko?',
    txt_global_std_monthm: 'M',
    txt_global_std_alinktodownloadyourdemoappwillbesenttoemailwhenready: 'Ein Link zum Herunterladen Ihrer Demo-App wird an {email} gesendet, sobald sie fertig ist.',
    txt_global_std_countminutes: '{zählen} Minuten',
    txt_global_std_OSolbergMusic: 'Kommt bald ',
    txt_global_std_remainingpoints: 'Verbleibende Punkte',
    txt_global_std_cimage13h: 'Lassen Sie die Geschwindigkeit fallen',
    txt_global_std_norweigan: 'Norwegen',
    txt_global_std_ifyouwantinfoonnotificationscallphonenumberorgotoweburlorgotothefaqpagelink: 'Wenn Sie Informationen zu Benachrichtigungen wünschen, rufen Sie {phoneNumber} an oder rufen Sie {webUrl} auf. Oder gehen Sie zu {faqPageLink}.',
    txt_global_std_startyourcarsengine: 'Starten Sie den Motor Ihres Autos',
    txt_global_std_p2pviewless: 'Weniger anzeigen',
    txt_global_std_cimage18h: 'geplante Durchschnittsgeschwindigkeit',
    txt_global_std_startdrivingtoappearontheleaderboard: 'Fahren Sie los, um eine Punktzahl in der Rangliste zu erhalten!',
    txt_global_std_p2pselectvehicle: 'Fahrzeug auswählen',
    txt_global_std_aonceyoupairtheapptoyourcehiclesbluethoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhatscoreyougetwhendriving: 'Sobald Sie die App mit dem Bluetooth Ihres Fahrzeugs gekoppelt haben und der App die Nutzung von Ortungsdiensten gestatten, gleichen wir Ihre Fahrgewohnheiten mit der Bibliothek unserer KI mit Fahrermustern ab und stellen eine Punktzahl bereit. Ihre Versicherungsprämie richtet sich nach der Punktzahl, die Sie beim Fahren erzielen.',
    txt_global_std_connecttocarunlockedkey: 'Schön gemacht! Sie haben sich mit dem Auto verbunden und sind fahrbereit. Sicher sein!',
    txt_global_std_listview: 'Listenansicht',
    txt_global_std_ifyourcarhasbluetoothbutdoesnotshowupintheappyoumightneedtocheckthebluetoothsettingsforbothyourphoneandyourcartryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagain: 'Wenn Ihr Auto über Bluetooth verfügt, aber nicht in der App angezeigt wird, müssen Sie möglicherweise die Bluetooth-Einstellungen für Ihr Telefon und Ihr Auto überprüfen. Versuchen Sie, die Verbindung Ihres Autos zu Ihrem Telefon zurückzusetzen, indem Sie sie entfernen und dann erneut hinzufügen.',
    txt_global_std_PolicySchedule2: 'Agentur.',
    txt_global_std_invitenewmember: 'Neues Mitglied einladen',
    txt_global_std_topimprover: 'Top-Verbesserer',
    txt_global_std_advancedsettings: 'Erweiterte Einstellungen',
    txt_global_std_inviteafriend: 'Einen Freund einladen',
    txt_global_std_excellentdriving: 'Ausgezeichnetes Fahren!',
    txt_global_std_conversionkmmi: 'Umrechnung km/mi',
    txt_global_std_p2pborrowcar: 'Auto ausleihen',
    txt_global_std_competitionrules: 'Wettbewerbsregeln',
    txt_global_std_smspackagefiasdcactivation: 'Willkommen bei der FIA-SDC! Bitte klicken Sie auf den Link unten, [LINK] \n' +
      'Aktivierungscode: {activationcode}\n' +
      '\n',
    txt_global_std_challangeranking: 'Ihr Challenge-Ranking',
    txt_global_std_gdprterms: 'DSGVO-Bedingungen',
    txt_global_CoreTemplate_more: 'Mehr',
    txt_global_std_riskmatcha: 'Risikoübereinstimmung {type}',
    txt_global_std_newpwdcheckinbox: 'Bitte überprüfen Sie Ihren Posteingang',
    txt_global_std_towed: 'Abgeschleppt',
    txt_global_std_averagecosavings: 'Durchschnittliche CO₂-Einsparung',
    txt_global_std_startguide: 'Anleitung starten',
    txt_global_std_bysigningupyouaccepttheusertermsprivacypolicy: 'Mit der Anmeldung akzeptieren Sie die {userTerms} & {privacyPolicy}',
    txt_global_std_cimage34d: '',
    txt_global_std_p2pdonthaveatemporarypasswordregisterat: 'Sie haben kein temporäres Passwort?\n' +
      'Registrieren Sie sich auf [Kinto-Website]',
    txt_global_std_riskydecisionbetweendays: 'Riskante Entscheidung zwischen den Tagen',
    txt_global_std_exceededlimit: 'Limit überschritten',
    txt_global_std_accountstartdate: 'Startdatum des Kontos',
    txt_global_std_exchangedpoints: 'Punkte getauscht',
    txt_global_std_challengetermsconditions: 'Teilnahmebedingungen für Herausforderungen',
    txt_global_std_animalcollision123: 'Tierkollision',
    txt_global_std_litre: 'Liter',
    txt_global_std_p2pyoureceivedaninvoicetosplitcost: 'Sie haben eine Rechnung zur Aufteilung der Kosten erhalten.',
    txt_global_std_PolicySchedule13: 'MARKTWERT ZUM ZEITPUNKT DES VERLUSTES',
    txt_global_std_appcreator_upsell_pitch_bulletpoint2: 'Pilotieren und erweitern Sie Ihr digitales Angebot ohne kostspielige Entwicklungszeit',
    txt_global_std_timeline2: 'Zeitleiste',
    txt_global_std_buylater: 'Später kaufen',
    txt_global_std_p2pmakeseparatebookings: 'Machen Sie separate Buchungen',
    txt_global_std_skillscomparison: 'Vergleich der Fähigkeiten',
    txt_global_std_intraining: 'In der Ausbildung',
    txt_global_std_p2pfaq5q: 'Wie vergrößere ich ein Bild in Nachrichten?',
    txt_global_std_alertdrivingtext: 'Sie fahren jetzt technisch.',
    txt_global_std_sonicethatyoudecidedtojoinus: 'So schön, dass Sie sich für uns entschieden haben!',
    txt_global_std_buyheatsnowtocompete: 'Kaufen ',
    txt_global_std_wecanretrievedataformultiplecompletedtripsinasinglerequesthoweverwerecommendnottosendrequestswithdataexceeding50mb: 'Wir können Daten für mehrere abgeschlossene Fahrten in einer einzigen Anfrage abrufen. Wir empfehlen jedoch, keine Anfragen mit Daten über 50 MB zu senden.',
    txt_global_std_doyouwanttojointeam: 'Möchten Sie sich dem Team anschließen?',
    txt_global_std_inviteafriendtostartachallenge: 'Lade einen Freund ein, um eine Herausforderung zu beginnen!',
    txt_global_std_driving: 'Fahren ',
    txt_global_std_allusers: 'Alle Nutzer',
    txt_global_std_searchbyfilter: 'Suche nach Filter',
    txt_global_std_youonlyhavedistancelefttotravelleft: 'Sie haben nur noch {distanceLeftToTravel} übrig.',
    txt_global_std_startadrivemanually: 'Start måling nu',
    txt_global_std_topdrivers: 'Top-Fahrer',
    txt_global_std_whenyourfriendusesyourreferralcodetoactivateanewsidekickcarinsurancebothofyouget250krinthepiggybank: 'Wenn Ihr Freund Ihren Empfehlungscode verwendet, um eine neue Sidekick-Autoversicherung zu aktivieren, erhalten Sie beide 250 kr im Sparschwein.',
    txt_global_std_warikan_onboarding2: 'Vielen Dank, dass Sie sich für die Nutzung der Warikan KINTO-App beworben haben.',
    txt_global_std_achievementtool: 'Erfolgstool',
    txt_global_std_addexpenses: 'Kosten hinzufügen',
    txt_global_std_communication: 'Kommunikation',
    txt_global_std_idonthavebluetoothinmycar: 'Ich habe kein Bluetooth in meinem Auto',
    txt_global_std_changeswillnotbesaved: 'Änderungen werden nicht gespeichert',
    txt_global_std_1findyourobdportinyourcar2inserttheobdreaderandstartyourcarwaitforthereadertoshowabluelight3turnonyourphonesbluetooth4oncetheapprecognisestheobdreaderyoullmoveautomaticallytothenextstep5selectyourobddevicefromthelistobdmini2: '1: Finden Sie Ihren OBD-Port in Ihrem Auto. 2: Setzen Sie den OBD-Leser ein und starten Sie Ihr Auto. Warten Sie, bis das Lesegerät ein blaues Licht anzeigt. 3: Schalten Sie das Bluetooth Ihres Telefons ein. 4: Sobald die App das OBD-Lesegerät erkennt, gelangen Sie automatisch zum nächsten Schritt. 5: Wählen Sie Ihr OBD-Gerät aus der Liste aus: OBD Mini 2',
    txt_global_std_p2pwearegladthatyouwanttousewarikanasawaytoshareyourcar: 'Wir freuen uns, dass Sie Warikan nutzen möchten, um Ihr Auto zu teilen.',
    txt_global_std_welcomeback: 'Willkommen zurück ',
    txt_global_std_alerthighusagetext: 'Warnung! Hoher Verbrauch [Höhe] kr.',
    txt_global_std_wrongusernameorpassword: 'Falscher Benutzername oder Passwort',
    txt_global_std_p2pborrowed: 'geliehen',
    txt_global_std_telephonecontact: 'Telefon',
    txt_global_std_p2pemailnotpaidbookings: 'Nicht bezahlte Buchungen',
    txt_global_std_enterodometervalue: 'Kilometerzählerwert eingeben',
    txt_global_std_stopdrivingq: 'Das Fahren beenden?',
    txt_global_std_pushnotdrivenforesometimetitle2: 'Wir vermissen dich, unser Freund!',
    txt_global_std_byinvitingfriendsyouwillearnloyaltypoints: 'Wenn Sie Freunde einladen, erhalten Sie Treuepunkte',
    txt_global_std_100offdeductible: '100% Rabatt auf Selbstbehalt',
    txt_global_std_areyousure: 'Bist du sicher?',
    txt_global_std_p2pfaq9a: 'Bitten Sie Ihren Freund, zur Buchungszusammenfassung zu gehen und auf Buchung beenden zu klicken. Die Buchung ist dann abgeschlossen und Ihr Auto kann wieder ausgeliehen werden.',
    txt_global_std_undertheusersectionextendittoupdateyouraccountdetailsorlogoutfromtheadminportal: 'Erweitern Sie ihn im Benutzerbereich, um Ihre Kontodetails zu aktualisieren oder sich vom Admin-Portal abzumelden.',
    txt_global_std_cimage17h: 'Aktives Fahren auf der Autobahn',
    txt_global_std_MSIG_InsuranceLetter33: 'Firmenreg. Nr. 195000106C',
    txt_global_std_p2pyouwillnotbeabletoseeorwriteanymessagesinthisgroupchat: 'In diesem Gruppenchat können Sie keine Nachrichten sehen oder schreiben.',
    txt_global_std_calculatethedates: 'Berechne die Termine',
    txt_global_std_whywouldyou: 'Warum würdest du?',
    txt_global_std_workspace: 'Arbeitsplatz',
    txt_global_std_yesterday: 'Gestern',
    txt_global_std_smartdriverweekly: 'Smart Driver wöchentlich',
    txt_global_std_effectivedates: 'Datum des Inkrafttretens',
    txt_global_std_whatdoineedtodoinordertoconnectmycar: 'What do I need to do in order to connect my car?',
    txt_global_std_yourfriendsnickname: 'Benutzername Ihrer Freunde',
    txt_global_std_p2pcarborrow: 'Wagen',
    txt_global_std_tuneup: 'Stimmen Sie ab',
    txt_global_std_findmycar2: 'Finde mein Auto',
    txt_global_std_gamificationinspirestosmarterdrivingforlowerco2imprintandfewerroadaccidents: 'Gamification inspiriert zu intelligenterem Fahren für einen geringeren CO2-Ausstoß und weniger Verkehrsunfälle',
    txt_global_std_turnonlocationservicesinyourphone: 'Aktivieren Sie die Ortungsdienste (GPS) in Ihrem Telefon.',
    txt_global_std_youhavebeenchallengedtoahead2headacceptitnow: 'Sie wurden zu einem Kopf-an-Kopf-Rennen herausgefordert. Akzeptiere es jetzt.',
    txt_global_std_managetheimpactyourteamhasonroadsafetyandco2emissions: 'Verwalten Sie die Auswirkungen Ihres Teams auf die Verkehrssicherheit und die CO2-Emissionen',
    txt_global_std_startguidetip1: 'Stellen Sie sicher, dass der OBD-Reader an die OBD-Verbindung des Autos angeschlossen ist.',
    txt_global_std_p2pconfirmedph: '{count}Bestätigt',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscores: 'A: Sobald Sie die App mit dem Bluetooth Ihres Fahrzeugs koppeln und der App erlauben, Ortungsdienste zu verwenden, gleichen wir Ihre Fahrgewohnheiten mit der Bibliothek von Fahrermustern unserer KI ab und stellen eine Punktzahl bereit. Ihre Versicherungsprämie richtet sich danach, wer Ihr Fahrzeug fährt und wie gut er fährt. Jeder, der Ihr Fahrzeug fährt, wird ermutigt, die App herunterzuladen und seine Ergebnisse zu verfolgen.',
    txt_global_std_statusheader: 'Status',
    txt_global_std_25offdeductible: '25% vom Selbstbehalt',
    txt_global_std_validatedata: 'Daten validieren',
    txt_global_std_newtoagent: 'Neu bei {agent}?',
    txt_global_std_whydoyouwanttoterminateyourinsurance: 'Warum möchten Sie Ihre Versicherung kündigen?',
    txt_global_std_user: 'Nutzer',
    txt_global_std_ourinsurancesolutionisbaseduponafixedandvariablecost: 'Unsere Versicherungslösung basiert auf festen und variablen Kosten. Die Fixkosten basieren auf dem versicherten Fahrzeug und der registrierten Adresse des Fahrzeugbesitzers und werden monatlich im Voraus in Rechnung gestellt, unabhängig davon, wie oft und auf welche Weise das Fahrzeug verwendet wird. Die variablen Kosten richten sich nach Versicherungsschutz, Kilometerstand und Fahrverhalten. Um die Kosten für Ihr Fahren zu berechnen und zu bewerten, muss das mitgelieferte OBD-Gerät (Enerfy-Plug-In) in Ihrem Fahrzeug installiert sein - oder - wenn Ihr Fahrzeug die Möglichkeit hat, Ihr Telefon direkt über Bluetooth zu verbinden - die Enerfy-App während der Fahrt in Ihrem Smartphone laufen. Ihre variablen Kosten werden dann basierend auf den Prämienstufen berechnet, dh basierend auf Ihrem individuellen Fahrverhalten, wobei die Prämienstufe 1 die Stufe mit den niedrigsten Kosten pro Kilometer und die Prämienstufe 15 ist das höchste. Die variablen Kosten werden für jedes Laufwerk kumuliert und im kommenden Monat in Rechnung gestellt. Wenn Sie nicht verbunden sind und Ihre Laufwerke nicht registriert sind, wird Ihnen die Premium-Stufe 10 in Rechnung gestellt.',
    txt_global_std_p2pyouneedtocreateabookingbeforeaddingaexpense: 'Sie müssen eine Buchung erstellen, bevor Sie eine Ausgabe hinzufügen können.',
    txt_global_std_trygonboarding1: 'Wir freuen uns sehr, dass Sie sich für uns entschieden haben! Lassen Sie uns Ihr Profil fertigstellen.',
    txt_global_std_p2pcostsplit: 'Kostenaufteilung',
    txt_global_std_KristofferssonBesttip: 'Fahren Sie vorausschauend. Dadurch haben Sie mehr Zeit, um Ihre Fahrt zu planen.',
    txt_global_std_acceptconnectiontogetascore: 'Akzeptiere die Verbindung, um eine Punktzahl zu erhalten',
    txt_global_std_p2ppasswordnotmatchedpleasetryagain: 'Passwort stimmt nicht überein. Bitte versuche es erneut.',
    txt_global_std_woman: 'Frau',
    txt_global_std_onesofthebestsdrivers: 'Einer der besten Fahrer!',
    txt_global_std_youhavedrivenatotalof: 'Sie sind insgesamt gefahren',
    txt_global_std_anyonewiththelinkcangetinvited: '(Jeder mit dem Link kann eingeladen werden)',
    txt_global_std_signinwith2: 'Anmelden mit',
    txt_global_std_pigsinvitedunlocked: 'Schön! Du hast jetzt 250 Kronen mehr in deinem Sparschwein, weil du einen Freund angeworben hast',
    txt_global_std_contactinformation: 'Kontakt Information',
    txt_global_std_mpltiplvl7: 'Wir empfehlen ein Fahrertraining mit besonderem Augenmerk auf Fahrerfokus und -planung. Verlieren Sie also nie den Fokus auf den Verkehr und die Bedingungen um Sie herum. Wir sind Yamato und konzentrieren und planen das Fahren immer mit besonderem Augenmerk auf Freundlichkeit, Priorisierung, emotionale Selbstkontrolle und Vertrauen',
    txt_global_std_failedtoedittranslation: 'Übersetzung konnte nicht bearbeitet werden!',
    txt_global_std_pigwonlocked: 'Tritt in head2head an und gewinne gegen einen Freund, um diese Auszeichnung zu erhalten',
    txt_global_EnerfyActivityDetailTemplate_comparetomy: 'Vergleich zu',
    txt_global_std_letsdothis: 'Lass uns das machen!',
    txt_global_std_yearlymilageused: 'Jahreskilometerverbrauch',
    txt_global_std_browsetemplates: 'Vorlagen durchsuchen',
    txt_global_std_verygood: 'Sehr gut',
    txt_global_std_kintofaq3q: 'Wie verdiene ich einen Loyalitätsstatus?',
    txt_global_std_contactinfo: 'Kontakt Info',
    txt_global_std_pleasenotethatwewilldetuct: 'Bitte beachten Sie, dass wir 1 SEK von Ihrer Karte abziehen, wenn Sie Ihre Kartendaten aktualisieren. Der Betrag wird vollständig zurückgezahlt. Falls Sie noch nicht bezahlte Rechnungen haben, werden die Rechnungen kurz nach dem Update bezahlt. Stellen Sie außerdem sicher, dass Ihre Karte für Einkäufe im Internet geöffnet ist.',
    txt_global_std_customizeyourapp: 'Passen Sie Ihre App an',
    txt_global_std_toreportyourclaim: 'Befolgen Sie einfach die folgenden Schritte, um Ihren Anspruch zu melden. Ihr Anspruch wird in Kürze bearbeitet.',
    txt_global_std_p2ppressthedrivebuttontoconnecttoyourcarandstartatripyouwillgetfeedbackandinsights: 'Drücken Sie die Fahrtaste, um sich mit Ihrem Auto zu verbinden und eine Fahrt zu beginnen. Sie erhalten Feedback und Einblicke in Ihre Fahrweise.',
    txt_global_std_planetsaver: 'Planetensparer',
    txt_global_std_speeddecrease: 'Geschwindigkeit verringern',
    txt_global_std_byinvitingfriendsyouwillearnachievements: 'Wenn Sie Freunde einladen, werden Sie Erfolge erzielen',
    txt_global_std_motorclaimshotline: 'Motor Claims Hotline',
    txt_global_std_friendstotheapp: 'Freunde der Enerfy Loyalty App.',
    txt_global_std_dontworryaslongastheenerfyappisinstalledandyouareloggedin: "Don't worry, as long as the Enerfy app is installed and you are logged in, the app will connect automatically to your device.",
    txt_global_std_connectnow: 'Verbinde jetzt',
    txt_global_std_emailwithremovallink: 'Erfolg! Es wurde eine E-Mail mit einem Entfernungslink gesendet. Bitte überprüfen Sie Ihren Posteingang!',
    txt_global_std_totaltranslatedwords: 'Insgesamt übersetzte Wörter',
    txt_global_std_cimage24h: 'Finde Sie die optimale Drehzahl',
    txt_global_std_baseusers: 'Basisbenutzer',
    txt_global_std_keyname: 'Schlüsselname',
    txt_global_std_collectpointsbyearningbadgeseverybadgeisworthadifferentamountofpointstapabadgetolearnmoreaboutit: 'Sammeln Sie Punkte, indem Sie Abzeichen verdienen. Jedes Abzeichen ist eine andere Punktzahl wert. Tippen Sie auf ein Abzeichen, um mehr darüber zu erfahren.',
    txt_global_std_selectcar: 'Auto auswählen',
    txt_global_std_pleasetryusinganotherusername: 'Bitte versuchen Sie es mit einem anderen Benutzernamen.',
    txt_global_std_whyterminateinsurance: 'Warum die {appName} kündigen?',
    txt_global_std_getdiscountcode: 'Rabattcode erhalten',
    txt_global_std_warikan_onboarding4_2: '◎お問い合わせはこちら\nKINTOカスタマーセンター',
    txt_global_std_confirmconnectionbetweenyourphoneandobdreader: 'Bestätigen Sie die Verbindung zwischen Ihrem Telefon und dem OBD-Reader.',
    txt_global_std_copytoclipboard: 'In die Zwischenablage kopieren',
    txt_global_std_p2pregisterkinto: 'Registrieren Sie Kinto',
    txt_global_std_accountdetails: 'Kontodetails',
    txt_global_std_whyshouldanyonejoinyourteam: 'Warum sollte jemand Ihrem Team beitreten?',
    txt_global_std_pleaseenteravalidemailaddress: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    txt_global_std_planningfeedbacktips: '<b> Wenn Sie weiter nach vorne schauen </b> (und nicht nur direkt vor Ihnen), führt dies normalerweise zu einem ruhigeren Fahren und einer höheren Wahrscheinlichkeit, Hindernisse rechtzeitig zu entdecken. <br> <br>\n' +
      '<b> Sorgen Sie für eine angenehme Fahrt </b> für Sie und Ihre Passagiere.',
    txt_global_std_p2pifyouareakintoownerallyouneedtodoisregisteryourcarhereandyoullbereadytogo: 'Wenn Sie ein Kinto-Besitzer sind, müssen Sie nur Ihr Auto hier registrieren und schon können Sie loslegen!',
    txt_global_std_p2punread: 'ungelesen',
    txt_global_std_competitions: 'Wettbewerbe',
    txt_global_std_accountidentification: 'Kontoidentifikation',
    txt_global_std_starttrip: 'Fahrt beginnen ',
    txt_global_std_youcanexpectthereadertoarrivewithin35businessdays: 'Sie können damit rechnen, dass der Leser innerhalb von 3-5 Werktagen eintrifft.',
    txt_global_std_individualdrivers: 'Einzelne Fahrer',
    txt_global_std_p2pyoudonthaveanytripsyet: 'Sie haben noch keine Reisen.',
    txt_global_std_tomczykDescription: 'Nach einer Kart-Karriere startete Tom in der Formel BMW und der Formel 3. 2001 war er der jüngste DTM-Pilot, aller Zeiten. 2011 wurde er DTM-Meister, 2007 Dritter, 2006 Vierter und 2004 Fünfter, nachdem er acht Rennen gewonnen hatte. Insgesamt war Tom 16 Jahre in der DTM-Meisterschaft. 2017 fuhr er in der IMSA, um sich auf die Langstreckenrennen vorzubereiten. 2018/19 ist er Teil des BMW M8 GTE WEC Programms (World Endurance Championship).',
    txt_global_std_kintoImprovement3: 'Vergessen Sie nicht, Ihr Feedback zu lesen, um Einsparungen zu erzielen!',
    txt_global_std_failedtoremove: 'Fehler beim Entfernen',
    txt_global_std_youractivationcodes: 'Ihr Aktivierungscode',
    txt_global_std_warikan_onboarding18: 'Temporäres Passwort',
    txt_global_std_doyouwanttocontinue: 'Wollen Sie fortfahren',
    txt_global_std_tomasengeinterests: 'Rennsport im Allgemeinen, Kinder',
    txt_global_std_TaxInvoice12: 'Die Zahlung eines Versicherungsnehmers an einen Vermittler oder Makler gilt als Zahlung an den Versicherer',
    txt_global_std_MSIG_InsuranceLetter8: 'Art der Deckung.',
    txt_global_std_p2ppleaseselectacar: 'Bitte wählen Sie ein Auto',
    txt_global_std_history: 'Geschichte',
    txt_global_std_drivtimeover80: 'Fahrzeit über {Geschwindigkeit}',
    txt_global_std_whyyoushouldusefiasdcforyourbusiness: 'Warum Sie FIA SDC für Ihr Unternehmen nutzen sollten',
    txt_global_std_trackingofyourco2emissions: 'Verfolgung Ihrer CO2-Emissionen.',
    txt_global_std_sender: 'Absender',
    txt_global_std_replaydrive: 'Fahrt wiederholen',
    txt_global_std_homeaddress: 'Heimatadresse',
    txt_global_std_teammemberbig: 'TEAMMITGLIED\n',
    txt_global_std_p2pbookingpending: 'Buchung ausstehend',
    txt_global_std_p2pselectedfriend: 'Ausgewählter Freund',
    txt_global_std_appcreator_upsell_pitch_bulletpoint3: 'Steigen Sie im Rennen um kundenorientierte Digital-First-Lösungen nach vorne',
    txt_global_std_opensettingsinthephoneturnon: 'Öffnen Sie „Einstellungen“ im Telefon, schalten Sie Bluetooth ein und öffnen Sie die App.',
    txt_global_std_pleaseentercompanyname: 'Bitte geben Sie den Firmennamen ein',
    txt_global_std_therearenoaccountscreatedundertheagreement: 'Im Rahmen der Vereinbarung werden keine Konten erstellt.',
    txt_global_std_p2pfaq14a: 'Wir empfehlen, dass Sie den Autobesitzer in den Chat-Nachrichten informieren, wenn Sie wissen, dass Sie zu spät kommen.',
    txt_global_std_allow: 'ermöglichen',
    txt_global_std_vehicleapi: 'Fahrzeug-API',
    txt_global_std_yourmaxspeed: 'Ihre Höchstgeschwindigkeit',
    txt_global_std_p2pemailforcommunication: 'E-Mail zur Kommunikation',
    txt_global_std_loyaltystatus: 'Loyalitätsstatus',
    txt_global_std_gps: 'GPS',
    txt_global_std_mpltipdev7pp: 'Dies ist ein geübter Fahrer, weist aber auf eine unbeständige Fahrweise hin. Über viele Tage oder manchmal über Wochen wird Cool Driving praktiziert. Aber dann wird Cool Driving nur für einen Tag ignoriert. Es gibt etwas, das den Fahrer dazu bringt, seine Einstellung zum Fahren zu ändern, und für uns ist es schwierig, den Grund dafür zu bestimmen. Wir empfehlen daher ein kurzes Gespräch mit dem Fahrer und fragen nach seiner Meinung, warum dies passiert.',
    txt_global_std_youmustpickadate: 'Sie müssen ein Datum auswählen',
    txt_global_std_riskindex: 'Risikoindex',
    txt_global_std_settingssmall: 'die Einstellungen',
    txt_global_std_ended: 'Beendet',
    txt_global_std_nickname: 'Spitzname',
    txt_global_std_getoverviewtrips: 'Verschaffen Sie sich einen Überblick über alle Ihre Fahrten',
    txt_global_std_cimage23h: 'Rollen Sie einige Extrameter',
    txt_global_std_itsalittleemptyinhere: 'Hier drinnen ist es ein bisschen leer',
    txt_global_std_ifbuttondontworktrylink: 'Wenn die obige Schaltfläche nicht funktioniert, fügen Sie diesen Link in Ihren Webbrowser ein:',
    txt_global_std_teamname1: 'Teamname',
    txt_global_std_dontstress: 'Betonen Sie nicht',
    txt_global_std_areyousureyouwanttoredeempointstotradepoints: 'Sind Sie sicher, dass Sie {pointsToTrade} Punkte einlösen möchten?',
    txt_global_std_ConfirmFia_Text6: 'Place de la Concorde, 75008 Paris, France',
    txt_global_std_addyourvehicleidentificationtobeabltoenjoyallthebenefitsandclaimyourrewards: 'Fügen Sie Ihre Fahrzeugidentifikation hinzu, um alle Vorteile nutzen und Ihre Belohnungen einfordern zu können!',
    txt_global_std_yourfriendslistlooksabitemptyusethebuttonbelowtostartinvitingyourfriends: 'Ihre Freundesliste sieht etwas leer aus. Verwenden Sie die Schaltfläche unten, um Ihre Freunde einzuladen.',
    txt_global_std_MSIG_InsuranceLetter27: '* Beschränkungen, die durch Abschnitt 8 des Gesetzes über Kraftfahrzeuge (Risiken und Entschädigungen Dritter) (Kapitel 189) und Abschnitt 95 des Straßenverkehrsgesetzes von 1987 (Malaysia) außer Kraft gesetzt wurden, sind in diesen Überschriften nicht enthalten.',
    txt_global_std_addfriends: 'Freunde hinzufügen',
    txt_global_std_checkifcorrectobdreaderisconnected: 'Prüfen Sie, ob der richtige OBD-Reader angeschlossen ist.',
    txt_global_std_placethewarningtriangleatleast100metersfromthecar: 'Platzieren Sie das Warndreieck mindestens 100 Meter vom Auto entfernt',
    txt_global_std_cimage28h: 'Es ist nicht angemessen, den Tempomat anzuwenden. ',
    txt_global_std_wall: 'Wand / Mauer',
    txt_global_std_somethingiswrong: 'Irgendwas stimmt nicht',
    txt_global_std_p2pfaq32q: '\nBitte sagen Sie mir, wie ich die Kosten aufteilen soll.',
    txt_global_std_accessdeniedyoudonothavepermissiontoaccesstheworkspacepleasecontactyouradministratorifincorrect: 'Zugriff abgelehnt. Sie sind nicht berechtigt, auf den Arbeitsbereich zuzugreifen. Wenden Sie sich bei Fehlern bitte an Ihren Administrator.',
    txt_global_std_ChicheritQuote: 'Demnächst...',
    txt_global_std_insuranceactivesince: 'Versicherung aktiv seit',
    txt_global_std_MSIG_InsuranceLetter4: 'Vorschriften für Kraftfahrzeuge (Risiken und Entschädigungen Dritter), 1960',
    txt_global_std_enginescanner: 'Motorscanner',
    txt_global_std_bestscore: 'Bestes Ergebnis',
    txt_global_std_howitworks1: 'Wie es funktioniert',
    txt_global_std_p2pcleaning: 'Reinigung',
    txt_global_std_MSIG_InsuranceLetter37: '36 Robinson Road # 16-01 City House Singapore 068877 Tel .: (65) 6507 3848 Fax: (65) 6507 3849',
    txt_global_std_heavydamage: 'Schwerer Schaden?',
    txt_global_std_KristofferssonFacts: '',
    txt_global_std_youwilllosedrivedata: 'You will lose all data, including drives, if you login with another user.',
    txt_global_std_typeoftraining: 'Art des Trainings',
    txt_global_std_MSIG_InsuranceLetter18: 'Autorisierter Fahrer *',
    txt_global_std_setupcompanychallenge_fia_text: 'Schließen Sie sich Ihrem Team im FIA SDC an und bleiben Sie aktiv, smart und nachhaltig, wenn Sie mit dem Auto reisen.',
    txt_global_std_selectyourcar: 'Wählen Sie Ihr Auto aus',
    txt_global_std_youarearockstardriver: 'Ihr Fahren war auf dieser Reise ausgezeichnet! Wir haben derzeit keine weiteren Tipps für Sie. Du bist ein Rockstar!',
    txt_global_std_trygroadsideassistance: 'Pannenhilfe versuchen',
    txt_global_std_MSIG_Password1: 'Hallo!',
    txt_global_std_unknown: 'unbekannte',
    txt_global_std_youhaveupdatedyourdetails: 'Sie haben Ihre Angaben aktualisiert',
    txt_global_std_cimage31d: '',
    txt_global_std_promocode: 'Promo Code',
    txt_global_std_youvefinishedyourdrive: 'Sie haben Ihre Fahrt beendet!',
    txt_global_std_learnmore: 'Mehr erfahren',
    txt_global_std_amazingdriverlockedkey: 'Beende eine Fahrt bei Punktzahl {score} oder besser, um dieses Abzeichen freizuschalten. Sie können sich dieses Abzeichen einmal pro Tag verdienen.',
    txt_global_std_reportaccidentheadtext: 'Wenn Sie einen Unfall haben, melden Sie ihn bitte so schnell wie möglich unten, es dauert nicht länger als 10 Sekunden.',
    txt_global_std_expired: 'Abgelaufen',
    txt_global_std_locationreasontext: 'Ortungsdienste sind erforderlich, um Ihre Fahrpunktzahl zu bestimmen.',
    txt_global_std_searchbygroupname: 'Suche nach Gruppe, Name',
    txt_global_std_MarklundChamp: '2x FIA European Rallycross Champion',
    txt_global_std_insnum: 'Vers. nummer',
    txt_global_std_itseasytomonitoryouresgtargets: 'Es ist einfach, Ihre ESG-Ziele zu überwachen',
    txt_global_std_kintorecruit5userslocked: 'Schalten Sie diesen Erfolg frei und verdienen Sie 10.000 ¥, indem Sie 5 Kinto-Benutzer rekrutieren.',
    txt_global_std_unplugtheobdreaderandplugitbackagain: 'Trennen Sie den OBD-Leser und schließen Sie ihn wieder an. Warten Sie, bis der Leser blau blinkt.',
    txt_global_std_activeparticipantsfor: 'Aktive Teilnehmer für',
    txt_global_std_SignupFia_Text5: 'Laden Sie die SDC-App herunter, um einen Überblick über das zu erhalten, was kommt',
    txt_global_std_restrictions: 'Beschränkungen',
    txt_global_std_stayfocused: 'Konzentriert bleiben',
    txt_global_std_TomczykFacts: 'Nach einer Kart-Karriere startete Tom in der Formel BMW und der Formel 3. 2001 war er der jüngste DTM-Pilot, aller Zeiten. 2011 wurde er DTM-Meister, 2007 Dritter, 2006 Vierter und 2004 Fünfter, nachdem er acht Rennen gewonnen hatte. Insgesamt war Tom 16 Jahre in der DTM-Meisterschaft. 2017 fuhr er in der IMSA, um sich auf die Langstreckenrennen vorzubereiten. 2018/19 ist er Teil des BMW M8 GTE WEC Programms (World Endurance Championship).',
    txt_global_std_fileinput: 'Dateieingabe',
    txt_global_std_theapikeydoesnthavepermissionstoperformtherequest: 'Der API-Schlüssel hat keine Berechtigungen zum Ausführen der Anfrage.',
    txt_global_std_latesttrip: 'Letzte Reise',
    txt_global_std_areyousureyouwanttodeletethegroupdeletingthegroupdoesnotdeletetheusersaccounts: 'Möchten Sie die Gruppe wirklich löschen?\n' +
      '\n' +
      'Durch das Löschen der Gruppe werden die Benutzerkonten nicht gelöscht.',
    txt_global_std_theuserwillbeaddedtoyouragreementaccordingtoyourpriceplan: 'Der Benutzer wird gemäß Ihrem Preisplan zu Ihrer Vereinbarung hinzugefügt.',
    txt_global_std_rwmselectfriendtext: 'Wählen Sie den Freund/die Freunde aus, die mit Ihnen gefahren sind',
    txt_global_std_welcomeresendemail: 'Willkommen in Ihrem neuen Konto! Wir haben Ihnen eine E-Mail gesendet, um Ihr neues Konto zu bestätigen. Denken Sie daran, Ihren Spam-Ordner zu überprüfen. Keine E-Mail erhalten?',
    txt_global_std_automaticstartandstoprequiresaccesstobluetoothlocationservicesgpsandmotionfitness: 'Automatisches Starten und Stoppen erfordert Zugriff auf Bluetooth, Ortungsdienste (GPS) und Bewegung & Fitness.',
    txt_global_std_riskportfoliotracker_upsell_pitch: 'Verknüpfen Sie ganz einfach Ihr gesamtes Buch für eine Ansicht des tatsächlichen Risikos und der CO2-Auswirkungen für:',
    txt_global_std_inviteall: 'Lade alle ein',
    txt_global_std_enterdriverriskweight: 'Geben Sie das Risikogewicht des Fahrers ein',
    txt_global_std_warikan_onboardingemailsubject: 'KINTO Co., Ltd. | 4-8-18 Meieki, Nakamura-ku, Nagoya City Nagoya Mitsui Gebäude Nordgebäude 14F',
    txt_global_std_couldnotsavechanges: 'Ihre Änderungen konnten nicht gespeichert werden',
    txt_global_std_youraveragelevelispoints: 'Dein durchschnittliches Level beträgt {points}',
    txt_global_std_connectivity: 'Konnektivität',
    txt_global_std_avgrisklevel: 'Durchschn. Risikostufe',
    txt_global_std_youneedtodrivemorethankmkmduringaheatweekinordertogetascoreintheheatleaderboard: 'Sie müssen während eines Laufs (Woche) mehr als {km} km fahren, um eine Punktzahl in der Rangliste der Läufe zu erhalten.',
    txt_global_std_driveraverage: 'Fahrer Durchschnitt',
    txt_global_std_challengeleaderboard_upsell_pitch: 'Organisieren Sie eine Herausforderung für Teams, Abteilungen, Kunden, Partner und andere Gäste;',
    txt_global_std_noofusers: 'Anzahl der Benutzer',
    txt_global_std_yourunsavedchangeswillbelostsavechangesbeforeclosing: 'Ihre nicht gespeicherten Änderungen gehen verloren. Änderungen vor dem Schließen speichern?',
    txt_global_std_emailuniqueinvitations: 'Senden Sie einzigartige Einladungen per E-Mail',
    txt_global_std_TaxInvoice1: 'STEUERRECHNUNG',
    txt_global_std_starburstnamekey: 'Starburst!',
    txt_global_std_p2paskakintoownertoinviteyouandyouwillgetaccesstoallthecoolfunctionsinthisapp: 'Bitten Sie einen Kinto-Besitzer, Sie einzuladen, und Sie erhalten Zugriff auf alle coolen Funktionen dieser App.',
    txt_global_std_continue123: 'Fortsetzen',
    txt_global_std_p2pcoolandfunstatsaboutyourfriendsbookingofyourcar: 'Coole und lustige Statistiken über die Buchung Ihres Autos durch Ihren Freund.',
    txt_global_std_ridewithmekey: 'Fahr mit mir',
    txt_global_std_joinrandom: 'zufällig beitreten',
    txt_global_std_couldnotcalculatespeedimprovementpossibility: 'Geschwindigkeitsverbesserungsmöglichkeit konnte nicht berechnet werden!',
    txt_global_std_trygdemo: 'Überspringen und loslegen mit Tryg Drive',
    txt_global_std_from2: 'aus',
    txt_global_std_driveminimum1kmduringaheatinordertoqualifyforthechallenge: '• Fahren Sie während eines Laufs mindestens 1 km, um sich für die Herausforderung zu qualifizieren.',
    txt_global_std_invitedmembers: 'Eingeladene Mitglieder',
    txt_global_std_p2ppushtitlelendingcomingup: 'Kredite stehen an',
    txt_global_std_communication_upsell_bulletpoint2: 'Verbinden Sie sich mit Benutzern auf ihren bevorzugten Kanälen',
    txt_global_std_startlendingyourcar: 'Beginnen Sie damit, Ihr Auto auszuleihen!',
    txt_global_std_seehowmuchco2savingsyougetwhenyoudrivewell: 'Sehen Sie, wie viel CO2-Einsparung Sie erzielen, wenn Sie gut fahren',
    txt_global_std_trip: 'Reise',
    txt_global_std_opensettingsinyourphonegotoprivacy: 'Öffnen Sie "Einstellungen" in Ihrem Telefon. Gehen Sie zu Datenschutz, öffnen Sie den Ortungsdienst und schalten Sie FIA ​​Smart ein.',
    txt_global_std_warikan_onboarding17: 'Mail Adresse',
    txt_global_std_sendmyposition: 'Senden Sie meine Position',
    txt_global_std_buymorekilometers: 'Kaufen Sie mehr Kilometer',
    txt_global_std_letsstartwiththebasics: 'Fangen wir mit den Grundlagen an',
    txt_global_std_nofiltersyet: 'Noch keine Filter',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint2: 'Gratulieren Sie den Fahrern zum Erreichen ihrer Meilensteine/Ziele',
    txt_global_std_warikan_onboarding15: 'Anfrageformular:\n',
    txt_global_std_trysidekick: 'Versuchen Sie Sidekick',
    txt_global_std_carsagreements: 'Autos & Vereinbarungen',
    txt_global_std_thepasswordminimumlengthis8charactersandmustinclude1lowercaseuppercasenumeralandspecialcharacter: 'Die Mindestlänge des Passworts beträgt 8 Zeichen und muss 1 Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen enthalten',
    txt_global_std_SignupFia_Text4: 'Sind Sie für die Herausforderung bereit?',
    txt_global_std_confirmationmail: 'Sie sind jetzt gebucht!',
    txt_global_std_invoiceoverview: 'Kosten ',
    txt_global_std_welcometoappname: 'Willkommen bei {appName}!',
    txt_global_std_whenwillmyratechange: 'Wann ändert sich mein Tarif?',
    txt_global_std_gpserror: 'GPS Fehler',
    txt_global_std_qhowdoifileaclaim: 'F: Wie reiche ich einen Anspruch ein?',
    txt_global_std_analyzingtheresults: 'Die Ergebnisse Ihrer Fahrt analysieren',
    txt_global_std_exportexcel: 'XLS exportieren',
    txt_global_std_toturnonbluetoothinyourphone: 'Um Bluetooth in Ihrem Telefon einzuschalten, können Sie zu Ihren Einstellungen gehen und es von dort aus einschalten. Sie können auch nach unten wischen, um Bluetooth von Ihrem Kontrollzentrum aus einzuschalten.',
    txt_global_std_manageadvancedusersettingsandsupporthereusethequickaccessbuttonsbelowtoeasilynavigatebetweenuserscarsagreementsandfollowupandhelpoutwiththeironboarding: 'Verwalten Sie hier erweiterte Benutzereinstellungen und Support. Verwenden Sie die Schnellzugriffsschaltflächen unten, um einfach zwischen Benutzern, Autos und Vereinbarungen zu navigieren und ihre Einarbeitung zu verfolgen und zu unterstützen.',
    txt_global_std_target: 'Ziel',
    txt_global_std_inordertoreceiveadrivingdividend75ofthedistancetraveledmustbecompletedwiththeappandchiptenfoldremembertohaveboththeappandbluetoothonwhenyoudriveandthetripwillberegistered: 'Um eine Fahrdividende zu erhalten, müssen 75% der zurückgelegten Strecke mit der App und dem Chip zehnfach zurückgelegt werden. Denken Sie daran, während der Fahrt sowohl die App als auch Bluetooth eingeschaltet zu haben, damit die Fahrt registriert wird.',
    txt_global_std_youcanalwaysgobacktothisvideoguidebyclickingtheinthetopnavigationbar: 'Sie können jederzeit zu dieser Videoanleitung zurückkehren, indem Sie auf das ? in der oberen Navigationsleiste.',
    txt_global_std_wowgoodprogress: 'Wow, gute Fortschritte!',
    txt_global_std_myinsurance2: 'Meine Versicherung',
    txt_global_std_adjustyourannualmileage: 'Passen Sie Ihre Jahreskilometer an',
    txt_global_std_changelater: 'Change later',
    txt_global_std_p2pcalendar: 'Kalender',
    txt_global_std_publicanyonewiththeappcanparticipate: 'Öffentlich (jeder mit der App kann teilnehmen)',
    txt_global_std_key: 'Taste',
    txt_global_std_countryofresidence: 'Land des Wohnsitzes',
    txt_global_std_overviewoftheonboarding: 'Überblick über das Onboarding',
    txt_global_std_analyzingyourdrive1: 'Analysieren Sie Ihr Laufwerk',
    txt_global_std_wrongcurrentpassword: 'Falsches aktuelles Passwort, bitte versuchen Sie es erneut.',
    txt_global_std_p2penterthedigitcodeorclickonthelinksentto: 'Geben Sie den 6-stelligen Code ein oder klicken Sie auf den gesendeten Link an:',
    txt_global_std_messageheader: 'Kopfzeile der Nachricht',
    txt_global_std_wewilldeducttheinvoicepaymentfromthe: 'We will deduct the invoice payment from you the 1st day each month for the fixed premium in advance and the variable premium for the past month. The deduction will be made on the debit or credit card you used when purchasing the insurance.',
    txt_global_std_unlockbenefitsandrewards: 'Schalte Vorteile und Belohnungen frei',
    txt_global_std_new: 'Neu',
    txt_global_std_activationcodeisnotvalid: 'Aktivierungscode ist ungültig.',
    txt_global_std_fueltype: 'Kraftstoffart',
    txt_global_std_saveandclose: 'Speichern und schließen',
    txt_global_std_ifyoudonthaveanygroupsyetyoucancreatetheminthegroupsmodule: 'Wenn Sie noch keine Gruppen haben, können Sie diese im Gruppenmodul erstellen.',
    txt_global_std_p2ppushtitlenewmessage: 'Neue Nachricht von {name}',
    txt_global_std_keepasafedistance: 'Abstand halten',
    txt_global_std_email2: 'Email',
    txt_global_std_update: 'Aktualisieren',
    txt_global_std_p2phasconfirmedbooking: ' hat die Buchung bestätigt',
    txt_global_std_ifyoudidntmakerequestignore: 'Wenn Sie diese Anfrage nicht gestellt haben, können Sie diese E-Mail ignorieren.',
    txt_global_std_levellevel: 'Level',
    txt_global_std_howcaniimprovemyscore: 'Wie kann ich meine Punktzahl verbessern?',
    txt_global_std_pressondevicebelowtoaddit: 'Drücken Sie unten auf das Gerät, um es hinzuzufügen.',
    txt_global_std_yousignedupforournewsletter: 'Sie haben sich für den Newsletter angemeldet. ',
    txt_global_std_wereanyothercarsinvolved: 'Waren noch andere Autos dabei?',
    txt_global_std_p2pfaq26q: 'Bitte sagen Sie mir, wie man ein Auto mit Freunden teilt.',
    txt_global_std_invalidoldpasswordornewpassword: 'Ungültiges altes Passwort oder neues Passwort',
    txt_global_std_ecodriving: 'Umweltbewusstes Fahren',
    txt_global_std_general: 'Allgemeines',
    txt_global_std_PaddonInterests: 'Laufen, Golf, Mountainbiken',
    txt_global_std_thisisneededtogiveyouestimatedsavings: 'Dies ist erforderlich, um Ihnen geschätzte Einsparungen zu geben.',
    txt_global_std_p2pshowyourreferralcode: 'Zeigen Sie Ihren Empfehlungscode für Freunde',
    txt_global_std_platenumberornameofcar: 'Kennzeichen oder Name des Autos',
    txt_global_std_activationcodeisrequired: 'Aktivierungscode ist erforderlich',
    txt_global_std_motionfitnesswillhelpyoustartandstopdrivesautomaticallysothatyoucanjustfocusonyourdriving: 'Motion & Fitness hilft Ihnen dabei, Fahrten automatisch zu starten und zu stoppen, sodass Sie sich nur auf Ihr Fahren konzentrieren können.',
    txt_global_std_timet: 'T',
    txt_global_std_savechanges2: 'Änderungen speichern?',
    txt_global_std_p2pfinishandpayquestion: 'Fertig stellen und bezahlen?',
    txt_global_std_reactivateinsurance: 'Versicherung reaktivieren',
    txt_global_std_p2pgoheretofindcostsplitfunctioncheckyourdrivingstatsvisityourfriendsprofileandmore: 'Gehen Sie hier, um die Kostenaufteilungsfunktion zu finden, Ihre Fahrstatistiken zu überprüfen, Ihr Freundesprofil zu besuchen und vieles mehr.',
    txt_global_std_firedamage123: 'Feuer Schaden',
    txt_global_std_firstwithscore: 'Lassen Sie uns der erste sein, der Punkte in der Hitze erzielt',
    txt_global_std_p2pconfirmedthebooking: 'bestätigte die Buchung.',
    txt_global_std_numberofparticipants: 'Zahl der Teilnehmer',
    txt_global_std_HansenShortDescription: '',
    txt_global_std_nextstatus: 'Nächster Status',
    txt_global_std_somethingwentwrong: 'Etwas ist schief gelaufen',
    txt_global_std_thatsokwhenyouarereadytodriveyoucanconnecthere: 'Das ist okay! Wenn Sie fahrbereit sind, können Sie sich hier mit Ihrem Auto verbinden.',
    txt_global_std_viewalltrips: 'Alle Reisen anzeigen',
    txt_global_std_fiasdcecoscore: 'FIA SDC Eco-Punktzahl',
    txt_global_std_nothanks2: 'Nein Danke',
    txt_global_std_onlylettersnumbersandperiodsareallowed: 'Erlaubt sind nur Buchstaben (a-z), Ziffern (0-9) und Punkte (.).',
    txt_global_std_15discountonrenewal: '15% Rabatt auf Verlängerung',
    txt_global_std_yourcarwillstillbeinsuredbutyourpremium: 'Your car will still be insured, but your premium cost might increase due to us billing you at premium level 10 for non connected drives.',
    txt_global_std_howdoisignupfortheinsurance: 'Wie melde ich mich für die Versicherung an?',
    txt_global_std_youralreadyuseddevices: 'Ihre bereits verwendeten Geräte',
    txt_global_std_borrowacar: 'Leihe dir ein Auto',
    txt_global_std_create: 'Erstellen',
    txt_global_std_totalsavings: 'Gesamtersparnis',
    txt_global_std_anticipation: 'Vorwegnahme',
    txt_global_std_allday: 'Den ganzen Tag',
    txt_global_std_noeventtoday: 'Keine Veranstaltung heute',
    txt_global_std_p2paddexpense: 'Ausgaben hinzufügen',
    txt_global_std_signingupforinsurance: 'Versicherung abschließen',
    txt_global_std_MSIG_InsuranceLetter9: 'Zertifikatsnummer.',
    txt_global_std_ridewithmelockedkey: 'Ist ein Freund mit dir gefahren? Registrieren Sie eine Mitfahrgelegenheit, um dieses Abzeichen zu erhalten. Diese Funktion finden Sie im Menü mit den drei Punkten. Sie können dieses Abzeichen einmal pro Woche verdienen.',
    txt_global_std_accruedlevel: 'Aufgelaufenes Level =',
    txt_global_std_joinenerfy: 'Teilnehmen ',
    txt_global_std_herunderkandusefleredetaljeromdennetur: 'Herunder kan du se flere detaljer om denne tur.',
    txt_global_std_402requestfailed: '402 - Anfrage fehlgeschlagen',
    txt_global_std_invitefriend: 'Einen Freund einladen',
    txt_global_std_almosttherewewillnowbuildyourdemoapp: 'Fast dort. Wir bauen nun Ihre Demo-App.',
    txt_global_std_connectedbluetoothid: 'Verbundene Bluetooth-ID',
    txt_global_std_nosampledata: 'Keine Probedaten',
    txt_global_std_nicknamesmayonlyincludeletters: 'Spitznamen darf nur Buchstaben enthalten,',
    txt_global_std_appcreatoroverview: 'Überblick über den App Creator',
    txt_global_std_75offdeductible: '75% vom Selbstbehalt',
    txt_global_std_whendrivingincityconditionsavoidchasingthenextcrossingortrafficlightbygainingspeedquicklythenslowingquicklythishurtstraveltimeandfueleconomyandincreasescrashrisklookingwellaheadanddrivingsmoothlywillhelpyouanticipateandhandleanyeventnew: 'Wenn Sie in der Stadt fahren, vermeiden Sie es, die nächste Kreuzung oder Ampel zu jagen, indem Sie schnell beschleunigen und dann schnell verlangsamen. Dies beeinträchtigt die Reisezeit und den Kraftstoffverbrauch und erhöht das Unfallrisiko. Weit vorausschauend und reibungslos zu fahren hilft Ihnen, jedes Ereignis vorherzusehen und zu bewältigen.',
    txt_global_std_HSolbergQuote: 'Als langjähriger Profifahrer habe ich schon viele fahrerische Herausforderungen in meiner Karriere mitgemacht. Das Erste, was mir in den Sinn kommt, wenn ich hinter dem Lenkrad sitze, ist das „sichere Fahren“. Lass uns diese Herausforderung mit der FIA Smart Driving Challenge für "sichere Straßen" gemeinsam angehen. ',
    txt_global_std_PettersolbergDescription: 'Petter Solberg, der erste FIA-Weltmeister in zwei verschiedenen Motorsport-Disziplinen, konnte seinen Erfolg bei der Rallye-Weltmeisterschaft 2003 mit dem Gewinn der FIA World Rallycross-Meisterschaft in zwei aufeinanderfolgenden Saisons in den Jahren 2014 und 2015 fortsetzen. In seiner norwegischen Heimat machte Solberg, der für seine vielen Fans als "Hollywood" bekannt wurde, Solberg, der für seine vielen Fans als "Hollywood" bekannt wurde, 1998 sein Debüt bei der Rallye-Weltmeisterschaft, bevor er 2000 bei der Rallye GB vor seinem ersten Sieg zu Subaru wechselte..',
    txt_global_std_BeforeFirstBill_Text3: 'ie zahlen zwei Prämien pro Monat. eine variable und eine feste Prämie. Die feste Prämie ist gleich und ändert sich nicht, sie basiert auf dem Fahrzeugtyp, den Sie haben. Die variable Prämie basiert vollständig auf Ihrer Fahrweise. Wenn Sie intelligent und sicher fahren, wird Ihre Prämie günstiger.',
    txt_global_std_filternofilter: 'Kein Filter',
    txt_global_std_fiasdccompanychallenge: 'FIA SDC Company Challenge',
    txt_global_std_vehicleidentificationwassaved: 'Fahrzeugidentifikation wurde gespeichert',
    txt_global_std_areyousureyouwanttodeletethegroup: 'Möchten Sie die Gruppe wirklich löschen?',
    txt_global_std_seeyourprogressandgetinsightsandfeedbackonyourdriving: 'Sehen Sie Ihren Fortschritt und erhalten Sie Einblicke und Feedback zu Ihrem Fahrverhalten.',
    txt_global_std_asyoumayhaveareportconsistingofmanymembersyouareabletosubmitarequesttodownloadthewholesetifyouwish: 'Da Sie möglicherweise einen Bericht haben, der aus vielen Mitgliedern besteht, können Sie eine Anfrage zum Herunterladen des gesamten Satzes stellen, wenn Sie dies wünschen.',
    txt_global_std_welcometocompany: 'Willkommen bei [Unternehmen]!',
    txt_global_std_secondsec: 'sek',
    txt_global_std_useapi: 'API verwenden',
    txt_global_std_howdoicompeteinappchallengeanswer: 'Sie nehmen automatisch teil, wenn Sie sich anmelden. Keine Sorge, in der Rangliste wird nur Ihr Spitzname angezeigt, sodass Sie immer anonym bleiben. Es gibt einen monatlichen und jährlichen Wettbewerb.',
    txt_global_std_youwillsoonbeready: 'Sie werden bald fertig sein!',
    txt_global_std_conversionto: 'Umrechnung zu',
    txt_global_std_TidemandShortDescription: 'WRC 2 Champion 2017, Tourenwagen-Meister 2010.',
    txt_global_std_manageyourteammembersandtheiraccountpermissions: 'Verwalten Sie Ihre Teammitglieder und ihre Kontoberechtigungen',
    txt_global_std_learnmoreaboutus: 'lerne mehr über uns',
    txt_global_std_sendcodetome: 'Senden Sie mir den Code',
    txt_global_std_ready2go: 'Ready2Go',
    txt_global_std_yourplateshouldcontainacombinationofnumbersandletters: 'Ihr Teller sollte eine Kombination aus Zahlen und Buchstaben enthalten.',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimeinconstantspeedunnecessaryspeedvariationcansometimesindicatedistractionorlackoffocus: 'Auf dieser Art von Reise verbrachte der durchschnittliche Öko-Champion mehr Zeit mit konstanter Geschwindigkeit. Unnötige Geschwindigkeitsvariationen können manchmal auf Ablenkung oder mangelnde Konzentration hindeuten.',
    txt_global_std_whymustiallowalwaysallowlocationsservices: 'Warum muss ich &quot;Standortdienste immer zulassen&quot; zulassen?',
    txt_global_std_collect: 'Sammeln',
    txt_global_std_acceptusertermsconditionsandprivacypolicy: 'Akzeptieren Sie die Nutzungsbedingungen und Datenschutzbestimmungen der Benutzer',
    txt_global_std_atleast10characters: 'Mindestens 10 Zeichen',
    txt_global_std_added: 'Hinzugefügt',
    txt_global_std_MSIG_InsuranceLetter38: 'Jede Person, die auf Anweisung des Versicherten oder mit dessen Erlaubnis fährt.',
    txt_global_std_OgierQuote: 'Kommt bald ',
    txt_global_std_tryingtosignyouintoenerfy: 'Sie versuchen sich bei {appName} anmelden ...',
    txt_global_std_noreaderyetskipthisstep: 'Noch kein Leser? Überspringen Sie diesen Schritt',
    txt_global_std_almostdone: 'Fast fertig.',
    txt_global_std_anactivationcodecanbeusedtogetaccesstospecificeventsandchallenges: 'Ein Aktivierungscode kann verwendet werden, um Zugang zu bestimmten Ereignissen und Herausforderungen zu erhalten.',
    txt_global_std_points123: 'Punkte',
    txt_global_std_forquestionsandfeedbackregardingtheappcontacttheemailabove: 'Wenden Sie sich bei Fragen und Feedback zur App an die obige E-Mail-Adresse.',
    txt_global_std_theactivationcodehasbeensent: 'Der Aktivierungscode wurde gesendet.',
    txt_global_std_liveonmap: 'Live auf der Karte',
    txt_global_std_customerservice: 'Kundenservice',
    txt_global_std_somethingwentwrongpleasetryagainlater: 'Etwas ist schief gelaufen, bitte versuchen Sie es später erneut.',
    txt_global_std_PolicySchedule17: 'NCDP,',
    txt_global_std_savechanges: 'Änderungen speichern',
    txt_global_std_acceptthedongle: 'Bitte erlauben Sie dem Dongle auch Ihr Telefon anzuschließen',
    txt_global_std_averagespeed: 'Durchschnittsgeschwindigkeit',
    txt_global_std_annualprice: 'Jahrespreis',
    txt_global_std_youneedtodrivemorethan10kmduringaheatweekinordertogetascoreintheheatleaderboard: 'Sie müssen während eines Laufs (Woche) mehr als 10 km fahren, um in der Laufbestenliste zu punkten.',
    txt_global_std_cimage31h: 'Versuchen Sie kleine Beschleunigungen zu vermeiden',
    txt_global_std_enteravalidcode: 'Geben Sie einen gültigen Code ein.',
    txt_global_std_firstdriveunlocked: 'Sie sind gefahren und haben eine Punktzahl erhalten! Gut gemacht!',
    txt_global_std_resendpassword: 'Passwort erneut senden',
    txt_global_std_chassinumberalreadyexists: 'Chassinumber existiert bereits',
    txt_global_std_nextqualificationfortheendfinalstarts: 'Die nächste Qualifikation für das Endfinale beginnt',
    'txt_global_std_Al-KuwariChamp': '',
    txt_global_std_p2ppushbodyhasnowfinalizedthebooking: '{name} hat die Buchung nun abgeschlossen.',
    txt_global_std_enerfyriskportfoliotracker: 'Enerfy Risk Portfolio Tracker',
    txt_global_std_socialbutterflylockedkey: 'Empfehlen Sie 10 Freunde, um diesen Erfolg freizuschalten und 300 Punkte für Ihre Kinto-Brieftasche zu sammeln.',
    txt_global_std_letsgetstarted: 'Lass uns anfangen!',
    txt_global_std_onboardingstatus: 'Onboarding-Status',
    txt_global_std_fiasdc2019w1price3: '1 Saison Paket',
    'txt_global_std_ben-sulayemDescription': 'Er hat an Rallyes in Afrika, Europa, dem Nahen Osten und an Weltmeisterschaften teilgenommen. Er gewann einen seiner Titel, als er sich von einem Genickbruch erholte.',
    txt_global_std_drivingtogetherismorefunaddyourcoworkersorsendthemalink: 'Gemeinsam fahren macht mehr Spaß, fügen Sie Ihre Kollegen hinzu oder senden Sie ihnen einen Link.',
    txt_global_std_subscribetoupdates: 'Abonnieren Sie Updates',
    txt_global_std_p2pfaq16q: 'Was ist, wenn ich das Auto beschädige?',
    txt_global_std_p2pcalendaroverview: 'Kalenderübersicht',
    txt_global_std_april: 'April',
    txt_global_std_sendsms: 'SMS senden ',
    txt_global_std_thisisrequired: 'Dies ist erforderlich',
    txt_global_std_WelcomeLetter_Text3: 'Wenn Sie Fragen zu Ihrer Kfz-Versicherung haben, wenden Sie sich an uns oder besuchen Sie unsere Website www.enerfy.se, um weitere Informationen zur Funktionsweise von {appName} zu erhalten.',
    txt_global_std_performancechart: 'Leistungsdiagramm',
    txt_global_std_p2paddmember: 'Mitglied hinzufügen',
    txt_global_std_p2pselectcalendar: 'Wählen Sie Kalender',
    txt_global_std_youhave: 'Du hast',
    txt_global_std_premiumdiscount: 'Premium-Rabatt',
    txt_global_std_comebacklatertherearenochallengeshereatthemoment: 'Kommen Sie später wieder, hier gibt es im Moment keine Herausforderungen.',
    txt_global_std_viewriskandco2levelsregardlessofvehicletypeandlocation: 'Zeigen Sie Risiko- und CO2-Werte unabhängig von Fahrzeugtyp und Standort an',
    txt_global_std_shareyourredeemcode: 'Teile deinen Einlösecode',
    txt_global_std_p2ppleaseenteravalidpriceabovezero: 'Bitte geben Sie einen gültigen Preis über 0 . ein',
    txt_global_std_seedetails: 'Siehe Einzelheiten',
    txt_global_std_p2pnewmessage: 'Neue Nachricht',
    txt_global_std_thisisgonnabesofunletsgetstartedwithyourreallysmartdriving: ' Das wird so lustig! Beginnen wir mit Ihrem intelligenten Fahren. Ich werde da sein, um dich wirklich gut zu punkten.',
    txt_global_std_competitionformat: 'Wettbewerbsformat',
    txt_global_std_createanaccounttocollectandstoreyourresultsitsfree: 'Erstellen Sie ein Konto, um Ihre Ergebnisse zu sammeln und zu speichern. Es ist kostenlos!',
    txt_global_std_wrongpasswordpopuptext: 'Falsches Passwort',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimedecreasingspeedeffectiveobservationandleavingasafefollowingdistancecanhelpreduceunnecessaryspeedvariations: 'Auf dieser Art von Reise verbrachte der durchschnittliche Öko-Champion weniger Zeit damit, die Geschwindigkeit zu verringern. Effektives Beobachten und das Einhalten eines sicheren Sicherheitsabstands können dazu beitragen, unnötige Geschwindigkeitsschwankungen zu reduzieren.',
    txt_global_std_thereisnoinsurancetoterminate: 'Es besteht keine Kündigungsversicherung',
    txt_global_std_zipdriverriskmorerelevantthancreditscore: 'Zip-Driver-Risiko relevanter als Kreditwürdigkeit',
    txt_global_std_drivesafethismonth: 'Fahren Sie diesen Monat sicher',
    txt_global_std_p2pnoconfirmedbookingrequests: 'Keine bestätigten Buchungsanfragen',
    txt_global_std_vehicletype2: 'Fahrzeugtyp',
    txt_global_std_safetydrivingskillupwithwarikankinto: 'Fahrsicherheit verbessern mit Warikan KINTO',
    txt_global_std_notuploadeddrivingtime: 'Nicht hochgeladene Fahrzeit:',
    txt_global_std_p2pnopendingbookingrequests: 'Keine ausstehenden Buchungsanfragen',
    txt_global_std_youhavenofinishedchallengesyet: 'Sie haben noch keine fertigen Herausforderungen',
    txt_global_std_unabletosavesettings: 'Einstellungen können nicht gespeichert werden',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatuscheckouttheachievementsbelowyoucanearnbybeingaappnamesuperdriver: 'Erfolge helfen Ihnen, Punkte zu sammeln, um Ihren Loyalitätsstatus zu erlangen. Schauen Sie sich die folgenden Erfolge an, die Sie als {appname} Superfahrer erzielen können!',
    txt_global_std_p2pviewyourbookings: 'Zeigen Sie Ihre Buchungen an.',
    txt_global_std_defineaneventthatinitiatesapushnotification: 'Definieren Sie ein Ereignis, das eine Push-Benachrichtigung auslöst',
    txt_global_std_howdoiearnsavingsanswer: 'Einsparungen werden generiert, wenn Sie sicher fahren. Für jede Fahrt, die Sie sicher fahren, erzielen Sie eine Einsparung pro Kilometer. Je sicherer Sie fahren, desto höher ist die Ersparnis! Die Speicherung wird direkt nach Beendigung Ihrer Reise {appname} und in Ihrer Brieftasche {appname} .',
    txt_global_std_adwancedadmin: 'Erweiterter Administrator',
    txt_global_std_p2pmessages: 'Mitteilungen',
    txt_global_std_templatestabtext: 'Vorlagen',
    txt_global_std_takeapicture: 'Machen Sie ein Foto',
    txt_global_std_insuredvehicle: 'Versichertes Fahrzeug',
    txt_global_std_p2pitlookslikethisaccountisalreadyexist: 'Dieses Konto scheint bereits vorhanden zu sein',
    txt_global_std_p2pfaq23q: 'Ist es möglich, die Kostenaufteilung zu ändern?',
    txt_global_std_insurancecard: 'Versicherungskarte',
    txt_global_std_whatisanactivationcode: 'Was ist ein Aktivierungscode?',
    txt_global_std_showreasons: 'Gründe zeigen',
    txt_global_std_TomczykChamp: '16 Jahre DTM - 2011 DTM Meister ',
    txt_global_std_adduserstodifferentgroups: 'Fügen Sie Benutzer zu verschiedenen Gruppen hinzu.',
    txt_global_std_needstobeavalidphonenumbercanonlyincludenumbersand: 'Muss eine gültige Telefonnummer sein. Darf nur Ziffern und + enthalten',
    txt_global_std_typeofnotification: 'Art der Benachrichtigung',
    txt_global_std_datafailedtosent: 'Beim Versuch, Ihre Daten zu senden, ist ein Fehler aufgetreten. Stellen Sie sicher, dass Ihre E-Mail-Adresse korrekt ist, und versuchen Sie es erneut.',
    txt_global_std_daydreamingormindwandering: 'Tagträumen oder Gedankenwandern',
    txt_global_std_p2phasacceptedtherequest: 'hat die Anfrage angenommen.',
    txt_global_std_dashboard_groups: 'Gruppen',
    txt_global_std_estimatedpricebasedonyourperformance: 'Geschätzter Preis basierend auf Ihrer Leistung',
    txt_global_std_french: 'Französisch',
    txt_global_std_speedincrease: 'Geschwindigkeitssteigerung',
    txt_global_std_howtogetstartedwithzipdriverriskindex: 'Erste Schritte mit dem Zip-Treiber-Risikoindex',
    txt_global_std_dates: 'Termine',
    txt_global_std_p2pkeepthecommunicationgoingandchatwithyourfriendaboutyourbookings: 'Halten Sie die Kommunikation am Laufen und chatten Sie mit Ihrem Freund über Ihre Buchungen.',
    txt_global_std_alreadyhaveanaccount: 'Haben Sie schon ein Konto?',
    txt_global_std_PolicySchedule7: 'Jahr der Registrierung',
    txt_global_std_loyalty_upsell_pitch_bulletpoint1: 'Anreize für sicheres, umweltfreundliches Fahren schaffen',
    txt_global_std_yourveryownfiasdccompanychallenge: 'Ihre ganz eigene FIA SDC Company Challenge',
    txt_global_std_vs: 'vs',
    txt_global_std_overview2: 'Überblick',
    txt_global_std_p2pselectedmember: 'Ausgewähltes Mitglied',
    txt_global_std_p2premembertomarkthemaspaidonceyoureceivethepayment: 'Denken Sie daran, sie als bezahlt zu markieren, sobald Sie die Zahlung erhalten haben.',
    txt_global_std_thisistheworldsfirstchallengerewardingsmartskillsatthewheelgetyourcompanyinvolvedinsmartdriving: 'Dies ist die weltweit erste Herausforderung, bei der intelligente Fähigkeiten am Steuer belohnt werden. Engagieren Sie Ihr Unternehmen für intelligentes Fahren.',
    txt_global_std_privateforthoseinmygroupwiththesameemaildomainname: 'Privat (für diejenigen in meiner Gruppe mit demselben E-Mail-Domänennamen)',
    txt_global_std_noinsuranceregistered: 'Keine Versicherung angemeldet',
    txt_global_std_yourdroveatscoretypescoreorbetterawesomeyouhaveearnedpointspoints: 'Du bist mit {score} oder besser gefahren! Genial! Sie haben {points} Punkte verdient!',
    txt_global_std_subscribetoupdates_2: 'Abonnieren Sie Updates',
    txt_global_std_areyousureyouwanttodisablethecommunication: 'Möchten Sie die Kommunikation wirklich deaktivieren?',
    txt_global_std_odometer: 'Kilometerzähler',
    txt_global_std_acceptbluetooth: 'Akzeptiere Bluetooth',
    txt_global_std_yourinsurancehasbeenterminated: 'Ihre Versicherung wurde gekündigt!',
    txt_global_std_tosignwiththisaccountwejustneedsomecomplementaryinfoaboutyoubeforeyoucanstart: 'Um mit diesem Konto zu unterschreiben, benötigen wir nur einige ergänzende Informationen über Sie, bevor Sie beginnen können!',
    txt_global_std_MSIG_InsuranceLetter17: 'Überschuss:',
    txt_global_std_kintofaq4q: 'Wie nehme ich an der Kinto Challenge teil?',
    txt_global_std_MikkelsenMusic: 'Beim Fahren höre ich eigentlich jede Art von Musik, aber ich würde sagen, dass ich am meisten Pop und elektronische Musik höre. ',
    txt_global_std_createnewpushnotification: 'Neue Push-Benachrichtigung erstellen',
    txt_global_std_notethiscodeexpiresin6minutesbutyoucangenerateanotherbylogginginagain: 'Hinweis: Dieser Code läuft in 6 Minuten ab, aber Sie können einen neuen generieren, indem Sie sich erneut anmelden.',
    txt_global_std_filteroptionactive: 'Aktiv',
    txt_global_std_theconnectiontypeissaved: 'Der Verbindungstyp wird gespeichert.',
    txt_global_std_reasontoterminatetheinsurance: 'Grund, die Versicherung zu kündigen',
    txt_global_std_50000pts: '> 50 000 Pkt',
    txt_global_std_youraveragescoreis2: 'Ihre durchschnittliche Punktzahl ist {points}',
    txt_global_std_yourcarmodelistoooldanddoesnthavebluetoothnodot: 'Ihr Automodell ist zu alt und hat kein Bluetooth',
    txt_global_std_bonuspaymentday: 'Bonus Zahltag',
    txt_global_std_toregisteryourdrivingcorrectlyyoumustselectalways: 'Um Ihr Fahren korrekt zu registrieren, müssen Sie «Immer» auswählen.',
    txt_global_std_getthecarinsuranceyoudeservethesaferyoudrivethemoreyousave: 'Holen Sie sich die Autoversicherung, die Sie verdienen. Je sicherer Sie fahren, desto mehr sparen Sie.',
    txt_global_std_onboardingstatususeranalytics: 'Onboarding-Status / Benutzeranalyse',
    txt_global_std_userguides: 'Benutzerleitfäden',
    txt_global_std_logout: 'Ausloggen',
    txt_global_std_fromfromvalkmuptotovalkm: 'Von {fromVal} km bis zu {toVal} km.',
    txt_global_std_Fia_Receipt_Text10: 'Menge',
    txt_global_std_p2pexpenses: 'Kosten',
    txt_global_std_apitool: 'API-Tool',
    txt_global_std_zipriskindexinsuranceratingcomponent: 'Zip-Risikoindex - Versicherungsbewertungskomponente',
    txt_global_std_daydreamingisacommontypeofdriverdistractionyoumaythinkitsharmlessbutanythingthattakesyourmindoffthedrivingtaskincreasescrashriskchangingyourgazefrequentlycanhelpyoumaintainfocusontheenvironmentaroundyou: 'Tagträume sind eine häufige Art der Fahrerablenkung. Sie denken vielleicht, dass es harmlos ist, aber alles, was Sie von der Fahraufgabe ablenkt, erhöht das Unfallrisiko. Häufige Blickwechsel können Ihnen helfen, sich auf die Umgebung um Sie herum zu konzentrieren.',
    txt_global_std_qualified: 'Qualifiziert',
    txt_global_std_VilliersMileage: 'In Südafrika haben wir einige schöne Pässe. Der Franschhoek-Pass in meiner Nähe meines Zuhauses, ist einer meiner Favoriten.',
    txt_global_std_topimprovercomingsoon: 'Top-Verbesserer kommt bald',
    txt_global_std_savetranslation: 'Übersetzung speichern',
    txt_global_std_heresyour15discountcode: 'Hier ist Ihr 15 % Rabattcode',
    txt_global_std_p2ppushtitlenewpaymentquestion: 'New payment?',
    txt_global_std_edithistory: 'Verlauf bearbeiten',
    txt_global_std_numberbetweenxandyrule: 'Nummer muss zwischen {x} und {y} liegen',
    txt_global_std_areyouthenextworldchampion: 'Sind Sie der nächste Weltmeister?',
    txt_global_std_qhowdoesthescoringsystemwork: 'F: Wie funktioniert das Bewertungssystem?',
    txt_global_std_Fia_Receipt_Text11: 'Gesamt',
    txt_global_std_inorderfortheapptoworkproperlyweneedaccesstoyourphoneslocationaswellasexerciseandfitnessdata: 'Damit die App ordnungsgemäß funktioniert, benötigen wir Zugriff auf den Standort Ihres Telefons sowie auf Trainings- und Fitnessdaten',
    txt_global_std_drive: 'Fahren Sie',
    txt_global_std_technicaldrivingperformance: 'Technische Fahrleistung',
    txt_global_std_p2pnext: 'Nächster',
    txt_global_std_syncwithgarminconnect: 'Mit Garmin Connect™ synchronisieren',
    txt_global_std_HSolbergBesttip: 'Fahre entspannt, weniger bremsen = weniger Beschleunigungen, die Energie verbrauchen',
    txt_global_std_thiswindowwillclosein30sec: 'Dieses Fenster wird in {closeSeconds} Sekunden geschlossen',
    txt_global_std_policynumbercouldnotbeverified: 'Policennummer konnte nicht verifiziert werden.',
    txt_global_std_p2pfuel: 'Treibstoff',
    txt_global_std_p2pand: 'und',
    txt_global_std_apikey1: 'API-Schlüssel 1',
    txt_global_std_teamc02savings: 'Team c02 Einsparungen',
    txt_global_std_policynumber: 'Versicherungsnummer',
    txt_global_std_SulayemChamp: '14 mal Fia Middle Ost Rally Champion',
    txt_global_std_PSolbergFacts: '',
    txt_global_std_available: 'Verfügbar',
    txt_global_std_swipetoopenthecontrolcenterandmakesureyouhavebluetoothturnedon: 'Wischen Sie, um das Kontrollzentrum zu öffnen und sicherzustellen, dass Bluetooth aktiviert ist.',
    txt_global_std_nothankyou: 'Nein danke',
    txt_global_std_theinsurancedeleted: 'Die Versicherung gelöscht!',
    txt_global_std_thankyouforallowingustoaccessyourbluetooth: 'Vielen Dank, dass Sie uns den Zugriff auf Ihre Bluetooth-Einstellungen ermöglichen. Wir legen Wert auf Ihre Privatsphäre und geben diese Informationen nicht an Dritte weiter.',
    txt_global_std_challange: 'Herausforderung',
    txt_global_std_personaldetails2: 'Persönliche Details',
    txt_global_std_areyousurethatyouwanttocompletelyremoveyouraccount: 'Sind Sie sicher, dass Sie Ihr Konto, Fahrunterlagen und Versicherungsinformationen vollständig entfernen möchten?',
    txt_global_std_getstarsaftereachtripforhowgoodsafeandecofriendlyyourdrivewas: 'Nach jeder Fahrt erhalten Sie eine Punktzahl zwischen 0-100, je nachdem, wie sicher Sie gefahren sind. Hier finden Sie Ihre Gesamtpunktzahl.',
    txt_global_std_p2pgroupname: 'Gruppenname',
    txt_global_std_youonlyhave: 'Du hast nur',
    txt_global_std_uploadintervalsecondssec: '{uploadIntervalSeconds} Sek',
    txt_global_std_namesmayonlyincludeletters: 'Namen dürfen nur Buchstaben enthalten,',
    txt_global_std_HSolbergFacts: 'Solberg ist der ältere Bruder des FIA-Rallye-Weltmeisters 2003 Petter Solberg und durch seine schwedische Ehefrau Maud der Stiefvater des schwedischen Rallyefahrers Pontus Tidemand. Einer der erfahrensten Fahrer des Sports. Wenn er im Groove ist, hat er die Macht, die Jugendlichen in Verlegenheit zu bringen.',
    txt_global_std_qdoesagentacceptdriversthatarenotlicensedincountry: 'F: Akzeptiert {Agent} Fahrer, die in {Land} nicht zugelassen sind?',
    txt_global_std_p2ppushbodybookingcomingup: 'Bitte beachten Sie, dass die Buchung mit "{start}" beginnt.',
    txt_global_std_nameemailhasinvitedyoutojointheirgrouptostartdrivingsmarter: '{name} ({email}) hat Sie eingeladen, seiner {group} beizutreten, um intelligenter zu fahren.',
    txt_global_std_opensettingsinthephonelocationturnon: 'Öffnen Sie &quot;Einstellungen&quot; auf Ihrem Telefon. Gehen Sie zu Datenschutz, öffnen Sie den Ortungsdienst und schalten Sie Enerfy ein.',
    txt_global_std_timeinretardation: 'Verzögerungszeit',
    txt_global_std_loadingitems: 'Artikel laden',
    txt_global_std_interests: 'Interessen',
    txt_global_std_viamail: 'Via Mail',
    txt_global_std_TaxInvoice10: 'ALLE EINZELNEN POLITIKINHABER SIND VOR ABDECKUNG DER GEWÄHRLEISTUNG ZAHLUNGSFÄHIG',
    txt_global_std_MSIG_Onboarding2: 'Vielen Dank, dass Sie sich für MS First Capital Car Insurance entschieden haben. Sie haben die Versicherung für das Auto unterschrieben',
    txt_global_std_teamaveragescorebig: 'TEAM-DURCHSCHNITTSPUNKT\n',
    txt_global_std_notspecified: 'Keine Angabe',
    txt_global_std_apitool_upsell_pitch_bulletpoint3: 'Einfache, aufwandsarme Integration von Daten zur Steigerung der Unternehmenseffizienz',
    txt_global_std_p2pfinishquestion: 'Fertig?',
    txt_global_std_p2pwrongemailorpassword: 'Falsche Email oder Passwort!',
    txt_global_std_ayesagentacceptsdriverswhoarelicensedinothercountries: 'A: Ja, {Agent} akzeptiert Fahrer, die in anderen Ländern zugelassen sind.',
    txt_global_std_enterinvitationcode: 'Einladungscode eingeben',
    txt_global_std_thiswonttakemorethanaminuteyoullbeintheworkspaceonceitsready: 'Dies dauert nicht länger als eine Minute, Sie befinden sich im Arbeitsbereich, sobald er fertig ist.',
    txt_global_std_f_lanzbesttip: 'Schauen Sie immer nach vorne, suchen Sie den Horizont nach möglichen Gefahren ab. Genießen Sie gleichzeitig die Aussicht.',
    txt_global_std_exportdata: 'Daten exportieren',
    txt_global_std_savingsisgeneratedifriskislowerthanclaims: 'Einsparungen werden generiert, wenn das Risiko geringer ist als die berechneten Ansprüche',
    txt_global_std_qualifiedto2021finals: 'Qualifiziert für das Finale 2021',
    txt_global_std_documentthedamage: 'Dokumentieren Sie den Schaden',
    txt_global_std_riskportfoliotracker: 'Risikoportfolio-Tracker',
    'txt_global_std_Al-KhelaifiFacts': '',
    txt_global_std_from: 'Aus',
    txt_global_std_callroadsideassistance: 'Pannenhilfe anrufen',
    txt_global_std_p2ppushtitlehasacceptedthebooking: 'Buchung möglich',
    txt_global_std_loyalty: 'Loyalität',
    txt_global_std_selectcountry: 'Land auswählen',
    txt_global_std_HansenBesttip: 'Für mich gibt es zwei entscheidende Schlüsselfaktoren für intelligentes Fahren. Der Erste: Schauen Sie nach, was um Sie herum passiert. Der Zweite: Lernen Sie Ihr Auto kennen, wie es auf Ihre Eingaben von Gas, Bremse und Motor reagiert. Die Kombination dieser beiden entscheidenden Faktoren wird Sie in diesem Wettbewerb wirklich weit bringen.',
    txt_global_std_therulesforthecompanychallengeareasfollowingbrtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsbrdriveminimum1kmduringaheatinordertoqualifyforthechallengebrthesamegeographicalroutewillbescoredamaximumof10timesinoneday1: 'Die Regeln für die Firmen-Challenge lauten wie folgt:\n' +
      '<br>• Die Punktzahl der einzelnen Fahrer wird auf der Grundlage der besten 10 % der Fahrten als Durchschnittswert berechnet.\n' +
      '<br>• Fahren Sie während eines Laufs mindestens 1 km, um sich für die Herausforderung zu qualifizieren.\n' +
      '<br>• Dieselbe geografische Route wird maximal 10 Mal an einem Tag gewertet.',
    txt_global_std_addinjuredperson: 'Verletzte hinzufügen',
    txt_global_std_okaywewillendthetour: 'Okay, wir werden die Tour beenden.',
    txt_global_std_p2pexpensessummary: 'Kostenübersicht',
    txt_global_std_PolicySchedule28: 'SGD1,000 ALL FORDERUNGEN WERDEN AN FAHRER GESETZT, DIE DEN DONGLE MINDESTENS 14 TAGE IN EINEM KALENDERJAHR DEAKTIVIERT HABEN.',
    txt_global_std_insurancecost: 'Versicherungskosten',
    txt_global_std_couldnotretrievedrivedata: 'Reisedaten konnten nicht abgerufen werden',
    txt_global_std_loyaltypointsgainedfor: 'Für die Reservierung gewonnene Treuepunkte',
    txt_global_std_youversususername: 'Sie gegen {user}',
    txt_global_std_splittrips: '  ',
    txt_global_std_topecodrivers: 'Top Öko-Fahrer',
    txt_global_std_speedchangeanalysis: 'Geschwindigkeitsanalyse',
    txt_global_std_ongoinggames: 'Aktuelle Ereignisse',
    txt_global_std_totalpointsthisweek: 'Gesamtpunktzahl in dieser Woche:',
    txt_global_std_statusnondriving: 'Nicht fahren',
    txt_global_std_unabletosavenote: 'Notiz kann nicht gespeichert werden',
    txt_global_std_schedule: 'Zeitlicher Ablauf',
    txt_global_std_deleteapikey: 'API-Schlüssel löschen',
    txt_global_std_leaderboard: 'Rangliste',
    txt_global_std_cherry: 'Kirsche!',
    txt_global_std_p2pendbeforestart: 'Bitte stellen Sie sicher, dass die Endzeit nicht vor der Startzeit liegt!',
    txt_global_std_verifyyourphonenumber: 'Bestätige deine Telefonnummer',
    txt_global_std_monthshorthand: 'Monate',
    txt_global_std_youarenowconnectedandready: 'Sie sind jetzt verbunden und können Ihre erste Fahrt starten!',
    txt_global_std_youearnedthisbadgextimes: 'Du hast dieses Abzeichen {x} Mal verdient',
    txt_global_std_p2pconfirmprice: 'Preis bestätigen',
    txt_global_std_Fia_Receipt_Text14: 'Versand und Mehrwertsteuer (6%) sind im Preis inbegriffen.',
    txt_global_std_p2pincludesafedriverbonus: 'Safedriver Bonus einschließen',
    txt_global_std_isthisanelectriccar: 'Ist das ein Elektroauto?',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint2: 'Sofortige Identifizierung von Fahrern mit hohem und niedrigem Risiko',
    txt_global_std_MSIG_Onboarding5: 'Unsere Kunden bedeuten uns alles und wir freuen uns, Sie an Bord zu haben!',
    txt_global_std_signinwithfacebook: 'Mit Facebook anmelden',
    txt_global_std_zipdriver_upsell: 'Zip-Driver – Bestimmen Sie das genaue Risikoniveau basierend auf dem Fahrort.',
    txt_global_std_month: 'Monat',
    txt_global_std_uploadcustombadgeicon: 'Laden Sie ein benutzerdefiniertes Badge-Symbol hoch',
    txt_global_std_PolicySchedule30: 'Die Windschutzscheibenabdeckung unter dem Vermerk Nr. 89C ist unbegrenzt.',
    txt_global_std_removeaccount: 'Konto entfernen',
    txt_global_std_verifyphonenumber: 'Überprüfen Sie die Telefonnummer mit dem Sicherheitscode',
    txt_global_std_deletegroup2: 'Gruppe löschen',
    txt_global_std_p2prepair: 'Reparatur',
    txt_global_std_repetition: 'Wiederholung',
    txt_global_std_requestinsuranceactivationcodebysms: 'Hi! Sie haben einen neuen Aktivierungscode für Ihr {appname}-Konto angefordert: {activationcode} Grüße {appname} gang in {companyshort}.',
    txt_global_std_SignupFia_Text6: 'Die Anmeldung wird in Kürze geöffnet.',
    txt_global_std_planningtips: 'Ein Blick nach vorne (und nicht nur direkt vor Ihnen) führt normalerweise zu einem ruhigeren Fahren und einer höheren Wahrscheinlichkeit, Hindernisse rechtzeitig zu entdecken.\n' +
      '\n' +
      'Sorgen Sie für eine angenehme Fahrt für Sie und Ihre Passagiere.',
    txt_global_std_riskindex_upsell_pitch_bulletpoint1: 'Einzigartige Einblicke in Risikotrends für Fahrer auf der ganzen Welt',
    txt_global_std_endaddress: 'Zieladresse',
    txt_global_std_daysremaininginthisperiod: '{days} Tage verbleiben in diesem Zeitraum',
    txt_global_std_p2pcoderesent: 'Code erneut gesendet',
    txt_global_std_didafriendridewithyou: 'Ist ein Freund mit dir gefahren?',
    txt_global_std_TomczykMileage: 'Der Weg zum Familienurlaub ist allen voran eine meiner Lieblingsstrecken.',
    txt_global_std_activationcode: 'Aktivierungscode',
    txt_global_std_recruitfriendsandgetfreeheats: 'Freunde werben und kostenlos Heats bekommen!',
    txt_global_std_areyoureadytodrive: 'Fahrbereit?',
    txt_global_std_ringsosphoneno: 'SOS klingeln: {phoneNo}',
    txt_global_std_insuranceactivationkeyalreadycreated: 'Der Aktivierungsschlüssel für die Versicherung wurde bereits erstellt',
    txt_global_std_p2pconnecttoyourfriendscarwhenyourbookingstartsyouwillgetfeedbackandinsightsonyourdriving: 'Verbinden Sie sich mit dem Auto Ihres Freundes, wenn Ihre Buchung beginnt. Sie erhalten Feedback und Einblicke in Ihre Fahrweise.',
    txt_global_std_trafficdelaysdeadlinesandrunninglatetheycanallcausestresswhichisnegativeforyourhealthandsafetyallowingextratimeplanningyourrouteandcheckingtravelupdatescanhelptoreducestressandimproveyourfocusnew: 'Verkehrsverzögerungen, Fristen und Verspätungen; Sie alle können Stress verursachen, was sich negativ auf Ihre Gesundheit UND Sicherheit auswirkt. Wenn Sie zusätzliche Zeit einplanen, Ihre Route planen und Reiseaktualisierungen überprüfen, können Sie Stress abbauen und Ihre Konzentration verbessern.',
    txt_global_std_typicallyyouseeadigitalscreendisplayingtworowsofnumbersoneforthecarstotaldistancetravelledandoneforthedailydistances: 'Normalerweise sehen Sie einen digitalen Bildschirm, der zwei Zahlenreihen anzeigt, eine für die gefahrene Gesamtstrecke des Fahrzeugs und eine für die täglichen Entfernungen.',
    txt_global_std_TaxInvoice7: 'ALLE UNTERNEHMENSPOLITIKINHABER UNTERLIEGEN FÜR VERSICHERUNGSZEITEN VON MEHR ALS 60 TAGEN EINER PREMIUM-ZAHLUNGSGARANTIE',
    txt_global_std_geyouwillreceiveanemailwithyourpersonaldata: 'Sie erhalten eine E-Mail mit Ihren persönlichen Daten',
    txt_global_std_codehasbeensentto: 'Der Code wurde Ihnen gesendet',
    txt_global_std_theenerfyglobalteam: 'Das EnerfyGlobal-Team',
    txt_global_std_p2pstartadrivetoaccessinsightsandstatisticsaboutyourdriving: 'Starten Sie eine Fahrt, um auf Erkenntnisse und Statistiken über Ihre Fahrt zuzugreifen.',
    txt_global_std_cimage17d: 'Planen und seien Sie aktiv, wenn Sie auf der Autobahn sind. Machen Sie sich bewusst, wie Sie längere Strecken verbessern können.',
    txt_global_std_removemissingtrip: 'Fahrt entfernen',
    txt_global_std_howdoyoumeasuremydrivinglexusanswer: 'Die Lexus-App verwendet künstliche Intelligenz, um Ihr Fahren zu messen. Die App stellt eine Verbindung zur Cloud her und analysiert jede Sekunde Ihres Fahrens - verwandelt in ein „Profil“. Die KI vergleicht Ihre Profile mit über 750 Millionen anderen einzigartigen Fahrprofilen. Die KI verfügt über mehr als 16 Jahre Erfahrung, um die Wahrscheinlichkeit eines Fahrmusters für einen Unfall zu ermitteln. Glücklicherweise kehren Fahrer und Passagiere nach fast jeder Reise sicher zu ihren Lieben nach Hause zurück.',
    txt_global_std_notsignedupforacovercubeinsuranceyet: 'Noch keine Covercube-Versicherung abgeschlossen?',
    txt_global_std_iwilldoitlater: 'ich werde es später tun',
    txt_global_std_sothedevicecanconnectyourphonewithyourcar: 'So kann das Gerät Ihr Telefon mit Ihrem Auto verbinden.',
    txt_global_std_WelcomeLetter_Text2: 'Hier finden Sie alle Ihre Versicherungsdokumente, einschließlich des Versicherungsscheins sowie Vorverkaufsinformationen. Die vollständigen Versicherungsbedingungen finden Sie in der App {appName} und unter www.enerfy.se. Wenn etwas in den beigefügten Dokumenten nicht korrekt ist, kontaktieren Sie uns bitte, damit wir es korrigieren können.',
    txt_global_std_markermovediscount: 'Bewegen Sie den Marker, um zu sehen, wie sich Ihr Level auf Ihren Rabatt auswirkt',
    txt_global_std_achachievements: 'Erfolge',
    txt_global_std_skip: 'Überspringen',
    txt_global_std_wetakeeverystepandmeasuretominimiseourco2footprintenergyconsumptionandroadaccidentsbyinspiringourdriverstosmarterdrivingandtrackingourperformance: 'Wir unternehmen alle Schritte und Maßnahmen, um unseren CO2-Fußabdruck, Energieverbrauch und Verkehrsunfälle zu minimieren, indem wir unsere Fahrer zu intelligenterem Fahren inspirieren und unsere Leistung verfolgen',
    txt_global_std_p2pfaq11a: '1.Drücken Sie das Menü mit den drei Punkten.\n' +
      '2.Gehen Sie zu Ihrer Freundeseite.\n' +
      '3.Drücken Sie die Schaltfläche Freunde einladen.\n' +
      '4.Senden Sie einen Einladungslink an Ihren Freund. Wenn Ihr Freund die App über den Link herunterlädt, wird er in Ihrer Freundesliste angezeigt.',
    txt_global_std_passwordsdonotmatch: 'Passwörter stimmen nicht überein',
    txt_global_std_sendthemalink: 'Senden Sie ihnen einen Link, damit sie die App verwenden können.',
    txt_global_std_howdoirenewmyinsurance: 'Wie verlängere ich meine Versicherung?',
    txt_global_std_35000pts2: '&#60;35 000 Pkt',
    txt_global_std_p2pselectcar: 'Auto auswählen',
    txt_global_std_notconnectedtoenerfycloud: 'Keine Verbindung zur Cloud {AppName}.',
    txt_global_std_dashboard_challenges: 'Herausforderungen',
    txt_global_std_exportcsv: 'CSV exportieren',
    txt_global_std_downloadingdrivers: 'Treiber herunterladen',
    txt_global_std_invalidemail: 'Ungültige E-Mail',
    txt_global_std_succesfullyactivatedinsurance: 'Versicherung erfolgreich aktiviert',
    txt_global_std_hello123: 'Hallo',
    txt_global_std_usernotfoundinteam: 'Benutzer nicht im Team gefunden.',
    txt_global_std_notifications: 'Benachrichtigungen ',
    txt_global_std_summaryofdistnacedivenperslectedperiod: 'Zusammenfassung der zurückgelegten Distanzen pro ausgewähltem Zeitraum.',
    txt_global_std_PolicySchedule15: 'Die Richtlinie unterliegt Vermerken / Klauseln',
    txt_global_std_contactsupport: 'Kontaktieren Sie Support',
    txt_global_std_iconheader: 'Entsperrt-Symbol',
    txt_global_std_userlockedout: 'Der Benutzer ist derzeit für zu viele fehlgeschlagene Anmeldeversuche gesperrt.',
    txt_global_std_kintosmsandemailtext: 'Fahren Sie mit mir sicherer! Folgen Sie dem Link unten und fahren Sie mit Kinto in 10 km Entfernung.',
    txt_global_std_since: 'Seit',
    txt_global_std_unrestrictedinvitelink: 'Uneingeschränkter Einladungslink',
    txt_global_std_alertbreaktimetext: 'Restalarm ausgelöst.',
    txt_global_std_areyoudrivinganelectriccar: 'Fahren Sie ein Elektroauto?',
    txt_global_std_mycardoesnthavebluetoothanswer: 'Kein Problem! Fordern Sie einen BT-OBD-Dongle an und er wird die Magie machen! Schließen Sie einfach den Dongle an das Kabel an und stecken Sie ihn in Ihr 12-V-Ladegerät im Auto. Befolgen Sie dann wie gewohnt die Schritte „Mit Auto verbinden“ und wählen Sie während der Bluetooth-Verbindung den Namen „Enerfy OBD“.',
    txt_global_std_firsttimeinapp: 'Zum ersten Mal in App',
    txt_global_std_mutualfriends: 'Gemeinsame Freunde',
    txt_global_std_p2pdeletemessage: 'Nachricht löschen',
    txt_global_std_improvetext: 'Ausflüge mit Bonus in Folge',
    txt_global_std_marklundDescription: '',
    txt_global_std_changeyournumber: 'Ändern Sie Ihre Nummer',
    txt_global_std_error: 'Error',
    txt_global_std_drivingtechnique: 'Fahrtechnik',
    txt_global_std_deleteachievementpopuptext: 'Ihr Erfolg wird endgültig gelöscht. Der Fortschritt Ihres Benutzers geht verloren. Diejenigen, die den Erfolg bereits erhalten haben, können ihn weiterhin sehen.',
    txt_global_std_challengeinvitesent: 'Challenge-Einladung gesendet',
    txt_global_std_bycheckingthischeckboxandlogginginyoureacceptingour: 'Indem Sie dieses Kontrollkästchen aktivieren und sich anmelden, akzeptieren Sie unsere',
    txt_global_std_startdriving: 'Fahren Sie los',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithagentwithalapseincoverage: 'Sie haben ab dem Ablaufdatum {days} Tage Zeit, um eine Zahlung zu leisten und Ihre Police bei {agent} mit Erlöschen des Versicherungsschutzes fortzusetzen.',
    txt_global_std_thechaser: 'Der Verfolger',
    txt_global_std_pickyourcoverimage: 'Wählen Sie Ihr Titelbild aus',
    txt_global_std_tobeabletosendmessagesweneedtoaddyourfriendsletsinvitethem: 'Um Nachrichten senden zu können, müssen wir Ihre Freunde hinzufügen. Laden wir sie ein!',
    txt_global_std_pleasechoosewhichtypeofpictures: 'Bitte wählen Sie die Art der Bilder',
    txt_global_std_demotext5: 'Sehen Sie Ihren Glücksbonus hier.',
    txt_global_std_p2pverifyphonenumber: 'Telefonnummer bestätigen',
    txt_global_std_yourlinkshouldbeavailableinyourinboxin5minutespleaseremembertocheckyourjunk: 'Ihr Link sollte in 5 Minuten in Ihrem Posteingang verfügbar sein. Bitte denken Sie daran, Ihren Müll zu überprüfen.',
    txt_global_std_startyourcar: 'Starten Sie Ihr Auto.',
    txt_global_std_manydriversrelytooheavilyoncruisecontrolitiscomfortableyesbutlethalforfueleconomyinmanycasesusingcruisecontrolontheslightestuphillordownhillwillcausetheenginetofighthardtosatisfyyourwishofmaintainingtheexactspeedyousetinsteadmanagingyourspeedwithyourfootandaimingforasmoothridewillhelpyousaveahugeamountonfuel: 'Viele Autofahrer verlassen sich zu sehr auf den Tempomat. Es ist bequem, ja, aber in vielen Fällen tödlich für den Kraftstoffverbrauch. Die Verwendung des Tempomats bei der geringsten Steigung oder Gefälle führt dazu, dass der Motor hart kämpft, um Ihren Wunsch zu erfüllen, die von Ihnen eingestellte Geschwindigkeit exakt beizubehalten. Wenn Sie stattdessen Ihre Geschwindigkeit mit Ihrem Fuß steuern und auf eine reibungslose Fahrt abzielen, können Sie eine Menge Kraftstoff sparen!',
    txt_global_std_bethefirsttoreceivenews: 'Seien Sie der Erste, der die Neuigkeiten erhält',
    txt_global_std_p2pitstimetosplityourcostformonthofph: 'Es ist an der Zeit, Ihre Kosten für den Monat {month} aufzuteilen',
    txt_global_std_enterzipcode: 'Bitte Postleitzahl eingeben',
    txt_global_std_top3bestdrives: 'Top 3 der besten Laufwerke',
    txt_global_std_KristofferssonMusic: 'Ich höre gerne Godsmack.',
    txt_global_std_contactinfogreaterthaneuformoreinformationonhowtoactivatethismodule: 'Wenden Sie sich an info@greatthan.eu, um weitere Informationen zur Aktivierung dieses Moduls zu erhalten',
    txt_global_std_startguidetip3: 'Klicken Sie auf die Play-Schaltfläche in der unteren Bildschirmmitte.',
    txt_global_std_carsagreementsinsurance: 'Autos & Verträge / Versicherungen',
    txt_global_std_enteravalidchassisnumber: 'Geben Sie eine gültige Fahrgestellnummer ein.',
    txt_global_std_p2pyoudonthaveanycostsplitsorinvoicesyet: 'Sie haben noch keine Kostenaufteilungen oder Rechnungen.',
    txt_global_std_p2pacceptmarketingandprivacy: 'Akzeptieren Sie die Datenschutzbestimmungen und melden Sie sich für den Newsletter an.',
    txt_global_std_p2pselectfriendsoragroup: 'Wählen Sie Freunde oder eine Gruppe aus',
    txt_global_std_cimage4h: 'Planen Sie egelmäßige Pausen ein',
    txt_global_std_notconnectednoaddress: 'Nicht verbunden - keine Adresse',
    txt_global_std_savingsisbasedonrisklevelanddistance: 'Das Sparen basiert auf dem Risikograd und der Entfernung',
    txt_global_std_searchfriends: 'Freunden suchen',
    txt_global_std_p2pnofriendsfoundpleaseinvitefriend: 'Keine Freunde gefunden, bitte Freund einladen',
    txt_global_std_insureddistance_used_100__1: 'Sie haben jetzt Ihren Kilometerstand verwendet. Um eine vollständige Kompensation zu erhalten, ist es wichtig, dass Sie die Anzahl der Kilometer nicht überschreiten. Bitte nehmen Sie hier Kontakt auf oder passen Sie die Laufleistung an.',
    txt_global_std_p2psendrequest: 'Anfrage senden',
    txt_global_std_usersupportcenter: 'Benutzer-Support-Center',
    txt_global_std_drivingstartandstopsettings: 'Start- und Stoppeinstellungen für das Fahren',
    txt_global_std_aaninsuranceadjusterwillcontactyouwithin24hoursofyourclaimbeingsubmittedtheentireclaimsprocesswilldependonthecomplexityoftheclaim: 'A: Ein Versicherungssachverständiger wird sich innerhalb von 24 Stunden nach Einreichung Ihres Antrags mit Ihnen in Verbindung setzen. Der gesamte Anspruchsprozess hängt von der Komplexität des Anspruchs ab.',
    txt_global_std_signedinas: 'Angemeldet als ',
    txt_global_std_pleaseenablebluetoothlocationservicesandmotionfitness: 'Bitte aktivieren Sie Bluetooth, Ortungsdienste und Motion & Fitness.',
    txt_global_std_createanaccounttostartyour30dayfreetrial: 'Erstellen Sie ein Konto, um Ihre 30-tägige kostenlose Testversion zu starten.',
    txt_global_std_members3: 'Mitglieder',
    txt_global_std_yourchassisshouldcontainacombinationofnumbersandletters: 'Ihr Chassis sollte eine Kombination aus Zahlen und Buchstaben enthalten.',
    txt_global_std_directtoapp: 'Direkt zur App',
    txt_global_std_cimage10d: 'Fahren Sie im höchsten Gang wie möglich und halten Sie die Drehzahl niedrig.',
    txt_global_std_TidemandInterests: 'Wassersport, Freunde und Motorsport',
    txt_global_std_firsttimeusingtheserviceregisterfromsignuplink: 'Verwenden Sie den Dienst zum ersten Mal? Registrieren Sie sich über {signUpLink}',
    txt_global_std_youhavedeniedthelocationpermission: 'Sie haben die Standorterlaubnis verweigert',
    txt_global_std_somethingwhentwrongtryagain: 'Etwas ging schief, versuchen Sie es erneut.',
    txt_global_std_yourreporthasbeensentsuccessfully: 'Ihr Bericht wurde erfolgreich versendet!',
    txt_global_std_fromtheleftsidetoolbarhoverorclickontheiconstonavigatethroughtheportaltoitsdifferentcomponentsandpages: 'Bewegen Sie den Mauszeiger oder klicken Sie in der Symbolleiste auf der linken Seite auf die Symbole, um durch das Portal zu den verschiedenen Komponenten und Seiten zu navigieren.',
    txt_global_std_october: 'Oktober',
    'txt_global_std_drivedetailshavebeensentto{email}': 'Reisedetails wurden an {E-Mail} gesendet.',
    txt_global_std_payouts: 'Auszahlungen',
    txt_global_std_choosesetup: 'Wählen Sie Einrichtung',
    txt_global_std_picturereceived: 'Bild erhalten',
    txt_global_std_oliversolbergDescription: 'Sohn von Petter Solberg. Er fährt das Auto seines Vaters für das nordische Team.',
    'txt_global_std_H.SolbergDescription': 'Solberg ist der ältere Bruder des FIA-Rallye-Weltmeisters 2003 Petter Solberg und durch seine schwedische Ehefrau Maud der Stiefvater des schwedischen Rallyefahrers Pontus Tidemand. Einer der erfahrensten Fahrer des Sports. Wenn er im Groove ist, hat er die Macht, die Jugendlichen in Verlegenheit zu bringen.',
    txt_global_std_cimage32h: 'Jede Beschleunigung kostet Geld',
    txt_global_std_tryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagain: '• Versuchen Sie, die Verbindung Ihres Autos zu Ihrem Telefon zurückzusetzen, indem Sie sie entfernen und dann erneut hinzufügen.',
    txt_global_std_whendrivingonmotorwaystoomanydriverspumptheacceleratorpedalupanddowninaneverendingpattern100kmhto110kmhthen110kmhto100kmhandsoonthistypeofdrivingusesunnecessaryfuelkeepingasafesuitablefollowingdistancecanhelpavoidthetemptationtovaryspeedunnecessarily: 'Beim Fahren auf Autobahnen drücken zu viele Autofahrer das Gaspedal in einem endlosen Muster auf und ab: 100 km/h auf 110 km/h, dann 110 km/h auf 100 km/h und so weiter! Diese Art des Fahrens verbraucht unnötig Kraftstoff. Das Einhalten eines sicheren, angemessenen Abstands kann dazu beitragen, der Versuchung zu widerstehen, die Geschwindigkeit unnötig zu variieren.',
    txt_global_std_reward: 'Belohnung',
    txt_global_std_changeyourcontactdetails: 'Ändern Sie Ihre Kontaktdaten',
    txt_global_std_cimage3d: 'Versuchen Sie, Ereignisse zu vermeiden',
    txt_global_std_thepumper: 'Der Pumper',
    txt_global_std_hereisthetestkey: 'Hier ist der Testschlüssel',
    txt_global_std_headers: 'Überschriften',
    txt_global_std_3selectyourcarinthelist: '3. Wählen Sie Ihr Auto in der Liste aus:\n',
    txt_global_std_textaboutwhentranslationswillbevisibleinappetc: 'Text darüber, wann Übersetzungen in der App sichtbar sein werden usw.',
    txt_global_std_useruploadtemplate: 'Benutzer-Upload-Vorlage',
    txt_global_std_joinwithactivationcode: 'Melden Sie sich mit dem Aktivierungscode an',
    txt_global_std_communityteams: 'Community-Teams',
    txt_global_std_resendemail: 'E-Mail zurücksenden',
    txt_global_std_headerpassword: 'Passwort',
    txt_global_std_f_lanzwhyjoin: 'Lassen Sie uns gleichzeitig die Herausforderungen kennenlernen, denen wir täglich auf den Straßen begegnen.\n' +
      'Lernen mit Spaß führt immer zu besseren Ergebnissen',
    txt_global_std_OgierFacts: "Er ist der derzeitige Inhaber der World Rally Drivers 'Championship, nachdem er den Titel sechs Mal in den Jahren 2013, 2014, 2015, 2016, 2017 und 2018 gewonnen hat Zweit erfolgreichster WRC-Pilot nach dem ehemaligen Citroën WRC-Teamkollegen Sébastien Loeb (neun Titel).",
    txt_global_Fia_Receipt_Text1: 'Your purchase is completed!',
    txt_global_std_teamranking: 'Teamwertung',
    txt_global_std_p2pawaitingapproval: 'Warten auf Genehmigung',
    txt_global_std_MSIG_InsuranceLetter35: 'MS First Capital Insurance Limited, 6 Raffles Quay Nr. 21-00 Singapur 048580 Tel .: (65) 6222 2311 Fax: (65) 6222 3547 www.msfirstcapital.com.sg',
    txt_global_std_exclude: 'Ausschließen',
    txt_global_std_sendinvite: 'Sende Einladung',
    txt_global_std_milesmi: 'Meilen (mi)',
    txt_global_std_finalistsheat1: 'Finalisten - Heat 1',
    txt_global_std_whenwouldyouliketoterminateyourinsurance2: 'Wann möchten Sie Ihre Versicherung kündigen?',
    txt_global_std_fixitnow: "Reparier's jetzt",
    txt_global_std_p2ppushbodyaddedexpences: 'Überprüfen Sie die Kosten bei der Buchung. Von: {bookingperiod}',
    txt_global_std_theloyaltysystem: 'Das Treue-System',
    txt_global_std_entertheemailthatyouusedwhenyouregisteredyourinsurance: 'Geben Sie die E-Mail-Adresse ein, die Sie bei der Registrierung Ihrer Versicherung verwendet haben',
    txt_global_std_choosealwaysorallowwhileusing: 'Wählen Sie "Immer" oder "Bei Verwendung zulassen".',
    txt_global_std_gpsactive: 'GPS aktiv',
    txt_global_std_discardchanges: 'Änderungen verwerfen',
    txt_global_std_theuseriscurrentlylockedoutfortoomanyfailedattempts: 'Der Benutzer ist derzeit für zu viele Fehlversuche gesperrt',
    txt_global_std_levelprogress: 'Level-Fortschritt',
    txt_global_std_MSIG_InsuranceLetter23: '* Vorausgesetzt, dass die Person, die das Kraftfahrzeug fährt, gemäß den Zulassungs- oder anderen Gesetzen oder Vorschriften zum Führen des Kraftfahrzeugs zugelassen ist oder dies zugelassen wurde und nicht aufgrund eines Gerichtsbeschlusses oder einer Verordnung in diesem Namen disqualifiziert wird vom Fahren des Kraftfahrzeugs.',
    txt_global_std_accidentguide: 'Unfallhandbuch',
    txt_global_std_p2plendyourkintotofamilyandfriends: 'Leihen Sie Ihre Familie und Freunde',
    txt_global_std_BeforeFirstBill_Text1Enerfy: 'In Kürze werden wir Ihre Kreditkarte mit den Kosten für Ihre Autoversicherung belasten. Wir haben die häufigsten Fragen zusammengestellt, die unsere Kunden haben, wenn sie ihre erste Rechnung erhalten.',
    txt_global_std_yourdrivewillendin: 'Ihre Fahrt wird automatisch enden in:',
    txt_global_std_howdoyoumeasuremydrivinganswer: '{appname} verwendet künstliche Intelligenz, um Ihr Fahren zu messen. Die App stellt eine Verbindung zur Cloud her und analysiert jede Sekunde Ihres Fahrens - verwandelt in ein „Profil“. Die KI vergleicht Ihre Profile mit über 750 Millionen anderen einzigartigen Fahrprofilen. Die KI verfügt über mehr als 16 Jahre Erfahrung, um die Wahrscheinlichkeit eines Fahrmusters für einen Unfall zu ermitteln. Glücklicherweise kehren Fahrer und Passagiere nach fast jeder Reise sicher zu ihren Lieben nach Hause zurück.',
    txt_global_std_p2ptemppassword: 'Temporäres Passwort',
    txt_global_std_roadsideassistance: 'Pannenhilfe',
    txt_global_std_signupforinsurance: 'Melden Sie sich für eine Versicherung an',
    txt_global_std_100pointtripachievementlocked: '{scoretype} {score} eine Fahrt mit {scoretype} {score} oder besser, um diesen Erfolg freizuschalten und 100 Punkte zu verdienen.',
    txt_global_std_keepingyourmindandeyesonwhatshappeningaroundyouisvitalforyoursafetyandthesafetyofothersphoneactivityandotherdistractionsaffectyourfocusandincreasecrashrisknew: 'Die Beibehaltung der beabsichtigten Geschwindigkeit ist äußerst wichtig für den Kraftstoffverbrauch. Gedankenschweifen und mangelnde Konzentration verursachen oft Geschwindigkeitsschwankungen nach oben und unten, was zu unnötigen Beschleunigungen führt und somit den Kraftstoffverbrauch erhöht.',
    txt_global_std_baseurl: 'Basis-URL',
    txt_global_std_SignupFia_Text1: 'Sie sind angemeldet!',
    txt_global_std_leavechallenge: 'Verlasse die Challenge',
    txt_global_std_accountcreatedlogin: 'Ihr Konto wurde erstellt, Sie können sich jetzt anmelden!',
    txt_global_std_ihavesoldmycar2: 'Ich habe mein Auto verkauft',
    txt_global_std_hi: 'Hi!',
    txt_global_std_photo: 'Foto',
    txt_global_std_TaxInvoice9: 'Der Versicherer hat Anspruch auf eine anteilige Prämienzahlung für die 60-Tage-Deckung (mindestens 25 S $).',
    txt_global_std_obdconnected: 'OBD angeschlossen',
    txt_global_std_nodatatoshow_loyalty: 'Keine Daten zum Anzeigen',
    txt_global_std_ifyoudidntmakethisrequestyoucansafelyignorethisemail: 'Wenn Sie diese Anfrage nicht gestellt haben, können Sie diese E-Mail ignorieren.',
    txt_global_std_activationcodeemail1: 'Ihr Aktivierungscode',
    txt_global_std_p2ppushtitlechangeperiodfromto: 'Zeitraum geändert',
    txt_global_std_minutesshort: 'm',
    txt_global_std_youhavenowreceivedamountyenatcompany: 'Sie haben jetzt {amount} Yen bei {company} erhalten',
    txt_global_std_chooseafile: 'Wählen Sie eine Datei',
    txt_global_std_alertlocationnotalways: 'Set Location Service permission to Always to register drives without data loss.',
    txt_global_std_nodriversonleaderboard: 'Derzeit sind keine Treiber in der Bestenliste',
    txt_global_std_nextstep: 'Nächster',
    txt_global_std_registrationnumberalreadyexists: 'Registrierungsnummer existiert bereits',
    txt_global_std_typeofmessage: 'Art der Nachricht',
    txt_global_std_birthdate: 'Geburtsdatum',
    txt_global_std_badgeisworthxpointsunlocked: 'Das Abzeichen ist {points} Punkte wert',
    txt_global_std_realtimefeedbackonthego: 'Echtzeit-Feedback für unterwegs.',
    txt_global_std_youarereadytogodriveandearnyourfirstbonus: 'Du bist bereit zu gehen! Fahren Sie und verdienen Sie Ihren ersten Bonus :)',
    txt_global_std_Fia_Receipt_Text13: '',
    txt_global_std_inactive: 'Inaktiv',
    txt_global_std_retardation: 'Verzögerung ',
    txt_global_std_totalminutes: 'Minuten insgesamt',
    txt_global_std_yesihaveredeemedmypoints: 'Ja, ich habe meine Punkte eingelöst!',
    txt_global_std_jointeam: 'Einem Team beitreten',
    txt_global_std_p2pblockeddate: 'Sperrdatum',
    txt_global_std_getstartedmanual: 'Erste Schritte Handbuch',
    txt_global_std_bringoutyourcompetitivesideandreceivethehighestscoreoftheevent: 'Bringen Sie Ihre wettbewerbsfähige Seite zur Geltung und erhalten Sie die höchste Punktzahl des Events.',
    txt_global_std_timeinconstantspeed: 'Zeit in konstanter Geschwindigkeit',
    txt_global_std_claimsdepartment: 'Schadensabteilung',
    txt_global_std_p2pwrongcodetryagain: 'Falscher Code. Versuchen Sie es nochmal',
    txt_global_all_wednesday: 'Mittwoch',
    txt_global_std_MSIG_InsuranceLetter14: 'Finanzinstitution',
    txt_global_std_doyouacceptthechallenge: 'Nimmst du die Herausforderung an?',
    txt_global_std_off: 'Aus',
    txt_global_std_oversigtovernivauetpdintur: 'Aufsicht über nivauet på din tur.',
    txt_global_std_allowallthetime: 'Erlaube die ganze Zeit',
    txt_global_std_youcandobetter: 'Das kannst du besser!',
    txt_global_std_yourdrivewasnotregisteredifyouhadyourenerfypluginconnectedwhiledrivingthecarbutdidnothavetheappbluetoothorgpslocationservicesactivesothatitregistersdrivingdatacompletedrunswillbechargedafterwardsinpremiumlevel10to15: 'Ihr Laufwerk wurde nicht registriert. Wenn Sie Ihr Enerfy-Plug-In während der Fahrt mit dem Auto verbunden hatten, aber die App, Bluetooth oder GPS / Ortungsdienste nicht aktiv waren, um Fahrdaten zu registrieren, werden abgeschlossene Läufe anschließend in Premium-Level 10 bis 15 berechnet.',
    txt_global_std_atrustedscoreusedbyfia: 'Eine vertrauenswürdige Bewertung, die von der FIA verwendet wird',
    txt_global_std_thedriverwiththebestscorewinstheround: 'Der Fahrer mit dem besten Ergebnis gewinnt die Runde!',
    txt_global_std_usertermsandconditions: 'Benutzerbedingungen',
    txt_global_std_verifyemail: 'E-Mail bestätigen',
    txt_global_std_authentication: 'Authentifizierung',
    txt_global_std_tomasengechamp: 'Erster tschechischer Fahrer in F1 und Indycar, Tschechischer Circuit-Meister, Deutscher FFord-Meister',
    txt_global_std_carrorewardworkshops: 'Carro Belohnungsworkshops',
    txt_global_std_welcometo2: 'Willkommen zu',
    txt_global_std_cardnummer: 'Karten-Nr.',
    txt_global_std_henningsolbergDescription: 'Solberg ist der ältere Bruder des FIA-Rallye-Weltmeisters 2003 Petter Solberg und durch seine schwedische Ehefrau Maud der Stiefvater des schwedischen Rallyefahrers Pontus Tidemand. Einer der erfahrensten Fahrer des Sports. Wenn er im Groove ist, hat er die Macht, die Jugendlichen in Verlegenheit zu bringen.',
    txt_global_std_cimage10h: 'Fahren Sie bei niedriger Drehzahl und im höchsten Gang ',
    txt_global_std_signedinasv2: 'Eingeloggt als',
    txt_global_std_insureddistance_used_80__1: 'Sie haben jetzt 80% Ihres jährlichen Kilometerstandes ausgegeben. Möchten Sie die Laufleistung anpassen?',
    txt_global_std_p2pbookingmodifiedsuccessfully: 'Buchung erfolgreich geändert',
    txt_global_std_ConfirmFia_Text4: 'Zeit',
    txt_global_std_p2pfaqandsupport: 'FAQ und Support',
    txt_global_std_congratulationsyoursetupiscompleteandyouarereadytogo: 'Herzliche Glückwünsche! Ihre Einrichtung ist abgeschlossen und Sie können loslegen.',
    txt_global_std_nottowed: 'Nicht abgeschleppt',
    txt_global_std_zipdriver: 'Zip-Treiber',
    txt_global_std_userstate: 'Benutzerstatus',
    txt_global_std_p2pyoucaninvitexfriendspercar: 'Du kannst {number} Freunde pro Auto einladen',
    txt_global_std_online: 'Online',
    txt_global_std_obdreaderonitsway: 'OBD-Leser unterwegs!',
    txt_global_std_cimage19h: 'Behalten Sie Ihr Gas konstant.',
    txt_global_std_p2pchooseafriendorinviteanewfriend: 'Wähle einen Freund oder lade einen neuen Freund ein',
    txt_global_std_notificationsisagreatwaytostayontopallowthemtoletusguideyoutowardsbecomeanevensmarterdriverandtakeyourdrivingtothenextlevel: 'Benachrichtigungen sind eine großartige Möglichkeit, den Überblick zu behalten. Erlauben Sie ihnen, sich von uns zu einem noch intelligenteren Fahrer zu führen und Ihr Fahren auf die nächste Stufe zu heben.',
    txt_global_std_mpltipafpp: 'Dies ist ein erfahrener Fahrer, aber manchmal während einer Fahrt und nur für kurze Zeit neigt dieser Fahrer dazu, den Fokus auf cooles Fahren zu verlieren. Einige Ablenkungen oder Stress können dazu führen, dass dieser Fahrer das Sicherheitsbewusstsein ignoriert. Dies wird oft behoben, indem man mit dem Fahrer spricht und um seine Rücksicht bittet.',
    txt_global_std_translationtool_upsell_pitch_bulletpoint1: 'Machen Sie Ihre App schnell mehrsprachig',
    txt_global_std_itisa12digitcodethatyoureceivefromtheeventorchallengeorganizer: 'Es ist ein 12-stelliger Code, den Sie vom Event- oder Challenge-Organisator erhalten.',
    txt_global_std_searchandmanageagreements: 'Vereinbarungen suchen und verwalten',
    txt_global_std_afollowthesestepstoconnecttheapptoyourcar1pressthedrivebuttonintheapp2attachtheobddevicetothecableandplugitintothe12vpowercigarettelightersocketinyourcar3startyourcarsengine4selecttheobdreaderinthelistintheappandcontinue: 'A: Befolgen Sie diese Schritte, um die App mit Ihrem Auto zu verbinden:\n' +
      '1. Drücken Sie die Fahrtaste in der App\n' +
      '2. Schließen Sie das OBD-Gerät an das Kabel an und stecken Sie es in die 12-V-Steckdose (Zigarettenanzünder) Ihres Autos.\n' +
      '3. Starten Sie den Motor Ihres Autos\n' +
      '4. Wählen Sie den OBD-Leser in der Liste in der App aus und fahren Sie fort.',
    txt_global_std_calendar: 'Kalender',
    txt_global_std_p2pnonsequentialdatesselected: 'Nicht aufeinanderfolgende Daten ausgewählt',
    txt_global_std_sortby: 'Sortiert nach',
    txt_global_std_youcannoteditthisfromvisualeditor: 'Sie können dies nicht im visuellen Editor bearbeiten.',
    txt_global_std_teamcolon: 'Team',
    txt_global_std_exportxml: 'XML exportieren',
    txt_global_std_youraveragelevelis123: 'Ihr durchschnittliches Niveau ist',
    txt_global_std_triptypes: 'Reisearten',
    txt_global_std_belowisalistofalluserswhosnotcompletedthisstep: 'Nachfolgend finden Sie eine Liste aller Benutzer, die diesen Schritt noch nicht abgeschlossen haben',
    txt_global_std_ChicheritFacts: 'Monster Athlet Guerlain Chicherit tritt 2018 mit seinem eigenen Team von GC Kompetition auf der FIA World Rallycross Championship an, die den neuen, von Prodrive entwickelten Renault Megane RS fährt. Als professioneller Skifahrer und viermaliger Freeride-Weltmeister hält Chicherit den Rekord, vollendete er als erster Mann in der Geschichte einen ungesicherten Backflip mit einem Auto. Chicherit debütierte 2015 im JRM Mini RX Countryman',
    txt_global_std_p2ptotal: 'Gesamt:',
    txt_global_std_sendasemail: 'Als E-Mail versenden',
    txt_global_std_advancedusersettingsfor: 'Erweiterte Benutzereinstellungen für',
    txt_global_std_bluetoothreasontext: 'Eine Bluetooth-Verbindung ist erforderlich, um sicherzustellen, dass Sie in Ihrem Auto sitzen.',
    txt_global_std_wrongpassword: 'Falsches Passwort',
    txt_global_std_youaredrivingregno: 'Sie fahren {regno}',
    txt_global_std_p2pblockmycar: 'Blockiere mein Auto',
    txt_global_std_loadingteamleaderboard: 'Teamleaderboard wird geladen ...',
    txt_global_std_successfulpasswordchange: 'Sie haben Ihr Passwort erfolgreich geändert.',
    txt_global_std_dayd: 'T',
    txt_global_std_inviteandinspireeverydriverinyournetworktoparticipateandgetnewdataonyouresgperformance: 'Laden Sie jeden Fahrer in Ihrem Netzwerk ein und inspirieren Sie ihn zur Teilnahme und erhalten Sie neue Daten zu Ihrer ESG-Performance',
    txt_global_std_startadrive: 'Beginne eine Fahrt ',
    txt_global_std_statusdriving: 'Fahren',
    txt_global_std_tilladnotifikationer: 'Tillad Notifikationer',
    txt_global_std_safedriverweeklyunlockedkey: 'Tolle Arbeit {user}! Sie haben eine Woche lang eine Punktzahl über {score} gehalten.',
    txt_global_std_wecannotfindanydata: 'Wir können keine Daten finden!',
    txt_global_std_qhowwilligetpaidforaclaim: 'F: Wie werde ich für einen Anspruch bezahlt?',
    txt_global_std_notripsperday: 'Anzahl Fahrten pro Tag',
    txt_global_std_kintofaqlink1: 'Erste Schritte Handbuch (Japanisch)',
    txt_global_std_ranking: 'Rangliste',
    txt_global_std_noconnectiontocar: 'Keine Verbindung zum Auto',
    txt_global_std_yournicknameshouldcontainatleastthree: 'Ihr Spitzname sollte mindestens drei (3) Zeichen und keine Leerzeichen enthalten.',
    txt_global_std_decline: 'Ablehnen',
    txt_global_std_p2pconfirm: 'Bestätigen',
    txt_global_std_tobeabletostartadrive: 'Um eine Fahrt starten zu können, benötigen wir Zugriff auf Bluetooth, Ortungsdienste und Bewegungsfitness.',
    txt_global_std_SendMyPosition_Text1: 'hat Ihnen ihre Position aus der {appName} -app gesendet.',
    txt_global_std_enterbasepremium: 'Grundprämie eingeben',
    txt_global_std_colortheme: 'Farbthema:',
    txt_global_std_1gotosettingsforappnameinyourphonebrb2allowaccesstomotionfitnessb: '1. Gehen Sie zu den Einstellungen für {appname} in Ihrem Telefon. <br> <b> 2. Ermöglichen Sie den Zugriff auf Motion & Fitness.</b>',
    txt_global_std_mostpopular: 'Am beliebtesten',
    txt_global_std_challengeaverage1: 'Herausforderungsdurchschnitt',
    txt_global_std_statistikfordennekretur: 'Statistik für denne køretur',
    txt_global_std_OSolbergQuote: 'Zitat kommt bald',
    txt_global_std_invitationsent: 'Einladung versendet.',
    txt_global_std_tilladatappensenderdignotifikationernrdererregistreretnyekreture: "Tillad at App'en sender dig notifikationer, når der er registreret nye køreture.",
    txt_global_std_p2pcarcare: 'Autopflege',
    txt_global_std_p2pemailopenapp: 'OPEN {appname}',
    'txt_global_std_Al-KuwariMusic': 'Klassische Musik',
    txt_global_std_pleasefirstfillinyourfirstnameandlastname: 'Bitte geben Sie zuerst Ihren Vornamen und Nachnamen ein.',
    txt_global_std_nicknamenicknamealreadyexists: 'Spitzname {nickname} existiert bereits',
    txt_global_std_2makesureyourcarsbluetoothison: '2. Stellen Sie sicher, dass Bluetooth in Ihrem Auto aktiviert ist.',
    txt_global_std_enginemustberunning: 'Motor muss laufen',
    txt_global_std_qualificationfinalstarts30thmarch: 'Das Qualifikationsfinale beginnt am 30. März.',
    txt_global_std_shareinvite: 'Einladung teilen',
    txt_global_std_p2pselecteddateandtime: 'Ausgewähltes Datum und Uhrzeit',
    txt_global_std_forthisapptoworkproperlyweneedtoaccessyourlocationbluetoothandphysicalfitnesspermissionthelocationserviceisusedinbackgroundforthedrivingfeaturetoanalyzeyourdrivingwithoptimalresultstheappalsodoesthisinthebackgroundwevalueyourprivacyandwillnotsharethisinformationwithathirdpartyentity: 'Damit diese App ordnungsgemäß funktioniert, müssen wir auf Ihren Standort, Bluetooth und Ihre Berechtigung für körperliche Fitness zugreifen. Der Ortungsdienst wird im Hintergrund für die Fahrfunktion verwendet. Um Ihr Fahren mit optimalen Ergebnissen zu analysieren, tut die App dies auch im Hintergrund. Wir schätzen Ihre Privatsphäre und geben diese Informationen nicht an Dritte weiter.',
    txt_global_std_kintofaq9a: 'Weil die KI den Weg kennen muss, um die Punktzahl berechnen zu können und Ihnen im Notfall zu helfen. Die Kinto-App wird niemals den Standort oder andere Daten an Dritte weitergeben.',
    txt_global_std_qhowdoiconnecttomycarsbluetooth: 'F: Wie verbinde ich mich mit dem Bluetooth meines Autos?',
    txt_global_std_passwordchanged: 'Passwort geändert',
    txt_global_std_deleteaccount: 'Konto löschen',
    txt_global_std_repetitionsnumber: 'Anzahl der Wiederholungen',
    txt_global_std_collectablebadges: 'Abzeichen zum Sammeln',
    txt_global_std_youbooked5timesinonemonthyouhaveearned1500points: 'Sie haben 5 Mal in einem Monat gebucht. Sie haben 1500 Punkte verdient.',
    txt_global_std_goodthatyouarekeepingyouraccountsecure: 'Gut, dass Sie Ihr Konto sicher aufbewahren.',
    txt_global_std_BeforeFirstBill_Hello: 'Hallo',
    txt_global_std_drivemorethan1miletogetmoredetailedanalyticsaboutyourtrip: 'Fahren Sie mehr als 1 Meile, um detailliertere Analysen zu Ihrer Reise zu erhalten.',
    txt_global_std_summaryofanalyse: 'Zusammenfassung der Analyse',
    txt_global_std_youearnedapercentdiscountbydrivingsafe: 'Sie haben durch sicheres Fahren einen Rabatt von {percent} % erhalten',
    txt_global_std_thepiggybank: 'Das Sparschwein',
    txt_global_std_youareamongthebestappnamedrivers: 'Beeindruckend! Sie gehören zu den besten {appname} Treibern!',
    txt_global_std_step3: 'Schritt 3',
    txt_global_std_upcoming: 'Kommt',
    txt_global_std_pigsinvited: 'Freunde werben',
    txt_global_std_weregladtohaveyouonboardwatchourvideoguideforsomequicktipstogetyouupandrunning: 'Wir freuen uns, Sie an Bord zu haben. Sehen Sie sich unsere Videoanleitung an, um einige schnelle Tipps für den Einstieg zu erhalten.',
    txt_global_std_youmayhavetogotoyourbluetoothsettingsforbothyourphoneandcar: 'Möglicherweise müssen Sie Ihre Bluetooth-Einstellungen für Ihr Telefon und Ihr Auto aufrufen.',
    txt_global_std_buy2: 'Kaufen',
    txt_global_std_p2pborrowyour: 'leihen Sie sich Ihre',
    txt_global_std_f_lanzmusic: 'Klassik, Hip Hop und Tanz',
    txt_global_std_HSolbergWhyjoin: 'Zum ersten Mal können meine Fans und ich gemeinsam an einem FIA-Wettbewerb teilnehmen.',
    txt_global_std_friendsreferral: 'Überweisung von Freunden',
    txt_global_std_qcanisignupforinsurancetobegintoday: 'F: Kann ich eine Versicherung ab heute abschließen?',
    txt_global_std_MSIG_Onboarding9: '3. Fahren!',
    txt_global_std_gooddrivingtechnique: 'Gute Fahrtechnik',
    txt_global_std_howdoifileaclaim: 'Wie reiche ich einen Anspruch ein?',
    txt_global_std_SignupFia_Text3: 'Die FIA ​​SDC ist die Initiative, bei der sich Top-Rennstars und -fahrer aus aller Welt in einem Wettbewerb treffen. Mit einer Smartphone-App können Sie einem Team beitreten, die besten Fahrer der Welt herausfordern und um den Titel als FIA Smartest Driver kämpfen.',
    txt_global_std_yourappnamehastobeatleasttwocharacterslong: 'Ihr App-Name muss mindestens zwei Zeichen lang sein',
    txt_global_std_appcreator_upsell: 'App Creator – Erstellen Sie in wenigen Minuten Ihre eigene Marken-Fahr-App.',
    txt_global_std_advancedusersettings: 'Erweiterte Benutzereinstellungen',
    txt_global_std_weneedpermissiontoaccessyourbluetoothforthisapptowork: 'Wir benötigen die Erlaubnis, auf Ihr Bluetooth zuzugreifen, damit diese App funktioniert. Um diese App weiterhin nutzen zu können, müssen Sie zu Ihrem Gerät gehen',
    txt_global_std_p2pfaq10q: 'Was ist, wenn mein Freund mein Auto beschädigt?',
    txt_global_std_rulesforservice: 'Regeln für den Service',
    txt_global_std_itemsperpage: 'Objekte pro Seite',
    txt_global_std_idling: 'Im Leerlauf',
    txt_global_std_searchtranslation: 'Übersetzung suchen',
    txt_global_std_fiasmartdrivingchallenge: 'FIA Smart Driving Challenge',
    txt_global_std_p2phasreceivedlendingrequest: ' hat Ihre Kreditanfrage erhalten.',
    txt_global_std_publishchanges: 'Änderungen veröffentlichen',
    txt_global_std_endinsurance: 'Versicherung beenden',
    txt_global_std_qhowdoiconnecttomycar: 'F: Wie verbinde ich mich mit meinem Auto?',
    txt_global_std_congratulationyoupositiononleaderboardwasincreased: 'Glückwunsch! Ihre Position in der Rangliste wurde erhöht!',
    txt_global_std_TidemandDescription: '',
    txt_global_std_p2pfaq8a: 'Sie können Ihr Auto problemlos an Tagen blockieren, an denen Sie wissen, dass Sie es selbst fahren werden oder aus anderen Gründen Ihr Auto nicht teilen möchten.\n' +
      '1. Nach Hause gehen\n' +
      '2.Drücken Sie oben links auf die Schaltfläche Kalender\n' +
      '3. Wählen Sie dann das Datum und die Uhrzeit, zu der Sie Ihr Auto blockieren möchten.',
    txt_global_std_youdrovereallywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Du bist wirklich gut gefahren! Ihr Durchschnitt war eine Woche lang besser als {score}! Sie haben {points} Punkte verdient!',
    txt_global_std_SulayemMileage: 'Liwa Oasis - Al Ain Straße durch die Wüste des leeren Viertels, die spektakuläre friedliche Schönheit der Wüste erleben. Wenn Sie auf dieser Straße fahren, werden Sie ruhig und gelassen sein.',
    txt_global_std_failedtosendtothefollowingusers: 'Senden an folgende Nutzer fehlgeschlagen:',
    txt_global_std_repeatyourpassword2: 'Wiederhole dein Passwort',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint3: 'Kommunizieren Sie kontextbezogene Botschaften zum richtigen Zeitpunkt',
    txt_global_std_pleasereloadortryagainlater: 'Bitte neu laden oder später erneut versuchen.',
    txt_global_std_inviteuser: 'Benutzer einladen',
    txt_global_std_nofriendsfound: 'Keine Freunde gefunden.',
    txt_global_std_co2lockedkey: 'Fahren Sie umweltfreundlich und sparen Sie mehr als {co2value} CO₂ pro Fahrt, um dieses Abzeichen zu erhalten.',
    txt_global_std_addfriendtolist: 'Freundesliste  hinzufügen',
    txt_global_std_daysdays: '{days} Tage',
    txt_global_std_bestdriverstoday: 'Best drivers today',
    txt_global_std_backtoleaderboard: 'Zurück zur Bestenliste',
    txt_global_std_50kmunlockedkey: 'Du bist mit der App 50 km gefahren!',
    txt_global_std_warikan_onboarding_title_2: '【わりかんKINTO】アプリ　仮パスワードのご案内',
    txt_global_std_allowmotionfitness: 'Bewegung & Fitness zulassen',
    txt_global_std_locationselectalways: 'Standort - "immer" auswählen',
    txt_global_std_sms: 'SMS',
    txt_global_std_idonthaveanaccount: 'Ich habe keinen Account?',
    txt_global_std_allowlocationservices: 'Ortungsdienste zulassen',
    txt_global_std_activatecode: 'Aktivieren Sie den Code',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint3: 'Schaffen Sie Anreize für Benutzer mit Ihren eigenen KPIs, einschließlich CO2-Reduktionen, Risikominderungen, autofreien Tagen und mehr',
    txt_global_std_TaxInvoice11: 'Die vollständige Prämienzahlung muss vor Vertragsbeginn zum Zeitpunkt der Dokumentation erfolgen.',
    txt_global_std_hereyoucanseehowyouhavedriven: 'Hier können Sie sehen, wie Sie gefahren sind.',
    txt_global_std_appnamewallet: '{appname} Brieftasche',
    txt_global_std_canbeearnedwhenyoufocustoimproveinthisspeed: 'kann verdient werden, wenn Sie sich auf die Verbesserung der Geschwindigkeit konzentrieren',
    txt_global_std_wildfire: 'Lauffeuer!',
    txt_global_std_letsgo: 'Lass uns gehen',
    txt_global_std_theresetlinkwillexpirein20minafterthatyoullneedtosubmitanewrequestinordertochangeyourpassword: 'Der Link zum Zurücksetzen läuft in 20 Minuten ab, danach müssen Sie eine neue Anfrage stellen, um Ihr Passwort zu ändern.',
    txt_global_std_warikan_onboarding13: 'Öffnungszeiten der Rezeption 9:00 bis 18:00 Ganzjährig geöffnet (außer an den Feiertagen zum Jahresende und Neujahr)\n',
    txt_global_std_challengesomeone: 'Fordern Sie jemanden hinaus',
    txt_global_std_settingssaved: 'Einstellungen gespeichert ',
    txt_global_std_p2pviewreceipt: 'Quittung ansehen',
    txt_global_std_greatstreak: 'Großartige Serie!',
    txt_global_std_vouchercodes: 'Gutscheincode ',
    txt_global_std_getstarted2: 'Loslegen',
    txt_global_std_p2pdistancetravelled: 'Zurückgelegte Strecke',
    txt_global_std_discount: 'Rabatt',
    txt_global_std_buymoremilage: 'Kaufen Sie mehr Laufzeit',
    txt_global_std_closed: 'Geschlossen',
    txt_global_std_addcustombadgeicon: 'Fügen Sie ein benutzerdefiniertes Abzeichen-Symbol hinzu',
    txt_global_std_feedbackoptions: 'Feedback Optionen',
    txt_global_std_navigation: 'Navigation',
    txt_global_std_willbeaddedtoyoursubscription: 'wird Ihrem Abonnement hinzugefügt',
    txt_global_std_fortst: 'Fortsæt',
    txt_global_std_youmustnotuseappwhiledriving: 'Sie dürfen die App nicht während der Fahrt verwenden.',
    txt_global_std_allowbluetoothtocollectdrivingscore: 'Ermöglichen Sie Bluetooth, Ihre Fahrergebnisse zu erfassen.',
    txt_global_std_viewpassword: 'Passwort anzeigen',
    txt_global_std_leaderboard_tab_monthly: 'Monatlich',
    txt_global_std_invitationsentto: 'Einladung gesendet an',
    txt_global_std_thesebadgesyoucanonlyearnonetime: 'Diese Abzeichen können Sie nur einmal verdienen.',
    txt_global_std_communication_upsell: 'Kommunikationstool – Steigern Sie das Engagement mit personalisierten Benachrichtigungen.',
    txt_global_std_ifyoucomeacrosswordsandphrasesyoucanteditbyclickinginthevisualeditoryoucansearchandfindtheminthelistbelow: ' * Wenn Sie auf Wörter und Phrasen stoßen, die Sie nicht bearbeiten können, indem Sie auf den visuellen Editor klicken, können Sie sie in der folgenden Liste suchen und finden.',
    txt_global_std_typehere: 'Geben Sie hier ein',
    txt_global_std_endd: 'Ende',
    txt_global_std_accountpreferences: 'Kontoeinstellungen',
    txt_global_std_rules: 'Regeln',
    txt_global_std_showonleaderboard: 'Auf Rangliste anzeigen',
    txt_global_std_cimage5h: 'Bergtechnik',
    txt_global_std_MSIG_InsuranceLetter7: 'Art der Police.',
    txt_global_std_connectingyourgarminwatchiseasyclicktheconnectbuttonbelowtohelpusuncovernewinsightsintocrashriskbyanalyzingbothdrivingandhealthdata: 'Das Anschließen Ihrer Garmin-Uhr ist ganz einfach! Klicken Sie unten auf die Schaltfläche Verbinden, um uns dabei zu helfen, neue Erkenntnisse zum Unfallrisiko zu gewinnen, indem wir sowohl Fahr- als auch Gesundheitsdaten analysieren.',
    txt_global_std_showall: 'Alles anzeigen',
    txt_global_std_p2pcostsplitisbasedonhoursthecarisused: 'Die Kostenaufteilung basiert auf den Stunden, in denen das Auto genutzt wird.',
    txt_global_std_anonymous: 'anonym',
    txt_global_std_viewloyaltypoints: 'Treuepunkte anzeigen',
    txt_global_std_totaldistance2: 'Gesamtentfernung',
    txt_global_std_: 'Bei den meisten Fahrzeugen befindet sich die OBD-Buchse links unter dem Lenkrad. Wenn Sie die OBD-Buchse immer noch nicht finden können, klicken Sie hier.',
    txt_global_std_namecancelledthebooking: '{name} hat die Buchung storniert',
    txt_global_std_cimage40d: 'Durch Scrollen, behalten Sie den Fortschritt bei, sparen Kraftstoff und bewahren das Auto vor dem Verschleiß.',
    txt_global_std_uservsuser: 'Benutzer gegen Benutzer',
    txt_global_std_powerhuman: 'Macht Mensch',
    txt_global_std_p2pdividedequallyamongfriendsthatusedcar: 'Zu gleichen Teilen unter Freunden geteilt, die das Auto gebraucht haben.',
    txt_global_std_achievementcreatedmessage: 'Achievements wurden erstellt!',
    txt_global_std_p2pitstimetosplityourcostformonthof: 'Es ist an der Zeit, Ihre Kosten für den Monat aufzuteilen',
    txt_global_std_dontrestrictkey: 'Schlüssel nicht einschränken',
    txt_global_std_usethistemplate: 'Verwenden Sie diese Vorlage',
    txt_global_std_sendagain: 'Nochmals senden',
    txt_global_std_mysubscriptioncost: 'Meine Abonnementkosten',
    txt_global_std_kilometers_driven: 'gefahrene Kilometer',
    txt_global_std_typeyourverificationcode: 'Geben Sie Ihren Bestätigungscode ein',
    txt_global_std_howdoesthescoringsystemwork: 'Wie funktioniert das Punktesystem?',
    txt_global_std_orrecruitafriend: 'Oder einen Freund rekrutieren',
    txt_global_std_stop: 'Stop',
    txt_global_std_youcanuploadmax10photosofyourcar: 'Sie können maximal 9 Fotos Ihres Autos hochladen',
    txt_global_std_hereyoufindyourhighestimprovmentpotential: 'Hier finden Sie Ihr höchstes Verbesserungspotenzial',
    txt_global_std_easywaytogetinvolved: 'Einfache Möglichkeit, sich zu engagieren',
    txt_global_std_OSolbergFacts: 'Sohn von Petter Solberg. Er fährt das Auto seines Vaters für das nordische Team.',
    txt_global_std_usersuccessfullyadded: 'Benutzer erfolgreich hinzugefügt',
    txt_global_std_avgrpm: 'Durchschn. RPM',
    txt_global_std_youearnedapercentagephdiscount: 'Sie haben einen Rabatt von {percentage}% erhalten',
    txt_global_CoreTemplate_insuranceoverview: 'Versicherungsübersicht',
    txt_global_std_addvehicleidentification: 'Fahrzeugidentifikation hinzufügen',
    txt_global_std_includedkm: 'Eingeschlossene Kilometer',
    txt_global_std_password: 'Passwort',
    txt_global_std_apikey: 'API-Schlüssel',
    txt_global_std_points2: 'Punkte',
    txt_global_std_saveinsurancekey: 'Speichern Sie den Versicherungsschlüssel',
    txt_global_std_namekeytitle: 'Titel, angezeigt in App',
    txt_global_std_cannotfindanybluetoothdevice: 'Kann kein Bluetooth-Gerät finden.',
    txt_global_std_p2pborrowacartwo: 'Ein Auto ausleihen',
    txt_global_std_p2ppushbodynewexpensetoapprove: '{name} added expenses for the month of {monthname}.',
    txt_global_std_userspecific: 'Benutzerspezifisch',
    txt_global_std_deletedtranslationfromreview: 'gelöschte Übersetzung aus Überprüfung',
    txt_global_std_MSIG_InsuranceLetter31: '(Anerkannte Versicherer)',
    txt_global_std_bysigningupyouaccept: 'Mit der Anmeldung akzeptieren Sie',
    txt_global_all_monday: 'Montag',
    txt_global_std_worldclass: 'Weltklasse',
    txt_global_std_typeofchallenge: 'Art der Herausforderung',
    txt_global_std_problempersistcontactsupport: 'Wenn das Problem weiterhin besteht, wenden Sie sich an den Support.',
    txt_global_std_kintoremainingpointstonextlevel: '{points} points remaining to {level}',
    txt_global_std_do: 'Do',
    txt_global_std_feb: 'Feb',
    txt_global_std_apirestrictions: 'API-Einschränkungen',
    txt_global_std_standard: 'Standard',
    txt_global_std_MSIG_InsuranceLetter34: 'GST Reg. Nr. M2-0001676-9',
    txt_global_std_driver: 'Fahrer',
    txt_global_std_loyalty_upsell_pitch: 'Passen Sie schnell Ihr eigenes Markentreueprogramm an;',
    txt_global_std_warikan_onboardingmail_temppwd: '【仮パスワード】',
    txt_global_std_SignupFia_Text11: 'für mehr Informationen.',
    txt_global_std_savesettings: 'Einstellungen speichern ',
    txt_global_std_compareactivities: 'Aktivitäten vergleichen',
    txt_global_std_errorfetchingtrends: 'Fehler beim Abrufen von Trends',
    txt_global_std_p2pfaq2q: 'Wie kann ich Datum und Uhrzeit für eine Buchung bearbeiten?',
    txt_global_std_1000pointaverageachievementunlocked: 'Du bist wirklich gut gefahren! Ihr durchschnittlicher {scoretype} was better than {score} eine Woche {scoretype} was better than {score} ! Sie haben 1000 Punkte verdient!',
    txt_global_std_emailregisteredintheapp: 'E-Mail in der App registriert',
    txt_global_std_p2ppushbodyaddadmin: 'Administratorrechte für {vehicle} has been added by {name} .',
    txt_global_std_keepingyourmindandeyesonwhatshappeningaroundyouisvitalforyoursafetyandthesafetyofothersphoneactivityandotherdistractionsaffectyourfocusandincreasecrashrisk: 'Ihre Gedanken und Augen auf das zu richten, was um Sie herum passiert, ist für Ihre Sicherheit und die Sicherheit anderer von entscheidender Bedeutung. Telefonaktivität und andere Ablenkungen beeinträchtigen Ihre Konzentration und erhöhen das Unfallrisiko.',
    txt_global_std_fuelprice: 'Kraftstoffpreis',
    txt_global_std_recoveryhelp: 'Hilfe zur Wiederherstellung',
    txt_global_std_areyousureyouwanttoterminateInsurance: 'Möchten Sie die Versicherung wirklich beenden?',
    txt_global_std_carrorewards: 'Carro Belohnungen',
    txt_global_std_recruitedfriends: 'Rekrutierte Freunde',
    txt_global_std_changesinreview: 'Änderungen in der Überprüfung',
    txt_global_std_youhaveinvitedamountfriends: 'Sie haben {Betrag} Freunde eingeladen',
    txt_global_std_searchforgroups: 'Suchen Sie nach Gruppen',
    txt_global_std_sidekickusertermsandconditions: 'Nutzungsbedingungen für Benutzer',
    txt_global_std_iacceptthe: 'Ich akzeptiere das',
    txt_global_std_clientlibraries: 'Client-Bibliotheken',
    txt_global_std_kintofaqlink2: 'Entdecken Sie das App-Handbuch (Japanisch)',
    txt_global_std_apirestrictionsspecifytheenabledapisthatthiskeycancall: 'API-Einschränkungen geben die aktivierten APIs an, die dieser Schlüssel aufrufen kann',
    txt_global_std_nextstep2: 'Nächster Schritt',
    txt_global_std_MSIG_InsuranceLetter16: 'Marktwert zum Zeitpunkt des Verlustes',
    txt_global_std_signinwithappname: 'Mit {appName} anmelden',
    txt_global_std_uniquebadges: 'Einzigartige Abzeichen',
    txt_global_std_hellothereletsgetstarted: 'Hallo! Lass uns anfangen.',
    txt_global_std_loyaltydistribution: 'Loyalitätsverteilung',
    txt_global_std_sendalinktoyourfriendsothattheycandownloadtheapp: 'Senden Sie Ihrem Freund einen Link, damit er die App herunterladen kann.',
    txt_global_std_p2pemailsupport: 'Für technischen App-Support wochentags von 09:00 bis 18:00 Uhr',
    txt_global_std_offerendsin: 'Angebot endet in',
    txt_global_std_invited2: 'Eingeladen',
    txt_global_std_viewoffer: 'Zum Angebot',
    txt_global_std_loadingdata: 'Daten werden geladen',
    txt_global_std_saveandexit: 'Speichern und schließen',
    txt_global_std_SulayemBesttip: '',
    txt_global_std_p2pborrow: 'Leihen',
    txt_global_std_pleaseverifyifyourmileageiscorrectbeforesavingit: 'Bitte überprüfen Sie, ob Ihr Kilometerstand korrekt ist, bevor Sie ihn speichern',
    txt_global_std_clickthelinkbelowtoconfirmyouremailandfinishcreatingyourenerfyglobalaccount: 'Klicken Sie auf den Link unten, um Ihre E-Mail zu bestätigen und die Erstellung Ihres Enerfy Global-Kontos abzuschließen.',
    txt_global_std_p2pwantstolendyou: ' will dich leihen',
    txt_global_std_choosehowmanydrivesyouwanttochallengeyourfriendon: 'Wählen Sie aus, auf wie vielen Fahrten Sie Ihren Freund herausfordern möchten.',
    txt_global_std_callsosphonenumber: 'Anruf SOS, Telefonnummer:',
    txt_global_std_regnumber: 'Reg. Nummer',
    txt_global_std_p2pfaq5a: 'Drücken Sie auf das Bild und zoomen Sie dann mit den Fingern.',
    txt_global_std_yourannualmilagewasunabletoreadfromtheserverpleasetryagainlater: 'Ihr Jahreskilometerstand konnte nicht vom Server gelesen werden, bitte versuchen Sie es später erneut.',
    txt_global_std_contactforactivation: 'Kontakt zur Aktivierung',
    txt_global_std_p2pconfirmwith: 'Bestätigen mit',
    txt_global_std_lastlogfilesent: 'Zuletzt gesendete Logdatei',
    txt_global_std_removeparticipant: 'Teilnehmer entfernen',
    txt_global_std_fiatodayrule2020: 'Sie müssen mindestens 1 km fahren, um eine Punktzahl in der Rangliste zu erhalten.',
    txt_global_std_v2ilevel: 'Niveau',
    txt_global_std_lostconnectionbig: 'Die Reise wird enden',
    txt_global_std_p2pfaq22a: '1. Wählen Sie „Warikan“ aus dem „Menü“.\n' +
      '2. Wählen Sie "Zusatzkosten".\n' +
      '3. Wählen Sie Kosten hinzufügen.\n' +
      '4. Wählen Sie das Auto aus, wählen Sie die Art der Ausgabe, geben Sie die Gebühr ein, laden Sie das Bild des Belegs hoch und wählen Sie Speichern.\n' +
      '5. Die zusätzlichen Kosten werden auf der Seite "Zusätzliche Kosten" angezeigt.',
    txt_global_std_accidentdetails: 'Unfalldetails',
    txt_global_std_rwmregister: 'Registrieren',
    txt_global_std_insuranceandpayments: 'Versicherung und Zahlungen',
    txt_global_std_notjoinable: '',
    txt_global_std_yourinsuranceperiodisover: 'Ihre Versicherungszeit ist abgelaufen:',
    txt_global_std_join: 'Teilnehmen ',
    txt_global_std_chooseallowalways: 'Wählen Sie Immer zulassen',
    txt_global_std_refer10friendstounlockthisachievementandearn300pointstoyour: 'Empfehlen Sie 10 Freunde, um diesen Erfolg freizuschalten und 300 Punkte für Ihre zu sammeln',
    txt_global_std_areyousurethatyouwanttoremovethisdrivenotreversable: 'Sind Sie sicher, dass Sie diese Reise entfernen möchten? Der Vorgang ist nicht rückgängig zu machen ',
    txt_global_std_searchbynameoremailaddress: 'Suche nach Name oder E-Mail-Adresse',
    txt_global_std_challengearandomdriver: 'Fordern Sie einen zufälligen Fahrer heraus',
    txt_global_std_howdidyoufeel: 'Wie haben Sie sich gefühlt?',
    txt_global_std_theanalysistookalongertime: 'Diese Analyse hat länger gedauert als erwartet, aber keine Sorge, wir senden Ihnen eine Push-Benachrichtigung, wenn sie abgeschlossen ist',
    txt_global_std_p2psafedriverbonusincluded: 'Safedriver Bonus inklusive',
    txt_global_std_assigntogroups: 'Gruppen zuweisen',
    txt_global_std_kilometerpremiumtohigh: 'Kilometerprämie zu hoch',
    txt_global_std_groupmanagement: 'Gruppenmanagement',
    txt_global_std_website: 'Webseite',
    txt_global_std_speedvariation: 'Geschwindigkeitsvariation',
    txt_global_std_profilesettings: 'Profil/Einstellungen',
    txt_global_std_yourlocation1: 'Ihr Standort',
    txt_global_std_connecttoyourcarbeforelendingittoafriend: 'Stellen Sie eine Verbindung zu Ihrem Auto her, bevor Sie es einem Freund leihen',
    txt_global_std_areyousureyouwanttounpublishyourtranslationsifyouunpublishtranslationstheappwillnotshowthislanguageanymore: ' Möchten Sie die Veröffentlichung Ihrer Übersetzungen wirklich aufheben? Wenn Sie die Veröffentlichung von Übersetzungen rückgängig machen, zeigt die App diese Sprache nicht mehr an.',
    txt_global_std_adgangtilbluetooth: 'Weiter zu Bluetooth',
    txt_global_std_somethingsmissing: 'Etwas fehlt...',
    txt_global_std_teamscore: 'TEAM-ERGEBNIS',
    txt_global_std_ConfirmFia_Text3: 'Datum',
    txt_global_std_p2pcostsplitisbasedonthepercentageofuse: 'Die Kostenaufteilung basiert auf dem Prozentsatz der Nutzung.',
    txt_global_std_p2preceiptsummary: 'Belegübersicht',
    txt_global_std_25kmlockedkey: 'Fahren Sie eine Strecke von 50 km, um dieses Abzeichen zu erhalten.',
    txt_global_std_PolicySchedule22: 'NCD-Schutz (falls vorhanden)',
    txt_global_std_403forbidden: '403 Verboten',
    txt_global_std_worldchallenge: 'Weltweite Herausforderung',
    txt_global_std_yourinsuranceisnowactivated: 'Ihre Versicherung ist jetzt aktiviert',
    txt_global_std_p2pfaq12q: 'Kann ich einen Freund entfernen?',
    txt_global_std_reset: 'Zurücksetzen ',
    txt_global_std_remove: 'Löschen',
    txt_global_std_deleteuseraccount: 'Benutzerkonto löschen',
    txt_global_std_addaddress: 'Adresse hinzufügen',
    txt_global_std_recurring: 'Wiederkehrend',
    txt_global_std_sendupdate: 'Update senden',
    txt_global_std_novalidapikeyprovided: 'Kein gültiger API-Schlüssel angegeben.',
    txt_global_std_bestdriversintotal: 'Bedste bilister i alt',
    txt_global_std_secondss: 's',
    txt_global_std_selecttheregnumberofyourcar: 'Wählen Sie die Fahrzeugnummer Ihres Autos',
    txt_global_std_NewPW_Text1: 'Sie haben ein neues Passwort für Ihr {appName} -Konto angefordert.',
    txt_global_std_thislinkexpiresinxtimeandcanonlybusedonce: 'Dieser Link läuft in 6 Minuten ab und kann nur einmal verwendet werden.',
    txt_global_std_sosinternationalaroundtheclock: 'Internationaler Notruf rund um die Uhr',
    txt_global_std_drivesafeandecofriendly: 'Fahren Sie sicher und umweltfreundlich',
    txt_global_std_carstatsthisday: 'Autostatistiken für den letzten Tag',
    txt_global_std_youhaventpairedwithacaryet: 'Sie haben noch kein Auto gepaart.',
    txt_global_std_p2powneruserguide: 'Bedienungsanleitung für den Besitzer',
    txt_global_std_p2ptodo: 'Machen',
    txt_global_std_amount_default: 'Menge',
    txt_global_std_thisisatestblabla: 'Dies ist ein Test Blabla',
    txt_global_std_challengeranking: 'Ihr Challenge-Ranking',
    txt_global_std_insuranceoverview2: 'Versicherungsübersicht',
    txt_global_std_resetpassword: 'Passwort zurücksetzen',
    txt_global_std_achievementtool_upsell: 'Achievements Tool – Machen Sie es sich leicht, intelligentes Fahren zur Gewohnheit zu machen.',
    txt_global_std_verybad: 'Sehr schlecht',
    txt_global_std_thebetteryoudrivethemoreyousave: 'Je besser Sie fahren, desto mehr sparen Sie.',
    txt_global_std_whymustiallowalwaysallowlocationsservicesanswer: 'Weil die KI den Weg kennen muss, um die Punktzahl berechnen zu können und Ihnen im Notfall zu helfen.',
    txt_global_std_members2: 'Mitglieder',
    txt_global_std_getstartedtips: 'Tipps zum Einstieg',
    txt_global_std_ifyourfriendregisterswiththereferralcodebothyouandyourfriendwillreceivenok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsurance: 'Wenn sich Ihr Freund mit dem Empfehlungscode anmeldet, erhalten sowohl Sie als auch Ihr Freund NOK 250 im Sparschwein (dafür müssen Sie beide mindestens einen Monat nach Versicherungsbeginn Kunde von Tryg sein).',
    txt_global_std_mytrips: 'Meine Reisen',
    txt_global_std_tripsapi: 'Trips-API',
    txt_global_std_youwillbebilledatpremiumlevel10: 'You will be billed at premium level 10, please refer to your insurance letter for price details.',
    txt_global_std_p2pwantstolendborrow: 'will {leihen}',
    txt_global_std_kintofaq9q: 'Warum muss ich Standortdienste &quot;immer zulassen&quot; zulassen?',
    txt_global_std_doyouwanttodeletetheapikey: 'Möchten Sie den API-Schlüssel löschen?',
    txt_global_std_yourealmostthere: 'Du bist fast da!',
    txt_global_std_trygdiscountpopuptext: 'Hier sehen Sie, wie viel Sie sparen würden, wenn Sie eine Sidekick-Kfz-Versicherung hätten. Das Einsparpotenzial wird anhand Ihres Jahrespreises, der Jahreskilometer und des Fahrzeugmodells berechnet.\n' +
      'Wenn Sie diese Informationen nicht eingegeben haben, wird die Einsparung auf Basis eines Jahrespreises von 5.000 NOK und einer jährlichen Laufleistung von 20.000 km berechnet.',
    txt_global_std_thesebadgesyoucanearnseveraltimes: 'Diese Abzeichen können Sie mehrmals verdienen.',
    txt_global_std_doyouwanttodriveforteamnamesteam: 'Möchten Sie für das Team von {teamName} fahren?',
    txt_global_std_p2ptypeamessagedot: 'Geben Sie eine Nachricht ein ...',
    txt_global_std_WelcomeLetter_Text6: 'Vorstand',
    txt_global_std_welcometoyournewaccountwehavesentyouanemailtoverifyyournewaccountremembertocheckyourspamfolderdidntgetanemail: 'Willkommen in Ihrem neuen Konto! Wir haben Ihnen eine E-Mail gesendet, um Ihr neues Konto zu bestätigen. Denken Sie daran, Ihren Spam-Ordner zu überprüfen. Keine E-Mail erhalten?',
    txt_global_std_searchchallenges: 'Herausforderung suchen',
    txt_global_std_yourcarisntturnedon: '• Ihr Auto ist nicht eingeschaltet',
    txt_global_std_100pointtripachievementunlocked: 'Du bist mit {scoretype} {score} oder besser {scoretype} {score} ! Großartig! Sie haben 100 Punkte verdient!',
    txt_global_std_upcomingtraining: 'Anstehende Ausbildung',
    txt_global_std_estimatedprice: 'Ungefährer Preis',
    txt_global_std_yourmileagewillbesavedintheobddevice: "Your mileage will be saved in the OBD device's built in memory storage and will be uploaded at premium level 10 when you complete your next connected drive.",
    txt_global_std_exportdrives: 'Laufwerke exportieren',
    txt_global_std_usersoverview: 'Benutzerübersicht',
    txt_global_std_PolicySchedule3: 'Begleitschreiben / Ref. Nein.',
    txt_global_std_youhaveexceededthedrivingmaxtimeof: 'Sie haben die maximale Fahrzeit von {breakTime} überschritten.',
    txt_global_std_KleinschmidtChamp: 'Dakar Rally Champion 2001',
    txt_global_std_mustbeavalidsizenumbersand: 'Muss eine gültige Größe, Zahlen und + sein',
    txt_global_std_reasonterminatedinsurance: 'Grund Kündigung der Versicherung',
    txt_global_std_automatic: 'Automatisch',
    txt_global_std_tryoutappname: 'Probieren Sie {appName} aus',
    txt_global_std_allowingaccesstobluetoothisnecessaryforthisapptoconnecttoyourcarandmeasureyourdriving: 'Das Ermöglichen des Zugriffs auf Bluetooth ist erforderlich, damit diese App eine Verbindung zu Ihrem Auto herstellen und Ihr Fahren messen kann.',
    txt_global_std_accidentandclaims: 'Unfälle & Schäden',
    txt_global_std_buyinsurance: 'Versicherung abschließen',
    txt_global_std_ifyourcardoesnotshowupinthelistyoumightneedtogotobluetoothsettingsforbothyourphoneandyourcar: 'Wenn Ihr Auto nicht in der Liste angezeigt wird, müssen Sie möglicherweise sowohl für Ihr Telefon als auch für Ihr Auto zu den Bluetooth-Einstellungen gehen.',
    txt_global_std_verifycode: 'Verifizieren Sie den Code',
    txt_global_std_p2pcancelledph: '{count}Storniert',
    txt_global_std_destination: 'Ziel',
    txt_global_std_thesepermissionsarenecessaryfortheapptobeabletoregisteryourdrivescorrectlyletsgo: 'Diese Berechtigungen sind erforderlich, damit die App Ihre Laufwerke korrekt registrieren kann. Lass uns gehen!',
    txt_global_std_willbesenttotheemailregisteredintheonboarding: ' Wird an die im Onboarding registrierte E-Mail gesendet',
    txt_global_std_findyourobdportinyourcar: 'Finden Sie Ihren OBD-Anschluss in Ihrem Auto',
    txt_global_std_yournewappishere: 'Ihre neue App ist da!',
    txt_global_std_workspacepermissions: 'Workspace-Berechtigungen',
    txt_global_std_rpmspeednotfluctating: 'Drehzahl / Geschwindigkeit nicht schwankend',
    'txt_global_std_Al-KhelaifiInterests': 'Autos, Fotografie, Motorsport',
    txt_global_std_addtogroups: 'Zu Gruppen hinzufügen',
    txt_global_std_enteryourpolicynumber: 'Geben Sie Ihre Versicherungsnummer ein',
    txt_global_std_youneedtofinishsettingupyourprofilebeforejoining: 'Sie müssen das Profil einrichten, bevor Sie an einer Veranstaltung teilnehmen können.',
    txt_global_std_allteamleaders: 'alle Teamleiter',
    txt_global_std_createanaccountforname: 'Erstellen Sie ein Konto für {appName}',
    txt_global_std_apitool_upsell_pitch_bulletpoint1: 'Umfassendes digitales Onboarding für nahtlose Integration',
    txt_global_std_jpfaqtitle: 'アプリを車に接続して、走行\nデータを記録する方法を教えてく\nださい。',
    txt_global_std_trygtakemetoapp: 'Überspringen und loslegen mit Tryg Drive',
    txt_global_std_cimage2h: 'Halten Sie Abstand Halten Sie Abstand ',
    txt_global_std_canisignupforinsurancetobegintoday: 'Kann ich noch heute eine Versicherung abschließen?',
    txt_global_std_p2psubmit: 'einreichen',
    txt_global_std_timesinceparked: 'Zeit seit geparkt',
    txt_global_std_testarenandra: 'Kiểm tra một giây',
    txt_global_std_restrictkey: 'Schlüssel einschränken',
    txt_global_std_yourinsurancehasbeenrenewedformonth: 'Ihre Versicherung wurde um {Monat} verlängert.',
    txt_global_std_p2pyouhavechosen: 'Du hast gewählt:',
    txt_global_std_5streak: '5 Streifen!',
    txt_global_std_p2pfaq13q: 'Was ist, wenn ich das Auto vorzeitig zurückgebe?',
    txt_global_std_p2pconfirmedbookings: 'Bestätigte Buchungen',
    txt_global_std_thepasswordminimumislength8charactersandmustinclude1lowercaseuppercasenumeralandspecialcharacter: 'Das Passwort muss mindestens 8 Zeichen lang sein und muss 1 Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen enthalten',
    txt_global_std_resetyourenerfyglobalpassword: 'Setzen Sie Ihr Enerfy Global-Passwort zurück',
    txt_global_std_welcomebackenerfyfirstname: 'Willkommen zurück bei {appName}, {firstName}!',
    txt_global_std_westzone: 'Westzone',
    txt_global_std_monitoringyourdrivinghasneverbeeneasier: 'Das Überwachen Ihres Fahrens war noch nie einfacher.',
    txt_global_std_apis: 'APIs',
    txt_global_std_f_lanzchamp: '2020 KZ2 SA Champion, 2019 KZ2 SA Vize Champion, 2018 Rok Final Shifter Ladies Champion und P16 Overall\n',
    txt_global_std_companyaverage: 'Unternehmensdurchschnitt',
    txt_global_std_p2pcostsplits: 'Kostenaufteilungen',
    txt_global_std_placethewarningtriangle: '',
    txt_global_std_FiaPassword_Text2: 'Sie haben ein neues Passwort für Ihren FIA Smart Driving Challenge-Account angefordert. Ihr neues Passwort für den Benutzer',
    txt_global_std_drivesettingsnotfinished: 'Antriebseinstellungen nicht abgeschlossen',
    txt_global_std_allowaccesstomotionfitness: 'Ermöglichen Sie den Zugriff auf Motion & Fitness',
    txt_global_std_myprojects: 'Meine Projekte',
    txt_global_std_60days: '60 Tage',
    txt_global_std_howdoesitwork: 'Wie funktioniert es?',
    txt_global_std_selectatemplate: 'Wählen Sie eine Vorlage aus',
    txt_global_std_birthday: 'Geburtstag',
    txt_global_std_sdcrapply2019price3: '300 SEK Benzinchecks',
    txt_global_std_thisactivationcodeisinvalid: 'Dieser Aktivierungscode ist ungültig',
    txt_global_std_insurancehasbeenactivatedforregno: 'Die Versicherung für das Fahrzeug {regNo} wurde aktiviert.',
    txt_global_std_nextlevel: 'nächste Stufe.',
    txt_global_std_claimshandling: 'Schadensabwicklung',
    txt_global_std_insuranceperiod: 'Versicherungsdauer',
    txt_global_std_activationcodeemail3: 'Sie können es in der App aktivieren!',
    txt_global_std_cantfinduserinhead2head: 'Sie können Ihren Benutzer nicht finden? Sie können einen Freund in ReferFriend einladen.',
    txt_global_std_actualtimegain: 'Tatsächlicher Zeitgewinn',
    txt_global_std_anodometerorodographisaninstrumentusedformeasuringthedistancetravelledbyavehicleitcanbefoundinthecarsdashboard: 'Ein Kilometerzähler oder Odograph ist ein Instrument zur Messung der von einem Fahrzeug zurückgelegten Strecke. Es ist im Armaturenbrett des Autos zu finden.',
    txt_global_std_p2pfriendscalendars: 'Freundeskalender',
    txt_global_std_p2pfaq16a: 'Sie können die Kinto-Versicherung nutzen. Bitte benachrichtigen Sie jedoch die verantwortliche Person und besprechen Sie untereinander, wie das Problem gelöst werden soll.',
    txt_global_std_stress: 'Betonen',
    txt_global_std_weareunabletorecordyourtrip: 'Wir können Ihre Reise nicht aufzeichnen!',
    txt_global_std_validbetween: 'Gültig zwischen',
    txt_global_std_city: 'Stadt',
    txt_global_std_daydreamingisacommontypeofdriverdistractionyoumaythinkitsharmlessbutanythingthattakesyourmindoffthedrivingtaskincreasescrashriskchangingyourgazefrequentlycanhelpyoumaintainfocusontheenvironmentaroundyounew: 'Tagträume sind eine häufige Art der Fahrerablenkung. Sie denken vielleicht, dass es harmlos ist, aber alles, was Sie von der Fahraufgabe ablenkt, erhöht das Unfallrisiko. Häufige Blickwechsel können Ihnen helfen, sich auf die Umgebung um Sie herum zu konzentrieren.',
    txt_global_std_whatdoyouwanttodo: 'Was möchten Sie tun?',
    txt_global_std_p2pwhenitstimetodrive: 'Wenn es Zeit ist zu fahren',
    txt_global_std_yourdrivingstatsforthistripcomparedtotheaveragedriver: 'Ihre Fahrstatistiken für diese Fahrt im Vergleich zum durchschnittlichen Fahrer.',
    txt_global_std_myrewards: 'Meine Belohnungen',
    txt_global_std_whatisanodometer: 'Was ist ein Kilometerzähler?',
    txt_global_std_Fia_Receipt_Text15: 'Ihr Aktivierungscode:',
    txt_global_std_locationservice: 'Standortdienst',
    txt_global_std_resetpasswordforname: 'Passwort für {name} zurücksetzen?',
    txt_global_std_sdcrapply2019price1: '1000 SEK Benzinchecks',
    txt_global_std_driversnow: 'Treiber jetzt',
    txt_global_std_pointsheader: 'Punkte',
    txt_global_std_p2pyouhavexfriendsintheapp: 'Du hast {number} Freunde in der App',
    txt_global_std_step4: 'Schritt 4',
    txt_global_std_warikan_onboarding4: 'Gebrauchsanweisung:',
    txt_global_std_p2plogintomykintoaccount: 'Melden Sie sich bei meinem Kinto-Konto an',
    txt_global_std_nobluetoothbrnoworries: 'Kein Bluetooth? <br/>Keine Sorge!',
    txt_global_std_insuranceactivationkeydoesnotexist: 'Der Aktivierungsschlüssel für die Versicherung ist nicht vorhanden',
    txt_global_std_wearebuildingyourdemoapp: 'Wir bauen Ihre Demo-App',
    txt_global_std_reportuser: 'Benutzer melden',
    txt_global_std_lendings: 'Ausleihe',
    txt_global_std_entervalidphone: 'Geben Sie eine Telefonnummer mit mindestens 6 Ziffern ein',
    txt_global_std_idriveforaridesharingcompanywillyouinsuremyvehicle: 'Ich fahre für ein Mitfahrunternehmen, versichern Sie mein Fahrzeug?',
    txt_global_std_youpurchasedcdw5timestoyourbookingyouhaveearned100points: 'Sie haben CDW 5 Mal bei Ihrer Buchung gekauft. Sie haben 100 Punkte verdient.\n',
    txt_global_std_WelcomeLetter_Hello: 'Hallo',
    txt_global_std_enteroptionalactivationcode: 'Optionalen Aktivierungscode eingeben',
    txt_global_std_yourapikey: 'Ihr API-Schlüssel',
    txt_global_std_settingshasbeensaved: 'Einstellungen wurden gespeichert',
    txt_global_std_loginwithoutaccount: 'Melden Sie sich ohne Konto an',
    txt_global_std_participantsfor: 'Teilnehmer für',
    txt_global_std_yourfinalscorewillbecalculatednexttime: 'Ihr Endergebnis wird bei der nächsten Verbindung berechnet',
    txt_global_std_whenyouhavecompletedyourfirstdriveyouwillseeithere: 'Wenn Sie Ihre erste Fahrt abgeschlossen haben, werden Sie es hier sehen.',
    txt_global_std_turnonyourphonesbluetooth: 'Schalten Sie das Bluetooth Ihres Telefons ein.',
    txt_global_std_removeuser: 'Benutzer entfernen',
    txt_global_std_youneedtoactivateprivacysettings: 'Sie müssen die Datenschutzeinstellungen aktivieren',
    txt_global_std_covercubecollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: 'Covercube sammelt Standortdaten, um die Fahrbewertung auch dann zu ermöglichen, wenn die App geschlossen oder nicht verwendet wird.',
    txt_global_std_MikkelsenChamp: 'IRC-Champion 2011 & 2012. 3. Platz in der Rallye-Weltmeisterschaft (2014, 2015 & 2016). 3 Siege in der Rallye-Weltmeisterschaft. War Teil der FIA Young Driver Excellence Akademie',
    txt_global_std_drivewascancelledbeforeitwasvalidandwillbediscarded: 'Das Laufwerk wurde abgebrochen, bevor es gültig war, und wird verworfen',
    txt_global_std_rwmconnecttocar: 'Mit dem Auto verbinden',
    txt_global_std_insnumber: 'Versicherungsnummer',
    txt_global_std_p2ptotalexpenses: 'Gesamtausgaben',
    txt_global_std_theusermustacceptaninvitationtothegroupfirst: 'Der Benutzer muss zuerst eine Einladung in die Gruppe annehmen.',
    txt_global_std_p2pfaq24a: 'Es gibt vier Methoden, die Sie frei wählen können.\n' +
      '(Gesamtkosten: KINTO-Monatsgebühr plus zusätzliche Kosten für jedes Mitglied)\n' +
      '\n' +
      '1. "Nutzungsverhältnis" Berechnen Sie das Verhältnis der Nutzungszeit jedes Mitglieds zur monatlichen Gesamtzeit (Beispiel Juli: 24 Stunden x 31 Tage = 744 Stunden) und multiplizieren Sie es mit den Gesamtkosten.\n' +
      '2. "Nutzungszeit" Teilen Sie die Gesamtkosten durch die Gesamtnutzungszeit, um den Einheitspreis der Nutzungszeit zu berechnen. Multiplizieren Sie jede Nutzungszeit.\n' +
      '3. „Gleiche Aufteilung“ Die Gesamtkosten werden gleichmäßig durch die Anzahl der Personen in der Gruppe geteilt.\n' +
      '4. "Kilometerstand" Berechnen Sie den Einheitspreis für den Kilometerstand, indem Sie die Gesamtkosten durch den Gesamtkilometerstand des Monats teilen. Multiplizieren Sie Ihren Kilometerstand.',
    txt_global_std_active: 'Aktiv',
    txt_global_std_cimage43h: 'Vermeiden Sie das Anfahren und einen abrupten Stop bei Stau',
    txt_global_std_policynumberwassaved: 'Die Richtliniennummer wurde gespeichert',
    txt_global_std_p2pminutes: 'Std',
    txt_global_std_createddate: 'Erstellungsdatum',
    txt_global_std_activecompetitions: 'Aktive Wettbewerbe',
    txt_global_std_p2pfaq17q: 'Wie füge ich Freunde hinzu?',
    txt_global_std_chooseoneoption: 'Wählen Sie eine Option',
    txt_global_std_howdoiconnecttomycarsbluetooth: 'Wie verbinde ich mich mit dem Bluetooth meines Autos?',
    txt_global_std_viewandredeem: 'Anzeigen und einlösen',
    txt_global_std_insureddistance_used_90__2: 'Bald leer! Erhöhen Sie die jährliche Laufleistung hier.',
    txt_global_std_translationtool_upsell_pitch_bulletpoint3: 'Positionieren Sie Ihre Produkte vor einem größeren Publikum',
    txt_global_std_ChicheritMusic: 'Demnächst...',
    txt_global_std_whenyouarereadytodrive: 'Wenn Sie bereit sind zu fahren',
    txt_global_std_keepingyourmindandeyesontheroadandtrafficaroundyouisvitalforyoursafetyandthesafetyofotherslookingwellaheadandscanningtheenvironmentwillhelpyoutoanticipatewhatotherroadusersmightdonew: 'Ihre Gedanken und Augen auf die Straße und den Verkehr um Sie herum zu richten, ist für Ihre Sicherheit und die Sicherheit anderer von entscheidender Bedeutung. Wenn Sie weit vorausschauen und die Umgebung scannen, können Sie vorhersehen, was andere Verkehrsteilnehmer tun könnten.',
    txt_global_std_couldhavesaved: 'Könnte gespeichert haben',
    txt_global_std_level123: 'Niveau',
    txt_global_std_theapikey: 'Der API-Schlüssel',
    txt_global_std_yougetthiswhenyouhaveachieved5tripswithascorebetterthan3point3: 'Sie erhalten dies, wenn Sie 5 Fahrten mit einer Punktzahl von mehr als 3,3 erreicht haben',
    txt_global_std_andspaces: 'und Räume.',
    txt_global_std_pressconfirmtoadjustyourmilagebonusplan: 'Drücken Sie "Bestätigen", um Ihren Meilenbonusplan anzupassen.',
    txt_global_std_OSolbergMileage: 'Kommt bald ',
    txt_global_std_settled: 'Erledigt',
    txt_global_std_group: 'Gruppe',
    txt_global_std_avgspeed7d: 'Durchschnittliche Geschwindigkeit 7D',
    txt_global_EnerfyLeaderboardTemplate_currleaguetext: 'Du bist in der Liga',
    txt_global_std_anotherverificationemailhasbeensentpleasecheckyourinbox: 'Eine weitere Bestätigungs-E-Mail wurde gesendet, bitte überprüfen Sie Ihren Posteingang',
    txt_global_std_PolicySchedule4: 'Policen-Nr',
    txt_global_std_keepingyourmindandeyesontheroadandtrafficaroundyouisvitalforyoursafetyandthesafetyofotherslookingwellaheadandscanningtheenvironmentwillhelpyoutoanticipatewhatotherroadusersmightdo: 'Ihre Gedanken und Augen auf die Straße und den Verkehr um Sie herum zu richten, ist für Ihre Sicherheit und die Sicherheit anderer von entscheidender Bedeutung. Wenn Sie weit vorausschauen und die Umgebung scannen, können Sie vorhersehen, was andere Verkehrsteilnehmer tun könnten.',
    txt_global_std_clickhere2: 'klicken Sie hier.',
    txt_global_std_feedbackinterval: '',
    txt_global_std_MSIG_InsuranceLetter44: 'Bei einem Unfall, der der AVG-Punktzahl unterliegt, gilt ein TIERED-Überschuss von bis zu 2.000,00 USD',
    txt_global_std_p2pthetemporarypasswordisonlyvalidfor72hourspleaserequestanewpassword: 'Das temporäre Passwort ist nur\n' +
      '72 Stunden gültig. Bitte fordern Sie ein neues Passwort an.',
    txt_global_std_joiningheatspleasewait: '',
    txt_global_std_yourmessagewillbesentat: 'Ihre Nachricht wird gesendet an:',
    txt_global_std_oncepairedyoullautomaticallymovetothenextstep: 'Nach der Kopplung gelangen Sie automatisch zum nächsten Schritt.',
    txt_global_std_youdroveextremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Du bist sehr gut gefahren! Ihr durchschnittlicher {scoretype} war eine Woche lang besser als {score}! Sie haben {points} Punkte verdient!',
    txt_global_std_pioneeronepageuserguide: 'Benutzerhandbuch',
    txt_global_std_checkifyouareconnectedcorrectobdreader: 'Prüfen Sie, ob Sie mit dem richtigen OBD-Lesegerät verbunden sind.',
    txt_global_std_joinaheat: 'Nehmen Sie an einer Herausforderung teil',
    txt_global_std_selectnewmileageselector: 'Neuen Kilometerstand auswählen',
    txt_global_std_setupcompanychallenge: 'Unternehmens-Challenge einrichten',
    txt_global_std_p2pclose: 'Schließen',
    txt_global_std_locked: '(Gesperrt)',
    txt_global_std_getyourdrivingscore: 'Holen Sie sich Ihre Fahrnote!',
    txt_global_std_competeasindividualsandinteams: 'Treten Sie als Einzelpersonen und in Teams an',
    txt_global_std_AbdullaDescription: 'Adel gewann die FIA CCR T2-Weltmeisterschaft mit Hakam Rabie als Beifahrer, nach einem Protest, der zu einem FIA-Schiedsgerich führte. In seiner ersten kompletten Saison im Cross-Country-Wettbewerb konnte Adel Hussein während einer erfolgreichen Kampagne in der härtesten Offroad-Meisterschaft der Welt Siege in Portugal, Abu Dhabi und Spanien für sich verbuchen.',
    txt_global_std_p2pcontactsupport: 'Kontaktieren Sie Support',
    txt_global_std_eventcreator: 'Ereignisersteller',
    txt_global_std_leaderboards: 'Ranglisten ',
    txt_global_std_consentInfo: 'Ja = Treten Sie uns von Anfang an bei, treten Sie unserer Community bei und werden Sie Teil von Tryg Drive. Sobald Sie Ihre Einwilligung erteilt haben, können Sie an unseren Wettbewerben teilnehmen und vollen Zugriff auf die Funktionen der App erhalten. Tryg kann mich per E-Mail, in der Tryg Drive-App und per SMS kontaktieren, um Neuigkeiten und Sonderangebote von Tryg A / S zu Versicherungen, Sachversicherungsprodukten, Wettbewerben und anderen Trygs-Diensten zu erhalten. Sie können jederzeit einfach und kostenlos Marketinganfragen stellen. Nein = Sie haben Ihre &quot;Einwilligung&quot; in den Einstellungen nicht aktiviert. Wenn &quot;Zustimmung&quot; nicht aktiviert ist, können wir nicht alle aufregenden Wettbewerbe und Tipps, die in der Tryg Drive-App bereitgestellt werden, fortlaufend teilen.',
    txt_global_std_howmanycarswereinvolved: 'Wie viele Autos waren beteiligt?',
    txt_global_std_communicationtool: 'Kommunikationswerkzeug',
    txt_global_std_nothingtoshowhere: 'Hier gibt es nichts zu zeigen',
    txt_global_std_MSIG_InsuranceLetter5: 'Gesetz über den Straßenverkehr, 1987 (Malaysia)',
    txt_global_std_kintorefer50friendsunlocked: 'Vielen Dank! Sie haben 50 Kinto-Benutzer empfohlen. ¥ 5000 wurden an Ihre Kinto-Brieftasche gesendet.',
    txt_global_std_whenusingthiscodeorlinkuserwillbeonboardedtoyourenerfyglobalteam: 'Bei Verwendung dieses Codes oder Links wird der Benutzer in Ihr Enerfy Global-Team aufgenommen.',
    txt_global_std_avgtrips7d: 'Durchschnittliche Fahrten 7D',
    txt_global_std_whereismycar: 'Wo ist mein Auto?',
    txt_global_std_donthaveanaccount: 'Sie haben kein Konto?',
    txt_global_std_invitecolleaguesorevenfriendsandfamilytoyourchallengetomakeyourcompanychallengemoreinteresting: 'Laden Sie Kollegen oder sogar Freunde und Familie zu Ihrer Challenge ein, um Ihre Company Challenge interessanter zu gestalten!',
    txt_global_std_customfilters: 'BENUTZERDEFINIERTE FILTER',
    txt_global_std_tipcity3DESC: 'Der blaue Autofahrer wird natürlich langsamer und fährt sanft über die Kreuzung. Der rote Autofahrer beschleunigt und bremst vor der Überfahrt zu stark.',
    txt_global_std_pleasewaitwhileweauthenticateyouremail: 'Bitte warten Sie, während wir Ihre E-Mail authentifizieren',
    txt_global_std_doyouwanttojoinname: 'Möchten Sie {name} beitreten?',
    txt_global_std_whendrivingonmotorwaystoomanydriverspumptheacceleratorpedalupanddowninaneverendingpattern100kmhto110kmhthen110kmhto100kmhandsoonthistypeofdrivingusesunnecessaryfuelkeepingasafesuitablefollowingdistancecanhelpavoidthetemptationtovaryspeedunnecessarilynew: 'Beim Fahren auf Autobahnen drücken zu viele Autofahrer das Gaspedal in einem endlosen Muster auf und ab: 100 km/h auf 110 km/h, dann 110 km/h auf 100 km/h und so weiter! Diese Art des Fahrens verbraucht unnötig Kraftstoff. Das Einhalten eines sicheren, angemessenen Abstands kann dazu beitragen, der Versuchung zu widerstehen, die Geschwindigkeit unnötig zu variieren.',
    txt_global_std_reportaccident: 'Unfall melden ',
    txt_global_std_closingthispopupyouconfirmyouhaveredeemedyourpoints: 'Wenn Sie dieses Popup schließen, bestätigen Sie, dass Sie Ihre Punkte eingelöst haben.',
    txt_global_std_aryoudrivinganelectricorobd: '電気自動車を運転していますか、または、車載デバイスを取り付けていますか？',
    txt_global_std_previewheader: 'Vorschau',
    txt_global_std_activationletter_text15sidekick: 'Mit der Sidekick-App erfahren Sie mehr über Ihren eigenen Fahrstil und wie Sie ein sicherer Fahrer werden können. Darüber hinaus können Sie Geld für die Versicherung sparen, indem Sie eine gute Fahrbewertung erzielen!',
    txt_global_std_opened: 'Geöffnet',
    txt_global_std_subheats: '?',
    txt_global_std_thesevehiclesarealreadyactivated: 'Diese Fahrzeuge sind bereits aktiviert:',
    txt_global_std_avgmaxspeed7d: 'Durchschnittliche Höchstgeschwindigkeit 7D',
    txt_global_std_teamco2: 'Team CO2-Einsparung',
    txt_global_std_skillcomparison: 'Vergleich der Fähigkeit',
    txt_global_std_allowlocationaccess: 'Standortzugriff zulassen',
    txt_global_std_officialtimingpartner: 'OFFIZIELLER TIMING-PARTNER',
    txt_global_std_createnew: 'Erstelle neu',
    txt_global_std_saved: 'Gespeichert',
    txt_global_std_doitlater: 'Ich werde es später machen',
    txt_global_std_yourinsurance: 'Ihre Versicherung',
    txt_global_std_yougotthecodefrommail: 'Sie haben den Code per E-Mail erhalten, als Sie die Versicherung unterschrieben haben.',
    txt_global_std_gallonus: 'Gallon US',
    txt_global_std_vehicle2: 'Fahrzeug',
    txt_global_std_pleaseenteryouremailaddress: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
    txt_global_std_SignupFia_Text2: 'Sie sind auf der Liste, um Neuigkeiten und Updates aus erster Hand über die FIA ​​Smart Driving Challenge zu erhalten, ein Wettbewerb, wobei intelligente Fähigkeiten am Steuer belohnt werden. ',
    txt_global_std_webclaims: 'Web-Ansprüche',
    txt_global_std_xxperofourcustomers: '{perc} % unserer Kunden verwenden die Automatik aufgrund der Benutzerfreundlichkeit.',
    txt_global_std_groups: 'Gruppen',
    txt_global_std_hereisapreviewofhowyourappwilllookifyouarehappywiththeresultpleasecontinuetopublishyourdemoapp: 'Hier ist eine Vorschau, wie Ihre App aussehen wird. Wenn Sie mit dem Ergebnis zufrieden sind, veröffentlichen Sie Ihre Demo-App bitte weiter.',
    txt_global_std_amounttopay: 'Zu zahlender Betrag',
    txt_global_std_cimage37d: 'Untersuchen Sie den Rollabstand bei verschiedenen Geschwindigkeiten.',
    txt_global_std_forthebestexperience: 'Für das beste Erlebnis',
    txt_global_std_BeforeFirstBill_Text1: 'In Kürze werden wir Ihre Kreditkarte mit den Kosten für Ihre Autoversicherung belasten. Wir haben die häufigsten Fragen zusammengestellt, die unsere Kunden haben, wenn sie ihre erste Rechnung erhalten.',
    txt_global_std_clickontheareaswhereyourvehiclehasbeendamages: 'Klicken Sie auf die Bereiche, in denen Ihr Fahrzeug beschädigt wurde',
    txt_global_std_riskindex_upsell_pitch_bulletpoint2: 'Die Auswirkungen politischer, gesundheitlicher und wirtschaftlicher Veränderungen auf das Fahrrisiko',
    txt_global_std_bodyrepair1panel: 'Karosseriereparatur 1 Platte',
    txt_global_std_tripdata: 'Reisedaten',
    txt_global_std_premiumwithzipdrivingriskindex: 'Premium mit Zip-Driving-Risikoindex',
    txt_global_std_3selectyourcarinthelistbelow: '3. Wählen Sie Ihr Auto in der folgenden Liste aus.',
    txt_global_std_aresetlinkhasbeensenttouser: 'Ein Reset-Link wurde an den Benutzer gesendet',
    txt_global_std_idrivetomuch2: 'I drive too much',
    txt_global_std_doyouwanttochallengename: 'Möchten Sie {name} herausfordern?',
    txt_global_std_p2pbookingsummary: 'Buchungsübersicht',
    txt_global_std_weekw: 'w',
    txt_global_std_ifyouhaveaninsuranceyoucanfindyourpolicynumberinyourinsurancedocuments: 'Wenn Sie versichert sind, finden Sie Ihre Versicherungsnummer in Ihren Versicherungsdokumenten.\n' +
      'Sie haben es gerade nicht zur Hand? Keine Bange! Sie können es später in den Einstellungen hinzufügen.',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Du bist wirklich gut gefahren! Ihr durchschnittlicher {scoretype} war eine Woche lang besser als {score}! Sie haben {points} Punkte verdient!',
    txt_global_std_clickdownbelowtoseehowitwent: 'Klicken Sie unten, um zu sehen, wie es gelaufen ist',
    txt_global_std_set_up_automatic_start_stop: 'Einrichtung: Automatischer Start & Stopp',
    txt_global_std_youhavebeeninvitedtojoinagroup: 'Sie wurden eingeladen, einer Gruppe beizutreten',
    txt_global_std_HusseinAbdullaMileage: 'Nichts Spezielles',
    txt_global_std_speedcostanalysis: 'Geschwindigkeitsanalyse',
    txt_global_std_co2reduced: 'CO₂ reduziert',
    txt_global_std_cimage39h: 'Gehen Sie früher vom Gas - wagen Sie mehr zu rollen',
    txt_global_std_youdroveextremelywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: 'Du bist sehr gut gefahren! Ihr Durchschnitt war eine Woche lang besser als {score}! Sie haben {points} Punkte verdient!',
    txt_global_std_p2pstart: 'Start:',
    txt_global_std_searchforusers: 'Suchen Sie nach Benutzern',
    txt_global_std_distancedriven: 'Strecke gefahren',
    txt_global_std_drivertrainingrecommended: 'Fahrertraining empfohlen',
    txt_global_std_iunderstandthatthiswillcompletewipeallofmyaccount: 'Ich verstehe, dass dies alle meine Kontodaten und Fahrdaten vervollständigt.',
    txt_global_std_ownerdidnotgetthatnotification: 'Der Besitzer hat diese Benachrichtigung nicht erhalten',
    txt_global_std_p2pwaitingtoconfirmthespecifiedprice: 'Warten auf Bestätigung des angegebenen Preises.',
    txt_global_std_cannotbeempty: 'Kann nicht leer sein',
    txt_global_std_acceptconnectioninapp: 'Verbindung in App annehmen',
    txt_global_std_thisuserwillbeaddedtoyourplanatanadditionalcostofuserfeeperuser: 'Dieser Benutzer wird Ihrem Plan gegen eine zusätzliche Gebühr von {userfee} pro Benutzer hinzugefügt.',
    txt_global_std_theusermustacceptaninvitationtotheteam: 'Der Benutzer muss eine Einladung zum Team annehmen.',
    txt_global_std_acheivementcannotbedeleted: ' Bitte beachte, dass dieser Erfolg nicht gelöscht werden kann.',
    txt_global_std_refer10friendsachievementunlocked: 'Sie haben 10 Freunde empfohlen! Nett! Sie haben 300 Punkte verdient!',
    txt_global_std_female: 'Weiblich',
    txt_global_std_member1: 'Mitglied',
    txt_global_std_viewloyaltypoints1: 'Treuepunkte anzeigen',
    txt_global_std_manageusersettingsandsupporthereusethequickaccessbuttonsbelowandnavigatebetweenusersbysearchingforthemintheusersview: 'Verwalten Sie hier Benutzereinstellungen und Support. Verwenden Sie die Schnellzugriffsschaltflächen unten und navigieren Sie zwischen Benutzern, indem Sie in der Benutzeransicht nach ihnen suchen.',
    txt_global_std_afteryourfirstnumberofmonthsmonthperiodourappwilltrackyourscoresandprovidearatebasedonyourdrivinghabitsifyouhaveafewbadscoresyouhaveanentirenumberofmonths2monthstoimproveyouwillreceiveupdatesalongthewaywitheachtripadvisingyouofyourpotentialsavingsorifyourneedtoprovidemorefocuswithyourdrivinghabits: 'Nach Ihrem ersten {numberOfMonths}-Monats-Zeitraum verfolgt unsere App Ihre Punktzahlen und bietet einen Preis basierend auf Ihren Fahrgewohnheiten. Wenn Sie ein paar schlechte Ergebnisse haben, haben Sie ganze {numberOfMonths2} Monate Zeit, um sich zu verbessern. Sie erhalten unterwegs bei jeder Fahrt Updates, die Sie auf Ihre Einsparpotenziale hinweisen oder wenn Sie sich mehr auf Ihre Fahrgewohnheiten konzentrieren müssen.',
    txt_global_std_sunday: 'Sonntag',
    txt_global_std_youwillbereadysoon: 'Sie werden bald fertig sein!',
    txt_global_std_stepcurrentstepoftotalsteps: 'Schritt {currentStep} von {totalSteps}',
    txt_global_std_advancedadmin: 'Erweiterter Administrator',
    txt_global_std_teamnames: 'Teamnamen',
    txt_global_std_recoverpassword: 'Passwort wiederherstellen',
    txt_global_std_dontyouhaveanaccountwithenerfy: 'Haben Sie kein Konto bei {appName}?',
    txt_global_std_cimage1d: 'Geschwindigkeit verringern',
    txt_global_std_seehowmuchyoucouldsave: 'Sehen Sie, wie viel Sie sparen könnten',
    txt_global_std_showmehow: 'Zeig mir wie',
    txt_global_std_yourownbrandedworkspaceopenforalldriverthatwishtoparticipate: 'Ihr eigener Markenarbeitsbereich steht allen Fahrern offen, die teilnehmen möchten',
    txt_global_std_friendstothe: 'Freunde zum',
    txt_global_std_yougetthiswhenyoufirstachieveascoreabove4point4: 'Sie erhalten dies, wenn Sie zum ersten Mal eine Punktzahl über 4,4 erreichen',
    txt_global_EnerfySettingsTemplate_other: 'Andere',
    txt_global_std_ConfirmFia_Text1: 'Gratulation',
    txt_global_std_leaderboardpositionincrease: 'Ihre Leaderboard-Position hat sich erhöht!',
    txt_global_std_KristofferssonDescription: 'Johan begann seine Karriere bei der Internationalen Super Serie mit dem schwedischen Team, wo er beeindruckte und zur Touring Car Championship (STCC) wechselte, wo er 2002 in einer Einzelsaison gewann. Danach wechselte zur Saison 2013 - 2014 zum European RX und 20,15 zur FIA WRX, wo er 5 Rallyes gewann und in der Gesamtwertung den dritten Platz belegte. Er wurde 2016 hinter seinem Landsmann Mattias Ekström Zweiter und gewann 2017.',
    txt_global_std_settings: 'Einstellungen ',
    txt_global_std_alertdriveservicetitle: 'Laufwerksservice',
    txt_global_std_warikan_onboarding5_2: '株式会社KINTO',
    txt_global_std_p2pbookingmessages: 'Nachrichten buchen',
    txt_global_std_moreinformation: 'Mehr Informationen',
    txt_global_std_hellothere: 'Hallo!',
    txt_global_std_ifyourdriverhasproblemswithreceivingtheirpasswordyoucanviewitherenotethatthecurrentpasswordresetswhenyouclickthebutton: 'Wenn Ihr Fahrer Probleme hat, sein Passwort zu erhalten, können Sie es hier einsehen. Beachten Sie, dass das aktuelle Passwort zurückgesetzt wird, wenn Sie auf die Schaltfläche klicken.',
    txt_global_std_profilepreferences: 'Profileinstellungen',
    txt_global_std_continue: 'Weiter',
    txt_global_std_tiphighway2DESC: 'Der blaue Autofahrer fährt gut geplant und hält ein gutes Tempo und einen guten Abstand zu den umliegenden Autos. Der rote Autofahrer verschmilzt ständig und steuert das Auto vor sich an.',
    txt_global_std_p2pcarsharingismorefunwhenyouinviteyourfriendsandfamily: 'Carsharing macht mehr Spaß, wenn Sie Ihre Freunde und Familie zum Mitmachen einladen!',
    txt_global_std_failedtogetreviewlist: 'Überprüfungsliste konnte nicht abgerufen werden',
    txt_global_std_noofparticipants: 'Anzahl der Teilnehmer',
    txt_global_std_downloadapp: 'Lade App herunter',
    txt_global_all_weekday: 'Wochentag',
    txt_global_std_choosethecarontheappscreenandwaituntilyougetnotificatedthatthedevicesarepaired: 'Wählen Sie das Auto auf dem App-Bildschirm aus und warten Sie, bis Sie benachrichtigt werden, dass die Geräte gekoppelt sind',
    txt_global_std_statusinitdriving: 'Verbunden',
    txt_global_std_p2puploadpicturesofyourcartodocumentanyscratchesordents: 'Laden Sie Bilder in Ihr Auto hoch, um Kratzer oder Dellen zu dokumentieren.',
    txt_global_std_p2pfinishbooking: 'Buchung beenden',
    txt_global_std_acceptedpermissions: 'Akzeptierte Berechtigungen',
    txt_global_std_publishachievementpopuptext: 'Beim Veröffentlichen eines Erfolgs wird sein Sperrstatus für Benutzer in der App sichtbar. Sobald ein Benutzer ein Abzeichen erhalten hat, wird es in seinem entsperrten Zustand angezeigt.',
    txt_global_std_highspeedconditions: 'Hochgeschwindigkeitsbedingungen',
    txt_global_std_eastzone: 'Ostzone',
    txt_global_std_denneerndvendigforatappenkanvidehvornrduholderstilleeribevgelseaccelerererellerbremser: "Denne er nødvendig for at app'en kan vide, hvornår du holder stille, er i bevægelse, accelererer eller bremser.",
    txt_global_std_warikan_onboarding12: 'Tel: 0120-075-912',
    txt_global_std_HansenChamp: '',
    txt_global_std_communication_upsell_pitch_bulletpoint3: 'Verbinden Sie sich mit Benutzern auf ihren bevorzugten Kanälen',
    txt_global_std_VilliersWhyjoin: 'Weil wir alle unser Möglichstes tun müssen, um unsere Straßen sicherer zu machen.',
    txt_global_std_p2psaveandclose: 'Speichern und schließen',
    txt_global_std_selectanotherdevice: 'Wähle ein anderes Gerät aus',
    txt_global_std_adduser: 'Benutzer hinzufügen',
    txt_global_std_pioneersmartdrivingchallenge: 'Pioneer Smart Driving Challenge',
    txt_global_std_exportpdf: 'PDF exportieren',
    txt_global_std_creatingaccountwiththirdpartyname: 'Konto mit {Name Dritte Person} erstellen',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint1: 'Verstehen Sie die Faktoren, die das Risikoniveau bestimmen',
    txt_global_std_locationwithbest: 'Standort mit bestem Verbesserungspotential',
    txt_global_std_carbonclean: 'Carbon sauber',
    txt_global_std_march: 'März',
    txt_global_std_3rddate: '3',
    txt_global_std_p2ppushbodynewfriend: 'Du bist jetzt mit {name} befreundet.',
    txt_global_std_people: 'Menschen Personen',
    txt_global_std_MSIG_InsuranceLetter20: '1) Der Versicherte',
    txt_global_std_cimage15d: 'Seien Sie sich bewusst, dass die kleinen Dinge den Unterschied machen.',
    txt_global_std_youractivatedkeys: 'Ihre aktivierten Schlüssel',
    txt_global_std_riskydecisionbetweentrips: 'Riskante Entscheidung zwischen Reisen',
    txt_global_std_youhavenowachievedyourbestscoresofar: 'Sie haben jetzt Ihre bisher beste Punktzahl erreicht (über 4,4)',
    txt_global_std_p2pshareakintocar: 'Teilen Sie ein KINTO-Auto',
    'txt_global_std_Al-KuwariInterests': '',
    txt_global_std_foratappenskalfungerekorrektharvibrugforadgangtildintelefonslokationsamtmotionogfitnessdata: 'Für alle App-Skalfunktionen korrekt, har vi brug for adgang to din phones location samt motion og fitness data.',
    txt_global_std_p2paddexpenses: 'Kosten hinzufügen',
    txt_global_std_nomapcoordinatesavailableforthisrun: 'Für diese Fahrt sind keine Kartenkoordinaten verfügbar',
    txt_global_std_cimage22h: 'Vermeiden Sie den Tempomat',
    txt_global_std_emergencycontact: 'Notfallkontakt',
    txt_global_std_repetition1: 'Wiederholung',
    txt_global_std_p2pbookingrequests: 'Buchungsanfragen',
    txt_global_std_addtosubscription: 'Zum Abonnement hinzufügen',
    txt_global_std_kintofaq6a1: 'Stellen Sie sicher, dass Sie alle Schritte unter „Mit Auto verbinden“ ausführen. Bitte mailen',
    txt_global_std_enteravalidphonenumber: 'Geben Sie eine gültige Telefonnummer ein.',
    txt_global_std_wednesday: 'Mittwoch',
    txt_global_std_describeaccident: 'Unfall beschreiben',
    txt_global_std_pointslabel: 'Punkte',
    txt_global_std_drivingskills: 'Fahrkönnen',
    txt_global_std_takecontrolofyouraccountandprofile: 'Übernehmen Sie die Kontrolle über Ihr Konto und Ihr Profil.',
    txt_global_std_MSIG_Onboarding1: 'Herzlich willkommen!',
    txt_global_std_loyaltyoverview: 'Loyalitätsübersicht',
    txt_global_std_warikanchallengerulesnotes: '【Anmerkungen】\n' +
      '・Durch den Beitritt werden Ihr Spitzname und Ihr Symbol anderen Benutzern angezeigt. Indem Sie auf „Am Wettbewerb teilnehmen“ klicken, stimmen Sie zu, an die Bedingungen dieser Vereinbarung gebunden zu sein. Sie können Ihren Spitznamen auch auf Ihrem Profileinstellungsbildschirm ausblenden.\n' +
      '・Die Entfernung muss länger als 1 km pro Fahrt sein.\n' +
      '・Die gleiche Fahrt wird bis zu zweimal am Tag gewertet.\n' +
      '・Die Gesamtstrecke muss mehr als 1 km betragen.\n' +
      '・Heat steht für den Punktedurchschnitt und Total für den Durchschnitt der besten 10 % deiner Fahrten.',
    txt_global_std_VilliersBesttip: 'Seien Sie stets wachsam und vorausschauend und achten darauf, was andere Fahrer können. Sie könnten direkt vor Ihnen nach rechts abbiegen,  Sie müssen also aufmerksam sein, um rechtzeitig anzuhalten oder ihnen auszuweichen.',
    txt_global_std_nopleasebeadvisedthattheenerfyapp: 'No. Please be advised that the Enerfy app needs to be installed on your phone and that you need to be logged in for your drives to get registered. You also need to have Bluetooth activated on your phone in order to connect.',
    txt_global_std_p2punpaid: 'Unbezahlt',
    txt_global_std_youhavebeendrivingwithtrygdriveforayear: 'Sie fahren seit einem Jahr mit TrygDrive!',
    txt_global_std_latest: '',
    txt_global_std_co2unlockedkey: 'Gut gemacht! Sie haben bei dieser Fahrt über {co2value} % CO₂ eingespart, indem Sie umweltfreundlich gefahren sind.',
    txt_global_std_PolicySchedule19: 'Bruttoprämie',
    txt_global_std_afewsimplereasonswhyfiasdcmakesadifferenceforyourbusiness: 'Ein paar einfache Gründe, warum FIA SDC einen Unterschied für Ihr Unternehmen macht',
    txt_global_std_bestregards: 'Mit freundlichen Grüßen',
    txt_global_std_distanceunit: 'Entfernungseinheit',
    txt_global_std_pressthedrivebuttonintheapp: 'Drücke in der App den Drive-Button',
    txt_global_std_at: 'bei',
    txt_global_std_events_overview_season: 'Jahreszeit',
    txt_global_std_p2p2totalcosttosplit: 'Gesamtkosten zum Aufteilen',
    txt_global_std_youpositiononleaderboardwasincreased: 'Ihre Position auf der Rangliste wurde erhöht!',
    txt_global_std_responses: 'Antworten',
    txt_global_std_avglevel123: 'Durchschn. Niveau',
    txt_global_std_PolicySchedule34: 'Registrierungsnr',
    txt_global_std_companyname: 'Name der Firma',
    txt_global_std_yourealmostdonenowwejustneedtoverifyyouremailaverificationlinkhasbeensentto: 'Du bist fast fertig! Jetzt müssen wir nur noch Ihre E-Mail verifizieren. Ein Bestätigungslink wurde gesendet an:',
    txt_global_std_startatrip: 'Beginne eine Fahrt',
    txt_global_std_ayouwillhave60daysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithcovercubewithalapseincoverage: 'A: Sie haben ab dem Ablaufdatum 60 Tage Zeit, um eine Zahlung zu leisten und Ihren Versicherungsschutz bei Covercube mit Erlöschen des Versicherungsschutzes fortzusetzen.',
    txt_global_std_done: 'Fertig',
    txt_global_std_MSIG_InsuranceLetter2: 'KOPIEREN',
    txt_global_std_rwmnotnow: 'Nicht jetzt',
    txt_global_std_foratopretteforbindelsemellemdinbilogappnskaldergivesadgangtilbluetooth: 'Denn bei oprette forbindelse mellem din bil og appén skal der does adgang til bluetooth.',
    txt_global_std_youvsecochampions: 'Sie gegen Öko-Champions',
    txt_global_std_carsharingcompanydescription: 'Kurzer Satz dazu.',
    txt_global_std_circle: 'Kreis!',
    txt_global_std_p2pfaq1q: 'Warum sind einige Schaltflächen in der App ausgegraut?',
    txt_global_std_selectlanglabel: 'Sprache auswählen',
    txt_global_std_gennemsnitsbilist: 'Gennemsnits bilist',
    txt_global_std_loyaltysystemdesc: 'Bitte beachten Sie, dass Ihre Vergünstigungen auf den Endergebnissen der vorherigen Treueperiode basieren. Punkte werden durch Erfolge gesammelt und zu Beginn eines neuen Zeitraums zurückgesetzt.',
    txt_global_std_connectiontype: 'Verbindungstyp',
    txt_global_std_manual_start_and_stop_requires_access_to_location_services_and_motion_fitness: 'Manuelles Starten und Stoppen erfordert Zugriff auf Ortungsdienste (GPS) und Bewegung & Fitness.',
    txt_global_std_pluginthedeviceinyourcar: 'Stecken Sie das Gerät in Ihr Auto.',
    txt_global_std_yourdrivingskills: 'Ihr Fahrkönnen',
    txt_global_std_keepcollectingloyaltypointseventhoughsomeoneelseisdrivingyourcar: 'Sammeln Sie weiterhin Treuepunkte, auch wenn jemand anderes Ihr Auto fährt!',
    txt_global_std_anyspeedgainisanaccelerationnomatterhowsmallsoplanandmakegooduseofeveryspeedincrease: 'Jeder Geschwindigkeitsgewinn ist eine Beschleunigung, egal wie klein. Planen Sie also jede Geschwindigkeitssteigerung ein und nutzen Sie sie gut.',
    txt_global_std_validatedactivationcode: 'Validierter Aktivierungscode',
    txt_global_std_p2pchoosehowtosplitcost: 'Wählen Sie aus, wie die Kosten aufgeteilt werden sollen',
    txt_global_std_yourlinkshouldbeavailableinyourinboxinminutespleaseremembertocheckyourjunk: 'Ihr Link sollte in {number} Minuten in Ihrem Posteingang verfügbar sein. Bitte denken Sie daran, Ihren Müll zu überprüfen.',
    txt_global_std_userwithoutinsurance: '{Appname} -User ohne Versicherung',
    txt_global_std_successfullyunpublishedlanguage: 'Erfolgreich unveröffentlichte Sprache',
    txt_global_std_p2pfaq24q: 'Wie können Sie die Kosten aufteilen?',
    txt_global_std_nointernetconnection2: 'Keine Internetverbindung!',
    txt_global_std_bluetoothlink: 'Bluetooth link',
    txt_global_std_OgierMusic: 'Kommt bald ',
    txt_global_std_congratulations2: 'Herzliche Glückwünsche',
    txt_global_std_events_overview_today: '?',
    txt_global_std_userhasbeenscheduledforremoval: 'Die Entfernung des Nutzers {{email}} wurde um {{timestamp}} geplant.',
    txt_global_std_fileaclaimdirectlyintheappbyclickingfileclaimlinkorbycallingphonenumber: 'Reichen Sie einen Anspruch direkt in der App ein, indem Sie auf {fileClaimLink} klicken oder {phoneNumber} anrufen.',
    txt_global_std_p2ppushtitleexpensesdenied: 'Expenses denied',
    txt_global_std_HSolbergMileage: 'Ich liebe es, auf Schnee und Eis zu fahren, das ist der ultimative Fahrspaß',
    txt_global_std_info: 'Info ',
    txt_global_std_newpasswordsaved: 'Neues Passport gespeichert ',
    txt_global_std_texttofriend: 'Text an Freund',
    txt_global_std_connected2: 'In Verbindung gebracht',
    txt_global_std_p2piwanttoborrowakinto: 'Ich möchte einen Kinto ausleihen',
    txt_global_std_welcometoenerfyfirstname: 'Willkommen zu {appName}',
    txt_global_std_charactercountexceeded: 'Zeichenanzahl überschritten',
    txt_global_std_roadconditionswithbest: 'Straßenzustand mit bestem Verbesserungspotential',
    txt_global_std_opensettingsonyourphoneandmakesuretohavebluetoothactivated: 'Öffnen Sie die Einstellungen auf Ihrem Telefon und vergewissern Sie sich, dass Bluetooth aktiviert ist.',
    txt_global_std_compareriskandco2levelsacrossyourorganization: 'Vergleichen Sie Risiken und CO2-Werte in Ihrem Unternehmen',
    txt_global_std_yourmessagehasbeencreated: 'Ihre Nachricht wurde erstellt!',
    txt_global_std_redeemed: 'Eingelöst',
    txt_global_std_specialimagefilenameid: 'Benutzerdefiniertes Bild',
    txt_global_std_improveheader: 'Beste Serie',
    txt_global_std_yesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforagentinsurance: 'Ja, Sie können die App herunterladen und die Option „App ausprobieren“ auswählen. Sie können mit der App fahren und sehen, wie viel Sie sparen können, wenn Sie eine {agent}-Versicherung abschließen.',
    txt_global_std_select: 'Wählen',
    txt_global_std_p2pimakintoowner: 'Ich bin ein Kinto-Besitzer',
    txt_global_std_searchbynamegrouporaccounttype: 'Suchen Sie nach Name, Gruppe oder Kontotyp',
    txt_global_std_tripsinsight: 'Trips Einblick',
    txt_global_std_removeaccountpluspersonaldata: 'Konto + persönliche Daten entfernen',
    txt_global_std_drivertobeannounced: 'Fahrer wird noch bekanntgegeben',
    txt_global_std_dennekretur: 'Denne Köretur',
    txt_global_std_p2puploadreceipt: 'Laden Sie das Bild Ihrer Quittung hoch',
    txt_global_std_dailytrips: 'Tägliche Ausflüge',
    txt_global_std_thisisanexampletitle: 'Dies ist ein Beispieltitel',
    txt_global_std_invitationcode: 'Einladungscode',
    txt_global_std_p2pnamecancelledbooking: '{name} stornierte Buchung',
    txt_global_std_connecttocar: 'Mit dem Auto verbinden',
    txt_global_std_experiencethebenefits: 'Erleben Sie die Vorteile mit {appName}',
    txt_global_std_p2pfaq13a: '1.Gehen Sie zur Buchungszusammenfassung Ihrer Buchung.\n' +
      '2.Drücken Sie die Schaltfläche Buchung beenden.',
    txt_global_std_discountcodetermsconditions: 'Allgemeine Geschäftsbedingungen für Rabattcodes',
    txt_global_std_Fia_Receipt_Text6: 'Ihre Quittung:',
    txt_global_std_p2pfaq21q: 'Ich möchte die Mitglieder der Gruppe überprüfen.',
    txt_global_std_addemail: 'E-Mail hinzufügen',
    txt_global_std_Fia_Receipt_Text8: 'Ihre Bestellung',
    txt_global_std_p2pfaq25a: '1. Wählen Sie „Fahrdaten“ aus dem „Menü“.\n' +
      '2. Sie können die Daten Ihres Fahrverlaufs überprüfen.\n' +
      '3. Wenn Sie das Register „Daten teilen“ wählen, können Sie die Fahrdaten der Gruppe zusammen mit der Mitgliederhistorie überprüfen.',
    txt_global_std_driverslog: 'Fahrerprotokoll',
    txt_global_std_achievementcannotbedeleted: 'Bitte beachte, dass dieser Erfolg nicht gelöscht werden kann.',
    txt_global_std_format2: 'Format',
    txt_global_std_addexpenseserror: 'Bitte fügen Sie Ihre Ausgabenart, Kosten und Bild hinzu.',
    txt_global_std_invitedrivers: 'Fahrer einladen',
    txt_global_std_endtime: 'Endzeit',
    txt_global_std_2attachtheobddevicetothecableandplugitintothe12vpowercigarettelightersocketinyourcar: '2. Schließen Sie das OBD-Gerät an das Kabel an und stecken Sie es in die 12-V-Steckdose (Zigarettenanzünder) Ihres Autos.',
    txt_global_std_alreadyhaveaninsurance: 'Sie haben bereits eine Versicherung?',
    txt_global_std_weareproudyouwanttobe: 'Wir sind stolz darauf, dass Sie Teil von {appName} zu möchten!',
    txt_global_std_icannotconnectanswertext2: 'für Hilfe.',
    txt_global_std_invitefriends: 'Freunde einladen ',
    txt_global_std_successfullyaddedtogroups: 'Gruppen erfolgreich hinzugefügt',
    txt_global_std_yes: 'Ja',
    txt_global_std_joinenerfyandgetawarded: 'Mach bei Enerfy mit und lass dich auszeichnen!',
    txt_global_std_entert: 'Eingeben',
    txt_global_std_acceptfitnessmotion: 'Akzeptiere Fitness / Bewegung',
    txt_global_std_deepdiveintodrivers: 'Tauchen Sie tief in Fahrer und Versicherungsnehmer ein: Leistung, Unfallwahrscheinlichkeit, umweltbewusstes Fahren, Emissionen und mehr <br /> Sobald Sie einen Bericht aktiviert haben, können Sie ihn so oft herunterladen, wie Sie möchten.',
    txt_global_std_congratulationsyoujustclimbedtheleaderboardviewyourranking: 'Herzlichen Glückwunsch - Sie haben gerade die Bestenliste erklommen! Zeigen Sie Ihr Ranking an.',
    txt_global_std_ecoscorescore: 'Punktzahl Umweltbeswusstsein',
    txt_global_std_welcome: 'Willkommen!',
    txt_global_std_onlylettersaznumbers09andperiodsareallowed: 'Erlaubt sind nur Buchstaben (az), Ziffern (0-9) und Punkte (.).',
    txt_global_std_finalistsheat3: 'Finalisten - Heat 3',
    txt_global_std_username: 'Benutzername',
    txt_global_std_customnotification: 'Benutzerdefinierte Benachrichtigung',
    txt_global_std_friend: 'Freund ',
    'txt_global_std_Al-KuwariDescription': '',
    txt_global_std_checkyouremailformoredetails: 'Weitere Informationen finden Sie in Ihrer E-Mail.',
    txt_global_std_f_lanzquote: 'Wo die Leidenschaft liegt, fließt die Anstrengung leidenschaftlich',
    txt_global_std_loginorcreateaccounthere: 'Melden Sie sich hier an oder erstellen Sie ein Konto',
    txt_global_std_PSolbergMusic: 'demnächst',
    txt_global_std_bronzeusers: 'Bronze-Benutzer',
    txt_global_std_internalservererrorjsonnotvalid: 'Interner Serverfehler (JSON nicht gültig)',
    txt_global_std_cimage2d: 'Halten Sie mindestens 3 Sekunden bis zum nächsten Fahrzeug.',
    txt_global_std_loyaltynumber: 'Treuenummer',
    txt_global_std_newmobilitycompanydescription: 'Smarte On-Demand-Mobilität.',
    txt_global_std_anerroroccurredwhiletryingtousetheactivationkey: 'Beim Versuch, den Aktivierungsschlüssel zu verwenden, ist ein Fehler aufgetreten',
    txt_global_std_p2pselectafriendtoviewallbookingsoftheircar: 'Wählen Sie einen Freund aus, um alle Buchungen seines Autos anzuzeigen.',
    txt_global_std_mystatsthisperiod: 'Meine Statistik in diesem Zeitraum',
    txt_global_std_second: 'Sekunde',
    txt_global_std_calculationexample: 'Rechenbeispiel',
    txt_global_std_invitationlink: 'Einladungslink',
    txt_global_std_oilchange: 'Ölwechsel',
    txt_global_std_waxing: 'Wachsen',
    txt_global_std_teamco2savings: 'TEAM CO2-ERSPARNIS',
    txt_global_std_driversselected: 'Treiber ausgewählt',
    txt_global_std_ayescovercubeacceptsdriverswhoarelicensedinothercountries: 'A: Ja, Covercube akzeptiert Fahrer, die in anderen Ländern lizenziert sind.',
    txt_global_std_minutemin: 'Min',
    txt_global_std_sparknamekey: 'Funke!',
    txt_global_std_endsin: 'Endet in ',
    txt_global_std_p2phasspecifiedprice: ' hat einen Preis angegeben.',
    txt_global_std_consentInfoYes: 'Treten Sie uns von Anfang an bei, treten Sie unserer Community bei und werden Sie Teil von Tryg Drive. Sobald Sie Ihre Einwilligung erteilt haben, können Sie an unseren Wettbewerben teilnehmen und vollen Zugriff auf die Funktionen der App erhalten. Tryg kann mich per E-Mail, in der Tryg Drive-App und per SMS kontaktieren, um Neuigkeiten und Sonderangebote von Tryg A / S zu Versicherungen, Sachversicherungsprodukten, Wettbewerben und anderen Trygs-Diensten zu erhalten. Sie können jederzeit einfach und kostenlos Marketinganfragen stellen.',
    txt_global_std_achieved: 'Erreicht',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatus: 'Erfolge helfen Ihnen, Punkte zu sammeln, um Ihren Loyalitätsstatus zu erlangen. Schauen Sie sich unten die Erfolge an, die Sie als {appname} erzielen können!',
    txt_global_std_drivername: 'Name des Fahrers',
    txt_global_std_constantspeeddescription: 'Versuchen Sie, die Höchstgeschwindigkeit zu verringern. Eine hohe Höchstgeschwindigkeit bedeutet nicht immer eine hohe Durchschnittsgeschwindigkeit.',
    txt_global_std_southzone: 'Südzone',
    txt_global_std_thisisagorillatest: 'Dies ist ein Gorilla-Test',
    txt_global_std_withtheenerfyriskportfoliotrackeryouget: 'Mit dem Enerfy Risk Portfolio Tracker erhalten Sie einen Überblick über das tatsächliche Risiko und die CO2-Auswirkungen.',
    txt_global_std_p2pnonsequentialdatesselected2: 'Nicht aufeinanderfolgende Daten ausgewählt',
    txt_global_std_unlockatbronzelevel: 'Auf Bronzeebene freischalten',
    txt_global_std_insureddistance_used_90__1: 'Sie haben jetzt 90% Ihres jährlich gewählten Kilometerstandes ausgegeben. Vergessen Sie nicht, den Kilometerstand anzupassen, um eine Verkürzung im Schadensfall zu vermeiden. Sie können hier den Kilometerstand einstellen.',
    txt_global_std_duedate2: 'Geburtstermin',
    txt_global_std_p2ppushbodytimetosplitcost: 'Split your cost for {monthname}.',
    txt_global_std_wanttoshareyourprogress: 'Möchten Sie Ihre Fortschritte teilen?',
    txt_global_std_p2plendtext: '{name} möchte dir {vehicle} leihen',
    txt_global_std_OgierInterests: 'Kommt bald ',
    txt_global_std_kintoImprovement4: 'So nah! Sie hätten auf dieser Reise {savings} ¥ verdienen können. Konzentrieren wir uns!',
    txt_global_std_apitool_upsell_pitch: 'Mit diesem umfassenden Einstiegspunkt für Entwickler können Sie im Handumdrehen loslegen, z.',
    txt_global_std_welcometoenerfyglobalworkspace: 'Willkommen bei Enerfy Global Workspace!',
    txt_global_std_p2pcreateanameforthisgroup: 'Erstellen Sie einen Namen für diese Gruppe',
    txt_global_std_youdonothaveanyupcomingchallengesatthemoment: 'Sie haben im Moment keine anstehenden Herausforderungen.',
    txt_global_std_exploretheappmanual: 'Entdecken Sie das App-Handbuch',
    txt_global_std_status1: 'Status',
    txt_global_std_ayesyoucanswitchyourinsuranceanytimemostinsurancecompaniesdonothaveanearlyterminationpenaltybutyouwillneedtoinquirewithyourcurrentinsurancecarrier: 'A: Ja, Sie können Ihre Versicherung jederzeit wechseln. Die meisten Versicherungsgesellschaften haben keine Vorfälligkeitsentschädigung, aber Sie müssen sich bei Ihrem aktuellen Versicherungsträger erkundigen.',
    txt_global_std_edityourcar: 'Bearbeiten Sie Ihr Auto',
    txt_global_std_signinwith: 'Mit {Name} a anmelden ',
    txt_global_std_addmultipleusers: 'Fügen Sie mehrere Benutzer hinzu',
    txt_global_std_onlyshowdriversthatneedaction: 'Zeigen Sie nur Treiber an, die Maßnahmen benötigen',
    txt_global_std_p2pborrowedfrom: 'Ausgeliehen von',
    txt_global_std_p2pyoucaninvitexfriends: 'Du kannst {number} Freunde einladen',
    txt_global_std_competeonlyasindividualsorbothindividuallyandinteamsteamscouldbedifferentdivisionsatyourcompany: 'Treten Sie nur als Einzelpersonen oder sowohl einzeln als auch in Teams an. Teams können verschiedene Abteilungen in Ihrem Unternehmen sein.',
    txt_global_std_doyouwanttocancelconnectionandchangeobdreader: 'Möchten Sie die Verbindung abbrechen und den OBD-Leser ändern?',
    txt_global_std_yesyoumustadviseyouragentthatyourvehicleisusedforaridesharingcompanyandtheywillapplytheridesharingendorsementtoyourpolicy: 'Ja, Sie müssen Ihrem Agenten mitteilen, dass Ihr Fahrzeug für ein Mitfahrunternehmen verwendet wird, und dieser wird die Mitfahrgelegenheit auf Ihre Police anwenden.',
    txt_global_std_pointexchange: 'Punktetausch',
    txt_global_std_createatrip: 'Erstellen Sie eine Reise',
    txt_global_std_removeaccount_subject: 'Konto entfernen',
    txt_global_std_p2pchoosepaymentmethod: 'Zahlungsart auswählen',
    txt_global_std_pleasewaitaminuteortwobeforeyourefreshthisviewtogetnewsuggestions: '',
    txt_global_std_p2psentaneditrequest: 'hat eine Bearbeitungsanfrage gesendet',
    txt_global_std_accelerationdescription: 'Eine sanfte Beschleunigung ist oft sicherer und Sie sparen zusätzlich Kraftstoff.',
    txt_global_std_drivesafeandecofriendlysavemoneyonyourinsurance: 'Fahren Sie sicher und umweltfreundlich - Sparen Sie Geld bei Ihrer Versicherung.',
    txt_global_std_TaxInvoice13: 'Die Garantie ist erfüllt, sobald die Zahlung vor Versicherungsbeginn an den Versicherer oder Vertreter oder Makler erfolgt ist',
    txt_global_std_readdocumentation: 'Dokumentation lesen',
    txt_global_std_nodriversinthislistyet: 'Noch keine Treiber in dieser Liste',
    txt_global_std_temporarypassword_: 'Temporäres Passwort',
    txt_global_std_thischallenge: 'Diese Herausforderung',
    txt_global_std_inyourcar: 'in deinem Auto.',
    txt_global_std_p2ptobepaid: 'Bezahlt werden',
    txt_global_std_division2: 'Aufteilung',
    txt_global_std_cropyourprofileimage: 'Beschneiden Sie Ihr Profilbild',
    txt_global_std_MikkelsenInterests: 'Motocross, Ski Alpin, Angeln und alle Sportarten im Allgemeinen.',
    txt_global_std_acceptpermissions: 'Berechtigungen akzeptieren',
    txt_global_std_p2pshareyourcarwithfamilyandfriends: 'Teile dein Auto mit Familie und Freunden',
    txt_global_std_previousstep: 'Vorheriger Schritt',
    txt_global_std_cimage29h: 'Schalte zum Tempomat - geplant',
    txt_global_std_PSolbergChamp: '',
    txt_global_std_p2pselectfriend: 'Freund auswählen',
    txt_global_std_p2pthischatmightnotbeprivatealladminsandownersofthecarcanseethischat: 'Dieser Chat ist möglicherweise nicht privat. Alle Administratoren und Besitzer des Autos können diesen Chat sehen.',
    txt_global_std_p2pfaq2a: '1.Gehen Sie zur Buchungszusammenfassung für diese Buchung und klicken Sie auf die Schaltfläche Buchung bearbeiten.\n' +
      '2.Stellen Sie das neue Datum und die neue Uhrzeit ein und klicken Sie auf Update senden.\n' +
      '3.Warten Sie, bis Ihr Freund Ihre neue vorgeschlagene Zeit bestätigt hat.',
    txt_global_std_p2ppushtitlelendcarnomessage: 'Neue Kreditanfrage erhalten',
    txt_global_std_earnings: 'Verdienste',
    txt_global_std_greatdrivertoday: 'Toller Fahrer heute',
    txt_global_std_JyrkiainenInterests: 'Natürlich Motorsport, Joggen, Golfen, Skifahren, Motorschlitten fahren im finnischen Lappland',
    txt_global_std_mileageinkm: 'Kilometerstand in km',
    txt_global_std_hours: 'Stunden',
    txt_global_std_parkedvsstartoftrip: 'Geparkt vs. Fahrtbeginn',
    txt_global_std_milesdriven: 'Kilometer gefahren',
    txt_global_std_getitfilled: 'Bekommen Sie es gefüllt!',
    txt_global_std_collectpointstounlock: 'Sammle Punkte, um sie freizuschalten.',
    txt_global_std_youmustfillinthemileage: 'Sie müssen den Kilometerstand eingeben',
    txt_global_std_achievementsreceived: 'Erfolge erhalten',
    txt_global_std_registerakinto: 'Registrieren Sie einen Kinto',
    txt_global_std_rwmnotripalreadyregisterforthisweektext: 'Es tut uns leid, Sie können nur einmal pro Woche eine Mitfahrgelegenheit registrieren und Punkte sammeln.',
    txt_global_std_NewPW_Text4: 'Mit freundlichen Grüßen {appName}',
    txt_global_std_ontheinjury: 'auf die Verletzung',
    txt_global_std_p2pdeletemessages: 'Nachrichten löschen',
    txt_global_std_p2prewardyourfriend: 'Belohnen Sie Ihren Freund',
    txt_global_std_actions: 'Aktionen',
    txt_global_std_loyaltycertificate: 'Treuezertifikat',
    txt_global_std_mystatsthisday: 'Meine Statistiken für den letzten Tag',
    txt_global_std_kintorecruit5usersunlocked: 'Vielen Dank! Sie haben 5 Kinto-Benutzer angeworben. ¥ 10.000 wurden an Ihre Kinto-Brieftasche gesendet.',
    txt_global_std_whenwillyoudeducttheinvoicepayment: 'When will you deduct the invoice payment?',
    txt_global_std_phone: 'Telefon',
    txt_global_std_zipdriver_upsell_pitch: 'Geografische Analyse unter Verwendung der Postleitzahlen-/Postleitzahl-Risikodatenbank zu;',
    txt_global_std_p2pbooksequentialdates: 'Buchen Sie aufeinanderfolgende Daten',
    txt_global_std_turnonthewarninglight: 'Schalten Sie die Warnleuchte ein',
    txt_global_std_insurancepolicydocuments: 'Dokumente zur Versicherungspolice',
    txt_global_std_youdroveatscoretypescoreorbettergreatyouhaveearnedpointspoints: 'Du bist mit {score} oder besser gefahren! Toll! Sie haben {points} Punkte verdient!',
    txt_global_std_OSolbergInterests: 'Kommt bald ',
    txt_global_std_qidonthavebluetoothinmycarcanistillusetheapp: 'F: Ich habe kein Bluetooth in meinem Auto, kann ich die App trotzdem verwenden?',
    txt_global_std_qhowdoirenewmyinsurance: 'F: Wie verlängere ich meine Versicherung?',
    txt_global_std_MSIG_InsuranceLetter12: 'Versicherungszeitraum',
    txt_global_std_acommontypeofunsafedrivingbehaviorisputtinghugeeffortintochasingthenexttrafficlightitslikeachallengetogettothenextcrossingasquicklyaspossibleonlytobestuckwaitingforthelighttoturngreendrivingsmoothlyandlookingwellaheadtoanticipatewhatscomingupsavesbothtimeandfuel: 'Eine häufige Art von unsicherem Fahrverhalten ist es, mit großem Aufwand die nächste Ampel zu jagen! Es ist wie eine Herausforderung, so schnell wie möglich zur nächsten Kreuzung zu gelangen, nur um dort festzusitzen und darauf zu warten, dass die Ampel auf Grün schaltet.',
    txt_global_std_youmayhavetogoto: 'Möglicherweise müssen Sie zu gehen',
    txt_global_std_what: 'Was',
    txt_global_std_wanttobuyinsurance: 'Sie möchten eine Versicherung abschließen?',
    txt_global_std_unlockbenefitsenterpolicynumber: 'Vorteile freischalten - Geben Sie die Richtliniennummer ein',
    txt_global_std_timing: 'Zeitliche Koordinierung',
    txt_global_std_bydrivingsafeandsmartrememberto: 'Sicher und intelligent fahren. Erinnere dich an:',
    txt_global_std_p2pcompleted: 'Abgeschlossen',
    txt_global_std_whenusingthiscodeorlinkuserwillbeonboardedtoyourenerfyglobalteamandthefollowingsubgroupgroups: 'Bei Verwendung dieses Codes oder Links wird der Benutzer in Ihr Enerfy Global-Team und die folgenden Untergruppen/Gruppen aufgenommen',
    txt_global_std_p2precommended: 'Empfohlen',
    txt_global_std_avgspeed: 'Durchschn. Geschwindigkeit',
    txt_global_std_photos: 'Fotos ',
    txt_global_std_pickyourteam: 'Wähle dein Team aus',
    txt_global_std_p2phoursused: 'Genutzte Stunden',
    txt_global_std_automatichelpsyourecord: 'Die automatische Verbindung hilft Ihnen, Ihre Fahrten aufzuzeichnen, falls Sie vergessen, die App zu öffnen.',
    txt_global_std_invitationqrcode: 'QR-Code der Einladung',
    txt_global_std_connectengine: 'Start the engine to connect',
    txt_global_std_driverforscore: 'Fahren Sie {appname} für eine Punktzahl',
    txt_global_std_cimage26d: '',
    txt_global_std_ConfirmFia_Text8: 'Wir sehen uns dort!',
    txt_global_std_ringphoneno: 'Rufen Sie {TelefonNr} an',
    txt_global_std_northzone: 'Nordzone',
    txt_global_std_p2pdistancetravelledrequiresbluetooth: 'Zurückgelegte Entfernung erfordert Bluetooth',
    txt_global_std_name: 'Name ',
    txt_global_std_customize: 'Anpassen',
    txt_global_std_p2pwelcometokintowarikan: 'Willkommen bei Kinto Warikan!',
    txt_global_std_cartype: 'Auto Typ',
    txt_global_std_unknownstreet: 'Unbekannte Straße',
    txt_global_std_p2ppushbodyyouhaveabookingtofinalize: 'Bitte beachten Sie, dass eine Buchung nicht abgerechnet wird',
    txt_global_std_imagepickerlocked: 'Wählen Sie das Symbol für das gesperrte Abzeichen',
    txt_global_std_vouchercodesaved: 'Gutscheincode gespeichert',
    txt_global_std_enerfyanalytics_upsell_pitch: 'Verschaffen Sie sich mit personalisierten oder vordefinierten Berichten ein vollständiges Verständnis Ihrer Daten;',
    txt_global_std_warikan_onboarding1_2: 'わりかんKINTOご利用者様',
    txt_global_std_selectdate: 'Datum auswählen',
    txt_global_std_invite10friendstojointhechallengeforabetterworld: 'Lade 10 Freunde ein, sich der Herausforderung für eine bessere Welt anzuschließen.',
    txt_global_std_activate: 'Aktivieren',
    txt_global_std_makesureyouandothersareinasafeplaceifanyoneisinjuredcallphoneno: 'Stellen Sie sicher, dass Sie und andere an einem sicheren Ort sind. Wenn jemand verletzt ist, rufen Sie an: {phoneNo}',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatuscheckoutbelowtheachievementsyoucanearnbybeinga: 'Erfolge helfen Ihnen, Punkte zu sammeln, um Ihren Loyalitätsstatus zu erlangen. Schauen Sie sich unten die Erfolge an, die Sie als',
    txt_global_std_oncetheapprecognisestheobdreaderyoullmoveautomaticallytothenextstep: 'Sobald die App das OBD-Lesegerät erkennt, gelangen Sie automatisch zum nächsten Schritt.',
    txt_global_std_greatdrive: 'Tolle Fahrt!',
    txt_global_std_thankyouforyourorderenerfy: 'Vielen Dank für Ihre Bestellung. Ihr Enerfy-Plug-In wird Ihnen am nächsten Arbeitstag zugesandt. Voraussichtliche Lieferzeit beträgt 1-3 Werktage.',
    txt_global_std_internalservererrorpleasecontactinfogreaterthaneulicensekeynotvalid: 'Interner Serverfehler, bitte wenden Sie sich an info@greaterthan.eu (Lizenzschlüssel ungültig)',
    txt_global_std_countrycode: 'Landesvorwahl',
    txt_global_std_astronautssuperlonggroupnamegroup23: 'Astronauten, Superlanger Gruppenname, Gruppe 23',
    txt_global_std_welcomeletsstart: 'Willkommen! Lasst uns beginnen!',
    txt_global_std_car2: 'Wagen',
    txt_global_std_tripsperperiod: 'Fahrten pro Zeitraum',
    txt_global_std_p2pdateandtime: 'Terminzeit',
    txt_global_std_p2pwehavesentanewtemporarypasswordtoyouremailaddress: 'Wir haben ein neues temporäres Passwort an Ihre E-Mail-Adresse gesendet.',
    txt_global_std_map2: 'Karte',
    txt_global_std_enteravalidpolicynumber: 'Geben Sie eine gültige Versicherungsnummer ein.',
    txt_global_std_sendnow: 'Schick jetzt',
    txt_global_std_qrcode: 'QR-Code',
    txt_global_std_wrongemailorpassword: 'falsche Email oder Passwort',
    txt_global_std_randomperson: 'Zufällige Person',
    txt_global_std_3gotobluetoothsettinginyourmobilemakesureyourmobilesbluetoothisturnedon: '3. Gehen Sie in Ihrem Handy zur Bluetooth-Einstellung und stellen Sie sicher, dass das Bluetooth Ihres Handys aktiviert ist.',
    txt_global_std_step5: 'Schritt',
    txt_global_std_currentinsurancecompany: 'Aktuelle Versicherungsgesellschaft',
    txt_global_std_mysettings: 'Meine Einstellungen ',
    txt_global_std_areyousureyouwanttoremoveuserfromthegroup: 'Möchten Sie den Benutzer wirklich aus der Gruppe entfernen?',
    txt_global_std_youhaventdriventhismonth: 'Sie haben diesen Monat nicht gefahren',
    txt_global_std_checkyourinbox: 'Überprüfe deinen Posteingang',
    txt_global_std_communication_upsell_bulletpoint3: 'Kommunizieren Sie kontextbezogene Botschaften zum richtigen Zeitpunkt',
    txt_global_std_reportfilled: 'Bericht ausgefüllt',
    txt_global_std_Alert_WereYouDrivingBody: 'Denken Sie daran, Ihre Fahrten in der App zu starten und zu stoppen, um Ihre Fahrpunktzahl zu erhalten.',
    txt_global_std_invitemoreusers: 'Laden Sie weitere Benutzer ein',
    txt_global_std_searchmenu: 'Suchmenü',
    txt_global_std_header: 'Header',
    txt_global_std_p2pconnecttocarcomingsoon: 'Verbindung zum Auto herstellen - In Kürze verfügbar',
    txt_global_std_actual: 'Aktuell',
    txt_global_std_or2: 'oder',
    txt_global_std_communication_upsell_pitch_bulletpoint2: 'Verbinden Sie sich mit Benutzern auf ihren bevorzugten Kanälen',
    txt_global_std_cimage20d: 'Sie sparen Zeit, indem Sie Ihr Fahren planen. Hohe Höchstgeschwindigkeit führt zu geringem oder keinem Zeitgewinn.',
    txt_global_std_internetconnectionlost: 'Verbindung unterbrochen. Dies kann zu Verzögerungen in der App führen und sich auf Ihre Fahrdaten auswirken.',
    txt_global_std_turnonyourcar: 'Schalten Sie Ihr Auto ein',
    txt_global_std_p2pemailnewbookings: 'Neue Buchungen',
    txt_global_std_alertdrivenotstarted: 'Warnung! Sie scheinen zu reisen, aber es wurde keine Fahrt gestartet.',
    txt_global_std_manageyourteammembersandtheiraccountpermissionshere: 'Verwalten Sie hier Ihre Teammitglieder und deren Kontoberechtigungen.',
    'txt_global_std_Al-KuwariQuote': 'Zitat kommt bald ...',
    txt_global_std_startbonusno: 'Startbonus',
    txt_global_std_saving: 'Speichern',
    txt_global_std_nationalinsuranceno: 'Staatliche Versicherung. Nein',
    txt_global_std_ChicheritDescription: 'Monster Athlet Guerlain Chicherit tritt 2018 mit seinem eigenen Team von GC Kompetition auf der FIA World Rallycross Championship an, die den neuen, von Prodrive entwickelten Renault Megane RS fährt. Als professioneller Skifahrer und viermaliger Freeride-Weltmeister hält Chicherit den Rekord, vollendete er als erster Mann in der Geschichte einen ungesicherten Backflip mit einem Auto. Chicherit debütierte 2015 im JRM Mini RX Countryman',
    txt_global_CoreTemplate_Offline: 'Offline Trip',
    txt_global_std_youhaveunsavedsettingsquitanyway: 'Sie haben nicht gespeicherte Einstellungen, möchten Sie trotzdem beenden?',
    txt_global_std_p2ppushtitlehasdeniedthebooking: 'Buchung abgelehnt',
    txt_global_std_MikkelsenMileage: 'Ich fahre immer gerne in meine Heimatstadt Oslo, wenn ich zurück in Norwegen bin. Ich bin viel unterwegs und es ist immer schön, nach Hause zu kommen. Ich fahre aber auch gerne in den Alpen, weil die Aussicht dort unglaublich ist.',
    txt_global_std_inviteyourfriendsandfamilytojointhechallengeforabetterworld: 'Lade deine Freunde und Familie ein, sich der Herausforderung für eine bessere Welt anzuschließen',
    txt_global_std_yourcarnowhasanactivatedinsurance: 'Ihre Auto hat nun eine aktivierte Versicherung ',
    txt_global_std_weadaptthestartscreen: 'Wir passen den Startbildschirm entsprechend Ihrer Auswahl an.',
    txt_global_std_highlightedtheareainwhichyouhavethemostimprovementpotentialinthemapandchart: 'Wir haben den Bereich hervorgehoben, in dem Sie das größte Verbesserungspotenzial in der Karte und der obigen Karte haben. Überzeugen Sie sich selbst!',
    txt_global_std_theusermustacceptaninvitationtotheteamfirst: 'Der Benutzer muss zuerst eine Einladung zum Team annehmen.',
    txt_global_std_caniswitchtoagentifialreadyhaveanactiveinsurancepolicy: 'Kann ich zu {agent} wechseln, wenn ich bereits eine aktive Versicherungspolice habe?',
    txt_global_std_createnewemail: 'Neue E-Mail erstellen',
    txt_global_std_p2pfaq15q: 'Wie füge ich Ausgaben für das Auto hinzu?',
    txt_global_std_yourpasswordwaschanged: 'Ihr Passwort wurde geändert',
    txt_global_std_sparkunlockedkey: 'Du bist wirklich gut gefahren! Ihr durchschnittliches Niveau war eine Woche lang besser als 4! Ihrem Kinto Wallet wurden 1000 Punkte hinzugefügt.',
    txt_global_std_joinaheattoenterthefiasdc: '',
    txt_global_std_turnonbluetooth: 'Schalten Sie Bluetooth ein',
    txt_global_std_yeary: 'y',
    txt_global_std_HusseinAbdullaWhyjoin: 'Geist der Herausforderung',
    txt_global_std_rwmnotripalreadyregisterforthisweektitle: 'Ridesharing-Fahrt wurde für diese Woche bereits angemeldet',
    txt_global_std_JyrkiainenWhyjoin: 'Mit FIA Smart Driving Challenge können wir gemeinsam Spaß haben und unsere Effizienz beim Fahren verbessern!',
    txt_global_std_cimage34h: 'Stressen Sie nicht bei Stau',
    txt_global_std_manageadvancedusersettingsandsupporthereusethequickaccessbuttonsbelowtoeasilynavigatebetweenusersagreementsandfollowupandhelpoutwithonboarding: 'Verwalten Sie hier erweiterte Benutzereinstellungen und Support. Verwenden Sie die Schnellzugriffsschaltflächen unten, um einfach zwischen Benutzern und Vereinbarungen zu navigieren und beim Onboarding nachzufassen und zu helfen.',
    txt_global_std_yesagentacceptsdriverswhohavebeenlicensedinothercountries: 'Ja, {agent} akzeptiert Fahrer, die in anderen Ländern zugelassen sind.',
    txt_global_std_MSIG_InsuranceLetter45: 'Für jeden und jeden WINDSCREEN-Anspruch gilt ein Überschuss von 100,00 USD',
    txt_global_std_datespan: 'Datumsspanne',
    txt_global_std_top3besttrips: 'Top 3 der besten Reisen',
    txt_global_std_welldone: 'Gut gemacht',
    txt_global_std_practicaltimesaving: 'Praktische Zeitersparnis',
    txt_global_std_thelowerthescorethemoresavingsyoucanearnyouwillseeyourpotentialsavingswitheachtrip: 'Je niedriger die Punktzahl, desto mehr Einsparungen können Sie erzielen. Sie sehen Ihr Einsparpotenzial bei jeder Fahrt.',
    txt_global_std_payment: 'Zahlung',
    txt_global_std_remembertostartandstop: 'Denken Sie daran, Ihre Fahrten in der App zu starten und zu stoppen, um Punkte und Prämien zu sammeln.',
    txt_global_std_searchbartitleandpointstext: 'Suche nach Titel oder Punkten',
    txt_global_std_currentstandings: 'Aktuelle Rangliste:',
    txt_global_std_kintofaq4a: 'Sie nehmen automatisch teil, wenn Sie sich anmelden. Keine Sorge, in der Rangliste wird nur Ihr Spitzname angezeigt, sodass Sie immer anonym bleiben. Es gibt einen monatlichen und jährlichen Wettbewerb.',
    txt_global_std_p2ppushbodynewinvoicetopay: 'You received an invoice for cost split of {vehicletype}',
    txt_global_CoreTemplate_help: 'Hilfe',
    txt_global_std_drivingmenu: 'Fahren',
    txt_global_std_weareimpressed: 'Wow {user}, wir sind beeindruckt!',
    txt_global_std_youhavenowdriven5tripsbetterthan3point3: 'Sie sind jetzt 5 Fahrten besser als 3.3 gefahren',
    txt_global_std_qcaniswitchtoagentifialreadyhaveanactiveinsurancepolicy: 'F: Kann ich zu {agent} wechseln, wenn ich bereits eine aktive Versicherungspolice habe?',
    txt_global_std_savingsistobeusedforsubscriptiondeduction: 'Das Speichern ist für den Abonnementabzug zu verwenden',
    txt_global_std_p2pfaq31a: '1. Wählen Sie „Fahrdaten“ aus dem „Menü“.\n' +
      '2. Sie können die Daten Ihres Fahrverlaufs überprüfen. Sie können die Details der Rückmeldung sehen, indem Sie bestimmte Fahrdaten auswählen.',
    txt_global_std_campaignname: 'Kampagnenname',
    txt_global_std_tomasengedescriptionshort: 'Ein ehemaliger Profi-Rennfahrer und der erste tschechische Fahrer in der F1.\n' +
      'Er liebt die Stille beim Autofahren.',
    txt_global_std_invalidtime: 'Ungültige Zeit.',
    txt_global_std_kilometerpremiumtoohigh: 'Kilometerprämie zu hoch',
    txt_global_std_redeemablepoints: 'einlösbare Punkte',
    txt_global_std_nofriendsselected: 'Keine Freunde ausgewählt',
    txt_global_std_propernumber: 'Muss eine richtige Telefonnummer sein',
    txt_global_std_ConfirmFia_Hello: 'Sie sind jetzt gebucht!',
    txt_global_std_readthecarsengineerrorsusingtheiobd2app: 'Lesen Sie die Motorfehler des Autos mithilfe der iOBD2-App',
    txt_global_std_createchallenge: 'Herausforderung schaffen',
    txt_global_std_defaulticonimage: 'Standard',
    txt_global_std_p2pfaq4q: 'Wie lösche ich eine Nachricht?',
    txt_global_std_confirmandupdateaccount: 'Konto bestätigen und aktualisieren',
    txt_global_std_notesaved: 'Notiz gespeichert',
    txt_global_std_youhaveinvited: 'Sie haben eingeladen',
    txt_global_std_p2pmaintenance: 'Instandhaltung',
    txt_global_std_viewalldrivingdata: 'Alle Fahrdaten anzeigen',
    txt_global_std_audiofeedback: 'Audio feedback',
    txt_global_std_p2pfaq3a: 'In Kürze werden wir eine Funktion hinzufügen, die Ihnen hilft, Ihr Auto zu finden. Im Moment empfehlen wir, Ihren Freund in den Chat-Nachrichten zu fragen.',
    txt_global_std_encouragetostayfocused: 'Ermutigen Sie, während der Fahrt konzentriert zu bleiben, um riskante Entscheidungen zu vermeiden',
    txt_global_std_nicetohaveyouonboard: 'Schön, Sie an Bord zu haben!',
    txt_global_std_warikan_onboarding14: 'Bitte beachten Sie, dass es schwierig sein kann, eine Verbindung herzustellen, wenn es voll ist.',
    txt_global_std_p2pseeyourbookingsorcheckavailabilityofyourfriendscar: 'Sehen Sie sich Ihre Buchungen an oder prüfen Sie die Verfügbarkeit des Autos Ihres Freundes.',
    txt_global_std_dateandtime: 'Datum (und Uhrzeit',
    txt_global_std_totalistheleaderboardforthechallenge: 'Total ist die Bestenliste für die Herausforderung',
    txt_global_std_updatetext: 'Text aktualisieren',
    txt_global_std_total: 'Total',
    txt_global_std_SulayemFacts: '',
    txt_global_std_congratulationsyouhaveverifiedyouremailyouwillberedirectedshortly: 'Herzlichen Glückwunsch, Sie haben Ihre E-Mail-Adresse bestätigt. Sie werden in Kürze weitergeleitet',
    txt_global_std_PSolbergQuote: 'Zitat demnächst',
    txt_global_std_tryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagainnodot: 'Versuchen Sie, die Verbindung Ihres Autos zu Ihrem Telefon zurückzusetzen, indem Sie sie entfernen und dann erneut hinzufügen',
    txt_global_std_cimage25h: 'optimale Drehzahl',
    txt_global_std_p2pbookingcancelled: 'Buchung storniert',
    txt_global_std_drivingyieldtotalamount: 'Fahrertrag (Gesamtbetrag)',
    'txt_global_std_drivingtimeabove{speed}': 'Fahrzeit über {Geschwindigkeit}',
    txt_global_std_saveanduploadreport: 'Bericht speichern und hochladen',
    txt_global_std_pleasechooseaninsurance: 'Bitte wählen Sie eine Versicherung',
    txt_global_std_techsupport: 'Technischer Support',
    txt_global_std_p2piacceptthe: 'Ich akzeptiere das',
    txt_global_std_safedriverweekly: 'Wöchentlich sicherer Fahrer',
    txt_global_std_pleasemakesurethatyouareconnectedtoyourcar: 'Bitte stellen Sie sicher, dass Sie mit Ihrem Auto verbunden sind und versuchen Sie es erneut.',
    'txt_global_std_{count}points': '{Summe} Punkte',
    txt_global_std_bestoptionifyoudonthavebt: 'Dies ist die beste Option, wenn Sie in Ihrem Auto keine Bluetooth-Verbindung haben.',
    txt_global_std_planaheadbeprepared: 'Planen Sie voraus, seien Sie vorbereitet',
    txt_global_std_top: 'Top',
    txt_global_std_howdoidrivewell: 'Wie fahre ich gut?',
    txt_global_std_fellowdriver: 'Mitfahrer',
    txt_global_std_apopupintheappwillaskyoutoconnect: 'Ein Popup in der App fordert Sie auf, eine Verbindung herzustellen (kann bis zu 2 Minuten dauern)',
    txt_global_std_qhowdoiterminatemyinsurance: 'F: Wie kündige ich meine Versicherung?',
    txt_global_std_incorrectlyformattedcsvfilemakesuretofollowtheexactformatofthetemplate: 'Falsch formatierte .csv-Datei. Achten Sie darauf, das exakte Format der Vorlage einzuhalten!',
    txt_global_std_startaddress: 'Startadresse',
    txt_global_std_collectablecategory: 'Sammelbar',
    txt_global_std_andallowitwevalueyourprivacy: 'und erlaube es. Wir legen Wert auf Ihre Privatsphäre und geben diese Informationen nicht an Dritte weiter.',
    txt_global_std_9digitactivationcode: 'Geben Sie den 9-stelligen Aktivierungscode ein, der an Ihr Telefon und Ihre E-Mail gesendet wurde.',
    txt_global_std_linktopolicies: 'Link zu Richtlinien',
    txt_global_std_couldnotchangeyourpassword: 'Passwort konnte nicht geändert werden!',
    txt_global_std_december: 'Dezember',
    txt_global_std_SignupFia_Text9: '#FIASmartDrivingChallenge',
    txt_global_std_5000pointaverageachievementlocked2: 'Fahren Sie eine Woche lang mit einem durchschnittlichen {scoretype} of {score} oder besser, um diesen Erfolg freizuschalten und 5000 Punkte zu verdienen.',
    txt_global_std_nicedrive: 'Schöne Fahrt!',
    txt_global_std_uniquecategory: 'Einzigartig',
    txt_global_std_appName: '{App Name}',
    txt_global_std_howdoiearnaloyaltystatus: 'Wie verdiene ich einen Loyalitätsstatus?',
    txt_global_std_p2pcontinueandpay: 'Weiter und bezahlen',
    txt_global_std_planahead: 'Vorausplanen',
    txt_global_std_p2ppushtitleaddedexpences: '{name} hat Ausgaben hinzugefügt',
    txt_global_std_competeagainsteachotherwintogether: 'Gegeneinander antreten - gemeinsam gewinnen',
    txt_global_std_forbothyourphoneandcar: 'für Ihr Telefon und Auto.',
    txt_global_std_nodatatoshow: 'Keine Daten zum Anzeigen!',
    txt_global_std_HansenDescription: 'Kevin Hansen, der jüngste FIA-Europameister im Supercar und in 2016 FIA-Neueinsteiger des Jahres, begann seine Karriere bereits im Alter von fünf Jahren im Kartsport. Nach dem Kartunterricht wechselte Kevin 2012 zu RX und zeigte schnell, dass er da war, um erfolgreich zu sein. Kevin gewann mehrere Meisterschaften in den Jahren 2013 - 2015 und erreichte seinen Traum im Jahr 2016, mit dem Gewinn der EuroRX. Seit 2017 ist Kevin World Rallycross-Stammfahrer und wurde zweimal Zweiter in der Weltmeisterschaft. 2018 wurde er mit 19 Jahren Werksfahrer von Peugeot!',
    txt_global_std_p2pyouborrowed: 'Du hast ausgeliehen',
    txt_global_std_manual: 'Handbuch',
    txt_global_std_resetpasswordwrongpasswordformat: 'Falsches Passwortformat. Mindestens 8 Zeichen, mindestens ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl.',
    txt_global_std_fileaclaim: 'Reklamation einreichen',
    txt_global_std_drivercppr: 'Profilerstellungsbericht für Fahrerunfallmuster',
    txt_global_std_membernumber: 'Mitgliedsnummer',
    txt_global_std_amazingdriverunlockedkey: 'Du bist großartig {user}! Du hast heute eine Punktzahl über {score}!',
    txt_global_std_openinghoursinsurance: '8 - 20 Eochentage, 10 - 18 Wochenende',
    txt_global_std_howdoicompeteinlexuschallenge: 'Wie nehme ich an der Lexus Challenge teil?',
    txt_global_std_p2perrorcodebookingmaynotbeearlierthannow: 'Die Buchung darf nicht früher als jetzt erfolgen.',
    txt_global_std_p2paddedcaraslendable: 'Auto als ausleihbar hinzugefügt',
    txt_global_std_activateitnow: 'Jetzt aktivieren',
    txt_global_std_choosetypeofapp: 'Wählen Sie den App-Typ',
    txt_global_std_challengeafriend3: 'Fordern Sie einen Freund heraus!',
    txt_global_std_f_lanzdescriptionshort: 'Die beste Rennfahrerin Südafrikas. Sie liebt tanzen, wilde Naturforscher',
    txt_global_std_carbondioxide: 'Kohlendioxid ist ein farbloses Gas mit einer um 60% höheren Dichte als die trockene Luft. Kohlendioxid besteht aus einem Kohlenstoffatom, das kovalent an zwei Sauerstoffatome doppelt gebunden ist. Es kommt in der Erdatmosphäre natürlich als Spurengas vor.',
    txt_global_std_p2pfaq19a: 'Wenn Sie ein Android-Telefon verwenden, werden möglicherweise keine Fahrdaten aufgezeichnet, wenn die Funktion "Akku optimieren" aktiviert ist.\n' +
      '1. Wählen Sie auf Ihrem Smartphone unter „Einstellungen“ „Apps und Benachrichtigungen“.\n' +
      '2. Wählen Sie erweiterte App- und Benachrichtigungseinstellungen und dann Spezieller App-Zugriff.\n' +
      '3. Wählen Sie im speziellen App-Zugang „Stromoptimierung“ aus.\n' +
      '4. Wählen Sie „Alle Apps“ aus dem Pulldown-Menü und dann „Warikan KINTO“.\n' +
      '5. Wählen Sie im Dialog "Nicht optimieren".',
    txt_global_std_add: 'Hinzufügen',
    txt_global_std_documentyourcar: 'Dokumentieren Sie Ihr Auto',
    txt_global_std_schema: 'Schema',
    txt_global_std_visualeditor: 'Visueller Editor',
    txt_global_std_estimatedsurcharge: 'Geschätzter Aufpreis',
    txt_global_std_plugindevice: 'Gerät anschließen ',
    txt_global_std_therecouldbeotherreasonswhyyourcarsbluetoothdoesntshowupinthelist: 'Es kann andere Gründe geben, warum das Bluetooth Ihres Autos nicht in der Liste angezeigt wird:',
    txt_global_std_silverusers: 'Silberbenutzer',
    txt_global_std_clickhere: 'Klicken Sie hier!',
    txt_global_std_p2pemailifyouremailclientdoesnotacceptbutton: 'Wenn Ihr E-Mail-Client die Schaltfläche nicht akzeptiert, klicken Sie stattdessen auf diesen Link.',
    txt_global_std_toconnectyourcar: 'So verbinden Sie Ihr Auto:',
    txt_global_std_nousersfoundtryreloadingthepage: 'Keine Benutzer gefunden. Versuchen Sie, die Seite neu zu laden.',
    txt_global_std_co2: 'CO2',
    txt_global_std_improvementpossibility: 'Verbesserungsmöglichkeit',
    txt_global_std_unpublish: 'Veröffentlichung aufheben',
    txt_global_std_textmessage: 'Textnachricht',
    txt_global_std_cimage43d: '',
    txt_global_std_set_up_manual_start_stop: 'Einrichtung: Manueller Start & Stopp',
    txt_global_std_pwdtenandtwice: 'Ihr Passwort muss aus mindestens zehn (10) Zeichen bestehen und zweimal geschrieben werden!',
    txt_global_std_cimage30h: 'Planen Sie jede Beschleunigung, um einen Nutzen davon zu erhalten.',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint3: 'Erhalten Sie genaue Einblicke in die geografische Risikoexposition in Echtzeit',
    txt_global_std_zipdrivingriskindex: 'Zip-Driving-Risikoindex',
    txt_global_std_p2pfaq18q: 'Bitte sagen Sie mir, wie Sie die App mit dem Auto verbinden und Fahrdaten aufzeichnen.',
    txt_global_std_maxfilesize: 'Maximale Dateigröße',
    txt_global_std_togetmorefriendssuggestionsyoucanloadyourfriendsfromfacebook: '',
    txt_global_std_youcanemailthiscertificatetogetdiscountswithdifferentvendors: 'Sie können dieses Zertifikat per E-Mail versenden, um Rabatte bei verschiedenen Anbietern zu erhalten.',
    txt_global_std_toprovideyouwithaccuratestatsanddrivingscoreappnameneedstoruninbackgroundmode: 'Um Ihnen genaue Statistiken und Fahrergebnisse zu liefern, muss {appname} im Hintergrundmodus ausgeführt werden.',
    txt_global_std_carwash: 'Autowäsche',
    txt_global_std_ABC: 'ABC',
    txt_global_std_whoisthesmartestdriver: 'Wer ist der klügste Fahrer?',
    txt_global_std_enerfyanalytics_upsell_bulletpoint1: 'Verstehen Sie die Faktoren, die das Risikoniveau bestimmen',
    txt_global_std_api: 'API',
    txt_global_std_adgangtilmotionogfitnessdata: 'Hinzufügen von Bewegungs- und Fitnessdaten',
    txt_global_std_p2ptotaltopaytoph: 'Zu zahlender Gesamtbetrag an {user}',
    txt_global_std_ConfirmFia_Text71: 'Wenn Sie es nicht schaffen, stornieren Sie bitte Ihr Zeitfenster. Klicken Sie auf den folgenden Link.',
    txt_global_std_electriccarorobd: 'Elektroauto oder OBD?',
    'txt_global_std_Al-KuwariMileage': 'Ich fahre gerne lange Strecken Ich finde mich in großen Entfernungen.',
    txt_global_std_oliversolbergShortDescription: 'Keiner / Keine',
    txt_global_std_p2pwaitingfor: 'Warten auf',
    txt_global_std_iwanttoreceiveinformationandupdatesviaemail: 'Ich möchte Informationen und Updates per E-Mail erhalten.',
    txt_global_std_fullname: 'Vollständiger Name',
    txt_global_std_3rd: '3er',
    txt_global_std_p2ppushbodyhasdeniedthebooking: '{name} hat die Buchung abgelehnt.',
    txt_global_std_OSolbergChamp: 'Kommt bald ',
    txt_global_std_referfriendsgetrewards: 'Freunde empfehlen. Erhalten Sie Belohnungen.',
    txt_global_std_p2ppushtitlesetpriceforbookingto: 'Der Buchungspreis ist festgelegt',
    txt_global_std_pleasewait: 'Warten Sie mal',
    txt_global_std_joinevent: 'Nimm an Event teil',
    txt_global_std_points1: 'Punkte',
    txt_global_std_pair: 'Paar',
    txt_global_std_demotext2: 'Sehen Sie, wie hoch Ihre Punktzahl wird.',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriend: 'Tritt in deinem täglichen Fahren gegen deinen Freund an.',
    txt_global_all_thursday: 'Donnerstag',
    txt_global_std_youneedtoselectatype2: 'Sie müssen einen Typ auswählen',
    txt_global_std_gallonimp: 'Gallonen Imp',
    txt_global_std_socialbutterflyunlockedkey: 'Sie haben 10 Freunde empfohlen! Nett! Ihrem Kinto Wallet wurden 300 Punkte hinzugefügt.',
    txt_global_std_findaservicecenter: 'Finden Sie ein Service-Center',
    txt_global_std_pickyourteamleader2: 'Wähle deinen Teamleiter',
    txt_global_std_401unauthorized: '401 nicht Autorisiert',
    txt_global_std_smssent: 'SMS gesendet!',
    txt_global_std_p2psplittingthecostforyourkintosubscribtionwithyourfriendsiseasy: 'Es ist ganz einfach, die Kosten für dein Kinto-Abonnement mit deinen Freunden aufzuteilen!',
    txt_global_std_iveforgottenmypassword: 'Ich habe mein Passwort vergessen',
    txt_global_std_OSolbergBesttip: 'Kommt bald ',
    txt_global_std_connectedtoobd: 'Verbunden mit OBD',
    txt_global_std_emailorusername: 'E-Mail oder Benutzername',
    txt_global_std_documentsandpolicies: 'Dokumente und Richtlinien',
    txt_global_std_sendreport2: 'Bericht senden',
    txt_global_std_skillslevel7d: 'Fähigkeitsstufe 7D',
    txt_global_std_login2: 'Anmeldung',
    txt_global_std_MSIG_Onboarding8: '2. Befolgen Sie die Schritte in der App.',
    txt_global_std_privacysettingssaved: 'Datenschutzeinstellungen gespeichert',
    'txt_global_std_Al-KhelaifiDescription': 'Abdulla nimmt an den meisten Motorsportevents in Katar teil und hat mehrere Meisterschaften in verschiedenen Rennsportarten wie National Drag, Qatar Mile, National Sprint und QTCC.',
    txt_global_std_temporarypassword: 'Temporäres Passwort',
    txt_global_std_clickthelinkbelowtoconfirmemail: 'Klicken Sie auf den Link unten, um Ihre E-Mail zu bestätigen und die Erstellung Ihres Enerfy Global-Kontos abzuschließen.',
    txt_global_std_lendmycar: 'Leih mein Auto',
    txt_global_std_ifyourcardoesnthavebluetooth: "If your car doesn't have bluetooth",
    txt_global_std_tripanalytics: 'Reiseanalyse',
    txt_global_std_getstartedfolks: 'Fangen Sie an, Leute!',
    txt_global_std_takecareofinjury: 'Achten Sie auf Verletzungen',
    txt_global_std_drivenkmperyear: 'Gefahrene km pro Jahr',
    txt_global_std_sos: 'Notruf',
    txt_global_std_showmodal: 'Modal anzeigen',
    txt_global_std_hiheresmylocation: 'Hallo, hier ist mein Standort:',
    txt_global_std_pushnotdrivenforesometimebody2: 'Stellen Sie sicher, dass Sie beim nächsten Fahren eine Verbindung herstellen.',
    txt_global_std_p2pgotocarsharing: 'Gehen Sie zum Carsharing',
    txt_global_std_keeponpracticingandyoucangetabetterprice: 'Üben Sie weiter und Sie können einen besseren Preis für Ihre Versicherung erzielen.',
    txt_global_std_distancekm2: 'Entfernung/km',
    txt_global_std_dontallow: 'Nicht zulassen',
    txt_global_std_insurancecontactdetails: 'Kontaktdaten der Versicherung',
    txt_global_std_agreementnumber: 'Übereinkommensnummer',
    txt_global_std_warikan_onboarding7: '3. Geben Sie die folgenden Informationen ein, um ein Konto zu erstellen\n',
    txt_global_std_dennetur: 'Denne tur.',
    txt_global_std_successfullypublishedlanguage: 'Erfolgreich veröffentlichte Sprache',
    txt_global_std_registeryouraccount: 'Registriere dein Konto',
    txt_global_std_countryroadroundaboutDESC: 'Der blaue Autofahrer passt die Geschwindigkeit an, bevor er in den Kreisverkehr einfährt. Der rote Autofahrer passt das Tempo nicht ausreichend an, um reibungslos in den Kreisverkehr einzusteigen.',
    txt_global_std_p2ppushtitletimetosplitcost: '費用分割の時期になりました。\n',
    txt_global_std_period: 'Zeitraum',
    txt_global_std_MSIG_Onboarding11: 'Fahren Sie sicher,',
    txt_global_std_totaldistance: 'Gesamtentfernung',
    txt_global_std_usersuccessfullyremoved: 'Benutzer erfolgreich entfernt',
    txt_global_std_eventualinsurancebonus: 'Eventual insurance bonus\n',
    txt_global_std_activatebluetoothandconnect: '3. Aktivieren Sie Bluetooth und schließen Sie den OBD-Leser an',
    txt_global_std_locationservicetroubleshooting: 'Fehlersuche bei Standort-Service',
    txt_global_std_vehicleidentification: 'Fahrzeug identifikation',
    txt_global_std_dsadsadsadsa: 'dadsasd',
    txt_global_std_phonenumber: 'Telefonnummer',
    txt_global_std_ayessompoacceptsdriverswhoarelicensedinothercountries: 'A: Ja, Sompo akzeptiert Fahrer, die in anderen Ländern zugelassen sind.',
    txt_global_std_somethingwentwrongwhenattemptingtoredeempointspleasetryagainlater: 'Beim Versuch, Punkte einzulösen, ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut',
    txt_global_std_1startyourcarsengine: '1. Starten Sie den Motor Ihres Autos.',
    txt_global_std_moreoptions: 'Mehr Optionen',
    txt_global_std_minutem: 'M',
    txt_global_std_learnmorehere: 'Erfahren Sie hier mehr',
    txt_global_std_iaccepttheusertermslinkandconditionsprivacypolicylink: 'Ich akzeptiere die {userTermsLink} und Bedingungen & {privacyPolicyLink}',
    txt_global_std_messagebody: 'Nachrichtentext',
    txt_global_std_safedriver: 'Sicherer Fahrer',
    txt_global_std_optionnotavailableyet: 'Diese Option ist noch nicht verfügbar!',
    txt_global_std_insuredvehicles: 'Versicherte Fahrzeuge',
    txt_global_std_VilliersInterests: 'Motorsport, All-Terrain-Rennen, Rundstreckenrennen',
    txt_global_std_reviewenerfy: 'Überprüfung',
    txt_global_std_downloadqrcode: 'QR-Code herunterladen',
    txt_global_std_nocountryselected: 'Kein Land ausgewählt',
    txt_global_std_MarklundMileage: 'Ich liebe es, die Strecke nach Hause zu fahren. Wenn Sie viel reisen, schätzen Sie es, einfach zu Hause zu sein. Das Gefühl, näher und näher zu kommen, ist wirklich befriedigend. Ich lebe in Nordschweden, wo die Straßen manchmal ganz leer sind und die Landschaft mit viel Wald und Wildnis mich ruhig und glücklich macht.',
    txt_global_std_clickheretobuyfiasdc: 'Klicken Sie hier, um FIA SDC zu kaufen',
    txt_global_std_yesyoucanswitchyourinsuranceanytimemostinsurancecompaniesdonothaveanearlyterminationpenaltyyouwillneedtoinquirewithyourcurrentinsurancecarrier: 'Ja, Sie können Ihre Versicherung jederzeit wechseln. Die meisten Versicherungen haben keine Vorfälligkeitsentschädigung. Sie müssen sich bei Ihrem aktuellen Versicherungsträger erkundigen.',
    txt_global_std_p2pskip: 'Überspringen',
    txt_global_std_primarybluetoothid: 'Primäre Bluetooth-ID',
    txt_global_std_nofinishedchallenges: 'Keine abgeschlossenen Herausforderungen',
    txt_global_std_selectcountrycode: 'Ländercode auswählen',
    txt_global_std_youbooked10timesinonemonthyouhaveearned2500points: 'Sie haben 10 Mal in einem Monat gebucht. Sie haben 2500 Punkte verdient.',
    txt_global_std_typeyourcodefrominsuranceletter: 'Geben Sie Ihren Code aus dem Begrüßungsschreiben der Versicherung ein',
    txt_global_std_wecantfindanydrives: 'Wir können keine Laufwerke finden!',
    txt_global_std_watchexplainervideo: 'Erklärvideo ansehen',
    txt_global_std_thiskeydoesnothaveatranslationinthislanguage: 'Dieser Schlüssel hat keine Übersetzung in dieser Sprache',
    txt_global_std_documentandpolicyes: 'Dokumente und Richtlinien',
    txt_global_std_loyaltypoints: 'Treuepunkte',
    txt_global_std_couldnotconnect: 'Konnte keine Verbindung herstellen',
    txt_global_std_countmonths: '{zählen} Monate',
    txt_global_std_closestyou: 'Ihnen am nächsten',
    txt_global_std_viewcost: 'Kosten ansehen',
    txt_global_std_theinsurancehasbeenactivated: 'Die Versicherung wurde aktiviert.',
    txt_global_std_createnewtextmessage: 'Neue SMS erstellen',
    txt_global_std_tomasengemusic: 'Ich höre nichts im Auto, da ich die Stille beim Fahren liebe - wie im Rennwagen',
    txt_global_std_cimage5d: 'Verwenden Sie gute Bergtechniken, halten Sie das Gas konstant, verringern Sie die Geschwindigkeit bergauf und rollen Sie den Hügel hinunter.',
    txt_global_std_smsclientonboardingpincode: 'Here comes your {pinlength} digits PIN code: {pincode}. PIN code is valid for {validminutes} minutes.',
    txt_global_std_remembertodrivesmart: 'Denken Sie daran, intelligent zu fahren.',
    txt_global_std_countryroad4DESC: 'Der blaue Autofahrer wird langsamer und nähert sich sanft der Kurve. Der rote Autofahrer passt die Geschwindigkeit nicht an die Krümmung an und muss bremsen.',
    txt_global_std_p2ppleasecontactsupport: 'Bitte wenden Sie sich an den Support.',
    txt_global_std_MarklundFacts: '',
    txt_global_std_to_make_a_change_in_your_insurance_policy: 'Um Ihre Versicherungspolice zu ändern, melden Sie sich bitte unter {url} an oder wenden Sie sich an den Support unter {email} oder {phone}.',
    txt_global_std_spooring: 'Spooring',
    txt_global_std_inviteapersonyouwantotshareinsurancewith: 'Laden Sie eine Person ein, mit der Sie Ihre Versicherung teilen möchten.',
    txt_global_std_yourusername: 'Ihr Benutzername',
    txt_global_std_superdriver: 'super Fahrer!',
    txt_global_std__enterthetemporarypasswordsendtoyoubyemail: 'Geben Sie das Ihnen per E-Mail zugesandte temporäre Passwort ein.',
    txt_global_std_kintofaq3a: 'Sie verdienen Ihren Treue-Status, indem Sie Punkte sammeln! Sie erhalten Punkte aus den verschiedenen verfügbaren Leistungen. Erfolge werden unseren treuen und sicheren Kinto-Fahrern zuteil! Weitere Informationen finden Sie im Abschnitt „Erfolge“.',
    txt_global_std_usethistemplateasabadgeinyourappcustomizeitinthenextstep: 'Verwenden Sie diese Vorlage als Abzeichen in Ihrer App. Passen Sie es im nächsten Schritt an.',
    txt_global_std_TomczykBesttip: 'Konzentrieren Sie sich auf Ihren Fahrstil und fahren Sie „defensiv“.',
    txt_global_std_writeanewpassword: 'Schreiben Sie ein neues Passwort',
    txt_global_std_userhasnogroups: 'Benutzer hat keine Gruppen',
    txt_global_std_nextqualificationforthegrandfinalindecemberstarts: 'Die nächste Qualifikation für das Grand Final im Dezember beginnt',
    txt_global_std_paddonQualification: 'Test Qualifikationstext für Paddon',
    txt_global_std_4th: '4er',
    txt_global_std_p2pchatwithyourfriends: 'Chatte mit deinen Freunden',
    txt_global_std_clickthebuttonbelowtoresetyourenerfyglobalpassword: 'Klicken Sie auf die Schaltfläche unten, um Ihr Enerfy Global-Passwort zurückzusetzen.',
    txt_global_std_lostconnectionsmall: 'Starten Sie den Motor, um fortzufahren',
    txt_global_std_enterdetails: 'Geben Sie Details ein',
    txt_global_std_entergender: 'Geben Sie das Geschlecht ein',
    txt_global_std_makesuretheobdreaderisreadytobepaired: 'Stellen Sie sicher, dass der OBD-Reader zum Pairing bereit ist. Wenn sie fertig ist, blinkt sie blau.',
    txt_global_std_yourpolicyshouldcontaineight8numbers: 'Ihre Richtlinie sollte acht (8) Nummern enthalten.',
    txt_global_std_step2: 'Schritt 2',
    txt_global_std_FiaPassword_Text1: 'Hallo!',
    txt_global_std_v2score: 'Ergebnis',
    txt_global_std_youraveragescoreis123: 'Ihre durchschnittliche Punktzahl ist',
    txt_global_std_saveinformation: 'Informationen speichern',
    txt_global_std_conclusion: 'Fazit',
    txt_global_std_p2pspecifyprice: 'Preis angeben',
    txt_global_std_p2pyouhaveanexpensetoapprove: 'Sie haben eine Ausgabe zu genehmigen',
    txt_global_std_redirectingyou: 'Sie umleiten ...',
    txt_global_std_developerpage: 'Entwicklerseite',
    txt_global_std_p2pspecifyegfuel: 'Angeben (z. B. Kraftstoff)',
    txt_global_std_p2ptotaltopayto: 'Gesamt zu zahlen an',
    txt_global_std_ayesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforcovercubeinsurance: 'A: Ja, Sie können die App herunterladen und die Option „App ausprobieren“ wählen. Sie können mit der App fahren und sehen, wie viel Sie sparen können, wenn Sie eine Covercube-Versicherung abgeschlossen haben.',
    txt_global_std_sendinvitation: 'Einladung senden ',
    txt_global_std_2makesureyourcarsbluetoothisonorthatyourbluetoothbeaconisconnected: '2. Stellen Sie sicher, dass das Bluetooth Ihres Autos eingeschaltet ist oder dass Ihr Bluetooth-Beacon verbunden ist.',
    txt_global_std_p2premovefriend: 'Freund entfernen',
    txt_global_std_noaccountsignuphere: 'Kein Account? Hier anmelden',
    txt_global_std_p2ppayments: 'Zahlungen',
    txt_global_std_internalservererror: 'Interner Serverfehler',
    txt_global_std_thisyear: 'Dieses Jahr',
    txt_global_std_costforspeedover80: 'Kosten für Geschwindigkeit über {Geschwindigkeit}',
    txt_global_std_timeframes: 'Zeitrahmen',
    txt_global_std_p2ppleaseenteratemporarypassword2: 'Bitte geben Sie ein temporäres Passwort ein.',
    txt_global_std_Gdpr_Text5: 'Bei Fragen wenden Sie sich bitte an info@fiasmartdrivingchallenge.com oder +46 8 555 932 00.',
    txt_global_std_TidemandQuote: 'Ich habe immer Spass an Herausforderungen, besonders an solchen die einem guten Zweck dienen. Intelligent und verantwortungsbewusst zu fahren ist etwas, das uns alle angeht. Durch die Verbesserung unseres umweltfreundlichen Fahrens in unserem Alltag investieren wir in unsere Zukunft. Ich bin dabei!',
    txt_global_std_locateyourobdsocket: '1. Suchen Sie Ihre OBD-Buchse',
    txt_global_std_noconnectiontomapserver: 'Keine Verbindung zum Kartenserver',
    txt_global_std_hiwelcometoappname: 'Hallo! Willkommen bei {appName}!',
    txt_global_std_unitskmmi: 'Einheiten Km / mi',
    txt_global_std_warikan_onboarding3_2: 'なお、仮パスワードの有効期限は72時間となっております。\n' +
      '超過 後 は, 「わりかん kinto」 アプリ の 「仮 ワ ー ワ を 再 送信 より ワ メ メ メ メ 受け取っ て メ メ メ メ メ 受け取っ て いただき メ メ メ お願いいたし ます\n' +
      '※ 本 メ ー ル は 送信 専用 です メ ご 返信 て も お応え お応え い た ね ます ので お問い合わせ お問い合わせ は 下記 へ お願いいたし ます ます お問い合わせ お問い合わせ は 下記 お願いいたし ます.',
    txt_global_std_pushnotification: 'Push-Benachrichtigung',
    txt_global_std_willigetnotifiedififorgottostart: 'Will I get notified if I forget to start the Enerfy app?',
    txt_global_std_backtolist: 'Zurück zur Liste',
    txt_global_std_startdrivingtogetascore: 'Fahren Sie los, um Punkte zu bekommen!',
    txt_global_std_enerfyanalytics2: 'Enerfy Analytics',
    txt_global_std_p2ppushbodyexpensesthismonthquestion: 'Make sure to add your expenses for {monthname}.',
    txt_global_std_totalthismonth: 'Insgesamt diesen Monat',
    txt_global_std_youcannotsetafuturedate: 'Sie können kein zukünftiges Datum festlegen',
    txt_global_std_myprofile: 'Meine Profile ',
    txt_global_std_achievementsavedmessage: 'Erfolge wurden gespeichert!',
    txt_global_std_export2: 'Export',
    txt_global_std_timelocationaretheonlymandatoryfields: 'Zeit & Ort sind die einzigen Pflichtfelder.',
    txt_global_std_daydreamingormindwanderingnew: 'Tagträumen oder Gedankenwandern',
    txt_global_std_stardriver: 'Sternfahrer',
    txt_global_std_p2phide: 'Ausblenden',
    txt_global_std_riskydecisionwithintripsbetweendays: 'Riskante Entscheidung innerhalb von Reisen und zwischen den Tagen',
    txt_global_std_connecttoyourcar: 'Verbinden Sie sich mit Ihrem Auto',
    'txt_global_std_Al-KuwariFacts': '',
    txt_global_std_averagescoreofparticipants: 'Durchschnittliche Punktzahl der Teilnehmer',
    txt_global_std_createaccountlater: 'Konto später erstellen',
    txt_global_std_p2pamount: 'Menge',
    txt_global_std_KleinschmidtInterests: 'Sport, Computertechnik, Fliegen',
    txt_global_std_drivendistanceperperiod: 'Fahrstrecke pro Periode',
    txt_global_all_tuesday: 'Dienstag',
    txt_global_std_p2pleavethisgroupchatquestion: 'Diesen Gruppenchat verlassen?',
    txt_global_std_90days: '90 Tage',
    txt_global_std_co2saver: 'CO₂-Sparer',
    txt_global_std_25km: '25 Kilometer',
    txt_global_std_tipcityparking2DESC: 'Der blaue Autofahrer alarmiert umgebende Autos mit einem Indikator und wird langsamer. Der rote Autofahrer benutzt vor dem parallelen Einparken auch keine Anzeige.',
    txt_global_std_inviteafriendyouallwillgetareward: 'Lade einen Freund ein ihr bekommt alle eine Belohnung',
    txt_global_std_shortcuts: 'Abkürzungen',
    txt_global_std_toprovideyouwithadrivingscoreandstatsaboutyourdrivingappnameneedsaccesstolocationservices: 'Um Ihnen eine Fahrbewertung und Statistiken über Ihr Fahren zu liefern, benötigt {appname} Zugriff auf Ortungsdienste.',
    txt_global_std_poor: 'Arm',
    txt_global_std_after6monthsyouwillgetarenewalofferbasedonyourdrivinguntilthenyoucankeepimprovingyourdrivingfollowyourprogressandseeyourestimatedfuturesavingshere: 'Nach 6 Monaten erhalten Sie ein Verlängerungsangebot basierend auf Ihrer Fahrweise. Bis dahin können Sie Ihr Fahrverhalten weiter verbessern. Verfolgen Sie Ihren Fortschritt und sehen Sie hier Ihre geschätzten zukünftigen Einsparungen.',
    txt_global_std_thecruisebeliever: 'Der Kreuzfahrtgläubige',
    txt_global_std_cimage6d: 'Vermeiden Sie das Anfahren und einen abrupten Stop bei Stau',
    txt_global_std_KleinschmidtShortDescription: 'Dakar Rally Champion 2001',
    txt_global_std_senddocumentto2: 'Dokument senden an:',
    txt_global_std_saveunits: 'Units speichern',
    txt_global_std_perusermonth: 'pro Benutzer/Monat',
    txt_global_std_nameheader: 'Titel der Leistung',
    txt_global_std_changeyouraddress: 'Ändern Sie Ihre Adresse',
    txt_global_std_translationstoapprove: 'Übersetzungen zu genehmigen',
    txt_global_std_MSIG_Password3: 'ist',
    txt_global_std_privateonlyformyorg: 'Privat (nur für meine Organisation)',
    txt_global_std_settimeperiodforhowlongyourdatawillbestored: 'Legen Sie den Zeitraum fest, wie lange Ihre Daten gespeichert werden',
    txt_global_std_404notfound: 'Ressource nicht gefunden!',
    txt_global_std_ayesyoumustadviseyouragentthatyourvehicleisusedforaridesharingcompanyandtheywillapplytheridesharingendorsementtoyourpolicy: 'A: Ja, Sie müssen Ihrem Agenten mitteilen, dass Ihr Fahrzeug für ein Mitfahrunternehmen verwendet wird, und dieser wird die Mitfahrgelegenheit auf Ihre Police anwenden.',
    txt_global_std_thelinkwillbeavailableforxdays: 'Der Link ist für {x} Tage verfügbar.',
    txt_global_std_200ok: '200 - OK',
    txt_global_std_trygdrivehelpsyouwithmotivationandtipstodrivebettersaferandmoreecofriendly: 'Sidekick hilft Ihnen mit Motivation und Tipps, um besser, sicherer und umweltfreundlicher zu fahren.',
    txt_global_std_earnpointsbydrivingsafe: 'Sammeln Sie Punkte, indem Sie sicher fahren.',
    txt_global_std_privacy: 'Privatsphäre',
    txt_global_std_riskscore: 'Risiko-Score',
    txt_global_std_addaphoto: 'Foto hinzufügen',
    txt_global_std_p2ppushbodysetpriceforbookingto: '{name} set price for booking to: {currprice}',
    txt_global_std_easyas123: 'Einfach wie 1-2-3',
    txt_global_std_actualtranslation: 'Tatsächliche Übersetzung',
    txt_global_std_androidallowallthetime: '…aber um die richtigen Fahrstatistiken zu erhalten, müssen Sie zu den App-Einstellungen - Berechtigungen gehen und <b>Allzeit erlauben</b> auswählen',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint3: 'Detaillierte Einblicke für die Sicherheits- und Nachhaltigkeitsberichterstattung',
    txt_global_std_heattab: 'Wettrennen',
    txt_global_std_createnewgroup: 'Neue Gruppe erstellen',
    txt_global_std_cimage14d: 'Fahren Sie bergab, um eine höhere Geschwindigkeit zu erreichen.',
    txt_global_std_xkm: '{km} km',
    txt_global_std_pigsavedlocked: 'Für jeweils 50 Kronen bekommst du diesen Erfolg',
    txt_global_std_created: 'erstellt',
    txt_global_std_TaxInvoice14: 'DIES IST EIN COMPUTERERSTELLTES DOKUMENT, KEINE UNTERZEICHNUNG IST ERFORDERLICH',
    txt_global_std_requiredformatcolumnastext: 'Erforderlich, Spalte als Text formatieren',
    txt_global_std_therulesforthecompanychallengeareasfollowingbrtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsbrdriveminimum1kmduringaheatinordertoqualifyforthechallengebrthesamegeographicalroutewillbescoredamaximumof10timesinoneday: 'Die Regeln für die Firmen-Challenge lauten wie folgt:\n' +
      '<br>• Die Punktzahl der einzelnen Fahrer wird auf der Grundlage der besten 10 % der Fahrten als Durchschnittswert berechnet.\n' +
      '<br>• Fahren Sie während eines Laufs mindestens 1 km, um sich für die Herausforderung zu qualifizieren.\n' +
      '<br>• Dieselbe geografische Route wird maximal 10 Mal an einem Tag gewertet.',
    txt_global_std_drivingtogetherismorefun: 'Gemeinsam fahren macht mehr Spaß',
    txt_global_std_currentpassword: 'Derzeitiges Passwort',
    txt_global_std_unavailable: 'Nicht verfügbar',
    'txt_global_std_emailalreadyusedpleasetrywithanotheremail.': 'E-Mail bereits verwendet. Bitte versuchen Sie es mit einer anderen E-Mail.',
    txt_global_std_loadingpleasewait: 'Laden, bitte warten',
    txt_global_std_availablefromlevel: 'Verfügbar ab Stufe',
    txt_global_std_p2ptobesettled: 'Geklärt werden:',
    txt_global_std_makeyourselection: 'Bitte treffen Sie Ihre Auswahl unten:',
    txt_global_std_focusfeedbacktips: '<b> Behalten Sie die Straße im Auge </b> und konzentrieren Sie sich ganz auf das Fahren. Manchmal ist es leicht, sich von Umgebungen, Telefonen, Personen im Auto usw. ablenken zu lassen. <br> <br>\n' +
      '<b> Müdes oder gestresstes Fahren </b> kann den Fokus beeinträchtigen und Sie einem höheren Risiko aussetzen. <br> <br>\n' +
      '<b> Wir kümmern uns um Sie. </b>',
    txt_global_std_SulayemWhyjoin: 'Als 14-maliger FIA-Motorsport-Champion habe ich eine Leidenschaft fürs Fahren und den Motorsport. Ich bin fasziniert von den technologischen Fortschritten in der Automobilindustrie, die wir derzeit erleben. Diese müssen wir dazu nutzen um unsere Umwelt positiv zu beeinflussen.',
    txt_global_std_great: 'großartig',
    txt_global_std_TidemandBesttip: '',
    txt_global_std_enterpasswordtodeleteuseraccount: 'Geben Sie das Passwort ein, um das Benutzerkonto zu löschen',
    txt_global_std_p2pfromthismonth: 'Ab diesem Monat',
    txt_global_std_dolinkonthedamage: 'Mach {link} auf den Schaden',
    txt_global_std_rwmnoeligibletripstext: 'Registrieren Sie Ihre Mitfahrgelegenheit innerhalb von 1 Tag nach Ende der Fahrt.',
    txt_global_std_yourchangesweresaved: 'Ihre Änderungen wurden gespeichert ',
    txt_global_std_pickyourteamleader: 'Wählen Sie Ihren Teamleiter aus',
    txt_global_std_mar: 'Mrz',
    txt_global_std_averagemonthsmonths: 'Durchschnittlich {Monate} Monate',
    txt_global_std_doesagentacceptdriversthatarenotlicensedincountry: 'Akzeptiert {agent} Fahrer, die nicht in {country} lizenziert sind?',
    txt_global_std_majorDescription: '',
    txt_global_std_p2pnamesentalendrequesttoname2: '{name} hat eine Leihanfrage an {name2} gesendet',
    txt_global_std_subject: 'Thema',
    txt_global_std_rank: 'Rang',
    txt_global_std_OgierWhyjoin: 'Kommt bald ',
    txt_global_std_teamleaderboard: 'Team-Rangliste',
    txt_global_std_hereyoucanseehowmuchyouwouldhavegottenbackifyouhadhadatrygdrivecarinsurancethebonusiscalculatedbasedontheannualinsurancepriceyourkilometersdrivenperyearandthecartypebasedonthenumberplateyouhaveprovidedifyouhavenotstatedacurrentinsurancepricenumberofkilometersperyearandanumberplatethebonusiscalculatedonthebasisofanannualinsurancepriceofdkk5000andanannualmileageof20000km: 'Hier können Sie sehen, wie viel Sie bei einer Tryg Drive Autoversicherung zurückbekommen hätten. Der Bonus berechnet sich aus dem Jahresversicherungspreis, Ihrer gefahrenen Kilometer pro. Jahr und den Fahrzeugtyp anhand des von Ihnen angegebenen Nummernschilds. Wenn Sie keinen aktuellen Versicherungspreis angegeben haben, Anzahl Kilometer pro. Jahr und einem Nummernschild wird der Bonus auf der Grundlage eines jährlichen Versicherungspreises von 5.000 DKK berechnet. und eine jährliche Laufleistung von 20.000 km.\n',
    txt_global_std_sendchallenge: 'Herausforderung senden',
    txt_global_std_stats2: 'Statistiken',
    txt_global_std_p2pnomessages: 'Keine Nachrichten',
    txt_global_std_securetheaccidentsite: 'Unfallstelle sichern',
    txt_global_std_cashback: 'Cashback',
    txt_global_std_selectwhatyouwanttouse: 'Wählen Sie aus, was Sie verwenden möchten.',
    txt_global_std_p2pwaitingforacceptanceandprice: 'Warten auf Annahme und Preis.',
    txt_global_std_MikkelsenBesttip: 'Ich stelle immer sicher, dass ich genügend Abstand zu den Autos vor mir habe. Das bewahrt Sie vor unangenehmen Situationen und Sie können komfortabler, intelligenter und effizienter fahren.',
    txt_global_std_Gdpr_Text6: 'Mit freundlichen Grüßen das FIA Smart Team',
    txt_global_std_badgetitleplaceholder: 'Titel des Abzeichens',
    txt_global_std_getsexpenseandextradataofgiventrips: 'Ruft Kosten und zusätzliche Daten von bestimmten Reisen ab',
    txt_global_std_garminconnected: 'Garmin verbunden',
    txt_global_std_fiasdcisbettertogether: 'FIA SDC ist gemeinsam besser',
    txt_global_std_chooseateamleaderthatyouwanttocompeteforwerecommendoneoftheseteamleadersbasedonyourlocationandgoal: 'Wählen Sie einen Teamleiter aus, für den Sie antreten möchten. Wir empfehlen einen dieser Teamleiter basierend auf Ihrem Standort und Ziel.',
    txt_global_std_termsconditions: 'Terms & amp; Bedingungen',
    txt_global_std_tipcity2DESC: 'Der blaue Autofahrer nutzt die natürliche Verlangsamung, um reibungslos über die Kreuzung zu fahren. Der rote Autofahrer beschleunigt und bremst vor der Überfahrt zu stark.',
    txt_global_std_lighttheme: 'Lichtthema',
    txt_global_std_activationemail: 'Aktivierungs-E-Mail',
    txt_global_std_highcostperkilometer: 'Hohe Kosten pro Kilometer.',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimeincreasingspeedunnecessaryharshaccelerationisbadfortheenvironmentandforroadsafety: 'Auf dieser Art von Reise verbrachte der durchschnittliche Öko-Champion mehr Zeit damit, die Geschwindigkeit zu erhöhen. Unnötig hartes Beschleunigen schadet der Umwelt und der Verkehrssicherheit.',
    txt_global_std_savings: 'Ersparnisse',
    txt_global_std_opensettingsandmakesureyourbluetoothison: 'Öffnen Sie die Einstellungen und stellen Sie sicher, dass Bluetooth aktiviert ist.',
    txt_global_std_navigatebetweenmodules: 'Navigieren Sie zwischen den Modulen',
    txt_global_std_fiasdcnewsletter: 'FIA SDC NEWSLETTER',
    txt_global_std_emailclaims: 'E-Mail-Ansprüche',
    txt_global_std_connectingto: 'Verbinden mit',
    txt_global_std_mypoints: 'Meine Punkte',
    txt_global_std_pressthedrivebuttontogetstarted: 'Drücken Sie die Fahrtaste, um loszulegen.',
    txt_global_std_makeaclaim: 'Anspruch erheben',
    txt_global_std_carsharestats: 'Car-Share-Statistiken',
    txt_global_std_failsenddetails: 'Details konnten nicht gesendet werden',
    txt_global_std_getshareablelink: 'Holen Sie sich einen teilbaren Link',
    txt_global_std_imagerecognitioncompleted: 'Bilderkennung abgeschlossen',
    txt_global_std_startdrive2: 'Starten Sie die Fahrt',
    txt_global_std_cimage7d: 'Beschleunigen Sie schnell auf die Fahrtgeschwindigkeit, wenn der Verkehr dies zulässt.',
    txt_global_std_unknownracedriverShortDescription: 'Keiner',
    txt_global_std_p2ppushbodyhasacceptedthebooking: '{name} hat die Buchung angenommen.',
    txt_global_std_teamposition: 'Team Position:',
    txt_global_std_sendallpersonaldatatoyouremail: 'Senden Sie alle persönlichen Daten an Ihre E-Mail-Adresse.',
    txt_global_std_belowyoucanseemoredetailsaboutthistrip: 'Nachfolgend finden Sie weitere Details zu dieser Reise.',
    txt_global_std_applications: 'Anwendungen',
    txt_global_std_examplemessagepush: 'Sie haben eine Beispielanfrage von John Doe erhalten. Öffnen Sie die App, um jetzt zu antworten!',
    txt_global_std_topdriversnow: 'Top-Fahrer jetzt',
    txt_global_std_nousersfound: 'Keine Benutzer gefunden',
    txt_global_std_yourhavebeeninvitedby: 'Sie wurden eingeladen von:',
    txt_global_std_p2pgroupchatsettings: 'Gruppenchat-Einstellungen',
    txt_global_std_p2pviewall: 'Alle ansehen',
    txt_global_std_MarklundQuote: '',
    txt_global_std_yourposition: 'Ihre Position:',
    txt_global_std_notethisinformationwillbevisibletoanyonewhodownloadstheapp: 'Hinweis: Diese Informationen sind für jeden sichtbar, der die App herunterlädt.',
    txt_global_std_numoftrips: '{} Fahrten',
    txt_global_std_selectyourcarsbluetooth: 'Wählen Sie Ihre Autos Bluetooth',
    txt_global_std_otherinformation: 'Andere Informationen',
    txt_global_std_wanttojointhechallenge: 'Möchten Sie an der Herausforderung teilnehmen?',
    txt_global_std_whenyouarereadytodriveyoucanconnect: 'Wenn Sie fahrbereit sind, können Sie sich mit Ihrem Auto verbinden und hier Ihre erste Fahrt beginnen.',
    txt_global_std_both2: 'Beide',
    txt_global_std_MSIG_Onboarding14: 'Ihre Versicherungsbescheinigung ist beigefügt und durch Ihr NRIC oder FIN passwortgeschützt.',
    txt_global_std_ilendthecar: 'Ich leihe das Auto',
    txt_global_std_applanguage: 'Sprache der App',
    txt_global_std_searchonname: 'Nach Namen suchen',
    txt_global_std_set: 'einstellen',
    txt_global_std_content: 'Inhalt',
    txt_global_std_goback: 'Geh zurück',
    txt_global_std_to: 'Nach',
    txt_global_std_illegalxrule: 'Unzulässiges Wort: {word}',
    txt_global_std_agentofferselectronicfundstransferfromyourcheckingaccountorrecurringcreditcardpaymentfromyourcreditdebitcardyoucansignupforthesepaymentplanswhenyoustartyourpolicyoranytimeduringyourpolicytermbyloggingintoweburl: '{agent} bietet eine elektronische Überweisung von Ihrem Girokonto oder eine wiederkehrende Kreditkartenzahlung von Ihrer Kredit-/Debitkarte an. Sie können sich zu Beginn Ihrer Police oder jederzeit während der Vertragslaufzeit für diese Zahlungspläne anmelden, indem Sie sich bei {webUrl} anmelden.',
    txt_global_std_resetviewpassword: 'Passwort zurücksetzen und anzeigen',
    txt_global_std_aagentofferselectronicfundstransferfromyourcheckingaccountorrecurringcreditcardpaymentfromyourcreditdebitcardyoucansignupforthesepaymentplanswhenyoustartyourpolicyoranytimeduringyourpolicytermbyloggingintolink: 'A: {agent} bietet elektronische Überweisungen von Ihrem Girokonto oder wiederkehrende Kreditkartenzahlungen von Ihrer Kredit-/Debitkarte an. Sie können sich zu Beginn Ihrer Police oder jederzeit während der Vertragslaufzeit für diese Zahlungspläne anmelden, indem Sie sich bei {link} anmelden.',
    txt_global_std_whathappensnow: 'Was passiert jetzt?',
    txt_global_std_iflocationserviceisoffyourperformancegetworse: 'Wenn der Standortdienst deaktiviert ist, wird Ihre Leistung schlechter.',
    txt_global_std_unlocked: '(Entsperrt)',
    txt_global_std_seconds: 'Sekunden',
    txt_global_std_anemailhasbeensentwithyournewpassword: 'Es wurde eine E-Mail mit Ihrem neuen Passwort gesendet.',
    txt_global_std_accidentfaq: 'FAQ zu Unfällen',
    txt_global_std_timeandlocationistheonlymandatoryfield: 'Zeit & Ort ist das einzige Pflichtfeld. Bitte laden Sie innerhalb von 24 Stunden Bilder Ihres Autos hoch.',
    txt_global_std_riskportfoliotracker_upsell: 'Enerfy Risk Portfolio Tracker – Ihr Schlüssel zu operativer Exzellenz.',
    txt_global_std_vouchercode: 'Gutscheincode ',
    txt_global_std_getstartedguide: 'Anleitung starten',
    txt_global_std_OgierChamp: 'Hat 6 Mal den WRC und 1 Mal den J-WRC gewonnen',
    txt_global_std_heatsmall: 'Hitze',
    txt_global_std_yourmonthwithtrygdrive: 'Dein Monat mit TrygDrive!',
    txt_global_std_getspagedlistoftrips: 'Ruft eine ausgelagerte Liste der Fahrten ab',
    txt_global_std_p2phasreceivedyoureditrequest: 'hat Ihre Bearbeitungsanfrage erhalten',
    txt_global_std_startingdrive: 'Reise beginnen',
    txt_global_std_addyourbrandcolors: 'Fügen Sie Ihre Markenfarben hinzu',
    txt_global_std_introductionwelcome: 'Herzlich willkommen',
    txt_global_std_yourquoteformonthofmonth: 'Ihr Angebot für den Monat {month}',
    txt_global_std_parameters: 'Parameter',
    txt_global_std_openlog: 'Protokoll öffnen',
    txt_global_std_kintofaq6q: 'Ich kann keine Verbindung herstellen?',
    txt_global_std_activeachievements: 'Aktive Erfolge',
    txt_global_std_dontyouhaveacode: 'Haben Sie keinen Code?',
    txt_global_std_namemustacceptaninvitationtotheteamfirst: '{name} muss zuerst eine Einladung zum Team annehmen.',
    txt_global_std_allowaccesstobluetooth: 'Zugriff auf Bluetooth zulassen',
    txt_global_std_eco1: 'Öko',
    txt_global_std_p2phasspecifiedaprice: 'hat einen Preis angegeben',
    txt_global_std_share: 'Teilen',
    txt_global_std_p2pfaq23a: '1. Wählen Sie „Warikan“ aus dem „Menü“.\n' +
      '2. Wählen Sie „Einstellungen“ und wählen Sie die Änderungsmethode. Sie können zwischen "Nutzungsverhältnis", "Nutzungsdauer", "Gleichteilung" und "Kilometerstand" wählen.',
    txt_global_std_p2pfaq15a: 'Diese App hat keine Funktion zur Abrechnung von Ausgaben. Bitte vereinbaren und begleichen Sie die Kosten daher direkt und in Übereinstimmung mit dem Gesetz mit der Person.',
    txt_global_std_youmayhavetogotobluetoothsettingforbothyourphoneandcar: 'Möglicherweise müssen Sie die Bluetooth-Einstellung sowohl für Ihr Telefon als auch für Ihr Auto aufrufen.',
    txt_global_std_ecofriendweeklylockedkey: 'Fahren Sie umweltfreundlich und sparen Sie mehr als {co2value} CO₂ in einer Woche, um dieses Abzeichen zu erhalten.',
    txt_global_std_thisaccountdoesnothaveapaymentplanaddpaymentplantoaccesspaymentspage: 'Dieses Konto hat keinen Zahlungsplan. Zahlungsplan hinzufügen, um auf die Zahlungsseite zuzugreifen.',
    txt_global_std_getupandrunninginnotimewithourwhitelabelappsolution: 'Mit unserer White-Label-App-Lösung sind Sie im Handumdrehen einsatzbereit.',
    txt_global_std_PolicySchedule27: 'SGD3,500.00 ABSCHNITT I &amp; II WIRD GETRENNT FÜR FAHRER UNTER 22 JAHREN UND / ODER FÜR FAHRERFAHRUNGEN UNTER 2 JAHREN.',
    txt_global_std_yesiamsure: 'Ja, ich bin sicher!',
    txt_global_std_youhavebeeninvitedby: 'Sie wurden eingeladen von:',
    txt_global_std_p2pinvoiced: 'Fakturiert',
    txt_global_std_redeemfriendscode: 'Freundescode einlösen',
    txt_global_std_advancedadmin_upsell_pitch_bulletpoint1: 'Informationen und Unterstützung, um Ihre Entscheidungen zu treffen',
    txt_global_std_drivetime: 'Fahrzeit',
    txt_global_std_showalldrives: 'Alle Laufwerke anzeigen',
    txt_global_std_drivesafeandpaylessforyourfiat500subscription: 'Fahren Sie sicher und zahlen Sie weniger für Ihr Fiat 500-Abonnement',
    txt_global_std_kintosmsmailtext: 'Fahre mit mir sicherer! Folgen Sie dem Link unten und starten Sie die Fahrt mit Kinto.',
    txt_global_std_photosofyourcarscurrentcondition: 'Fotos des aktuellen Zustands Ihres Autos können gut zur Hand sein, wenn etwas mit Ihrem Auto passieren sollte.',
    txt_global_std_logsaved: 'Protokoll gespeichert',
    txt_global_std_createanaccounttostartyour30dayfreetrialnocreditcardrequired: 'Erstellen Sie ein Konto, um Ihre 30-tägige kostenlose Testversion zu starten. Keine Kreditkarte benötigt.',
    txt_global_std_p2pfaq6q: 'Wie verlasse ich einen Gruppenchat?',
    txt_global_std_p2pfaq17a: 'Sie müssen von einem Kinto-Besitzer eingeladen werden, um sie als Freund hinzuzufügen. Bitte bitten Sie Ihren Freund, Ihnen einen Einladungslink zu senden.',
    txt_global_std_ifyouareinneedofroadsideassistanceplease: 'Wenn Sie Pannenhilfe benötigen, bitte',
    txt_global_std_wouldntitbenicetobeabletosaythatasacompanyyoumakenoticabledifferenceinloweringtheimpactvehiclesanddrivinghaveontheworldstartyourownchallengeandmakethatimpacttoday: 'Wäre es nicht schön, sagen zu können, dass Sie als Unternehmen einen spürbaren Unterschied machen, wenn es darum geht, die Auswirkungen von Fahrzeugen und Fahren auf die Welt zu verringern? Starten Sie Ihre eigene Herausforderung und machen Sie Eindruck – noch heute!',
    txt_global_std_warikan_onboarding16: 'KINTO Co., Ltd. | 4-8-18 Meieki, Nakamura-ku, Nagoya City Nagoya Mitsui Gebäude Nordgebäude 14F',
    txt_global_std_MarklundBesttip: 'Zunächst planen Sie Ihr Fahren und sorgen Sie dafür, dass Sie genug Zeit haben, denn Stress ist niemals sinnvoll. Fahren Sie stets vorausdchauend, vor Ihnen könnte jemand bremsen. Vielleicht haben Sie die Zeit, den Fuss vom Gas zu nehmen, statt hart auf die Bremse zu steigen. Seien Sie geduldig und verabschieden Sie sich von Ihren alten Fahrstil. Passen Sie Ihren Fahrweise an den Verkehr und die Wetterbedingungen an. Ich weiß, es ist hart, aber es macht aus Ihnen einen intelligenteren Fahrer.',
    txt_global_std_p2pnocarsavailable: 'Keine Autos verfügbar',
    txt_global_std_selectyourcarinthelistsompo: '3. Wählen Sie Ihr Auto in der Liste aus:',
    txt_global_std_passwordchangefailed: 'Passwort ändern fehlgeschlagen',
    txt_global_std_ihaveanaccount: 'Ich habe einen Account',
    txt_global_std_notnowlater: 'Nicht jetzt',
    txt_global_std_yourpasswordshouldcontainatleast5charactersandmustbeenteredcorrectlytwice: 'Ihr Passwort sollte mindestens 5 Zeichen enthalten und muss zweimal korrekt eingegeben werden.',
    txt_global_std_rwmnodrivestext: 'Sie müssen sich mit Ihrem Auto verbinden und fahren, bevor Sie diese Funktion nutzen können. Kommen Sie zurück, um Ihre Fahrt zu registrieren, nachdem Sie eine Fahrt mit Freunden geteilt haben.',
    txt_global_std_gooddriving2: 'Gutes Fahren!',
    txt_global_std_p2ptoconfirmbooking: ' um die Buchung zu bestätigen',
    txt_global_std_createcommunicationthatreachesout: 'Schaffen Sie Kommunikation, die ankommt',
    txt_global_std_signedinasusername: 'Angemeldet als {userName}',
    txt_global_std_connectyourobdreader: 'Schließen Sie Ihren OBD-Leser an Ihr Auto an',
    txt_global_std_documentanyscratchesordingsonyourcar: 'Dokumentieren Sie Kratzer oder Dellen an Ihrem Auto.',
    txt_global_std_OgierBesttip: 'Kommt bald',
    txt_global_std_calmdriversoftengetbetterscore: 'Ruhige Fahrer bekommen oft bessere Punkte',
    txt_global_std_thepasswordcontainsforbiddencharacters6062orspace: "Das Passwort enthält verbotene Zeichen: ' & / &#60; &#62; , . = oder Leerzeichen",
    txt_global_std_owner2: 'Inhaber',
    txt_global_std_theapikeynamecreatedcreationdatewillbedeletedimmediatelyandpermanentlyoncedeleteditcannolongerbeusertomakeapirequests: 'Der API-Schlüssel {name}, erstellt am {creationdate}, wird sofort und dauerhaft gelöscht. Nach dem Löschen kann der Benutzer keine API-Anfragen mehr stellen.',
    txt_global_std_byconnectingtoyourcarwithyoursmartphone: 'By connecting to your car with your smartphone and the Enerfy app, your drive will be analyzed by our AI database, where your drive will be compared with more than 500 million driving profiles which will validate how safe and environmentally friendly you drive.',
    txt_global_std_publishapp: 'App veröffentlichen',
    txt_global_std_emergencyroadsideassistance: 'Pannenhilfe im Notfall',
    txt_global_std_avaragescoreofparticipants: 'Durchschnittliche Punktzahl der Teilnehmer',
    txt_global_std_invite: 'Einladen',
    txt_global_std_PolicySchedule18: 'PDP und SLEC',
    txt_global_std_ensammenligningmeddennekreturogetgennemsnitafalleandrebilisterskrselsadfrd: 'En sammenligning med denne køretur og et gennemsnit af alle andre bilisters kørselsadfærd.',
    txt_global_std_mine: 'Meine',
    txt_global_std_wehavesentanewverificationcodetophoneno: 'Wir haben einen neuen Bestätigungscode an {phoneNo} gesendet.',
    txt_global_std_youneedtoacceptallofthepermissionsinoderfortheapp: 'Sie müssen alle Berechtigungen akzeptieren, damit die App ordnungsgemäß funktioniert',
    txt_global_std_earngreatrewards: '- Verdiene tolle Belohnungen!',
    txt_global_std_currentstatsper: 'Aktuelle Statistik per',
    txt_global_std_signupletter: 'Sie sind auf der Liste!',
    txt_global_std_joinedparticipants: 'Beigetretene Teilnehmer',
    txt_global_std_all: 'Alles',
    txt_global_std_ttest96: 'das ist ein Test',
    txt_global_std_earn100pointswhenyoupurchasecdw5timestoyourbooking: 'Sammeln Sie 100 Punkte, wenn Sie CDW 5 Mal für Ihre Buchung kaufen',
    txt_global_std_anticipate: 'Erwarten',
    txt_global_std_p2pyouhavebookingswithoutregistereddistance: 'Sie haben Buchungen ohne registrierte Entfernung.',
    txt_global_std_ifyourecruitafriendbothyouandyourfriendget250kronerinthepiggybank: 'Wenn Sie einen Freund rekrutieren, erhalten Sie und Ihr Freund 250 Kronen im Sparschwein.',
    txt_global_std_PolicySchedule16: '2, 25, 57, 72, 89C, E28, E29, E33, E45, E48J, E6,',
    txt_global_std_p2pcancelbooking: 'Buchung stornieren',
    txt_global_std_upload123: 'Hochladen',
    txt_global_std_qhowdoisignupforagentinsurance: 'F: Wie melde ich mich für die {Agent}-Versicherung an?',
    txt_global_std_country: 'Land',
    txt_global_std_p2pdiscount: 'Rabatt',
    txt_global_std_yourpassword: 'Ihr Passwort',
    txt_global_std_timeandplace2: 'Zeit und Ort',
    txt_global_std_starburtslockedkey: 'Beende eine Fahrt auf Stufe 4.4 oder besser, um diesen Erfolg freizuschalten und 300 Punkte für deine Kinto-Brieftasche zu sammeln.',
    txt_global_std_MSIG_InsuranceLetter39: 'NIL für autorisierte Fahrer',
    txt_global_std_machinedamage123: 'Maschinenschaden',
    txt_global_std_heresyourauthenticationcode: 'Hier ist Ihr Authentifizierungscode:',
    txt_global_std_editpermissionsfor: 'Berechtigungen bearbeiten für',
    txt_global_std_fromthetoprightappsiconyoucannavigatebetweendifferentmoduleswithintheworkspace: 'Über das Apps-Symbol oben rechts können Sie zwischen verschiedenen Modulen innerhalb des Arbeitsbereichs navigieren',
    txt_global_std_therulesforthecompanychallengeareasfollowingtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsdriveminimum1kmduringaheatinordertoqualifyforthechallengethesamegeographicalroutewillbescoredamaximumof10timesinoneday: 'Die Regeln für die Firmen-Challenge lauten wie folgt:\n' +
      '• Die individuelle Fahrerwertung wird als Durchschnitt auf Basis der besten 10 % der Fahrten berechnet.\n' +
      '• Fahren Sie während eines Laufs mindestens 1 km, um sich für die Herausforderung zu qualifizieren.\n' +
      '• Die gleiche geografische Route wird maximal 10 Mal an einem Tag gewertet.',
    txt_global_std_somethingwhenwrongwithyourpasswordpleasetryanotherpassword: 'Irgendwas, wenn Sie mit Ihrem Passwort falsch liegen. Bitte versuchen Sie es mit einem anderen Passwort.',
    txt_global_std_maintainingintendedspeedisutmostimportantforfueleconomymindwanderingandlackoffocusoftencausesspeedvariationupanddowncausingunnecessaryaccelerationshenceincreasesfuelconsumption: 'Die Beibehaltung der beabsichtigten Geschwindigkeit ist äußerst wichtig für den Kraftstoffverbrauch. Gedankenschweifen und Konzentrationsmangel verursachen oft Geschwindigkeitsschwankungen nach oben und unten, was unnötige Beschleunigungen verursacht und somit den Kraftstoffverbrauch erhöht.',
    txt_global_std_singleaccident2: 'Einzelunfall',
    txt_global_std_reportandtrackyourresults: 'Berichte und verfolge deine Ergebnisse',
    txt_global_std_MarklundInterests: 'Motorschlitten, Natur / Tierwelt, Familie und Freunde',
    txt_global_std_GDPR: 'Bitte Kontoinformationen zu löschen',
    txt_global_std_theactivationcodehastobe36characters: 'Der Aktivierungscode muss 36 Zeichen lang sein',
    'txt_global_std_Al-KhelaifiMileage': 'Umm Bab nach Dukhan Highway (Route 39). Wegen der Höhenunterschiede und der Landschaft.',
    txt_global_std_p2peditbooking: 'Buchung bearbeiten',
    txt_global_std_failedtogettranslations: 'Übersetzungen konnten nicht abgerufen werden',
    txt_global_std_cost2: 'Kosten',
    txt_global_std_youwillbemailedyourpersonaldata: 'Wir werden Ihnen Ihre persönlichen Daten per Mail zusenden ',
    txt_global_std_hideadvancedsettings: 'Verberge erweiterte Einstellungen',
    txt_global_std_publishtranslations: 'Übersetzungen veröffentlichen',
    txt_global_std_kilometerkm: 'Kilometer (km)',
    txt_global_std_smartdriverweeklyunlockedkey: 'Um dieses Abzeichen zu erhalten, müssen Sie eine Woche lang eine Punktzahl von über 3,5 erreichen',
    txt_global_std_ttest95: 'das ist ein Test',
    txt_global_std_HusseinAbdullaInterests: 'Autos, Ralleys, Rennen',
    txt_global_std_chataboutabookingorstartagroupchatwithyourinvitedfriends: 'Chatten Sie über eine Buchung oder starten Sie einen Gruppenchat mit Ihren eingeladenen Freunden.',
    txt_global_std_unregistereddriving: 'Nicht registriertes Fahren',
    txt_global_std_ConfirmFia_Text5: 'Ort',
    txt_global_std_february: 'Februar ',
    txt_global_std_OrderConfirmation: 'Auftragsbestätigung FIA Smart Driving Challenge',
    txt_global_std_thereisnotworkshopstoshow: 'Es gibt keine Workshops zu zeigen.',
    txt_global_std_PolicySchedule31: 'Ein Selbstbehalt von 100,00 USD gilt für jeden Schadensfall an der Windschutzscheibe. Danach wird die Windschutzscheibenabdeckung automatisch wieder hergestellt.',
    txt_global_std_noofdrivers: 'Anzahl der Fahrer',
    txt_global_std_jointheglobalcommunityreducingco2emission: 'Treten Sie der globalen Gemeinschaft bei und reduzieren Sie den CO2-Ausstoß.',
    txt_global_std_accentcolor: 'Akzentfarbe',
    txt_global_std_enteredodometervalue: 'Eingegebener Kilometerzählerwert',
    txt_global_std_minutes: 'Minuten',
    txt_global_std_youralreadyboundeddevices: 'Sie verwenden bereits Geräte',
    txt_global_std_permissions: 'Berechtigungen',
    txt_global_CoreTemplate_cost: 'Kosten',
    txt_global_std_300pointTripAchievementlocked: '{scoretype} {score} eine Fahrt mit {scoretype} {score} oder besser, um diesen Erfolg freizuschalten und 300 Punkte zu verdienen.',
    txt_global_std_everythingworkedasexpected: 'Alles funktionierte wie erwartet.',
    txt_global_std_about: 'Über',
    txt_global_std_yourfriends: 'Ihre Freunde',
    txt_global_std_ontheleaderboardpageyoucamseechallengesoverview: 'Auf der Leaderboard-Seite sehen Sie eine Herausforderungsübersicht.',
    txt_global_std_thetotalscoremeasuringbothyourrisklevelandecodriving: 'Die Gesamtpunktzahl misst sowohl Ihr Risikoniveau als auch Ihr umweltbewusstes Fahren.',
    txt_global_std_schedulesend: 'Senden planen',
    txt_global_std_typeofaccident: 'Unfallart',
    txt_global_std_upto6000kmmilage: 'Bis zu 6000km.',
    txt_global_std_p2ptogetaninvitationlinkfromakintoownerifyouarehavingtroublewiththelinkpleasecontactsupportandwewillhelpyou: 'Um ein Kinto-Auto auszuleihen, benötigen Sie einen Einladungslink von einem Kinto-Besitzer. Wenn Sie Probleme mit dem Link haben, wenden Sie sich bitte an den Support. Wir helfen Ihnen dann weiter',
    txt_global_std_decideyourownview: '',
    txt_global_std_youmustwriteareason: 'Sie müssen einen Grund schreiben',
    txt_global_std_passwordisnotvalid: 'Passwort ist ungültig.',
    txt_global_std_imageuploadfailed: 'Bild konnte nicht hochgeladen werden, bitte versuchen Sie es erneut',
    txt_global_std_achievementsdesc: 'Begeistern Sie Ihre Benutzer mit Erfolgen in Ihrer App. Erfolge sind In-App-Abzeichen und können einzigartig oder Sammlerstücke sein.',
    txt_global_std_notification: 'Benachrichtigung',
    txt_global_std_p2pyouhaveselectedmultipledays: 'Sie haben mehrere Tage ausgewählt',
    txt_global_std_silver: 'Silber',
    txt_global_std_speedareawithbest: 'Geschwindigkeitsbereich mit bestem Verbesserungspotential',
    txt_global_std_p2pinvitenewfriend: 'Lade einen neuen Freund ein',
    txt_global_std_declined: 'Abgelehnt',
    txt_global_std_redeemedpoints: 'Eingelöste Punkte',
    txt_global_std_usingtheapp: 'Verwenden der App',
    txt_global_std_findworkshop: 'Werkstatt finden',
    txt_global_std_Fia_Receipt_Text7: 'Bestell ID',
    txt_global_std_kintofaq2a: 'Ein sicherer Fahrer ist ein bewusster Fahrer. Beim sicheren Fahren geht es darum, sich zu 100% auf das Fahren und die Umgebung zu konzentrieren. Bleiben Sie wachsam und berücksichtigen Sie den Verkehrsrhythmus und andere Umstände wie Regen usw. PASSEN SIE IHRE GESCHWINDIGKEIT IMMER AN DIE VORHANDENEN BEDINGUNGEN AN.',
    txt_global_std_errorloggingintoyouraccountpleasetryagain: 'Fehler bei der Anmeldung bei Ihrem Konto, bitte versuchen Sie es erneut!',
    txt_global_std_qidriveforaridesharingcompanywillyouinsuremyvehicle: 'F: Ich fahre für ein Mitfahrunternehmen. Versichern Sie mein Fahrzeug?',
    txt_global_std_yourscore: 'Ihr Ergebnis',
    txt_global_std_leavewithoutsaving: 'Verlassen ohne zu speichern',
    txt_global_std_PolicySchedule32: 'Unterzeichnet für und im Namen des Unternehmens',
    txt_global_std_pushdriveendlevel4: 'Nu kører du risikabelt. Klik hende for at se, hvad du kan forbedre.',
    txt_global_std_p2pallcars: 'Alle Autos',
    txt_global_std_gold: 'Gold',
    'txt_global_std_Compare with my': 'Vergleich mit meinem/meiner/meinen',
    txt_global_std_notdrivingthiscar: 'Fahren Sie dieses Auto nicht?',
    txt_global_std_checkhowitwent: 'Überprüfen Sie, wie es gelaufen ist',
    txt_global_std_5discountonrenewal: '5% Rabatt auf Verlängerung',
    txt_global_std_fix: 'Fix',
    txt_global_std_cantfindyourcargoto: 'Können Sie Ihr Auto nicht finden? Gehe zu',
    txt_global_std_adduserstogroups: 'Benutzer zu Gruppen hinzufügen',
    txt_global_std_wereyoudriving: 'Bist du gefahren?',
    txt_global_std_viewinvoice: 'Rechnung anzeigen',
    txt_global_std_paidaftertheendoftheinsuranceyear: 'Auszahlung nach Ablauf des Versicherungsjahres.',
    txt_global_std_forfurtherinstructionspleasechooseyourconnectionmethod: 'Für weitere Anweisungen wählen Sie bitte Ihre Verbindungsmethode',
    txt_global_std_details: 'Details',
    txt_global_std_notsigneduptryg: 'Sie haben noch keine Sidekick-Versicherung abgeschlossen?',
    txt_global_std_p2pyescancelbooking: 'Ja, Buchung stornieren',
    txt_global_std_mmyy: 'MM / JJ',
    txt_global_std_orderednewpassword: 'Neues Passwort bestellt',
    txt_global_std_kintofaq1a: 'Nein, die App funktioniert im Hintergrundmodus! Stellen Sie sicher, dass Bluetooth- und Ortungsdienste auf Ihrem Telefon immer aktiviert sind.',
    txt_global_std_translations: 'Übersetzungen',
    txt_global_std_v2stargrade: 'Score',
    txt_global_std_poins: 'Punkte',
    txt_global_std_usethiskeyinyourapplicationby: 'Verwenden Sie diesen Schlüssel in Ihrer Anwendung von.........',
    txt_global_std_gooddrivingperformance: 'Gute Fahrleistung',
    txt_global_std_driveforthefuture: 'Fahren Sie für die Zukunft.',
    txt_global_std_weareinareyou: 'Wir sind dabei – Sie?',
    txt_global_std_fleetcompanydescription: 'Fahrerrisikomanagement und CO2-Auswirkungen.',
    txt_global_std_wasthecartowed2: 'Wurde das Auto abgeschleppt?',
    txt_global_std_insuranceusage: 'Versicherungsnutzung',
    txt_global_std_yourpolicybeginsonthedateandtimewhenyouelectronicallysigntheapplicationandmakethedownpaymentonyourpolicy: 'Ihre Police beginnt mit dem Datum und der Uhrzeit, an dem Sie den Antrag elektronisch unterschreiben und die Anzahlung auf Ihre Police leisten.',
    txt_global_std_areyousureyouwanttoendyourcurrentrun: 'Sind Sie sicher, dass Sie Ihre aktuelle Reise beenden möchten?',
    txt_global_std_ohno: 'Ach nein',
    txt_global_std_keeptrackhowmanykilometersyouhavedriven: 'Verfolgen Sie, wie viele Kilometer Sie gefahren sind',
    txt_global_std_nextloayaltylevelprogressbar: 'Fortschrittsbalken für die nächste Loyalitätsstufe',
    txt_global_std_VilliersMusic: 'Wenn ich fahre höre ich jeglich Art von Musik. Ich bin da nicht so wählerisch, ehrlich gesagt.',
    txt_global_std_garminwatchconnected: 'Garmin-Uhr verbunden',
    txt_global_std_drivesafeandecofriendlyearngreatrewards: 'Fahren Sie sicher und umweltfreundlich\n– Verdiene tolle Belohnungen!',
    txt_global_std_forquestionscallagentatphonenumberphoneno: 'Bei Fragen rufen Sie {agent} unter der Telefonnummer {phoneNo} an',
    txt_global_std_p2ppushtitlenewexpensetoapprove: 'New expenses to approve',
    txt_global_std_unlockeddescription: 'Entsperrt, wenn der Ausweis empfangen wurde',
    txt_global_std_totalscore2: 'Gesamtpunktzahl',
    txt_global_std_insuranceinformation: 'Versicherungsinformation',
    txt_global_std_doihavetoactivatetheappeverytimeidrive: 'Muss ich die App jedes Mal aktivieren, wenn ich fahre?',
    txt_global_std_NewPW_Hello: 'Hallo',
    txt_global_std_namecannotbetoolong: 'Der Name darf nicht zu lang sein',
    txt_global_std_recruitafriend: 'Freund werben',
    txt_global_std_filters: 'Filter',
    txt_global_std_yourinvitationwassuccessfullysent: 'Ihre Einladung wurde erfolgreich versendet!',
    txt_global_std_currentlynotopdrivers: 'Derzeit keine Top-Treiber',
    txt_global_std_MSIG_InsuranceLetter3: 'Gesetz über Kraftfahrzeuge (Risiken und Entschädigungen Dritter) (Kapitel 189)',
    txt_global_std_finishedhead2head: 'Fertige Kopf-2-Kopf-Herausforderungen',
    txt_global_std_warikan_onboarding1: 'KINTO ONE Auftragnehmer',
    txt_global_std_p2pwecouldnotverifyyourkintonumberpleaselogintoyourkintoaccountandclickonthelinktodownloadthewarikanapp: 'Wir konnten Ihre Kinto-Nummer nicht überprüfen. Bitte melden Sie sich bei Ihrem Kinto-Konto an und klicken Sie auf den Link, um die Warikan-App herunterzuladen',
    txt_global_std_topdriversthishour: 'Top Fahrer diese Stunde',
    txt_global_std_pushinsurancerenewalodoremtitle: '{appname}',
    txt_global_std_yougetthiswhenyouhavescoredover4point410times: 'Sie erhalten dies, wenn Sie 10 Mal mehr als 4,4 Punkte erzielt haben',
    txt_global_std_myaccount: 'Mein Konto',
    txt_global_std_anemailaddressisrequired: 'Eine E-Mail-Adresse ist erforderlich',
    txt_global_std_howdoigetagoodscore: 'Wie bekomme ich eine gute Punktzahl?',
    txt_global_std_p2pmarkaspaid: 'Als bezahlt markieren',
    txt_global_std_SulayemInterests: 'Rennsport, Motorsport, Oldtimer',
    txt_global_std_reportclaim: 'Anspruch melden',
    txt_global_std_thismonth: 'Diesen Monat',
    txt_global_std_totalamountofaccountscreated: 'Gesamtbetrag der erstellten Konten',
    txt_global_std_myleaguetext: 'Du fährst in der Liga. Fahren Sie weiter smart und steigen Sie in der Rangliste auf.',
    txt_global_std_atthechallengedashboardyoucanviewyourchallengesandcreatenewonestocompeteindrivingsafeandecofriendly: 'Auf dem Herausforderungs-Dashboard können Sie Ihre Herausforderungen anzeigen und neue erstellen, um sicher und umweltfreundlich zu fahren.',
    txt_global_std_donthaveanaccountsignupsignuplink: 'Sie haben kein Konto? Melden Sie sich an{signuplink}',
    txt_global_std__login: 'Einloggen',
    txt_global_std_thisactionwillremoveyouraccount: 'Diese Aktion entfernt Ihr Konto und Ihre persönlichen Daten. Diese Aktion kann nicht rückgängig gemacht werden!',
    txt_global_std_howdoiearnaloyaltystatusanswer: 'Sie verdienen Ihren Loyalitätsstatus, indem Sie Punkte sammeln! Sie erhalten Punkte aus den verschiedenen verfügbaren Leistungen. Unsere treuen und sicheren {appname} -Fahrer erhalten Erfolge! Weitere Informationen finden Sie im Abschnitt „Erfolge“.',
    txt_global_std_MSIG_Password4: 'Mit freundlichen Grüßen MS First Capital',
    txt_global_std_translationtoolvisualeditor: 'Übersetzungstool - visueller Editor',
    txt_global_std_redeemyourrewards: 'Löse deine Belohnungen ein',
    txt_global_std_nocode: 'Kein Code? Klick hier',
    txt_global_std_f_lanzmileage: '200km Naturreise. Hier draußen in Südafrika können wir schnell der Stadt entfliehen und einen malerischen Nture Drive genießen. Sehr offener Raum und der Geist kann sich konzentrieren und verarbeiten und gleichzeitig entspannen.',
    txt_global_std_pushdriveendlevel2: 'Rigtigt godt arbejdet. Hvis du vil se blot info, så klik på hende.',
    txt_global_std_youredone: 'Sie sind fertig!',
    txt_global_std_numberno: 'Nein.',
    txt_global_std_VillersDescription: 'Giniel De Villiers ist nie in den Ruhestand gegangen. Noch besser: Nur einmal hat er es nicht in die Top 10 (2007) geschafft. Er hat 14 Etappensiege und 2009 den ersten Dakar-Wettbewerb in Südamerika gewonnen.',
    txt_global_std_youneedtoinputavalidemailformat: 'Sie müssen ein gültiges E-Mail-Format eingeben',
    txt_global_std_JyrkiainenDescription: 'Aufgewachsen im Motorsport in einer Rennsportfamilie, Tochter von Minna Sillankorva, 1991 Weltmeisterin der Damenrallye und neunfache finnische Meisterin',
    txt_global_std_youcannotopenthispopupagain: 'Sie können dieses Popup nicht erneut öffnen.',
    txt_global_std_addlicense: 'LIZENZ HINZUFÜGEN',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint1: 'Bewerten Sie das Fahrrisiko basierend auf tatsächlichen Fahrorten, nicht zu Hause oder am Arbeitsplatz',
    txt_global_std_numbercantinclude6062s: `Nummer darf nicht "'?&/&#60; &#62;\\s#!%$%^*- enthalten-`,
    txt_global_std_gointoapp: 'Gehen Sie in die App',
    txt_global_std_movethemarkertoseehowyourlevelaffectsyourdiscount: 'Bewegen Sie den Marker, um zu sehen, wie sich Ihr Level auf Ihren Rabatt auswirkt',
    txt_global_std_profile: 'Profil',
    txt_global_std_TidemandMusic: '',
    txt_global_std_SignupFia_Text10: '',
    txt_global_std_cimage39d: 'Ihr Fahrzeug rollt längere Strecken, als Sie glauben.',
    txt_global_std_youhavebeeninvited: 'Du bist eingeladen!',
    txt_global_std_disconnectallotherdevicesconnectedtotheobd: 'Trennen Sie alle anderen Geräte, die an den OBD-Reader angeschlossen sind. Es kann nur jeweils ein Gerät angeschlossen werden.',
    txt_global_std_yourdrivewillautomaticallyending: 'Ihr Laufwerk wird automatisch beendet',
    txt_global_std_monday: 'Montag',
    txt_global_std_activateyourinsurance: 'Aktivieren Sie Ihre Versicherung',
    txt_global_std_addyourcurrentmileagevalueinkmtakingapictureofyourodometeroursystemcouldaddthisvalueusingimagerecognition: 'Fügen Sie Ihren aktuellen Kilometerstand (Wert in km) hinzu und fotografieren Sie Ihren Kilometerzähler. Unser System könnte diesen Wert durch Bilderkennung steigern!',
    txt_global_std_totalco2savings: 'Gesamte CO2-Einsparung',
    txt_global_std_finalistsnationalchallenges: 'Finalisten - NATIONALE HERAUSFORDERUNGEN',
    txt_global_std_youhavereferred10friendsnice: 'Sie haben 10 Freunde empfohlen! Nett!',
    txt_global_std_heats: 'Ereignisse ',
    txt_global_std_addedodometervaluemileage: 'Zusätzlicher Kilometerzählerwert (Kilometerstand)',
    txt_global_std_usercannotberemoved: 'Benutzer {{email}} kann nicht entfernt werden.',
    txt_global_std_p2pcarlend: 'Wagen',
    txt_global_std_rowsperpage: 'Zeilen pro Seite',
    txt_global_std_100kmunlockedkey: 'Du bist mit der App 100 km gefahren!',
    txt_global_std_pleaseenteryourpasswordtodeletethischallenge: 'Bitte geben Sie Ihr Passwort ein, um diese Herausforderung zu löschen',
    txt_global_std_distractions: 'Ablenkungen',
    txt_global_std_motorclaimshotline1: 'Hotline für Motoransprüche',
    txt_global_std_actionsheader: 'Aktionen',
    txt_global_std_manualstartstopofdrive: 'Manual start/stop of drive',
    txt_global_std_PolicySchedule8: 'Marke / Körpertyp',
    txt_global_std_pioneergetstartedguide: 'Erste-Schritte-Anleitung',
    txt_global_std_therearenocostsyet: 'Es fallen noch keine Kosten an!',
    txt_global_std_p2pmessage: 'Botschaft',
    txt_global_std_befocused: 'Sei konzentriert',
    txt_global_std_MSIG_InsuranceLetter42: 'SGD3,500.00 ABSCHNITT I &amp; II WIRD SEINEN FAHRERN, DIE UNTER 22 JAHREN UND / ODER WENIGER ALS 2 JAHRE FAHRERFAHRUNG HABEN, GETRENNT.',
    txt_global_std_policynumber1: 'Versicherungsnummer',
    txt_global_std_p2pend: 'Ende:',
    txt_global_std_silverlevel: 'Silberstufe',
    txt_global_std_yourrecenttrips: 'Ihre letzten Reisen',
    txt_global_std_support: 'Unterstützung',
    txt_global_std_addyourcurrentmileagevalueinkm: 'Fügen Sie Ihren aktuellen Kilometerstand hinzu (Wert in km)',
    txt_global_std_joined: 'Teilgenommen ',
    txt_global_std_turnoncarengine: 'Automotor einschalten',
    txt_global_std_youcanstillusetheapptogetinsightsandstatisticsonyourdriving: 'Sie können die App weiterhin verwenden, um Einblicke und Statistiken zu Ihrem Fahren zu erhalten.',
    txt_global_std_connectyourdevice: '2. Schließen Sie Ihr Gerät an',
    txt_global_std_youarealreadydrivingwhenyouhavefinished: 'Sie fahren bereits. Wenn Sie Ihre Fahrt beendet haben, finden Sie hier Ihr Auto.',
    txt_global_std_qdoessompoacceptdriversthatarenotlicensedintheunitedstates: 'F: Akzeptiert Sompo Fahrer, die nicht in den USA lizenziert sind?',
    txt_global_std_p2pmemberuserguide: 'Benutzerhandbuch für Mitglieder',
    txt_global_std_diesel: 'Diesel',
    txt_global_std_lokaliteterndvendigforatvurderedinkrselsadfrdogberegnedinkrescore: 'Lokalitet er nødvendig for at vurdere din kørsels-adfærd og beregne din kørescore.',
    txt_global_std_turnonbluetoothinyourphone: 'Schalten Sie Bluetooth in Ihrem Telefon ein.',
    txt_global_std_MSIG_InsuranceLetter29: 'Ausgestellt in Singapur am',
    txt_global_std_january: 'Januar',
    txt_global_std_warning: 'Warnung',
    txt_global_std_youdroveatscoretypescoreorbettergreatyouhaveunlockedthisbadge: 'Du bist mit {scoretype} {score} oder besser gefahren! Groß! Sie haben dieses Abzeichen freigeschaltet.',
    txt_global_std_drivingsmartmeansusinglessenergyandlessenergymeanslowerco2emissions: 'Intelligentes Fahren bedeutet, weniger Energie zu verbrauchen, und weniger Energie bedeutet weniger CO2-Emissionen.',
    txt_global_std_pigsavedunlocked: 'Für jeweils 50 Kronen erhalten Sie diese Auszeichnung',
    txt_global_std_qwhenwillmyratechange: 'F: Wann ändert sich mein Tarif?',
    txt_global_std_alertmaxspeedtext: 'Max Geschwindigkeitsalarm ausgelöst.',
    txt_global_std_entertheemailaddressthatyouhaveregisteredonyouragentaccount: 'Geben Sie die E-Mail-Adresse ein, die Sie in Ihrem {agent}-Konto registriert haben',
    txt_global_std_oneuniformmeasurementregardlessofvehicletypeandgeography: 'Eine einheitliche Messung unabhängig von Fahrzeugtyp und Standort',
    txt_global_std_august: 'August',
    txt_global_std_cimage25d: 'Untersuchen Sie, welche Drehzahl Sie bei 80 km / h im höchsten Gang haben. Versuchen Sie dann, bei allen Gängen unterhalb dieser Drehzahl zu schalten',
    txt_global_std_ridewithmeunlockedkey: 'Gut gemacht! Gemeinsam im Auto zu fahren, statt getrennte Autos zu nehmen, spart viel CO2! Sie können dieses Abzeichen einmal pro Woche verdienen.',
    txt_global_std_p2pbyname: 'Namentlich',
    txt_global_std_obdreader: 'OBD-Leser',
    txt_global_std_howdoicompeteinenerfyloyaltychallengeanswer: 'Sie nehmen automatisch teil, wenn Sie sich anmelden. Keine Sorge, in der Rangliste wird nur Ihr Spitzname angezeigt, sodass Sie immer anonym bleiben. Es gibt einen monatlichen und jährlichen Wettbewerb.',
    txt_global_all_saturday: 'Samstag',
    txt_global_std_p2pnamewantstoborrowvehicle: '{name} möchte {vehicle} ausleihen',
    txt_global_std_activatebluetoothsothedevicecanconnectyourphonewithyourcar: 'Aktivieren Sie Bluetooth, damit das Gerät Ihr Telefon mit Ihrem Auto verbinden kann',
    txt_global_std_BeforeFirstBill_Text4: 'Kann ich meine Kosten verfolgen?',
    txt_global_std_PSolbergMileage: 'demnächst',
    txt_global_std_editapikey: 'API-Schlüssel bearbeiten',
    txt_global_std_p2pfaq4a: 'Halten Sie die Nachricht, die Sie löschen möchten, gedrückt. Daraufhin wird ein Dialogfeld angezeigt, in dem Sie die Nachricht löschen können.',
    txt_global_std_progressstatus: 'Fortschrittsstatus',
    txt_global_std_p2ppushtitlerevokeadmin: 'Administratorrechte widerrufen',
    txt_global_std_sdcrapply2019price2: '500 SEK Benzinchecks',
    txt_global_std_preparingyournewenerfyhome: 'Ihr neues {appName} Zuause wird vorbereitet ...',
    txt_global_std_networkoffline: 'Netzwerk offline',
    txt_global_std_Alert_WereYouDrivingTitle: 'Bist du gefahren?',
    txt_global_std_loyaltysmsmailtext: 'Fahre mit mir sicherer! Folgen Sie dem Link unten und starten Sie die Fahrt mit',
    txt_global_std_invitefriendsgetawards: 'Freunde einladen. Holen Sie sich Auszeichnungen.',
    txt_global_std_failedtopublishedlanguage: 'Fehler beim Veröffentlichen der Sprache!',
    txt_global_std_unlocklimitheader: 'Limit freischalten',
    txt_global_std_SignupFia_Text8: 'Bleiben Sie dran - Folgen Sie uns in den sozialen Medien',
    txt_global_std_percentagedissconnectedtripsmax25: '{percentageDissconnectedTrips}% nicht verbundene Fahrten (von max. 25%)',
    txt_global_std_users: 'Benutzer',
    txt_global_std_orderobd: 'OBD bestellen',
    txt_global_std_challengesent2: 'Herausforderung gesendet!',
    txt_global_std_verifyyourchanges: 'Überprüfen Sie Ihre Änderungen',
    txt_global_std_belowisyourlocationsoyoucaneasilydescribewhereyouaretotheroadassistantmanager: 'Unten finden Sie Ihren Standort, damit Sie dem Road Assistant-Manager leicht beschreiben können, wo Sie sich befinden.',
    txt_global_std_trafficdelaysdeadlinesandrunninglatetheycanallcausestresswhichisnegativeforyourhealthandsafetyallowingextratimeplanningyourrouteandcheckingtravelupdatescanhelptoreducestressandimproveyourfocus: 'Verkehrsverzögerungen, Fristen und Verspätungen; Sie alle können Stress verursachen, was sich negativ auf Ihre Gesundheit UND Sicherheit auswirkt. Wenn Sie zusätzliche Zeit einplanen, Ihre Route planen und Reiseaktualisierungen überprüfen, können Sie Stress abbauen und Ihre Konzentration verbessern.',
    txt_global_std_TaxInvoice2: 'Datum',
    txt_global_std_current: 'Aktuell:',
    txt_global_std_refer10friendsachievementlocked: 'Empfehlen Sie 10 Freunde, um diesen Erfolg freizuschalten und 300 Punkte zu verdienen.',
    txt_global_std_removephoto: 'Foto entfernen',
    txt_global_std_pleaseenteravalidemail: 'Bitte geben Sie eine gültige Email-Adresse ein',
    txt_global_std_p2pblockcar: 'Auto blockieren',
    txt_global_std_countryroadfilechangeDESC: 'Der blaue Autofahrer nimmt eine Spur und fährt konzentriert. Der rote Autofahrer hakt das Auto vor und wechselt stressig die Fahrspur.',
    txt_global_std_discountinformation: 'Ihr durchschnittliches Niveau in diesem Monat ist die Grundlage für Ihren Rabatt. Sie erhalten den Rabatt, wenn Sie Ihre Versicherung für die nächsten Monate verlängern',
    txt_global_std_business2: 'Geschäft',
    txt_global_std_cimage30d: 'Eine Beschleunigung, gefolgt von einer Verzögerung, ist Geldverschwendung ist Geldverschwendung.',
    txt_global_std_30daysfreetrialnocreditcardrequired: '30 Tage kostenlos testen. Keine Kreditkarte benötigt.',
    txt_global_std_onceyoupairtheapptoyourvehiclesbluetoothandchangethesettingstoallowlocationserviceswematchyourdrivinghabitstoexistingdriversandprovideascoreinsuranceisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsabouttheagentappandcompeteagainstthemforlowerscores: 'Sobald Sie die App mit dem Bluetooth Ihres Fahrzeugs koppeln und die Einstellungen ändern, um Ortungsdienste zuzulassen, gleichen wir Ihre Fahrgewohnheiten mit vorhandenen Fahrern ab und geben eine Bewertung ab. Die Versicherung richtet sich danach, wer Ihr Fahrzeug fährt und wie gut er fährt. Jeder, der Ihr Fahrzeug fährt, wird ermutigt, die App herunterzuladen und seine Ergebnisse zu verfolgen. Erzählen Sie Ihren Freunden von der {agent}-App und treten Sie gegen sie an, um niedrigere Punktzahlen zu erzielen.',
    txt_global_std_locationservicesnotsettoalwaysallow: 'Ortungsdienste sind nicht auf Immer zulassen eingestellt',
    txt_global_std_yourfriendsname: 'Der Name Ihres Freundes',
    txt_global_std_accepted: 'Akzeptiert',
    txt_global_std_thesaferyoudrivethehigherthesaving: 'Je sicherer Ihr Laufwerk - desto höher die Einsparung!',
    txt_global_std_clear: 'Klar',
    txt_global_std_achievementcannotbedeletedpopup: 'Dieser Erfolg kann nicht gelöscht werden.',
    txt_global_std_alluploadedphotoswillbemarkedwithacheckmark: 'Alle hochgeladenen Fotos werden mit einem Häkchen markiert.',
    txt_global_std_p2paddedbyyou: 'Von dir hinzugefügt',
    txt_global_std_partlyavailable: 'Teilweise verfügbar',
    txt_global_std_p2pbookingconfirmed: 'Buchung bestätigt',
    txt_global_std_avgtimeonroad7d: 'Durchschnittliche Zeit auf der Straße 7D',
    txt_global_std_createyouraccounttosaveyourtripsandearnbonusetc: 'Erstellen Sie Ihr Konto, um Ihre Fahrten zu speichern, Bonus zu verdienen und Zugang zu Ihrem persönlichen Fahrertrainer zu erhalten. Sie können Ihr Konto zu einem späteren Zeitpunkt erstellen.',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint1: 'Umfassende Lösung für vorausschauendes Risiko-/Nachhaltigkeitsmanagement',
    txt_global_std_warikan_onboarding9: '5. Wählen Sie das Datum und die Uhrzeit der Nutzung des Autos aus dem Kalender und nehmen Sie eine Reservierung vor',
    txt_global_std_exampleemail: '',
    txt_global_std_startguidetip2: 'Stellen Sie sicher, dass Bluetooth und der Standortdienst aktiviert sind.',
    txt_global_std_getspecialoffers: 'Bekommen Sie spezielle Angebote ',
    txt_global_std_acceptfreemiumterms: 'Accept terms and policies',
    txt_global_std_MikkelsenQuote: 'Es ist mir eine Ehre, in diesem spannenden Projekt als Teamleiter ausgewählt zu werden! Ich bin auf der elitären Ebene des Motorsports, und wenn ich als Fahrer andere dazu inspirieren kann, kluge, sichere und umweltfreundliche Fahrer zu werden, bin ich froh, das zu tun. Tritt heute meinem Team bei und lass uns intelligenter fahren!',
    txt_global_std_searchbartext: 'Suche nach xxx, xxx oder xxx',
    txt_global_std_qhowlongwillittaketoprocessmyclaim: 'F: Wie lange dauert die Bearbeitung meiner Reklamation?',
    txt_global_std_PolicySchedule23: 'Prämie',
    txt_global_std_insurancecompany: 'Versicherungsunternehmen',
    txt_global_std_TaxInvoice15: 'Eingetragen von STELLAL',
    txt_global_std_thepasswordhasbeenreset: 'Das Passwort wurde zurückgesetzt.',
    txt_global_std_youreinvited: 'Ihr seid eingeladen!',
    txt_global_std_resetpasswordsuccess: 'Erfolg! Suchen Sie in Ihrer E-Mail nach einem Link zum Zurücksetzen des Passworts.',
    txt_global_std_enerfycoachtip3: '3. Bleiben Sie beim Fahren ruhig, auch wenn Sie es eilig haben',
    txt_global_std_whatwillhappenifmyphonelosessignalorifmyphone: 'What will happen if my phone loses signal or if my phone runs out of battery?',
    txt_global_std_bestregardscomma: 'Mit freundlichen Grüßen,',
    txt_global_std_competeindividuallyonly: 'Nur einzeln antreten',
    txt_global_std_paid: 'Bezahlt',
    txt_global_std_friendwasremoved: 'Freund wurde entfernt',
    txt_global_std_makesureyourengineison: 'Stellen Sie sicher, dass Ihr Motor eingeschaltet ist',
    txt_global_std_confirmandcreateaccount: 'Konto bestätigen und erstellen',
    txt_global_std_pigsaved: '50 Kronen gespart',
    txt_global_std_qcanitrytheappbeforesigningup: 'F: Kann ich die App testen, bevor ich mich anmelde?',
    txt_global_std_HusseinAbdullaQuote: 'Zitat kommt bald ',
    txt_global_std_p2ppushtitleborrowcar: '{name} - Auto ausleihen?',
    txt_global_std_home: 'Zuhause',
    txt_global_std_youareabouttopublishtranslationspleasemakesureyouhavereviewedalltranslationsbeforeproceeding: 'Sie sind im Begriff, Übersetzungen zu veröffentlichen, stellen Sie bitte sicher, dass Sie alle Übersetzungen überprüft haben, bevor Sie fortfahren.',
    txt_global_std_patternprofiling: 'Musterprofilierung',
    txt_global_std_qualifications: 'Qualifikationen:',
    txt_global_std_p2pfaq32a: '1. Zu Beginn des Monats wird automatisch eine „Warikan“-Erinnerung auf dem Startbildschirm angezeigt. Oder wählen Sie „Warikan“ aus dem „Menü“.\n' +
      '2. Wählen Sie "Warikan" für den Monat.\n' +
      '3. Wählen Sie im Pulldown-Menü vier Aufteilungsmethoden aus und überlegen Sie, wie Sie die Kosten aufteilen. Sie können zwischen "Nutzungsverhältnis", "Nutzungsdauer", "Gleichteilung" und "Kilometerstand" wählen.\n' +
      '4. Überprüfen Sie als nächstes die Kosten, die für jedes Mitglied in Rechnung gestellt werden. Sie können den Betrag anpassen, indem Sie die Bleistiftmarkierung auswählen.\n' +
      '5. Wählen Sie Kosten berechnen aus, um Mitglieder zu belasten.\n' +
      '6. Wenn Sie die Kosten vom Mitglied erhalten, wählen Sie "Zahlung abschließen".',
    txt_global_std_SulayemMusic: 'Smooth Jazz',
    txt_global_std_competeforthesebadges: 'Kämpfe um diese Abzeichen',
    txt_global_std_youaretheowner: '',
    txt_global_std_staytunedonupcomingchallenges: 'Folgen Sie kommenden Herausforderungen.',
    txt_global_std_statstotal: 'Gesamt',
    txt_global_std_signin: 'Anmelden ',
    txt_global_std_p2ptypeofexpenses: 'Art der Ausgaben',
    txt_global_std_estimatedcost: 'Geschätzte Kosten',
    txt_global_std_automatic_start_and_stop_requires_access_to_bluetooth_location_services_and_motion_fitness: 'Automatisches Starten und Stoppen erfordert Zugriff auf Bluetooth, Ortungsdienste (GPS) und Bewegung & Fitness.',
    txt_global_std_defaultfirsttimeusingtheserviceregisterfromsignuplink: 'Verwenden Sie den Dienst zum ersten Mal? Registrieren Sie sich über {signUpLink}',
    txt_global_std_p2pcalculationswillnotbecorrect: 'Berechnungen werden nicht korrekt sein.',
    txt_global_std_youcanfileaclaimbyclickingonfileaclaiminyourenerfyapp: 'Sie können einen Anspruch einreichen, indem Sie in Ihrer Enerfy-App auf Anspruch einreichen klicken.',
    txt_global_std_illdothislater: 'Ich mache das später',
    txt_global_std_dataintervalupload: '20 Sek',
    txt_global_std_youneedtofillindriversname: 'Sie müssen den Treibernamen eingeben',
    txt_global_std_challengeafriend: 'Fordere einen Freund heraus!',
    txt_global_std_sorrytheimagerecognitionfailed: 'Entschuldigung, die Bilderkennung ist fehlgeschlagen',
    txt_global_std_emailaddress: 'Email Adresse',
    txt_global_std_p2pmycars: 'Meine Autos',
    txt_global_std_howdoiearnsavings: 'Wie verdiene ich Ersparnisse?',
    txt_global_std_getupdatesregardingnews: 'Erhalten Sie Updates zu Neuigkeiten und Herausforderungen!',
    txt_global_std_closestworkshop: 'Nächste Werkstatt',
    txt_global_std_sidekickprofilepreferences: 'Profileinstellungen',
    txt_global_std_stardriverunlockedkey: 'Du bist ein Star, {user}! Du hast heute eine Punktzahl über {score}!',
    txt_global_std_atthecompanychallengepageyoucancreatenewprivatechallengeswhereyoucancompeteindrivingsafeandecofriendly: 'Auf der Seite „Company Challenge“ können Sie neue private Herausforderungen erstellen, bei denen Sie sich im sicheren und umweltfreundlichen Fahren messen können.',
    txt_global_std_therecouldbeotherreasonswhyyourcarsbluetoothyourbluetoothbeacondoesntshowupinthelist: 'Es kann andere Gründe geben, warum Sie\n' +
      'Das Bluetooth des Autos/Ihr Bluetooth-Beacon wird nicht im angezeigt\n' +
      'aufführen:',
    txt_global_std_rwmnofriendstitle: 'Du hast noch keine Freunde',
    txt_global_std_p2ppushtitlecancelledperiodfromto: 'Die Buchung mit dem Zeitraum {from} wurde storniert.',
    'txt_global_std_sorryiobd2errorcodereadingnotsupportedbywindowsphone.': 'Das Lesen von iOBD2-Fehlercodes wird in Windows Phone nicht unterstützt.',
    txt_global_std_warikan_onboarding3_1: 'なお、仮パスワードの有効期限は72時間となっております。\n' +
      '超過 後 は, 「わりかん kinto」 アプリ の 「仮 ワ ー ワ を 再 送信 より ワ メ メ メ メ 受け取っ て メ メ メ メ メ 受け取っ て いただき メ メ メ お願いいたし ます\n' +
      '※ 本 メ ー ル は 送信 専用 です メ ご 返信 て も お応え お応え い た ね ます ので お問い合わせ お問い合わせ は 下記 へ お願いいたし ます ます お問い合わせ お問い合わせ は 下記 お願いいたし ます.',
    txt_global_std_english: 'Englisch',
    txt_global_std_pleaseconfirmyourlicenseplate: 'Bitte bestätigen Sie Ihr Kennzeichen:',
    txt_global_std_aifyourcardoesnthavebluetoothpleasecontactsupportatphonenotoorderanobdreaderthatyouwillneedtoplugintoyourcar: 'A: Wenn Ihr Auto kein Bluetooth hat, wenden Sie sich bitte unter {phoneNo} an den Support, um ein OBD-Lesegerät zu bestellen, das Sie an Ihr Auto anschließen müssen.',
    txt_global_std_ignore: 'Ignorieren ',
    txt_global_std_someviewslookdifferentdependingonifthereisdrivingdataornotyoucanlogintoyouraccountintheapporusethedifferentuserstatestoviewandtranslatetheappwithorwithoutdrivingdata: '* Einige Ansichten sehen unterschiedlich aus, je nachdem, ob Fahrdaten vorhanden sind oder nicht. Sie können sich in der App bei Ihrem Konto anmelden oder die verschiedenen Benutzerstatus verwenden, um die App mit oder ohne Fahrdaten anzuzeigen und zu übersetzen.',
    txt_global_std_phonenumberdoesnotexist: 'Telefonnummer existiert nicht!',
    txt_global_std_themescreendesign: 'Thema / Bildschirmdesign',
    txt_global_std_my_saving: 'MEIN SPAREN',
    txt_global_std_finalistsheat2: 'Finalisten - Heat 2',
    txt_global_std_addusersto: 'Benutzer hinzufügen zu',
    txt_global_std_yourchallenges: 'Ihre Herausforderungen',
    txt_global_std_gender: 'Geschlecht',
    txt_global_std_youraddress: 'Deine Adresse',
    txt_global_std_company: 'Firma ',
    txt_global_std_MSIG_InsuranceLetter13: 'Versicherter Schätzwert',
    txt_global_std_reminderinsurance: 'Erinnerung',
    txt_global_std_bluetoothtroubleshooting: 'Bluetooth trouble shooting',
    txt_global_std_cimage28d: 'Verwenden Sie den Tempomat nur auf ebenen Straßen,  halten Sie das Gas ansonsten ruhig.',
    txt_global_std_joinussignupforourcommunityandbecomeapartofus: 'Mach mit, melde dich für unsere Community an und werde ein Teil von uns. Sobald Sie Ihre Einwilligung erteilt haben, können Sie an unseren Wettbewerben teilnehmen oder einen Freund herausfordern.',
    txt_global_std_p2pwarningexpenses: 'Stellen Sie sicher, dass Sie zuerst Ihre Ausgaben hinzufügen. Das können Sie später nicht mehr.',
    txt_global_std_youupgradedtovalueplanyouhaveearned10000points: 'Sie haben ein Upgrade auf Value Plan durchgeführt. Sie haben 10000 Punkte verdient.',
    txt_global_std_igetit: 'Ich verstehe es!',
    txt_global_std_registeryourridesharingtripwithin1dayafterthetriphasended: 'Registrieren Sie Ihre Mitfahrgelegenheit innerhalb von 1 Tag nach Ende der Fahrt',
    txt_global_std_darktheme: 'Dunkles Thema',
    txt_global_std_designandsendpushnotificationstoyourteam: 'Entwerfen und senden Sie im Handumdrehen Push-Benachrichtigungen an Ihr Team oder planen Sie sie für die beste Customer Journey.',
    txt_global_std_tipcityparking1DESC: 'Der blaue Autofahrer benutzt einen Indikator und wartet, bis eine größere Lücke zwischen den Autos besteht. Das rote Auto verlässt die Parklücke ohne Anzeige oder wartet auf eine natürliche Lücke im Verkehr.',
    txt_global_all_friday: 'Freitag',
    txt_global_std_p2prequestanewpassword: 'ein neues Passwort anfordern',
    txt_global_std_newpassword: 'Neues Passwort',
    txt_global_std_seewherepoliciesarewrittenvswheretheyaredriving: 'Sehen Sie, wo Richtlinien geschrieben werden und wo sie fahren',
    txt_global_std_shareyourinvitecode: 'Teilen Sie Ihren Einladungscode',
    txt_global_std_ridewithme: 'Fahr mit mir',
    txt_global_std_yourfriendemail: 'ihr-freund@email.com',
    txt_global_std_p2pchataboutabookingorstartagroupchatwithyourinvitedfriends: 'Chatten Sie über eine Buchung oder starten Sie einen Gruppenchat mit Ihren eingeladenen Freunden.',
    txt_global_std_yougetthisachievementafterdrivingafullweekandmaintaininganaveragescoreofover4: 'Sie erhalten diesen Erfolg, nachdem Sie eine ganze Woche gefahren sind und eine durchschnittliche Punktzahl von über 4,0 beibehalten haben',
    txt_global_std_setupyourcompanychallengeandinviteyourcoworkerstothefiasmartdrivingchallengecompeteagainsteachotherinafunwaytobecomebetterdriverssavemoreco2andimproveyourscore: 'Richten Sie Ihre Company Challenge ein und laden Sie Ihre Kollegen zur FIA Smart Driving Challenge ein. Treten Sie auf spielerische Weise gegeneinander an, um bessere Fahrer zu werden, mehr CO2 zu sparen und Ihre Punktzahl zu verbessern.',
    txt_global_std_howdoestheappwork: 'Wie funktioniert die App?',
    txt_global_std_activationcodeforname: 'Aktivierungscode für {name}',
    txt_global_std_1stdate: '1',
    txt_global_std_choosebetweenenteringchassinumberplatenumberorpolicynumber: 'Wählen Sie zwischen der Eingabe von Chassi-Nummer, Kennzeichen oder Richtliniennummer.',
    txt_global_std_gotochallenge: 'Gehen Sie zur Herausforderung',
    txt_global_std_p2pfaq12a: 'Ja, wenn Sie einen Freund entfernen, können Sie mit diesem Freund keine neuen Buchungen erstellen.\n' +
      '1.Gehen Sie aus dem Menü "Mehr" auf die Seite "Freunde" (drei Punkte).\n' +
      '2.Wählen Sie Ihren Freund aus, um dessen Profil anzuzeigen.\n' +
      '3.Drücken Sie die Einstellungsschaltfläche.\n' +
      '4.Wählen Sie Freund entfernen.',
    txt_global_std_redeemedon: 'Eingelöst am',
    txt_global_std_saturday: 'Samstag',
    txt_global_std_bluetooth: 'Bluetooth',
    txt_global_std_yourlatesttripwasbetterthanappnameaverage: 'Gut gemacht! Ihre letzte Reise war besser als der Durchschnitt von {appname} !',
    txt_global_std_p2pyouhavereachedthemaximumamountoffriendspercar: 'Du hast die maximale Anzahl an Freunden pro Auto erreicht',
    txt_global_std_getstartedandgainaccessto: 'Fangen Sie an und erhalten Sie Zugriff auf',
    txt_global_std_createcustom: 'Benutzerdefiniert erstellen',
    txt_global_std_loyaltylevel: 'Loyalitätsstufe',
    txt_global_std_you: 'Sie',
    txt_global_std_teamaveragescore: 'Durchschnittliche Punktzahl des Teams',
    txt_global_std_cropimage: 'Bild zuschneiden',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriends: 'Messen Sie sich im Alltag gegen Ihre Freunde. Der Fahrer mit der besten Punktzahl gewinnt die Runde!',
    txt_global_std_driverspolicy: 'Fahrerrichtlinie',
    txt_global_std_pleasemakesurethatyouareconnectedtoyourcarandtryagain: 'Bitte vergewissern Sie sich, dass Sie mit Ihrem Auto verbunden sind, und versuchen Sie es erneut.',
    txt_global_std_deletechallenge: 'Herausforderung löschen',
    txt_global_std_everydayheroeswearegatheringtomakeanimpactwedrivetoreduceourcoemissionsbybeingmoremindfulawareandactiveinthewaywetransportourselvesfromoneadventuretothenextbeittheweekendgetawayortogetthekidsfromsoccerpracticewemaketheworldsaferandbetterhowmuchletsfindout: 'Helden des Alltags – wir versammeln uns, um etwas zu bewirken. Wir streben danach, unsere CO²-Emissionen zu reduzieren, indem wir achtsamer, bewusster und aktiver von einem Abenteuer zum nächsten reisen (sei es beim Wochenendausflug oder um die Kinder vom Fußballtraining zu holen). Wir machen die Welt sicherer und besser. Wie viel? Lass es uns herausfinden!',
    txt_global_std_mysaving: 'MEIN SPAREN',
    txt_global_std_theparameterswerevalidbuttherequestfailed: 'Die Parameter waren gültig, aber die Anforderung ist fehlgeschlagen.',
    txt_global_std_automotivecompany: 'Automobilunternehmen',
    txt_global_std_p2pfinish: 'Fertig',
    txt_global_std_findmycar: 'Finden Sie Ihr Auto',
    txt_global_std_individual: 'Individuell',
    txt_global_std_kmleftyourinsuranceisrenewed: 'km übrig.\nIhre Versicherung wird erneuert',
    txt_global_std_p2pbookedby: 'Gebucht von',
    txt_global_std_p2pfaq18a: '1. Wählen Sie „Verbinden / Einstellungen“ aus der Menüleiste am unteren Bildschirmrand.\n' +
      '2. Bluetooth, Standortdienste, Bewegungs- und Fitnesseinstellungen zulassen. Wählen Sie für Ortungsdienste "Immer" für eine genaue Datenerfassung.\n' +
      '3. Nachdem Sie den Motor gestartet haben, verbinden Sie das Auto und die App über Bluetooth. Wenn der Name des Autos in der App angezeigt wird, wählen Sie "Auto auswählen".\n' +
      '4. Wenn Sie mit dem Fahren beginnen, erhalten Sie Echtzeit-Feedback zu Ihrem Fahren.\n' +
      '5. Nach Beendigung der Fahrt werden die Fahrdaten automatisch in der App gespeichert.',
    txt_global_std_optional2: '(Wahlweise)',
    txt_global_std_atleast8characters: 'Mindestens 8 Zeichen.',
    txt_global_std_uploadimage: 'Bild hochladen',
    txt_global_std_p2ppushtitlenewfriend: 'Neuer Freund',
    txt_global_std_p2paddmembers: 'Mitglieder dieser Gruppe',
    txt_global_std_allowlocationtoparticipateinfiasdc: 'Erlauben Sie dem Standort die Teilnahme an der FIA SDC',
    txt_global_std_bookingchat: 'Chat buchen',
    txt_global_std_summaryoftimedrivenperselectedperiod: 'Zusammenfassung der gefahrenen Zeit pro ausgewähltem Zeitraum.',
    txt_global_std_timeandcanonlybeusedonce: 'Zeit und kann nur einmal verwendet werden.',
    txt_global_std_PolicySchedule11: 'Fahrwerksnummer',
    txt_global_std_bad: 'Schlecht',
    txt_global_std_averagescore: 'Durchschnittliche Punktzahl',
    txt_global_std_MSIG_InsuranceLetter1: 'VERSICHERUNGSZERTIFIKAT',
    txt_global_std_yourinsurancewasautomaticallyrenewalformonth: 'Ihre Versicherung wurde für {month} automatisch verlängert.',
    txt_global_std_unitsaved: 'Einheit gespeichert',
    txt_global_std_pleasenotethattojoinyoumusthavethefiasdcappinstalledonyourmobiledeviceinordertobeabletojointhegroup: 'Bitte beachten Sie, dass Sie die FIA SDC-App auf Ihrem Mobilgerät installiert haben müssen, um der Gruppe beitreten zu können.',
    txt_global_std_p2plendmycar: 'Leih mein {carname}',
    txt_global_std_typeofbusiness: 'Geschäftsart',
    txt_global_std_close: 'Schließen',
    txt_global_std_datetimetogt: 'Datum Zeit zu GT',
    txt_global_std_risklevelandscoring: 'Risikostufe und Bewertung',
    txt_global_std_teammemberbig1: 'TEAMMITGLIED',
    txt_global_std_thereaderwillnotbevisibleinyourphonesbluetoothsettings: 'Der Reader ist in den Bluetooth-Einstellungen Ihres Telefons nicht sichtbar',
    txt_global_std_kintoloyaltylevels: 'Kinto-Loyalitätsstufen:',
    txt_global_std_p2pmember: 'Mitglied',
    txt_global_std_presspaynowwhenyouarereadytocheckoutintheservicecenter: 'Drücken Sie "Jetzt bezahlen", wenn Sie bereit sind, im Servicecenter auszuchecken.',
    txt_global_std_tttest99: 'das ist ein Test 99',
    txt_global_std_p2phours: 'Std',
    txt_global_std_yourbonus: 'Ihr Bonus',
    txt_global_std_carstatsthismonth: 'Autostatistiken diesen Monat',
    txt_global_std_signinwithemail: 'Mit E-Mail anmelden',
    txt_global_std_p2powner: 'Inhaber',
    txt_global_std_carroreward: 'Carro Belohnung',
    txt_global_std_pleaseenteravalidphonenumberincludingareacode: 'Bitte geben Sie eine gültige Telefonnummer mit Vorwahl ein',
    txt_global_std_somethinghasgonewrong: 'Etwas ist schief gelaufen!',
    txt_global_std_Fia_Receipt_Text3: 'Willkommen zur FIA Smart Driving Challenge, 2021! Eine SMS wird mit einem einmaligen Aktivierungscode an Sie gesendet.',
    txt_global_std_completedaconnecteddrive: 'Ein verbundenes Laufwerk abgeschlossen',
    txt_global_std_1st: '1er',
    txt_global_std_requiredformatcolumnastext2: 'Erforderlich (Spalte als Text formatieren)',
    txt_global_std_OnboardingEmail: 'Willkommen bei MS First Capital Car Insurance!',
    txt_global_std_ourpremierworkshops: 'Unsere besten Workshops',
    txt_global_std_connect: 'Verbinden',
    txt_global_std_p2pfaq11q: 'Wie kann ich meine Freunde zu Warikan Kinto einladen?',
    txt_global_std_sparet: 'Sparet',
    txt_global_std_connecttoobdreader: 'Schließen Sie an OBD-Leser an',
    txt_global_std_drives: 'Fahrten',
    'txt_global_std_timein{state}': 'Zeit in {state}',
    txt_global_std_bekindtotheenvironment: 'Seien Sie freundlich zur Umwelt.',
    txt_global_std_2minutesagoshorthand: 'Vor 2m',
    txt_global_std_selectthetypeofinjurythatyouwishtoregisterbelowandfollowtheinstructions: 'Wählen Sie unten die Art der Verletzung, die Sie registrieren möchten, und folgen Sie den Anweisungen',
    txt_global_std_earnpointsandachievethenextlevel: 'Sammeln Sie Punkte und erreichen Sie das nächste Level!',
    txt_global_std_avoidhardacceleration: 'Vermeiden Sie starke Beschleunigung',
    txt_global_std_deleteachivementpopuptext: 'Ihr Erfolg wird endgültig gelöscht. Der Fortschritt Ihres Benutzers geht verloren. Diejenigen, die den Erfolg bereits erhalten haben, können ihn weiterhin sehen.',
    txt_global_std_notes: 'Notizen/ Bemerkungen',
    txt_global_std_head2headchallenge: 'Head 2 Head Challenge',
    txt_global_std_yourrequestedauthenticationcode: 'Ihr angeforderter Authentifizierungscode:',
    txt_global_std_leveloverview: 'Levelübersicht',
    'txt_global_std_t brikken': 'Kontakt Information ',
    txt_global_std_cropyourbackground: 'Beschneiden Sie Ihren Hintergrund',
    txt_global_std_yourein: 'Sie sind drin!',
    'txt_global_std_countryroadcatch-up2DESC': 'Der rote Autofahrer fährt mit hoher Geschwindigkeit an das Auto heran und fährt mit der Heckklappe nach vorne. Der blaue Autofahrer ist langsamer und fährt konzentriert und flüssig.',
    txt_global_std_howdoyoumeasuremydriving: 'Wie messen Sie mein Fahren?',
    txt_global_std_Gdpr_Text4: 'Konto entfernen, indem Sie auf den Link unten klicken:',
    txt_global_std_managegroups: 'Gruppen verwalten',
    txt_global_std_unlockedtitle: 'Entsperrt',
    txt_global_std_encouragelowriskdrivingviaapotentialsavingspertrip: 'Fördern Sie das Fahren mit geringem Risiko durch potenzielle Einsparungen pro Fahrt',
    txt_global_std_actiontimestamp: 'Aktionszeit',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsaboutthecovercubeappandcompeteagainstthemforlowerscores: 'A: Sobald Sie die App mit dem Bluetooth Ihres Fahrzeugs koppeln und der App erlauben, Ortungsdienste zu verwenden, gleichen wir Ihre Fahrgewohnheiten mit der Bibliothek von Fahrermustern unserer KI ab und stellen eine Punktzahl bereit. Ihre Versicherungsprämie richtet sich danach, wer Ihr Fahrzeug fährt und wie gut er fährt. Jeder, der Ihr Fahrzeug fährt, wird ermutigt, die App herunterzuladen und seine Ergebnisse zu verfolgen. Erzählen Sie Ihren Freunden von der Covercube-App und treten Sie gegen sie an, um niedrigere Punktzahlen zu erzielen.',
    txt_global_std_accountremoval: 'Konto entfernen',
    txt_global_std_p2pifyouwanttoupdatetheemailusedasusername: 'Wenn Sie die als Benutzername verwendete E-Mail aktualisieren möchten,',
    txt_global_std_thismaytakeafewseconds: 'Dies kann einige Sekunden dauern.',
    txt_global_std_youhavebeenchallengedby: 'Sie wurden von herausgefordert',
    txt_global_std_smartdriving: 'Intelligentes Fahren',
    txt_global_std_preparingsomethings: 'Einige Dinge werden vorbereitet ...',
    txt_global_std_wecannotfindthecaridentityselectregno: 'Wählen Sie die Fahrzeugnummer, die Sie fahren.',
    txt_global_std_weareherforeyoucontact: 'Wir sind für Sie da. Kontaktieren Sie uns!',
    txt_global_std_focustips: 'Behalten Sie die Straße im Auge und konzentrieren Sie sich ganz auf das Fahren. Manchmal ist es leicht, sich von Umgebungen, Telefonen, Personen im Auto usw. ablenken zu lassen.\n' +
      '\n' +
      'Wenn Sie müde oder gestresst fahren, kann dies den Fokus beeinträchtigen und Sie einem höheren Risiko aussetzen.\n' +
      '\n' +
      'Wir sorgen uns um dich.',
    txt_global_std_gas: 'Gas',
    txt_global_std_qhowcaniimprovemyscore: 'F: Wie kann ich meine Punktzahl verbessern?',
    txt_global_std_somefacts: 'Einige Fakten',
    txt_global_std_MSIG_InsuranceLetter24: 'Einschränkungen bezüglich der Verwendung *',
    txt_global_std_paymentpalndoesnotexistonthisaccountgotoeventcreatorandcreateaddpaymentplan: 'Zahlungsplan existiert nicht auf diesem Konto. Gehen Sie zum Event-Ersteller und erstellen Sie einen Zahlungsplan hinzufügen.',
    txt_global_std_ReminderMail: 'Sind Sie für Ihre SDC-Testfahrt bereit?',
    txt_global_std_kintoImprovement2: 'Hey - wir kümmern uns um dich! Machen Sie es sich beim nächsten Mal gemütlich!',
    txt_global_std_yourinsurancehasbeencanceled: 'Ihre Versicherung wurde gekündigt',
    txt_global_std_base: 'Base',
    txt_global_std_petrol: 'Benzin',
    txt_global_std_endtour: 'Tour beenden',
    txt_global_std_carregistrationnumber: 'Autokennzeichen',
    txt_global_std_starttime: 'Startzeit',
    txt_global_std_who: 'Wer',
    txt_global_std_rwmcontinue: 'Fortsetzen',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedthisbadge: 'Du bist wirklich gut gefahren! Ihr durchschnittlicher {scoretype} war eine Woche lang besser als {score}! Du hast dir dieses Abzeichen verdient!',
    txt_global_std_2faenabled: 'Zwei-Faktor aktiviert',
    txt_global_std_p2pborrowacar: 'Leihen Sie sich einen {Autonamen} aus',
    txt_global_std_youalwaysstarttheinsuranceyearwithnok1000inthepiggybankyouwillbepaidtheseifyouhavebeenasafedriverthroughouttheinsuranceyearifyouchoosetoterminatetheinsurancebeforetheendoftheyearacalculationwillbemadeonthebasisoftheperiodyouhadtheinsurance: 'Sie beginnen das Versicherungsjahr immer mit NOK 1000 im Sparschwein. Diese werden Ihnen ausbezahlt, wenn Sie während des gesamten Versicherungsjahres ein sicherer Fahrer waren. Entscheiden Sie sich für eine vorzeitige Beendigung der Versicherung, erfolgt eine Berechnung auf Basis der Versicherungsdauer.',
    txt_global_std_jpfaqanswer: '1. 画面下のメニューバーから「接続」を選択します。\n' +
      '2. Bluetooth-Verbindung\n' +
      'フィットネスの設定を許可してください。位置情報\n' +
      'サービスは、正確なデータ取得のために「常に」\n' +
      'を選択してください。\n' +
      '3. Bluetooth-Verbindung\n' +
      'クルマとアプリを接続してください。アプリ上に\n' +
      'クルマの名前が表示されたら「クルマを選択」を選択します。\n' +
      '4. 走行を開始すると、リアルタイムで運転に対する\n' +
      'フィードバックを受け取ることができます。\n' +
      '5. 運転が終了すると、走行データは自動的にアプリ\n' +
      'に保存されます。',
    txt_global_std_manydriversrelytooheavilyoncruisecontrolitiscomfortableyesbutlethalforfueleconomyinmanycasesusingcruisecontrolontheslightestuphillordownhillwillcausetheenginetofighthardtosatisfyyourwishofmaintainingtheexactspeedyousetinsteadmanagingyourspeedwithyourfootandaimingforasmoothridewillhelpyousaveahugeamountonfuelnew: 'Viele Autofahrer verlassen sich zu sehr auf den Tempomat. Es ist bequem, ja, aber in vielen Fällen tödlich für den Kraftstoffverbrauch. Die Verwendung des Tempomats bei der geringsten Steigung oder Gefälle führt dazu, dass der Motor hart kämpft, um Ihren Wunsch zu erfüllen, die von Ihnen eingestellte Geschwindigkeit exakt beizubehalten. Wenn Sie stattdessen Ihre Geschwindigkeit mit Ihrem Fuß steuern und auf eine reibungslose Fahrt abzielen, können Sie eine Menge Kraftstoff sparen!',
    txt_global_std_leaderboardisempty: 'Die Rangliste ist leer.',
    txt_global_std_tttest78: 'Prüfung\nasd',
    txt_global_std_pleasecompletethesesteps: 'Bitte führen Sie diese Schritte aus',
    txt_global_std_rwmsheettitle: 'Einen Freund einladen',
    txt_global_std_dontforgettocheckfeedback: 'Vergessen Sie nicht, Ihr Feedback zu lesen, um Ihr Fahrverhalten zu verbessern.',
    txt_global_std_youwillbenotifiedintheappwhenitstimetorenewyourinsuranceifyoudonthaveautomaticrenewalsetupfollowthestepsintheapptoensureyouhavecoveragefornextmonth: 'Sie werden in der App benachrichtigt, wenn es Zeit ist, Ihre Versicherung zu erneuern. Wenn Sie keine automatische Verlängerung eingerichtet haben, befolgen Sie die Schritte in der App, um sicherzustellen, dass Sie für den nächsten Monat versichert sind.',
    txt_global_std_keepgooddistancebetweencars: 'Halten Sie einen guten Abstand zwischen den Autos',
    txt_global_std_unit: 'Einheit / Lektion',
    txt_global_std_JyrkiainenBesttip: 'Seien Sie geduldig und proaktiv. Folgen Sie der Umgebung und dem Verkehr. Es ist auch gut, den Kraftstoffverbrauch zu überprüfen.',
    txt_global_std_personaldatapreferences: 'Persönliche Dateneinstellungen',
    txt_global_std_p2pwecouldnotfindanybookingchatswhenyoucreateabookingyouwillbeabletochataboutthatbookinghere: 'Wir konnten keine Buchungs-Chats finden. Wenn Sie eine Buchung erstellen, können Sie hier über diese Buchung chatten.',
    txt_global_std_adressline1: 'Adresszeile 1',
    txt_global_std_PaddonWhyjoin: 'In unserem Team werden Sie gewinnen! Ich bin eine sehr wettbewerbsfähige Person und werde alles tun, um mit allen Teamkollegen zusammenzuarbeiten, um sicherzustellen, dass wir unser Bestes geben. Während dies ein großartiger Wettbewerb sein wird, ist es wichtig, die wichtigen Botschaften, die der Wettbewerb mit sich bringt nicht zu vergessen. Wir sind bestrebt, sicherere und zuverlässiger Fahrer zu sein, etwas, das wir alle teilen und andere Menschen dazu inspirieren können.',
    txt_global_std_downloadtheappnameapptodrivemycar: 'Laden Sie die App {appname} herunter, um mein Auto zu fahren',
    txt_global_std_p2pwecouldnotfindyou: 'Wir konnten dich nicht finden',
    txt_global_std_enablemotionfitnesstogetsupportforstartingandendingyourtripsautomatically: 'Aktivieren Sie Bewegung und Fitness, um Unterstützung für das automatische Starten und Beenden Ihrer Reisen zu erhalten.',
    txt_global_std_pushinsurancerenewalodorembody: 'Ihre Versicherungszeit geht zu Ende und wird verlängert. Sie müssen Ihren Kilometerstand aktualisieren.',
    txt_global_std_p2ppushtitleaddadmin: 'Administratorrechte hinzugefügt',
    txt_global_std_p2ppushbodyexpensesapproved: '{name} approved your added expenses.',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint2: 'Bewerten Sie das Fahrrisiko basierend auf tatsächlichen Fahrorten, nicht zu Hause oder am Arbeitsplatz',
    txt_global_std_iamapassenger: 'Ich bin ein passagier',
    txt_global_std_ongoing: 'Laufend',
    txt_global_std_kintoEncouragement3: 'Gut gemacht! Ihre letzte Reise war besser als der Kinto-Durchschnitt!',
    txt_global_std_areyousureyouwanttoredeempoints: 'Sind Sie sicher, dass Sie Punkte einlösen möchten?',
    txt_global_std_pwdfiveandtwice: 'Ihr Passwort muss aus mindestens fünf (5) Zeichen bestehen und muss zweimal geschrieben werden!',
    txt_global_std_selectdamagereport: 'Wählen Sie den Schadensbericht aus',
    txt_global_std_autojoinchallenges: 'Herausforderungen automatisch beitreten',
    txt_global_std_noreviewsselectedtobepublished: 'Keine Bewertungen zur Veröffentlichung ausgewählt!',
    txt_global_std_namesmayonlyincludelettersandspaces: 'Namen dürfen nur Buchstaben, -und Leerzeichen enthalten.',
    txt_global_std_kintofaq2q: 'Wie fahre ich gut?',
    txt_global_std_p2pyesdelete: 'Ja, löschen',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimedecreasingspeedleavingasafefollowingdistanceenablesyoutodecreaseyourspeedsteadilyandsmoothly: 'Auf dieser Art von Reise verbrachte der durchschnittliche Öko-Champion mehr Zeit damit, die Geschwindigkeit zu verringern. Wenn Sie einen sicheren Abstand halten, können Sie Ihre Geschwindigkeit stetig und gleichmäßig verringern.',
    txt_global_std_ecotips: 'Ihr Öko-Score misst, wie umweltfreundlich Sie fahren. In vielerlei Hinsicht kann es mit Ihrer Planung verbunden werden.\n' +
      '\n' +
      'Passen Sie Ihre Beschleunigungen an, um unnötige Bremsen und Stopps zu vermeiden. Jede Beschleunigung und Bremse verbraucht Energie.\n' +
      '\n' +
      'Gemeinsam können wir einen Unterschied für die Zukunft machen.',
    txt_global_std_nicestreak: 'Schöne Serie!',
    txt_global_std_finalizepersonalinformation: 'Persönliche Daten abschließen',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint2: 'Verbinden Sie sich mit Benutzern auf ihren bevorzugten Kanälen',
    txt_global_std_downloadthefiasdcappbyclickingthelinkonyourmobilephone: 'Laden Sie die FIA SDC-App herunter, indem Sie auf den Link auf Ihrem Mobiltelefon klicken',
    txt_global_std_presstoloadmore: 'Drücken Sie, um mehr zu laden',
    txt_global_std_alertweredrivingyoudrivingtitle: 'Bist du gefahren?',
    txt_global_std_awardedbadges: 'Verliehene Abzeichen',
    txt_global_std_hereyouloweryoursubscriptioncostbydrivingsafe: 'Hier senken Sie Ihre Abonnementkosten, indem Sie sicher fahren.',
    txt_global_std_activateinsurance: 'Versicherung aktivieren',
    txt_global_std_settingupprofile: 'Profil einrichten',
    txt_global_std_completeinfoandcreateaccount: 'Füllen Sie die Informationen aus und erstellen Sie ein Konto',
    txt_global_std_kintofaq1q: 'Muss ich die App jedes Mal aktivieren, wenn ich fahre?',
    txt_global_std_yourmessagehasbeensent: 'Ihre Nachricht wurde gesendet!',
    txt_global_std_choosingbalwaysallowbisnecessarytogetcorrectstatsaboutyourdriving: 'Die Auswahl von <b> Immer zulassen </b> ist erforderlich, um korrekte Statistiken über Ihr Fahren zu erhalten.',
    txt_global_std_ayesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforagentinsurance: 'A: Ja, Sie können die App herunterladen und die Option „App testen“ auswählen. Sie können mit der App fahren und sehen, wie viel Sie sparen könnten, wenn Sie sich für die {agent}-Versicherung anmelden.',
    txt_global_std_servicedandsoldby: 'Gewartet und verkauft von',
    txt_global_std_p2pwhenyougiveyourfriendadminrightstheycanviewandhandlebookingsofyourcar: 'Wenn Sie Ihren Freunden Administratorrechte erteilen, können diese Buchungen Ihres Autos anzeigen und bearbeiten.',
    txt_global_std_mydrivingstats: 'Meine Fahrstatistiken',
    'txt_global_std_Click the plus sign to create a new challenge.': 'Klicken Sie auf das Pluszeichen, um einen neuen Wettbewerb zu starten.',
    txt_global_std_alreadyhaveacovercubepolicy: 'Sie haben bereits eine Covercube-Police?',
    txt_global_std_TidemandWhyjoin: 'Denn zusammen sind wir unschlagbar und streben immer nach oben!',
    txt_global_std_PaddonMileage: 'Ich liebe es, auf den Hinterlandstraßen Neuseelands zu fahren und neue Orte zu erkunden. Die Westküste der Südinsel ist ein ganz besonderer Ort, da Sie sich einerseits so isoliert fühlen und gleichzeitig auf erstaunlich kurvigen und malerischen Straßen fahren.',
    txt_global_std_WelcomeLetter_Text5: 'Mit freundlichen Grüßen,',
    txt_global_std_kintofaq8a: 'Einsparungen werden generiert, wenn Sie sicher fahren. Für jede Fahrt, die Sie sicher fahren, erzielen Sie eine Einsparung pro Kilometer. Je sicherer Sie fahren, desto höher ist die Ersparnis! Die Speicherung ist direkt nach Beendigung Ihrer Reise sichtbar und wird in Ihrer Kinto-Brieftasche angezeigt.',
    txt_global_std_accident: 'Unfall',
    txt_global_std_enerfycoachtip2: '2. Halten Sie großen Abstand zum vorausfahrenden Fahrzeug, auch bei niedriger Geschwindigkeit.',
    txt_global_std_learnmoreaboutpermissions: 'Erfahren Sie mehr über Berechtigungen',
    txt_global_std_rwmyoucanonlyregistertripsmadewithinthelastday: 'Sie können nur Fahrten registrieren, die innerhalb des letzten Tages getätigt wurden',
    txt_global_std_nooftrips: 'Anzahl Fahrten',
    txt_global_std_p2pongoing: 'Laufend',
    txt_global_std_p2pbooking: 'Buchung',
    txt_global_std_setuplocationservices: 'Richten Sie Ortungsdienste ein',
    txt_global_std_cancel: 'Stornieren',
    txt_global_std_VilliersChamp: '4-maliger SA Tourenwagen-Meister, 7 Dakar-Podestplätze, 1 Dakar-Sieg, 3-facher Marocco-Rallyesieger, 2008 die Portugal-Rallye gewonnen, 2008-Sieger von Dos Sertoes in Brasilien.',
    txt_global_std_p2psafedriverdiscount: 'Safedriver Rabatt',
    txt_global_std_setupcomplete: 'Einrichtung abgeschlossen!',
    txt_global_std_enablemotionfitness: 'Aktivieren Sie Motion & Fitness in Ihrem Telefon.',
    txt_global_std_gettingtheappready: 'App vorbereiten',
    txt_global_std_TomczykMusic: 'Was ich beim Autofahren höre, hängt von meiner Stimmung ab. Ich mag Hard Rock wie Dio und Manowar, höre aber auch aktuelle Musikcharts.',
    txt_global_std_youhavealreadyorderedanobdreader: 'Sie haben bereits einen OBD-Leser bestellt',
    txt_global_std_loyaltylevelrewards: 'Diese Loyalitätsstufe berechtigt Sie zu einer {reward}',
    txt_global_std_theenerfyteam: 'Das Enerfy-Team',
    txt_global_std_invitetherestofyourteam: 'Laden Sie den Rest Ihres Teams ein',
    txt_global_std_teams: 'Teams',
    txt_global_std_joinchallenge: 'Herausforderung beitreten',
    txt_global_std_coach: 'Coach',
    txt_global_std_appcreator_upsell_pitch: 'Erstellen und passen Sie Ihre eigene Fahr-App an, ohne dass Codierung erforderlich ist.',
    txt_global_std_c02overview: 'C02-Übersicht',
    txt_global_std_youhavenowdriven25tripswithtrygdrive: 'Sie haben jetzt 25 Fahrten mit Tryg Drive gefahren',
    txt_global_std_thankyou: 'Vielen Dank!',
    txt_global_std_gotoyourpreferredservicecenterandshowthemthispopup: 'Gehen Sie zu Ihrem bevorzugten Servicecenter und zeigen Sie ihnen dieses Popup.',
    txt_global_std_mystats: 'Meine Statistiken',
    txt_global_std_p2pamountshouldbeshownonthereceipt: '* Der Betrag sollte auf der Quittung angegeben werden.',
    txt_global_std_p2pviewexpenses: 'Ausgaben anzeigen',
    txt_global_std_cimage11d: 'Motor im Stillstand immer abstellen',
    txt_global_std_p2pemailbestregards: 'Freundliche Grüße,<br> das {appname} Team',
    txt_global_std_p2ppleaseuploadareceiptpicture: 'Bitte lade ein Quittungsbild hoch',
    txt_global_std_wecannotfindthecaridentityenteractivationcode: 'Wir können die Fahrzeugidentität nicht finden. Geben Sie den Aktivierungscode ein, den Sie in der Begrüßungsmail haben.',
    txt_global_std_onboardingnotificationsheader: 'Benachrichtigungen',
    txt_global_std_takethefootofthepedalandletthecarcoastasmuchasitmakesensesavesfuel: 'Den Fuß vom Pedal nehmen und das Auto so weit rollen lassen, wie es sinnvoll ist, spart Sprit.',
    txt_global_std_sonicethatyoudecidedtojoinusletssetupyourprofile: 'So schön, dass Sie sich für uns entschieden haben! Lass uns dein Profil einrichten.',
    txt_global_std_mystatstext: 'Meine Statistiken',
    txt_global_std_successfullyupdatedtranslation: 'Übersetzung erfolgreich aktualisiert',
    txt_global_std_nice: 'Schön',
    txt_global_std_origin: 'Herkunft',
    txt_global_std_p2pemailnewsavailableinappcarSharing: 'Nachrichten verfügbar in {appname} - Car Sharing',
    txt_global_std_inviteusers: 'Benutzer einladen',
    txt_global_std_choosethetypeofbusinessthatdescribesyourcompanythebest: 'Wählen Sie die Geschäftsart, die Ihr Unternehmen am besten beschreibt.',
    txt_global_std_polish: 'Polieren',
    txt_global_std_startdrive: 'Reise beginnen ',
    txt_global_std_icannotconnect: 'Ich kann keine Verbindung herstellen?',
    txt_global_std_gameonyourchallengehasbeenaccepted: 'Spiel weiter! Ihre Herausforderung wurde angenommen!',
    txt_global_std_earnsavingsintoyourkintowalletforsafedriving: 'Verdienen Sie Einsparungen in Ihrem Kinto-Portemonnaie - für sicheres Fahren:',
    txt_global_std_p2pmaximum9999999allowedforanexpense: 'Maximal zulässige {currency} 9.999.999 für eine Ausgabe',
    txt_global_std_100kmlockedkey: 'Fahren Sie 100 km zurück, um dieses Abzeichen zu erhalten.',
    txt_global_std_challenge: 'Herausforderung',
    txt_global_std_dragme: 'Zieh mich!',
    txt_global_std_namesmayonlyincludeletters1: 'Namen dürfen nur Buchstaben enthalten.',
    txt_global_std_Fia_Receipt_Text17: 'Greater Than, Karlavägen 60, 11449 Stockholm Sweden. Handelsregisternummer: 556608-3258',
    txt_global_std_PolicySchedule25: 'Total fällig',
    txt_global_std_p2padditionalexpense: 'Mehraufwand',
    txt_global_std_preferredrequirementaccuracy: 'Bevorzugte Anforderungsgenauigkeit',
    txt_global_std_signupandsavemoneyonyourinsurance: 'Melden Sie sich an und sparen Sie Geld für Ihre Versicherung',
    txt_global_std_search: 'Suchen',
    txt_global_std_p2pfaqcontactinfo2: '・ Bitte beachten Sie, dass es schwierig sein kann, eine Verbindung herzustellen, wenn es überfüllt ist.',
    txt_global_std_emailhasbeensent2: 'Die Email wurde verschickt!',
    txt_global_std_communication_upsell_pitch: 'Entwerfen Sie ganz einfach Ihren eigenen Messaging- und Kommunikationsplan, um;',
    txt_global_std_endday: 'Endtag',
    txt_global_std_pointstounlock: 'Punkte zum Entsperren.',
    txt_global_std_ifyouhaveaninsuranceyoucanfindyourpolicynumberinyourappnameaccountdonthaveitathandrightnownoworriesyoucanadditlaterinsettings: 'Wenn Sie versichert sind, finden Sie Ihre Versicherungsnummer in Ihrem {appname} Konto.\n' +
      'Sie haben es gerade nicht zur Hand? Keine Sorgen! Sie können es später in den Einstellungen hinzufügen.',
    txt_global_std_allmonth: 'Den ganzen Monat',
    txt_global_std_ChicheritChamp: 'Demnächst...',
    txt_global_std_gettheapp: 'Hol dir die App',
    txt_global_std_everymonth: 'Jeden Monat ',
    txt_global_std_youaredriving: 'Sie fahren ',
    txt_global_std_secondarycolor: 'Sekundäre Farbe',
    txt_global_std_cimage11h: 'Motor abstellen',
    txt_global_std_latest10: 'Die letzten 10',
    txt_global_std_p2papprove: 'Genehmigen',
    txt_global_std_yourdatais: 'Ihre Daten sind =',
    txt_global_std_numberofrepetitions: 'Anzahl der Wiederholungen',
    txt_global_std_warikan_onboarding2_2: 'この度は、「わりかんKINTO」アプリのご利用お申し込み、誠にありがとうございます。\n' +
      '以下の仮パスワードをアプリの画面に入力のうえ、アカウント作成をお願いいたします。',
    txt_global_all_sunday: 'Sonntag',
    txt_global_std_authenticating: 'Authentifizieren',
    txt_global_std_HusseinAbdullaChamp: '2016 gewann FIA T2-Weltmeister',
    txt_global_std_nodataavailable: 'Keine Daten verfügbar',
    txt_global_std_signinginwiththirdpartyname: 'Anmeldung mit {Name des Drittanbieters} ...',
    txt_global_std_fiaquarterfinalqualifyrule2020: 'Sie müssen an mindestens 4 Läufen teilnehmen, um sich für das Qualifikationsfinale zu qualifizieren.',
    txt_global_std_RemoveAccount_text1: 'Hallo! Wir haben Ihren Wunsch erhalten, Ihre Informationen in Ihrem Konto zu löschen. Sie sollten wissen, dass gelöschte Daten nicht wiederhergestellt werden können. Ihr Konto und alle Daten über Sie werden gelöscht, einschließlich Fahrdaten, Teams, Renntermine und Wettbewerbe. Wenn Sie ein Versicherungskunde sind, werden Ihre Informationen erst 1 Jahr nach Abschluss Ihrer Versicherung gelöscht und alle Fälle geschlossen sind. Durch das Löschen Ihres Kontos wird Ihre Versicherung nicht beendet. Konto entfernen, indem Sie auf den folgenden Link klicken: {{removelink}} Wenn Sie Fragen haben, wenden Sie sich bitte an info@enerfy.se oder +46 8-555 932 00',
    txt_global_std_cardwithmoreinformationaboutgdpr: 'Karte mit weiteren Informationen zur DSGVO',
    txt_global_std_bluetoothsystem: 'Bluetooth-System',
    txt_global_std_nophotosadded: 'Keine Fotos hinzugefügt',
    txt_global_std_SignupFia_Text7: 'Laden Sie die App herunter!',
    txt_global_std_fiasdcsmartscore: 'FIA SDC Smart Score',
    txt_global_std_youcanaddtranslationbyclickinghere: 'Sie können eine Übersetzung hinzufügen, indem Sie hier klicken.',
    'txt_global_std_de-villiersDescription': 'Giniel De Villiers ist noch nie in den Ruhestand gegangen. Noch besser, nur einmal war er nicht in den Top 10 (2007). Er hat 14 Etappensiege und 2009 den ersten Dakar-Wettbewerb in Südamerika gewonnen.',
    txt_global_std_digitaltraining: 'Digitales Training',
    txt_global_std_valuedisconnctedtripsofmaxmaxvalue: '{value} % abgebrochene Fahrten (von max. {maxvalue} %)',
    txt_global_std_energysaved: 'Energie gespart',
    txt_global_std_reactivateyourinsurancetokeepyourcoverage: 'Reaktivieren Sie Ihre Versicherung, um Ihren Versicherungsschutz zu behalten.',
    txt_global_std_theoreticaltimesaving: 'Theoretische Zeitersparnis',
    txt_global_std_splits: '',
    txt_global_std_MSIG_Onboarding10: 'Haben Sie irgendwelche Fragen? Kontaktieren Sie unser Support-Team unter 0123456789 oder senden Sie eine E-Mail an info@msfc.com.sg.',
    txt_global_std_incorrectpassword: 'Falsches Passwort',
    txt_global_std_youhavebeeninvitedtojoin: 'Sie wurden zur Teilnahme eingeladen:',
    txt_global_std_finishingyourscore: 'Beenden Sie Ihre Punktzahl ..',
    txt_global_std_requestssent: 'Anfrage gesendet',
    txt_global_std_companychallengename2: 'Name der Unternehmensherausforderung',
    txt_global_std_pleaseseethefinishedreport: 'Den fertigen Bericht finden Sie unter',
    txt_global_std_creationdate: 'Erstellungsdatum',
    txt_global_std_p2pfaq10a: 'Bitte besuchen Sie https://kinto-jp.com/insurance/ für weitere Informationen.',
    txt_global_std_policyholder: 'Versicherungsnehmer',
    txt_global_std_rwmnoeligibletripstitle: 'Wir konnten keine Fahrten innerhalb des letzten Tages finden.',
    txt_global_std_myscore: 'Meine Punktzahl',
    txt_global_std_riskindex_upsell_pitch: 'Verwandeln Sie Ihr Wissen in Maßnahmen mit diesem ausgeklügelten Tool, das Nachrichten, Daten und Risikoanalysen kombiniert, um Risiken zu identifizieren;',
    txt_global_std_shootingstarlockedkey: 'Beende eine Fahrt auf Stufe 3.3 oder besser, um diesen Erfolg freizuschalten und 100 Punkte für deine Kinto-Brieftasche zu sammeln.',
    txt_global_std_p2papply: 'Anwenden',
    txt_global_std_termsofservice: 'Nutzungsbedingungen & Datenschutzbestimmungen',
    txt_global_std_youneedtoaddatleast8characters: 'Sie müssen mindestens 8 Zeichen hinzufügen. Einschließlich 1 Kleinbuchstaben (az), 1 Großbuchstaben (AZ) und 1 Zahl (0-9).',
    txt_global_std_reallynicerun: 'Wirklich schöner Lauf',
    txt_global_std_changingyouremaildoesnotchangeyourusername: 'Das Ändern Ihrer E-Mail ändert Ihren Benutzernamen nicht',
    txt_global_std_influencer: 'Influencer',
    txt_global_std_agreementcreated: 'Vereinbarung erstellt',
    txt_global_std_orderobddeviceto: 'OBD-Gerät bestellen an:',
    txt_global_std_p2pfaq19q: 'Es gibt Abschnitte, in denen keine Fahrdaten aufgezeichnet werden.',
    txt_global_std_letsdrivewithwarikankintopushbody: 'Lass uns mit Warikan KINTO fahren und Abzeichen und Punkte sammeln! Punkte können gegen Geschenke eingetauscht werden! Die Bewertungsfunktion und der Erwerb von Abzeichen sind auch dann verfügbar, wenn Sie kein KINTO ONE-Benutzer sind.',
    txt_global_std_analytics: 'Analytik',
    txt_global_std_p2pforgotwhereyouparkedyourcarnoworrieswegotyoucoveredjustpressthisbuttonandwewillfinditforyou: 'Vergessen, wo Sie Ihr Auto geparkt haben? Keine Sorge, wir haben Sie abgesichert. Drücken Sie einfach diesen Knopf und wir werden es für Sie finden!',
    txt_global_std_kintofaq5a: 'Informieren Sie das Kinto-Team per E-Mail. Stellen Sie dann erneut eine Verbindung zu Ihrem neuen Auto her, indem Sie die Schritte unter „Mit Auto verbinden“ erneut ausführen.',
    txt_global_std_annualcarinsuranceprice: 'Jährlicher Kfz-Versicherungspreis',
    txt_global_std_p2paddedmemberstothegroup: 'Mitglieder zur Gruppe hinzugefügt',
    txt_global_std_acceptlocationservices: 'Ortungsdienste akzeptieren',
    txt_global_std_youcancontactyourinsuranceagency: 'Sie können sich an Ihre Versicherungsagentur wenden.',
    txt_global_std_savechangespopuptext: 'Ihre nicht gespeicherten Änderungen gehen verloren. Änderungen vor dem Schließen speichern?',
    txt_global_std_change: 'Veränderung',
    txt_global_std_whatwillhappenifidonotconnectmycar: 'What will happen if I do not connect my car?',
    txt_global_std_SendMyPosition_Text2: 'Mit freundlichen Grüßen {appName}',
    txt_global_std_tomasengequote: 'Wenn du deine Ziele erreichen willst, musst du alles dafür tun',
    txt_global_std_ayourscoreisbasedonthedrivingpatternsyoucreatewitheachtripconcentrateonyourdrivingskillstoimproveyourscoreswewillprovideahistoryofallyourtripssoyoucanseehowyouredoing: 'A: Ihre Punktzahl basiert auf den Fahrmustern, die Sie bei jeder Fahrt erstellen. Konzentrieren Sie sich auf Ihre Fahrkünste, um Ihre Punktzahl zu verbessern. Wir stellen Ihnen einen Verlauf aller Ihrer Reisen zur Verfügung, damit Sie sehen können, wie es Ihnen geht.',
    txt_global_std_ifyouwanttolendyourcartoafriendyoushouldinviteyourfriendtotheappbytappingthebuttonbelowthisensuresyourcarisstilltrackedbyloyaltysystem: 'Wenn Sie Ihr Auto einem Freund leihen möchten, sollten Sie Ihren Freund zur App einladen, indem Sie auf die Schaltfläche unten tippen. Dies stellt sicher, dass Ihr Auto weiterhin vom Treue-System verfolgt wird.',
    txt_global_std_acceptconsent: 'Zustimmung akzeptieren',
    txt_global_std_bycontinuingyouaccept: 'Wenn Sie fortfahren, akzeptieren Sie unsere',
    txt_global_std_5000pointaverageachievementlocked: 'Fahren Sie eine Woche lang mit einem durchschnittlichen {scoretype) of {score} oder besser, um diesen Erfolg freizuschalten und 5000 Punkte zu verdienen.',
    txt_global_std_kintoquestions: 'KINTO Fragen:',
    txt_global_std_co2besparelse: 'CO2 sparsam',
    txt_global_std_wewillnotbeabletoanalyzeyourdriveand: 'We will not be able to analyze your drive and you will be billed at premium level 10.',
    txt_global_std_apitool_upsell_pitch_bulletpoint2: 'Schnelle und sichere Übertragung von Fahrerdaten',
    txt_global_std_yourdatahasbeensent2: 'Ihre Daten wurden gesendet, bitte überprüfen Sie Ihren Posteingang!',
    txt_global_std_HansenMusic: 'Während der Fahrt Musik zu hören, kann die Aufmerksamkeit beeinträchtigen. Aber wenn ich auf der Autobahnen fahre, höre ich wirklich gerne Musik, die mich dazu bringt, mitzusingen.',
    txt_global_std_insureddistance_used_100__2: 'Raus! Erhöhen Sie die jährliche Laufleistung hier.',
    txt_global_std_uploadpicturestooldaccidentreport: 'Bitte laden Sie innerhalb von 24 Stunden nach Ihrer Meldung Bilder Ihres Autos hoch. Sie können unten auf Ihren alten Bericht klicken, um Bilder hinzuzufügen.',
    txt_global_std_edituser: 'Benutzer bearbeiten',
    txt_global_std_opendocument: 'Dokument öffnen',
    txt_global_std_qdoihavetoactivatetheappeverytimeidrive: 'F: Muss ich die App jedes Mal aktivieren, wenn ich fahre?',
    txt_global_std_orderobdreader: 'OBD-Leser bestellen',
    txt_global_std_friday: 'Freitag',
    txt_global_std_otherdocuments: 'Sonstige Unterlagen',
    txt_global_std_insuranceisrenewedplaceholder: 'Sie haben nur noch 63 km.\nIhre Versicherung wird verlängert 2021-10-29',
    txt_global_std_manageapikeys: 'API-Schlüssel verwalten',
    txt_global_std_zipcode2: 'Postleitzahl',
    txt_global_std_minutesandcanonlybeusedonce: 'Minuten und kann nur einmal verwendet werden.',
    txt_global_std_couldnotconnect1: 'Konnte keine Verbindung herstellen!',
    txt_global_std_brakevca: 'Bremse V / C / A.',
    txt_global_std_insurancestatus: 'Versicherungsstatus',
    txt_global_std_takecareoftheinjured: 'Kümmere dich um die Verletzten',
    txt_global_std_deactivateuserfrom: 'Benutzer deaktivieren von:',
    txt_global_std_jointaveragescore: 'Gemeinsame Durchschnittsnote',
    txt_global_std_duration: 'Dauer',
    txt_global_std_tryglocationservice: 'Zugriff auf Standort zulassen - Immer auswählen',
    txt_global_std_p2ppleaseselectpaymentmethod: 'Bitte Zahlungsmethode auswählen',
    txt_global_std_hellothisisanonboardingtext: 'Hallo, dies ist ein Onboarding-Text.',
    txt_global_std_manualstartstoprequiresaccess: 'Manuelles Starten und Stoppen erfordert Zugriff auf Ortungsdienste (GPS) und Bewegung & Fitness.',
    txt_global_std_yournumber: 'Ihre Nummer',
    txt_global_std_startcarengine: '1. Starten Sie den Motor Ihres Autos.',
    txt_global_std_yourfriendsemail: 'Die E-Mail Adresse Ihres Freundes',
    txt_global_std_theamountisenteredinthepiggybankwhentherecruitmentcodehasbeenregisteredyouhaveafulltimeoverviewofyourrecruitsonthesavingsside: 'Der Betrag wird nach der Registrierung des Einstellungscodes in das Sparschwein eingegeben. Auf der Sparseite haben Sie einen Vollzeit-Überblick über Ihre Rekruten.',
    txt_global_std_p2pyouhavenocar: 'Du hast kein Auto',
    txt_global_std_gohome: 'Nach Hause gehen',
    txt_global_std_connectingtocar: 'Verbindung zum Auto',
    txt_global_std_beonestepahead: 'Seien Sie einen Schritt voraus',
    txt_global_std_skillup: 'Verbessere dich',
    txt_global_std_TidemandChamp: 'WRC 2 Champion 2017, Tourenwagen-Meister 2010',
    txt_global_std_cimage1h: 'Reduzieren Sie Ihre max. Geschwindigkeit',
    txt_global_std_MSIG_Password2: 'Sie haben ein neues Passwort für Ihr MS First Capital-Konto angefordert. Ihr neues Passwort an den Benutzer',
    txt_global_std_pleasenotethatifyouhavealargerproportionofdisconnectedtripsyoumaynotbepaidadrivingdividendremembertodrivewiththeappconnected: 'Bitte beachten Sie, dass Ihnen bei einem größeren Anteil nicht verbundener Fahrten möglicherweise keine Fahrdividende ausgezahlt wird. Denken Sie daran, mit verbundener App zu fahren.',
    txt_global_std_2pairyourphonewithyourcarviabluetooth: '2. Koppeln Sie Ihr Telefon über Bluetooth mit Ihrem Auto.',
    txt_global_std_cimage14h: 'Nutzen Sie bergab',
    txt_global_std_TidemandMileage: '',
    txt_global_std_p2psplityourcostfor: 'Teilen Sie Ihre Kosten auf für',
    txt_global_std_category: 'Kategorie',
    txt_global_std_cantfindyourchallenge: 'Können Sie Ihre Herausforderung nicht finden?',
    txt_global_std_OSolbergWhyjoin: 'Kommt bald ',
    txt_global_std_inspiringsmarterdrivinghelpsreducefueluseco2footprintandcrashes: 'Inspirierendes intelligenteres Fahren trägt dazu bei, den Kraftstoffverbrauch, den CO2-Fußabdruck und Unfälle zu reduzieren',
    txt_global_std_HusseinAbdullaMusic: 'Ruhige Musik',
    'txt_global_std_Al-KhelaifiBesttip': 'Nach der Arbeit nach Hause pendeln.',
    txt_global_std_loading: 'Wird geladen',
    txt_global_std_p2pnogoback: 'Nein, geh zurück',
    txt_global_std_nov: 'Nov',
    txt_global_std_drivenotregistered: 'Laufwerk nicht registriert',
    txt_global_std_exploretheapp: 'Entdecken Sie die App',
    txt_global_std_ridewithmesubtitle: 'Sammeln Sie 300 Punkte für Mitfahrgelegenheiten!',
    txt_global_std_login1: 'Einloggen',
    txt_global_std_successfullycreatedgroup: 'Gruppe erfolgreich erstellt',
    txt_global_std_carinformation: 'Fahrzeuginformationen',
    txt_global_std_removealldatafromthisaccount: 'Alle Daten von diesem Konto entfernen?',
    txt_global_std_september: 'September',
    txt_global_std_TomczykWhyjoin: 'Mein Ziel ist nicht nur selbstverständlich zu gewinnen. Es macht auch Spaß mitzumachen und darum zu kämpfen, sich als das beste und intelligenteste Team zu qualifizieren.',
    txt_global_std_ratedrivingriskbasedonactualdrivinglocationsnothomeorworkplace: 'Bewerten Sie das Fahrrisiko basierend auf tatsächlichen Fahrorten, nicht zu Hause oder am Arbeitsplatz.',
    txt_global_std_june: 'Juni',
    txt_global_std_weliketorewardyousoeverytripyoudrivesafeyouwillearn: 'Wir belohnen Sie gerne - damit Sie bei jeder Fahrt, die Sie sicher fahren, eine Ersparnis verdienen. Je sicherer Sie fahren, desto mehr sparen Sie! Die Einsparungen werden sofort hier in Ihrer Brieftasche gesammelt.',
    txt_global_std_invitecoworkersandstartcompetingagainsteachothertobecomebetterdriversandsavemoreco2: 'Laden Sie Kollegen ein und treten Sie gegeneinander an, um bessere Fahrer zu werden und mehr CO2 einzusparen.',
    txt_global_std_PSolbergInterests: 'demnächst',
    txt_global_std_tirerotationunload: 'Reifendrehung, Entladen…',
    txt_global_std_p2ptounlockyouraccesstotheappandthebenefitsregisteratthewebsite: 'Um Ihren Zugang zur App und den Vorteilen freizuschalten, registrieren Sie sich auf der [Website]',
    txt_global_std_whatmusicdoyouliketolistentowhenyoudrive: 'Welche Musik hören Sie gerne beim Fahren?',
    txt_global_std_yourinsurancewasautomaticallyrenewedformonthwithadiscountonyourpremium: 'Ihre Versicherung wurde automatisch um {month} mit einem Rabatt auf Ihre Prämie verlängert.',
    txt_global_std_thisisatestphraseforonboarding: 'Dies ist ein Testsatz für das Onboarding.',
    txt_global_std_cimage29d: 'Wenn es Straße und Verkehr zulassen, schalte in zwei Stufen.',
    txt_global_std_issomeoneelsegoingtodriveyourcar2: 'Wird jemand anderes Ihr Auto fahren? Senden Sie ihnen einen Link, damit sie die App verwenden können.',
    txt_global_std_reportaccidentheresmile: 'Melden Sie hier Unfälle! :-)',
    txt_global_std_locationaccess: 'Standortzugriff',
    txt_global_std_p2ppushtitlebookingcomingup: 'Buchung steht an',
    txt_global_std_whatifichangecarlexusanswer: 'Informieren Sie das Lexus-Team per E-Mail. Stellen Sie dann erneut eine Verbindung zu Ihrem neuen Auto her, indem Sie die Schritte unter „Mit Auto verbinden“ erneut ausführen.',
    txt_global_std_p2pyoucanfindcarsrharingin: 'Sie finden Car Sharing im Menü (drei Punkte) unten.',
    txt_global_std_registerridesharingtrip: 'Mitfahrgelegenheit anmelden',
    txt_global_std_optional: 'Freiwillig ',
    txt_global_std_yourinsurancewasautomaticallyrenewedformonth: 'Ihre Versicherung wurde automatisch um {month} verlängert.',
    txt_global_std_qhowdoisetupautomaticrenewal: 'F: Wie richte ich die automatische Verlängerung ein?',
    txt_global_std_exampleofvalidemailaddresses: 'Beispiel für gültige E-Mail-Adressen:',
    txt_global_std_willbedeletedimmediatelyandpermanentlyoncedeleteditcannolongerbeusertomakeapirequests: 'werden sofort und dauerhaft gelöscht. Nach dem Löschen kann der Benutzer keine API-Anfragen mehr stellen.',
    txt_global_std_drivewellchallenge: 'Fahren Sie gut Herausforderung',
    txt_global_std_gotoaservicecentertoredeemyourrewards: 'Gehen Sie zu einem Servicecenter, um Ihre Prämien einzulösen.',
    txt_global_std_removeuserfromgroup: 'Benutzer aus der Gruppe entfernen',
    txt_global_std_p2pto: 'zu',
    txt_global_std_customemail: 'Benutzerdefinierte E-Mail',
    txt_global_std_p2ppending: 'steht aus',
    txt_global_std_youraveragescoreis: 'Ihre durchschnittliche Punktzahl ist',
    txt_global_std_wehavetoaccesslocationservicesinthebackgroundwhile: 'Wir müssen im Hintergrund auf die Ortungsdienste zugreifen, während Sie sich auf das Fahren konzentrieren. Wenn dieses Popup angezeigt wird, wählen Sie bitte "Immer zulassen".',
    txt_global_std_MSIG_InsuranceLetter28: 'Hiermit bestätige ich / wir, dass die Police, auf die sich dieses Zertifikat bezieht, in Übereinstimmung mit den Bestimmungen des Gesetzes über Kfz-Risiken (Kapitel 189) und Teil IV des Straßenverkehrsgesetzes von 1987 (Malaysia) ausgestellt wurde.',
    txt_global_std_avgrisklevel123: 'Durchschn. Risikostufe',
    txt_global_std_avgscore: 'Durchschn. Ergebnis',
    txt_global_std_p2pfaq29q: 'Bitte sagen Sie mir, wie man ein Auto ausleiht',
    txt_global_std_deepdiveintodrivercrash: 'Tauchen Sie tief ein in die Unfallwahrscheinlichkeit des Fahrers, das getragene Risiko und weitere Statistiken',
    txt_global_std_contactdetailsfromyouraccountpleaseeditdetailsifneeded: 'Kontaktdaten aus Ihrem Konto, bitte bearbeiten Sie die Details bei Bedarf.',
    txt_global_std_thischallengeactiveandupcomingchallenges: 'Diese Herausforderung, aktive und bevorstehende Herausforderungen',
    txt_global_std_p2pfaq29a: '1. Wählen Sie auf dem Startbildschirm "Auto mieten".\n' +
      '2. Wählen Sie den Besitzer des Autos aus, das Sie mieten möchten, und wählen Sie das Auto aus.\n' +
      '3. Wählen Sie Datum und Uhrzeit aus.\n' +
      '4. Geben Sie Ihre Nachricht ein (optional) und wählen Sie abschließend "Anfrage senden".\n' +
      '5. Die Buchungsanfrage wird gehalten, bis das Mitglied sie genehmigt oder ablehnt.',
    txt_global_std_last10drives: 'Letzten 10 Fahrten',
    txt_global_std_nousersfoundinviteuserstogetstarted: 'Keine Benutzer gefunden. Laden Sie Benutzer ein, loszulegen.',
    txt_global_std_edit: 'Bearbeiten',
    txt_global_std_statusnotconnected: 'Nicht verbunden',
    txt_global_std_reasonforpoints: 'Grund für Punkte',
    txt_global_std_appquestions: 'APP Fragen:',
    txt_global_std_connecting: 'Verbinden',
    txt_global_std_back1: 'Zurück',
    txt_global_std_max30characters: 'Maximal 30 Zeichen',
    txt_global_std_fileisattached: 'Datei ist angehängt',
    txt_global_std_yourpwwasnotchanged: 'Ihr Passwort wurde nicht geändert. Versuchen Sie es mit einem anderen neuen Passwort oder schreiben Sie ein gültiges aktuelles Passwort!',
    txt_global_std_badges: 'Abzeichen',
    txt_global_std_p2pnotincluded: 'Nicht enthalten',
    txt_global_std_p2pdistancetravellednotrecommended: 'Zurückgelegte Entfernung nicht empfohlen.',
    txt_global_std_addpolicynumber: 'Policennummer hinzufügen',
    txt_global_std_entervalidmessage: 'Geben Sie einen Text an Ihren Freund ein',
    txt_global_std_visibleinmaps: 'In Karten sichtbar',
    txt_global_std_challengename: 'Herausforderungsname',
    txt_global_std_MSIG_InsuranceLetter22: '2) Jede andere Person, die auf Anweisung des Versicherten oder mit seiner Erlaubnis fährt.',
    txt_global_std_areyousureyouwanttodeletethismessage: 'Möchten Sie diese Nachricht wirklich löschen?',
    txt_global_std_takemetotheappinstead: 'Bring mich stattdessen zur App',
    txt_global_std_cantfindanybluetoothdevice: 'Kann kein bluetooth-Gerät finden.',
    txt_global_std_youareoffline: 'Sie sind offline',
    txt_global_std_co2savingsscore: 'CO2-Einsparung',
    txt_global_std_p2pfaq31q: 'Wo kann ich die Fahrdaten überprüfen?',
    txt_global_std_improvementareaforspeed: 'Verbesserungsbereich für Geschwindigkeit',
    txt_global_std_createyouraccount: 'Erstelle deinen Account',
    txt_global_std_Gdpr_Text3: 'Wenn Sie ein Versicherungskunde sind, werden Ihre Informationen erst 1 Jahr nach Abschluss Ihrer Versicherung gelöscht und alle Fälle sind geschlossen. Beachten Sie, dass das Löschen Ihres Kontos Ihre Versicherung nicht beendet.',
    txt_global_std_anonymoususer: 'Anonymer Benutzer',
    txt_global_std_pointslefttoreach: 'Noch zu erreichende Punkte',
    txt_global_std_thistrip: 'Dieser Ausflug',
    txt_global_std_weeklyachievements: 'Wöchentliche Erfolge',
    txt_global_std_options: 'Optionen',
    txt_global_std_consentleaderboard: 'Zustimmung zu unseren Wettbewerben & Kontakt',
    txt_global_std_MSIG_InsuranceLetter32: 'Autorisierte Unterschrift',
    txt_global_std_namehasinvitedyoutojointheirgroup: '{name} hat Sie eingeladen, seiner {group} beizutreten',
    txt_global_std_35000pts: '&#62;35 000 Pkt',
    txt_global_std_totaldistancekm: 'Gesamtstrecke (km)',
    txt_global_std_mydrives: 'Meine Antriebe',
    txt_global_std_comment: 'Kommentar',
    txt_global_std_pluginthedevice: 'Stecken Sie das Gerät ein',
    txt_global_std_thecontactinformationyousubmithereiswhatwewillusetocontactyouregardingtheappandsendyoutheappsdownloadlink: 'Die Kontaktinformationen, die Sie hier übermitteln, werden von uns verwendet, um Sie bezüglich der App zu kontaktieren und Ihnen den Download-Link der App zuzusenden.',
    txt_global_std_enteryearlyprice: 'Geben Sie den Jahrespreis ein',
    txt_global_std_welcometo: 'Willkommen zu/zur',
    txt_global_std_repeatthechallengedailyweeklymonthlyorannually: 'Wiederholen Sie die Herausforderung täglich, wöchentlich, monatlich oder jährlich.',
    txt_global_std_totaltime: 'Gesamtzeit',
    txt_global_std_downloadreport: 'Bericht herunterladen',
    txt_global_std_referafriend: 'Empfehlen Sie einen Freund',
    txt_global_std_p2pnoconfirmedbookings: 'Keine bestätigten Buchungen zu zeigen.',
    txt_global_std_p2pyourshareofcost: 'Ihr Kostenanteil:',
    txt_global_std_p2ppushbodycancelledperiodfromto: 'Bitte beachten Sie, dass die Buchung {from} storniert wurde. Es wurde eine andere Buchung generiert und akzeptiert, die sich mit dieser nicht akzeptierten Buchung überschneidet.',
    txt_global_std_welcometoenerfy: 'Willkommen zu einem neuen Fahrerlebnis.',
    txt_global_std_enerfyanalyticsriskportfoliotrackerapp: 'Enerfy Analytics Risk Portfolio Tracker, App',
    txt_global_std_locationservicemustbeontodrivewithdimmedscreen: 'Der Ortungsdienst muss eingeschaltet sein, wenn der Bildschirm abgeblendet ist. Andernfalls gehen Daten verloren.',
    txt_global_std_weekly: 'Wöchentlich',
    txt_global_std_exporttranslations: 'Übersetzungen exportieren',
    txt_global_std_pleasereachouttothesupportifyouseethesameerrorwhenyoutrynexttimelater: 'Bitte wenden Sie sich an den Support, wenn beim nächsten Versuch derselbe Fehler auftritt.',
    txt_global_std_tojointeam: 'Team beitreten:',
    txt_global_std_cimage15h: 'Fokus auf die Details',
    txt_global_std_numbersonly: 'Nur Zahlen',
    txt_global_std_youmustchooseareason: 'Sie müssen einen Grund wählen',
    txt_global_std_theoreticaltimegain: 'Theoretischer Zeitgewinn',
    txt_global_std_TaxInvoice5: 'SCHECK FÜR MS FIRST CAPITAL INSURANCE LTD. ZAHLBAR MACHEN',
    txt_global_std_why: 'Warum',
    txt_global_std_p2pyourexpenses: 'Ihre Ausgaben',
    txt_global_std_japanese: 'Japanisch',
    txt_global_std_recruiterunlockedkey: 'Großartig, {user}! Du hast einen Freund in die App eingeladen!',
    txt_global_std_downloadingevents: 'Ereignisse herunterladen',
    txt_global_std_drivenotstarted: 'Fahrt nicht gestartet',
    txt_global_std_sendon: 'Weiterleitung:',
    txt_global_std_updatecarddetails: 'Kartendetails aktualisieren',
    txt_global_std_policyholderinformation: 'Informationen zum Versicherungsnehmer',
    txt_global_std_settingupclientcredentials: 'Einrichten von Client-Anmeldeinformationen',
    txt_global_std_howmanypeoplewereinjured: 'Wie viele Menschen wurden verletzt?',
    txt_global_std_writefriendspromocode: 'Schreiben Sie Freunden Promo-Code',
    txt_global_std_uploadfiles: 'Daten hochladen',
    txt_global_std_p2prequestsenttoname: 'Anfrage an {name} gesendet',
    txt_global_std_constantspeed: 'Konstante Geschwindigkeit',
    txt_global_std_noteam: 'Kein Team',
    txt_global_std_wehavesentatemporarypasswordtoyouremailaddress: 'Wir haben ein temporäres Passwort an Ihre E-Mail-Adresse gesendet.',
    txt_global_std_p2pallopenexpenses: 'Alle offenen Ausgaben',
    txt_global_std_analyzeyourdrivinghabitstoimprove: 'Analysieren Sie Ihre Fahrgewohnheiten, um Ihre fahrerischen Fähigkeiten zu verbessern.',
    txt_global_std_cimage32d: '',
    txt_global_std_howcanwehelpyou: 'Wie können wir Ihnen helfen?\n',
    txt_global_std_chassinumber: 'Chassi Nummer',
    txt_global_std_insurancedocuments: 'Versicherungsdokumente',
    txt_global_std_p2pacceptedbooking: 'Akzeptierte Buchung',
    txt_global_std_yourchallangeranking: 'Dein Challenge-Ranking',
    txt_global_std_dec: 'Dez',
    txt_global_std_enerfyanalytics: 'Enerfy Analytics',
    txt_global_std_addusers: 'Benutzer hinzufügen',
    txt_global_std_p2pparking: 'Parken',
    txt_global_std_pointsexpires: 'Punkte verfallen',
    txt_global_std_yourbonuswillbepaidoutinafewdays: 'Ihr Bonus wird in wenigen Tagen ausgezahlt',
    txt_global_std_avg: 'Durchschn.',
    txt_global_std_underwrittenby: 'Gesichert von',
    txt_global_std_p2ppushtitleexpensesthismonthquestion: 'Expenses this month?',
    txt_global_std_goheretocreateabookingofyourfriendscar: 'Gehen Sie hier, um eine Buchung für das Auto Ihres Freundes zu erstellen.',
    txt_global_std_cimage35d: 'Beginnen Sie zum Beispiel mit einem höheren Gang, wenn Sie bergab starten.',
    txt_global_std_tomasengewhyjoin: 'Ich liebe es, der Beste am Steuer zu sein, und ich bin sicher, dass jeder, der sich mir anschließt, das gleiche Ziel verfolgt.',
    txt_global_std_youhavenotfinishedanychallengesyet: 'Sie haben noch keine Herausforderungen abgeschlossen.',
    txt_global_std_jul: 'Jul',
    txt_global_std_p2pfilter: 'Filter',
    txt_global_std_youremail: 'Ihre E-Mail',
    txt_global_std_hi123: 'Hi',
    txt_global_std_kintofaq8q: 'Wie verdiene ich Ersparnisse?',
    txt_global_std_civilistatusmarriedcohabitingsingle: 'Zivilstatus (verheiratet, zusammenlebend, ledig)',
    txt_global_std_whenyoudrivesafelyandwithgoodflowweputmoneyinyourpiggybankyouwillbepaidthesewhentheinsuranceyearisovertogetherwithmoneyforkilometersyoumaynothaveusedthetotalamountincludesstartbonusmoneysavedforsafedrivingandmoneyforfriendrecruitment: 'Wenn Sie sicher und mit gutem Flow fahren, legen wir Geld in Ihr Sparschwein. Diese erhalten Sie nach Ablauf des Versicherungsjahres zusammen mit Geld für eventuell nicht gefahrene Kilometer. Der Gesamtbetrag beinhaltet den Startbonus, das gesparte Geld für sicheres Fahren und das Geld für die Freundschaftswerbung.',
    txt_global_std_p2p1booking: '(1 Buchung)',
    txt_global_std_p2ppushbodylendcar: '{name} hat Ihnen eine Kreditanfrage gesendet',
    txt_global_std_damagereport: 'Schadensbericht',
    txt_global_std_areyousureyouwanttoenablethecommunication: 'Möchten Sie die Kommunikation wirklich aktivieren?',
    txt_global_std_nowitstimetoconnecttoyourcarsbluetooth: 'Jetzt ist es Zeit, sich mit dem Bluetooth Ihres Autos zu verbinden. ',
    txt_global_std_thisisneededbeforeyoustartyourfirstdrive: 'Dies wird vor der ersten Fahrt benötigt.',
    txt_global_std_getstartedinnotimewithourtutorials: 'Legen Sie mit unseren Tutorials im Handumdrehen los!',
    txt_global_std_p2punblockcar: 'Auto entsperren',
    txt_global_std_zipdriverriskindexinsuranceratingcomponent: 'Zip-Driver-Risikoindex - Versicherungsbewertungskomponente',
    txt_global_std_topimprovers: 'Top-Verbesserer',
    txt_global_std_establishingbluetoothlink: 'Bluetooth-Verbindung herstellen',
    txt_global_std_drivewithanaveragescoretypeofscoreorbetterforaweektounlockthisachievementandearnpointspoints: 'Fahren Sie eine Woche lang mit einem durchschnittlichen {scoretype} von {score} oder besser, um diesen Erfolg freizuschalten und {points} Punkte zu sammeln.',
    txt_global_std_sharelink: 'Einen Link teilen',
    txt_global_std_warikan_onboarding6: '2. Starten Sie die Warikan KINTO-App und wählen Sie „Anmelden“\n',
    txt_global_std_insurancerenew: 'Sie haben nur noch {km} km übrig. Ihre Versicherung wird am {date} verlängert.',
    txt_global_std_p2ppartlyavailable: 'Teilweise verfügbar',
    txt_global_std_loadmore: 'Drücken Sie, um mehr zu laden',
    txt_global_std_MikkelsenDescription: 'Andreas hat einen breiten sportlichen Hintergrund. Er hat im Alpinen Skisport mitgemacht und war Mitglied des Alpinen Skiteams der nationalen Junioren. 2003 und 2004 nahm er an Motocross-Rennen teil und vertrat auch die Junioren-Nationalmannschaft. Er wurde in den Jahren 2014, 2015 und 2016 Dritter der Rallye-Weltmeisterschaft und gewann drei Siege und 20 Podiumsplätze.',
    txt_global_std_safedriverdiscountpercent: 'Safedriver-Rabatt {percent}%',
    txt_global_std_iunderstand: 'ich verstehe',
    txt_global_std_MSIG_InsuranceLetter36: 'Schaden- und Motor Underwriting-Abteilung:',
    txt_global_std_referfriend: 'Empfehle einen Freund.',
    txt_global_std_p2presendcode: 'Code erneut senden',
    txt_global_std_p2pbecomefriendstoviewthisprofile: 'Werden Sie Freunde, um dieses Profil anzuzeigen',
    txt_global_std_p2pthisemailadressisusedforustosendcommunication: 'Diese E-Mail-Adresse wird für uns verwendet, um Mitteilungen zu senden, beispielsweise wenn Sie Ihr Passwort vergessen haben.',
    txt_global_std_no: 'Nein',
    txt_global_std_p2pmytripscomingsoon: 'Meine Reisen - Bald erhältlich',
    txt_global_std_pigwonunlocked: 'Herzliche Glückwünsche! Du hast einen Wettbewerb in head2head gewonnen',
    txt_global_std_registrationnumber2: 'Registrationsnummer',
    txt_global_std_notyetdecided: 'Noch nicht bestimmt ',
    txt_global_std_driversidentificationnumber: 'Nummer des Fahrers',
    txt_global_std_insuranceidcard: 'Versicherungsausweis',
    txt_global_std_repeatchallenge: 'Herausforderung wiederholen?',
    txt_global_std_rewardandendorsesmartbehavior: 'Belohnen und unterstützen Sie kluges Verhalten',
    txt_global_std_KleinschmidtFacts: '',
    txt_global_std_MSIG_InsuranceLetter10: 'Fahrzeug Nr. / Fahrgestellnr',
    txt_global_std_qwhathappensififorgettorenewbeforemyinsurancehasexpired: 'F: Was passiert, wenn ich die Verlängerung vor Ablauf meiner Versicherung vergesse?',
    txt_global_std_community: 'Gemeinschaft',
    txt_global_std_estimateddiscount: 'Geschätzter Rabatt',
    txt_global_std_score2: 'Ergebnis',
    txt_global_std_addprice: 'Preis hinzufügen',
    txt_global_std_p2phowmuchdoyouwanttocharge: 'Wie viel möchten Sie berechnen?',
    txt_global_std_p2pkintonumber: 'Kinto Nummer',
    txt_global_std_p2pfaq7a: '1.Gehen Sie zur Buchungszusammenfassung für diese Buchung.\n' +
      '2.Drücken Sie oben links auf die Schaltfläche Buchung stornieren.',
    txt_global_std_kintofaq5q: 'Was ist, wenn ich das Auto wechsle?',
    txt_global_std_Fia_Receipt_Text1: 'Ihr Einkauf ist abgeschlossen!',
    txt_global_std_postalcode: 'Postleitzahl',
    txt_global_std_weareinthischallengetomaketheworldsaferandbettertogetherjoinustohelpmakeanimpact: 'Wir stellen uns dieser Herausforderung, die Welt sicherer und besser zu machen – gemeinsam. Machen Sie mit, um etwas zu bewirken!',
    txt_global_std_anotheappworksinbackgroundmodemakesureyoualwayskeepbluetoothandlocationservicesactivatedonyourphone: 'A: Nein, die App funktioniert im Hintergrundmodus. Stellen Sie sicher, dass Bluetooth und Standortdienste auf Ihrem Telefon immer aktiviert sind.',
    txt_global_std_p2pstatus: 'Status',
    txt_global_std_p2pshareofmonthlyfee: 'Anteil der monatlichen Gebühr',
    txt_global_std_rwmselectthetripsyouwereridesharing: 'Wählen Sie die Fahrten aus, bei denen Sie Mitfahrgelegenheiten genutzt haben',
    txt_global_std_ecoscore123: 'Öko-Punktzahl',
    txt_global_std_p2pgreatyourcostsplitwassenttoyourfriends: 'Groß! Ihre Kostenaufteilung wurde an Ihre Freunde gesendet.',
    txt_global_std_activationletter_text14: 'Folgen Sie den Anweisungen in der App.',
    txt_global_std_advancedadmin_upsell_pitch_bulletpoint3: 'Dokumente, Richtlinien und andere intelligente Verwaltungstools an einem Ort',
    txt_global_std_youarebeingredirectedtodashboard: 'Sie werden zum Dashboard weitergeleitet.',
    txt_global_std_sportscorescore: 'Sportergebnis {Punktzahl}',
    txt_global_std_yourlatestscore: 'Ihre letzte Punktzahl:',
    txt_global_std_MSIG_InsuranceLetter11: 'Name des Versicherten',
    txt_global_std_codewasnotfound: 'Code wurde nicht gefunden',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimeincreasingspeedreadingtheroadaheadcanhelpyoudeterminewhetheritissuitabletobuildspeed: 'Auf dieser Art von Reise verbrachte der durchschnittliche Öko-Champion weniger Zeit damit, die Geschwindigkeit zu erhöhen. Das Lesen der vorausliegenden Straße kann Ihnen helfen, festzustellen, ob sie geeignet ist, um Geschwindigkeit aufzubauen.',
    txt_global_std_p2pbluetoothconnectionrequired: 'Bluetooth-Verbindung erforderlich',
    txt_global_std_topteamdrivers: 'Top Teamfahrer',
    txt_global_std_mycar: 'Mein Auto',
    txt_global_std_weeks: 'Wochen',
    txt_global_std_cimage13d: 'Lassen Sie Ihre Geschwindigkeit etwas sinken und fahren bergauf',
    txt_global_std_km: 'KM',
    txt_global_std_p2ptoyotawalletcomingsoon: 'Toyota Geldbörse (bald verfügbar)',
    txt_global_std_weresettingupyourchallenge: 'Wir richten Ihre Herausforderung ein',
    txt_global_std_greatrun: 'Toller Lauf',
    txt_global_std_welcometocovercube: 'Willkommen bei Covercube!',
    txt_global_std_sidekickhelpsyouwiththemotivationandtipstodrivebettersaferandmoreecofriendly: 'Sidekick hilft Ihnen mit Motivation und Tipps, um besser, sicherer und umweltfreundlicher zu fahren.',
    txt_global_std_bysigningupiacceptthetermsandconditionsandprivacypolicy: 'Mit der Anmeldung akzeptiere ich die AGB\nund Datenschutzrichtlinie.',
    txt_global_std_p2ppushbodynewmessage: 'erste Zeile der neuen Nachricht',
    txt_global_std_JyrkiainenMusic: 'Pop und Rapmusik',
    txt_global_std_p2ptimes: 'mal',
    txt_global_std_totalvalid: 'Insgesamt gültig',
    txt_global_std_2pairyourphonewithyourcarsbluetoothsystem: '2. Koppeln Sie Ihr Telefon mit dem Bluetooth-System Ihres Autos.',
    txt_global_std_schedulemessage: 'Nachricht planen',
    txt_global_std_activatebluetooth: 'Aktivieren Sie Bluetooth',
    txt_global_std_p2paddyourexpensesbeforethelastdayofthemonthtogetthemincludedinthenextcostsplit: 'Fügen Sie Ihre Ausgaben vor dem letzten Tag des Monats hinzu, um sie in die nächste Kostenteilung aufzunehmen.',
    txt_global_std_p2pcurioustoknowhowyoudrivekeeptrackofyourprogressandsestatsaboutyourscoreco2savingsdistanceandmore: 'Neugierig zu wissen, wie Sie fahren? Behalten Sie Ihren Fortschritt im Auge und sehen Sie Statistiken über Ihre Punktzahl, CO2-Einsparungen, Entfernung und mehr.',
    txt_global_std_rwmsendinvitelink: 'Einladungslink senden',
    txt_global_std_p2pdocumentmycarcomingsoon: 'Dokumentiere mein Auto - Bald erhältlich',
    txt_global_std_p2pmessagedeleted: 'Nachricht gelöscht!',
    txt_global_std_clickthroughtheappintheframeandfindthetranslationyouwanttoeditorfindtheminthelistontherightside: 'Klicken Sie sich durch die App im Rahmen und suchen Sie die Übersetzung, die Sie bearbeiten möchten, oder suchen Sie sie in der Liste auf der rechten Seite.',
    txt_global_std_allset: 'Alles bereit!',
    txt_global_std_p2pwaitingforacceptance: 'Warten auf Annahme.',
    txt_global_std_createaccounttext: 'Benutzerkonto anlegen',
    txt_global_std_costsplit: 'Kostenaufteilung',
    txt_global_std_p2pcancelledthebooking: 'stornierte die Buchung.',
    txt_global_std_help: 'Hilfe',
    txt_global_std_youcannowclosethiswindoworreturntothedashboard: 'Sie können dieses Fenster jetzt schließen oder zum Dashboard zurückkehren.',
    txt_global_std_getshareabledomainlink: 'Holen Sie sich einen teilbaren Domain-Link',
    txt_global_std_lowrisk: 'Niedriges Risiko',
    txt_global_std_tttest98: 'Das ist ein Test 98',
    txt_global_std_p2pcostsplitisbasedonkilometersdriven: 'Die Kostenaufteilung basiert auf gefahrenen Kilometern.',
    txt_global_std_pleasechooseareason: 'Please choose a reason',
    txt_global_std_selectedreader: 'Ladegerät auswählen ',
    txt_global_std_domainrestrictedinvitelink: 'Domänenbeschränkter Einladungslink',
    txt_global_std_challenge2: 'HERAUSFORDERUNG',
    txt_global_std_startcarengineandpleasemakesurebluetoothoncarsideisenabledifthisisyourfirsttimeconnectingyourphonetocarpleaseconnectbluetoothfromcarside: 'Starten Sie den Automotor und vergewissern Sie sich, dass Bluetooth auf der Fahrzeugseite aktiviert ist.\n' +
      'Wenn Sie Ihr Telefon zum ersten Mal mit dem Auto verbinden, verbinden Sie bitte Bluetooth von der Fahrzeugseite.',
    txt_global_std_10discountonrenewal: '10% Rabatt auf Verlängerung',
    txt_global_std_treessaved: 'Bäume gerettet',
    txt_global_std_JyrkiainenQuote: 'Mit der FIA Smart Driving Challenge kann gezeigt werden, dass Umweltfragen auch im Motorsport eine Rolle spielen. Tritt gegen deine Freunde an und fahre intelligent!',
    txt_global_std_couldnotvalidateyourcode: 'Code konnte nicht validiert werden! Bitte stellen Sie sicher, dass Sie den Code korrekt eingegeben haben und dieser gültig ist, und versuchen Sie es erneut!',
    txt_global_std_preview1: 'Vorschau',
    txt_global_std_tojoingroup: 'Gruppe beitreten:',
    'txt_global_std_costofspeedabove{speed}': 'Kosten für Geschwindigkeit über {Geschwindigkeit}',
    txt_global_std__entertheemailthatyouusedwhenyouregisteredyourinsurance: 'Geben Sie die E-Mail-Adresse ein, die Sie bei der Registrierung Ihrer Versicherung verwendet haben.',
    txt_global_std_above1000streakunlocked: 'Sie sind fünfmal über 1000 Punkte gefahren. Fantastische Arbeit!',
    txt_global_std_apitool_upsell: 'API Tool – der Einstieg für Entwickler in die Datenintegration.',
    txt_global_std_choosenumberbetween1and9: 'Wählen Sie eine Zahl zwischen 1 und 9',
    txt_global_std_importtranslations: 'Übersetzungen importieren',
    txt_global_std_renewyourinsuranceformonthandgetadiscountonyourpremium: 'Verlängern Sie Ihre Versicherung für {Monat} und erhalten Sie einen Rabatt auf Ihre Prämie',
    txt_global_std_compareyourtripresultstothebestecodriverswecallthemecochampions: 'Vergleichen Sie Ihre Reiseergebnisse mit den besten Öko-Fahrern. Wir nennen sie Öko-Champions.',
    txt_global_std_signup1: 'Anmelden',
    txt_global_std_notvalidchassinumber: 'Nicht gültige Chassinummer',
    txt_global_std_describepurpose: 'Beschreiben Sie den Zweck',
    txt_global_std_latesttripdate: 'Letzte Reise: {date}',
    txt_global_std_insurancecustomer: 'Versicherungskunde',
    txt_global_std_connecttoanothercar: 'Verbinden Sie sich mit einem anderen Auto',
    txt_global_std_KleinschmidtMusic: 'Beim Fahren höre ich gerne Radio und Informationen ',
    txt_global_std_reviewchanges: 'Änderungen prüfen',
    txt_global_std_p2puserexpenses: '{name} Ausgaben',
    txt_global_std_partnercode: 'Partnercode',
    txt_global_std_bluetoothsettings: 'Bluetooth-Einstellungen',
    txt_global_std_p2pincluded: 'Inbegriffen',
    txt_global_std_p2pstarttime: 'Startzeit:',
    txt_global_std_rwminviteanewfriend: 'Lade einen neuen Freund ein',
    txt_global_std_calculateyourco2savings: 'Berechnen Sie Ihre CO2-Einsparung',
    txt_global_std_currentbonusperiod: 'Aktueller Bonuszeitraum:',
    txt_global_std_200km: '200 Kilometer',
    txt_global_std_youreceivedarenewalofferformonth: 'Sie haben ein Verlängerungsangebot für {month} erhalten.',
    txt_global_std_activationcodestartdate: 'Startdatum des Aktivierungscodes',
    txt_global_std_previous: 'Bisherige',
    txt_global_std_uploadphotosofthedamagesorscratchesonyourcar: 'Laden Sie Fotos von Beschädigungen oder Kratzern in Ihrem Auto hoch',
    txt_global_std_yourcarmodelistoooldanddoesnthavebluetooth: '• Ihr Automodell ist zu alt und verfügt nicht über Bluetooth',
    txt_global_std_contributesustainabilitythroughwarikanbody: 'Warum fahren Sie nicht gerne und achten gleichzeitig auf umweltfreundliches Fahren? Warikan KINTO ist hier, um zu helfen! Die Bewertungsfunktion und der Erwerb von Abzeichen sind auch dann verfügbar, wenn Sie kein KINTO ONE-Benutzer sind',
    txt_global_std_cityconditions: 'Städtische Verhältnisse',
    txt_global_std_cimage18d: 'Die geplante Durchschnittsgeschwindigkeit spart mehr Zeit als die Höchstgeschwindigkeit',
    txt_global_std_1startyourcar: '1. Starten Sie Ihr Auto.',
    txt_global_std_accessdeniedyoudonothavepermissiontoaccesstheworkspacepleasecontactyouradministratorifincorrect1: 'Zugriff abgelehnt. Sie sind nicht berechtigt, auf den Arbeitsbereich zuzugreifen. Wenden Sie sich bei Fehlern bitte an Ihren Administrator.',
    txt_global_std_yourchangesaresaved: 'Ihre Änderungen werden gespeichert!',
    txt_global_std_challengeleaderboard_upsell: 'Herausforderungs-Dashboard – Fördern Sie sicheres Fahren mit benutzerdefinierten Herausforderungen.',
    txt_global_std_distancekm1: 'Entfernung (km)',
    txt_global_std_p2pthispasswordhasexpired: 'Dieses Passwort ist abgelaufen',
    txt_global_std_p2psendinvoices: 'Rechnungen versenden',
    txt_global_std_HansenFacts: 'Kevin Hansen, der jüngste FIA-Europameister im Supercar und FIA-Rookie 2016 des Jahres.',
    txt_global_std_drivers2: 'Treiber',
    txt_global_std_p2pfaq26a: '1. Wählen Sie im Startbildschirm „Reservieren“.\n' +
      '2. Wählen Sie „Auto leihen“.\n' +
      '3. Wählen Sie das Mitglied aus, für das Sie ein Auto mieten möchten.\n' +
      '4. Wählen Sie Datum und Uhrzeit aus.\n' +
      '5. Geben Sie Ihre Nachricht ein (optional) und wählen Sie abschließend "Anfrage senden".\n' +
      '6. Der Kreditantrag wird ausgesetzt, bis das Mitglied ihn genehmigt oder ablehnt.',
    txt_global_std_doyouwanttoresetthepasswordforuser: 'Möchten Sie das Passwort für {user} zurücksetzen?',
    txt_global_std_2makesureyourphoneispairedtoyourcarviabluetooth: '2. Stellen Sie sicher, dass Ihr Telefon über Bluetooth mit Ihrem Auto gekoppelt ist.',
    txt_global_std_summaryoftripsperslectecdperiod: 'Zusammenfassung der Fahrten pro ausgewähltem Zeitraum.',
    txt_global_std_newreport: 'Neuer Bericht',
    txt_global_std_cardwithmoreinformationaboutpermissions: 'Karte mit weiteren Informationen zu Berechtigungen',
    txt_global_std_p2pbookingof: ' Buchung von',
    txt_global_std_MSIG_Onboarding7: '1. Klicken Sie auf den Link, den Sie per Text erhalten haben.',
    txt_global_std_qhowdoestheappwork: 'F: Wie funktioniert die App?',
    txt_global_std_kintoloyaltysystemdesc: 'Bitte beachten Sie, dass Ihre Vergünstigungen auf den Endergebnissen der vorherigen Treueperiode basieren. Punkte werden durch Erfolge gesammelt und zu Beginn eines neuen Zeitraums zurückgesetzt.',
    txt_global_std_p2pyourfriendinvitedyoutoborrowtheircar: 'Dein Freund hat dich eingeladen, ihr Auto auszuleihen.',
    txt_global_std_place: 'Standort',
    txt_global_std_inorderforustoshowyouvariousstatisticsitisimportantthatthephoneissettouselocationalways: 'Damit wir Ihnen verschiedene Statistiken anzeigen können, ist es wichtig, dass das Telefon so eingestellt ist, dass es den Standort „immer“ verwendet.',
    txt_global_std_gotosettingsonyourcarscreenandpairthebluetoothwithyourphone: 'Gehen Sie zu den Einstellungen auf Ihrem Autobildschirm und koppeln Sie das Bluetooth mit Ihrem Telefon',
    txt_global_std_cimage6h: 'Vermeiden Sie unnötiges Überholen',
    txt_global_std_youhavenotenabledyourprivacyundersettings: 'Sie haben Ihre &quot;Privatsphäre&quot; unter &quot;Einstellungen&quot; nicht aktiviert. Wenn &quot;Datenschutz&quot; nicht aktiviert ist, können wir nicht alle aufregenden Wettbewerbe und Tipps, die in der App bereitgestellt werden, mit Ihnen teilen. Dann aktiviere &quot;Privacy&quot; und trete schon heute unserer Community bei.',
    txt_global_std_downloadcsvtemplate: 'CSV-Vorlage herunterladen',
    txt_global_std_countryroadroundabout2DESC: 'Der blaue Autofahrer fährt sanft durch den Kreisverkehr. Der rote Autofahrer fährt unkonzentriert und nutzt häufiger Bremsen und Getriebe.',
    txt_global_std_cimage12d: '',
    txt_global_std_pleaseenteravalidcellphonenumber: 'Bitte geben Sie eine gültige Handynummer ein',
    txt_global_std_sendorder: 'Bestellung senden',
    txt_global_std_brokenitems: 'Gebrochene Gegenstände?',
    txt_global_std_selectatemplatedesc: 'Verwenden Sie diese Vorlage als Abzeichen in Ihrer App. Passen Sie es im nächsten Schritt an.',
    txt_global_std_sendlink: 'Link senden',
    txt_global_std_leavewithoutchanges: 'Ohne Änderungen verlassen',
    txt_global_std_toestablishaconnectiontoyourcarappnameneedstoaccesstobluetooth: 'Um eine Verbindung zu Ihrem Auto herzustellen, muss {appname} auf Bluetooth zugreifen.',
    txt_global_std_p2pleavethisgroup: 'Diese Gruppe verlassen',
    txt_global_std_PolicySchedule14: 'Benannte Treiber',
    txt_global_std_p2pbookingnotifications: 'Buchungsbenachrichtigungen',
    txt_global_std_bestdriversoftheday: 'Beste Fahrer des Tages',
    txt_global_std_customtabtext: 'Benutzerdefinierte Erfolge',
    txt_global_std_MarklundWhyjoin: 'Wir werden nur einen Unterschied zum Besseren machen, indem wir unseren Fahrstil ändern. In meinem Team konzentrieren wir uns darauf, etwas zu erreichen, mit dem Ziel zu gewinnen und auf dem Weg dorthin werden wir Spaß haben. Ich mag Wettbewerbe und ich gewinne gerne, aber ich sehe auch gerne, dass meine zukünftigen Kinder an einem sicheren und gesunden Ort aufwachsen. Das ist das Wichtigste.',
    txt_global_std_thepersonyouinvitegetaccessyoalldrivesandcost: 'Die Person, die Sie einladen, erhält Zugriff auf alle Ihre Reisen und Kosten.',
    txt_global_std_drivewithanaverageofscoreorbetterforaweektounlockthisachievementandearnpointspoints: 'Fahren Sie eine Woche lang mit einem Durchschnitt von {score} oder besser, um diesen Erfolg freizuschalten und {points} Punkte zu sammeln.',
    txt_global_std_advancedadmin_upsell_pitch: 'Navigieren Sie ganz einfach durch Ihre eigene digitale Customer Success Journey für;',
    txt_global_std_answer: 'Antwort',
    txt_global_std_thursday: 'Donnerstag',
    txt_global_std_cimage40h: 'Jeder zusätzlich gerollte Meter verbessert das Ergebnis',
    txt_global_std_simplyprovideyourpersonaldetailssowecansetupyouradminaccountnextyoullbeabletostartaddingteammemberstojoinyourfiasdccompanychallenge: 'Geben Sie einfach Ihre persönlichen Daten an, damit wir Ihr Administratorkonto einrichten können. Als Nächstes können Sie damit beginnen, Teammitglieder hinzuzufügen, um an Ihrer FIA SDC Company Challenge teilzunehmen!',
    txt_global_std_datetimedistance: 'Datum, Zeit, Entfernung ',
    txt_global_std_identifyfollowupandreportonsafetyandsustainabilitytargets: 'Identifizieren, verfolgen und berichten Sie über Sicherheits- und Nachhaltigkeitsziele',
    txt_global_std_sharecertificate: 'Aktienzertifikat',
    txt_global_std_coporateoffice: 'Coporate Office',
    txt_global_std_p2pemailstartingsoon: 'Beginnt bald',
    txt_global_std_p2pfaq27q: 'Was sind Administratorrechte?',
    txt_global_std_acaddyourbrandcolors: 'Fügen Sie Ihre Markenfarben hinzu',
    txt_global_std_improvementpotential: 'Verbesserungspotential',
    txt_global_std_thepasswordcontainsforbiddencharactersorspace: "Das Passwort enthält verbotene Zeichen: ' ? & / &#60; &#62; oder Leerzeichen",
    txt_global_std_typeofachievement: 'Art der Leistung',
    txt_global_std_finishdrive: 'Reise beenden',
    txt_global_std_youshouldaddapictureofyourodometer: 'Sie sollten ein Bild von Ihrem Kilometerzähler hinzufügen',
    txt_global_std_connectingtogps: 'Mit GPS verbinden',
    txt_global_std_app: 'App',
    txt_global_std_Fia_Receipt_Text2: 'Klicken Sie auf den Link, um an der FIA SDC teilzunehmen:',
    txt_global_std_2nddate: '2',
    txt_global_std_startd: 'Start',
    txt_global_std_youcanalsouploadpicturesthatmightberelevant: 'Sie können auch Bilder hochladen, die im Falle eines Unfalls relevant sein könnten.',
    txt_global_std_missingbonuspayout: 'Fehlende Bonusauszahlung',
    txt_global_std_daysshort: 'D',
    txt_global_std_areyoudriving: 'Fahren Sie',
    txt_global_std_timelefttoreactivateinsurance: 'Noch Zeit, um die Versicherung zu reaktivieren',
    txt_global_std_fivereasonswhyjoiningfiasdcisasmartbusinessdecision: 'Fünf Gründe, warum der Beitritt zur FIA SDC eine kluge Geschäftsentscheidung ist:',
    txt_global_std_orenteractivationcode: 'Oder geben Sie den Aktivierungscode ein:',
    txt_global_std_youdonothaveanyongoingchallengesatthemoment: 'Sie haben derzeit keine laufenden Herausforderungen.',
    txt_global_std_locationserviceneedstobeontoworkcorrectly: 'Der Ortungsdienst muss eingeschaltet sein, wenn der Bildschirm abgeblendet ist. Andernfalls gehen Daten verloren.',
    txt_global_std_forthisapptoworkproperlyweneedtoaccess: 'Damit diese App ordnungsgemäß funktioniert, müssen wir auf Ihren Standort, Bluetooth und Fitness / Bewegung zugreifen. Wir legen Wert auf Ihre Privatsphäre und geben diese Informationen nicht an Dritte weiter.',
    txt_global_std_translationtool_upsell: 'Übersetzungstool – spiegeln Sie Ihre Markenwerte wider, indem Sie auf Ihre eigene Weise kommunizieren.',
    txt_global_std_cimage23d: 'Jeder zusätzliche gerollte Meter wirkt sich auf die Geschwindigkeitsabnahme oder den Stopp aus.',
    txt_global_std_analyzingdotdot: 'Analysieren...',
    txt_global_std_insureddistance_used_100__3: 'Oj! Stellen Sie sicher, dass Sie bei Beschädigungen nicht abkürzen. Passen Sie den Fahrweg so an, dass er korrekt ist.',
    txt_global_std_youmayaddaphotolater: 'Sie können später ein Foto hinzufügen',
    txt_global_std_sendassms: 'Als SMS versenden',
    txt_global_std_selectinsurancetoterminate: 'Wählen Sie die zu beendende Versicherung aus',
    txt_global_std_nowitstimetosetupyourcompanychallenge: 'Jetzt ist es an der Zeit, Ihre Company Challenge einzurichten!',
    txt_global_std_youvehavegatheredatotalof: 'Sie haben insgesamt gesammelt',
    txt_global_std_alsochallengeyourselfandengageyourfriendsinaworldcompetition: 'Fordern Sie sich selbst heraus und beteiligen Sie Ihre Freunde an einem Weltwettbewerb.',
    txt_global_std_youmusthaveinternetconnection: 'Sie benötigen eine Internetverbindung, um Live-Ergebnisse zu erhalten.',
    txt_global_std_p2pnomessagesyet: 'Noch keine Nachrichten',
    txt_global_std_invalidinput: 'Ungültige Eingabe',
    txt_global_std_cimage7h: 'Schnell zur Fahrtgeschwindigkeit',
    txt_global_std_p2pdenyy: 'Verweigern',
    txt_global_std_surcharge: 'Zuschlag',
    txt_global_std_p2pgotosupport: 'Gehen Sie zur Unterstützung',
    txt_global_std_MSIG_InsuranceLetter43: 'SGD1,000.00 ALLE ANSPRÜCHE WERDEN FAHRERN auferlegt, die den DONGLE mindestens 14 Tage in einem Kalenderjahr deaktiviert haben.',
    txt_global_std_cimage16h: 'Pkanen Sie Ihre Geschwindigkeit',
    txt_global_std_timeinacceleration: 'Zeit in Beschleunigung',
    txt_global_std_planning: 'Planung',
    txt_global_std_p2ptimeused: 'Verwendete Zeit',
    txt_global_std_whenyouvestartedadriveyoucanseeyourcarslocation: 'Wenn Sie eine Fahrt begonnen haben, können Sie den Standort Ihres Autos sehen.',
    txt_global_std_myfriends: 'Meine Freunde ',
    txt_global_std_1000drive: '1000 fahren!',
    txt_global_std_cimage26h: 'Gang bei niedriger Drehzahl',
    txt_global_std_p2pyoublocked: 'Du hast blockiert',
    txt_global_std_reportaccident2: 'Unfall melden',
    txt_global_std_bycheckingthisboxandlogginginyoureacceptingour: 'Indem Sie dieses Kontrollkästchen aktivieren und sich anmelden, akzeptieren Sie unsere',
    txt_global_std_wesharethislater: 'Wir teilen das später ...',
    txt_global_std_syncingwithyourgarminconnectaccountiseasyclicktheconnectbuttonbelowtohelpusuncovernewinsightsintocrashriskbyanalyzingbothdrivingandhealthdata: 'Die Synchronisierung mit Ihrem Garmin Connect™-Konto ist ganz einfach! Klicken Sie unten auf die Schaltfläche Verbinden, um uns dabei zu helfen, neue Erkenntnisse zum Unfallrisiko zu gewinnen, indem wir sowohl Fahr- als auch Gesundheitsdaten analysieren.',
    txt_global_std_showadvancedsettings: 'Erweiterte Einstellungen anzeigen',
    txt_global_std_rewardthosewhoexcel: 'Belohnen Sie diejenigen, die sich auszeichnen!',
    txt_global_std_kintofaq6a2: 'für Hilfe.',
    txt_global_std_uploadintervalminutesmin: '{uploadIntervalMinutes} Min',
    txt_global_std_p2penterkintonumber: 'Geben Sie die Kinto-Nummer ein',
    txt_global_std_startadrivetogetascore: 'Starten Sie eine Fahrt, um Punkte zu bekommen!',
    txt_global_std_orgenerateinvitationcodeandlinkformultipleuse: 'Oder generieren Sie Einladungscode und Link zur mehrfachen Verwendung',
    txt_global_std_atrustedscorevettedbythefia: 'Eine vertrauenswürdige Punktzahl, die von der FIA geprüft wurde',
    txt_global_std_timetoconnecttoyourcar: 'Zeit, sich mit Ihrem Auto zu verbinden',
    txt_global_std_MSIG_InsuranceLetter26: 'Die Richtlinie gilt nicht für die Verwendung zum Mieten oder zur Belohnung, für Rennen, für Schrittmacher, für Zuverlässigkeitsprüfungen, für Geschwindigkeitsprüfungen, für die Beförderung von Gütern mit Ausnahme von Mustern in Verbindung mit einem Gewerbe oder Geschäft oder für die Verwendung zu irgendeinem Zweck in Verbindung mit dem Kraftfahrzeughandel.',
    txt_global_std_acontactyourinsuranceagencytoterminateyourinsurance: 'A: Wenden Sie sich an Ihre Versicherungsagentur, um Ihre Versicherung zu kündigen.',
    txt_global_std_selectinsurance: 'Versicherung auswählen',
    txt_global_std_nameunavailable: 'Name nicht verfügbar',
    txt_global_std_p2pwarningdeletebooking: 'Dadurch wird die Buchung dauerhaft gelöscht.',
    txt_global_std_numberoftimes: 'Anzahl',
    txt_global_std_menu: 'Menü',
    txt_global_std_enteravalidlicenseplate: 'Geben Sie ein gültiges Kennzeichen ein!',
    txt_global_std_tuesday: 'Dienstag',
    txt_global_std_endinsuranceforname: 'Versicherung für {name} beenden?',
    txt_global_std_enerfyanalytics_upsell_bulletpoint3: 'Holen Sie mehr Wert aus Daten, um den ROI zu verbessern',
    txt_global_std_greatprogress: 'Großer Fortschritt!',
    txt_global_std_manageyourgroups: 'Verwalten Sie Ihre Gruppen',
    txt_global_std_colleaguespartnersandinvitedguestscanjoinyourbrandedworkspace: 'Kollegen, Partner und geladene Gäste können Ihrem Branded Workspace beitreten',
    txt_global_std_enablepushnotifications: 'Aktivieren Sie Push-Benachrichtigungen',
    txt_global_std_messages: 'Nachrichten ',
    txt_global_std_p2puploadaprofileimage: 'Laden Sie ein Profilbild hoch',
    txt_global_std_createyourownworkspaceinthefiasmartdrivingchallengeyoucancreatemanyworkspacessuitableforcustomersandotherpartnersinviteandgettoknowmoreaboutdrivingandyourco2imprint: 'Erstellen Sie Ihren eigenen Arbeitsplatz in der FIA Smart Driving Challenge. Sie können viele Arbeitsbereiche erstellen, die für Kunden und andere Partner geeignet sind. Laden Sie ein und erfahren Sie mehr über das Autofahren und Ihren CO2-Fußabdruck.',
    txt_global_std_makeapayment: 'Eine Zahlung tätigen',
    txt_global_std_dashboard: 'Armaturenbrett',
    txt_global_std_addyourlogotypeandchoosecolorthemeexpandtoseeapreviewofthechallenge: 'Fügen Sie Ihr Logo hinzu und wählen Sie ein Farbthema. Erweitern, um eine Vorschau der Herausforderung anzuzeigen.',
    txt_global_std_selectaparent: 'Wählen Sie einen Elternteil aus',
    txt_global_std_accept: 'Akzeptieren',
    txt_global_std_p2pfaq27a: 'Sie können Mitgliedern einige der Rechte des Eigentümers erteilen.\n' +
      'Was Sie tun können: Reservierungen verwalten (Reservierungen von anderen Mitgliedern genehmigen / ablehnen, Reservierungszeit ändern usw.)',
    txt_global_std_bluetoothid: 'Bluetooth-ID',
    txt_global_std_timelocationaretheonlymandatoryfieldspleaseuploadpicturesofyourcarwithin24hours: 'Zeit & Ort sind die einzigen Pflichtfelder. Bitte laden Sie innerhalb von 24 Stunden Bilder Ihres Autos hoch.',
    txt_global_std_cantfindyourevent: 'Können Sie Ihre Veranstaltung nicht finden?',
    txt_global_std_step: 'Schritt',
    txt_global_std_privacypolicy: 'Datenschutz-Bestimmungen',
    txt_global_std_improveyourdrivingandtheplanet: 'Verbessern Sie Ihr Fahren und den Planeten',
    txt_global_std_pleasetryagain: 'Bitte versuche es erneut',
    txt_global_std_youcanfindotheruserguidesifyougotothefaqinthemoremenu: 'Weitere Benutzerhandbücher finden Sie unter den FAQ im Menü Mehr.',
    txt_global_std_remembertotakepicturesofthedamageandsavethedatetimeandplace: 'Denken Sie daran, den Schaden zu fotografieren und Datum, Uhrzeit und Ort zu speichern.',
    txt_global_std_topecodriver: 'Top Öko-Fahrer',
    txt_global_std_p2pclosethispopup: 'Schließen Sie dieses Popup',
    txt_global_std_highestscore: 'Höchste Punktzahl',
    txt_global_std_totaldistance3: 'Gesamtstrecke',
    txt_global_std_pushdriveendlevel3: 'Gud kørsel. Tryk på mig for at se din feedback.',
    txt_global_std_viewmarketingmessages: 'Nachrichten in Aktivität anzeigen',
    txt_global_std_p2pfaq28q: 'Ich möchte einem Freund Administratorrechte hinzufügen',
    txt_global_std_logininformation: 'Anmeldeinformationen',
    txt_global_std_bonus: 'Bonus',
    txt_global_std_uploadyourlogoandaddyourcolorstomaketheappyourown: 'Laden Sie Ihr Logo hoch und fügen Sie Ihre Farben hinzu, um die App zu Ihrer eigenen zu machen.',
    txt_global_std_activepoints: 'Aktive Punkte',
    txt_global_std_addyourpolicynumbertobeabletoenjoyallthebenefitsandclaimyourrewards: 'Fügen Sie Ihre Versicherungsnummer hinzu, um alle Vorteile zu genießen und Ihre Prämien zu beanspruchen!',
    txt_global_std_rewards: 'Belohnung',
    txt_global_std_yourthreemostimportantdrivingtips: 'Ihre drei wichtigsten Fahrtipps',
    txt_global_std_KleinschmidtQuote: '',
    txt_global_std_avgdriver: 'Durchschn. Treiber',
    txt_global_std_top3bestdriverscomingsoon: 'Die Top 3 der besten Fahrer folgen in Kürze',
    txt_global_std_avgcosavings: 'Durchschn. CO₂-Einsparung',
    txt_global_std_p2pminimumbookingtime: 'Die Mindestbuchungszeit beträgt 30 Minuten',
    txt_global_std_p2pchoosecar: 'Wählen Sie Auto',
    txt_global_std_syncedwithgarminconnect: 'Synchronisiert mit Garmin Connect™',
    txt_global_std_entertheactivationcodeyouhavereceivedfromyourworkspaceadministrator: 'Geben Sie den Aktivierungscode ein, den Sie von Ihrem Workspace-Administrator erhalten haben',
    txt_global_std_send2: 'Senden',
    txt_global_EnerfyLeaderboardTemplate_keepituptext: 'Fahren Sie weiterhin gut und smart und sie werden bald einen weiteren Rang gewinnen',
    txt_global_std_removingaparticipantfromachallengedoesnotdeletetheuserortheirdrivingdataintheappiftheyhavealreadydriven: 'Das Entfernen eines Teilnehmers aus einer Challenge löscht nicht den Benutzer oder seine Fahrdaten in der App, wenn er bereits gefahren ist.',
    txt_global_std_readmore1: 'Weiterlesen',
    txt_global_std_carstats: 'Autostatistiken',
    txt_global_std_comingup: 'Kommt bald',
    txt_global_std_viewall: 'Alle ansehen',
    txt_global_std_showonmap: 'Auf der Karte anzeigen',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint3: 'Holen Sie mehr Wert aus Daten, um den ROI zu verbessern',
    txt_global_std_activationcodeemail2: 'Hier ist Ihr Aktivierungscode',
    txt_global_std_syncyourgarminconnectaccount: 'Synchronisieren Sie Ihr Garmin Connect™-Konto',
    txt_global_std_changepassword: 'Passwort ändern',
    txt_global_std_garmin15discountcodeforparticipantsandprizesformonthlywinners: 'Garmin 15 % Rabattcode für Teilnehmer und Preise für Monatsgewinner.',
    txt_global_std_sooncompleted: 'Soon completed!',
    txt_global_std_maycontactmeonphoneemailandsms: 'Ja, {appName} and Greater Than may contact me on phone , {appName} email , {appName} und SMS an and Greater Than may contact me on phone {appName} , um Neuigkeiten und Sonderangebote von Greater Than zu Versicherungen, Produkten zur Verhütung von Verletzungen, Konkurrenzprodukten und Testversuchen zu erhalten. Sie können Ihre Referenzen immer für das Marketing lesen und bevorzugen.',
    txt_global_std_skilluplockedkey: 'Um dieses Abzeichen zu erhalten, müssen Sie eine Woche lang eine Punktzahl über {score} halten.',
    txt_global_std_mypage: 'Meine Seite',
    txt_global_std_MSIG_InsuranceLetter15: 'Zu',
    txt_global_std_ChicheritMileage: 'Demnächst...',
    txt_global_std_insurancenumber2: 'Vers. nummer',
    txt_global_std_activitydetails: 'Aktivitätsdetails',
    txt_global_std_lockeddescription: 'Gesperrt Wenn Badge <br />noch nicht erhalten wurde',
    txt_global_std_TomczykQuote: 'Die FIA-Herausforderung des intelligenten Fahrens ermunterte mich, sparsamer, sicherer und aufmerksamer zu fahren. Nach der Fahrt können Sie in Echtzeit sehen, wie gut Sie sind und es mit anderen Fahrern auf der ganzen Welt vergleichen. Schließen Sie sich meinem Team an und lassen Sie uns gemeinsam die maximale Punktzahl erreichen. Fordern Sie sich selbst heraus und versuchen Sie, der beste intelligente Fahrer der Welt zu sein.',
    txt_global_std_level2: 'Level',
    txt_global_std_ChicheritBesttip: 'Demnächst...',
    txt_global_std_p2plendborrow: 'Leihen / Ausleihen',
    txt_global_std_p2pchoosecarcarstolend: 'Wählen Sie Auto / Autos zum Ausleihen',
    txt_global_std_newchallenge: 'Neue Herausforderung',
    txt_global_std_p2pfaq28a: 'Sie können Mitglieder auswählen und ihnen Administratorrechte für das Auto erteilen.\n' +
      '1. Wählen Sie „Mitglieder“ aus dem „Menü“.\n' +
      '2. Wählen Sie ein Mitglied aus, um Ihr Profil anzuzeigen.\n' +
      '3. Wählen Sie Einstellungen.\n' +
      '4. Betätigen Sie den Knopf des Autos, dem Sie die Erlaubnis erteilen möchten, die Erlaubnis zu erteilen.',
    txt_global_std_yourobdreaderhasalreadybeenorderedandyoucanexpectthereadertoarrivewithin35businessdays: 'Ihr OBD-Lesegerät wurde bereits bestellt und Sie können damit rechnen, dass das Lesegerät innerhalb von 3-5 Werktagen eintrifft.',
    txt_global_std_p2ppushtitleborrowcarnomessage: 'Neue Kreditanfrage erhalten',
    txt_global_std_regno2: 'Reg. No',
    txt_global_std_PaddonMusic: 'Beim Autofahren höre ich gerne entspannende / softe Musik wie Coldplay, Mumford und Sons usw.',
    txt_global_std_sdclevel: 'SDC Level:',
    txt_global_std_ifyoudonthavebluetoothinyourcaryoucanpluginaobdreaderanobdreadercanbepluggedintocarsmanufacturedyear2001andlater: 'Wenn Sie kein Bluetooth in Ihrem Auto haben, können Sie einen OBD-Leser anschließen.\n' +
      '\n' +
      'Ein OBD-Leser kann an Autos ab Baujahr 2001 angeschlossen werden.',
    txt_global_std_validateyourdata: 'Validieren Sie Ihre Daten',
    txt_global_std_kintoEncouragement2: 'Your driving is saving the planet! You save {percent}% against vs average.',
    txt_global_std_entercodehere: 'Code hier eingeben',
    txt_global_std_youbooked15timesinonemonthyouhaveearned3500points: 'Sie haben 15 Mal in einem Monat gebucht. Sie haben 3500 Punkte verdient.',
    txt_global_std_p2pgotomykinto: 'Geh zu meinem Kinto',
    txt_global_std_enteremailaddressyoucanentermorethanoneifyouseparatethembyacomma: 'E-Mail-Adresse eingeben, Sie können mehr als eine eingeben, wenn Sie sie durch ein Komma trennen.',
    txt_global_std_trytheapp: 'Probieren Sie die App aus',
    txt_global_std_firstdrivelocked: 'Sie schalten diesen Erfolg frei, wenn Sie das erste Mal fahren.',
    txt_global_std_onceaclaimsettlementisdeterminedyoucanreceiveacheckoranelectronicpaymentintoyourbankaccount: 'Sobald eine Schadensregulierung festgestellt wurde, können Sie einen Scheck oder eine elektronische Zahlung auf Ihr Bankkonto erhalten.',
    txt_global_std_disabled: 'Behindert',
    txt_global_std_youmustpickaninsurance: 'Sie müssen eine Versicherung abholen',
    txt_global_std_changenow: 'Jetzt ändern',
    txt_global_std_areyousureyouwanttoremoveuserfromthegroupremovingtheuserfromthegroupdoesnotdeletetheusersaccount: 'Möchten Sie den Benutzer wirklich aus der Gruppe entfernen?\n' +
      '\n' +
      'Durch das Entfernen des Benutzers aus der Gruppe wird das Benutzerkonto nicht gelöscht.',
    txt_global_std_earn2500pointsifyoubook10timesamonth: 'Sammeln Sie 2500 Punkte, wenn Sie 10 Mal im Monat buchen',
    txt_global_std_p2perrorcodestartofbookingisoverlapping: 'Der Beginn der Buchung überschneidet sich.',
    txt_global_std_createaccount: 'Anmelden',
    txt_global_std_activationcode2: 'Aktivierungscode',
    txt_global_std_thisisatranslation: 'Dies ist eine Übersetzung.',
    txt_global_std_p2pseefriendcode: 'Freundescodes anzeigen oder einlösen',
    txt_global_std_activatebluetoothinsettings: 'Aktivieren Sie Bluetooth in Ihrem Telefon',
    txt_global_std_challengeaverage2: 'Herausforderungsdurchschnitt:',
    txt_global_std_usermanagement: 'Gruppenmanagement',
    txt_global_std_publishyourdemoapp: 'Veröffentlichen Sie Ihre Demo-App',
    txt_global_std_dateoftraining: 'Datum der Ausbildung',
    txt_global_std_saveandcontinue: 'Speichern und weiter',
    txt_global_std_maxcharacters: 'max. 15 Zeichen',
    txt_global_std_locationofaccident: 'Unfallort',
    txt_global_std_leaderboardoverview: 'Leaderboard-Übersicht',
    txt_global_std_thechallengenamecantbelongerthan30characters: 'Der Herausforderungsname darf nicht länger als 30 Zeichen sein',
    txt_global_std_createnewachievement: 'Erstellen Sie einen neuen Erfolg',
    txt_global_std_matched: 'Abgestimmt',
    txt_global_std_pleasereportyouraccidentusingtheclaimsformassoonaspossible: 'Bitte melden Sie Ihren Unfall so schnell wie möglich über das Schadenformular.',
    txt_global_std_retardationdescription: '',
    txt_global_std_cropyourteamimage: 'Beschneiden Sie Ihr Teambild',
    txt_global_std_p2ppleaseselectadate: 'Bitte wählen Sie ein Datum',
    txt_global_std_examplephone: '12 34 56 78',
    txt_global_std_onboardingnotificationstext: 'Erlauben Sie {{appName}}, Ihnen Benachrichtigungen zu senden, wenn Sie Aktionen in der App ausführen müssen.',
    txt_global_std_verygooddriving: 'Sehr gut fahren!',
    txt_global_std_p2ppushbodychangeperiodfromto: '{name} geänderter Zeitraum. Von: {von} Bis: {bis}',
    txt_global_std_motorclaimshotline2: 'Hotline für Motoransprüche',
    txt_global_std_uploadphoto: 'Foto hochladen',
    txt_global_std_challengeleaderboard: 'Bestenliste herausfordern',
    txt_global_std_doyouhaveanymileagethatyoufancy: 'Haben Sie eine Strecke, die Sie am liebsten fahren möchten, warum diese?',
    txt_global_std_byinvitingfriends: 'Indem Sie Freunde einladen',
    txt_global_std_kintoImprovement1: 'Bitte fahre vorsichtig! Wir sorgen uns um dich!',
    txt_global_std_p2pyoublockedyourcar: 'Du hast dein Auto blockiert',
    txt_global_std_skillupunlockedkey: 'Tolle Arbeit {user}! Sie haben eine Woche lang eine Punktzahl über {score} gehalten.',
    txt_global_std_100000pts: '> 100 000 Pkt',
    txt_global_std_p2pforfamilyfriends: 'Für Familie & Freunde',
    txt_global_std_foryourowndocumentation: 'Für deine eigene Dokumentation :)',
    txt_global_std_OgierMileage: 'Kommt bald ',
    txt_global_std_jun: 'Jun',
    txt_global_std_TidemandFacts: '',
    txt_global_std_thisfeatureisnotavailableyet: 'Diese Funktion ist noch nicht verfügbar',
    txt_global_std_reason1: 'Grund',
    txt_global_std_createyourownchallengetoinspiresmartdrivingexpandtoseeapreviewofthechallenge: 'Erstellen Sie Ihre eigene Herausforderung, um intelligentes Fahren zu inspirieren! Erweitern, um eine Vorschau der Herausforderung anzuzeigen.',
    txt_global_std_friendfinder: 'Freundesfinder',
    txt_global_std_p2pfaq25q: 'Wo kann ich die Fahrdaten überprüfen?',
    txt_global_std_agreements: 'Vereinbarungen',
    txt_global_std_lexusweekend: 'Lexus Wochenende',
    txt_global_std_5000pointaverageachievementunlocked: 'Du bist sehr gut gefahren! Ihr durchschnittlicher {scoretype} was better than {score} eine Woche {scoretype} was better than {score} ! Sie haben 5000 Punkte verdient!',
    txt_global_std_carstatstext: 'Autostatistiken',
    txt_global_std_claims: 'Behauptungen',
    txt_global_std_sendinvitationby: 'Einladung senden von',
    txt_global_std_wewouldrecommenddrivertraining: 'Wir empfehlen ein Fahrertraining mit besonderem Augenmerk auf Fahrerfokus und -planung. Verlieren Sie also nie den Fokus auf den Verkehr und die Bedingungen um Sie herum. Wir sind Yamato und konzentrieren und planen das Fahren immer mit besonderem Augenmerk auf Freundlichkeit, Priorisierung, emotionale Selbstkontrolle und Vertrauen.',
    txt_global_std_tttest97: 'Das ist ein Test 97',
    txt_global_std_howtogetstarted: 'Wie man anfängt',
    txt_global_std_searchandmanageusers: 'Benutzer suchen und verwalten',
    txt_global_std_fixedpremium: 'Feste Prämie',
    txt_global_std_createanaccount: 'Ein Konto erstellen',
    txt_global_std_open: 'Öffnen ',
    txt_global_std_connectyourcarandstartadrive: 'Verbinden Sie Ihr Auto und starten Sie eine Fahrt, um Ihre geschätzten Einsparungen zu sehen. Drücken Sie die Antriebstaste, um zu beginnen.',
    txt_global_std_whatifichangecaranswer: 'Informieren Sie das {appname} -Team per E-Mail. Stellen Sie dann erneut eine Verbindung zu Ihrem neuen Auto her, indem Sie die Schritte unter „Mit Auto verbinden“ erneut ausführen.',
    txt_global_std_greaterthansprivacypolicy: 'Die Datenschutzrichtlinie von Greater Than.',
    txt_global_std_followupontheonboardingstatusofyourusersclickonastatusbarbelowtoseeandhelpyourusersthatmightbestuck: 'Verfolgen Sie den Onboarding-Status Ihrer Benutzer, klicken Sie unten auf eine Statusleiste, um Ihre Benutzer zu sehen und ihnen zu helfen, die möglicherweise feststecken.',
    txt_global_std_p2pwelcomekintoowner: 'Willkommen, Kinto-Besitzer!',
    txt_global_std_p2ppleasetypeprice: 'Bitte Preis eingeben',
    txt_global_std_unpublishtranslations: 'Veröffentlichung von Übersetzungen rückgängig machen',
    txt_global_std_p2pendtime: 'Endzeit:',
    txt_global_std_clickheretocallsupport: 'Klicken Sie {hier}, um den Support anzurufen',
    txt_global_std_50km: '50 Km',
    txt_global_std_loyalty_upsell_pitch_bulletpoint3: 'Erhöhen Sie die Sichtbarkeit Ihres Programms, um den bestmöglichen ROI zu erzielen',
    txt_global_std_kintoEncouragement1: 'Beeindruckend! Sie gehören zu den besten Kinto-Fahrern!',
    txt_global_std_publishapptest: 'App-Test veröffentlichen',
    txt_global_std_startadrive_: 'Starten Sie eine Fahrt',
    txt_global_std_300pointTripAchievementunlocked: '{scoretype} {score} mit {scoretype} {score} oder besser {scoretype} {score} ! Genial! Sie haben 300 Punkte verdient!',
    txt_global_std_p2pemailunreadmessages: 'Ungelesene Nachrichten',
    txt_global_std_ecofriendlockedkey: 'Fahren Sie umweltfreundlich und sparen Sie in einer Woche mehr als {co2value} % CO₂ ein, um dieses Abzeichen zu erhalten.',
    txt_global_std_p2ppushtitlenewinvoicetopay: 'New invoice to pay',
    txt_global_std_rateapp: 'App bewerten',
    txt_global_std_reportcentersworkshops: 'Berichtszentren & Workshops',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint1: 'Ermutigen Sie die Fahrer, durch Gamification neue Fähigkeiten aufzubauen',
    txt_global_std_invitelink: 'Link einladen',
    txt_global_std_cimage8d: 'Beschleunigen Sie leicht, wenn der Verkehr oder die Straße Ihre Geschwindigkeit stark verändern.',
    txt_global_std_loyalty_upsell_pitch_bulletpoint2: 'Belohnen Sie intelligente Fahrer, um Engagement und Bindung zu fördern',
    txt_global_std_p2plendmycartwo: 'Leih mein Auto',
    txt_global_std_HusseinAbdullaBesttip: 'Geduld ',
    txt_global_std_youwillcomebacktothesesettingsthenexttimeyoupressthedrivebutton: 'Beim nächsten Drücken der Fahrtaste kehren Sie zu diesen Einstellungen zurück.',
    txt_global_std_advancedadmin_upsell: 'Advanced Admin – einfaches Selbstverwaltungsportal für eine leistungsstarke Benutzererfahrung.',
    txt_global_std_selecttime: 'Zeit auswählen',
    txt_global_std_deletingthegroupdoesnotdeletetheusersaccounts: 'Durch das Löschen der Gruppe werden die Benutzerkonten nicht gelöscht.',
    txt_global_std_addyourmileage: 'Fügen Sie Ihren Kilometerstand hinzu',
    txt_global_std_totalnooftrips: 'Gesamtzahl der Fahrten',
    txt_global_std_thatwasaverygooddrive: 'Das war eine sehr gute Fahrt!',
    txt_global_std_weeklyheat: 'Wöchentliche Hitze',
    txt_global_std_enerfyanalytics_upsell: 'Enerfy Analytics – benutzerfreundliches Datenanalysetool für leistungsstarke Berichte.',
    txt_global_std_mediumrisk: 'Mittleres Risiko',
    txt_global_std_ConfirmFia_Text12: 'Stellen Sie sicher,  dass Sie 10 Minuten vor dem vereinbarten Zeitpunkt am Veranstaltungsort anzukommen. Unsere Mitarbeiter helfen Ihnen bei der Anmeldung und bei der Suche nach Ihrem Auto.',
    txt_global_std_finishadriveatscoretypescoreorbettertounlockthisbadge: 'Beende ein Laufwerk bei {scoretype} {score} oder besser, um dieses Abzeichen freizuschalten.',
    txt_global_std_pleaseenterthenameofyourcontactperson: 'Bitte geben Sie den Namen Ihres Ansprechpartners ein',
    txt_global_std_sendinvitetocountfriends: 'Einladung an Freunde senden?',
    txt_global_std_insurancedetails: 'Angaben zur Versicherung',
    txt_global_std_kintorefer50friendslocked: 'Schalten Sie diesen Erfolg frei und verdienen Sie ¥ 5000, indem Sie 50 Kinto-Benutzer empfehlen.',
    txt_global_std_userhasnopermissions: 'Benutzer hat keine Berechtigungen',
    txt_global_std_v2level: 'Niveau',
    txt_global_std_soonyouwillbeabletochallengeotherdriversinsafeandecofriendlydriving: 'Bald können Sie andere Fahrer im sicheren und umweltfreundlichen Fahren herausfordern.',
    txt_global_std_civilstatus: 'Zivilstand',
    txt_global_std_qhowdoisignupforcovercubeinsurance: 'F: Wie melde ich mich für die Covercube-Versicherung an?',
    txt_global_std_ifyouhaveone: 'wenn Sie eine/n haben',
    txt_global_std_drivingstatisticstabs: 'Registerkarten für Fahrstatistiken',
    txt_global_std_signinsurance: 'Versicherung unterschreiben',
    txt_global_std_p2pcheckyouremail: 'Überprüfen Sie Ihren Posteingang',
    txt_global_std_whatisyourbesttiptodrivesmart: 'Was ist Ihr bester Tipp, um intelligent zu fahren?',
    txt_global_std_call: 'Anruf',
    txt_global_std_test: 'Prüfung',
    txt_global_std_wanttoshareyourprogresswithyourfriends: 'Möchten Sie Ihren Fortschritt mit Ihren Freunden teilen?',
    txt_global_std_yourcar2: 'Dein Auto',
    txt_global_std_fitmarkers: 'Markierungen anbringen',
    txt_global_std_autostartobd: 'Automatischer Start von OBD',
    txt_global_std_theindividualdriverscoreiscalculatedonanaveragebasedonthetop10trips: '• Die individuelle Fahrerwertung wird als Durchschnitt auf Basis der besten 10 % der Fahrten berechnet.',
    txt_global_std_excellent: 'Ausgezeichnet',
    txt_global_std_email: 'E-Mail',
    txt_global_std_sdcrules: 'SDC Regeln',
    txt_global_std_ridewithmetext: 'Eine Fahrt mit einem Freund zu teilen, anstatt zwei Autos zu benutzen, spart viel CO2! Durch die Registrierung einer Mitfahrgelegenheit sammeln Sie und Ihr Freund Punkte.',
    txt_global_std_howwillyouknowaboutmydrivingbehaviour: 'How will you know about my driving behavior?',
    txt_global_std_howdoicompeteinlexuschallengeanswer: 'Sie nehmen automatisch teil, wenn Sie sich anmelden. Keine Sorge, in der Rangliste wird nur Ihr Spitzname angezeigt, sodass Sie immer anonym bleiben. Es gibt einen monatlichen und jährlichen Wettbewerb.',
    txt_global_std_enerfyisaproductfromgreaterthanab: 'Enerfy ist ein Produkt von Greater Than AB.',
    txt_global_std_ecofeedbacktips: '<b> Ihr Öko-Score misst, wie umweltfreundlich Sie fahren. </b> In vielerlei Hinsicht kann er mit Ihrer Planung in Verbindung gebracht werden. <br> <br>\n' +
      '<b> Passen Sie Ihre Beschleunigungen an </b> an, um unnötige Bremsen und Stopps zu vermeiden. Jede Beschleunigung und Bremse verbraucht Energie. <br> <br>\n' +
      'Gemeinsam können wir <b> einen Unterschied </b> für die Zukunft machen.',
    txt_global_std_therulesforthecompanychallengeareasfollowing: 'Die Regeln für die Firmen-Challenge lauten wie folgt:',
    txt_global_std_hhmmss: 'HH:MM:SS',
    txt_global_std_iwantrematch: 'Ich möchte einen Rückkampf!',
    txt_global_std_httpstatuscodesummary: 'Zusammenfassung des HTTP-Statuscodes',
    txt_global_std_errors: 'Fehler',
    txt_global_std_availablepointexchange: 'Verfügbarer Punktetausch',
    txt_global_std_p2punavailable: 'Nicht verfügbar',
    txt_global_std_daysleftuntilrenewal: 'Noch Tage bis zur Verlängerung',
    txt_global_std_trainername: 'Trainer Name',
    txt_global_std_required: 'Erforderlich',
    txt_global_std_ifyouwantinfoonnotificationscallphonenumberorgotoanchorlinkorgotorouterlinkpage: 'Wenn Sie Informationen zu Benachrichtigungen wünschen, rufen Sie {phoneNumber} an oder rufen Sie die Seite {anchorLink} oder {routerLink} auf.',
    txt_global_std_p2pyouborrowedusersvehiclextimes: 'Du hast {user} {car} {times} Mal ausgeliehen',
    txt_global_std_topthreeworldteams: 'Top 3 Weltteams',
    txt_global_std_reporting: 'Berichterstattung',
    txt_global_std_attributes: 'Attribute',
    txt_global_std_reportdamage: 'Schaden melden',
    txt_global_std_welcomebackenerfy: 'Willkommen zurück bei {appName}',
    txt_global_std_p2pfinishandpay: 'Beenden und bezahlen',
    txt_global_std_p2pcash: 'Kasse',
    txt_global_std_kintofaq7q: 'Mein Auto hat kein Bluetooth?',
    txt_global_std_maintainalowrisklevelbykeepingyourfocusontheroadandplanahead: 'Behalten Sie ein niedriges Risikoniveau bei, indem Sie sich auf die Straße konzentrieren und vorausplanen.',
    txt_global_std__emailaddress: 'E-Mail-Addresse',
    'txt_global_std_Its time for some fun. Join a challenge!': 'Es ist Zeit für etwas Spaß. Nehmen Sie an einer Herausforderung teil!',
    txt_global_std_fuelinjectorcleaner: 'Reiniger für Einspritzventil',
    txt_global_std_BeforeFirstBill_Text6: 'Mit freundlichen Grüßen {appName}',
    txt_global_std_enteryouremailadress: 'Geben Sie Ihre E-Mailadresse ein',
    txt_global_std_pleasenotethattojoinyoumusthavetheaanzappinstalledonyourmobiledeviceinordertobeabletojointhegroup: 'Bitte beachten Sie, dass Sie die FIA SDC-App auf Ihrem Mobilgerät installiert haben müssen, um dem Team beitreten zu können.',
    txt_global_std_avgkm7d: 'Durchschnitt km 7D',
    txt_global_std_finished: 'Fertig',
    txt_global_std_returntoooverview: 'Zurück zur Übersicht',
    txt_global_std_turnonbluetoothviayourcarsinfotainmentsystemstereoandfollowtheinstructionsforpairingyourphone: 'Schalten Sie Bluetooth über das Infotainmentsystem/Stereo Ihres Autos ein und befolgen Sie die Anweisungen zum Koppeln Ihres Telefons.',
    txt_global_std_howdoiearnsavingslexusanswer: 'Einsparungen werden generiert, wenn Sie sicher fahren. Für jede Fahrt, die Sie sicher fahren, erzielen Sie eine Einsparung pro Kilometer. Je sicherer Sie fahren, desto höher ist die Ersparnis! Die Speicherung ist direkt nach Beendigung Ihrer Reise sichtbar und wird in Ihrer Lexus-Brieftasche angezeigt.',
    txt_global_std_finishedtraining: 'Abgeschlossene Ausbildung',
    txt_global_std_savechangespopupheader: 'Änderungen speichern?',
    txt_global_std_repeatyournewpassword: 'Wiederholen Sie Ihr neues Passwort',
    txt_global_std_prefernottosay: 'Mache lieber keine Angabe',
    txt_global_std_coverimage: 'Titelbild:',
    txt_global_std_pleasefocus: 'Bitte konzentrieren Sie sich!',
    txt_global_std_communication_upsell_pitch_bulletpoint1: 'Entwerfen Sie ganz einfach Ihren eigenen Messaging- und Kommunikationsplan, um;',
    txt_global_std_secretuser: 'Geheimnis',
    txt_global_std_redeemcoupon: 'Gutschein einlösen',
    txt_global_std_createanaccounttosavealldrivingdata: 'Erstellen Sie ein Konto, um alle Fahrdaten zu speichern, Ihre Routen zu verfolgen und Ihre Prämien für zukünftige Reisen zu speichern.',
    txt_global_std_needhelp: 'Brauchen Sie Hilfe?',
    txt_global_std_usernameshort: 'Benutzername',
    txt_global_std_zipriskindexhelpsyoudeterminepreciserisklevelbasedondrivinglocation: 'Der Zip-Risikoindex hilft Ihnen, das genaue Risikoniveau basierend auf dem Fahrort zu bestimmen.',
    txt_global_std_setupyourfiasdccompanychallenge: 'Richten Sie Ihre FIA SDC Company Challenge ein.',
    txt_global_std_mycardoesnthavebluetooth: 'Mein Auto hat kein Bluetooth?',
    txt_global_std_firstscore: 'Lassen Sie uns der erste sein, der Punkte in der Hitze erzielt',
    txt_global_std_p2pallofyourtripsgatheredinoneplacegetaccesstofeedbackandstatisticsaboutyourdrives: 'Alle Ihre Reisen versammelten sich an einem Ort. Erhalten Sie Zugriff auf Feedback und Statistiken zu Ihren Laufwerken.',
    txt_global_std_yousaved: 'Sie haben gespeichert ',
    txt_global_std_PolicySchedule6: 'bis Mitternacht',
    txt_global_std_youneedtoinputanemail: 'Sie müssen eine E-Mail eingeben',
    txt_global_std_noregnumberregistered: 'Keine Registernummer registriert',
    txt_global_std_socialsecuritynumber: 'Sozialversicherungsnummer',
    txt_global_std_skeyscore: 'Ergebnis',
    txt_global_std_participants: 'Teilnehmer',
    txt_global_std_qhowdoisignupforcompanyinsurance: 'F: Wie melde ich mich für eine Versicherung von [Unternehmen] an?',
    txt_global_std_yourpasswordmustconsistofatleastfive: 'Ihr Passwort muss aus mindestens fünf (5) Zeichen bestehen.',
    txt_global_std_p2perrorcodeendofbookingisoverlapping: 'Das Ende der Buchung überschneidet sich',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint1: 'Inspirieren Sie zu intelligenterem Fahren und tragen Sie dazu bei, Risiken, Kraftstoffverbrauch und CO2-Emissionen zu reduzieren',
    txt_global_std_youcanfindyourresultshortlyinyourtrips: 'Ihr Ergebnis finden Sie in Kürze auf Ihren Reisen.',
    txt_global_std_p2pbookingfinished: 'Buchung beendet',
    txt_global_std_alltabtext: 'Alle',
    txt_global_std_buynow: 'Kaufen Sie jetzt',
    txt_global_std_myleague: 'Meine Liga',
    txt_global_std_startdrivingtoappearonleaderboard: 'Beginnen Sie mit dem Fahren auf die Rangliste!',
    txt_global_std_toprovideyouwithaccuratestatsanddrivingscoreappnameneedstoruninbackgroundmodebrinthenextpopupbrchooseballowallthetimeb: 'Um Ihnen genaue Statistiken und Fahrergebnisse zu liefern, muss {appname} im Hintergrundmodus ausgeführt werden. <br> Im nächsten Popup: <br> Wählen Sie: <b> Die ganze Zeit zulassen </b>',
    txt_global_std_aninsuranceadjusterwillcontactyouwithinhourshoursofsubmittingyourclaimtheentireclaimsprocesswilldetermineonthecomplexityoftheclaim: 'Ein Versicherungssachverständiger wird sich innerhalb von {hours} Stunden nach Einreichung Ihres Antrags mit Ihnen in Verbindung setzen. Der gesamte Anspruchsprozess hängt von der Komplexität des Anspruchs ab.',
    txt_global_std_recruiter: 'Personalvermittler',
    txt_global_std_forthisapptoworklocationservices: `For this app to work properly and to register your trips, we want you to change the permission of location services to "Always". If you don't do it know, you will get this popup later on.`,
    txt_global_std_welcomeletsgetstarted: 'Willkommen! Lass uns anfangen!',
    txt_global_std_uploadgroupimage: 'Gruppenbild hochladen',
    txt_global_std_married: 'Verheiratet',
    txt_global_std_PolicySchedule21: 'OFD',
    txt_global_std_inallourappswehavecontactinformationsothatuserscancontactthecompanyfillinyourcompanyscontactinformationhere: 'In allen unseren Apps haben wir Kontaktinformationen, damit Benutzer das Unternehmen kontaktieren können. Geben Sie hier die Kontaktdaten Ihres Unternehmens ein.',
    txt_global_std_VilliersFacts: 'Giniel De Villiers has never retired. Even better, only once has he not finished in the Top 10 (in 2007). He has 14 stage victories and in 2009 he won the first Dakar contest in South America.',
    txt_global_std_back: 'zurück',
    txt_global_std_advancedadmin1: '  Erweiterter Administrator',
    txt_global_std_pleasegivethreeoptionsincaseyourfirstoptionsisalreadyused: 'Bitte geben Sie drei Optionen an, falls Ihre erste(n) Option(en) bereits verwendet wird/werden.',
    txt_global_std_yourpasswordmustconsistofatleastten: 'Ihr Passwort muss aus mindestens zehn (10) Zeichen bestehen.',
    txt_global_std_completed: 'Vollendet, fertiggestellt',
    txt_global_std_documentsentto: 'Dokument gesendet an',
    txt_global_std_estimatedsavings: 'Geschätzte Einsparungen',
    txt_global_std_maybesavedwhenfocusingonthisspeed: 'kann gespeichert werden, wenn Sie sich auf die Verbesserung dieser Geschwindigkeit konzentrieren',
    txt_global_std_step1: 'Schritt 1',
    txt_global_std_p2pdecreasetextsize: 'Textgröße verringern',
    txt_global_std_recruiterlockedkey: 'Lade einen Freund in die App ein, um dieses Abzeichen zu verdienen. Sie erhalten dieses Abzeichen, wenn Ihr Freund ein Konto in der App erstellt.',
    txt_global_std_translationtool: 'Übersetzungstool',
    txt_global_std_insurancecompanydescription: 'Für präzise Preisgestaltung und Underwriting-Exzellenz.',
    txt_global_std_terminate: 'Beenden',
    txt_global_std_cimage4d: 'Überfahren einer roten Ampel. ',
    txt_global_std_showofflinedrives: 'Offline-Laufwerke anzeigen',
    txt_global_std_enerfydynamiccollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: 'Enerfy Dynamic sammelt Standortdaten, um Fahrpunkte zu ermöglichen, selbst wenn die App geschlossen oder nicht verwendet wird.',
    txt_global_std_cantseeyourcar: 'Ich kann dein Auto nicht sehen'
  },
  es: {
    txt_global_std_searchchallenges: 'Buscar desafíos',
    txt_global_std_yourcarisntturnedon: '• Su automóvil no está encendido.',
    txt_global_std_100pointtripachievementunlocked: '¡Condujiste a {scoretype} {score} o mejor! ¡Excelente! ¡Has ganado 100 puntos!',
    txt_global_std_upcomingtraining: 'Próximos entrenamientos',
    txt_global_std_yourmileagewillbesavedintheobddevice: 'Su millaje se guardará en el almacenamiento de memoria integrado del dispositivo OBD y se cargará en el nivel premium 10 cuando complete su próximo viaje conectado.',
    txt_global_std_estimatedprice: 'Precio estimado',
    txt_global_std_exportdrives: 'Exportar unidades',
    txt_global_std_usersoverview: 'Resumen de usuarios',
    txt_global_std_youhaveexceededthedrivingmaxtimeof: 'Ha superado el tiempo máximo de conducción de {breakTime}.',
    txt_global_std_PolicySchedule3: 'Nota de portada/Ref. No.',
    txt_global_std_mustbeavalidsizenumbersand: 'Debe ser un tamaño válido, números y +',
    txt_global_std_KleinschmidtChamp: 'Campeón Rally Dakar 2001',
    txt_global_std_reasonterminatedinsurance: 'Motivo de la rescisión del seguro',
    txt_global_std_automatic: 'Automático',
    txt_global_std_tryoutappname: 'Prueba {appName}',
    txt_global_std_allowingaccesstobluetoothisnecessaryforthisapptoconnecttoyourcarandmeasureyourdriving: 'Es necesario permitir el acceso a bluetooth para que esta aplicación se conecte a su automóvil y mida su conducción.',
    txt_global_std_accidentandclaims: 'Accidentes y reclamaciones',
    txt_global_std_buyinsurance: 'comprar seguro',
    txt_global_std_verifycode: 'Código de verificación',
    txt_global_std_ifyourcardoesnotshowupinthelistyoumightneedtogotobluetoothsettingsforbothyourphoneandyourcar: 'Si su automóvil no aparece en la lista, es posible que deba ir a la configuración de Bluetooth tanto para su teléfono como para su automóvil.',
    txt_global_std_p2pcancelledph: '{contar} cancelado',
    txt_global_std_thesepermissionsarenecessaryfortheapptobeabletoregisteryourdrivescorrectlyletsgo: 'Estos permisos son necesarios para que la aplicación pueda registrar sus unidades correctamente. Valoramos su privacidad y no compartiremos esta información con una entidad de terceros. ¡Vamos!',
    txt_global_std_destination: 'Destino',
    txt_global_std_willbesenttotheemailregisteredintheonboarding: ' Se enviará al correo electrónico registrado en el onboarding',
    txt_global_std_findyourobdportinyourcar: 'Encuentra tu puerto OBD en tu coche',
    txt_global_std_yournewappishere: '¡Tu nueva aplicación está aquí!',
    txt_global_std_workspacepermissions: 'Permisos de espacio de trabajo',
    txt_global_std_welcometofiatciaodrive1: 'Bienvenido a FIAT CIAO DRIVE',
    'txt_global_std_Al-KhelaifiInterests': 'Autos, Fotografía, Automovilismo',
    txt_global_std_rpmspeednotfluctating: 'RPM/velocidad sin fluctuaciones',
    txt_global_std_youneedtofinishsettingupyourprofilebeforejoining: 'Debe terminar de configurar su perfil antes de unirse a un evento.',
    txt_global_std_enteryourpolicynumber: 'Ingrese su número de póliza',
    txt_global_std_addtogroups: 'Agregar a grupos',
    txt_global_std_allteamleaders: 'todos los líderes de equipo',
    txt_global_std_jpfaqtitle: 'Dígame cómo conectar la aplicación al automóvil y registrar los datos de conducción.',
    txt_global_std_apitool_upsell_pitch_bulletpoint1: 'Incorporación digital integral para una integración perfecta',
    txt_global_std_createanaccountforname: 'Crea una cuenta para {appName}',
    txt_global_std_cimage2h: 'MANTÉN DISTANCIA',
    txt_global_std_trygtakemetoapp: 'Saltar y comenzar con Tryg Drive',
    txt_global_std_canisignupforinsurancetobegintoday: '¿Puedo inscribirme para que el seguro comience hoy?',
    txt_global_std_p2psubmit: 'Enviar',
    txt_global_std_timesinceparked: 'Tiempo desde estacionado',
    txt_global_std_testarenandra: 'Kiểm tra một giây',
    txt_global_std_yourinsurancehasbeenrenewedformonth: 'Su seguro ha sido renovado por {mes}.',
    txt_global_std_restrictkey: 'Restringir clave',
    txt_global_std_p2pyouhavechosen: 'Has elegido:',
    txt_global_std_5streak: '¡5 rachas!',
    txt_global_std_p2pfaq13q: '¿Qué pasa si devuelvo el auto antes de tiempo?',
    txt_global_std_thepasswordminimumislength8charactersandmustinclude1lowercaseuppercasenumeralandspecialcharacter: 'El mínimo de la contraseña es de 8 caracteres y debe incluir 1 minúscula, mayúscula, número y carácter especial',
    txt_global_std_p2pconfirmedbookings: 'Reservas confirmadas',
    txt_global_std_resetyourenerfyglobalpassword: 'Restablece tu contraseña de Enerfy Global',
    txt_global_std_welcomebackenerfyfirstname: '¡Bienvenido de nuevo a {appName}, {firstName}!',
    txt_global_std_westzone: 'Zona Oeste',
    txt_global_std_monitoringyourdrivinghasneverbeeneasier: 'Supervisar su conducción nunca ha sido tan fácil.',
    txt_global_std_apis: 'API',
    txt_global_std_companyaverage: 'Promedio de la empresa',
    txt_global_std_f_lanzchamp: 'Campeona KZ2 SA 2020, vicecampeona KZ2 SA 2019, campeona femenina Rok Final Shifter 2018 y general P16\n',
    txt_global_std_p2pcostsplits: 'división de costos',
    txt_global_std_placethewarningtriangle: 'Coloque el triángulo de advertencia en un lugar visible al menos a 100 metros frente al automóvil.',
    txt_global_std_drivesettingsnotfinished: 'La configuración de la unidad no ha terminado',
    txt_global_std_FiaPassword_Text2: 'Ha solicitado una nueva contraseña para su cuenta FIA Smart Driving Challenge. Su nueva contraseña para el usuario',
    txt_global_std_allowaccesstomotionfitness: 'Permitir el acceso a Motion ＆ Fitness',
    txt_global_std_howdoesitwork: '¿Como funciona?',
    txt_global_std_60days: '60 días',
    txt_global_std_myprojects: 'Mis proyectos',
    txt_global_std_selectatemplate: 'Seleccione una plantilla',
    txt_global_std_sdcrapply2019price3: 'Cheques de gasolina de 300 kr',
    txt_global_std_birthday: 'Cumpleaños',
    txt_global_std_thisactivationcodeisinvalid: 'Este código de activación no es válido',
    txt_global_std_insurancehasbeenactivatedforregno: 'El seguro ha sido activado para el vehículo {regNo}.',
    txt_global_std_nextlevel: 'siguiente nivel',
    txt_global_std_claimshandling: 'Manejo de reclamos',
    txt_global_std_insuranceperiod: 'Período de seguro',
    txt_global_std_cantfinduserinhead2head: '¿No encuentras a tu usuario? Puedes invitar a un amigo en ReferFriend.',
    txt_global_std_activationcodeemail3: '¡Puedes activarlo en la aplicación!',
    txt_global_std_actualtimegain: 'Ganancia de tiempo real',
    txt_global_std_anodometerorodographisaninstrumentusedformeasuringthedistancetravelledbyavehicleitcanbefoundinthecarsdashboard: 'Un cuentakilómetros u odógrafo es un instrumento que se utiliza para medir la distancia recorrida por un vehículo. Se puede encontrar en el salpicadero del coche.',
    txt_global_std_p2pfriendscalendars: 'Amigos con calendarios de autos',
    txt_global_std_p2pfaq16a: 'Puede utilizar el Seguro Kinto. Sin embargo, notifique a la persona a cargo y discuta entre ellos cómo resolverlo.',
    txt_global_std_areyoudrivinganelectricorobd: '¿Conduce un vehículo eléctrico o tiene dispositivos instalados en el vehículo?',
    txt_global_std_nodrivingdata: 'Sin datos de conducción',
    txt_global_std_HusseinAbdullaFacts: 'Adel ha ganado el Campeonato del Mundo FIA CCR T2 con Sebastian Delaunay como copiloto. En su primera temporada completa de competición a campo traviesa, Adel Hussein obtuvo victorias en Portugal, Abu Dhabi y España durante una exitosa campaña en el campeonato todoterreno más duro del mundo.',
    txt_global_std_gotoleaderboard: 'Ir a la tabla de posiciones',
    txt_global_std_inordertoconnecttoyourcartheincludedobd: 'Para conectarse a su automóvil, el dispositivo OBD incluido, el complemento Enerfy, debe estar instalado en su vehículo o, si su vehículo tiene la opción de conectar su teléfono a través de Bluetooth directamente, la aplicación Enerfy debe estar ejecutándose. su teléfono mientras conduce.',
    txt_global_std_p2pfaq20a: '1. Seleccione "Conducir" en el "Menú" para comprobar los datos y la evaluación de conducción más recientes.\n' +
      '2. Seleccione datos de conducción específicos para ver los detalles de los comentarios.',
    txt_global_std_p2pcancelled: 'Cancelado',
    txt_global_std_togetthecorrectstatsofyourdrivingyouneedtogototheappsettingsinyourphoneand: 'Para obtener las estadísticas correctas de su conducción, debe ir a la configuración de la aplicación en su teléfono y elegir Permitir siempre en los servicios de ubicación.',
    txt_global_std_minimum5characters: 'Mínimo 5 caracteres',
    txt_global_std_noinsurancesfound: 'No se encontraron seguros',
    txt_global_std_letstrythatagain: '¡Intentémoslo de nuevo!',
    txt_global_std_apr: 'Abr',
    txt_global_std_HSolbergMusic: 'Música pop y rock que da espíritu entusiasta en mi sangre.',
    txt_global_std_youhaveatotalcostof: 'Tienes un costo total de',
    txt_global_std_challengedetails: 'Detalles del desafío:',
    txt_global_std_driversname: 'Nombre del conductor',
    txt_global_std_yourinsurancehasbeenrenewed: 'Su seguro ha sido renovado.',
    txt_global_std_tomasengemileage: 'Siempre amo todos los caminos que conducen a mi hogar, a mi familia.',
    txt_global_std_p2pnamecancelledname2sbooking: '{name} canceló la reserva de {name2}',
    txt_global_std_p2pviewandpay: 'Ver y pagar',
    txt_global_std_now: 'Ahora',
    txt_global_std_telephoneclaims: 'Reclamos Telefónicos',
    txt_global_std_emailalreadyusedpleasetrywithanotheremail: 'Correo electronico ya fue usado. Intente con otro correo electrónico.',
    txt_global_std_newtext: 'Nuevo texto',
    'txt_global_std_Al-KuwariBesttip': 'Concentración e intentar disfrutar del trayecto.',
    txt_global_std_carmodelform: 'Modelo de auto:',
    txt_global_std_p2pemailhello: 'Hola',
    txt_global_std_failedtoaddusers: 'Error al agregar usuarios',
    txt_global_std_sendreferrallink: 'Enviar enlace de referencia',
    txt_global_std_hoponinletsdrivesmart: 'Súbete, conduzcamos inteligentemente',
    txt_global_std_feedback: 'Retroalimentación',
    txt_global_std_JyrkiainenChamp: 'Mejor piloto novato en el Campeonato de Finlandia 2016',
    txt_global_std_hellogreattoseeyou: '¡Hola, qué bueno verte!',
    txt_global_std_p2pmembersinthisgroup: 'Miembros en este grupo',
    txt_global_std_couldnotjoinevent: 'No se pudo unir al evento.',
    txt_global_std_cantfindyourcaryet: '¡Aún no puedes encontrar tu coche!',
    txt_global_std_allusershavesuccessfullycompletedthisstep: 'Todos los usuarios han completado con éxito este paso.',
    txt_global_std_forgotyourpassword1: '¿Olvidaste tu contraseña?',
    txt_global_std_paddonDescription: 'Hayden comenzó a conducir go-karts a la edad de 6 años. Vivió y respiró deportes de motor toda su vida.',
    txt_global_std_security: 'Seguridad',
    txt_global_std_welcometoapp: '¡Bienvenido a {aplicación}!',
    txt_global_std_adjustyouryearlymileage: 'Ajuste su kilometraje anual',
    txt_global_std_easilykeeptrackofyourcostandco2emissionsintheapp: 'Realice fácilmente un seguimiento de sus costos y emisiones de CO2 en la aplicación.',
    txt_global_std_p2ppleasechooseexpensetype: 'Elija el tipo de gasto',
    txt_global_std_electriccar: '¿Coche eléctrico?',
    txt_global_std_someoneelseispairedtoyourcar: '• Alguien más está emparejado con su automóvil.',
    txt_global_std_moreattributes: 'Más atributos',
    txt_global_std_disableachievementtooltiptext1: 'Este logro se creó sin la Herramienta de logros. <br> Por lo tanto, actualmente no se puede modificar en esta herramienta.',
    txt_global_std_atheappwilltrackyourscoresandprovidearatebasedonyourdrivinghabitsyouwillgetthefirstmonthlyratethatisbasedonyourdrivingafteryourfirstsixmonthperiodyourratewillthenchangeonamonthlybasisbasedonyourscoreanddrivinghabitsyouwillreceiveupdatesaftereachtripadvisingyouofyourpotentialsavingsorifyouneedtoimproveyourdrivingscoretoearnadiscount: 'R: La aplicación hará un seguimiento de sus puntuaciones y le proporcionará una tarifa basada en sus hábitos de conducción. Obtendrá la primera tarifa mensual que se basa en su conducción después de su primer período de seis meses. Luego, su tarifa cambiará mensualmente según su puntaje y sus hábitos de manejo. Recibirá actualizaciones después de cada viaje que le informarán sobre sus posibles ahorros o si necesita mejorar su puntaje de conducción para obtener un descuento.',
    txt_global_std_notsupported: 'No soportado',
    txt_global_std_inviteafriendorfamilytoborrow: 'Invite a un amigo o familiar a pedir prestado su automóvil {appname}',
    txt_global_std_400badrequest: '400 Petición Incorrecta',
    txt_global_std_TaxInvoice4: 'Moneda/Tipo de cambio',
    txt_global_std_someoneelseispairedtoyourcaryourbluetoothbeacon: '• Alguien más está emparejado con su automóvil/su baliza bluetooth\n',
    txt_global_std_f_lanzinterests: 'Escalada de aventura, rafting, tirolesa, carreras de montaña, baile, explorador de la naturaleza salvaje',
    txt_global_std_password2: 'Clave',
    txt_global_std_v2sdcscore: 'Puntaje',
    txt_global_std_trytheappfunctionisnotavailableindemomode: 'La función "Prueba la aplicación" no está disponible en el modo de demostración',
    txt_global_std_leaderboard_tab_daily: 'Diariamente',
    txt_global_std_days: 'Días',
    txt_global_std_youcanuploadmaximum9photos: 'Puedes subir máximo 9 fotos',
    txt_global_std_connectwithobdreader: 'Conectar con lector OBD',
    txt_global_std_ongoinghead2headchallenges: 'Desafíos continuos Head 2 Head',
    txt_global_std_easytogetstartedrequestyourzipriskindexviaourapi: 'Fácil de comenzar, solicite su índice de riesgo Zip a través de nuestra API.',
    txt_global_std_idnumber: 'Número de identificación',
    txt_global_std_avglevel: 'Promedio nivel',
    txt_global_CoreTemplate_enter: 'Ingresar',
    txt_global_std_startsin: 'Empieza en',
    txt_global_std_retrive: 'Recuperar',
    txt_global_std_ok: 'OK',
    txt_global_std_PaddonChamp: 'Campeón de PWRC en 2011, 4 victorias en el Campeonato de Rally de Nueva Zelanda (2008, 2009, 2013, 2018). Ganó el Rally de Argentina en 2016.',
    txt_global_std_questionsaboutinsurance: 'Preguntas sobre el seguro',
    txt_global_std_saveyourcar: 'guarda tu auto',
    txt_global_std_ayouwillbenotifiedintheappwhenitstimetorenewyourinsuranceifyoudonthaveautomaticrenewalsetupfollowthestepsintheapptoensureyouhavecoverageforthefollowingmonth: 'R: Se le notificará en la aplicación cuando sea el momento de renovar su seguro. Si no tiene configurada la renovación automática, siga los pasos en la aplicación para asegurarse de tener cobertura para el mes siguiente.',
    txt_global_std_selectteam: 'Selecciona un equipo',
    txt_global_std_globalteams: 'Equipos globales',
    txt_global_std_mystatsthismonth: 'Mis estadísticas este mes',
    txt_global_std_downloadinglatestversionofapp: 'Ahora estamos descargando las últimas versiones de todas las aplicaciones. Por favor, póngase en contacto con nosotros, puede tardar hasta un minuto.',
    txt_global_std_countryroadintersectionDESC: 'El conductor del automóvil azul está planificado y concentrado y conduce sin problemas a través del cruce.\n' +
      'El conductor del automóvil rojo no ajusta su velocidad a la situación de manejo y, a menudo, necesita usar los frenos bruscamente.',
    txt_global_std_renewedfor: 'Renovado por',
    txt_global_std_tomasengebesttip: 'Centrarse en la conducción tanto como sea posible es clave para llegar con éxito a su destino',
    txt_global_std_thisensuresyourcarisstilltrackedbyloyaltysystem: 'Esto asegura que su automóvil aún sea rastreado por el sistema de lealtad.',
    txt_global_std_nestgroup: 'grupo nido',
    txt_global_std_replay: 'Repetición',
    txt_global_std_p2ptypeamessage: 'Escriba un mensaje',
    txt_global_std_thisisaskilleddriver: 'Este es un conductor hábil, sin embargo, a veces durante un viaje y solo por un corto período de tiempo, este conductor tiende a perder el enfoque en Cool Driving. Algunas distracciones o estrés pueden hacer que este conductor ignore la conciencia de seguridad. Esto a menudo se soluciona hablando con el conductor y pidiéndole su consideración.',
    txt_global_std_establishingobddatalink: 'Establecimiento del enlace de datos OBD',
    txt_global_std_PolicySchedule20: 'ENT',
    txt_global_std_clickrefreshbuttoninapptotryagain: 'Haga clic en el botón Actualizar en la aplicación para volver a intentarlo.',
    txt_global_std_whendrivingonhigherspeedroadsplanningismoreimportantthaneverenteringahighwaytooslowlycancausedangertofellowmotoristsandleadtoqueuesbehindyouobservingthetrafficflowandadaptingyourspeedtotheconditionsisimportanttoavoidunnecessaryqueuesandpanicbrakingnew: 'Al conducir en carreteras de alta velocidad, la planificación es más importante que nunca. Entrar en una autopista demasiado lento puede causar peligro a otros automovilistas y generar colas detrás de usted. Es importante observar el flujo de tráfico y adaptar la velocidad a las condiciones para evitar colas innecesarias y frenadas de pánico.',
    txt_global_std_addlogo: 'Agregar logotipo',
    txt_global_std_bad2: 'Malo',
    txt_global_std_howdoiterminatemyinsurance: '¿Cómo doy por terminado mi seguro?',
    txt_global_std_manageparticipants: 'Administrar participantes',
    txt_global_std_MSIG_InsuranceLetter19: 'Personas o clases de personas con derecho a conducir*',
    txt_global_std_tryouttrygdrive: 'Prueba la aplicación',
    txt_global_std_onceyouhavecompletedachallengeitwillappearhere: 'Una vez que hayas completado un desafío, aparecerá aquí.',
    txt_global_std_daysdayshourshoursminutesminutes: '{días} Días {horas} Horas {minutos} Minutos',
    txt_global_std_pickstarttime: 'Elige la hora de inicio',
    txt_global_std_p2psendinvite: 'Enviar invitación',
    txt_global_std_setupyourprofiletocontinue: 'Configura tu perfil para continuar',
    txt_global_std_riskindex_upsell: 'Índice de riesgo: acceda al panorama general para comprender las tendencias globales en la conducción del riesgo.',
    txt_global_std_teammembers: 'Miembros del equipo:',
    txt_global_std_KleinschmidtBesttip: 'Me parece importante prever el camino por delante, para que no tengas que frenar, acelerar y frenar de nuevo todo el tiempo.',
    txt_global_std_newmobilitycompany: 'nueva empresa de movilidad',
    txt_global_std_statsforthistrip: 'Estadísticas de este viaje',
    txt_global_std_warikan_onboarding19: ' Si no se muestra el botón, descargue KINTO directamente desde App Store o Google Play.',
    txt_global_std_hoursshort: 'h',
    txt_global_std_cimage24d: 'Encuentre las RPM que proporcionen el consumo de combustible más bajo a gran velocidad.',
    txt_global_std_joinname: 'Únete a {nombre de la aplicación}',
    txt_global_std_anewaccountwillbecreated: 'Se creará una nueva cuenta.',
    txt_global_std_restrictedtopeoplewithinmyorganization: '(restringido a personas dentro de mi organización)',
    txt_global_std_youraveragelevelthismonthisthebasisforyourdiscountyoureceivethediscountwhenyourenewyourinsurancefortheupcomingmonths: 'Su nivel promedio este mes es la base de su descuento. Recibes el descuento al renovar tu seguro para los próximos meses',
    txt_global_std_units: 'Unidades',
    txt_global_std_listoffunctions: 'Lista de funciones',
    txt_global_std_p2psendyourcode: 'Envía tu código de amigo',
    txt_global_std_viewactivationcode: 'Ver código de activación',
    txt_global_std_p2pviewyourbookingsandblockyourcarwhenyouwanttomakeitunavailable: 'Consulta tus reservas y bloquea tu coche cuando quieras que no esté disponible.',
    txt_global_std_results: 'Resultados',
    txt_global_std_teamleaderscore: 'Puntaje de líder de equipo',
    txt_global_std_whenyouactivateyourinsurance: 'Cuando actives tu seguro, obtendrás información al respecto aquí',
    txt_global_std_choosefromlibrary: 'Elige de la biblioteca',
    txt_global_std_distancefromparking: 'Distancia desde el estacionamiento e inicio del viaje',
    txt_global_std_youhavebeenchallengedtoahead2head: 'Usted ha sido desafiado a un cabeza a cabeza. ¡Abre la aplicación y acéptala ahora!',
    txt_global_std_wanttochallengeanewfriend: '¿Quieres desafiar a un nuevo amigo?',
    txt_global_std_currentodometervalueinkm: 'Introduce el valor actual de tu cuentakilómetros (km)',
    txt_global_std_HansenMileage: 'Realmente me apetece un buen viaje largo si puedo compartir el viaje con mi familia o amigos.',
    txt_global_std_countryroad3DESC: 'El conductor del automóvil azul mantiene un ritmo lento para conducir concentrado y sin problemas.\n' +
      '\n' +
      'El conductor del coche rojo no planifica la conducción y acelera/frena innecesariamente a menudo.',
    txt_global_std_collectpointsbyearningbadgeseverybadgeisworthdifferentamountofpointstapabadgetolearnmoreaboutit: 'Acumula puntos ganando insignias. Cada insignia vale una cantidad diferente de puntos. Toque una insignia para obtener más información al respecto.',
    txt_global_std_getstarted: 'Empezar',
    txt_global_std_challenges: 'Desafíos',
    txt_global_std_may: 'Mayo',
    txt_global_std_documentation2: 'Documentación',
    txt_global_std_alreadyhaveanenerfypluginclickhere: '¿Ya tienes un complemento de Enerfy? ¡Haga clic aquí!',
    txt_global_std_drivercpproverview: 'Informe de perfilado del patrón de accidentes del conductor: descripción general',
    txt_global_std_likeourcarsharingpage: 'Dale me gusta a nuestra página de Facebook',
    txt_global_std_youearnedapercentagediscount: 'Obtuviste un {percentage}% de descuento',
    txt_global_std_uploadyourcompanylogo: 'Sube el logo de tu empresa',
    txt_global_std_pushneverdrivenbody2: 'Comience con {appname}, ¡conéctese a su automóvil la próxima vez que vaya a conducir!',
    txt_global_std_kintofaq10q: '¿Cómo miden mi conducción?',
    txt_global_std_p2ptotaltosplit: 'Total a dividir:',
    txt_global_std_worldranking: 'Clasificación mundial',
    txt_global_std_yourinsuranceisrenewedrenewaldate: 'Su seguro se renueva {renewaldate}.',
    txt_global_std_setupyourcompanychallenge: 'Configura el espacio de trabajo de tu empresa',
    txt_global_std_companychallenge: 'Desafío de la empresa',
    txt_global_std_ConfirmFia_Text11: '¡Quedan menos de 24 horas para su horario programado en la prueba de manejo del FIA Smart Driver Challenge en París!',
    txt_global_std_clickonthesettingsbelowtochangepermission: 'Haga clic en la configuración a continuación para cambiar el permiso',
    txt_global_std_starttheengineandopentheapp: 'Arranque el motor y abra la aplicación.',
    txt_global_std_supportedfileformats: 'Formatos de archivo compatibles',
    txt_global_std_demotext3: 'Vea cuánto ha conducido en total.',
    txt_global_std_savedimagesmarkedwitha: 'Imágenes guardadas marcadas con un',
    txt_global_std_makesureengineisrunning: 'Asegúrese de que el motor esté funcionando.',
    txt_global_std_havealreadybeeninapp: 'Ya eres un usuario',
    txt_global_std_unpublishachievementpopuptext: 'Cuando cancela la publicación de un logro, se eliminará para todos los usuarios que aún no lo hayan desbloqueado. Si un usuario ya lo recibió, aún lo tendrá entre sus otros logros. Si aún no lo ha recibido, se eliminará al cancelar la publicación.',
    txt_global_std_KleinschmidtWhyjoin: 'Porque somos el equipo más genial y nos divertimos mucho.',
    txt_global_std_risklevel: 'Nivel de riesgo',
    txt_global_std_driveabitmoretogetscore: 'Conduce un poco más para obtener una puntuación',
    txt_global_std_updatedtranslation: 'Traducción actualizada',
    txt_global_std_countyears: '{contar} años',
    txt_global_std_insurance: 'Seguro',
    txt_global_std_shownewactivities: 'Mostrar actividades de noticias',
    txt_global_std_startachallenge: 'Iniciar un desafío',
    txt_global_std_discipline: 'Disciplina',
    txt_global_std_competition: 'Competencia',
    txt_global_std_notsignedupforsompoclimateheroinsuranceyet: '¿Aún no estás inscrito en el seguro Sompo Climate Hero?',
    txt_global_std_pleaserefreshthepage: 'Por favor actualice la página',
    txt_global_std_hereyoustartadrivemanuallywhenyouhavefinished: 'Aquí se inicia una unidad de forma manual. Cuando haya terminado su unidad, haga clic en Detener unidad para obtener su puntuación. Usamos GPS para darle una puntuación. Si conduce hacia un túnel o un estacionamiento, su manejo puede verse afectado.',
    txt_global_std_p2pborrowedtime: 'Tiempo prestado',
    txt_global_std_collision123: 'Colisión',
    txt_global_std_atleast5characters: 'Al menos 5 caracteres',
    txt_global_std_p2plendyou: 'te presto',
    txt_global_std_signup: 'Inscribirse',
    txt_global_std_calculating: 'Calculador',
    txt_global_std_30daysavg: '30 días promedio',
    txt_global_std_pleaseverifyyourenerfyglobalemailadress: 'Verifique su dirección de correo electrónico de Enerfy Global',
    txt_global_std_p2pmorefunctions: 'Más funciones',
    txt_global_std_scantheqrcodetoaccessthechallenge: 'Escanea el código QR para acceder al desafío.',
    txt_global_std_man: 'Hombre',
    txt_global_std_sharelink1: 'Compartir enlace',
    txt_global_std_refresh: 'Actualizar',
    txt_global_std_easilymanagecommunication: 'Gestiona fácilmente la comunicación en todos los canales. Algunos de los mensajes están automatizados para optimizar el recorrido del cliente. Cree y personalice los suyos propios o elija entre correos electrónicos, mensajes de texto, notificaciones automáticas y notificaciones en la aplicación existentes.',
    txt_global_std_sep: 'Sep',
    txt_global_std_p2perrorcodebookingduplicaterequest: 'Ya ha enviado una solicitud para esta hora.',
    txt_global_std_p2pborrowtext: '{name} quiere tomar prestado tu {vehículo}',
    txt_global_std_youwillearnachievements: 'obtendrás logros',
    txt_global_std_mysavings: 'mis ahorros',
    txt_global_std_besuretoaddfourmainpicturesofyourcarfrontleftrightandbacksidesasshowninthediagramsbelow: 'Asegúrese de agregar cuatro imágenes principales de su automóvil (lados delantero, izquierdo, derecho y trasero) como se muestra en los diagramas a continuación.',
    txt_global_std_creatingyourprofile: 'Creando tu perfil',
    txt_global_std_sorrytheimagerecognitionfailedpleaseentertheodometervaluemanually: 'Lo sentimos, el reconocimiento de imagen falló. Introduzca el valor del cuentakilómetros manualmente.',
    txt_global_std_alreadyhaveanaccountlogin: '¿Ya tienes una cuenta? Iniciar sesión',
    txt_global_std_PolicySchedule33: 'Dirección',
    txt_global_std_TomczykInterests: 'Coches clásicos, todo tipo de deportivos.',
    txt_global_std_p2psafedriverbonusnotincluded: 'Bono Safedriver no incluido',
    txt_global_std_takesomephotosofyourcar: 'Toma algunas fotos de tu auto',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriendsthedriverwiththebestscorewinstheround: 'Compite en tu conducción diaria contra tus amigos. ¡El piloto con la mejor puntuación gana la ronda!',
    txt_global_std_p2pemailcancelledbookings: 'Reservas canceladas',
    txt_global_std_whatisapayasyoudriveinsurance: '¿Qué es un seguro de pago mientras conduce?',
    txt_global_std__log_in: 'Iniciar sesión',
    txt_global_std_gobackandfix: 'Vuelve atrás y arregla',
    txt_global_std_allyear: 'Todo el año',
    txt_global_std_world: 'Mundo',
    txt_global_std_leftuntilrenewal: 'Izquierda hasta renovación',
    txt_global_std_policynumberalreadyexists: 'El número de póliza ya existe',
    txt_global_std_buyandinsuranceandstartsavingmoneynow: 'Contrata un seguro y empieza a ahorrar dinero ahora',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint2: 'Descubra nuevos conocimientos para permitir la acción dirigida',
    txt_global_std_oct: 'Oct',
    txt_global_std_coupons: 'Cupones',
    txt_global_std_okaywewillremindyoulater: 'Está bien, te lo recordaremos más tarde.',
    txt_global_std_successfullypublishedreviewedtranslations: 'Traducciones revisadas publicadas con éxito',
    txt_global_std_p2pyouhavenobooking: 'No tienes solicitudes de reserva',
    txt_global_std_warikan_onboarding5: '1. El contratista propietario KINTO ONE (cliente) descarga la aplicación',
    txt_global_std_koscore: 'ecológico',
    txt_global_std_editprofilepicture: 'Editar foto de perfíl',
    txt_global_std_deactivated: 'Desactivado',
    txt_global_std_timesincelogin: 'Tiempo desde inicio de sesión, estacionado, etc.',
    txt_global_std_selectyourobddevicefromthelistobdmini2: 'Seleccione su dispositivo OBD de la lista: OBD Mini 2',
    txt_global_std_enterthenrofdigitsdigitcodeorclickonthelinksenttophonenr: 'Ingrese el código {nrOfDigits} - dígito o haga clic en el enlace enviado a: {phoneNr}',
    txt_global_std_howtoplay: 'Cómo jugar',
    txt_global_std_kintofaq7a: '¡No hay problema! ¡Solicite un dongle BT-OBD y hará la magia! Simplemente conecte el dongle al cable y conéctelo a su cargador de 12v en el automóvil. Luego siga los pasos de "Conectar al automóvil" como de costumbre y elija el nombre "Enerfy OBD" durante la conexión bluetooth.',
    txt_global_std_senddetails: 'Enviar detalles',
    txt_global_std_youwillreceiveanemailwithyourpersonaldata: 'Recibirás un email con tus datos personales',
    txt_global_std_ihaveanenerfypluginhowdoiconnectitinmycar: 'Tengo un Enerfy Plug-in, ¿cómo lo conecto a mi auto?',
    txt_global_std_thepasswordhastobe8charactersorlongerandmustinclude1numeralandspecialcharacter: 'La contraseña debe tener 8 caracteres o más, y debe incluir 1 número y un carácter especial',
    txt_global_std_enddate: 'Fecha final',
    txt_global_std_p2ppushbodylendingcomingup: 'Tenga en cuenta que "{name}" le prestará su coche. Comienza "{inicio}"',
    txt_global_std_inviteyournetworktostartdrivingsmarter: '¡Invite a su red a comenzar a conducir de manera más inteligente!',
    txt_global_std_calender: 'Calandrar',
    txt_global_std_p2pnocancelledbookingrequests: 'No hay solicitudes de reserva canceladas',
    txt_global_std_itwillexpireshortlysomakesuretoverifysoonremembertocheckyourspamfolder: 'Caducará en breve, así que asegúrese de verificarlo pronto. Recuerda revisar tu carpeta de spam.',
    txt_global_std_me: 'Yo',
    txt_global_std_connectingtoyourcar: 'Conexión a tu coche',
    txt_global_std_ttest94: 'esto es una prueba',
    txt_global_std_alerthighdivisiontext: '¡Advertencia! Tu estilo de conducción genera un elevado coste por kilómetro.',
    'txt_global_std_Al-KhelaifiQuote': 'Cotización próximamente...',
    txt_global_std_p2ppushbodyexpensesdenied: '{name} no aprobó el gasto',
    txt_global_std_canitrytheappbeforesigningup: '¿Puedo probar la aplicación antes de registrarme?',
    txt_global_std_PolicySchedule24: 'GST',
    txt_global_std_kintoloyaltysystem: 'El sistema de fidelización',
    txt_global_std_download: 'Descargar',
    txt_global_std_youareinthetop5keepgoing: 'Estás en el top 5, ¡Sigue adelante!',
    txt_global_std_buttogetthecorrectstatsofyourdrivingyouneedtogotoappsettingsinyourphoneandchoosebalwaysb: '...pero para obtener las estadísticas correctas de su conducción, debe ir a la configuración de la aplicación en su teléfono y elegir <b>Siempre.</b>',
    txt_global_std_p2ppushbodyrevokeadmin: 'Sus derechos de administrador para "{vehicle}" han sido revocados por {name}.',
    txt_global_std_filteredon: 'filtrado en',
    txt_global_std_smallscratches: '¿Pequeños rasguños?',
    txt_global_std_yourinsurancewillberenewedonmonthdayyear: 'Su seguro se renovará el {mes} {día}, {año}',
    txt_global_std_placeholdertext: 'Texto del marcador',
    txt_global_std_thisisaskilledinconsistentdriver: 'Este es un conductor hábil, sin embargo, a veces durante un viaje y solo por un corto período de tiempo, este conductor tiende a perder el enfoque en Cool Driving. Algunas distracciones o estrés pueden hacer que este conductor ignore la conciencia de seguridad. Esto a menudo se soluciona hablando con el conductor y pidiéndole su consideración.',
    txt_global_std_lockedtitle: 'bloqueado',
    txt_global_std_chooseallowonce: 'Elija Permitir una vez',
    txt_global_std_connectionwasabortedbeforethedrivewasvalid: 'La conexión se canceló antes de que la unidad fuera válida, la unidad se descartará',
    txt_global_std_preparing: 'Preparando...',
    txt_global_std_usermustacceptaninvitationtotheteamfirst: 'El usuario debe aceptar primero una invitación al equipo.',
    txt_global_std_p2pfaq7q: '¿Cómo puedo cancelar una reserva?',
    txt_global_std_drivingdatatroubleshooting: 'Resolución de problemas de datos de conducción',
    txt_global_std_time: 'Tiempo',
    txt_global_std_continuetosetup: 'Continuar a la configuración',
    txt_global_std_fleetcompany: 'empresa de flota',
    txt_global_std_p2pleavegroupchat: 'Salir del chat de grupo',
    txt_global_std_selecttype2: 'Seleccione tipo',
    txt_global_std_acceptuserterms: 'Aceptar términos de usuario',
    txt_global_std_mustbeavalidsizeandcanonlyincludenumbersand: 'Debe tener un tamaño válido y solo puede incluir números y +',
    txt_global_std_start: 'comienzo',
    txt_global_std_aiexplainer: 'AI-Explicador',
    txt_global_std_MSIG_Onboarding12: 'MS First Capital Insurance Ltd',
    txt_global_std_HSolbergChamp: 'Ganó 5 veces el Campeonato de Noruega (1999-2003)',
    txt_global_std_createanaccountoruseexistingone: 'Crea una cuenta o usa una existente',
    txt_global_std_youwonyourh2hchallenge: '¡Ganaste tu desafío Head2Head contra {usuario}!',
    txt_global_std_Inviteafriedbothwillgetawarded: '¡Invita a un amigo y ambos serán premiados!',
    txt_global_std_burglary123: 'Robo',
    txt_global_std_apioverview: 'Descripción general de la API',
    txt_global_std_whenthechallengeisaccepteditwillshowuponthestartpage: 'Cuando se acepte el desafío, aparecerá en la página de inicio.',
    txt_global_std_kintofaq10a: 'La aplicación Kinto utiliza inteligencia artificial para medir su conducción. La aplicación se conecta a la nube y analizará cada segundo de su conducción, convertido en "un perfil". La IA está comparando sus perfiles con más de 650 millones de otros perfiles de conducción únicos. La IA tiene más de 16 años de experiencia para buscar la probabilidad de un patrón de conducción de estar involucrado en un accidente. Afortunadamente, después de casi todos los viajes, el conductor y los pasajeros regresan a casa sanos y salvos con sus seres queridos.',
    txt_global_std_theft: '¿Robo?',
    txt_global_std_PolicySchedule26: 'FRANQUICIA NIL PARA ASEGURADOS Y CONDUCTORES AUTORIZADOS',
    txt_global_std_PolicySchedule9: 'Tonelaje/CC',
    txt_global_std_letmeshowyouhoweverythingworks: '¡Déjame mostrarte cómo funciona todo aquí!',
    txt_global_std_disableachievementtooltiptext: 'Este logro fue creado sin la herramienta de logros. <br> Por lo tanto, actualmente no se puede modificar en esta herramienta.',
    txt_global_std_warikan_onboarding3: 'Descargue la aplicación Warikan KINTO desde la siguiente URL.\n',
    txt_global_std_howlongwillittaketoprocessmyclaim: '¿Cuánto tiempo tomará procesar mi reclamo?',
    txt_global_std_apikeymanager: 'ADMINISTRADOR DE CLAVE API',
    txt_global_std_100km: '100 kilometros',
    txt_global_std_whendrivingonhigherspeedroadsplanningismoreimportantthaneverenteringahighwaytooslowlycancausedangertofellowmotoristsandleadtoqueuesbehindyouobservingthetrafficflowandadaptingyourspeedtotheconditionsisimportanttoavoidunnecessaryqueuesandpanicbraking: 'Al conducir en carreteras de alta velocidad, la planificación es más importante que nunca. Entrar en una autopista demasiado lento puede causar peligro a otros automovilistas y generar colas detrás de usted. Es importante observar el flujo de tráfico y adaptar la velocidad a las condiciones para evitar colas innecesarias y frenadas de pánico.',
    txt_global_std_searchbynameoraccounttype: 'Buscar por nombre o tipo de cuenta',
    txt_global_std_save: 'Ahorrar',
    txt_global_std_pushdriveendmessagetitle: 'La unidad ha finalizado: {0}',
    'txt_global_std_your@email.com': 'tu@email.com',
    txt_global_std_KristofferssonMileage: 'Disfruto conduciendo por el norte de Suecia en un soleado día de invierno, listo para un viaje de esquí.',
    txt_global_std_unknownracedriverDescription: 'Que se anunciará',
    txt_global_std_enerfyanalytics_upsell_bulletpoint2: 'Descubra nuevos conocimientos para permitir la acción dirigida',
    txt_global_std_creategroup: 'Crea un grupo',
    txt_global_std_ifyoudonothavebluetoothinthecaryoucanuseachipthatisplacedinthecarsobd2portachipcanbeusedinmostcarsfrom2001orlater: 'Si no tiene Bluetooth en el automóvil, puede usar un chip que se coloca en el puerto OBD2 del automóvil. Se puede usar un chip en la mayoría de los automóviles a partir de 2001 o posteriores.',
    txt_global_std_invitefriendsviaemail: 'Invitar a amigos por correo electrónico',
    txt_global_std_findteamordriver: 'Encuentra equipo o piloto',
    txt_global_std_getiobd2: 'Obtener iOBD2',
    txt_global_std_yougetthisafterdriving25tripswithtrygdrive: 'Obtienes esto después de conducir 25 viajes con Tryg Drive',
    txt_global_std_observe: 'Observar',
    txt_global_std_distance: 'Distancia',
    txt_global_std_KleinschmidtDescription: 'Es la primera y hasta ahora la única mujer que ha obtenido la victoria absoluta en el rally más largo y duro del mundo, el Rally Dakar. Su carrera deportiva comenzó con la motocicleta. Después de 4 rallies en moto, cambió a 4 ruedas en 1995 y se involucró en el desarrollo del coche de rallies más exitoso, el Mitsubishi Pajero Evo, con el que ganó el Dakar en 2001. Además de su trabajo como instructora, ha publicado su libro “Meine Siege bei der Dakar” – Mi victoria en Dakar.',
    txt_global_std_demotext4: 'Cuando anotas, ¡salvas! Vea su bono total aquí.',
    txt_global_std_okay: 'De acuerdo',
    txt_global_std_damageabroad: 'Daños en el extranjero',
    txt_global_std_deletetranslation: 'Eliminar traducción',
    txt_global_std_joinyourteaminthefiasdcandstayactivesmartandsustainablewhenyoutravelbycar: 'Únase a su equipo en Fia SDC y manténgase activo, inteligente y sostenible cuando viaje en automóvil.',
    txt_global_std_p2ppushtitlelendcar: '{name} - ¿prestar mi coche?',
    txt_global_std_p2pemailorpasswordwrong: 'Dirección de correo electrónico/contraseña: ambas o una de ellas es incorrecta',
    txt_global_std_areyousureyouwanttoadjustyourmileagethepricewillbeadjustedaccordingtothenewmileage: '¿Está seguro de que desea ajustar su kilometraje? El precio se ajustará de acuerdo con el nuevo kilometraje.',
    txt_global_std_ajustingmilageplan: 'Ajuste del plan de kilometraje',
    txt_global_std_vsteam: 'VS. Equipo',
    txt_global_std_yourscoreisbasedonthedrivingpatternsyoucreatewitheachtripconcentrateonyourdrivingskillstoimproveyourscoreswewillprovideahistoryofallyourtripssoyoucanseehowyouredoing: 'Su puntuación se basa en los patrones de conducción que crea con cada viaje. Concéntrate en tus habilidades de conducción para mejorar tus puntuaciones. Le proporcionaremos un historial de todos sus viajes para que pueda ver cómo le va.',
    txt_global_std_youcancontactoneofourlicensedinsuranceagenciesbyclickingonlinkorcallingagentatphoneno: 'Puede comunicarse con una de nuestras agencias de seguros autorizadas haciendo clic en {link} o llamando a {agent} al {phoneNo}',
    txt_global_std_belowisthelinktoyourenerfyglobalapp: 'A continuación se muestra el enlace a su aplicación Enerfy Global.',
    txt_global_std_cimage12h: 'GANE VELOCIDAD ANTES DE SUBIR',
    txt_global_std_emailtoolong: 'El correo electrónico puede tener un máximo de 127 caracteres.',
    txt_global_std_changecar: 'Cambiar carro',
    txt_global_std_noreviewstobepublishedpleaseedittranslationsifyouwishtopublish: 'No se publicarán reseñas, edite las traducciones si desea publicarlas.',
    txt_global_std_howdoicompeteinenerfyloyaltychallenge: '¿Cómo compito en Enerfy Loyalty Challenge?',
    txt_global_std_cimage20h: 'LA ALTA VELOCIDAD MÁXIMA NO DA GANANCIAS DE TIEMPO',
    txt_global_std_enteryourpolicynumbertounlockallthebenefitsofthisapp: 'Ingresa tu número de póliza para desbloquear todos los beneficios de esta aplicación.',
    txt_global_std_kretursanalyse: 'Análisis de viaje',
    txt_global_std_cimage21d: 'Es técnicamente más fácil conducir económicamente a velocidades más bajas.',
    txt_global_std_createyourownleaderboardinthefiasmartdrivingchallengeandchallengeeachothertobecomesmarterandsaferontheroad: 'Cree su propia tabla de clasificación en el Desafío de conducción inteligente de la FIA y desafíese unos a otros para ser más inteligentes y seguros en la carretera.',
    txt_global_std_emergencynumber: 'llamada de socorro',
    txt_global_std_avaragescore: 'Puntuación media',
    txt_global_std_makesureyouarewithintenmeters: 'Asegúrate de estar a menos de 10 m. del lector OBD.',
    txt_global_std_tiphighway1DESC: 'El conductor del auto azul está concentrado y entra a la carretera de manera segura.\n' +
      'El conductor del automóvil rojo no ajusta su velocidad a la situación de manejo y necesita frenar con frecuencia.',
    txt_global_std_drivingtips: 'Consejos para conducir',
    txt_global_std_starting: 'Comenzando',
    txt_global_std_introduction: 'Introducción',
    txt_global_std_noactiveparticipantsthisweek: 'No hay participantes activos esta semana',
    txt_global_std_yourchallengeranking: 'Clasificación de tu desafío',
    txt_global_std_fieldcannotbeempty: 'El campo no puede estar vacío',
    txt_global_std_createnewchallenge: 'Crear nuevo desafío',
    txt_global_std_enteravaliddate: 'Introduzca una fecha válida.',
    txt_global_std_p2pemailadressforcommunication: 'Dirección de correo electrónico para la comunicación',
    txt_global_std_searchcardriver: 'Buscar coche/conductor',
    txt_global_std_recruitedby: 'Reclutado por',
    txt_global_std_containsforbiddencharactersorspace: " contiene caracteres prohibidos: ' ? & / &#60 &#62; o espacio",
    txt_global_std_p2psignupandcontinue: 'Regístrate y continúa',
    txt_global_std_youhavenowearnedascoreof4point4outof510timeswithtrygdrive: 'Ahora ha obtenido una puntuación de 4,4 sobre 5 10 veces con Tryg Drive',
    txt_global_std_allowbluetooth: 'Permitir Bluetooth',
    txt_global_std_idonthavebluetoothinmycarcanistillusetheapp: 'No tengo Bluetooth en mi coche, ¿puedo seguir usando la aplicación?',
    txt_global_std_pleaseallowkintoaccesstoyourlocationtogetstartedwiththeapp: 'Permita que Kinto acceda a su ubicación para comenzar con la aplicación.',
    txt_global_std_p2pselectedcar: 'coche seleccionado',
    txt_global_std_ifyoucontinuetodriveonlevel: 'Si continúa conduciendo en el nivel {level}, podría ahorrar {discount} en su seguro.',
    txt_global_std_baselevel: 'Nivel básico',
    txt_global_std_generateinvitationcode: 'Generar código de invitación',
    txt_global_std_p2pconnectyourphonetoyourcarandyouwillunlockallthemagic: 'Conecte su teléfono a su automóvil y desbloqueará toda la magia.',
    txt_global_std_MSIG_InsuranceLetter6: 'Normas sobre vehículos motorizados (riesgos de terceros), 1959 (Malasia)',
    txt_global_std_addusersbyuploadingacsvfilewiththeirdetailsthefieldsfornameemailandpermissionsaremandatory: 'Agregue usuarios cargando un archivo CSV con sus detalles. Se requiere nombre, apellido, dirección de correo electrónico, número de teléfono y grupo.',
    txt_global_std_fixitnowreminder: 'Arréglalo ahora',
    txt_global_std_managecarsagreementshere: 'Administre automóviles y acuerdos aquí',
    txt_global_std_comparemyactivities: 'Comparar mis viajes',
    txt_global_std_readytodrive: 'Listo para conducir',
    txt_global_std_drivescoresave: 'Conducir. Puntaje. Ahorrar.',
    txt_global_std_earnpointsandsavemoney: 'Gana puntos y ahorra dinero en tu deducible',
    txt_global_std_p2pmycalendar: 'Mi calendario',
    txt_global_std_highest: 'más alto',
    txt_global_std_testdeploynb: 'hej',
    txt_global_std_backtochallengedashboard: 'Volver al panel de desafíos',
    txt_global_std_warikan_onboarding11: 'Centro de atención al cliente de KINTO',
    txt_global_std_acommontypeofunsafedrivingbehaviorisputtinghugeeffortintochasingthenexttrafficlightitslikeachallengetogettothenextcrossingasquicklyaspossibleonlytobestuckwaitingforthelighttoturngreendrivingsmoothlyandlookingwellaheadtoanticipatewhatscomingupsavesbothtimeandfuelnew: '¡Un tipo común de comportamiento de conducción inseguro es hacer un gran esfuerzo para perseguir el próximo semáforo! Es como un desafío llegar al próximo cruce lo más rápido posible solo para quedarse atrapado esperando que el semáforo se ponga en verde😊 Conducir sin problemas y mirar hacia adelante para anticipar lo que se avecina ahorra tiempo y combustible.',
    txt_global_std_takepicturesofyourcar: 'Toma fotos de tu auto',
    txt_global_std_addyourlogotypeandchoosecolortheme: 'Agregue su logotipo y elija el tema de color',
    txt_global_std_accounttype: 'Tipo de cuenta',
    txt_global_std_noofdrives: 'Nº de unidades',
    txt_global_std_wasanyoneinjured: '¿Alguien resultó herido?',
    txt_global_std_november: 'Noviembre',
    txt_global_std_variablepremium: 'prima mensual variable',
    txt_global_std_youneedtoinputapassword: 'Necesita ingresar una contraseña',
    txt_global_std_findchallenges: 'Encuentra retos',
    txt_global_std_easytomonitoryouresgtargets: 'Fácil de monitorear sus objetivos ESG',
    txt_global_std_iwanttonotifyyouaboutyourdrivingresults: 'Quiero notificarle sobre sus resultados de conducción. ¿OK?',
    txt_global_std_xhours: '{horas} horas',
    txt_global_std_p2ppercentageused: 'porcentaje utilizado',
    txt_global_std_p2pwarikanloginorlinkissues: 'Warikan: problemas de inicio de sesión o enlace',
    txt_global_std_loyalty_upsell: 'Páginas de fidelización: apoye el crecimiento empresarial a través de relaciones más sólidas.',
    txt_global_std_youraveragerisklevelispoints: 'Su nivel de riesgo promedio es {puntos}',
    txt_global_std_enterpassword: 'Introducir la contraseña',
    txt_global_std_lastdatereceived: 'última fecha recibida',
    txt_global_std_neverbeeneasier: 'nunca ha sido tan fácil',
    txt_global_std_riskindex_upsell_pitch_bulletpoint3: 'Niveles de riesgo a nivel mundial, por país o por código postal/código postal',
    txt_global_std_p2pother: 'Otro',
    txt_global_std_activatemyinsurance: 'Activar mi seguro',
    txt_global_std_whendrivingincityconditionsavoidchasingthenextcrossingortrafficlightbygainingspeedquicklythenslowingquicklythishurtstraveltimeandfueleconomyandincreasescrashrisklookingwellaheadanddrivingsmoothlywillhelpyouanticipateandhandleanyevent: 'Cuando conduzca en condiciones de ciudad, evite perseguir el siguiente cruce o semáforo aumentando la velocidad rápidamente y luego disminuyendo la velocidad rápidamente. Esto perjudica el tiempo de viaje y la economía de combustible, y aumenta el riesgo de accidentes. Mirar bien hacia adelante y conducir sin problemas lo ayudará a anticipar y manejar cualquier evento.',
    txt_global_std_appnamecollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: '{appname} recopila datos de ubicación para habilitar la puntuación de conducción incluso cuando la aplicación está cerrada o no está en uso.',
    txt_global_std_cimage21h: 'UNA VELOCIDAD MÁS BAJA LO HACE TÉCNICAMENTE MÁS FÁCIL',
    txt_global_std_ourairiskinsighttoolgivesyouthepowertoidentifyfollowupandreportoncsrtargetstrackriskandco2perteammemberandrewardthosewhoexcel: 'Nuestra herramienta de conocimiento de riesgos de IA le brinda el poder de identificar, hacer un seguimiento e informar sobre los objetivos de RSE, realizar un seguimiento del riesgo y el CO2 por miembro del equipo y recompensar a los que sobresalen.',
    txt_global_std_p2pwhatdoyouwanttodo: '¿Qué es lo que quieres hacer?',
    txt_global_std_yourdocumentwassent: '¡Tu documento fue enviado!',
    txt_global_std_average: 'Promedio',
    txt_global_std_won: 'Ganó',
    txt_global_std_followupdate: 'Fecha de seguimiento',
    txt_global_std_speedchangedescription: 'Este gráfico muestra la cantidad total de tiempo que ha pasado en aceleración, velocidad constante y velocidad decreciente. Consejos generales: Intente pasar más tiempo disminuyendo la velocidad que acelerando.',
    txt_global_std_hellolinus: 'hola Linus!',
    txt_global_std_easytogeteverydriverinyournetworkinvolved: 'Fácil de involucrar a todos los controladores de su red',
    txt_global_std_letsdrive: '¡Manejemos!',
    txt_global_std_above1000unlocked: '¡Condujiste por encima de 1000! ¡Impresionante!',
    txt_global_std_p2pblockedthecar: '{name} bloqueó el auto',
    txt_global_std_fiasdcisaninitiativetoreducepollutionandincreasesafetyontheraodswhiledrivingmotoristsgettheirdrivingassessedinrealtimebyouraitechnologythatunderstandseachtripslevelofriskandcarbonfootprintjustbymakingmotoristsawareoftheirimpactwecancreateapositivechange: 'Fia SDC es una iniciativa para reducir la contaminación y aumentar la seguridad en las carreteras. Mientras conducen, los automovilistas obtienen una evaluación de su conducción en tiempo real mediante nuestra tecnología de inteligencia artificial, que comprende el nivel de riesgo y la huella de carbono de cada viaje. Con solo hacer que los automovilistas sean conscientes de su impacto, podemos crear un cambio positivo.',
    txt_global_std_membersinthisgroup: 'Miembros en este grupo',
    txt_global_std_VilliersQuote: 'Cotización próximamente...',
    txt_global_std_renewaldate: 'Fecha de renovación',
    txt_global_std_1000pointaverageachievementlocked: 'Conduzca con un {scoretype} promedio de {score} o mejor durante una semana para desbloquear este logro y ganar 1000 puntos.',
    txt_global_std_carriedrisk: 'riesgo asumido',
    txt_global_std_idrivetoomuch: 'conduzco demasiado',
    txt_global_std_headtohead: 'Cabeza a cabeza',
    txt_global_std_HansenQuote: '¡Conducir con inteligencia es divertido! Me ahorra dinero y reduce mi impacto en nuestro precioso medio ambiente.',
    txt_global_std_p2psendalinksothatthepersonborrowingyourcarcandownload: 'Envíe un enlace para que la persona que toma prestado su automóvil pueda descargar la aplicación {appname}.',
    txt_global_std_p2pgotocostsplit: 'Ir a división de costos',
    txt_global_std_reducing: 'reduciendo',
    txt_global_std_thisisonlyneededthefirsttimeyouconnecttoyourcar: 'Esto solo es necesario la primera vez que se conecta a su automóvil.',
    txt_global_std_p2pusesameemailasregisteredonkintoaccount: '* Use el mismo correo electrónico que registró en la cuenta de Kinto',
    txt_global_std_choosebetweenenteringchassisnumberenginenumberplatenumberorpolicynumber: 'Elija entre ingresar número de chasis/número de motor, número de placa o número de póliza.',
    txt_global_std_PettersolbergShortDescription: 'Campeón del Mundo RX 2014 y 2015, Campeón WRC 13 veces, Campeón Rally GB 2002 y 2003',
    txt_global_std_chooseaccidenttype: 'Elige el tipo de accidente',
    txt_global_std_alreadyexistspossibleduplicationinternalservererror: 'Ya existe, posible duplicación. Error de servidor interno',
    txt_global_std_choosefile: 'Elija el archivo...',
    txt_global_std_p2pdoyouwanttobookthedaysinbetweenormakeitseparatebookings: '¿Quieres reservar los días intermedios o hacer reservas separadas?',
    txt_global_std_cimage22d: 'Utilice el control de crucero únicamente en carreteras llanas. Las carreteras pueden clasificarse como planas si no necesita usar el freno de estacionamiento.',
    txt_global_std_youraveragelevelis: 'Tu nivel medio es',
    txt_global_std_bottomfinaliststext: 'El World Challenge consta de 3 eliminatorias de desafío, 3 períodos de calificación entre las eliminatorias y 1 gran final.\n' +
      '\n' +
      'Los 3 mejores pilotos de cada serie de desafíos se clasificarán directamente para la gran final en diciembre.',
    txt_global_std_anerroroccurredwhilesavingyourchanges: 'Ocurrió un error al guardar los cambios',
    txt_global_std_activatelocationservice: 'Activar servicios de ubicación',
    txt_global_std_p2ppaynow: 'Pagar ahora',
    txt_global_std_wheresmyoutlet: '¿Dónde está mi punto de venta?',
    txt_global_std_friendstothekintoapp: 'amigos a la aplicación Kinto',
    txt_global_std_createyouraccounttosaveandtrackthecostofyourtripsandgetaccesstoyourpersonaldrivingcoach: 'Cree su cuenta para guardar y realizar un seguimiento del costo de sus viajes y obtenga acceso a su entrenador de manejo personal',
    txt_global_std_plugitinyourcaarsobdoutlet: 'Conéctalo a la toma OBD de tu coche. Normalmente ubicado cerca del volante (su dongle estará con usted dentro de 1 a 3 días hábiles)',
    txt_global_std_WelcomeLetter_Text4: '¡Bienvenidos!',
    txt_global_std_HSolbergInterests: 'Coches, rally y carreras. Días de promoción con mis patrocinadores',
    txt_global_std_areyousureyouwanttoredeemxpointscoupon: '¿Está seguro de que desea canjear {x} puntos?',
    txt_global_std_currentodometervalue: 'Valor actual del odómetro',
    txt_global_std_p2pclickdatestoseebooking: 'Haga clic en las fechas en el calendario para ver sus reservas.',
    txt_global_std_alreadyhavefiasdc: '¿Ya tienes FIA SDC?',
    txt_global_std_description: 'Descripción',
    txt_global_std_platenumber: 'número de placa',
    txt_global_std_uploadyourdatatoseehowsufficientitis: 'Sube tus datos para ver qué tan suficientes son.',
    txt_global_std_p2pyesremovefriend: 'Sí, eliminar amigo',
    txt_global_std_invalidcode: 'Codigo invalido',
    txt_global_std_paylessnextmonth: 'paga menos el próximo mes',
    txt_global_std_KristofferssonQuote: 'La seguridad vial está muy cerca de mi corazón. El desafío de conducción inteligente de la FIA será tan divertido e inteligente como parece y permitirá que cualquiera mejore su conducción ecológica y segura. Como he estado compitiendo en todas las condiciones de la carretera que puedas imaginar, ahora tengo muchas ganas de transmitir ese conocimiento para fomentar una conducción más inteligente entre todos nosotros.',
    txt_global_std_p2pyour: 'Su',
    txt_global_std_renewyourinsurance: 'Renueva tu seguro',
    txt_global_std_youcancontactoneofourlicensedinsuranceagenciesbyclickingonfindanagentonourwebsiteorcallingagentatphonenumber: 'Puede comunicarse con una de nuestras agencias de seguros autorizadas haciendo clic en Buscar un agente en nuestro sitio web o llamando a {agent} al {phoneNumber}.',
    txt_global_std_yesexchangepoints: 'Sí canjea puntos',
    txt_global_std_p2pcost: 'Costo',
    txt_global_std_oldpasswordhelp: 'Necesitamos su contraseña actual para asegurarnos de que usted es el propietario de esta cuenta.',
    txt_global_std_offlinetrip: 'Desconectado',
    txt_global_std_donthaveanaccountsignuplink: '¿No tienes una cuenta? {enlace de registro}',
    txt_global_std_uploadlogotype: 'Subir logotipo',
    txt_global_std_haveaproblemwiththeappvisitourfaqorcontactus: '¿Tienes algún problema con la aplicación? Visite nuestras preguntas frecuentes o póngase en contacto con nosotros...',
    txt_global_std_starburstunlockedkey: '¡Condujiste en el nivel 4.4 o mejor! ¡Impresionante! Se han agregado 300 puntos a su billetera Kinto.',
    txt_global_std_aonceaclaimsettlementisdeterminedyoucanreceiveacheckoranelectronicpaymentintoyourbankaccount: 'R: Una vez que se determina la liquidación de un reclamo, puede recibir un cheque o un pago electrónico en su cuenta bancaria.',
    txt_global_std_enteremailtodostuff: 'Ingrese su correo electrónico para recibir un correo, donde puede restablecer y establecer una nueva contraseña de su elección.',
    txt_global_std_completewizard: 'Asistente completo',
    txt_global_std_datasentsuccess: 'Sus datos han sido enviados, por favor revise su bandeja de entrada!',
    txt_global_std_on: 'En',
    txt_global_std_whatifichangecar: '¿Y si cambio de coche?',
    txt_global_std_connecttotheprovidedobddeviceifyourcar: 'Conéctese al dispositivo OBD provisto (Enerfy Mini OBD) si su automóvil no tiene bluetooth',
    txt_global_std_selectyourcarmodeltoseethemostaccuratedataofsavedco2: 'Seleccione su modelo de automóvil para ver los datos más precisos de CO2 ahorrado.',
    txt_global_std_forgotyourpassword: '¿Olvidaste tu contraseña?',
    txt_global_std_getacoscoreof15orlowerstartdrivingtogetyourscore: 'Obtenga una puntuación de CO₂ del 15 % o inferior. ¡Empieza a conducir para conseguir tu puntuación!',
    txt_global_std_TaxInvoice3: 'Doctor no',
    txt_global_std_enerfyplugin: 'Complemento Enerfy',
    txt_global_std_best: 'Mejor',
    txt_global_std_addnoteshere: 'Añadir notas aquí',
    txt_global_std_p2pyourkintonumbercanbefoundwhenyoulogintoyourkintoaccount: 'Su número de Kinto se puede encontrar cuando inicia sesión en su cuenta de Kinto.',
    txt_global_std_fitnessmotion: 'Fitness/movimiento',
    txt_global_std_p2pnogoback2: 'no, vuelve',
    txt_global_std_sorrywecouldnotfindthatemailaddresspleasecontactagentsupport: 'Lo sentimos, no pudimos encontrar esa dirección de correo electrónico. Póngase en contacto con {agentSupport}.',
    txt_global_std_doihavetoactivatetheappeverytimeidriveanswer: 'No, ¡la aplicación funciona en segundo plano! Asegúrese de mantener siempre activados los servicios de ubicación y Bluetooth en su teléfono.',
    txt_global_std_superstar: 'Superestrella',
    txt_global_std_ayoucancontactoneofourlicensedinsuranceagenciesbyclickingonlinkorcallingagentatphoneno: 'R: Puede comunicarse con una de nuestras agencias de seguros autorizadas haciendo clic en {link} o llamando al {agent} al {phoneNo}.',
    txt_global_std_someoneelseispairedtoyourcarnodot: 'Alguien más está emparejado con tu coche',
    txt_global_std_somethingwentwrongandwecouldnotdisplayyourresulthere: 'Algo salió mal y no pudimos mostrar su resultado aquí.',
    txt_global_std_thisisonlyneededthefirsttimeyouconnecttoyourcar1: 'Esto solo es necesario la primera vez que se conecta a su automóvil.',
    txt_global_std_selectagreementnumber: 'Seleccionar número de acuerdo',
    txt_global_std_p2pfriendcalendar: 'calendario de {nombre}',
    txt_global_std_thisfunctionisunstable: 'Esta funcionalidad es inestable y debe utilizarse con precaución.',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweek: '¡Condujiste muy bien! ¡Tu {scoretype} promedio fue mejor que {score} durante una semana!',
    txt_global_std_hourh: 'h',
    txt_global_std_vehicledata: 'Datos del vehículo',
    txt_global_std_bothyouandyourfriendwillget10kmfreedistancetodrivewithenerfy: '¡Tanto tú como tu amigo obtendrán 10 km de distancia gratis para conducir con Enerfy!',
    txt_global_std_amazingdriver: 'Conductor increíble',
    txt_global_std_confirmyouremailaddress: 'Confirme su dirección de correo electrónico',
    txt_global_std_rwmgoback: 'Regresa',
    txt_global_std_appcreator_upsell_pitch_bulletpoint1: 'Optimice el viaje de su cliente digital, incorporando colores de marca y logotipo',
    txt_global_std_yourco2overview: 'Su resumen de CO2',
    txt_global_std_excessdiscount: 'Descuento por exceso',
    txt_global_std_addressline2: 'Línea de dirección 2',
    txt_global_std_inbonusforyourinsurance: 'en bonificación para su seguro.',
    txt_global_std_didyoureceivethepayment: '¿Recibiste el pago?',
    txt_global_std_createyourapp: 'Crea tu aplicación',
    txt_global_std_ensammenligningmeddennekreturoggennemsnittetafalledineturedeseneste30dage: 'Sus estadísticas de manejo para este viaje en comparación con su promedio de 30 días.',
    txt_global_std_dashboard_users: 'Usuarios',
    txt_global_std_notificationsettings: 'Configuración de las notificaciones',
    txt_global_std_ifbluetoothisalreadyactivatedyoucanstillgetthiserror: 'Siga estos pasos para asegurarse de que tiene Bluetooth activado.',
    txt_global_std_firstname: 'Nombre de pila',
    txt_global_std_loadingevents: 'Cargando eventos...',
    txt_global_std_wrongpasswordformatminimum8charactersatleastoneuppercaseletteronelowercaseletterandonenumber: 'Formato de contraseña incorrecto. Mínimo 8 caracteres, al menos una letra mayúscula, una letra minúscula y un número.',
    txt_global_std_hassentyouachallenge: 'te ha enviado un reto!',
    txt_global_std_pigwon: 'Ganador de cabeza a cabeza',
    txt_global_std_claimsdata: 'Datos de reclamaciones',
    txt_global_std_thereisnopermissions: 'no hay permisos',
    txt_global_std_personalizeyourchallenge: 'Personaliza tu desafío',
    txt_global_std_badinternetconnection: 'Mala conexión de Internet. Esto puede causar retrasos en la aplicación y afectar sus datos de manejo.',
    txt_global_std_test_key22: 'esto es una prueba 2',
    txt_global_std_6months: '6 meses',
    txt_global_std_groupvsgroup: 'Grupo contra grupo',
    txt_global_std_reportaccidentonline: 'Reportar accidente en línea',
    txt_global_std_date: 'Fecha',
    txt_global_std_communicate_upsell_pitch_bulletpoint2: 'Conéctese con los usuarios en sus canales preferidos',
    txt_global_std_v2risklevel: 'Nivel de riesgo',
    txt_global_std_hereyoucanloweryoursubscriptioncostbyupto20permonthbydrivingsafe2: 'Aquí puede reducir el costo de su suscripción hasta en un 20% por mes conduciendo de manera segura.',
    txt_global_std_ifyouareaninsurancecustomeryoucanfindyourpolicynumberwhenyoulogintoyourappnameaccount: 'Si es un cliente de seguros, puede encontrar su número de póliza cuando inicie sesión en su cuenta {appname}.',
    txt_global_std_above1000streaklocked: 'Desbloquea este logro conduciendo con una puntuación superior a 1000 5 veces.',
    txt_global_std_loadingusers: 'Cargando usuarios...',
    txt_global_std_createapikey: 'Crear clave API',
    txt_global_std_unlockeddescriptionlabel: 'Descripción desbloqueada, que se muestra en la aplicación',
    txt_global_std_totalpointscollected: 'Puntos totales acumulados',
    txt_global_std_p2ptogetaccesstoallfunctionsinthisappyouneedtobeinvitedbyakintocarowner: 'Para obtener acceso a todas las funciones de esta aplicación, debe ser invitado por el propietario de un automóvil Kinto.',
    txt_global_std_hour: 'Hora',
    txt_global_std_yourteam: 'Tu equipo',
    txt_global_std_p2plendcar: 'prestar coche',
    txt_global_std_demotext1: 'Conduce de forma inteligente y reduce las emisiones de CO2. Vea su ahorro de CO2 aquí.',
    txt_global_std_p2pconfirmselection: 'Confirmar selección',
    txt_global_std_skeylevel: 'Nivel',
    txt_global_std_terminateinsurance2: 'rescindir el seguro',
    txt_global_std_achievements: 'logros',
    txt_global_std_applyfilters: 'Aplicar filtros',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsabouttheagentappandcompeteagainstthemforlowerscores: 'R: Una vez que empareja la aplicación con el Bluetooth de su vehículo y permite que la aplicación use los servicios de ubicación, comparamos sus hábitos de conducción con la biblioteca de patrones de conducción de nuestra IA y proporcionamos una puntuación. La prima de su seguro se basa en quién conduce su vehículo y qué tan bien lo conduce. Se anima a quienquiera que esté conduciendo su vehículo a descargar la aplicación y realizar un seguimiento de sus puntuaciones. Cuéntales a tus amigos sobre la aplicación {agent} y compite contra ellos para obtener puntajes más bajos.',
    txt_global_std_carsharingcompany: 'empresa de coches compartidos',
    txt_global_std_communicate_upsell_pitch_bulletpoint3: 'Comunicar mensajes contextuales en el momento adecuado',
    txt_global_std_p2pwantstoborrowyour: ' quiere tomar prestado su',
    txt_global_std_editgroup: 'Editar grupo',
    txt_global_std_coreresources: 'Recursos básicos',
    txt_global_std_highrisk: 'Alto riesgo',
    txt_global_std_cosavings: 'Ahorro de CO²',
    txt_global_std_fitnessreasontext: 'Fitness/Motion son necesarios para determinar si está conduciendo o caminando.',
    txt_global_std_p2perrorcostcannotbegreaterthantotalcosttosplit: 'Error: el costo no puede ser mayor que el costo total para dividir',
    txt_global_std_register: 'Registro',
    txt_global_std_Unsubscribe: 'Haga clic aquí para darse de baja de esta lista.',
    txt_global_std_team: 'Equipo',
    txt_global_std_greatdriving: 'Gran conducción!',
    txt_global_std_aroundtheclock: 'Alrededor del reloj',
    txt_global_std_enteravalidvehicleidentification: 'Introduzca una identificación de vehículo válida.',
    txt_global_std_createnewbtntext: 'Crear nuevo',
    txt_global_std_everydayhero: 'Héroe cotidiano',
    txt_global_std_resultofanalyse: 'resultado de analizar',
    txt_global_std_MSIG_Onboarding3: 'que es válido desde',
    txt_global_std_p2paccept: 'Aceptar',
    txt_global_std_cimage3h: 'ANTICIPE LAS REDUCCIONES DE VELOCIDAD',
    txt_global_std_goldlevel: 'Nivel de oro',
    txt_global_std_p2puserborrowedcartimesph: '{usuario} tomó prestado {coche} {veces} veces',
    txt_global_std_smartdriverweeklylockedkey: 'Para obtener esta insignia, mantenga una puntuación superior a 3,5 durante una semana.',
    txt_global_std_p2ppushbodynewpaymentquestion: '¿Recibió un pago de {name}? Asegúrate de marcarlo como pagado.',
    txt_global_std_howdoicompeteinappchallenge: '¿Cómo compito en el desafío {appname}?',
    txt_global_std_forthisapptoworkproperlylocationservicemustbeallowed: 'Para que esta aplicación funcione correctamente, el servicio de ubicación debe estar habilitado en su dispositivo. Seleccione "Siempre" en la configuración de ubicación.',
    txt_global_std_mapsarenotavailableforthisperiod: 'Los mapas no están disponibles para este período',
    txt_global_std_faq: 'Preguntas más frecuentes',
    txt_global_std_searchdivision: 'División de búsqueda',
    txt_global_std_of: 'de',
    txt_global_std_PolicySchedule12: 'Tipo de portada',
    txt_global_std_chooseyouroption: 'Elige tu opción:',
    txt_global_std_rwmsheettext: 'Envíe un enlace a su amigo para que pueda descargar la aplicación Pioneer. Una vez que hayan creado una cuenta, aparecerán en su lista de amigos. Luego podrá registrar un viaje en el que compartió un viaje.',
    txt_global_std_PaddonQuote: '¡No solo lo sueñes, consíguelo! ¡Hazlo por el planeta!',
    txt_global_std_locked_account: 'Este usuario ha sido bloqueado por demasiados intentos fallidos de inicio de sesión. Vuelva a intentarlo en 1 hora.',
    txt_global_std_30days: '30 dias',
    txt_global_std_account: 'cuenta',
    txt_global_std_challengedashboard: 'Tablero de desafíos',
    txt_global_std_youcanonlyregisteraridesharingtriponceperweek: 'Solo puede registrar un viaje compartido una vez por semana',
    txt_global_std_thephonenumbercannotincludespecialcharacterslikes: `El número de teléfono no puede incluir caracteres especiales como "'?&/&#60; &#62;\\s#!%$%^*-`,
    txt_global_std_writemessage: 'Escribir pequeño mensaje',
    txt_global_std_costoverview: 'Resumen de costos',
    txt_global_std_p2pyouandmembersborrowingyourcarneedtoconnecttheapptoyourcarsbluetooth: 'Usted y los miembros que toman prestado su automóvil deben conectar la aplicación al sistema Bluetooth de su automóvil antes de conducir.',
    txt_global_std_yourreportisready: '¡Tu informe está listo!',
    txt_global_std_needstobeavalidphonenumbermustbeavalidsizeandcanonlyincludenumbersand: 'Tiene que ser un número de teléfono válido. Debe tener un tamaño válido y solo puede incluir números y +',
    txt_global_std_week: 'Semana',
    txt_global_std_reviewbeforepublishing: 'Revisar antes de publicar',
    txt_global_std_addnewuser: 'Añadir nuevo usuario',
    txt_global_std_p2pfaq3q: '¿Qué pasa si no puedo encontrar dónde está estacionado el auto?',
    txt_global_std_typeofscore: 'Tipo de puntuación',
    txt_global_std_p2pthefollowingbonuswillbegiven: 'Se otorgará el siguiente bono.',
    txt_global_std_JyrkiainenFacts: 'Ha crecido rodeada de deportes de motor en una familia de carreras, hija de Minna Sillankorva, Campeona del Mundo de Rally Femenino de 1991 y nueve veces Campeona de Finlandia.',
    txt_global_std_yourtripsinsight: 'Información sobre tus viajes',
    txt_global_std_account1: 'Cuenta:',
    txt_global_std_toptrips: 'Los mejores viajes',
    txt_global_std_fax: 'Fax',
    txt_global_std_highusageparamamount: 'Uso alto {cantidad}.',
    txt_global_std_startyourcarandmakesureyourbluetoothison: 'Encienda su automóvil y asegúrese de que su bluetooth esté encendido.',
    txt_global_std_selectaminimumoftwogroupsthatshouldcompeteagainsteachotherinthechallenge: 'Seleccione un mínimo de dos grupos que deben competir entre sí en el desafío.',
    txt_global_std_examplevalue: 'Valor de ejemplo',
    txt_global_std_PolicySchedule1: 'CALENDARIO',
    txt_global_std_p2ppaywith: 'Pagar con',
    txt_global_std_german: 'Alemán',
    txt_global_std_presstoconnect: 'Presiona para conectar',
    txt_global_std_connectiontoyourcar: 'Conexión a tu coche',
    txt_global_std_buyfiasdc: 'Comprar FIA SDC',
    txt_global_std_copiedtoclipboard: '¡Copiado al portapapeles!',
    txt_global_std_therequestwasunacceptableoftenduetomissingarequiredparameter: 'La solicitud era inaceptable, a menudo debido a que faltaba un parámetro obligatorio.',
    txt_global_std_thedescriptioncantbelongerthan180characters: 'La descripción no puede tener más de 180 caracteres.',
    txt_global_std_previewapp: 'Vista previa de la aplicación',
    txt_global_std_usersuccessfullyadded2: 'Usuario añadido con éxito',
    txt_global_std_waitingtodownloadyourapp: 'Esperando para descargar tu aplicación',
    txt_global_std_fiaquarterfinalparticipaterule2020: 'Para participar en la final de clasificación, debes convertirte en uno de los 20 mejores pilotos o uno de los 3 mejores pilotos de los 3 mejores equipos.',
    txt_global_std_aphonenumbermayonlyincludenumerics: 'Un número de teléfono solo puede incluir números,',
    txt_global_std_challengesuccessfullyremoved: 'Desafío eliminado con éxito',
    txt_global_std_topfeatures: 'Características principales',
    txt_global_std_p2pkintomonthlyfee: 'Cuota mensual de Kinto',
    txt_global_std_pickyourcolortheme: 'Elige tu tema de color',
    txt_global_std_calledsalvage: 'Salvamento llamado',
    txt_global_std_toreportitnow: 'para denunciarlo ahora.',
    txt_global_std_cimage9d: 'Solo use el control de crucero cuando esté plano, de lo contrario, mantenga el gas constante.',
    txt_global_std_downloadingdata: 'Descarga de datos',
    txt_global_std_confirm2: 'Confirmar',
    txt_global_std_cimage19d: '¡Mantenga el gas constante, NO, la velocidad constante!',
    txt_global_std_sendchallengeinvitation: 'Enviar invitación de desafío',
    txt_global_std_validforxxminutes: '(Válido por 6 minutos)',
    txt_global_std_TaxInvoice6: 'NOTICIA IMPORTANTE:',
    txt_global_std_male: 'Masculino',
    txt_global_std_getanoverviewofyourcustomerportfolio: 'Obtenga una visión general de su cartera de clientes',
    txt_global_std_exceededinsureddistance: 'Ahora ha excedido su millaje anual y no recibirá una compensación total por los daños. Comuníquese con nosotros para que podamos ajustar el kilometraje y asegurarnos de que esté debidamente asegurado.',
    txt_global_std_howwilligetpaidforaclaim: '¿Cómo me pagarán por un reclamo?',
    txt_global_std_pleasecontactsupportlinkalllowercaseifyouhavenotreceivedthereaderafter7businessdays: '{Contacte con SupportLinkAllLowerCase} si no ha recibido el lector después de 7 días hábiles.',
    txt_global_std_howwewantyoudata: 'Cómo queremos tus datos',
    txt_global_std_getinsured: 'estar asegurado',
    txt_global_std_viewwhoisparticipatinginthechallenge: 'Ver quién está participando en el desafío',
    txt_global_std_resendtemporarypassword: 'Reenviar contraseña temporal',
    txt_global_std_day: 'Día',
    txt_global_std_sendalinksothatthepersondrivingyourcarcandownloadtheapp: 'Envía un enlace para que la persona que conduce tu coche pueda descargar la aplicación.',
    txt_global_std_documentcar: 'coche de documentos',
    txt_global_std_competitionrulesapplied: 'Reglas de competencia aplicadas',
    txt_global_std_renewinsurance: 'renovar seguro',
    txt_global_std_incabtraining: 'entrenamiento en el coche',
    txt_global_std_testingnewfronttranslation3: 'testingnewfronttranslation3 editar',
    txt_global_std_reporthistory: 'Historial de informes',
    txt_global_std_yournicknameshouldcontainatleastthree2: 'Su apodo debe contener al menos tres (3) caracteres y ningún carácter especial.',
    txt_global_std_seeperiod: 'Detalles',
    txt_global_std_areyousureyouwanttodeletethisitem: '¿Está seguro de que desea eliminar este elemento?',
    txt_global_std_validto: 'Válido hasta',
    txt_global_std_downloadpersonaldata: 'Descargar datos personales',
    txt_global_std_seedemo: 'Ver demostración',
    txt_global_std_MSIG_Onboarding6: 'Lo que tienes que hacer ahora es:',
    txt_global_std_worldchallenge2022: 'Desafío Mundial 2022',
    txt_global_std_sidekickprivacypolicy: 'política de privacidad',
    txt_global_std_fiaheatrule2020: 'Debe conducir más de 10 km durante una serie (semana) para obtener una puntuación en la clasificación de la serie.',
    txt_global_all_weekend: 'Fin de semana',
    txt_global_std_Fia_Receipt_Text12: 'Si tiene alguna pregunta, envíenos un correo electrónico:',
    txt_global_std_p2pselectedowner: 'Propietario seleccionado',
    txt_global_std_MSIG_InsuranceLetter30: 'MS First Capital Insurance Ltd',
    txt_global_std_automotivecompanydescription: 'Nuevos modelos de propiedad y compromiso del conductor.',
    txt_global_std_yourinsuranceexpires: 'Su seguro vence: {insuranceenddate} Recuerde agregar su millaje actual (valor en km) antes de que venza su seguro.',
    txt_global_std_location2: 'Ubicación',
    txt_global_std_youneedtofillinallnecessaryfields: 'Necesitas llenar todos los campos necesarios',
    txt_global_std_retry123: 'Rever',
    txt_global_std_apicreator: 'Creador de API',
    txt_global_std_resetyourpassword: 'Restablecer su contraseña',
    txt_global_std_hello: 'Hola',
    txt_global_std_nogoback: 'No, ¡regresa!',
    txt_global_std_KristofferssonShortDescription: 'Campeón del mundo de RX 2017 y 2018',
    txt_global_std_p2pdeny: 'Negar',
    txt_global_std_youhavereducedtheaveragecoemissionsofyourcarby: 'Has reducido las emisiones medias de CO₂ de tu coche en un {percent}',
    txt_global_std_p2pleftthegroup: 'dejó el grupo',
    txt_global_std_notavailableindemo: 'No disponible en demostración',
    txt_global_std_publishunpublish: 'Publicar / Despublicar',
    txt_global_std_wanttojoinanevent: '¿Quieres unirte a un evento?',
    txt_global_std_PSolbergWhyjoin: 'Próximamente, en breve, pronto...',
    txt_global_std_moveyourselfandotherstosecure: 'Muévase usted y los demás a un lugar seguro. En caso de lesión llamar al:',
    txt_global_std_p2pnotavalidprice: 'No es un precio válido',
    txt_global_std_cimage37h: 'APRENDE HASTA DONDE RUEDA TU VEHÍCULO A DIFERENTES VELOCIDADES',
    txt_global_std_hereswhereyouhavethelargestimprovementpossibility: 'Aquí es donde tienes la mayor posibilidad de mejora',
    txt_global_std_areyousureyouwanttodeletethechallengeoncedeletedyouwillnolongerbeabletoseethechallengeresultsparticipantswillstillbeabletoseetheirtripsinthefiasdcapp: '¿Seguro que quieres eliminar el desafío? Una vez eliminado, ya no podrá ver los resultados del desafío. Los participantes aún podrán ver sus viajes en la aplicación FIA SDC.',
    txt_global_std_year: 'Año',
    txt_global_std_phonenumbercannotincludecharacterslike6062s: `Número de teléfono No puede incluir caracteres como "'?&/&#60; &#62;\\s#!%$%^*-`,
    txt_global_std_whenyouhavecompletedyourfirstdrivewithenerfy: 'Cuando haya completado su primer viaje con Enerfy, podrá ver su costo y facturas.',
    txt_global_std_onboardingtype: 'Tipo de incorporación:',
    txt_global_std_enteravalidemailaddress: 'Introduzca una dirección de correo electrónico válida.',
    txt_global_std_showstimesincethevehiclehasbeenparked: 'Muestra el tiempo desde que el vehículo ha sido estacionado.',
    txt_global_std_200kmlockedkey: 'Conduce una distancia de 200 km para ganar esta insignia.',
    txt_global_std_twofactorenabled1: 'Dos factores habilitados',
    txt_global_std_examplemessagesms: '¡Hola! Este es un ejemplo de mensaje de texto.',
    txt_global_std_p2pspecifiedprice: 'Precio especificado',
    txt_global_std_oilandoilfilter: 'Aceite y filtro de aceite',
    txt_global_std_maximizeheader: 'Maximizar',
    txt_global_std_daysdriven: 'Días conducidos',
    txt_global_std_p2pwithfamilyandfriends: 'con familiares y amigos',
    txt_global_std_p2pfaq9q: '¿Qué pasa si mi amigo devuelve el auto antes de tiempo?',
    txt_global_std_joinclubsevent: 'Únete al evento del Club',
    txt_global_std_thesamegeographicalroutewillbescoredamaximumof10timesinoneday: '• Se puntuará un mismo recorrido geográfico un máximo de 10 veces en un día.',
    txt_global_std_livetopdrivers: 'Los mejores pilotos en vivo',
    txt_global_std_p2pcostsplitinvoice: 'Factura de división de costos',
    txt_global_std_news: 'Actividad',
    txt_global_std_authenticatedrequest: 'Solicitud autenticada',
    txt_global_std_enterpasswordtoremove: 'Introduce la contraseña para eliminar',
    txt_global_std_loadingapps: 'Cargando aplicaciones...',
    txt_global_std_SendMyPosition_Hello: 'Hola',
    txt_global_std_basecolor: 'Color de base',
    txt_global_std_enddateisrequired: 'La fecha de finalización es obligatoria',
    txt_global_std_sendalinksothatthepersondrivingyourcarcandownloadtheappnameapp: 'Envíe un enlace para que la persona que conduce su automóvil pueda descargar la aplicación {appname}.',
    txt_global_std_reportaccidentbottomtext: 'Cargue fotos de su automóvil dentro de las 24 horas posteriores a su informe. Puede hacer clic en su informe anterior para agregarle imágenes.',
    txt_global_std_therearenoongoinggamesrightnow: 'No hay juegos en curso en este momento.',
    txt_global_std_loginsignup: 'Iniciar sesión Registrarse',
    txt_global_std_alertweredrivingyoudrivingbody: 'Recuerde iniciar y detener sus viajes en la aplicación para obtener su puntaje de manejo.',
    txt_global_std_connectyourgarminwatch: 'Conecta tu reloj Garmin',
    txt_global_std_pickendtime: 'Elige la hora de finalización',
    txt_global_std_NewPW_Text3: 'es:',
    txt_global_std_BeforeFirstBill_Text2: '¿Qué voy a pagar?',
    txt_global_std_wildfirelockedkey: 'Conduzca con un nivel promedio de 4.4 o mejor durante una semana para desbloquear este logro y ganar 5000 puntos para su billetera Kinto.',
    txt_global_std_p2ptoconfirmprice: 'para confirmar el precio especificado',
    txt_global_std_selectgroupsintheformandclickonthegeneratebuttonaccessthecode: 'Seleccione grupos en el formulario y haga clic en el botón generar acceda al código',
    txt_global_std_or: 'o',
    txt_global_std_4thdate: '4to',
    txt_global_std_setupmanualstartstop: 'Configuración: inicio y parada manual',
    txt_global_std_yourdrivingstatsforthistripcomparedtoyour30daysaverage: 'Sus estadísticas de manejo para este viaje en comparación con su promedio de 30 días.',
    txt_global_std_wrongformat: '¡Formato erróneo!',
    txt_global_std_driventimeperperiod: 'Tiempo conducido por periodo',
    txt_global_std_speed: 'Velocidad',
    txt_global_std_events_overview_total: 'Total',
    txt_global_std_MSIG_InsuranceLetter41: 'Ley de Transporte por Carretera, 1987 (Malasia)',
    txt_global_std_appcreator: 'Creador de aplicaciones',
    txt_global_std_earnpercentpremiumreductionperperiod: 'Gane {porcentaje} de reducción de prima por período',
    txt_global_std_activationcodevalidated: 'Código de activación validado',
    txt_global_std_countday: '{contar} día',
    txt_global_std_selectfriend: 'Seleccionar amigo',
    txt_global_std_july: 'Julio',
    txt_global_std_ConfirmFia_Text7: 'Recuerda llegar al lugar 10 minutos antes de la franja horaria seleccionada.',
    txt_global_std_KristofferssonChamp: 'Campeón del mundo de RX 2017 y 2018',
    txt_global_std_wewillnowbuildyourdemoapp: 'Ahora construiremos su aplicación de demostración.',
    txt_global_std_youdroveextremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedthisbadge: '¡Condujiste muy bien! ¡Tu {scoretype} promedio fue mejor que {score} durante una semana! ¡Te has ganado esta insignia!',
    txt_global_std_congrats: '¡Felicidades!',
    txt_global_std_p2ppushtitlehasnowfinalizedthebooking: 'Reserva finalizada',
    txt_global_std_noinvoicesyet: 'No hay facturas todavía',
    txt_global_std_consentInfoNo: 'No ha activado su "consentimiento" en la configuración. Cuando el "consentimiento" no está habilitado, no podremos compartir todos los emocionantes concursos y consejos proporcionados en la aplicación Tryg Drive de manera continua.',
    txt_global_std_friends: 'Amigos',
    txt_global_std_searchbynamegrouporscore: 'Buscar por nombre, grupo o puntuación',
    txt_global_std_balancing: 'Equilibrio',
    txt_global_std_ChicheritInterests: 'Próximamente, en breve, pronto...',
    txt_global_std_ayourpolicybeginsonthedateandtimewhenyouelectronicallysigntheapplicationandmakethedownpaymentonyourpolicy: 'R: Su póliza comienza en la fecha y hora en que firma electrónicamente la solicitud y realiza el pago inicial de su póliza.',
    txt_global_std_manageagreementshere: 'Gestionar acuerdos aquí',
    txt_global_std_inmostcarstheobdsocket: 'En la mayoría de los automóviles, el enchufe OBD está a la izquierda debajo del volante. Si aún no puede encontrar el conector OBD, haga clic aquí.',
    txt_global_std_p2pgiveadiscountiffriend: 'Ofrezca un descuento si su amigo conduce su automóvil de manera segura.',
    txt_global_std_improvementspeedarea: 'Área de velocidad de mejora',
    txt_global_std_otherreasonsthatyourcarisnotshowingupinthelist: 'Otras razones por las que su automóvil no aparece en la lista:',
    txt_global_std_success: '¡Éxito!',
    txt_global_std_phonenumberforinsurance: 'Ingrese el número de teléfono que utilizó cuando se inscribió en su seguro.',
    'txt_global_std_Al-KhelaifiWhyjoin': 'Porque disfruto de diferentes tipos de desafíos y soy un piloto competitivo.',
    txt_global_std_ifyoureferafriendyoubothgetnok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsuranceamountinmarkedfontshowsvalidamountsaddedtothepiggybankpaymentinordertoreceiveadrivingdividend75ofthedistancetraveledmustbecompletedwiththeappandchiptenfoldremembertohaveboththeappandbluetoothonwhenyoudriveandthetripwillberegistered: 'Si recomiendas a un amigo, ambos obtienen NOK 250 en la alcancía. Esto requiere que ambos sean clientes de Tryg durante al menos un mes después de la fecha de inicio del seguro. Cantidad en fuente marcada, muestra cantidades válidas añadidas a la alcancía. Pago\n' +
      'Para recibir un dividendo de conducción, el 75% de la distancia recorrida debe completarse diez veces con la aplicación y el chip. Recuerda tener tanto la aplicación como el Bluetooth activados cuando conduces, y el viaje quedará registrado.',
    txt_global_std_startdrivingsaferwithmefollowthelinkbelowandget10kmdistancetodrivewithenerfy: '¡Empieza a conducir más seguro conmigo! Siga el enlace a continuación y obtenga 10 km de distancia para conducir con Enerfy.',
    txt_global_std_parkingaccident2: 'Accidente de estacionamiento',
    txt_global_std_achievementstitle: 'logros',
    txt_global_std_maintainingintendedspeedisutmostimportantforfueleconomymindwanderingandlackoffocusoftencausesspeedvariationupanddowncausingunnecessaryaccelerationshenceincreasesfuelconsumptionnew: 'Mantener la velocidad prevista es sumamente importante para el ahorro de combustible. La mente errante y la falta de concentración a menudo provocan variaciones de velocidad hacia arriba y hacia abajo, lo que genera aceleraciones innecesarias y, por lo tanto, aumenta el consumo de combustible.',
    txt_global_std_inthenextpopupbr1makesurebprecisebissettobonbbr2chooseballowonceb: 'En la siguiente ventana emergente: <br> 1. Asegúrese de que <b>Preciso</b> esté establecido en: <b>Activado.</b> <br> 2. Elija <b>Permitir una vez.</b>',
    txt_global_std_p2pgoheretoinvitemembersandstartlendingyourcar: 'Vaya aquí para invitar a miembros y comenzar a prestar su automóvil.',
    txt_global_std_p2piamhavingproblemswiththelinkloggingin: 'Tengo problemas con el enlace/iniciar sesión.',
    txt_global_std_allowpushnotifications: 'Permitir notificaciones automáticas',
    txt_global_std_currency: 'kr',
    txt_global_std_subscribe: 'Suscribir',
    txt_global_std_MikkelsenFacts: 'Andreas tiene una amplia experiencia en deportes. Ha competido en esquí alpino y fue miembro del equipo nacional junior de esquí alpino. En 2003 y 2004 compitió en motocross, representando también a la selección nacional juvenil. Terminó tercero en el campeonato mundial de rally en 2014, 2015 y 2016, acumulando tres victorias y 20 podios.',
    txt_global_std_expireson: 'Expira el',
    txt_global_std_sentlogfiles: 'Archivos de registro enviados',
    txt_global_std_athelowerthescorethemoresavingsyoucanearnyouwillseeyourpotentialsavingswitheachtrip: 'R: Cuanto más bajo sea el puntaje, más ahorros puede ganar. Verás tus ahorros potenciales con cada viaje.',
    txt_global_std_encouragetoavoiddistractions: 'Anime al conductor a evitar distracciones',
    txt_global_std_nationalitycolon: 'Nacionalidad:',
    txt_global_std_pleaseselectvehiclesregistrationnumberyouaredriving: 'Seleccione el número de registro del vehículo que conduce.',
    txt_global_std_latesttrips: 'Últimos viajes',
    txt_global_std_avgscore123: 'Promedio puntaje',
    txt_global_std_other: 'Otro',
    txt_global_std_twofactorauthenticationcode: 'Código de autenticación de dos factores',
    txt_global_std_writeamessagetoafriendoragroup: 'Escribir un mensaje a un amigo o a un grupo',
    txt_global_std_resendactivationcode: 'Reenviar código de activación',
    txt_global_std_personalizeyouraccount: 'Personaliza tu cuenta',
    txt_global_std_achievementtool_upsell_pitch: 'Crea tus propias insignias y logros inspiradores para;',
    txt_global_std_addemail2: 'añadir correo electrónico',
    txt_global_std_addexistinguser: 'Agregar usuario existente',
    txt_global_std_miles: 'Millas',
    txt_global_std_drivewithanaveragescoretypeofscoreorbetterforaweektounlockthisbadge: 'Conduce con un {scoretype} promedio de {score} o mejor durante una semana para desbloquear esta insignia.',
    txt_global_std_p2pfaq6a: '1.Abra el chat grupal del que desea salir.\n' +
      '2.Presione el icono de configuración en la parte superior derecha del encabezado del chat grupal.\n' +
      '3. Elija abandonar este chat grupal.',
    txt_global_std_writeyourregistrationnumber: 'Escriba su número de registro',
    txt_global_std_p2pcarrentoverview: 'Resumen de alquiler de coches',
    txt_global_std_cimage35h: 'ENCUENTRE EL EQUIPAMIENTO ÓPTIMO PARA COMENZAR',
    txt_global_std_Fia_Receipt_Text4: 'Puede ingresar esto manualmente al crear una cuenta.',
    txt_global_std_discovermore: 'Descubrir más',
    txt_global_std_BeforeFirstBill_Text5: 'Siempre puede realizar un seguimiento de sus costos en la aplicación {appName}, allí puede ver el costo de cada unidad y el monto total del mes. Vaya a "Costos" en la aplicación para ver sus costos.',
    txt_global_std_coolcatsunisshining: 'el sol del gato genial está brillando',
    txt_global_std_riskydecisionwithintrips: 'Decisión arriesgada dentro de los viajes',
    txt_global_std_apireference: 'Referencia de la API',
    txt_global_std_defaulthere: 'aquí',
    txt_global_std_above1000locked: 'Conduce con una puntuación superior a 1000 para desbloquear este logro.',
    txt_global_std_allownotifications: 'Permitir notificaciones',
    txt_global_std_previousbonusperiod: 'Período de bonificación anterior',
    txt_global_std_challengeaverage: 'Desafío promedio',
    txt_global_std_uploadatleastfourphotosofyourcarfrontbackleftandrightsides: 'Sube al menos 4 fotos de tu coche. Lados delantero, trasero, izquierdo y derecho.',
    txt_global_std_teamleaderslatestscore: 'Última puntuación de Teamleaders:',
    txt_global_std_p2ppleaseselectavehicle: 'Por favor seleccione un vehículo',
    txt_global_std_wallet: 'Cartera',
    txt_global_std_earnedpoints: 'Puntos Ganados',
    txt_global_std_cohabiting: 'Cohabitando',
    txt_global_std_radiatorflush: 'Lavado del radiador',
    txt_global_std_single: 'Único',
    txt_global_std_p2pincompletebooking: 'Reserva incompleta',
    txt_global_std_electric: 'Eléctrico',
    txt_global_std_notallowedtoorderanobdreader: 'No está permitido pedir un lector OBD',
    txt_global_std_finishadriveatscoretypescoreorbettertounlockthisachievementandearnpointspoints: 'Termina un viaje en {score} o mejor para desbloquear este logro y ganar {points} puntos.',
    txt_global_std_tinaQuote: 'Poder desde adentro y el poder de elección',
    txt_global_std_threecharactersbetweenazand09: 'Requiere tres caracteres entre A-Z y 0-9',
    txt_global_std_saveenergyandco2: 'Ahorre energía y CO₂',
    txt_global_std_trainingdate: 'Fecha de entrenamiento',
    txt_global_std_successsenddetails: 'Detalles enviados con éxito',
    txt_global_std_sendinvitetofriend: 'Enviar invitación a un amigo',
    txt_global_std_pigsinvitedlocked: 'Obtienes esto cuando has reclutado a un amigo para Sidekick',
    txt_global_std_nodatafoundstartdrivingtoseethisstatistic: 'Datos no encontrados. ¡Empieza a conducir para ver esta estadística!',
    txt_global_std_okdriving: '¡Bien conduciendo!',
    txt_global_std_Fia_Receipt_Text5: '',
    txt_global_std_poweredby: 'energizado por',
    txt_global_std_demotext6: '¡Te ayudaré a conducir seguro! Te guiaré en tu viaje hacia las cinco estrellas.',
    txt_global_std_addall: 'Añadir todo',
    txt_global_std_insuranceoverview: 'Resumen de seguros',
    txt_global_std_HansenInterests: 'Entrenamiento, automovilismo y barista',
    txt_global_std_useralreadyexists: '¡No se pudo crear la cuenta, el correo electrónico ya existe!',
    txt_global_std_rwmnodrivestitle: 'Aún no tienes ningún viaje.',
    txt_global_std_editchallenge: 'Editar desafío',
    txt_global_std_monthlycost: 'Costo mensual',
    txt_global_std_welcometoyournewapp: 'Bienvenido a tu nueva aplicación.',
    txt_global_std_weknowthisiscrazy: 'Sabemos que esto es una locura...',
    txt_global_std_ayoucanfileaclaimbycontactingphonenoorclickingonfileaclaiminyouragentapp: 'R: Puede presentar un reclamo comunicándose con {phoneNo} o haciendo clic en Presentar un reclamo en su aplicación {agent}.',
    txt_global_std_damageguide: 'Guía de daños',
    txt_global_std_cimage36h: 'PLAN PARA EVITAR PARADA TOTAL EN OBSTÁCULOS',
    txt_global_std_speedgappoints: 'Puntos de diferencia de velocidad',
    txt_global_std_howdoisetupautomaticrenewal: '¿Cómo configuro la renovación automática?',
    txt_global_std_availablepoints: 'Puntos disponibles',
    txt_global_std_locationservicealways: 'Servicio de Localización (Siempre)',
    txt_global_std_signupwith: 'Regístrese con {nombre}',
    txt_global_std_enteravalidplatenumber: 'Introduce un número de placa válido.',
    txt_global_std_p2pusershareofcost: '{name} parte del costo',
    txt_global_std_finalresult: 'Resultado final',
    txt_global_std_focus: 'Enfoque',
    txt_global_std_yourbluetoothisntturnedon: 'Tu Bluetooth no está activado',
    txt_global_std_nosplitsavailableforthisrun: 'No hay divisiones disponibles para esta carrera',
    txt_global_std_centralzone: 'Zona Centro',
    txt_global_std_technicalservice: 'Servicio tecnico',
    txt_global_std_cimage9h: 'MANTENGA EL GAS CONSTANTE EN LUGAR DE LA VELOCIDAD',
    txt_global_std_okdriving2: 'bien conduciendo',
    txt_global_std_coverage: 'Cobertura',
    txt_global_std_selectaccount: 'Seleccionar cuenta',
    txt_global_std_ifyouhavebluetoothyoucanturnonautomatic: 'Si tiene Bluetooth en su automóvil, puede activar el inicio y la parada automáticos.',
    txt_global_std_p2pgroupnamechanged: 'Se cambió el nombre del grupo',
    txt_global_std_address: 'Dirección',
    txt_global_std_discoverourheats: 'Descubre nuestros eventos',
    txt_global_std_sendmessage: 'ENVIAR MENSAJE',
    txt_global_std_p2paddedby: 'Añadido por',
    txt_global_std_turnonhazardlights: 'Enciende las luces de emergencia',
    txt_global_std_soon: 'pronto.',
    txt_global_std_alluserscompeteindividually: 'Todos, los usuarios compiten individualmente',
    txt_global_std_ecofriendweeklyunlockedkey: 'Ha ahorrado más de {co2value}% CO₂ esta semana al conducir de manera ecológica. ¡Gracias por cuidar el medio ambiente!',
    txt_global_std_thepasswordminimumlengthis8charactersandmustinclude1numeralandspecialcharacter: 'La longitud mínima de la contraseña es de 8 caracteres y debe incluir 1 número y un carácter especial',
    txt_global_std_lastname: 'Apellido',
    txt_global_std_translationtool_upsell_pitch: 'Administre sin esfuerzo sus propias traducciones y actualizaciones de idiomas para;',
    txt_global_std_wewillsendtheobdreadertotheaddressyouhaveregisteredwithusneedtochangeanythingcontactsupport: 'Le enviaremos el lector OBD a la dirección que haya registrado con nosotros. ¿Necesitas cambiar algo? {enlace de soporte de contacto}.',
    txt_global_std_loyaltypages: 'Páginas de lealtad',
    txt_global_std_managepermissions: 'Administrar permisos',
    txt_global_std_addressline1: 'Dirección Línea 1',
    txt_global_std_youcanfindtheoutlethere: 'Puedes encontrar la salida aquí',
    txt_global_std_whatwillhappeniftheobddeviceisactive: '¿Qué pasará si el dispositivo OBD está activo pero olvido iniciar la aplicación?',
    'txt_global_std_Al-KhelaifiChamp': 'Dos veces Campeón del Campeonato de Turismos de Qatar. Dos veces Campeón Nacional de Qatar Sprint. Ganador de la clase de la Copa 1 de la carrera de resistencia Spa 12H 2017',
    txt_global_std_sharemycar: 'compartir mi coche',
    txt_global_std_hereyoucanuploadanyextrainformationregardingyourclaim: 'Aquí puede cargar cualquier información adicional con respecto a su reclamo. Todos los campos son opcionales.',
    txt_global_std_testglobal: 'prueba global',
    txt_global_std_chassisenginenumber: 'Número de chasis/motor',
    txt_global_std_youarenowloggedin: 'Ahora está conectado!',
    txt_global_std_engagingteammembersinasharedmissionisgoodforproductivity: 'Involucrar a los miembros del equipo en una misión compartida es bueno para la productividad',
    txt_global_std_letmein: '¡Déjame entrar!',
    txt_global_std_badformattingwithkey: 'Mal formateo con clave',
    txt_global_std_drives2: 'Unidades',
    txt_global_std_good: 'Bueno',
    txt_global_std_questionsandanswers: 'Preguntas y respuestas',
    txt_global_std_uploadfromlist: 'Cargar desde la lista',
    txt_global_std_somethingtherenewfake: 'Algo nuevo falso',
    txt_global_std_drivemorethan1kilometertogetmoredetailedanalyticsaboutyourtrip: 'Conduzca más de 1 kilómetro para obtener análisis más detallados sobre su viaje.',
    txt_global_std_enteryouractivationcodeyoureceived: 'Ingrese su código de activación que recibió en su carta de activación o correo electrónico cuando se registró.',
    txt_global_std_cimage16d: 'Planifique cada aumento de velocidad para mantener la velocidad máxima planificada o para rodar tantos metros como sea posible.',
    txt_global_std_yourtripwasnotregisteredifyouhadyourpluginconnected: 'Su viaje no fue registrado. Si tenías tu plug-in conectado cuando conducías el coche pero no tenías activados los servicios de app, Bluetooth o GPS/ubicación para que registre datos de conducción, los viajes completados se cobran después en los niveles premium 10 a 15.',
    txt_global_std_byjoiningthechallengeyouacceptthe: 'Al unirte al desafío aceptas la',
    txt_global_std_yourprofilephotowassaved: 'Tu foto de perfil fue guardada',
    txt_global_std_imagepickerunlocked: 'Elija el icono de la insignia desbloqueada',
    txt_global_std_ecofriend: 'amigo ecologico',
    txt_global_std_p2punavaliblebetween: 'No disponible entre',
    txt_global_std_youaregoodtogostartdriving: 'Estás listo para irte, ¡empieza a conducir!',
    txt_global_std_thiswindowwillcloseinclosesecondsseconds: 'Esta ventana se cerrará en {closeSeconds} segundos',
    txt_global_std_failedtopublishreviews: 'Error al publicar Reseñas!',
    txt_global_std_FiaPassword_Text3: 'es',
    txt_global_std_selectyourcountry: 'Selecciona tu pais',
    txt_global_std_writecurrentpassword: 'Escriba la contraseña actual',
    txt_global_std_compare: 'Comparar',
    txt_global_std_zoomtofit: 'Zoom para ajustar',
    txt_global_std_head2head2: 'Cabeza 2 Cabeza',
    txt_global_std_p2pyouwillnotbeabletosendorreceivebookingsfromthisfriendanymore: 'Ya no podrás enviar ni recibir reservas de este amigo.',
    txt_global_std_addbackground: 'Agregar fondo',
    txt_global_std_KristofferssonInterests: 'Mi familia y el fitness',
    txt_global_std_passwordforname: 'Nueva contraseña para {nombre}',
    txt_global_std_collectedpoints: 'PUNTOS ACUMULADOS',
    txt_global_std_showdataby: 'Mostrar datos por',
    txt_global_std_elite: 'Élite',
    txt_global_std_yourinsurancehasexpired: 'Su seguro ha expirado.',
    txt_global_std_MarklundMusic: 'Me gusta escuchar documentales interesantes en la radio mientras conduzco, sin embargo, cuando necesito concentrarme, prefiero que sea totalmente silencioso.',
    txt_global_std_congratulationsyouhavesuccessfullyconnectedtoyourcar: '¡Felicidades! Te has conectado con éxito a tu coche.',
    txt_global_std_p2pcomingsoon: 'Próximamente, en breve, pronto',
    txt_global_std_ofcourseyoumayaddphotoslater: 'Por supuesto, puedes agregar fotos más tarde.',
    txt_global_std_pleaseselectaprojectfrommyprojects: ' Seleccione un proyecto de Mis proyectos.',
    txt_global_std_pleasecheckyouremailtoverify: 'Por favor revise su correo electrónico para verificar',
    txt_global_std_connectyourself: '¡Conéctate!',
    txt_global_std_startdate: 'Fecha de inicio',
    txt_global_std_youcannoteditthistranslationfromvisualeditorduetotechnicalreasonspleasecontactusourdeveloperswillupdateitforyou: 'No puede editar esta traducción desde el editor visual por razones técnicas. Póngase en contacto con nosotros, nuestros desarrolladores lo actualizarán por usted.',
    txt_global_std_redeem: 'Redimir',
    txt_global_std_warikan_onboarding8: '4. Invite a los miembros a compartir el automóvil para crear un grupo compartido',
    txt_global_std_loyaltyemailheadertext: '¡y obtén recompensas!',
    txt_global_std_p2pemailchangedbookings: 'reservas modificadas',
    txt_global_std_enterpasswordtopublishchanges: 'Introduce la contraseña para publicar los cambios',
    txt_global_std_badgeisworthxpoints: 'Valor de la insignia: {puntos}P',
    txt_global_std_welcometoname: 'Bienvenido a {nombre de la aplicación}',
    txt_global_std_supportedfiles: 'Formato de archivo admitido: png, svg. máx. tamaño del archivo: X mb.',
    txt_global_std_p2pwearehappythatyouarehere: '¡Estamos felices de que estés aquí!',
    txt_global_std_categorytypeheader: 'Escribe',
    txt_global_std_earn3500pointsifyoubook15timesamonth: 'Gana 3.500 puntos al realizar 15 reservas en un mes.',
    txt_global_std_paynow: 'Pagar ahora',
    txt_global_std_showthisreceiptatcheckout: 'Muestre este recibo al finalizar la compra.',
    txt_global_std_jointco2savings: 'Ahorro conjunto de CO₂\n',
    txt_global_std_p2pinvoices: 'Facturas',
    txt_global_std_sparklockedkey: 'Conduzca con un nivel promedio de 4 o superior durante una semana para desbloquear este logro y gane 1000 puntos para su billetera Kinto.',
    txt_global_std_thefiasmartdrivingchallengeisaworldchallenge: 'El FIA Smart Driving Challenge es un desafío mundial en el que la inteligencia supera a la velocidad. Inspírate con el líder de tu equipo y compite con tu conducción diaria.',
    txt_global_std_renewyourinsuranceformonthofmonthatalowercost: 'Renueve su seguro para el mes de {mes} a un costo menor',
    txt_global_std_clubs: 'Clubs',
    txt_global_std_p2penterthetemporarypasswordsenttoyouremail: 'Ingrese la contraseña temporal enviada a su correo electrónico',
    txt_global_std_counthours: '{cuenta} horas',
    txt_global_std_zipcodeglobal: 'Código postal',
    txt_global_std_p2pconfirmed: 'Confirmado',
    txt_global_std_premiuminsurancecode: 'Código de seguro de prima',
    txt_global_std_adduserstogroup: 'Agregar usuarios al grupo',
    txt_global_std_p2pequallydivided: 'igualmente dividido',
    txt_global_std_afteryoustartdrivingyourinvoiceswillshowuphere: 'Después de empezar a conducir, sus facturas aparecerán aquí.',
    txt_global_std_saloon: 'Salón',
    txt_global_std_awesomestreak: '¡Impresionante racha!',
    txt_global_std_currentstatus: 'Estado actual:',
    txt_global_std_emailisalreadyaddedtobeinvited: 'El correo electrónico ya está agregado para ser invitado',
    txt_global_std_hereyoucandocumentyourcar: 'Aquí puede documentar su coche.',
    txt_global_std_include: 'Incluir',
    txt_global_std_web: 'Web',
    txt_global_std_p2pgoback: 'Regresa',
    txt_global_std_carrescue: 'Rescate de coches',
    txt_global_std_p2pyoudonthaveanyfriendsyet: 'Aún no tienes amigos.',
    txt_global_std_eventname: 'Nombre del evento',
    txt_global_std_this15discountcodecanberedeemedongarmincomonselectedgarminautomotivedevices: 'Este código de descuento del 15 % se puede canjear en garmin.com en dispositivos automotrices seleccionados de Garmin.',
    txt_global_std_yourdeviceisonitsway: 'Su dispositivo está en camino. Cuando llegue, todo lo que tienes que hacer es enchufarlo. ¡Mientras tanto, puedes unirte a un equipo e inscribirte en las eliminatorias!',
    txt_global_std_twoormoregroupscompeteagainsteachother: 'Dos o más grupos compiten entre sí.',
    txt_global_std_agreementnumber2: 'Numero de acuerdo:',
    txt_global_std_removingtheuserfromthegroupdoesnotdeletetheusersaccount: 'Eliminar al usuario del grupo no elimina la cuenta de los usuarios.',
    txt_global_std_pushdriveendlevel1: '¡Guau, tu conducción es de clase mundial! Pulse aquí para ver los detalles de su viaje.',
    txt_global_std_administrateandinviteusers: 'Administrar e invitar usuarios.',
    txt_global_std_ecofriendunlockedkey: 'Ha ahorrado más de {co2value}% CO₂ esta semana al conducir de manera ecológica. ¡Gracias por cuidar el medio ambiente!',
    txt_global_std_savemoneyonyourinsurance: '- Ahorre dinero en su seguro.',
    txt_global_std_afollowthesestepstoconnecttheapptoyourcar1pressthedrivebuttonintheapp2startyourcarsengine3yourcarshouldshowupintheapp4selectyourcar: 'R: Siga estos pasos para conectar la aplicación a su automóvil:\n' +
      '    1. Presione el botón de conducir en la aplicación\n' +
      '    2. Arranca el motor de tu coche\n' +
      '    3. Tu coche debería aparecer en la aplicación\n' +
      '    4. Selecciona tu coche\n',
    txt_global_std_firstdrive4: 'primer viaje',
    txt_global_std_p2pnothinghaschanged: 'Nada ha cambiado',
    txt_global_std_recruitmentamount: 'Monto de contratación',
    txt_global_std_repeatyourpassword: 'repita su contraseña',
    txt_global_std_selectcategory: 'Selecciona una categoría',
    txt_global_std_howdoidrivewellanswer: 'Un conductor seguro es un conductor consciente. La conducción segura se trata de mantenerse 100 % concentrado en su forma de conducir y en el entorno que lo rodea. Manténgase alerta y tenga en cuenta el ritmo del tráfico y otras circunstancias, como la lluvia, etc. AJUSTE SIEMPRE SU VELOCIDAD A LAS CONDICIONES PREDOMINANTES.',
    txt_global_std_choose: 'Elegir:',
    txt_global_std_ifyoucannotfindyourfriendorarenotregisteredasauseryourfriendencouragesyoutodownloadtheappnameappandgetaprofile: 'Si no puede encontrar a su amigo o no está registrado como usuario, su amigo lo alienta a descargar la aplicación {appname} y obtener un perfil',
    txt_global_std_addcarinvolved: 'Añadir coche implicado',
    txt_global_std_tinaDescription: '2008 Estuve históricamente en el Medio Oriente compitiendo con Nasser Saleh Al-Attiyha (Qatar), en este momento el perfil deportivo más grande allí: ganamos dos Copas del Mundo en 2008, una en la serie y otra en la serie Baja. ¡En esta constelación, solo ha habido un equipo deportivo tan exitoso en el mundo! Que hayamos tenido éxito en nuestros éxitos, me gustaría agradecer a la madre de Nasser, quien crió a su hijo para que no tuviera opiniones preconcebidas sobre el color de piel, el sexo o la religión de su lector de tarjetas. También lideramos el rally Dakar en Sudamérica en 2009 cuando tuvimos que romper la sexta etapa.\n' +
      '\n' +
      'Mi carrera en los deportes de motor comenzó en 1990. Desde entonces, he ganado tres títulos del Campeonato Mundial femenino, dos segundos lugares en Gr. N. campeonato mundial de rally y dos victorias en la copa del mundo en rally raid 2008 y 2009. Para mis principales méritos, espero un segundo y tercer puesto en el famoso rally Dakar.\n' +
      '\n' +
      'Quizás mis pilotos más famosos hayan sido Colin McRae, Jutta Kleinschmidt, Kenneth Eriksson y Ari Vatanen; Además, he navegado a una veintena de conductores internacionales.\n',
    txt_global_std_youhavenoactivechallengesrightnow: 'No tienes desafíos activos en este momento',
    txt_global_std_type2: 'Escribe',
    txt_global_std_addfriend: 'Añadir amigo',
    txt_global_std_confirmpurchase: 'Confirmar compra',
    txt_global_std_pullthebartoseehowyourleveleffectsyourdiscount: 'Mueva el marcador para ver cómo su nivel afecta su descuento.',
    txt_global_std_idontwanttoconnectnow: 'No quiero conectarme ahora',
    txt_global_std_p2ppushtitleyouhaveabookingtofinalize: 'Tienes una reserva por finalizar',
    txt_global_std_stateinsuranceinformation: '{estado} Información del seguro',
    txt_global_std_youhaveexceededthespeedlimitof: 'Ha excedido el límite de velocidad de {maxSpeed} km/h.',
    txt_global_std_copyinvitationlink: 'Copiar enlace de invitación',
    txt_global_std_carusage: 'Uso del coche',
    txt_global_std_selectyourcarmodeltoseethemostaccuratedata: 'Selecciona el modelo de tu coche para ver los datos más precisos',
    txt_global_std_createyourveryowncompanychallengeinthefiasdcandunderstandandmanagetheimpactyourteamhasonroadsafetyandco2emissions: 'Cree su propio desafío empresarial en el SDC de la FIA y comprenda y gestione el impacto que tiene su equipo en la seguridad vial y las emisiones de CO2.',
    txt_global_std_iobd2: 'iOBD2',
    txt_global_std_safedriverweeklylockedkey: 'Para obtener esta insignia, mantenga una puntuación superior a {score} durante una semana.',
    txt_global_std_connecttocarlockedkey: 'Conecte su aplicación al automóvil a través de Bluetooth para obtener esta insignia.',
    txt_global_std_p2pearnpointswhenyoudrivesafe: 'Gana puntos cuando conduces seguro',
    txt_global_std_connectwithbluetooth: 'Conectar con Bluetooth',
    txt_global_std_detailedstatisticsaboutyourdrivingwilldisplayherewhenyouhavedoneyourfirsttrip: 'Las estadísticas detalladas sobre su conducción se mostrarán aquí cuando haya hecho su primer viaje.',
    txt_global_std_entertheinvitationcodeyouhavereceivedfromyourworkspaceadministrator: 'Introduce el código de invitación que has recibido del administrador de tu espacio de trabajo',
    txt_global_std_division: 'DIVISIÓN',
    txt_global_std_selectfriendsoragroup: 'Seleccionar amigos o un grupo',
    txt_global_std_p2ppushtitleexpensesapproved: 'Gastos aprobados',
    txt_global_std_ifyourfriendregisterswiththereferralcodebothyouandyourfriendwillreceivenok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsurancetheamountisenteredinthepiggybankwhentherecruitmentcodehasbeenregisteredyouhaveafulltimeoverviewofyourrecruitsonthesavingsside: 'Si su amigo se registra con el código de referencia, tanto usted como su amigo recibirán NOK 250 en la alcancía (esto requiere que ambos sean clientes de Tryg durante al menos un mes después de la fecha de inicio del seguro).\n' +
      '\n' +
      'El importe se introduce en la hucha cuando se ha registrado el código de contratación. Tiene una visión general a tiempo completo de sus reclutas en el lado de los ahorros.',
    txt_global_std_stardriverlockedkey: 'Termina un recorrido con una puntuación de {puntuación} o mejor para desbloquear esta insignia. Puedes ganar esta insignia una vez al día.',
    txt_global_std_createyourowncompanychallengeinjustthreesteps: '¡Crea tu propio Company Challenge en solo tres pasos!',
    txt_global_std_p2puserguides: 'Guías de usuario',
    txt_global_std_describetheaccident: 'Describa el accidente',
    txt_global_std_kintoemailheadertext: '¡Únete a Kinto y obtén recompensas!',
    txt_global_std_status: 'Estado',
    txt_global_std_comingsoon: '¡Próximamente, en breve, pronto!',
    txt_global_std_ChicheritWhyjoin: 'Próximamente, en breve, pronto...',
    txt_global_std_needhelptogetstarted: '¿Necesitas ayuda para empezar?',
    txt_global_std_remainingpointstonextlevel: '{puntos} puntos restantes para {nivel}',
    txt_global_std_damagesalvagecall: 'por daños y perjuicios',
    txt_global_std_lookslikethisisempty: '¡Parece que esto está vacío!',
    txt_global_std_monthmon: 'lun',
    txt_global_std_selectdates: 'Seleccionar fechas',
    txt_global_std_createyourownappinafewclicks: 'Crea tu propia aplicación en unos pocos clics',
    txt_global_std_usernotfound: 'Usuario no encontrado.',
    txt_global_std_recruitfivefriendsandyouallcompete: 'Recluta a 5 amigos y todos competirán en la primera manga gratis',
    txt_global_std_shootingstarnamekey: '¡Estrella fugaz!',
    txt_global_std_conteactinformation: 'Información del contacto',
    txt_global_std_bestdriversrightnow: '¡Los mejores conductores ahora mismo!',
    txt_global_std_nameofapp: 'Nombre de la aplicación',
    txt_global_std_quantitytoshow: 'Cantidad a mostrar',
    txt_global_std_TaxInvoice8: 'La cobertura finaliza el día 61 si la prima no se paga dentro de los 60 días posteriores a la fecha de inicio de la póliza.',
    txt_global_std_checkifcorrectobdreader: 'Compruebe si el lector OBD está correctamente conectado.',
    txt_global_std_youhaveonlydistancekmleft: 'Solo te quedan {distancia} km.',
    txt_global_std_p2pfaq8q: '¿Cómo hago para que mi automóvil no esté disponible los días que lo estoy usando?',
    txt_global_std_thisisadrivingappforustobeabletomeasureyourdrivingweneedtoaccesstobbluetoothlocationservicesbandbmotionfitnessb: 'Esta es una aplicación de conducción. Para que podamos medir su conducción, necesitamos acceso a <b>Bluetooth, servicios de ubicación</b> y <b>movimiento y forma física.</b>',
    txt_global_std_HansenWhyjoin: 'Trabajaré en estrecha colaboración con mis compañeros de equipo e intentaré combinar los dos factores de la conducción inteligente. ¡Juntos intentaremos disfrutar de nuestra conducción, esforzarnos para hacerlo mejor y conducir de manera súper inteligente y segura! Al hacerlo, creo que podemos ganar todas las carreras, incluso la más grande de todas: ¡la carrera por salvar nuestro planeta!',
    txt_global_std_JyrkiainenMileage: 'Tengo carné de conducir desde hace solo casi un año, pero me encanta la ruta a la Laponia finlandesa, ¡el paisaje se vuelve cada vez más nevado!',
    txt_global_std_earn1500pointsifyoubook5timesamonth: 'Gana 1.500 puntos al realizar 5 reservas en un mes.',
    txt_global_std__ongoing: '(En curso)',
    txt_global_std_p2pdeletingofmessages: 'Eliminando {nocurrent} de {todelete} mensajes',
    txt_global_std_additionalfiles: 'Archivos adicionales',
    txt_global_std_p2pmystatscomingsoon: 'Mis estadísticas – Próximamente',
    txt_global_std_afriend: 'Un amigo',
    txt_global_std_PolicySchedule29: 'SE APLICARÁ UN EXCESO ESCALONADO DE HASTA $2,000 EN CASO DE UN ACCIDENTE SUJETO A LA PUNTUACIÓN PROMEDIO.',
    txt_global_std_areyouabsolutelysuperdupersurethatyouwanttoexchangeyourpreciouspointsnowandnotsavethemforlater: '¿Está absolutamente seguro de que desea intercambiar sus preciados puntos ahora y no guardarlos para más adelante?',
    txt_global_std_supportingcluestowhy: 'Pistas de apoyo a "Por qué"',
    txt_global_std_age: 'Años',
    txt_global_std_maximizetext: 'viajes con bono',
    txt_global_std_MikkelsenWhyjoin: 'Usaré mi experiencia en rallies y carreras para ayudar a las personas a ser mejores pilotos. Ser un buen conductor no se trata solo de ser rápido, sino también de ser eficiente e inteligente.',
    txt_global_std_bronze: 'Bronce',
    txt_global_std_areyousureyouwanttosignout: '¿Estás seguro de que quieres cerrar sesión?',
    txt_global_std_super: 'Súper',
    txt_global_std_sidekicknotifications: 'Para disfrutar de la mejor experiencia con la aplicación, permita que Sidekick le envíe notificaciones.',
    txt_global_std_hangin: 'Colgar en',
    txt_global_std_notavailable: 'No disponible',
    txt_global_std_removeuserfrom: 'Quitar usuario de',
    txt_global_std_wildfireunlockedkey: '¡Condujiste muy bien! ¡Tu nivel promedio fue mejor que 4.4 durante una semana! Se han agregado 5000 puntos a su billetera Kinto.',
    txt_global_std_HEAT: 'CALOR',
    txt_global_std_borrowmycar: 'tomar prestado mi coche',
    txt_global_std_PolicySchedule10: 'Asientos',
    txt_global_std_contactus: 'Contáctenos',
    txt_global_std_p2pfriendinvitedyouto: 'Tu amigo te invitó a Kinto Car Sharing',
    txt_global_std_jan: 'Ene',
    txt_global_std_sentbyenerfyglobalaproductbygreaterthanab: 'Enviado por Enerfy Global, un producto de Greater Than AB.',
    txt_global_std_groupchat: 'Grupo de chat',
    txt_global_std_allownotificationstogetdrivingtipsafter: 'Permita que las notificaciones reciban consejos de conducción después de cada viaje y lleve sus habilidades de conducción al siguiente nivel.',
    txt_global_std_newpasswordagain: 'Nueva contraseña de nuevo',
    txt_global_std_cimage36d: ' ',
    txt_global_std_enterthetemporarypasswordsenttoyoubyemail: 'Ingrese la contraseña temporal que se le envió por correo electrónico.',
    txt_global_std_loyaltydrivers: 'Conductores',
    txt_global_std_fiasdc2019w1price2: 'Paquete de 3 estaciones',
    txt_global_std_totalscore1: 'Puntaje total',
    txt_global_std_trips: 'Excursiones',
    txt_global_std_attention: 'Atención',
    txt_global_std_p2preservation: 'Reserva',
    txt_global_std_p2ppendingph: '{recuento} pendiente',
    txt_global_std_nothingtoshow: 'nada que mostrar',
    txt_global_std_wehavereceivedyourreport: 'Hemos recibido su informe y su reclamo será procesado en breve.',
    txt_global_std_vehicleinfo: 'información del vehículo',
    txt_global_std_warikan_onboarding6_2: '14F, Nagoya Mitsui Building North Tower, 4-8-18 Meieki, Nakamura-ku, Nagoya, Aichi 450-0002, Japón',
    txt_global_std_50kmlockedkey: 'Conduce una distancia de 50 km para ganar esta insignia.',
    txt_global_std_ayouwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithappnamewithalapseincoverage: 'R: Tendrá {days} días a partir de la fecha de vencimiento para realizar un pago y continuar con su póliza con {appName} con una caducidad en la cobertura.',
    txt_global_std_contact: 'Contacto',
    txt_global_std_this15discountcodecanberedeemedagainstanygarminpndpurchase: 'Este código de descuento del 15 % se puede canjear por cualquier compra de Garmin PND.',
    txt_global_std_p2pscore: 'Puntaje:',
    txt_global_std_nodataavailable1: 'datos no disponibles',
    txt_global_std_uploadextrareportinfo: 'Subir información adicional del informe',
    txt_global_std_signout: 'desconectar',
    txt_global_std_maxxcharactersrule: '{x} caracteres como máximo',
    txt_global_std_herearetheresultsofthechallengename: 'Aquí están los resultados de la',
    txt_global_std_enteryourinformation: 'ingrese su informacion',
    txt_global_std_earn10000pointsifyouupgradetovalueplan: 'Gane 10,000 puntos cuando actualice a Value Plan.',
    txt_global_std_p2pdrivendistance: 'distancia recorrida',
    txt_global_std_tiphighway4DESC: 'El conductor del auto rojo usa el freno y el gas con más frecuencia.',
    txt_global_std_nationality: 'Nacionalidad',
    txt_global_std_delete: 'Borrar',
    txt_global_std_startguidetip4: 'Deje que la aplicación se cargue hasta que vea el círculo de puntuación.',
    txt_global_std_howmuch: 'Cuánto',
    txt_global_std_arequesthasbeenreceivedtochangethepasswordforyour: 'Se ha recibido una solicitud para cambiar la contraseña de su',
    txt_global_std_thislinkexpiresin: 'Este enlace caduca en',
    txt_global_std_p2preceipt: 'Recibo',
    txt_global_std_nochallengestojoinyet: 'Aún no hay desafíos para unirse',
    txt_global_std_b1gotosettingsforappnameinyourphonebbrb2allowaccesstobluetoothb: '<b>1. Ve a la configuración de {appname} en tu teléfono.</b> <br> <b>2. Permitir el acceso a Bluetooth</b>',
    txt_global_std_view: 'Vista',
    txt_global_std_daterange: 'Rango de fechas',
    txt_global_std_youdrovesupremelywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: '¡Condujiste supremamente bien! ¡Tu promedio fue mejor que {score} durante una semana! ¡Has ganado {puntos} puntos!',
    txt_global_std_p2psuccessfullyeditedadminrights: 'Derechos de administrador editados con éxito',
    txt_global_std_yourinvitewassenttoemail: 'Su invitación fue enviada a {email}',
    txt_global_std_MSIG_Onboarding4: 'adelante. Encuentre su certificado de seguro y otra información adjunta en este correo electrónico.',
    txt_global_std_soclosetoearningachievement: 'Estuvo tan cerca de obtener un logro y ganar puntos de fidelidad. ¡Concentrémonos!',
    txt_global_std_p2pincreasetextsize: 'Aumentar el tamaño del texto',
    txt_global_std_p2pfindmycarcomingsoon: 'Encuentra mi coche – Próximamente',
    txt_global_std_monthlysavings: 'ahorros mensuales',
    txt_global_std_stats: 'Estadísticas',
    txt_global_std_50offdeductible: '50% de descuento en deducible',
    txt_global_std_ongoing__: '(En curso)',
    txt_global_std_startday: 'día de inicio',
    txt_global_std_addcomparison: 'Añadir comparación',
    txt_global_std_awesomedriveroftheday: 'Impresionante conductor del día.',
    txt_global_std_selectformat2: 'Seleccionar formato',
    txt_global_std_aug: 'Ago',
    txt_global_std_choosebetweenenteringchassisnumberplatenumberorpolicynumber: 'Elige entre ingresar número de chasis, número de placa o número de póliza.',
    txt_global_std_cimage8h: 'ACELERAR SUAVEMENTE',
    txt_global_std_PolicySchedule5: 'Asegurado',
    txt_global_std_findyourfriends: 'Encuentra a tus amigos',
    txt_global_std_bevisibleorhiddenintheleaderboard: 'Estar visible/oculto en la tabla de posiciones',
    txt_global_std_p2psplittingthecostbydistancetravelledrequiresbluetoothconnection: 'Dividir el costo por la distancia recorrida requiere una conexión Bluetooth.',
    txt_global_std_SulayemQuote: 'Cotización próximamente...',
    txt_global_std_errorcodes: 'Códigos de error',
    txt_global_std_MSIG_InsuranceLetter40: '3500,00 SGD, LAS SECCIONES I Y II SE IMPONEN POR SEPARADO A LOS CONDUCTORES MENORES DE 22 AÑOS O QUE TENGAN MENOS DE 2 AÑOS DE EXPERIENCIA DE CONDUCCIÓN 1000,00 SGD, TODOS LOS RECLAMOS SE IMPONEN A LOS CONDUCTORES QUE HAN DESACTIVADO EL DONGLE DURANTE AL MENOS 14 DÍAS EN UN AÑO CALENDARIO. SE APLICARÁ UNA FRANQUICIA ESCALONADA DE HASTA $2,000.00 EN CASO DE UN ACCIDENTE SUJETO A LA PUNTUACIÓN PROMEDIO SE APLICARÁ UNA FRANQUICIA DE $100.00 PARA TODAS Y CADA RECLAMACIÓN DE PARABRISAS\n',
    txt_global_std_inserttheobdreaderandstartyourcarwaitforthereadertoshowabluelight: 'Inserta el lector OBD y arranca tu coche. Espere a que el lector muestre una luz azul.',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithcovercubewithalapseincoverage: 'Tendrá {days} días a partir de la fecha de vencimiento para realizar un pago y continuar con su póliza con Covercube con una caducidad en la cobertura.',
    txt_global_std_yourcarisntturnedonnodot: 'Tu coche no está encendido.',
    txt_global_std_qdoescovercubeacceptdriversthatarenotlicensedintheunitedstates: 'P: ¿Covercube acepta conductores que no tienen licencia en los Estados Unidos?',
    txt_global_std_1year: '1 año',
    txt_global_std_warikan_onboarding10: '* Esta dirección de correo electrónico es solo para envíos. Tenga en cuenta que no podemos responder a este correo electrónico directamente.\n',
    txt_global_std_p2pfaq30a: 'Cada mes, recibe una factura del propietario y el mensaje "Recibió los detalles de la factura" se muestra en "Qué hacer" en la pantalla de inicio. Alternativamente, puede verificarlo desde "Warikan" en "Menú".\n' +
      '1. Después de seleccionar "Warikan", verifique los detalles en la pestaña Factura.\n' +
      '2. Después de confirmar los detalles de facturación, seleccione "Continuar" para confirmar el monto del pago.\n' +
      '3. Seleccione un método de pago y seleccione "Pagar ahora".',
    txt_global_std_welcometoteam: 'Bienvenido a {equipo}',
    txt_global_std_PaddonFacts: 'Hayden comenzó a conducir go-karts a la edad de 6 años. Vivió y respiró deportes de motor toda su vida.',
    txt_global_std_timezone: 'Zona horaria',
    txt_global_std_yourobdreaderisonitsway: 'Su lector OBD está en camino y llegará en 3 a 5 días hábiles. Siga el siguiente paso sobre cómo instalarlo fácilmente.',
    txt_global_std_welcometocovercube_: '¡Bienvenido a Covercube!',
    txt_global_std_youravgscoreis123: 'Su puntaje promedio es',
    txt_global_std_thisisatextmessage: 'Este es un mensaje de texto.',
    txt_global_std_challengefriend2: 'Reto amigo!',
    txt_global_std_Fia_Receipt_Text9: 'Cantidad',
    txt_global_std_events_overview_monthly: 'Mensual',
    txt_global_std_theworldchampionshipwheresmartnessbeatsspeed: 'el desafío mundial donde la inteligencia vence a la velocidad. Compite en tu conducción diaria y deja que los profesionales te entrenen para convertirte en el conductor más inteligente del mundo.',
    txt_global_std_wildfirenamekey: '¡Incendio forestal!',
    txt_global_std_p2pchangedthenameofthegroup: 'cambio el nombre del grupo',
    txt_global_std_socialbutterflynamekey: '¡Mariposa social!',
    txt_global_std_p2perrorcodecarmustbebonded: 'El coche debe estar en condiciones de servidumbre.',
    txt_global_std_2nd: '2: nd',
    txt_global_std_invalidvouchercode: 'Código de cupón no válido',
    txt_global_std_expensessummary: 'Resumen de gastos',
    txt_global_std_everyday: 'Todos los días',
    txt_global_std_whatwillitcosttodrivewithoutbeing: '¿Cuánto me costará conducir sin estar conectado a mi coche?',
    txt_global_std_featured: 'Presentado',
    txt_global_std_p2peasyoverviewinyourcalendar: 'Resumen fácil en su calendario',
    txt_global_std_youdrovesupremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: '¡Condujiste supremamente bien! ¡Tu {scoretype} promedio fue mejor que {score} durante una semana! ¡Has ganado {puntos} puntos!',
    txt_global_std_contactdetailss: 'Detalles de contacto',
    txt_global_std_congratulationsonthistypeoftripyouareanecochampionthanksforbeinganecofriendlydriver: '¡Felicidades! En este tipo de viaje, ¡eres un campeón ecológico! ¡Gracias por ser un conductor ecológico!',
    txt_global_std_networkerrorpleasetryagainlater: 'Error de red, inténtalo de nuevo más tarde',
    txt_global_std_orientering: 'Anticipar',
    txt_global_std_refer10friendstounlockthisbadge: 'Recomiende a 10 amigos para desbloquear esta insignia.',
    txt_global_std_downloadapispecification: 'Descargar especificación API',
    txt_global_std_showchanges: '¿Mostrar cambios?',
    txt_global_std_contributesustainabilitythroughwarikan: 'Contribuye a la sustentabilidad a través de Warikan KINTO',
    txt_global_std_makeitempty: '¡Hazlo vacío!',
    txt_global_std_changeemail: 'Cambiar e-mail',
    txt_global_std_letsfindyourcar: 'Encontremos tu auto',
    txt_global_std_MSIG_Onboarding13: 'MS First Capital Insurance Ltd | Oficina corporativa: 6, Raffles Quay, #21-00 | Singapur 048580 | Teléfono: (65) 6222 2311',
    txt_global_std_MSIG_InsuranceLetter25: 'Utilizar únicamente para fines sociales, domésticos y de placer y para el negocio del Asegurado.',
    txt_global_std_co2saverunlockedkey: '¡Gran trabajo! Ahorró más de {co2value}% CO₂ este viaje al conducir de manera ecológica.',
    txt_global_std_profilesetup: 'Configuración de perfil',
    txt_global_std_goldusers: 'usuarios de oro',
    txt_global_std_iwillfixitlater: 'lo arreglaré más tarde',
    txt_global_std_joinwithinvitationcode: 'Únase con el código de invitación',
    txt_global_std_statisticsshowingyourpersonalprogress: 'Estadísticas que muestran su progreso personal.',
    txt_global_std_mandatory: 'Obligatorio',
    txt_global_std_p2pfaq20q: '¿Es posible comprobar los datos de conducción?',
    txt_global_std_allowlocationservicetogetaccuratescore: 'Permita que el servicio de ubicación obtenga consejos personalizados y una puntuación precisa.',
    txt_global_std_templates: 'Plantillas',
    txt_global_std_enterpolicynumberhere: 'Ingrese el número de póliza aquí',
    txt_global_std_addadditionalfiles: 'Agregar archivos adicionales',
    txt_global_std_shootingstarunlockedkey: '¡Condujiste en el nivel 3.3 o mejor! ¡Excelente! Se han agregado 100 puntos a su billetera Kinto.',
    txt_global_std_hasbeeninvitedasaroletoyourteam: 'Han sido invitados con los permisos de {role}',
    txt_global_std_mpltipiip: 'Este es un conductor experto, pero indica inconsistencia en la conducción. En este caso,\n' +
      'el conductor está ignorando Cool Driving durante un día o viaje aleatorio. Esto es un\n' +
      'combinación de ignorar Cool Driving durante un día o solo para un viaje\n' +
      'durante un día. Para empezar, recomendamos que el entrenador junto con\n' +
      'controlador, trate de averiguar cuál puede ser el problema.',
    txt_global_std_p2phasreceivedyourbooking: 'ha recibido su solicitud de reserva.',
    txt_global_std_yourdatahasbeensent: 'Tus datos han sido enviados, por favor revisa tu bandeja de entrada!',
    txt_global_std_translationtool_upsell_pitch_bulletpoint2: 'Haga coincidir las comunicaciones con el tono de voz de su marca',
    txt_global_std__resendtemporarypassword: 'Reenviar contraseña temporal.',
    txt_global_std_forthisapptoworkproperlyweneedtoaccessnobr: 'Para poder registrar una unidad, debe\n' +
      'permitir bluetooth, servicios de localización y\n' +
      'movimiento y estado físico.',
    txt_global_std_today: 'Este Dia',
    txt_global_std_prev: 'Anterior',
    txt_global_std_OgierDescription: 'Es el actual poseedor del Campeonato Mundial de Pilotos de Rally, habiendo ganado el título en seis ocasiones, en 2013, 2014, 2015, 2016, 2017 y 2018. Con 44 victorias en el Campeonato Mundial de Rally y seis títulos WRC consecutivos, es el segundo piloto de WRC más exitoso, después del ex compañero de equipo de Citroën WRC Sébastien Loeb (nueve títulos).',
    txt_global_std_MSIG_InsuranceLetter21: 'El Asegurado también podrá conducir un Automóvil que no sea de su propiedad ni esté alquilado (en virtud de un contrato de compraventa a plazos o de otro modo) a él, a su empleador o a su socio.',
    txt_global_std_terrainhandling: 'Manejo del terreno',
    txt_global_std_readytogoappsolutionsthatwehavehandpickedforyou: 'Soluciones de aplicaciones listas para usar que hemos seleccionado para usted.',
    txt_global_std_whatwillhappenififorgettoconnect: '¿Qué sucederá si olvido conectarme a mi automóvil?',
    txt_global_std_callwithdriver: 'En el lugar de entrenamiento',
    txt_global_std_icannotconnectanswertext1: 'Asegúrese de completar todos los pasos en "Conectar al automóvil". Por favor envíe un correo electrónico',
    txt_global_std_youhavenowmaintainedafullweeksdrivingwithanaveragescoreabove4: 'Ha mantenido una semana completa de conducción con una puntuación media superior a 4,0',
    txt_global_std_filteroptionnotinuse: 'No esta en uso',
    txt_global_std_anewpasswordhasbeengeneratedforuser: 'Se ha generado una nueva contraseña para {usuario}',
    txt_global_std_ConfirmFia_Text10: '¿Estás listo para tu prueba de manejo de SDC?',
    txt_global_std_fiasdc2019w1price1: 'Paquete de 5 estaciones',
    txt_global_std_p2pselectthedatesandtime: 'Seleccione las fechas y horas en las que desea que su automóvil no esté disponible',
    txt_global_std_p2pfaq14q: '¿Qué pasa si devuelvo el coche demasiado tarde?',
    txt_global_std_groupnames: 'Nombres de grupos',
    txt_global_std_p2pfaq30q: 'Por favor, dígame cómo pagar el costo.',
    txt_global_std_youcanrepublishyourtranslationslaterifyouwant: 'Si lo desea, puede volver a publicar sus traducciones más tarde.',
    txt_global_std_speedchangeanalysis2: 'Análisis de cambio de velocidad',
    txt_global_std_p2pfaq22q: 'Quiero registrar costos adicionales.',
    txt_global_std_p2pfaqcontactinfo1: '・ Horario de recepción de 9:00 a 18:00 (excluidos los festivos de fin de año y año nuevo)',
    txt_global_std_resendactivationcodeto: 'Reenviar el código de activación a {name}',
    txt_global_std_tripdetailshavebeensenttoemail1: 'Los detalles del viaje han sido enviados al correo electrónico.',
    txt_global_std_areyousureyouwanttodeletememberfromworkspacethisactioncannotbeundone: '¿Está seguro de que desea eliminar el usuario? Esta acción no se puede deshacer.\n' +
      '\n' +
      'Tenga en cuenta que la propia cuenta de los usuarios en la aplicación no se eliminará.',
    txt_global_std_mars: 'Marte',
    txt_global_std_activationcodeoptional: 'Código de activación (opcional)',
    txt_global_std_lockeddescriptionlabel: 'Descripción bloqueada, que se muestra en la aplicación',
    txt_global_std_minute: 'Minuto',
    txt_global_std_enerfycoachtip1: '1. Manténgase 100 % concentrado en su conducción.',
    txt_global_std_p2pcostsplitsettings: 'Configuración de división de costos',
    txt_global_std_p2pnamesentaneditrequesttoname2: '{name} envió una solicitud de edición a {name2}',
    txt_global_std_changebackground: 'Cambiar imagen de fondo',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithappnamewithalapseincoverage: 'Tendrá {days} días a partir de la fecha de vencimiento para realizar un pago y continuar con su póliza con {appName} con una caducidad en la cobertura.',
    txt_global_std_ridewithmehowitworks: 'Cómo funciona',
    txt_global_std_youhavereachthemaximumlevel: '¡Has alcanzado el nivel máximo!',
    txt_global_std_p2pfriendadminrights: 'Derechos de administrador de amigos',
    txt_global_std_tipcity1DESC: 'El conductor del automóvil azul se mantiene a una distancia considerable de los automóviles que lo rodean.\n' +
      'El conductor del coche rojo acelera y frena innecesariamente a menudo',
    txt_global_std_ConfirmFia_Text2: 'Tienes un lugar reservado en el lanzamiento del FIA Smart Driving Challenge en París.',
    txt_global_std_wearebuildingyourworkspace: 'Estamos construyendo tu espacio de trabajo',
    txt_global_std_events: 'Eventos',
    txt_global_std_teamage: 'Años:',
    txt_global_std_NewPW_Text2: 'Su nueva contraseña para el usuario',
    txt_global_std_safetyalarms: 'alarmas de seguridad',
    txt_global_std_p2pfaq21a: 'Puede comprobar todos los miembros registrados seleccionando "Miembros" en el "Menú".',
    txt_global_std_superadmin: 'súper administrador',
    txt_global_std_workshops: 'Talleres de trabajo',
    txt_global_std_obdreadersnearby: 'Lectores OBD cercanos',
    txt_global_std_failedtogettranslationoverview: 'Error al obtener TranslationOverview',
    txt_global_std_p2pfaq1a: 'Esas son funciones realmente geniales que estarán disponibles en la aplicación pronto. Así que, ¡estén atentos a las actualizaciones!',
    txt_global_std_thisisyourusername: 'este es tu nombre de usuario',
    txt_global_std_numberofexecutions: 'Número de ejecuciones',
    txt_global_std_daysleft: 'Días restantes',
    txt_global_std_drivingdata: 'Datos de conducción',
    txt_global_std_p2paddedexpenses: 'Gastos',
    'txt_global_std_Al-KuwariWhyjoin': 'Porque creo una familia dentro del equipo.',
    txt_global_std_p2pitsalittleemptyinhere: 'Está un poco vacío aquí',
    txt_global_std_oldpassword: 'Contraseña anterior',
    txt_global_std_permission: 'Permiso',
    txt_global_std_iconback: '{icono} Atrás',
    txt_global_std_KleinschmidtMileage: 'También yo, mi experiencia de kilometraje depende de la situación actual del tráfico y de mi situación personal.',
    txt_global_std_followupontheonboardingstatusofyourusers: 'Seguimiento del estado de onboarding de tus usuarios',
    txt_global_std_bronzelevel: 'Nivel Bronce',
    txt_global_std_jointhechallenge: 'Únete al desafío',
    txt_global_std_KristofferssonWhyjoin: 'Si ves una competición como un reto y tu objetivo es dar el 100 % con las condiciones que tienes, entonces deberías unirte a mi equipo.\n',
    txt_global_std_p2pview: 'Vista',
    txt_global_std_youcollectedpointsduringthepreviosperiod: 'Acumuló {puntos} puntos durante el período anterior.',
    txt_global_std_noogpsdatafound: 'Sin datos GPS',
    txt_global_std_safetydrivingskillupwithwarikankintobody: 'Warikan KINTO te ayuda a mejorar tu conducción segura. La función de puntuación y la adquisición de insignias están disponibles incluso si no es usuario de KINTO ONE.',
    txt_global_std_activity: 'Actividad',
    txt_global_std_ifyouwanttolendyourcartoafriendyoushouldinviteyourfriendtotheappnameappbytappingthebuttonbelow: 'Si desea prestarle su automóvil a un amigo, debe invitarlo a la aplicación {appname} tocando el botón a continuación.',
    txt_global_std_end: 'Final',
    txt_global_std_rwmnofriendstext: 'Invita a un amigo para poder registrar un viaje compartido.',
    txt_global_std_secureyourlocation: 'Asegure su ubicación',
    txt_global_std_adgangtillokalitet: 'Permitir servicios de ubicación',
    txt_global_std_heat123: 'CALOR',
    txt_global_std_p2pcancelthisbooking: '¿Cancelar esta reserva?',
    txt_global_std_yourpolicyisreneweddate: 'Su póliza se renueva el {fecha}',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimeinconstantspeedpayingattentiontoyourenvironmenthelpsyoutosmoothlyandsteadilyvaryyourspeedaccordinglythroughoutyourtrip: 'En este tipo de viaje, el campeón eco promedio pasó menos tiempo en velocidad constante. Prestar atención a su entorno lo ayuda a variar su velocidad de manera suave y constante en consecuencia a lo largo de su viaje.',
    txt_global_std_compilinginformationdrive: 'Recopilando información de su disco...',
    txt_global_std_region: 'Región',
    txt_global_std_videotutorials: 'Tutoriales en vídeo',
    txt_global_std_Fia_Receipt_Text16: '',
    txt_global_std_PaddonBesttip: 'Ten paciencia, es mi consejo más fuerte. Es muy fácil ponerse tenso o impaciente con otros usuarios de la vía cuando está en la vía, pero recuerde, una pequeña diferencia de velocidad solo hará una diferencia de unos minutos al final. Entonces, relájate, sube el volumen de la música y disfruta de tu viaje.',
    txt_global_std_findanagent: 'Encuentre un agente',
    txt_global_std_insurancenumbernumber: 'Número de seguro: {número}',
    txt_global_std_p2pwriteamessagetoafriendorgroupregardingabookingoranythingselsethatsonyourmind: 'Escriba un mensaje a un amigo o grupo sobre una reserva o cualquier otra cosa que tenga en mente.',
    txt_global_std_features: 'Características',
    txt_global_std_co2savings: 'Ahorro de CO₂',
    txt_global_std_getsarouteforagiventrip: 'Obtiene una ruta para un viaje dado',
    txt_global_std_p2pselectmember: 'Seleccionar miembro',
    txt_global_std_notheappworksinbackgroundmodemakesureyoualwayskeepbluetoothandlocationservicesactivatedonyourphone: 'No, la aplicación funciona en segundo plano. Asegúrese de mantener siempre activados los servicios de ubicación y Bluetooth en su teléfono.',
    txt_global_std_p2pemailsent: '¡Email enviado!',
    txt_global_std_p2ppaid: 'Pagado',
    txt_global_std_acceleration: 'Aceleración',
    txt_global_std_nocloudconnection: 'Sin conexión a la nube',
    txt_global_std_pointsoverview: 'Resumen de puntos',
    txt_global_std_textwelcomewiget: '¡Esto va a ser muy divertido! Empecemos a conducir inteligentemente. ¡Estaré cerca para guiarte a través de la puntuación alta!',
    txt_global_std_emailnotfound: 'El correo electrónico no encontrado',
    txt_global_std_PSolbergBesttip: 'Próximamente, en breve, pronto...',
    txt_global_std_countdays: '{contar} días',
    txt_global_std_Insurance_WelcomeLetter: 'Carta de bienvenida',
    txt_global_std_addphoto: 'Añadir foto',
    txt_global_std_privateorbusiness: '¿Privado o comercial?',
    txt_global_std_private2: 'Privado',
    txt_global_std_loadingyourdrives: 'Cargando sus unidades...',
    txt_global_std_invitesent: 'Invitar enviado',
    txt_global_std_whathappensififorgettorenewbeforemyinsurancehasexpired: '¿Qué pasa si me olvido de renovar antes de que venza mi seguro?',
    txt_global_std_letsdrivewithwarikankinto: '¡Conduzcamos con Warikan KINTO!',
    txt_global_std_recommended: 'Recomendado',
    txt_global_std_mobilenumber: 'Número de teléfono móvil',
    txt_global_std_wantmoreindepthanalysis: '¿Desea un análisis más profundo e información detallada con identificación instantánea de factores de alto y bajo riesgo?',
    txt_global_std_p2pgoheretocreateabookingofyourfriendscar: 'Vaya aquí para crear una reserva del coche de su amigo.',
    txt_global_std_alinktodownloadyourdemoappwillbesenttoemailwhenready: 'Se enviará un enlace para descargar su aplicación de demostración a {email} cuando esté lista.',
    txt_global_std_monthm: 'metro',
    txt_global_std_countminutes: '{contar} minutos',
    txt_global_std_OSolbergMusic: 'Próximamente, en breve, pronto...',
    txt_global_std_remainingpoints: 'Puntos restantes',
    txt_global_std_ifyouwantinfoonnotificationscallphonenumberorgotoweburlorgotothefaqpagelink: 'Si desea información sobre las notificaciones, llame a {phoneNumber} o vaya a {webUrl}. O vaya a {faqPageLink}.',
    txt_global_std_norweigan: 'noruego',
    txt_global_std_cimage13h: 'DEJAR BAJAR LA VELOCIDAD',
    txt_global_std_p2pviewless: 'ver menos',
    txt_global_std_startyourcarsengine: 'Arranca el motor de tu auto',
    txt_global_std_cimage18h: 'VELOCIDAD MEDIA PLANEADA',
    txt_global_std_startdrivingtoappearontheleaderboard: '¡Empieza a conducir para conseguir una puntuación en la clasificación!',
    txt_global_std_p2pselectvehicle: 'Seleccionar vehículo',
    txt_global_std_connecttocarunlockedkey: '¡Bien hecho! Se conectó al automóvil y está listo para conducir. ¡Cuidate!',
    txt_global_std_aonceyoupairtheapptoyourcehiclesbluethoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhatscoreyougetwhendriving: 'Una vez que empareja la aplicación con el Bluetooth de su vehículo y permite que la aplicación use los servicios de ubicación, comparamos sus hábitos de conducción con la biblioteca de patrones de conducción de nuestra IA y proporcionamos una puntuación. La prima de su seguro se basa en la puntuación que obtiene al conducir.',
    txt_global_std_ifyourcarhasbluetoothbutdoesnotshowupintheappyoumightneedtocheckthebluetoothsettingsforbothyourphoneandyourcartryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagain: 'Si su automóvil tiene Bluetooth, pero no aparece en la aplicación, es posible que deba verificar la configuración de Bluetooth tanto para su teléfono como para su automóvil. Intente restablecer la conexión de su automóvil a su teléfono eliminándolo y luego agregándolo nuevamente.',
    txt_global_std_listview: 'Vista de la lista',
    txt_global_std_topimprover: 'mejorador superior',
    txt_global_std_invitenewmember: 'Invitar a un nuevo usuario',
    txt_global_std_PolicySchedule2: 'Agencia.',
    txt_global_std_advancedsettings: 'Ajustes avanzados',
    txt_global_std_excellentdriving: 'Excelente conducción!',
    txt_global_std_inviteafriend: 'Invita a un amigo',
    txt_global_std_conversionkmmi: 'Conversión km/mi',
    txt_global_std_smspackagefiasdcactivation: '¡Bienvenido al SDC de la FIA!\n' +
      'Haga clic en el enlace a continuación para descargar automáticamente la aplicación y unirse a FIA SDC.\n' +
      '\n' +
      '[ENLACE]\n' +
      '\n' +
      'Código de activación: {código de activación}',
    txt_global_std_competitionrules: 'Normas de competencia',
    txt_global_std_p2pborrowcar: 'coche prestado',
    txt_global_std_challangeranking: 'Clasificación de tu desafío',
    txt_global_CoreTemplate_more: 'Más',
    txt_global_std_gdprterms: 'Términos del RGPD',
    txt_global_std_towed: 'Remolcado',
    txt_global_std_newpwdcheckinbox: '¡Por favor revise su bandeja de entrada!',
    txt_global_std_riskmatcha: 'Coincidencia de riesgo {tipo}',
    txt_global_std_startguide: 'Guía de inicio',
    txt_global_std_bysigningupyouaccepttheusertermsprivacypolicy: 'Al registrarse, acepta los {userTerms} y {privacyPolicy}',
    txt_global_std_cimage34d: ' ',
    txt_global_std_riskydecisionbetweendays: 'Decisión arriesgada entre días',
    txt_global_std_p2pdonthaveatemporarypasswordregisterat: '¿No tienes una contraseña temporal?\nRegístrese en [sitio web de Kinto]',
    txt_global_std_exceededlimit: 'Límite excedido',
    txt_global_std_exchangedpoints: 'puntos canjeados',
    txt_global_std_accountstartdate: 'Fecha de inicio de la cuenta',
    txt_global_std_challengetermsconditions: 'Términos y condiciones del desafío',
    txt_global_std_animalcollision123: 'Colisión de animales',
    txt_global_std_litre: 'Litro',
    txt_global_std_p2pyoureceivedaninvoicetosplitcost: 'Recibió una factura para dividir el costo.',
    txt_global_std_PolicySchedule13: 'VALOR DE MERCADO AL MOMENTO DE LA PÉRDIDA',
    txt_global_std_appcreator_upsell_pitch_bulletpoint2: 'Pruebe y haga crecer su oferta digital sin un tiempo de desarrollo costoso',
    txt_global_std_timeline2: 'Cronología',
    txt_global_std_buylater: 'comprar más tarde',
    txt_global_std_p2pmakeseparatebookings: 'Hacer reservas separadas',
    txt_global_std_intraining: 'Entrenando',
    txt_global_std_skillscomparison: 'Comparación de habilidades',
    txt_global_std_p2pfaq5q: '¿Cómo amplío una imagen en los mensajes?',
    txt_global_std_alertdrivingtext: 'Ahora estás técnicamente conduciendo.',
    txt_global_std_sonicethatyoudecidedtojoinus: '¡Qué bueno que hayas decidido unirte a nosotros!',
    txt_global_std_wecanretrievedataformultiplecompletedtripsinasinglerequesthoweverwerecommendnottosendrequestswithdataexceeding50mb: 'Podemos recuperar datos de múltiples viajes completados en una sola solicitud. Sin embargo, recomendamos no enviar solicitudes con datos que excedan los 50 MB.',
    txt_global_std_buyheatsnowtocompete: '¡Compre series ahora para competir!',
    txt_global_std_inviteafriendtostartachallenge: '¡Invita a un amigo a comenzar un desafío!',
    txt_global_std_doyouwanttojointeam: '¿Quieres unirte al equipo',
    txt_global_std_allusers: 'Todos los usuarios',
    txt_global_std_driving: 'Conduciendo',
    txt_global_std_searchbyfilter: 'Buscar por filtro',
    txt_global_std_youonlyhavedistancelefttotravelleft: 'Solo te queda {distanceLeftToTravel}.',
    txt_global_std_selectrecipients: 'Seleccionar destinatarios',
    txt_global_std_topdrivers: 'Los mejores conductores',
    txt_global_std_startadrivemanually: 'Iniciar una unidad manualmente',
    txt_global_std_whenyourfriendusesyourreferralcodetoactivateanewsidekickcarinsurancebothofyouget250krinthepiggybank: 'Cuando su amigo usa su código de referencia para activar un nuevo seguro de automóvil Sidekick, ambos obtienen 250 kr en la alcancía.',
    txt_global_std_warikan_onboarding2: 'Gracias por solicitar el uso de la aplicación Warikan KINTO.',
    txt_global_std_achievementtool: 'herramienta de logro',
    txt_global_std_addexpenses: 'Añadir gastos',
    txt_global_std_communication: 'Comunicación',
    txt_global_std_idonthavebluetoothinmycar: 'no tengo bluetooth en mi carro',
    txt_global_std_1findyourobdportinyourcar2inserttheobdreaderandstartyourcarwaitforthereadertoshowabluelight3turnonyourphonesbluetooth4oncetheapprecognisestheobdreaderyoullmoveautomaticallytothenextstep5selectyourobddevicefromthelistobdmini2: '1: Encuentra tu puerto OBD en tu coche. 2: Inserte el lector OBD y arranque su automóvil. Espere a que el lector muestre una luz azul. 3: Encienda el Bluetooth de su teléfono. 4: Una vez que la aplicación reconozca el lector OBD, pasará automáticamente al siguiente paso. 5: Seleccione su dispositivo OBD de la lista: OBD Mini 2',
    txt_global_std_changeswillnotbesaved: 'Los cambios no se guardarán',
    txt_global_std_p2pwearegladthatyouwanttousewarikanasawaytoshareyourcar: 'Nos complace que desee utilizar Warikan como una forma de compartir su automóvil.',
    txt_global_std_wrongusernameorpassword: 'Nombre de usuario o contraseña incorrectos',
    txt_global_std_alerthighusagetext: '¡Advertencia! Alto uso [cantidad] kr.',
    txt_global_std_welcomeback: '¡Bienvenido de nuevo!',
    txt_global_std_p2pborrowed: 'prestado',
    txt_global_std_telephonecontact: 'Teléfono',
    txt_global_std_p2pemailnotpaidbookings: 'Reservas no pagadas',
    txt_global_std_enterodometervalue: 'Ingrese el valor del odómetro',
    txt_global_std_stopdrivingq: '¿Dejar de conducir?',
    txt_global_std_byinvitingfriendsyouwillearnloyaltypoints: 'Invitando amigos ganarás puntos de fidelidad',
    txt_global_std_pushnotdrivenforesometimetitle2: '¡Te extrañamos amigo!',
    txt_global_std_100offdeductible: '100% de descuento en deducible',
    txt_global_std_p2pfaq9a: 'Pídele a tu amigo que vaya al resumen de la reserva y presione finalizar reserva. La reserva habrá finalizado y su coche estará disponible para prestarlo de nuevo.',
    txt_global_std_areyousure: '¿Está seguro?',
    txt_global_std_cimage17h: 'CONDUCCIÓN ACTIVA EN AUTOPISTA',
    txt_global_std_undertheusersectionextendittoupdateyouraccountdetailsorlogoutfromtheadminportal: 'Amplíe la sección de usuario para actualizar los detalles de su cuenta, ver pagos o cerrar sesión en el espacio de trabajo.',
    txt_global_std_MSIG_InsuranceLetter33: 'Registro de empresa Nº 195000106C',
    txt_global_std_p2pyouwillnotbeabletoseeorwriteanymessagesinthisgroupchat: 'No podrá ver ni escribir ningún mensaje en este chat grupal.',
    txt_global_std_workspace: 'espacio de trabajo',
    txt_global_std_whywouldyou: '¿Por que lo harias?',
    txt_global_std_calculatethedates: 'Calcular las fechas',
    txt_global_std_yesterday: 'El dia de ayer',
    txt_global_std_smartdriverweekly: 'Conductor inteligente semanalmente',
    txt_global_std_private: 'Privado',
    txt_global_std_whatdoineedtodoinordertoconnectmycar: '¿Qué debo hacer para conectar mi coche?',
    txt_global_std_effectivedates: 'Fechas de vigencia',
    txt_global_std_yourfriendsnickname: 'Nombre de usuario de tus amigos',
    txt_global_std_findmycar2: 'encontrar mi coche',
    txt_global_std_tuneup: 'Afinar',
    txt_global_std_p2pcarborrow: 'Coche',
    txt_global_std_gamificationinspirestosmarterdrivingforlowerco2imprintandfewerroadaccidents: 'La gamificación inspira a una conducción más inteligente para una menor huella de CO2 y menos accidentes de tráfico',
    txt_global_std_turnonlocationservicesinyourphone: 'Active los servicios de ubicación (GPS) en su teléfono.',
    txt_global_std_youhavebeenchallengedtoahead2headacceptitnow: 'Usted ha sido desafiado a un cabeza a cabeza. Acéptalo ahora.',
    txt_global_std_managetheimpactyourteamhasonroadsafetyandco2emissions: 'Gestiona el impacto de tu equipo en la seguridad vial y las emisiones de CO2',
    txt_global_std_p2pconfirmedph: '{contar} confirmado',
    txt_global_std_startguidetip1: 'Asegúrese de que el lector OBD esté conectado a la conexión OBD del automóvil.',
    txt_global_std_statusheader: 'Estado',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscores: 'R: Una vez que empareja la aplicación con el Bluetooth de su vehículo y permite que la aplicación use los servicios de ubicación, comparamos sus hábitos de conducción con la biblioteca de patrones de conducción de nuestra IA y proporcionamos una puntuación. La prima de su seguro se basa en quién conduce su vehículo y qué tan bien lo conduce. Se anima a quienquiera que esté conduciendo su vehículo a descargar la aplicación y realizar un seguimiento de sus puntuaciones.',
    txt_global_std_25offdeductible: '25% de descuento en deducible',
    txt_global_std_validatedata: 'Validar datos',
    txt_global_std_whydoyouwanttoterminateyourinsurance: '¿Por qué desea cancelar su seguro?',
    txt_global_std_newtoagent: '¿Nuevo en {agente}?',
    txt_global_std_user: 'Usuario',
    txt_global_std_ourinsurancesolutionisbaseduponafixedandvariablecost: 'Nuestra solución de seguro se basa en un costo fijo y variable. El costo fijo se basa en el vehículo asegurado y la dirección del propietario del vehículo, y se factura mensualmente por adelantado, independientemente de la frecuencia y la forma en que se utilice el vehículo. El costo variable se basa en la cobertura del seguro, el kilometraje y su comportamiento al volante. Para calcular y cotizar el costo de su conducción, el dispositivo OBD incluido (complemento Enerfy) debe estar instalado en su vehículo o, si su vehículo tiene la opción de conectar su teléfono a través de Bluetooth directamente, la aplicación Enerfy tiene que estar corriendo en su teléfono inteligente mientras conduce. Luego, su costo variable se calcula en función de los niveles de prima, es decir, niveles de conducción de uno (1) a (15) quince, en función de su comportamiento de conducción individual, donde el nivel de prima 1 es el nivel con el costo por kilómetro más bajo y el nivel de prima 15 es el más alto. El costo variable se acumula para cada unidad y se factura al mes siguiente. Si no está conectado y sus unidades no están registradas, se le facturará en el nivel premium 10.',
    txt_global_std_p2pyouneedtocreateabookingbeforeaddingaexpense: 'Debe crear una reserva antes de agregar un gasto.',
    txt_global_std_trygonboarding1: '¡Estamos muy contentos de que hayas decidido unirte a nosotros! Sigamos con la preparación de su perfil.',
    txt_global_std_p2pcostsplit: 'división de costos',
    txt_global_std_acceptconnectiontogetascore: 'Aceptar la conexión para obtener una puntuación',
    txt_global_std_KristofferssonBesttip: 'Mantenga la vista muy adelante para anticiparse al tráfico. Esto le dará más tiempo para planificar su conducción.',
    txt_global_std_onesofthebestsdrivers: 'Unos de los mejores conductores!',
    txt_global_std_woman: 'Mujer',
    txt_global_std_p2ppasswordnotmatchedpleasetryagain: 'Contraseña no coincidente. Inténtalo de nuevo.',
    txt_global_std_youhavedrivenatotalof: 'Has conducido un total de',
    txt_global_std_signinwith2: 'Inicia sesión con',
    txt_global_std_anyonewiththelinkcangetinvited: '(cualquiera que tenga el enlace puede ser invitado)',
    txt_global_std_pigsinvitedunlocked: '¡Agradable! Ahora tienes 250 coronas más en tu alcancía porque has reclutado a un amigo.',
    txt_global_std_contactinformation: 'Información del contacto',
    txt_global_std_mpltiplvl7: 'Recomendamos la formación de conductores con especial atención al enfoque y la planificación del conductor. Lo que significa que nunca pierdas el enfoque en el tráfico y las condiciones que te rodean. Somos Yamato y siempre enfocamos y planificamos la conducción con especial atención a la Amabilidad, Priorización, Autocontrol Emocional y Confianza.',
    txt_global_std_pigwonlocked: 'Compite y gana contra un amigo en head2head para recibir este premio',
    txt_global_std_failedtoedittranslation: '¡Error al editar la traducción!',
    txt_global_std_yearlymilageused: 'Kilometraje anual utilizado',
    txt_global_std_letsdothis: '¡Hagámoslo!',
    txt_global_std_howitworks: 'Cómo funciona',
    txt_global_EnerfyActivityDetailTemplate_comparetomy: 'Comparar con:',
    txt_global_std_verygood: 'Muy bueno',
    txt_global_std_browsetemplates: 'Examinar plantillas',
    txt_global_std_pleasenotethatwewilldetuct: 'Tenga en cuenta que deduciremos 1 SEK de su tarjeta cuando actualicemos los datos de su tarjeta. El importe se devolverá en su totalidad. En caso de que tenga facturas impagas, las facturas se pagarán poco después de la actualización. Además, asegúrese de que su tarjeta esté abierta a compras por Internet.',
    txt_global_std_contactinfo: 'Datos de contacto',
    txt_global_std_kintofaq3q: '¿Cómo obtengo un estado de fidelidad?',
    txt_global_std_toreportyourclaim: 'Para reportar su reclamo, simplemente siga los siguientes pasos y su reclamo será procesado en breve.',
    txt_global_std_customizeyourapp: 'Personaliza tu aplicación',
    txt_global_std_p2ppressthedrivebuttontoconnecttoyourcarandstartatripyouwillgetfeedbackandinsights: 'Presione el botón de conducción para conectarse a su automóvil y comenzar un viaje. Obtendrá comentarios e información sobre su forma de conducir.',
    txt_global_std_planetsaver: 'protector de planeta',
    txt_global_std_byinvitingfriendsyouwillearnachievements: 'Invitando amigos obtendrás logros',
    txt_global_std_CertificateofInsurance: 'Certificado de seguro',
    txt_global_std_speeddecrease: 'Disminución de velocidad',
    txt_global_std_motorclaimshotline: 'Línea Directa de Reclamaciones de Automóviles',
    txt_global_std_friendstotheapp: 'amigos a la aplicación Enerfy Loyalty.',
    txt_global_std_dontworryaslongastheenerfyappisinstalledandyouareloggedin: 'No se preocupe, siempre que la aplicación Enerfy esté instalada y haya iniciado sesión, la aplicación se conectará automáticamente a su dispositivo.',
    txt_global_std_emailwithremovallink: '¡Éxito! Se ha enviado un correo electrónico con un enlace de eliminación, ¡verifique su bandeja de entrada!',
    txt_global_std_connectnow: 'Conecte ahora',
    txt_global_std_totaltranslatedwords: 'Total de palabras traducidas',
    txt_global_std_cimage24h: 'ENCUENTRE LAS RPM ÓPTIMAS',
    txt_global_std_baseusers: 'Usuarios base',
    txt_global_std_keyname: 'Nombre clave',
    txt_global_std_collectpointsbyearningbadgeseverybadgeisworthadifferentamountofpointstapabadgetolearnmoreaboutit: 'Acumula puntos ganando insignias. Cada insignia vale una cantidad diferente de puntos. Toque una insignia para obtener más información al respecto.',
    txt_global_std_selectcar: 'Seleccionar coche',
    txt_global_std_pleasetryusinganotherusername: 'Intente usar otro nombre de usuario.',
    txt_global_std_whyterminateinsurance: '¿Por qué rescindir el seguro de {appName}?',
    txt_global_std_getdiscountcode: 'Obtener código de descuento',
    txt_global_std_warikan_onboarding4_2: 'Contacto:\nCentro de atención al cliente de KINTO',
    txt_global_std_confirmconnectionbetweenyourphoneandobdreader: 'Confirme la conexión entre su teléfono y el lector OBD.',
    txt_global_std_p2pregisterkinto: 'Registrar Kinto',
    txt_global_std_copytoclipboard: 'Copiar al portapapeles',
    txt_global_std_accountdetails: 'detalles de la cuenta',
    txt_global_std_whyshouldanyonejoinyourteam: '¿Por qué alguien debería unirse a su equipo?',
    txt_global_std_pleaseenteravalidemailaddress: 'Por favor, introduce una dirección de correo electrónico válida',
    txt_global_std_planningfeedbacktips: '<b>Mirar más adelante</b> (y no solo justo enfrente de ti) generalmente da como resultado una conducción más suave y una mayor probabilidad de encontrar obstáculos a tiempo.<br><br>\n' +
      '<b>Garantizando un viaje placentero</b> para usted y sus pasajeros.',
    txt_global_std_p2pifyouareakintoownerallyouneedtodoisregisteryourcarhereandyoullbereadytogo: 'Si es propietario de Kinto, todo lo que necesita hacer es registrar su automóvil aquí y ¡estará listo para comenzar!',
    txt_global_std_starttrip: 'Iniciar viaje',
    txt_global_std_accountidentification: 'Identificación de la cuenta',
    txt_global_std_competitions: 'Competiciones',
    txt_global_std_p2punread: 'no leído',
    txt_global_std_youcanexpectthereadertoarrivewithin35businessdays: 'Puede esperar que el lector llegue dentro de 3 a 5 días hábiles.',
    txt_global_std_individualdrivers: 'Conductores individuales',
    txt_global_std_p2pyoudonthaveanytripsyet: 'Aún no tienes ningún viaje.',
    txt_global_std_tomczykDescription: 'Después de una carrera en karting, Tom corrió en Fórmula BMW y Fórmula 3. En 2001, fue el piloto de DTM más joven en ese momento. Se convierte en campeón del DTM en 2011, tercero en 2007, cuarto en 2006 y quinto en 2004, habiendo ganado ocho carreras. En total Tom estuvo 16 años dentro del campeonato DTM. En 2017 corrió en la IMSA para prepararse para las carreras de resistencia. 2018/19 forma parte del programa BMW M8 GTE WEC (World Endurance Championship).',
    txt_global_std_youractivationcodes: 'Sus códigos de activación',
    txt_global_std_failedtoremove: 'No se pudo eliminar',
    txt_global_std_kintoImprovement3: '¡No olvide revisar sus comentarios para comenzar a ganar algunos ahorros!',
    txt_global_std_doyouwanttocontinue: 'Quieres continuar',
    txt_global_std_warikan_onboarding18: 'Contraseña temporal:',
    txt_global_std_tomasengeinterests: 'Carreras en general, niños.',
    txt_global_std_TaxInvoice12: 'El pago de un asegurado a un agente o corredor se considera pago al asegurador',
    txt_global_std_MSIG_InsuranceLetter8: 'Tipo de Cobertura.',
    txt_global_std_p2ppleaseselectacar: 'Por favor selecciona un auto',
    txt_global_std_history: 'Historia',
    txt_global_std_drivtimeover80: 'Tiempo de conducción superior a {velocidad}',
    txt_global_std_whyyoushouldusefiasdcforyourbusiness: 'Por qué debería usar FIA SDC para su negocio',
    txt_global_std_trackingofyourco2emissions: 'Seguimiento de tus emisiones de CO2.',
    txt_global_std_sender: 'Remitente',
    txt_global_std_replaydrive: 'viaje de repetición',
    txt_global_std_homeaddress: 'Direccion de casa',
    txt_global_std_p2pselectedfriend: 'amigo seleccionado',
    txt_global_std_p2pbookingpending: 'Reserva pendiente',
    txt_global_std_teammemberbig: 'MIEMBRO DEL EQUIPO\n',
    txt_global_std_opensettingsinthephoneturnon: 'Abra "Configuración" en el teléfono, ENCIENDA Bluetooth y abra la aplicación.',
    txt_global_std_appcreator_upsell_pitch_bulletpoint3: 'Adelántese en la carrera por soluciones digitales centradas en el cliente',
    txt_global_std_pleaseentercompanyname: 'Por favor, introduzca el nombre de la empresa',
    txt_global_std_therearenoaccountscreatedundertheagreement: 'No hay cuentas creadas bajo el acuerdo.',
    txt_global_std_allow: 'Permitir',
    txt_global_std_p2pfaq14a: 'Le recomendamos que informe al propietario del automóvil en los mensajes de chat si sabe que va a llegar tarde.',
    txt_global_std_vehicleapi: 'API de vehículos',
    txt_global_std_p2pemailforcommunication: 'Correo electrónico para la comunicación',
    txt_global_std_yourmaxspeed: 'Tu velocidad máxima',
    txt_global_std_gps: 'GPS',
    txt_global_std_loyaltystatus: 'Estado de lealtad',
    txt_global_std_mpltipdev7pp: 'Este es un conductor experto, pero indica inconsistencia en la conducción. Durante muchos días oa veces durante semanas, se practica Cool Driving. Pero solo por un día, Cool Driving es ignorado. Hay algo que hace que el conductor cambie de actitud al conducir y es difícil para nosotros determinar por qué. Por lo tanto, recomendamos una breve conversación con el conductor y pedirle su opinión sobre por qué sucede esto.',
    txt_global_std_youmustpickadate: 'Debes elegir una fecha',
    txt_global_std_settingssmall: 'ajustes',
    txt_global_std_riskindex: 'Índice de riesgo',
    txt_global_std_ended: 'Terminado',
    txt_global_std_nickname: 'Apodo',
    txt_global_std_getoverviewtrips: 'Obtenga una visión general de todos sus viajes',
    txt_global_std_itsalittleemptyinhere: 'Está un poco vacío aquí',
    txt_global_std_cimage23h: 'RUEDA UNOS METROS ADICIONALES',
    txt_global_std_ifbuttondontworktrylink: 'Si el botón de arriba no funciona, pegue este enlace en su navegador web:',
    txt_global_std_teamname1: 'Nombre del equipo',
    txt_global_std_dontstress: 'no te estreses',
    txt_global_std_areyousureyouwanttoredeempointstotradepoints: '¿Seguro que quieres canjear puntos?',
    txt_global_std_ConfirmFia_Text6: 'Plaza de la Concordia, 75008 París, Francia',
    txt_global_std_addyourvehicleidentificationtobeabltoenjoyallthebenefitsandclaimyourrewards: '¡Agregue la identificación de su vehículo para poder disfrutar de todos los beneficios y reclamar sus recompensas!',
    txt_global_std_yourfriendslistlooksabitemptyusethebuttonbelowtostartinvitingyourfriends: 'Tu lista de amigos parece un poco vacía. Use el botón de abajo para comenzar a invitar a sus amigos.',
    txt_global_std_MSIG_InsuranceLetter27: '* Las limitaciones invalidadas por la Sección 8 de la Ley de Vehículos Motorizados (Compensación y Riesgos de Terceros) (Capítulo 189) y la Sección 95 de la Ley de Transporte por Carretera de 1987 (Malasia), no deben incluirse en estos títulos.',
    txt_global_std_addfriends: 'Añade amigos',
    txt_global_std_addapurpose: 'Agregar un propósito',
    txt_global_std_placethewarningtriangleatleast100metersfromthecar: 'Coloque el triángulo de advertencia al menos a 100 metros del automóvil.',
    txt_global_std_checkifcorrectobdreaderisconnected: 'Compruebe si está conectado el lector OBD correcto.',
    txt_global_std_cimage28h: 'NO ES APROPIADO UTILIZAR EL CONTROL DE CRUCERO',
    txt_global_std_wall: 'Muro',
    txt_global_std_somethingiswrong: 'Algo está mal',
    txt_global_std_accessdeniedyoudonothavepermissiontoaccesstheworkspacepleasecontactyouradministratorifincorrect: 'Acceso denegado. No tienes permiso para acceder al espacio de trabajo. Póngase en contacto con su administrador si es incorrecto.',
    txt_global_std_p2pfaq32q: '\nPor favor, dígame cómo dividir el costo.',
    txt_global_std_ChicheritQuote: 'Próximamente, en breve, pronto...',
    txt_global_std_insuranceactivesince: 'Seguro activo desde',
    txt_global_std_MSIG_InsuranceLetter4: 'Reglamento de vehículos de motor (riesgos e indemnizaciones de terceros), 1960',
    txt_global_std_enginescanner: 'escáner de motor',
    txt_global_std_bestscore: 'Mejor puntuación',
    txt_global_std_howitworks1: 'Cómo funciona',
    txt_global_std_p2pcleaning: 'Limpieza',
    txt_global_std_heavydamage: '¿Daño pesado?',
    txt_global_std_MSIG_InsuranceLetter37: '36 Robinson Road #16-01 City House Singapur 068877 Tel: (65) 6507 3848 Fax: (65) 6507 3849',
    txt_global_std_KristofferssonFacts: 'Johan comenzó su carrera en la Super Serie Internacional con el equipo de Suecia, donde impresionó y pasó al Touring Car Championship (STCC), donde ganó en una sola temporada en 2002. Luego pasó al European RX en la temporada 2013-2014 y en 2015 a el FIA WRX donde ganó 5 rallies y quedó tercero en la clasificación general. Terminó subcampeón en 2016 detrás de su compatriota Mattias Ekström y lo ganó en 2017.',
    txt_global_std_youwilllosedrivedata: 'Perderá todos los datos, incluidas las unidades, si inicia sesión con otro usuario.',
    txt_global_std_typeoftraining: 'Tipo de Entrenamiento',
    txt_global_std_selectyourcar: 'Selecciona tu coche',
    txt_global_std_setupcompanychallenge_fia_text: 'Únase a su equipo en la FIA SDC y manténgase activo, inteligente y sostenible cuando viaje en automóvil.',
    txt_global_std_MSIG_InsuranceLetter18: 'Conductor Autorizado*',
    txt_global_std_youarearockstardriver: '¡Tu conducción fue excelente en este viaje! No tenemos más consejos para darte en este momento. ¡Eres una estrella de rock!',
    txt_global_std_trygroadsideassistance: 'Tryg asistencia en carretera',
    txt_global_std_MSIG_Password1: '¡Hola!',
    txt_global_std_unknown: 'Desconocido',
    txt_global_std_youhaveupdatedyourdetails: 'Has actualizado tus datos',
    txt_global_std_cimage31d: ' ',
    txt_global_std_youvefinishedyourdrive: '¡Has terminado tu viaje!',
    txt_global_std_promocode: 'Código promocional',
    txt_global_std_learnmore: 'Aprende más',
    txt_global_std_amazingdriverlockedkey: 'Termina un recorrido con una puntuación de {puntuación} o mejor para desbloquear esta insignia. Puedes ganar esta insignia una vez al día.',
    txt_global_std_expired: 'Venció',
    txt_global_std_reportaccidentheadtext: 'Si tiene un accidente, infórmelo lo antes posible a continuación.',
    txt_global_std_locationreasontext: 'Los servicios de ubicación son necesarios para determinar su puntaje de manejo. Si los servicios de ubicación no están permitidos, la aplicación no funcionará en segundo plano y no iniciará su viaje automáticamente.',
    txt_global_std_searchbygroupname: 'Buscar por grupo, nombre',
    txt_global_std_MarklundChamp: '2x Campeón de Europa de Rallycross de la FIA',
    txt_global_std_insnum: 'En s. número',
    txt_global_std_itseasytomonitoryouresgtargets: 'Es fácil monitorear sus objetivos ESG',
    txt_global_std_kintorecruit5userslocked: 'Desbloquee este logro y gane ¥ 10,000 al reclutar 5 usuarios de Kinto.',
    txt_global_std_unplugtheobdreaderandplugitbackagain: 'Desconecte el lector OBD y vuelva a conectarlo. Espere hasta que el lector parpadee en azul.',
    txt_global_std_activeparticipantsfor: 'Participantes activos de',
    txt_global_std_SignupFia_Text5: '¡Descargue la aplicación SDC para obtener un adelanto de lo que se avecina!',
    txt_global_std_restrictions: 'Restricciones',
    txt_global_std_stayfocused: 'mantente enfocado',
    txt_global_std_TomczykFacts: 'Después de una carrera en karting, Tom corrió en Fórmula BMW y Fórmula 3. En 2001, fue el piloto de DTM más joven en ese momento. Se convierte en campeón del DTM en 2011, tercero en 2007, cuarto en 2006 y quinto en 2004, habiendo ganado ocho carreras. En total Tom estuvo 16 años dentro del campeonato DTM. En 2017 corrió en la IMSA para prepararse para las carreras de resistencia. 2018/19 forma parte del programa BMW M8 GTE WEC (World Endurance Championship).',
    txt_global_std_fileinput: 'Entrada de archivo',
    txt_global_std_latesttrip: 'último viaje',
    txt_global_std_theapikeydoesnthavepermissionstoperformtherequest: 'La clave API no tiene permisos para realizar la solicitud.',
    txt_global_std_areyousureyouwanttodeletethegroupdeletingthegroupdoesnotdeletetheusersaccounts: '¿Está seguro de que desea eliminar el grupo?\n' +
      '\n' +
      'Eliminar el grupo no elimina las cuentas de los usuarios.',
    txt_global_std_theuserwillbeaddedtoyouragreementaccordingtoyourpriceplan: 'El usuario se agregará a su acuerdo de acuerdo con su plan de precios.',
    txt_global_std_rwmselectfriendtext: 'Seleccione el amigo/amigos que viajaron con usted',
    txt_global_std_welcomeresendemail: '¡Bienvenido a tu nueva cuenta! Le hemos enviado un correo electrónico para verificar su nueva cuenta. Recuerda revisar tu carpeta de spam. ¿No recibiste un correo electrónico?',
    txt_global_std_riskportfoliotracker_upsell_pitch: 'Conecte fácilmente toda su cartera para obtener una vista integral del riesgo del conductor. Ganar;',
    txt_global_std_automaticstartandstoprequiresaccesstobluetoothlocationservicesgpsandmotionfitness: 'El inicio y la parada automáticos requieren acceso a bluetooth, servicios de ubicación (GPS) y movimiento y estado físico.',
    txt_global_std_inviteall: 'Invita a todos',
    txt_global_std_enterdriverriskweight: 'Ingrese la ponderación de riesgo del conductor',
    txt_global_std_couldnotsavechanges: 'No se pudieron guardar los cambios',
    txt_global_std_warikan_onboardingemailsubject: '[Warikan KINTO] Información sobre cómo usar la aplicación\n',
    txt_global_std_connectivity: 'Conectividad',
    txt_global_std_youraveragelevelispoints: 'Tu nivel promedio es {puntos}',
    txt_global_std_avgrisklevel: 'Promedio Nivel de riesgo',
    txt_global_std_driveraverage: 'Promedio del conductor',
    txt_global_std_youneedtodrivemorethankmkmduringaheatweekinordertogetascoreintheheatleaderboard: 'Debe conducir más de {km} km durante una serie (semana) para obtener una puntuación en la clasificación de la serie.',
    txt_global_std_challengeleaderboard_upsell_pitch: 'Organice un desafío para equipos, divisiones, clientes, socios y otros invitados para;',
    txt_global_std_yourunsavedchangeswillbelostsavechangesbeforeclosing: 'Los cambios no guardados se perderán. ¿Guardar cambios antes de cerrar?',
    txt_global_std_noofusers: 'Nº de usuarios',
    txt_global_std_emailuniqueinvitations: 'Invitaciones únicas por correo electrónico',
    txt_global_std_TaxInvoice1: 'FACTURA FISCAL',
    txt_global_std_starburstnamekey: '¡Estallido estelar!',
    txt_global_std_p2paskakintoownertoinviteyouandyouwillgetaccesstoallthecoolfunctionsinthisapp: 'Pídale a un propietario de Kinto que lo invite y obtendrá acceso a todas las funciones interesantes de esta aplicación.',
    txt_global_std_p2pcoolandfunstatsaboutyourfriendsbookingofyourcar: 'Estadísticas geniales y divertidas sobre la reserva de su automóvil por parte de su amigo.',
    txt_global_std_continue123: 'Continuar',
    txt_global_std_ridewithmekey: 'Pasea conmigo',
    txt_global_std_joinrandom: 'unirse al azar',
    txt_global_std_trygdemo: 'Saltar y comenzar con Tryg Drive',
    txt_global_std_couldnotcalculatespeedimprovementpossibility: '¡No se pudo calcular la posibilidad de mejora de la velocidad!',
    txt_global_std_p2ppushtitlelendingcomingup: 'se acercan los prestamos',
    txt_global_std_invitedmembers: 'miembros invitados',
    txt_global_std_driveminimum1kmduringaheatinordertoqualifyforthechallenge: '• Conduce un mínimo de 1 km durante una eliminatoria para calificar para el desafío.',
    txt_global_std_from2: 'de',
    txt_global_std_communication_upsell_bulletpoint2: 'Conéctese con los usuarios en sus canales preferidos',
    txt_global_std_seehowmuchco2savingsyougetwhenyoudrivewell: 'Vea cuánto ahorro de CO2 obtiene cuando conduce bien',
    txt_global_std_startlendingyourcar: '¡Empieza a prestar tu coche!',
    txt_global_std_opensettingsinyourphonegotoprivacy: 'Abra "Configuración" en su teléfono. Vaya a Privacidad, abra Servicio de ubicación y active FIA Smart.',
    txt_global_std_trip: 'Viaje',
    txt_global_std_warikan_onboarding17: 'Correo Electronico:',
    txt_global_std_buymorekilometers: 'Compra más kilómetros',
    txt_global_std_sendmyposition: 'Enviar mi posición',
    txt_global_std_nofiltersyet: 'No hay filtros todavía',
    txt_global_std_letsstartwiththebasics: 'Empecemos con lo básico',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint2: 'Felicitar a los conductores por alcanzar sus hitos/objetivos',
    txt_global_std_warikan_onboarding15: 'Formulario de consulta:\n',
    txt_global_std_trysidekick: 'Prueba Compañero',
    txt_global_std_thepasswordminimumlengthis8charactersandmustinclude1lowercaseuppercasenumeralandspecialcharacter: 'La longitud mínima de la contraseña es de 8 caracteres y debe incluir 1 minúscula, mayúscula, número y carácter especial',
    txt_global_std_carsagreements: 'Autos y acuerdos',
    txt_global_std_SignupFia_Text4: '¿Estás preparado para el reto?',
    txt_global_std_confirmationmail: '¡Ya estás reservado!',
    txt_global_std_welcometoappname: '¡Bienvenido a {appName}!',
    txt_global_std_invoiceoverview: 'Costo',
    txt_global_std_gpserror: 'Error de GPS',
    txt_global_std_whenwillmyratechange: '¿Cuándo cambiará mi tarifa?',
    txt_global_std_analyzingtheresults: 'Analizando los resultados de tu viaje',
    txt_global_std_qhowdoifileaclaim: 'P: ¿Cómo presento un reclamo?',
    txt_global_std_toturnonbluetoothinyourphone: 'Para activar Bluetooth en su teléfono, puede ir a su configuración y activarlo desde allí. También puede deslizar hacia abajo para activar Bluetooth desde su centro de control.',
    txt_global_std_exportexcel: 'Exportar XLS',
    txt_global_std_manageadvancedusersettingsandsupporthereusethequickaccessbuttonsbelowtoeasilynavigatebetweenuserscarsagreementsandfollowupandhelpoutwiththeironboarding: 'Administre la configuración de usuario avanzada y soporte aquí. Use los botones de acceso rápido a continuación para navegar fácilmente entre usuarios y acuerdos y hacer un seguimiento y ayudar con la incorporación.',
    txt_global_std_inordertoreceiveadrivingdividend75ofthedistancetraveledmustbecompletedwiththeappandchiptenfoldremembertohaveboththeappandbluetoothonwhenyoudriveandthetripwillberegistered: 'Para recibir un dividendo de conducción, el 75% de la distancia recorrida debe completarse diez veces con la aplicación y el chip. Recuerda tener tanto la aplicación como el Bluetooth activados cuando conduces, y el viaje quedará registrado.',
    txt_global_std_target: 'Objetivo',
    txt_global_std_youcanalwaysgobacktothisvideoguidebyclickingtheinthetopnavigationbar: 'Siempre puede volver a esta guía de video haciendo clic en ? en la barra de navegación superior.',
    txt_global_std_adjustyourannualmileage: 'Ajusta tu kilometraje anual',
    txt_global_std_myinsurance2: 'mi seguro',
    txt_global_std_wowgoodprogress: '¡Vaya, buen progreso!',
    txt_global_std_publicanyonewiththeappcanparticipate: 'Público (cualquiera que tenga la aplicación puede participar)',
    txt_global_std_p2pcalendar: 'Calendario',
    txt_global_std_changelater: 'cambiar más tarde',
    txt_global_std_key: 'Llave',
    txt_global_std_countryofresidence: 'País de residencia',
    txt_global_std_overviewoftheonboarding: 'Descripción general de la incorporación',
    txt_global_std_analyzingyourdrive1: 'Analizando tu disco',
    txt_global_std_wrongcurrentpassword: 'Contraseña actual incorrecta, inténtelo de nuevo.',
    txt_global_std_p2penterthedigitcodeorclickonthelinksentto: 'Ingrese el código de 6 dígitos o haga clic en el enlace enviado a: {número}',
    txt_global_std_messageheader: 'Encabezado del mensaje',
    txt_global_std_unlockbenefitsandrewards: 'Desbloquee beneficios y recompensas',
    txt_global_std_wewilldeducttheinvoicepaymentfromthe: 'Le descontaremos el pago de la factura el primer día de cada mes por la prima fija por adelantado y la prima variable del último mes. La deducción se realizará en la tarjeta de débito o crédito que utilizó al contratar el seguro.',
    txt_global_std_new: 'Nuevo',
    txt_global_std_activationcodeisnotvalid: 'El código de activación no es válido.',
    txt_global_std_fueltype: 'Tipo de combustible',
    txt_global_std_saveandclose: 'Guardar y cerrar',
    txt_global_std_p2ppushtitlenewmessage: 'Nuevo mensaje de {nombre}',
    txt_global_std_ifyoudonthaveanygroupsyetyoucancreatetheminthegroupsmodule: 'Si aún no tiene ningún grupo, puede crearlo en el módulo Grupos.',
    txt_global_std_keepasafedistance: 'Mantenga una distancia segura',
    txt_global_std_update: 'Actualizar',
    txt_global_std_email2: 'Correo electrónico',
    txt_global_std_ifyoudidntmakerequestignore: 'Si no realizó esta solicitud, puede ignorar este correo electrónico de manera segura.',
    txt_global_std_p2phasconfirmedbooking: ' tiene reserva confirmada',
    txt_global_std_levellevel: 'Nivel {nivel}',
    txt_global_std_howcaniimprovemyscore: '¿Cómo puedo mejorar mi puntuación?',
    txt_global_std_pressondevicebelowtoaddit: 'Presione en el dispositivo a continuación para agregarlo.',
    txt_global_std_yousignedupforournewsletter: '¡Te suscribiste a nuestro boletín!',
    txt_global_std_wereanyothercarsinvolved: '¿Hubo otros autos involucrados?',
    txt_global_std_p2pfaq26q: 'Por favor, dime cómo compartir un coche con amigos.',
    txt_global_std_invalidoldpasswordornewpassword: 'Contraseña antigua no válida o contraseña nueva',
    txt_global_std_ecodriving: 'Conducción ecológica',
    txt_global_std_general: 'General',
    txt_global_std_PaddonInterests: 'Carreras, golf, ciclismo de montaña',
    txt_global_std_thisisneededtogiveyouestimatedsavings: 'Esto es necesario para darle ahorros estimados.',
    txt_global_std_p2pshowyourreferralcode: 'Muestra tu código de referencia de amigo',
    txt_global_std_platenumberornameofcar: 'Matrícula o nombre del coche',
    txt_global_std_motionfitnesswillhelpyoustartandstopdrivesautomaticallysothatyoucanjustfocusonyourdriving: 'Motion & Fitness lo ayudará a iniciar y detener las unidades automáticamente para que pueda concentrarse en su conducción.',
    txt_global_std_activationcodeisrequired: 'Se requiere código de activación',
    txt_global_std_timet: 'T',
    txt_global_std_savechanges2: '¿Guardar cambios?',
    txt_global_std_p2pfinishandpayquestion: '¿Finalizar?',
    txt_global_std_firedamage123: 'Daño por fuego',
    txt_global_std_p2pgoheretofindcostsplitfunctioncheckyourdrivingstatsvisityourfriendsprofileandmore: 'Vaya aquí para encontrar la función de división de costos, verifique sus estadísticas de manejo, visite el perfil de sus amigos y más.',
    txt_global_std_reactivateinsurance: 'Reactivar seguro',
    txt_global_std_firstwithscore: 'Seamos los primeros con puntaje en el heat',
    txt_global_std_p2pconfirmedthebooking: 'confirmó la reserva.',
    txt_global_std_numberofparticipants: 'Número de participantes',
    txt_global_std_HansenShortDescription: 'Campeón EURO RX 2016, RX Lite Cup 2015, Campeón del Mundo en ROK Junior 2010',
    txt_global_std_somethingwentwrong: 'Algo salió mal',
    txt_global_std_nextstatus: 'Estado siguiente',
    txt_global_std_thatsokwhenyouarereadytodriveyoucanconnecthere: '¡Está bien! Cuando esté listo para conducir, puede conectarse a su automóvil aquí.',
    txt_global_std_viewalltrips: 'Ver todos los viajes',
    txt_global_std_nothanks2: 'No, gracias',
    txt_global_std_fiasdcecoscore: 'Puntuación FIA SDC Eco',
    txt_global_std_onlylettersnumbersandperiodsareallowed: 'Solo se permiten letras (a-z), números (0-9) y puntos (.).',
    txt_global_std_15discountonrenewal: '15% de descuento en la renovación',
    txt_global_std_yourcarwillstillbeinsuredbutyourpremium: 'Su automóvil seguirá estando asegurado, pero el costo de su prima podría aumentar debido a que le facturamos al nivel de prima 10 por unidades no conectadas.',
    txt_global_std_howdoisignupfortheinsurance: '¿Cómo me doy de alta en el seguro?',
    txt_global_std_youralreadyuseddevices: 'Tus dispositivos ya usados',
    txt_global_std_create: 'Crear',
    txt_global_std_borrowacar: 'pedir prestado un coche',
    txt_global_std_totalsavings: 'ahorros totales',
    txt_global_std_anticipation: 'Anticipación',
    txt_global_std_noeventtoday: 'Ningún evento hoy',
    txt_global_std_allday: 'Todo el dia',
    txt_global_std_signingupforinsurance: 'Contratar un seguro',
    txt_global_std_p2paddexpense: 'Agregar gasto',
    txt_global_std_ridewithmelockedkey: '¿Un amigo viajó contigo? Registre un viaje compartido para ganar esta insignia. Puede encontrar esta función en el menú con los tres puntos. Puedes ganar esta insignia una vez por semana.',
    txt_global_std_MSIG_InsuranceLetter9: 'certificado no.',
    txt_global_std_accruedlevel: 'Nivel acumulado =',
    txt_global_std_herunderkandusefleredetaljeromdennetur: 'A continuación puedes ver más detalles sobre este viaje.',
    txt_global_std_joinenerfy: 'Únete a {nombre de la aplicación}',
    txt_global_std_invitefriend: 'Invita a un amigo',
    txt_global_std_402requestfailed: '402 - Solicitud fallida',
    txt_global_std_almosttherewewillnowbuildyourdemoapp: 'Casi ahí. Ahora construiremos su aplicación de demostración.',
    txt_global_std_connectedbluetoothid: 'ID de Bluetooth conectado',
    txt_global_std_nosampledata: 'Sin datos de muestra',
    txt_global_std_nicknamesmayonlyincludeletters: 'Los apodos solo pueden incluir letras,',
    txt_global_std_75offdeductible: '75% de descuento en el deducible',
    txt_global_std_appcreatoroverview: 'Descripción general del Creador de aplicaciones',
    txt_global_std_HSolbergQuote: 'Siendo un conductor profesional durante tantos años, he sido parte de muchos desafíos de conducción en mi carrera. Lo primero que me viene a la mente cuando estoy detrás del volante es la “conducción segura”. Hagamos juntos este desafío en el Desafío de conducción inteligente de la FIA para "carreteras más seguras".',
    txt_global_std_whendrivingincityconditionsavoidchasingthenextcrossingortrafficlightbygainingspeedquicklythenslowingquicklythishurtstraveltimeandfueleconomyandincreasescrashrisklookingwellaheadanddrivingsmoothlywillhelpyouanticipateandhandleanyeventnew: 'Cuando conduzca en condiciones de ciudad, evite perseguir el siguiente cruce o semáforo aumentando la velocidad rápidamente y luego disminuyendo la velocidad rápidamente. Esto perjudica el tiempo de viaje y la economía de combustible, y aumenta el riesgo de accidentes. Mirar bien hacia adelante y conducir sin problemas lo ayudará a anticipar y manejar cualquier evento.',
    txt_global_std_PettersolbergDescription: "Petter Solberg, la primera persona en coronarse Campeón del Mundo de la FIA en dos disciplinas diferentes del automovilismo, siguió su éxito en el Campeonato del Mundo de Rallyes de 2003 al ganar el Campeonato del Mundo de Rallycross de la FIA durante dos temporadas consecutivas en 2014 y 2015. Habiendo comenzado su carrera en el automovilismo con salidas de rallycross. En su Noruega natal, Solberg, conocido como 'Hollywood' entre sus muchos fans por sus travesuras para complacer a la multitud, hizo su debut en el Campeonato Mundial de Rally en 1998 antes de cambiar a Subaru en 2000 antes de su primera victoria, en el Rally GB de 2002.",
    txt_global_std_BeforeFirstBill_Text3: 'Pagará dos primas cada mes; una prima variable y una fija. La prima fija es la misma y no cambia, se basa en el tipo de automóvil que tenga. La prima variable se basa completamente en cómo conduce. Si conduce de manera inteligente y segura, su prima se vuelve más barata.',
    txt_global_std_fiasdccompanychallenge: 'Desafío de la empresa FIA SDC',
    txt_global_std_filternofilter: 'Sin filtro',
    txt_global_std_vehicleidentificationwassaved: 'Se guardó la identificación del vehículo.',
    txt_global_std_areyousureyouwanttodeletethegroup: '¿Está seguro de que desea eliminar el grupo?',
    txt_global_std_seeyourprogressandgetinsightsandfeedbackonyourdriving: 'Vea su progreso y obtenga información y comentarios sobre su conducción.',
    txt_global_std_asyoumayhaveareportconsistingofmanymembersyouareabletosubmitarequesttodownloadthewholesetifyouwish: 'Como puede tener un informe que consta de muchos miembros, puede enviar una solicitud para descargar el conjunto completo si lo desea.',
    txt_global_std_welcometocompany: '¡Bienvenido a [Compañía]!',
    txt_global_std_useapi: 'Usar API',
    txt_global_std_secondsec: 'segundo',
    txt_global_std_howdoicompeteinappchallengeanswer: 'Participas automáticamente cuando te registras. No se preocupe, la tabla de clasificación solo mostrará su apodo, por lo que siempre permanecerá en el anonimato. Hay una competencia mensual y anual.',
    txt_global_std_conversionto: 'conversión a',
    txt_global_std_youwillsoonbeready: '¡Pronto estarás listo!',
    txt_global_std_TidemandShortDescription: 'Campeón WRC 2 2017, Campeón de Turismos 2010.',
    txt_global_std_learnmoreaboutus: 'Aprenda más acerca de nosotros',
    txt_global_std_manageyourteammembersandtheiraccountpermissions: 'Administre los miembros de su equipo y los permisos de sus cuentas',
    txt_global_std_sendcodetome: 'Envíame el código',
    txt_global_std_ready2go: 'Ready2Go',
    txt_global_std_yourplateshouldcontainacombinationofnumbersandletters: 'Su placa debe contener una combinación de números y letras.',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimeinconstantspeedunnecessaryspeedvariationcansometimesindicatedistractionorlackoffocus: 'En este tipo de viaje, el campeón eco promedio pasó más tiempo en velocidad constante. La variación innecesaria de la velocidad a veces puede indicar distracción o falta de concentración.',
    txt_global_std_whymustiallowalwaysallowlocationsservices: '¿Por qué debo permitir "permitir siempre los servicios de ubicación"?',
    txt_global_std_collect: 'Recoger',
    txt_global_std_added: 'Adicional',
    txt_global_std_atleast10characters: 'Al menos 10 caracteres',
    txt_global_std_acceptusertermsconditionsandprivacypolicy: 'Acepto los términos, condiciones y política de privacidad del usuario.',
    txt_global_std_OgierQuote: 'Próximamente, en breve, pronto...',
    txt_global_std_MSIG_InsuranceLetter38: 'Cualquier persona que conduzca por orden del Asegurado o con su permiso.',
    txt_global_std_noreaderyetskipthisstep: '¿Todavía no tienes lector? Saltar este paso',
    txt_global_std_tryingtosignyouintoenerfy: 'Intentando iniciar sesión en {appName}...',
    txt_global_std_almostdone: 'Casi termino',
    txt_global_std_anactivationcodecanbeusedtogetaccesstospecificeventsandchallenges: 'Se puede usar un código de activación para obtener acceso a eventos y desafíos específicos.',
    txt_global_std_points123: 'puntos',
    txt_global_std_forquestionsandfeedbackregardingtheappcontacttheemailabove: 'Para preguntas y comentarios sobre la aplicación, comuníquese con el correo electrónico anterior.',
    txt_global_std_liveonmap: 'vivir en el mapa',
    txt_global_std_theactivationcodehasbeensent: 'El código de activación ha sido enviado.',
    txt_global_std_somethingwentwrongpleasetryagainlater: 'Algo salió mal, inténtalo de nuevo más tarde.',
    txt_global_std_customerservice: 'Servicio al Cliente',
    txt_global_std_PolicySchedule17: 'NCDP,',
    txt_global_std_savechanges: 'Guardar cambios',
    txt_global_std_averagespeed: 'Velocidad media',
    txt_global_std_acceptthedongle: 'Permita conectar el dongle también a su teléfono',
    txt_global_std_annualprice: 'Precio Anual',
    txt_global_std_enteravalidcode: 'Introduzca un código válido.',
    txt_global_std_cimage31h: 'INTENTE EVITAR INCLUSO LAS PEQUEÑAS ACELERACIONES',
    txt_global_std_youneedtodrivemorethan10kmduringaheatweekinordertogetascoreintheheatleaderboard: 'Debe conducir más de 10 km durante una serie (semana) para obtener una puntuación en la clasificación de la serie.',
    txt_global_std_firstdriveunlocked: '¡Condujiste y recibiste una puntuación! ¡Buen trabajo!',
    txt_global_std_resendpassword: 'Reenviar contraseña',
    txt_global_std_chassinumberalreadyexists: 'El número de chasis ya existe',
    'txt_global_std_Al-KuwariChamp': '2.º lugar Copa del Mundo FIA 2016 de Rallies Cross-Country - T2 - Copilotos, 1.º FIA MERC 2 2017 - Copiloto, 1.º FIA MERC 2 2018 - Copiloto',
    txt_global_std_nextqualificationfortheendfinalstarts: 'Arranca la próxima clasificación para la Final Final',
    txt_global_std_enerfyriskportfoliotracker: 'Seguimiento de la cartera de riesgos de Enerfy',
    txt_global_std_p2ppushbodyhasnowfinalizedthebooking: '{name} ahora ha finalizado la reserva.',
    txt_global_std_socialbutterflylockedkey: 'Recomiende a 10 amigos para desbloquear este logro y gane 300 puntos para su Kinto Wallet.',
    txt_global_std_onboardingstatus: 'Estado de incorporación',
    txt_global_std_letsgetstarted: '¡Empecemos!',
    txt_global_std_fiasdc2019w1price3: 'Paquete de 1 temporada',
    'txt_global_std_ben-sulayemDescription': 'Ha competido en rallies de campeonatos africanos, europeos, de Oriente Medio y del Mundo. Ganó uno de sus títulos mientras se recuperaba de una fractura de cuello.',
    txt_global_std_subscribetoupdates: 'Suscríbete a las actualizaciones',
    txt_global_std_drivingtogetherismorefunaddyourcoworkersorsendthemalink: 'Conducir juntos es más divertido, agrega a tus compañeros de trabajo o envíales un enlace.',
    txt_global_std_p2pfaq16q: '¿Qué pasa si daño el auto?',
    txt_global_std_p2pcalendaroverview: 'Descripción general del calendario',
    txt_global_std_april: 'Abril',
    txt_global_std_sendsms: 'Enviar SMS',
    txt_global_std_WelcomeLetter_Text3: 'Si tiene alguna pregunta sobre su seguro de automóvil, comuníquese con nosotros o visite nuestro sitio web www.enerfy.se para obtener más información sobre cómo funciona {appName}.',
    txt_global_std_thisisrequired: 'Esto es requerido',
    txt_global_std_performancechart: 'Gráfico de rendimiento',
    txt_global_std_p2pselectcalendar: 'Seleccionar calendario',
    txt_global_std_p2paddmember: 'Añadir miembro',
    txt_global_std_youhave: 'Tú tienes',
    txt_global_std_comebacklatertherearenochallengeshereatthemoment: 'Vuelva más tarde, no hay desafíos aquí en este momento.',
    txt_global_std_premiumdiscount: 'Descuento Premium',
    txt_global_std_shareyourredeemcode: 'Comparte tu código de canje',
    txt_global_std_viewriskandco2levelsregardlessofvehicletypeandlocation: 'Ver los niveles de riesgo y CO2 independientemente del tipo de vehículo y la ubicación',
    txt_global_std_p2ppleaseenteravalidpriceabovezero: 'Introduce un precio válido superior a 0',
    txt_global_std_thisisgonnabesofunletsgetstartedwithyourreallysmartdriving: '¡Esto va a ser muy divertido! Comencemos con su conducción inteligente. Estaré cerca para anotarte muy bien.',
    txt_global_std_p2pnewmessage: 'Nuevo mensaje',
    txt_global_std_seedetails: 'Ver detalles',
    txt_global_std_createanaccounttocollectandstoreyourresultsitsfree: 'Cree una cuenta para recopilar y almacenar sus resultados. ¡Es gratis!',
    txt_global_std_competitionformat: 'Formato de competencia',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimedecreasingspeedeffectiveobservationandleavingasafefollowingdistancecanhelpreduceunnecessaryspeedvariations: 'En este tipo de viaje, el campeón ecológico promedio pasó menos tiempo disminuyendo la velocidad. La observación efectiva y el dejar una distancia de seguimiento segura pueden ayudar a reducir las variaciones de velocidad innecesarias.',
    txt_global_std_wrongpasswordpopuptext: 'Contraseña incorrecta',
    txt_global_std_thereisnoinsurancetoterminate: 'No hay seguro para terminar',
    txt_global_std_zipdriverriskmorerelevantthancreditscore: 'El riesgo de Zip-Driver es más relevante que el puntaje crediticio',
    txt_global_std_drivesafethismonth: 'Conduce seguro este mes',
    txt_global_std_p2pnoconfirmedbookingrequests: 'No hay solicitudes de reserva confirmadas',
    txt_global_std_vehicletype2: 'Tipo de vehiculo',
    txt_global_std_safetydrivingskillupwithwarikankinto: 'Habilidad de conducción segura con Warikan KINTO',
    txt_global_std_p2pnopendingbookingrequests: 'Sin solicitudes de reserva pendientes.',
    txt_global_std_notuploadeddrivingtime: 'Tiempo de conducción no subido:',
    txt_global_std_youhavenofinishedchallengesyet: 'Aún no tienes desafíos terminados',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatuscheckouttheachievementsbelowyoucanearnbybeingaappnamesuperdriver: 'Los logros lo ayudan a acumular puntos para ganar su estado de lealtad. ¡Mira los logros a continuación que puedes obtener al ser un súper conductor de {appname}!',
    txt_global_std_unabletosavesettings: 'No se puede guardar la configuración',
    txt_global_std_p2pviewyourbookings: 'Ver tus reservas.',
    txt_global_std_defineaneventthatinitiatesapushnotification: 'Definir un evento que inicia una notificación push',
    txt_global_std_howdoiearnsavingsanswer: 'Los ahorros se generan cuando se conduce seguro. Por cada viaje que conduzcas seguro, generarás un ahorro por kilómetro. ¡Cuanto más seguro conduzca, mayor será el ahorro! El ahorro es visible directamente después de que termine su viaje y será visible en su billetera {appname}.',
    txt_global_std_adwancedadmin: 'Administrador avanzado',
    txt_global_std_templatestabtext: 'Plantillas',
    txt_global_std_p2pmessages: 'Mensajes',
    txt_global_std_takeapicture: 'Toma una foto',
    txt_global_std_insuredvehicle: 'vehículo asegurado',
    txt_global_std_p2pitlookslikethisaccountisalreadyexist: 'Parece que esta cuenta ya existe',
    txt_global_std_p2pfaq23q: '¿Es posible cambiar la forma de dividir el costo?',
    txt_global_std_insurancecard: 'Tarjeta de seguro',
    txt_global_std_whatisanactivationcode: '¿Qué es un código de activación?',
    txt_global_std_showreasons: 'Mostrar razones',
    txt_global_std_adduserstodifferentgroups: 'Agregar usuarios a diferentes grupos.',
    txt_global_std_TomczykChamp: '16 años DTM - Campeón DTM 2011',
    txt_global_std_needstobeavalidphonenumbercanonlyincludenumbersand: 'Tiene que ser un número de teléfono válido. Solo puede incluir números y +',
    txt_global_std_typeofnotification: 'tipo de notificación',
    txt_global_std_datafailedtosent: 'Algo salió mal al intentar enviar sus datos, asegúrese de que su dirección de correo electrónico sea correcta y vuelva a intentarlo.',
    txt_global_std_daydreamingormindwandering: 'Soñar despierto o divagar',
    txt_global_std_p2phasacceptedtherequest: 'ha aceptado la solicitud.',
    txt_global_std_dashboard_groups: 'Grupos',
    txt_global_std_carsharing: 'Auto compartido',
    txt_global_std_estimatedpricebasedonyourperformance: 'El precio estimado este mes basado en su rendimiento es',
    txt_global_std_french: 'Francés',
    txt_global_std_howtogetstartedwithzipdriverriskindex: 'Cómo empezar con el índice de riesgo de Zip-Driver',
    txt_global_std_speedincrease: 'aumento de velocidad',
    txt_global_std_dates: 'fechas',
    txt_global_std_p2pkeepthecommunicationgoingandchatwithyourfriendaboutyourbookings: 'Mantén la comunicación y chatea con tu amigo sobre tus reservas.',
    txt_global_std_alreadyhaveanaccount: '¿Ya tienes una cuenta?',
    txt_global_std_PolicySchedule7: 'Año de registro',
    txt_global_std_loyalty_upsell_pitch_bulletpoint1: 'Incentivar la conducción segura y ecológica',
    txt_global_std_yourveryownfiasdccompanychallenge: 'Su propio espacio de trabajo de la empresa FIA SDC',
    txt_global_std_overview2: 'Visión general',
    txt_global_std_vs: 'contra',
    txt_global_std_p2pselectedmember: 'miembro seleccionado',
    txt_global_std_p2premembertomarkthemaspaidonceyoureceivethepayment: 'Recuerda marcarlos como pagados una vez que recibas el pago.',
    txt_global_std_noinsuranceregistered: 'Sin seguro registrado',
    txt_global_std_privateforthoseinmygroupwiththesameemaildomainname: 'Privado (para aquellos en mi grupo con el mismo nombre de dominio de correo electrónico)',
    txt_global_std_thisistheworldsfirstchallengerewardingsmartskillsatthewheelgetyourcompanyinvolvedinsmartdriving: 'Este es el primer desafío del mundo que recompensa las habilidades inteligentes al volante. Involucra a tu empresa en la conducción inteligente.',
    txt_global_std_yourdroveatscoretypescoreorbetterawesomeyouhaveearnedpointspoints: '¡Condujo a {score} o mejor! ¡Impresionante! ¡Has ganado {puntos} puntos!',
    txt_global_std_subscribetoupdates_2: 'Suscríbete a las actualizaciones',
    txt_global_std_areyousureyouwanttodisablethecommunication: '¿Está seguro de que desea deshabilitar la comunicación?',
    txt_global_std_acceptbluetooth: 'Aceptar Bluetooth',
    txt_global_std_odometer: 'Cuentakilómetros',
    txt_global_std_yourinsurancehasbeenterminated: '¡Su seguro ha sido cancelado!',
    txt_global_std_tosignwiththisaccountwejustneedsomecomplementaryinfoaboutyoubeforeyoucanstart: 'Para iniciar sesión con esta cuenta, ¡solo necesitamos información complementaria sobre usted antes de que pueda comenzar!',
    txt_global_std_kintofaq4q: '¿Cómo compito en Kinto Challenge?',
    txt_global_std_MSIG_InsuranceLetter17: 'Exceso:',
    txt_global_std_MikkelsenMusic: 'Escucho todo tipo de música cuando conduzco, pero diría que principalmente escucho pop y música electrónica de baile.',
    txt_global_std_notethiscodeexpiresin6minutesbutyoucangenerateanotherbylogginginagain: 'Nota: este código caduca en 6 minutos, pero puede generar otro iniciando sesión nuevamente.',
    txt_global_std_createnewpushnotification: 'Crear nueva notificación push',
    txt_global_std_theconnectiontypeissaved: 'El tipo de conexión se guarda.',
    txt_global_std_filteroptionactive: 'Activo',
    txt_global_std_reasontoterminatetheinsurance: 'Razón para terminar el seguro',
    txt_global_std_50000pts: '> 50 000 puntos',
    txt_global_std_youraveragescoreis2: 'Tu puntaje promedio es {puntos}',
    txt_global_std_getthecarinsuranceyoudeservethesaferyoudrivethemoreyousave: 'Consigue el seguro de coche que te mereces. Cuanto más seguro conduzcas, más ahorrarás.',
    txt_global_std_toregisteryourdrivingcorrectlyyoumustselectalways: 'Para registrar tu conducción correctamente, debes seleccionar «Siempre»',
    txt_global_std_bonuspaymentday: 'día de pago de bonificación',
    txt_global_std_yourcarmodelistoooldanddoesnthavebluetoothnodot: 'El modelo de tu coche es demasiado antiguo y no tiene Bluetooth.',
    txt_global_std_onboardingstatususeranalytics: 'Estado de incorporación / análisis de usuarios',
    txt_global_std_logout: 'Cerrar sesión',
    txt_global_std_userguides: 'Guías de usuario',
    txt_global_std_fromfromvalkmuptotovalkm: 'Desde {fromVal} km hasta {toVal} km.',
    txt_global_std_Fia_Receipt_Text10: 'Monto',
    txt_global_std_apitool: 'herramienta API',
    txt_global_std_p2pexpenses: 'Gastos',
    txt_global_std_zipriskindexinsuranceratingcomponent: 'Índice de riesgo Zip - Componente de calificación de seguros',
    txt_global_std_daydreamingisacommontypeofdriverdistractionyoumaythinkitsharmlessbutanythingthattakesyourmindoffthedrivingtaskincreasescrashriskchangingyourgazefrequentlycanhelpyoumaintainfocusontheenvironmentaroundyou: 'Soñar despierto es un tipo común de distracción del conductor. Puede pensar que es inofensivo, pero cualquier cosa que distraiga su mente de la tarea de conducir aumenta el riesgo de choque. Cambiar tu mirada con frecuencia puede ayudarte a mantener el enfoque en el entorno que te rodea.',
    txt_global_std_qualified: 'Calificado',
    txt_global_std_topimprovercomingsoon: 'Próximamente mejorador superior',
    txt_global_std_VilliersMileage: 'En Sudáfrica tenemos algunos pases hermosos y el de Franschoek, cerca de mi casa, es uno de mis favoritos.',
    txt_global_std_heresyour15discountcode: 'Aquí está su código de descuento del 15%',
    txt_global_std_savetranslation: 'Guardar traducción',
    txt_global_std_p2ppushtitlenewpaymentquestion: 'Nuevo pago?',
    txt_global_std_numberbetweenxandyrule: 'El número debe estar entre {x} y {y}',
    txt_global_std_edithistory: 'Editar historial',
    txt_global_std_areyouthenextworldchampion: '¿Eres el próximo campeón mundial?',
    txt_global_std_Fia_Receipt_Text11: 'Total',
    txt_global_std_qhowdoesthescoringsystemwork: 'P: ¿Cómo funciona el sistema de puntuación?',
    txt_global_std_drive: 'Conducir',
    txt_global_std_inorderfortheapptoworkproperlyweneedaccesstoyourphoneslocationaswellasexerciseandfitnessdata: 'Para que la aplicación funcione correctamente, necesitamos acceder a la ubicación de su teléfono, así como a los datos de ejercicio y estado físico.',
    txt_global_std_technicaldrivingperformance: 'Rendimiento técnico de conducción',
    txt_global_std_syncwithgarminconnect: 'Sincroniza con Garmin Connect™',
    txt_global_std_p2pnext: 'próximo',
    txt_global_std_HSolbergBesttip: 'Tener fluidez en la conducción, frenando menos = menos aceleraciones que consumen energía',
    txt_global_std_policynumbercouldnotbeverified: 'No se pudo verificar el número de póliza.',
    txt_global_std_thiswindowwillclosein30sec: 'Esta ventana se cerrará en {closeSeconds} segundos',
    txt_global_std_p2pfuel: 'Combustible',
    txt_global_std_apikey1: 'Clave API 1',
    txt_global_std_p2pand: 'y',
    txt_global_std_policynumber: 'Número de póliza',
    txt_global_std_teamc02savings: 'Ahorros del equipo c02',
    txt_global_std_SulayemChamp: '14 veces campeón de rally de Oriente Medio de la FIA',
    txt_global_std_PSolbergFacts: "Petter Solberg, la primera persona en coronarse Campeón del Mundo de la FIA en dos disciplinas diferentes del automovilismo, siguió su éxito en el Campeonato del Mundo de Rallyes de 2003 al ganar el Campeonato del Mundo de Rallycross de la FIA durante dos temporadas consecutivas en 2014 y 2015. Habiendo comenzado su carrera en el automovilismo con salidas de rallycross. En su Noruega natal, Solberg, conocido como 'Hollywood' entre sus muchos fans por sus travesuras para complacer a la multitud, hizo su debut en el Campeonato Mundial de Rally en 1998 antes de cambiar a Subaru en 2000 antes de su primera victoria, en el Rally GB de 2002.",
    txt_global_std_available: 'Disponible',
    txt_global_std_swipetoopenthecontrolcenterandmakesureyouhavebluetoothturnedon: 'Deslice para abrir el centro de control y asegúrese de tener Bluetooth activado.',
    txt_global_std_nothankyou: 'No gracias',
    txt_global_std_thankyouforallowingustoaccessyourbluetooth: 'Gracias por permitirnos acceder a su configuración de Bluetooth. Valoramos su privacidad y no compartiremos esta información con una entidad de terceros.',
    txt_global_std_theinsurancedeleted: 'El Seguro eliminado!',
    txt_global_std_challange: 'Reto',
    txt_global_std_personaldetails2: 'Detalles personales',
    txt_global_std_getstarsaftereachtripforhowgoodsafeandecofriendlyyourdrivewas: 'Después de cada viaje, obtienes una puntuación entre 0 y 100, según la seguridad con la que hayas conducido. Aquí puedes encontrar tu puntuación total.',
    txt_global_std_areyousurethatyouwanttocompletelyremoveyouraccount: '¿Está seguro de que desea eliminar por completo su cuenta, registros de manejo, información de seguros?',
    txt_global_std_youonlyhave: 'Tu solo tienes',
    txt_global_std_p2pgroupname: 'Nombre del grupo',
    txt_global_std_uploadintervalsecondssec: '{uploadIntervalSeconds} segundos',
    txt_global_std_HSolbergFacts: 'Solberg es el hermano mayor del Campeón del Mundo de Rallyes FIA 2003 Petter Solberg y, a través de su esposa sueca Maud, el padrastro del piloto de rallyes sueco Pontus Tidemand. Uno de los pilotos más experimentados del deporte, cuando está en el ritmo tiene el poder de avergonzar a los jóvenes.',
    txt_global_std_namesmayonlyincludeletters: 'Los nombres solo pueden incluir letras,',
    txt_global_std_qdoesagentacceptdriversthatarenotlicensedincountry: 'P: ¿{agent} acepta conductores que no tienen licencia en {country}?',
    txt_global_std_nameemailhasinvitedyoutojointheirgrouptostartdrivingsmarter: '{name} ({email}) te ha invitado a unirte a su {grupo} para empezar a conducir de forma más inteligente.',
    txt_global_std_p2ppushbodybookingcomingup: 'Tenga en cuenta que la reserva comienza "{start}".',
    txt_global_std_timeinretardation: 'Tiempo en retardo',
    txt_global_std_opensettingsinthephonelocationturnon: 'Abra "Configuración" en su teléfono. Vaya a Privacidad, abra el Servicio de ubicación y active Enerfy.',
    txt_global_std_loadingitems: 'Cargando artículos',
    txt_global_std_viamail: 'Por correo',
    txt_global_std_interests: 'Intereses',
    txt_global_std_TaxInvoice10: 'TODOS LOS TOMADORES INDIVIDUALES ESTÁN SUJETOS A UN PAGO ANTES DE LA GARANTÍA DE CUBIERTA',
    txt_global_std_teamaveragescorebig: 'PUNTUACIÓN PROMEDIO DEL EQUIPO\n',
    txt_global_std_MSIG_Onboarding2: 'Gracias por elegir MS First Capital Insurance Ltd. Ha firmado el seguro para el automóvil.',
    txt_global_std_p2pfinishquestion: '¿Finalizar?',
    txt_global_std_apitool_upsell_pitch_bulletpoint3: 'Integración de datos fácil y de bajo esfuerzo para mejorar la eficiencia empresarial',
    txt_global_std_notspecified: 'No especificado',
    txt_global_std_p2pwrongemailorpassword: '¡Correo o contraseña equivocada!',
    txt_global_std_ayesagentacceptsdriverswhoarelicensedinothercountries: 'R: Sí, {agent} acepta conductores con licencia de otros países.',
    txt_global_std_enterinvitationcode: 'Introduce el código de invitación',
    txt_global_std_thiswonttakemorethanaminuteyoullbeintheworkspaceonceitsready: 'Esto no tomará más de un minuto, estarás en el espacio de trabajo una vez que esté listo.',
    txt_global_std_f_lanzbesttip: 'Mire siempre hacia adelante, escanee el horizonte en busca de peligros potenciales. Al mismo tiempo disfrutar de las vistas.',
    txt_global_std_savingsisgeneratedifriskislowerthanclaims: 'El ahorro se genera si el riesgo es menor que las reclamaciones calculadas',
    txt_global_std_exportdata: 'Exportar datos',
    txt_global_std_qualifiedto2021finals: 'Clasificado a la final de 2021',
    txt_global_std_documentthedamage: 'Documentar el daño',
    txt_global_std_riskportfoliotracker: 'Rastreador de cartera de riesgo',
    'txt_global_std_Al-KhelaifiFacts': 'Abdulla participa en la mayoría de los eventos de automovilismo en Qatar y tiene varios campeonatos en diferentes tipos de carreras como National Drag, Qatar Mile, National Sprint y QTCC.',
    txt_global_std_from: 'De',
    txt_global_std_p2ppushtitlehasacceptedthebooking: 'Reserva aceptada',
    txt_global_std_callroadsideassistance: 'Llamar a asistencia en carretera',
    txt_global_std_loyalty: 'Lealtad',
    txt_global_std_selectcountry: 'Seleccionar país',
    txt_global_std_therulesforthecompanychallengeareasfollowingbrtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsbrdriveminimum1kmduringaheatinordertoqualifyforthechallengebrthesamegeographicalroutewillbescoredamaximumof10timesinoneday1: 'Las reglas para el desafío de la empresa son las siguientes:\n' +
      '<br />• El puntaje individual del conductor se calcula en un promedio basado en los viajes del 10% superior.\n' +
      '<br/>• Maneja un mínimo de 1 km durante una manga para calificar para el desafío.\n' +
      '<hr>• La misma ruta geográfica se puntuará un máximo de 10 veces en un día.',
    txt_global_std_HansenBesttip: 'Para mí, hay dos factores clave cruciales para la conducción inteligente. La primera: mira y comprueba lo que sucede a tu alrededor. El segundo: aprende y conoce tu coche, y cómo reacciona a tus acciones de aceleración, frenado y frenado del motor. La combinación de estos dos factores cruciales te llevará muy lejos en esta competencia.',
    txt_global_std_addinjuredperson: 'Añadir persona lesionada',
    txt_global_std_p2pexpensessummary: 'Resumen de gastos',
    txt_global_std_okaywewillendthetour: 'Bien, vamos a terminar el recorrido.',
    txt_global_std_PolicySchedule28: 'SGD1,000 TODAS LAS RECLAMACIONES SE IMPONEN A LOS CONDUCTORES QUE HAN DESACTIVADO EL DONGLE DURANTE AL MENOS 14 DÍAS EN UN AÑO CALENDARIO.',
    txt_global_std_insurancecost: 'Costo de seguro',
    txt_global_std_splittrips: 'viaje dividido',
    txt_global_std_youversususername: 'Tú contra {usuario}',
    txt_global_std_loyaltypointsgainedfor: 'Puntos de fidelidad ganados por reserva',
    txt_global_std_couldnotretrievedrivedata: 'No se pudieron recuperar los datos del viaje',
    txt_global_std_speedchangeanalysis: 'ANÁLISIS DE CAMBIO DE VELOCIDAD',
    txt_global_std_topecodrivers: 'Los mejores conductores ecológicos',
    txt_global_std_ongoinggames: 'Eventos en curso',
    txt_global_std_totalpointsthisweek: 'Total de puntos esta semana:',
    txt_global_std_statusnondriving: 'no conducir',
    txt_global_std_unabletosavenote: 'No se puede guardar la nota',
    txt_global_std_schedule: 'Calendario',
    txt_global_std_deleteapikey: 'Eliminar clave API',
    txt_global_std_leaderboard: 'Tabla de clasificación',
    txt_global_std_cherry: '¡Cereza!',
    txt_global_std_p2pendbeforestart: '¡Asegúrese de que la hora de finalización no sea anterior a la hora de inicio!',
    txt_global_std_monthshorthand: 'lun',
    txt_global_std_verifyyourphonenumber: 'verifica tu numero de telefono',
    txt_global_std_youarenowconnectedandready: '¡Ya está conectado y listo para comenzar su primer viaje!',
    txt_global_std_youearnedthisbadgextimes: '(Obtuviste esta insignia {x} veces)',
    txt_global_std_p2pconfirmprice: 'Confirmar precio',
    txt_global_std_p2pincludesafedriverbonus: 'Incluye bono de conductor seguro',
    txt_global_std_Fia_Receipt_Text14: 'El envío y el IVA (6%) están incluidos en el precio.',
    txt_global_std_isthisanelectriccar: '¿Es esto un coche eléctrico?',
    txt_global_std_MSIG_Onboarding5: '¡Nuestros clientes significan todo para nosotros y nos complace tenerlo a bordo!',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint2: 'Comprensión proactiva de las fortalezas y debilidades de la cartera',
    txt_global_std_uploadcustombadgeicon: 'Subir icono de insignia personalizado',
    txt_global_std_month: 'Mes',
    txt_global_std_zipdriver_upsell: 'Zip-Driver: determine el nivel de riesgo preciso en función del lugar de conducción.',
    txt_global_std_signinwithfacebook: 'Iniciar sesión usando Facebook',
    txt_global_std_PolicySchedule30: 'La cubierta del parabrisas bajo el Endoso No. 89C es ilimitada.',
    txt_global_std_removeaccount: 'Eliminar cuenta',
    txt_global_std_verifyphonenumber: 'Su código de seguridad para verificar el número de teléfono es',
    txt_global_std_deletegroup2: 'Eliminar grupo',
    txt_global_std_repetition: 'Repetición',
    txt_global_std_p2prepair: 'Reparar',
    txt_global_std_requestinsuranceactivationcodebysms: '¡Hola!\n' +
      'Ha solicitado un nuevo código de activación para su cuenta {appname}: {activationcode}\n' +
      'Saludos, pandilla de {appname} en {companyshort}.',
    txt_global_std_SignupFia_Text6: 'El registro se abrirá pronto.',
    txt_global_std_planningtips: 'Mirar más adelante (y no solo justo en frente de usted) generalmente da como resultado una conducción más suave y una mayor probabilidad de descubrir obstáculos a tiempo.\n' +
      '\n' +
      'Garantizando un viaje agradable para usted y sus pasajeros.',
    txt_global_std_riskindex_upsell_pitch_bulletpoint1: 'Información única sobre las tendencias de riesgo de los conductores en todo el mundo',
    txt_global_std_p2pcoderesent: 'Código reenviado',
    txt_global_std_daysremaininginthisperiod: '{días} días restantes en este período',
    txt_global_std_endaddress: 'dirección final',
    txt_global_std_didafriendridewithyou: '¿Un amigo viajó contigo?',
    txt_global_std_recruitfriendsandgetfreeheats: '¡Recluta amigos y obtén calores gratis!',
    txt_global_std_activationcode: 'Código de activación',
    txt_global_std_TomczykMileage: 'La ruta de las vacaciones en familia es, sobre todo, una de mis millas favoritas para conducir.',
    txt_global_std_ringsosphoneno: 'Llamar SOS: {phoneNo}',
    txt_global_std_areyoureadytodrive: '¿Listo para conducir?',
    txt_global_std_insuranceactivationkeyalreadycreated: 'Clave de activación del seguro ya creada',
    txt_global_std_p2pconnecttoyourfriendscarwhenyourbookingstartsyouwillgetfeedbackandinsightsonyourdriving: 'Conéctese al automóvil de su amigo cuando comience su reserva. Obtendrá comentarios e información sobre su forma de conducir.',
    txt_global_std_typicallyyouseeadigitalscreendisplayingtworowsofnumbersoneforthecarstotaldistancetravelledandoneforthedailydistances: 'Por lo general, verá una pantalla digital que muestra dos filas de números, una para la distancia total recorrida por el automóvil y otra para las distancias diarias.',
    txt_global_std_trafficdelaysdeadlinesandrunninglatetheycanallcausestresswhichisnegativeforyourhealthandsafetyallowingextratimeplanningyourrouteandcheckingtravelupdatescanhelptoreducestressandimproveyourfocusnew: 'Retrasos en el tráfico, plazos y retrasos; todos pueden causar estrés, lo cual es negativo para su salud Y seguridad. Permitir tiempo adicional, planificar su ruta y verificar las actualizaciones de viaje puede ayudar a reducir el estrés y mejorar su enfoque.',
    txt_global_std_TaxInvoice7: 'TODOS LOS TITULARES DE PÓLIZA CORPORATIVOS ESTÁN SUJETOS A UNA GARANTÍA DE PAGO DE LA PRIMA Para períodos de seguro de más de 60 días',
    txt_global_std_geyouwillreceiveanemailwithyourpersonaldata: 'Recibirás un email con tus datos personales',
    txt_global_std_codehasbeensentto: 'El código ha sido enviado a',
    txt_global_std_theenerfyglobalteam: 'El equipo de EnerfyGlobal',
    txt_global_std_p2pstartadrivetoaccessinsightsandstatisticsaboutyourdriving: 'Comience una conducción para acceder a información y estadísticas sobre su forma de conducir.',
    txt_global_std_removemissingtrip: 'Quitar viaje',
    txt_global_std_cimage17d: 'Planifica y mantente activo cuando estés en la carretera. Tome conciencia de cómo puede mejorar la conducción de distancias más largas.',
    txt_global_std_howdoyoumeasuremydrivinglexusanswer: 'La aplicación Lexus utiliza inteligencia artificial para medir su conducción. La aplicación se conecta a la nube y analizará cada segundo de su conducción, convertido en "un perfil". La IA está comparando sus perfiles con más de 750 millones de otros perfiles de conducción únicos. La IA tiene más de 16 años de experiencia para buscar la probabilidad de un patrón de conducción de estar involucrado en un accidente. Afortunadamente, después de casi todos los viajes, los conductores y pasajeros regresan a casa sanos y salvos con sus seres queridos.',
    txt_global_std_iwilldoitlater: 'lo haré después',
    txt_global_std_notsignedupforacovercubeinsuranceyet: '¿Aún no estás inscrito en un seguro de Covercube?',
    txt_global_std_sothedevicecanconnectyourphonewithyourcar: 'para que el dispositivo pueda conectar su teléfono con su automóvil.',
    txt_global_std_WelcomeLetter_Text2: 'En este correo electrónico, encontrará los documentos de su seguro, incluida su carta de seguro e información previa a la venta. Los términos completos del seguro se pueden encontrar en la aplicación {appName} y en www.enerfy.se. Si algo en los documentos adjuntos es incorrecto, por favor contáctenos para que podamos corregirlo.',
    txt_global_std_markermovediscount: 'Mueva el marcador para ver cómo su nivel afecta su descuento',
    txt_global_std_wetakeeverystepandmeasuretominimiseourco2footprintenergyconsumptionandroadaccidentsbyinspiringourdriverstosmarterdrivingandtrackingourperformance: 'Damos todos los pasos y medidas para minimizar nuestra huella de CO2, el consumo de energía y los accidentes de tráfico inspirando a nuestros conductores a conducir de manera más inteligente y rastreando nuestro desempeño.',
    txt_global_std_skip: 'Saltar',
    txt_global_std_achachievements: 'logros',
    txt_global_std_p2pfaq11a: '1.Presiona el menú con los tres puntos.\n' +
      '2.Ve a la página de tus amigos.\n' +
      '3.Presione el botón Invitar amigos.\n' +
      '4.Envíe un enlace de invitación a su amigo. Cuando su amigo descargue la aplicación a través del enlace, aparecerá en su lista de amigos.',
    txt_global_std_passwordsdonotmatch: 'Las contraseñas no coinciden',
    txt_global_std_sendthemalink: 'Envíales un enlace para que puedan usar la aplicación.',
    txt_global_std_howdoirenewmyinsurance: '¿Cómo renuevo mi seguro?',
    txt_global_std_35000pts2: '&#60;35 000 puntos',
    txt_global_std_dashboard_challenges: 'Desafíos',
    txt_global_std_notconnectedtoenerfycloud: 'No conectado a la nube de {appName}.',
    txt_global_std_p2pselectcar: 'Seleccionar coche',
    txt_global_std_exportcsv: 'Exportar CSV',
    txt_global_std_downloadingdrivers: 'Descarga de controladores',
    txt_global_std_invalidemail: 'Email inválido',
    txt_global_std_hello123: 'Hola',
    txt_global_std_succesfullyactivatedinsurance: 'Seguro activado correctamente',
    txt_global_std_notifications: 'Notificaciones',
    txt_global_std_usernotfoundinteam: 'Usuario no encontrado en el equipo.',
    txt_global_std_summaryofdistnacedivenperslectedperiod: 'Resumen de la distancia buceada por período seleccionado.',
    txt_global_std_PolicySchedule15: 'La póliza está sujeta a endosos/cláusulas',
    txt_global_std_iconheader: 'icono desbloqueado',
    txt_global_std_contactsupport: 'Soporte de contacto',
    txt_global_std_userlockedout: 'El usuario está actualmente bloqueado por demasiados intentos fallidos de inicio de sesión',
    txt_global_std_kintosmsandemailtext: '¡Empieza a conducir más seguro conmigo! Siga el enlace a continuación y obtenga 10 km de distancia para conducir con Kinto.',
    txt_global_std_since: 'Ya que',
    txt_global_std_unrestrictedinvitelink: 'Enlace de invitación sin restricciones',
    txt_global_std_alertbreaktimetext: 'Alarma de descanso activada.',
    txt_global_std_areyoudrivinganelectriccar: '¿Estás conduciendo un coche eléctrico?',
    txt_global_std_mycardoesnthavebluetoothanswer: '¡No hay problema! ¡Solicite un dongle BT-OBD y hará la magia! Simplemente conecte el dongle al cable y conéctelo a su cargador de 12v en el automóvil. Luego siga los pasos de "Conectar al automóvil" como de costumbre y elija el nombre "Enerfy OBD" durante la conexión Bluetooth.',
    txt_global_std_firsttimeinapp: 'Primera vez en la aplicación',
    txt_global_std_mutualfriends: 'Amigos en común',
    txt_global_std_p2pdeletemessage: 'Borrar mensaje',
    txt_global_std_improvetext: 'Viajes con bono seguidos',
    txt_global_std_marklundDescription: 'Comenzó a competir internacionalmente en 2011 y desde entonces ha competido en series como la Porsche Carrera Cup Scandinavia, el Campeonato Mundial/Europeo de Rallycross de la FIA y los X-Games.',
    txt_global_std_changeyournumber: 'cambia tu número',
    txt_global_std_error: 'Error',
    txt_global_std_drivingtechnique: 'técnica de conducción',
    txt_global_std_deleteachievementpopuptext: 'Su logro se eliminará de forma permanente. El progreso de tus usuarios se perderá. Quienes ya hayan recibido el logro aún podrán verlo.',
    txt_global_std_challengeinvitesent: 'Invitación al desafío enviada',
    txt_global_std_bycheckingthischeckboxandlogginginyoureacceptingour: 'Al marcar esta casilla de verificación e iniciar sesión, acepta nuestros',
    txt_global_std_startdriving: 'empezar a conducir',
    txt_global_std_youwillhavedaysdaysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithagentwithalapseincoverage: 'Tendrá {days} días a partir de la fecha de vencimiento para realizar un pago y continuar su póliza con {agent} con un lapso de cobertura.',
    txt_global_std_thechaser: 'El perseguidor',
    txt_global_std_pickyourcoverimage: 'Elige tu imagen de portada',
    txt_global_std_tobeabletosendmessagesweneedtoaddyourfriendsletsinvitethem: 'Para poder enviar mensajes necesitamos agregar a tus amigos. ¡Vamos a invitarlos!',
    txt_global_std_pleasechoosewhichtypeofpictures: 'Por favor, elija qué tipo de imágenes',
    txt_global_std_demotext5: 'Cuando anotas, ¡salvas! Vea su bono total aquí.',
    txt_global_std_p2pverifyphonenumber: 'Verificar número de teléfono',
    txt_global_std_yourlinkshouldbeavailableinyourinboxin5minutespleaseremembertocheckyourjunk: 'Su enlace debería estar disponible en su bandeja de entrada en 5 minutos. Por favor, recuerda revisar tu basura.',
    txt_global_std_startyourcar: 'Arranca tu auto.',
    txt_global_std_manydriversrelytooheavilyoncruisecontrolitiscomfortableyesbutlethalforfueleconomyinmanycasesusingcruisecontrolontheslightestuphillordownhillwillcausetheenginetofighthardtosatisfyyourwishofmaintainingtheexactspeedyousetinsteadmanagingyourspeedwithyourfootandaimingforasmoothridewillhelpyousaveahugeamountonfuel: 'Muchos conductores confían demasiado en el control de crucero. Es cómodo, sí, pero letal para el ahorro de combustible en muchos casos. Usar el control de crucero en la más mínima cuesta arriba o cuesta abajo hará que el motor luche duro para satisfacer su deseo de mantener la velocidad exacta que estableció. En su lugar, controlar la velocidad con el pie y apuntar a una conducción suave te ayudará a ahorrar una gran cantidad de combustible.',
    txt_global_std_bethefirsttoreceivenews: 'Sé el primero en recibir noticias',
    txt_global_std_p2pitstimetosplityourcostformonthofph: 'Es hora de dividir su costo para el mes de {month}',
    txt_global_std_enterzipcode: 'Introduzca código postal',
    txt_global_std_top3bestdrives: 'Los 3 mejores discos duros',
    txt_global_std_KristofferssonMusic: 'Me gusta escuchar Godsmack.',
    txt_global_std_contactinfogreaterthaneuformoreinformationonhowtoactivatethismodule: 'Póngase en contacto con info@greaterthan.eu para obtener más información sobre cómo activar este módulo',
    txt_global_std_startguidetip3: 'Haga clic en el botón Reproducir en la parte inferior central de la pantalla.',
    txt_global_std_carsagreementsinsurance: 'Autos y acuerdos / seguros',
    txt_global_std_enteravalidchassisnumber: 'Introduzca un número de chasis válido.',
    txt_global_std_p2pyoudonthaveanycostsplitsorinvoicesyet: 'Todavía no tiene ninguna división de costos ni facturas.',
    txt_global_std_p2pacceptmarketingandprivacy: 'Acepte la política de privacidad y regístrese para recibir el boletín.',
    txt_global_std_p2pselectfriendsoragroup: 'Seleccionar amigos o un grupo',
    txt_global_std_cimage4h: 'PLAN DE PARADAS FRECUENTES',
    txt_global_std_notconnectednoaddress: 'No conectado - sin dirección',
    txt_global_std_savingsisbasedonrisklevelanddistance: 'El ahorro se basa en el nivel de riesgo y la distancia',
    txt_global_std_searchfriends: 'Buscar amigos',
    txt_global_std_p2pnofriendsfoundpleaseinvitefriend: 'No se encontraron amigos, por favor invite a un amigo',
    txt_global_std_insureddistance_used_100__1: 'Du har nu använt din körsträcka. Para att få full kompensation är det viktigt att du inte överskrider antalet kms som valts. Vänligen kontakta eller justera körsträckan här.',
    txt_global_std_p2psendrequest: 'Enviar petición',
    txt_global_std_usersupportcenter: 'Centro de atención al usuario',
    txt_global_std_drivingstartandstopsettings: 'Configuraciones de inicio y parada de conducción',
    txt_global_std_aaninsuranceadjusterwillcontactyouwithin24hoursofyourclaimbeingsubmittedtheentireclaimsprocesswilldependonthecomplexityoftheclaim: 'R: Un ajustador de seguros se comunicará con usted dentro de las 24 horas posteriores a la presentación de su reclamo. Todo el proceso de reclamaciones dependerá de la complejidad de la reclamación.',
    txt_global_std_signedinas: 'Registrado como',
    txt_global_std_pleaseenablebluetoothlocationservicesandmotionfitness: 'Habilite Bluetooth, servicios de ubicación y Motion & Fitness.',
    txt_global_std_createanaccounttostartyour30dayfreetrial: 'Cree una cuenta para comenzar su prueba gratuita de 30 días.',
    txt_global_std_members3: 'miembros',
    txt_global_std_yourchassisshouldcontainacombinationofnumbersandletters: 'Su chasis debe contener una combinación de números y letras.',
    txt_global_std_directtoapp: 'Directo a la aplicación',
    txt_global_std_cimage10d: 'Conduzca en la marcha más alta posible y mantenga bajas las RPM.',
    txt_global_std_TidemandInterests: 'Deportes acuáticos, ver amigos y Motorsports',
    txt_global_std_firsttimeusingtheserviceregisterfromsignuplink: '¿Primera vez que usa el servicio? Regístrese desde {signUpLink}',
    txt_global_std_youhavedeniedthelocationpermission: 'Has denegado el permiso de ubicación.',
    txt_global_std_somethingwhentwrongtryagain: 'Algo salió mal, intenta de nuevo.',
    txt_global_std_yourreporthasbeensentsuccessfully: 'Su informe ha sido enviado con éxito!',
    txt_global_std_fromtheleftsidetoolbarhoverorclickontheiconstonavigatethroughtheportaltoitsdifferentcomponentsandpages: 'Desde la barra de herramientas del lado izquierdo, desplace o haga clic en los iconos para navegar a través del portal a sus diferentes componentes y páginas.',
    txt_global_std_october: 'Octubre',
    'txt_global_std_drivedetailshavebeensentto{email}': 'Los detalles del viaje han sido enviados a {email}',
    txt_global_std_payouts: 'Pagos',
    txt_global_std_choosesetup: 'Elija la configuración',
    txt_global_std_picturereceived: 'Imagen recibida',
    txt_global_std_oliversolbergDescription: 'Hijo de Peter Solberg. Conduce el coche de su padre para el equipo nórdico.',
    'txt_global_std_H.SolbergDescription': 'Solberg är den äldre bror hasta 2003, el Campeón del Mundo de Rallyes de la FIA, Petter Solberg, y Genom sin svenska maka Maud, styvfar hasta den svenska rallydrivaren Pontus Tidemand. En av sportens mest erfarna förare, när han är i spåret, har han makt att skämma bort ungdomarna.',
    txt_global_std_cimage32h: 'CADA ACELERACIÓN CUESTA DINERO',
    txt_global_std_tryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagain: '• Intente restablecer la conexión de su automóvil a su teléfono eliminándolo y luego agregándolo nuevamente.',
    txt_global_std_whendrivingonmotorwaystoomanydriverspumptheacceleratorpedalupanddowninaneverendingpattern100kmhto110kmhthen110kmhto100kmhandsoonthistypeofdrivingusesunnecessaryfuelkeepingasafesuitablefollowingdistancecanhelpavoidthetemptationtovaryspeedunnecessarily: 'Al conducir por autopistas, demasiados conductores pisan el acelerador hacia arriba y hacia abajo en un patrón interminable: ¡de 100 km/h a 110 km/h, luego de 110 km/h a 100 km/h y así sucesivamente! Este tipo de conducción utiliza combustible innecesario. Mantener una distancia de seguimiento adecuada y segura puede ayudar a evitar la tentación de variar la velocidad innecesariamente.',
    txt_global_std_reward: 'Premio',
    txt_global_std_changeyourcontactdetails: 'Cambiar su información de contacto',
    txt_global_std_cimage3d: 'Trate de prever los acontecimientos',
    txt_global_std_thepumper: 'el bombeador',
    txt_global_std_hereisthetestkey: 'Aquí está la clave de prueba.',
    txt_global_std_headers: 'Encabezados',
    txt_global_std_3selectyourcarinthelist: '3. Selecciona tu coche en la lista:\n',
    txt_global_std_textaboutwhentranslationswillbevisibleinappetc: 'Texto sobre cuándo estarán visibles las traducciones en la aplicación, etc.',
    txt_global_std_useruploadtemplate: 'Plantilla de carga del usuario',
    txt_global_std_joinwithactivationcode: 'Únete con el código de activación',
    txt_global_std_communityteams: 'Equipos comunitarios',
    txt_global_std_resendemail: 'Reenviar email',
    txt_global_std_headerpassword: 'Clave',
    txt_global_std_f_lanzwhyjoin: 'Mientras aprendemos sobre los desafíos que enfrentamos diariamente en las carreteras, disfrutemos al mismo tiempo.\n' +
      'Aprender divirtiéndose siempre es igual a mejores resultados',
    txt_global_std_OgierFacts: 'Es el actual poseedor del Campeonato Mundial de Pilotos de Rally, habiendo ganado el título en seis ocasiones, en 2013, 2014, 2015, 2016, 2017 y 2018. Con 44 victorias en el Campeonato Mundial de Rally y seis títulos WRC consecutivos, es el segundo piloto de WRC más exitoso, después del ex compañero de equipo de Citroën WRC Sébastien Loeb (nueve títulos).',
    txt_global_Fia_Receipt_Text1: '¡Bienvenidos!',
    txt_global_std_teamranking: 'Clasificación del equipo',
    txt_global_std_p2pawaitingapproval: 'Esperando aprobacion',
    txt_global_std_MSIG_InsuranceLetter35: 'MS First Capital Insurance Ltd 6 Raffles Quay #21-00 Singapur 048580 Tel: (65) 6222 2311 Fax: (65) 6222 3547 www.msfirstcapital.com.sg',
    txt_global_std_exclude: 'Excluir',
    txt_global_std_sendinvite: 'Enviar invitación',
    txt_global_std_milesmi: 'Millas (millas)',
    txt_global_std_finalistsheat1: 'Finalistas - Serie 1',
    txt_global_std_whenwouldyouliketoterminateyourinsurance2: '¿Cuándo le gustaría terminar su seguro?',
    txt_global_std_fixitnow: 'Arréglalo ahora',
    txt_global_std_p2ppushbodyaddedexpences: 'Consultar gastos en la reserva.\nDesde: {periodo de reserva}',
    txt_global_std_theloyaltysystem: 'El sistema de fidelización',
    txt_global_std_entertheemailthatyouusedwhenyouregisteredyourinsurance: 'Ingrese el correo electrónico que utilizó cuando registró su seguro',
    txt_global_std_choosealwaysorallowwhileusing: 'Elija "Siempre" o "Permitir mientras se usa".',
    txt_global_std_gpsactive: 'GPS activo',
    txt_global_std_discardchanges: 'Descartar los cambios',
    txt_global_std_theuseriscurrentlylockedoutfortoomanyfailedattempts: 'El usuario está actualmente bloqueado por demasiados intentos fallidos',
    txt_global_std_levelprogress: 'Progreso de nivel',
    txt_global_std_MSIG_InsuranceLetter23: '* Siempre que la persona que conduce esté autorizada de acuerdo con la licencia u otras leyes o reglamentos para conducir el Vehículo Motorizado o se le haya permitido y no esté descalificado por orden de un Tribunal de Justicia o en virtud de cualquier promulgación o reglamento en ese nombre. de conducir el Vehículo Motorizado.',
    txt_global_std_accidentguide: 'Guía de accidentes',
    txt_global_std_p2plendyourkintotofamilyandfriends: 'Preste su Kinto a familiares y amigos',
    txt_global_std_BeforeFirstBill_Text1Enerfy: 'Próximamente sacaremos dinero de tu tarjeta para el seguro de tu coche. Hemos recopilado las preguntas más comunes que tienen nuestros clientes al recibir su primera factura.',
    txt_global_std_yourdrivewillendin: 'Tu viaje terminará automáticamente en:',
    txt_global_std_howdoyoumeasuremydrivinganswer: '{appname} usa inteligencia artificial para medir tu conducción. La aplicación se conecta a la nube y analizará cada segundo de su conducción, convertido en "un perfil". La IA está comparando sus perfiles con más de 750 millones de otros perfiles de conducción únicos. La IA tiene más de 16 años de experiencia para buscar la probabilidad de un patrón de conducción de estar involucrado en un accidente. Afortunadamente, después de casi todos los viajes, los conductores y pasajeros regresan a casa sanos y salvos con sus seres queridos.',
    txt_global_std_p2ptemppassword: 'Contraseña temporal',
    txt_global_std_roadsideassistance: 'Asistencia en carretera',
    txt_global_std_signupforinsurance: 'Regístrese para el seguro',
    txt_global_std_100pointtripachievementlocked: 'Termina un viaje en {scoretype} {score} o mejor para desbloquear este logro y ganar 100 puntos.',
    txt_global_std_keepingyourmindandeyesonwhatshappeningaroundyouisvitalforyoursafetyandthesafetyofothersphoneactivityandotherdistractionsaffectyourfocusandincreasecrashrisknew: 'Mantener la velocidad prevista es sumamente importante para el ahorro de combustible. La mente errante y la falta de concentración a menudo provocan variaciones de velocidad hacia arriba y hacia abajo, lo que genera aceleraciones innecesarias y, por lo tanto, aumenta el consumo de combustible.',
    txt_global_std_baseurl: 'URL base',
    txt_global_std_SignupFia_Text1: '¡Estás dentro!',
    txt_global_std_leavechallenge: 'Dejar desafío',
    txt_global_std_accountcreatedlogin: 'Tu cuenta ha sido creada, ¡ya puedes iniciar sesión!',
    txt_global_std_ihavesoldmycar2: 'he vendido mi coche',
    txt_global_std_hi: '¡Hola!',
    txt_global_std_photo: 'Foto',
    txt_global_std_TaxInvoice9: 'La aseguradora tiene derecho a un pago de prima prorrateado por la cobertura de 60 días (sujeto a un mínimo de S$25).',
    txt_global_std_obdconnected: 'OBD conectado',
    txt_global_std_nodatatoshow_loyalty: 'No hay datos para mostrar',
    txt_global_std_ifyoudidntmakethisrequestyoucansafelyignorethisemail: 'Si no realizó esta solicitud, puede ignorar este correo electrónico de manera segura.',
    txt_global_std_activationcodeemail1: 'Su código de activación',
    txt_global_std_p2ppushtitlechangeperiodfromto: 'Período cambiado',
    txt_global_std_minutesshort: 'metro',
    txt_global_std_youhavenowreceivedamountyenatcompany: 'Ahora ha recibido {cantidad} yenes en {empresa}.',
    txt_global_std_chooseafile: 'Escoge un archivo',
    txt_global_std_alertlocationnotalways: 'Establezca el permiso del Servicio de ubicación en Siempre para registrar unidades sin pérdida de datos.',
    txt_global_std_nodriversonleaderboard: 'Actualmente no hay pilotos en la clasificación',
    txt_global_std_nextstep: 'próximo',
    txt_global_std_registrationnumberalreadyexists: 'El número de registro ya existe',
    txt_global_std_typeofmessage: 'tipo de mensaje',
    txt_global_std_birthdate: 'Fecha de nacimiento',
    txt_global_std_badgeisworthxpointsunlocked: 'Valor de la insignia: {puntos}P',
    txt_global_std_realtimefeedbackonthego: 'Comentarios en tiempo real sobre la marcha.',
    txt_global_std_youarereadytogodriveandearnyourfirstbonus: '¡Estás listo para ir! Conduce y gana tu primer bono :)',
    txt_global_std_Fia_Receipt_Text13: 'info@fiasmartdrivingchallenge.com',
    txt_global_std_inactive: 'Inactivo',
    txt_global_std_retardation: 'Retraso',
    txt_global_std_totalminutes: 'Tiempo Total',
    txt_global_std_yesihaveredeemedmypoints: '¡Sí, he canjeado mis puntos!',
    txt_global_std_jointeam: 'Unirse al equipo',
    txt_global_std_p2pblockeddate: 'fecha bloqueada',
    txt_global_std_getstartedmanual: 'Manual de inicio',
    txt_global_std_bringoutyourcompetitivesideandreceivethehighestscoreoftheevent: 'Saca tu lado competitivo y obtén la puntuación más alta del evento.',
    txt_global_std_timeinconstantspeed: 'Tiempo en velocidad constante',
    txt_global_std_claimsdepartment: 'Departamento de reclamaciones',
    txt_global_std_p2pwrongcodetryagain: 'Codigo erroneo. Intentar otra vez.',
    txt_global_all_wednesday: 'miércoles',
    txt_global_std_MSIG_InsuranceLetter14: 'Institución financiera',
    txt_global_std_doyouacceptthechallenge: 'aceptas el reto?',
    txt_global_std_off: 'Apagado',
    txt_global_std_oversigtovernivauetpdintur: 'Resumen de niveles',
    txt_global_std_allowallthetime: 'Permitir todo el tiempo',
    txt_global_std_youcandobetter: '¡Puedes hacerlo mejor!',
    txt_global_std_yourdrivewasnotregisteredifyouhadyourenerfypluginconnectedwhiledrivingthecarbutdidnothavetheappbluetoothorgpslocationservicesactivesothatitregistersdrivingdatacompletedrunswillbechargedafterwardsinpremiumlevel10to15: 'Su unidad no fue registrada. Si tenías tu plug-in Enerfy conectado mientras conducías el coche pero no tenías activa la app, el Bluetooth o los servicios de localización/GPS para que registre los datos de conducción, las carreras completadas se cobrarán después en el nivel premium 10 a 15.',
    txt_global_std_atrustedscoreusedbyfia: 'Una puntuación fiable utilizada por la FIA',
    txt_global_std_thedriverwiththebestscorewinstheround: '¡El piloto con la mejor puntuación gana la ronda!',
    txt_global_std_usertermsandconditions: 'Términos y condiciones del usuario',
    txt_global_std_verifyemail: 'Verificar correo electrónico',
    txt_global_std_authentication: 'Autenticación',
    txt_global_std_tomasengechamp: 'Primer piloto checo en F1 e Indycar, campeón de circuito checo, campeón alemán de FFord',
    txt_global_std_carrorewardworkshops: 'Talleres de recompensa carro',
    txt_global_std_welcometo2: 'Bienvenido a',
    txt_global_std_cardnummer: 'Número de tarjeta',
    txt_global_std_henningsolbergDescription: 'Solberg es el hermano mayor del campeón del mundo de rallies de la FIA de 2003, Petter Solberg, y a través de su esposa sueca, Maud, el padrastro del piloto de rallies sueco Pontus Tidemand. Uno de los pilotos más experimentados del deporte, cuando está en el ritmo tiene el poder de avergonzar a los jóvenes.',
    txt_global_std_cimage10h: 'CONDUCIR EN BAJAS RPM Y ALTAS VELOCIDADES',
    txt_global_std_signedinasv2: 'Registrado como',
    txt_global_std_insureddistance_used_80__1: 'Du har nu använt 80% av din årliga valda körsträcka. Vill du justera körsträckan?',
    txt_global_std_p2pbookingmodifiedsuccessfully: 'Reserva modificada con éxito',
    txt_global_std_ConfirmFia_Text4: 'Tiempo',
    txt_global_std_p2pfaqandsupport: 'Preguntas frecuentes y soporte',
    txt_global_std_congratulationsyoursetupiscompleteandyouarereadytogo: '¡Felicidades! Su configuración está completa y está listo para comenzar.',
    txt_global_std_nottowed: 'no remolcado',
    txt_global_std_zipdriver: 'Controlador de cremallera',
    txt_global_std_userstate: 'Estado de usuario',
    txt_global_std_p2pyoucaninvitexfriendspercar: 'Puedes invitar a {número} amigos por coche',
    txt_global_std_online: 'En línea',
    txt_global_std_obdreaderonitsway: '¡Lector OBD en camino!',
    txt_global_std_cimage19h: 'MANTENGA SU GAS CONSTANTE',
    txt_global_std_p2pchooseafriendorinviteanewfriend: 'Elige un amigo o invita a un nuevo amigo',
    txt_global_std_notificationsisagreatwaytostayontopallowthemtoletusguideyoutowardsbecomeanevensmarterdriverandtakeyourdrivingtothenextlevel: 'Las notificaciones son una excelente manera de mantenerse al tanto. Permítales que nos dejen guiarlo para convertirse en un conductor aún más inteligente y llevar su conducción al siguiente nivel.',
    txt_global_std_mpltipafpp: 'Este es un conductor hábil, sin embargo, a veces durante un viaje y solo por un corto período de tiempo, este conductor tiende a perder el enfoque en Cool Driving. Algunas distracciones o estrés pueden hacer que este conductor ignore la conciencia de seguridad. Esto a menudo se soluciona hablando con el conductor y pidiéndole su consideración.',
    txt_global_std_translationtool_upsell_pitch_bulletpoint1: 'Haga que su aplicación sea multilingüe rápidamente',
    txt_global_std_itisa12digitcodethatyoureceivefromtheeventorchallengeorganizer: 'Es un código de 12 dígitos que recibes del organizador del evento o desafío.',
    txt_global_std_searchandmanageagreements: 'Buscar y administrar acuerdos',
    txt_global_std_afollowthesestepstoconnecttheapptoyourcar1pressthedrivebuttonintheapp2attachtheobddevicetothecableandplugitintothe12vpowercigarettelightersocketinyourcar3startyourcarsengine4selecttheobdreaderinthelistintheappandcontinue: 'R: Siga estos pasos para conectar la aplicación a su automóvil:\n' +
      '1. Presione el botón de conducir en la aplicación\n' +
      '2. Conecte el dispositivo OBD al cable y conéctelo a la toma de corriente de 12v (encendedor de cigarrillos) de su automóvil.\n' +
      '3. Arranca el motor de tu coche\n' +
      '4. Seleccione el lector OBD en la lista de la aplicación y continúe.',
    txt_global_std_calendar: 'Calendario',
    txt_global_std_p2pnonsequentialdatesselected: 'Fechas no secuenciales seleccionadas',
    txt_global_std_sortby: 'Ordenar por',
    txt_global_std_youcannoteditthisfromvisualeditor: 'No puede editar esto desde el editor visual.',
    txt_global_std_teamcolon: 'Equipo:',
    txt_global_std_exportxml: 'Exportar XML',
    txt_global_std_youraveragelevelis123: 'Tu nivel medio es',
    txt_global_std_triptypes: 'Tipos de viaje',
    txt_global_std_belowisalistofalluserswhosnotcompletedthisstep: 'A continuación se muestra una lista de todos los usuarios que no completaron este paso',
    txt_global_std_ChicheritFacts: 'El atleta de monstruos Guerlain Chicherit sube al Campeonato Mundial de Rallycross de la FIA en 2018 con su propio equipo GC Kompetition conduciendo el nuevo Renault Megane RS desarrollado por Prodrive. Esquiador profesional y cuatro veces campeón mundial de freeride, Chicherit ostenta el récord de convertirse en el primer hombre en la historia en completar una voltereta hacia atrás sin ayuda en un automóvil. Chicherit hizo su debut en World RX en el JRM Mini RX Countryman en 2015.',
    txt_global_std_p2ptotal: 'Total:',
    txt_global_std_sendasemail: 'Enviar como correo electrónico',
    txt_global_std_advancedusersettingsfor: 'Configuración de usuario avanzada para {name}',
    txt_global_std_bluetoothreasontext: 'Se requiere una conexión Bluetooth para asegurarse de que está sentado en su automóvil.',
    txt_global_std_wrongpassword: 'Contraseña incorrecta',
    txt_global_std_youaredrivingregno: 'Estás conduciendo {regno}',
    txt_global_std_p2pblockmycar: 'bloquear mi coche',
    txt_global_std_loadingteamleaderboard: 'Cargando clasificación del equipo...',
    txt_global_std_successfulpasswordchange: 'Has cambiado satisfactoriamente tu contraseña.',
    txt_global_std_dayd: 'd',
    txt_global_std_inviteandinspireeverydriverinyournetworktoparticipateandgetnewdataonyouresgperformance: 'Invite e inspire a todos los conductores de su red a participar y obtenga nuevos datos sobre su desempeño ESG',
    txt_global_std_startadrive: 'iniciar un viaje',
    txt_global_std_statusdriving: 'Conduciendo',
    txt_global_std_tilladnotifikationer: 'Permitir notificaciones automáticas',
    txt_global_std_safedriverweeklyunlockedkey: '¡Buen trabajo {usuario}! Mantuviste una puntuación superior a {score} durante una semana.',
    txt_global_std_wecannotfindanydata: '¡No podemos encontrar ningún dato!',
    txt_global_std_qhowwilligetpaidforaclaim: 'P: ¿Cómo me pagarán por un reclamo?',
    txt_global_std_notripsperday: 'Número de viajes por día',
    txt_global_std_kintofaqlink1: 'Manual de inicio (japonés)',
    txt_global_std_ranking: 'Clasificación',
    txt_global_std_noconnectiontocar: 'Sin conexión con el coche',
    txt_global_std_yournicknameshouldcontainatleastthree: 'Su apodo debe contener al menos tres (3) caracteres y sin espacios.',
    txt_global_std_decline: 'Rechazar',
    txt_global_std_p2pconfirm: 'Confirmar',
    txt_global_std_tobeabletostartadrive: 'Para poder iniciar un viaje, necesitamos acceso a bluetooth, servicios de ubicación y fitness de movimiento.',
    txt_global_std_SendMyPosition_Text1: 'te ha enviado su posición desde la aplicación {appName}.',
    txt_global_std_enterbasepremium: 'Ingrese la prima base',
    txt_global_std_colortheme: 'Tema de color:',
    txt_global_std_1gotosettingsforappnameinyourphonebrb2allowaccesstomotionfitnessb: '1. Vaya a la configuración de {appname} en su teléfono.<br><b>2. Permitir el acceso a Motion & Fitness.</b>',
    txt_global_std_mostpopular: 'Más popular',
    txt_global_std_challengeaverage1: 'Desafío promedio',
    txt_global_std_statistikfordennekretur: 'Estadísticas de este viaje',
    txt_global_std_OSolbergQuote: 'Cotización próximamente...',
    txt_global_std_invitationsent: 'Invitación enviada.',
    txt_global_std_tilladatappensenderdignotifikationernrdererregistreretnyekreture: 'Permita que Tryg Drive le envíe notificaciones cuando tenga acciones que hacer en la aplicación.',
    txt_global_std_p2pcarcare: 'Cuidado del automóvil',
    txt_global_std_p2pemailopenapp: 'ABRIR {nombre de la aplicación}',
    'txt_global_std_Al-KuwariMusic': 'Música clásica.',
    txt_global_std_pleasefirstfillinyourfirstnameandlastname: 'Por favor, primero ingrese su nombre y apellido.',
    txt_global_std_nicknamenicknamealreadyexists: 'El apodo {apodo} ya existe',
    txt_global_std_2makesureyourcarsbluetoothison: '2. Asegúrese de que el Bluetooth de su automóvil esté activado.',
    txt_global_std_enginemustberunning: 'El motor debe estar funcionando',
    txt_global_std_qualificationfinalstarts30thmarch: 'La final de calificación comienza el 30 de marzo.',
    txt_global_std_shareinvite: 'Compartir invitación',
    txt_global_std_p2pselecteddateandtime: 'Fecha y hora seleccionada',
    txt_global_std_forthisapptoworkproperlyweneedtoaccessyourlocationbluetoothandphysicalfitnesspermissionthelocationserviceisusedinbackgroundforthedrivingfeaturetoanalyzeyourdrivingwithoptimalresultstheappalsodoesthisinthebackgroundwevalueyourprivacyandwillnotsharethisinformationwithathirdpartyentity: 'Para que esta aplicación funcione correctamente, necesitamos acceder a su ubicación, Bluetooth y permiso de condición física. El servicio de ubicación se utiliza en segundo plano para la función de conducción. Para analizar su conducción con resultados óptimos, la aplicación también lo hace en segundo plano. Valoramos su privacidad y no compartiremos esta información con una entidad de terceros.',
    txt_global_std_kintofaq9a: 'Porque la IA necesita conocer el camino para poder calcular la puntuación y ayudarte en caso de emergencia. La aplicación Kinto nunca compartirá la ubicación ni ningún otro dato con un tercero.',
    txt_global_std_qhowdoiconnecttomycarsbluetooth: 'P: ¿Cómo me conecto al Bluetooth de mi coche?',
    txt_global_std_passwordchanged: 'contraseña cambiada',
    txt_global_std_deleteaccount: 'Borrar cuenta',
    txt_global_std_repetitionsnumber: 'Número de repeticiones',
    txt_global_std_collectablebadges: 'Insignias coleccionables',
    txt_global_std_youbooked5timesinonemonthyouhaveearned1500points: 'Has ganado 1.500 puntos por hacer 5 reservas en un mes.',
    txt_global_std_goodthatyouarekeepingyouraccountsecure: 'Es bueno que mantengas tu cuenta segura.',
    txt_global_std_business: 'Negocio',
    txt_global_std_BeforeFirstBill_Hello: 'Hola',
    txt_global_std_drivemorethan1miletogetmoredetailedanalyticsaboutyourtrip: 'Conduzca más de 1 milla para obtener análisis más detallados sobre su viaje.',
    txt_global_std_summaryofanalyse: 'Resumen de análisis',
    txt_global_std_youearnedapercentdiscountbydrivingsafe: 'Obtuviste un {percent}% de descuento por conducir de manera segura',
    txt_global_std_thepiggybank: 'la alcancía',
    txt_global_std_youareamongthebestappnamedrivers: '¡Guau! ¡Estás entre los mejores conductores de {appname}!',
    txt_global_std_step3: 'Paso 3',
    txt_global_std_upcoming: 'próximos',
    txt_global_std_pigsinvited: 'Recomendar amigos',
    txt_global_std_weregladtohaveyouonboardwatchourvideoguideforsomequicktipstogetyouupandrunning: 'Nos complace tenerte a bordo. Mire nuestra guía de video para obtener algunos consejos rápidos que lo ayudarán a ponerse en marcha.',
    txt_global_std_youmayhavetogotoyourbluetoothsettingsforbothyourphoneandcar: 'Es posible que deba ir a la configuración de Bluetooth tanto para su teléfono como para su automóvil.',
    txt_global_std_buy2: 'Comprar',
    txt_global_std_p2pborrowyour: 'toma prestada tu',
    txt_global_std_f_lanzmusic: 'Clásica, Hip Hop y Danza',
    txt_global_std_HSolbergWhyjoin: 'Por primera vez, mis fans y yo podemos competir juntos para ganar un FIA Challenge.',
    txt_global_std_friendsreferral: 'Referencia de amigos',
    txt_global_std_qcanisignupforinsurancetobegintoday: 'P: ¿Puedo inscribirme para que el seguro comience hoy?',
    txt_global_std_MSIG_Onboarding9: '3. ¡Conduce!',
    txt_global_std_gooddrivingtechnique: 'Buena técnica de conducción.',
    txt_global_std_howdoifileaclaim: '¿Cómo presento un reclamo?',
    txt_global_std_SignupFia_Text3: 'FIA SDC es la iniciativa donde las mejores estrellas de las carreras y los pilotos de todo el mundo se encuentran en un desafío épico. Con el uso de una aplicación para teléfonos inteligentes, podrá unirse a un equipo, desafiar a los mejores pilotos del mundo y competir por el título como el piloto más inteligente de la FIA.',
    txt_global_std_yourappnamehastobeatleasttwocharacterslong: 'El nombre de tu aplicación debe tener al menos dos caracteres',
    txt_global_std_appcreator_upsell: 'Creador de aplicaciones: cree su propia aplicación de conducción de marca en minutos.',
    txt_global_std_advancedusersettings: 'Configuración de usuario avanzada',
    txt_global_std_weneedpermissiontoaccessyourbluetoothforthisapptowork: 'Necesitamos permiso para acceder a su Bluetooth para que esta aplicación funcione. Para continuar usando esta aplicación, debe ir a su dispositivo',
    txt_global_std_p2pfaq10q: '¿Qué pasa si mi amigo daña mi auto?',
    txt_global_std_rulesforservice: 'Reglas para el servicio',
    txt_global_std_itemsperpage: 'elementos por página',
    txt_global_std_idling: 'De marcha en vacío',
    txt_global_std_searchtranslation: 'Buscar traducción',
    txt_global_std_fiasmartdrivingchallenge: 'Desafío de conducción inteligente de la FIA',
    txt_global_std_p2phasreceivedlendingrequest: ' ha recibido su solicitud de préstamo.',
    txt_global_std_publishchanges: 'Publicar cambios',
    txt_global_std_endinsurance: 'Fin del seguro',
    txt_global_std_qhowdoiconnecttomycar: 'P: ¿Cómo me conecto a mi coche?',
    txt_global_std_congratulationyoupositiononleaderboardwasincreased: '¡Felicidades! ¡Se incrementó tu posición en la clasificación!',
    txt_global_std_TidemandDescription: 'El primer contacto de Pontus con la competencia fue en crosskarts, donde se convirtió en campeón nórdico y sueco. 2011, el piloto más joven en ganar el Campeonato Sueco de Rally',
    txt_global_std_p2pfaq8a: 'Puede bloquear fácilmente su automóvil en los días que sabe que lo conducirá usted mismo o, por otras razones, no quiere compartir su automóvil.\n' +
      '1.Ir a Inicio\n' +
      '2.Presione el botón Calendario en la parte superior izquierda\n' +
      '3.Luego, elija la fecha y la hora en que le gustaría bloquear su automóvil.',
    txt_global_std_youdrovereallywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: '¡Condujiste muy bien! ¡Tu promedio fue mejor que {score} durante una semana! ¡Has ganado {puntos} puntos!',
    txt_global_std_SulayemMileage: 'Oasis de Liwa – Carretera de Al Ain a través del Desierto del Barrio Vacío, experimentando la espectacular y pacífica belleza del desierto, conducir por esta carretera te hará sentir tranquilo y sereno.',
    txt_global_std_failedtosendtothefollowingusers: 'Se agregó un mensaje y se envió a todos los usuarios excepto a los siguientes:',
    txt_global_std_repeatyourpassword2: 'repita su contraseña',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint3: 'Reforzar los buenos comportamientos a través de la gamificación',
    txt_global_std_pleasereloadortryagainlater: 'Vuelva a cargar o inténtelo de nuevo más tarde.',
    txt_global_std_inviteuser: 'Invitar usuario',
    txt_global_std_nofriendsfound: 'No se encontraron amigos.',
    txt_global_std_co2lockedkey: 'Conduzca de manera ecológica y ahorre más del {co2value}% CO₂ por viaje para ganar esta insignia.',
    txt_global_std_addfriendtolist: 'Agregar amigo a la lista',
    txt_global_std_daysdays: '{días} Días',
    txt_global_std_bestdriverstoday: 'Los mejores conductores de hoy',
    txt_global_std_backtoleaderboard: 'volver a la clasificación',
    txt_global_std_50kmunlockedkey: '¡Condujiste una distancia de 50 km con la aplicación!',
    txt_global_std_warikan_onboarding_title_2: '【Warikan KINTO】Contraseña temporal de la aplicación',
    txt_global_std_allowmotionfitness: 'Permitir movimiento y estado físico',
    txt_global_std_locationselectalways: 'Ubicación - seleccione "siempre"',
    txt_global_std_sms: 'SMS',
    txt_global_std_idonthaveanaccount: 'no tengo cuenta?',
    txt_global_std_allowlocationservices: 'Permitir servicios de ubicación',
    txt_global_std_activatecode: 'codigo de activacion',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint3: 'Incentive a los usuarios utilizando sus propios KPI, incluidas las reducciones de CO2, las reducciones de riesgos, los días sin automóviles y más',
    txt_global_std_TaxInvoice11: 'El pago total de la prima debe realizarse antes del inicio de la póliza en el momento de la documentación.',
    txt_global_std_hereyoucanseehowyouhavedriven: 'Aquí puedes ver cómo has conducido.',
    txt_global_std_appnamewallet: 'Billetera {appname}',
    txt_global_std_canbeearnedwhenyoufocustoimproveinthisspeed: 'se puede ganar cuando te enfocas en mejorar en esta velocidad',
    txt_global_std_wildfire: '¡Incendio forestal!',
    txt_global_std_letsgo: 'Vamos',
    txt_global_std_theresetlinkwillexpirein20minafterthatyoullneedtosubmitanewrequestinordertochangeyourpassword: 'El enlace de restablecimiento caducará en 20 minutos, después de eso, deberá enviar una nueva solicitud para cambiar su contraseña.',
    txt_global_std_warikan_onboarding13: 'Horario de recepción 9:00 a 18:00 Abierto todo el año (excepto las vacaciones de fin de año y año nuevo)\n',
    txt_global_std_challengesomeone: 'Desafíar a alguien',
    txt_global_std_settingssaved: 'Ajustes guardados',
    txt_global_std_p2pviewreceipt: 'Ver recibo',
    txt_global_std_greatstreak: '¡Gran racha!',
    txt_global_std_vouchercodes: 'Códigos de cupón',
    txt_global_std_getstarted2: 'Empezar',
    txt_global_std_p2pdistancetravelled: 'Distancia recorrida',
    txt_global_std_discount: 'Descuento',
    txt_global_std_buymoremilage: 'Comprar más kilometraje',
    txt_global_std_closed: 'Cerrado',
    txt_global_std_addcustombadgeicon: 'Añadir icono de insignia personalizado',
    txt_global_std_feedbackoptions: 'Opciones de comentarios',
    txt_global_std_navigation: 'Navegación',
    txt_global_std_willbeaddedtoyoursubscription: 'se agregará a su suscripción',
    txt_global_std_fortst: 'Continuar',
    txt_global_std_youmustnotuseappwhiledriving: 'No debe utilizar la aplicación mientras conduce.',
    txt_global_std_allowbluetoothtocollectdrivingscore: 'Permita que Bluetooth recopile su puntaje de manejo.',
    txt_global_std_viewpassword: 'Ver contraseña',
    txt_global_std_leaderboard_tab_monthly: 'Mensual',
    txt_global_std_invitationsentto: 'Invitación enviada a',
    txt_global_std_thesebadgesyoucanonlyearnonetime: 'Estas insignias solo las puedes ganar una vez.',
    txt_global_std_communication_upsell: 'Herramienta de comunicación: aumente el compromiso con notificaciones personalizadas.',
    txt_global_std_ifyoucomeacrosswordsandphrasesyoucanteditbyclickinginthevisualeditoryoucansearchandfindtheminthelistbelow: ' * Si encuentra palabras y frases que no puede editar haciendo clic en el editor visual, puede buscarlas y encontrarlas en la lista a continuación.',
    txt_global_std_typehere: 'Escriba aquí',
    txt_global_std_endd: 'Final',
    txt_global_std_accountpreferences: 'Preferencias de la cuenta',
    txt_global_std_rules: 'normas',
    txt_global_std_showonleaderboard: 'Mostrar en la tabla de clasificación',
    txt_global_std_cimage5h: 'TÉCNICA COLINA',
    txt_global_std_MSIG_InsuranceLetter7: 'Tipo de Póliza.',
    txt_global_std_connectingyourgarminwatchiseasyclicktheconnectbuttonbelowtohelpusuncovernewinsightsintocrashriskbyanalyzingbothdrivingandhealthdata: '¡Conectar tu reloj Garmin es fácil! Haga clic en el botón Conectar a continuación para ayudarnos a descubrir nuevos conocimientos sobre el riesgo de accidentes mediante el análisis de datos de conducción y de salud.',
    txt_global_std_showall: 'Mostrar todo',
    txt_global_std_p2pcostsplitisbasedonhoursthecarisused: 'La división de costos se basa en las horas en que se usa el automóvil.',
    txt_global_std_anonymous: 'anónimo',
    txt_global_std_viewloyaltypoints: 'Ver puntos de fidelidad',
    txt_global_std_totaldistance2: 'Distancia total',
    txt_global_std_: '',
    txt_global_std_namecancelledthebooking: '{name} canceló la reserva',
    txt_global_std_cimage40d: 'Al rodar mantienes el avance, además de ahorrar combustible y el auto no se desgasta.',
    txt_global_std_uservsuser: 'Usuario contra usuario',
    txt_global_std_powerhuman: 'Poder Humano',
    txt_global_std_p2pdividedequallyamongfriendsthatusedcar: 'Dividido en partes iguales entre amigos que usaron auto.',
    txt_global_std_achievementcreatedmessage: '¡Se ha creado el logro!',
    txt_global_std_p2pitstimetosplityourcostformonthof: 'Es hora de dividir su costo para el mes de',
    txt_global_std_dontrestrictkey: 'No restrinja la clave',
    txt_global_std_usethistemplate: 'Usa esta plantilla',
    txt_global_std_sendagain: 'Enviar de nuevo',
    txt_global_std_mysubscriptioncost: 'Mi costo de suscripción',
    txt_global_std_kilometers_driven: 'Kilómetros recorridos',
    txt_global_std_typeyourverificationcode: 'Escribe tu código de verificación',
    txt_global_std_howdoesthescoringsystemwork: '¿Cómo funciona el sistema de puntuación?',
    txt_global_std_orrecruitafriend: 'O recluta a un amigo',
    txt_global_std_stop: 'Deténgase',
    txt_global_std_youcanuploadmax10photosofyourcar: 'Puedes subir un máximo de 9 fotos de tu coche.',
    txt_global_std_hereyoufindyourhighestimprovmentpotential: 'Aquí encuentras tu mayor potencial de mejora',
    txt_global_std_easywaytogetinvolved: 'Manera fácil de involucrarse',
    txt_global_std_OSolbergFacts: 'Hijo de Peter Solberg. Conduce el coche de su padre para el equipo nórdico.',
    txt_global_std_usersuccessfullyadded: 'Usuario añadido con éxito',
    txt_global_std_avgrpm: 'Promedio RPM',
    txt_global_std_youearnedapercentagephdiscount: 'Obtuviste un {percentage}% de descuento',
    txt_global_CoreTemplate_insuranceoverview: 'Resumen de seguros',
    txt_global_std_addvehicleidentification: 'Agregar identificación del vehículo',
    txt_global_std_includedkm: 'kilómetros incluidos',
    txt_global_std_password: 'Clave',
    txt_global_std_apikey: 'Clave API',
    txt_global_std_points2: 'Puntos',
    txt_global_std_saveinsurancekey: 'Guardar clave de seguro',
    txt_global_std_namekeytitle: 'Título, mostrado en la aplicación',
    txt_global_std_cannotfindanybluetoothdevice: 'No se puede encontrar ningún dispositivo Bluetooth.',
    txt_global_std_p2pborrowacartwo: 'pedir prestado un coche',
    txt_global_std_p2ppushbodynewexpensetoapprove: '{name} agregó gastos para el mes de {monthname}.',
    txt_global_std_userspecific: 'Usuario específico',
    txt_global_std_deletedtranslationfromreview: 'traducción eliminada de la revisión',
    txt_global_std_MSIG_InsuranceLetter31: '(Aseguradoras Homologadas)',
    txt_global_std_bysigningupyouaccept: 'Al registrarte aceptas',
    txt_global_all_monday: 'Lunes',
    txt_global_std_worldclass: 'Clase mundial',
    txt_global_std_typeofchallenge: 'tipo de reto',
    txt_global_std_problempersistcontactsupport: 'Si el problema persiste, póngase en contacto con el soporte.',
    txt_global_std_kintoremainingpointstonextlevel: '{puntos} puntos restantes para {nivel}',
    txt_global_std_do: 'Hacer',
    txt_global_std_feb: 'Feb',
    txt_global_std_apirestrictions: 'Restricciones de la API',
    txt_global_std_standard: 'Estándar',
    txt_global_std_MSIG_InsuranceLetter34: 'Registro de GST Nº M2-0001676-9',
    txt_global_std_driver: 'Conductor',
    txt_global_std_loyalty_upsell_pitch: 'Personalice rápidamente su propio programa de fidelización de marca para;',
    txt_global_std_warikan_onboardingmail_temppwd: 'Contraseña temporal:',
    txt_global_std_SignupFia_Text11: 'para más información.',
    txt_global_std_savesettings: 'Guardar ajustes',
    txt_global_std_compareactivities: 'Comparar actividades',
    txt_global_std_errorfetchingtrends: 'Error al obtener tendencias',
    txt_global_std_p2pfaq2q: '¿Cómo puedo editar la fecha y la hora de una reserva?',
    txt_global_std_1000pointaverageachievementunlocked: '¡Condujiste muy bien! ¡Tu {scoretype} promedio fue mejor que {score} durante una semana! ¡Has ganado 1000 puntos!',
    txt_global_std_emailregisteredintheapp: 'Correo electrónico registrado en la aplicación',
    txt_global_std_p2ppushbodyaddadmin: '{nombre} ha agregado derechos de administrador para {vehicle}.',
    txt_global_std_keepingyourmindandeyesonwhatshappeningaroundyouisvitalforyoursafetyandthesafetyofothersphoneactivityandotherdistractionsaffectyourfocusandincreasecrashrisk: 'Mantener la mente y los ojos en lo que sucede a su alrededor es vital para su seguridad y la seguridad de los demás. La actividad del teléfono y otras distracciones afectan su concentración y aumentan el riesgo de accidentes.',
    txt_global_std_fuelprice: 'precio del combustible',
    txt_global_std_recoveryhelp: 'Ayuda de recuperación',
    txt_global_std_areyousureyouwanttoterminateInsurance: '¿Está seguro de que desea cancelar su seguro?',
    txt_global_std_carrorewards: 'Recompensas de carro',
    txt_global_std_recruitedfriends: 'amigos reclutados',
    txt_global_std_changesinreview: 'Cambios en revisión',
    txt_global_std_youhaveinvitedamountfriends: 'Has invitado a {amount} amigos',
    txt_global_std_searchforgroups: 'Buscar grupos',
    txt_global_std_sidekickusertermsandconditions: 'términos y condiciones del usuario',
    txt_global_std_iacceptthe: 'acepto el',
    txt_global_std_clientlibraries: 'Bibliotecas cliente',
    txt_global_std_kintofaqlink2: 'Explore el manual de la aplicación (japonés)',
    txt_global_std_apirestrictionsspecifytheenabledapisthatthiskeycancall: 'Las restricciones de API especifican las API habilitadas a las que esta clave puede llamar',
    txt_global_std_nextstep2: 'Próximo paso',
    txt_global_std_MSIG_InsuranceLetter16: 'Valor de mercado en el momento de la pérdida',
    txt_global_std_signinwithappname: 'Iniciar sesión con {appName}',
    txt_global_std_uniquebadges: 'Insignias únicas',
    txt_global_std_hellothereletsgetstarted: '¡Hola! Empecemos.',
    txt_global_std_loyaltydistribution: 'Distribución de lealtad',
    txt_global_std_sendalinktoyourfriendsothattheycandownloadtheapp: 'Envía un enlace a tu amigo para que pueda descargar la aplicación.',
    txt_global_std_p2pemailsupport: '・Para soporte técnico de aplicaciones de lunes a viernes de 09:00 a 18:00',
    txt_global_std_offerendsin: 'La oferta termina en',
    txt_global_std_invited2: 'Invitado',
    txt_global_std_viewoffer: 'Ver oferta',
    txt_global_std_loadingdata: 'Cargando datos…',
    txt_global_std_saveandexit: 'Guardar y Salir',
    txt_global_std_SulayemBesttip: 'Piense primero en la seguridad, sea siempre consciente de su entorno y mantenga su velocidad dentro de los límites.',
    txt_global_std_p2pborrow: 'Tomar prestado',
    txt_global_std_pleaseverifyifyourmileageiscorrectbeforesavingit: 'Verifique si su millaje es correcto antes de guardarlo',
    txt_global_std_clickthelinkbelowtoconfirmyouremailandfinishcreatingyourenerfyglobalaccount: 'Haga clic en el enlace a continuación para confirmar su correo electrónico y terminar de crear su cuenta Enerfy Global.',
    txt_global_std_p2pwantstolendyou: ' quiere prestarte',
    txt_global_std_choosehowmanydrivesyouwanttochallengeyourfriendon: 'Elige en cuántas unidades quieres desafiar a tu amigo.',
    txt_global_std_callsosphonenumber: 'Llame a SOS, número de teléfono:',
    txt_global_std_regnumber: 'registro número',
    txt_global_std_p2pfaq5a: 'Presione la imagen y luego haga zoom con los dedos.',
    txt_global_std_yourannualmilagewasunabletoreadfromtheserverpleasetryagainlater: 'Su kilometraje anual no pudo leerse del servidor, inténtelo de nuevo más tarde.',
    txt_global_std_contactforactivation: 'Contacto para activación',
    txt_global_std_p2pconfirmwith: 'Confirmar con',
    txt_global_std_lastlogfilesent: 'Último archivo de registro enviado',
    txt_global_std_removeparticipant: 'Quitar participante',
    txt_global_std_fiatodayrule2020: 'Debe conducir al menos 1 km para obtener una puntuación en la tabla de clasificación.',
    txt_global_std_v2ilevel: 'Nivel',
    txt_global_std_lostconnectionbig: 'el viaje terminará',
    txt_global_std_p2pfaq22a: '1. Seleccione "Warikan" en "Menú".\n' +
      '2. Seleccione "Costos adicionales".\n' +
      '3. Seleccione Agregar costo.\n' +
      '4. Selecciona el auto, selecciona el tipo de gasto, ingresa la tarifa, sube la imagen del recibo y selecciona guardar.\n' +
      '5. Los costos adicionales se reflejarán en la página "Costos adicionales".',
    txt_global_std_accidentdetails: 'Detalles del accidente',
    txt_global_std_rwmregister: 'Registro',
    txt_global_std_insuranceandpayments: 'Seguros y pagos',
    txt_global_std_notjoinable: 'no unible',
    txt_global_std_yourinsuranceperiodisover: 'Su período de seguro ha terminado:',
    txt_global_std_join: 'Unirse',
    txt_global_std_chooseallowalways: 'Elija Permitir siempre',
    txt_global_std_refer10friendstounlockthisachievementandearn300pointstoyour: 'Recomiende a 10 amigos para desbloquear este logro y gane 300 puntos para su',
    txt_global_std_areyousurethatyouwanttoremovethisdrivenotreversable: '¿Está seguro de que desea eliminar este viaje? Esta acción no es reversible.',
    txt_global_std_searchbynameoremailaddress: 'Buscar por nombre o dirección de correo electrónico',
    txt_global_std_challengearandomdriver: 'Desafía a un conductor aleatorio',
    txt_global_std_howdidyoufeel: '¿Cómo te sentiste?',
    txt_global_std_theanalysistookalongertime: 'Este análisis tomó más tiempo de lo esperado, pero no se preocupe, le enviaremos una notificación automática cuando haya terminado.',
    txt_global_std_p2psafedriverbonusincluded: 'Bono de conductor seguro incluido',
    txt_global_std_assigntogroups: 'Asignar a grupos',
    txt_global_std_kilometerpremiumtohigh: 'Prima kilométrica a alta',
    txt_global_std_groupmanagement: 'Manejo de grupo',
    txt_global_std_website: 'Sitio web',
    txt_global_std_speedvariation: 'Variación de velocidad',
    txt_global_std_profilesettings: 'Configuración de perfil',
    txt_global_std_yourlocation1: 'Tu ubicación',
    txt_global_std_connecttoyourcarbeforelendingittoafriend: 'Conéctese a su automóvil antes de prestárselo a un amigo',
    txt_global_std_areyousureyouwanttounpublishyourtranslationsifyouunpublishtranslationstheappwillnotshowthislanguageanymore: ' ¿Seguro que quieres anular la publicación de tus traducciones? Si cancela la publicación de las traducciones, la aplicación ya no mostrará este idioma.',
    txt_global_std_adgangtilbluetooth: 'Permitir Bluetooth',
    txt_global_std_somethingsmissing: 'Algo falta, algo está faltando...',
    txt_global_std_teamscore: 'PUNTUACIÓN DEL EQUIPO',
    txt_global_std_ConfirmFia_Text3: 'Fecha',
    txt_global_std_p2pcostsplitisbasedonthepercentageofuse: 'La división de costos se basa en el porcentaje de uso.',
    txt_global_std_p2preceiptsummary: 'Resumen de recibo',
    txt_global_std_25kmlockedkey: 'Conduce una distancia de 25 km para ganar esta insignia.',
    txt_global_std_PolicySchedule22: 'Protector de ENT (si lo hay)',
    txt_global_std_403forbidden: '403 - Prohibido',
    txt_global_std_worldchallenge: 'Desafío mundial',
    txt_global_std_yourinsuranceisnowactivated: 'Tu seguro ya está activado',
    txt_global_std_p2pfaq12q: '¿Puedo eliminar a un amigo?',
    txt_global_std_reset: 'Reiniciar',
    txt_global_std_remove: 'Remover',
    txt_global_std_deleteuseraccount: 'Eliminar cuenta de usuario',
    txt_global_std_addaddress: 'Añadir dirección',
    txt_global_std_recurring: 'Periódico',
    txt_global_std_sendupdate: 'Enviar actualización',
    txt_global_std_novalidapikeyprovided: 'No se ha proporcionado ninguna clave de API válida.',
    txt_global_std_bestdriversintotal: 'Mejores pilotos en total',
    txt_global_std_secondss: 's',
    txt_global_std_selecttheregnumberofyourcar: 'Seleccione el número de registro de su automóvil',
    txt_global_std_NewPW_Text1: 'Ha solicitado una nueva contraseña para su cuenta de {appName}.',
    txt_global_std_thislinkexpiresinxtimeandcanonlybusedonce: 'Este enlace caduca en 6 minutos y solo se puede usar una vez.',
    txt_global_std_sosinternationalaroundtheclock: 'SOS internacional, las 24 horas',
    txt_global_std_drivesafeandecofriendly: 'Conduce seguro y respetuoso con el medio ambiente',
    txt_global_std_carstatsthisday: 'Estadísticas de autos del último día',
    txt_global_std_youhaventpairedwithacaryet: 'Todavía no te has emparejado con un coche.',
    txt_global_std_p2powneruserguide: 'Guía del usuario del propietario',
    txt_global_std_p2ptodo: 'Que hacer',
    txt_global_std_amount_default: 'Monto',
    txt_global_std_thisisatestblabla: 'Esta es una prueba blabla',
    txt_global_std_challengeranking: 'Clasificación de tu desafío',
    txt_global_std_insuranceoverview2: 'Descripción general del seguro',
    txt_global_std_resetpassword: 'Restablecer la contraseña',
    txt_global_std_achievementtool_upsell: 'Herramienta de logros: facilita la formación de un hábito de conducción inteligente.',
    txt_global_std_verybad: 'Muy mal',
    txt_global_std_thebetteryoudrivethemoreyousave: 'Cuanto mejor conduzcas, más ahorrarás.',
    txt_global_std_whymustiallowalwaysallowlocationsservicesanswer: 'Porque la IA necesita conocer el camino para poder calcular la puntuación y ayudarte en caso de emergencia.',
    txt_global_std_members2: 'miembros',
    txt_global_std_getstartedtips: 'Consejos para empezar',
    txt_global_std_ifyourfriendregisterswiththereferralcodebothyouandyourfriendwillreceivenok250inthepiggybankthisrequiresthatyouarebothcustomersoftrygforatleastonemonthafterthestartdateoftheinsurance: 'Si su amigo se registra con el código de referencia, tanto usted como su amigo recibirán NOK 250 en la alcancía (esto requiere que ambos sean clientes de Tryg durante al menos un mes después de la fecha de inicio del seguro).',
    txt_global_std_mytrips: 'Mis viajes',
    txt_global_std_tripsapi: 'API de viajes',
    txt_global_std_youwillbebilledatpremiumlevel10: 'Se le facturará al nivel de prima 10, consulte la carta de su seguro para obtener detalles sobre el precio.',
    txt_global_std_p2pwantstolendborrow: 'quiere {prestar prestado}',
    txt_global_std_kintofaq9q: '¿Por qué debo permitir los servicios de ubicaciones "permitir siempre"?',
    txt_global_std_doyouwanttodeletetheapikey: '¿Quieres eliminar la clave API?',
    txt_global_std_yourealmostthere: '¡Ya casi estás ahí!',
    txt_global_std_trygdiscountpopuptext: 'Aquí puedes ver cuánto te ahorrarías si tuvieras un seguro de auto Sidekick. Los ahorros potenciales se calculan según su precio anual, millaje anual y modelo de automóvil. Si no ha introducido esta información, los ahorros se calculan sobre la base de un precio anual de 5000 NOK y un kilometraje anual de 20 000 km.',
    txt_global_std_thesebadgesyoucanearnseveraltimes: 'Estas insignias se pueden ganar varias veces.',
    txt_global_std_doyouwanttodriveforteamnamesteam: '¿Quieres conducir para el equipo de {teamName}?',
    txt_global_std_p2ptypeamessagedot: 'Escribe un mensaje...',
    txt_global_std_WelcomeLetter_Text6: 'CEO',
    txt_global_std_welcometoyournewaccountwehavesentyouanemailtoverifyyournewaccountremembertocheckyourspamfolderdidntgetanemail: '¡Bienvenido a tu nueva cuenta! Le hemos enviado un correo electrónico para verificar su nueva cuenta. Recuerda revisar tu carpeta de spam. ¿No recibiste un correo electrónico?',
    txt_global_std_stress: 'Estrés',
    txt_global_std_weareunabletorecordyourtrip: '¡No podemos registrar su viaje!',
    txt_global_std_validbetween: 'Válido entre',
    txt_global_std_city: 'Ciudad',
    txt_global_std_daydreamingisacommontypeofdriverdistractionyoumaythinkitsharmlessbutanythingthattakesyourmindoffthedrivingtaskincreasescrashriskchangingyourgazefrequentlycanhelpyoumaintainfocusontheenvironmentaroundyounew: 'Soñar despierto es un tipo común de distracción del conductor. Puede pensar que es inofensivo, pero cualquier cosa que distraiga su mente de la tarea de conducir aumenta el riesgo de choque. Cambiar tu mirada con frecuencia puede ayudarte a mantener el enfoque en el entorno que te rodea.',
    txt_global_std_whatdoyouwanttodo: '¿Qué es lo que quieres hacer?',
    txt_global_std_p2pwhenitstimetodrive: 'Cuando es hora de conducir',
    txt_global_std_yourdrivingstatsforthistripcomparedtotheaveragedriver: 'Sus estadísticas de manejo para este viaje en comparación con el conductor promedio.',
    txt_global_std_myrewards: 'Mi recompensa',
    txt_global_std_whatisanodometer: '¿Qué es un odómetro?',
    txt_global_std_Fia_Receipt_Text15: 'Su código de activación:',
    txt_global_std_locationservice: 'Servicio de localización',
    txt_global_std_resetpasswordforname: '¿Restablecer la contraseña de {nombre}?',
    txt_global_std_sdcrapply2019price1: 'Cheques de gasolina de 1000 kr',
    txt_global_std_driversnow: 'conductores ahora',
    txt_global_std_pointsheader: 'Puntos',
    txt_global_std_p2pyouhavexfriendsintheapp: 'Tienes {número} amigos en la aplicación',
    txt_global_std_step4: 'Paso 4',
    txt_global_std_warikan_onboarding4: 'Instrucciones de uso:',
    txt_global_std_p2plogintomykintoaccount: 'Iniciar sesión en mi cuenta de Kinto',
    txt_global_std_nobluetoothbrnoworries: '¿Sin Bluetooth? <br/>¡No te preocupes!',
    txt_global_std_insuranceactivationkeydoesnotexist: 'La clave de activación del seguro no existe',
    txt_global_std_wearebuildingyourdemoapp: 'Estamos construyendo su aplicación de demostración',
    txt_global_std_reportuser: 'Reportar usuario',
    txt_global_std_lendings: 'préstamos',
    txt_global_std_entervalidphone: 'Ingrese el número de teléfono con al menos 6 dígitos',
    txt_global_std_idriveforaridesharingcompanywillyouinsuremyvehicle: 'Conduzco para una empresa de viajes compartidos, ¿asegurarán mi vehículo?',
    txt_global_std_youpurchasedcdw5timestoyourbookingyouhaveearned100points: 'Obtuvo 100 puntos por hacer 5 reservas y se inscribió en CDW. Este logro se puede obtener más de una vez.',
    txt_global_std_WelcomeLetter_Hello: 'Hola',
    txt_global_std_enteroptionalactivationcode: 'Introduzca el código de activación opcional',
    txt_global_std_yourapikey: 'Tu clave API',
    txt_global_std_settingshasbeensaved: 'La configuración se ha guardado',
    txt_global_std_loginwithoutaccount: 'Iniciar sesión sin cuenta',
    txt_global_std_participantsfor: 'Participantes para',
    txt_global_std_yourfinalscorewillbecalculatednexttime: 'Tu puntuación final se calculará la próxima vez que te conectes',
    txt_global_std_whenyouhavecompletedyourfirstdriveyouwillseeithere: 'Cuando haya completado su primer viaje, lo verá aquí.',
    txt_global_std_turnonyourphonesbluetooth: 'Encienda el Bluetooth de su teléfono.',
    txt_global_std_removeuser: 'Eliminar usuario',
    txt_global_std_youneedtoactivateprivacysettings: 'Necesitas activar la configuración de privacidad',
    txt_global_std_covercubecollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: 'Covercube recopila datos de ubicación para habilitar la puntuación de conducción incluso cuando la aplicación está cerrada o no está en uso.',
    txt_global_std_MikkelsenChamp: 'Campeón IRC 2011 y 2012. 3er lugar en el Campeonato Mundial de Rally (2014, 2015 y 2016). 3 victorias en el Campeonato Mundial de Rally. Fue parte de la academia de excelencia para jóvenes pilotos de la FIA\n',
    txt_global_std_drivewascancelledbeforeitwasvalidandwillbediscarded: 'La unidad se canceló antes de que fuera válida y se descartará',
    txt_global_std_rwmconnecttocar: 'Conectar al coche',
    txt_global_std_insnumber: 'Numero de seguro',
    txt_global_std_p2ptotalexpenses: 'Gastos totales',
    txt_global_std_theusermustacceptaninvitationtothegroupfirst: 'El usuario debe aceptar una invitación al grupo primero.',
    txt_global_std_p2pfaq24a: 'Hay cuatro métodos, y puede elegir libremente.\n' +
      '(Costo total: cuota mensual de KINTO más costo adicional por cada miembro)\n' +
      '\n' +
      '1. "Proporción de uso" Calcule la proporción entre el tiempo de uso de cada miembro y el tiempo total mensual (ejemplo de julio: 24 horas x 31 días = 744 horas) y multiplíquelo por el costo total.\n' +
      '2. "Tiempo de uso" Divida el costo total por el tiempo de uso total para calcular el precio unitario del tiempo de uso. Multiplica cada tiempo de uso.\n' +
      '3. "División equitativa" El costo total se divide equitativamente por el número de personas en el grupo.\n' +
      '4. "Millas" Calcula el precio unitario de las millas dividiendo el costo total por las millas totales del mes. Multiplica tu kilometraje.',
    txt_global_std_active: 'Activo',
    txt_global_std_cimage43h: 'EVITE EL INICIO Y LA PARADA INMEDIATA EN LAS COLAS',
    txt_global_std_policynumberwassaved: 'Se guardó el número de póliza',
    txt_global_std_p2pminutes: 'minutos',
    txt_global_std_createddate: 'Fecha de creación',
    txt_global_std_activecompetitions: 'Competiciones activas',
    txt_global_std_p2pfaq17q: '¿Cómo agrego amigos?',
    txt_global_std_chooseoneoption: 'Elige una opción',
    txt_global_std_howdoiconnecttomycarsbluetooth: '¿Cómo me conecto al Bluetooth de mi coche?',
    txt_global_std_viewandredeem: 'Ver y canjear',
    txt_global_std_insureddistance_used_90__2: 'Snart tomt! Öka årlig körsträcka här.',
    txt_global_std_insuranceterms2: 'términos del seguro',
    txt_global_std_translationtool_upsell_pitch_bulletpoint3: 'Posiciona tus productos frente a un público más amplio',
    txt_global_std_ChicheritMusic: 'Próximamente, en breve, pronto...',
    txt_global_std_whenyouarereadytodrive: 'Cuando esté listo para conducir',
    txt_global_std_keepingyourmindandeyesontheroadandtrafficaroundyouisvitalforyoursafetyandthesafetyofotherslookingwellaheadandscanningtheenvironmentwillhelpyoutoanticipatewhatotherroadusersmightdonew: 'Mantener la mente y los ojos en la carretera y el tráfico que lo rodea es vital para su seguridad y la seguridad de los demás. Mirar bien hacia adelante y escanear el entorno te ayudará a anticipar lo que podrían hacer otros usuarios de la carretera.',
    txt_global_std_couldhavesaved: 'Podría haber salvado',
    txt_global_std_level123: 'Nivel',
    txt_global_std_theapikey: 'La clave API',
    txt_global_std_yougetthiswhenyouhaveachieved5tripswithascorebetterthan3point3: 'Lo obtienes cuando has conseguido 5 viajes con una puntuación superior a 3,3',
    txt_global_std_andspaces: 'y espacios.',
    txt_global_std_pressconfirmtoadjustyourmilagebonusplan: 'Presione "confirmar" para ajustar su plan de bonificación de millas.',
    txt_global_std_OSolbergMileage: 'Próximamente, en breve, pronto...',
    txt_global_std_settled: 'Establecido',
    txt_global_std_group: 'Grupo',
    txt_global_std_avgspeed7d: 'Velocidad media 7D',
    txt_global_EnerfyLeaderboardTemplate_currleaguetext: 'estas en la liga',
    txt_global_std_anotherverificationemailhasbeensentpleasecheckyourinbox: 'Se ha enviado otro correo electrónico de verificación, compruebe su bandeja de entrada',
    txt_global_std_PolicySchedule4: 'No política',
    txt_global_std_keepingyourmindandeyesontheroadandtrafficaroundyouisvitalforyoursafetyandthesafetyofotherslookingwellaheadandscanningtheenvironmentwillhelpyoutoanticipatewhatotherroadusersmightdo: 'Mantener la mente y los ojos en la carretera y el tráfico que lo rodea es vital para su seguridad y la seguridad de los demás. Mirar bien hacia adelante y escanear el entorno te ayudará a anticipar lo que podrían hacer otros usuarios de la carretera.',
    txt_global_std_clickhere2: 'haga clic aquí',
    txt_global_std_feedbackinterval: 'Intervalo de retroalimentación',
    txt_global_std_MSIG_InsuranceLetter44: 'SE APLICARÁ UNA FRANQUICIA ESCALONADA DE HASTA $2,000.00 EN CASO DE ACCIDENTE SUJETO A LA PUNTUACIÓN PROMEDIO',
    txt_global_std_p2pthetemporarypasswordisonlyvalidfor72hourspleaserequestanewpassword: 'La contraseña temporal es sólo\n' +
      'válido por 72 horas. Solicite una nueva contraseña.',
    txt_global_std_joiningheatspleasewait: 'Uniéndose a las eliminatorias, por favor espere...',
    txt_global_std_yourmessagewillbesentat: 'Su mensaje será enviado a:',
    txt_global_std_oncepairedyoullautomaticallymovetothenextstep: 'Una vez emparejado, pasará automáticamente al siguiente paso.',
    txt_global_std_youdroveextremelywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: '¡Condujiste muy bien! ¡Tu {scoretype} promedio fue mejor que {score} durante una semana! ¡Has ganado {puntos} puntos!',
    txt_global_std_pioneeronepageuserguide: 'Guía del usuario',
    txt_global_std_checkifyouareconnectedcorrectobdreader: 'Compruebe si está conectado al lector OBD correcto.',
    txt_global_std_joinaheat: 'Únete a un calor',
    txt_global_std_selectnewmileageselector: 'Seleccionar nuevo kilometraje',
    txt_global_std_setupcompanychallenge: 'Configurar el espacio de trabajo de la empresa',
    txt_global_std_p2pclose: 'Cerca',
    txt_global_std_locked: '(Bloqueado)',
    txt_global_std_getyourdrivingscore: '¡Consigue tu puntuación de conducción!',
    txt_global_std_competeasindividualsandinteams: 'Compite individualmente y en equipos.',
    txt_global_std_AbdullaDescription: 'Adel ganó el Campeonato Mundial FIA CCR T2 con Hakam Rabie como copiloto después de una protesta que condujo a una apelación ante el Tribunal de la FIA. En su primera temporada completa de competición a campo traviesa, Adel Hussein obtuvo victorias en Portugal, Abu Dhabi y España durante una exitosa campaña en el campeonato todoterreno más duro del mundo.',
    txt_global_std_p2pcontactsupport: 'Soporte de contacto',
    txt_global_std_eventcreator: 'Creador de eventos',
    txt_global_std_leaderboards: 'Tablas de clasificación',
    txt_global_std_consentInfo: 'Sí = Únase a nosotros desde el principio, únase a nuestra comunidad y forme parte de Tryg Drive. Una vez que haya dado su consentimiento, puede participar en nuestros concursos y obtener acceso completo a las funciones de la aplicación.\n' +
      'Tryg puede ponerse en contacto conmigo por correo electrónico, en la aplicación Tryg Drive y por SMS para recibir noticias y ofertas especiales de Tryg A/S sobre seguros, productos de seguros que no sean de vida, concursos y otros servicios de Trygs. Siempre puede solicitar consultas de marketing de forma fácil y gratuita.\n' +
      'No = No ha activado su "consentimiento" en la configuración. Cuando el "consentimiento" no está habilitado, no podremos compartir todos los emocionantes concursos y consejos proporcionados en la aplicación Tryg Drive de manera continua.',
    txt_global_std_howmanycarswereinvolved: '¿Cuántos autos estuvieron involucrados?',
    txt_global_std_communicationtool: 'Herramienta de comunicación',
    txt_global_std_nothingtoshowhere: 'Nada que mostrar aquí',
    txt_global_std_MSIG_InsuranceLetter5: 'Ley de transporte por carretera (enmienda) de 2019 (Malasia)',
    txt_global_std_kintorefer50friendsunlocked: '¡Gracias! Has referido a 50 usuarios de Kinto. Se han enviado ¥ 5000 a su billetera Kinto.',
    txt_global_std_whenusingthiscodeorlinkuserwillbeonboardedtoyourenerfyglobalteam: 'Al usar este código o enlace, el usuario se incorporará a su equipo de Enerfy Global.',
    txt_global_std_avgtrips7d: 'Viajes promedio 7D',
    txt_global_std_whereismycar: '¿Donde está mi coche?',
    txt_global_std_donthaveanaccount: '¿No tienes una cuenta?',
    txt_global_std_invitecolleaguesorevenfriendsandfamilytoyourchallengetomakeyourcompanychallengemoreinteresting: 'Invite e inspire a todos los conductores de su red a participar y obtenga nuevos datos sobre su rendimiento ESG.',
    txt_global_std_customfilters: 'FILTROS PERSONALIZADOS',
    txt_global_std_tipcity3DESC: 'El conductor del automóvil azul naturalmente reduce la velocidad y conduce a través del cruce sin problemas.\n' +
      'El conductor del coche rojo acelera y frena a fondo antes del cruce.',
    txt_global_std_pleasewaitwhileweauthenticateyouremail: 'Espere mientras autenticamos su correo electrónico',
    txt_global_std_doyouwanttojoinname: '¿Quieres unirte a {nombre}?',
    txt_global_std_whendrivingonmotorwaystoomanydriverspumptheacceleratorpedalupanddowninaneverendingpattern100kmhto110kmhthen110kmhto100kmhandsoonthistypeofdrivingusesunnecessaryfuelkeepingasafesuitablefollowingdistancecanhelpavoidthetemptationtovaryspeedunnecessarilynew: 'Al conducir por autopistas, demasiados conductores pisan el acelerador hacia arriba y hacia abajo en un patrón interminable: ¡de 100 km/h a 110 km/h, luego de 110 km/h a 100 km/h y así sucesivamente! Este tipo de conducción utiliza combustible innecesario. Mantener una distancia de seguimiento adecuada y segura puede ayudar a evitar la tentación de variar la velocidad innecesariamente.',
    txt_global_std_reportaccident: 'Reportar accidente',
    txt_global_std_closingthispopupyouconfirmyouhaveredeemedyourpoints: 'Al cerrar esta ventana emergente, confirma que ha canjeado sus puntos.',
    txt_global_std_aryoudrivinganelectricorobd: '¿?',
    txt_global_std_previewheader: 'Avance',
    txt_global_std_activationletter_text15sidekick: 'Con la aplicación Sidekick, puede obtener más información sobre su propio estilo de conducción y cómo puede convertirse en un conductor más seguro. ¡Además, puede ahorrar dinero en seguros al obtener una buena calificación de manejo!',
    txt_global_std_opened: 'Abrió',
    txt_global_std_subheats: 'subcalentamientos',
    txt_global_std_thesevehiclesarealreadyactivated: 'Estos vehículos ya están activados:',
    txt_global_std_avgmaxspeed7d: 'Velocidad máxima media 7D',
    txt_global_std_teamco2: 'Ahorro de CO2 del equipo',
    txt_global_std_skillcomparison: 'Comparación de habilidades',
    txt_global_std_allowlocationaccess: 'Permitir acceso a la ubicación',
    txt_global_std_officialtimingpartner: 'SOCIO OFICIAL DE CRONOMETRAJE',
    txt_global_std_createnew: 'Crear nuevo',
    txt_global_std_saved: 'Salvado',
    txt_global_std_doitlater: 'lo haré después',
    txt_global_std_yourinsurance: 'tu seguro',
    txt_global_std_yougotthecodefrommail: 'Recibiste el código por correo cuando firmaste la incursión.',
    txt_global_std_gallonus: 'Galón EE. UU.',
    txt_global_std_vehicle2: 'Vehículo',
    txt_global_std_pleaseenteryouremailaddress: 'Por favor, introduzca su dirección de correo electrónico.',
    txt_global_std_SignupFia_Text2: 'Estás en la lista para recibir noticias y actualizaciones de primera mano sobre el Desafío de conducción inteligente de la FIA, el primer desafío que premia las habilidades inteligentes al volante.',
    txt_global_std_webclaims: 'Reclamaciones web',
    txt_global_std_xxperofourcustomers: 'El {perc} % de nuestros clientes usa el sistema automático debido a su facilidad de uso.',
    txt_global_std_groups: 'Grupos',
    txt_global_std_hereisapreviewofhowyourappwilllookifyouarehappywiththeresultpleasecontinuetopublishyourdemoapp: 'Aquí hay una vista previa de cómo se verá su aplicación. Si está satisfecho con el resultado, continúe publicando su aplicación de demostración.',
    txt_global_std_amounttopay: 'Monto a pagar',
    txt_global_std_cimage37d: 'Investiga la distancia de rodadura a diferentes velocidades.',
    txt_global_std_forthebestexperience: 'Para la mejor experiencia',
    txt_global_std_BeforeFirstBill_Text1: 'Próximamente sacaremos dinero de tu tarjeta para el seguro de tu coche. Hemos recopilado las preguntas más comunes que tienen nuestros clientes al recibir su primera factura.',
    txt_global_std_clickontheareaswhereyourvehiclehasbeendamages: 'Haga clic en las áreas donde su vehículo ha sido dañado',
    txt_global_std_riskindex_upsell_pitch_bulletpoint2: 'El impacto de los cambios políticos, sanitarios y económicos en la conducción del riesgo',
    txt_global_std_bodyrepair1panel: 'Reparación de carrocería 1 panel',
    txt_global_std_tripdata: 'Datos del viaje',
    txt_global_std_premiumwithzipdrivingriskindex: 'Índice de riesgo Premium con Zip-Driving',
    txt_global_std_3selectyourcarinthelistbelow: '3. Seleccione su automóvil en la lista a continuación.',
    txt_global_std_aresetlinkhasbeensenttouser: 'Se ha enviado un enlace de reinicio a',
    txt_global_std_idrivetomuch2: 'conduzco mucho',
    txt_global_std_doyouwanttochallengename: '¿Quieres desafiar a {name}?',
    txt_global_std_p2pbookingsummary: 'Resumen de la reserva',
    txt_global_std_weekw: 'w',
    txt_global_std_ifyouhaveaninsuranceyoucanfindyourpolicynumberinyourinsurancedocuments: 'Si tiene un seguro, puede encontrar su número de póliza en los documentos de su seguro.\n' +
      '¿No lo tienes a mano ahora mismo? ¡Sin preocupaciones! Puedes agregarlo más tarde en la configuración.',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedpointspoints: '¡Condujiste muy bien! ¡Tu {scoretype} promedio fue mejor que {score} durante una semana! ¡Has ganado {puntos} puntos!',
    txt_global_std_clickdownbelowtoseehowitwent: 'Haz clic abajo para ver cómo te fue',
    txt_global_std_set_up_automatic_start_stop: 'Configuración: inicio y parada automáticos',
    txt_global_std_youhavebeeninvitedtojoinagroup: 'Has sido invitado a unirte a un grupo.',
    txt_global_std_HusseinAbdullaMileage: 'Nada específico',
    txt_global_std_speedcostanalysis: 'ANÁLISIS DE COSTE DE VELOCIDAD',
    txt_global_std_co2reduced: 'CO₂ reducido',
    txt_global_std_cimage39h: 'DESCARGA GAS ANTES - ATRÉVETE A ROLLAR MÁS',
    txt_global_std_youdroveextremelywellyouraveragewasbetterthanscoreforaweekyouhaveearnedpointspoints: '¡Condujiste muy bien! ¡Tu promedio fue mejor que {score} durante una semana! ¡Has ganado {puntos} puntos!',
    txt_global_std_p2pstart: 'Comienzo:',
    txt_global_std_searchforusers: 'Buscar usuarios',
    txt_global_std_distancedriven: 'Distancia recorrida',
    txt_global_std_drivertrainingrecommended: 'Se recomienda formación de conductores',
    txt_global_std_iunderstandthatthiswillcompletewipeallofmyaccount: 'Entiendo que esto borrará completamente todos los datos de mi cuenta y registros de manejo.',
    txt_global_std_ownerdidnotgetthatnotification: 'El propietario no recibió esa notificación.',
    txt_global_std_p2pwaitingtoconfirmthespecifiedprice: 'A la espera de confirmar el precio especificado.',
    txt_global_std_cannotbeempty: 'No puede estar vacío',
    txt_global_std_acceptconnectioninapp: 'Aceptar conexión en la aplicación',
    txt_global_std_thisuserwillbeaddedtoyourplanatanadditionalcostofuserfeeperuser: 'El usuario se agregará a su acuerdo de acuerdo con su plan de precios.',
    txt_global_std_theusermustacceptaninvitationtotheteam: 'El usuario debe aceptar una invitación al equipo.',
    txt_global_std_acheivementcannotbedeleted: ' Tenga en cuenta que este logro no se puede eliminar.',
    txt_global_std_refer10friendsachievementunlocked: '¡Has recomendado a 10 amigos! ¡Agradable! ¡Has ganado 300 puntos!',
    txt_global_std_female: 'Femenino',
    txt_global_std_member1: 'miembro',
    txt_global_std_viewloyaltypoints1: 'Ver puntos de fidelidad',
    txt_global_std_manageusersettingsandsupporthereusethequickaccessbuttonsbelowandnavigatebetweenusersbysearchingforthemintheusersview: 'Administre la configuración del usuario y el soporte aquí. Use los botones de acceso rápido a continuación y navegue entre los usuarios buscándolos en la vista de usuarios.',
    txt_global_std_afteryourfirstnumberofmonthsmonthperiodourappwilltrackyourscoresandprovidearatebasedonyourdrivinghabitsifyouhaveafewbadscoresyouhaveanentirenumberofmonths2monthstoimproveyouwillreceiveupdatesalongthewaywitheachtripadvisingyouofyourpotentialsavingsorifyourneedtoprovidemorefocuswithyourdrivinghabits: 'Después de su primer período de {numberOfMonths} meses, nuestra aplicación hará un seguimiento de sus puntajes y le proporcionará una tarifa basada en sus hábitos de conducción. Si tiene algunas puntuaciones malas, tiene {numberOfMonths2} meses completos para mejorar. Recibirá actualizaciones en el camino con cada viaje que le informarán sobre sus ahorros potenciales o si necesita enfocarse más en sus hábitos de manejo.',
    txt_global_std_sunday: 'Domingo',
    txt_global_std_youwillbereadysoon: '¡Estarás listo pronto!',
    txt_global_std_stepcurrentstepoftotalsteps: 'Paso {currentStep} de {totalSteps}',
    txt_global_std_advancedadmin: 'Administrador avanzado',
    txt_global_std_teamnames: 'nombres de equipo',
    txt_global_std_recoverpassword: 'Recuperar contraseña',
    txt_global_std_dontyouhaveanaccountwithenerfy: '¿No tienes una cuenta con {appName}?',
    txt_global_std_cimage1d: 'Baja velocidad',
    txt_global_std_seehowmuchyoucouldsave: 'Vea cuánto podría ahorrar',
    txt_global_std_showmehow: 'Muéstrame cómo',
    txt_global_std_yourownbrandedworkspaceopenforalldriverthatwishtoparticipate: 'Su propio espacio de trabajo de marca abierto para todos los conductores que deseen participar',
    txt_global_std_friendstothe: 'amigos a la',
    txt_global_std_yougetthiswhenyoufirstachieveascoreabove4point4: 'Obtienes esto cuando obtienes una puntuación superior a 4,4 por primera vez.',
    txt_global_EnerfySettingsTemplate_other: 'Otro',
    txt_global_std_ConfirmFia_Text1: 'Felicidades',
    txt_global_std_leaderboardpositionincrease: '¡Tu posición en la clasificación ha aumentado!',
    txt_global_std_KristofferssonDescription: 'Johan comenzó su carrera en la Super Serie Internacional con el equipo de Suecia, donde impresionó y pasó al Touring Car Championship (STCC), donde ganó en una sola temporada en 2002. Luego pasó al European RX en la temporada 2013-2014 y en 2015 a el FIA WRX donde ganó 5 rallies y quedó tercero en la clasificación general. Terminó subcampeón en 2016 detrás de su compatriota Mattias Ekström y lo ganó en 2017.',
    txt_global_std_settings: 'Ajustes',
    txt_global_std_alertdriveservicetitle: 'Servicio de conducción',
    txt_global_std_warikan_onboarding5_2: 'Corporación KINTO',
    txt_global_std_p2pbookingmessages: 'Mensajes de reserva',
    txt_global_std_carsharingemptyviewtitle: 'Sin actividades',
    txt_global_std_moreinformation: 'Más información',
    txt_global_std_hellothere: '¡Hola!',
    txt_global_std_ifyourdriverhasproblemswithreceivingtheirpasswordyoucanviewitherenotethatthecurrentpasswordresetswhenyouclickthebutton: 'Si su conductor tiene problemas para recibir su contraseña, puede verla aquí. Tenga en cuenta que la contraseña actual se restablece cuando hace clic en el botón.',
    txt_global_std_profilepreferences: 'Preferencias de perfil',
    txt_global_std_continue: 'Continuar',
    txt_global_std_tiphighway2DESC: 'El conductor del automóvil azul está conduciendo bien planificado y mantiene un buen ritmo y distancia con los automóviles que lo rodean.\n' +
      'El conductor del automóvil rojo está adelantando sin razón y está siguiendo de cerca al automóvil de adelante.',
    txt_global_std_p2pcarsharingismorefunwhenyouinviteyourfriendsandfamily: '¡Compartir autos es más divertido cuando invitas a tus amigos y familiares a unirse!',
    txt_global_std_failedtogetreviewlist: 'No se pudo obtener la lista de revisión',
    txt_global_std_noofparticipants: 'Número de participantes',
    txt_global_std_downloadapp: 'Descargar aplicación',
    txt_global_all_weekday: 'Día laborable',
    txt_global_std_choosethecarontheappscreenandwaituntilyougetnotificatedthatthedevicesarepaired: 'Elija el automóvil en la pantalla de la aplicación y espere hasta que reciba una notificación de que los dispositivos están emparejados',
    txt_global_std_statusinitdriving: 'Conectado',
    txt_global_std_p2puploadpicturesofyourcartodocumentanyscratchesordents: 'Cargue imágenes de su automóvil para documentar cualquier rasguño o abolladura.',
    txt_global_std_p2pfinishbooking: 'Finalizar reserva',
    txt_global_std_acceptedpermissions: 'Permisos aceptados',
    txt_global_std_publishachievementpopuptext: 'Al publicar un logro, su estado bloqueado será visible para los usuarios en la aplicación. Una vez que un usuario recibe una insignia, se mostrará en su estado desbloqueado.',
    txt_global_std_highspeedconditions: 'Condiciones de alta velocidad',
    txt_global_std_eastzone: 'Zona Este',
    txt_global_std_denneerndvendigforatappenkanvidehvornrduholderstilleeribevgelseaccelerererellerbremser: 'Habilite Motion & Fitness para obtener asistencia para iniciar y finalizar sus viajes automáticamente.',
    txt_global_std_warikan_onboarding12: 'Tel：0120-075-912',
    txt_global_std_HansenChamp: 'Campeón EURO RX 2016, RX Lite Cup 2015, Campeón del Mundo en ROK Junior 2010',
    txt_global_std_communication_upsell_pitch_bulletpoint3: 'Comunicar mensajes contextuales en el momento adecuado',
    txt_global_std_VilliersWhyjoin: 'Porque todos tenemos que hacer lo que podamos para que nuestras carreteras sean más seguras y, además, esto va a ser muy divertido.',
    txt_global_std_p2psaveandclose: 'Guardar y cerrar',
    txt_global_std_selectanotherdevice: 'Selecciona otro dispositivo',
    txt_global_std_adduser: 'Agregar usuario',
    txt_global_std_pioneersmartdrivingchallenge: 'Desafío de conducción pionera',
    txt_global_std_exportpdf: 'Exportar PDF',
    txt_global_std_creatingaccountwiththirdpartyname: 'Creando cuenta con {thirdPartyName}...',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint1: 'Comprender los factores que determinan el nivel de riesgo',
    txt_global_std_locationwithbest: 'Ubicación con mejor potencial de mejora',
    txt_global_std_carbonclean: 'carbono limpio',
    txt_global_std_march: 'Marzo',
    txt_global_std_3rddate: '3ro',
    txt_global_std_p2ppushbodynewfriend: 'Ahora eres amigo de {name}.',
    txt_global_std_people: 'Gente',
    txt_global_std_MSIG_InsuranceLetter20: '1) El Asegurado',
    txt_global_std_cimage15d: 'Sé consciente de que las pequeñas cosas marcan la diferencia.',
    txt_global_std_youractivatedkeys: 'Tus códigos activados',
    txt_global_std_riskydecisionbetweentrips: 'Decisión arriesgada entre viajes',
    txt_global_std_youhavenowachievedyourbestscoresofar: 'Ahora ha logrado su mejor puntaje hasta ahora (más de 4.4)',
    txt_global_std_p2pshareakintocar: 'Comparte un coche KINTO',
    'txt_global_std_Al-KuwariInterests': 'Carreras de autos, Resistencia de autos, Rallies de campo traviesa, Rallies de baja',
    txt_global_std_foratappenskalfungerekorrektharvibrugforadgangtildintelefonslokationsamtmotionogfitnessdata: 'Para registrar su historial de manejo, active Bluetooth, servicios de ubicación, movimiento y estado físico. Esta información personal no será compartida con terceros.',
    txt_global_std_p2paddexpenses: 'Añadir gastos',
    txt_global_std_nomapcoordinatesavailableforthisrun: 'No hay coordenadas de mapa disponibles para esta carrera',
    txt_global_std_cimage22h: 'EVITE EL CONTROL DE CRUCERO',
    txt_global_std_emergencycontact: 'Contacto de emergencia',
    txt_global_std_repetition1: 'Repetición',
    txt_global_std_p2pbookingrequests: 'Solicitudes de reserva',
    txt_global_std_addtosubscription: 'Agregar a la suscripción',
    txt_global_std_kintofaq6a1: 'Asegúrese de completar todos los pasos en "Conectar al automóvil". Por favor envíe un correo electrónico',
    txt_global_std_enteravalidphonenumber: 'Introduce un número de teléfono válido.',
    txt_global_std_wednesday: 'miércoles',
    txt_global_std_describeaccident: 'Describir accidente',
    txt_global_std_pointslabel: 'Puntos',
    txt_global_std_drivingskills: 'Habilidades de conducción',
    txt_global_std_takecontrolofyouraccountandprofile: 'Toma el control de tu cuenta y perfil.',
    txt_global_std_MSIG_Onboarding1: '¡Bienvenidos!',
    txt_global_std_loyaltyoverview: 'Resumen de lealtad',
    txt_global_std_warikanchallengerulesnotes: '【Notas】\n' +
      '・Al unirte, tu apodo e ícono se mostrarán a otros usuarios. Al hacer clic en "Unirse a la competencia", acepta estar sujeto a los términos y condiciones de este acuerdo. También puede ocultar su apodo en la pantalla de configuración de su perfil.\n' +
      '・La distancia debe ser superior a 1 km por viaje.\n' +
      '・El mismo viaje se puntuará hasta dos veces al día.\n' +
      '・La distancia total debe ser superior a 1 km.\n' +
      '・Calor es para el puntaje promedio y Total es para el promedio del 10 % superior de tus viajes.',
    txt_global_std_VilliersBesttip: 'Esté siempre alerta y anticípese a lo que harán otros conductores. Pueden girar a la derecha frente a usted, por lo que debe estar alerta para poder detenerse a tiempo o evitarlos.',
    txt_global_std_nopleasebeadvisedthattheenerfyapp: 'No. Tenga en cuenta que la aplicación Enerfy debe instalarse en su teléfono y que debe iniciar sesión para que sus unidades se registren. También necesita tener Bluetooth activado en su teléfono para poder conectarse.',
    txt_global_std_premium: 'De primera calidad',
    txt_global_std_p2punpaid: 'No pagado',
    txt_global_std_youhavebeendrivingwithtrygdriveforayear: '¡Ha estado conduciendo con TrygDrive durante un año!',
    txt_global_std_latest: 'Más reciente',
    txt_global_std_trygfromabroad: 'A salvo desde el extranjero\n',
    txt_global_std_co2unlockedkey: '¡Gran trabajo! Ahorró más de {co2value}% CO₂ este viaje al conducir de manera ecológica.',
    txt_global_std_PolicySchedule19: 'Prima bruta',
    txt_global_std_afewsimplereasonswhyfiasdcmakesadifferenceforyourbusiness: 'Algunas razones simples por las que FIA SDC marca la diferencia para su negocio',
    txt_global_std_bestregards: 'Saludos',
    txt_global_std_distanceunit: 'Unidad de distancia',
    txt_global_std_pressthedrivebuttonintheapp: 'Presione el botón de conducir en la aplicación',
    txt_global_std_at: 'a',
    txt_global_std_events_overview_season: 'Temporada',
    txt_global_std_p2p2totalcosttosplit: 'Costo total para dividir',
    txt_global_std_youpositiononleaderboardwasincreased: '¡Se incrementó tu posición en la clasificación!',
    txt_global_std_responses: 'Respuestas',
    txt_global_std_avglevel123: 'Promedio nivel',
    txt_global_std_PolicySchedule34: 'Número de registro',
    txt_global_std_companyname: 'Nombre de empresa',
    txt_global_std_yourealmostdonenowwejustneedtoverifyyouremailaverificationlinkhasbeensentto: '¡Ya casi terminas! Ahora solo necesitamos verificar tu correo electrónico. Se ha enviado un enlace de verificación a:',
    txt_global_std_startatrip: 'iniciar un viaje',
    txt_global_std_ayouwillhave60daysfromtheexpirationdatetomakeapaymentandcontinueyourpolicywithcovercubewithalapseincoverage: 'R: Tendrá 60 días a partir de la fecha de vencimiento para realizar un pago y continuar su póliza con Covercube con un lapso de cobertura.',
    txt_global_std_done: 'Hecho',
    txt_global_std_MSIG_InsuranceLetter2: 'ORIGINAL',
    txt_global_std_rwmnotnow: 'no ahora',
    txt_global_std_foratopretteforbindelsemellemdinbilogappnskaldergivesadgangtilbluetooth: 'Para establecer una conexión con su automóvil, Tryg Drive necesita acceso a bluetooth.',
    txt_global_std_youvsecochampions: 'Tú contra campeones ecológicos',
    txt_global_std_carsharingcompanydescription: 'Frase corta sobre esto.',
    txt_global_std_circle: '¡Circulo!',
    txt_global_std_p2pfaq1q: '¿Por qué algunos botones de la aplicación están atenuados?',
    txt_global_std_selectlanglabel: 'Seleccione el idioma',
    txt_global_std_gennemsnitsbilist: 'Promedio Conductor',
    txt_global_std_loyaltysystemdesc: 'Tenga en cuenta que sus ventajas se basan en los resultados finales del período de fidelidad anterior. Los puntos se obtienen al obtener logros y se restablecen al comienzo de un nuevo período.',
    txt_global_std_connectiontype: 'Tipo de conección',
    txt_global_std_manual_start_and_stop_requires_access_to_location_services_and_motion_fitness: 'El inicio y la parada manuales requieren acceso a los servicios de ubicación (GPS) y movimiento y estado físico.',
    txt_global_std_pluginthedeviceinyourcar: 'Enchufe el dispositivo en su coche.',
    txt_global_std_yourdrivingskills: 'Tus habilidades de conducción',
    txt_global_std_keepcollectingloyaltypointseventhoughsomeoneelseisdrivingyourcar: '¡Siga acumulando puntos de fidelidad aunque otra persona conduzca su automóvil!',
    txt_global_std_anyspeedgainisanaccelerationnomatterhowsmallsoplanandmakegooduseofeveryspeedincrease: 'Cualquier aumento de velocidad es una aceleración, por pequeña que sea. Por lo tanto, planifique y aproveche cada aumento de velocidad.',
    txt_global_std_validatedactivationcode: 'Código de activación validado',
    txt_global_std_p2pchoosehowtosplitcost: 'Elija cómo dividir el costo',
    txt_global_std_yourlinkshouldbeavailableinyourinboxinminutespleaseremembertocheckyourjunk: 'Tu enlace debería estar disponible en tu bandeja de entrada en {número} minutos. Por favor, recuerda revisar tu basura.',
    txt_global_std_userwithoutinsurance: '{appName}-usuario sin seguro',
    txt_global_std_successfullyunpublishedlanguage: 'Idioma sin publicar con éxito',
    txt_global_std_p2pfaq24q: '¿De qué manera se puede dividir el costo',
    txt_global_std_nointernetconnection2: '¡Sin conexión a Internet!',
    txt_global_std_bluetoothlink: 'enlace Bluetooth',
    txt_global_std_OgierMusic: 'Próximamente, en breve, pronto...',
    txt_global_std_congratulations2: 'Felicidades',
    txt_global_std_events_overview_today: 'Calor',
    txt_global_std_userhasbeenscheduledforremoval: 'Se programó la eliminación del usuario {{email}} a las {{timestamp}}.',
    txt_global_std_fileaclaimdirectlyintheappbyclickingfileclaimlinkorbycallingphonenumber: 'Presente un reclamo directamente en la aplicación haciendo clic en {fileClaimLink} o llamando al {phoneNumber}.',
    txt_global_std_p2ppushtitleexpensesdenied: 'Gastos denegados',
    txt_global_std_HSolbergMileage: 'Me encanta conducir sobre nieve y hielo, esa es la máxima diversión al volante.',
    txt_global_std_info: 'Información',
    txt_global_std_newpasswordsaved: 'Nueva contraseña guardada',
    txt_global_std_texttofriend: 'Texto a un amigo',
    txt_global_std_connected2: 'Conectado',
    txt_global_std_p2piwanttoborrowakinto: 'Quiero tomar prestado un Kinto',
    txt_global_std_welcometoenerfyfirstname: '¡Bienvenido a {appName}!',
    txt_global_std_charactercountexceeded: 'Número de caracteres excedido',
    txt_global_std_roadconditionswithbest: 'Condiciones de la carretera con mejor potencial de mejora',
    txt_global_std_opensettingsonyourphoneandmakesuretohavebluetoothactivated: 'Abra la configuración en su teléfono y asegúrese de tener Bluetooth activado.',
    txt_global_std_compareriskandco2levelsacrossyourorganization: 'Compare los niveles de riesgo y CO2 en toda su organización',
    txt_global_std_yourmessagehasbeencreated: '¡Tu mensaje ha sido creado!',
    txt_global_std_redeemed: 'Redimido',
    txt_global_std_specialimagefilenameid: 'Imagen personalizada',
    txt_global_std_improveheader: 'mejor racha',
    txt_global_std_yesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforagentinsurance: 'Sí, puede descargar la aplicación y elegir la opción "probar la aplicación". Podrá conducir con la aplicación y ver cuánto podría ahorrar si se inscribiera en el seguro {agent}.',
    txt_global_std_select: 'Seleccione',
    txt_global_std_p2pimakintoowner: 'Soy propietario de Kinto',
    txt_global_std_searchbynamegrouporaccounttype: 'Buscar por nombre, grupo o tipo de cuenta',
    txt_global_std_tripsinsight: 'Información de viajes',
    txt_global_std_removeaccountpluspersonaldata: 'Eliminar cuenta + datos personales',
    txt_global_std_drivertobeannounced: 'Conductor por anunciar',
    txt_global_std_dennekretur: 'Este viaje',
    txt_global_std_p2puploadreceipt: 'Sube imagen de tu recibo',
    txt_global_std_dailytrips: 'Viajes diarios',
    txt_global_std_thisisanexampletitle: 'Este es un titulo de ejemplo',
    txt_global_std_invitationcode: 'Código de invitación',
    txt_global_std_p2pnamecancelledbooking: '{name} canceló la reserva',
    txt_global_std_connecttocar: 'Conectar al coche',
    txt_global_std_experiencethebenefits: 'Experimente los beneficios con {appName}',
    txt_global_std_p2pfaq13a: '1. Vaya al resumen de reserva de su reserva.\n' +
      '2.Pulsa el botón Finalizar reserva.',
    txt_global_std_discountcodetermsconditions: 'Términos y condiciones del código de descuento',
    txt_global_std_Fia_Receipt_Text6: 'SU RECIBO:',
    txt_global_std_p2pfaq21q: 'Quiero comprobar los miembros del grupo.',
    txt_global_std_addemail: 'Agregar correo electrónico',
    txt_global_std_Fia_Receipt_Text8: 'Su pedido',
    txt_global_std_p2pfaq25a: '1. Seleccione "Datos de conducción" en el "Menú".\n' +
      '2. Puedes consultar los datos de tu historial de conducción.\n' +
      '3. Si selecciona la pestaña "Compartir datos", puede consultar los datos de conducción del grupo junto con el historial de miembros.',
    txt_global_std_driverslog: 'registro del conductor',
    txt_global_std_achievementcannotbedeleted: 'Tenga en cuenta que este logro no se puede eliminar.',
    txt_global_std_format2: 'Formato',
    txt_global_std_addexpenseserror: 'Agregue su tipo de gasto, costo e imagen.',
    txt_global_std_invitedrivers: 'Invitar a los conductores',
    txt_global_std_endtime: 'Hora de finalización',
    txt_global_std_2attachtheobddevicetothecableandplugitintothe12vpowercigarettelightersocketinyourcar: '2. Conecte el dispositivo OBD al cable y conéctelo a la toma de corriente de 12v (encendedor de cigarrillos) de su automóvil.',
    txt_global_std_alreadyhaveaninsurance: '¿Ya tienes un seguro?',
    txt_global_std_weareproudyouwanttobe: '¡Estamos orgullosos de que quieras ser parte de {appName}!',
    txt_global_std_icannotconnectanswertext2: 'por ayuda',
    txt_global_std_invitefriends: 'Invitar a amigos',
    txt_global_std_successfullyaddedtogroups: 'Agregado correctamente a los grupos',
    txt_global_std_yes: 'Sí',
    txt_global_std_joinenerfyandgetawarded: '¡Únete a Enerfy y obtén premios!',
    txt_global_std_entert: 'Ingresar',
    txt_global_std_acceptfitnessmotion: 'Aceptar Fitness/Movimiento',
    txt_global_std_deepdiveintodrivers: 'Sumérgete en los conductores y titulares de pólizas: rendimiento, probabilidad de colisión, conducción ecológica, emisiones y más <br /> Una vez que hayas activado un informe, puedes descargarlo tantas veces como quieras.',
    txt_global_std_congratulationsyoujustclimbedtheleaderboardviewyourranking: 'Felicitaciones, ¡acabas de ascender en la clasificación! Ver su clasificación.',
    txt_global_std_ecoscorescore: 'Puntuación ecológica {puntuación}',
    txt_global_std_welcome: '¡Bienvenidos!',
    txt_global_std_onlylettersaznumbers09andperiodsareallowed: 'Solo se permiten letras (az), números (0-9) y puntos (.).',
    txt_global_std_finalistsheat3: 'Finalistas - Serie 3',
    txt_global_std_username: 'Nombre de usuario',
    txt_global_std_customnotification: 'Notificación personalizada',
    txt_global_std_friend: 'Amigo',
    'txt_global_std_Al-KuwariDescription': 'Como copiloto, Nasser ganó el segundo lugar en 2016 FIA Cross Country copiloto, 1st en 2017 y 2018 MERC 2 – copiloto, 9 veces Campeonatos de Qatar como copiloto y 4 veces campeón en Campeonatos de Kuwait.',
    txt_global_std_checkyouremailformoredetails: 'Revisa tu correo electrónico para más detalles.',
    txt_global_std_f_lanzquote: 'Donde yace la Pasión, el esfuerzo fluye apasionadamente',
    txt_global_std_loginorcreateaccounthere: 'Inicia sesión o crea una cuenta aquí',
    txt_global_std_PSolbergMusic: 'Próximamente, en breve, pronto...',
    txt_global_std_bronzeusers: 'Usuarios bronce',
    txt_global_std_internalservererrorjsonnotvalid: 'Error interno del servidor (JSON no válido)',
    txt_global_std_cimage2d: 'Mantenga al menos 3 segundos hasta el próximo vehículo.',
    txt_global_std_loyaltynumber: 'número de fidelidad',
    txt_global_std_newmobilitycompanydescription: 'Movilidad inteligente bajo demanda.',
    txt_global_std_anerroroccurredwhiletryingtousetheactivationkey: 'Ocurrió un error al intentar usar la clave de activación',
    txt_global_std_p2pselectafriendtoviewallbookingsoftheircar: 'Seleccione un amigo para ver todas las reservas de su coche.',
    txt_global_std_mystatsthisperiod: 'Mis estadísticas este período',
    txt_global_std_second: 'Segundo',
    txt_global_std_calculationexample: 'Ejemplo de cálculo',
    txt_global_std_invitationlink: 'Enlace de invitación',
    txt_global_std_oilchange: 'Cambio de aceite',
    txt_global_std_waxing: 'Depilación',
    txt_global_std_teamco2savings: 'AHORRO DE CO2 DEL EQUIPO',
    txt_global_std_driversselected: 'conductores seleccionados',
    txt_global_std_ayescovercubeacceptsdriverswhoarelicensedinothercountries: 'R: Sí, Covercube acepta conductores con licencia de otros países.',
    txt_global_std_minutemin: 'min',
    txt_global_std_sparknamekey: '¡Chispa - chispear!',
    txt_global_std_endsin: 'Termina en',
    txt_global_std_p2phasspecifiedprice: ' ha especificado un precio.',
    txt_global_std_consentInfoYes: 'Únase a nosotros desde el principio, únase a nuestra comunidad y forme parte de Tryg Drive. Una vez que haya dado su consentimiento, puede participar en nuestros concursos y obtener acceso completo a las funciones de la aplicación. Tryg puede ponerse en contacto conmigo por correo electrónico, en la aplicación Tryg Drive y por SMS para recibir noticias y ofertas especiales de Tryg A/S sobre seguros, productos de seguros que no sean de vida, concursos y otros servicios de Trygs. Siempre puede solicitar consultas de marketing de forma fácil y gratuita.',
    txt_global_std_achieved: 'Logrado',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatus: 'Los logros lo ayudan a acumular puntos para ganar su estado de lealtad. ¡Mira a continuación los logros que puedes obtener siendo un súper conductor de {appname}!',
    txt_global_std_drivername: 'Nombre del conductor',
    txt_global_std_constantspeeddescription: 'Trate de bajar la velocidad máxima. Una velocidad máxima alta no siempre significa una velocidad media alta.',
    txt_global_std_southzone: 'Zona Sur',
    txt_global_std_thisisagorillatest: 'Esta es una prueba de gorila.',
    txt_global_std_withtheenerfyriskportfoliotrackeryouget: 'Con Enerfy Risk Portfolio Tracker, obtiene una vista del riesgo real y el impacto del CO2.',
    txt_global_std_p2pnonsequentialdatesselected2: 'Fechas no secuenciales seleccionadas',
    txt_global_std_unlockatbronzelevel: 'Desbloquear en el nivel de bronce',
    txt_global_std_insureddistance_used_90__1: 'Du har nu använt 90% av din årliga valda körsträcka. Kom ihåg att justera körsträckan så att du undviker förkortning vid skador. Du kan justera körsträckan här.',
    txt_global_std_duedate2: 'Fecha de vencimiento',
    txt_global_std_p2ppushbodytimetosplitcost: 'Divida su costo para {monthname}.',
    txt_global_std_wanttoshareyourprogress: '¿Quieres compartir tu progreso?',
    txt_global_std_p2plendtext: '{name} quiere prestarte {vehicle}',
    txt_global_std_OgierInterests: 'Próximamente, en breve, pronto...',
    txt_global_std_kintoImprovement4: '¡Tan cerca! Podrías haber ganado {ahorros}¥ en este viaje. ¡Concentrémonos!',
    txt_global_std_apitool_upsell_pitch: 'Ponte en marcha en poco tiempo con este completo punto de entrada para desarrolladores, para;',
    txt_global_std_welcometoenerfyglobalworkspace: '¡Bienvenido a Enerfy Global Workspace!',
    txt_global_std_p2pcreateanameforthisgroup: 'Crear un nombre para este grupo',
    txt_global_std_youdonothaveanyupcomingchallengesatthemoment: 'No tienes ningún desafío próximo en este momento.',
    txt_global_std_exploretheappmanual: 'Explore el manual de la aplicación',
    txt_global_std_status1: 'Estado',
    txt_global_std_ayesyoucanswitchyourinsuranceanytimemostinsurancecompaniesdonothaveanearlyterminationpenaltybutyouwillneedtoinquirewithyourcurrentinsurancecarrier: 'R: Sí, puede cambiar su seguro en cualquier momento. La mayoría de las compañías de seguros no tienen una multa por terminación anticipada, pero deberá consultar con su compañía de seguros actual.',
    txt_global_std_edityourcar: 'Edita tu coche',
    txt_global_std_signinwith: 'Iniciar sesión con {nombre}',
    txt_global_std_addmultipleusers: 'Añadir múltiples usuarios',
    txt_global_std_onlyshowdriversthatneedaction: 'Mostrar solo los controladores que necesitan acción',
    txt_global_std_p2pborrowedfrom: 'Tomado de',
    txt_global_std_p2pyoucaninvitexfriends: 'Puedes invitar a {número} amigos',
    txt_global_std_competeonlyasindividualsorbothindividuallyandinteamsteamscouldbedifferentdivisionsatyourcompany: 'Compite solo como individuos o tanto individualmente como en equipos. Los equipos pueden ser diferentes divisiones en su empresa.',
    txt_global_std_doyouwanttocancelconnectionandchangeobdreader: '¿Quieres cancelar la conexión y cambiar el lector OBD?',
    txt_global_std_yesyoumustadviseyouragentthatyourvehicleisusedforaridesharingcompanyandtheywillapplytheridesharingendorsementtoyourpolicy: 'Sí, debe informar a su agente que su vehículo se usa para una empresa de viajes compartidos y ellos aplicarán el endoso de viajes compartidos a su póliza.',
    txt_global_std_pointexchange: 'Intercambio de puntos',
    txt_global_std_createatrip: 'crear un viaje',
    txt_global_std_removeaccount_subject: 'Eliminar cuenta',
    txt_global_std_p2pchoosepaymentmethod: 'elige el método de pago',
    txt_global_std_pleasewaitaminuteortwobeforeyourefreshthisviewtogetnewsuggestions: 'Espere uno o dos minutos antes de actualizar esta vista para obtener nuevas sugerencias.',
    txt_global_std_p2psentaneditrequest: 'envió una solicitud de edición',
    txt_global_std_accelerationdescription: 'Una aceleración suave suele ser más segura y, además, ahorra algo de combustible.',
    txt_global_std_drivesafeandecofriendlysavemoneyonyourinsurance: 'Conduce seguro y respetuoso con el medio ambiente: ahorra dinero en tu seguro.',
    txt_global_std_TaxInvoice13: 'La garantía se cumple una vez que se realiza el pago a la Aseguradora o agente o corredor antes del inicio de la póliza',
    txt_global_std_readdocumentation: 'Leer documentación',
    txt_global_std_nodriversinthislistyet: 'Aún no hay controladores en esta lista',
    txt_global_std_temporarypassword_: 'Contraseña temporal',
    txt_global_std_thischallenge: 'este reto',
    txt_global_std_inyourcar: 'en tu auto',
    txt_global_std_p2ptobepaid: 'A pagar',
    txt_global_std_division2: 'División',
    txt_global_std_cropyourprofileimage: 'Recorta tu imagen de perfil',
    txt_global_std_MikkelsenInterests: 'Motocross, esquí alpino, pesca y todos los deportes en general.',
    txt_global_std_congratulations: '¡Felicidades!',
    txt_global_std_acceptpermissions: 'Aceptar permisos',
    txt_global_std_p2pshareyourcarwithfamilyandfriends: 'Comparte tu coche con familiares y amigos.',
    txt_global_std_previousstep: 'Paso anterior',
    txt_global_std_cimage29h: 'CORRER A LA VELOCIDAD DE CRUCERO - PLANIFICADO',
    txt_global_std_PSolbergChamp: 'Campeón del Mundo RX 2014 y 2015, Campeón WRC 13 veces, Campeón Rally GB 2002 y 2003',
    txt_global_std_p2pselectfriend: 'Seleccionar amigo',
    txt_global_std_p2pthischatmightnotbeprivatealladminsandownersofthecarcanseethischat: 'Es posible que este chat no sea privado. Todos los administradores y propietarios del automóvil pueden ver este chat.',
    txt_global_std_p2pfaq2a: '1. Vaya al resumen de la reserva para esa reserva y presione el botón editar reserva.\n' +
      '2. Configure la nueva fecha y hora y presione enviar actualización.\n' +
      '3.Espere a que su amigo confirme su nueva hora propuesta.',
    txt_global_std_p2ppushtitlelendcarnomessage: 'Nueva solicitud de préstamo recibida',
    txt_global_std_earnings: 'Ganancias',
    txt_global_std_greatdrivertoday: 'Gran conductor hoy',
    txt_global_std_JyrkiainenInterests: 'Deportes de motor obviamente, trotar, golf, esquiar, conducir motos de nieve en la Laponia finlandesa',
    txt_global_std_mileageinkm: 'Kilometraje en km',
    txt_global_std_hours: 'Horas',
    txt_global_std_parkedvsstartoftrip: 'Estacionado vs inicio de viaje',
    txt_global_std_milesdriven: 'Millas recorridas',
    txt_global_std_getitfilled: '¡Llénalo!',
    txt_global_std_collectpointstounlock: 'Acumula puntos para desbloquear.',
    txt_global_std_youmustfillinthemileage: 'Debes completar el kilometraje',
    txt_global_std_achievementsreceived: 'logros recibidos',
    txt_global_std_registerakinto: 'Registrar un Kinto',
    txt_global_std_rwmnotripalreadyregisterforthisweektext: 'Lo sentimos, solo puede registrar un viaje compartido y ganar puntos una vez por semana.',
    txt_global_std_NewPW_Text4: 'Sinceramente,\r\n{nombre de la aplicación}',
    txt_global_std_ontheinjury: 'sobre la lesión',
    txt_global_std_p2pdeletemessages: 'Eliminar mensajes',
    txt_global_std_p2prewardyourfriend: 'Recompensa a tu amigo',
    txt_global_std_actions: 'Comportamiento',
    txt_global_std_loyaltycertificate: 'Certificado de fidelidad',
    txt_global_std_mystatsthisday: 'Mis estadísticas del último día',
    txt_global_std_kintorecruit5usersunlocked: '¡Gracias! Has reclutado a 5 usuarios de Kinto. Se han enviado ¥10.000 a su monedero Kinto.',
    txt_global_std_whenwillyoudeducttheinvoicepayment: '¿Cuándo se deducirá el pago de la factura?',
    txt_global_std_phone: 'Teléfono',
    txt_global_std_zipdriver_upsell_pitch: 'Análisis geográfico utilizando código postal/base de datos de riesgo de código postal para;',
    txt_global_std_p2pbooksequentialdates: 'Reservar fechas secuenciales',
    txt_global_std_turnonthewarninglight: 'Encender la luz de advertencia',
    txt_global_std_insurancepolicydocuments: 'Documentos de la póliza de seguro',
    txt_global_std_youdroveatscoretypescoreorbettergreatyouhaveearnedpointspoints: '¡Condujiste a {score} o mejor! ¡Excelente! ¡Has ganado {puntos} puntos!',
    txt_global_std_OSolbergInterests: 'Próximamente, en breve, pronto...',
    txt_global_std_qidonthavebluetoothinmycarcanistillusetheapp: 'P: No tengo Bluetooth en mi automóvil, ¿puedo usar la aplicación de todos modos?',
    txt_global_std_qhowdoirenewmyinsurance: 'P: ¿Cómo renuevo mi seguro?',
    txt_global_std_MSIG_InsuranceLetter12: 'Periodo de seguro',
    txt_global_std_acommontypeofunsafedrivingbehaviorisputtinghugeeffortintochasingthenexttrafficlightitslikeachallengetogettothenextcrossingasquicklyaspossibleonlytobestuckwaitingforthelighttoturngreendrivingsmoothlyandlookingwellaheadtoanticipatewhatscomingupsavesbothtimeandfuel: '¡Un tipo común de comportamiento de conducción inseguro es hacer un gran esfuerzo para perseguir el próximo semáforo! Es como un desafío llegar al próximo cruce lo más rápido posible solo para quedarse atrapado esperando que el semáforo se ponga en verde😊 Conducir sin problemas y mirar bien hacia adelante para anticipar lo que se avecina ahorra tiempo y combustible.',
    txt_global_std_youmayhavetogoto: 'Puede que tengas que ir a',
    txt_global_std_what: 'Qué',
    txt_global_std_wanttobuyinsurance: '¿Quieres comprar un seguro?',
    txt_global_std_unlockbenefitsenterpolicynumber: 'Desbloquee los beneficios - Ingrese el número de póliza',
    txt_global_std_timing: 'Momento',
    txt_global_std_bydrivingsafeandsmartrememberto: 'Conduciendo de forma segura e inteligente. Recuerda:',
    txt_global_std_p2pcompleted: 'Terminado',
    txt_global_std_whenusingthiscodeorlinkuserwillbeonboardedtoyourenerfyglobalteamandthefollowingsubgroupgroups: 'Al usar este código o enlace, el usuario se incorporará a su equipo de Enerfy Global y a los siguientes subgrupos',
    txt_global_std_p2precommended: 'Recomendado',
    txt_global_std_avgspeed: 'Promedio Velocidad',
    txt_global_std_photos: 'Fotos',
    txt_global_std_pickyourteam: 'Elige tu equipo',
    txt_global_std_p2phoursused: 'Horas utilizadas',
    txt_global_std_automatichelpsyourecord: 'La conexión automática lo ayuda a registrar sus viajes en caso de que olvide abrir la aplicación.',
    txt_global_std_invitationqrcode: 'Código QR de invitación',
    txt_global_std_connectengine: 'Arranque el motor para conectar',
    txt_global_std_driverforscore: 'Conduce {appname} por una puntuación',
    txt_global_std_cimage26d: ' ',
    txt_global_std_ConfirmFia_Text8: '¡Te veo allí!',
    txt_global_std_ringphoneno: 'Timbrar {número de teléfono}',
    txt_global_std_northzone: 'Zona Norte',
    txt_global_std_p2pdistancetravelledrequiresbluetooth: 'Distancia recorrida requiere bluetooth',
    txt_global_std_name: 'Nombre',
    txt_global_std_customize: 'personalizar',
    txt_global_std_p2pwelcometokintowarikan: '¡Bienvenido a Kinto Warikan!',
    txt_global_std_cartype: 'Tipo de carro',
    txt_global_std_unknownstreet: 'calle desconocida',
    txt_global_std_p2ppushbodyyouhaveabookingtofinalize: 'Tenga en cuenta que una reserva no está liquidada.',
    txt_global_std_imagepickerlocked: 'Elija el icono de la insignia bloqueada',
    txt_global_std_vouchercodesaved: 'Código de cupón guardado',
    txt_global_std_enerfyanalytics_upsell_pitch: 'Obtenga una comprensión completa de sus datos con informes personalizados o predefinidos para;',
    txt_global_std_warikan_onboarding1_2: 'Querido. Cliente de Warikan KINTO',
    txt_global_std_selectdate: 'Seleccione fecha',
    txt_global_std_invite10friendstojointhechallengeforabetterworld: 'Invita a 10 amigos a unirse al desafío por un mundo mejor.',
    txt_global_std_activate: 'Activar',
    txt_global_std_makesureyouandothersareinasafeplaceifanyoneisinjuredcallphoneno: 'Asegúrese de que usted y los demás estén en un lugar seguro. Si alguien está herido, llame a: {phoneNo}',
    txt_global_std_achievementshelpyoucollectpointstoearnyourloyaltystatuscheckoutbelowtheachievementsyoucanearnbybeinga: 'Los logros lo ayudan a acumular puntos para ganar su estado de lealtad. Consulte a continuación los logros que puede obtener al ser un',
    txt_global_std_oncetheapprecognisestheobdreaderyoullmoveautomaticallytothenextstep: 'Una vez que la aplicación reconozca el lector OBD, pasará automáticamente al siguiente paso.',
    txt_global_std_greatdrive: 'Gran unidad!',
    txt_global_std_thankyouforyourorderenerfy: 'Gracias por su orden. Su complemento Enerfy se le enviará el siguiente día hábil. La entrega estimada es de 1 a 3 días hábiles.',
    txt_global_std_internalservererrorpleasecontactinfogreaterthaneulicensekeynotvalid: 'Error interno del servidor, póngase en contacto con info@greaterthan.eu (clave de licencia no válida)',
    txt_global_std_countrycode: 'Código de país',
    txt_global_std_astronautssuperlonggroupnamegroup23: 'Astronautas, nombre de grupo súper largo, Grupo 23',
    txt_global_std_welcomeletsstart: '¡Bienvenidos! ¡Empecemos!',
    txt_global_std_car2: 'Coche',
    txt_global_std_tripsperperiod: 'Viajes por periodo',
    txt_global_std_p2pdateandtime: 'Fecha y hora',
    txt_global_std_p2pwehavesentanewtemporarypasswordtoyouremailaddress: 'Hemos enviado una nueva contraseña temporal a su dirección de correo electrónico.',
    txt_global_std_map2: 'Mapa',
    txt_global_std_enteravalidpolicynumber: 'Ingrese un número de póliza válido.',
    txt_global_std_sendnow: 'Enviar ahora',
    txt_global_std_qrcode: 'Código QR',
    txt_global_std_wrongemailorpassword: 'Correo o contraseña equivocada',
    txt_global_std_randomperson: 'Persona aleatoria',
    txt_global_std_3gotobluetoothsettinginyourmobilemakesureyourmobilesbluetoothisturnedon: '3. Vaya a la configuración de Bluetooth en su móvil, asegúrese de que el Bluetooth de su móvil esté encendido.',
    txt_global_std_step5: 'Paso {número}',
    txt_global_std_currentinsurancecompany: 'Compañía de seguros actual',
    txt_global_std_mysettings: 'Mi configuración',
    txt_global_std_areyousureyouwanttoremoveuserfromthegroup: '¿Está seguro de que desea eliminar al usuario del grupo?',
    txt_global_std_youhaventdriventhismonth: 'No has conducido este mes',
    txt_global_std_checkyourinbox: 'Revisa tu correo',
    txt_global_std_communication_upsell_bulletpoint3: 'Comunicar mensajes contextuales en el momento adecuado',
    txt_global_std_reportfilled: 'Informe lleno',
    txt_global_std_Alert_WereYouDrivingBody: 'Recuerde iniciar y detener sus viajes en la aplicación para obtener su puntaje de manejo.',
    txt_global_std_invitemoreusers: 'Invitar a más usuarios',
    txt_global_std_searchmenu: 'Menú de búsqueda',
    txt_global_std_header: 'Encabezamiento',
    txt_global_std_p2pconnecttocarcomingsoon: 'Conectar al coche – Próximamente',
    txt_global_std_actual: 'Actual',
    txt_global_std_or2: 'o',
    txt_global_std_communication_upsell_pitch_bulletpoint2: 'Conéctese con los usuarios en sus canales preferidos',
    txt_global_std_cimage20d: 'Ahorras tiempo al planificar tu conducción. La velocidad máxima alta proporciona ganancias de tiempo pequeñas o nulas.',
    txt_global_std_internetconnectionlost: 'Conexión perdida. Esto puede causar retrasos en la aplicación y afectar sus datos de manejo.',
    txt_global_std_turnonyourcar: 'enciende tu auto',
    txt_global_std_p2pemailnewbookings: 'Nuevas reservas',
    txt_global_std_alertdrivenotstarted: '¡Advertencia! Parece que está viajando, pero no se inicia ninguna unidad.',
    txt_global_std_manageyourteammembersandtheiraccountpermissionshere: 'Administre los usuarios y sus permisos de cuenta aquí',
    'txt_global_std_Al-KuwariQuote': 'Cotización próximamente...',
    txt_global_std_startbonusno: 'bono de inicio',
    txt_global_std_saving: 'Ahorro',
    txt_global_std_nationalinsuranceno: 'Seguro Nacional. No',
    txt_global_std_ChicheritDescription: 'El atleta de monstruos Guerlain Chicherit sube al Campeonato Mundial de Rallycross de la FIA en 2018 con su propio equipo GC Kompetition conduciendo el nuevo Renault Megane RS desarrollado por Prodrive. Esquiador profesional y cuatro veces campeón mundial de freeride, Chicherit ostenta el récord de convertirse en el primer hombre en la historia en completar una voltereta hacia atrás sin ayuda en un automóvil. Chicherit hizo su debut en World RX en el JRM Mini RX Countryman en 2015.',
    txt_global_CoreTemplate_Offline: 'viaje fuera de línea',
    txt_global_std_youhaveunsavedsettingsquitanyway: '¿Tiene configuraciones sin guardar para salir de todos modos?',
    txt_global_std_p2ppushtitlehasdeniedthebooking: 'Reserva denegada',
    txt_global_std_MikkelsenMileage: 'Siempre disfruto conduciendo en mi ciudad natal, Oslo, cuando vuelvo a Noruega. Viajo bastante, así que también para mí siempre es agradable volver a casa. Sin embargo, también disfruto conduciendo en los Alpes, ya que las vistas son increíbles.',
    txt_global_std_inviteyourfriendsandfamilytojointhechallengeforabetterworld: 'Invita a tus amigos y familiares a unirse al desafío por un mundo mejor',
    txt_global_std_yourcarnowhasanactivatedinsurance: 'Tu coche ya tiene un seguro activado',
    txt_global_std_weadaptthestartscreen: 'Adaptamos la pantalla de inicio según sus selecciones.',
    txt_global_std_highlightedtheareainwhichyouhavethemostimprovementpotentialinthemapandchart: 'Hemos resaltado el área en la que tiene el mayor potencial de mejora en el mapa y el cuadro de arriba, ¡compruébelo usted mismo!',
    txt_global_std_theusermustacceptaninvitationtotheteamfirst: 'El usuario debe aceptar primero una invitación al equipo.',
    txt_global_std_caniswitchtoagentifialreadyhaveanactiveinsurancepolicy: '¿Puedo cambiarme a {agent} si ya tengo una póliza de seguro activa?',
    txt_global_std_createnewemail: 'Crear nuevo correo electrónico',
    txt_global_std_p2pfaq15q: '¿Cómo agrego los gastos relacionados con el auto?',
    txt_global_std_yourpasswordwaschanged: '¡Tu contraseña fue cambiada!',
    txt_global_std_sparkunlockedkey: '¡Condujiste muy bien! ¡Tu nivel promedio fue mejor que 4 durante una semana! Se han agregado 1000 puntos a su billetera Kinto.',
    txt_global_std_joinaheattoenterthefiasdc: '¡Únete a una serie para participar en el Smart Driving Challenge!',
    txt_global_std_turnonbluetooth: 'activar bluetooth',
    txt_global_std_yeary: 'y',
    txt_global_std_HusseinAbdullaWhyjoin: 'Espíritu de desafío',
    txt_global_std_rwmnotripalreadyregisterforthisweektitle: 'El viaje compartido ya estaba registrado para esta semana',
    txt_global_std_JyrkiainenWhyjoin: '¡Juntos podemos divertirnos y mejorar nuestras habilidades de conducción eficiente con FIA Smart Driving Challenge!',
    txt_global_std_cimage34h: 'NO SE ESTRESE EN LAS COLAS',
    txt_global_std_manageadvancedusersettingsandsupporthereusethequickaccessbuttonsbelowtoeasilynavigatebetweenusersagreementsandfollowupandhelpoutwithonboarding: 'Administre la configuración de usuario avanzada y soporte aquí. Use los botones de acceso rápido a continuación para navegar fácilmente entre usuarios, acuerdos y hacer un seguimiento y ayudar con la incorporación.',
    txt_global_std_yesagentacceptsdriverswhohavebeenlicensedinothercountries: 'Sí, {agent} acepta conductores con licencia en otros países.',
    txt_global_std_MSIG_InsuranceLetter45: 'SE APLICA UN EXCESO DE $100.00 PARA TODOS Y CADA RECLAMO DE PARABRISAS',
    txt_global_std_datespan: 'Intervalo de fechas',
    txt_global_std_top3besttrips: 'Los 3 mejores viajes',
    txt_global_std_welldone: 'Bien hecho',
    txt_global_std_practicaltimesaving: 'Práctico ahorro de tiempo',
    txt_global_std_thelowerthescorethemoresavingsyoucanearnyouwillseeyourpotentialsavingswitheachtrip: 'Cuanto más bajo sea el puntaje, más ahorros puede ganar. Verás tus ahorros potenciales con cada viaje.',
    txt_global_std_payment: 'Pago',
    txt_global_std_remembertostartandstop: 'Recuerde iniciar y detener sus viajes en la aplicación para acumular puntos y recompensas.',
    txt_global_std_searchbartitleandpointstext: 'Buscar por título o puntos',
    txt_global_std_currentstandings: 'Posiciones actuales',
    txt_global_std_kintofaq4a: 'Participas automáticamente cuando te registras. No se preocupe, la tabla de clasificación solo mostrará su apodo, por lo que siempre permanecerá en el anonimato. ¡La competencia dura del 1 de noviembre de 2020 al 31 de enero de 2021 y el ganador gana un viaje para ir a pescar en el hielo en el norte de Suecia!',
    txt_global_std_p2ppushbodynewinvoicetopay: 'Recibió una factura por la división de costos de {vehicletype}',
    txt_global_std_workshop: 'Taller',
    txt_global_CoreTemplate_help: 'Ayuda',
    txt_global_std_drivingmenu: 'conduciendo',
    txt_global_std_weareimpressed: '¡Guau, {usuario}, estamos impresionados!',
    txt_global_std_youhavenowdriven5tripsbetterthan3point3: 'Ahora ha conducido 5 viajes mejor que 3.3',
    txt_global_std_qcaniswitchtoagentifialreadyhaveanactiveinsurancepolicy: 'P: ¿Puedo cambiarme a {agente} si ya tengo una póliza de seguro activa?',
    txt_global_std_savingsistobeusedforsubscriptiondeduction: 'El ahorro se utilizará para la deducción de suscripción.',
    txt_global_std_p2pfaq31a: '1. Seleccione "Datos de conducción" en el "Menú".\n' +
      '2. Puedes consultar los datos de tu historial de conducción. Puede ver los detalles de los comentarios seleccionando datos de conducción específicos.',
    txt_global_std_campaignname: 'Nombre de campaña',
    txt_global_std_tomasengedescriptionshort: 'Un ex piloto de carreras profesional y el primer piloto checo en F1. Le encanta el silencio mientras conduce.',
    txt_global_std_invalidtime: 'Hora inválida.',
    txt_global_std_kilometerpremiumtoohigh: 'Prima por kilómetro demasiado alta',
    txt_global_std_redeemablepoints: 'puntos canjeables',
    txt_global_std_nofriendsselected: 'No hay amigos seleccionados',
    txt_global_std_propernumber: 'Debe ser un número de teléfono adecuado',
    txt_global_std_ConfirmFia_Hello: '¡Ya estás reservado!',
    txt_global_std_readthecarsengineerrorsusingtheiobd2app: 'Lea los errores del motor del automóvil con la aplicación iOBD2',
    txt_global_std_createchallenge: 'Crear desafío',
    txt_global_std_defaulticonimage: 'Defecto',
    txt_global_std_insuranceterms: 'Términos del seguro',
    txt_global_std_p2pfaq4q: '¿Cómo elimino un mensaje?',
    txt_global_std_confirmandupdateaccount: 'Confirmar y actualizar cuenta',
    txt_global_std_notesaved: 'Nota guardada',
    txt_global_std_youhaveinvited: 'has invitado',
    txt_global_std_p2pmaintenance: 'Mantenimiento',
    txt_global_std_viewalldrivingdata: 'Ver todos los datos de conducción',
    txt_global_std_audiofeedback: 'Comentarios de audio',
    txt_global_std_p2pfaq3a: 'Pronto agregaremos una función que lo ayudará a encontrar su automóvil. Por ahora, te recomendamos preguntarle a tu amigo en los mensajes de chat.',
    txt_global_std_encouragetostayfocused: 'Anime al conductor a mantenerse enfocado',
    txt_global_std_nicetohaveyouonboard: '¡Encantado de tenerte a bordo!',
    txt_global_std_warikan_onboarding14: 'Tenga en cuenta que puede ser difícil conectarse cuando hay mucha gente.',
    txt_global_std_p2pseeyourbookingsorcheckavailabilityofyourfriendscar: 'Consulta tus reservas o consulta la disponibilidad del coche de tu amigo.',
    txt_global_std_dateandtime: 'Fecha y hora',
    txt_global_std_totalistheleaderboardforthechallenge: 'Total es la tabla de clasificación para el desafío.',
    txt_global_std_updatetext: 'Actualizar texto',
    txt_global_std_total: 'Total',
    txt_global_std_SulayemFacts: 'Ha competido en rallies de campeonatos africanos, europeos, de Oriente Medio y del Mundo. Ganó uno de sus títulos mientras se recuperaba de una fractura de cuello',
    txt_global_std_congratulationsyouhaveverifiedyouremailyouwillberedirectedshortly: 'Enhorabuena, has verificado tu correo electrónico. Serás redirigido en breve',
    txt_global_std_PSolbergQuote: 'Cotización próximamente...',
    txt_global_std_tryresettingyourcarsconnectiontoyourphonebyremovingitandthenaddingitagainnodot: 'Intente restablecer la conexión de su automóvil a su teléfono eliminándolo y luego agregándolo nuevamente',
    txt_global_std_cimage25h: 'RPM ÓPTIMAS',
    txt_global_std_p2pbookingcancelled: 'Reserva cancelada',
    txt_global_std_drivingyieldtotalamount: 'Rendimiento de conducción (importe total)',
    'txt_global_std_drivingtimeabove{speed}': 'tiempo de conducción por encima de {velocidad}',
    txt_global_std_saveanduploadreport: 'Guardar y cargar informe',
    txt_global_std_pleasechooseaninsurance: 'Por favor elige un seguro',
    txt_global_std_techsupport: 'Apoyo técnico',
    txt_global_std_p2piacceptthe: 'acepto el',
    txt_global_std_safedriverweekly: 'Conductor Seguro Semanalmente',
    txt_global_std_pleasemakesurethatyouareconnectedtoyourcar: 'Asegúrate de estar conectado a tu coche e inténtalo de nuevo.',
    'txt_global_std_{count}points': '{contar} puntos',
    txt_global_std_bestoptionifyoudonthavebt: 'Esta es la mejor opción si no tiene una conexión Bluetooth en su automóvil.',
    txt_global_std_planaheadbeprepared: 'Planifica con anticipación, prepárate',
    txt_global_std_top: 'Parte superior',
    txt_global_std_howdoidrivewell: '¿Cómo conduzco bien?',
    txt_global_std_fellowdriver: 'compañero conductor',
    txt_global_std_apopupintheappwillaskyoutoconnect: 'Una ventana emergente en la aplicación le pedirá que se conecte (puede demorar hasta 2 minutos)',
    txt_global_std_qhowdoiterminatemyinsurance: 'P: ¿Cómo cancelo mi seguro?',
    txt_global_std_incorrectlyformattedcsvfilemakesuretofollowtheexactformatofthetemplate: 'Archivo .csv con formato incorrecto. ¡Asegúrate de seguir el formato exacto de la plantilla!',
    txt_global_std_startaddress: 'dirección de inicio',
    txt_global_std_collectablecategory: 'Coleccionable',
    txt_global_std_andallowitwevalueyourprivacy: 'y permitirlo. Valoramos su privacidad y no compartiremos esta información con una entidad de terceros.',
    txt_global_std_9digitactivationcode: 'Ingrese el código de activación de 9 dígitos enviado a su teléfono y correo electrónico.',
    txt_global_std_linktopolicies: 'Enlace a políticas',
    txt_global_std_couldnotchangeyourpassword: '¡No se pudo cambiar su contraseña!',
    txt_global_std_december: 'Diciembre',
    txt_global_std_SignupFia_Text9: '#FIASmartDrivingChallenge',
    txt_global_std_5000pointaverageachievementlocked2: 'Conduzca con un {scoretype} promedio de {score} o mejor durante una semana para desbloquear este logro y ganar 5000 puntos.',
    txt_global_std_nicedrive: '¡Buen viaje!',
    txt_global_std_uniquecategory: 'Único',
    txt_global_std_appName: '{nombre de la aplicación}',
    txt_global_std_howdoiearnaloyaltystatus: '¿Cómo obtengo un estado de lealtad?',
    txt_global_std_p2pcontinueandpay: 'Continuar y Pagar',
    txt_global_std_planahead: 'Planifique con anticipación',
    txt_global_std_p2ppushtitleaddedexpences: '{name} gastos adicionales',
    txt_global_std_competeagainsteachotherwintogether: 'Competir entre sí - ganar juntos',
    txt_global_std_forbothyourphoneandcar: 'tanto para tu teléfono como para tu coche.',
    txt_global_std_nodatatoshow: 'Comience a ver su progreso',
    txt_global_std_HansenDescription: 'Kevin Hansen, el campeón europeo de superdeportivos más joven de la historia y Novato del año de la FIA en 2016, comenzó su carrera en karting con solo cinco años. Progresando en las clases de karting, Kevin cambió a RX en 2012 y rápidamente demostró que estaba allí para tener éxito. Al ganar varios campeonatos durante 2013-2015, Kevin alcanzó su sueño en 2016 al ganar EuroRX. Desde 2017, Kevin ha sido un habitual del World Rallycross y ha terminado octavo dos veces en el Campeonato del Mundo, ¡y en 2018 se convirtió en piloto de fábrica de Peugeot a la edad de 19 años!',
    txt_global_std_p2pyouborrowed: 'tomaste prestado',
    txt_global_std_manual: 'Manual',
    txt_global_std_resetpasswordwrongpasswordformat: 'Formato de contraseña incorrecto. Mínimo 8 caracteres, al menos una letra mayúscula, una letra minúscula y un número.',
    txt_global_std_fileaclaim: 'Presentar una reclamación',
    txt_global_std_drivercppr: 'Informe de perfilado del patrón de accidentes del conductor',
    txt_global_std_membernumber: 'Número de miembro',
    txt_global_std_amazingdriverunlockedkey: '¡Eres increíble {usuario}! ¡Obtuviste una puntuación superior a {score} hoy!',
    txt_global_std_openinghoursinsurance: '8-20 días laborables, 10-18 fines de semana',
    txt_global_std_howdoicompeteinlexuschallenge: '¿Cómo compito en Lexus Challenge?',
    txt_global_std_p2perrorcodebookingmaynotbeearlierthannow: 'La reserva no puede ser antes de ahora.',
    txt_global_std_p2paddedcaraslendable: 'Coche añadido como prestable',
    txt_global_std_activateitnow: 'Actívalo ahora',
    txt_global_std_choosetypeofapp: 'Elige el tipo de aplicación',
    txt_global_std_challengeafriend3: '¡Reta a un amigo!',
    txt_global_std_f_lanzdescriptionshort: 'La mejor piloto de carreras de Sudáfrica. Le encanta bailar, exploradora de la naturaleza salvaje',
    txt_global_std_carbondioxide: 'El dióxido de carbono es un gas incoloro con una densidad un 60% superior a la del aire seco. El dióxido de carbono consiste en un átomo de carbono unido por doble enlace covalente a dos átomos de oxígeno. Ocurre naturalmente en la atmósfera de la Tierra como un gas traza.',
    txt_global_std_p2pfaq19a: 'Si está utilizando un teléfono Android, es posible que los datos de conducción no se registren si la función "Optimizar batería" está activada.\n' +
      '1. Seleccione "Aplicaciones y notificaciones" en "Configuración" en su teléfono inteligente.\n' +
      '2. Seleccione la configuración avanzada de aplicaciones y notificaciones, luego seleccione Acceso especial a aplicaciones.\n' +
      '3. Seleccione "Optimización de la electricidad" desde el acceso a la aplicación especial.\n' +
      '4. Seleccione "Todas las aplicaciones" en el menú desplegable y luego seleccione "Warikan KINTO".\n' +
      '5. Seleccione "No optimizar" en el cuadro de diálogo.',
    txt_global_std_add: 'Agregar',
    txt_global_std_documentyourcar: 'Documenta tu coche',
    txt_global_std_schema: 'Esquema',
    txt_global_std_visualeditor: 'editor visual',
    txt_global_std_estimatedsurcharge: 'Recargo estimado',
    txt_global_std_plugindevice: 'Conecta el dispositivo',
    txt_global_std_therecouldbeotherreasonswhyyourcarsbluetoothdoesntshowupinthelist: 'Puede haber otras razones por las que el Bluetooth de su automóvil no aparece en la lista:',
    txt_global_std_silverusers: 'Usuarios de plata',
    txt_global_std_clickhere: '¡Haga clic aquí!',
    txt_global_std_p2pemailifyouremailclientdoesnotacceptbutton: 'Si su cliente de correo electrónico no acepta el botón, haga clic en este enlace.',
    txt_global_std_toconnectyourcar: 'Para conectar tu coche:',
    txt_global_std_nousersfoundtryreloadingthepage: 'No se encontraron usuarios. Intenta recargar la página.',
    txt_global_std_co2: 'CO2',
    txt_global_std_improvementpossibility: 'Posibilidad de mejora',
    txt_global_std_unpublish: 'despublicar',
    txt_global_std_textmessage: 'Mensaje de texto',
    txt_global_std_cimage43d: ' ',
    txt_global_std_set_up_manual_start_stop: 'Configuración: inicio y parada manual',
    txt_global_std_pwdtenandtwice: 'Su contraseña debe tener al menos diez (10) caracteres y debe escribirse dos veces.',
    txt_global_std_cimage30h: 'PLANIFICA CADA ACELERACIÓN PARA APROVECHARLO',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint3: 'Obtenga información precisa sobre la exposición al riesgo geográfico en tiempo real',
    txt_global_std_zipdrivingriskindex: 'Índice de riesgo Zip-Driving',
    txt_global_std_p2pfaq18q: 'Dígame cómo conectar la aplicación al automóvil y registrar los datos de conducción.',
    txt_global_std_maxfilesize: 'Tamaño máximo de archivo',
    txt_global_std_togetmorefriendssuggestionsyoucanloadyourfriendsfromfacebook: 'Para obtener más sugerencias de amigos, puede cargar sus amigos de Facebook.',
    txt_global_std_youcanemailthiscertificatetogetdiscountswithdifferentvendors: 'Puede enviar este certificado por correo electrónico para obtener descuentos con diferentes proveedores.',
    txt_global_std_toprovideyouwithaccuratestatsanddrivingscoreappnameneedstoruninbackgroundmode: 'Para brindarle estadísticas precisas y puntaje de conducción, {appname} debe ejecutarse en segundo plano.',
    txt_global_std_carwash: 'Lavado de autos',
    txt_global_std_ABC: 'A B C',
    txt_global_std_whoisthesmartestdriver: '¿Quién es el conductor más inteligente?',
    txt_global_std_enerfyanalytics_upsell_bulletpoint1: 'Comprender los factores que determinan el nivel de riesgo',
    txt_global_std_api: 'API',
    txt_global_std_adgangtilmotionogfitnessdata: 'Permitir movimiento y estado físico',
    txt_global_std_p2ptotaltopaytoph: 'Total a pagar a {usuario}',
    txt_global_std_ConfirmFia_Text71: 'Si por casualidad no puede asistir al evento, cancele su franja horaria haciendo clic en el siguiente enlace:',
    txt_global_std_electriccarorobd: '¿Coche eléctrico u OBD?',
    'txt_global_std_Al-KuwariMileage': 'Me gusta conducir largas distancias Me encuentro en largas distancias.',
    txt_global_std_oliversolbergShortDescription: 'Ninguna',
    txt_global_std_p2pwaitingfor: 'Esperando',
    txt_global_std_iwanttoreceiveinformationandupdatesviaemail: 'Quiero recibir información y actualizaciones por correo electrónico.',
    txt_global_std_fullname: 'Nombre completo',
    txt_global_std_3rd: '3: rd',
    txt_global_std_p2ppushbodyhasdeniedthebooking: '{name} ha denegado la reserva.',
    txt_global_std_OSolbergChamp: 'Próximamente, en breve, pronto...',
    txt_global_std_referfriendsgetrewards: 'Recomendar amigos. Obtener recompensas.',
    txt_global_std_p2ppushtitlesetpriceforbookingto: 'Se fija el precio de la reserva',
    txt_global_std_pleasewait: 'Espere por favor',
    txt_global_std_joinevent: 'Unirse al evento',
    txt_global_std_points1: 'puntos',
    txt_global_std_pair: 'Par',
    txt_global_std_demotext2: 'Cuanto mejor conduzcas, menor nivel obtendrás. Consulta tu nivel aquí.',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriend: 'Compite en tu conducción diaria contra tu amigo.',
    txt_global_all_thursday: 'jueves',
    txt_global_std_youneedtoselectatype2: 'Tienes que seleccionar un tipo',
    txt_global_std_gallonimp: 'Diablillo de galón',
    txt_global_std_socialbutterflyunlockedkey: '¡Has recomendado a 10 amigos! ¡Agradable! Se agregaron 300 puntos a su billetera Kinto.',
    txt_global_std_findaservicecenter: 'Encuentre un centro de servicio',
    txt_global_std_pickyourteamleader2: 'Elige a tu líder de equipo',
    txt_global_std_401unauthorized: '401 - No autorizado',
    txt_global_std_smssent: 'SMS enviado!',
    txt_global_std_p2psplittingthecostforyourkintosubscribtionwithyourfriendsiseasy: '¡Dividir el costo de su suscripción a Kinto con sus amigos es fácil!',
    txt_global_std_iveforgottenmypassword: 'he olvidado mi contraseña',
    txt_global_std_OSolbergBesttip: 'Próximamente, en breve, pronto...',
    txt_global_std_connectedtoobd: 'Conectado a OBD',
    txt_global_std_emailorusername: 'Correo electrónico o nombre de usuario',
    txt_global_std_documentsandpolicies: 'Documentos y pólizas',
    txt_global_std_sendreport2: 'Enviar reporte',
    txt_global_std_skillslevel7d: 'Habilidades nivel 7D',
    txt_global_std_login2: 'Acceso',
    txt_global_std_MSIG_Onboarding8: '2. Siga los pasos de la aplicación.',
    txt_global_std_privacysettingssaved: 'Configuración de privacidad guardada',
    'txt_global_std_Al-KhelaifiDescription': 'Abdulla participa en la mayoría de los eventos de automovilismo en Qatar y tiene varios campeonatos en diferentes tipos de carreras como National Drag, Qatar Mile, National Sprint y QTCC.',
    txt_global_std_temporarypassword: 'Contraseña temporal',
    txt_global_std_clickthelinkbelowtoconfirmemail: 'Haga clic en el enlace a continuación para confirmar su correo electrónico y terminar de crear su cuenta Enerfy Global.',
    txt_global_std_lendmycar: 'prestar mi coche',
    txt_global_std_ifyourcardoesnthavebluetooth: 'Si tu coche no tiene bluetooth',
    txt_global_std_tripanalytics: 'Análisis de viaje',
    txt_global_std_getstartedfolks: '¡Empiecen amigos!',
    txt_global_std_takecareofinjury: 'Cuidate de las lesiones',
    txt_global_std_drivenkmperyear: 'Km recorridos al año',
    txt_global_std_sos: 'llamada de socorro',
    txt_global_std_showmodal: 'Mostrar modal',
    txt_global_std_hiheresmylocation: 'Hola aqui esta mi ubicacion:',
    txt_global_std_pushnotdrivenforesometimebody2: 'Asegúrese de conectarse la próxima vez que conduzca.',
    txt_global_std_p2pgotocarsharing: 'Ir a coche compartido',
    txt_global_std_keeponpracticingandyoucangetabetterprice: 'Sigue practicando y podrás conseguir un mejor precio en tu seguro.',
    txt_global_std_distancekm2: 'Distancia/km',
    txt_global_std_dontallow: 'no permitas',
    txt_global_std_insurancecontactdetails: 'Datos de contacto del seguro',
    txt_global_std_agreementnumber: 'Numero de acuerdo',
    txt_global_std_warikan_onboarding7: '3. Ingrese la siguiente información para crear una cuenta\n',
    txt_global_std_dennetur: 'Este viaje',
    txt_global_std_successfullypublishedlanguage: 'Idioma publicado con éxito',
    txt_global_std_registeryouraccount: 'Registra tu cuenta',
    txt_global_std_countryroadroundaboutDESC: 'El conductor del coche azul ajusta su velocidad antes de entrar en la rotonda.\n' +
      'El conductor del coche rojo no ajusta su velocidad lo suficiente para entrar en la rotonda sin problemas',
    txt_global_std_p2ppushtitletimetosplitcost: 'Es hora de dividir los costos',
    txt_global_std_period: 'Período',
    txt_global_std_MSIG_Onboarding11: 'Conduce con seguridad,',
    txt_global_std_totaldistance: 'Distancia',
    txt_global_std_usersuccessfullyremoved: 'Usuario eliminado con éxito',
    txt_global_std_eventualinsurancebonus: 'bono de seguro eventual\n',
    txt_global_std_activatebluetoothandconnect: '3. Activa el bluetooth y conecta el lector OBD',
    txt_global_std_locationservicetroubleshooting: 'Solución de problemas del servicio de ubicación',
    txt_global_std_vehicleidentification: 'Identificación del vehículo',
    txt_global_std_dsadsadsadsa: 'papásasd',
    txt_global_std_phonenumber: 'Número de teléfono',
    txt_global_std_ayessompoacceptsdriverswhoarelicensedinothercountries: 'R: Sí, Sompo acepta conductores con licencia de otros países.',
    txt_global_std_somethingwentwrongwhenattemptingtoredeempointspleasetryagainlater: 'Algo salió mal al intentar canjear puntos, intente nuevamente más tarde',
    txt_global_std_1startyourcarsengine: '1. Arranque el motor de su automóvil.',
    txt_global_std_moreoptions: 'Mas opciones',
    txt_global_std_minutem: 'metro',
    txt_global_std_learnmorehere: 'Aprende más aquí',
    txt_global_std_iaccepttheusertermslinkandconditionsprivacypolicylink: 'Acepto {userTermsLink} y {privacyPolicyLink}',
    txt_global_std_messagebody: 'Cuerpo del mensaje',
    txt_global_std_safedriver: 'Conductor seguro',
    txt_global_std_optionnotavailableyet: '¡Esta opción aún no está disponible!',
    txt_global_std_insuredvehicles: 'vehículos asegurados',
    txt_global_std_VilliersInterests: 'Automovilismo, carreras todo terreno, carreras de circuito',
    txt_global_std_reviewenerfy: 'Revisar',
    txt_global_std_downloadqrcode: 'Descargar Código QR',
    txt_global_std_nocountryselected: 'Ningún país seleccionado',
    txt_global_std_MarklundMileage: 'Me encanta conducir la ruta a casa. Cuando viajas mucho, realmente aprecias estar en casa y la sensación de estar cada vez más cerca es realmente satisfactoria. Vivo en el norte de Suecia, donde las carreteras a veces están vacías y donde el paisaje de muchos bosques y naturaleza me tranquiliza y me hace feliz.\n',
    txt_global_std_clickheretobuyfiasdc: 'Haga clic aquí para comprar FIA SDC',
    txt_global_std_yesyoucanswitchyourinsuranceanytimemostinsurancecompaniesdonothaveanearlyterminationpenaltyyouwillneedtoinquirewithyourcurrentinsurancecarrier: 'Sí, puede cambiar su seguro en cualquier momento. La mayoría de las compañías de seguros no tienen una multa por terminación anticipada. Deberá consultar con su compañía de seguros actual.',
    txt_global_std_p2pskip: 'Saltar',
    txt_global_std_primarybluetoothid: 'ID de bluetooth principal',
    txt_global_std_nofinishedchallenges: 'Sin desafíos terminados',
    txt_global_std_selectcountrycode: 'Seleccionar código de país',
    txt_global_std_youbooked10timesinonemonthyouhaveearned2500points: 'Has ganado 2.500 puntos por hacer 10 reservas en un mes.',
    txt_global_std_typeyourcodefrominsuranceletter: 'Escriba su código de la carta de bienvenida del seguro',
    txt_global_std_wecantfindanydrives: '¡No podemos encontrar ninguna unidad!',
    txt_global_std_thiskeydoesnothaveatranslationinthislanguage: 'esta clave no tiene traducción en este idioma',
    txt_global_std_documentandpolicyes: 'Documentos y pólizas',
    txt_global_std_loyaltypoints: 'Puntos de lealtad',
    txt_global_std_couldnotconnect: 'No podía conectar',
    txt_global_std_countmonths: '{contar} meses',
    txt_global_std_closestyou: 'más cerca de ti',
    txt_global_std_viewcost: 'Ver costo',
    txt_global_std_theinsurancehasbeenactivated: 'El seguro ha sido activado.',
    txt_global_std_createnewtextmessage: 'Crear nuevo mensaje de texto',
    txt_global_std_tomasengemusic: 'No escucho nada en el coche porque me encanta el silencio mientras conduzco, como en un coche de carreras.',
    txt_global_std_cimage5d: 'Use una buena técnica de colina; mantenga el gas constante, pierda velocidad cuesta arriba, ruede cuesta abajo.',
    txt_global_std_smsclientonboardingpincode: 'Aquí viene su código PIN de {pinlength} dígitos: {pincode}. El código PIN es válido durante {validminutes} minutos.\n' +
      '\n' +
      'Saludos,\n' +
      '{nombre de empresa}',
    txt_global_std_remembertodrivesmart: 'Recuerda conducir con inteligencia.',
    txt_global_std_countryroad4DESC: 'El conductor del automóvil azul reduce la velocidad y se acerca a la curva sin problemas.\n' +
      'El conductor del coche rojo no ajusta su velocidad a la curvatura y se ve obligado a frenar.',
    txt_global_std_p2ppleasecontactsupport: 'por favor, póngase en contacto con el soporte.',
    txt_global_std_MarklundFacts: 'Comenzó a competir internacionalmente en 2011 y desde entonces ha competido en series como la Porsche Carrera Cup Scandinavia, el Campeonato Mundial/Europeo de Rallycross de la FIA y los X-Games.',
    txt_global_std_to_make_a_change_in_your_insurance_policy: 'Para realizar un cambio en su póliza de seguro, inicie sesión en {url} o póngase en contacto con el soporte en {email} o {phone}.',
    txt_global_std_spooring: 'despojo',
    txt_global_std_inviteapersonyouwantotshareinsurancewith: 'Invita a una persona con la que quieras compartir tu seguro.',
    txt_global_std_yourusername: 'Su nombre de usuario',
    txt_global_std_superdriver: '¡súper conductor!',
    txt_global_std__enterthetemporarypasswordsendtoyoubyemail: 'Ingrese la contraseña temporal que se le envió por correo electrónico.',
    txt_global_std_kintofaq3a: '¡Gana su estado de lealtad acumulando puntos! Obtienes puntos de los diferentes logros disponibles. ¡Los logros se otorgan a nuestros leales y seguros conductores de Kinto! Obtenga más información sobre lo que se necesita en la sección "Logros".',
    txt_global_std_usethistemplateasabadgeinyourappcustomizeitinthenextstep: 'Utilice esta plantilla como insignia en su aplicación. Personalízalo en el siguiente paso.',
    txt_global_std_TomczykBesttip: 'Concéntrese en su estilo de conducción y conduzca "a la defensiva".',
    txt_global_std_writeanewpassword: 'Escribe una nueva contraseña',
    txt_global_std_userhasnogroups: 'El usuario no tiene grupos',
    txt_global_std_nextqualificationforthegrandfinalindecemberstarts: 'Arranca la próxima clasificación para la Gran Final de diciembre',
    txt_global_std_paddonQualification: 'Texto de calificación de prueba para Paddon',
    txt_global_std_4th: '4:º',
    txt_global_std_p2pchatwithyourfriends: 'Chatea con tus amigos',
    txt_global_std_clickthebuttonbelowtoresetyourenerfyglobalpassword: 'Haga clic en el botón de abajo para restablecer su contraseña de Enerfy Global.',
    txt_global_std_lostconnectionsmall: 'arrancar el motor para continuar',
    txt_global_std_enterdetails: 'Ingrese los detalles',
    txt_global_std_entergender: 'Ingrese el género',
    txt_global_std_makesuretheobdreaderisreadytobepaired: 'Asegúrese de que el lector OBD esté listo para emparejarse. Parpadeará en azul cuando esté listo.',
    txt_global_std_yourpolicyshouldcontaineight8numbers: 'Su póliza debe contener ocho (8) números.',
    txt_global_std_step2: 'Paso 2',
    txt_global_std_FiaPassword_Text1: '¡Hola!',
    txt_global_std_v2score: 'Puntaje',
    txt_global_std_youraveragescoreis123: 'Su puntaje promedio es',
    txt_global_std_saveinformation: 'Guardar información',
    txt_global_std_conclusion: 'Conclusión',
    txt_global_std_p2pspecifyprice: 'Especificar precio',
    txt_global_std_p2pyouhaveanexpensetoapprove: 'Tienes un gasto que aprobar',
    txt_global_std_redirectingyou: 'Redirigiendote...',
    txt_global_std_developerpage: 'Página del desarrollador',
    txt_global_std_p2pspecifyegfuel: 'Especifique (por ejemplo, combustible)',
    txt_global_std_p2ptotaltopayto: 'Total a pagar a',
    txt_global_std_ayesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforcovercubeinsurance: 'R: Sí, puede descargar la aplicación y elegir la opción "probar la aplicación". Podrá conducir con la aplicación y ver cuánto podría ahorrar si se inscribiera en el seguro Covercube.',
    txt_global_std_sendinvitation: 'Enviar invitacion',
    txt_global_std_2makesureyourcarsbluetoothisonorthatyourbluetoothbeaconisconnected: '2. Asegúrese de que el Bluetooth de su automóvil esté encendido o que su baliza bluetooth esté conectada.',
    txt_global_std_p2premovefriend: 'Eliminar amigo',
    txt_global_std_noaccountsignuphere: '¿Sin cuenta? Registrate aquí',
    txt_global_std_p2ppayments: 'Pagos',
    txt_global_std_internalservererror: 'Error de servidor interno',
    txt_global_std_thisyear: 'Este año',
    txt_global_std_costforspeedover80: 'Costo por velocidad superior a {speed}',
    txt_global_std_timeframes: 'Marcos de tiempo',
    txt_global_std_p2ppleaseenteratemporarypassword2: 'Por favor ingrese una contraseña temporal.',
    txt_global_std_TidemandQuote: 'Siempre disfruto de un buen desafío, especialmente un desafío que tiene un propósito mayor. Conducir de forma inteligente y responsable es algo que nos concierne a todos y, al mejorar nuestra conducción ecológica en nuestra vida cotidiana, estamos invirtiendo en nuestro futuro. ¡Estoy adentro!',
    txt_global_std_locateyourobdsocket: '1. Localice su enchufe OBD',
    txt_global_std_noconnectiontomapserver: 'Sin conexión con el servidor de mapas',
    txt_global_std_hiwelcometoappname: '¡Hola! ¡Bienvenido a {appName}!',
    txt_global_std_unitskmmi: 'Unidades km/mi',
    txt_global_std_warikan_onboarding3_2: 'Tenga en cuenta que la contraseña temporal es válida durante 72 horas.\n' +
      'Después del vencimiento, vuelva a emitir otra contraseña temporal haciendo clic en el botón "Reenviar contraseña temporal" en la aplicación.\n' +
      '\n' +
      '*Esta dirección de correo electrónico es solo para fines de envío. No podremos responder a ninguna respuesta, así que dirija cualquier consulta a la siguiente dirección.',
    txt_global_std_pushnotification: 'Notificación de inserción',
    txt_global_std_willigetnotifiedififorgottostart: '¿Me notificarán si olvido iniciar la aplicación Enerfy?',
    txt_global_std_backtolist: 'volver a la lista',
    txt_global_std_startdrivingtogetascore: '¡Empieza a conducir para conseguir una puntuación!',
    txt_global_std_enerfyanalytics2: 'Análisis energético',
    txt_global_std_p2ppushbodyexpensesthismonthquestion: 'Asegúrese de agregar sus gastos para {monthname}.',
    txt_global_std_totalthismonth: 'totales este mes',
    txt_global_std_youcannotsetafuturedate: 'No puede establecer una fecha futura',
    txt_global_std_myprofile: 'Mi perfil',
    txt_global_std_achievementsavedmessage: '¡El logro se ha guardado!',
    txt_global_std_export2: 'Exportar',
    txt_global_std_timelocationaretheonlymandatoryfields: 'La hora y la ubicación son los únicos campos obligatorios.',
    txt_global_std_daydreamingormindwanderingnew: 'Soñar despierto o divagar',
    txt_global_std_stardriver: 'Conductor estrella',
    txt_global_std_p2phide: 'Ocultar',
    txt_global_std_riskydecisionwithintripsbetweendays: 'Decisión arriesgada dentro de los viajes y entre días',
    txt_global_std_connecttoyourcar: 'Conéctate a tu coche',
    'txt_global_std_Al-KuwariFacts': 'Como copiloto, Nasser ganó el segundo lugar en 2016 FIA Cross Country Co-piloto, el primero en 2017 y 2018 MERC 2 –copiloto, 9 veces Campeonatos de Qatar como copiloto y 4 veces campeón en Campeonatos de Kuwait.',
    txt_global_std_averagescoreofparticipants: 'Puntuación media de los participantes',
    txt_global_std_createaccountlater: 'Crear cuenta más tarde',
    txt_global_std_p2pamount: 'Monto',
    txt_global_std_KleinschmidtInterests: 'Deportes, informática, vuelo.',
    txt_global_std_drivendistanceperperiod: 'Distancia recorrida por periodo',
    txt_global_all_tuesday: 'martes',
    txt_global_std_p2pleavethisgroupchatquestion: '¿Abandonar este chat grupal?',
    txt_global_std_90days: '90 dias',
    txt_global_std_co2saver: 'Ahorro de CO₂',
    txt_global_std_25km: '25 kilometros',
    txt_global_std_tipcityparking2DESC: 'El conductor del automóvil azul alerta a los automóviles circundantes mediante el uso de señales de giro.\n' +
      'El conductor del automóvil rojo no usa las señales de giro antes de estacionarse en paralelo.',
    txt_global_std_inviteafriendyouallwillgetareward: 'invita a un amigo, todos obtendrán una recompensa',
    txt_global_std_shortcuts: 'Atajos',
    txt_global_std_toprovideyouwithadrivingscoreandstatsaboutyourdrivingappnameneedsaccesstolocationservices: 'Para proporcionarle una puntuación de conducción y estadísticas sobre su forma de conducir, {appname} necesita acceder a los servicios de ubicación.',
    txt_global_std_poor: 'Pobre',
    txt_global_std_after6monthsyouwillgetarenewalofferbasedonyourdrivinguntilthenyoucankeepimprovingyourdrivingfollowyourprogressandseeyourestimatedfuturesavingshere: 'Después de 6 meses, recibirá una oferta de renovación basada en su forma de conducir. Hasta entonces puedes seguir mejorando tu conducción. Siga su progreso y vea sus ahorros futuros estimados aquí.',
    txt_global_std_thecruisebeliever: 'El creyente de los cruceros',
    txt_global_std_cimage6d: 'Evite adelantar a otros vehículos.',
    txt_global_std_KleinschmidtShortDescription: 'Campeón Rally Dakar 2001',
    txt_global_std_senddocumentto2: 'Enviar documento a:',
    txt_global_std_saveunits: 'Guardar unidades',
    txt_global_std_perusermonth: 'por usuario/mes',
    txt_global_std_nameheader: 'título del logro',
    txt_global_std_changeyouraddress: 'cambia tu dirección',
    txt_global_std_translationstoapprove: 'Traducciones para aprobar',
    txt_global_std_head2head: '¡Has sido desafiado en Head to Head!',
    txt_global_std_MSIG_Password3: 'es',
    txt_global_std_privateonlyformyorg: 'Privado (solo para mi organización)',
    txt_global_std_settimeperiodforhowlongyourdatawillbestored: 'Establezca el período de tiempo durante el cual se almacenarán sus datos',
    txt_global_std_404notfound: '¡Recurso no encontrado!',
    txt_global_std_ayesyoumustadviseyouragentthatyourvehicleisusedforaridesharingcompanyandtheywillapplytheridesharingendorsementtoyourpolicy: 'R: Sí, debe informar a su agente que su vehículo se utiliza para una empresa de viajes compartidos y ellos aplicarán el endoso de viajes compartidos a su póliza.',
    txt_global_std_thelinkwillbeavailableforxdays: 'El enlace estará disponible durante {x} días.',
    txt_global_std_200ok: '200 - Bien',
    txt_global_std_trygdrivehelpsyouwithmotivationandtipstodrivebettersaferandmoreecofriendly: 'Sidekick te ayuda con la motivación y los consejos para conducir mejor, de forma más segura y más ecológica.',
    txt_global_std_earnpointsbydrivingsafe: 'Gana puntos conduciendo seguro.',
    txt_global_std_privacy: 'Privacidad',
    txt_global_std_riskscore: 'Puntuación de riesgo',
    txt_global_std_addaphoto: 'Agregar una foto',
    txt_global_std_p2ppushbodysetpriceforbookingto: '{name} fijó el precio de la reserva a: {currprice}',
    txt_global_std_easyas123: 'Fácil como 1-2-3',
    txt_global_std_actualtranslation: 'Traducción real',
    txt_global_std_androidallowallthetime: '…pero para obtener las estadísticas correctas de su conducción, debe ir a la configuración de la aplicación: permisos y elegir <b>Permitir todo el tiempo</b>',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint3: 'Perspectivas de riesgo procesables para decisiones comerciales más inteligentes',
    txt_global_std_heattab: 'Calor',
    txt_global_std_createnewgroup: 'Crear nuevo grupo',
    txt_global_std_cimage14d: 'Use cuesta abajo para ganar mayor velocidad.',
    txt_global_std_xkm: '{km} kilómetros',
    txt_global_std_pigsavedlocked: 'Por cada 50 coronas obtienes este logro',
    txt_global_std_created: 'creado',
    txt_global_std_TaxInvoice14: 'ESTE ES UN DOCUMENTO GENERADO POR COMPUTADORA, NO SE REQUIERE FIRMA',
    txt_global_std_requiredformatcolumnastext: 'Obligatorio, formato de columna como texto',
    txt_global_std_therulesforthecompanychallengeareasfollowingbrtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsbrdriveminimum1kmduringaheatinordertoqualifyforthechallengebrthesamegeographicalroutewillbescoredamaximumof10timesinoneday: 'Las reglas para el desafío de la empresa son las siguientes:\n' +
      '<br>• La puntuación del conductor individual se calcula en un promedio basado en el 10% de los mejores viajes.\n' +
      '<br>• Maneja un mínimo de 1 km durante una manga para calificar para el desafío.\n' +
      '<br>• La misma ruta geográfica se puntuará un máximo de 10 veces en un día.',
    txt_global_std_drivingtogetherismorefun: 'Conducir juntos es más divertido',
    txt_global_std_currentpassword: 'Contraseña actual',
    txt_global_std_unavailable: 'Indisponible',
    'txt_global_std_emailalreadyusedpleasetrywithanotheremail.': 'Correo electronico ya fue usado. Intente con otro correo electrónico.',
    txt_global_std_loadingpleasewait: 'Cargando por favor espere',
    txt_global_std_availablefromlevel: 'Disponible desde el nivel',
    txt_global_std_p2ptobesettled: 'Asentarse:',
    txt_global_std_makeyourselection: 'Por favor haga su selección a continuación:',
    txt_global_std_focusfeedbacktips: '<b>Mantén la vista en la carretera</b> y concéntrate por completo en la conducción. A veces es fácil distraerse con el entorno, los teléfonos, las personas en el coche, etc.<br><br>\n' +
      '<b>Conducir cansado o estresado</b> puede afectar el enfoque y exponerlo a un mayor riesgo.<br><br>\n' +
      '<b>Nos preocupamos por ti.</b>',
    txt_global_std_SulayemWhyjoin: 'Como 14 veces campeón de automovilismo de la FIA, me apasiona la conducción y los deportes de motor. intrigado por los avances tecnológicos en la industria del automóvil que estamos experimentando actualmente. Necesitamos adoptar estas tecnologías y ser parte de la solución para impactar positivamente en nuestro medio ambiente.',
    txt_global_std_great: 'Excelente',
    txt_global_std_TidemandBesttip: 'Para que la conducción inteligente sea lo más fácil posible, comience por no estresarse nunca al volante y esté siempre atento a lo que sucede a su alrededor y adapte la velocidad a tiempo.',
    txt_global_std_enterpasswordtodeleteuseraccount: 'Ingrese la contraseña para eliminar la cuenta de usuario',
    txt_global_std_p2pfromthismonth: 'Desde este mes',
    txt_global_std_dolinkonthedamage: 'Haz {link} sobre el daño',
    txt_global_std_rwmnoeligibletripstext: 'Registre su viaje compartido dentro de 1 día después de que finalice el viaje.',
    txt_global_std_yourchangesweresaved: 'Tus cambios fueron guardados',
    txt_global_std_pickyourteamleader: 'Elige a tu líder de equipo',
    txt_global_std_mar: 'Mar',
    txt_global_std_averagemonthsmonths: 'Promedio de {meses} meses',
    txt_global_std_doesagentacceptdriversthatarenotlicensedincountry: '¿{agent} acepta conductores que no tienen licencia en {country}?',
    txt_global_std_majorDescription: 'Anders enas gurkans hämnd 19',
    txt_global_std_p2pnamesentalendrequesttoname2: '{name} envió una solicitud de préstamo a {name2}',
    txt_global_std_subject: 'Tema',
    txt_global_std_rank: 'Rango',
    txt_global_std_OgierWhyjoin: 'Próximamente, en breve, pronto...',
    txt_global_std_teamleaderboard: 'Tabla de clasificación del equipo',
    txt_global_std_hereyoucanseehowmuchyouwouldhavegottenbackifyouhadhadatrygdrivecarinsurancethebonusiscalculatedbasedontheannualinsurancepriceyourkilometersdrivenperyearandthecartypebasedonthenumberplateyouhaveprovidedifyouhavenotstatedacurrentinsurancepricenumberofkilometersperyearandanumberplatethebonusiscalculatedonthebasisofanannualinsurancepriceofdkk5000andanannualmileageof20000km: 'Aquí puedes ver cuánto habrías recuperado si hubieras tenido un seguro de coche de Tryg Drive. La bonificación se calcula en función del precio anual del seguro, sus kilómetros recorridos por. año y el tipo de coche en función de la matrícula que haya proporcionado. Si no ha indicado un precio de seguro actual, número de kilómetros por. año y matrícula, la bonificación se calcula sobre la base de un precio de seguro anual de 5.000 coronas danesas. y un kilometraje anual de 20.000 km.\n',
    txt_global_std_sendchallenge: 'Enviar desafío',
    txt_global_std_stats2: 'Estadísticas',
    txt_global_std_p2pnomessages: 'Ningún mensaje',
    txt_global_std_securetheaccidentsite: 'Asegurar el lugar del accidente',
    txt_global_std_cashback: 'Devolución de dinero',
    txt_global_std_selectwhatyouwanttouse: 'Selecciona lo que quieras usar.',
    txt_global_std_p2pwaitingforacceptanceandprice: 'A la espera de aceptación y precio.',
    txt_global_std_MikkelsenBesttip: 'Siempre me aseguro de tener suficiente espacio para los autos delante de mí. Eso siempre te salva de muchas situaciones y te permite conducir de forma más cómoda, inteligente y eficiente.',
    txt_global_std_badgetitleplaceholder: 'Título de la insignia',
    txt_global_std_getsexpenseandextradataofgiventrips: 'Obtiene gastos y datos adicionales de viajes dados',
    txt_global_std_garminconnected: 'garmin conectado',
    txt_global_std_fiasdcisbettertogether: 'FIA SDC es mejor juntos',
    txt_global_std_chooseateamleaderthatyouwanttocompeteforwerecommendoneoftheseteamleadersbasedonyourlocationandgoal: 'Elige un líder de equipo por el que quieras competir. Recomendamos uno de estos líderes de equipo según su ubicación y objetivo.',
    txt_global_std_termsconditions: 'Términos y condiciones',
    txt_global_std_tipcity2DESC: 'El motor del conductor del automóvil azul frena y conduce a través del cruce sin problemas.\n' +
      'El conductor del auto rojo acelera y frena demasiado fuerte antes del cruce.',
    txt_global_std_lighttheme: 'Tema ligero',
    txt_global_std_activationemail: 'Correo electrónico de activación',
    txt_global_std_highcostperkilometer: 'Alto costo por kilómetro.',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimeincreasingspeedunnecessaryharshaccelerationisbadfortheenvironmentandforroadsafety: 'En este tipo de viaje, el campeón ecológico promedio pasó más tiempo aumentando la velocidad. La aceleración brusca innecesaria es mala para el medio ambiente y para la seguridad vial.',
    txt_global_std_savings: 'Ahorros',
    txt_global_std_opensettingsandmakesureyourbluetoothison: 'Abra la configuración y asegúrese de que su Bluetooth esté activado.',
    txt_global_std_navigatebetweenmodules: 'Navegar entre módulos',
    txt_global_std_fiasdcnewsletter: 'BOLETÍN DE LA COSUDE DE LA FIA',
    txt_global_std_emailclaims: 'Reclamos por correo electrónico',
    txt_global_std_connectingto: 'Conectado a',
    txt_global_std_mypoints: 'Mis puntos',
    txt_global_std_pressthedrivebuttontogetstarted: 'Pulse el botón de conducción para empezar.',
    txt_global_std_makeaclaim: 'Hacer un reclamo',
    txt_global_std_carsharestats: 'Estadísticas de coches compartidos',
    txt_global_std_failsenddetails: 'Error al enviar los detalles',
    txt_global_std_getshareablelink: 'Obtener enlace para compartir',
    txt_global_std_imagerecognitioncompleted: 'Reconocimiento de imagen completado',
    txt_global_std_startdrive2: 'Iniciar unidad',
    txt_global_std_cimage7d: 'Acelere rápidamente a la velocidad de crucero cuando el tráfico lo permita.',
    txt_global_std_unknownracedriverShortDescription: 'Ninguna',
    'txt_global_std_Al-KhelaifiMusic': 'Nada específico. Música tranquila en la radio. Nada que distraiga.',
    txt_global_std_p2ppushbodyhasacceptedthebooking: '{name} ha aceptado la reserva.',
    txt_global_std_teamposition: 'Posición del equipo:',
    txt_global_std_sendallpersonaldatatoyouremail: '¿Enviar todos los datos personales a su correo electrónico?',
    txt_global_std_belowyoucanseemoredetailsaboutthistrip: 'A continuación puedes ver más detalles sobre este viaje.',
    txt_global_std_applications: 'Aplicaciones',
    txt_global_std_examplemessagepush: 'Ha recibido una solicitud de ejemplo de John Doe. ¡Abre la aplicación para responder ahora!',
    txt_global_std_topdriversnow: 'Los mejores conductores ahora',
    txt_global_std_nousersfound: 'No se encontraron usuarios, intente recargar la página',
    txt_global_std_yourhavebeeninvitedby: 'Has sido invitado por:',
    txt_global_std_p2pgroupchatsettings: 'Configuración de chat grupal',
    txt_global_std_p2pviewall: 'Ver todo',
    txt_global_std_MarklundQuote: 'Cotización próximamente...',
    txt_global_std_yourposition: 'Tu posición:',
    txt_global_std_notethisinformationwillbevisibletoanyonewhodownloadstheapp: 'Nota: esta información será visible para cualquier persona que descargue la aplicación.',
    txt_global_std_numoftrips: '{numoftrips} viajes',
    txt_global_std_selectyourcarsbluetooth: 'Selecciona el bluetooth de tu coche',
    txt_global_std_otherinformation: 'Otra información',
    txt_global_std_wanttojointhechallenge: '¿Quieres unirte al desafío?',
    txt_global_std_whenyouarereadytodriveyoucanconnect: 'Cuando esté listo para conducir, puede conectarse a su automóvil y comenzar su primer viaje aquí.',
    txt_global_std_both2: 'Ambas cosas',
    txt_global_std_MSIG_Onboarding14: 'Su certificado de seguro está adjunto y protegido con contraseña con su NRIC/FIN/UEN en minúsculas.',
    txt_global_std_ilendthecar: 'presto el auto',
    txt_global_std_applanguage: 'Idioma de la aplicación',
    txt_global_std_searchonname: 'Buscar por nombre',
    txt_global_std_set: 'Establecer',
    txt_global_std_content: 'Contenido',
    txt_global_std_goback: 'Regresa',
    txt_global_std_to: 'A',
    txt_global_std_illegalxrule: 'Palabra ilegal: {palabra}',
    txt_global_std_agentofferselectronicfundstransferfromyourcheckingaccountorrecurringcreditcardpaymentfromyourcreditdebitcardyoucansignupforthesepaymentplanswhenyoustartyourpolicyoranytimeduringyourpolicytermbyloggingintoweburl: '{agent} ofrece transferencias electrónicas de fondos desde su cuenta corriente o pago recurrente con tarjeta de crédito desde su tarjeta de crédito/débito. Puede suscribirse a estos planes de pago cuando inicia su póliza o en cualquier momento durante el plazo de su póliza iniciando sesión en {webUrl}.',
    txt_global_std_resetviewpassword: 'Restablecer y ver contraseña',
    txt_global_std_aagentofferselectronicfundstransferfromyourcheckingaccountorrecurringcreditcardpaymentfromyourcreditdebitcardyoucansignupforthesepaymentplanswhenyoustartyourpolicyoranytimeduringyourpolicytermbyloggingintolink: 'R: {agent} ofrece transferencias electrónicas de fondos desde su cuenta corriente o pago recurrente con tarjeta de crédito desde su tarjeta de crédito/débito. Puede inscribirse en estos planes de pago cuando inicia su póliza o en cualquier momento durante el plazo de su póliza iniciando sesión en {link}.',
    txt_global_std_whathappensnow: '¿Que pasa ahora?',
    txt_global_std_iflocationserviceisoffyourperformancegetworse: 'Si el Servicio de ubicación está DESACTIVADO, su rendimiento empeora.',
    txt_global_std_unlocked: '(Desbloqueado)',
    txt_global_std_seconds: 'Segundos',
    txt_global_std_anemailhasbeensentwithyournewpassword: 'Se ha enviado un correo electrónico con su nueva contraseña.',
    txt_global_std_accidentfaq: 'Preguntas frecuentes sobre accidentes',
    txt_global_std_timeandlocationistheonlymandatoryfield: 'Hora y ubicación es el único campo obligatorio. Cargue fotos de su automóvil dentro de las 24 horas.',
    txt_global_std_riskportfoliotracker_upsell: 'Enerfy Risk Portfolio Tracker: su clave para la excelencia operativa.',
    txt_global_std_vouchercode: 'Código de cupón',
    txt_global_std_getstartedguide: 'Guía de inicio',
    txt_global_std_OgierChamp: 'Ganó 6 veces el WRC y 1 vez el J-WRC',
    txt_global_std_heatsmall: 'Calor',
    txt_global_std_yourmonthwithtrygdrive: '¡Tu mes con TrygDrive!',
    txt_global_std_getspagedlistoftrips: 'Obtiene una lista paginada de viajes',
    txt_global_std_p2phasreceivedyoureditrequest: 'ha recibido su solicitud de edición',
    txt_global_std_startingdrive: 'Iniciando viaje...',
    txt_global_std_addyourbrandcolors: 'Agrega los colores de tu marca',
    txt_global_std_introductionwelcome: 'Bienvenidos',
    txt_global_std_yourquoteformonthofmonth: 'Su cotización para el mes de {month}',
    txt_global_std_parameters: 'Parámetros',
    txt_global_std_openlog: 'Abrir registro',
    txt_global_std_kintofaq6q: '¿No puedo conectarme?',
    txt_global_std_activeachievements: 'Logros activos',
    txt_global_std_dontyouhaveacode: '¿No tienes ningún código?',
    txt_global_std_namemustacceptaninvitationtotheteamfirst: '{name} primero debe aceptar una invitación al equipo.',
    txt_global_std_allowaccesstobluetooth: 'Permitir el acceso a Bluetooth',
    txt_global_std_eco1: 'ecológico',
    txt_global_std_p2phasspecifiedaprice: 'ha especificado un precio',
    txt_global_std_share: 'Cuota',
    txt_global_std_p2pfaq23a: '1. Seleccione "Warikan" en "Menú".\n' +
      '2. Seleccione "Configuración" y seleccione el método de cambio. Puede seleccionar entre "Proporción de uso", "Tiempo de uso", "División equitativa" y "Millas".',
    txt_global_std_p2pfaq15a: 'Esta aplicación no tiene una función para liquidar gastos. Por lo tanto, acuerde y liquide los gastos con la persona directamente y de acuerdo con la ley.',
    txt_global_std_youmayhavetogotobluetoothsettingforbothyourphoneandcar: 'Es posible que deba ir a la configuración de Bluetooth tanto para su teléfono como para su automóvil.',
    txt_global_std_ecofriendweeklylockedkey: 'Conduzca de manera ecológica y ahorre más de {co2value}% CO₂ en una semana para ganar esta insignia.',
    txt_global_std_thisaccountdoesnothaveapaymentplanaddpaymentplantoaccesspaymentspage: 'Esta cuenta no tiene un plan de pago. Agregue el plan de pago para acceder a la página de pagos.',
    txt_global_std_getupandrunninginnotimewithourwhitelabelappsolution: 'Póngase en marcha en poco tiempo con nuestra solución de aplicación de marca blanca.',
    txt_global_std_PolicySchedule27: 'SGD3,500.00 LAS SECCIONES I Y II SE IMPONEN POR SEPARADO A AQUELLOS CONDUCTORES QUE TIENEN MENOS DE 22 AÑOS Y/O QUE TIENEN MENOS DE 2 AÑOS DE EXPERIENCIA DE CONDUCCIÓN.',
    txt_global_std_yesiamsure: '¡Sí, estoy seguro!',
    txt_global_std_youhavebeeninvitedby: 'Has sido invitado por:',
    txt_global_std_p2pinvoiced: 'Facturado',
    txt_global_std_redeemfriendscode: 'Canjear código de amigos',
    txt_global_std_advancedadmin_upsell_pitch_bulletpoint1: 'Información y apoyo para informar sus decisiones',
    txt_global_std_drivetime: 'Hora de manejar',
    txt_global_std_showalldrives: 'Mostrar todas las unidades',
    txt_global_std_drivesafeandpaylessforyourfiat500subscription: 'Conduce seguro y paga menos por tu suscripción Fiat 500.',
    txt_global_std_kintosmsmailtext: '¡Empieza a conducir más seguro conmigo! Siga el enlace a continuación y comience a conducir con Kinto.',
    txt_global_std_photosofyourcarscurrentcondition: 'Las fotos de la condición actual de su automóvil pueden ser buenas para tenerlas a mano si algo le sucede a su automóvil.',
    txt_global_std_logsaved: 'Registro guardado',
    txt_global_std_createanaccounttostartyour30dayfreetrialnocreditcardrequired: 'Cree una cuenta para comenzar su prueba gratuita de 30 días. No se requiere tarjeta de crédito.',
    txt_global_std_p2pfaq6q: '¿Cómo salgo de un chat grupal?',
    txt_global_std_p2pfaq17a: 'Debe ser invitado por un propietario de Kinto para agregarlo como amigo. Pídele a tu amigo que te envíe un enlace de invitación.',
    txt_global_std_ifyouareinneedofroadsideassistanceplease: 'Si necesita asistencia en la carretera, por favor',
    txt_global_std_wouldntitbenicetobeabletosaythatasacompanyyoumakenoticabledifferenceinloweringtheimpactvehiclesanddrivinghaveontheworldstartyourownchallengeandmakethatimpacttoday: '¿No sería bueno poder decir que, como empresa, hacen una diferencia notable en la reducción del impacto que los vehículos y la conducción tienen en el mundo? Comience su propio desafío y haga ese impacto, ¡hoy!',
    txt_global_std_warikan_onboarding16: 'KINTO Co., Ltd. | 4-8-18 Meieki, Nakamura-ku, ciudad de Nagoya Edificio Nagoya Mitsui Edificio norte 14F',
    txt_global_std_MarklundBesttip: 'En primer lugar, planifique su conducción y asegúrese de tener suficiente tiempo, el estrés nunca es una forma inteligente de conducir. Mantenga la vista al frente y esté atento a lo que sucede delante de usted, ¿quizás alguien frena? Entonces, ¿quizás tengas tiempo para levantar el acelerador en lugar de frenar instantáneamente y con fuerza? Sea paciente y adapte su estilo de conducción y velocidad a circunstancias como el tráfico y las condiciones meteorológicas. Sé que es difícil, pero te convierte en un conductor más inteligente.\n',
    txt_global_std_p2pnocarsavailable: 'No hay coches disponibles',
    txt_global_std_selectyourcarinthelistsompo: '3. Selecciona tu coche en la lista:',
    txt_global_std_passwordchangefailed: 'Cambio de contraseña falló',
    txt_global_std_ihaveanaccount: 'tengo una cuenta',
    txt_global_std_notnowlater: 'no ahora',
    txt_global_std_yourpasswordshouldcontainatleast5charactersandmustbeenteredcorrectlytwice: 'Su contraseña debe contener al menos 5 caracteres y debe ingresarse correctamente dos veces.',
    txt_global_std_rwmnodrivestext: 'Debe conectarse a su automóvil y conducir antes de poder usar esta función. Regrese para registrar su viaje después de haber compartido un viaje con amigos.',
    txt_global_std_gooddriving2: '¡Buena conducción!',
    txt_global_std_p2ptoconfirmbooking: ' para confirmar la reserva',
    txt_global_std_createcommunicationthatreachesout: 'Crea una comunicación que llegue',
    txt_global_std_signedinasusername: 'Registrado como\n{nombre de usuario}',
    txt_global_std_connectyourobdreader: 'Conecte su lector OBD a su automóvil.',
    txt_global_std_documentanyscratchesordingsonyourcar: 'Documente cualquier rasguño o abolladura en su automóvil.',
    txt_global_std_OgierBesttip: 'Próximamente, en breve, pronto...',
    txt_global_std_calmdriversoftengetbetterscore: 'Los conductores tranquilos suelen obtener una mejor puntuación',
    txt_global_std_thepasswordcontainsforbiddencharacters6062orspace: "La contraseña contiene caracteres prohibidos: ' & / &#60; &#62; , . = o espacio",
    txt_global_std_owner2: 'Dueño',
    txt_global_std_theapikeynamecreatedcreationdatewillbedeletedimmediatelyandpermanentlyoncedeleteditcannolongerbeusertomakeapirequests: 'La clave API {name}, creada {creationdate}, se eliminará de forma inmediata y permanente. Una vez eliminado, ya no puede ser usuario para realizar solicitudes de API.',
    txt_global_std_byconnectingtoyourcarwithyoursmartphone: 'Al conectarse a su automóvil con su teléfono inteligente y la aplicación Enerfy, nuestra base de datos de IA analizará su manejo, donde se comparará su manejo con más de 500 millones de perfiles de conducción que validarán qué tan seguro y respetuoso con el medio ambiente conduce.',
    txt_global_std_publishapp: 'Publicar aplicación',
    txt_global_std_emergencyroadsideassistance: 'Asistencia de emergencia en carretera',
    txt_global_std_avaragescoreofparticipants: 'Puntuación media de los participantes',
    txt_global_std_invite: 'Invitar',
    txt_global_std_PolicySchedule18: 'PDP y SLEC',
    txt_global_std_ensammenligningmeddennekreturogetgennemsnitafalleandrebilisterskrselsadfrd: 'Sus estadísticas de manejo para este viaje en comparación con el conductor promedio.',
    txt_global_std_mine: 'Mío',
    txt_global_std_wehavesentanewverificationcodetophoneno: 'Hemos enviado un nuevo código de verificación a {phoneNo}.',
    txt_global_std_youneedtoacceptallofthepermissionsinoderfortheapp: 'Debe aceptar todos los permisos para que la aplicación funcione correctamente',
    txt_global_std_earngreatrewards: '- ¡Gana grandes recompensas!',
    txt_global_std_currentstatsper: 'Estadísticas actuales por',
    txt_global_std_signupletter: '¡Estás en la lista!',
    txt_global_std_joinedparticipants: 'Participantes unidos',
    txt_global_std_all: 'Todos',
    txt_global_std_ttest96: 'esto es una prueba',
    txt_global_std_earn100pointswhenyoupurchasecdw5timestoyourbooking: 'Gane 100 puntos cuando haga 5 reservas y opte por CDW. Este logro se puede obtener más de una vez.',
    txt_global_std_anticipate: 'Anticipar',
    txt_global_std_p2pyouhavebookingswithoutregistereddistance: 'Tienes reservas sin distancia registrada.',
    txt_global_std_ifyourecruitafriendbothyouandyourfriendget250kronerinthepiggybank: 'Si recluta a un amigo, tanto usted como su amigo obtienen 250 coronas en la alcancía.',
    txt_global_std_PolicySchedule16: '2, 25, 57, 72, 89C, E28, E29, E33, E45, E48J, E6,',
    txt_global_std_p2pcancelbooking: 'Cancelar reserva',
    txt_global_std_upload123: 'Subir',
    txt_global_std_qhowdoisignupforagentinsurance: 'P: ¿Cómo me inscribo en el seguro {agent}?',
    txt_global_std_country: 'País',
    txt_global_std_p2pdiscount: 'descuento',
    txt_global_std_yourpassword: 'Tu contraseña',
    txt_global_std_timeandplace2: 'Tiempo y lugar',
    txt_global_std_starburtslockedkey: 'Termina un viaje en el nivel 4.4 o superior para desbloquear este logro y ganar 300 puntos para tu billetera Kinto.',
    txt_global_std_MSIG_InsuranceLetter39: 'NIL PARA CONDUCTORES ASEGURADOS Y AUTORIZADOS',
    txt_global_std_machinedamage123: 'Daños a la máquina',
    txt_global_std_heresyourauthenticationcode: 'Aquí está su código de autenticación:',
    txt_global_std_editpermissionsfor: 'Editar permisos para',
    txt_global_std_fromthetoprightappsiconyoucannavigatebetweendifferentmoduleswithintheworkspace: 'Desde el ícono de aplicaciones superior derecho, puede navegar entre diferentes módulos dentro del espacio de trabajo',
    txt_global_std_therulesforthecompanychallengeareasfollowingtheindividualdriverscoreiscalculatedonanaveragebasedonthetop10tripsdriveminimum1kmduringaheatinordertoqualifyforthechallengethesamegeographicalroutewillbescoredamaximumof10timesinoneday: 'Las reglas para el desafío de la empresa son las siguientes:\n' +
      '• La puntuación del conductor individual se calcula en un promedio basado en el 10% de los mejores viajes.\n' +
      '• Conduce un mínimo de 1 km durante una eliminatoria para calificar para el desafío.\n' +
      '• Se puntuará un mismo recorrido geográfico un máximo de 10 veces en un día.',
    txt_global_std_somethingwhenwrongwithyourpasswordpleasetryanotherpassword: 'Algo cuando está mal con su contraseña. Intente con otra contraseña.',
    txt_global_std_maintainingintendedspeedisutmostimportantforfueleconomymindwanderingandlackoffocusoftencausesspeedvariationupanddowncausingunnecessaryaccelerationshenceincreasesfuelconsumption: 'Mantener la velocidad prevista es sumamente importante para el ahorro de combustible. La mente errante y la falta de concentración a menudo provocan variaciones de velocidad hacia arriba y hacia abajo, lo que genera aceleraciones innecesarias y, por lo tanto, aumenta el consumo de combustible.',
    txt_global_std_singleaccident2: 'Accidente único',
    txt_global_std_reportandtrackyourresults: 'Informe y realice un seguimiento de sus resultados',
    txt_global_std_MarklundInterests: 'Motos de nieve, Naturaleza/vida salvaje, Familia y amigos',
    txt_global_std_theactivationcodehastobe36characters: 'El código de activación debe tener 36 caracteres.',
    'txt_global_std_Al-KhelaifiMileage': 'Umm Bab a la autopista Dukhan (ruta 39). Debido a los cambios de elevación y el paisaje.',
    txt_global_std_p2peditbooking: 'Editar reserva',
    txt_global_std_failedtogettranslations: 'Error al obtener las traducciones',
    txt_global_std_cost2: 'Costo',
    txt_global_std_youwillbemailedyourpersonaldata: 'Se le enviará por correo sus datos personales',
    txt_global_std_hideadvancedsettings: 'Ocultar configuraciones avanzadas',
    txt_global_std_publishtranslations: 'Publicar traducciones',
    txt_global_std_kilometerkm: 'Kilómetro (km)',
    txt_global_std_smartdriverweeklyunlockedkey: 'Para obtener esta insignia, mantenga una puntuación superior a 3,5 durante una semana',
    txt_global_std_ttest95: 'esta es una prueba prueba prueba',
    txt_global_std_HusseinAbdullaInterests: 'Coches, Rallies, Carreras',
    txt_global_std_chataboutabookingorstartagroupchatwithyourinvitedfriends: 'Chatea sobre una reserva o inicia un chat grupal con tus amigos invitados.',
    txt_global_std_unregistereddriving: 'Conducción no registrada',
    txt_global_std_ConfirmFia_Text5: 'Lugar',
    txt_global_std_february: 'Febrero',
    txt_global_std_OrderConfirmation: 'Confirmación de pedido FIA Smart Driving Challenge',
    txt_global_std_thereisnotworkshopstoshow: 'No hay talleres para mostrar.',
    txt_global_std_PolicySchedule31: 'Se aplica un exceso de $ 100.00 para cada reclamo de parabrisas, después de lo cual, la cubierta del parabrisas se restablecerá automáticamente.',
    txt_global_std_noofdrivers: 'Nº de conductores',
    txt_global_std_jointheglobalcommunityreducingco2emission: 'Únase a la comunidad mundial que reduce las emisiones de CO2.',
    txt_global_std_accentcolor: 'Acentuar el color',
    txt_global_std_enteredodometervalue: 'Valor del cuentakilómetros introducido',
    txt_global_std_minutes: 'Minutos',
    txt_global_std_youralreadyboundeddevices: 'Tus dispositivos ya usados.',
    txt_global_std_permissions: 'permisos',
    txt_global_CoreTemplate_cost: 'Costo',
    txt_global_std_300pointTripAchievementlocked: 'Termina un viaje en {scoretype} {score} o mejor para desbloquear este logro y ganar 300 puntos.',
    txt_global_std_everythingworkedasexpected: 'Todo funcionó como se esperaba.',
    txt_global_std_about: 'Sobre',
    txt_global_std_yourfriends: 'Tus amigos',
    txt_global_std_ontheleaderboardpageyoucamseechallengesoverview: 'En la página de la Tabla de clasificación, puede ver una descripción general de los desafíos.',
    txt_global_std_thetotalscoremeasuringbothyourrisklevelandecodriving: 'La puntuación total que mide tanto su nivel de riesgo como la conducción ecológica.',
    txt_global_std_schedulesend: 'Programar envío',
    txt_global_std_typeofaccident: 'tipo de accidente',
    txt_global_std_upto6000kmmilage: 'Hasta 6000 km.',
    txt_global_std_p2ptogetaninvitationlinkfromakintoownerifyouarehavingtroublewiththelinkpleasecontactsupportandwewillhelpyou: 'Para pedir prestado un automóvil Kinto, debe obtener un enlace de invitación de un propietario de Kinto. Si tiene problemas con el enlace, comuníquese con soporte y lo ayudaremos.',
    txt_global_std_decideyourownview: 'Decide tu propia vista',
    txt_global_std_youmustwriteareason: 'Debes escribir un motivo',
    txt_global_std_passwordisnotvalid: 'La contraseña no es válida.',
    txt_global_std_imageuploadfailed: 'No se pudo cargar la imagen, intente nuevamente',
    txt_global_std_achievementsdesc: 'Involucre a sus usuarios con logros en su aplicación. Los logros son insignias integradas en la aplicación y pueden ser únicas o coleccionables.',
    txt_global_std_notification: 'Notificación',
    txt_global_std_p2pyouhaveselectedmultipledays: 'Ha seleccionado varios días',
    txt_global_std_silver: 'Plata',
    txt_global_std_speedareawithbest: 'Zona de velocidad con mayor potencial de mejora',
    txt_global_std_p2pinvitenewfriend: 'invitar a un nuevo amigo',
    txt_global_std_declined: 'Rechazado',
    txt_global_std_redeemedpoints: 'puntos canjeados',
    txt_global_std_usingtheapp: 'Usando la aplicación',
    txt_global_std_findworkshop: 'encontrar taller',
    txt_global_std_Fia_Receipt_Text7: 'Solicitar ID',
    txt_global_std_kintofaq2a: 'Un conductor seguro es un conductor consciente. La conducción segura se trata de mantenerse 100 % concentrado en la conducción y en el entorno que lo rodea. Manténgase alerta y tenga en cuenta el ritmo del tráfico y otras circunstancias, como la lluvia, etc. AJUSTE SIEMPRE SU VELOCIDAD A LAS CONDICIONES PREDOMINANTES.',
    txt_global_std_errorloggingintoyouraccountpleasetryagain: 'Error al iniciar sesión en su cuenta, inténtelo de nuevo.',
    txt_global_std_qidriveforaridesharingcompanywillyouinsuremyvehicle: 'P: Conduzco para una empresa de viajes compartidos, ¿asegurarán mi vehículo?',
    txt_global_std_yourscore: 'Tu puntuación',
    txt_global_std_leavewithoutsaving: 'Salir sin guardar',
    txt_global_std_PolicySchedule32: 'Firmado por y en representación de la Compañía',
    txt_global_std_pushdriveendlevel4: 'Ahora estás conduciendo arriesgado. Toca aquí para ver qué puedes mejorar.',
    txt_global_std_p2pallcars: 'Todos los carros',
    txt_global_std_gold: 'Oro',
    'txt_global_std_Compare with my': 'comparar con mi',
    txt_global_std_notdrivingthiscar: '¿No conduces este auto?',
    txt_global_std_checkhowitwent: 'Mira cómo te fue',
    txt_global_std_5discountonrenewal: '5% de descuento en la renovación',
    txt_global_std_fix: 'Arreglar',
    txt_global_std_cantfindyourcargoto: '¿No encuentras tu coche? Ir',
    txt_global_std_adduserstogroups: 'Agregar usuarios a grupos',
    txt_global_std_wereyoudriving: '¿Estabas conduciendo?',
    txt_global_std_viewinvoice: 'Mirar la factura',
    txt_global_std_paidaftertheendoftheinsuranceyear: 'Pagado después del final del año de seguro.',
    txt_global_std_forfurtherinstructionspleasechooseyourconnectionmethod: 'Para obtener más instrucciones, elija su método de conexión',
    txt_global_std_details: 'Detalles',
    txt_global_std_notsigneduptryg: '¿Aún no está inscrito en un seguro Sidekick?',
    txt_global_std_p2pyescancelbooking: 'Sí, cancelar reserva',
    txt_global_std_mmyy: 'MM/AA',
    txt_global_std_orderednewpassword: 'Nueva contraseña solicitada',
    txt_global_std_kintofaq1a: 'No, ¡la aplicación funciona en segundo plano! Asegúrate de tener siempre activados los servicios de ubicación y bluetooth en tu teléfono.',
    txt_global_std_translations: 'Traducciones',
    txt_global_std_v2stargrade: 'Nivel',
    txt_global_std_poins: 'Puntos',
    txt_global_std_usethiskeyinyourapplicationby: 'Use esta clave en su aplicación por..........',
    txt_global_std_gooddrivingperformance: 'Buen rendimiento de conducción',
    txt_global_std_driveforthefuture: 'Conducir para el futuro.',
    txt_global_std_weareinareyou: 'Estamos adentro, ¿y tú?',
    txt_global_std_fleetcompanydescription: 'Gestión del riesgo del conductor e impacto de CO2.',
    txt_global_std_wasthecartowed2: '¿El coche fue remolcado?',
    txt_global_std_insuranceusage: 'uso de seguro',
    txt_global_std_yourpolicybeginsonthedateandtimewhenyouelectronicallysigntheapplicationandmakethedownpaymentonyourpolicy: 'Su póliza comienza en la fecha y hora en que firma electrónicamente la solicitud y realiza el pago inicial de su póliza.',
    txt_global_std_areyousureyouwanttoendyourcurrentrun: '¿Está seguro de que desea finalizar su viaje actual?',
    txt_global_std_ohno: 'Oh, no',
    txt_global_std_keeptrackhowmanykilometersyouhavedriven: 'Mantenga un registro de cuántos kilómetros ha conducido',
    txt_global_std_nextloayaltylevelprogressbar: 'Barra de progreso del siguiente nivel de fidelidad',
    txt_global_std_VilliersMusic: 'Cuando conduzco, escucho buena música, no soy demasiado quisquilloso con la música, para ser honesto.',
    txt_global_std_garminwatchconnected: 'reloj garmin conectado',
    txt_global_std_drivesafeandecofriendlyearngreatrewards: 'Conduce seguro y respetuoso con el medio ambiente\n' +
      '- ¡Gana grandes recompensas!',
    txt_global_std_forquestionscallagentatphonenumberphoneno: 'Si tiene preguntas, llame a {agent} al número de teléfono {phoneNo}',
    txt_global_std_p2ppushtitlenewexpensetoapprove: 'Nuevos gastos a aprobar',
    txt_global_std_unlockeddescription: 'Desbloqueado cuando se ha recibido la insignia',
    txt_global_std_totalscore2: 'Puntaje',
    txt_global_std_insuranceinformation: 'Información del seguro',
    txt_global_std_doihavetoactivatetheappeverytimeidrive: '¿Tengo que activar la aplicación cada vez que conduzco?',
    txt_global_std_NewPW_Hello: 'Hola',
    txt_global_std_namecannotbetoolong: 'El nombre no puede ser demasiado largo.',
    txt_global_std_recruitafriend: 'recluta amigo',
    txt_global_std_filters: 'filtros',
    txt_global_std_yourinvitationwassuccessfullysent: '¡Su invitación fue enviada con éxito!',
    txt_global_std_currentlynotopdrivers: 'Actualmente no hay mejores conductores',
    txt_global_std_MSIG_InsuranceLetter3: 'Ley de Vehículos Motorizados (Riesgos de Terceros y Compensación) (Capítulo 189)',
    txt_global_std_finishedhead2head: 'Desafíos cabeza 2 cabeza terminados',
    txt_global_std_warikan_onboarding1: 'KINTO ONE contratista',
    txt_global_std_p2pwecouldnotverifyyourkintonumberpleaselogintoyourkintoaccountandclickonthelinktodownloadthewarikanapp: 'No pudimos verificar su número de kinto. Inicie sesión en su cuenta de Kinto y haga clic en el enlace para descargar la aplicación Warikan',
    txt_global_std_topdriversthishour: 'Los mejores conductores esta hora',
    txt_global_std_pushinsurancerenewalodoremtitle: 'Renovación de {nombre de la aplicación}',
    txt_global_std_score: 'Puntaje',
    txt_global_std_yougetthiswhenyouhavescoredover4point410times: 'Obtienes esto cuando has anotado más de 4.4 10 veces',
    txt_global_std_myaccount: 'Mi cuenta',
    txt_global_std_anemailaddressisrequired: 'Se requiere una dirección de correo electrónico',
    txt_global_std_howdoigetagoodscore: '¿Cómo obtengo una buena puntuación?',
    txt_global_std_p2pmarkaspaid: 'Marcar como pagado',
    txt_global_std_SulayemInterests: 'Carreras, Automovilismo, Coches clásicos',
    txt_global_std_reportclaim: 'Reportar reclamo',
    txt_global_std_thismonth: 'Este mes',
    txt_global_std_totalamountofaccountscreated: 'Cantidad total de cuentas creadas',
    txt_global_std_myleaguetext: 'Conduces en {liga} liga. Continúe conduciendo de manera inteligente y suba en la clasificación.',
    txt_global_std_atthechallengedashboardyoucanviewyourchallengesandcreatenewonestocompeteindrivingsafeandecofriendly: 'En el panel Desafío, puede ver sus desafíos y crear otros nuevos para competir en la conducción segura y ecológica.',
    txt_global_std_donthaveanaccountsignupsignuplink: '¿No tienes una cuenta?\nRegístrate {enlace de registro}',
    txt_global_std__login: 'Iniciar sesión',
    txt_global_std_thisactionwillremoveyouraccount: 'Esta acción eliminará su cuenta, datos personales. ¡Esta acción no se puede deshacer!',
    txt_global_std_howdoiearnaloyaltystatusanswer: '¡Gana su estado de lealtad acumulando puntos! Obtienes puntos de los diferentes logros disponibles. ¡Los logros se otorgan a nuestros conductores leales y seguros de {appname}! Obtenga más información al respecto en la sección "Logros".',
    txt_global_std_MSIG_Password4: 'Atentamente, MS Primera Capital',
    txt_global_std_translationtoolvisualeditor: 'Herramienta de traducción - editor visual',
    txt_global_std_redeemyourrewards: 'Canjea tus recompensas',
    txt_global_std_nocode: '¿Sin código? haga clic aquí',
    txt_global_std_f_lanzmileage: 'Paseo por la naturaleza de 200 km. Aquí en Sudáfrica, podemos escapar rápidamente de la ciudad y disfrutar de un pintoresco Nture Drive. Espacio muy abierto y la mente puede enfocarse y procesar, y relajarse al mismo tiempo.',
    txt_global_std_pushdriveendlevel2: 'Muy buen manejo. Si quieres ver más información, por favor toca aquí.',
    txt_global_std_youredone: '¡Ya terminaste!',
    txt_global_std_numberno: 'No.',
    txt_global_std_VillersDescription: 'Giniel De Villiers nunca se ha retirado. Mejor aún, solo una vez no ha terminado en el Top 10 (en 2007). Tiene 14 victorias de etapa y en 2009 ganó el primer Dakar de Sudamérica.',
    txt_global_std_youneedtoinputavalidemailformat: 'Debe ingresar un formato de correo electrónico válido',
    txt_global_std_JyrkiainenDescription: 'Ha crecido rodeada de deportes de motor en una familia de carreras, hija de Minna Sillankorva, campeona mundial de rally femenino de 1991 y nueve veces campeona de Finlandia.',
    txt_global_std_youcannotopenthispopupagain: 'No puede volver a abrir esta ventana emergente.',
    txt_global_std_addlicense: 'AÑADIR LICENCIA',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint1: 'Califique el riesgo de conducción en función de los lugares de conducción reales, no de la casa o el lugar de trabajo',
    txt_global_std_numbercantinclude6062s: `El número no puede incluir "'?&/&#60; &#62;\\s#!%$%^*-`,
    txt_global_std_gointoapp: 'Entra en la aplicación',
    txt_global_std_movethemarkertoseehowyourlevelaffectsyourdiscount: 'Mueva el marcador para ver cómo su nivel afecta su descuento',
    txt_global_std_profile: 'Perfil',
    txt_global_std_TidemandMusic: 'Una mezcla bien compuesta de todos los géneros de diferentes épocas. Cuando conduzco, se trata de encontrar la vibra adecuada para el estado de ánimo, estoy dentro.',
    txt_global_std_SignupFia_Text10: "FIA Smart Driving Challenge es propiedad y está organizado por Fédération Internationale de l'Automobile y Greater Than. Visitar",
    txt_global_std_cimage39d: 'Su vehículo rueda distancias más largas de lo que cree.',
    txt_global_std_youhavebeeninvited: '¡Has sido invitado!',
    txt_global_std_disconnectallotherdevicesconnectedtotheobd: 'Desconecte todos los demás dispositivos conectados al lector OBD, solo se puede conectar a un dispositivo a la vez.',
    txt_global_std_yourdrivewillautomaticallyending: 'Su unidad finalizará automáticamente',
    txt_global_std_monday: 'Lunes',
    txt_global_std_activateyourinsurance: 'Activa tu seguro',
    txt_global_std_addyourcurrentmileagevalueinkmtakingapictureofyourodometeroursystemcouldaddthisvalueusingimagerecognition: 'Añade tu kilometraje actual (valor en km) tomando una foto de tu odómetro. ¡Nuestro sistema podría agregar este valor usando el reconocimiento de imágenes!',
    txt_global_std_totalco2savings: 'Ahorro total de CO2',
    txt_global_std_finalistsnationalchallenges: 'finalistas - RETOS NACIONALES',
    txt_global_std_youhavereferred10friendsnice: '¡Has recomendado a 10 amigos! ¡Agradable!',
    txt_global_std_heats: 'Eventos',
    txt_global_std_addedodometervaluemileage: 'Valor agregado del odómetro (kilometraje)',
    txt_global_std_usercannotberemoved: 'El usuario {{email}} no se puede eliminar.',
    txt_global_std_p2pcarlend: 'Coche',
    txt_global_std_rowsperpage: 'Filas por página',
    txt_global_std_100kmunlockedkey: '¡Condujiste una distancia de 100 km con la aplicación!',
    txt_global_std_pleaseenteryourpasswordtodeletethischallenge: 'Por favor ingrese su contraseña para eliminar este desafío',
    txt_global_std_distractions: 'Distracciones',
    txt_global_std_motorclaimshotline1: 'Línea Directa de Reclamaciones de Automóviles',
    txt_global_std_actionsheader: 'Comportamiento',
    txt_global_std_manualstartstopofdrive: 'Arranque/parada manual de la unidad',
    txt_global_std_PolicySchedule8: 'Marca/Tipo de cuerpo',
    txt_global_std_pioneergetstartedguide: 'Guía de inicio',
    txt_global_std_therearenocostsyet: 'Aún no tienes costos.',
    txt_global_std_p2pmessage: 'Mensaje',
    txt_global_std_befocused: 'Estar enfocado',
    txt_global_std_MSIG_InsuranceLetter42: 'SGD3,500.00 LAS SECCIONES I Y II SE IMPONEN POR SEPARADO A AQUELLOS CONDUCTORES QUE TIENEN MENOS DE 22 AÑOS Y/O QUE TIENEN MENOS DE 2 AÑOS DE EXPERIENCIA DE CONDUCCIÓN.',
    txt_global_std_policynumber1: 'Número de póliza',
    txt_global_std_p2pend: 'Final:',
    txt_global_std_silverlevel: 'Nivel Plata',
    txt_global_std_yourrecenttrips: 'Tus viajes recientes',
    txt_global_std_support: 'Apoyo',
    txt_global_std_addyourcurrentmileagevalueinkm: 'Agregue su millaje actual (valor en km)',
    txt_global_std_joined: 'Unido',
    txt_global_std_turnoncarengine: 'Encienda el motor del coche',
    txt_global_std_youcanstillusetheapptogetinsightsandstatisticsonyourdriving: 'Todavía puede usar la aplicación para obtener información y estadísticas sobre su conducción.',
    txt_global_std_connectyourdevice: '2. Conecta tu dispositivo',
    txt_global_std_youarealreadydrivingwhenyouhavefinished: 'Ya estás conduciendo. Cuando haya terminado su viaje, encontrará su coche aquí.',
    txt_global_std_qdoessompoacceptdriversthatarenotlicensedintheunitedstates: 'P: ¿Sompo acepta conductores que no tienen licencia en los Estados Unidos?',
    txt_global_std_p2pmemberuserguide: 'guía de usuario para miembros',
    txt_global_std_diesel: 'Diesel',
    txt_global_std_lokaliteterndvendigforatvurderedinkrselsadfrdogberegnedinkrescore: 'Tryg Drive recopila datos de ubicación para habilitar la puntuación de conducción incluso cuando la aplicación está cerrada o no está en uso.',
    txt_global_std_turnonbluetoothinyourphone: 'Encienda Bluetooth en su teléfono.',
    txt_global_std_MSIG_InsuranceLetter29: 'Expedido en Singapur el',
    txt_global_std_january: 'enero',
    txt_global_std_warning: 'Advertencia',
    txt_global_std_youdroveatscoretypescoreorbettergreatyouhaveunlockedthisbadge: '¡Condujiste a {scoretype} {score} o mejor! ¡Excelente! Has desbloqueado esta insignia.',
    txt_global_std_drivingsmartmeansusinglessenergyandlessenergymeanslowerco2emissions: 'Conducir inteligentemente significa usar menos energía, y menos energía significa menos emisiones de CO2.',
    txt_global_std_pigsavedunlocked: 'Por cada 50 coronas obtienes este premio',
    txt_global_std_qwhenwillmyratechange: 'P: ¿Cuándo cambiará mi tarifa?',
    txt_global_std_alertmaxspeedtext: 'Alarma de velocidad máxima disparada.',
    txt_global_std_entertheemailaddressthatyouhaveregisteredonyouragentaccount: 'Ingrese la dirección de correo electrónico que registró en su cuenta {agent}',
    txt_global_std_oneuniformmeasurementregardlessofvehicletypeandgeography: 'Una medida uniforme independientemente del tipo de vehículo y la geografía',
    txt_global_std_august: 'Agosto',
    txt_global_std_cimage25d: 'Investiga qué RPM tienes en la marcha más alta a 80 km/h. Luego intente engranar por debajo de estas RPM en todas las marchas.',
    txt_global_std_ridewithmeunlockedkey: '¡Gran trabajo! Viajar juntos en un automóvil en lugar de tomar automóviles separados ahorra mucho CO2. Puedes ganar esta insignia una vez por semana.',
    txt_global_std_p2pbyname: 'Por nombre',
    txt_global_std_obdreader: 'Lector OBD',
    txt_global_std_howdoicompeteinenerfyloyaltychallengeanswer: 'Participas automáticamente cuando te registras. No se preocupe, la tabla de clasificación solo mostrará su apodo, por lo que siempre permanecerá en el anonimato. Hay una competencia mensual y anual.',
    txt_global_all_saturday: 'sábado',
    txt_global_std_p2pnamewantstoborrowvehicle: '{name} quiere tomar prestado {vehicle}',
    txt_global_std_activatebluetoothsothedevicecanconnectyourphonewithyourcar: 'Active Bluetooth para que el dispositivo pueda conectar su teléfono con su automóvil',
    txt_global_std_BeforeFirstBill_Text4: '¿Puedo hacer un seguimiento de mis costos?',
    txt_global_std_PSolbergMileage: 'Próximamente, en breve, pronto...',
    txt_global_std_editapikey: 'Editar clave API',
    txt_global_std_p2pfaq4a: 'Mantenga presionado el mensaje que desea eliminar y aparecerá un cuadro de diálogo donde puede eliminar el mensaje.',
    txt_global_std_progressstatus: 'Status del progreso',
    txt_global_std_p2ppushtitlerevokeadmin: 'Derechos de administrador revocados',
    txt_global_std_sdcrapply2019price2: 'Cheques de gasolina de 500 kr',
    txt_global_std_preparingyournewenerfyhome: 'Preparando tu nueva casa de {appName}...',
    txt_global_std_networkoffline: 'Red fuera de línea',
    txt_global_std_Alert_WereYouDrivingTitle: '¿Estabas conduciendo?',
    txt_global_std_loyaltysmsmailtext: '¡Empieza a conducir más seguro conmigo! Siga el enlace a continuación y comience a conducir con',
    txt_global_std_invitefriendsgetawards: 'Invitar a amigos. Consigue premios.',
    txt_global_std_failedtopublishedlanguage: '¡Error en el idioma publicado!',
    txt_global_std_unlocklimitheader: 'Límite de desbloqueo',
    txt_global_std_SignupFia_Text8: 'Estén atentos - Síganos en las redes sociales',
    txt_global_std_percentagedissconnectedtripsmax25: '{percentageDissconnectedTrips}% de viajes desconectados (del máximo 25%)',
    txt_global_std_users: 'Usuarios',
    txt_global_std_orderobd: 'Orden OBD',
    txt_global_std_challengesent2: 'Reto enviado!',
    txt_global_std_verifyyourchanges: 'Verifica tus cambios',
    txt_global_std_belowisyourlocationsoyoucaneasilydescribewhereyouaretotheroadassistantmanager: 'A continuación se muestra su ubicación, para que pueda describir fácilmente dónde se encuentra al gerente de Road Assistant.',
    txt_global_std_trafficdelaysdeadlinesandrunninglatetheycanallcausestresswhichisnegativeforyourhealthandsafetyallowingextratimeplanningyourrouteandcheckingtravelupdatescanhelptoreducestressandimproveyourfocus: 'Retrasos en el tráfico, plazos y retrasos; todos pueden causar estrés, lo cual es negativo para su salud Y seguridad. Permitir tiempo adicional, planificar su ruta y verificar las actualizaciones de viaje puede ayudar a reducir el estrés y mejorar su enfoque.',
    txt_global_std_TaxInvoice2: 'Fecha',
    txt_global_std_current: 'Actual:',
    txt_global_std_refer10friendsachievementlocked: 'Recomiende a 10 amigos para desbloquear este logro y gane 300 puntos.',
    txt_global_std_removephoto: 'Quitar foto',
    txt_global_std_pleaseenteravalidemail: 'Por favor introduzca una dirección de correo electrónico válida.',
    txt_global_std_emailhasbeensent: '¡E-mail ha sido enviado!',
    txt_global_std_p2pblockcar: 'coche de bloque',
    txt_global_std_countryroadfilechangeDESC: 'El conductor del automóvil azul elige un carril y conduce concentrado.\n' +
      'El conductor del auto rojo está siguiendo al auto de adelante y cambia de carril estresante.',
    txt_global_std_discountinformation: 'Su nivel promedio este mes es la base de su descuento. Recibes el descuento al renovar tu seguro para los próximos meses.',
    txt_global_std_business2: 'Negocio',
    txt_global_std_cimage30d: 'Una aceleración seguida de un retraso es una pérdida de dinero.',
    txt_global_std_onceyoupairtheapptoyourvehiclesbluetoothandchangethesettingstoallowlocationserviceswematchyourdrivinghabitstoexistingdriversandprovideascoreinsuranceisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsabouttheagentappandcompeteagainstthemforlowerscores: 'Una vez que empareja la aplicación con el Bluetooth de su vehículo y cambia la configuración para permitir los servicios de ubicación, hacemos coincidir sus hábitos de conducción con los de los conductores existentes y proporcionamos una puntuación. El seguro se basa en quién conduce su vehículo y qué tan bien lo conduce. Se anima a quienquiera que esté conduciendo su vehículo a descargar la aplicación y realizar un seguimiento de sus puntuaciones. Cuéntales a tus amigos sobre la aplicación {agent} y compite contra ellos para obtener puntajes más bajos.',
    txt_global_std_30daysfreetrialnocreditcardrequired: '30 días de prueba gratis. No se requiere tarjeta de crédito.',
    txt_global_std_locationservicesnotsettoalwaysallow: 'Los servicios de ubicación no están configurados para Permitir siempre',
    txt_global_std_accepted: 'Aceptado',
    txt_global_std_yourfriendsname: 'el nombre de tus amigos',
    txt_global_std_thesaferyoudrivethehigherthesaving: '¡Cuanto más seguro sea su viaje, mayor será el ahorro!',
    txt_global_std_achievementcannotbedeletedpopup: 'Este logro no se puede eliminar.',
    txt_global_std_clear: 'Claro',
    txt_global_std_alluploadedphotoswillbemarkedwithacheckmark: 'Todas las fotos cargadas se marcarán con una marca de verificación.',
    txt_global_std_partlyavailable: 'Parcialmente disponible',
    txt_global_std_p2paddedbyyou: 'Agregado por ti',
    txt_global_std_p2pbookingconfirmed: 'Reserva confirmada',
    txt_global_std_avgtimeonroad7d: 'Tiempo medio en carretera 7D',
    txt_global_std_warikan_onboarding9: '5. Selecciona la fecha y hora de uso del coche del calendario y haz una reserva',
    txt_global_std_riskportfoliotracker_upsell_pitch_bulletpoint1: 'Perspectivas de riesgo del conductor de arriba hacia abajo filtrables por geografía, fecha, hora, vehículo y más',
    txt_global_std_createyouraccounttosaveyourtripsandearnbonusetc: 'Cree su cuenta para guardar sus viajes, ganar bonos y obtener acceso a su entrenador de manejo personal. Puede crear su cuenta en un momento posterior.',
    txt_global_std_getspecialoffers: 'Recibe ofertas especiales',
    txt_global_std_startguidetip2: 'Asegúrate de que Bluetooth y el servicio de ubicación estén activados.',
    txt_global_std_exampleemail: 'nombre@dominio.com',
    txt_global_std_MikkelsenQuote: '¡Es un honor ser seleccionado como líder del equipo en este emocionante proyecto! Estoy compitiendo en el nivel de élite del automovilismo, y si yo, como piloto, puedo inspirar a otros a convertirse en conductores inteligentes, seguros y respetuosos con el medio ambiente, estoy feliz de hacerlo. ¡Únase a mi equipo hoy y conduzcamos de manera más inteligente!',
    txt_global_std_acceptfreemiumterms: 'Aceptar términos y políticas',
    txt_global_std_searchbartext: 'Buscar por xxx, xxx o xxx',
    txt_global_std_qhowlongwillittaketoprocessmyclaim: 'P: ¿Cuánto tiempo llevará procesar mi reclamo?',
    txt_global_std_TaxInvoice15: 'Introducido por STELLAL',
    txt_global_std_insurancecompany: 'Compañía de seguros',
    txt_global_std_PolicySchedule23: 'De primera calidad',
    txt_global_std_youreinvited: '¡Estás invitado!',
    txt_global_std_thepasswordhasbeenreset: 'La contraseña se ha restablecido.',
    txt_global_std_enerfycoachtip3: '3. No se apresure al conducir, incluso si tiene prisa.',
    txt_global_std_resetpasswordsuccess: '¡Éxito! Revise su correo electrónico para obtener un enlace para restablecer la contraseña.',
    txt_global_std_bestregardscomma: 'Saludos,',
    txt_global_std_whatwillhappenifmyphonelosessignalorifmyphone: '¿Qué sucederá si mi teléfono pierde señal o si se queda sin batería?',
    txt_global_std_friendwasremoved: 'Amigo fue eliminado',
    txt_global_std_paid: 'Pagado',
    txt_global_std_competeindividuallyonly: 'Compite individualmente solo',
    txt_global_std_makesureyourengineison: 'Asegúrate de que tu motor esté encendido',
    txt_global_std_confirmandcreateaccount: 'Confirmar y crear cuenta',
    txt_global_std_pigsaved: '50 coronas ahorradas',
    txt_global_std_qcanitrytheappbeforesigningup: 'P: ¿Puedo probar la aplicación antes de registrarme?',
    txt_global_std_HusseinAbdullaQuote: 'Cotización próximamente...',
    txt_global_std_p2ppushtitleborrowcar: '{name} - ¿coche prestado?',
    txt_global_std_home: 'Hogar',
    txt_global_std_youareabouttopublishtranslationspleasemakesureyouhavereviewedalltranslationsbeforeproceeding: 'Está a punto de publicar traducciones, asegúrese de haber revisado todas las traducciones antes de continuar.',
    txt_global_std_patternprofiling: 'Perfilado de patrones',
    txt_global_std_qualifications: 'Calificaciones:',
    txt_global_std_p2pfaq32a: '1. A principios de mes, se mostrará automáticamente un recordatorio de "Warikan" en la pantalla de inicio. O seleccione "Warikan" en "Menú".\n' +
      '2. Seleccione "Warikan" para el mes.\n' +
      '3. Del menú desplegable, seleccione cuatro métodos de división y considere cómo dividir el costo. Puede elegir entre "Proporción de uso", "Tiempo de uso", "Reparto equitativo" y "Millas".\n' +
      '4. A continuación, verifique el costo a cobrar por cada miembro. Puede ajustar la cantidad seleccionando la marca de lápiz.\n' +
      '5. Seleccione Cobrar gastos para cobrar a los miembros.\n' +
      '6. Cuando reciba el costo del miembro, seleccione "Pago completo".',
    txt_global_std_youaretheowner: 'Necesitamos su contraseña actual para asegurarnos de que usted es el propietario de esta cuenta.',
    txt_global_std_competeforthesebadges: 'Compite por estas insignias',
    txt_global_std_SulayemMusic: 'Jazz suave',
    txt_global_std_staytunedonupcomingchallenges: 'Estén atentos a los próximos desafíos',
    txt_global_std_statstotal: 'Total',
    txt_global_std_signin: 'Iniciar sesión',
    txt_global_std_p2ptypeofexpenses: 'tipo de gastos',
    txt_global_std_automatic_start_and_stop_requires_access_to_bluetooth_location_services_and_motion_fitness: 'El inicio y la parada automáticos requieren acceso a bluetooth, servicios de ubicación (GPS) y movimiento y estado físico.',
    txt_global_std_estimatedcost: 'Costo estimado',
    txt_global_std_defaultfirsttimeusingtheserviceregisterfromsignuplink: '¿Primera vez que usa el servicio? Regístrese desde {signUpLink}',
    txt_global_std_p2pcalculationswillnotbecorrect: 'Los cálculos no serán correctos.',
    txt_global_std_youcanfileaclaimbyclickingonfileaclaiminyourenerfyapp: 'Puede presentar un reclamo haciendo clic en Presentar un reclamo en su aplicación Enerfy.',
    txt_global_std_illdothislater: 'Haré esto más tarde',
    txt_global_std_dataintervalupload: '20 seg',
    txt_global_std_youneedtofillindriversname: 'Debe completar el nombre del controlador',
    txt_global_std_challengeafriend: '¡Reta a un amigo!',
    txt_global_std_sorrytheimagerecognitionfailed: 'Lo sentimos, el reconocimiento de imagen falló',
    txt_global_std_emailaddress: 'Dirección de correo electrónico',
    txt_global_std_p2pmycars: 'Mis carros',
    txt_global_std_howdoiearnsavings: '¿Cómo gano ahorros?',
    txt_global_std_getupdatesregardingnews: '¡Recibe actualizaciones sobre noticias y desafíos!',
    txt_global_std_closestworkshop: 'Taller más cercano',
    txt_global_std_sidekickprofilepreferences: 'Preferencias de perfil',
    txt_global_std_stardriverunlockedkey: '¡Eres una estrella, {usuario}! ¡Hoy obtuviste una puntuación superior a {score}!',
    txt_global_std_therecouldbeotherreasonswhyyourcarsbluetoothyourbluetoothbeacondoesntshowupinthelist: 'Podría haber otras razones por las que su\n' +
      'el Bluetooth del automóvil/su baliza bluetooth no aparece en el\n' +
      'lista:',
    txt_global_std_atthecompanychallengepageyoucancreatenewprivatechallengeswhereyoucancompeteindrivingsafeandecofriendly: 'En la página Company Challenge, puede crear nuevos desafíos privados en los que puede competir en la conducción segura y ecológica.',
    txt_global_std_rwmnofriendstitle: 'todavía no tienes amigos',
    txt_global_std_p2ppushtitlecancelledperiodfromto: 'La reserva con el período {desde} ha sido cancelada.',
    'txt_global_std_sorryiobd2errorcodereadingnotsupportedbywindowsphone.': 'Lo sentimos, la lectura del código de error iOBD2 no es compatible con Windows Phone.',
    txt_global_std_warikan_onboarding3_1: 'なお、仮パスワードの有効期限は72時間となっております。\n' +
      '超過 後 は 、 「わりかん kinto」 アプリ の 「仮 パス ワード を 再 送信」 より 再度 メール を 受け取っ て いただき 、 手続き を お願いいたし ます。。\n' +
      '※ 本 メール は 送信 専用 です。 ご 返信 いただい て も お応え い た しか ね ます ので 、 お問い合わせ は 下記 へ お願いいたし ます。。。',
    txt_global_std_english: 'inglés',
    txt_global_std_aifyourcardoesnthavebluetoothpleasecontactsupportatphonenotoorderanobdreaderthatyouwillneedtoplugintoyourcar: 'R: Si su automóvil no tiene Bluetooth, comuníquese con el soporte en {phoneNo} para solicitar un lector OBD que deberá conectar a su automóvil.',
    txt_global_std_pleaseconfirmyourlicenseplate: 'Por favor, confirme su matrícula:',
    txt_global_std_ignore: 'Pasar por alto',
    txt_global_std_someviewslookdifferentdependingonifthereisdrivingdataornotyoucanlogintoyouraccountintheapporusethedifferentuserstatestoviewandtranslatetheappwithorwithoutdrivingdata: '* Algunas vistas se ven diferentes dependiendo de si hay datos de manejo o no. Puede iniciar sesión en su cuenta en la aplicación o utilizar los diferentes estados de usuario para ver y traducir la aplicación con o sin datos de conducción.',
    txt_global_std_themescreendesign: 'Tema / Diseño de pantalla',
    txt_global_std_phonenumberdoesnotexist: '¡El número de teléfono no existe!',
    txt_global_std_my_saving: 'MI AHORRO',
    txt_global_std_finalistsheat2: 'Finalistas - Serie 2',
    txt_global_std_yourchallenges: 'Tus retos',
    txt_global_std_addusersto: 'Agregar usuarios a',
    txt_global_std_gender: 'Género',
    txt_global_std_youraddress: 'Su dirección',
    txt_global_std_company: 'Compañía',
    txt_global_std_reminderinsurance: 'Recordatorio',
    txt_global_std_MSIG_InsuranceLetter13: 'Valor Estimado Asegurado',
    txt_global_std_cimage28d: 'Solo use el control de crucero cuando esté plano, de lo contrario, mantenga el gas constante.',
    txt_global_std_bluetoothtroubleshooting: 'Solución de problemas de Bluetooth',
    txt_global_std_youupgradedtovalueplanyouhaveearned10000points: 'Ha ganado 10,000 puntos por actualizar a Value Plan.',
    txt_global_std_p2pwarningexpenses: 'Asegúrate de agregar tus gastos primero. No podrás hacer eso más tarde.',
    txt_global_std_joinussignupforourcommunityandbecomeapartofus: 'Únete a nosotros, regístrate en nuestra comunidad y sé parte de nosotros. Una vez que haya dado su consentimiento, puede participar en nuestras competiciones o desafiar a un amigo.',
    txt_global_std_registeryourridesharingtripwithin1dayafterthetriphasended: 'Registre su viaje compartido dentro de 1 día después de que finalice el viaje',
    txt_global_std_igetit: '¡Lo entiendo!',
    txt_global_std_darktheme: 'tema oscuro',
    txt_global_all_friday: 'Viernes',
    txt_global_std_tipcityparking1DESC: 'El conductor del auto azul usa sus señales de giro y espera hasta que haya una brecha más grande entre los autos.\n' +
      'El automóvil rojo sale del espacio de estacionamiento sin usar las señales de giro ni esperar un espacio natural en el tráfico.',
    txt_global_std_designandsendpushnotificationstoyourteam: 'Diseñe y envíe mensajes a su equipo en poco tiempo, o prográmelos para el mejor recorrido del cliente.',
    txt_global_std_newpassword: 'Nueva contraseña',
    txt_global_std_p2prequestanewpassword: 'Pide una nueva contraseña',
    txt_global_std_seewherepoliciesarewrittenvswheretheyaredriving: 'Vea dónde están escritas las políticas frente a dónde conducen',
    txt_global_std_shareyourinvitecode: 'Comparte tu código de invitación',
    txt_global_std_ridewithme: 'Pasea conmigo',
    txt_global_std_yourfriendemail: 'tu-amigo@email.com',
    txt_global_std_yougetthisachievementafterdrivingafullweekandmaintaininganaveragescoreofover4: 'Obtienes este logro después de conducir una semana completa y mantener un puntaje promedio de más de 4.0',
    txt_global_std_p2pchataboutabookingorstartagroupchatwithyourinvitedfriends: 'Chatea sobre una reserva o inicia un chat grupal con tus amigos invitados.',
    txt_global_std_setupyourcompanychallengeandinviteyourcoworkerstothefiasmartdrivingchallengecompeteagainsteachotherinafunwaytobecomebetterdriverssavemoreco2andimproveyourscore: 'Configura tu Company Challenge e invita a tus compañeros de trabajo al FIA Smart Driving Challenge. Compite unos contra otros de forma divertida para convertirte en mejores conductores, ahorrar más CO2 y mejorar tu puntuación.',
    txt_global_std_howdoestheappwork: '¿Cómo funciona la aplicación?',
    txt_global_std_activationcodeforname: 'Código de activación para {nombre}',
    txt_global_std_1stdate: '1º',
    txt_global_std_choosebetweenenteringchassinumberplatenumberorpolicynumber: 'Elige entre ingresar número de chasis, número de placa o número de póliza.',
    txt_global_std_p2pfaq12a: 'Sí, si eliminas a un amigo no podrás crear nuevas reservas con ese amigo.\n' +
      '1. Vaya a la página de sus amigos desde el menú más (tres puntos)\n' +
      '2.Seleccione a su amigo para ver su perfil.\n' +
      '3.Presione el botón de configuración.\n' +
      '4. Elija eliminar amigo.',
    txt_global_std_gotochallenge: 'ir al desafío',
    txt_global_std_yourlatesttripwasbetterthanappnameaverage: '¡Gran trabajo! ¡Tu último viaje fue mejor que el promedio de {appname}!',
    txt_global_std_bluetooth: 'Bluetooth',
    txt_global_std_saturday: 'sábado',
    txt_global_std_redeemedon: 'Canjeado el',
    txt_global_std_getstartedandgainaccessto: 'Comience y acceda a',
    txt_global_std_p2pyouhavereachedthemaximumamountoffriendspercar: 'Has alcanzado la cantidad máxima de amigos por coche',
    txt_global_std_loyaltylevel: 'Nivel de lealtad',
    txt_global_std_createcustom: 'Crear personalizado',
    txt_global_std_teamaveragescore: 'Puntuación media de los participantes',
    txt_global_std_you: 'Tú',
    txt_global_std_cropimage: 'Delimitar imagen',
    txt_global_std_driverspolicy: 'política de conductores',
    txt_global_std_competeinyoureverydaydrivingagainstyourfriends: 'Compite en tu conducción diaria contra tus amigos. ¡El piloto con la mejor puntuación gana la ronda!',
    txt_global_std_deletechallenge: 'Eliminar desafío',
    txt_global_std_pleasemakesurethatyouareconnectedtoyourcarandtryagain: 'Asegúrate de estar conectado a tu coche e inténtalo de nuevo.',
    txt_global_std_everydayheroeswearegatheringtomakeanimpactwedrivetoreduceourcoemissionsbybeingmoremindfulawareandactiveinthewaywetransportourselvesfromoneadventuretothenextbeittheweekendgetawayortogetthekidsfromsoccerpracticewemaketheworldsaferandbetterhowmuchletsfindout: 'Héroes cotidianos: nos reunimos para generar un impacto. Conducimos para reducir nuestras emisiones de CO² al ser más conscientes, conscientes y activos en la forma en que nos transportamos de una aventura a la siguiente (ya sea una escapada de fin de semana o para llevar a los niños a la práctica de fútbol). Hacemos el mundo más seguro y mejor. ¿Cuánto cuesta? ¡Vamos a averiguar!',
    txt_global_std_theparameterswerevalidbuttherequestfailed: 'Los parámetros eran válidos pero la solicitud falló.',
    txt_global_std_mysaving: 'MI AHORRO',
    txt_global_std_automotivecompany: 'Empresa automotriz',
    txt_global_std_p2pfinish: 'Finalizar',
    txt_global_std_individual: 'Individual',
    txt_global_std_findmycar: 'encontrar mi coche',
    txt_global_std_p2pbookedby: 'Reservado por',
    txt_global_std_kmleftyourinsuranceisrenewed: 'kilometros a la izquierda.\nTu seguro se renueva',
    txt_global_std_p2pfaq18a: '1. Seleccione "Conectar/Configuración" en la barra de menú en la parte inferior de la pantalla.\n' +
      '2. Permita la configuración de Bluetooth, servicios de ubicación, movimiento y estado físico. Para los servicios de ubicación, seleccione "Siempre" para una adquisición de datos precisa.\n' +
      '3. Después de arrancar el motor, conecte el automóvil y la aplicación a través de Bluetooth. Cuando se muestre el nombre del automóvil en la aplicación, seleccione "Seleccionar un automóvil".\n' +
      '4. Cuando comience a conducir, recibirá comentarios en tiempo real sobre su conducción.\n' +
      '5. Cuando finaliza la conducción, los datos de conducción se guardan automáticamente en la aplicación.',
    txt_global_std_optional2: '(Opcional)',
    txt_global_std_atleast8characters: 'Al menos 8 carácteres.',
    txt_global_std_uploadimage: 'Cargar imagen',
    txt_global_std_p2paddmembers: 'Añadir miembros',
    txt_global_std_p2ppushtitlenewfriend: 'Nuevo amigo',
    txt_global_std_allowlocationtoparticipateinfiasdc: 'Permitir que la ubicación participe en FIA SDC',
    txt_global_std_bookingchat: 'Chat de reserva',
    txt_global_std_timeandcanonlybeusedonce: 'tiempo y solo se puede usar una vez.',
    txt_global_std_summaryoftimedrivenperselectedperiod: 'Resumen del tiempo conducido por período seleccionado.',
    txt_global_std_bad: 'Malo',
    txt_global_std_PolicySchedule11: 'Chasis No',
    txt_global_std_averagescore: 'Puntuación media',
    txt_global_std_MSIG_InsuranceLetter1: 'CERTIFICADO DE SEGURO',
    txt_global_std_yourinsurancewasautomaticallyrenewalformonth: 'Su seguro fue renovado automáticamente por {mes}.',
    txt_global_std_unitsaved: 'Unidad guardada',
    txt_global_std_p2plendmycar: 'presta mi {nombre del coche}',
    txt_global_std_pleasenotethattojoinyoumusthavethefiasdcappinstalledonyourmobiledeviceinordertobeabletojointhegroup: 'Tenga en cuenta que para unirse debe tener la aplicación FIA SDC instalada en su dispositivo móvil para poder unirse al equipo.',
    txt_global_std_typeofbusiness: 'Tipo de negocio',
    txt_global_std_close: 'Cerca',
    txt_global_std_risklevelandscoring: 'Nivel de riesgo y puntuación',
    txt_global_std_datetimetogt: 'Fecha hora para GT',
    txt_global_std_thereaderwillnotbevisibleinyourphonesbluetoothsettings: 'El lector no será visible en la configuración de bluetooth de su teléfono',
    txt_global_std_teammemberbig1: 'MIEMBRO DEL EQUIPO',
    txt_global_std_p2pmember: 'Miembro',
    txt_global_std_kintoloyaltylevels: 'Niveles de fidelidad de Kinto:',
    txt_global_std_tttest99: 'esto es una prueba',
    txt_global_std_presspaynowwhenyouarereadytocheckoutintheservicecenter: 'Presiona "Pagar ahora" cuando estés listo para pagar en el centro de servicio.',
    txt_global_std_p2phours: 'horas',
    txt_global_std_yourbonus: 'tu bono',
    txt_global_std_carstatsthismonth: 'Estadísticas de autos este mes',
    txt_global_std_signinwithemail: 'Iniciar sesión con correo electrónico',
    txt_global_std_p2powner: 'Dueño',
    txt_global_std_pleaseenteravalidphonenumberincludingareacode: 'Ingrese un número de teléfono válido, incluido el código de área',
    txt_global_std_carroreward: 'carro recompensa',
    txt_global_std_Fia_Receipt_Text3: '¡Bienvenido al Desafío de conducción inteligente de la FIA, 2021! Se le envía un SMS con su código de activación único, siga las instrucciones para comenzar.',
    txt_global_std_somethinghasgonewrong: '¡Algo salió mal!',
    txt_global_std_completedaconnecteddrive: 'Completó una unidad conectada',
    txt_global_std_1st: '1: st',
    txt_global_std_requiredformatcolumnastext2: 'Obligatorio (columna de formato como texto)',
    txt_global_std_OnboardingEmail: '¡Bienvenido a MS First Capital Insurance Ltd!',
    txt_global_std_ourpremierworkshops: 'Nuestros talleres de primera',
    txt_global_std_connect: 'Conectar',
    txt_global_std_p2pfaq11q: '¿Cómo puedo invitar a mis amigos a Warikan Kinto?',
    txt_global_std_drives: 'unidades',
    txt_global_std_connecttoobdreader: 'Conectar al lector OBD',
    txt_global_std_sparet: 'Salvado',
    'txt_global_std_timein{state}': 'Tiempo en {estado}',
    txt_global_std_bekindtotheenvironment: 'Sea amable con el medio ambiente.',
    txt_global_std_2minutesagoshorthand: 'hace 2m',
    txt_global_std_selectthetypeofinjurythatyouwishtoregisterbelowandfollowtheinstructions: 'Seleccione el tipo de lesión que desea registrar a continuación y siga las instrucciones',
    txt_global_std_earnpointsandachievethenextlevel: '¡Gana puntos y pasa al siguiente nivel!',
    txt_global_std_avoidhardacceleration: 'Evitar aceleraciones bruscas',
    txt_global_std_deleteachivementpopuptext: 'Su logro se eliminará de forma permanente. El progreso de tus usuarios se perderá. Quienes ya hayan recibido el logro aún podrán verlo.',
    txt_global_std_notes: 'notas',
    txt_global_std_head2headchallenge: 'Desafío cabeza 2 cabeza',
    txt_global_std_yourrequestedauthenticationcode: 'Su código de autenticación solicitado:',
    txt_global_std_leveloverview: 'Resumen de niveles',
    txt_global_std_cropyourbackground: 'Recorta tu fondo',
    'txt_global_std_t brikken': 'Información del contacto',
    'txt_global_std_countryroadcatch-up2DESC': 'El conductor del coche rojo se acerca a gran velocidad y sigue al coche de delante. El conductor del automóvil azul mantiene un ritmo lento para conducir concentrado y sin problemas.',
    txt_global_std_yourein: '¡Estas en!',
    txt_global_std_howdoyoumeasuremydriving: '¿Cómo miden mi conducción?',
    txt_global_std_managegroups: 'Administrar grupos',
    txt_global_std_unlockedtitle: 'desbloqueado',
    txt_global_std_actiontimestamp: 'Tiempo de acción',
    txt_global_std_encouragelowriskdrivingviaapotentialsavingspertrip: 'Fomentar la conducción de bajo riesgo a través de un ahorro potencial por viaje',
    txt_global_std_aonceyoupairtheapptoyourvehiclesbluetoothandallowtheapptouselocationserviceswematchyourdrivinghabitstoouraislibraryofdriverpatternsandprovideascoreyourinsurancepremiumisbasedonwhodrivesyourvehicleandhowwelltheydrivewhoeverisdrivingyourvehicleisencouragedtodownloadtheappandtracktheirscorestellyourfriendsaboutthecovercubeappandcompeteagainstthemforlowerscores: 'R: Una vez que empareja la aplicación con el Bluetooth de su vehículo y permite que la aplicación use los servicios de ubicación, comparamos sus hábitos de conducción con la biblioteca de patrones de conducción de nuestra IA y proporcionamos una puntuación. La prima de su seguro se basa en quién conduce su vehículo y qué tan bien lo conduce. Se anima a quienquiera que esté conduciendo su vehículo a descargar la aplicación y realizar un seguimiento de sus puntuaciones. Cuéntales a tus amigos sobre la aplicación Covercube y compite contra ellos para obtener puntajes más bajos.',
    txt_global_std_accountremoval: 'Eliminación de cuenta',
    txt_global_std_carsharingemptyviewlent: 'Du har inte lånat någon bil än. Dina körningar kommer sammanställas direkt i appen.',
    txt_global_std_youhavebeenchallengedby: 'Has sido desafiado por',
    txt_global_std_thismaytakeafewseconds: 'Esto puede tardar unos pocos segundos.',
    txt_global_std_p2pifyouwanttoupdatetheemailusedasusername: 'Si desea actualizar el correo electrónico utilizado como nombre de usuario,',
    txt_global_std_smartdriving: 'Conducción inteligente',
    txt_global_std_wecannotfindthecaridentityselectregno: 'Seleccione la matrícula del vehículo que conduce.',
    txt_global_std_preparingsomethings: 'Preparando algunas cositas...',
    txt_global_std_focustips: 'Mantén la vista en la carretera y concéntrate por completo en la conducción. A veces es fácil distraerse con el entorno, los teléfonos, las personas en el automóvil, etc.\n' +
      '\n' +
      'Conducir cansado o estresado puede afectar el enfoque y exponerlo a un mayor riesgo.\n' +
      '\n' +
      'Nos preocupamos por usted.',
    txt_global_std_weareherforeyoucontact: 'Estamos aquí para ti. ¡Contáctenos!',
    txt_global_std_gas: 'Gas',
    txt_global_std_qhowcaniimprovemyscore: 'P: ¿Cómo puedo mejorar mi puntuación?',
    txt_global_std_somefacts: 'Algunos hechos',
    txt_global_std_MSIG_InsuranceLetter24: 'Limitaciones en cuanto al uso',
    txt_global_std_paymentpalndoesnotexistonthisaccountgotoeventcreatorandcreateaddpaymentplan: 'El plan de pago no existe en esta Cuenta. Vaya al creador del evento y cree un plan de pago adicional.',
    txt_global_std_ReminderMail: '¿Estás listo para tu prueba de manejo de SDC?',
    txt_global_std_kintoImprovement2: '¡Oye, nos preocupamos por ti! ¡Tómatelo con calma la próxima vez!',
    txt_global_std_yourinsurancehasbeencanceled: 'Su seguro ha sido cancelado',
    txt_global_std_petrol: 'Gasolina',
    txt_global_std_base: 'Base',
    txt_global_std_endtour: 'Tour final',
    txt_global_std_carregistrationnumber: 'Número de matrícula del coche',
    txt_global_std_starttime: 'Hora de inicio',
    txt_global_std_rwmcontinue: 'Continuar',
    txt_global_std_who: 'Quién',
    txt_global_std_youdrovereallywellyouraveragescoretypewasbetterthanscoreforaweekyouhaveearnedthisbadge: '¡Condujiste muy bien! ¡Tu {scoretype} promedio fue mejor que {score} durante una semana! ¡Te has ganado esta insignia!',
    txt_global_std_p2pborrowacar: 'Pide prestado un {nombre de coche}',
    txt_global_std_2faenabled: 'Dos factores habilitados',
    txt_global_std_youalwaysstarttheinsuranceyearwithnok1000inthepiggybankyouwillbepaidtheseifyouhavebeenasafedriverthroughouttheinsuranceyearifyouchoosetoterminatetheinsurancebeforetheendoftheyearacalculationwillbemadeonthebasisoftheperiodyouhadtheinsurance: 'Siempre comienzas el año del seguro con NOK 1000 en la alcancía. Se le pagarán estos si ha sido un conductor seguro durante todo el año del seguro. Si opta por terminar el seguro antes de que finalice el año, se hará un cálculo en base al período que tuvo el seguro.',
    txt_global_std_manydriversrelytooheavilyoncruisecontrolitiscomfortableyesbutlethalforfueleconomyinmanycasesusingcruisecontrolontheslightestuphillordownhillwillcausetheenginetofighthardtosatisfyyourwishofmaintainingtheexactspeedyousetinsteadmanagingyourspeedwithyourfootandaimingforasmoothridewillhelpyousaveahugeamountonfuelnew: 'Muchos conductores confían demasiado en el control de crucero. Es cómodo, sí, pero letal para el ahorro de combustible en muchos casos. Usar el control de crucero en la más mínima cuesta arriba o cuesta abajo hará que el motor luche duro para satisfacer su deseo de mantener la velocidad exacta que estableció. En su lugar, controlar la velocidad con el pie y apuntar a una conducción suave te ayudará a ahorrar una gran cantidad de combustible.',
    txt_global_std_jpfaqanswer: '1. Seleccione "Conectar" en la barra de menú en la parte inferior de la pantalla.\n' +
      '2. Permitir Bluetooth, servicio de ubicación y configuración de estado físico de movimiento. Para los servicios de ubicación, seleccione "Siempre" para una adquisición de datos precisa.\n' +
      '3. Después de arrancar el motor, conecte el automóvil y la aplicación a través de Bluetooth. Cuando se muestre el nombre del automóvil en la aplicación, seleccione "Seleccionar un automóvil".\n' +
      '4. Cuando comience a conducir, recibirá comentarios en tiempo real sobre su conducción.\n' +
      '5. Cuando termine de conducir, los datos de conducción se guardarán automáticamente en la aplicación.',
    txt_global_std_leaderboardisempty: 'La tabla de clasificación está vacía.',
    txt_global_std_tttest78: 'prueba\nasd',
    txt_global_std_pleasecompletethesesteps: 'Por favor complete estos pasos',
    txt_global_std_rwmsheettitle: 'Invita a un amigo',
    txt_global_std_dontforgettocheckfeedback: 'No olvides revisar tus comentarios para comenzar a mejorar tu conducción.',
    txt_global_std_youwillbenotifiedintheappwhenitstimetorenewyourinsuranceifyoudonthaveautomaticrenewalsetupfollowthestepsintheapptoensureyouhavecoveragefornextmonth: 'Se le notificará en la aplicación cuando sea el momento de renovar su seguro. Si no tiene configurada la renovación automática, siga los pasos en la aplicación para asegurarse de tener cobertura para el próximo mes.',
    txt_global_std_keepgooddistancebetweencars: 'Mantén una buena distancia entre los autos.',
    txt_global_std_unit: 'Unidad',
    txt_global_std_personaldatapreferences: 'preferencias de datos personales',
    txt_global_std_JyrkiainenBesttip: 'Sea paciente y proactivo. Siga los alrededores y el tráfico. También es bueno comprobar el consumo de combustible.',
    txt_global_std_p2pwecouldnotfindanybookingchatswhenyoucreateabookingyouwillbeabletochataboutthatbookinghere: 'No pudimos encontrar ningún chat de reserva. Cuando cree una reserva, podrá chatear sobre esa reserva aquí.',
    txt_global_std_adressline1: 'Dirección linea 1',
    txt_global_std_PaddonWhyjoin: '¡En nuestro equipo ganarás! Soy una persona muy competitiva y haré todo lo posible para trabajar con todos nuestros compañeros de equipo para asegurarnos de dar lo mejor de nosotros. Si bien esta será una gran competencia, es importante no olvidar los mensajes importantes, este desafío continúa. Nos esforzamos por ser conductores más seguros y completos, algo que todos podemos compartir e inspirar a muchas más personas a serlo.',
    txt_global_std_downloadtheappnameapptodrivemycar: 'Descargar la aplicación {appname} para conducir mi auto',
    txt_global_std_p2pwecouldnotfindyou: 'no pudimos encontrarte',
    txt_global_std_enablemotionfitnesstogetsupportforstartingandendingyourtripsautomatically: 'Habilite Motion & Fitness para obtener asistencia para iniciar y finalizar sus viajes automáticamente.',
    txt_global_std_pushinsurancerenewalodorembody: 'Su período de seguro vence pronto\n' +
      'Recuerde tomar una foto de su odómetro antes de que finalice el período del seguro.',
    txt_global_std_p2ppushtitleaddadmin: 'Derechos de administrador agregados',
    txt_global_std_p2ppushbodyexpensesapproved: '{name} aprobó sus gastos adicionales.',
    txt_global_std_zipdriver_upsell_pitch_bulletpoint2: 'Incorporar el riesgo de ubicación en el programa de precios/seguridad del seguro',
    txt_global_std_iamapassenger: 'soy un pasajero',
    txt_global_std_kintoEncouragement3: '¡Gran trabajo! ¡Tu último viaje fue mejor que el promedio de Kinto!',
    txt_global_std_ongoing: 'En curso',
    txt_global_std_areyousureyouwanttoredeempoints: '¿Seguro que quieres canjear puntos?',
    txt_global_std_pwdfiveandtwice: 'Su contraseña debe tener al menos cinco (5) caracteres y debe escribirse dos veces.',
    txt_global_std_selectdamagereport: 'Seleccione Informe de daños',
    txt_global_std_autojoinchallenges: 'Unirse automáticamente a los desafíos',
    txt_global_std_noreviewsselectedtobepublished: '¡Ninguna reseña seleccionada para ser publicada!',
    txt_global_std_namesmayonlyincludelettersandspaces: 'Los nombres solo pueden incluir letras y espacios.',
    txt_global_std_kintofaq2q: '¿Cómo conduzco bien?',
    txt_global_std_p2pyesdelete: 'si, eliminar',
    txt_global_std_onthistypeoftriptheaverageecochampionspentmoretimedecreasingspeedleavingasafefollowingdistanceenablesyoutodecreaseyourspeedsteadilyandsmoothly: 'En este tipo de viaje, el campeón ecológico promedio pasó más tiempo disminuyendo la velocidad. Dejar una distancia de seguimiento segura le permite disminuir su velocidad de manera constante y suave.',
    txt_global_std_ecotips: 'Tu puntuación ecológica mide qué tan ecológico conduces. De muchas maneras se puede conectar a su planificación.\n' +
      '\n' +
      'Ajusta tus aceleraciones para evitar frenos y paradas innecesarias. Cada aceleración y cada frenado consumen energía.\n' +
      '\n' +
      'Juntos podemos marcar la diferencia para el futuro.',
    txt_global_std_nicestreak: '¡Buena racha!',
    txt_global_std_finalizepersonalinformation: 'Finalizar información personal',
    txt_global_std_downloadthefiasdcappbyclickingthelinkonyourmobilephone: 'Descargue la aplicación FIA SDC haciendo clic en el enlace en su teléfono móvil',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint2: 'Reforzar los buenos comportamientos a través de la gamificación',
    txt_global_std_alertweredrivingyoudrivingtitle: '¿Estabas conduciendo?',
    txt_global_std_presstoloadmore: 'Presiona para cargar más',
    txt_global_std_awardedbadges: 'Insignias otorgadas',
    txt_global_std_hereyouloweryoursubscriptioncostbydrivingsafe: 'Aquí reduce el costo de su suscripción conduciendo con seguridad.',
    txt_global_std_activateinsurance: 'Activar seguro',
    txt_global_std_settingupprofile: 'Configuración de perfil',
    txt_global_std_completeinfoandcreateaccount: 'Completar info y crear cuenta',
    txt_global_std_kintofaq1q: '¿Tengo que activar la aplicación cada vez que conduzco?',
    txt_global_std_yourmessagehasbeensent: '¡Tu mensaje ha sido enviado!',
    txt_global_std_choosingbalwaysallowbisnecessarytogetcorrectstatsaboutyourdriving: 'Elegir <b>Permitir siempre</b> es necesario para obtener estadísticas correctas sobre su conducción.',
    txt_global_std_servicedandsoldby: 'Revisado y vendido por',
    txt_global_std_ayesyoucandownloadtheappandchoosetheoptiontrytheappyouwillbeabletodrivewiththeappandseehowmuchyoucouldsaveifyousignedupforagentinsurance: 'R: Sí, puede descargar la aplicación y elegir la opción "probar la aplicación". Podrá conducir con la aplicación y ver cuánto podría ahorrar si se inscribiera en el seguro {agent}.',
    txt_global_std_p2pwhenyougiveyourfriendadminrightstheycanviewandhandlebookingsofyourcar: 'Cuando otorga a su amigo derechos de administrador, puede ver y manejar las reservas de su automóvil.',
    'txt_global_std_Click the plus sign to create a new challenge.': 'Haga clic en el signo más para crear un nuevo desafío.',
    txt_global_std_mydrivingstats: 'las estadisticas de mi auto',
    txt_global_std_alreadyhaveacovercubepolicy: '¿Ya tienes una póliza de Covercube?',
    txt_global_std_kintofaq8a: 'Los ahorros se generan cuando se conduce seguro. Por cada viaje que conduzcas seguro, generarás un ahorro por kilómetro. ¡Cuanto más seguro conduzca, mayor será el ahorro! El ahorro es visible directamente después de que termine su viaje y será visible en su billetera Kinto.',
    txt_global_std_WelcomeLetter_Text5: 'Sinceramente,',
    txt_global_std_PaddonMileage: 'Me encanta conducir por las carreteras secundarias de Nueva Zelanda y explorar nuevos lugares. La costa oeste de la Isla Sur es un lugar muy especial, ya que te sientes tan aislado al mismo tiempo que conduces por sorprendentes y sinuosas carreteras escénicas.',
    txt_global_std_TidemandWhyjoin: '¡Porque juntos somos imbatibles y siempre aspiramos a lo más alto!',
    txt_global_std_accident: 'Accidente',
    txt_global_std_enerfycoachtip2: '2. Mantenga una gran distancia con el automóvil de adelante, incluso a baja velocidad.',
    txt_global_std_learnmoreaboutpermissions: 'Obtenga más información sobre los permisos',
    txt_global_std_rwmyoucanonlyregistertripsmadewithinthelastday: 'Solo se pueden registrar viajes realizados en el último día',
    txt_global_std_nooftrips: 'Nº de viajes',
    txt_global_std_p2pongoing: 'En curso',
    txt_global_std_p2pbooking: 'Reserva',
    txt_global_std_setuplocationservices: 'Configurar servicios de ubicación',
    txt_global_std_cancel: 'Cancelar',
    txt_global_std_VilliersChamp: '4 veces campeón de turismos SA, 7 podios en el Dakar, 1 victoria en el Dakar, 3 veces ganador del rally de Marruecos, 2008 ganó el Rally de Portugal, 2008 ganador del Rally Dos Sertoes de Brasil.',
    txt_global_std_p2psafedriverdiscount: 'descuento de conductor seguro',
    txt_global_std_enablemotionfitness: 'Habilite Motion & Fitness en su teléfono.',
    txt_global_std_setupcomplete: '¡Instalación completa!',
    txt_global_std_gettingtheappready: 'Preparando la aplicación',
    txt_global_std_TomczykMusic: 'Lo que escucho cuando conduzco depende de mi estado de ánimo. Me gusta mucho el hardrock, como Dio y Manowar, pero también escucho listas musicales actuales.',
    txt_global_std_youhavealreadyorderedanobdreader: 'Ya ha pedido un lector OBD',
    txt_global_std_theenerfyteam: 'El equipo de Enerfy',
    txt_global_std_loyaltylevelrewards: 'Este nivel de lealtad le da derecho a una {recompensa}',
    txt_global_std_invitetherestofyourteam: 'Invita al resto de tu equipo',
    txt_global_std_teams: 'equipos',
    txt_global_std_joinchallenge: 'Únete al desafío',
    txt_global_std_coach: 'Entrenador',
    txt_global_std_appcreator_upsell_pitch: 'Cree y personalice su propia aplicación de conducción, sin necesidad de codificación, para;',
    txt_global_std_youhavenowdriven25tripswithtrygdrive: 'Ya ha realizado 25 viajes con Tryg Drive',
    txt_global_std_c02overview: 'Resumen de C02',
    txt_global_std_thankyou: '¡Gracias!',
    txt_global_std_gotoyourpreferredservicecenterandshowthemthispopup: 'Vaya a su centro de servicio preferido y muéstreles esta ventana emergente.',
    txt_global_std_mystats: 'Mis estadísticas este año',
    txt_global_std_p2pviewexpenses: 'ver gastos',
    txt_global_std_p2pamountshouldbeshownonthereceipt: '*La cantidad debe mostrarse en el recibo.',
    txt_global_std_cimage11d: 'Apague siempre el motor en parada',
    txt_global_std_p2pemailbestregards: 'Saludos cordiales, <br> el equipo de {appname}',
    txt_global_std_p2ppleaseuploadareceiptpicture: 'Sube una foto del recibo',
    txt_global_std_wecannotfindthecaridentityenteractivationcode: 'No podemos encontrar la identidad del coche, introduce el código de activación que tienes en el correo de bienvenida.',
    txt_global_std_onboardingnotificationsheader: 'Notificaciones',
    txt_global_std_takethefootofthepedalandletthecarcoastasmuchasitmakesensesavesfuel: 'Tome el pie del pedal y deje que el automóvil se deslice tanto como tenga sentido, ahorra combustible.',
    txt_global_std_sonicethatyoudecidedtojoinusletssetupyourprofile: '¡Qué bueno que hayas decidido unirte a nosotros! Déjanos configurar tu perfil.',
    txt_global_std_mystatstext: 'Mis estadísticas',
    txt_global_std_successfullyupdatedtranslation: 'Traducción actualizada con éxito',
    txt_global_std_p2pemailnewsavailableinappcarSharing: 'Novedades disponibles en {appname} - Car Sharing',
    txt_global_std_origin: 'Origen',
    txt_global_std_nice: '¡Agradable!',
    txt_global_std_inviteusers: 'Invitar usuarios',
    txt_global_std_choosethetypeofbusinessthatdescribesyourcompanythebest: 'Elija el tipo de negocio que mejor describa a su empresa.',
    txt_global_std_polish: 'Polaco',
    txt_global_std_startdrive: 'Iniciar viaje',
    txt_global_std_gameonyourchallengehasbeenaccepted: '¡Juego encendido! ¡Tu desafío ha sido aceptado!',
    txt_global_std_icannotconnect: '¿No puedo conectarme?',
    txt_global_std_p2pmaximum9999999allowedforanexpense: 'Máximo {moneda} 9,999,999 permitido para un gasto',
    txt_global_std_earnsavingsintoyourkintowalletforsafedriving: 'Obtenga ahorros en su billetera Kinto - para una conducción segura:',
    txt_global_std_dragme: '¡Arrástrame!',
    txt_global_std_challenge: 'Desafío',
    txt_global_std_100kmlockedkey: 'Conduce una distancia de 100 km para ganar esta insignia.',
    txt_global_std_Fia_Receipt_Text17: 'Mayor que, Karlavägen 60, 114 49 Estocolmo Suecia. Número de organización: 556608-3258',
    txt_global_std_namesmayonlyincludeletters1: 'Los nombres solo pueden incluir letras.',
    txt_global_std_PolicySchedule25: 'Total adeudado',
    txt_global_std_preferredrequirementaccuracy: 'Precisión del requisito preferido',
    txt_global_std_p2padditionalexpense: 'Gasto adicional',
    txt_global_std_signupandsavemoneyonyourinsurance: 'Regístrate y ahorra dinero en tu seguro',
    txt_global_std_search: 'Búsqueda',
    txt_global_std_emailhasbeensent2: '¡E-mail ha sido enviado!',
    txt_global_std_p2pfaqcontactinfo2: '・ Tenga en cuenta que puede ser difícil conectarse cuando hay mucha gente.',
    txt_global_std_communication_upsell_pitch: 'Diseñe fácilmente su propio programa de mensajería y comunicación para;',
    txt_global_std_pointstounlock: 'Puntos para desbloquear.',
    txt_global_std_endday: 'Día final',
    txt_global_std_ifyouhaveaninsuranceyoucanfindyourpolicynumberinyourappnameaccountdonthaveitathandrightnownoworriesyoucanadditlaterinsettings: 'Si tiene un seguro, puede encontrar su número de póliza en su cuenta de {appname}.\n' +
      '¿No lo tienes a mano ahora mismo? ¡Sin preocupaciones! Puedes agregarlo más tarde en la configuración.',
    txt_global_std_ChicheritChamp: 'Próximamente, en breve, pronto...',
    txt_global_std_allmonth: 'Todo el mes',
    txt_global_std_gettheapp: 'Obtener la aplicación',
    txt_global_std_everymonth: 'Cada mes',
    txt_global_std_youaredriving: 'Estás conduciendo',
    txt_global_std_secondarycolor: 'Color secundario',
    txt_global_std_p2papprove: 'Aprobar',
    txt_global_std_latest10: 'Últimos 10',
    txt_global_std_cimage11h: 'GIRO DE MOTOR',
    txt_global_std_yourdatais: 'Tus datos son =',
    txt_global_std_numberofrepetitions: 'Número de repeticiones',
    txt_global_std_warikan_onboarding2_2: 'Muchas gracias por su solicitud para la aplicación "Warikan KINTO".\n' +
      'Utilice la siguiente contraseña temporal para crear una cuenta en la aplicación.',
    txt_global_std_authenticating: 'autenticando',
    txt_global_all_sunday: 'Domingo',
    txt_global_std_HusseinAbdullaChamp: '2016 ganó el Campeón del Mundo FIA T2',
    txt_global_std_nodataavailable: 'Datos no disponibles',
    txt_global_std_signinginwiththirdpartyname: 'Iniciando sesión con {thirdPartyName}...',
    txt_global_std_RemoveAccount_text1: 'Hola\n' +
      'Hemos recibido su deseo de eliminar su información en su cuenta. Debe tener en cuenta que los datos eliminados no se pueden recuperar. Se eliminarán su cuenta y todos sus datos, incluidos los registros de conducción, los equipos, las fechas de las carreras y las competiciones.\n' +
      '\n' +
      'Si es un cliente de seguros, su información no se eliminará hasta 1 año después de que haya completado su seguro y todos los casos estén cerrados. Eliminar su cuenta no finaliza su seguro.\n' +
      ' \n' +
      'Eliminar cuenta haciendo clic en el siguiente enlace:\n' +
      '{{remover enlace}}\n' +
      ' \n' +
      'Si tiene alguna pregunta, contáctenos en info@energy.se o 08-555 932 00',
    txt_global_std_fiaquarterfinalqualifyrule2020: 'Debes participar en al menos 4 eliminatorias para calificar para la Final de Calificación.',
    txt_global_std_cardwithmoreinformationaboutgdpr: 'Tarjeta con más información sobre el RGPD',
    txt_global_std_bluetoothsystem: 'sistema Bluetooth',
    txt_global_std_nophotosadded: 'No se agregaron fotos',
    txt_global_std_SignupFia_Text7: '¡Descarga la aplicación!',
    txt_global_std_fiasdcsmartscore: 'Puntuación inteligente FIA SDC',
    txt_global_std_Insurance_Chat: 'Charlar',
    txt_global_std_youcanaddtranslationbyclickinghere: 'Puede agregar la traducción haciendo clic aquí.',
    'txt_global_std_de-villiersDescription': 'Giniel De Villiers nunca se ha retirado. Mejor aún, solo una vez no ha terminado en el Top 10 (en 2007). Tiene 14 victorias de etapa y en 2009 ganó el primer Dakar de Sudamérica.',
    txt_global_std_valuedisconnctedtripsofmaxmaxvalue: '{value}% de viajes desconectados (del máximo {maxvalue}%)',
    txt_global_std_digitaltraining: 'Formación digital',
    txt_global_std_energysaved: 'Energía ahorrada',
    txt_global_std_theoreticaltimesaving: 'Ahorro de tiempo teórico',
    txt_global_std_reactivateyourinsurancetokeepyourcoverage: 'Reactiva tu seguro para mantener tu cobertura.',
    txt_global_std_incorrectpassword: 'Contraseña incorrecta',
    txt_global_std_MSIG_Onboarding10: '¿Tiene usted alguna pregunta? Comuníquese con nuestro equipo de soporte al 0123456789 o envíenos un correo electrónico a info@msfc.com.sg.',
    txt_global_std_splits: 'Divisiones',
    txt_global_std_youhavebeeninvitedtojoin: 'Has sido invitado a unirte:',
    txt_global_std_finishingyourscore: 'Terminando tu puntaje..',
    txt_global_std_companychallengename2: 'Nombre del desafío de la empresa',
    txt_global_std_requestssent: 'Solicitud enviada',
    txt_global_std_pleaseseethefinishedreport: 'Por favor vea el informe terminado en',
    txt_global_std_creationdate: 'Fecha de creación',
    txt_global_std_p2pfaq10a: 'Visite https://kinto-jp.com/insurance/ para obtener más información.',
    txt_global_std_policyholder: 'Tenedor de una póliza',
    txt_global_std_rwmnoeligibletripstitle: 'No pudimos encontrar ningún viaje en el último día.',
    txt_global_std_myscore: 'Mi puntaje',
    txt_global_std_p2papply: 'Aplicar',
    txt_global_std_shootingstarlockedkey: 'Termina un viaje en el nivel 3.3 o superior para desbloquear este logro y ganar 100 puntos para tu billetera Kinto.',
    txt_global_std_riskindex_upsell_pitch: 'Convierta el conocimiento en acción con esta sofisticada herramienta que combina noticias, datos y análisis de riesgos para identificar;',
    txt_global_std_termsofservice: 'Términos de política y privacidad del servicio',
    txt_global_std_youneedtoaddatleast8characters: 'Debe agregar al menos 8 caracteres. Incluyendo 1 minúscula (a-z), 1 mayúscula (A-Z) y 1 número (0-9).',
    txt_global_std_changingyouremaildoesnotchangeyourusername: 'Cambiar tu correo electrónico no cambia tu nombre de usuario',
    txt_global_std_reallynicerun: 'muy buena carrera',
    txt_global_std_agreementcreated: 'Acuerdo creado',
    txt_global_std_influencer: 'Hombre de influencia',
    txt_global_std_p2pfaq19q: 'Hay tramos en los que no se registran datos de conducción.',
    txt_global_std_orderobddeviceto: 'Ordene el dispositivo OBD a:',
    txt_global_std_analytics: 'Analítica',
    txt_global_std_letsdrivewithwarikankintopushbody: '¡Conduzcamos con Warikan KINTO y recolectemos insignias y puntos! ¡Los puntos se podrán canjear por regalos! La función de puntuación y la adquisición de insignias están disponibles incluso si no es usuario de KINTO ONE.',
    txt_global_std_p2pforgotwhereyouparkedyourcarnoworrieswegotyoucoveredjustpressthisbuttonandwewillfinditforyou: '¿Olvidaste dónde estacionaste tu auto? No se preocupe, lo tenemos cubierto. ¡Solo presiona este botón y lo encontraremos para ti!',
    txt_global_std_kintofaq5a: 'Informe al equipo de Kinto por correo electrónico. Luego, vuelva a conectarse a su nuevo automóvil siguiendo los pasos de "Conectar al automóvil" nuevamente.',
    txt_global_std_annualcarinsuranceprice: 'Precio anual del seguro de coche',
    txt_global_std_acceptlocationservices: 'Aceptar servicios de ubicación',
    txt_global_std_p2paddedmemberstothegroup: 'miembros agregados al grupo',
    txt_global_std_youcancontactyourinsuranceagency: 'Puede ponerse en contacto con su agencia de seguros.',
    txt_global_std_change: 'Cambio',
    txt_global_std_savechangespopuptext: 'Los cambios no guardados se perderán. ¿Guardar cambios antes de cerrar?',
    txt_global_std_tomasengequote: 'Si quieres alcanzar tus metas debes hacer todo por ello',
    txt_global_std_SendMyPosition_Text2: 'Sinceramente,\r\n{nombre de la aplicación}',
    txt_global_std_whatwillhappenifidonotconnectmycar: '¿Qué pasará si no conecto mi coche?',
    txt_global_std_ayourscoreisbasedonthedrivingpatternsyoucreatewitheachtripconcentrateonyourdrivingskillstoimproveyourscoreswewillprovideahistoryofallyourtripssoyoucanseehowyouredoing: 'R: Su puntuación se basa en los patrones de conducción que crea con cada viaje. Concéntrate en tus habilidades de conducción para mejorar tus puntuaciones. Le proporcionaremos un historial de todos sus viajes para que pueda ver cómo le va.',
    txt_global_std_ifyouwanttolendyourcartoafriendyoushouldinviteyourfriendtotheappbytappingthebuttonbelowthisensuresyourcarisstilltrackedbyloyaltysystem: 'Si desea prestar su automóvil a un amigo, debe invitar a su amigo a la aplicación tocando el botón a continuación.\n' +
      'Esto asegura que su automóvil aún sea rastreado por el sistema de lealtad.',
    txt_global_std_acceptconsent: 'Aceptar consentimiento',
    txt_global_std_5000pointaverageachievementlocked: 'Conduzca con un {scoretype) promedio de {score} o mejor durante una semana para desbloquear este logro y ganar 5000 puntos.',
    txt_global_std_bycontinuingyouaccept: 'Al continuar aceptas nuestra',
    txt_global_std_kintoquestions: 'KINTO Preguntas:',
    txt_global_std_co2besparelse: 'CO2 reducido',
    txt_global_std_yourdatahasbeensent2: 'Tus datos han sido enviados, por favor revisa tu bandeja de entrada!',
    txt_global_std_apitool_upsell_pitch_bulletpoint2: 'Transferencia rápida y segura de los datos del conductor',
    txt_global_std_wewillnotbeabletoanalyzeyourdriveand: 'No podremos analizar su unidad y se le facturará en el nivel premium 10.',
    txt_global_std_HansenMusic: 'Escuchar música mientras se conduce puede quitarle mucha concentración. Pero al salir a las autopistas y conducir, realmente disfruto escuchar música que me haga participar y cantar.',
    txt_global_std_uploadpicturestooldaccidentreport: 'Cargue fotos de su automóvil dentro de las 24 horas posteriores a su informe. Puede hacer clic en su informe anterior a continuación para agregarle imágenes.',
    txt_global_std_insureddistance_used_100__2: '¡Utah! Öka årlig körsträcka här.',
    txt_global_std_edituser: 'Editar usuario',
    txt_global_std_opendocument: 'Abrir documento',
    txt_global_std_orderobdreader: 'Solicitar lector OBD',
    txt_global_std_qdoihavetoactivatetheappeverytimeidrive: 'P: ¿Tengo que activar la aplicación cada vez que conduzco?',
    txt_global_std_friday: 'Viernes',
    txt_global_std_otherdocuments: 'Otros documentos',
    txt_global_std_insuranceisrenewedplaceholder: 'Solo te quedan 63 km.\nTu seguro se renueva 2021-10-29',
    txt_global_std_manageapikeys: 'Administrar claves API',
    txt_global_std_zipcode2: 'Código postal',
    txt_global_std_couldnotconnect1: '¡No podía conectar!',
    txt_global_std_minutesandcanonlybeusedonce: 'minutos y solo se puede usar una vez.',
    txt_global_std_brakevca: 'Freno V/C/A',
    txt_global_std_insurancestatus: 'estado del seguro',
    txt_global_std_jointaveragescore: 'Puntaje promedio conjunto',
    txt_global_std_deactivateuserfrom: 'Desactivar usuario de:',
    txt_global_std_takecareoftheinjured: 'Cuidar de los heridos',
    txt_global_std_tryglocationservice: 'Permitir el acceso a la ubicación - Seleccionar siempre',
    txt_global_std_duration: 'Duración',
    txt_global_std_p2ppleaseselectpaymentmethod: 'Por favor seleccione el método de pago',
    txt_global_std_manualstartstoprequiresaccess: 'El inicio y la parada manuales requieren acceso a los servicios de ubicación (GPS) y movimiento y estado físico.',
    txt_global_std_hellothisisanonboardingtext: 'Hola, este es un texto de incorporación.',
    txt_global_std_startcarengine: '1. Arranque el motor de su automóvil.',
    txt_global_std_yournumber: 'Tu número',
    txt_global_std_yourfriendsemail: 'El correo electrónico de tus amigos',
    txt_global_std_theamountisenteredinthepiggybankwhentherecruitmentcodehasbeenregisteredyouhaveafulltimeoverviewofyourrecruitsonthesavingsside: 'El importe se introduce en la hucha cuando se ha registrado el código de contratación. Tiene una visión general a tiempo completo de sus reclutas en el lado de los ahorros.',
    txt_global_std_p2pyouhavenocar: 'no tienes coche',
    txt_global_std_gohome: 'Vete a casa',
    txt_global_std_beonestepahead: 'Estar un paso por delante',
    txt_global_std_connectingtocar: 'Conexión al coche',
    txt_global_std_TidemandChamp: 'Campeón WRC 2 2017, Campeón de Turismos 2010',
    txt_global_std_skillup: 'Habilidad',
    txt_global_std_cimage1h: 'REDUCE TU VELOCIDAD MÁXIMA',
    txt_global_std_pleasenotethatifyouhavealargerproportionofdisconnectedtripsyoumaynotbepaidadrivingdividendremembertodrivewiththeappconnected: 'Tenga en cuenta que si tiene una mayor proporción de viajes desconectados, es posible que no reciba un dividendo de conducción. Recuerda conducir con la app conectada.',
    txt_global_std_MSIG_Password2: 'Ha solicitado una nueva contraseña para su cuenta de MS First Capital. Su nueva contraseña para el usuario',
    txt_global_std_2pairyourphonewithyourcarviabluetooth: '2. Empareje su teléfono con su automóvil a través de Bluetooth.',
    txt_global_std_TidemandMileage: 'Prefiero caminos más pequeños con giros y vueltas rodeados de hermosos paisajes en comparación con las autopistas. Es más divertido de conducir y nunca sabes lo que te puedes encontrar después de la próxima curva. Suecia o cualquier otro lugar del mundo no importa, descubrir nuevos caminos siempre es divertido.',
    txt_global_std_cimage14h: 'UTILIZAR CUESTA ABAJO',
    txt_global_std_category: 'Categoría',
    txt_global_std_p2psplityourcostfor: 'Divide tu costo por {mes}',
    txt_global_std_cantfindyourchallenge: '¿No encuentras tu desafío?',
    txt_global_std_inspiringsmarterdrivinghelpsreducefueluseco2footprintandcrashes: 'Inspirar una conducción más inteligente ayuda a reducir el consumo de combustible, la huella de CO2 y los accidentes',
    txt_global_std_OSolbergWhyjoin: 'Próximamente, en breve, pronto...',
    txt_global_std_HusseinAbdullaMusic: 'Música tranquila',
    'txt_global_std_Al-KhelaifiBesttip': 'Ir a casa después del trabajo.',
    txt_global_std_loading: 'Cargando...',
    txt_global_std_drivenotregistered: 'Unidad no registrada',
    txt_global_std_nov: 'Nov',
    txt_global_std_p2pnogoback: 'no, vuelve',
    txt_global_std_exploretheapp: 'Explora la aplicación',
    txt_global_std_login1: 'Acceso',
    txt_global_std_ridewithmesubtitle: '¡Gana 300 puntos por compartir viajes!',
    txt_global_std_successfullycreatedgroup: 'Grupo creado con éxito',
    txt_global_std_carinformation: 'Información del coche',
    txt_global_std_september: 'Septiembre',
    txt_global_std_removealldatafromthisaccount: '¿Eliminar todos los datos de esta cuenta?',
    txt_global_std_TomczykWhyjoin: 'Mi objetivo no es solo el objetivo obvio de ganar. También es la parte divertida de unirse y luchar durante las eliminatorias para calificar como el mejor y más inteligente equipo.',
    txt_global_std_ratedrivingriskbasedonactualdrivinglocationsnothomeorworkplace: 'Califique el riesgo de conducción en función de los lugares de conducción reales, no del hogar o el lugar de trabajo.',
    txt_global_std_june: 'Junio',
    txt_global_std_invitecoworkersandstartcompetingagainsteachothertobecomebetterdriversandsavemoreco2: 'Invite a sus compañeros de trabajo y comience a competir entre sí para convertirse en mejores conductores y ahorrar más CO2.',
    txt_global_std_weliketorewardyousoeverytripyoudrivesafeyouwillearn: 'Nos gusta recompensarlo, por lo que en cada viaje que conduzca de manera segura obtendrá un ahorro. ¡Cuanto más seguro conduzcas, más ahorrarás! Los ahorros se recogen inmediatamente aquí en su billetera.',
    txt_global_std_PSolbergInterests: 'Próximamente, en breve, pronto...',
    txt_global_std_p2ptounlockyouraccesstotheappandthebenefitsregisteratthewebsite: 'Para desbloquear su acceso a la aplicación y los beneficios, regístrese en el [Sitio web]',
    txt_global_std_tirerotationunload: 'Rotación de neumáticos, descarga…',
    txt_global_std_whatmusicdoyouliketolistentowhenyoudrive: '¿Qué música te gusta escuchar cuando conduces?',
    txt_global_std_yourinsurancewasautomaticallyrenewedformonthwithadiscountonyourpremium: 'Su seguro se renovó automáticamente por {mes} con un descuento en su prima.',
    txt_global_std_cimage29d: 'Cuando la carretera y el tráfico lo permitan. Engranaje en dos pasos.',
    txt_global_std_thisisatestphraseforonboarding: 'Esta es una frase de prueba para la incorporación.',
    txt_global_std_issomeoneelsegoingtodriveyourcar2: '¿Alguien más va a conducir tu coche? Envíales un enlace para que puedan usar la aplicación.',
    txt_global_std_reportaccidentheresmile: '¡Reporta accidentes aquí! :-)',
    txt_global_std_locationaccess: 'Acceso a la ubicación',
    txt_global_std_p2ppushtitlebookingcomingup: 'próxima reserva',
    txt_global_std_p2pyoucanfindcarsrharingin: 'Puede encontrar Car Sharing en el menú (tres puntos) a continuación.',
    txt_global_std_whatifichangecarlexusanswer: 'Informe al equipo de Lexus por correo electrónico. Luego, vuelva a conectarse a su nuevo automóvil siguiendo los pasos de "Conectar al automóvil" nuevamente.',
    txt_global_std_optional: 'Opcional',
    txt_global_std_registerridesharingtrip: 'Registrar viaje de viaje compartido',
    txt_global_std_qhowdoisetupautomaticrenewal: 'P: ¿Cómo configuro la renovación automática?',
    txt_global_std_yourinsurancewasautomaticallyrenewedformonth: 'Su seguro se renovó automáticamente por {mes}.',
    txt_global_std_willbedeletedimmediatelyandpermanentlyoncedeleteditcannolongerbeusertomakeapirequests: 'será eliminado de forma inmediata y permanente. Una vez eliminado, ya no puede ser usuario para realizar solicitudes de API.',
    txt_global_std_exampleofvalidemailaddresses: 'Ejemplo de direcciones de correo electrónico válidas:',
    txt_global_std_drivewellchallenge: 'Conducir bien desafío',
    txt_global_std_gotoaservicecentertoredeemyourrewards: 'Vaya a un centro de servicio para canjear sus recompensas.',
    txt_global_std_removeuserfromgroup: 'Eliminar usuario del grupo',
    txt_global_std_p2pto: 'a',
    txt_global_std_customemail: 'Correo electrónico personalizado',
    txt_global_std_p2ppending: 'Pendiente',
    txt_global_std_youraveragescoreis: 'Tu nivel medio es',
    txt_global_std_wehavetoaccesslocationservicesinthebackgroundwhile: 'Tenemos que acceder a los servicios de ubicación en segundo plano mientras te concentras en conducir. Cuando aparezca esta ventana emergente, seleccione "Permitir siempre".',
    txt_global_std_MSIG_InsuranceLetter28: 'POR LA PRESENTE CERTIFICO/CERTIFICO que la Póliza a la que se refiere este Certificado se emite de conformidad con las disposiciones de la Ley de Vehículos Motorizados (Compensación y Riesgos de Terceros) (Capítulo 189) y la Parte IV de la Ley de Transporte por Carretera de 1987 (Malasia)',
    txt_global_std_avgscore: 'Promedio puntaje',
    txt_global_std_avgrisklevel123: 'Promedio nivel de riesgo',
    txt_global_std_deepdiveintodrivercrash: 'Sumérjase en la probabilidad de choque del conductor, el riesgo asumido y más estadísticas',
    txt_global_std_p2pfaq29q: 'Por favor, dime cómo pedir prestado un coche.',
    txt_global_std_contactdetailsfromyouraccountpleaseeditdetailsifneeded: 'Detalles de contacto de su cuenta, edite los detalles si es necesario.',
    txt_global_std_thischallengeactiveandupcomingchallenges: 'Este desafío, desafíos activos y próximos',
    txt_global_std_p2pfaq29a: '1. Selecciona "Alquilar un coche" en la pantalla de inicio.\n' +
      '2. Seleccione el propietario del automóvil que desea alquilar y seleccione el automóvil.\n' +
      '3. Seleccione la fecha y la hora.\n' +
      '4. Ingrese su mensaje (opcional) y finalmente seleccione "Enviar Solicitud".\n' +
      '5. La solicitud de reserva se retendrá hasta que el socio la apruebe o la rechace.',
    txt_global_std_last10drives: 'Últimos 10 viajes',
    txt_global_std_nousersfoundinviteuserstogetstarted: 'No se encontraron usuarios. Invite a los usuarios a comenzar.',
    txt_global_std_statusnotconnected: 'No conectado',
    txt_global_std_edit: 'Editar',
    txt_global_std_reasonforpoints: 'Motivo de los puntos',
    txt_global_std_connecting: 'Conectando',
    txt_global_std_appquestions: 'Preguntas de la aplicación:',
    txt_global_std_back1: 'atrás',
    txt_global_std_fileisattached: 'El archivo está adjunto',
    txt_global_std_max30characters: '30 caracteres como máximo',
    txt_global_std_badges: 'Insignias',
    txt_global_std_yourpwwasnotchanged: 'Su contraseña no fue cambiada. ¡Pruebe otra contraseña nueva o escriba una contraseña actual válida!',
    txt_global_std_p2pnotincluded: 'No incluido',
    txt_global_std_p2pdistancetravellednotrecommended: 'Distancia recorrida no recomendada.',
    txt_global_std_entervalidmessage: 'Ingresa un mensaje de texto a tu amigo',
    txt_global_std_addpolicynumber: 'Agregar número de póliza',
    txt_global_std_visibleinmaps: 'Visible en mapas',
    txt_global_std_MSIG_InsuranceLetter22: '2) Cualquier otra persona que conduzca por orden del Asegurado o con su permiso.',
    txt_global_std_challengename: 'Nombre del desafío',
    txt_global_std_takemetotheappinstead: 'Llévame a la aplicación en su lugar',
    txt_global_std_areyousureyouwanttodeletethismessage: '¿Seguro que quieres borrar este mensaje?',
    txt_global_std_cantfindanybluetoothdevice: 'No se puede encontrar ningún dispositivo Bluetooth.',
    txt_global_std_co2savingsscore: 'Ahorro de CO₂',
    txt_global_std_youareoffline: 'Estas desconectado',
    txt_global_std_improvementareaforspeed: 'Área de mejora para la velocidad',
    txt_global_std_p2pfaq31q: '¿Dónde puedo consultar los datos de conducción?',
    txt_global_std_createyouraccount: 'Crea tu cuenta',
    txt_global_std_anonymoususer: 'Usuario anónimo',
    txt_global_std_pointslefttoreach: 'puntos que quedan por alcanzar',
    txt_global_std_thistrip: 'Este viaje',
    txt_global_std_weeklyachievements: 'logros semanales',
    txt_global_std_duedate: 'Fecha de vencimiento',
    txt_global_std_options: 'Opciones',
    txt_global_std_consentleaderboard: 'Consentimiento para nuestros concursos y contacto',
    txt_global_std_namehasinvitedyoutojointheirgroup: '{name} te ha invitado a unirte a su {grupo}',
    txt_global_std_MSIG_InsuranceLetter32: 'Firma autorizada',
    txt_global_std_mydrives: 'mis unidades',
    txt_global_std_totaldistancekm: 'Distancia total (km)',
    txt_global_std_35000pts: '&#62;35 000 puntos',
    txt_global_std_pluginthedevice: 'Enchufe el dispositivo',
    txt_global_std_comment: 'Comentario',
    txt_global_std_thecontactinformationyousubmithereiswhatwewillusetocontactyouregardingtheappandsendyoutheappsdownloadlink: 'La información de contacto que envíe aquí es la que usaremos para contactarlo con respecto a la aplicación y enviarle el enlace de descarga de la aplicación.',
    txt_global_std_enteryearlyprice: 'Ingrese el precio anual',
    txt_global_std_welcometo: 'Bienvenido a',
    txt_global_std_repeatthechallengedailyweeklymonthlyorannually: 'Repita el desafío diariamente, semanalmente, mensualmente o anualmente.',
    txt_global_std_totaltime: 'Tiempo Total',
    txt_global_std_downloadreport: 'Descargar informe',
    txt_global_std_referafriend: 'Recomendar un amigo',
    txt_global_std_p2pnoconfirmedbookings: 'No hay reservas confirmadas para mostrar.',
    txt_global_std_p2pyourshareofcost: 'Su parte del costo',
    txt_global_std_p2ppushbodycancelledperiodfromto: 'Tenga en cuenta que la reserva {desde} ha sido cancelada. Se ha generado y aceptado otra reserva que se superpone con esta reserva no aceptada.',
    txt_global_std_welcometoenerfy: 'Bienvenido a una nueva experiencia de conducción.',
    txt_global_std_enerfyanalyticsriskportfoliotrackerapp: 'Enerfy Analytics Risk Portfolio Tracker, aplicación',
    txt_global_std_locationservicemustbeontodrivewithdimmedscreen: 'El servicio de ubicación debe estar activado para conducir con la pantalla atenuada; de lo contrario, perderá datos.',
    txt_global_std_weekly: 'Semanalmente',
    txt_global_std_exporttranslations: 'Exportar traducciones',
    txt_global_std_pleasereachouttothesupportifyouseethesameerrorwhenyoutrynexttimelater: 'Comuníquese con el soporte si ve el mismo error la próxima vez que lo intente.',
    txt_global_std_tojointeam: 'Para unirse al equipo:',
    txt_global_std_cimage15h: 'ENFOQUE EN LOS DETALLES',
    txt_global_std_theoreticaltimegain: 'Ganancia de tiempo teórica',
    txt_global_std_youmustchooseareason: 'Debes elegir un motivo',
    txt_global_std_numbersonly: 'Solo numeros',
    txt_global_std_p2pyourexpenses: 'tus gastos',
    txt_global_std_why: 'Por qué',
    txt_global_std_TaxInvoice5: 'POR FAVOR HAGA EL CHEQUE A nombre de MS FIRST CAPITAL INSURANCE LTD',
    txt_global_std_japanese: 'japonés',
    txt_global_std_recruiterunlockedkey: '¡Genial, {usuario}! ¡Invitaste a un amigo a la aplicación!',
    txt_global_std_drivenotstarted: 'Unidad no iniciada',
    txt_global_std_downloadingevents: 'Descarga de eventos',
    txt_global_std_policyholderinformation: 'Información del titular de la póliza',
    txt_global_std_updatecarddetails: 'Actualizar detalles de la tarjeta',
    txt_global_std_sendon: 'Enviar en:',
    txt_global_std_howmanypeoplewereinjured: '¿Cuántas personas resultaron heridas?',
    txt_global_std_settingupclientcredentials: 'Configuración de credenciales de cliente',
    txt_global_std_writefriendspromocode: 'Escribir amigos Código de promoción',
    txt_global_std_p2prequestsenttoname: 'Solicitud enviada a {nombre}',
    txt_global_std_uploadfiles: 'Subir archivos',
    txt_global_std_noteam: 'sin equipo',
    txt_global_std_constantspeed: 'Velocidad constante',
    txt_global_std_wehavesentatemporarypasswordtoyouremailaddress: 'Hemos enviado una contraseña temporal a su dirección de correo electrónico.',
    txt_global_std_analyzeyourdrivinghabitstoimprove: 'Analice sus hábitos de conducción para mejorar sus habilidades de conducción.',
    txt_global_std_p2pallopenexpenses: 'Todos los gastos abiertos',
    txt_global_std_cimage32d: ' ',
    txt_global_std_chassinumber: 'número de chasis',
    txt_global_std_howcanwehelpyou: 'como podemos ayudarte?',
    txt_global_std_insurancedocuments: 'Documentos de seguro',
    txt_global_std_yourchallangeranking: 'Clasificación de tu desafío',
    txt_global_std_p2pacceptedbooking: 'Reserva aceptada',
    txt_global_std_dec: 'Dic',
    txt_global_std_enerfyanalytics: 'Análisis energético',
    txt_global_std_addusers: 'Agregar usuarios',
    txt_global_std_p2pparking: 'Estacionamiento',
    txt_global_std_pointsexpires: 'Los puntos caducan',
    txt_global_std_yourbonuswillbepaidoutinafewdays: 'Su bono se pagará en unos días.',
    txt_global_std_avg: 'Promedio',
    txt_global_std_goheretocreateabookingofyourfriendscar: 'Vaya aquí para crear una reserva del coche de su amigo.',
    txt_global_std_p2ppushtitleexpensesthismonthquestion: 'Gastos este mes?',
    txt_global_std_underwrittenby: 'Suscrito por',
    txt_global_std_cimage35d: 'Por ejemplo, comience con una marcha más alta si comienza cuesta abajo.',
    txt_global_std_tomasengewhyjoin: 'Me encanta ser el mejor detrás del volante y estoy seguro de que todos los que me acompañan tienen el mismo objetivo.',
    txt_global_std_youhavenotfinishedanychallengesyet: 'Aún no has terminado ningún desafío.',
    txt_global_std_jul: 'jul',
    txt_global_std_p2pfilter: 'Filtrar',
    txt_global_std_youremail: 'Tu correo electrónico',
    txt_global_std_kintofaq8q: '¿Cómo gano ahorros?',
    txt_global_std_hi123: 'Hola',
    txt_global_std_civilistatusmarriedcohabitingsingle: 'Estado civil (casado, conviviente, soltero)',
    txt_global_std_whenyoudrivesafelyandwithgoodflowweputmoneyinyourpiggybankyouwillbepaidthesewhentheinsuranceyearisovertogetherwithmoneyforkilometersyoumaynothaveusedthetotalamountincludesstartbonusmoneysavedforsafedrivingandmoneyforfriendrecruitment: 'Cuando maneja con seguridad y con buen flujo, ponemos dinero en su alcancía. Estos se te pagarán cuando finalice el año del seguro, junto con el dinero de los kilómetros que no hayas utilizado. El monto total incluye el bono de inicio, el dinero ahorrado para una conducción segura y el dinero para reclutar amigos.',
    txt_global_std_p2p1booking: '(1 reserva)',
    txt_global_std_p2ppushbodylendcar: '{name} te ha enviado una solicitud de préstamo',
    txt_global_std_damagereport: 'Reporte de daños',
    txt_global_std_areyousureyouwanttoenablethecommunication: '¿Está seguro de que desea habilitar la comunicación?',
    txt_global_std_nowitstimetoconnecttoyourcarsbluetooth: 'Ahora es el momento de conectarse al bluetooth de su automóvil.',
    txt_global_std_thisisneededbeforeyoustartyourfirstdrive: 'Esto es necesario antes de iniciar su primera unidad.',
    txt_global_std_p2punblockcar: 'Desbloquear coche',
    txt_global_std_getstartedinnotimewithourtutorials: '¡Comienza en poco tiempo con nuestros tutoriales!',
    txt_global_std_establishingbluetoothlink: 'Estableciendo enlace bluetooth',
    txt_global_std_topimprovers: 'Mejores mejoradores',
    txt_global_std_zipdriverriskindexinsuranceratingcomponent: 'Índice de riesgo Zip-Driver - Componente de calificación de seguros',
    txt_global_std_sharelink: 'Compartir enlace',
    txt_global_std_drivewithanaveragescoretypeofscoreorbetterforaweektounlockthisachievementandearnpointspoints: 'Conduzca con un {scoretype} promedio de {score} o mejor durante una semana para desbloquear este logro y ganar {points} puntos.',
    txt_global_std_warikan_onboarding6: '2. Inicie la aplicación Warikan KINTO y seleccione "Registrarse"\n',
    txt_global_std_insurancerenew: 'Solo te quedan {km} km. Su seguro se renueva el {fecha}.',
    txt_global_std_safedriverdiscountpercent: 'Descuento de Safedriver {percent}%',
    txt_global_std_MikkelsenDescription: 'Andreas tiene una amplia experiencia en deportes. Ha competido en esquí alpino y fue miembro del equipo nacional junior de esquí alpino. En 2003 y 2004 compitió en motocross, representando también a la selección nacional juvenil. Terminó tercero en el campeonato mundial de rally en 2014, 2015 y 2016, acumulando tres victorias y 20 podios.',
    txt_global_std_loadmore: 'Presiona para cargar más',
    txt_global_std_p2ppartlyavailable: 'Parcialmente disponible',
    txt_global_std_iunderstand: 'entiendo',
    txt_global_std_MSIG_InsuranceLetter36: 'Departamento de Siniestros y Suscripción de Automóviles:',
    txt_global_std_p2presendcode: 'Reenviar codigo',
    txt_global_std_referfriend: 'Recomendar un amigo.',
    txt_global_std_p2pbecomefriendstoviewthisprofile: 'Hazte amigo para ver este perfil',
    txt_global_std_p2pthisemailadressisusedforustosendcommunication: 'Esta dirección de correo electrónico se utiliza para que le enviemos comunicaciones, por ejemplo, si olvida su contraseña.',
    txt_global_std_no: 'No',
    txt_global_std_pigwonunlocked: '¡Felicidades! Has ganado una competición en head2head',
    txt_global_std_p2pmytripscomingsoon: 'Mis viajes – Próximamente',
    txt_global_std_notyetdecided: 'aún no decidido',
    txt_global_std_registrationnumber2: 'Número de registro',
    txt_global_std_insuranceidcard: 'Tarjeta de identificación del seguro',
    txt_global_std_driversidentificationnumber: 'Número de identificación del conductor',
    txt_global_std_repeatchallenge: '¿Repetir desafío?',
    txt_global_std_rewardandendorsesmartbehavior: 'Recompensar y respaldar el comportamiento inteligente',
    txt_global_std_MSIG_InsuranceLetter10: 'Número de vehículo / Número de chasis',
    txt_global_std_KleinschmidtFacts: 'Es la primera y hasta ahora la única mujer que ha obtenido la victoria absoluta en el rally más largo y duro del mundo, el Rally Dakar. Su carrera deportiva comenzó con la motocicleta. Después de 4 rallies en moto, cambió a 4 ruedas en 1995 y se involucró en el desarrollo del coche de rallies más exitoso, el Mitsubishi Pajero Evo, con el que ganó el Dakar en 2001. Además de su trabajo como ponente, ha publicado su libro “Mein Sieg bei der Dakar” – Mi victoria en Dakar.',
    txt_global_std_community: 'Comunidad',
    txt_global_std_qwhathappensififorgettorenewbeforemyinsurancehasexpired: 'P: ¿Qué sucede si olvido renovar antes de que venza mi seguro?',
    txt_global_std_estimateddiscount: 'descuento estimado',
    txt_global_std_score2: 'Puntaje',
    txt_global_std_addprice: 'Añadir precio',
    txt_global_std_p2phowmuchdoyouwanttocharge: '¿Cuánto quieres cobrar?',
    txt_global_std_p2pkintonumber: 'Número de Kinto',
    txt_global_std_kintofaq5q: '¿Y si cambio de coche?',
    txt_global_std_p2pfaq7a: '1. Vaya al resumen de la reserva para esa reserva.\n' +
      '2.Presione el botón cancelar reserva en la parte superior izquierda.',
    txt_global_std_postalcode: 'código postal',
    txt_global_std_Fia_Receipt_Text1: '¡Bienvenidos!',
    txt_global_std_anotheappworksinbackgroundmodemakesureyoualwayskeepbluetoothandlocationservicesactivatedonyourphone: 'R: No, la aplicación funciona en segundo plano. Asegúrese de mantener siempre activados los servicios de ubicación y Bluetooth en su teléfono.',
    txt_global_std_weareinthischallengetomaketheworldsaferandbettertogetherjoinustohelpmakeanimpact: 'Estamos en este desafío de hacer que el mundo sea más seguro y mejor, juntos. ¡Únase a nosotros para ayudar a tener un impacto!',
    txt_global_std_p2pstatus: 'Estado',
    txt_global_std_p2pshareofmonthlyfee: 'Parte de la tarifa mensual',
    txt_global_std_rwmselectthetripsyouwereridesharing: 'Selecciona los viajes que estabas compartiendo',
    txt_global_std_ecoscore123: 'Puntuación ecológica',
    txt_global_std_p2pgreatyourcostsplitwassenttoyourfriends: '¡Excelente! Su división de costos se envió a sus amigos.',
    txt_global_std_activationletter_text14: 'Siga las instrucciones en la aplicación.',
    txt_global_std_youarebeingredirectedtodashboard: 'Está siendo redirigido al panel de control.',
    txt_global_std_advancedadmin_upsell_pitch_bulletpoint3: 'Documentos, políticas y otras herramientas de administración inteligente, todo en un solo lugar',
    txt_global_std_sportscorescore: 'Puntuación deportiva {puntuación}',
    txt_global_std_MSIG_InsuranceLetter11: 'Nombre del asegurado',
    txt_global_std_yourlatestscore: 'Tu última puntuación:',
    txt_global_std_codewasnotfound: 'No se encontró el código',
    txt_global_std_p2pbluetoothconnectionrequired: 'Requiere conexión Bluetooth',
    txt_global_std_onthistypeoftriptheaverageecochampionspentlesstimeincreasingspeedreadingtheroadaheadcanhelpyoudeterminewhetheritissuitabletobuildspeed: 'En este tipo de viaje, el campeón ecológico promedio pasó menos tiempo aumentando la velocidad. Leer el camino por delante puede ayudarlo a determinar si es adecuado para aumentar la velocidad.',
    txt_global_std_topteamdrivers: 'Los mejores pilotos del equipo',
    txt_global_std_mycar: 'Mi coche',
    txt_global_std_weeks: 'Semanas',
    txt_global_std_km: 'kilómetros',
    txt_global_std_cimage13d: 'Deje que su velocidad disminuya y baje ligeramente al subir una colina.',
    txt_global_std_p2ptoyotawalletcomingsoon: 'Billetera Toyota (próximamente)',
    txt_global_std_weresettingupyourchallenge: 'Estamos configurando tu desafío',
    txt_global_std_greatrun: 'gran carrera',
    txt_global_std_welcometocovercube: '¡Bienvenido a Covercube!',
    txt_global_std_sidekickhelpsyouwiththemotivationandtipstodrivebettersaferandmoreecofriendly: 'Sidekick te ayuda con la motivación y los consejos para conducir mejor, de forma más segura y más ecológica.',
    txt_global_std_p2ppushbodynewmessage: 'primera línea del nuevo mensaje',
    txt_global_std_bysigningupiacceptthetermsandconditionsandprivacypolicy: 'Al registrarme, acepto los Términos y Condiciones\n' +
      'y Política de Privacidad.',
    txt_global_std_JyrkiainenMusic: 'Música pop y rap.',
    txt_global_std_totalvalid: 'Total válido',
    txt_global_std_p2ptimes: 'veces',
    txt_global_std_2pairyourphonewithyourcarsbluetoothsystem: '2. Empareje su teléfono con el sistema Bluetooth de su automóvil.',
    txt_global_std_activatebluetooth: 'Activar Bluetooth',
    txt_global_std_schedulemessage: 'Programar mensaje',
    txt_global_std_p2pcurioustoknowhowyoudrivekeeptrackofyourprogressandsestatsaboutyourscoreco2savingsdistanceandmore: '¿Tienes curiosidad por saber cómo conduces? Realice un seguimiento de su progreso y vea estadísticas sobre su puntaje, ahorro de CO2, distancia y más.',
    txt_global_std_p2paddyourexpensesbeforethelastdayofthemonthtogetthemincludedinthenextcostsplit: 'Agregue sus gastos antes del último día del mes para incluirlos en la siguiente división de costos.',
    txt_global_std_p2pdocumentmycarcomingsoon: 'Documentar mi coche – Próximamente',
    txt_global_std_rwmsendinvitelink: 'Enviar enlace de invitación',
    txt_global_std_p2pmessagedeleted: '¡Mensaje borrado!',
    txt_global_std_clickthroughtheappintheframeandfindthetranslationyouwanttoeditorfindtheminthelistontherightside: 'Haga clic en la aplicación en el marco y busque la traducción que desea editar, o encuéntrela en la lista del lado derecho.',
    txt_global_std_allset: '¡Todo listo!',
    txt_global_std_p2pwaitingforacceptance: 'Esperando aceptación.',
    txt_global_std_createaccounttext: 'Crear una cuenta',
    txt_global_std_help: 'Ayuda',
    txt_global_std_p2pcancelledthebooking: 'canceló la reserva.',
    txt_global_std_costsplit: 'división de costos',
    txt_global_std_youcannowclosethiswindoworreturntothedashboard: 'Ahora puede cerrar esta ventana o volver al panel de control.',
    txt_global_std_getshareabledomainlink: 'Obtener enlace de dominio para compartir',
    txt_global_std_lowrisk: 'Riesgo bajo',
    txt_global_std_p2pcostsplitisbasedonkilometersdriven: 'La división de costos se basa en los kilómetros recorridos.',
    txt_global_std_tttest98: 'esto es una prueba 98',
    txt_global_std_pleasechooseareason: 'Por favor elige una razón',
    txt_global_std_domainrestrictedinvitelink: 'Enlace de invitación de dominio restringido',
    txt_global_std_selectedreader: 'Lector seleccionado',
    txt_global_std_challenge2: 'DESAFÍO',
    txt_global_std_10discountonrenewal: '10% de descuento en la renovación',
    txt_global_std_startcarengineandpleasemakesurebluetoothoncarsideisenabledifthisisyourfirsttimeconnectingyourphonetocarpleaseconnectbluetoothfromcarside: 'Arranque el motor del automóvil y asegúrese de que Bluetooth en el lado del automóvil esté habilitado.\n' +
      'Si es la primera vez que conecta su teléfono al automóvil, conecte Bluetooth desde el lado del automóvil.',
    txt_global_std_treessaved: 'Arboles salvados',
    txt_global_std_couldnotvalidateyourcode: '¡No se pudo validar su código! ¡Asegúrese de haber ingresado el código correctamente y de que el código sea válido y vuelva a intentarlo!',
    txt_global_std_JyrkiainenQuote: 'FIA Smart Driving Challenge es una excelente manera de demostrar que las cuestiones ambientales también son importantes en el automovilismo. ¡Compite contra tus amigos y conduce inteligentemente!',
    txt_global_std_preview1: 'Avance',
    txt_global_std_tojoingroup: 'Para unirse al equipo:',
    'txt_global_std_costofspeedabove{speed}': 'costo de la velocidad por encima de {speed}',
    txt_global_std__entertheemailthatyouusedwhenyouregisteredyourinsurance: 'Ingrese el correo electrónico que utilizó cuando registró su seguro.',
    txt_global_std_above1000streakunlocked: 'Condujo por encima de una puntuación de 1000, 5 veces. ¡Fantástico trabajo!',
    txt_global_std_choosenumberbetween1and9: 'Elige un número entre 1 y 9',
    txt_global_std_apitool_upsell: 'Herramienta API: la puerta de entrada para los desarrolladores a la integración de datos.',
    txt_global_std_importtranslations: 'Importar traducciones',
    txt_global_std_renewyourinsuranceformonthandgetadiscountonyourpremium: 'Renueva tu seguro por {mes} y obtén un descuento en tu prima',
    txt_global_std_compareyourtripresultstothebestecodriverswecallthemecochampions: 'Compare los resultados de su viaje con los mejores conductores ecológicos. Los llamamos campeones ecológicos.',
    txt_global_std_signup1: 'Inscribirse',
    txt_global_std_latesttripdate: 'Último viaje: {fecha}',
    txt_global_std_describepurpose: 'Describir propósito',
    txt_global_std_notvalidchassinumber: 'Número de chasis no válido',
    txt_global_std_connecttoanothercar: 'Conectar a otro coche',
    txt_global_std_insurancecustomer: 'cliente de seguros',
    txt_global_std_KleinschmidtMusic: 'Cuando conduzco prefiero escuchar la radio y la información.',
    txt_global_std_p2puserexpenses: 'gastos de {nombre}',
    txt_global_std_reviewchanges: 'Revisar cambios',
    txt_global_std_partnercode: 'Código de socio',
    txt_global_std_bluetoothsettings: 'Configuración de Bluetooth',
    txt_global_std_p2pstarttime: 'Hora de inicio:',
    txt_global_std_p2pincluded: 'Incluido',
    txt_global_std_rwminviteanewfriend: 'Invitar a un nuevo amigo',
    txt_global_std_calculateyourco2savings: 'Calcula tu ahorro de CO2',
    txt_global_std_currentbonusperiod: 'Período de bonificación actual:',
    txt_global_std_200km: '200 kilometros',
    txt_global_std_youreceivedarenewalofferformonth: 'Recibió una oferta de renovación por {mes}.',
    txt_global_std_activationcodestartdate: 'Fecha de inicio del código de activación',
    txt_global_std_previous: 'Anterior',
    txt_global_std_contributesustainabilitythroughwarikanbody: '¿Por qué no disfrutas conduciendo siendo consciente de la conducción ecológica? ¡Warikan KINTO está aquí para ayudar! La función de puntuación y la adquisición de insignias están disponibles incluso si no es usuario de KINTO ONE',
    txt_global_std_yourcarmodelistoooldanddoesnthavebluetooth: '• El modelo de su automóvil es demasiado antiguo y no tiene Bluetooth.',
    txt_global_std_uploadphotosofthedamagesorscratchesonyourcar: 'Sube fotos de los daños o rayones en tu auto',
    txt_global_std_cimage18d: 'La velocidad media planificada ahorra más tiempo que la velocidad máxima alta',
    txt_global_std_cityconditions: 'Condiciones de la ciudad',
    txt_global_std_1startyourcar: '1. Arranca tu auto.',
    txt_global_std_accessdeniedyoudonothavepermissiontoaccesstheworkspacepleasecontactyouradministratorifincorrect1: 'Acceso denegado. No tienes permiso para acceder al espacio de trabajo. Póngase en contacto con su administrador si es incorrecto.',
    txt_global_std_distancekm1: 'Distancia (km)',
    txt_global_std_challengeleaderboard_upsell: 'Panel de desafíos: fomenta la conducción segura con desafíos personalizados.',
    txt_global_std_yourchangesaresaved: '¡Tus cambios están guardados!',
    txt_global_std_p2pthispasswordhasexpired: 'Esta contraseña ha caducado',
    txt_global_std_HansenFacts: 'Kevin Hansen, el campeón europeo de superdeportivos de la FIA más joven y Novato del año de la FIA en 2016',
    txt_global_std_p2psendinvoices: 'Enviar facturas',
    txt_global_std_drivers2: 'Conductores',
    txt_global_std_p2pfaq26a: '1. Seleccione "Reservar" en la pantalla de inicio.\n' +
      '2. Seleccione "Prestar un coche".\n' +
      '3. Seleccione el miembro para el que desea alquilar un automóvil.\n' +
      '4. Seleccione la fecha y la hora.\n' +
      '5. Ingrese su mensaje (opcional) y finalmente seleccione "Enviar solicitud".\n' +
      '6. La solicitud de préstamo quedará en espera hasta que el miembro la apruebe o la rechace.',
    txt_global_std_doyouwanttoresetthepasswordforuser: '¿Quieres restablecer la contraseña de',
    txt_global_std_summaryoftripsperslectecdperiod: 'Resumen de viajes por período seleccionado.',
    txt_global_std_carsharingemptyviewborrowed: '¡Presta el coche y será un poco más divertido!',
    txt_global_std_2makesureyourphoneispairedtoyourcarviabluetooth: '2. Asegúrese de que su teléfono esté emparejado con su automóvil a través de Bluetooth.',
    txt_global_std_cardwithmoreinformationaboutpermissions: 'Tarjeta con más información sobre permisos',
    txt_global_std_newreport: 'Nuevo reporte',
    txt_global_std_p2pbookingof: ' reserva de',
    txt_global_std_MSIG_Onboarding7: '1. Haga clic en el enlace que recibió por mensaje de texto.',
    txt_global_std_kintoloyaltysystemdesc: 'Tenga en cuenta que sus ventajas se basan en los resultados finales del período de fidelidad anterior. Los puntos se obtienen al obtener logros y se restablecen al comienzo de un nuevo período.',
    txt_global_std_qhowdoestheappwork: 'P: ¿Cómo funciona la aplicación?',
    txt_global_std_p2pyourfriendinvitedyoutoborrowtheircar: 'Tu amigo te invitó a tomar prestado su auto.',
    txt_global_std_place: 'Lugar',
    txt_global_std_inorderforustoshowyouvariousstatisticsitisimportantthatthephoneissettouselocationalways: 'Para que podamos mostrarle varias estadísticas, es importante que el teléfono esté configurado para usar la ubicación "siempre"',
    txt_global_std_cimage6h: 'EVITE LOS Adelantos Innecesarios',
    txt_global_std_gotosettingsonyourcarscreenandpairthebluetoothwithyourphone: 'Vaya a la configuración en la pantalla de su automóvil y empareje el bluetooth con su teléfono',
    txt_global_std_youhavenotenabledyourprivacyundersettings: 'No ha habilitado su "privacidad" en Configuración. Cuando la "privacidad" no está habilitada, no podemos compartir con usted todos los emocionantes concursos y consejos que se brindan en la aplicación. Entonces active "privacidad" y únase a nuestra comunidad ya hoy.',
    txt_global_std_downloadcsvtemplate: 'Descargar plantilla CSV',
    txt_global_std_countryroadroundabout2DESC: 'El conductor del coche azul ajusta su velocidad para atravesar la rotonda sin problemas.\n' +
      'El conductor del coche rojo no ajusta su velocidad para entrar en la rotonda sin problemas.',
    txt_global_std_pleaseenteravalidcellphonenumber: 'Por favor, introduzca un número de teléfono móvil válido',
    txt_global_std_cimage12d: ' ',
    txt_global_std_brokenitems: '¿Artículos rotos?',
    txt_global_std_sendorder: 'Enviar orden',
    txt_global_std_selectatemplatedesc: 'Utilice esta plantilla como insignia en su aplicación. Personalízalo en el siguiente paso.',
    txt_global_std_leavewithoutchanges: 'Salir sin cambios',
    txt_global_std_sendlink: 'Enviar enlace',
    txt_global_std_p2pleavethisgroup: 'Deja este grupo',
    txt_global_std_toestablishaconnectiontoyourcarappnameneedstoaccesstobluetooth: 'Para establecer una conexión con su automóvil, {appname} necesita acceso a bluetooth.',
    txt_global_std_p2pbookingnotifications: 'Notificaciones de reserva',
    txt_global_std_PolicySchedule14: 'Controladores con nombre',
    txt_global_std_bestdriversoftheday: 'Los mejores conductores del día.',
    txt_global_std_customtabtext: 'Logros personalizados',
    txt_global_std_MarklundWhyjoin: 'Marcaremos la diferencia hacia lo mejor solo cambiando nuestro estilo de conducción. En mi equipo nos enfocaremos en marcar la diferencia, con el objetivo de ganar y en el camino, nos divertiremos. Me gustan las competencias y me gusta ganar, pero también me gusta ver crecer a mis futuros hijos en un lugar seguro y saludable conmigo allí. Eso es lo más importante.\n',
    txt_global_std_thepersonyouinvitegetaccessyoalldrivesandcost: 'La persona que invites, tendrá acceso a todos tus viajes y costos.',
    txt_global_std_drivewithanaverageofscoreorbetterforaweektounlockthisachievementandearnpointspoints: 'Conduzca con un promedio de {puntaje} o mejor durante una semana para desbloquear este logro y ganar {puntos} puntos.',
    txt_global_std_answer: 'Responder',
    txt_global_std_advancedadmin_upsell_pitch: 'Navegue fácilmente su propio viaje de éxito del cliente digital para;',
    txt_global_std_thursday: 'jueves',
    txt_global_std_cimage40h: 'CADA METRO ADICIONAL RODADO MEJORA EL RESULTADO',
    txt_global_std_datetimedistance: 'Fecha, hora, distancia',
    txt_global_std_simplyprovideyourpersonaldetailssowecansetupyouradminaccountnextyoullbeabletostartaddingteammemberstojoinyourfiasdccompanychallenge: 'Simplemente proporcione sus datos personales para que podamos configurar su cuenta de administrador. A continuación, podrá comenzar a agregar miembros del equipo para que se unan a su desafío empresarial FIA SDC.',
    txt_global_std_sharecertificate: 'Compartir certificado',
    txt_global_std_identifyfollowupandreportonsafetyandsustainabilitytargets: 'Identificar, hacer seguimiento e informar sobre objetivos de seguridad y sostenibilidad',
    txt_global_std_coporateoffice: 'Oficina Corporativa',
    txt_global_std_p2pemailstartingsoon: 'Comenzando pronto',
    txt_global_std_p2pfaq27q: '¿Qué son los privilegios de administrador?',
    txt_global_std_firstdrive: 'primer viaje',
    txt_global_std_acaddyourbrandcolors: 'Agrega los colores de tu marca',
    txt_global_std_improvementpotential: 'Potencial de mejora',
    txt_global_std_thepasswordcontainsforbiddencharactersorspace: "La contraseña contiene caracteres prohibidos: ' & / &#60; &#62; o espacio",
    txt_global_std_typeofachievement: 'tipo de logro',
    txt_global_std_connectingtogps: 'Conexión a GPS',
    txt_global_std_youshouldaddapictureofyourodometer: 'Debes agregar una foto de tu odómetro',
    txt_global_std_finishdrive: 'Terminar viaje',
    txt_global_std_app: 'aplicación',
    txt_global_std_Fia_Receipt_Text2: 'Haga clic en el enlace para comenzar a competir en FIA SDC:',
    txt_global_std_2nddate: '2do',
    txt_global_std_youcanalsouploadpicturesthatmightberelevant: 'También puede cargar imágenes que podrían ser relevantes en caso de accidente.',
    txt_global_std_startd: 'comienzo',
    txt_global_std_missingbonuspayout: 'Pago de bonificación faltante',
    txt_global_std_areyoudriving: 'Estas conduciendo',
    txt_global_std_daysshort: 'd',
    txt_global_std_publish: 'Publicar',
    txt_global_std_fivereasonswhyjoiningfiasdcisasmartbusinessdecision: 'Cinco razones por las que unirse a FIA SDC es una decisión comercial inteligente:',
    txt_global_std_timelefttoreactivateinsurance: 'Tiempo restante para reactivar el seguro',
    txt_global_std_orenteractivationcode: 'O ingrese el código de activación:',
    txt_global_std_forthisapptoworkproperlyweneedtoaccess: 'Para que esta aplicación funcione correctamente, necesitamos acceder a su ubicación, Bluetooth y estado físico/movimiento. El servicio de ubicación se utiliza en segundo plano para la función de conducción. Para analizar su conducción con resultados óptimos, la aplicación también lo hace en segundo plano. Valoramos su privacidad y no compartiremos esta información con una entidad de terceros.',
    txt_global_std_locationserviceneedstobeontoworkcorrectly: 'El servicio de ubicación debe estar activado para funcionar correctamente con la funcionalidad del mapa.',
    txt_global_std_youdonothaveanyongoingchallengesatthemoment: 'No tiene ningún desafío en curso en este momento.',
    txt_global_std_translationtool_upsell: 'Herramienta de traducción: refleje los valores de su marca comunicándolos a su manera.',
    txt_global_std_cimage23d: 'Cada metro adicional rodado tiene un impacto en la disminución o parada de la velocidad.',
    txt_global_std_insureddistance_used_100__3: '¡Oye! Ver hasta att du inte riskerar förkortning vid skador. Justera körsträckan så att den är korrekt.',
    txt_global_std_analyzingdotdot: 'Analizando...',
    txt_global_std_youmayaddaphotolater: 'Puedes agregar una foto más tarde.',
    txt_global_std_sendassms: 'Enviar como SMS',
    txt_global_std_selectinsurancetoterminate: 'Seleccione el seguro para terminar',
    txt_global_std_nowitstimetosetupyourcompanychallenge: '¡Ahora es el momento de configurar tu desafío de empresa!',
    txt_global_std_youvehavegatheredatotalof: 'Has reunido un total de',
    txt_global_std_youmusthaveinternetconnection: 'Debe tener una conexión a Internet para recibir puntajes en vivo.',
    txt_global_std_alsochallengeyourselfandengageyourfriendsinaworldcompetition: 'Además, desafíate a ti mismo e involucra a tus amigos en una competencia mundial.',
    txt_global_std_p2pnomessagesyet: 'Aún no hay mensajes',
    txt_global_std_invalidinput: 'Entrada inválida',
    txt_global_std_cimage7h: 'RÁPIDO A VELOCIDAD DE CRUCERO',
    txt_global_std_p2pdenyy: 'Negar',
    txt_global_std_surcharge: 'Recargo',
    txt_global_std_MSIG_InsuranceLetter43: 'SGD1,000.00 TODAS LAS RECLAMACIONES SE IMPONEN A LOS CONDUCTORES QUE HAN DESACTIVADO EL DONGLE DURANTE AL MENOS 14 DÍAS EN UN AÑO CALENDARIO.',
    txt_global_std_p2pgotosupport: 'ir a soporte',
    txt_global_std_cimage16h: 'PLANIFICA TU AUMENTO DE VELOCIDAD',
    txt_global_std_timeinacceleration: 'Tiempo en aceleración',
    txt_global_std_planning: 'Planificación',
    txt_global_std_whenyouvestartedadriveyoucanseeyourcarslocation: 'Cuando haya iniciado un viaje, puede ver la ubicación de su automóvil.',
    txt_global_std_p2ptimeused: 'Tiempo utilizado',
    txt_global_std_myfriends: 'Mis amigos',
    txt_global_std_1000drive: '1000 unidades!',
    txt_global_std_cimage26h: 'ENGRANAJE EN BAJAS RPM',
    txt_global_std_p2pyoublocked: 'bloqueaste',
    txt_global_std_bycheckingthisboxandlogginginyoureacceptingour: 'Al marcar esta casilla e iniciar sesión, acepta nuestros',
    txt_global_std_reportaccident2: 'Reportar Accidente',
    txt_global_std_syncingwithyourgarminconnectaccountiseasyclicktheconnectbuttonbelowtohelpusuncovernewinsightsintocrashriskbyanalyzingbothdrivingandhealthdata: '¡La sincronización con tu cuenta de Garmin Connect™ es fácil! Haga clic en el botón Conectar a continuación para ayudarnos a descubrir nuevos conocimientos sobre el riesgo de accidentes mediante el análisis de datos de conducción y de salud.',
    txt_global_std_wesharethislater: 'Compartimos esto más adelante...',
    txt_global_std_showadvancedsettings: 'Mostrar configuración avanzada',
    txt_global_std_kintofaq6a2: 'por ayuda',
    txt_global_std_rewardthosewhoexcel: '¡Recompense a los que sobresalen!',
    txt_global_std_uploadintervalminutesmin: '{uploadIntervalMinutes} min',
    txt_global_std_p2penterkintonumber: 'Ingrese el número de Kinto',
    txt_global_std_startadrivetogetascore: '¡Comience una unidad para obtener una puntuación!',
    txt_global_std_orgenerateinvitationcodeandlinkformultipleuse: 'O genere un código de invitación y un enlace para uso múltiple',
    txt_global_std_atrustedscorevettedbythefia: 'Una puntuación de confianza examinada por la FIA',
    txt_global_std_tryagain: 'Intentar otra vez',
    txt_global_std_timetoconnecttoyourcar: 'Hora de conectarte a tu coche',
    txt_global_std_MSIG_InsuranceLetter26: 'La Póliza no cubre el uso a cambio de recompensa, carreras, marcapasos, pruebas de confiabilidad, pruebas de velocidad, el transporte de mercancías que no sean muestras en relación con cualquier comercio o negocio o el uso para cualquier propósito en relación con el comercio de automóviles.',
    txt_global_std_acontactyourinsuranceagencytoterminateyourinsurance: 'R: Comuníquese con su agencia de seguros para cancelar su seguro.',
    txt_global_std_selectinsurance: 'Seleccione Seguro',
    txt_global_std_nameunavailable: 'Nombre no disponible',
    txt_global_std_p2pwarningdeletebooking: 'Esto eliminará permanentemente la reserva.',
    txt_global_std_numberoftimes: 'Numero de veces',
    txt_global_std_menu: 'Menú',
    txt_global_std_enteravalidlicenseplate: '¡Ingrese una matrícula válida!',
    txt_global_std_tuesday: 'martes',
    txt_global_std_endinsuranceforname: '¿Finalizar el seguro para {name}?',
    txt_global_std_greatprogress: '¡Gran progreso!',
    txt_global_std_enerfyanalytics_upsell_bulletpoint3: 'Obtenga más valor de los datos para mejorar el ROI',
    txt_global_std_manageyourgroups: 'Administra tus grupos',
    txt_global_std_enablepushnotifications: 'Habilitar notificaciones automáticas',
    txt_global_std_colleaguespartnersandinvitedguestscanjoinyourbrandedworkspace: 'Los colegas, socios e invitados pueden unirse a su espacio de trabajo de marca',
    txt_global_std_p2puploadaprofileimage: 'Subir una imagen de perfil',
    txt_global_std_messages: 'Mensajes',
    txt_global_std_createyourownworkspaceinthefiasmartdrivingchallengeyoucancreatemanyworkspacessuitableforcustomersandotherpartnersinviteandgettoknowmoreaboutdrivingandyourco2imprint: 'Crea tu propio espacio de trabajo en el Desafío de conducción inteligente de la FIA. Puede crear muchos espacios de trabajo, adecuados para clientes y otros socios. Invita y conoce más sobre la conducción y tu huella de CO2.',
    txt_global_std_makeapayment: 'Realizar un pago',
    txt_global_std_dashboard: 'Tablero',
    txt_global_std_selectaparent: 'Seleccione un padre',
    txt_global_std_addyourlogotypeandchoosecolorthemeexpandtoseeapreviewofthechallenge: 'Agregue su logotipo y elija el tema de color. Expanda para ver una vista previa del desafío.',
    txt_global_std_accept: 'Aceptar',
    txt_global_std_p2pfaq27a: 'Puede otorgar a los miembros algunos de los privilegios de propietario.\n' +
      'Qué puedes hacer: Gestionar reservas (aprobar/rechazar reservas de otros miembros, cambiar la hora de la reserva, etc.)',
    txt_global_std_bluetoothid: 'ID de Bluetooth',
    txt_global_std_timelocationaretheonlymandatoryfieldspleaseuploadpicturesofyourcarwithin24hours: 'La hora y la ubicación son los únicos campos obligatorios. Cargue fotos de su automóvil dentro de las 24 horas.',
    txt_global_std_cantfindyourevent: '¿No encuentras tu evento?',
    txt_global_std_step: 'Paso',
    txt_global_std_pleasetryagain: 'Inténtalo de nuevo',
    txt_global_std_improveyourdrivingandtheplanet: 'Mejora tu conducción y el planeta',
    txt_global_std_privacypolicy: 'Política de privacidad',
    txt_global_std_youcanfindotheruserguidesifyougotothefaqinthemoremenu: 'Puede encontrar otras guías de usuario si va a las preguntas frecuentes en el menú más.',
    txt_global_std_remembertotakepicturesofthedamageandsavethedatetimeandplace: 'Recuerde tomar fotografías de los daños y guardar la fecha, la hora y el lugar.',
    txt_global_std_topecodriver: 'Eco-conductor superior',
    txt_global_std_p2pclosethispopup: 'Cerrar esta ventana emergente',
    txt_global_std_highestscore: 'Puntuación más alta',
    txt_global_std_totaldistance3: 'Distancia total',
    txt_global_std_pushdriveendlevel3: 'Buen manejo. Tócame para ver tus comentarios.',
    txt_global_std_viewmarketingmessages: 'Ver noticias en Actividad',
    txt_global_std_p2pfaq28q: 'Quiero agregar derechos de administrador a un amigo',
    txt_global_std_bonus: 'Prima',
    txt_global_std_logininformation: 'Información Entrar',
    txt_global_std_uploadyourlogoandaddyourcolorstomaketheappyourown: 'Cargue su logotipo y agregue sus colores para personalizar la aplicación.',
    txt_global_std_rewards: 'Recompensas',
    txt_global_std_addyourpolicynumbertobeabletoenjoyallthebenefitsandclaimyourrewards: '¡Agregue su número de póliza para poder disfrutar de todos los beneficios y reclamar sus recompensas!',
    txt_global_std_activepoints: 'puntos activos',
    txt_global_std_yourthreemostimportantdrivingtips: 'Sus tres consejos de manejo más importantes',
    txt_global_std_avgdriver: 'Promedio Conductor',
    txt_global_std_KleinschmidtQuote: '¡Juntos podemos hacer del mundo un lugar mejor! Súmate a este reto y sé parte de una gran contribución a una conducción más segura y respetuosa con el medio ambiente. ¡Mejore sus habilidades de conducción inteligente, diviértase mucho y disfrute de una competencia emocionante!',
    txt_global_std_top3bestdriverscomingsoon: 'Próximamente los 3 mejores pilotos',
    txt_global_std_p2pchoosecar: 'Elige Coche',
    txt_global_std_p2pminimumbookingtime: 'El tiempo mínimo de reserva es de 30 minutos.',
    txt_global_std_syncedwithgarminconnect: 'Sincronizado con Garmin Connect™',
    txt_global_std_send2: 'Enviar',
    txt_global_std_entertheactivationcodeyouhavereceivedfromyourworkspaceadministrator: 'Introduce el código de activación que has recibido del administrador de tu espacio de trabajo',
    txt_global_std_removingaparticipantfromachallengedoesnotdeletetheuserortheirdrivingdataintheappiftheyhavealreadydriven: 'Eliminar a un participante de un desafío no elimina al usuario ni sus datos de conducción en la aplicación, si ya ha conducido.',
    txt_global_EnerfyLeaderboardTemplate_keepituptext: '¡Sigue conduciendo bien y con inteligencia y pronto subirás de rango!',
    txt_global_std_readmore1: 'Lee mas',
    txt_global_std_carstats: 'Estadísticas de autos',
    txt_global_std_viewall: 'Ver todo',
    txt_global_std_comingup: 'Subiendo',
    txt_global_std_enerfyanalytics_upsell_pitch_bulletpoint3: 'Obtenga más valor de los datos para mejorar el ROI',
    txt_global_std_showonmap: 'Mostrar en el mapa',
    txt_global_std_activationcodeemail2: 'Aquí está su código de activación',
    txt_global_std_syncyourgarminconnectaccount: 'Sincroniza tu cuenta de Garmin Connect™',
    txt_global_std_garmin15discountcodeforparticipantsandprizesformonthlywinners: 'Código de descuento del 15% de Garmin para los participantes y premios para los ganadores mensuales.',
    txt_global_std_changepassword: 'Cambia la contraseña',
    txt_global_std_sooncompleted: '¡Pronto completado!',
    txt_global_std_skilluplockedkey: 'Para obtener esta insignia, mantenga una puntuación superior a {score} durante una semana.',
    txt_global_std_maycontactmeonphoneemailandsms: 'Sí, {appName} y Greater Than pueden comunicarse conmigo por teléfono, correo electrónico, aplicación de manejo {appName} y SMS con respecto a recibir noticias y ofertas especiales de Greater Than sobre seguros, productos preventivos de lesiones, competidores y otros servicios de prueba. Siempre puede leer y preferir sus referencias para marketing.',
    txt_global_std_mypage: 'Mi página',
    txt_global_std_MSIG_InsuranceLetter15: 'A',
    txt_global_std_insurancenumber2: 'En s. número',
    txt_global_std_ChicheritMileage: 'Próximamente, en breve, pronto...',
    txt_global_std_activitydetails: 'Detalles de la actividad',
    txt_global_std_lockeddescription: 'Bloqueado Cuando la credencial no <br />todavía se ha recibido',
    txt_global_std_TomczykQuote: 'El desafío de conducción inteligente de la FIA me animó a conducir de manera más económica, más segura y con más atención. Después de conducir, puede ver en tiempo real qué tan bien condujo y compararlo con otros conductores de todo el mundo.\n' +
      'Únete a mi equipo y logremos juntos el máximo de puntos. Ponte a prueba e intenta ser el mejor conductor inteligente del mundo.',
    txt_global_std_level2: 'Nivel',
    txt_global_std_ChicheritBesttip: 'Próximamente, en breve, pronto...',
    txt_global_std_p2plendborrow: 'Prestar/Tomar prestado',
    txt_global_std_p2pchoosecarcarstolend: 'Elija coche/coches para prestar',
    txt_global_std_newchallenge: 'Nuevo desafío',
    txt_global_std_p2pfaq28a: 'Puede seleccionar miembros y otorgarles privilegios de administrador para el automóvil.\n' +
      '1. Seleccione "Miembros" en el "Menú".\n' +
      '2. Seleccione un miembro para ver su perfil.\n' +
      '3. Seleccione Configuración.\n' +
      '4. Opere el botón del automóvil al que desea dar permiso para dar permiso.',
    txt_global_std_yourobdreaderhasalreadybeenorderedandyoucanexpectthereadertoarrivewithin35businessdays: 'Su lector OBD ya ha sido pedido y puede esperar que el lector llegue dentro de 3 a 5 días hábiles.',
    txt_global_std_p2ppushtitleborrowcarnomessage: 'Nueva solicitud de préstamo recibida',
    txt_global_std_regno2: 'registro No',
    txt_global_std_ifyoudonthavebluetoothinyourcaryoucanpluginaobdreaderanobdreadercanbepluggedintocarsmanufacturedyear2001andlater: 'Si no tiene Bluetooth en su automóvil, puede conectar un lector OBD.\n' +
      '\n' +
      'Se puede conectar un lector OBD a los automóviles fabricados en el año 2001 y posteriores.',
    txt_global_std_sdclevel: 'Nivel COSUDE:',
    txt_global_std_PaddonMusic: 'Cuando conduzco, me gustaría escuchar música relajante/suave como Coldplay, Mumford and Sons, etc.',
    txt_global_std_validateyourdata: 'Valida tus datos',
    txt_global_std_kintoEncouragement2: '¡Tu conducción está salvando el planeta! Ahorras {percent}% frente al promedio.',
    txt_global_std_youbooked15timesinonemonthyouhaveearned3500points: 'Has ganado 3.500 puntos por hacer 15 reservas en un mes.',
    txt_global_std_entercodehere: 'Ingresa el código aquí',
    txt_global_std_p2pgotomykinto: 'Ir a mi Kinto',
    txt_global_std_trytheapp: 'Prueba la aplicación',
    txt_global_std_enteremailaddressyoucanentermorethanoneifyouseparatethembyacomma: 'Ingrese la dirección de correo electrónico, puede ingresar más de uno si los separa con una coma.',
    txt_global_std_firstdrivelocked: 'Desbloqueas este logro cuando conduces por primera vez.',
    txt_global_std_onceaclaimsettlementisdeterminedyoucanreceiveacheckoranelectronicpaymentintoyourbankaccount: 'Una vez que se determina la liquidación de un reclamo, puede recibir un cheque o un pago electrónico en su cuenta bancaria.',
    txt_global_std_youmustpickaninsurance: 'Debes elegir un seguro',
    txt_global_std_disabled: 'Desactivado',
    txt_global_std_changenow: 'Cambia ahora',
    txt_global_std_earn2500pointsifyoubook10timesamonth: 'Gana 2.500 puntos al realizar 10 reservas en un mes.',
    txt_global_std_areyousureyouwanttoremoveuserfromthegroupremovingtheuserfromthegroupdoesnotdeletetheusersaccount: '¿Está seguro de que desea eliminar al usuario del grupo?\n' +
      '\n' +
      'Eliminar al usuario del grupo no elimina la cuenta de los usuarios.',
    txt_global_std_p2perrorcodestartofbookingisoverlapping: 'El inicio de la reserva se superpone.',
    txt_global_std_activationcode2: 'Código de activación',
    txt_global_std_createaccount: 'Inscribirse',
    txt_global_std_thisisatranslation: 'Esta es una traducción.',
    txt_global_std_p2pseefriendcode: 'Mostrar o canjear códigos de amigos',
    txt_global_std_challengeaverage2: 'Desafío promedio:',
    txt_global_std_activatebluetoothinsettings: 'Activa el bluetooth en tu teléfono',
    txt_global_std_usermanagement: 'Gestión de usuarios',
    txt_global_std_dateoftraining: 'Fecha de entrenamiento',
    txt_global_std_publishyourdemoapp: 'Publica tu aplicación de demostración',
    txt_global_std_saveandcontinue: 'Guardar y continuar',
    txt_global_std_maxcharacters: 'máx. 15 caracteres',
    txt_global_std_locationofaccident: 'Lugar del accidente',
    txt_global_std_thechallengenamecantbelongerthan30characters: 'El nombre del desafío no puede tener más de 30 caracteres.',
    txt_global_std_leaderboardoverview: 'Descripción general de la tabla de clasificación',
    txt_global_std_createnewachievement: 'Crear nuevo logro',
    txt_global_std_pleasereportyouraccidentusingtheclaimsformassoonaspossible: 'Informe su accidente utilizando el formulario de reclamaciones lo antes posible.',
    txt_global_std_matched: 'emparejado',
    txt_global_std_retardationdescription: 'Planifica la conducción. Prolongue la energía del vehículo soltando antes el pedal del acelerador.',
    txt_global_std_cropyourteamimage: 'Recorta la imagen de tu equipo',
    txt_global_std_examplephone: '12 34 56 78',
    txt_global_std_p2ppleaseselectadate: 'Por favor seleccione una fecha',
    txt_global_std_onboardingnotificationstext: 'Permita que {appName} le envíe notificaciones cuando tenga acciones que hacer en la aplicación.',
    txt_global_std_p2ppushbodychangeperiodfromto: '{name} cambió de período.\nDe: {de}\nPara: {para}',
    txt_global_std_verygooddriving: '¡Muy buena conducción!',
    txt_global_std_challengeleaderboard: 'Tablero de desafíos',
    txt_global_std_uploadphoto: 'Subir foto',
    txt_global_std_motorclaimshotline2: 'Línea Directa de Reclamaciones de Automóviles',
    txt_global_std_doyouhaveanymileagethatyoufancy: 'Tienes algún kilometraje que más te apetece conducir, ¿por qué este?',
    txt_global_std_kintoImprovement1: '¡Por favor conduzca con cuidado! ¡Nos preocupamos por usted!',
    txt_global_std_byinvitingfriends: 'Al invitar amigos',
    txt_global_std_skillupunlockedkey: '¡Buen trabajo {usuario}! Mantuviste una puntuación superior a {score} durante una semana.',
    txt_global_std_p2pyoublockedyourcar: 'Bloqueaste tu auto',
    txt_global_std_100000pts: '> 100 000 puntos',
    txt_global_std_p2pforfamilyfriends: 'Para familiares y amigos',
    txt_global_std_OgierMileage: 'Próximamente, en breve, pronto...',
    txt_global_std_foryourowndocumentation: 'Para su propia documentación :)',
    txt_global_std_TidemandFacts: 'El primer contacto de Pontus con la competencia llegó en los karts de cross, donde se convirtió en campeón nórdico y sueco. 2011, el piloto más joven en ganar el Campeonato Sueco de Rally',
    txt_global_std_jun: 'Jun',
    txt_global_std_thisfeatureisnotavailableyet: 'Esta función aún no está disponible',
    txt_global_std_reason1: 'Razón',
    txt_global_std_createyourownchallengetoinspiresmartdrivingexpandtoseeapreviewofthechallenge: '¡Crea tu propio desafío para inspirar la conducción inteligente! Expanda para ver una vista previa del desafío.',
    txt_global_std_friendfinder: 'Buscador de amigos',
    txt_global_std_lexusweekend: 'Fin de semana de Lexus',
    txt_global_std_agreements: 'Acuerdos',
    txt_global_std_p2pfaq25q: '¿Dónde puedo consultar los datos de conducción?',
    txt_global_std_sendinvitationby: 'Enviar invitación por',
    txt_global_std_claims: 'Reclamación (es',
    txt_global_std_carstatstext: 'Estadísticas de autos',
    txt_global_std_5000pointaverageachievementunlocked: '¡Condujiste muy bien! ¡Tu {scoretype} promedio fue mejor que {score} durante una semana! ¡Has ganado 5000 puntos!',
    txt_global_std_tttest97: 'esto es una prueba 97 2',
    txt_global_std_wewouldrecommenddrivertraining: 'Recomendamos la formación de conductores con especial atención al enfoque y la planificación del conductor. Lo que significa que nunca pierdas el enfoque en el tráfico y las condiciones que te rodean. Somos Yamato y siempre enfocamos y planificamos la conducción con especial atención a la Amabilidad, Priorización, Autocontrol Emocional y Confianza.',
    txt_global_std_searchandmanageusers: 'Buscar y administrar usuarios',
    txt_global_std_howtogetstarted: 'Cómo empezar',
    txt_global_std_fixedpremium: 'prima mensual fija',
    txt_global_std_open: 'Abierto',
    txt_global_std_createanaccount: 'Crea una cuenta',
    txt_global_std_whatifichangecaranswer: 'Informe al equipo de {appname} por correo electrónico. Luego, vuelva a conectarse a su nuevo automóvil siguiendo los pasos de "Conectar al automóvil" nuevamente.',
    txt_global_std_connectyourcarandstartadrive: 'Conecte su automóvil y comience a conducir para ver sus ahorros estimados. Pulse el botón de conducción para empezar.',
    txt_global_std_greaterthansprivacypolicy: 'Política de privacidad de Greater Than.',
    txt_global_std_followupontheonboardingstatusofyourusersclickonastatusbarbelowtoseeandhelpyourusersthatmightbestuck: 'Haga un seguimiento del estado de incorporación de sus usuarios, haga clic en una barra de estado a continuación para ver y ayudar a los usuarios que podrían estar atascados.',
    txt_global_std_p2pwelcomekintoowner: '¡Bienvenido, propietario de Kinto!',
    txt_global_std_p2ppleasetypeprice: 'Por favor escriba precio',
    txt_global_std_unpublishtranslations: 'Cancelar la publicación de traducciones',
    txt_global_std_p2pendtime: 'Hora de finalización:',
    txt_global_std_clickheretocallsupport: 'Haga clic {aquí} para llamar a soporte',
    txt_global_std_50km: '50 kilometros',
    txt_global_std_kintoEncouragement1: '¡Guau! ¡Estás entre los mejores controladores de Kinto!',
    txt_global_std_loyalty_upsell_pitch_bulletpoint3: 'Aumente la visibilidad de su programa para ofrecer el mejor ROI posible',
    txt_global_std_publishapptest: 'Publicar prueba de aplicación',
    txt_global_std_startadrive_: 'Iniciar una unidad',
    txt_global_std_300pointTripAchievementunlocked: '¡Condujo a {scoretype} {score} o mejor! ¡Impresionante! ¡Has ganado 300 puntos!',
    txt_global_std_p2pemailunreadmessages: 'Mensajes no leídos',
    txt_global_std_ecofriendlockedkey: 'Conduzca de manera ecológica y ahorre más de {co2value}% CO₂ en una semana para ganar esta insignia.',
    txt_global_std_p2ppushtitlenewinvoicetopay: 'Nueva factura a pagar',
    txt_global_std_rateapp: 'Calificar aplicacion',
    txt_global_std_invitelink: 'Enlace de invitación',
    txt_global_std_achievementtool_upsell_pitch_bulletpoint1: 'Anime a los conductores a desarrollar nuevas habilidades a través de la gamificación',
    txt_global_std_reportcentersworkshops: 'Centros de informes y talleres',
    txt_global_std_loyalty_upsell_pitch_bulletpoint2: 'Recompense a los conductores inteligentes para fomentar el compromiso y la retención',
    txt_global_std_cimage8d: 'Acelera suavemente cuando el tráfico o la carretera hagan que tu velocidad varíe mucho.',
    txt_global_std_p2plendmycartwo: 'prestar mi coche',
    txt_global_std_youwillcomebacktothesesettingsthenexttimeyoupressthedrivebutton: 'Volverá a esta configuración la próxima vez que presione el botón de conducción.',
    txt_global_std_HusseinAbdullaBesttip: 'Paciencia',
    txt_global_std_advancedadmin_upsell: 'Administrador avanzado: portal de autogestión simple para una experiencia de usuario poderosa.',
    txt_global_std_selecttime: 'Seleccionar hora',
    txt_global_std_deletingthegroupdoesnotdeletetheusersaccounts: 'Eliminar el grupo no elimina las cuentas de los usuarios.',
    txt_global_std_addyourmileage: 'Añade tu kilometraje',
    txt_global_std_totalnooftrips: 'Nº total de viajes',
    txt_global_std_thatwasaverygooddrive: '¡Ese fue un muy buen viaje!',
    txt_global_std_weeklyheat: 'Calor semanal',
    txt_global_std_ConfirmFia_Text12: 'Asegúrate de llegar al lugar 10 minutos antes de la hora programada. Nuestro personal le ayudará a registrar y encontrar su coche.',
    txt_global_std_mediumrisk: 'Riesgo medio',
    txt_global_std_enerfyanalytics_upsell: 'Enerfy Analytics: herramienta de análisis de datos fácil de usar para generar informes potentes.',
    txt_global_std_finishadriveatscoretypescoreorbettertounlockthisbadge: 'Termina un recorrido en {scoretype} {score} o mejor para desbloquear esta insignia.',
    txt_global_std_pleaseenterthenameofyourcontactperson: 'Por favor ingrese el nombre de su persona de contacto',
    txt_global_std_sendinvitetocountfriends: '¿Enviar invitación a {count} amigos?',
    txt_global_std_insurancedetails: 'Detalles del seguro',
    txt_global_std_kintorefer50friendslocked: 'Desbloquee este logro y gane 5000 yenes al recomendar a 50 usuarios de Kinto.',
    txt_global_std_v2level: 'Nivel',
    txt_global_std_userhasnopermissions: 'El usuario no tiene permisos',
    txt_global_std_soonyouwillbeabletochallengeotherdriversinsafeandecofriendlydriving: 'Pronto podrá desafiar a otros conductores en una conducción segura y ecológica.',
    txt_global_std_civilstatus: 'Estado civil',
    txt_global_std_qhowdoisignupforcovercubeinsurance: 'P: ¿Cómo me inscribo en el seguro de Covercube?',
    txt_global_std_ifyouhaveone: '-Si tienes uno',
    txt_global_std_drivingstatisticstabs: 'Pestañas de estadísticas de conducción',
    txt_global_std_signinsurance: 'firmar seguro',
    txt_global_std_p2pcheckyouremail: 'Consultar su correo electrónico',
    txt_global_std_whatisyourbesttiptodrivesmart: '¿Cuál es tu mejor consejo para conducir inteligentemente?',
    txt_global_std_call: 'Llamar',
    txt_global_std_test: 'prueba.',
    txt_global_std_fitmarkers: 'Marcadores de ajuste',
    txt_global_std_yourcar2: 'Tu carro',
    txt_global_std_wanttoshareyourprogresswithyourfriends: '¿Quieres compartir tu progreso con tus amigos?',
    txt_global_std_autostartobd: 'Inicio automático de OBD',
    txt_global_std_excellent: 'Excelente',
    txt_global_std_theindividualdriverscoreiscalculatedonanaveragebasedonthetop10trips: '• La puntuación del conductor individual se calcula en un promedio basado en el 10% de los mejores viajes.',
    txt_global_std_email: 'Correo electrónico',
    txt_global_std_sdcrules: 'Reglas de la COSUDE',
    txt_global_std_howdoicompeteinlexuschallengeanswer: 'Participas automáticamente cuando te registras. No se preocupe, la tabla de clasificación solo mostrará su apodo, por lo que siempre permanecerá en el anonimato. Hay una competencia mensual y anual.',
    txt_global_std_howwillyouknowaboutmydrivingbehaviour: '¿Cómo sabrán acerca de mi comportamiento al volante?',
    txt_global_std_ridewithmetext: '¡Compartir un viaje con un amigo en lugar de usar dos autos ahorra mucho CO2! Al registrar un viaje compartido, tanto usted como su amigo ganarán puntos',
    txt_global_std_enerfyisaproductfromgreaterthanab: 'Enerfy es un producto de Greater Than AB.',
    txt_global_std_therulesforthecompanychallengeareasfollowing: 'Las reglas para el desafío de la empresa son las siguientes:',
    txt_global_std_ecofeedbacktips: '<b>Tu puntuación ecológica mide qué tan respetuoso con el medio ambiente conduces.</b> Puede estar relacionado con tu planificación de muchas maneras.<br><br>\n' +
      '<b>Ajusta tus aceleraciones</b> para evitar frenadas y paradas innecesarias. Cada aceleración y frenado consumen energía.<br><br>\n' +
      'Juntos podemos <b>marcar la diferencia</b> para el futuro.',
    txt_global_std_hhmmss: 'hh:mm:ss',
    txt_global_std_httpstatuscodesummary: 'Resumen del código de estado HTTP',
    txt_global_std_iwantrematch: '¡Quiero la revancha!',
    txt_global_std_errors: 'errores',
    txt_global_std_availablepointexchange: 'Intercambio de puntos disponibles',
    txt_global_std_p2punavailable: 'Indisponible',
    txt_global_std_daysleftuntilrenewal: 'Días restantes hasta la renovación',
    txt_global_std_trainername: 'Nombre del entrenador',
    txt_global_std_required: 'Requerido',
    txt_global_std_ifyouwantinfoonnotificationscallphonenumberorgotoanchorlinkorgotorouterlinkpage: 'Si desea información sobre las notificaciones, llame a {phoneNumber} o vaya a {anchorLink} o vaya a la página {routerLink}.',
    txt_global_std_p2pyouborrowedusersvehiclextimes: 'Tomaste prestado {usuario} {coche} {veces} veces',
    txt_global_std_topthreeworldteams: 'Los 3 mejores equipos del mundo',
    txt_global_std_attributes: 'Atributos',
    txt_global_std_reporting: 'Informes',
    txt_global_std_reportdamage: 'Reportar daño',
    txt_global_std_welcomebackenerfy: '¡Bienvenido de nuevo a {appName}!',
    txt_global_std_p2pfinishandpay: 'Finalizar',
    txt_global_std_p2pcash: 'Dinero',
    txt_global_std_kintofaq7q: '¿Mi coche no tiene Bluetooth?',
    txt_global_std_maintainalowrisklevelbykeepingyourfocusontheroadandplanahead: 'Mantenga un nivel de riesgo bajo manteniendo su enfoque en el camino y planifique con anticipación.',
    'txt_global_std_Its time for some fun. Join a challenge!': 'Es hora de divertirse. ¡Únete a un desafío!',
    txt_global_std__emailaddress: 'Dirección de correo electrónico',
    txt_global_std_fuelinjectorcleaner: 'Limpiador de inyectores de combustible',
    txt_global_std_pleasenotethattojoinyoumusthavetheaanzappinstalledonyourmobiledeviceinordertobeabletojointhegroup: 'Tenga en cuenta que para unirse debe tener instalada la aplicación DSFB Driver en su dispositivo móvil para poder unirse al equipo.',
    txt_global_std_enteryouremailadress: 'Introduce tu correo electrónico',
    txt_global_std_BeforeFirstBill_Text6: 'Sinceramente,\r\n{nombre de la aplicación}',
    txt_global_std_avgkm7d: 'Promedio km 7D',
    txt_global_std_finished: 'Acabado',
    txt_global_std_turnonbluetoothviayourcarsinfotainmentsystemstereoandfollowtheinstructionsforpairingyourphone: 'Encienda Bluetooth a través del sistema de infoentretenimiento/estéreo de su automóvil y siga las instrucciones para vincular su teléfono.',
    txt_global_std_returntoooverview: 'Volver a la descripción general',
    txt_global_std_repeatyournewpassword: 'Repite tu nueva contraseña',
    txt_global_std_savechangespopupheader: '¿Guardar cambios?',
    txt_global_std_finishedtraining: 'entrenamiento terminado',
    txt_global_std_howdoiearnsavingslexusanswer: 'Los ahorros se generan cuando se conduce seguro. Por cada viaje que conduzcas seguro, generarás un ahorro por kilómetro. ¡Cuanto más seguro conduzca, mayor será el ahorro! El ahorro es visible directamente después de que termine su viaje y será visible en su billetera Lexus.',
    txt_global_std_prefernottosay: 'Prefiero no decirlo',
    txt_global_std_coverimage: 'Imagen de portada:',
    txt_global_std_pleasefocus: '¡Por favor concéntrate!',
    txt_global_std_communication_upsell_pitch_bulletpoint1: 'Mejore la experiencia y el compromiso del usuario',
    txt_global_std_secretuser: 'Secreto',
    txt_global_std_redeemcoupon: 'Canjear cupón',
    txt_global_std_createanaccounttosavealldrivingdata: 'Cree una cuenta para guardar todos los datos de conducción, realizar un seguimiento de sus rutas y guardar sus recompensas para futuros viajes.',
    txt_global_std_needhelp: '¿Necesitas ayuda?',
    txt_global_std_usernameshort: 'Nombre de usuario',
    txt_global_std_zipriskindexhelpsyoudeterminepreciserisklevelbasedondrivinglocation: 'El índice de riesgo Zip lo ayuda a determinar el nivel de riesgo preciso según la ubicación de conducción.',
    txt_global_std_mycardoesnthavebluetooth: '¿Mi coche no tiene Bluetooth?',
    txt_global_std_setupyourfiasdccompanychallenge: 'Configure su espacio de trabajo de la empresa FIA SDC.',
    txt_global_std_p2pallofyourtripsgatheredinoneplacegetaccesstofeedbackandstatisticsaboutyourdrives: 'Todos tus viajes reunidos en un solo lugar. Obtenga acceso a comentarios y estadísticas sobre sus viajes.',
    txt_global_std_firstscore: 'Seamos los primeros con puntaje en el heat',
    txt_global_std_youneedtoinputanemail: 'Necesita ingresar un correo electrónico',
    txt_global_std_PolicySchedule6: 'hasta la medianoche del',
    txt_global_std_yousaved: 'Salvaste',
    txt_global_std_socialsecuritynumber: 'Número de seguro social',
    txt_global_std_noregnumberregistered: 'No hay número de registro registrado',
    txt_global_std_skeyscore: 'Puntaje',
    txt_global_std_participants: 'Participantes',
    txt_global_std_qhowdoisignupforcompanyinsurance: 'P: ¿Cómo me inscribo en el seguro de [Compañía]?',
    txt_global_std_p2perrorcodeendofbookingisoverlapping: 'El final de la reserva se superpone',
    txt_global_std_yourpasswordmustconsistofatleastfive: 'Su contraseña debe constar de al menos cinco (5) caracteres.',
    txt_global_std_challengeleaderboard_upsell_pitch_bulletpoint1: 'Inspirar una conducción más inteligente, ayudando a reducir el riesgo, el uso de combustible y las emisiones de CO2',
    txt_global_std_ecoscore: 'Puntuación ecológica',
    txt_global_std_p2pbookingfinished: 'Reserva terminada',
    txt_global_std_youcanfindyourresultshortlyinyourtrips: 'Puedes encontrar tu resultado en breve en tus viajes.',
    txt_global_std_alltabtext: 'Todos',
    txt_global_std_buynow: 'Comprar ahora',
    txt_global_std_myleague: 'Mi liga',
    txt_global_std_startdrivingtoappearonleaderboard: '¡Empieza a conducir para aparecer en la clasificación!',
    txt_global_std_toprovideyouwithaccuratestatsanddrivingscoreappnameneedstoruninbackgroundmodebrinthenextpopupbrchooseballowallthetimeb: 'Para brindarle estadísticas precisas y puntaje de manejo, {appname} debe ejecutarse en segundo plano. <br>En la siguiente ventana emergente:<br>Elija:\n' +
      ' <b>Permitir todo el tiempo</b>',
    txt_global_std_aninsuranceadjusterwillcontactyouwithinhourshoursofsubmittingyourclaimtheentireclaimsprocesswilldetermineonthecomplexityoftheclaim: 'Un ajustador de seguros se comunicará con usted dentro de las {horas} horas posteriores a la presentación de su reclamo. Todo el proceso de reclamos determinará la complejidad del reclamo.',
    txt_global_std_recruiter: 'Reclutador',
    txt_global_std_forthisapptoworklocationservices: 'Para que esta aplicación funcione correctamente y registre sus viajes, queremos que cambie el permiso de los servicios de ubicación a "Siempre". Si no lo sabe, aparecerá esta ventana emergente más adelante.',
    txt_global_std_welcomeletsgetstarted: '¡Bienvenidos! ¡Empecemos!',
    txt_global_std_uploadgroupimage: 'Subir imagen de grupo',
    txt_global_std_married: 'Casado',
    txt_global_std_PolicySchedule21: 'OFD',
    txt_global_std_VilliersFacts: 'Giniel De Villiers nunca se ha retirado. Mejor aún, solo una vez no ha terminado en el Top 10 (en 2007). Tiene 14 victorias de etapa y en 2009 ganó el primer Dakar de Sudamérica.',
    txt_global_std_inallourappswehavecontactinformationsothatuserscancontactthecompanyfillinyourcompanyscontactinformationhere: 'En todas nuestras apps tenemos información de contacto para que los usuarios puedan contactar con la empresa. Complete la información de contacto de su empresa aquí.',
    txt_global_std_back: 'atrás',
    txt_global_std_pleasegivethreeoptionsincaseyourfirstoptionsisalreadyused: 'Proporcione tres opciones en caso de que su primera opción (s) ya se haya utilizado.',
    txt_global_std_advancedadmin1: '  Administrador avanzado',
    txt_global_std_yourpasswordmustconsistofatleastten: 'Su contraseña debe constar de al menos diez (10) caracteres.',
    txt_global_std_completed: 'Terminado',
    txt_global_std_documentsentto: 'Documento enviado a',
    txt_global_std_maybesavedwhenfocusingonthisspeed: 'puede ahorrarse cuando se enfoca en mejorar en esta velocidad',
    txt_global_std_estimatedsavings: 'Ahorro estimado',
    txt_global_std_step1: 'Paso 1',
    txt_global_std_recruiterlockedkey: 'Invita a un amigo a la aplicación para ganar esta insignia. Obtienes esta insignia cuando tu amigo crea una cuenta en la aplicación.',
    txt_global_std_p2pdecreasetextsize: 'Disminuir el tamaño del texto',
    txt_global_std_translationtool: 'Herramienta de traducción',
    txt_global_std_insurancecompanydescription: 'Para precios de precisión y excelencia en suscripción.',
    txt_global_std_terminate: 'Terminar',
    txt_global_std_cimage4d: 'Conducir con luz roja',
    txt_global_std_showofflinedrives: 'Mostrar unidades fuera de línea',
    txt_global_std_cantseeyourcar: 'no puedo ver tu auto',
    txt_global_std_enerfydynamiccollectslocationdatatoenabledrivingscoreevenwhentheappisclosedornotinuse: 'Enerfy Dynamic recopila datos de ubicación para habilitar la puntuación de conducción incluso cuando la aplicación está cerrada o no está en uso.'
  }
};
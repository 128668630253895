import { StripeCustomer, Product, Subscription } from '@/types/stripeCustomer';
import { MutationTree } from 'vuex';
import { StripeState } from './types';

export const mutations: MutationTree<StripeState> = {
  setCustomer(state, payload: StripeCustomer): void {
    state.Customer = payload;
  },
  setProducts(state, payload: Product[]): void {
    state.Products = payload;
  },
  setSubscription(state, payload: Subscription): void {
    state.Subscription = payload;
  }
};

import {Locale} from '@/store/user/types';

export const locales = [
  {
    title: 'English',
    value: 'en'
  },
  {
    title: '日本語',
    value: 'ja'
  },
  {
    title: 'Française',
    value: 'fr'
  },
  {
    title: 'Deutsch',
    value: 'de'
  },
  {
    title: 'Español',
    value: 'es'
  }
] as Locale[];

import api from '@/api/toolbar';
import { GlobalActionTrigger, ToolbarActionTrigger } from '@/types/toolbar';
import { ActionTree } from 'vuex';
import { RootState } from '../rootState';
import { ToolbarState } from './types';

export enum ToolbarActions {
  addAction = 'toolbar/addAction',
  addActionGroup = 'toolbar/addActionGroup'
}

export const actions: ActionTree<ToolbarState, RootState> = {

  async addAction( {} , payload: GlobalActionTrigger) {
    try {
      const res = await api.addAction(payload);
      return res.data;
    } catch (e) {
      await Promise.reject(e);
    }
  },
  async addActionGroup( {} , payload: ToolbarActionTrigger) {
    try {
      const res = await api.addActionGroup(payload);
      return res.data;
    } catch (e) {
      await Promise.reject(e);
    }
  }

};

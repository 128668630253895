import { AchievementStatusEnum } from '@/store/achievement/enums';
import { IAchievmentDefault } from '@/store/achievement/types';
import { achievementhandler } from '../utils/http';
import { AxiosCall, Methods, IResponseObject } from './misc';

// Standard endpoint urls to use
enum AchievementApiUrls {
    base = 'achievement-types'
}

/**
 * Use this bad boi to easier make api calls and have error handeling
 * @param payload {@link AxiosCall} contining the proper properites for executing this function
 * @returns Promise<{@link IResponseObject}>
 */
const apiHandler = async (payload: AxiosCall): Promise<IResponseObject> => {
    try {
        const { status, data } = await (achievementhandler[payload.method] as any)(
            payload.url,
            (payload.payload ?? [])[0],
            (payload.payload ?? [])[1]
        );
        if (status < 200 || status >= 300) {
            throw new Error(
                `Error code:  ${status} in function function ${payload.error.functionName}`
            );
        }
        return { data, error: null };
    } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error);
        return {
            data: null,
            error: payload.error.userError
        };
    }
};

export const getAchievementsByAlk = async (alk: string): Promise<IResponseObject> => {

    const params = new URLSearchParams();
    params.append('licenseKey', alk);

    const axiosSetup: AxiosCall = {
        url: AchievementApiUrls.base,
        method: Methods.GET,
        payload: [{params}],
        error: {
            functionName: 'getAchievementsByAlk',
            userError:
                'Something went wrong when fetching achievement. Please try again'
        }
    };

    return await apiHandler(axiosSetup);
};

export const createAchievement = async (createPayload: IAchievmentDefault): Promise<IResponseObject> => {
    const params = new URLSearchParams();
    params.append('licenseKey', createPayload.licenseKey ?? '');

    const axiosSetup: AxiosCall = {
        url: AchievementApiUrls.base,
        method: Methods.POST,
        payload: [createPayload, {params}],
        error: {
            functionName: 'createAchievement',
            userError:
                'Something went wrong when creating achievement. Please try again'
        }
    };
    return await apiHandler(axiosSetup);
};

export const updateAchievement = async (achivementId: string | undefined = '', updatedPayload: IAchievmentDefault): Promise<IResponseObject> => {

    const axiosSetup: AxiosCall = {
        url : `${AchievementApiUrls.base}/${achivementId}`,
        method: Methods.PUT,
        payload: [updatedPayload],
        error: {
            functionName: 'updateAchievement',
            userError:
                'Something went wrong when updating achievement. Please try again'
        }
    };
    return await apiHandler(axiosSetup);
};

export const deleteAchievement = async  (achivementId: string | undefined = ''): Promise<IResponseObject> => {

    const axiosSetup: AxiosCall = {
        url : `${AchievementApiUrls.base}/${achivementId}`,
        method: Methods.DELETE,
        error: {
            functionName: 'deleteAchievement',
            userError:
                'Something went wrong when deleting achievement. Please try again'
        }
    };
    return await apiHandler(axiosSetup);
};

export const publishUnpublishAchievement = async (achivementId: string | undefined = '', statusAction: AchievementStatusEnum): Promise<IResponseObject> => {

    const axiosSetup: AxiosCall = {
        url : `${AchievementApiUrls.base}/${achivementId}`,
        method: Methods.PATCH,
        payload: [{status: statusAction}],
        error: {
            functionName: 'publishUnpublishAchievement',
            userError: 'Something went wrong. Please try again'
        }
    };
    return await apiHandler(axiosSetup);
};

import { StripeState } from './types';
import { GetterTree } from 'vuex';
import { RootState } from '../rootState';
import { Product, StripeCustomer } from '@/types/stripeCustomer';

export const getters: GetterTree<StripeState, RootState> = {
  getUser(state): StripeCustomer | undefined {
    if (state.Customer.id) {
      return state.Customer;
    }
    const storageCustomer = sessionStorage.getItem('stripeCustomer');
    if (storageCustomer) {
      return JSON.parse(storageCustomer);
    }
  },
  getProducts(state): Product[] {
    return state.Products;
  }
};

import { MutationTree } from 'vuex';
import { LanguageAbbreviation } from './enums';
import { getDefaultState } from './initialState';
import { ITranslationDefault, TranslationEntry } from './types';

export enum TranslationMutations {
    setSelectedLanguage = 'translation/setSelectedLanguage',
    setPreviousSelectedLanguages = 'translation/setPreviousSelectedLanguages',
    setFetchingTranslations = 'translation/setFetchingTranslations',
    setNameKey = 'translation/setNameKey',
    setLockedKey = 'translation/setLockedKey',
    setUnlockedKey = 'translation/setUnlockedKey',
    addLangProperty = 'translation/addLangProperty',
    removeLangProperty = 'translation/removeLangProperty',
    setNameKeyObject = 'translation/setNameKeyObject',
    setLockedKeyObject = 'translation/setLockedKeyObject',
    setUnlockedKeyObject = 'translation/setUnlockedKeyObject',
    setTranslations = 'translation/setTranslations',
    resetState = 'translation/resetState'
}

export const mutations: MutationTree<ITranslationDefault> = {
    setSelectedLanguage(state, payload: TranslationEntry): void {
        state.selectedLanguage = payload;
    },
    setPreviousSelectedLanguages(state, payload: LanguageAbbreviation[]): void {
        state.previousSelectedLanguages = payload;
    },

    setFetchingTranslations(state, payload: boolean): void {
        state.fetchingTranslations = payload;
    },
    /**
     * Set a value to the key that matches the value of state.selectedLanguage. Ex [state.selectedLanguage] : value
     * @param state
     * @param payload String value
     */
    setNameKey(state, payload: string): void {
        state.translations.nameKey[state.selectedLanguage.lang] = payload;
    },
    setLockedKey(state, payload: string): void {
        state.translations.lockedKey[state.selectedLanguage.lang] = payload;
    },
    setUnlockedKey(state, payload: string): void {
        state.translations.unlockedKey[state.selectedLanguage.lang] = payload;
    },
    addLangProperty(state, payload: string) {
        state.translations.nameKey = { ...state.translations.nameKey, ...{ [payload]: '' } };
        state.translations.lockedKey = { ...state.translations.lockedKey, ...{ [payload]: '' } };
        state.translations.unlockedKey = { ...state.translations.unlockedKey, ...{ [payload]: '' } };
    },
    removeLangProperty(state, payload: string) {
        delete state.translations.nameKey[payload];
        delete state.translations.lockedKey[payload];
        delete state.translations.unlockedKey[payload];
    },
    setTranslations(state, payload) {
        state.translations = payload;
    },
    resetState(state): void {
        Object.assign(state, getDefaultState());
    }
};

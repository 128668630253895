import { IResponseObject } from '@/api/misc';
import { imageBlobStorageHandler } from './http';

export const convertFileToBase64 = async (file: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        const temporaryFileReader = new FileReader();
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException('Problem parsing input file.'));
        };
        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsDataURL(file);
    });
};

export const convertUrlToBase64 = async (url: string): Promise<IResponseObject> => {
    try {
        const { status, data } = await imageBlobStorageHandler.get(url);
        if (status !== 200) {
            throw new Error();
        }
        return {
            data: (await convertFileToBase64(data)) as string,
            error: null
        };
    } catch (error) {
        return { data: null, error: 'error' };
    }
};

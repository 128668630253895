import { agreementHandler } from '@/utils/http';
import { IResponseObject } from './misc';

enum AgreementUrls {
    agentScoreType = 'agents/'
}

export const getAgentScoreType = async (licenseKey: string): Promise<IResponseObject> => {
    if (!licenseKey) {
        return { data: '', error: null };
    }
    try {
        const { status, data } = await agreementHandler.get(AgreementUrls.agentScoreType + licenseKey);
        if (status < 200 || status >= 300) {
            throw new Error(`Error code:  ${status}.`);
        }
        return { data: { scoreType: data.drivingScoreTypeInApp }, error: null };
    } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error);
        return {
            data: null,
            error: 'Something whent wrong when trying to fetch the agent scoretype!'
        };
    }
};

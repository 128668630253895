import { IAchievmentDefault } from '../achievement/types';

export interface IFilter {
    key: string;
    value: any;
}
export const AgentScoreType = {
    LevelDecimal: 4,
    Level100: 8,
    SdcScore: 16,
    StarGrade: 32
} as const;

export type AgentScoreType = typeof AgentScoreType[keyof typeof AgentScoreType];

export interface IAchievementMisc {
    template_achivements: IAchievmentDefault[];
    licenseKey_achievements: IAchievmentDefault[];
    current_licenseKey: string;
    idsOfUpdatedAchievements: Set<string>;
    timeStampOfLatestUpdate: number | null;
    displayableAchievements: IAchievmentDefault[];
    filters: IFilter[];
    agentScoreType: AgentScoreType | null;
}

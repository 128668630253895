import { Module } from 'vuex';
import { UserState } from './types';
import { RootState } from '../rootState';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

// Look for session storage first
let u;
if (sessionStorage.getItem('user') !== null) {
  u = JSON.parse(sessionStorage.getItem('user') || '');
}
// Vue won't be able to attach watchers to properties in state if default values are not set
const state: UserState = {
  access_token: undefined,
  token_type: undefined,
  expires_in: undefined,
  refresh_token: undefined,
  user: u,
  roles: undefined,
  agent: undefined,
  isLoggingIn: false,
  loginState: false
};

export const user: Module<UserState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};

import { api, app04 } from '../utils/http';

const setToken = (accessToken: string): void => {
  // tslint:disable-next-line: no-string-literal
  api.defaults.headers.common.token = accessToken;
  api.defaults.headers.common['X-Authtoken'] = accessToken;
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  app04.defaults.headers.common.token = accessToken;
  app04.defaults.headers.common['X-Authtoken'] = accessToken;
  app04.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

const removeToken = (): void => {
  // tslint:disable-next-line: no-string-literal
  delete api.defaults.headers.common.token;
  delete api.defaults.headers.common['X-Authtoken'];

  delete app04.defaults.headers.common.token;
  delete app04.defaults.headers.common['X-Authtoken'];
};

const setClient = (agent: string): void => {
  // tslint:disable-next-line: no-string-literal
  api.defaults.headers.common.licenseKey = agent;

  app04.defaults.headers.common.licenseKey = agent;
};

export {
  setToken,
  setClient,
  removeToken
};

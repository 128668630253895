import { AchievementStatusEnum, IconStatus } from './enums';
import { IAchievmentDefault } from './types';

const state: IAchievmentDefault = {
    licenseKey: undefined,
    name: undefined,
    group: undefined,
    key: undefined,
    nameKey: undefined,
    points: undefined,
    lockedKey: undefined,
    lockedIcon: undefined,
    unlockedKey: undefined,
    unlockedIcon: undefined,
    comparisonOpType: undefined,
    comparisonValue: undefined,
    comparisonValueType: undefined,
    actionType: undefined,
    timeType: undefined,
    onceOnly: false,
    unlockLimit: 1,
    score: 0,
    jsonUrl: null,
    atlasUrl: null,
    backgroundColor: undefined,
    isTemplate: true,
    translations: undefined,
    status: AchievementStatusEnum.UNPUBLISH,
    lockedIconStatus: IconStatus.NOT_CHANGED,
    unlockedIconStatus: IconStatus.NOT_CHANGED
};

export function getDefaultState() {
    return { ...state };
}

export const enum Methods {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
    PATCH = 'patch'
}

export interface AxiosCall {
    url: string;
    method: Methods;
    payload?: any[];
    error: IErrorObject;
}
export interface IResponseObject {
    error: any;
    data: any;
}

export interface IErrorObject {
    functionName: string;
    userError: string;
}

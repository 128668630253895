export enum LanguageAbbreviation {
    VI = 'vi',
    DE = 'de',
    DA = 'da',
    FR = 'fr',
    PL = 'pl',
    FI = 'fi',
    SV = 'sv',
    JA = 'ja',
    NB = 'nb',
    ID = 'id',
    EN = 'en'
}

import stripeApi from '@/api/stripe';
import { ActionTree } from 'vuex';
import { RootState } from '../rootState';
import { AxiosResponse } from 'axios';
import {
  AddSubscriptionItemRequest,
  CheckoutSession,
  NewSubscriptionRequest,
  PortalSession,
  PortalSessionRequest,
  Product,
  StripeCustomer,
  StripeSession,
  Subscription,
  SubscriptionItem
} from '@/types/stripeCustomer';
import { StripeState } from './types';

export enum StripeActions {
  checkoutSession = 'stripe/checkoutSession',
  addStripeCustomer = 'stripe/addStripeCustomer',
  fetchStripeCustomer = 'stripe/fetchStripeCustomer',
  fetchCheckoutSession = 'stripe/fetchCheckoutSession',
  portalSession = 'stripe/portalSession',
  fetchStripeProducts = 'stripe/fetchStripeProducts',
  createSubscription = 'stripe/createSubscription',
  addProductToSubscription = 'stripe/addProductToSubscription'
}

export const actions: ActionTree<StripeState, RootState> = {
    async addProductToSubscription({commit}, request: AddSubscriptionItemRequest): Promise<AxiosResponse<SubscriptionItem[]>> {
      return await stripeApi.addProductsToSubscription(request);
    },
    async fetchStripeProducts({commit}, productId: string[]): Promise<Product[]> {
      const res = await stripeApi.fetchStripeProducts(productId);
      commit('setProducts', res.data);
      return res.data;
    },
    async createSubscription({commit}, payload: NewSubscriptionRequest): Promise<Subscription> {
      const res = await stripeApi.addStripeSubscription(payload);
      commit('setSubscription', res.data.data);
      return res.data;
    },
    async checkoutSession({}, payload: NewSubscriptionRequest): Promise<CheckoutSession> {
      const res: AxiosResponse<CheckoutSession> = await stripeApi.createPaymentSession(payload);
      return res.data;
    },
    async fetchCheckoutSession({}, payload: string): Promise<StripeSession> {
      const res: AxiosResponse<StripeSession> = await stripeApi.fetchPaymentSession(payload);
      return res.data;
    },
    async portalSession({}, payload: PortalSessionRequest): Promise<PortalSession> {
      const res: AxiosResponse<PortalSession> = await stripeApi.fetchPortalSession(payload);
      return res.data;
    },
    async addStripeCustomer({ commit }, payload: StripeCustomer) {
        try {
          let res: AxiosResponse<StripeCustomer>;
          res = await stripeApi.addStripeCustomer(payload);
          commit('setCustomer', res.data);
          return res.data;
        } catch (e) {
          Promise.reject(e);
        }
      },
    async fetchStripeCustomer({commit}, alk: string) {
        try {
          let res: AxiosResponse<StripeCustomer>;
          res = await stripeApi.fetchStripeCustomer(alk);
          sessionStorage.setItem('stripeCustomer', JSON.stringify(res.data));
          commit('setCustomer', res.data);
        } catch (e) {
          Promise.reject(e);
        }
    }
};



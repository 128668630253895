import { GetterTree } from 'vuex';
import { RootState } from '../rootState';
import { LanguageAbbreviation } from './enums';
import { ITranslationDefault, ITranslations, TranslationEntry } from './types';

export enum TranslationGetters {
    getStringifiedTranslations = 'translation/getStringifiedTranslations',
    getNameKey = 'translation/getNameKey',
    getLockedKey = 'translation/getLockedKey',
    getUnlockedKey = 'translation/getUnlockedKey',
    getSelectedLanguage = 'translation/getSelectedLanguage',
    getPreviousSelectedLanguages = 'translation/getPreviousSelectedLanguages',
    getTranslations = 'translation/getTranslations',
    isFetchingTranslations = 'translation/isFetchingTranslations',
    hasSelectedBeenTranslated = 'translation/hasSelectedBeenTranslated'
}

export const getters: GetterTree<ITranslationDefault, RootState> = {
    getSelectedLanguage(state): TranslationEntry {
        return state.selectedLanguage;
    },
    getPreviousSelectedLanguages(state): LanguageAbbreviation[] {
        return state.previousSelectedLanguages;
    },
    isFetchingTranslations(state): boolean {
        return state.fetchingTranslations;
    },
    getNameKey(state): string | undefined {
        return state.translations.nameKey[state.selectedLanguage.lang];
    },
    getLockedKey(state): string | undefined {
        return state.translations.lockedKey[state.selectedLanguage.lang];
    },
    getUnlockedKey(state): string | undefined {
        return state.translations.unlockedKey[state.selectedLanguage.lang];
    },
    getStringifiedTranslations(state): string {
        return JSON.stringify(state.translations);
    },
    getTranslations(state): ITranslations {
        return state.translations;
    },
    hasSelectedBeenTranslated: (state) => {
        return state.previousSelectedLanguages.includes(state.selectedLanguage.lang);
    }
};

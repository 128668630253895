import { ToolbarState } from './types';
import { GetterTree } from 'vuex';
import { RootState } from '../rootState';
import { SelectedGroup, SelectedUser } from '@/types/toolbar';

export const getters: GetterTree<ToolbarState, RootState> = {
  getSelectedUsers(state): SelectedUser[] | undefined {
    return state.SelectedUsers;
  },
  getSelectedGroups(state): SelectedGroup[] | undefined {
    return state.SelectedGroups;
  }
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.refLogin),expression:"!refLogin"}]},[_c('div',{staticClass:"login-language-container d-flex justify-end"},[_c('MenuDropdown',{staticClass:"app-usersettings",attrs:{"items":_vm.locales},on:{"click":function (locale) { return _vm.$router.replace(("/" + locale + "/Login")); }},model:{value:(_vm.$userLocale),callback:function ($$v) {_vm.$userLocale=$$v},expression:"$userLocale"}})],1),_c('div',{key:_vm.$userLocale,staticClass:"box"},[_c('div',{staticClass:"login"},[(!_vm.hastwofactor)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"header"},[_c('span',{directives:[{name:"localize",rawName:"v-localize",value:({ key: 'welcome' }),expression:"{ key: 'welcome' }"}]})]),_c('div',{staticClass:"form"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":_vm.$localize('email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"outlined":"","placeholder":"","error-messages":errors,"label":_vm.$localize('email'),"required":"","readonly":"","onfocus":"this.removeAttribute('readonly');"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$localize('password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input mt-4",attrs:{"outlined":"","error-messages":errors,"label":_vm.$localize('password'),"type":_vm.show ? 'text' : 'password',"append-icon":_vm.show ? 'far fa-eye' : 'far fa-eye-slash login-eye-icon',"required":"","readonly":"","onfocus":"this.removeAttribute('readonly');"},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Two Factor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(false)?_c('v-text-field',{staticClass:"input",attrs:{"outlined":"","error-messages":errors,"label":"Two factor code","type":"text","required":"","readonly":"","onfocus":"this.removeAttribute('readonly');"},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.securityCode),callback:function ($$v) {_vm.securityCode=$$v},expression:"securityCode"}}):_vm._e()]}}],null,true)}),_c('div',{staticClass:"checkbox-area",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"left"},[_c('validation-provider',{attrs:{"rules":"required","name":"checkbox"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"checkbox",attrs:{"error-messages":errors,"value":"1","type":"checkbox","required":"","color":"primary","on-icon":'far fa-check-square',"off-icon":'far fa-square'},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})]}}],null,true)})],1),_c('div',{staticClass:"right"},[_c('span',{staticClass:"privacy",attrs:{"id":"privacy"}},[_vm._v(" "+_vm._s(_vm._f("localize")('bycheckingthischeckboxandlogginginyoureacceptingour'))+" "),_c('a',{attrs:{"target":"_blank","href":"https://az414782.vo.msecnd.net/fma/100/8185780a-c644-49f6-be15-0f5ec1fb0a56.pdf"}},[_vm._v(_vm._s(_vm._f("localize")('gdprterms')))]),_vm._v(" & "),_c('a',{attrs:{"target":"_blank","href":" https://greaterthan.eu/wp-content/uploads/2019/10/Enerfy-Rules-Of-Service.pdf"}},[_vm._v(_vm._s(_vm._f("localize")('usertermsandconditions')))])])])]),_c('div',{staticClass:"footer mt-12"},[_c('router-link',{directives:[{name:"localize",rawName:"v-localize",value:({ key: 'resetpassword' }),expression:"{ key: 'resetpassword' }"}],staticClass:"link",attrs:{"to":"/ResetPassword"}}),_c('br'),_c('a',{directives:[{name:"localize",rawName:"v-localize",value:({ key: 'noaccountsignuphere' }),expression:"{ key: 'noaccountsignuphere' }"}],staticClass:"link",on:{"click":_vm.signup}}),_c('v-btn',{staticClass:"float-right",attrs:{"type":"submit","disabled":invalid,"color":"primary"}},[_vm._v(" "+_vm._s(_vm._f("localize")('_login'))+" ")])],1)],1)]}}],null,false,3462647153)})],1)]):_vm._e(),(_vm.hastwofactor)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"header"},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('twofactorauth')))]),_c('p',[_vm._v(_vm._s(_vm._f("localize")('inputyourcodetologin')))]),_c('div',{staticClass:"form"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Two Factor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input mt-4",attrs:{"outlined":"","error-messages":errors,"label":_vm.$localize('securitycode'),"type":"text","required":""},model:{value:(_vm.securityCode),callback:function ($$v) {_vm.securityCode=$$v},expression:"securityCode"}})]}}],null,true)}),_c('div',{staticClass:"footer"},[_c('a',{directives:[{name:"localize",rawName:"v-localize",value:({ key: 'back' }),expression:"{ key: 'back' }"}],staticClass:"link",attrs:{"to":"/Login"},on:{"click":function($event){_vm.hastwofactor = false}}}),_c('v-btn',{staticClass:"float-right caption",attrs:{"type":"submit","disabled":invalid,"color":"primary","depressed":""}},[_vm._v(" "+_vm._s(_vm._f("localize")('login'))+" ")])],1)],1)]}}],null,false,4175464616)})],1)])]):_vm._e()]),_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":(_vm.$mediaUrl + "/fma/100/8e1cf063-bd4e-421c-9c0b-cf062a799ad6.png"),"height":"526","alt":"road by ocean"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }
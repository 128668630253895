import { User } from '@/types/user';
import { MiscState } from './types';
import { GetterTree } from 'vuex';
import { RootState } from '../rootState';
import { MenuItem } from '@/types/menu';

export const getters: GetterTree<MiscState, RootState> = {
  menuItems(state): MenuItem[] | undefined {
    return state.MenuItems;
  },
  showLoading(state): boolean {
    return state.showLoading;
  }
};

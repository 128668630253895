import { MutationTree } from 'vuex';
import { IAchievmentDefault } from '../achievement/types';
import { AgentScoreType, IAchievementMisc, IFilter } from './types';

export enum AchievementMiscMutations {
    setTemplates = 'achievementMisc/setTemplates',
    setAchievements = 'achievementMisc/setAchievements',
    setCurrentLicenseKey = 'achievementMisc/setCurrentLicenseKey',
    addIdOfUpdatedAchievement = 'achievementMisc/addIdOfUpdatedAchievement',
    setTimeStampOfLatestUpdate = 'achievementMisc/setTimeStampOfLatestUpdate',
    setDisplayableAchievements = 'achievementMisc/setDisplayableAchievements',
    setFilters = 'achievementMisc/setFilters',
    setAgentScoreType = 'achievementMisc/setAgentScoreType'
}

export const mutations: MutationTree<IAchievementMisc> = {
    setTemplates(state, payload: IAchievmentDefault[]): void {
        state.template_achivements = payload;
        state.current_licenseKey = '';
    },
    setAchievements(state, payload: IAchievmentDefault[]): void {
        state.licenseKey_achievements = payload;
        state.displayableAchievements = payload;
    },
    setCurrentLicenseKey(state, payload: string): void {
        state.current_licenseKey = payload;
    },
    addIdOfUpdatedAchievement(state, payload: string): void {
        state.idsOfUpdatedAchievements.add(payload);
    },
    setTimeStampOfLatestUpdate(state, payload: number): void {
        state.timeStampOfLatestUpdate = payload;
    },
    setDisplayableAchievements(state, payload: IAchievmentDefault[]): void {
        state.displayableAchievements = payload;
    },
    setFilters(state, payload: IFilter[]): void {
        state.filters = payload;
    },
    setAgentScoreType(state, payload: AgentScoreType | null): void {
        state.agentScoreType = payload;
    }
};
